import { Injectable } from "@angular/core";
import { getApp } from "@angular/fire/app";
import { Firestore, collection, getDocs, query, where } from "@angular/fire/firestore";
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import algoliasearch, { SearchIndex } from 'algoliasearch';
import * as _ from 'lodash';
import MeiliSearch, { Index, SearchResponse } from "meilisearch";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { FormatUtils } from "../utils/format-utils";
import { AuthService } from "./auth.service";
import {
  CalendarEventIndex,
  CustomChargeCodes,
  CustomChargeCodeStatus, Invoice,
  INVOICE_LINE_TYPE, MEMBER_STATUS, PatientAccountIndex,
  PROVIDER_MEDICAL_TYPE_CODE, SearchOptions,
  PROVIDER_SPECIALITY,
  SPECIALITY_MEDICAL_TYPE
} from '@meraki-flux/schema';

export interface LookupSelection {
  Code: string;
  Description: string;
  Quantity?: number;
  Price?: number;
  DisplayString?: string;
  Data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private readonly diagnosisIndex!: SearchIndex;
  private readonly medicinesIndex!: SearchIndex;
  private readonly tariffCodesIndex: Index;
  private readonly meiliSearchClient!: MeiliSearch;
  private readonly meiliSearchLookup!: MeiliSearch;
  private readonly meiliSearchAccount!: MeiliSearch;
  private readonly meiliSearchInvoice!: MeiliSearch;
  private readonly meiliSearchCalendar!: MeiliSearch;
  private allCustomChargeCodes$ = new BehaviorSubject<CustomChargeCodes[]>([]);

  REPLACEMENT_CHAR_REGEX = /\uFFFD/g;
  totalCount: number;

  // TODO: Clean up this service and move keys to appropriate storage like firebase.
  constructor(
    private firestore: Firestore,
    private auth: AuthService,
  ) {
    const client = algoliasearch('7YZ5D9RL1C', '6822793c2c51c5a104c9b973577476b7');
    this.diagnosisIndex = client.initIndex('prod_DIAGNOSES');
    this.medicinesIndex = client.initIndex('prod_MEDICINES');

    this.meiliSearchClient = new MeiliSearch({
      host: environment.meilisearchHost,
      apiKey: environment.meilisearchSearchKey,
    });

    this.meiliSearchLookup = new MeiliSearch({
      host: environment.meilisearchLookup,
      apiKey: environment.meilisearchLookupKey,
    });

    this.meiliSearchAccount = new MeiliSearch({
      host: environment.meilisearchAccount,
      apiKey: environment.meilisearchAccountKey,
    });

    this.meiliSearchInvoice = new MeiliSearch({
      host: environment.meilisearchInvoice,
      apiKey: environment.meilisearchInvoiceKey,
    });

    this.meiliSearchCalendar = new MeiliSearch({
      host: environment.meilisearchCalendar,
      apiKey: environment.meilisearchCalendarKey,
    });

    this.tariffCodesIndex = this.meiliSearchLookup.index('tariff_code');

    // Load custom charge codes
    // All custom charge codes are loaded here and filtered as needed.
    getDocs(query(
      collection(this.firestore, `Practice/${this.auth.selectedBPN}/CustomChargeCode`),
      where('Status', '==', CustomChargeCodeStatus.ACTIVE)
    )).then((res) => {
      this.allCustomChargeCodes$.next(res.docs.map(doc => doc.data() as CustomChargeCodes));
    });
  }

  private formatHighlightedValue(value: string) {
    return value.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>');
  }

  private formatHighlightedValueProcedures(value: string, query: string) {
    return value.replace(query, `<strong>${query}</strong>`);
  }

  private cleanup(description: string) {
    return description?.replace(this.REPLACEMENT_CHAR_REGEX, '') || '';
  }

  formatDiagnosis(serachResult: any): LookupSelection[] {
    const result = serachResult.hits.map(hit => {
      return {
        Code: hit._highlightResult.data.ICD10Code.value,
        Description: this.cleanup(hit.data.ICD10CodeDescription),
        DisplayString: this.cleanup(`${hit._highlightResult.data.ICD10Code.value} - ${this.formatHighlightedValue(hit._highlightResult.data.ICD10CodeDescription.value)}`),
      };
    });
    return _.flattenDeep<LookupSelection>(result);
  }

  async searchDiagnosis(query: string = '', pageLimit: number = 10, page: number = 0) {
    const result = await this.diagnosisIndex.search(query, {
      facetFilters: ['data.ValidForClinicalUse:1'],
      hitsPerPage: pageLimit,
      page
    });
    return result;
  }

  async getDiagnosis(objectId: string) {
    return await this.diagnosisIndex.getObject(objectId);
  }

  async searchDiagnosisAutocomplete(query: string = '') {
    const result = await this.searchDiagnosis(query);
    return this.formatDiagnosis(result);
  }

  formatMedicine(searchResult: any, searchType: "MEDICINE" | "CONSUMABLE" = "MEDICINE"): LookupSelection[] {
    const result = searchResult.hits.map(hit =>
      hit.data.PackInfo.map((pack, index) => {
        const hasProductStrength = hit.data.ProductStrength;
        const hasVolume = pack?.Volume;
        const productStrength = `${hit?.data?.ProductStrength} ${hit?.data?.Units}`;
        const volume = `${pack?.Volume} ${hit?.data?.VolumeUnits}`;
        const packInfo = `(Pack size: ${pack.StdDispensingVolumePacksize} - R${pack.SingleExitPricePerPack})`;
        const dspStrings = [];
        if (hit._highlightResult) {
          const discontinued = hit.data?.PackInfo[index]?.ProductStatus === 'I' ? 'DISC ' : '';
          dspStrings.push(`${this.formatHighlightedValue(hit._highlightResult.data.PackInfo[index].NAPPICode10.value)} - ${discontinued}${this.formatHighlightedValue(hit._highlightResult.data.ProductName.value)}`);
          if (hit.data?.ProductForm) dspStrings.push(hit.data?.ProductForm);
          if (hasProductStrength) dspStrings.push(productStrength);
          if (hasVolume) dspStrings.push(volume);
          dspStrings.push(packInfo);
        } else {
          dspStrings.push(`${hit.data.PackInfo[index].NAPPICode10} - ${hit.data.ProductName} ${hit.data.ProductForm}`);
          if (hit.data?.ProductForm) dspStrings.push(hit.data?.ProductForm);
          if (hasProductStrength) dspStrings.push(productStrength);
          if (hasVolume) dspStrings.push(volume);
          dspStrings.push(packInfo);
        }
        const descStrings = [hit.data.ProductName];
        if (hit.data?.ProductForm) descStrings.push(hit.data?.ProductForm);
        if (hasProductStrength) descStrings.push(productStrength);
        if (hasVolume) descStrings.push(volume);
        if (hit.data?.PackInfo[index]?.ProductStatus === 'I') descStrings.unshift('DISC');
        return {
          Code: pack.NAPPICode10?.length > 9 && pack.NAPPICode10?.startsWith("0") ? pack.NAPPICode10.substring(1) : pack.NAPPICode10,
          Description: this.cleanup(FormatUtils.join(descStrings, ' ')),
          Quantity: pack.StdDispensingVolumePacksize,
          Price: pack.SingleExitPricePerPack,
          DisplayString: this.cleanup(FormatUtils.join(dspStrings, ' ')),
          Data: hit.data,
        };
      })
    );
    return _.flattenDeep<LookupSelection>(result);
  }

  async searchMedicines(query: string = '', pageLimit: number = 10, page: number = 0, searchType: "MEDICINE" | "CONSUMABLE" = "MEDICINE") {
    const result = await this.medicinesIndex.search(query, {
      hitsPerPage: pageLimit,
      page,
      filters: searchType === "CONSUMABLE" ? `(data.ConsumableDrugFlag:1 OR data.ConsumableDrugFlag:2)` : `(data.ConsumableDrugFlag:0 OR data.ConsumableDrugFlag:2)`
    });
    return result;
  }

  async searchMedicinesAutocomplete(query: string = '', lineType: INVOICE_LINE_TYPE = INVOICE_LINE_TYPE.MEDICINE, pageLimit: number = 10, page: number = 0) {
    const searchType = lineType === INVOICE_LINE_TYPE.CONSUMABLE ? "CONSUMABLE" : "MEDICINE";
    const result = await this.searchMedicines(query, pageLimit, page, searchType);
    
    // if the total nbhits is more than 1000, default to 1000. 
    // Anything more than 1000 is not needed or needs to increase the page limit to get more results.
    this.totalCount = result.nbHits > 1000 ? 1000 : result.nbHits;
    return this.formatMedicine(result, searchType);
  }

  async getMedicine(fullNappi: string, searchType: "MEDICINE" | "CONSUMABLE" = "MEDICINE"): Promise<LookupSelection> {
    try {
      // get only first 6 digits of nappi code
      const shortNappi = fullNappi.substring(0, 6);
      let medicine: any;

      // The below try / catch is to handle some fringe case medicines.
      try {
        medicine = await this.medicinesIndex.getObject(shortNappi); }
      catch (the_above_short_nappi_does_not_find_a_med){
        medicine = await this.medicinesIndex.getObject(fullNappi.substring(0, fullNappi.length - 3));
      }

      const formatedMeds = this.formatMedicine({ hits: [medicine] }, searchType);
      // return the medicine that matches the full nappi code
      return formatedMeds.find(med => med.Code === fullNappi);
    } catch (cant_find_based_on_default_search) {
      const medicines = await this.searchMedicinesAutocomplete(fullNappi, searchType === "MEDICINE" ? INVOICE_LINE_TYPE.MEDICINE : INVOICE_LINE_TYPE.CONSUMABLE, 1);
      const med = medicines.filter(m => m.Code === fullNappi);
      return med.length > 0 ? med[0] : null;
    }
  }

  async searchProcedures(query: string = '', year: number = new Date().getFullYear(), type: string = '10', page: number = 0, pageLimit: number = 10) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const searchProcedures = httpsCallable(functions, 'med-sea-v1-oncall-searchProcedure');
    let field = "Code";
    if (isNaN(+query.charAt(0))) {
      field = "Description";
    }
    const result: any = await searchProcedures({ query: query, year: year, field: field, type: type, page: page, pageLimit: pageLimit });
    return result.data.data.payload.PageResult;
  }

  async searchTariffCodes(query = '', type: INVOICE_LINE_TYPE.PROCEDURE | INVOICE_LINE_TYPE.MODIFIER = INVOICE_LINE_TYPE.PROCEDURE, disciplineCode: string = null, providerTypeCode: PROVIDER_MEDICAL_TYPE_CODE = 'MP'): Promise<{ Code: string, Description: string, DisplayString: string }[]> {
    const filter: string[] = [
      `TypeCode=${type === INVOICE_LINE_TYPE.MODIFIER ? '99' : '10'}`,
      `TariffMedicalTypeCode=${providerTypeCode}`,
    ];
    if(disciplineCode) {
      filter.push(`DisciplineCode=${disciplineCode}`)
    }
    const result = await this.tariffCodesIndex.search(query, {
      attributesToHighlight: ['Code', 'Description'],
      attributesToRetrieve: ['Code', 'Description'],
      highlightPreTag: '<strong>',
      highlightPostTag: '</strong>',
      limit: 10,
      filter: filter
    });

    return result.hits
      //filter out hits that have a code ending with A
      .filter(hit => !hit.Code.endsWith('A'))
      .map(hit => ({
        Code: hit.Code,
        Description: this.cleanup(hit?.Description),
        DisplayString: `${hit._formatted?.Code} ${this.cleanup(hit?._formatted?.Description)}`
      }));
  }

  async findTariffCode(code: string, type: INVOICE_LINE_TYPE.PROCEDURE | INVOICE_LINE_TYPE.MODIFIER = INVOICE_LINE_TYPE.PROCEDURE, providerTypeCode: PROVIDER_MEDICAL_TYPE_CODE = 'MP'): Promise<{ Code: string, Description: string, DisplayString: string }> {
    const searchResult = await this.tariffCodesIndex.search('', {
      limit: 1,
      attributesToRetrieve: ['Code', 'Description'],
      filter: [
        `TypeCode=${type === INVOICE_LINE_TYPE.MODIFIER ? '99' : '10'}`,
        `Code=${code}`,
        `TariffMedicalTypeCode=${providerTypeCode}`
      ]
    });

    if (searchResult?.hits?.length > 0) {
      return {
        Code: searchResult.hits[0].Code,
        Description: searchResult.hits[0].Description,
        DisplayString: searchResult.hits[0].Description
      }
    }

    return null;

  }

  formatProcedures(searchResult: any, query: string) {
    const result = searchResult.map(hit => {
      const desc: string = hit?.Description;
      const dspString = `${this.formatHighlightedValueProcedures(hit.Code, query)} - ${this.formatHighlightedValueProcedures(desc, query)}`
      return {
        Code: hit.Code,
        Description: this.cleanup(desc),
        DisplayString: this.cleanup(dspString)
      };
    }
    );
    return _.flattenDeep<LookupSelection>(result);
  }

  async searchProceduresAutocomplete(query: string = '', lineType: INVOICE_LINE_TYPE, providerSpeciality: string = '') {
    if (providerSpeciality) {
      const providerTypeCode = this.getProviderMedicalType(providerSpeciality);
      const disciplineCode = providerTypeCode === 'MP' || providerTypeCode === 'DP' ? null : this.getProviderSpecialityCode(providerSpeciality);
      const invoiceLineType = lineType === INVOICE_LINE_TYPE.MODIFIER ? INVOICE_LINE_TYPE.MODIFIER : INVOICE_LINE_TYPE.PROCEDURE;
      const result = await this.searchTariffCodes(query.trim(), invoiceLineType, disciplineCode, providerTypeCode);
      return this.formatProcedures(result, query);
    } else {
      const result = await this.searchProcedures(query, undefined, lineType === INVOICE_LINE_TYPE.MODIFIER ? '99' : '10');
      return this.formatProcedures(result, query);
    }
  }

  getProviderSpecialityCode(providerSpeciality: string) {
    let typeCode: string;
    if (providerSpeciality) {
      typeCode = "" + PROVIDER_SPECIALITY[providerSpeciality];
    }
    typeCode = typeCode.padStart(3, "0");
    return typeCode;
  }

  getProviderMedicalType(providerSpeciality: string): PROVIDER_MEDICAL_TYPE_CODE {    
    let typeCode;
    if (providerSpeciality) {
      typeCode = SPECIALITY_MEDICAL_TYPE[providerSpeciality];
    }
    return typeCode || 'MP';
  }

  searchCustomAutocomplete(query: string = '', lineType: any): LookupSelection[] {
    const searchVal = query?.toLowerCase().trim();
    const res = this.allCustomChargeCodes$.value.filter(val => val.Type === lineType &&
      (val.Code?.toLowerCase().startsWith(searchVal) || val.Description?.toLowerCase().includes(searchVal)));
    const mappedRes = res.map(ccode => {
      const desc = this.cleanup(ccode?.Description || 'Unknown');
      return {
        Code: ccode.Code,
        Description: desc,
        Price: ccode.UnitPrice || 0,
        Quantity: 1,
        DisplayString: this.cleanup(`${this.formatHighlightedValueProcedures(ccode.Code, query)} - ${this.formatHighlightedValueProcedures(desc, query)}`)
      }
    });
    return mappedRes;
  }

  async searchAutocomplete(query: string = '', lineType: INVOICE_LINE_TYPE, providerSpeciality: string = ''): Promise<LookupSelection[]> {
    switch (lineType) {
      case INVOICE_LINE_TYPE.MEDICINE:
        return await this.searchMedicinesAutocomplete(query, lineType);
      case INVOICE_LINE_TYPE.PROCEDURE:
        return await this.searchProceduresAutocomplete(query, lineType, providerSpeciality);
      case INVOICE_LINE_TYPE.CONSUMABLE:
        return await this.searchMedicinesAutocomplete(query, lineType);
      case INVOICE_LINE_TYPE.MODIFIER:
        return await this.searchProceduresAutocomplete(query, lineType, providerSpeciality);
      case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
        return this.searchCustomAutocomplete(query, lineType);
      case INVOICE_LINE_TYPE.CSTM_MEDICINE:
        return this.searchCustomAutocomplete(query, lineType);
      case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
        return this.searchCustomAutocomplete(query, lineType);
      case INVOICE_LINE_TYPE.ADMIN:
        return await this.searchCustomAutocomplete(query, lineType);
      default:
        return [];
    }
  }

  async searchPatients(practiceId: string, query: string, options?: SearchOptions) {
    let index: Index;
    index = this.meiliSearchAccount.index(`patient_${practiceId}`);
    const defaultFilter = [
      `PracticeId = ${practiceId}`,
      `Patient.AccountMemberStatus = ${MEMBER_STATUS.ACTIVE}`
    ];

    const limit = options?.limit || 1000;
    const hitsPerPage = options?.hitsPerPage || limit;
    const page = options?.page || 1;
    const sort = options?.sort || [];

    const searchOptions = {
      hitsPerPage,
      page,
      limit,
      sort,
      filter: [
        ...defaultFilter,
        ...options?.filter || []
      ],
      attributesToHighlight: options.attributesToHighlight || [],
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>"
    }

    let searchResults: SearchResponse<PatientAccountIndex>;
    try {
      searchResults = await index.search<PatientAccountIndex>(query, searchOptions);
    } catch (err) {
      index = this.meiliSearchClient.index(`patient_${(practiceId).slice(-2)}`);
      searchResults = await index.search<PatientAccountIndex>(query, searchOptions);
    }


    searchResults.hits.sort((a, b) => this.customSort(a, b, query));

    return searchResults;
  }

  async searchInvoices(practiceId: string, query: string, options?: SearchOptions) {
    const index = await this.meiliSearchInvoice.index(`invoice_${(practiceId).slice(-2)}`);
    const defaultFilter = [
      `PracticeId = ${practiceId}`,
      `Status != DRAFT`, // Don't show draft invoices
    ];

    const limit = options?.limit || 1000;
    const hitsPerPage = options?.hitsPerPage || limit;
    const page = options?.page || 1;
    const sort = options?.sort || [];

    const searchOptions = {
      hitsPerPage,
      page,
      limit,
      sort,
      filter: [
        ...defaultFilter,
        ...options?.filter || []
      ],
      attributesToHighlight: options.attributesToHighlight || [],
      attributesToSearchOn: options.attributesToSearchOn || [],
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>"
    }

    const searchResults = await index.search<Invoice>(query, searchOptions);

    searchResults.hits.sort((a, b) => this.customSort(a, b, query));

    return searchResults;
  }

  customSort(a, b, query) {
    const aHasExactMatch = this.hasExactMatch(a, query);
    const bHasExactMatch = this.hasExactMatch(b, query);

    if (aHasExactMatch && !bHasExactMatch) {
      // Prioritize documents with exact matches
      return -1;
    }
    if (!aHasExactMatch && bHasExactMatch) {
      return 1;
    }

    // If both have or don't have an exact match, compare by relevance score
    return a._score - b._score;
  }

  hasExactMatch(document: PatientAccountIndex, query: string): boolean {
    return document.Account?.AccountNo === query;
  }

  async searchCalendarEvents(practiceId: string, query: string, filter: string[] = []) {
    const index = this.meiliSearchCalendar.index(`calendar_event_${practiceId.slice(-2)}`);
    return await index.search<CalendarEventIndex>(query, {
      filter,
      limit: 10
    });
  }

  async getConsultCodes(requestData: any) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const consultCodeCheck = httpsCallable(functions, 'med-sea-v1-oncall-consultCodeCheck');   
    const result: any = await consultCodeCheck(requestData);
    const procedureCodes = result.data.data.payload;
    const consultCodes = procedureCodes.filter((item: { IsConsult: boolean; }) => item.IsConsult)?.map((line: any) => line.TariffCode);   
    return consultCodes;
  }
}
