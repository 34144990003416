import _ from "lodash";
import * as moment from "moment";
import {FormatUtils} from "../../utils/format-utils";
import {DateUtils} from "../../utils/date-utils";
import {
  ACCOUNT_ENTITY_TYPE,
  ACCOUNT_TYPE,
  AccountMember,
  BaseAccount,
  BaseInvoice, Branch, CellType, INVOICE_STATUS, INVOICE_SUBTYPE, INVOICE_TYPE, InvoiceLine,
  InvoiceMainMember, InvoiceReportModel, InvoiceTable, NameValue,
  Practice, PracticeSettings, ReportSnapscan,
  Scheme,
  SnapScan
} from "@meraki-flux/schema";
import {SchemeUtils} from "../../utils/scheme-utils";
import { getBase64Snapscan } from '@meraki-flux/purejs';

export class InvoiceReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(practice: Practice, account: BaseAccount, mainMember: InvoiceMainMember, invoice: BaseInvoice, scheme: Scheme, patient: AccountMember, snapscan: SnapScan): Promise<InvoiceReportModel> {
       const branch = this.matchBranch(practice.Branches, account.HomeBranch);
       // in case the branch name changes, update it in the invoice
       invoice.Branch = branch.Name;
       const invoiceResult = await this.buildStatementTables(account, invoice, patient, practice.Settings);
        return {
            Logo: practice.Settings.ReportLogoSetting,
            PracticeId: practice.BillingPracticeNumber,
            PracticeName: practice.PracticeName,
            Header: await this.buildMainHeader(invoice, account, mainMember),
            PracticeHeader: await this.buildPracticeHeader(practice, branch),
            AccountHeader: await this.buildAccountHeader(invoice, account, mainMember, scheme),
            InvoiceTables: invoiceResult.invoiceTables,
            InfoTable: await this.buildFooterInfoTable(invoice, account, practice, branch),
            FooterText:  `Please note that you are personally liable for any difference between the\n${"\t\u200B".repeat(3)}claimed amount and the amount paid by your medical aid.`,
            InvoiceMessage: practice.Settings.InvoiceFooterText,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ReportDate: moment(DateUtils.toDate(invoice.InvoiceDate)).format(this.DATE_FORMAT),
            Snapscan: await this.getQRImages(snapscan),
        };
    }

    matchBranch(practiceBranches: Branch[], accountHomeBranch: string) {
        let matchingBranch;

        if (accountHomeBranch)
            matchingBranch = practiceBranches.find((branch) =>
               branch.Name === accountHomeBranch && branch.Active
            )

        if (!matchingBranch)
            matchingBranch = practiceBranches.find((branch) =>
               branch.IsMainBranch && branch.Active
            )
        return matchingBranch;
    }

    async buildMainHeader(invoice: BaseInvoice, account: BaseAccount, patient : InvoiceMainMember) {
        return [
            invoice?.AmountVAT > 0 ? 'Tax invoice' : 'Invoice',
            `${invoice.InvoiceNo} - ${(patient.Title && patient.Title !== "Unknown")? patient.Title : ""} ${patient.Name} ${patient.Surname} (${account.AccountNo})`
        ].join(' ');
    }

    async buildFooterInfoTable(invoice: BaseInvoice, account: BaseAccount, practice: Practice, branch: Branch) {
        return {
            vatValue: invoice.AmountVAT,
            bankDetails : {
                practiceName: FormatUtils.join([
                    branch?.BankDetails?.ChequeAccountDetails?.AccountHolderName,
                    branch?.BankDetails?.ChequeAccountDetails?.Bank
                ], ', '),
                branchName: branch?.BankDetails?.ChequeAccountDetails?.BranchCode,
                accountNo: branch?.BankDetails?.ChequeAccountDetails?.AccountNo,
                accountType: branch?.BankDetails?.ChequeAccountDetails?.AccountType
            },
            bankDetailsEft: branch?.BankDetails?.SameAsEFT ? undefined : {
                practiceName: FormatUtils.join([
                    branch?.BankDetails?.EFTAccountDetails?.AccountHolderName,
                    branch?.BankDetails?.EFTAccountDetails?.Bank
                ], ', '),
                branchName: branch?.BankDetails?.EFTAccountDetails?.BranchCode,
                accountNo: branch?.BankDetails?.EFTAccountDetails?.AccountNo,
                accountType: branch?.BankDetails?.EFTAccountDetails?.AccountType
            },
            eftInfo : {
                paymentRef: account.AccountNo,
                email: branch?.ContactDetails?.EmailAddress
            },
            companyInfo : {
                registrationNo: practice.CompanyRegistrationNo,
                vatNo: practice.VatNo
            }
        }
    }

    async buildStatementTables(account: BaseAccount, invoice: BaseInvoice, patient: AccountMember, practiceSettings: PracticeSettings)  {
        const invoiceTables : InvoiceTable[] = [];
        const table = await this.buildStatementTable(account, invoice, patient, practiceSettings);
            invoiceTables.push(table);
        return {
            invoiceTables: invoiceTables
        };
    }

    async buildStatementTable(account: BaseAccount, invoice: BaseInvoice, patient: AccountMember, practiceSettings: PracticeSettings) {
        const headers = []
        headers.push(await this.buildPatientTableHeader(invoice, patient));
        headers.push(await this.buildInvoiceTableHeader(account, invoice, practiceSettings));
        const rowHeaders = await this.buildRowHeaders();
        if(invoice.Subtype === INVOICE_SUBTYPE.DEBIT_NOTE) rowHeaders[1] = {value: `Code`};
        const rows = [];
        for (const invoiceLine of _.sortBy(invoice.Lines, 'LineNumber')) {
            rows.push(await this.buildTableRow(invoice, invoiceLine))
        }
        rows.push(await this.buildTotalRow(invoice))
        const table = {
            TableHeaders: headers,
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        } as InvoiceTable;
        if (invoice?.HeaderDiagnosisCodes?.length > 0) {
            table.ICDLine = await this.buildInvoiceICDLine(invoice);
        }
        return table;
    }

    async buildTableRow(invoice: BaseInvoice, invoiceLine: InvoiceLine) {
        const row = [];
        row.push({
            value: moment(invoice.DateOfService).format(this.DATE_FORMAT)
        })
        row.push({
            value: invoiceLine.TariffCode
        })
        row.push({
            value: FormatUtils.join(this.formatDescriptionLines(invoiceLine), '\n')
        })
        row.push({
            value: invoiceLine.Quantity,
            type: CellType.NUMBER
        })
        row.push({
            value: invoiceLine.AmountBilled||0,
            type: CellType.CURRENCY
        })
        return row;
    }

    formatDescriptionLines(invoiceLine: InvoiceLine) {
        const desc = _.truncate(invoiceLine.Description, {'length' : 170});
        const icd10 = invoiceLine.DiagnosisCodes ? `ICD-10: ${invoiceLine.DiagnosisCodes}` : null;
        return [desc, icd10];
        // switch (invoiceLine.LineType) {
        //     case INVOICE_LINE_TYPE.MEDICINE:
                // return [
                //     packSize ? `${desc} (Pack size: ${packSize})` : desc,
                //     `Nappi: ${nappi}\tType: ${medicineType}`,
                //     icd10
                // ];
            // case INVOICE_LINE_TYPE.CSTM_MEDICINE:
            //     return [
            //         packSize ? `${desc} (Pack size: ${packSize})` : desc,
            //         `Nappi: ${nappi}\tType: ${medicineType}`,
            //         icd10
            //     ];
            // case INVOICE_LINE_TYPE.CONSUMABLE:
            //     return [
            //         packSize ? `${desc} (Pack size: ${packSize})` : desc,
            //         `Nappi: ${nappi}`,
            //         icd10
            //     ];
            // case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
            //     return [
            //         packSize ? `${desc} (Pack size: ${packSize})` : desc,
            //         `Code: ${nappi}`,
            //         icd10
            //     ];
        //     default:
        //         return [desc, icd10];
        // }
    }

    async buildTotalRow(invoice: BaseInvoice) {
        const row = [];
        row.push({
            value: "TOTAL",
            decoration: {
                bold: true
            }
        })
        row.push({
            value: "",
        })
        row.push({
            value: ""
        })
        row.push({
            value: ""
        })
        row.push({
            value: invoice.AmountBilled||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        return row;
    }

    resize(arr, size) {
        const add = arr.length > size;
        while (arr.length > size) { arr.pop(); }
        if (add) {
            arr[size-1] = arr[size - 1].replace(/.$/,'...')
        }
        return arr;
    }

    async buildRowHeaders() {
        return [
            { value: "Date of\nservice" },
            { value: `Tariff${"\t\u200B"}\ncode` },
            { value: "Description"},
            { value: "Qty." },
            { value: "Amount", width: 60,  type: CellType.NUMBER}
        ];
    }

    async buildPatientTableHeader(invoice: BaseInvoice, patient: AccountMember) {
        const rows = [];
        rows.push(await this.buildPatientTableHeaderLine(invoice, patient));
        return {
            outlined: true,
            rows: rows
        }
    }

    async buildInvoiceTableHeader(account: BaseAccount, invoice: BaseInvoice, practiceSettings: PracticeSettings) {
        const rows = [];
        rows.push(await this.buildInvoiceTableHeader1Line(invoice, practiceSettings));
        rows.push(await this.buildInvoiceTableHeader2Line(invoice, account));
        rows.push(await this.buildInvoiceTableHeader3Line(invoice));
        rows.push(await this.buildInvoiceTableHeader4Line(invoice));
        return {
            rows: rows,
            outlined: true,
            outlineColor: '#ececec'
        }
    }

    async buildInvoiceICDLine(invoice: BaseInvoice) {
        return {
            icdCode: `ICD-10 diagnosis: ${invoice.HeaderDiagnosisCodes}`
        }
    }

    async buildPatientTableHeaderLine(invoice: BaseInvoice, patient: AccountMember) {
        const rows = [];
        const keyStrings = [];
        if (INVOICE_SUBTYPE.DEBIT_NOTE === invoice.Subtype) keyStrings.push('DEBIT NOTE');
        keyStrings.push('Patient');
        await this.addKeyValue(rows, keyStrings.join(' '), `${(patient?.Title && patient?.Title !== "Unknown")? patient?.Title : ""} ${patient?.Name} ${patient?.Surname}`, true);
        if (patient?.DateOfBirth) {
            await this.addKeyValue(rows, "D.o.b", moment(patient?.DateOfBirth).format(this.DATE_FORMAT), true);
        }
        await this.addKeyValue(rows, "Dep. code", patient?.DependantCode, true);
        return rows;
    }

    async buildInvoiceTableHeader1Line(invoice: BaseInvoice, practiceSettings: PracticeSettings) {
        const rows = [];
        if (invoice.DateOfService) {
            await this.addKeyValue(rows, "Date of service", moment(invoice.DateOfService).format(this.DATE_FORMAT), true);
        }
        if (invoice.TreatingProvider?.FullName) {
            await this.addKeyValue(rows, "Treating provider", `${invoice.TreatingProvider?.FullName.split(',')[0]}`, true);
        }
        await this.addKeyValue(rows, "HPCSA", invoice.TreatingProvider?.HPCSANumber);
        if (practiceSettings?.ShowTPN) {
            await this.addKeyValue(rows, "Treating prac. no.", invoice.TreatingProvider?.TreatingPracticeNumber);
        }
        return rows;
    }

    async buildInvoiceTableHeader2Line(invoice: BaseInvoice, account: BaseAccount) {
        const rows = [];
        await this.addKeyValue(rows, "Dispensing no.", invoice.TreatingProvider?.DispensingLicNum);
        if (invoice.Branch && account.HomeBranch !== invoice.Branch) {
            await this.addKeyValue(rows, "Branch", invoice.Branch);
        }
        return rows;
    }

    async buildInvoiceTableHeader3Line(invoice: BaseInvoice) {
        const rows = [];
        await this.addKeyValue(rows, "Auth. no.", invoice.AuthorizationNo);
        await this.addKeyValue(rows, "Referral no.", invoice.ReferralNo);
        await this.addKeyValue(rows, "Lab ref. no.", invoice.LabReferenceNo);
        return rows;
    }

    async buildInvoiceTableHeader4Line(invoice: BaseInvoice) {
        const rows = [];
        if (invoice.ReferringProvider?.FullName) {
            await this.addKeyValue(rows, "Referring provider", `${invoice.ReferringProvider?.FullName.split(',')[0]} (${invoice.ReferringProvider?.TreatingPracticeNumber})`, true);
        }
        if (invoice.AssistingProvider?.FullName) {
            await this.addKeyValue(rows, "Assisting provider", `${invoice.AssistingProvider?.FullName.split(',')[0]} (${invoice.AssistingProvider?.TreatingPracticeNumber})`, true);
        }
        return rows;
    }

    async buildPracticeHeader(practice: Practice, branch?: Branch) {
        const right: NameValue[] = await this.buildRightPracticeHeaders(branch);
        const left: NameValue[] = await this.buildLeftPracticeHeaders(branch);
        return {
            left: left,
            right: right
        }
    }

    async buildRightPracticeHeaders(branch?: Branch) {
        const right: NameValue[] = [];
        await this.addKeyValue(right, "Office no", branch?.ContactDetails?.OfficeNo);
        await this.addKeyValue(right, "Email", branch?.ContactDetails?.EmailAddress);
        await this.addKeyValue(right, "Fax", branch?.ContactDetails?.FaxNo);
        await  this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address1);
        await this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address2);
        await this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address3);
        await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone1);
        await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone2);
        await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone3);
        return right;
    }

    async buildLeftPracticeHeaders(branch?: Branch) {
        const left: NameValue[] = [];
        await this.addKeyValue(left, null, branch?.Name);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line1);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line2);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line3);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Code);
        return left;
    }

    async buildAccountHeader(invoice: BaseInvoice, account: BaseAccount, patient: InvoiceMainMember, scheme: Scheme) {
        const right: NameValue[] = await this.buildRightAccountHeaders(invoice, patient, account, scheme);
        const left: NameValue[] = await this.buildLeftAccountHeaders(invoice, account);
        return {
            name: [invoice?.AmountVAT > 0 ? 'Tax' : '', `Invoice ${invoice.InvoiceNo}`].join(' '),
            caption: invoice.Status === INVOICE_STATUS.CANCELLED ? INVOICE_STATUS.CANCELLED.toUpperCase() : "",
            left: left,
            right: right
        }
    }

    async buildRightAccountHeaders(invoice: BaseInvoice, patient: InvoiceMainMember, account: BaseAccount, scheme: Scheme) {
        const right: NameValue[] = [];
        await this.addKeyValue(right, "Account no.", account?.AccountNo??"");
        await this.addKeyValue(right, "File no.", patient.FileNo === "" ? null : patient.FileNo);
        right.push({name:"", value: {value:""}});
        if (invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER) {
          await this.addKeyValue(right, "Medical insurer", invoice.MedicalInsurer?.Name||"", true);
          await this.addKeyValue(right, "Policy no.", invoice.PolicyNo||"", true);
        } else {
          let medAid = "Private";
          if (account.AccountType !== ACCOUNT_TYPE.PRIVATE) {
            medAid = SchemeUtils.formatSpo([scheme], account.Scheme, account.Plan, account.Option);
          }
          await this.addKeyValue(right, "Medical aid", medAid, true);
          if (invoice.Type === INVOICE_TYPE.CASH) {
            if (account.AccountType !== ACCOUNT_TYPE.PRIVATE) {
              await this.addKeyValue(right, "Member no.", account?.MemberNo??"", true);
            }
          } else {
            if (account.AccountType !== ACCOUNT_TYPE.PRIVATE) {
              await this.addKeyValue(right, "Member no.", invoice.Account?.MemberNo??"", true);
            }
          }
        }
        return right;
    }

    async buildLeftAccountHeaders(invoice: BaseInvoice, account: BaseAccount) {
        const left: NameValue[] = [];
        if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
            await this.addKeyValue(left, null, account.CompanyName, true);
        } else {
            await this.addKeyValue(left, null, `${(invoice.Patient?.Title && invoice.Patient?.Title !== "Unknown") ? invoice.Patient?.Title : ""} ${invoice.Patient?.Name??""} ${invoice.Patient?.Surname??""}`, true);
        }
        await this.addKeyValue(left, null, invoice.Patient?.Contact?.PostalAddress?.Line1);
        await this.addKeyValue(left, null, invoice.Patient?.Contact?.PostalAddress?.Line2);
        await this.addKeyValue(left, null, invoice.Patient?.Contact?.PostalAddress?.Line3);
        await this.addKeyValue(left, null, invoice.Patient?.Contact?.PostalAddress?.Code);
        if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
            left.push({name:"", value: {value:""}});
            await this.addKeyValue(left, "Company reg. no.", account.CompanyRegistrationNo, true);
            await this.addKeyValue(left, "VAT no.", account.VATNo, true);
        }
        return left;
    }


    async addKeyValue(col: NameValue[], key: string, value?: string, bold: boolean = false, italic: boolean = false) {
        if (value && value !== "") {
            col.push({name: key, value: {value: value, bold: bold, italic: italic}});
        }
        return col;
    }

    async addToArray(col: string[], value? :string) {
        if (value && value !== "") {
            col.push(value)
        }
    }

    async getQRImages(snapScan: SnapScan): Promise<ReportSnapscan> {
        if(snapScan && snapScan.Activated && snapScan.QRCode && snapScan.SnapScanURL) {
            return {
                qr: `${snapScan.SnapScanURL}${snapScan.QRCode}`,
                snapScanPng: getBase64Snapscan(),
            }
        }
        return null;
    }
}
