import { Timestamp } from "@google-cloud/logging/build/src/entry";
import { firestore } from "firebase-admin";
import { PatientVo } from '@meraki-flux/schema';


export interface PathologyReportVo {
    PathologyReportWorkflowId: string | null;
    PathologyReportId: string | null;
    Source: 'cloud' | null;
    /** 0 = Open, 1 = Read, 2 = Urgent, 3 = Hidden */
    // WorkflowStatusType?: PathologyReportWorkflowVoWorkflowStatusType | null;
    StatusType: 'Read' | 'Unread' | 'Urgent' | null;
    DeliveryStatus: 'Delivered' | 'Undelivered' | 'Ignored' | null;
    PatientInfoFromReport: PatientVo | null;
    FollowupActions: { Type: string; Date: Date }[] | null;
    PathologyReport: PathologyReport | null;
    PatientName: string | null;
    // PatientContactNo: string | null;
    Departments: string | null;
    ReceivedDate: Timestamp | firestore.FieldValue | null;
    DeliveryDate: Timestamp | firestore.FieldValue | null;
    // PatientDateOfBirth: Timestamp | null;
    ReportLabName: string | null;
    PatientIds: string[] | null;
    PrimaryProviderId: string | null;
    PracticeId: string | null;
    DoctorName: string | null;
    ReportXRef: string | null;
    EmbeddedPdf: boolean | null;
    XmlReference: string | null;
    ContentTypes?: ('pdf' | 'xml')[] | null;
}

export interface PathologyReport {
    Sender?: PathologyReportSender | null;
    Recipient?: PathologyReportRecipient | null;
    Patient?: PathologyReportPatient | null;
    Layout?: PathologyReportHeader[] | null;
    version?: string | null;
    batchNo?: string | null;
    batchDateTime?: Date | null;
    messageID?: string | null;
    PathologyRenderedReport?: PathologyRenderedReport | null;
    EmbeddedPdf?: boolean | null;
}

export interface PathologyResult extends PathologyReport {
    PathologyReportId: string;
    PathologyReport: PathologyReport;
}

export interface PathologyReportSender {
    Address?: PathologyReportAddressType | null;
    ReportHeader?: PathologyReportCommentLocalization[] | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportRecipient {
    patientReference?: string | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    Address?: PathologyReportAddressType | null;
    language?: string | null;
    type?: string | null;
    email?: string | null;
    Email?: { address: string }[] | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportPatient {
    Account?: PathologyReportPatientAccount | null;
    Requisition?: PathologyReportPatientRequisition[] | null;
    RegisteringDoctor?: PathologyReportDoctorType | null;
    Location?: PathologyCodedElementType | null;
    database?: string | null;
    urn?: string | null;
    mri?: string | null;
    surname?: string | null;
    firstName?: string | null;
    title?: string | null;
    idNumber?: string | null;
    age?: string | null;
    /** 0 = F, 1 = M, 2 = U */
    sex?: 'F' | 'M' | 'U' | null;
    dateOfBirth?: Date | null;
    serviceDate?: Date | null;
}

export interface PathologyReportHeader {
    Text?: PathologyReportCommentLocalization[] | null;
    Test?: PathologyReportLayoutTest[] | null;
    Header?: PathologyReportHeader[] | null;
}

export interface PathologyReportAddressType {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postalCode?: string | null;
}

export interface PathologyReportCommentLocalization {
    Text?: string | null;
    language?: string | null;
    default?: string | null;
}

export interface PathologyReportAdditionalCodeType {
    type?: string | null;
    version?: string | null;
    value?: string | null;
}

export interface PathologyReportCodedElementTypeLocalization {
    language?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportTelephoneType {
    /** 0 = home, 1 = work, 2 = mobile, 3 = fax */
    type?: 'home' | 'work' | 'mobile' | 'fax' | null;
    number?: string | null;
}

export interface PathologyReportPatientAccount {
    Guarantor?: PathologyReportAccountGuarantor | null;
    urn?: string | null;
    number?: string | null;
}

export interface PathologyReportPatientRequisition {
    Orders?: PathologyCodedElementType[] | null;
    SubmittingDoctor?: PathologyReportDoctorType | null;
    OtherDoctor?: PathologyReportRequisitionOtherDoctor[] | null;
    Specimen?: PathologyReportSpecimen[] | null;
    database?: string | null;
    urn?: string | null;
    number?: string | null;
    priority?: string | null;
    orderNo?: string | null;
}

export interface PathologyReportDoctorType {
    Telephone?: PathologyReportTelephoneType[] | null;
    Address?: PathologyReportAddressType | null;
    Email?: { address: string }[] | null;
    language?: string | null;
    type?: string | null;
    email?: string | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyCodedElementType {
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportLayoutTest {
    code?: string | null;
}

export interface PathologyReportAccountGuarantor {
    Telephone?: PathologyReportTelephoneType[] | null;
    Insurance?: PathologyReportAccountGuarantorInsurance | null;
    gid?: string | null;
    surname?: string | null;
    firstName?: string | null;
    title?: string | null;
}

export interface PathologyReportRequisitionOtherDoctor {
    id?: string | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    Address?: PathologyReportAddressType | null;
    language?: string | null;
    type?: string | null;
    email?: string | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportSpecimen {
    EnteredBy?: PathologyReportEnteredBy | null;
    CollectedBy?: PathologyReportCollectedBy | null;
    ReceivedBy?: PathologyReportSpecimenReceivedBy | null;
    Orders?: PathologyCodedElementType[] | null;
    Comment?: PathologyReportCommentLocalization[] | null;
    Department?: PathologyCodedElementType | null;
    Source?: PathologyCodedElementType | null;
    Description?: PathologyCodedElementType | null;
    OrderSite?: PathologyReportSite | null;
    ReceivedSite?: PathologyReportSite | null;
    Procedure?: PathologyReportSpecimenProcedure[] | null;
    SignedOutBy?: PathologyReportSignedOutBy[] | null;
    SignOutComment?: PathologyReportCommentLocalization[] | null;
    Layout?: PathologyReportLayout | null;
    urn?: string | null;
    number?: string | null;
    /** 0 = LAB, 1 = MIC, 2 = PTH */
    type?: PathologyReportSpecimenType | null;
    reportDate?: Date | null;
    reportTime?: Date | null;
    reportType?: string | null;
    referenceAge?: string | null;
    status?: string | null;
}

export interface PathologyReportAccountGuarantorInsurance {
    code?: string | null;
    name?: string | null;
    plan?: string | null;
    policyNo?: string | null;
}

export interface PathologyReportEnteredBy {
    enteredDate?: Date | null;
    enteredTime?: Date | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportCollectedBy {
    collectionDate?: Date | null;
    collectionTime?: Date | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportSpecimenReceivedBy {
    receivedDate?: Date | null;
    receivedTime?: Date | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportSite {
    Address?: PathologyReportAddressType | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportSpecimenProcedure {
    Test?: PathologyReportTest[] | null;
    Comment?: PathologyReportCommentLocalization[] | null;
    confidential?: boolean | null;
    reportable?: boolean | null;
    status?: string | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportSignedOutBy {
    signedOutDate?: Date | null;
    signedOutTime?: Date | null;
    signOutType?: string | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportLayout {
    Test?: PathologyReportLayoutTest[] | null;
    Header?: PathologyReportHeader[] | null;
}

export interface PathologyReportTest {
    PerformSite?: PathologyReportSite | null;
    Group?: PathologyCodedElementType | null;
    Result?: PathologyReportResult[] | null;
    confidential?: boolean | null;
    reportable?: boolean | null;
    status?: string | null;
    Code?: PathologyReportAdditionalCodeType[] | null;
    Localization?: PathologyReportCodedElementTypeLocalization[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
}

export interface PathologyReportResult {
    Code?: PathologyReportCodedResult[] | null;
    Localization?: PathologyReportResultLocalization[] | null;
    Method?: PathologyReportMethod | null;
    DictatedBy?: PathologyReportDictatedBy[] | null;
    EnteredBy?: PathologyReportEnteredBy[] | null;
    VerifiedBy?: PathologyReportVerifiedBy[] | null;
    Comment?: PathologyReportCommentLocalization[] | null;
    Attachment?: PathologyReportResultAttachment[] | null;
    Diagnosis?: PathologyReportResultDiagnosis[] | null;
    Procedure?: PathologyReportSpecimenProcedure[] | null;
    code?: string | null;
    name?: string | null;
    abbreviatedName?: string | null;
    value?: string | null;
    type?: string | null;
    units?: string | null;
    range?: string | null;
    flag?: string | null;
    revisionNo?: string | null;
}

export interface PathologyReportCodedResult {
    Code?: PathologyReportCodedResult[] | null;
    type?: string | null;
    version?: string | null;
    value?: string | null;
    name?: string | null;
}

export interface PathologyReportResultLocalization {
    language?: string | null;
    value?: string | null;
    units?: string | null;
}

export interface PathologyReportMethod {
    code?: string | null;
    name?: string | null;
    analyser?: string | null;
}

export interface PathologyReportDictatedBy {
    dictatedDate?: Date | null;
    dictatedTime?: Date | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportVerifiedBy {
    dictatedDate?: Date | null;
    dictatedTime?: Date | null;
    Telephone?: PathologyReportTelephoneType[] | null;
    code?: string | null;
    name?: string | null;
}

export interface PathologyReportResultAttachment {
    mimeType?: string | null;
    encoding?: string | null;
    filename?: string | null;
}

export interface PathologyReportResultDiagnosis {
    sequence?: string | null;
    type?: string | null;
    code?: string | null;
    name?: string | null;
}


export enum PathologyReportPatientSex {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export enum PathologyReportTelephoneTypeType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
    _3 = 3,
}

export enum PathologyReportSpecimenType {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export interface PathologyRenderedReport {
    RenderedFormat: string;
    RenderedFileName: string;
    RenderedHash: string;
    RenderedReport: string
}
