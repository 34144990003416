import { Injectable } from "@angular/core";
import { collection, collectionSnapshots, Firestore } from "@angular/fire/firestore";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import {Branch, PracticeProvider, ProviderType} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
  }

  currentPracticeProviders$ = this.authService.practice$.pipe(
    switchMap(practice => collectionSnapshots<PracticeProvider>(collection(this.firestore, `Practice/${practice.Id}/Provider`))),
    map(providerCollRef => providerCollRef.map(providerRef => ({ Id: providerRef.id, ...providerRef.data() })))
  );

  currentPracticeActiveProviders$ = this.currentPracticeProviders$.pipe(
    map(providers => providers.filter(p => p.IsActive && p.Type !== ProviderType.EXTERNAL))
  );

  getBranch(practiceBranches: Branch[], accountHomeBranch: string) {
    let matchingBranch;

    if (accountHomeBranch)
        matchingBranch = practiceBranches.find((branch) =>
           branch.Name === accountHomeBranch && branch.Active
        )

    if (!matchingBranch)
        matchingBranch = practiceBranches.find((branch) =>
           branch.IsMainBranch && branch.Active
        )
    return matchingBranch;
  }
}
