import { Injectable } from '@angular/core';
import { QueryConstraint } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CreateEventAction } from './action/create-event.action';
import { DeleteEventAction } from './action/delete-event.action';
import { UpdateEventAction } from './action/update-event.action';
import { CalendarEventQueryService } from './query/calendar-event-query.service';
import { RecurrentEventService } from './recurrent-event.service';
import {CALENDAR_EVENT_STATUS, CalendarEvent, CLINICAL_CALENDAR_EVENT_STATUS, Repeat} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class CalendarEventService {
  constructor(
    private auth: AuthService,
    private recurrentEventService: RecurrentEventService,
    private createEventAction: CreateEventAction,
    private updateEventAction: UpdateEventAction,
    private deleteEventAction: DeleteEventAction,
    private calendarEventQuery: CalendarEventQueryService
  ) { }

  search(practiceId: string, wheres: QueryConstraint[], providerIds?: string[]) {
    return this.calendarEventQuery.find(wheres, practiceId, providerIds);
  }

  search$(practiceId: string, wheres: QueryConstraint[]) {
    return this.calendarEventQuery.find$(wheres, practiceId);
  }

  async view(
    practiceId: string,
    wheres: QueryConstraint[],
    recurrentRootEventWheres: QueryConstraint[],
    viewDateFromGOE: Date,
    viewDateToLT: Date,
    providerIds?: string[]
  ) {
    return this.viewSelected(
      await this.search(practiceId, wheres, providerIds),
      await this.search(practiceId, recurrentRootEventWheres, providerIds),
      viewDateFromGOE,
      viewDateToLT
    );
  }

  view$(
    practiceId: string,
    wheres: QueryConstraint[],
    recurrentRootEventWheres: QueryConstraint[],
    viewDateFromGOE: Date,
    viewDateToLT: Date
  ) {
    return combineLatest([
      this.search$(practiceId, wheres),
      this.search$(practiceId, recurrentRootEventWheres),
    ]).pipe(
      map(([normal, recurrent]) =>
        this.viewSelected(normal, recurrent, viewDateFromGOE, viewDateToLT)
      )
    );
  }

  private viewSelected(
    normal: CalendarEvent[],
    recurrent: CalendarEvent[],
    viewDateFromGOE: Date,
    viewDateToLT: Date
  ) {
    const recurrentEvents = recurrent
      ?.map((e) =>
        this.recurrentEventService.prepareRecurrentEvents(e, viewDateFromGOE, viewDateToLT)
      )
      .reduce((a, b) => a.concat(b), []);
    const normalEvents = normal.map((e) =>
      this.recurrentEventService.fixRecurrentInfo(e, recurrent)
    );
    const events = [...normalEvents, ...recurrentEvents];
    events.forEach((e) => (e.Metadata.OriginalEvent = e));
    return events;
  }

  async getEvent(eventId: string) {
    const event = await this.calendarEventQuery.get(eventId);
    if (event?.RecurrentInfo?.RootEventId && event?.RecurrentInfo?.RootEventId !== event.Id) {
      const rootEvent = await this.calendarEventQuery.get(event?.RecurrentInfo?.RootEventId);
      this.recurrentEventService.fixRecurrentInfo(event, [rootEvent]);
    }
    return event;
  }

  /**
   * Creates new calendar event. Event Id is meant to be empty
   *
   * @param event - new event to be creates
   * @param repeat - repeat rules if needed
   */
  async createEvent(event: CalendarEvent, repeat: Repeat = event?.RecurrentInfo?.Rule?.Repeat) {
    await this.createEventAction.create(event, repeat);
  }

  /**
   * Updates calendar event and checks if event is part of recurrent series and core/template data
   * has been changed. If so then asks to potentially update other events in series.
   *
   * @param event - full calendar event details
   * @param repeat - optional if recurrent repeat rules are not changed
   * @returns false if user cancelled operation in confirmation dialog
   */
  async updateEvent(event: CalendarEvent, repeat: Repeat = event?.RecurrentInfo?.Rule?.Repeat) {
    return await this.updateEventAction.update(event, repeat);
  }

  /**
   * Use this method if you are sure that only this calendar  event must be updated
   *
   * @param event - full calendar event details
   * @returns false if user cancelled operation in confirmation dialog
   */
  async updateThisEvent(event: CalendarEvent) {
    return await this.updateEventAction.updateThis(event);
  }

  /**
   * Deletes event asks if potentially other events in recurrent series should be updated
   *
   * @param event - event to be deleted
   * @returns false if user cancelled operation in confirmation dialog
   */
  async deleteEvent(event: CalendarEvent) {
    return await this.deleteEventAction.delete(event);
  }

  async changeStatus(event: CalendarEvent, newStatus: CALENDAR_EVENT_STATUS) {
    const dbId = event.Metadata?.Id;
    const data = dbId ? {} : event;
    data.Status = newStatus;
    // update clinical status (booked/checked-in/cancelled) on hbc if no encounter created there otherwise allow hbc to manage status on their own
    if (event.ClinicalStatus != CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS) {
      data.ClinicalStatus = newStatus;
    }
    data.UpdatedAt = new Date();
    data.UpdatedBy = this.auth.uid;
    if (dbId) {
      // updating by Id to avoid other doc data potential update with 'updateThis' method
      await this.updateEventAction.updateThisById(dbId, data);
    } else {
      // saves recurrent event to DB with valid status
      await this.updateEventAction.updateThis(data);
    }
  }
}
