import {BasePdfReportData, ClaimDetailData, ReportContent, ReportTable} from "@meraki-flux/schema";
import { BasePdfGenerator, PDFMakeHelper } from '@meraki-flux/purejs';
import generateReportHeader = PDFMakeHelper.generateReportHeader;
import generateSimpleBorderedTable = PDFMakeHelper.generateSimpleBorderedTable;
import generateParagrpaphs = PDFMakeHelper.generateParagrpaphs;
import generateItalicFilledHeader = PDFMakeHelper.generateItalicFilledHeader;
import generatePageBreak = PDFMakeHelper.generatePageBreak;
import generateClaimDetailsDataTable = PDFMakeHelper.generateClaimDetailsDataTable;

/*
  Helper module to generate some common exceljs structures.
*/
export class ProofOfSubmissionPdfGenerator extends BasePdfGenerator {

  private readonly claimDetailData: ClaimDetailData;
  private readonly letterTable: ReportTable;
  private readonly letterText: string[];

  public override generateReport(): ReportContent {
    this.generateReportName();
    this.genDate = "";
    this.generateReportHeader();
    this.generateLetterTable();
    this.generateLetterText();
    this.generatePageBreak()
    this.generateSecondPageHeader();
    this.generateItalicFilledLine('Details')
    this.generateDetailsSection();
    this.generateItalicFilledLine('Line Details')
    this.generateTables();
    this.generateBottomMessage();
    this.generateFooterTable();
    return this.buildReport();
  }

  protected generateReportHeader() {
    if (this.data.doctorName && this.data.bpn) {
      this.content.push(generateReportHeader({ left: [
        { name: "Practice", data: { value: this.data.doctorName, decoration:{bold: true} } },
        { name: "Billing practice no.", data: { value: this.data.bpn, decoration:{bold: true} } }
      ] }))
    }
  }

  private generateLetterTable() {
    if (this.letterTable) {
      this.content.push(generateSimpleBorderedTable(this.letterTable.rowHeaders, this.letterTable.rows));
    }
  }

  private generateLetterText() {
    if (this.letterText) {
      this.content.push(generateParagrpaphs(this.letterText));
    }
  }

  private generateItalicFilledLine(text: string) {
    this.content.push(generateItalicFilledHeader(text));
  }

  private generatePageBreak() {
    this.content.push(generatePageBreak());
  }

  private generateSecondPageHeader() {
    this.content.push(
      {
        text: [
          {text: "This "},
          {text: "Proof of Submission ", bold: true},
          {text: "has been generated by Healthbridge."}
        ],
        margin: [3, 3, 3, 10],
      }
    );
    this.content.push(
      {
        text: [
          {text: "For any queries, please contact our Client Service Centre on 0860 200 222, or email us at "},
          {text: "mymps@healthbridge.co.za", link: "mailto:mymps@healthbridge.co.za", style : {color: "blue"}},
          {text: "."}
        ],
        margin: [3, 3, 3, 10],
      }
    );
  }

  private generateDetailsSection() {
    if (this.claimDetailData && this.claimDetailData.details) {
      this.content.push(generateClaimDetailsDataTable(this.claimDetailData.details))
    }
  }

  constructor(data: BasePdfReportData) {
    super(data)
    this.claimDetailData = data.claimDetailData;
    this.letterTable = data.letterTable;
    this.letterText = data.letterText
  }
}

