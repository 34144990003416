import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatChipInputEvent} from '@angular/material/chips';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {tap} from 'rxjs/operators';
import {DialogService} from '../../services/dialog.service';
import {EmailService} from '../../services/email.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {AccountRepository} from '../../repositories/account.repository';
import {FileUtils} from "../../utils/file-utils";
import {StorageService} from "../../services/storage.service";
import { LogService } from '../../services/log.service';
import {
  DialogButtonStyle,
  DialogType,
  EDITOR_CONFIG,
  EMAIL_REGEX,
  EMAIL_STATUS, EMAIL_TYPE,
  EmailRecipient, EmailRequest
} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  recipients?: EmailRecipient[] = this.data.recipients ?? [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  ccs: string[] = this.data.ccs ?? [];
  replyTo: EmailRecipient = this.data.replyTo ?? null;
  removeFileFromChild: Subject<string> = new Subject<string>();
  editorConfig = EDITOR_CONFIG;
  infoMessage = '';
  dialogTitle = 'New Mail';

  form: UntypedFormGroup = this.fb.group({
    Recipients: [],
    Ccs: [null],
    Subject: [null, RxwebValidators.required()],
    Body: [null, RxwebValidators.required()],
    Attachments: [[]]
  });

  constructor(
    public auth: AuthService,
    private bottomSheetRef: MatBottomSheetRef<EmailComponent>,
    private dialogService: DialogService,
    private emailService: EmailService,
    private fb: UntypedFormBuilder,
    private accountRepository: AccountRepository,
    private storageService: StorageService,
    private logger: LogService,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
  ) {
  }

  ngOnInit() {
    if (this.data?.dialogTitle)
      this.dialogTitle = this.data.dialogTitle;
    if (this.data?.infoMessage)
      this.infoMessage = this.data.infoMessage;
    this.form.controls.Subject?.patchValue(this.data.subject);
    this.form.controls.Body?.patchValue(this.data.body);
    const uuidFiles = [];
    if (this.data.files)
      for (const file of this.data.files)
        uuidFiles.push(FileUtils.renameFile(file, FileUtils.decorateFileNameWithUUID(file.name)));

    this.form.controls.Attachments?.patchValue(uuidFiles);
  }

  /**
   * Closes email form with dialog prompt to confirm action.
   */
  discard() {
    this.dialogService.showDialog({
      type: DialogType.WARNING,
      message: 'Are you sure you want to discard this email?',
      buttons: [
        {id: 'NO', caption: 'No', style: DialogButtonStyle.SECONDARY},
        {id: 'YES', caption: 'Yes', style: DialogButtonStyle.PRIMARY}
      ]
    })
      .closed.pipe(
      tap(res => {
        if (res === 'YES') {
          this.bottomSheetRef.dismiss(null);
        }
      }),
    ).subscribe();
  }

  /**
   * On click 'Send' button handler
   */
  async onSend() {
    const warningMessage = this.validateForm();
    if (warningMessage) {
      this.dialogService.showDialog({
        type: DialogType.WARNING,
        message: warningMessage,
        buttons: [
          {id: 'OK', caption: 'OK', style: DialogButtonStyle.SECONDARY}
        ]
      });
      return;
    }
    const docRefId = await this.sendEmail();
    this.bottomSheetRef.dismiss(docRefId);
    this.dialogService.showSnackbar('Email Sent');
  }

  /**
   * Builds email requests and saves it to db with status NEW
   */
  async sendEmail(): Promise<string> {
    const emailStatus: EMAIL_STATUS = this.data?.keepAsDraftOnSend ? EMAIL_STATUS.DRAFT : EMAIL_STATUS.NEW;
    const request: EmailRequest = {
      To: this.recipients,
      Cc: this.ccs.map(rec => ({EmailAddress: rec})),
      ReplyTo: this.replyTo,
      Body: this.form.get('Body')?.value,
      Subject: this.form.get('Subject')?.value,
      Attachments: await this.uploadFiles(),
      Type: this.data?.type || EMAIL_TYPE.AD_HOC,
      Status: emailStatus,
      PracticeId: this.auth.selectedBPN,
      AccountId: this.accountRepository.getActiveAccountId() || this.data?.context?.AccountId || null,
      Actions: this.data?.actions || []
    }
    return await this.emailService.createEmail(request);
  }

  /**
   * Upload attachments to storage
   */
  async uploadFiles() {
    const storagePath = `Practice/${this.auth.selectedBPN}/OutboundEmail/`;
    const uploadedAttachments = [];
    if (this.form.controls.Attachments?.value) {
      for (const file of this.form.controls.Attachments.value) {

        const onStateChange = (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.form.controls.Attachments?.value.forEach((element: any) => {
            if (element.name === file.name) {
              element.uploadProgress = Math.trunc(progress);
              if (this.data?.files.find(x => x.name === element.name) && element.uploadProgress >= 100) {
                element.uploadProgress = 100
              }
            }
          });
        };
        const onError = (error) => {
          this.logger.error(error)
        }
        uploadedAttachments.push(await this.storageService.uploadFile(storagePath, file, onStateChange, onError));
      }
    }
    return uploadedAttachments;
  }

  /**
   * Creates warning messages based on recipients and cc email validity.
   */
  validateForm(): string {
    if (this.recipients.length === 0) {
      return 'Please specify at least one recipient email address.'
    } else if (!this.validateRecipients()) {
      return 'One or more specified email addresses are not correct.'
    }
    return null;
  }

  /**
   * Checks if all recipient and cc emails are valid.
   */
  validateRecipients(): boolean {
    for (const recipient of this.recipients) {
      if (!this.isEmailValid(recipient?.EmailAddress)) {
        return false;
      }
    }
    for (const cc of this.ccs) {
      if (!this.isEmailValid(cc)) {
        return false;
      }
    }
    return true;
  }

  isEmailValid(email: string): boolean {
    return EMAIL_REGEX.test(email)
  }

  onRecipientAdded(event: MatChipInputEvent): void {
    const recipient = (event.value || '').trim();
    if (recipient) {
      this.recipients.push({ EmailAddress : recipient } as EmailRecipient)
    }
    event.chipInput?.clear();
  }

  onRecipientRemoved(recipient: EmailRecipient): void {
    const index = this.recipients.indexOf(recipient);
    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  onCCAdded(event: MatChipInputEvent): void {
    const cc = (event.value || '').trim();
    if (cc) {
      this.ccs.push(cc.trim())
    }
    event.chipInput?.clear();
  }

  onCCRemoved(cc: any): void {
    const index = this.ccs.indexOf(cc);
    if (index >= 0) {
      this.ccs.splice(index, 1);
    }
  }

  async onFilesAdded(files: File[]) {
    this.form.get('Attachments')?.setValue((this.form.get('Attachments')?.value as File[]).concat(files));
  }

  onFileRemoved(file: any) {
    this.form.patchValue({Attachments: (this.form.get('Attachments')?.value as File[]).filter(formAtt => formAtt.name !== file.name)});
    this.removeFileFromChild.next(file.name);
  }

  setKeepAsDraftOnSend(value) {
    this.data.keepAsDraftOnSend = value;
  }

  /**
   * Returns the name of the file without the randomized string in front of it.
   * @param fileName The name of the file with the randomized string.
   */
  removeUUIDFromFileName(fileName: string) {
    return FileUtils.unDecorateFileNameWithUUID(fileName);
  }

  formatRecipient(recipient: EmailRecipient) {
    if (!recipient?.EmailAddress) return '';
    return recipient?.Name ? `${recipient.Name} (${recipient?.EmailAddress})` : recipient?.EmailAddress;
  }

}
