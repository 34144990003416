import * as _ from "lodash";
import * as moment from "moment";
import {
  BaseInvoice, INVOICE_STATUS,
  InvoiceDetailsItem,
  NoDataError, Provider, ProviderInvoices, ProviderSummaryItem,
  ReferralProviderModel,
  ReferralReportInfo,
  ReferralReportModel,
  ReferralReportRequest
} from "@meraki-flux/schema";

export class ReferralReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";
   // readonly EXCLUDED_CLAIM_STATUSES: string[] = [CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID, CLAIM_STATUS.REVERSAL_PROCESSED, CLAIM_STATUS.REVERSAL_ACCEPTED];

    async build(reportRequest: ReferralReportRequest): Promise<ReferralReportModel> {
        const reportHeader: ReferralReportInfo = await this.buildReportInfo(reportRequest);
        const providersModel: ReferralProviderModel[] = await this.buildProvidersModel(reportRequest);

        this.checkDataAvailability(providersModel);

        let allProvidersModel: ReferralProviderModel = undefined;
        //if (!reportRequest.ReferringProvider && providersModel.length > 1) {
            allProvidersModel = this.buildAllProvidersModel(providersModel);
        //}
        return {
          ReferralReportInfo: reportHeader,
            ProviderModels: providersModel,
            AllProvidersModel: allProvidersModel,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(providersModel: ReferralProviderModel[]) {

        let noData = true;
        providersModel.forEach(providerModel => {
            if (providerModel.DetailsTable.length > 0) {
                noData = false;
            }
        });
        if (noData)
            throw new NoDataError();
    }

    private buildAllProvidersModel(providerTabs: ReferralProviderModel[]): ReferralProviderModel {
        //const reportName = 'Referral Report';
        const summaryTable: ProviderSummaryItem[] = [];
        let detailsTable: InvoiceDetailsItem[] = [];
        for (const providerTab of providerTabs) {
            providerTab.SummaryTable.forEach((summaryItem) => {
                let item: ProviderSummaryItem = summaryTable.find((item) => item.ReferringProvider === summaryItem.ReferringProvider);
                if (item == null) {
                    item = {...summaryItem};
                    summaryTable.push(item);
                } else {
                    item.InvoicedAmount += summaryItem.InvoicedAmount;
                    item.TotalCount += summaryItem.TotalCount;
                }
            })
            detailsTable = detailsTable.concat(providerTab.DetailsTable);
        }
        return {
            SummaryTable: summaryTable,
            DetailsTable: detailsTable,
            //ProviderName: reportName
        };
    }

    private async buildProvidersModel(reportRequest: ReferralReportRequest): Promise<ReferralProviderModel[]> {
        const providerTab: ReferralProviderModel[] = [];
        const providerList = [];
        if (reportRequest.ReferringProvider) {
            providerList.push(reportRequest.ReferringProvider);
        } else if (reportRequest.ReferringProviders) {
            providerList.push(...reportRequest.ReferringProviders)
        }
        _.sortBy(providerList, 'Surname', 'Name');
        for (const provider of providerList) {
            providerTab.push(await this.buildProviderTabModel(provider));
        }
        return providerTab;
    }

    private async buildProviderTabModel(providerInvoice: ProviderInvoices): Promise<ReferralProviderModel> {

        const summaryTable: ProviderSummaryItem[] = [];
        const detailsTable: InvoiceDetailsItem[] = [];

        const invoices: BaseInvoice[] = providerInvoice.Invoices
        if (invoices) {
            invoices.forEach((invoice: BaseInvoice) => {
                const invoiceDetailsItem: InvoiceDetailsItem = this.buildInvoiceDetails(invoice);
                if (invoiceDetailsItem.InvoiceStatus != INVOICE_STATUS.CANCELLED) {
                    detailsTable.push(invoiceDetailsItem);

                this.updateSummaryTable(summaryTable, invoiceDetailsItem);
              }
            })
        }
        return {
            SummaryTable: summaryTable,
            DetailsTable: detailsTable,
        };
    }

    private updateSummaryTable(summaryTable: ProviderSummaryItem[], claimDetailsItem: InvoiceDetailsItem) {
        const referralProviderName: string = claimDetailsItem.ReferralProviderName;

        let summaryItem: ProviderSummaryItem | undefined = summaryTable.find(item => item.ReferringProvider === referralProviderName);
        if (!summaryItem) {
            summaryItem = {
                ReferringProvider: referralProviderName,
                TotalCount: 0,
                InvoicedAmount: 0,
            };
            summaryTable.push(summaryItem);
        }
        summaryItem.TotalCount += 1;
        summaryItem.InvoicedAmount += claimDetailsItem.InvoicedAmount;

    }

    private buildInvoiceDetails(invoice: any): InvoiceDetailsItem {
        const invoiceDetailsItem: InvoiceDetailsItem = {};
        invoiceDetailsItem.AccountNo = invoice.Account?.AccountNo;
        invoiceDetailsItem.PatientName = `${invoice.Patient?.Name??''} ${invoice.Patient?.Surname??''}`.trim();
        invoiceDetailsItem.PatientID = invoice.Patient?.IdentityNo;
        invoiceDetailsItem.InvoiceStatus = invoice.Status;
        invoiceDetailsItem.InvoiceNo = invoice.InvoiceNo;
        invoiceDetailsItem.DateOfService = invoice.DateOfService ? moment(invoice.DateOfService).format(this.DATE_FORMAT) : "";
        invoiceDetailsItem.InvoicedAmount = invoice.AmountBilled;
        invoiceDetailsItem.eRAEnabled = invoice.ClaimInfo?.eRAEnabled;
        invoiceDetailsItem.PlaceOfService = invoice.PlaceOfService;
        invoiceDetailsItem.CreatedBy = invoice.CreatedBy;
        invoiceDetailsItem.Branch = invoice.Branch;

        const treatingText = invoice.TreatingProviderName as string;
        if(treatingText != '')
        {
            invoiceDetailsItem.TreatingProviderName = treatingText;
        }

        const referringText = invoice.ReferringProviderName as string;
        if(referringText != '')
        {
            invoiceDetailsItem.ReferralProviderName = referringText;
            invoiceDetailsItem.Speciality = invoice.Speciality;
        }

        return invoiceDetailsItem;
    }

    private async buildReportInfo(reportRequest: ReferralReportRequest): Promise<ReferralReportInfo> {
        const reportInfo: ReferralReportInfo = {};

        let fullTreatingProviderName: string;
        if (reportRequest.TreatingProvider) {
            fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
        }

        let fullReferringProviderName: string;
        let referringSpeciality: string;
        if (reportRequest.ReferringProvider) {
          fullReferringProviderName = this.formatProviderName(reportRequest.ReferringProvider.Provider);
          referringSpeciality = reportRequest.ReferringProvider.Provider.Speciality;
        }

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        if (reportRequest.Practice?.IsMultiBranch === true)
            reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

        reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
        reportInfo.ReferringProvider = fullReferringProviderName ? fullReferringProviderName : 'All';
        reportInfo.Speciality = referringSpeciality;
        return reportInfo;
    }

    private formatProviderName(treatingProviderData: Provider | undefined): string {
        return treatingProviderData?.Title + ' ' + treatingProviderData?.Name + ' ' + treatingProviderData?.Surname;
    }
}
