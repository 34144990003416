import moment = require("moment");
import {
  NoDataError,
  PatientListDetailsItem,
  PatientListModel,
  PatientListReportInfo,
  PatientListReportRequest
} from "@meraki-flux/schema";

export class PatientListReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: PatientListReportRequest): Promise<PatientListModel> {
        const reportHeader: PatientListReportInfo = await this.buildReportInfo(reportRequest);
        const reportDetails: PatientListDetailsItem[] = this.buildPatientListDetails(reportRequest);

        this.checkDataAvailability(reportDetails);

        return {
            PatientListReportInfo: reportHeader,
            PatientListDetailsItem: reportDetails,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(reportDetails: PatientListDetailsItem[]) {
        let noData = true;
        if (reportDetails.length > 0) {
            noData = false;
        }

        if (noData)
            throw new NoDataError();
    }

    private async buildReportInfo(reportRequest: PatientListReportRequest): Promise<PatientListReportInfo> {
        const reportInfo: PatientListReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;

        if (reportRequest.Practice?.IsMultiBranch === true)
            reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

        reportInfo.IsMultibranch = reportRequest.Practice?.IsMultiBranch;
        reportInfo.LastVisitWithin = reportRequest.LastVisitWithin;
        reportInfo.FromAge = reportRequest.FromAge;
        reportInfo.ToAge = reportRequest.ToAge;
        reportInfo.AccountType = reportRequest.AccountType;
        reportInfo.AccountStatus = reportRequest.AccountStatus;
        reportInfo.Gender = reportRequest.Gender;
        reportInfo.Scheme = reportRequest.Scheme;
        reportInfo.Conditions = reportRequest.Conditions;
        reportInfo.Medications = reportRequest.Medications;
        reportInfo.Procedures = reportRequest.Procedures;


        return reportInfo;
    }

    private customSort(a: any, b: any): number {
      // First, compare by Surname (case-insensitive)
      const surnameA = a.Patient?.Surname?.toLowerCase() || '';
      const surnameB = b.Patient?.Surname?.toLowerCase() || '';

      if (surnameA < surnameB) {
        return -1;
      } else if (surnameA > surnameB) {
        return 1;
      } else {
        // If surnames are equal, compare by Name (case-insensitive)
        const nameA = a.Patient?.Name?.toLowerCase() || '';
        const nameB = b.Patient?.Name?.toLowerCase() || '';

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      }
    }


    private calculateAge(dateOfBirth: Date): number {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      // Adjust age if birthday hasn't occurred yet this year
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    }

    private buildPatientListDetails(reportRequest: PatientListReportRequest): PatientListDetailsItem[] {
        const detailtems: PatientListDetailsItem[] = [];
        const patientList = reportRequest.PatientList
        const invoiceList = reportRequest.InvoiceList;

        patientList.sort(this.customSort);

        for(const patientAccount of patientList){
            const patient = patientAccount.Patient;
            const account = patientAccount.Account;
            const invoice = invoiceList.find(inv=> inv.Patient.Id == patient.Id)
            const mainMember = invoiceList.find(inv=> inv.Account?.AccountNo == account.AccountNo && invoice?.MainMember)?.MainMember;

            const detailsObj: PatientListDetailsItem = {};
            detailsObj.Surname = patient.Surname;
            detailsObj.FirstName = patient.Name;
            detailsObj.PreferredName = patient.PreferredName;
            detailsObj.DateOfBirth = patient.DateOfBirth;
            detailsObj.Gender = patient.Gender;

            const patientAge = this.calculateAge(patient.DateOfBirth);

            if(patientAge < 18){
              detailsObj.CellphoneNo =  mainMember?.Contact?.Cellphone;
            }
            else{
              detailsObj.CellphoneNo =  patient.Contact?.Cellphone;
            }

            if(patientAge < 18){
              detailsObj.EmailAddress =  mainMember?.Contact?.Email;
            }
            else{
              detailsObj.EmailAddress =  patient.Contact?.Email
            }

            detailsObj.AccountType = account.AccountType;
            detailsObj.AccountNo = account.AccountNo;
            detailsObj.AccountHolder = patient?.Id === account?.MainMember? "Yes" : "No";
            detailsObj.Scheme = invoice?.Account?.SchemeName;
            detailsObj.Plan =  invoice?.Account?.PlanName;
            detailsObj.Option =  invoice?.Account?.OptionName;
            detailsObj.MemberNo = account.MemberNo;
            detailsObj.DependentCode = patient.DependantCode;
            detailsObj.LastVisitDate = moment.unix(patient.LastInvoiceDate).format('DD/MM/YYYY');
            detailsObj.Branch =  account.HomeBranch;

            detailtems.push(detailsObj)

        }

        return detailtems;
    }
}
