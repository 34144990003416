import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toHrsMins'
})
export class ToHrsMinsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const x = Number(value);
      const tempTime = moment.duration(x, 'minutes');
      let mins = '';
      let hours = '';
      if (tempTime.hours() !== 0) {
        hours = tempTime.hours() + (tempTime.hours() === 1 ? ' hr ' : ' hrs ');
      }
      if (tempTime.minutes() === 1) {
        mins = tempTime.minutes() + ' min';
      } else if (tempTime.minutes() > 1) {
        mins = tempTime.minutes() + ' mins';
      }
      return hours + mins;
  }

}
