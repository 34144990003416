import { BasePdfReportData, BenefitCheckReportModel, BenefitCheckTable, ReportFooter, ReportTable } from "@meraki-flux/schema";

export class BenefitCheckPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(BenefitCheckReportModel: BenefitCheckReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = BenefitCheckReportModel.PracticeId;
        reportData.doctorName = BenefitCheckReportModel.PracticeName;
        reportData.reportName = "Benefit Check";
        reportData.reportNameColor = BenefitCheckPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.tables = this.buildTables(BenefitCheckReportModel);
        reportData.dateHeader = `Date generated: ${BenefitCheckReportModel.ReportDate}`;
        reportData.footer = this.buildFooter()
        reportData.claimDetailData = BenefitCheckReportModel.BenefitCheckData
        reportData.footerText = "\t\u200B".repeat(60)
        return reportData;
    }

    buildFooter(): ReportFooter {
        return {
            hbLogo: true,
        }
    }

    buildTables(invoiceReportModel: BenefitCheckReportModel): ReportTable[] {
        const tables: ReportTable[] = [];
        invoiceReportModel.BenefitCheckTables.forEach(stTable => {
            tables.push(this.buildReportTable(stTable));
        })
        return tables;
    }

    buildReportTable(inTable: BenefitCheckTable): ReportTable {
        return {
            rowHeaders: inTable.rowHeaders,
            rows: inTable.rows,
            borders: inTable.borders
        }
    }
}
