export interface TariffMedicalTypeModel {
  Code?: string;
  Description?: string;
}

export interface TariffDisciplineModel {
  Description?: string;
  Code?: string;
  TariffMedicalType?: TariffMedicalTypeModel;
  TariffMedicalTypeCode?: string
}

export interface TariffCodeModel {
  Code?: string
  Description?: string;
  TariffDiscipline?: TariffDisciplineModel;
  DisciplineCode?: string;
  TariffMedicalType?: TariffMedicalTypeModel;
  TariffMedicalTypeCode?: string;
  TypeCode?: string;
}

export interface TariffModifierModel {
  UnitTypeDescription?: string;
  UnitTypeCode?: string;
  TariffRandCalculated?: number;
  TariffRandRcfSchemeRate?: number;
  NumberOfUnits?: number;
  TariffRatePublished?: number;
  TariffRcfPublished?: number;
}

export interface TariffFileNetworkModel {
  Category?: string;
  SubCategory?: string;
  TariffNetworkDispensingCode?: number;
  TariffNetworkLevelCode?: string;
  TariffNetworkTypeCode?: number;
  TariffIndicator?: number;
  TariffPercentage?: number;
  TariffCode?: TariffCodeModel;
  TariffCodeCode?: string;
  TariffDiscipline?: TariffDisciplineModel;
  TariffDisciplineCode?: string;
  PlanSubOptionCode?: string;
  PlanOptionCode?: string;
  TariffRandCalculated?: number;
  TariffRandRcfSchemeRate?: number;
  TariffRandSchemeFixed?: number;
  NumberOfUnits?: number;
  Description?: string;
  PriceGroupType?: string;
  PriceGroupCode?: string;
  RuleType?: string;
  ExtraInfo?: string;
  UnitTypeDescription?: string;
  UnitTypeCode?: string;
  TypeCode?: string;
  Year?: number;
  TariffRatePublished?: number;
  TariffRcfPublished?: number;
  TariffModifiers?: TariffModifierModel[]
}

export interface TariffFileNetworksResponseModel {
  TariffFileNetworks?: PaginationListModel<TariffFileNetworkModel>;
  DataError?: DataError;
  IsDemoData?: boolean;
}

export interface DataError {
  Code?: number;
  Message?: string;
}

export interface ProductResponseModel {
  Product?: ProductModel;
  DataError?: DataError;
  IsDemoData?: boolean;
}

export interface ProductModel {
  DateFormat?: string;
  Code?: string;
  Name?: string;
  NappiCodeFirstSixDigits?: string;
  NappiCodeSuffixLastThreeDigits?: string;
  NappiCode?: string;
  StrengthMetric1?: string;
  UnitOfMeasure1?: string;
  VolumeMetric2?: string;
  UnitOfMeasure2?: string;
  VolumeMetric3?: string;
  UnitOfMeasure3?: string;
  Presentation?: PresentationModel;
  PresentationCode?: string;
  StandardPacksize?: number;
  DispensingVolumePacksize?: string;
  VolumePackFlag?: boolean;
  DrugSchedule?: string;
  AtcLevel5Code?: string;
  AtcLevel5?: AtcLevel5Model;
  Manufacturer?: ManufacturerModel;
  ManufacturerCode?: string;
  NonSubstitutableProductFlag?: boolean;
  MedpraxGenericIdCode?: string;
  GenericIndicatorCode?: string;
  ProductStatus?: boolean;
  SingleExitPrice?: number;
  SingleExitPriceStartDate?: string;
  SingleExitPriceEndDate?: string;
  EdlFlag?: boolean;
  OncologyFlag?: boolean;
  CatalogueNumber?: string;
  Barcode?: string;
  Product0201?: string;
  ProductIndicator?: string;
  ProductSize?: string;
  RxUnit?: string;
  ScriptingDosage?: string;
  GenderFlag?: string;
  MaximumAdultDosage?: string;
  DrugExclusionDescriptionCodeList?: string[];
  AdministrationRouteCodeList?: string[];
  ActiveSubstanceDescriptionCodeList?: string[];
  ActiveSubstanceList?: ActiveSubstanceModel[];
  DrugClassCodeList?: string;
  DrugClassList?: DrugClassModel[];
  MimsCodeList?: string;
  FullDescription?: string;
  ReferencePriceFileList?: ReferencePriceFileModel[];
  FormularyList?: FormularyModel[];
}

export interface PresentationModel {
  Code?: string;
  Description?: string;
}

export interface AtcLevel5Model {
  Code?: string;
  Description?: string;
  AtcLevel4?: AtcLevel4Model;
  AtcLevel4Code?: string;
  Dosage?: string;
  DosageDescription?: string;
  AdministrationRouteCodeList?: string[];
  ProductCodeList?: string[];
}

export interface AtcLevel4Model {
  Code?: string;
  Description?: string;
  AtcLevel3?: AtcLevel3Model;
  AtcLevel3Code?: string;
}

export interface AtcLevel3Model {
  Code?: string;
  Description?: string;
  AtcLevel2?: AtcLevel2Model;
  AtcLevel2Code?: string;
}

export interface AtcLevel2Model {
  Code?: string;
  Description?: string;
  AtcLevel1?: AtcLevel1Model;
  AtcLevel1Code?: string;
}

export interface AtcLevel1Model {
  Code?: string;
  Description?: string;
}

export interface ManufacturerModel {
  Code?: string;
  Description?: string;
}

export interface ActiveSubstanceModel {
  ProductCode?: string;
  Product?: ProductModel;
  ActiveSubstanceDescriptionCode?: string;
  ActiveSubstanceDescription?: ActiveSubstanceDescriptionModel;
  Dosage?: string;
}

export interface ActiveSubstanceDescriptionModel {
  Code?: string;
  ClassCode?: string;
  DrugClass?: DrugClassModel;
  Description?: string;
  ProductCodeList?: string[];
}

export interface DrugClassModel {
  Code?: string;
  Description?: string;
}

export interface ReferencePriceFileModel {
  PriceCodeCode?: string;
  PriceCode?: ProductModel;
  PackPrice?: number;
  UnitPrice?: number;
}

export interface PriceCodeModel {
  Code?: string;
  Description?: string;
}

export interface FormularyModel {
  NappiCodeFirstSixDigits?: string;
  FormularyCodeCode?: string;
  FormularyCode?: FormularyCodeModel;
}

export interface FormularyCodeModel {
  Code?: string;
  Description?: string;
}

export interface FilterModel {
  PropertyName?: string;
  Operation?: Operation,
  Value?: any;
}

export enum Operation {
  Equals = 0,
  GreaterThan = 1,
  LessThan = 2,
  GreaterThanOrEqual = 3,
  LessThanOrEqual = 4,
  Contains = 5,
  StartsWith = 6,
  EndsWith = 7
}

export interface ProductsResponseModel {
  Products?: PaginationListModel<ProductModel>,
  DataError?: DataError;
  IsDemoData?: boolean;
}

export interface TariffFileModel {
  TariffCode?: TariffCodeModel;
  TariffCodeCode?: string;
  TariffDiscipline?: TariffDisciplineModel;
  TariffDisciplineCode?: string;
  PlanSubOptionCode?: string;
  PlanOptionCode?: string;
  TariffRandCalculated?: number;
  TariffRandRcfSchemeRate?: number;
  TariffRandSchemeFixed?: number;
  NumberOfUnits?: number;
  Description?: string;
  PriceGroupType?: string;
  PriceGroupCode?: string;
  RuleType?: string;
  ExtraInfo?: string;
  UnitTypeDescription?: string;
  UnitTypeCode?: string;
  TypeCode?: string;
  Year?: number;
  TariffRatePublished?: number;
  TariffRcfPublished?: number;
  TariffModifiers?: TariffModifierModel[];
}

export interface TariffCodeModel {
  Code?: string;
  Description?: string;
  TariffDiscipline?: TariffDisciplineModel;
  DisciplineCode?: string;
  TariffMedicalType?: TariffMedicalTypeModel;
  TariffMedicalTypeCode?: string;
  TypeCode?: string;
}

export interface TariffCodesResponseModel {
  TariffCodes?: PaginationListModel<TariffCodeModel>;
  DataError?: DataError;
  IsDemoData?: boolean;
}

export interface TariffDisciplineModel {
  Description?: string;
  Code?: string;
  TariffMedicalType?: TariffMedicalTypeModel;
  TariffMedicalTypeCode?: string;
}

export interface TariffMedicalTypeModel {
  Code?: string;
  Description?: string;
}

export interface TariffModifierModel {
  UnitTypeDescription?: string;
  UnitTypeCode?: string;
  TariffRandCalculated?: number;
  TariffRandRcfSchemeRate?: number;
  NumberOfUnits?: number;
  TariffRatePublished?: number;
  TariffRcfPublished?: number;
}

export interface TariffFilesResponseModel {
  TariffFiles?: PaginationListModel<TariffFileModel>,
  DataError?: DataError,
  IsDemoData?: boolean;
}

export interface PaginationListModel<T> {
  HasPreviousPage?: boolean;
  HasNextPage?: boolean;
  PageIndex?: number;
  PageSize?: number;
  TotalPages?: number;
  TotalCount?: number;
  ThisCount?: number;
  PageResult?: T[];
}

export interface PlanSubOptionResponseModel {
  PlanSubOption: PlanSubOptionModel;
  DataError: DataError;
  IsDemoData: boolean;
}

export interface PlanSubOptionsResponseModel {
  PlanSubOptions: PaginationListModel<PlanSubOptionModel>;
  DataError: DataError;
  IsDemoData: boolean;
}

export interface PlanSubOptionModel {
  DateFormat: string;
  Code: string;
  PlanOption: PlanOptionModel;
  PlanOptionCode: string;
  AccountDestination: AccountDestinationModel;
  AccountDestinationCode: string;
  ChronicMedicineManagement: ChronicMedicineManagementModel;
  ChronicMedicineManagementCode: string;
  Option: string;
  DiscontinuedDate: string;
  Remarks: string;
  ExtraRemarks: string;
  ConsultationAndProcedure: ConsultationAndProcedureModel;
  ConsultationAndProcedureCode: string;
  DispensingRule: DispensingRuleModel;
  DispensingRuleCode: string;
  EthicalsCalc: PlanSubOptionCalculationCodeModel;
  EthicalsCalcList: PlanSubOptionCalculationModel[];
  EthicalsCalcCode: string;
  ConsumablesCalc: PlanSubOptionCalculationCodeModel;
  ConsumablesCalcList: PlanSubOptionCalculationModel[];
  ConsumablesCalcCode: string;
  ConsultationsCalc: PlanSubOptionCalculationCodeModel;
  ConsultationsCalcList: PlanSubOptionCalculationModel[];
  ConsultationsCalcCode: string;
  ReferencePriceFile: PriceCodeModel;
  ReferencPriceCode: string;
  FormularyCode: FormularyCodeModel;
  FormularyCodeCode: string;
  DataTransmissionList: DataTransmissionCodeModel[];
  DrugExclusionDescriptionCodeList: string[];
  GroupCode: string;
}

export interface PlanOptionModel {
  DateFormat: string;
  Code: string;
  PlanModel: PlanModel;
  PlanCode: string;
  Option: string;
  Remarks: string;
  BalanceBilling: boolean;
  DiscontinuedDate: string;
  OutHospitalReimbursementRate: string;
  PmbReimbursementRate: string;
  InHospitalReimbursementRate: string;
  PlanSubOptionCodeList: string[];
  CapitationOption: boolean;
}

export interface PlanModel {
  DateFormat: string;
  Code: string;
  Scheme: SchemeModel;
  SchemeCode: string;
  Option: string;
  Remarks: string;
  DiscontinuedDate: string;
  StartDate: string;
  PlanOptionCodeList: string[];
}

export interface SchemeModel {
  DateFormat: string;
  Name: string;
  Code: string;
  SchemeAdministrator: SchemeAdministratorModel,
  SchemeAdministratorCode: string;
  ManagedCareCompany: ManagedCareCompanyModel,
  ManagedCareCompanyCode: string;
  CaptitionScheme: string;
  DiscontinuedDate: string;
  CmsReferenceNumber: string;
  Category: string;
  SchemeTelephoneNumber: string;
  SchemeEmail: string;
  SchemeUrl: string;
  PlanCodeList: string[];
}

export interface SchemeResponseModel {
  Scheme: SchemeModel;
  DataError: DataError;
  IsDemoData: boolean;
}

export interface PlanOptionResponseModel {
  PlanOption: PlanOptionModel;
  DataError: DataError;
  IsDemoData: boolean;
}

export interface SchemesResponseModel {
  Schemes: PaginationListModel<SchemeModel>;
  DataError: DataError;
  IsDemoData: boolean;
}

export interface SchemeAdministratorModel {
  Code: string;
  Name: string;
  DiscontinuedDate: Date;
  SchemeCodeList: string[];
}

export interface ManagedCareCompanyModel {
  Code: string;
  Name: string;
  TollFree: string;
  TelephoneNumber: string;
  FaxNumber: string;
  Email: string;
  Url: string;
}

export interface AccountDestinationModel {
  Code: string;
  Address1: string;
  Address2: string;
  TelephoneNumber: string;
  FaxNumber: string;
  Email: string;
  Url: string;
}

export interface ChronicMedicineManagementModel {
  Code: string;
  Name: string;
  TollFree: string;
  TelephoneNumber: string;
  FaxNumber: string;
  Email: string;
  Url: string;
}

export interface ConsultationAndProcedureModel {
  Code: string;
  Levy1: string;
  Levy2: string;
  CipIndicator: string;
  Rule: string;
  PreferredProvider: boolean;
  LevyElse: string;
  Discount0201: string;
}

export interface DispensingRuleModel {
  Code: string;
  Rpl: string;
  PreferredProvider: boolean;
  Discount: string;
  Levy1: string;
  Levy2: string;
  Levy3: string;
  LevyPerScriptItem1: string;
  LevyPerScriptItem2: string;
  MaximumLevy: string;
  MinimumLevy: string;
  Levy: string;
  LevyElse: string;
  Rule: string;
  Remarks: string;
  CoPayment: boolean;
}

export interface PlanSubOptionCalculationCodeModel {
  Code: string;
  Description: string;
}

export interface PlanSubOptionCalculationModel {
  PlanSubOptionCalculationCodeCode?: string;
  PlanSubOptionCalculationCode?: PlanSubOptionCalculationCodeModel;
  Tier?: string;
  ScheduleFrom?: string;
  ScheduleTo?: string;
  CostFrom?: number;
  CostTo?: number;
  FixedFee?: number;
  MarkUpPercentage?: number;
  CalculationType?: string;
  CappedAt?: number;
  ProfessionalFeeFlag?: boolean;
  LineItemFlag?: string;
  Description?: string;
}

export interface DataTransmissionCodeModel {
  PlanSubOptionCode: string;
  Ip01Id: string;
  Ip02Id: string;
  Md01Id: string;
  Md03Id: string;
  Mk01Id: string;
  Mk03Id: string;
  Mk04Id: string;
  Mk06Id: string;
  Mp01Id: string;
  Ms01Id: string;
  Qe01Id: string;
  Ag01Id: string;
  HbRoute: string;
  Mk05Id: string;
  Mk02Id: string;
}

export interface SchemeLimitedResponseModel {
  Schemes: SchemeLimitedModel[],
  DataError: DataError,
  IsDemoData: boolean
}

export interface SchemeLimitedModel {
  Name: string;
  Code: string;
  SchemeAdministratorCode: string;
}

export interface PlanLimitedResponseModel {
  Plans: PlanLimitedModel[];
  DataError: DataError,
  IsDemoData: boolean
}

export interface PlanLimitedModel {
  Code: string;
  Option: string;
  SchemeCode: string;
}

export interface PlanOptionLimitedResponseModel {
  PlanOptions: PlanOptionLimitedModel[];
  DataError: DataError,
  IsDemoData: boolean
}

export interface PlanOptionLimitedModel {
  Code: string;
  Option: string;
  PlanCode: string;
}
