import {
  BaseExcelReportData, CellType,
  CellWidth, ExcelCellData, InvoiceDataList, PaymentsDataList,
  REPORT_NAME,
  ReversedTransactionsReportModel,
  Sheet,
  Table, TRANSACTION_TYPE, TransactionDataList
} from "@meraki-flux/schema";

export class ReversedTransactionsXlsReportDataBuilder {


    build(reversedTransactionsReportModel: ReversedTransactionsReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.REVERSED_TRANSACTIONS;
        excelReportData.data = [];

        excelReportData.data.push(this.buildInvoiceSheetDetail(reversedTransactionsReportModel));
        excelReportData.data.push(this.buildPatientPaymentDetailsSheet(reversedTransactionsReportModel));
        excelReportData.data.push(this.buildMedPaymentDetailsSheet(reversedTransactionsReportModel));
        excelReportData.data.push(this.buildCNDetailsSheet(reversedTransactionsReportModel));
        excelReportData.data.push(this.buildWriteOffDetailsSheet(reversedTransactionsReportModel));
        excelReportData.data.push(this.buildPayCorrectDetailsSheet(reversedTransactionsReportModel));

        this.buildReportInfo(excelReportData,reversedTransactionsReportModel);

        return excelReportData;
    }

    private buildInvoiceSheetDetail(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Invoices';
        sheet.tables = [];

        const detailsTable: Table = {};

        const invoiceDetails= reversedTransactionsReportModel.RVPInvoiceDS;
        detailsTable.headers = [
            {value: 'Cancellation date', bold: true, width: CellWidth.XS, hAlignment:'left'},
            {value: 'User', bold: true, width: CellWidth.S, hAlignment:'left'},
            {value: 'Cancellation reason', bold: true, width: CellWidth.XS, hAlignment:'left' ,wordWrap:true},
            {value: 'Invoice type', bold: true, width: CellWidth.XS, hAlignment:'left'},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
            {value: 'Date of service', bold: true, width:CellWidth.XS, hAlignment:'left',wordWrap:true},
            {value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left',wordWrap:true},
			{value: 'Treating provider', bold: true, width: CellWidth.S, hAlignment:'left',wordWrap:true},
			{value: 'Account no.', bold: true, width: CellWidth.S, hAlignment:'left'},
            {value: 'Account holder', bold: true, width: CellWidth.S, hAlignment:'left',wordWrap:true},
            {value: 'Invoiced amount', bold: true, width: CellWidth.XS, hAlignment:'right'},
            {value: 'Reference no.', bold: true, width: CellWidth.S, hAlignment:'left'}
        ];

        const detailsRows = [];

        invoiceDetails.forEach((item: InvoiceDataList) => {
        const row: ExcelCellData[] = [];
		row.push({value: item.DateCancelled, hAlignment:'left'});
		row.push({value: item.ReversalUser, hAlignment:'left'});
		row.push({value: item.CancelReason, hAlignment:'left' ,wordWrap:true});
        row.push({value: item.InvoiceType, hAlignment:'left'});
        row.push({value: item.InvNumber, hAlignment:'left'});
		row.push({ value: item.DateOfService, hAlignment: 'left' });
        row.push({ value: item.DateOfSubmission, hAlignment:'left' });
        row.push({ value: item.TreatingProvider, hAlignment:'left',wordWrap:true });
        row.push({ value: item.AccNumber, hAlignment:'left' });
		row.push({ value: item.AccHolder, hAlignment:'left',wordWrap:true });
        row.push({value: item.InvAmount, type: CellType.CURRENCY});
		row.push({value: item.ReferenceNum, hAlignment:'left',wordWrap:true});
        detailsRows.push(row);
      });

        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildPatientPaymentDetailsSheet(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Patient Payments';
        sheet.tables = [];

        const table: Table = {};
        const transactionDetails = reversedTransactionsReportModel.RVPaymentDS;
        const rows = [];

        const mainheaderRow: ExcelCellData[] = [
            { value: 'PATIENT PAYMENTS', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'INVOICE DETAILS', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
            // Add other header cells as needed
        ];
        rows.push(mainheaderRow);

        const headerRow: ExcelCellData[] = [
            { value: 'Reversal Date', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9',wordWrap:true },
            { value: 'User', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: 'Reversal Reason', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
            { value: 'Payment date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
            { value: 'Payment amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9',wordWrap:true },
            { value: 'Payment type', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Receipt no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: 'Date of service', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Treating provider', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2',wordWrap:true},
            { value: 'Account no.', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2',wordWrap:true},
            { value: 'Account holder', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Invoice Amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2',wordWrap:true }
            // Add other header cells as needed
        ];
        rows.push(headerRow);

        transactionDetails.forEach((item: PaymentsDataList) => {
        let transactionType : string;
        const row: ExcelCellData[] = [];
        if (item.TransactionType == TRANSACTION_TYPE.PATIENT_PAYMENT){
                transactionType = TRANSACTION_TYPE.PATIENT_PAYMENT;
            }
            if(transactionType !== undefined){


                row.push({ value: item.ReversalDate, hAlignment: 'left' });
                row.push({ value: item.ReversalUser, hAlignment:'left' });
                row.push({ value: item.ReversalReason, hAlignment:'left' });
                row.push({ value: item.PaymentDate, hAlignment:'left' });
                row.push({ value: item.PatPaymentAmt, type: CellType.CURRENCY, hAlignment:'right'});
                row.push({ value: item.PaymentType, hAlignment:'left' });
                row.push({ value: item.PaymentReceiptNo, hAlignment:'left' });
                row.push({ value: item.InvoiceNum, hAlignment:'left' });
                row.push({ value: item.DateOfService, hAlignment: 'left' });
                row.push({ value: item.DateOfSubmission, hAlignment:'left' });
                row.push({ value: item.TreatingProvider, hAlignment:'left',wordWrap:true });
                row.push({ value: item.AccountNum, hAlignment:'left' });
                row.push({ value: item.AccountHolder, hAlignment:'left',wordWrap:true });
                row.push({ value: item.InvoicePaymentAmount,type: CellType.CURRENCY, hAlignment:'right' });

            rows.push(row);
            }

          });

        table.rows = rows;
        sheet.tables.push(table);
        return sheet;
      }

      private buildMedPaymentDetailsSheet(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Medical Aid Payments';
        sheet.tables = [];

        const table: Table = {};
        const transactionDetails = reversedTransactionsReportModel.RVPaymentDS;

        const rows = [];
        const mainheaderRow: ExcelCellData[] = [
            { value: 'MEDICAL AID PAYMENTS', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'INVOICE DETAILS', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
            // Add other header cells as needed
        ];
        rows.push(mainheaderRow);

        const headerRow: ExcelCellData[] =[
            { value: 'Reversal Date', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'User', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Reversal Reason', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
            { value: 'Payment date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Payment amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: 'Payment type', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Receipt no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Scheme', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9'},
            { value: 'RA number', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9'},
            { value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: 'Date of service', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Treating provider', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Account no.', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2' },
            { value: 'Account holder', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
            { value: 'Invoice Amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
        ];
        rows.push(headerRow);

        transactionDetails.forEach((item: PaymentsDataList) => {
        let transactionType : string;
        if (item.TransactionType == TRANSACTION_TYPE.MEDICAL_AID_PAYMENT){
                transactionType = TRANSACTION_TYPE.MEDICAL_AID_PAYMENT;
            }
            if(transactionType !== undefined){

                const row: ExcelCellData[] = [];
                row.push({ value: item.ReversalDate, hAlignment: 'left' });
                row.push({ value: item.ReversalUser, hAlignment:'left' });
                row.push({ value: item.ReversalReason, hAlignment:'left',wordWrap:true });
                row.push({ value: item.PaymentDate, hAlignment:'left' });
                row.push({ value: item.MedPaymentAmt,type: CellType.CURRENCY, hAlignment:'right' });
                row.push({ value: item.PaymentType, hAlignment:'left' });
                row.push({ value: item.PaymentReceiptNo, hAlignment:'left' });
                row.push({ value: item.Scheme, hAlignment:'left',wordWrap:true });
                row.push({ value: item.RANumber, hAlignment:'left' });
                row.push({ value: item.InvoiceNum , hAlignment:'left'});
                row.push({ value: item.DateOfService, hAlignment: 'left' });
                row.push({ value: item.DateOfSubmission, hAlignment:'left' });
                row.push({ value: item.TreatingProvider, hAlignment:'left',wordWrap:true });
                row.push({ value: item.AccountNum, hAlignment:'left' });
                row.push({ value: item.AccountHolder, hAlignment:'left',wordWrap:true });
                row.push({ value: item.InvoicePaymentAmount,type: CellType.CURRENCY, hAlignment:'right' });

            rows.push(row);
            }

          });

        table.rows = rows;
        sheet.tables.push(table);
        return sheet;
      }

      private buildCNDetailsSheet(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Credit Notes';
        sheet.tables = [];

        const table: Table = {};
        const transactionDetails = reversedTransactionsReportModel.RVTransactionDS;

        const rows = [];
        const mainheaderRow: ExcelCellData[] = [
            { value: 'CREDIT NOTES', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'INVOICE DETAILS', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
            // Add other header cells as needed
        ];
        rows.push(mainheaderRow);

        const headerRow: ExcelCellData[] = [
            { value: 'Reversal Date', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'User', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Reversal Reason', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
			{ value: 'Credit note date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9'},
			{ value: 'Credit note amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9'},
			{ value: 'Credit note type', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true},
			{ value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of service', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Treating provider', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Account no.', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Account holder', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Invoice Amount', bold: true, width: CellWidth.XS , hAlignment:'right', bgColor:'f2f2f2'}
            // Add other header cells as needed
        ];
        rows.push(headerRow);

        transactionDetails.forEach((item: TransactionDataList) => {
        let transactionType : string;
        if (item.TransactionType == TRANSACTION_TYPE.CREDIT){
                transactionType = TRANSACTION_TYPE.CREDIT;
            }
            if(transactionType !== undefined){

                const row: ExcelCellData[] = [];
				row.push({ value: item.ReversalDate, hAlignment: 'left' });
				row.push({ value: item.ReversalUser, hAlignment:'left' });
				row.push({ value: item.ReversalReason, hAlignment:'left',wordWrap:true });
				row.push({ value: item.CrDate, hAlignment:'left' });
				row.push({ value: item.CrAmount,type: CellType.CURRENCY, hAlignment:'right' });
				row.push({ value: item.CrType, hAlignment:'left',wordWrap:true });
				row.push({ value: item.InvoiceNum, hAlignment:'left' });
				row.push({ value: item.DateOfService, hAlignment: 'left' });
				row.push({ value: item.DateOfSubmission, hAlignment:'left' });
				row.push({ value: item.TreatingProvider, hAlignment:'left',wordWrap:true });
				row.push({ value: item.AccountNum, hAlignment:'left' });
				row.push({ value: item.AccountHolder, hAlignment:'left',wordWrap:true });
				row.push({ value: item.InvoicePaymentAmount,type: CellType.CURRENCY, hAlignment:'right' });

            rows.push(row);
            }

          });

        table.rows = rows;
        sheet.tables.push(table);
        return sheet;
      }

      private buildWriteOffDetailsSheet(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Write-Offs';
        sheet.tables = [];

        const table: Table = {};
        const transactionDetails = reversedTransactionsReportModel.RVTransactionDS;

        const rows = [];
        const mainheaderRow: ExcelCellData[] = [
            { value: 'WRITE-OFFS', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'INVOICE DETAILS', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
            // Add other header cells as needed
        ];
        rows.push(mainheaderRow);

        const headerRow: ExcelCellData[] = [
            { value: 'Reversal Date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'User', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Reversal Reason', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
			{ value: 'Write-off date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9'},
			{ value: 'Write-off amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9'},
			{ value: 'Write-off type', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true},
			{ value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of service', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Treating provider', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Account no.', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Account holder', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Invoice Amount', bold: true, width: CellWidth.XS , hAlignment:'right', bgColor:'f2f2f2'}
            // Add other header cells as needed
        ];
        rows.push(headerRow);

        transactionDetails.forEach((item: TransactionDataList) => {
        let transactionType : string;
        if (item.TransactionType == TRANSACTION_TYPE.WRITE_OFF){
                transactionType = TRANSACTION_TYPE.WRITE_OFF;
            }
            if(transactionType !== undefined){

                const row: ExcelCellData[] = [];
				row.push({ value: item.ReversalDate, hAlignment: 'left' });
				row.push({ value: item.ReversalUser, hAlignment:'left' });
				row.push({ value: item.ReversalReason, hAlignment:'left',wordWrap:true });
				row.push({ value: item.WriteOffDate, hAlignment:'left' });
				row.push({ value: item.WriteOffAmount,type: CellType.CURRENCY, hAlignment:'right' });
				row.push({ value: item.WriteOffType, hAlignment:'left',wordWrap:true });
				row.push({ value: item.InvoiceNum, hAlignment:'left' });
				row.push({ value: item.DateOfService, hAlignment: 'left' });
				row.push({ value: item.DateOfSubmission , hAlignment:'left'});
				row.push({ value: item.TreatingProvider, hAlignment:'left',wordWrap:true });
				row.push({ value: item.AccountNum, hAlignment:'left' });
				row.push({ value: item.AccountHolder, hAlignment:'left',wordWrap:true });
				row.push({ value: item.InvoicePaymentAmount,type: CellType.CURRENCY, hAlignment:'right' });

            rows.push(row);
            }

          });

        table.rows = rows;
        sheet.tables.push(table);
        return sheet;
      }

      private buildPayCorrectDetailsSheet(reversedTransactionsReportModel: ReversedTransactionsReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Payment Corrections';
        sheet.tables = [];

        const table: Table = {};
        const transactionDetails = reversedTransactionsReportModel.RVTransactionDS;

        const rows = [];
        const mainheaderRow: ExcelCellData[] = [
            { value: 'PAYMENT CORRECTIONS', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.S, hAlignment: 'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9' },
            { value: 'INVOICE DETAILS', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left' , bgColor:'f2f2f2'},
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
            { value: '', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'f2f2f2' }
            // Add other header cells as needed
        ];
        rows.push(mainheaderRow);

        const headerRow: ExcelCellData[] = [
            { value: 'Reversal Date', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'User', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9' },
            { value: 'Reversal Reason', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'ffd9d9d9',wordWrap:true },
			{ value: 'Payment correction date', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'ffd9d9d9'},
			{ value: 'Payment correction amount', bold: true, width: CellWidth.XS, hAlignment:'right', bgColor:'ffd9d9d9'},
			{ value: 'Invoice no.', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of service', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Date of submission', bold: true, width: CellWidth.XS, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Treating provider', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Account no.', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2' },
			{ value: 'Account holder', bold: true, width: CellWidth.S, hAlignment:'left', bgColor:'f2f2f2',wordWrap:true },
			{ value: 'Invoice Amount', bold: true, width: CellWidth.XS , hAlignment:'right', bgColor:'f2f2f2'}
            // Add other header cells as needed
        ];
        rows.push(headerRow);

        transactionDetails.forEach((item: TransactionDataList) => {
        let transactionType : string;
        if (item.TransactionType == TRANSACTION_TYPE.PAYMENT_CORRECTION){
                transactionType = TRANSACTION_TYPE.PAYMENT_CORRECTION;
            }
            if(transactionType !== undefined){

                const row: ExcelCellData[] = [];
				row.push({ value: item.ReversalDate, hAlignment: 'left' });
				row.push({ value: item.ReversalUser, hAlignment:'left' });
				row.push({ value: item.ReversalReason , hAlignment:'left'});
				row.push({ value: item.PcDate, hAlignment:'left' });
				row.push({ value: item.PcAmount,type: CellType.CURRENCY, hAlignment:'right' });
				row.push({ value: item.InvoiceNum, hAlignment:'left' });
				row.push({ value: item.DateOfService, hAlignment: 'left' });
				row.push({ value: item.DateOfSubmission, hAlignment:'left' });
				row.push({ value: item.TreatingProvider , hAlignment:'left'});
				row.push({ value: item.AccountNum, hAlignment:'left' });
				row.push({ value: item.AccountHolder , hAlignment:'left'});
				row.push({ value: item.InvoicePaymentAmount,type: CellType.CURRENCY, hAlignment:'right' });

            rows.push(row);
            }

          });

        table.rows = rows;
        sheet.tables.push(table);
        return sheet;
      }

    private buildReportInfo(excelReportData: BaseExcelReportData, reversedTransactionsReportModel: ReversedTransactionsReportModel) {

        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: reversedTransactionsReportModel.ReversedTransactionsReportInfo.Practice, type: CellType.GENERAL}
        });
		excelReportData.parameters.push({
			name: 'Billing practice no',
			data: {value: reversedTransactionsReportModel.ReversedTransactionsReportInfo.BillingPracticeNumber, type: CellType.GENERAL},
		});
        excelReportData.parameters.push(
            {
                name: 'Date range type',
                   data: {value: reversedTransactionsReportModel.ReversedTransactionsReportInfo.DateRangeType, type: CellType.GENERAL}
            });

         excelReportData.parameters.push(
             {
                 name: 'Date range',
                 data: {value: reversedTransactionsReportModel.ReversedTransactionsReportInfo.DateRange, type: CellType.GENERAL}
             });
    }
}
