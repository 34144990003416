import {Practice} from "../practice.models";
import {REPORT_FORMAT_TYPE} from "../report.models";
import { ReportRequestData } from './common.model';

export class PaymentSummaryReportRequest implements ReportRequestData {
  ReportName?: string;
  Practice?: Practice;
  DateFrom?: Date;
  DateTo?: Date;
  ReportFormatType?: REPORT_FORMAT_TYPE;
  UserId: string;
  Recipient?: string;
}

export interface PaymentSummaryReportModel {
  ReportInfo: PaymentSummaryReportInfo;
  ReportDate?: string;

  PatientPaymentTypeData: PaymentSummaryGroupData
  MedicalAidPaymentTypeData: PaymentSummaryGroupData
  SchemeData: PaymentSummaryGroupData
  InsurerPaymentTypeData: PaymentSummaryGroupData
  InsurerData: PaymentSummaryGroupData
}

export class PaymentSummaryGroupData {

  map:Map<string, PaymentSummaryItem> = new Map;
  totals: PaymentSummaryItem = new PaymentSummaryItem();

  addData(key: string, newItem: PaymentSummaryItem) {
    if (!key) return;
    let item: PaymentSummaryItem = this.map.get(key);
    if (!item) {
      item = new PaymentSummaryItem();
      this.map.set(key, item);
    }
    item.addItem(newItem);
    this.totals.addItem(newItem);
  }

}

export class PaymentSummaryItem {
  count? = 0;
  total? = 0;
  allocated? = 0;
  unallocated? = 0;

  addItem(item: PaymentSummaryItem) {
    if (!item) return;
    this.count +=item.count;
    this.total += item.total as number;
    this.allocated +=item.allocated;
    this.unallocated +=item.unallocated;
  }
}

export interface PaymentSummaryReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  DateFrom?: Date,
  DateTo?: Date
}
