
<div class="flex flex-col w-full h-full">
  <div class="flex flex-col overflow-hidden">
      <!-- Description row -->
      <div class="flex flex-row gap-4 overflow-hidden">
          <div class="flex flex-col overflow-hidden">
              <label class="text-hb-text-subdued text-[11px] whitespace-nowrap">{{codeHeader}}</label>
              <label class="text-hb-text-default font-medium text-[13px] mt-0.5">{{code}}</label>
          </div>

          <div class="flex flex-col flex-1 overflow-hidden">
              <label class="text-hb-text-subdued text-[11px]">Description</label>
              <label class="text-hb-text-default font-medium text-[13px] mt-0.5 overflow-hidden whitespace-nowrap text-ellipsis">{{description}}</label>
          </div>
      </div>

      <!-- Form row -->
      <div class="flex flex-col gap-2 mt-2" [formGroup]="simpleTemplateForm">
          <mat-form-field class="flex-1 flux-form-field" appearance="fill">
              <mat-label>Name</mat-label>
              <input #nameField id='nameField' matInput cdkFocusInitial formControlName="name" title="Name">
          </mat-form-field>

          <mat-form-field class="flux-form-field w-24" appearance="fill">
              <mat-label>Order</mat-label>
              <input id='orderField' matInput formControlName="sortOrder" type="number" min="0" title="Sort Order">
          </mat-form-field>
      </div>
  </div>

  <div class="flex flex-row justify-end gap-2 px-6 py-1 mt-4 -mx-6 -mb-4 border-t border-t-hb-base-divider-grey">
      <button class="flux-button" mat-stroked-button mat-dialog-close>Close</button>
      <button id="save" class="flux-button" mat-flat-button color="primary" (click)="onDone()">Save</button>
  </div>
</div>
