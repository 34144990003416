import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { UserRepository } from '../repositories/user.repository';
import { FormatUtils } from '../utils/format-utils';
import { PathUtils } from '../utils/path-utils';
import {User} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private firestore: Firestore, private userRepository: UserRepository) {}

  async user(userId: string, refetch: boolean = false): Promise<User> {
    let user = !refetch ? this.userRepository.getUser(userId) : undefined;
    if (userId && !user) {
      const userDoc = await getDoc(doc(this.firestore, PathUtils.userPath(userId)));
      if (userDoc.exists()) {
        user = { ...userDoc.data() } as User;
        this.userRepository.saveUser(user);
      }
    }
    return user;
  }

  async userFullName(userId: string) {
    const lowercaseUserId = userId?.toLocaleLowerCase();
    if (
      !userId ||
      userId.includes(' ') ||
      lowercaseUserId === 'system' ||
      lowercaseUserId === 'clinical' ||
      lowercaseUserId === 'remittance advice reconcile'
    ) {
      return 'System';
    }
    const user = await this.user(userId);
    return user ? FormatUtils.nameSurname(user?.Name, user?.Surname) : userId;
  }
}
