import { QueryConstraint, where } from '@angular/fire/firestore';
import {CALENDAR_EVENT_STATUS, CALENDAR_EVENT_TYPE} from "@meraki-flux/schema";

export class CalendarEventWhereBuilder {
  wheres: QueryConstraint[];

  constructor(wheres: QueryConstraint[]) {
    this.wheres = wheres;
  }

  static builder(): CalendarEventWhereBuilder {
    return new CalendarEventWhereBuilder([]);
  }

  build() {
    return this.wheres;
  }

  private isValidBoolean(value: boolean) {
    return value === true || value === false;
  }

  status(value: CALENDAR_EVENT_STATUS) {
    if (value) this.wheres.push(where('Status', '==', value));
    return this;
  }

  statusIn(statuses: CALENDAR_EVENT_STATUS[]) {
    if (statuses?.length > 0) this.wheres.push(where('Status', 'in', statuses));
    return this;
  }

  treatingProvider(value: string) {
    if (value) this.wheres.push(where('TreatingProvider', '==', value));
    return this;
  }

  treatingProviders(values: string[]) {
    if (values?.length > 0 && values?.length <= 30) this.wheres.push(where('TreatingProvider', 'in', values));
    return this;
  }

  branch(value: string) {
    if (value) this.wheres.push(where('Branch', '==', value));
    return this;
  }

  endTimeFromGOE(value: any) {
    if (value) this.wheres.push(where('EndTime', '>=', value));
    return this;
  }

  endTimeToLT(value: any) {
    if (value) this.wheres.push(where('EndTime', '<', value));
    return this;
  }

  isRecurrentRoot(value: boolean) {
    if (this.isValidBoolean(value)) this.wheres.push(where('IsRecurrentRoot', '==', value));
    return this;
  }

  recurrentRootEventId(value: string) {
    if (value) this.wheres.push(where('RecurrentInfo.RootEventId', '==', value));
    return this;
  }

  recurrentEventIndexGOE(value: number) {
    if (value >= 0) this.wheres.push(where('RecurrentInfo.Index', '>=', value));
    return this;
  }

  patientId(value: string) {
    if (value) this.wheres.push(where('VisitInfo.PatientInfo.PatientId', '==', value));
    return this;
  }

  accountId(value: string) {
    if (value) this.wheres.push(where('VisitInfo.AccountInfo.AccountId', '==', value));
    return this;
  }

  startTimeGOE(value: any) {
    if (value) this.wheres.push(where('StartTime', '>=', value));
    return this;
  }

  startTimeLOE(value: any) {
    if (value) this.wheres.push(where('StartTime', '<=', value));
    return this;
  }

  eventType(value: CALENDAR_EVENT_TYPE) {
    if (value) this.wheres.push(where('EventType', '==', value));
    return this;
  }
}
