import {
  BaseExcelReportData, CategorySummaryItem,
  CellType,
  CellWidth, DetailsItem, ExcelCellData,
  JournalReportModel,
  REPORT_NAME, SchemeSummaryItem,
  Sheet,
  Table
} from "@meraki-flux/schema";

export class JournalXlsReportDataBuilder {

    build(journalReportModel: JournalReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = journalReportModel.JournalReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.JOURNAL;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, journalReportModel);
        excelReportData.data.push(this.buildReportSheetSummary(journalReportModel));
        excelReportData.data.push(this.buildReportSheetDetail(journalReportModel));


        return excelReportData;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, journalReportModel: JournalReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: journalReportModel.JournalReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Scheme',
            data: {value: journalReportModel.JournalReportInfo.Scheme, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Payment date range',
            data: {value: journalReportModel.JournalReportInfo.PaymentDateRange, type: CellType.GENERAL}
        });

    }

    private buildReportSheetSummary(journalReportModel: JournalReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.header = 'Journal Report';
        sheet.tables = [];

        //Category summary
        const categoryTable: Table = {};
        categoryTable.name = "Totals per journal category";
        categoryTable.headers = [
            {value: 'Category', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Total', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const categoryRows = [];

        const categorySummary = journalReportModel.JournalProviderModel.CategorySummaryItem;
        categorySummary.sort((a,b) => a.Category.localeCompare(b.Category));

        let totalCategory = 0;
        categorySummary.forEach((item: CategorySummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Category });
            row.push({value: item.Total, type: CellType.NUMBER});
            totalCategory= totalCategory + item.Total;
            categoryRows.push(row);
        });

        const totalRow: ExcelCellData[] = [];
        totalRow.push({value: '', bold: true});
        totalRow.push({value: totalCategory, bold: true});
        categoryRows.push(totalRow);

        categoryTable.rows = categoryRows;
        sheet.tables.push(categoryTable);
        //END - Category summary


        //Scheme summary
        const schemeTable: Table = {};
        schemeTable.name = "Totals per scheme";
        schemeTable.headers = [
            {value: 'Scheme', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Total', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const schemeRows = [];

        const schemeSummary = journalReportModel.JournalProviderModel.SchemeSummaryItem;
        schemeSummary.sort((a,b) => a.Scheme.localeCompare(b.Scheme));

        let totalScheme = 0;
        schemeSummary.forEach((item: SchemeSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Scheme });
            row.push({value: item.Total, type: CellType.NUMBER});
            totalScheme= totalScheme + item.Total;
            schemeRows.push(row);
        });

        const totalRowScheme: ExcelCellData[] = [];
        totalRowScheme.push({value: '', bold: true});
        totalRowScheme.push({value: totalScheme, bold: true});
        schemeRows.push(totalRowScheme);

        schemeTable.rows = schemeRows;
        sheet.tables.push(schemeTable);
        //END - Scheme summary

        return sheet;
    }

    private buildReportSheetDetail(journalReportModel: JournalReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Detail';
        //sheet.header = 'Journal Report';
        sheet.tables = [];

        const detailsTable: Table = {};

        detailsTable.headers = [
            {value: 'Scheme', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'EFT no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'RA date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'RA no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Journal category', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Journal description', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Journal amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Recon type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Date captured', bold: true, width: CellWidth.XS, wordWrap: true},
        ];

        const detailsRows = [];
        journalReportModel.JournalProviderModel.DetailsItem.forEach((item: DetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Scheme});
            row.push({value: item.PaymentDate, type: CellType.GENERAL});
            row.push({value: item.EFTNo, hAlignment:'left'});
            row.push({value: item.RADate, type: CellType.GENERAL});
            row.push({value: item.RANo, hAlignment:'left'});
            row.push({value: item.JournalCategory});
            row.push({value: item.JournalDescription});
            row.push({value: item.JournalAmount, type: CellType.CURRENCY});
            row.push({value: item.ReconType});
            row.push({value: item.DateCaptured, type: CellType.GENERAL});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }
}
