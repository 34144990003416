<div class="flex flex-col bg-hb-base-base-grey overflow-hidden">
    <!-- colour grid -->
    <div class="flex flex-row flex-wrap w-[180px] gap-1 p-2 border-b border-b-hb-base-divider-grey">
        <div *ngFor="let colour of colours" class="flux-colour-tile-base w-6 h-6 cursor-pointer" matRipple
            [ngStyle]="{'background-color': colour}" (click)="onColourClicked($event, colour)">
            <mat-icon *ngIf="colour === selectedColour" class="!text-hb-base-white !text-[20px]">check</mat-icon>
        </div>
    </div>

    <div class="flex flex-row items-center px-2 py-1 cursor-pointer hover:bg-hb-base-divider-grey" matRipple (click)="onToggleMoreColours($event)">
        <div class="rainbow-circle rounded-full border-2 w-6 h-6 border-hb-base-white"></div>
        <span class="font-normal text-xs ml-2">More colours</span>
    </div>

    <span *ngIf="moreColoursVisible" [style.background]="'#FFFFFF'" 
    [cpAlphaChannel]="'disabled'"
    [cpDialogDisplay]="'inline'"
    [cpToggle]="true"
    [(colorPicker)]="selectedColour" [cpWidth]="'180px'">
    </span>

    <div class="flex flex-row gap-2 p-2 bg-hb-base-white">
        <button mat-stroked-button color="secondary">Cancel</button>
        <button mat-flat-button  class="!text-hb-base-white" color="primary" (click)="onDone()">Done</button>
    </div>

</div>