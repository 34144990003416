import {
  BaseExcelReportData,
  CellType, CellWidth,
  CustomChargeCodesReportRequest, ExcelCellData,
  REPORT_NAME,
  Sheet,
  Table
} from "@meraki-flux/schema";
import moment from "moment";

export class CustomChargeCodesXlsReportDataBuilder {

  private readonly REPORT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

  build(reportModel: CustomChargeCodesReportRequest): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.name = REPORT_NAME.CUSTOM_CHARGE_CODES;
    excelReportData.data = [];
    excelReportData.parameters = [];
    excelReportData.parameters.push({
      name: "Practice",
      data: {
        value: reportModel.Practice.PracticeName
      }
    })
    excelReportData.parameters.push({
      name: "Billing practice no.",
      data: {
        value: reportModel.Practice.BillingPracticeNumber,
        type: CellType.GENERAL,
        hAlignment: "left"
      }
    })
    excelReportData.parameters.push({
      name: "Type",
      data: {
        value: reportModel.CCCType
      }
    })
    const sheet: Sheet = {};
    sheet.name = 'Details';
    sheet.tables = [];
    const dataTable: Table = {};
    dataTable.headers = [
      {value: 'Type', bold: true},
      {value: 'Code', bold: true},
      {value: 'Description', bold: true},
      {value: 'Unit Price', bold: true},
      {value: 'Pack size', bold: true},
      {value: 'Pack price', bold: true},
      {value: 'Status', bold: true},
      {value: 'Create date', bold: true},
      {value: 'Created by', bold: true},
      {value: 'Last edit date', bold: true},
      {value: 'Last edited by', bold: true},
    ];
    const rows = [];
    reportModel.Details.forEach(item => {
      const row: ExcelCellData[] = [];
      row.push({value: item.Type, width: CellWidth.S});
      row.push({value: item.Code});
      row.push({value: item.Description, width: CellWidth.M});
      row.push({value: item.UnitPrice, type: CellType.CURRENCY});
      row.push({value: item.PackSize, type: CellType.NUMBER});
      row.push({value: item.PackPrice, type: CellType.CURRENCY});
      row.push({value: item.Status});
      row.push({value: moment(item.CreateDate).format(this.REPORT_DATE_TIME_FORMAT), width: CellWidth.S});
      row.push({value: item.CreatedBy, width: CellWidth.S});
      row.push({value: moment(item.LastEditDate).format(this.REPORT_DATE_TIME_FORMAT), width: CellWidth.S});
      row.push({value: item.LastEditedBy, width: CellWidth.S});
      rows.push(row);
    })
    dataTable.rows = rows;
    sheet.tables.push(dataTable);
    excelReportData.data.push(sheet);

    return excelReportData;
  }
}
