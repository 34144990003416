import { Injectable } from "@angular/core";
import { Firestore, doc, getDoc } from "@angular/fire/firestore";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { WriteBatch } from "@firebase/firestore";
import { ClaimDetailReportService } from "../../report/claim-detail-report/claim-detail-report.service";
import { InvoiceReportService } from "../../report/invoice-report/invoice-report.service";
import { AuthService } from "../auth.service";
import { FirestoreService } from "../firestore.service";
import { Account, InvoiceHelperService } from "../invoice-helper.service";
import { AbstractInvoiceCapture, DefaultInvoiceCapture, MedicalInsuranceInvoiceCapture, UnroutableInvoiceCapture } from "./invoice_capture";
import { CalendarService } from "../calendar.service";
import {Invoice, INVOICE_SUBTYPE, INVOICE_TYPE, PracticeProvider, PROVIDER_SPECIALITY} from "@meraki-flux/schema";

@Injectable()
export class InvoiceCaptureService {

  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
    private claimDetailReportService: ClaimDetailReportService,
    private invoiceReportService: InvoiceReportService,
    private invoiceHelper: InvoiceHelperService,
    private calendarService: CalendarService) {
  }

  async capture(invoice: Invoice, account: Account, postAction?: WriteBatch): Promise<boolean> {
    const invoiceCapture: AbstractInvoiceCapture = await this.getInvoiceCapture(invoice);
    return await invoiceCapture.capture(invoice, account, postAction);
  }

  async getInvoiceCapture(invoice: Invoice): Promise<AbstractInvoiceCapture> {
    if (this.isMedicalInsurance(invoice)) {
      return new MedicalInsuranceInvoiceCapture(
        this.bottomSheet, this.claimDetailReportService, this.firestore, this.authService,
        this.invoiceHelper, this.firestoreService, this.invoiceReportService, this.calendarService)
    } else if (await this.isUnroutable(invoice)) {
      return new UnroutableInvoiceCapture(this.bottomSheet, this.claimDetailReportService,
        this.firestore, this.authService, this.invoiceHelper, this.firestoreService, this.calendarService);
    } else {
      return new DefaultInvoiceCapture(this.firestore, this.authService, this.firestoreService, this.calendarService);
    }
  }

  isMedicalInsurance(invoice: Invoice) {
    return invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER;
  }

  async isUnroutable(invoice: Invoice) {
    if (invoice.Type === INVOICE_TYPE.MEDICAL_AID) {
      const routeInfo = await this.invoiceHelper.getRouteInfo(invoice.Account.SchemeCode, invoice.Account.PlanCode, invoice.Account.OptionCode);
      if (!routeInfo?.routeCode) return true;
      let speciality = PROVIDER_SPECIALITY[invoice.TreatingProvider.Speciality];
      if (!speciality) {
        const providerRef = await getDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Provider/${invoice.TreatingProvider.HPCSANumber}`));
        const provider = providerRef.data() as PracticeProvider;
        speciality = PROVIDER_SPECIALITY[provider?.Speciality];
      }

      const isRoutable = await this.invoiceHelper.isRoutable(routeInfo.routeCode, speciality);

      return !isRoutable;
    }
    return false;
  }

}
