import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Firestore, collection, collectionSnapshots, query, where } from '@angular/fire/firestore';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { InvoiceTemplateService } from '../../services/invoice-template.service';
import { LookupSelection, SearchService } from '../../services/search.service';
import { PathUtils } from '../../utils/path-utils';
import { DialogService } from '../../services/dialog.service';
import { DiagnosisEngineService } from '../../services/diagnosis-engine.service';
import { LogService } from '../../services/log.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { InvoiceRepository } from '../../repositories/invoice.repository';
import {
  DialogSearchResult, DialogType,
  Invoice, INVOICE_LINE_TYPE, INVOICE_TEMPLATE_TYPE,
  InvoiceTemplate,
  SEARCH_TYPE,
  SearchDialogData,
  SearchRequest, SearchResponse,
  SearchResult
} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
@UntilDestroy()
export class SearchDialogComponent implements OnInit, OnDestroy {
  RESULT_PAGE_LIMIT = 10;

  @ViewChild('resultPaginator') resultPaginator!: MatPaginator;

  searchResults$ = new BehaviorSubject<any[]>([]);
  totalSearchResults$ = new BehaviorSubject<number>(0);
  currentPage$ = new BehaviorSubject(0);

  searchBusy$ = new BehaviorSubject<boolean>(false);
  searchRequest$ = new BehaviorSubject<{ query: string, page: number }>({ query: '', page: 0 });

  searchResultSource = new MatTableDataSource<SearchResult>([]);
  selectedResultSource = new MatTableDataSource<SearchResult>([]);

  diagnosisEngineMessages$ = new BehaviorSubject<string[]>([]);

  searchCtrl = new UntypedFormControl('');
  PAGE_LIMIT = 10;

  providerFavouriteMap$ = new BehaviorSubject<{ id: string, code: string, order: number }[]>([]);
  providerFavourteMaxOrderNo$ = new BehaviorSubject<number>(0);

  get searchType() {
    return this.dialogData?.searchType || SEARCH_TYPE.MEDICINE;
  }

  get dialogHeader() {
    switch (this.searchType) {
      case SEARCH_TYPE.MEDICINE:
        return 'Medicine';
      case SEARCH_TYPE.CONSUMABLE:
        return 'Consumable';
      case SEARCH_TYPE.DIAGNOSIS:
        return 'Diagnosis';
      case SEARCH_TYPE.PROCEDURE:
        return 'Procedure';
      default:
        return 'Unknown';
    }
  }

  get pluralTypeName() {
    switch (this.searchType) {
      case SEARCH_TYPE.MEDICINE:
        return 'medicines';
      case SEARCH_TYPE.CONSUMABLE:
        return 'consumables';
      case SEARCH_TYPE.DIAGNOSIS:
        return 'diagnoses';
      case SEARCH_TYPE.PROCEDURE:
        return 'procedures';
      default:
        return 'unknown'
    }
  }

  get codeColumnHeader() {
    switch (this.searchType) {
      case SEARCH_TYPE.MEDICINE:
      case SEARCH_TYPE.CONSUMABLE:
        return 'NAPPI Code';
      case SEARCH_TYPE.DIAGNOSIS:
        return 'ICD10';
      case SEARCH_TYPE.PROCEDURE:
        return 'Tariff Code';
      default:
        return 'Code';
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SearchDialogData,
    private dialogRef: MatDialogRef<SearchDialogComponent>,
    private changeRef: ChangeDetectorRef,
    private searchService: SearchService,
    private firestore: Firestore,
    private authService: AuthService,
    private templateService: InvoiceTemplateService,
    private dialogService: DialogService,
    private diagnosisEngine: DiagnosisEngineService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public invoiceRepository: InvoiceRepository,
    private invoiceTemplateService: InvoiceTemplateService,
    private logger: LogService
  ) {
  }

  ngOnDestroy(): void {
    return;
  }

  ngOnInit(): void {
    this.selectedResultSource = new MatTableDataSource(this.dialogData.preselectedData ? [...this.dialogData.preselectedData] : []);
    this.runDiagnosisEngine(this.selectedResultSource.data.map(d => d.code));

    this.searchRequest$.pipe(
      filter(req => !!req.query),
      debounceTime(500),
      tap(async (req: SearchRequest) => {
        await this.resolveSearch(req);
      }),
      untilDestroyed(this)
    ).subscribe();

    this.searchCtrl.valueChanges.pipe(
      filter(val => !!val),
      tap((query) => this.searchRequest$.next({ query, page: 0 })),
      untilDestroyed(this)
    ).subscribe();

    // Read provider templates to manage favourites
    if (this.dialogData.selectedProviderId) {
      collectionSnapshots(
        query(
          collection(this.firestore, PathUtils.providerTemplatesPath(this.authService.selectedBPN, this.dialogData.selectedProviderId)),
          where('Type', '==', this.searchType), where('IsActive', '==', true), where('IsGroup', '==', false))).pipe(
            map(res => res.map(t => ({ Id: t.id, ...t.data() } as InvoiceTemplate))),
            tap(templates => {
              let result = [];
              // We only care about the templates with 1 code on them
              switch (this.searchType) {
                case SEARCH_TYPE.DIAGNOSIS:
                  result = templates.filter(t => t.Diagnosis?.length === 1)?.map(t => ({ id: t.Id, code: t.Diagnosis[0].Code, order: t.SortOrder || 0 }))?.filter(d => !!d.code);
                  break;
                case SEARCH_TYPE.MEDICINE:
                case SEARCH_TYPE.CONSUMABLE:
                  result = templates.filter(t => t.Lines?.length === 1)?.map(t => ({ id: t.Id, code: t.Lines[0].NappiCode, order: t.SortOrder || 0 }))?.filter(l => !!l.code);
                  break;
                case SEARCH_TYPE.PROCEDURE:
                  result = templates.filter(t => t.Lines?.length === 1)?.map(t => ({ id: t.Id, code: t.Lines[0].ChargeCode, order: t.SortOrder || 0 }))?.filter(l => !!l.code);
                  break;
              }
              const maxOrder = Math.max(...result?.filter(res => res.order >= 0).map(res => +res.order) || [0])
              this.providerFavouriteMap$.next(result);
              this.providerFavourteMaxOrderNo$.next(maxOrder);
            }),
            untilDestroyed(this)
          ).subscribe();
    }

  }

  onClearSearch() {
    this.searchCtrl.setValue('', { emitEvent: false });
    this.totalSearchResults$.next(0);
    this.currentPage$.next(0);
    this.searchResultSource = new MatTableDataSource();
  }

  private async runDiagnosisEngine(codes: string[]) {
    if(this.dialogData.searchType !== SEARCH_TYPE.DIAGNOSIS) return;

    let diagResult = [];
    if(this.dialogData.patient) {
      diagResult = await this.diagnosisEngine.checkDiagnosisRules(null, codes, this.dialogData.patient);
    }
    this.diagnosisEngineMessages$.next(diagResult || []);
  }

  private async addFavourite(result: SearchResult) {
    this.googleAnalyticsService.logEvent('CAPTUREINVOICE_CREATE_FAVOURITE', { PracticeId: this.authService.selectedBPN });
      const invoice: Invoice = this.invoiceRepository.getActiveInvoice();

    if (!invoice.TreatingProvider?.Id) {
      this.dialogService.showSnackbar('Please select a treating provider before creating a template.');
      return;
    }

    const baseTemplate = this.createFavouriteTemplate(this.getInvoiceTemplateType(this.searchType), result.code, result.description)
    const templateType = this.getInvoiceTemplateType(this.searchType);

    const dialogResult = await this.invoiceTemplateService.showDialog({
      type: templateType,
      invoiceTemplate: baseTemplate,
      isFavourite: true
    }).closed.toPromise();

    if (dialogResult) {
      const newTemplate = dialogResult as InvoiceTemplate;
      newTemplate.Type = templateType;

      try {
        await this.invoiceTemplateService.addTemplate(newTemplate, invoice.TreatingProvider.HPCSANumber);
        this.dialogService.showSnackbar(`Template '${newTemplate.Caption ?? newTemplate.Description}' created.`)
      } catch (err) {
        this.logger.error(err);
        this.dialogService.showSnackbar('Could not save template.');
      }
    }
  }

  private async removeFavourite(code: string) {
    if(!this.dialogData.selectedProviderId || !code) return;

    const templateMap = this.providerFavouriteMap$.getValue().find(t => t.code === code);
    if(templateMap) {
      try {
        await this.templateService.removeFavourite(templateMap.id, this.dialogData.selectedProviderId);
      } catch(err) {
        this.dialogService.showErrorMessage('Failed to remove favourite');
        this.logger.error(`Failed to remove favourite. ${err}`);
      }
    }
  }

  async onFavouriteClicked(result: SearchResult) {
    if(!result || !this.dialogData.selectedProviderId) return;
    if(this.isFavourite(result.code)) {
      await this.removeFavourite(result.code);
    } else {
      await this.addFavourite(result);
    }
  }

  onSelectResult(result: SearchResult) {
    if (this.dialogData.allowMultiSelect && this.dialogData.searchType === SEARCH_TYPE.DIAGNOSIS) {
      if (!this.selectedResultSource.data.find(res => res.code === result.code)) {
        this.selectedResultSource = new MatTableDataSource([...this.selectedResultSource.data, result]);
        this.runDiagnosisEngine(this.selectedResultSource.data.map(d => d.code));
        this.changeRef.detectChanges();
      }
    } else {
      const response: DialogSearchResult = {
        pageResults: [result],
        addFavourite: false
      }
      this.dialogRef.close(response);
    }
  }

  onRemoveSelectedResult(result: SearchResult) {
    const diagIndex = this.selectedResultSource.data.findIndex(res => res.code === result.code);
    if (diagIndex >= 0) {
      const data = this.selectedResultSource.data;
      data.splice(diagIndex, 1);
      this.selectedResultSource = new MatTableDataSource(data)
      this.runDiagnosisEngine(this.selectedResultSource.data.map(d => d.code));
      this.changeRef.detectChanges();
    }
  }

  onSave() {
    const diagnosisErrors = this.diagnosisEngineMessages$?.getValue() || [];
    if(diagnosisErrors.length > 0) {
      const errorList = diagnosisErrors.map(err => `<li>${err}</li>`).join('\n');
      this.dialogService.showDialog({
        type: DialogType.ERROR,
        title: 'Invalid diagnosis codes',
        message: `
          <ul>
            <li>Please fix the following codes before saving:</li>
            ${errorList}
          </ul>
        `
      });
      return;
    }

    this.dialogRef.close(this.selectedResultSource.data);
  }

  async onPageChanged(e: PageEvent) {
    const query = this.searchCtrl.value;
    this.currentPage$.next(e.pageIndex);
    this.searchRequest$.next({ query, page: e.pageIndex });
  }

  async resolveSearch(req: SearchRequest) {
    this.searchBusy$.next(true);
    try {
      let res: SearchResponse;
      switch (this.searchType) {
        case SEARCH_TYPE.MEDICINE:
          res = await this.searchMedicinesConsumables(req.query, INVOICE_LINE_TYPE.MEDICINE, req.page);
          break;
        case SEARCH_TYPE.CONSUMABLE:
          res = await this.searchMedicinesConsumables(req.query, INVOICE_LINE_TYPE.CONSUMABLE, req.page);
          break;
        case SEARCH_TYPE.DIAGNOSIS:
          res = await this.searchDiagnoses(req.query, req.page);
          break;
        case SEARCH_TYPE.PROCEDURE:
          res = await this.searchProcedures(req.query, req.page);
          break;
      }

      this.totalSearchResults$.next(res.totalCount);
      this.searchResultSource = new MatTableDataSource(res.pageResults);

    } catch (err) {
      this.logger.info(`Error while searching`, err);
    }
    finally {
      this.searchBusy$.next(false);
    }
  }

  getAddTooltip(isSelected: boolean) {
    if (isSelected) {
      return 'Already selected';
    }

    if (this.dialogData.allowMultiSelect) {
      return 'Add to selection list';
    }

    return 'Select';
  }

  getInvoiceTemplateType(searchType: SEARCH_TYPE) {
    switch (searchType) {
      case SEARCH_TYPE.CONSUMABLE: return INVOICE_TEMPLATE_TYPE.CONSUMABLE;
      case SEARCH_TYPE.DIAGNOSIS: return INVOICE_TEMPLATE_TYPE.DIAGNOSIS;
      case SEARCH_TYPE.MEDICINE: return INVOICE_TEMPLATE_TYPE.MEDICINE;
      case SEARCH_TYPE.PROCEDURE: return INVOICE_TEMPLATE_TYPE.PROCEDURE;
      default: return INVOICE_TEMPLATE_TYPE.INVOICE;
    }
  }

  private createFavouriteTemplate(type: INVOICE_TEMPLATE_TYPE, code: string, description: string): InvoiceTemplate {
    const baseTemplate = {
      Caption: description,
      CreatedAt: new Date(),
      CreatedBy: this.authService.uid,
      IsActive: true,
      IsGroup: false,
      SortOrder: 0,
      Type: type
    } as InvoiceTemplate;

    switch (type) {
      case INVOICE_TEMPLATE_TYPE.DIAGNOSIS: {
        baseTemplate.Diagnosis = [
          {
            Code: code || '',
            Description: description || '',
            OrderNo: 0
          }
        ];
      }
        break;
      case INVOICE_TEMPLATE_TYPE.CONSUMABLE:
      case INVOICE_TEMPLATE_TYPE.MEDICINE: {
        baseTemplate.Lines = [
          {
            LineType: type === INVOICE_TEMPLATE_TYPE.MEDICINE ? INVOICE_LINE_TYPE.MEDICINE : INVOICE_LINE_TYPE.CONSUMABLE,
            NappiCode: code,
            Description: description
          }
        ];
      }
        break;
      case INVOICE_TEMPLATE_TYPE.PROCEDURE: {
        baseTemplate.Lines = [
          {
            ChargeCode: code,
            Description: description,
            LineType: INVOICE_LINE_TYPE.PROCEDURE
          }
        ]
      }
        break;

    }
    return baseTemplate;
  }

  isFavourite(code: string) {
    return this.providerFavouriteMap$.getValue().find(fav => fav.code === code);
  }

  isCodeSelected(code: string) {
    return !!this.selectedResultSource?.data?.some(d => d.code === code);
  }

  private async searchMedicinesConsumables(query: string, searchType: INVOICE_LINE_TYPE, page: number = 0): Promise<SearchResponse> {
    const searchRes = await this.searchService.searchMedicinesAutocomplete(query, searchType, this.PAGE_LIMIT, page);
    const pageResults = orderBy(searchRes.map((h: LookupSelection) => {
      const displayCode = this.formatHighlightedValue(h.Code);
      const displayDescription = this.formatHighlightedValue(h.DisplayString);
      return {
        code: h.Code,
        description: h.Description,
        displayCode,
        displayDescription,
        additionalData: h.Data
      } as SearchResult;
    }), ['code'], ['asc']);

    return { pageResults, totalCount: this.searchService.totalCount || pageResults.length };
  }

  private async searchProcedures(query: string, page: number = 0): Promise<SearchResponse> {
    const searchRes: any[] = await this.searchService.searchProcedures(query, undefined, '10', page, this.RESULT_PAGE_LIMIT);
    const pageResults = orderBy(searchRes.map(res => ({
      code: res.Code,
      description: res.Description,
      displayCode: res.Code,
      displayDescription: res.Description
    })));

    return { pageResults, totalCount: pageResults.length };
  }

  private async searchDiagnoses(query: string, page: number = 0): Promise<SearchResponse> {
    const searchRes = await this.searchService.searchDiagnosis(query, this.RESULT_PAGE_LIMIT, page);
    const pageResults = orderBy(searchRes.hits.map((h: any) => {
      const displayCode = this.formatHighlightedValue(h._highlightResult?.data?.ICD10Code?.value || h.data?.ICD10Code);
      const displayDescription = this.formatHighlightedValue(h._highlightResult?.data?.ICD10CodeDescription?.value || h.data?.ICD10CodeDescription);
      return {
        code: h.data.ICD10Code,
        description: h.data.ICD10CodeDescription,
        displayCode,
        displayDescription
      }
    }), ['code'], ['asc']);

    return { pageResults, totalCount: searchRes?.nbHits || pageResults.length };
  }

  private formatHighlightedValue(value: string) {
    return value.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>');
  }
}
