import moment = require("moment");
import {trim} from "lodash";
import {
  BASE64_DATA,
  CellType,
  ClaimDetailDataTable,
  FooterBankDetails,
  HAlignment,
  PDFCellData,
  ReportFooter,
  ReportHeader,
  ReportICDLine,
  ReportSnapscan,
  ReportTable,
  ReportTableLegend,
  RowHeaderData,
  StatementAges,
  StatementSummary,
  TableBorders,
  TableHeader
} from '@meraki-flux/schema';
import { FormatUtils } from '../../utils/format-utils';

/*
  Helper module to generate some common pdfmake structures.
*/
export module PDFMakeHelper {
  export function generateDocDefinition(reportName: string, content: any[], mainHeader?: string, pageOrientation?: string, genDate?: string, footerText?: string, hbLogo?: boolean) {
    return {
      content,
      info: {
        title: reportName,
      },
      defaultStyle: {
        font: "Helvetica",
        fontSize: 9,
      },
      pageOrientation: pageOrientation??"portrait",
      pageMargins: [15, 30, 15, 35],
      footer: function(currentPage: any, pageCount: any) {
        return hbFooter(currentPage, pageCount, hbLogo, footerText, pageOrientation);
      },
      header: function(currentPage: any, pageCount: any) {
        return hbHeader(currentPage, pageCount, mainHeader, genDate);
      },
    };
  }

// #region Tables

  /**
   * Generate a basic table. colHeaders and row lengths must match.
   * @param  {string[]} colHeaders Column headers
   * @param  {any[][]} rows Array of Rows. Each row is an array of values for columns in the row.
   */
  export function generateBasicTable(colHeaders: string[], rows: any[][]) {
    const table: any = {
      body: [],
    };

    if (colHeaders.length > 0) {
      table.headerRows = 1;
      const headers = colHeaders.map((header) =>
        generateBasicTableCell(header, true)
      );
      table.body.push(headers);
    }

    const rowList = rows.map((row) =>
      row.map((val) => generateBasicTableCell(val, false))
    );

    table.body.push(...rowList);

    return {
      margin: [0, 10, 0, 10],
      table,
    };
  }

  function generateBasicTableCell(value: string, header?: boolean) {
    return {
      margin: [2, 2, 2, 2],
      borderColor: ["lightgrey", "lightgrey", "lightgrey", "lightgrey"],
      text: value,
      bold: header,
    };
  }

  function generateValueCell(cellValue: string) {
    return {
      text: cellValue,
      borderColor: ["lightgrey", "lightgrey", "lightgrey", "lightgrey"],
      margin: [3, 3, 3, 3],
    };
  }

  function generateLabelCell(cellValue: string) {
    return {
      text: cellValue,
      bold: true,
      border: [false, false, false, false],
      margin: [3, 3, 3, 3],
    };
  }

  function mapDisplayData(label: string, value: string) {
    return [generateLabelCell(label), generateValueCell(value)];
  }
// #endregion

// #region Text

  export function generatePageName(text: string, color?: string, date?: string) {
    return {
      columns: [
        {
          text: text,
          margin: [0, 0, 0, 10],
          style: {
            bold: true,
            color: color,
            fontSize: 14,
          },
        },
        {
          text: date ? date : "",
          style: {
            alignment: "right",
            italics: true,
          },
        },
      ],
    };
  }

  export function generateItalicFilledHeader(text: string) {
    return {
      layout: "noBorders",
      table: {
        widths: ["100%"],
        body: [
          [
            {
              text,
              bold: true,
              italics: true,
              fillColor: "#ececec",
              margin: [3, 3],
            },
          ],
        ],
      },
    };
  }

  export function generatePageBreak() {
    return {
      layout: "noBorders",
      table: {
        widths: ["100%"],
        body: [
          [
            {
              text: "",
              pageBreak: "before",
            },
          ],
        ],
      },
    };
  }

  /**
   * Generate a large bold label
   * @param  {string} text The label text.
   */
  export function generatePageHeader(text: string) {
    return {
      text: text,
      bold: true,
      margin: [0, 0, 0, 10],
      fontSize: 12,
    };
  }

  /**
   * Generate a bold subsection header with standard font size.
   * @param  {string} text The label text.
   */
  export function generateSubSectionHeader(text: string, fontSize?: number, color?: string) {
    return {
      text,
      bold: true,
      style: {
        fontSize: fontSize ? fontSize : undefined,
        color: color ? color : undefined,
      },
    };
  }

  export function generateMainHeader(header: string, generatedDate?: string) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 0, 0, 10],
      table: {
        widths: ["*"],
        body: [],
      },
    };
    if (header) {
      result.table.body.push([
        {
          columns: [
            generateColumn("50%", header, [10, 10], undefined, undefined, undefined, undefined, "grey"),
            generateColumn("*", `${generatedDate}`, [10, 10], "right", undefined, undefined, true, "grey"),
          ],
        },
      ]);
    }
    return result;
  }

  export function generateReportHeader(header: ReportHeader, generatedDate?: string) {
    let i = 0;
    const result: any = {
      layout: "noBorders",
      margin: [0, 0, 0, 10],
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [],
      },
    };
    if (header.name) {
      result.table.body.push([
        {
          columns: [
            generateColumn("40%", header.name, [0, 10], undefined, true, 11, undefined, header.nameColor),
            generateColumn("20%", header.caption??"", [0, 10], "center", true, 11, undefined, header.captionColor),
            header.includeGeneratedDate ? generateColumn("*", `${generatedDate}`, [3, 10], "right", undefined, undefined, true) : {},
          ],
        },
      ]);
    }
    if (
      (header.left && header.left.length > 0) ||
        (header.right && header.right.length)
    ) {
      if (header.outlined) {
        result.layout = {
          hLineWidth: function(i: any, node: any) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function(i: any, node: any) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          hLineColor: function() {
            return header.outlineColor??"black";
          },
          vLineColor: function() {
            return header.outlineColor??"black";
          },
        };
      }
      while (i >= 0) {
        if (
          (header.left && header.left.length > i) ||
            (header.right && header.right.length > i)
        ) {
          result.table.body.push([
            {
              columns: [
                generateColumn(
                    header.left && header.left.length > i && header.left[i].name ?
                        "auto" :
                        "0%",
                    header.left && header.left.length > i && header.left[i].name ?
                        header.left[i].name + ":" :
                        "",
                    1,
                    "left"
                ),
                generateColumn(
                    "*",
                    header.left && header.left.length > i && header.left[i].data ?
                        format(header.left[i].data) :
                        "",
                    1,
                    "left",
                    header.left &&
                    header.left.length > i &&
                    header.left[i].data &&
                    header.left[i].data?.decoration &&
                    header.left[i].data?.decoration?.bold ?
                        true :
                        false
                ),
                generateColumn("*", ""),
                generateColumn(
                    "auto",
                    header.right &&
                    header.right.length > i &&
                    header.right[i].name ?
                        header.right[i].name + ":" :
                        "",
                    1,
                    "right"
                ),
                generateColumn(
                    "auto",
                    header.right &&
                    header.right.length > i &&
                    header.right[i].data ?
                        format(header.right[i].data) :
                        "",
                    1,
                    "left",
                    header.right &&
                    header.right.length > i &&
                    header.right[i].data &&
                    header.right[i].data?.decoration &&
                    header.right[i].data?.decoration?.bold ?
                        true :
                        false
                ),
              ],
            },
          ]);
          i++;
        } else {
          i = -1;
        }
      }
    }
    return result;
  }

  export function generateTableName(text: string, color?: string, underline?: boolean, underlineColor?: string, pageBreak: boolean = false) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 10, 0, 0],
      table: {
        widths: "100%",
        body: [[
          {
            text: text,
            style: {
              color: color,
            },
            bold: true,
            fontSize: 10,
            pageBreak: pageBreak ? "before" : "",
          },
        ]],
      },
    };
    if (underline) {
      result.layout = {
        hLineWidth: function(i: any, node: any) {
          return (i === node.table.body.length) ? 1 : 0;
        },
        hLineColor: function() {
          return underlineColor??"black";
        },
        vLineWidth: function(i: any) {
          return 0;
        },
        paddingLeft: function(i: any) {
          return 0;
        },
        paddingRight: function(i: any, node: any) {
          return 0;
        },
      };
    }
    return result;
  }

  export function generateTableSection(table: ReportTable) {
    const allContent = [];
    if (table.name) {
      allContent.push(generateTableName(table.name, table.nameColor, table.nameUnderline, table.nameUnderlineColor, table.newPage));
    }
    if (table.headers) {
      table.headers.forEach((header) => {
        allContent.push(generateTableHeader(header));
      });
    }
    if (table.reportICDLine) {
      allContent.push(generateStatementICDLine(table.reportICDLine));
    }
    allContent.push(generateTable(table.rowHeaders, table.rowHeadersBold, table.rows, table.rowHeadersBGColor, table.borders, table.noRecordsText));
    if (table.legend) {
      allContent.push(generateTableLegend(table.legend));
    }
    return allContent;
  }

  export function generateTableHeader(header: TableHeader) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 5, 0, 5],
      style: {
        fillColor: header.backgroundColor,
      },
      table: {
        widths: "100%",
        body: [],
      },
    };
    header.rows?.map((row) => {
      const headerRow: any = [];
      const headerColumns: any = {
        columns: [],
      };
      const column: any = {
        text: [],
        width: "*",
        margin: [3, 1, 3, 1],
      };
      row.map((cell, index) => {
        column.text.push({text: cell.name ? `${cell.name}: ` : ""});
        column.text.push({
          text: cell.data?.value ? `${cell.data.value}` : "",
          bold: cell.data?.decoration?.bold ?? false,
          italics: cell.data?.decoration?.italics ?? false,
        });
        if (cell.name || cell.data?.value) {
          column.text.push({text: "    "});
        }
      });
      if (column.text.length !== 0) {
        headerColumns.columns.push(column);
        headerRow.push(headerColumns);
        result.table.body.push(headerRow);
      }
    });
    if (header.outlined) {
      result.layout = {
        hLineWidth: function(i: any, node: any) {
          return (i === node.table.body.length) ? 0.5 : 0;
        },
        hLineColor: function() {
          return header.outlineColor ? header.outlineColor : "black";
        },
        vLineWidth: function(i: any) {
          return 0;
        },
        paddingLeft: function(i: any) {
          return 0;
        },
        paddingRight: function(i: any, node: any) {
          return 0;
        },
      };
    }
    return result;
  }

  export function generateClaimDetailsDataTable(claimDetailsData: ClaimDetailDataTable) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 10],
      table: {
        widths: ["25%", "25%", "25%", "25%"],
        body: [],
      },
    };
    claimDetailsData.rows.forEach((row) => {
      const line = [];
      pushGreyText(line, row.header1);
      pushText(line, row.value1);
      pushGreyText(line, row.header2);
      pushText(line, row.value2);
      result.table.body.push(line);
    });
    return result;
  }

  function pushText(line: any[], text: PDFCellData) {
    line.push({text: format(text), margin: [0, 3]});
  }

  function pushGreyText(line: any[], text: string) {
    line.push({text: text, margin: [0, 3], style: {color: "grey"}});
  }

  export function generateStatementICDLine(icdLine: ReportICDLine) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 5],
      table: {
        widths: "100%",
        body: [],
      },
    };
    const headerRow: any = [];
    const headerColumns: any = {
      columns: [],
    };
    headerColumns.columns.push({
      text: icdLine.icdCode,
      width: "*",
      margin: [3, 3, 3, 3],
    });
    headerColumns.columns.push({
      text: icdLine.status??"",
      width: "*",
      margin: [3, 3, 3, 3],
      italics: true,
      style: {
        alignment: "right",
      },
    });
    headerRow.push(headerColumns);
    result.table.body.push(headerRow);
    if (icdLine.outlined) {
      result.layout = {
        hLineWidth: function(i: any, node: any) {
          return (i === node.table.body.length) ? 0.5 : 0;
        },
        hLineColor: function() {
          return icdLine.outlineColor ? icdLine.outlineColor : "black";
        },
        vLineWidth: function(i: any) {
          return 0;
        },
        paddingLeft: function(i: any) {
          return 0;
        },
        paddingRight: function(i: any, node: any) {
          return 0;
        },
      };
    }
    return result;
  }

  export function generateTableLegend(footer: ReportTableLegend) {
    const result: any = {
      layout: "noBorders",
      margin: [0, 5],
      table: {
        widths: "100%",
        body: [],
      },
    };
    const headerRow: any = [];
    const headerColumns: any = {
      columns: [],
    };
    headerColumns.columns.push({
      text: footer.text,
      width: "*",
      margin: [3, footer.marginTop || 0, 3, 3],
      italics: footer?.italics || false,
    });
    headerRow.push(headerColumns);
    result.table.body.push(headerRow);
    result.layout = {
      hLineWidth: (i: any, node: any) => 0,
      hLineColor: () => 0,
      vLineWidth: () => 0,
      paddingLeft: (i: any) => 0,
      paddingRight: (i: any, node: any) => 0,
    };
    return result;
  }

  export function generateSimpleBorderedTable(headers?: PDFCellData[], rows?: PDFCellData[][]) {
    const result: any = {
      table: {
        headerRows: 1,
        body: [],
      },
      margin: [3, 10],
    };
    if (headers) {
      const widths = headers.map(() => {
        return "auto";
      });
      result.table.widths = widths;
      const tHeaders = headers.map((header) => {
        return generateTableDataCell(header);
      });
      result.table.body.push(tHeaders);
    }
    if (rows) {
      if (result.table.widths === undefined) {
        const widths = rows[0].map(() => {
          return "auto";
        });
        result.table.widths = widths;
      }
      const tData = rows.map((row) => {
        return row.map((data, i) => {
          return generateColumn(undefined, data.value, [2, 2, 2, 2], undefined, undefined, undefined, undefined, i === 0 ? "grey": undefined, undefined, ["lightgrey", "lightgrey", "lightgrey", "lightgrey"]);
        });
      });
      result.table.body.push(...tData);
    }
    return result;
  }

  export function generateTable(headers?: RowHeaderData[], headersBold?: boolean, rows?: PDFCellData[][], bgHeadersColor?: string, borders?: TableBorders, noRecorsText?: PDFCellData) {
    if (!rows && !headers) {
      if (noRecorsText) {
        return generateColumn("auto", noRecorsText.value, [3, 0], undefined, noRecorsText.decoration?.bold, undefined, noRecorsText.decoration?.italics);
      }
      return {};
    }
    const result: any = {
      table: {
        headerRows: 1,
        body: [],
        widths: "100%",
      },
    };
    if (headers) {
      const widths = headers.map((header) => {
        return header.width || "auto";
      });
      result.table.widths = widths;
      const tHeaders = headers.map((header) => {
        return generateTableDataCell(header, bgHeadersColor, headersBold);
      });
      result.table.body.push(tHeaders);
    }
    if (rows) {
      if (result.table.widths === undefined) {
        const widths = rows[0].map(() => {
          return "auto";
        });
        result.table.widths = widths;
      }
      const tData = rows.map((row) => {
        return row.map((data) => {
          return generateTableDataCell(data, undefined, data.decoration?.bold, data.decoration?.italics, data.decoration?.hAlignment);
        });
      });
      result.table.body.push(...tData);
    }
    if (borders) {
      result.layout = {
        hLineWidth: function(i: any, node: any) {
          if (!borders.hor) {
            return 0;
          }
          if (!borders.outerborder) {
            if (i === 0 || i === node.table.body.length) {
              return 0;
            }
          }
          if (i === 0 || i === 1 || i === node.table.body.length) {
            return borders.headerBorderSize;
          }
          return borders.rowBorderSize??0;
        },
        vLineWidth: function(i: any, node: any) {
          return borders.ver ? 1 : 0;
        },
      };
    }
    return result;
  }

  export function generateParagraphs(text: string[]) {
    const all = [];
    text.forEach((t, i) => {
      const result = {text: [], margin: [3, i === 0 ? 10 : 5]};
      result.text.push({text: t});
      return all.push(result);
    });
    return all;
  }

  export function generateParagrpaphs(text: string[]) {
    const all = [];
    text.forEach((t, i) => {
      const result = {text: [], margin: [3, i === 0 ? 10 : 5]};
      result.text.push({ text: t });
      return all.push(result)
    });
    return all;
  }

  function generateTableDataCell(value: PDFCellData, bgColor?: string, bold?: boolean, italics?: boolean, hAlignment?: HAlignment) {
    return {
      margin: [2 + value.leftMargin ? value.leftMargin : 0, 2 + value.topMargin ? value.topMargin : 0, 2, 2],
      borderColor: ["lightgrey", "lightgrey", "lightgrey", "lightgrey"],
      fillColor: bgColor ? bgColor : "",
      text: [
        value.value || value.value === 0 ? format(value) : "",
        {text: value.superscript ? ` ${value.superscript}` : "", sup: true},
      ],
      style: hAlignment? hAlignment: align(value),
      bold: bold,
      italics: italics,
    };
  }

  export function format(value?: PDFCellData) {
    if (CellType.CURRENCY === value?.type) {
      return FormatUtils.formatCents(value?.value);
    }
    if (CellType.DATE === value?.type) {
      const format = "DD/MM/yyyy";
      let dateString = "";
      if (isValidDate(new Date(value.value))) {
        dateString = moment(new Date(value.value)).format(format);
      } else if (isValidTimestamp(value)) {
        dateString = moment(new Date(value.value.seconds * 1000)).format(format);
      }
      return dateString;
    }
    return value?.value;
  }


  function isValidDate(date) {
    return (
      date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
    );
  }

  function isValidTimestamp(date) {
    return date && typeof date.seconds === "number";
  }

  export function formatCurrency(value?: any) {
    return "R " + Number(value / 100).toFixed(2);
  }

  export function align(value: PDFCellData) {
    if (CellType.CURRENCY === value.type || CellType.NUMBER === value.type) {
      return {alignment: "right"};
    }
    return undefined;
  }

  export function generateColumn(
      width: string,
      text: string,
      margin?: number | number[],
      alignment?: string,
      bold?: boolean,
      fontSize?: number,
      italics?: boolean,
      fontColor?: string,
      underline?: string,
      borderColor?: string[]
  ) {
    return {
      width: width,
      text: text,
      margin: margin,
      style: {
        alignment: alignment,
        fontSize: fontSize,
        color: fontColor,
        decoration: underline,

      },
      bold: bold,
      italics: italics,
      borderColor: borderColor,
    };
  }

  export function generateStatementSummary(statementSummary: StatementSummary) {
    const result: any = {
      layout: {
        hLineWidth: function(i: any, node: any) {
          return i === 0 || i === node.table.body.length ? 1 : 0;
        },
        vLineWidth: function(i: any, node: any) {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineColor: function() {
          return "#000000";
        },
        vLineColor: function() {
          return "#000000";
        },
      },
      margin: [0, 10],
      table: {
        widths: ["*"],
        body: [],
      },
    };
    result.table.body.push([
      {
        columns: [
          generateColumn("50%", "SUMMARY", [3, 3], undefined, true, 12, undefined, "#000000"),
          generateColumn("25%", "Due by patient", [3, 3], "right"),
          generateColumn("25%", formatCurrency(statementSummary.patientLiable), [3, 3], "right", true),
        ],
      },
    ]);
    if (statementSummary.medicalAidLiable) {
      result.table.body.push([
        {
          columns: [
            generateColumn("50%", "", [3, 3]),
            generateColumn("25%", "Due by med. aid", [3, 3], "right"),
            generateColumn("25%", formatCurrency(statementSummary.medicalAidLiable), [3, 3], "right"),
          ],
        },
      ]);
      result.table.body.push([
        {
          columns: [
            generateColumn("50%", "", [3, 3]),
            generateColumn("25%", "Balance", [3, 3], "right"),
            generateColumn("25%", formatCurrency(statementSummary.balance), [3, 3], "right", true),
          ],
        },
      ]);
    }
    return result;
  }

  export function generateStatementAges(statementAges: StatementAges) {
    const result: any = {
      layout: {
        hLineWidth: function(i: any, node: any) {
          return i === 0 || i === node.table.body.length ? 1 : 0;
        },
        vLineWidth: function(i: any, node: any) {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineColor: function() {
          return "#000000";
        },
        vLineColor: function() {
          return "#000000";
        },
      },
      margin: [0, 10],
      table: {
        widths: ["*"],
        body: [],
      },
    };
    const hColumns = [];
    if (statementAges.unallocated) {
      hColumns.push(generateColumn("*", "Unallocated", [3, 0], "center", undefined, undefined, undefined, "grey"));
    }
    hColumns.push(generateColumn("*", "120+ days", [3, 0], "center", undefined, undefined, undefined, "grey"));
    hColumns.push(generateColumn("*", "90 - 120 days", [3, 0], "center", undefined, undefined, undefined, "grey"));
    hColumns.push(generateColumn("*", "60 - 90 days", [3, 0], "center", undefined, undefined, undefined, "grey"));
    hColumns.push(generateColumn("*", "30 - 60 days", [3, 0], "center", undefined, undefined, undefined, "grey"));
    hColumns.push(generateColumn("*", "0 - 30 days", [3, 0], "center", undefined, undefined, undefined, "grey"));
    result.table.body.push([{
      columns: hColumns,
    }]);
    const rColumns = [];
    if (statementAges.unallocated) {
      rColumns.push(generateColumn("*", formatCurrency(statementAges.unallocated), [3, 0], "center", true, undefined, undefined, "#000000"));
    }
    rColumns.push(generateColumn("*", formatCurrency(statementAges.d120), [3, 0], "center", true, undefined, undefined, "#000000"));
    rColumns.push(generateColumn("*", formatCurrency(statementAges.d90_120), [3, 0], "center", true, undefined, undefined, "#000000"));
    rColumns.push(generateColumn("*", formatCurrency(statementAges.d60_90), [3, 0], "center", true, undefined, undefined, "#000000"));
    rColumns.push(generateColumn("*", formatCurrency(statementAges.d30_60), [3, 0], "center", true, undefined, undefined, "#000000"));
    rColumns.push(generateColumn("*", formatCurrency(statementAges.d0_30), [3, 0], "center", true, undefined, undefined, "#000000"));
    result.table.body.push([{
      columns: rColumns,
    }]);
    return result;
  }

  export function generateBottomMessage(statementMessage: string) {
    return {
      text: statementMessage,
      width: "100%",
      margin: [3, 10],
    };
  }

  /**
   * Generate a bold full page width section header with standard font size
   * and a grey background.
   * @param  {string} text The label text.
   */
  export function generateSectionHeader(text: string) {
    return {
      layout: "noBorders",
      table: {
        widths: ["100%"],
        body: [
          [
            {
              text,
              bold: true,
              fillColor: "lightgrey",
              margin: [3, 3, 3, 3],
            },
          ],
        ],
      },
    };
  }

  /**
   * Generate a full page width text field with a label
   * @param  {string} label The field label
   * @param  {string} value The field text
   */
  export function generateDisplayField(label: string, value: string) {
    return [
      {
        margin: [0, 10],
        layout: "noBorders",
        table: {
          widths: [70, "auto"],
          body: [
            [
              {
                text: label,
                bold: true,
                margin: [3, 3, 3, 3],
              },
              {
                text: value,
                margin: [3, 3, 3, 3],
              },
            ],
          ],
        },
      },
    ];
  }
// #endregion

// #region Layouts

  /**
   * Generate a split data display section with 2 columns of
   * label-value fields. The value will fill the remaining width
   * of the column. If no col2 is provided, col1 will fill the page width.
   * @param  {Map<string,string>} col1 Map of labels/values for first column
   * @param  {Map<string,string>} col2 Map of labels/values for second column
   * @param  {string?} header If provided, a section header will also be generated.
   */
  export function generateSplitDataDisplaySection(
      col1: Map<string, string>,
      col2?: Map<string, string>,
      header?: string
  ) {
    const result = [];
    header && result.push(generateSectionHeader(header));

    if (col2 && col2.size > 0) {
      result.push({
        margin: [0, 3, 0, 10],
        columns: [
          {
            width: "50%",
            table: generateDisplayTable(col1),
          },
          {
            width: "50%",
            table: generateDisplayTable(col2),
          },
        ],
      });
    } else {
      result.push(generateDisplayTable(col1));
    }

    return result;
  }

  /**
   * Generate a full page width data display section with label-value fields.
   * @param  {Map<string,string>} data Map of labels/values to display
   * @param  {string?} header If provided, a section header will also be generated.
   */
  export function generateDataDisplaySection(
      data: Map<string, string>,
      header?: string
  ) {
    const result = [];
    header && result.push(generateSectionHeader(header));

    result.push({
      margin: [0, 3, 0, 10],
      table: generateDisplayTable(data),
    });

    return result;
  }

  export function generateFooterTable(footer?: ReportFooter) {
    if (footer?.infoTable) {
      return [
        {
          text: footer.infoTable?.vatValue ? `The total reflected above includes ${formatCurrency(footer.infoTable?.vatValue)} VAT` : undefined,
          margin: [10, 15, 0, 5],
        },
        {
          margin: [0, footer.infoTable?.vatValue ? 0 : 15, 0, 0],
          layout: {
            hLineWidth: function(i: any, node: any) {
              return 0.1;
            },
            vLineWidth: function(i: any, node: any) {
              return i === 0 || i === node.table.widths.length ? 0 : 0.1;
            },
            hLineColor: function() {
              return "lightgrey";
            },
            vLineColor: function() {
              return "lightgrey";
            },
          },
          table: {
            widths: ["20%", "80%"],
            body: [
              ...getFooterBankDetails(footer),
              [
                {
                  text: "EFT info",
                  margin: [3, 3, 3, 3],
                },
                {
                  text: [
                    {text: footer.infoTable?.eftInfo?.paymentRef ? "Payment ref.: " : ""},
                    {text: footer.infoTable?.eftInfo?.paymentRef ? `${footer.infoTable?.eftInfo?.paymentRef}` : "", bold: true},
                    {text: footer.infoTable?.eftInfo?.paymentRef ? ", " : ""},
                    {text: footer.infoTable?.eftInfo?.email ? "Send proof of payment to: " : ""},
                    {text: footer.infoTable?.eftInfo?.email ? `${footer.infoTable?.eftInfo?.email}` : "", bold: true},
                  ],
                  margin: [3, 3, 3, 3],
                },
              ],
              [
                {
                  text: "Company info",
                  margin: [3, 3, 3, 3],
                },
                {
                  text: [
                    {text: footer.infoTable?.companyInfo?.registrationNo ? "Registration no.: " : ""},
                    {text: footer.infoTable?.companyInfo?.registrationNo ? `${trim(footer.infoTable?.companyInfo?.registrationNo)}` : "", bold: true},
                    {text: footer.infoTable?.companyInfo?.registrationNo ? ", " : "", bold: true},
                    {text: footer.infoTable?.companyInfo?.vatNo ? "VAT no: " : ""},
                    {text: footer.infoTable?.companyInfo?.vatNo ? `${footer.infoTable?.companyInfo?.vatNo}` : "", bold: true},
                  ],
                  margin: [3, 3, 3, 3],
                },
              ],
            ],
          },
        },
      ];
    }
    return {};
  }

  export function generateSnapscanDisplay(snapscan: ReportSnapscan) {
    return {
      alignment: 'right',
      margin: [0, 0, 0, -25],
      columns: [
          { width: '*', text: '' }, // Blank placeholder to force content to the right
          {
              width: 'auto',
              stack: [
                  {
                      image: snapscan.snapScanPng,
                      width: 60,
                      height: 60,
                      margin: [0, -1, 2, 0],
                  }
              ]
          },
          {
              width: 'auto',
              stack: [
                  {
                      qr: snapscan.qr,
                      fit: 83,
                  }
              ]
          },
      ]
    }
  }

  function generateDisplayTable(data: Map<string, string>) {
    return {
      widths: [120, 142],
      body: Array.from(data).map(([label, value]) =>
        mapDisplayData(label, value)
      ),
    };
  }

  function getFooterBankDetails(footer?: ReportFooter) {
    const bank = getBankDetail(footer?.infoTable?.bankDetails, footer?.infoTable.bankDetailsEft ? "Bank details (deposit)" : "Bank details");
    if (footer.infoTable?.bankDetailsEft) {
      return [bank,
        getBankDetail(footer?.infoTable?.bankDetailsEft, "Bank details (EFT)")];
    } else {
      return [bank];
    }
  }

  function getBankDetail(bankDetal?: FooterBankDetails, name?: string) {
    return [
      {
        text: name,
        margin: [3, 3, 3, 3],
      },
      {
        text: [
          {text: bankDetal?.practiceName ? `${bankDetal?.practiceName}, ` : "", bold: true},
          {text: bankDetal?.branchName ? "Branch: " : ""},
          {text: bankDetal?.branchName ? `${bankDetal?.branchName}, ` : "", bold: true},
          {text: bankDetal?.accountNo ? "Account no.: " : ""},
          {text: bankDetal?.accountType ? `${bankDetal?.accountNo}` : "", bold: true},
          {text: bankDetal?.accountType ? ", " : ""},
          {text: bankDetal?.accountType ? "Account type: " : ""},
          {text: bankDetal?.accountType ? `${bankDetal?.accountType}` : "", bold: true},
        ],
        margin: [3, 3, 3, 3],
      },
    ];
  }

  function hbHeader(currentPage: any, pageCount: any, header?: string, genDate?: string) {
    if (header && currentPage > 1) {
      return generateMainHeader(header, genDate);
    }
  }

  function hbFooter(currentPage: any, pageCount: any, hbLogo?: boolean, footerText?: string, pageOrientation?: string) {
    let rightMargin = 0;
    if (pageOrientation === "landscape") {
      rightMargin = -30;
    }
    return hbLogo ? [
      {
        columns: [
              footerText ? getPagesFooter(currentPage, pageCount, "left", "25%") : getFooterTextSection("", "25%", "left"),
              footerText ? getFooterTextSection(footerText, "50%", "left") : getPagesFooter(currentPage, pageCount, "left", "10%"),
              {
                image: BASE64_DATA.LOGO,
                width: 120,
                style: {alignment: "right"},
                margin: [0, -5, rightMargin, 0],
              },
        ],
        margin: [30, 10, 0, 10],
      },
    ] :
        [
          {
            text: currentPage.toString() + " of " + pageCount,
            style: {alignment: "center", color: "gray"},
            width: "33%",
            margin: [0, 10, 0, 10],
          },
        ];
  }

  function getPagesFooter(currentPage: any, pageCount: any, align: string, width: string) {
    return {
      text: `Page ${currentPage.toString()} of ${pageCount}`,
      style: {alignment: align, color: "grey"},
      width: width,
    };
  }

  function getFooterTextSection(text: string, width: string, align: string) {
    return {
      text: text,
      width: width,
      style: {
        alignment: align,
        color: "grey",
      },
      fontSize: 8,
    };
  }
  // #endregion
}
