import { Injectable } from '@angular/core';
import { EmailMessagingReportBuilder, EmailMessagingXlsReportDataBuilder, BaseExcelGenerator } from '@meraki-flux/purejs'
import { BaseExcelReportData, EmailMessagingReportRequest, Report, REPORT_FORMAT_TYPE, REPORT_NAME } from '@meraki-flux/schema';
import { ReportUtils } from '../report-utils';
@Injectable({
  providedIn: 'root'
})
export class EmailMessagingReportService {

  async generateReport(reportRequest: EmailMessagingReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildReportFileNameDates('Email', reportRequest.Practice, null, null, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.CREDIT_NOTES,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: EmailMessagingReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: EmailMessagingReportRequest): Promise<BaseExcelReportData> {
    return new EmailMessagingXlsReportDataBuilder().build(
      await new EmailMessagingReportBuilder().build(reportRequest),reportRequest);
  }

}
