import {
  BasePdfReportData,
  ProofOfPaymentReportModel,
  ReportFooter, ReportHeader,
  ReportTable, StatementHeader,
  StatementTable
} from "@meraki-flux/schema";

export class ProofOfPaymentPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(reportModel: ProofOfPaymentReportModel): BasePdfReportData {
      const reportData: BasePdfReportData = {};
      reportData.bpn = reportModel.PracticeId;
      reportData.doctorName = reportModel.PracticeName;
      reportData.doctorNameColor = ProofOfPaymentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
      reportData.headers = this.buildHeaders(reportModel);
      reportData.tables = this.buildTables(reportModel);
      reportData.footer = this.buildFooter(reportModel);
      reportData.mainHeader = reportModel.Header;
      reportData.dateHeader = `Date generated: ${reportModel.StatementDate}`;
      reportData.footerText = reportModel.FooterText;
      return reportData;
    }

  buildFooter(reportModel: ProofOfPaymentReportModel) : ReportFooter {
    return {
      hbLogo: true,
      infoTable: reportModel.InfoTable
    }
  }

  buildTables(reportModel: ProofOfPaymentReportModel) : ReportTable[] {
    const tables:ReportTable[] = [];
    reportModel.StatementTables.forEach(stTable => {
      tables.push(this.buildReportTable(stTable));
    })
    return tables;
  }

  buildReportTable(stTable: StatementTable) : ReportTable {
    return {
      headers: stTable.TableHeaders.map(x => {
        return {
          outlined: x.outlined,
          outlineColor: x.outlineColor,
          rows: x.rows.map(y => {
            return y.map(xy => {
              return {
                name: xy.name,
                data: {
                  value: xy.value.value,
                  decoration: {
                    bold: xy.value.bold
                  }
                }
              }
            })
          }),
        }
      }),
      reportICDLine: stTable.ICDLine,
      rowHeaders: stTable.rowHeaders,
      rows: stTable.rows,
      borders: stTable.borders
    }
  }

  buildHeaders(reportModel: ProofOfPaymentReportModel) : ReportHeader[] {
    const headers:ReportHeader[] = [];
    headers.push(this.buildReportHeader(reportModel.PracticeHeader));
    headers.push(this.buildAccountHeader(reportModel.AccountHeader));
    return headers;
  }

  buildReportHeader(statementHeader: StatementHeader): ReportHeader {
    return {
      right: statementHeader.right.map(x => {
        return {
          name: x.name,
          data: {
            value: x.value.value,
            decoration: {
              bold: x.value.bold
            }
          }
        }
      }),
      left: statementHeader.left.map(x => {
        return {
          data: {
            value: x.value.value,
            decoration: {
              bold: x.value.bold
            }
          }
        }
      })
    }
  }

  buildAccountHeader(statementHeader: StatementHeader): ReportHeader {
    return {
      name: statementHeader.name,
      nameColor: ProofOfPaymentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
      includeGeneratedDate: true,
      outlined: true,
      outlineColor: ProofOfPaymentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
      ... this.buildReportHeader(statementHeader)

    }
  }
}
