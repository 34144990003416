import { Injectable } from '@angular/core';
import { createStore, Store, withProps } from '@ngneat/elf';
import { getEntity, updateEntities, withEntities } from '@ngneat/elf-entities';
import {User} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private readonly userStore: Store;

  constructor() {
    this.userStore = createStore({ name: 'users' }, withEntities({ idKey: 'uid' }), withProps({}));
  }

  saveUser(user: User) {
    this.userStore.update(updateEntities(user.uid, user));
  }

  getUser(userId: string): User {
    return userId ? this.userStore.query(getEntity(userId)) : undefined;
  }

  clear() {
    return this.userStore.reset();
  }
}
