import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  query,
  QueryConstraint,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountRepository } from '../../repositories/account.repository';
import { PathUtils } from '../../utils/path-utils';
import { AuthService } from '../auth.service';
import { PaymentWhereBuilder } from './payment-where-builder';
import { TransactionCaptureService } from './transaction-capture.service';
import {
  ERROR_CODE,
  Payment,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PaymentUnallocate,
  RefundRequest,
  Reverse, TransactionCapture
} from "@meraki-flux/schema";

export interface PaymentFilter {
  Type: PAYMENT_TYPE | string | null;
  InvoicePath: string | null;
}

@Injectable()
export class PaymentService {
  constructor(
    private authService: AuthService,
    private accountRepository: AccountRepository,
    private firestore: Firestore,
    private transactionCaptureService: TransactionCaptureService
  ) {}

  selectedAccountPayments(wheres: QueryConstraint[]): Observable<Payment[]> {
    return this.authService.practice$.pipe(
      withLatestFrom(this.accountRepository.activeAccount$),
      switchMap(([practice, account]) =>
        collectionSnapshots(
          query(
            collection(
              this.firestore,
              `${PathUtils.paymentCollectionPath(practice.Id, account.Id)}`
            ),
            ...wheres
          )
        )
      ),
      map((arr) => arr.map((doc) => ({ ...doc.data(), Id: doc.id })))
    );
  }

  async getPayment(practiceId: string, accountId: string, paymentId: string) {
    const paymentDoc = await getDoc(
      doc(this.firestore, PathUtils.paymentPath(practiceId, accountId, paymentId))
    );
    return { ...paymentDoc.data(), Id: paymentDoc.id };
  }

  unallocatedCredits(): Observable<Payment[]> {
    return this.selectedAccountPayments(
      PaymentWhereBuilder.builder()
        //.status(PAYMENT_STATUS.VALID). not all payments have this field set yet, so filter manually
        .hasUnallocatedAmount()
        .build()
    ).pipe(
      map((payments) => payments.filter((p) => !p.Status || p.Status == PAYMENT_STATUS.VALID))
    );
  }

  async unallocate(
    accountId: string,
    unallocate: PaymentUnallocate,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    this.transactionCaptureService.unallocatePayment(accountId, unallocate, onCompleteFn);
  }

  async reverse(
    accountId: string,
    paymentId: string,
    reason: string,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const reverse = { PaymentId: paymentId, Reason: reason } as Reverse;
    await this.transactionCaptureService.reverse(accountId, reverse, onCompleteFn);
  }

  async capture(
    practiceId: string,
    accountId: string,
    payment: Payment,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    await this.transactionCaptureService.capturePayment(accountId, payment, onCompleteFn);
  }

  async refund(
    practiceId: string,
    accountId: string,
    refundRequest: RefundRequest,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const ref = (await this.transactionCaptureService.addTransactionCapture(accountId, {
      Refund: refundRequest,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    if (onCompleteFn) {
      await this.transactionCaptureService.waitForComplete(ref, onCompleteFn);
    }
  }
}
