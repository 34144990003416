import { BaseExcelReportData, CellType, CellWidth, ExcelCellData, 
    InboxActionsDSData, InboxActionsReportModel, REPORT_NAME, Sheet, Table } from "@meraki-flux/schema";
    import moment = require("moment");

export class InboxActionsXlsReportDataBuilder {


    build(inboxActionsReportModel: InboxActionsReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.INBOX_ACTIONS_REPORT;
        excelReportData.data = [];

        excelReportData.data.push(this.buildReportSheetDetail(inboxActionsReportModel, inboxActionsReportModel.ReportInfo.IsMultiBranch));
        
        this.buildReportInfo(excelReportData,inboxActionsReportModel);

        return excelReportData;
    }

    private buildReportSheetDetail(inboxActionsReportModel: InboxActionsReportModel,addBranchColumn?: boolean) {
        const sheet: Sheet = {};
        sheet.name = 'Details';
        sheet.tables = [];
  
        const detailsTable: Table = {};
        detailsTable.headers = [
            {value: 'User', bold: true, width: CellWidth.S},
            {value: 'Discarded/Completed', bold: true, width: CellWidth.S},
            {value: 'Date discarded/completed', bold: true, width: CellWidth.S},
            {value: 'Discard reason', bold: true, width: CellWidth.S},
            {value: 'Action group', bold: true, width: CellWidth.XS},
            {value: 'Action name', bold: true, width: CellWidth.S},
            {value: 'Action description', bold: true, width: CellWidth.M},
            {value: 'Date created', bold: true, width: CellWidth.XS},
        ];
        
        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.S});
        } 
  
        const detailsRows = [];
        
        const parseDateTime = (dateTimeString) => {
            return moment(dateTimeString, 'DD/MM/YYYY',true).startOf('day').toDate();
        };
        
        const inboxDetails= inboxActionsReportModel.InboxReportModel.InboxActionsDSData;
        inboxDetails.sort((a,b) => parseDateTime(a.DateCompleted).getTime() - parseDateTime(b.DateCompleted).getTime());

        inboxDetails.forEach((item: InboxActionsDSData) => {
        const row: ExcelCellData[] = [];
        row.push({value: item.User}),
        row.push({value: item.IbxStatus}),
        row.push({value: item.DateCompleted, hAlignment:'left'}),
        row.push({value: item.DiscardReason}),
        row.push({value: item.ActionGroup, hAlignment:'left'}),
        row.push({value: item.ActionName}),
        row.push({value: item.ActionDescription}),
        row.push({value: item.DateCreated, hAlignment:'left'})

        if (addBranchColumn)
                row.push({value: item.Branch});
        
        detailsRows.push(row);
      });
  
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, inboxActionsReportModel: InboxActionsReportModel) {

        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: inboxActionsReportModel.ReportInfo.Practice, type: CellType.GENERAL}
        });

        if (inboxActionsReportModel.ReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: inboxActionsReportModel.ReportInfo.Branch, type: CellType.GENERAL}
                });
        }

         excelReportData.parameters.push(
             {
                 name: 'Date range',
                 data: {value: inboxActionsReportModel.ReportInfo.DateRange, type: CellType.GENERAL}
             });
    }
}