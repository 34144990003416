import {Practice} from "../practice.models";
import {PracticeProvider} from "../provider.models";
import {BaseAccountMember} from "../member.models";
import { REPORT_FORMAT_TYPE } from '../report.models';
import { ReportRequestData } from './common.model';

export class AppointmentReportRequest implements ReportRequestData {
  Practice: Practice;
  BranchName?: string;
  SelectedTreatingProvider: PracticeProvider;
  TreatingProviders: PracticeProvider[];
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  Multibranch: boolean;
  IncludeInvoiceData: boolean;
  IncludeInactiveAppointments: boolean;
  Patient: BaseAccountMember;
  Recipient: string;
  UserId: string;
}

export interface AppointmentReportModel {
  ReportInfo: AppointmentReportInfo;
  ReportDate?: string;
  AllProviderModel:boolean;
  ProviderData: ProviderData[];
  TotalData: TotalData;
  VisitTypeData: VisitTypeData;
}

export interface VisitTypeData {
  TotalVisitCount: number;
  CountPerVisitType: Map<string, number>
}

export interface ProviderData {
  ProviderShortName: string;
  ProviderName: string;
  ProviderAppointments: ProviderAppointment[];
  ExistingPatientCount: number;
  NewPatientCount: number;
  RecomedCount: number;
  InvoicedCount: number;
  NotInvoicedCount: number;
  TotalInvoicedAmount: number;
}

export interface ProviderAppointment {
  Date: string;
  Time: string;
  Status: string;
  VisitType: string;
  Reason: string;
  AccountNo: string;
  FileNo: string;
  Patient: string;
  PatientCellphone: string;
  PatientEmail: string;
  Scheme: string;
  MemberNo: string;
  DependantCode: string;
  BC: string;
  BCStatus: string;
  BCMessageId: string;
  InvoiceAmount: number;
  InvoiceNo: string;
  Resource: string;
  // Source: string; // add later with Recomed integration
  CreatedBy: string;
  CreatedAt: string;
  Branch: string
}

export interface TotalData {
  ExistingPatientCount?: number;
  NewPatientCount?: number;
  RecomedCount?: number;
  InvoicedCount?: number;
  NotInvoicedCount?: number;
  TotalInvoicedAmount?: number;
}

export interface AppointmentReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  Branch?: string
  TreatingProvider?: string | ''
  DateRange?: string
  IsMultiBranch?: boolean;
  IncludeInvoiceData?: boolean;
  IncludeRecomedData?: boolean;
  ReportName?: string;
  PatientName?: string;
}
