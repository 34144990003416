import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import { RaStatementPdfReportDataBuilder } from './ra-statement-pdf-report-data-builder';
import { RaStatementReportBuilder } from './ra-statement-report-builder';
import { Firestore, collection, doc, docSnapshots, getDocs, query } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import moment from 'moment';
import {
  BasePdfReportData, RaDetails, RaJournalAmounts, RaPaymentDateUpdates, RaPayments, RaReasonCodes,
  RaStatementReportRequest, Reason, RemittanceClaim, RemittanceJournal,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class RaStatementReportService {

  private readonly DATE_FORMAT = "DD/MM/YYYY";
  private readonly DATE_TIME_FORMAT = "DD/MM/YYYY HH:MM";

  constructor(
    private firestore: Firestore,
  ){}

  async generateReport(reportRequest: RaStatementReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildRaStatementReportFileName('RAStatement', reportRequest.Practice, reportRequest.RaDetails?.Scheme, reportRequest.RaDetails?.EFTNo);

    const reportGenerator: BasePdfGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.RA_STATEMENT,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF
    }
  }

  private async getReportGenerator(reportRequest: RaStatementReportRequest): Promise<BasePdfGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    }
    else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: RaStatementReportRequest): Promise<BasePdfReportData> {
    return new RaStatementPdfReportDataBuilder().build(
      await new RaStatementReportBuilder().build(reportRequest));
  }

  private convertTimestampToDateTime(val: any): Date {
    if (val) {
      const seconds = val.seconds;
      const nanoseconds = val.nanoseconds;
      const milliseconds = seconds * 1000 + nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date;
    }

    return null;
  }

  getReasonCodes(reason: Reason[]): string {
    let reasonCodes = '';
    if (reason) {
      for (const reas of reason) {
        if(!reas.ReasonCode.startsWith('hb_')){
          reasonCodes = reasonCodes ? reasonCodes + ', ' + reas.ReasonCode : reas.ReasonCode;
        }
      }
    }
    return reasonCodes;
  }

  async getRADocment(raId:string, practiceBPN: string):Promise<RaDetails> {

    const raDetailsObj: RaDetails = {
      Id: '',
      Scheme: '',
      RANo: '',
      PaymentDate: '',
      EFTNo: '',
      RADate: '',
      ReconType: '',
      MedicalAidOrInsurer: '',
      TotalAmountPaid: 0,
      TotalValueCaptured: 0
    }

    try {
      const raDoc = await docSnapshots(
        doc(this.firestore, `/Practice/${practiceBPN}/RemittanceAdvice/${raId}`)
      )
        .pipe(first())
        .toPromise();
      try {
        if (raDoc.exists()) {
          const ra = raDoc.data();
          raDetailsObj.Id = ra.Id,
          raDetailsObj.Scheme = ra.SchemeName,
          raDetailsObj.RANo = ra.Payment?.RANum,
          raDetailsObj.PaymentDate = ra.Payment?.PayDate? moment(this.convertTimestampToDateTime(ra.Payment?.PayDate)).format(this.DATE_FORMAT):"",
          raDetailsObj.EFTNo = ra.Payment?.EFTNum,
          raDetailsObj.RADate = ra.Payment?.RADate? moment(this.convertTimestampToDateTime(ra.Payment?.RADate)).format(this.DATE_FORMAT): "",
          raDetailsObj.ReconType =  ra.Type,
          raDetailsObj.MedicalAidOrInsurer = ra.Insurer? "Insurer" : "Medical Aid",
          raDetailsObj.TotalAmountPaid = ra.Payment?.PaidAmount,
          raDetailsObj.TotalValueCaptured = 0,
          raDetailsObj.RaPayments = []

          const raPaymentDateUpdates: RaPaymentDateUpdates[] = [];
          const paymentAudits = ra.Audit?.filter((audit: { Description: string; }) => audit.Description == "PaymentDateUpdate");
          if (paymentAudits) {
            for (const paymentAudit of paymentAudits) {
              const obj: RaPaymentDateUpdates = {
                DateOfChange: paymentAudit.DateOfChange? moment(this.convertTimestampToDateTime(paymentAudit.DateOfChange)).format(this.DATE_TIME_FORMAT):"",
                User: '',
                OldPaymentDate: paymentAudit.OldPaymentDate? moment(this.convertTimestampToDateTime(paymentAudit.OldPaymentDate)).format(this.DATE_FORMAT):"",
                NewPaymentDate: paymentAudit.NewPaymentDate? moment(this.convertTimestampToDateTime(paymentAudit.NewPaymentDate)).format(this.DATE_FORMAT):"",
              };

              if (paymentAudit.UserId) {
                const user = await docSnapshots(doc(this.firestore, `User/${paymentAudit.UserId}`))
                  .pipe(first())
                  .toPromise();
                try {
                  if (user.exists()) {
                    obj.User = user.get('Name') + ' ' + user.get('Surname');
                  }
                } catch (userError) {
                  console.error('Error fetching User document @getSms:', userError);
                }
              }

              raPaymentDateUpdates.push(obj);
            }
            raDetailsObj.RaPaymentDateUpdates = raPaymentDateUpdates;
          }

          // Getting RemittanceClaim documents
          const claimQuery = query(
            collection(this.firestore, `/Practice/${practiceBPN}/RemittanceAdvice/${raId}/RemittanceClaim`)
          );

          const claimSnapshot = await getDocs(claimQuery);
          const raPaymentsArray: RaPayments[] = [];
          const raReasonCodes: RaReasonCodes[] = [];
          claimSnapshot.forEach(claimDoc => {
            const claimData: RemittanceClaim = claimDoc.data() as RemittanceClaim;
            for (const lineItem of claimData.LineItem) {
              const obj: RaPayments = {
                Status: claimData.Status,
                AccountNo: claimData.PatAccNum? claimData.PatAccNum: claimData.AccountNo,
                Patient: claimData.PatName + ' ' + claimData.PatSurname,
                MemberNo: claimData.MemNum,
                DateOfService: lineItem.DOS? moment(this.convertTimestampToDateTime(lineItem.DOS)).format(this.DATE_FORMAT):"",
                TariffCode: lineItem.ChargeCode,
                NappiCode: lineItem.NappiCode,
                Claimed: Number(lineItem.PayAdvice?.TariffAmount),
                Paid: Number(lineItem.PayAdvice?.PaymentAmount),
                ReasonCode: this.getReasonCodes(lineItem.Reason),
                Amount : Number(lineItem.PayAdvice?.PaymentAmount),
                IsNoteLine : false,
              };
              raPaymentsArray.push(obj);

              // Additional remittance notes
              if(claimData.Notes){
                for (const note of claimData.Notes){
                  const obj: RaPayments = {
                    Status: claimData.Status,
                    AccountNo: '',
                    Patient: "Note: " + note.Note,
                    MemberNo: '',
                    DateOfService: '',
                    TariffCode: '',
                    NappiCode: '',
                    Claimed: 0,
                    Paid: 0,
                    ReasonCode: '',
                    Amount : 0,
                    IsNoteLine : true,
                  };
                  raPaymentsArray.push(obj);
                }
              }

              if(lineItem && lineItem.Reason){
                for (const reasonCode of lineItem.Reason){
                  if (!raReasonCodes.some(code => code.ReasonCode === reasonCode.ReasonCode && code.Description === reasonCode.ReasonDesc)){
                    const objReasonCode: RaReasonCodes = {
                      ReasonCode: reasonCode.ReasonCode,
                      Description: reasonCode.ReasonDesc
                    };
                    if(reasonCode.ReasonCode !== 'hb_matched'){
                      raReasonCodes.push(objReasonCode);
                    }
                  }
                }
              }
            }
          });

          raDetailsObj.RaPayments = raPaymentsArray;
          raDetailsObj.RaReasonCodes = raReasonCodes.sort((a, b) => (a.ReasonCode?.toString() ?? '').localeCompare(b.ReasonCode?.toString() ?? ''));

          //END - Geeting RemittanceClaim documents

          // Geeting RemittanceJournal documents
          const journalQuery = query(
            collection(this.firestore, `/Practice/${practiceBPN}/RemittanceAdvice/${raId}/RemittanceJournal`)
          );

          const journalSnapshot = await getDocs(journalQuery);
          const raJournalAmountsArray: RaJournalAmounts[] = [];
          journalSnapshot.forEach(claimDoc => {
            const journalData: RemittanceJournal = claimDoc.data() as RemittanceJournal;
            const obj: RaJournalAmounts = {
              JournalDescription: journalData.JournalDes,
              Amount: Number(journalData.JournalAmount),
            };
            raJournalAmountsArray.push(obj);
          });

          raDetailsObj.RaJournalAmounts = raJournalAmountsArray;
          //END - Geeting RemittanceJournal documents

        }
      } catch (userError) {
        console.error('Error fetching RA document @getRADocment:', userError);
        throw userError;
      }
    } catch (err) {
      console.error('Search error', err);
      throw err;
    }

    return raDetailsObj;

  }
}
