import { Injectable } from '@angular/core';
import { collection, doc, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { ADDON_ID, CALENDAR_EVENT_STATUS, CLINICAL_CALENDAR_EVENT_STATUS, HbcIntegrationAddon } from '@meraki-flux/schema';
import { AuthService } from './auth.service';
import { CalendarEventService } from './calendar/calendar-event.service';
import { DialogService } from './dialog.service';

@Injectable({ providedIn: 'root' })
export class ClinicalCheckService {
  constructor(
    private dialogService: DialogService,
    protected firestore: Firestore,
    protected calendarEventService: CalendarEventService,
    private authService: AuthService  ) {}

  async simpleCheckClinicalInProgress(id: string, entity: string) {
    // check if the account/patient's ClinicalStatus is checked in or in-progress
    //and if the account/patient is already checked in, show a warning message to the user
    const hbcAddonSnapshot = (await getDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Addon/${ADDON_ID.HBC}`)));
    const hbcAddon = hbcAddonSnapshot.exists() ? hbcAddonSnapshot.data() as HbcIntegrationAddon : null;

    const calendarEventQuery = query(collection(this.firestore, `Practice/${this.authService.selectedBPN}/CalendarEvent`),
      where(`VisitInfo.${entity}Info.${entity}Id`, '==', id),
      where('ClinicalStatus', '==', CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS));
    const calendarEventQuerySnapshot = await getDocs(calendarEventQuery);

    if (calendarEventQuerySnapshot.size === 0 || !(hbcAddon?.Activated)) {
      return false;
    }

    return calendarEventQuerySnapshot.size > 0;
  }

  async simpleCheckInProgress(id: string, entity: string, isClinical: boolean = false) {
    // Base query filters (always include Status filter)
    let filters = [
        where(`VisitInfo.${entity}Info.${entity}Id`, '==', id),
        where('Status', 'in', [CALENDAR_EVENT_STATUS.BOOKED, CALENDAR_EVENT_STATUS.CHECKED_IN])
    ];

    if (isClinical) {
        filters.push(where('ClinicalStatus', '==', CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS));
    }

    const calendarEventQuery = query(
        collection(this.firestore, `Practice/${this.authService.selectedBPN}/CalendarEvent`),
        ...filters
    );
    const calendarEventQuerySnapshot = await getDocs(calendarEventQuery);

    if (isClinical) {
        const hbcAddonSnapshot = await getDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Addon/${ADDON_ID.HBC}`));
        const hbcAddon = hbcAddonSnapshot.exists() ? hbcAddonSnapshot.data() as HbcIntegrationAddon : null;
        
        if (calendarEventQuerySnapshot.size === 0 || !(hbcAddon?.Activated)) {
            return false;
        }
    }

    return calendarEventQuerySnapshot.size > 0;
  }
}
