import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { SystemAccessHelperService } from '../../services/system-access-helper.service';
import { CapturePaymentUtils } from '../../utils/capture-payment-utils';
import { FormUtils } from '../../utils/form-utils';
import { MoneyUtils } from '../../utils/money-utils';
import { TransferLiabilityComponent } from '../transfer-liability/transfer-liability.component';
import {
  Invoice,
  INVOICE_SUBTYPE,
  INVOICE_TYPE, InvoiceLine,
  InvoicePatient,
  MaPayableInvoice, MaPayableInvoiceLine,
  SYSTEM_ACCESS_CODE
} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class MaPayableInvoiceHelper {
  readonly REASON_CODES_REGEX = new RegExp('^[0-9,]+$');

  constructor(
    private authService: AuthService,
    private systemAccessHelperService: SystemAccessHelperService,
    private dialog: MatDialog
  ) {}

  practice() {
    return this.authService.practiceBF$;
  }

  resetAmountAllocatedToZero(invoice: MaPayableInvoice) {
    invoice?.Lines?.forEach((line) => line?._AmountAllocatedCtrl?.setValue(0));
  }

  onZeroPaidCheck(
    newValue: boolean,
    fullyPaidControl: UntypedFormControl,
    invoice: MaPayableInvoice
  ) {
    invoice._ZeroPaid = newValue;
    invoice?.Lines?.forEach((line) => FormUtils.enable(line?._AmountAllocatedCtrl, !newValue));
    if (newValue) {
      fullyPaidControl.patchValue(false);
      this.resetAmountAllocatedToZero(invoice);
    } else if (!fullyPaidControl.value) {
      this.resetAmountAllocatedToZero(invoice);
    }
  }

  onFullyPaidCheck(
    newValue: boolean,
    zeroPaidControl: UntypedFormControl,
    fullyPaidControl: UntypedFormControl,
    invoice: MaPayableInvoice
  ) {
    if (!newValue) {
      this.resetAmountAllocatedToZero(invoice);
      return;
    }
    const lines = invoice?.Lines || [];
    lines.forEach((line, index) =>
      line?._AmountAllocatedCtrl?.patchValue(
        MoneyUtils.fromCents(lines[index]?.Balance?.MedicalAidLiable || 0)
      )
    );
    zeroPaidControl.patchValue(false);
    this.onZeroPaidCheck(false, fullyPaidControl, invoice);
  }

  showTransferLiabilities() {
    return this.systemAccessHelperService.hasAccess(SYSTEM_ACCESS_CODE.TRANSFER_LIABILITIES);
  }

  onTransferLiabilities(invoice: Invoice) {
    this.dialog.open(TransferLiabilityComponent, {
      panelClass: 'flux-dialog-medium',
      data: invoice,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  invoiceDescription(invoiceLineIndex: number, invoice: Invoice) {
    if (invoiceLineIndex !== 0) return '';
    if (invoice.Type === INVOICE_TYPE.CASH) {
      return 'Cash';
    } else if (invoice.Type === INVOICE_TYPE.MEDICAL_AID) {
      return invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER
        ? `Insurance - ${invoice?.ClaimInfo?.ClaimStatus}`
        : invoice?.ClaimInfo?.ClaimStatus;
    } else {
      return undefined;
    }
  }

  patientNameSurnameDepCode(invoiceLineIndex: number, patient: InvoicePatient) {
    if (invoiceLineIndex !== 0) return '';
    return patient?.DependantCode
      ? `${patient?.Name} ${patient?.Surname} (${patient?.DependantCode})`
      : `${patient?.Name} ${patient?.Surname}`;
  }

  isReasonCodeChar(char: string) {
    return this.REASON_CODES_REGEX.test(char);
  }

  buildMaPayableInvoice(invoice: Invoice) {
    const maPayableInvoice = {
      ...invoice,
      Lines: invoice.Lines.map((line) => this.buildMaPayableInvoiceLine(line)),
    } as MaPayableInvoice;
    return maPayableInvoice;
  }

  buildMaPayableInvoiceLine(invoiceLine: InvoiceLine) {
    return {
      ...invoiceLine,
      _AmountAllocatedCtrl: new UntypedFormControl(null, Validators.min(0)),
      _ActionCtrl: new UntypedFormControl(),
      _BalanceOutstanding: invoiceLine?.Balance?.Outstanding,
    } as MaPayableInvoiceLine;
  }

  onChangeLineAllocatedAmount(
    allocatedAmountRands: number,
    line: MaPayableInvoiceLine,
    invoice: MaPayableInvoice,
    fillyPaidCtrl: UntypedFormControl,
    writeOffThreshold: number
  ) {
    if (!line || !invoice) return;
    const amountAllocated = MoneyUtils.toCents(allocatedAmountRands || 0);
    const initialBalanceOutstanding = line?.Balance?.Outstanding || 0;
    line._BalanceOutstanding = initialBalanceOutstanding - amountAllocated;
    const zeroPaid = invoice._ZeroPaid;
    // refresh action dropdown
    this.refreshActionControl(
      line._ActionCtrl,
      amountAllocated,
      line._BalanceOutstanding,
      zeroPaid,
      writeOffThreshold
    );
    // refresh totals
    invoice._AmountAllocated = invoice?.Lines?.reduce((acc, curr: MaPayableInvoiceLine) => {
      const lineAllocated = MoneyUtils.toCents(curr?._AmountAllocatedCtrl?.value || 0);
      return acc + lineAllocated;
    }, 0);
    invoice._BalanceOutstanding = invoice?.Lines?.reduce(
      (acc, curr: MaPayableInvoiceLine) => acc + (curr?._BalanceOutstanding || 0),
      0
    );
    if (
      fillyPaidCtrl.value &&
      (amountAllocated === 0 || amountAllocated < line.Balance.MedicalAidLiable)
    )
      fillyPaidCtrl.setValue(false, { emitEvent: false });
  }

  private refreshActionControl(
    control: UntypedFormControl,
    amountAllocated: number,
    balanceOutstanding: number,
    isZeroPaid: boolean,
    writeOffThreshold: number = 0
  ) {
    const hasLineAllocation = amountAllocated > 0 || isZeroPaid;
    if (hasLineAllocation) {
      const recommendedAction = CapturePaymentUtils.getRecommendedAction(
        balanceOutstanding,
        writeOffThreshold
      );
      control.setValue(recommendedAction);
      FormUtils.enable(control, balanceOutstanding > 0);
    } else {
      control.setValue(null);
      control.disable();
    }
    FormUtils.setRequired(hasLineAllocation, control);
  }
}
