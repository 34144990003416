import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {
  BaseExcelReportData,
  BasePdfReportData,
  PaymentPerProviderReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {
  BaseExcelGenerator,
  BasePdfGenerator,
  PaymentsPerProviderPdfReportBuilder,
  PaymentsPerProviderReportBuilder,
  PaymentsPerProviderXlsReportBuilder
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsPerProviderReportService {

  async generateReport(reportRequest: PaymentPerProviderReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPaymentPerProviderReportFileName('PaymentsPerProvider', reportRequest.Practice,
              reportRequest.DateFrom, reportRequest.DateTo, reportRequest.ReportFormatType);

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.PAYMENTS_PER_PROVIDER_REPORT,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: reportRequest.ReportFormatType
    }
  }

  private async getReportGenerator(reportRequest: PaymentPerProviderReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    } else
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error('Report format is not supported.');
    }
  }

  private async generatePdfReportData(reportRequest: PaymentPerProviderReportRequest): Promise<BasePdfReportData> {
    return new PaymentsPerProviderPdfReportBuilder().build(
      await new PaymentsPerProviderReportBuilder().build(reportRequest), reportRequest);
  }

  private async generateXlsReportData(reportRequest: PaymentPerProviderReportRequest): Promise<BaseExcelReportData> {
    return new PaymentsPerProviderXlsReportBuilder().build(
      await new PaymentsPerProviderReportBuilder().build(reportRequest), reportRequest);
  }
}
