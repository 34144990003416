import { Injectable } from "@angular/core";
import {
  BaseAccount, BaseInvoice, BasePdfReportData,
  ClaimDetailReportRequest, InvoiceMainMember,
  Practice,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { ReportUtils } from "../report-utils";
import { ClaimDetailPdfReportDataBuilder } from "./claim-detail-pdf-report-data-builder";
import { ClaimDetailReportBuilder } from "./claim-detail-report-builder";
import { ClaimDetailPdfGenerator } from "./pdf.claim-detail";

@Injectable({
  providedIn: 'root'
})
export class ClaimDetailReportService {

  async generateReport(reportRequest: ClaimDetailReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildClaimDetailsReportFileName(reportRequest.Practice.PracticeName,
      reportRequest.Invoice.Patient.Name, reportRequest.Invoice.Patient.Surname,
      REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = await this.getReportGenerator(reportRequest);

    return {
      reportName: REPORT_NAME.CLAIM_DETAILS,
      fileName: fileName,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF
    };
  }

  private async getReportGenerator(reportRequest: ClaimDetailReportRequest) {

    const reportGenerator = new ClaimDetailPdfGenerator(await this.generatePdfReportData(reportRequest.Practice,
      reportRequest.Account, reportRequest.Invoice.MainMember, reportRequest.Invoice));

    return reportGenerator;
  }

  private async generatePdfReportData(practice: Practice, account: BaseAccount, mainMember: InvoiceMainMember, invoice: BaseInvoice): Promise<BasePdfReportData> {
    return new ClaimDetailPdfReportDataBuilder().build(
      await new ClaimDetailReportBuilder().build(practice, account, mainMember, invoice));
  }
}
