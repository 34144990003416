import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {
  BaseExcelReportData,
  PatientListReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, PatientListReportBuilder, PatientListXlsReportDataBuilder } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class PatientListReportService {

  async generateReport(reportRequest: PatientListReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPatientListReportFileName('PatientList',reportRequest.Practice);
    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.CREDIT_NOTES,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }


  private async getReportGenerator(reportRequest: PatientListReportRequest): Promise<BaseExcelGenerator> {
    return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
  }

  private async generateXlsReportData(reportRequest: PatientListReportRequest): Promise<BaseExcelReportData> {
    return new PatientListXlsReportDataBuilder().build(
      await new PatientListReportBuilder().build(reportRequest));
  }

}
