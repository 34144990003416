<div class="flex flex-col h-full w-full ">
  <div
    class="flex flex-row justify-between items-center border-b border-hb-base-divider-grey !py-4 !px-6 -mt-6 -mx-6 mb-4">
    <h2 id="dialogHeader" class="font-medium !leading-8 text-xl">{{header}}  Favourite Group</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <meraki-flux-info-box  *ngIf="dialogData.type === templateTypes.INVOICE"  type="HELP" icon="info_outlined" class="mb-1">
    Please note that if modifier lines are saved in the template, you will only be able to specify the additional modifier details when capturing the invoice.
  </meraki-flux-info-box>

  <mat-dialog-content class="h-full w-full !m-0 !p-0 !max-h-full" [formGroup]="invoiceTemplateForm">
    <div class="flex flex-col gap-3 mb-3">
      <mat-form-field class="flux-form-field w-[408px]">
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="Caption" maxlength="50">
      </mat-form-field>
      <mat-form-field class="flux-form-field w-[102px]">
        <mat-label i18n>Order</mat-label>
        <input matInput type="number" min="0" formControlName="SortOrder">
      </mat-form-field>
    </div>
    <!-- Diagnosis search row -->
    <div id="inheritHeight" class=" flex flex-row align-middle items-center justify-start gap-3">
      <mat-form-field class="flux-form-field w-full">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Diagnoses</mat-label>
        <mat-chip-list #selectedDiagnoses aria-label="Conditions">
          <mat-chip *ngFor="let diagnosis of headerDiagnoses$ | async" (removed)="onHeaderDiagnosisRemoved(diagnosis)"
            [matTooltip]="diagnosis.description">
            {{diagnosis.code}}
            <button matChipRemove>
              <mat-icon>close</mat-icon>
            </button>
          </mat-chip>
          <ng-container *ngVar="headerDiagnoses$ | async as headDiags">
            <ng-container *ngIf="headDiags.length < 5">
              <input #headerDiag placeholder="Diagnoses..." formControlName='Diagnosis'
                [matAutocomplete]="diagnosisAuto" [matChipInputFor]="selectedDiagnoses">
              <!-- using a stop propagation and then a focus instead of fall-through focus to prevent focussing the first chip instead of the input -->
              <button *ngIf="headerDiag.value" mat-icon-button matSuffix
                (click)="$event.stopPropagation(); clearDiagnosisSearch(); headerDiag.focus()">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </ng-container>
        </mat-chip-list>
        <mat-autocomplete #diagnosisAuto="matAutocomplete" (closed)="onHeaderDiagnosisSearchClosed()"
          (optionSelected)="onHeaderDiagnosisSelected($event)" autoActiveFirstOption>
          <ng-container *ngVar="diagnosisSearchBusy$ | async as searchBusy">
            <mat-option *ngIf="searchBusy" disabled>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-option>
            <mat-option *ngIf="!searchBusy && (diagnosisSearchResult$ | async)?.length === 0" disabled>
              No Results
            </mat-option>
            <ng-container *ngIf="!searchBusy">
              <mat-option *ngFor="let result of diagnosisSearchResult$ | async" [value]="result">
                <span [innerHtml]="result.highlightedCode + ' - ' + result.highlightedDescription"></span>
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-icon class="!text-hb-text-disabled !w-1/12" fontSet="material-icons-outlined"
        matTooltip="Up to 5 diagnosis codes can be specified">
        info
      </mat-icon>

    </div>

    <!-- Invoice lines table -->
    <div *ngIf="dialogData.type !== templateTypes.DIAGNOSIS" class="flex flex-col">
      <mat-table class="invoice-lines-table flux-table" [dataSource]="templateLinesSource">


        <!-- add/remove column -->
        <ng-container matColumnDef="add">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <button id='rowAddLine' class="!w-6 !h-6 !leading-6" mat-icon-button (click)="addLine(i + 1)">
              <mat-icon class="!text-[20px]">add</mat-icon>
            </button>
            <button id='rowRemoveLine' class="!w-6 !h-6 !leading-6" mat-icon-button (click)="removeLine(i)">
              <mat-icon class="!text-[20px]">remove</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- Line type column -->
        <ng-container matColumnDef="lineType">
          <mat-header-cell *matHeaderCellDef> Line type </mat-header-cell>
          <mat-cell *matCellDef="let row" [formGroup]="row.form">
            <mat-form-field appearance="outline" class="table-field">
              <mat-select id='rowLineType' *ngIf="row.form" formControlName='LineType'>
                <mat-option *ngFor="let type of lineTypes$ | async" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- TariffCode column -->
        <ng-container matColumnDef="tariffCode">
          <mat-header-cell *matHeaderCellDef> Tariff code </mat-header-cell>
          <mat-cell *matCellDef="let row" [formGroup]="row.form">
            <mat-form-field
              *ngIf="row.form && 
              (row.form.get('LineType').value === lineTypes.MEDICINE || row.form.get('LineType').value === lineTypes.CSTM_MEDICINE)"
              appearance="outline" class="table-field">
              <mat-select id='rowTariffCodeSelect' formControlName="ChargeCode">
                <mat-option *ngFor="let code of medicineTariffCodes$ | async | keyvalue" [value]="code.key">
                  {{code.key}} - {{code.value.Description}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="row.form && 
            (row.form.get('LineType').value === lineTypes.CONSUMABLE || row.form.get('LineType').value === lineTypes.CSTM_CONSUMABLE)"
              appearance="outline" class="table-field">
              <mat-select id='rowTariffCodeSelect' formControlName="ChargeCode">
                <mat-option *ngFor="let code of consumableTariffCodes$ | async | keyvalue" [value]="code.key">
                  {{code.key}} - {{code.value.Description}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="row.form && 
              (row.form.get('LineType').value === lineTypes.PROCEDURE || 
              row.form.get('LineType').value === lineTypes.MODIFIER ||
              row.form.get('LineType').value === lineTypes.CSTM_PROCEDURE ||
              row.form.get('LineType').value === lineTypes.ADMIN )" appearance="outline" class="table-field">
              <input id='rowTariffCodeInput' matInput [matAutocomplete]="tariffCodeSearchAutoComplete"
                formControlName='ChargeCode'>
            </mat-form-field>

            <mat-autocomplete #tariffCodeSearchAutoComplete="matAutocomplete" (closed)="onTariffCodeSearchClosed()"
              (optionSelected)="onTariffCodeSelected($event, row.form)" panelWidth="50%">
              <ng-container *ngVar="tariffCodeSearchBusy$ | async as searchBusy">
                <mat-option *ngIf="searchBusy" disabled>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-option>
                <mat-option *ngIf="!searchBusy && (tariffCodeSearchResult$ | async)?.length === 0" disabled>
                  No Results
                </mat-option>
                <ng-container *ngIf="!searchBusy">
                  <mat-option *ngFor="let result of tariffCodeSearchResult$ | async" [value]="result">
                    <span [innerHtml]="result.DisplayString"></span>
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-autocomplete>

          </mat-cell>
        </ng-container>

        <!-- NappiCode column -->
        <ng-container matColumnDef="nappiCode">
          <mat-header-cell *matHeaderCellDef> Nappi code </mat-header-cell>
          <mat-cell *matCellDef="let row" [formGroup]="row.form">
            <mat-form-field appearance="outline" class="table-field">
              <input id='rowNappiCodeInput' [matAutocomplete]="nappiSearchAutoComplete" matInput
                formControlName='NappiCode'>
            </mat-form-field>

            <mat-autocomplete #nappiSearchAutoComplete="matAutocomplete" (closed)="onNappiCodeSearchClosed()"
              (optionSelected)="onNappiCodeSelected($event, row.form)" panelWidth="40%">
              <ng-container *ngVar="nappiCodeSearchBusy$ | async as searchBusy">
                <mat-option *ngIf="searchBusy" disabled>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-option>
                <mat-option *ngIf="!searchBusy && (nappiCodeSearchResult$ | async)?.length === 0" disabled>
                  No Results
                </mat-option>
                <ng-container *ngIf="!searchBusy">
                  <mat-option *ngFor="let result of nappiCodeSearchResult$ | async" [value]="result">
                    <span [innerHtml]="result.DisplayString"></span>
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-autocomplete>
          </mat-cell>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let row" [formGroup]="row.form">
            <mat-form-field appearance="outline" class="table-field">
              <input matInput formControlName='Description' [disabled]="true">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Quantity column -->
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef> Qty </mat-header-cell>
          <mat-cell *matCellDef="let row" [formGroup]="row.form">
            <mat-form-field appearance="outline" class="table-field">
              <input type="number" matInput formControlName='Quantity'>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

      </mat-table>

      <button (click)="addLine()" mat-button
      [disabled]="invoiceTemplateForm.controls['Caption'].value === ''"
        class="!border-dashed !border-[1px] !border-hb-base-divider-grey text-center !py-2.5 !my-4">
        <div class="flex flex-row justify-center align-middle"
        [matTooltip]="invoiceTemplateForm.controls['Caption'].value === '' ? 'Please enter a name' : ''">
          <mat-icon class="mr-2 !text-hb-secondary-100 text-center">add</mat-icon><span
            class="text-sm leading-6 font-medium text-hb-secondary-100 text-center">Add a line</span>
        </div>
      </button>
    </div>

    <ng-container *ngIf="prescribeWarning$ | async">
      <meraki-flux-info-box  *ngIf="dialogData.type === templateTypes.INVOICE"  type="HELP" icon="info_outlined" class="mb-1">
        This template has prescribed medicines that can only be edited on Healthbridge Clinical.
        <div *ngFor="let med of prescribedMeds$ | async" class="font-medium">
          {{ med.NappiCode }} - {{ med.MedicationDescription }}
        </div>
      </meraki-flux-info-box>
    </ng-container>

    <mat-autocomplete #diagAutoComplete="matAutocomplete">
      <mat-option *ngFor="let option of tableColumns" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="flux-button" mat-stroked-button mat-dialog-close>Close</button>
    <button id='save' class="flux-button" mat-flat-button color="primary" [disabled]="!invoiceTemplateForm.valid"
      (click)="onDone()">
      Save
    </button>
  </mat-dialog-actions>
</div>