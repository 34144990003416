import {Injectable} from "@angular/core";
import {ProofOfSubmissionPdfGenerator} from "./pdf.proof-of-submission";
import {ReportUtils} from "../report-utils";
import {ProofOfSubmissionPdfReportDataBuilder} from "./proof-of-submission-pdf-report-data-builder";
import {ProofOfSubmissionReportBuilder} from "./proof-of-submission-report-builder";
import {
  BaseAccount, BaseInvoice, BasePdfReportData,
  Practice,
  ProofOfSubmissionReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class ProofOfSubmissionReportService {

  async generateReport(reportRequest: ProofOfSubmissionReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildProofOfSubmissionReportFileName(reportRequest.Account.MemberNo,
      REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = new ProofOfSubmissionPdfGenerator(await this.generatePdfReportData(reportRequest.Practice,
      reportRequest.Account, reportRequest.Invoice));

    return {
      fileName: fileName,
      format: REPORT_FORMAT_TYPE.PDF,
      reportName: REPORT_NAME.PROOF_OF_CLAIM_SUBMISSION,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    };
  }

  private async generatePdfReportData(practice: Practice, account: BaseAccount, invoice: BaseInvoice): Promise<BasePdfReportData> {
    return new ProofOfSubmissionPdfReportDataBuilder().build(
      await new ProofOfSubmissionReportBuilder().build(practice, account, invoice));
  }
}
