import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData, InvoiceDetailsItem, ProviderSummaryItem,
  ReferralProviderModel,
  ReferralReportModel,
  REPORT_NAME,
  Sheet,
  Table
} from "@meraki-flux/schema";

export class ReferralXlsReportDataBuilder {

    build(referralReportModel: ReferralReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = referralReportModel.ReferralReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.REFERRAL;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, referralReportModel);

        if (referralReportModel.AllProvidersModel)
            excelReportData.data.push(this.buildReportSheetSummary(referralReportModel.AllProvidersModel, referralReportModel));
            excelReportData.data.push(this.buildReportSheet(referralReportModel.AllProvidersModel,
                referralReportModel.ReferralReportInfo.IsMultiBranch, true));

        return excelReportData;
    }

    private buildReportSheetSummary(providerModel: ReferralProviderModel, referralReportModel: ReferralReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.tables = [];

        const summaryTable: Table = {};
        summaryTable.name = 'Summary of referrals from ' + referralReportModel.ReferralReportInfo.DateRange;
        summaryTable.headers = [
            {value: 'Referring Provider', bold: true, width: CellWidth.S},
            {value: 'No. of referrals', bold: true, width: CellWidth.XS},
            {value: 'Total invoiced amount', bold: true, width: CellWidth.S},
        ];

        let totalCount = 0;
        let totalInvoicedAmount = 0;

        const rows = [];
        providerModel.SummaryTable.sort((a, b) => a.ReferringProvider > b.ReferringProvider ? 1 : -1).forEach((item: ProviderSummaryItem) => {
            totalCount += item.TotalCount;
            totalInvoicedAmount += item.InvoicedAmount;
            const row: ExcelCellData[] = [];
            row.push({value: item.ReferringProvider});
            row.push({value: item.TotalCount});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: ExcelCellData[] = [];
        row.push({value: 'Total', bold: true});
        row.push({value: totalCount, type: CellType.NUMBER, bold: true});
        row.push({value: totalInvoicedAmount, type: CellType.CURRENCY, bold: true});
        rows.push(row);
        summaryTable.rows = rows;
        sheet.tables.push(summaryTable);
        return sheet;
    }

    private buildReportSheet(providerModel: ReferralProviderModel, addBranchColumn?: boolean,
                             addTreatingProviderColumn?: boolean): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Details';
        sheet.tables = [];

        const detailsTable: Table = {};
        detailsTable.headers = [
            {value: 'Referring provider', bold: true, width: CellWidth.S},
            {value: 'Speciality', bold: true, width: CellWidth.XS},
            {value: 'Date of service', bold: true, width: CellWidth.S}];
            if (addTreatingProviderColumn) {
              detailsTable.headers.push({value: 'Treating provider', bold: true, width: CellWidth.S});
          }
          detailsTable.headers.push(
            {value: 'Patient', bold: true, width: CellWidth.S},
            {value: 'Account no.', bold: true, width: CellWidth.XS},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS},
            {value: 'Invoice amount', bold: true, width: CellWidth.XS},
            );
        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.S});
        }

        const detailsRows = [];
        providerModel.DetailsTable.sort((a, b) => {
          if (a.ReferralProviderName !== b.ReferralProviderName
            && typeof a.ReferralProviderName !== 'undefined' && a.ReferralProviderName
            && typeof b.ReferralProviderName !== 'undefined' && b.ReferralProviderName) {
            return a.ReferralProviderName.localeCompare(b.ReferralProviderName);
          } else {
            return a.DateOfService > b.DateOfService ? 1 : -1;
          }
        }).forEach((item: InvoiceDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.ReferralProviderName}),
            row.push({value: item.Speciality}),
            row.push({value: item.DateOfService, type: CellType.GENERAL});
            if (addTreatingProviderColumn) {
              row.push({value: item.TreatingProviderName});
          }
            row.push({value: item.PatientName});
            row.push({value: item.AccountNo});
            row.push({value: item.InvoiceNo});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
              if (addBranchColumn)
              row.push({value: item.Branch});
            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, referralReportModel: ReferralReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: referralReportModel.ReferralReportInfo.Practice, type: CellType.GENERAL}
        });
        if (referralReportModel.ReferralReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: referralReportModel.ReferralReportInfo.Branch, type: CellType.GENERAL}
                });
        }
        excelReportData.parameters.push(
            {
                name: 'Referring Provider',
                data: {value: referralReportModel.ReferralReportInfo.ReferringProvider, type: CellType.GENERAL}
            });
        excelReportData.parameters.push(
              {
                  name: 'Treating Provider',
                  data: {value: referralReportModel.ReferralReportInfo.TreatingProvider, type: CellType.GENERAL}
              });
        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: referralReportModel.ReferralReportInfo.DateRange, type: CellType.GENERAL}
            });
    }
}
