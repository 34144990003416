import * as _ from "lodash";
import * as moment from "moment";

import { filter, reduce } from "rxjs/operators";
import { from } from "rxjs";
import {
  AssistantDetailsItem,
  AssistantProviderModel,
  AssistantReportInfo,
  AssistantReportModel,
  AssistantReportRequest, AssistantSummaryItem, Invoice, InvoiceLine, NoDataError, Provider, ProviderInvoices
} from "@meraki-flux/schema";

export class AssistantReportBuilder {

  // async build(reportRequest: AssistantReportRequest): Promise<AssistantReportRequest> {
  //   if (!reportRequest.Invoices) {
  //     throw new NoDataError();
  //   }
  //   reportRequest.Invoices = _.sortBy(reportRequest.Invoices, "Surname", "Name")
  //   return reportRequest;
  // }

  private readonly DATE_FORMAT = "DD/MM/YYYY";

  async build(reportRequest: AssistantReportRequest): Promise<AssistantReportModel> {
      const reportHeader: AssistantReportInfo = await this.buildReportInfo(reportRequest);
      const providersModel: AssistantProviderModel[] = await this.buildProvidersModel(reportRequest);

      this.checkDataAvailability(providersModel);

      let allProvidersModel: AssistantProviderModel = undefined;
      //if (!reportRequest.TreatingProvider && providersModel.length > 1) {
          allProvidersModel = this.buildAllProvidersModel(providersModel);
      //}
      return {
          AssistantReportInfo: reportHeader,
          ProviderModels: providersModel,
          AllProvidersModel: allProvidersModel,
          ReportDate: moment(new Date()).format(this.DATE_FORMAT),
      };
  }

  private checkDataAvailability(providersModel: AssistantProviderModel[]) {
    let noData = true;
    providersModel.forEach(providerModel => {
        if (providerModel.DetailsTable.length > 0) {
            noData = false;
        }
    });
    if (noData)
        throw new NoDataError();
}

  private async buildReportInfo(reportRequest: AssistantReportRequest): Promise<AssistantReportInfo> {
      const reportInfo: AssistantReportInfo = {};

      let fullTreatingProviderName: string;
      let speciality: string;
      if (reportRequest.TreatingProvider) {
          fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
      }

      let fullAssistingProviderName: string;
      if (reportRequest.AssistingProvider) {
          fullAssistingProviderName = this.formatProviderName(reportRequest.AssistingProvider.Provider);
          speciality = reportRequest.AssistingProvider.Provider.Speciality;
       }

      reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
      reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
      if (reportRequest.Practice?.IsMultiBranch === true)
          reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

      reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
      reportInfo.AssistingProvider = fullAssistingProviderName ? fullAssistingProviderName : 'All';
      //reportInfo.DateRangeType = reportRequest.DateRangeType;
      reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
      //reportInfo.InvoiceType = reportRequest.InvoiceType ? reportRequest.InvoiceType : 'All';
      //reportInfo.Scheme = reportRequest.Scheme ? reportRequest.Scheme : 'All';
      reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
      reportInfo.Speciality = speciality;
      reportInfo.DateFrom = moment(reportRequest.DateFrom).format('DD/MM/YYYY');
      reportInfo.DateTo = moment(reportRequest.DateTo).format('DD/MM/YYYY');

      return reportInfo;
  }

  private buildAllProvidersModel(providerTabs: AssistantProviderModel[]): AssistantProviderModel {
      const treatingProviderName = 'All providers';
      const summaryTable: AssistantSummaryItem[] = [];
      let detailsTable: AssistantDetailsItem[] = [];

      for (const providerTab of providerTabs) {
          providerTab.SummaryTable.forEach((summaryItem) => {
              let item: AssistantSummaryItem = summaryTable.find((item) => item.AssistingProvider === summaryItem.AssistingProvider);
              if (item == null) {
                  item = {...summaryItem};
                  summaryTable.push(item);
              } else {
                  item.NoOfINvoices += summaryItem.NoOfINvoices;
                  item.LineTotal0008 += summaryItem.LineTotal0008;
                  item.LineTotal0009 += summaryItem.LineTotal0009;
                  item.TotalInvoicedAmount += summaryItem.TotalInvoicedAmount;
              }
          })
          detailsTable = detailsTable.concat(providerTab.DetailsTable);
      }
      return {
          SummaryTable: summaryTable,
          DetailsTable: detailsTable,
          ProviderName: treatingProviderName
      };
  }

  private async buildProvidersModel(reportRequest: AssistantReportRequest): Promise<AssistantProviderModel[]> {
      const providerTab: AssistantProviderModel[] = [];
      const providerList = [];
      if (reportRequest.AssistingProvider) {
          providerList.push(reportRequest.AssistingProvider);
      } else if (reportRequest.AssistingProviders) {
          providerList.push(...reportRequest.AssistingProviders)
      }
      _.sortBy(providerList, 'Surname', 'Name');
      for (const provider of providerList) {
          providerTab.push(await this.buildProviderTabModel(provider));
      }
      return providerTab;
  }

  private async buildProviderTabModel(providerInvoice: ProviderInvoices): Promise<AssistantProviderModel> {

      const treatingProviderName: string = providerInvoice.Provider?.Title == 'Dr' ?
          `${providerInvoice.Provider?.Title} ${providerInvoice.Provider?.Name} ${providerInvoice.Provider?.Surname}` :
          `${providerInvoice.Provider?.Name} ${providerInvoice.Provider?.Surname}`;
      let summaryTable: AssistantSummaryItem[] = [];
      const detailsTable: AssistantDetailsItem[] = [];

      const invoices: Invoice[] = providerInvoice.Invoices
      if (invoices) {
          invoices.forEach((invoice: Invoice) => {
              _.sortBy(invoice.Lines, 'LineNumber').forEach((invoiceLine: InvoiceLine) => {
                const claimDetailsItem: AssistantDetailsItem = this.buildClaimDetails(invoice, invoiceLine, treatingProviderName);
                detailsTable.push(claimDetailsItem);
              });

          })
          summaryTable = this.updateSummaryTable(summaryTable, detailsTable, invoices);
      }
      return {
          SummaryTable: summaryTable,
          DetailsTable: detailsTable,
          ProviderName: treatingProviderName
      };
  }

  private updateSummaryTable(summaryTable: AssistantSummaryItem[], assistantDetailsItem: AssistantDetailsItem[], invoices: Invoice[]) {

    const map = new Map<string, AssistantDetailsItem[]>();
    for(const item of assistantDetailsItem){
        const assistProv = item.AssistingProvider?? "";
        const array = map.get(assistProv)?? [];
        array.push(item);
        map.set(assistProv, array);
    }

    const assistantSummary: AssistantSummaryItem[] = [];
    for(const [assistingProvider, array] of map.entries()){
        let total0008 = 0;
        let total0009 = 0;
        let totalInvoiceAmount = 0;
        let noOfInvoices = 0;

        const invoiceLines$ = from(array);

        const allInvoices = [];
        array.forEach((invoice, index) =>{
            if(!allInvoices.includes(invoice.InvoiceNo)){
                allInvoices.push(invoice.InvoiceNo);
            }
        });
        noOfInvoices = allInvoices.length

        const lines0008$ = invoiceLines$.pipe(filter((line) => line.TariffCode === "0008"));
        lines0008$
          .pipe(reduce((acc, line) => acc + line.AmountBilled, 0))
          .subscribe((res) => (total0008 = Number(res)));

        const lines0009$ = invoiceLines$.pipe(filter((line) => line.TariffCode === "0009"));
        lines0009$
          .pipe(reduce((acc, line) => acc + line.AmountBilled, 0))
          .subscribe((res) => (total0009 = Number(res)));

        invoiceLines$
          .pipe(reduce((acc, line) => acc + line.AmountBilled, 0))
          .subscribe((res) => (totalInvoiceAmount = Number(res)));

        let summaryItem: AssistantSummaryItem = summaryTable.find((item) => item.AssistingProvider === assistingProvider);
        if (!summaryItem) {
            summaryItem = {
                AssistingProvider: assistingProvider,
                NoOfINvoices: 0,
                LineTotal0008: 0,
                LineTotal0009: 0,
                TotalInvoicedAmount: 0
            };
            summaryTable.push(summaryItem);
        }
        summaryItem.NoOfINvoices += noOfInvoices;
        summaryItem.LineTotal0008 += total0008;
        summaryItem.LineTotal0009 += total0009;
        summaryItem.TotalInvoicedAmount += totalInvoiceAmount;
        assistantSummary.push(summaryItem);
    }
    return assistantSummary;
}

  private buildClaimDetails(invoice: any, invoiceLine: InvoiceLine, treatingProviderName: string): AssistantDetailsItem {
    const assistantDetailsItem: AssistantDetailsItem = {};

    const assistingText = invoice.AssistingProvider as string;
    if(assistingText != '')
    {
        assistantDetailsItem.AssistingProvider = assistingText;
        assistantDetailsItem.Speciality = invoice.AssistingSpeciality;
    }

    const treatingText = invoice.TreatingProviderName as string;
    if(treatingText != '')
    {
        assistantDetailsItem.TreatingProviderName = treatingText;
    }

    assistantDetailsItem.DateOfService = invoice.DateOfService ? moment(invoice.DateOfService).format(this.DATE_FORMAT) : "";
    assistantDetailsItem.PatientName = `${invoice.Patient?.Name??''} ${invoice.Patient?.Surname??''}`.trim();
    assistantDetailsItem.AccountNo = invoice.Account?.AccountNo;
    assistantDetailsItem.InvoiceType = invoice.Type;
    assistantDetailsItem.InvoiceNo = invoice.InvoiceNo;
    assistantDetailsItem.TariffCode = invoiceLine.TariffCode;
    assistantDetailsItem.AmountBilled = invoiceLine.AmountBilled;
    assistantDetailsItem.InvoicedAmount = invoice.AmountBilled;
    assistantDetailsItem.Branch = invoice.Branch;
    return assistantDetailsItem;
  }

  private formatProviderName(treatingProviderData: Provider | undefined): string {
    return treatingProviderData?.Title + ' ' + treatingProviderData?.Name + ' ' + treatingProviderData?.Surname;
  }

}
