import {Entity} from './general.models';
import {BaseInvoice} from './invoice.models';

export interface Invoice extends BaseInvoice, Entity {
  WriteOffType?: string;
  WriteOffDate?: string;
  WriteOffCapturedBy?: string;
  WriteOffHeaderAmount?: number;
  WriteOffHeaderAmountVAT?: number;
  WriteOffHeaderId?: string;
  WriteOffLimit?: string;
  CreditNotesType?: string;
  CreditNotesDate?: string;
  CapturedBy?: string;
  CreditNotesHeaderAmount?: number;
  CreditNotesHeaderAmountVAT?: number;
  CreditNotesHeaderId?: string;
  PaymentCorrectionDate?: string;
  LiabilityToIncrease?: string;
  PaymentCorrectionCapturedBy?: string;
  PaymentCorrectionInvoiceType?: string;
  Indicators?: Record<string, boolean>[]
}

export interface InvoiceSearchResult extends BaseInvoice {
  PatientName: string;
  AccountNo: string;
  Scheme: string;
  DateOfService: Date;
  ClaimStatus: string;
  Amount: number;
  MedicalAidLiable: number;
  PatientLiable: number;
  InvoiceId: string;
}

export enum INVOICE_DISPLAY_TYPE {
  CASH = 'Cash',
  CASH_DEBIT_NOTE = 'Cash - debit note invoices',
  MEDICAL_AID = 'Medical Aid',
  MEDICAL_INSURANCE = 'Medical insurance',
  NO_CHARGE = 'No Charge',
}

export enum INVOICE_ACTION {
  INVOICE_DETAILS = 'Invoice details',
  INVOICE_PDF = 'Invoice PDF',
  CLAIM_REPORT = 'Claim report',
  OPEN_ACCOUNT = 'Open account',
  PROOF_OF_SUBMISSION = 'Proof of submission',
  PROOF_OF_PAYMENT = 'Proof of payment',
  NOTES = 'Notes',
  CAPTURE_PATIENT_PAYMENT = 'Capture patient payment',
  CAPTURE_MEDICAL_AID_PAYMENT = 'Capture medical aid payment',
  EMAIL_INVOICE = 'Email invoice',
  REVERSE_CLAIM = 'Reverse claim',
  COPY_AND_CREATE_NEW = 'Copy & create new',
  EDIT_AND_RESUBMIT = 'Edit & resubmit',
  CANCEL_INVOICE = 'Cancel invoice',
  EMAIL_PROOF_OF_PAYMENT = 'Email proof of payment',
  REFRESH_BALANCE = 'Refresh balance',
}

export enum COPY_INVOICE_MODE {
  RELOAD_PRICE,
  COPY_PRICE,
}

export interface CopyInvoiceContext {
  sourceInvoiceId?: string;
  copyPriceMode?: COPY_INVOICE_MODE;
  editAndResubmit?: boolean;
}

export enum CAPTURE_INVOICE_MODE {
  NEW = 'New',
  EDIT = 'Edit',
}
