export enum PUBSUB_TOPIC {
  INTEGRATION_REQUEST = "integration-request",
}

export enum EXCEPTION_TYPE {
  GENERAL_EXCEPTION,
  AUTH_EXCEPTION
}

export class Exception extends Error {
  type: EXCEPTION_TYPE
}