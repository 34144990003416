import * as moment from "moment";
import * as _ from "lodash";
import {
  Invoice,
  NoDataError, PaymentCorrectionDetailsItem,
  PaymentCorrectionProviderModel,
  PaymentCorrectionReportInfo,
  PaymentCorrectionReportModel,
  PaymentCorrectionReportRequest, PaymentCorrectionSummaryItem, Provider, ProviderInvoices
} from "@meraki-flux/schema";

export class PaymentCorrectionsReportBuilder {

  private readonly DATE_FORMAT = "DD/MM/YYYY";

  async build(reportRequest: PaymentCorrectionReportRequest): Promise<PaymentCorrectionReportModel> {
    const reportHeader: PaymentCorrectionReportInfo = await this.buildReportInfo(reportRequest);
    const providersModel: PaymentCorrectionProviderModel[] = await this.buildProvidersModel(reportRequest);

    this.checkDataAvailability(providersModel);

    let allProvidersModel: PaymentCorrectionProviderModel = undefined;
    if (!reportRequest.TreatingProvider && providersModel.length > 1) {
        allProvidersModel = this.buildAllProvidersModel(providersModel);
    }
    return {
        PaymentCorrectionReportInfo: reportHeader,
        ProviderModels: providersModel,
        AllProvidersModel: allProvidersModel,
        ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
}

  private checkDataAvailability(providersModel: PaymentCorrectionProviderModel[]) {
    let noData = true;
    providersModel.forEach(providerModel => {
        if (providerModel.DetailsTable.length > 0) {
            noData = false;
        }
    });
    if (noData)
        throw new NoDataError();
}

private buildAllProvidersModel(providerTabs: PaymentCorrectionProviderModel[]): PaymentCorrectionProviderModel {
    const treatingProviderName = 'All providers';
    let summaryTable: PaymentCorrectionSummaryItem[] = [];
    let detailsTable: PaymentCorrectionDetailsItem[] = [];
    for (const providerTab of providerTabs) {
        detailsTable = detailsTable.concat(providerTab.DetailsTable);
        summaryTable = summaryTable.concat(this.updateSummaryTable(summaryTable, providerTab.DetailsTable, providerTab.ProviderName));

    }
    return {
        SummaryTable: summaryTable,
        DetailsTable: detailsTable,
        ProviderName: treatingProviderName
    };
}

private async buildReportInfo(reportRequest: PaymentCorrectionReportRequest): Promise<PaymentCorrectionReportInfo> {
    const reportInfo: PaymentCorrectionReportInfo = {};

    let fullTreatingProviderName: string;
    let speciality: string;
    if (reportRequest.TreatingProvider) {
        fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
        speciality = reportRequest.TreatingProvider.Provider.Speciality;
    }

    reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    if (reportRequest.Practice?.IsMultiBranch === true)
        reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

    reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
    reportInfo.DateRangeType = reportRequest.DateRangeType;
    reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
    reportInfo.Speciality = speciality;
    return reportInfo;
}

private async buildProvidersModel(reportRequest: PaymentCorrectionReportRequest): Promise<PaymentCorrectionProviderModel[]> {
    const providerTab: PaymentCorrectionProviderModel[] = [];
    const providerList = [];
    if (reportRequest.TreatingProvider) {
        providerList.push(reportRequest.TreatingProvider);
    } else if (reportRequest.TreatingProviders) {
        //providerList.push(...reportRequest.TreatingProviders)
        for (const treatingProvider of reportRequest.TreatingProviders) {
            if(treatingProvider.Invoices)
                providerList.push(treatingProvider);
        }
    }
    _.sortBy(providerList, 'Surname', 'Name');
    for (const provider of providerList) {
        providerTab.push(await this.buildProviderTabModel(provider));
    }
    return providerTab;
}

private async buildProviderTabModel(providerInvoice: ProviderInvoices): Promise<PaymentCorrectionProviderModel> {

    const treatingProviderName: string = providerInvoice.Provider.Title == 'Dr' ?
        `${providerInvoice.Provider.Title} ${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}` :
        `${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}`;
    let summaryTable: PaymentCorrectionSummaryItem[] = [];
    const detailsTable: PaymentCorrectionDetailsItem[] = [];

    const invoices: Invoice[] = providerInvoice.Invoices
    if (invoices) {
        invoices.forEach((invoice: Invoice) => {
            const paymentCorrectionDetailsItem: PaymentCorrectionDetailsItem = this.buildPaymentCorrectionDetails(invoice, treatingProviderName);
            detailsTable.push(paymentCorrectionDetailsItem);

        })
        summaryTable = this.updateSummaryTable(summaryTable, detailsTable);
    }
    return {
        SummaryTable: summaryTable,
        DetailsTable: detailsTable,
        ProviderName: treatingProviderName
    };
}

private buildPaymentCorrectionDetails(invoice: Invoice, treatingProviderName: string): PaymentCorrectionDetailsItem {
  const paymentCorrectionDetailsItem: PaymentCorrectionDetailsItem = {};
  paymentCorrectionDetailsItem.PaymentCorrectionDate = invoice.PaymentCorrectionDate ? moment(invoice.PaymentCorrectionDate).format(this.DATE_FORMAT) : "";
  paymentCorrectionDetailsItem.LiabilityToIncrease = invoice.LiabilityToIncrease;
  paymentCorrectionDetailsItem.CorrectionAmount = invoice.PaymentsCorrectionsAmount;
  paymentCorrectionDetailsItem.Reason = invoice.Reason;
  paymentCorrectionDetailsItem.CaptureBy = invoice.PaymentCorrectionCapturedBy;
  paymentCorrectionDetailsItem.AccountNo = invoice.Account?.AccountNo;
  paymentCorrectionDetailsItem.Patient = `${invoice.Patient?.Name??''} ${invoice.Patient?.Surname??''}`.trim();
  paymentCorrectionDetailsItem.InvoiceNo = invoice.InvoiceNo;
  paymentCorrectionDetailsItem.DateOfService = invoice.DateOfService ? moment(invoice.DateOfService).format(this.DATE_FORMAT) : "";
  paymentCorrectionDetailsItem.DateofSubmission = invoice.DateOfSubmission ? moment(invoice.DateOfSubmission).format(this.DATE_FORMAT) : "";
  paymentCorrectionDetailsItem.InvoiceAmount = invoice.AmountBilled;
  paymentCorrectionDetailsItem.Provider = treatingProviderName;
  paymentCorrectionDetailsItem.Branch = invoice.Branch;
  paymentCorrectionDetailsItem.InvoiceType = invoice.PaymentCorrectionInvoiceType;

  return paymentCorrectionDetailsItem;
}
private updateSummaryTable(summaryTable: PaymentCorrectionSummaryItem[], paymentCorrectionDetailsItem: PaymentCorrectionDetailsItem[], providerName?: string) {

    const paymentCorrectionSummary: PaymentCorrectionSummaryItem[] = paymentCorrectionDetailsItem.reduce((acc, curr) => {
        const providerIndex = acc.findIndex(item => item.Provider === curr.Provider);
        const paymentCorrectionType = curr.InvoiceType?.replace(/\s/g, "_") ;
        if (providerIndex === -1) {
        acc.push({
            Provider: curr.Provider,
            [paymentCorrectionType]: curr.CorrectionAmount
        });
        } else {
            acc[providerIndex][paymentCorrectionType] = (acc[providerIndex][paymentCorrectionType] || 0) + curr.CorrectionAmount;
        }
        return acc;
    }, []);

    if(paymentCorrectionSummary.length == 0){
        if(providerName != undefined){
            const paymentCorrectionSummaryBlank = {
              Provider: providerName,
              Payment_corrections_total: 0,
            };
            paymentCorrectionSummary.push(paymentCorrectionSummaryBlank);
        }
    }

    return paymentCorrectionSummary.sort(this.orderByProvider);

}
  private orderByProvider(a, b) {
      const providerA = a.Provider.toLowerCase();
      const providerB = b.Provider.toLowerCase();
      if (providerA < providerB) return -1;
      if (providerA > providerB) return 1;
      return 0;
    }



private formatProviderName(treatingProviderData: Provider | undefined): string {
  return treatingProviderData?.Title + ' ' + treatingProviderData?.Name + ' ' + treatingProviderData?.Surname;
}

}
