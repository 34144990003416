import {Injectable} from "@angular/core";
import {
  AgeAnalysisPatientLiableReportModel,
  AgeAnalysisReportRequest, BaseExcelReportData,
  LIABLE_TYPE,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {ReportUtils} from "../report-utils";
import {AgeAnalysisPatientLiableReportBuilder} from "./age-analysis-patient-liable-report-builder";
import {AgeAnalysisMedicalAidLiableReportBuilder} from "./age-analysis-medical-aid-liable-report-builder";
import {
  AgeAnalysisMedicalAidLiableXlsReportDataBuilder,
  AgeAnalysisPatientLiableXlsReportDataBuilder,
  BaseExcelGenerator
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class AgeAnalysisReportService {

  private readonly FORMAT: REPORT_FORMAT_TYPE = REPORT_FORMAT_TYPE.EXCEL;

  constructor(private patientLiableReportModelBuilder: AgeAnalysisPatientLiableReportBuilder,
              private medicalAidLiableReportModelBuilder: AgeAnalysisMedicalAidLiableReportBuilder) {
  }

  async generateReport(reportRequest: AgeAnalysisReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildAgeAnalysisReportFileName(
      reportRequest.Practice, reportRequest.LiableType, this.FORMAT);

    let xlsReportData: BaseExcelReportData;

    if (reportRequest.LiableType === LIABLE_TYPE.ACCOUNT_HOLDER) {
      const model: AgeAnalysisPatientLiableReportModel = await
        this.patientLiableReportModelBuilder.build(reportRequest);
      xlsReportData = new AgeAnalysisPatientLiableXlsReportDataBuilder().build(model);
    } else if (reportRequest.LiableType === LIABLE_TYPE.MEDICAL_AID) {
      xlsReportData = new AgeAnalysisMedicalAidLiableXlsReportDataBuilder().build(
        await this.medicalAidLiableReportModelBuilder.build(reportRequest));
    } else {
      throw new Error('Unsupported liable type for aging report');
    }

    const reportGenerator: BaseExcelGenerator = new BaseExcelGenerator(xlsReportData);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.AGE_ANALYSIS,
      format: this.FORMAT,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice?.Id
    }
  }
}
