import { ClaimDetailData, ReportContent, BasePdfReportData } from "@meraki-flux/schema";
import { BasePdfGenerator } from "../generator/pdf.base";
import { PDFMakeHelper } from "../helpers/pdf-make.helper";


/*
  Helper module to generate some common exceljs structures.
*/
export class BenefitCheckPdfGenerator extends BasePdfGenerator {

  private BenefitCheckData: ClaimDetailData

  public override generateReport(serverSide = false): ReportContent {
    this.generateReportName();
    // this.generateReportHeader();
    // this.generateItalicFilledLine('Claim Status')
    this.generateStatusSection();
    this.generateItalicFilledLine('Benefit check details')
    this.generateDetailsSection();
    if (this.data.tables && this.data.tables.length > 1) {
      this.generateItalicFilledLine('Claim details')
      this.generateTables();
    }
    this.generateBottomMessage();
    this.generateFooterTable();
    return this.buildReport();
  }

  protected generateReportHeader() {
    if (this.data.doctorName && this.data.bpn) {
      this.content.push(PDFMakeHelper.generateReportHeader({
        left: [
          { name: "Practice", data: { value: this.data.doctorName, decoration: { bold: true } } },
          { name: "Billing practice no.", data: { value: this.data.bpn, decoration: { bold: true } } }
        ]
      }))
    }
  }

  private generateItalicFilledLine(text: string) {
    this.content.push(PDFMakeHelper.generateItalicFilledHeader(text));
  }

  private generateStatusSection() {
    if (this.BenefitCheckData && this.BenefitCheckData.claimStatus) {
      this.content.push(PDFMakeHelper.generateClaimDetailsDataTable(this.BenefitCheckData.claimStatus))
    }
  }

  private generateDetailsSection() {
    if (this.BenefitCheckData && this.BenefitCheckData.details) {
      this.content.push(PDFMakeHelper.generateClaimDetailsDataTable(this.BenefitCheckData.details))
    }
  }

  override generateTables() {
    if (this.data.tables && this.data.tables.length > 0) {
      this.data.tables.forEach(table => {
        this.content.push(PDFMakeHelper.generateTableSection(table));
        this.content.push(PDFMakeHelper.generateItalicFilledHeader(''));
      });
      this.content.pop();
    }
  }

  constructor(data: BasePdfReportData) {
    super(data)
    this.BenefitCheckData = data.claimDetailData;
  }
}

