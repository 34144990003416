import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { CalendarEventService } from '../../services/calendar/calendar-event.service';
import { CalendarEventWhereBuilder } from '../../services/calendar/query/calendar-event-where-builder';
import { InvoiceWhereBuilder } from '../../services/invoice/invoice-where-builder';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { UserService } from '../../services/user.service';
import { CalendarUtils } from '../../utils/calendar-utils';
import { DateUtils } from '../../utils/date-utils';
import {
  ACCOUNT_TYPE,
  AppointmentReportInfo,
  AppointmentReportModel,
  AppointmentReportRequest,
  CALENDAR_EVENT_STATUS,
  CALENDAR_EVENT_TYPE,
  CalendarEvent,
  INVOICE_STATUS,
  NoDataError, PracticeProvider, ProviderAppointment,
  ProviderData, REPORT_NAME,
  TotalData,
  VisitTypeData
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class AppointmentReportBuilder {
  private readonly INACTIVE_APPOINTMENT_STATUSES = [
    CALENDAR_EVENT_STATUS.DELETED,
    CALENDAR_EVENT_STATUS.CANCELLED,
  ];

  constructor(
    private calendarEventService: CalendarEventService,
    private userService: UserService,
    private invoiceService: InvoiceService
  ) {}

  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: AppointmentReportRequest): Promise<AppointmentReportModel> {
    const reportHeader: AppointmentReportInfo = await this.buildReportInfo(reportRequest);

    const appointmentList: CalendarEvent[] = await this.getAppointments(reportRequest);

    const cancelledInvoiceIds: string[] = await this.getCancelledInvoiceIds(reportRequest);

    if (appointmentList.length == 0) {
      throw new NoDataError();
    }

    const providersData: ProviderData[] = await this.buildProvidersModel(
      reportRequest,
      appointmentList,
      cancelledInvoiceIds
    );
    let allProvidersModel = false;
    if (!reportRequest.SelectedTreatingProvider && providersData.length > 1) {
      allProvidersModel = true;
    }
    const totalData: TotalData = this.buildTotals(providersData);
    const visitTypes: VisitTypeData = this.buildVisitTypeData(appointmentList);

    return {
      ReportInfo: reportHeader,
      ProviderData: providersData,
      AllProviderModel: allProvidersModel,
      TotalData: totalData,
      VisitTypeData: visitTypes,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

  private async getAppointments(reportRequest: AppointmentReportRequest): Promise<CalendarEvent[]> {
    const providerIds = reportRequest.TreatingProviders.map((i) => i.HPCSANumber);
    const practiceId = reportRequest.Practice.Id;
    const branch = reportRequest.Multibranch ? reportRequest.BranchName : null;
    const patientId = reportRequest.Patient?.Id;
    let appointments = await this.calendarEventService.view(
      practiceId,
      CalendarEventWhereBuilder.builder()
        .eventType(CALENDAR_EVENT_TYPE.PATIENT_VISIT)
        .treatingProviders(providerIds)
        .branch(branch)
        .patientId(patientId)
        .startTimeGOE(reportRequest.DateFrom)
        .startTimeLOE(reportRequest.DateTo)
        .isRecurrentRoot(false)
        .build(),
      CalendarEventWhereBuilder.builder()
        .eventType(CALENDAR_EVENT_TYPE.PATIENT_VISIT)
        .treatingProviders(providerIds)
        .branch(branch)
        .patientId(patientId)
        .isRecurrentRoot(true)
        .build(),
      reportRequest.DateFrom,
      reportRequest.DateTo,
      providerIds
    );
    if (!reportRequest.IncludeInactiveAppointments) {
      appointments = appointments.filter(
        (a) => !this.INACTIVE_APPOINTMENT_STATUSES.includes(a.Status)
      );
    }
    return appointments;
  }

  private async getCancelledInvoiceIds(reportRequest: AppointmentReportRequest): Promise<string[]> {
    const wheres = InvoiceWhereBuilder.builder()
      .status(INVOICE_STATUS.CANCELLED)
      .dateOfServiceGOE(
        reportRequest?.DateFrom ? DateUtils.addMonths(reportRequest.DateFrom, -1) : undefined
      )
      .build(); // select cancelled invoices starting DateFrom - 1 month (just in case)
    const invoices = await this.invoiceService.invoices(wheres);
    return invoices.map((invoice) => invoice.Id);
  }

  private async buildProvidersModel(
    reportRequest: AppointmentReportRequest,
    appointmentList: CalendarEvent[],
    cancelledInvoiceIds: string[]
  ) {
    const providerData: ProviderData[] = [];
    for (const provider of reportRequest.TreatingProviders) {
      const appointmentListPerProvider = appointmentList
        .filter((i) => i.TreatingProvider === provider.HPCSANumber)
        .sort((c1, c2) => c1.StartTime?.getTime() - c2.StartTime?.getTime());
      providerData.push(
        await this.buildProviderDataModel(provider, appointmentListPerProvider, cancelledInvoiceIds)
      );
    }
    return providerData;
  }

  private async buildProviderDataModel(
    provider: PracticeProvider,
    appointments: CalendarEvent[],
    cancelledInvoiceIds: string[]
  ) {
    const treatingProviderName: string =
      provider.Title == 'Dr'
        ? `${provider.Title} ${provider.Name} ${provider.Surname}`
        : `${provider.Name} ${provider.Surname}`;
    const treatingProviderShortName: string =
      provider.Title == 'Dr'
        ? `${provider.Title} ${provider.Name.substr(0, 1).toUpperCase()} ${provider.Surname}`
        : `${provider.Name} ${provider.Surname}`;
    const existingPatientCount = appointments.reduce(
      (acc, cur) => acc + (cur.VisitInfo?.PatientInfo?.PatientId ? 1 : 0),
      0
    );
    const newPatientCount = appointments.length - existingPatientCount;
    const invoicedCount = appointments.reduce(
      (acc, cur) =>
        acc +
        (cur.VisitInfo?.Invoiced && !cancelledInvoiceIds?.includes(cur.VisitInfo?.InvoiceId)
          ? 1
          : 0),
      0
    );
    const nonInvoicedCount = appointments.length - invoicedCount;
    const recomedCount = 0; // TODO: add later
    const totalInvoicedAmount = appointments.reduce(
      (acc, cur) => acc + (this.getInvoiceAmount(cur, cancelledInvoiceIds) || 0),
      0
    );
    const providerAppointments: Promise<ProviderAppointment>[] = appointments.map(async (event) => {
      const fixedVisitTime = CalendarUtils.fixVisitDateTime(event.StartTime);
      return {
        Date: fixedVisitTime.format('DD/MM/YYYY'),
        Time: fixedVisitTime.format('hh:mm a'),
        Status: event?.Status,
        ClinicalStatus: event?.Status,
        VisitType: event.VisitInfo?.VisitType,
        Reason: event.VisitInfo?.VisitReasonDescription,
        AccountNo: event.VisitInfo?.AccountInfo?.AccountNo,
        FileNo: event.VisitInfo?.PatientInfo?.FileNumber || '',
        Patient: `${event.VisitInfo?.PatientInfo?.Surname}, ${event.VisitInfo?.PatientInfo?.Name}`,
        PatientCellphone: event.VisitInfo?.PatientInfo?.Cellphone,
        PatientEmail: event.VisitInfo?.PatientInfo?.Email,
        Scheme: event.VisitInfo?.AccountInfo?.SchemeName || '',
        MemberNo: event.VisitInfo?.AccountInfo?.MemberNumber || '',
        DependantCode: event.VisitInfo?.PatientInfo?.DependantCode || '',
        BC: event.VisitInfo?.BenefitCheckMessageId ? 'Y' : 'N',
        BCStatus:
          event.VisitInfo?.AccountInfo?.AccountType === ACCOUNT_TYPE.PRIVATE
            ? 'The account type is “Private”'
            : event.VisitInfo?.BenefitCheckStatus || '',
        InvoiceAmount: this.getInvoiceAmount(event, cancelledInvoiceIds),
        BCMessageId: event.VisitInfo?.BenefitCheckMessageId,
        InvoiceNo: this.getInvoiceNo(event, cancelledInvoiceIds),
        Resource: event.Resource,
        CreatedBy: await this.userService.userFullName(event.CreatedBy),
        CreatedAt: event.CreatedAt
          ? moment(DateUtils.toDate(event.CreatedAt)).format('DD/MM/YYYY HH:mm:ss')
          : null,
        Branch: event.Branch,
      } as ProviderAppointment;
    });
    return {
      ProviderShortName: treatingProviderShortName,
      ProviderName: treatingProviderName,
      ProviderAppointments: await Promise.all(providerAppointments),
      ExistingPatientCount: existingPatientCount,
      InvoicedCount: invoicedCount,
      NewPatientCount: newPatientCount,
      NotInvoicedCount: nonInvoicedCount,
      RecomedCount: recomedCount,
      TotalInvoicedAmount: totalInvoicedAmount,
    } as ProviderData;
  }

  private getInvoiceAmount(event: CalendarEvent, cancelledInvoiceIds: string[]) {
    if (cancelledInvoiceIds?.includes(event?.VisitInfo?.InvoiceId)) {
      return null;
    } else {
      return event.VisitInfo?.InvoiceAmount;
    }
  }

  private getInvoiceNo(event: CalendarEvent, cancelledInvoiceIds: string[]) {
    if (cancelledInvoiceIds?.includes(event?.VisitInfo?.InvoiceId)) {
      return null;
    } else {
      return event.VisitInfo?.InvoiceNo;
    }
  }

  private async buildReportInfo(
    reportRequest: AppointmentReportRequest
  ): Promise<AppointmentReportInfo> {
    const reportInfo: AppointmentReportInfo = {};

    reportInfo.ReportName = reportRequest.IncludeInvoiceData
      ? REPORT_NAME.APPOINTMENT_WITH_INVOICE
      : REPORT_NAME.APPOINTMENT;
    reportInfo.IncludeInvoiceData = reportRequest.IncludeInvoiceData;
    reportInfo.PatientName = reportRequest.Patient
      ? `${reportRequest.Patient.Name} ${reportRequest.Patient.Surname}`
      : 'All';

    reportInfo.Practice = reportRequest.Practice?.PracticeName;
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    if (reportRequest.Practice?.IsMultiBranch === true)
      reportInfo.Branch = reportRequest.BranchName ? reportRequest.BranchName : 'All';

    reportInfo.TreatingProvider = reportRequest.SelectedTreatingProvider
      ? this.formatProviderName(reportRequest.SelectedTreatingProvider)
      : 'All';
    reportInfo.DateRange =
      moment(reportRequest.DateFrom).format(this.DATE_FORMAT) +
      ' - ' +
      moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
    return reportInfo;
  }

  private formatProviderName(treatingProviderData: PracticeProvider | undefined): string {
    return (
      treatingProviderData?.Title +
      ' ' +
      treatingProviderData?.Name +
      ' ' +
      treatingProviderData?.Surname
    );
  }

  private buildTotals(providersData: ProviderData[]): TotalData {
    const totalData: TotalData = {
      ExistingPatientCount: 0,
      NewPatientCount: 0,
      InvoicedCount: 0,
      NotInvoicedCount: 0,
      RecomedCount: 0,
      TotalInvoicedAmount: 0,
    };
    for (const providerData of providersData) {
      totalData.ExistingPatientCount += providerData.ExistingPatientCount;
      totalData.NewPatientCount += providerData.NewPatientCount;
      totalData.InvoicedCount += providerData.InvoicedCount;
      totalData.NotInvoicedCount += providerData.NotInvoicedCount;
      totalData.RecomedCount += providerData.RecomedCount;
      totalData.TotalInvoicedAmount += providerData.TotalInvoicedAmount;
    }
    return totalData;
  }

  private buildVisitTypeData(appointmentList: CalendarEvent[]) {
    let totalCount = 0;
    const visitTypesMap = new Map<string, number>();
    appointmentList.forEach((event) => {
      if (event.VisitInfo?.VisitType) {
        const count = visitTypesMap.has(event.VisitInfo?.VisitType)
          ? visitTypesMap.get(event.VisitInfo?.VisitType) + 1
          : 1;
        visitTypesMap.set(event.VisitInfo.VisitType, count);
        totalCount += 1;
      }
    });
    return {
      TotalVisitCount: totalCount,
      CountPerVisitType: visitTypesMap,
    } as VisitTypeData;
  }
}
