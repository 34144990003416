
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';
import {DialogType, INVOICE_TEMPLATE_TYPE, InvoiceTemplateData} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-invoice-simple-template',
  templateUrl: './invoice-simple-template.component.html',
  styleUrls: ['./invoice-simple-template.component.scss'],
})
export class InvoiceSimpleTemplateComponent implements OnInit, AfterViewInit {

  @ViewChild('nameField') nameInput: ElementRef<HTMLInputElement>;

  simpleTemplateForm: UntypedFormGroup = this.fb.group({
    name: new UntypedFormControl('', RxwebValidators.required()),
    sortOrder: new UntypedFormControl(0)
  });

  codeHeader = 'Tariff code';
  description: string;
  code: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: InvoiceTemplateData,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<InvoiceSimpleTemplateComponent>,
    private firestore: Firestore,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.simpleTemplateForm.patchValue({
      name: this.dialogData.invoiceTemplate?.Caption || '',
      sortOrder: ((this.dialogData.maxOrderNo < 0 ? 0 : this.dialogData.maxOrderNo) + 1)
    });
    this.setHeaderValues();
  }

  ngAfterViewInit(): void {
    // Select the prepopulated value so the user can immediately start typing to replace
    if (this.nameInput?.nativeElement) {
      setTimeout(() => {
        this.nameInput.nativeElement.select();
      }, 0);
    }
  }

  private setHeaderValues() {
    this.description = this.dialogData.invoiceTemplate?.Caption || '';

    switch (this.dialogData.type) {
      case INVOICE_TEMPLATE_TYPE.MEDICINE:
      case INVOICE_TEMPLATE_TYPE.CONSUMABLE: {
        this.codeHeader = 'NAPPI code';
        const line = this.dialogData.invoiceTemplate.Lines?.length > 0 ? this.dialogData.invoiceTemplate?.Lines[0] : null;
        if (line) {
          this.code = line.NappiCode;
          this.description = line.Description;
        }
      }
        break;
      case INVOICE_TEMPLATE_TYPE.DIAGNOSIS: {
        this.codeHeader = 'ICD10 code';
        const line = this.dialogData.invoiceTemplate?.Diagnosis?.length > 0 ? this.dialogData.invoiceTemplate?.Diagnosis[0] : null;
        if (line) {
          this.code = line.Code;
          this.description = line.Description;
        }
      }
        break;
      case INVOICE_TEMPLATE_TYPE.PROCEDURE: {
        const line = this.dialogData.invoiceTemplate?.Lines?.length > 0 ? this.dialogData.invoiceTemplate?.Lines[0] : null;
        if (line) {
          this.code = line.ChargeCode;
          this.description = line.Description;
        }
      }
        break;
    }
  }

  async onDone() {
    this.simpleTemplateForm.markAllAsTouched();

    if (!this.simpleTemplateForm.valid) return;

    const invoiceTemplate = this.dialogData.invoiceTemplate;
    invoiceTemplate.Caption = this.simpleTemplateForm.get('name')?.value || '';
    invoiceTemplate.SortOrder = this.simpleTemplateForm.get('sortOrder')?.value || 0;

    //check in firestore if the template exists with the same name and type
    const templateExists = (await getDocs(query(collection(this.firestore,
      `Practice/${this.auth.selectedBPN}/Provider/${this.dialogData.providerId}/InvoiceTemplate`),
      where('Caption', '==', invoiceTemplate.Caption.trim().toLowerCase()),
      where('Type', '==', invoiceTemplate.Type),
      where('IsActive', '==', invoiceTemplate.IsActive)))).size > 0;

    //if template exists, show error popup
    if (templateExists) {
      this.dialogService.showDialog({
        type: DialogType.WARNING,
        title: 'Warning',
        message: 'There is already a favourite of that name. Please choose a different name.'
      });
      return;
    }
    //remove trailing spaces from description
    invoiceTemplate.Caption = invoiceTemplate.Caption.trim();
    this.dialogRef.close(invoiceTemplate);
  }
}
