import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import {AlertOptions, DialogType} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-system-alert',
  templateUrl: './system-alert.component.html',
  styleUrls: ['./system-alert.component.scss'],
})
export class SystemAlertComponent {

  dialogTypes = DialogType;
  displayCloseButton = true;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) protected options: AlertOptions,
    private snackbarRef: MatSnackBarRef<SystemAlertComponent>) {
      if(options.title == 'The newest version of Nova is now available') {
        this.displayCloseButton = false;
      }else{
        this.displayCloseButton = true;
      }
    }

  execAction() {
    this.options.action?.callback();
  }

  close() {
    this.snackbarRef.dismiss();
  }

}
