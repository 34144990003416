import { Injectable } from '@angular/core';
import {Payment, PAYMENT_TYPE, Transaction, TRANSACTION_TYPE} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class TransactionUtils {
  static getActiveTransactions(transactions: Transaction[]) {
    const reversedTransactionIds = transactions
      .filter((tx) => TRANSACTION_TYPE.TRANSACTION_REVERSE === tx.TransactionType)
      .map((tx) => tx?.Metadata?.ReferenceTransactionId);
    return transactions.filter(
      (tx) =>
        TRANSACTION_TYPE.TRANSACTION_REVERSE !== tx.TransactionType &&
        !reversedTransactionIds.includes(tx.Id)
    );
  }

  static getPaymentTransactionDisplayType(payment: Payment) {
    let displayType;
    if (payment?.Type === PAYMENT_TYPE.MEDICAL_AID) {
      displayType = 'Medical aid';
    } else {
      displayType = `Patient - ${payment?.Type}`;
    }
    if (
      payment?.AmountPaid &&
      payment?.AmountRefunded &&
      payment?.AmountPaid === payment?.AmountRefunded
    ) {
      displayType += ' (refunded)';
    }
    return displayType;
  }
}
