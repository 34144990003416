import {
  AppointmentReportModel,
  BaseExcelReportData, CellType,
  CellWidth,
  ExcelCellData, ProviderData,
  Sheet,
  Table
} from '@meraki-flux/schema';
import { FormatUtils } from '../../utils/format-utils';

export class AppointmentXlsReportDataBuilder {

  build(report: AppointmentReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    this.buildReportInfo(excelReportData, report);
    excelReportData.practiceId = report.ReportInfo.PracticeId;
    excelReportData.name = report.ReportInfo.ReportName;
    excelReportData.data = [];
    excelReportData.data.push(this.buildSummarySheet(report))

    if (!report.AllProviderModel) {
      report.ProviderData.forEach((providerModel) => {
        excelReportData.data.push(
            this.buildReportSheet(providerModel,
              report.ReportInfo.IsMultiBranch, report.ReportInfo.IncludeInvoiceData)
        );
      });
    } else {

        report.ProviderData.forEach((providerModel) => {
          excelReportData.data.push(
              this.buildReportSheet(providerModel,
                report.ReportInfo.IsMultiBranch, report.ReportInfo.IncludeInvoiceData)
          );
        });

        excelReportData.data.push(
          this.buildReportSheetAllProviders(report.ProviderData,
            report.ReportInfo.IsMultiBranch, report.ReportInfo.IncludeInvoiceData)
      );
    }

    return excelReportData;
  }

  private buildSummarySheet(report: AppointmentReportModel): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    if (report.ReportInfo.IncludeInvoiceData)
    {
      sheet.header = `Appointments (Incl. Invoices) Report`
    }
    else
    {
      sheet.header = `Appointments Report`
    }

    sheet.tables = [];

    // 'Existing vs New' table
    sheet.tables.push(this.buildExistingVsNewTable(report));

    // 'Invoice summary per provider' table
    if (report.ReportInfo.IncludeInvoiceData)
      sheet.tables.push(this.buildInvoiceSummaryPerProviderTable(report));

    // 'Visit type summary' table
    sheet.tables.push(this.buildVisitTypeSummaryTable(report));

    return sheet;
  }

  private buildInvoiceSummaryPerProviderTable(report: AppointmentReportModel): Table {
    const table: Table = {};
    table.name = 'Invoice summary per provider';
    table.nameBGColor = 'DDDDDDDD'
    table.nameMergeCount = 4;
    table.headers = [
      {value: ''},
      {value: '# Appts not invoiced', bold: true, width: CellWidth.XS},
      {value: '# Appts invoiced', bold: true, width: CellWidth.XS},
      {value: 'Total invoiced', bold: true, width: CellWidth.XS}];
    table.noHeaderBackground = true;

    const rows = [];
    report.ProviderData.forEach(provider => {
      const row: ExcelCellData[] = [];
      row.push({value: provider.ProviderName});
      row.push({value: provider.NotInvoicedCount, type: CellType.NUMBER});
      row.push({value: provider.InvoicedCount, type: CellType.NUMBER});
      row.push({value: provider.TotalInvoicedAmount, type: CellType.CURRENCY});
      rows.push(row);
    });

    const totalRow = [{value: 'Total', bold: true},
      {value: report.TotalData.NotInvoicedCount, bold: true, type: CellType.NUMBER},
      {value: report.TotalData.InvoicedCount, bold: true, type: CellType.NUMBER},
      {value: report.TotalData.TotalInvoicedAmount, bold: true, type: CellType.CURRENCY}];
    rows.push(totalRow);

    table.rows = rows;

    return table;
  }

  private buildExistingVsNewTable(report: AppointmentReportModel): Table {
    const table: Table = {};
    table.name = 'Existing vs. new patients';
    // adding fill color to the table name
    table.nameBGColor = 'DDDDDDDD'
    table.nameMergeCount = 3;
    table.headers = [
      {value: '', width: CellWidth.M},
      {value: 'Existing patients', bold: true, width: CellWidth.XS},
      {value: 'New patients', bold: true, width: CellWidth.XS}];
    if (report.ReportInfo.IncludeRecomedData)
      table.headers.push({value: 'Recomed online bookings', bold: true});
    table.noHeaderBackground = true;

    const rows = [];
    report.ProviderData.forEach(provider => {
      const row: ExcelCellData[] = [];
      row.push({value: provider.ProviderName});
      row.push({value: provider.ExistingPatientCount, type: CellType.NUMBER});
      row.push({value: provider.NewPatientCount, type: CellType.NUMBER});
      if (report.ReportInfo.IncludeRecomedData)
        row.push({value: provider.RecomedCount, type: CellType.NUMBER});
      rows.push(row);
    });
    const totalRow = [{value: 'Total', bold: true},
      {value: report.TotalData.ExistingPatientCount, bold: true},
      {value: report.TotalData.NewPatientCount, bold: true}];
    if (report.ReportInfo.IncludeRecomedData)
      totalRow.push({value: report.TotalData.RecomedCount, bold: true});

    rows.push(totalRow);

    table.rows = rows;
    return table;
  }

  private buildVisitTypeSummaryTable(report: AppointmentReportModel): Table {
    const table: Table = {};
    table.name = 'Visit type summary';
    table.nameBGColor = 'DDDDDDDD'
    table.nameMergeCount = 2;
    table.headers = [
      {value: 'Visit type', bold: true, width: CellWidth.M},
      {value: '#', bold: true, width: CellWidth.XS, type: CellType.NUMBER}];

    table.noHeaderBackground = true;
    const rows = [];
    const visitTypes = Array.from(report.VisitTypeData.CountPerVisitType.keys()).sort();

    visitTypes.forEach(visitType => {
      const row: ExcelCellData[] = [];
      row.push({value: visitType});
      row.push({value: report.VisitTypeData.CountPerVisitType.get(visitType)});
      rows.push(row);
    });
    rows.push([{value: 'Total', bold: true} as ExcelCellData, {
      value: report.VisitTypeData.TotalVisitCount,
      bold: true
    } as ExcelCellData])
    table.rows = rows;
    return table;
  }

  private buildReportSheet(providerModel: ProviderData, addBranchColumn?: boolean, addInvoiceColumn?: boolean): Sheet {
    const sheet: Sheet = {};
    sheet.name = providerModel.ProviderShortName;
    sheet.header = `${addInvoiceColumn ? 'Appointments (Incl. Invoices)' : 'Appointments'} Report: ${providerModel.ProviderName}`

    sheet.tables = [];

    const table: Table = {};
    table.headers = [
      {value: 'Date', bold: true, width: CellWidth.XS},
      {value: 'Time', bold: true, width: CellWidth.XS},
      {value: 'Appt status', bold: true, width: CellWidth.XS},
      {value: 'Visit type', bold: true, width: CellWidth.XS},
      {value: 'Reason for visit', bold: true, width: CellWidth.XS},
      {value: 'Account no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'File no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'Patient', bold: true, width: CellWidth.S},
      {value: 'Patient cell no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'Patient email', bold: true, width: CellWidth.XS},
      {value: 'Scheme', bold: true, width: CellWidth.S},
      {value: 'Member no.', bold: true, width: CellWidth.XS},
      {value: 'BC (Y/N)', bold: true, width: CellWidth.XS},
      {value: 'BC status', bold: true, width: CellWidth.S},
      {value: 'BC message ID', bold: true, width: CellWidth.XS}];

    if (addBranchColumn) {
      table.headers.splice(0, 0, {value: 'Branch', bold: true, width: CellWidth.S})
    }

    if (addInvoiceColumn) {
      table.headers.push({value: 'Invoice amt.', bold: true, width: CellWidth.XS})
      table.headers.push({value: 'Invoice no.', bold: true, width: CellWidth.XS})
    }

    table.headers.push({value: 'Resource', bold: true, width: CellWidth.XS})
    table.headers.push({value: 'Appt created by', bold: true, width: CellWidth.XS})
    table.headers.push({value: 'Appt create date/time', bold: true, width: CellWidth.XS})

    const rows = [];
    providerModel.ProviderAppointments.forEach(event => {
      const row: ExcelCellData[] = [
        {value: event.Date},
        {value: event.Time},
        {value: event.Status},
        {value: event.VisitType},
        {value: event.Reason},
        {value: event.AccountNo, hAlignment:'left'},
        {value: event.FileNo, hAlignment:'left'},
        {value: event.Patient},
        {value: event.PatientCellphone, hAlignment:'left'},
        {value: event.PatientEmail},
        {value: event.Scheme},
        {value: FormatUtils.memberNoDepCode(event.MemberNo, event.DependantCode)},
        {value: event.BC},
        {value: event.BCStatus},
        {value: event.BCMessageId}
      ];
      if (addBranchColumn) {
        row.splice(0, 0, {value: event.Branch});
      }
      if (addInvoiceColumn) {
        row.push({value: event.InvoiceAmount, type: CellType.CURRENCY});
        row.push({value: event.InvoiceNo});
      }

      row.push({value: event.Resource});
      row.push({value: event.CreatedBy});
      row.push({value: event.CreatedAt});
      rows.push(row);
    })
    table.rows = rows;

    sheet.tables.push(table);
    return sheet;
  }

  private buildReportSheetAllProviders(providerModel: ProviderData[], addBranchColumn?: boolean, addInvoiceColumn?: boolean): Sheet {
    const sheet: Sheet={};
    sheet.name = 'All providers';
    sheet.header = `${addInvoiceColumn ? 'Appointments (Incl. Invoices)' : 'Appointments'} Report: All Providers`

    sheet.tables = [];

    const table: Table = {};
    table.headers = [
      {value: 'Date', bold: true, width: CellWidth.XS},
      {value: 'Time', bold: true, width: CellWidth.XS},
      {value: 'Appt status', bold: true, width: CellWidth.XS},
      {value: 'Visit type', bold: true, width: CellWidth.XS},
      {value: 'Reason for visit', bold: true, width: CellWidth.XS},
      {value: 'Account no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'File no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'Patient', bold: true, width: CellWidth.S},
      {value: 'Patient cell no.', bold: true, width: CellWidth.XS, hAlignment:'left'},
      {value: 'Patient email', bold: true, width: CellWidth.XS},
      {value: 'Scheme', bold: true, width: CellWidth.S},
      {value: 'Member no.', bold: true, width: CellWidth.XS},
      {value: 'BC (Y/N)', bold: true, width: CellWidth.XS},
      {value: 'BC status', bold: true, width: CellWidth.S},
      {value: 'BC message ID', bold: true, width: CellWidth.XS}];

      if (addBranchColumn) {
        table.headers.splice(0, 0, {value: 'Branch', bold: true, width: CellWidth.XS})
      }
      table.headers.splice(1, 0, {value: 'Provider', bold: true, width: CellWidth.S})

    if (addInvoiceColumn) {
      table.headers.push({value: 'Invoice amt.', bold: true, width: CellWidth.XS})
      table.headers.push({value: 'Invoice no.', bold: true, width: CellWidth.XS})
    }

    table.headers.push({value: 'Resource', bold: true, width: CellWidth.XS})
    table.headers.push({value: 'Appt created by', bold: true, width: CellWidth.XS})
    table.headers.push({value: 'Appt create date/time', bold: true, width: CellWidth.XS})

    const rows = [];
    providerModel.forEach(event => {
      event.ProviderAppointments.forEach(ev=>{

        const row: ExcelCellData[] =
        [
          {value: ev.Date},
          {value: ev.Time},
          {value: ev.Status},
          {value: ev.VisitType},
          {value: ev.Reason},
          {value: ev.AccountNo, hAlignment:'left'},
          {value: ev.FileNo, hAlignment:'left'},
          {value: ev.Patient},
          {value: ev.PatientCellphone, hAlignment:'left'},
          {value: ev.PatientEmail},
          {value: ev.Scheme},
          {value: FormatUtils.memberNoDepCode(ev.MemberNo, ev.DependantCode)},
          {value: ev.BC},
          {value: ev.BCStatus},
          {value: ev.BCMessageId}
        ];

        if (addBranchColumn) {
          row.splice(0, 0, {value: ev.Branch});
        }
        row.splice(1, 0, {value: event.ProviderName});

        if (addInvoiceColumn) {
          row.push({value: ev.InvoiceAmount, type: CellType.CURRENCY});
          row.push({value: ev.InvoiceNo});
        }

        row.push({value: ev.Resource});
        row.push({value: ev.CreatedBy});
        row.push({value: ev.CreatedAt});
        rows.push(row);
      })

    })
    table.rows = rows;

    sheet.tables.push(table);
    return sheet;
  }

  private buildReportInfo(excelReportData: BaseExcelReportData, appointmentReportModel: AppointmentReportModel) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: {value: appointmentReportModel.ReportInfo.Practice, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Billing practice no.',
      data: {value: appointmentReportModel.ReportInfo.PracticeId, type: CellType.GENERAL}
    });
    if (appointmentReportModel.ReportInfo.IsMultiBranch) {
      excelReportData.parameters.push(
        {
          name: 'Branch',
          data: {value: appointmentReportModel.ReportInfo.Branch, type: CellType.GENERAL}
        });
    }
    excelReportData.parameters.push(
      {
        name: 'Date Range',
        data: {value: appointmentReportModel.ReportInfo.DateRange, type: CellType.GENERAL}
      });
    excelReportData.parameters.push(
      {
        name: 'Treating Provider',
        data: {value: appointmentReportModel.ReportInfo.TreatingProvider, type: CellType.GENERAL}
      });
  }
}
