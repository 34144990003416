import {ActivatedRoute} from '@angular/router';
import {AccountStatusChangedAudit, BaseAccount} from './account.models';
import {Entity} from './general.models';
import {CalendarEvent} from './calendar.models';
import {AccountMember} from './member.models.ui';
import {Invoice} from './invoice.models.ui';
import {CREDIT_TYPE} from './payment.models';
import { FamCheckPatientRecord, FamCheckResponseDetails } from './familycheck-models';

export interface Account extends BaseAccount, Entity {}

export interface AccountMoveModalData {
  moveMethod?: ACCOUNT_MEMBER_MOVE_METHOD | null;
  member: AccountMember;
  currentAccountId: string;
  route: ActivatedRoute;
}
export interface AddAccountCalendarContext {
  calendarEvent?: CalendarEvent;
  // eslint-disable-next-line @typescript-eslint/ban-types
  saveCallback?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  cancelCallback?: Function;
}

export interface AddAccountCalendarCallbackResult {
  accountId?: string;
  checkedInPatientId?: string;
  accountData?: Partial<Account>;
  patientData?: Partial<AccountMember>;
}

export interface AccountMemberMoveContext {
  accountId?: string;
  member?: AccountMember;
  // eslint-disable-next-line @typescript-eslint/ban-types
  saveCallback?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  cancelCallback?: Function;
  route?: ActivatedRoute;
}

export interface AccountMemberMoveData {
  accountId?: string;
  member?: AccountMember;
  route?: ActivatedRoute;
}

export interface FamCheckResult {
  records?: FamCheckPatientRecord[];
  responseDetails?: FamCheckResponseDetails;
  success?: boolean;
  reason?: string;
}

export interface FamCheckModalData {
  account?: Account;
  mainMember?: AccountMember;
}

export interface LookupResult {
  success?: boolean;
  message?: string;
  error?: LOOKUP_ERROR;
  records?: FamCheckPatientRecord[];
}

export interface NoteModalData {
  Type: NOTE_TYPE;
  AccountId: string;
  AccountNo: string;
  Account?: Account;
  Name?: string;
  Surname?: string;
  InvoiceId?: string;
  InvoiceNo?: string;
}

export interface NoteIdCollectionNest {
  Id: string;
  CollectionName: string;
}

export interface Row {
  busyProcessing?: boolean;
}
export interface TransactionResult extends Row {
  TransactionId?: string;
  Date?: Date;
  RefNo?: string;
  Patient: string;
  Type: FILTERABLE_TRANSACTION_TYPE;
  DisplayType: string;
  AmountLiable?: number;
  Amount?: number;
  HeaderId?: string;
  CaptureDate?: Date;
  CapturedBy?: string;
}

export interface InvoiceTransactionLine {
  Code: string;
  PatientLiableAmount: number;
  MedicalAidLiableAmount: number;
  InvoicedAmount: number;
}

export interface InvoiceTransactionResult extends TransactionResult {
  Invoice: Invoice;
  Lines: InvoiceTransactionLine[];
  PatientLiableTotal: number;
  MedicalAidLiableTotal: number;
  InvoicePath: string;
  DisplayStatus: string;
}

export interface PaymentTransactionResult extends TransactionResult {
  PaymentId: string;
  ReceiptNo?: string;
  Invoices?: string[];
  DisplayInvoices?: string;
  AmountUnallocated?: number;
  AmountRefunded?: number;
  PaymentMethod?: string;
  RANo?: string;
  SchemeName?: string;
}

export interface WriteOffTransactionResult extends TransactionResult {
  InvoiceNo?: string;
  Reason?: string;
}

export interface CreditTransactionResult extends TransactionResult {
  InvoiceNo?: string;
  Reason?: string;
  CreditType?: CREDIT_TYPE;
}

export interface PaymentCorrectionTransactionResult extends TransactionResult {
  IncreasesPL?: boolean;
  IncreasesMAL?: boolean;
  InvoiceNo?: string;
  Reason?: string;
}

export interface TransactionQuery {
  DateFrom?: Date;
  DateTo?: Date;
  Type?: FILTERABLE_TRANSACTION_TYPE;
}

export enum ACCOUNT_ACTIONS {
  CAPTURE_INVOICE = 'Capture invoice',
  CREDIT_NOTE = 'Credit note',
  DEBIT_NOTE = 'Debit note',
  EDIT_ACCOUNT_DETAILS = 'Edit account details',
  EMAIL_STATEMENT = 'Email statement',
  GENERATE_STATEMENT = 'Generate statement',
  MEDICAL_AID_PAYMENT = 'Medical aid payment',
  NOTES = 'Notes',
  PATIENT_PAYMENT = 'Patient payment',
  SEND_SMS = 'Send SMS',
  UNALLOCATED_CREDIT = 'Unallocated credit',
  WRITE_OFF = 'Write off',
  DELETE_ACCOUNT = 'Delete account',
  EDIT_ACCOUNT_MEMBER = 'Edit account member',
}

export enum ACCOUNT_MEMBER_MOVE_METHOD {
  NEW = 'New',
  EXISTING = 'Existing',
}

export enum CAPTURE_RA_TYPE {
  MEDICAL_AID = 'Medical Aid',
  MEDICAL_INSURER = 'Medical Insurer',
}

export enum LIABILITY_TYPE {
  MAL = 'Medical Aid Liable',
  PL = 'Patient Liable',
}

export enum NOTE_FILTER_TYPE {
  ALL_USERS = 'All users',
  ALL_USERS_AND_SYSTEM = 'All users and system',
  SYSTEM = 'System',
}

export enum NOTE_TYPE {
  ACCOUNT = 'Account',
  INVOICE = 'Invoice',
}

export enum TRANSACTION_ACTION {
  VIEW_RECEIPT,
  UNALLOCATE,
  REVERSE,
}

export enum UNALLOCATED_CREDIT_ACTION {
  ALLOCATE_TO_INVOICES = 'Allocate to invoices',
  REFUND_PATIENT = 'Refund patient',
}

export enum IMPORT_ACTION {
  SAME_PERSON = 'is the same person as',
  IMPORT = 'is not on my system, import',
  DO_NOTHING = 'is not on my system, do nothing',
}

export enum LOOKUP_ERROR {
  NOT_FOUND,
}

export enum FILTERABLE_TRANSACTION_TYPE {
  ALL = 'All',
  INVOICE = 'Invoice',
  MA_PAYMENT = 'Medical aid payment',
  PATIENT_PAYMENT = 'Patient payment',
  CREDIT_NOTE = 'Credit note',
  WRITE_OFF = 'Write-off',
  PAYMENT_CORRECTION = 'Payment correction',
  REFUND = 'Refund',
}

// TODO : move FUNCTIONS to utility class to avoif mising business logic and models
/* FUNCTIONS */
export function instanceOfAccountStatusChangedAudit(
  object: any
): object is AccountStatusChangedAudit {
  return 'NewStatus' in object && 'Reason' in object;
}
