import {
  BaseExcelReportData, CellType,
  CellWidth, ClaimSummaryItem, ExcelCellData,
  REPORT_NAME,
  Sheet,
  Table, UnroutableClaimDSData,
  UnroutableClaimsReportModel
} from "@meraki-flux/schema";

export class UnroutableClaimsXlsReportDataBuilder {


    build(unroutableClaimReportModel: UnroutableClaimsReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.UNROUTABLE_CLAIMS;
        excelReportData.data = [];

        excelReportData.data.push(this.buildReportSheetSummary(unroutableClaimReportModel));
        excelReportData.data.push(this.buildReportSheetDetail(unroutableClaimReportModel, unroutableClaimReportModel.ReportInfo.IsMultiBranch));

        this.buildReportInfo(excelReportData,unroutableClaimReportModel);

        return excelReportData;
    }

    private buildReportSheetSummary(unroutableClaimReportModel: UnroutableClaimsReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.tables = [];

        //Scheme summary
        const schemeTable: Table = {};
        schemeTable.headers = [
            {value: 'Scheme', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'No. of claims', bold: true, width: CellWidth.XS, wordWrap: true, hAlignment:'right'},
            {value: 'Total invoiced', bold: true, width: CellWidth.S, wordWrap: true, hAlignment:'right'},
            {value: 'Medical aid liable', bold: true, width: CellWidth.S, wordWrap: true, hAlignment:'right'},
            {value: 'Patient liable', bold: true, width: CellWidth.S, wordWrap: true, hAlignment:'right'}
        ];

        const schemeRows = [];

        const schemeSummary = unroutableClaimReportModel.UnroutableReportModel.ClaimSummaryItem;
        schemeSummary.sort((a,b) => a.Scheme.localeCompare(b.Scheme));

        let totalClaims = 0;
        let ttotalInvoicedValue = 0;
        let ttotalMAValue = 0;
        let ttotalPALValue = 0;

        schemeSummary.forEach((item: ClaimSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Scheme });
            row.push({value: item.NoOfClaims, type: CellType.NUMBER});
            row.push({value: item.TotalInvoicedValue, type: CellType.CURRENCY});
            row.push({value: item.TotalMALValue, type: CellType.CURRENCY});
            row.push({value: item.TotalPALValue, type: CellType.CURRENCY});
            totalClaims= totalClaims + item.NoOfClaims;

            schemeRows.push(row);
        });

        const totalRowScheme: ExcelCellData[] = [];
        totalRowScheme.push({value: 'Totals', bold: true});
        totalRowScheme.push({value: totalClaims, bold: true});

        ttotalInvoicedValue = schemeSummary.reduce((acc, item) => acc + item.TotalInvoicedValue, 0);
        totalRowScheme.push({value: ttotalInvoicedValue, bold: true, type: CellType.CURRENCY});

        ttotalMAValue = schemeSummary.reduce((acc, item) => acc + item.TotalMALValue, 0);
        totalRowScheme.push({value: ttotalMAValue, bold: true, type: CellType.CURRENCY});

        ttotalPALValue = schemeSummary.reduce((acc, item) => acc + item.TotalPALValue, 0);
        totalRowScheme.push({value: ttotalPALValue, bold: true, type: CellType.CURRENCY});

        schemeRows.push(totalRowScheme);

        schemeTable.rows = schemeRows;
        sheet.tables.push(schemeTable);
        //END - Scheme summary

        return sheet;
    }

    private buildReportSheetDetail(unroutableClaimReportModel: UnroutableClaimsReportModel,addBranchColumn?: boolean) {
        const sheet: Sheet = {};
        sheet.name = 'Details';
        sheet.tables = [];

        const detailsTable: Table = {};
        detailsTable.headers = [
            {value: 'Acc. no.', bold: true, width: CellWidth.XS},
            {value: 'Patient', bold: true, width: CellWidth.S},
            {value: 'Patient ID no.', bold: true, width: CellWidth.XS},
            {value: 'Invoice type', bold: true, width: CellWidth.XS},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS},
            {value: 'Scheme', bold: true, width: CellWidth.S},
            {value: 'Plan', bold: true, width: CellWidth.XS},
            {value: 'Option', bold: true, width: CellWidth.XS},
            {value: 'Treating provider', bold: true, width: CellWidth.S},
            {value: 'Member no.', bold: true, width: CellWidth.XS},
            {value: 'Claim status', bold: true, width: CellWidth.S},
            {value: 'Date of service', bold: true, width:CellWidth.XS},
            {value: 'Date of submission', bold: true, width: CellWidth.XS},
            {value: 'Invoiced amount', bold: true, width: CellWidth.XS, hAlignment:'right'},
            {value: 'Med aid liable', bold: true, width: CellWidth.XS, hAlignment:'right'},
            {value: 'Patient liable', bold: true, width: CellWidth.XS, hAlignment:'right'},
            {value: 'Place of service', bold: true, width: CellWidth.S},
            {value: 'Location', bold: true, width: CellWidth.S},
            {value: 'Created by', bold: true, width: CellWidth.S},
        ];

        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.XS});
        }

        const detailsRows = [];

        const claimDetails= unroutableClaimReportModel.UnroutableReportModel.UnroutableClaimDSData;
        claimDetails.sort((a,b) => a.InvoiceNum.localeCompare(b.InvoiceNum));

        claimDetails.forEach((item: UnroutableClaimDSData) => {
        const row: ExcelCellData[] = [];
        row.push({value: item.AccountNo, hAlignment:'left'}),
        row.push({value: item.PatName+' '+item.PatSurname}),
        row.push({value: item.PatientIdNumber, hAlignment:'left'}),
        row.push({value: item.InvoiceType}),
        row.push({value: item.InvoiceNum, hAlignment:'left'}),
        row.push({value: item.Scheme}),
        row.push({value: item.Plan}),
        row.push({value: item.Option}),
        row.push({value: item.TreatingProvider}),
        row.push({value: item.MemberNum, hAlignment:'left'}),
        row.push({value: item.ClaimStatus}),
        row.push({value: item.DateOfService, type: CellType.GENERAL}),
        row.push({value: item.DateOfSubmission, type: CellType.GENERAL}),
        row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
        row.push({value: item.MedAidLiable, type: CellType.CURRENCY});
        row.push({value: item.PatLiable, type: CellType.CURRENCY});
        row.push({value: item.PlaceOfService}),
        row.push({value: item.Location}),
        row.push({value: item.CreatedBy})

        if (addBranchColumn)
                row.push({value: item.Branch});

        detailsRows.push(row);
      });

        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, unroutableClaimReportModel: UnroutableClaimsReportModel) {

        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: unroutableClaimReportModel.ReportInfo.Practice, type: CellType.GENERAL}
        });

        if (unroutableClaimReportModel.ReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: unroutableClaimReportModel.ReportInfo.Branch, type: CellType.GENERAL}
                });
        }

        excelReportData.parameters.push(
            {
                name: 'Date range type',
                   data: {value: unroutableClaimReportModel.ReportInfo.DateRangeType, type: CellType.GENERAL}
            });

         excelReportData.parameters.push(
             {
                 name: 'Date range',
                 data: {value: unroutableClaimReportModel.ReportInfo.DateRange, type: CellType.GENERAL}
             });
    }
}
