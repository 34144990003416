import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'meraki-flux-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group(
    {
      password: [null, RxwebValidators.compose({
        validators: [
          RxwebValidators.required(),
          RxwebValidators.password({
            validation: { minLength: 6, maxLength: 30, digit: true, upperCase: true, specialCharacter: true }, message: {
              minLength: "Password must be at least 6 characters",
              maxLength: "Password cannot be more than 30 characters",
              digit: "Password must contain at least 1 number",
              upperCase: "Password must contain at least 1 upper case character",
              specialCharacter: "Password must contain at least 1 special character"
            }
          })
        ]
      })],
      confirmPassword: [null, RxwebValidators.compose({
        validators: [
          RxwebValidators.required(),
          RxwebValidators.compare({ fieldName: 'password' })
        ]
      })]
    }
  );

  loading = false;
  token: string | any = null;

  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const updatePasswordRequest = { token: this.token, password: this.form.controls.password.value };
    this.http.post(environment.resetPasswordUrl, updatePasswordRequest)
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.dialogService.showInfoMessage('Your password was successfully updated');
          this.router.navigate(['../login'], { relativeTo: this.route });
        },
        error: error => this.dialogService.showErrorMessage(error.error.error.message)
      });
  }
}
