import moment = require("moment");
import {
  BasePdfReportData, CellType, PaymentSummaryGroupData,
  PaymentSummaryReportInfo,
  PaymentSummaryReportModel, PDFCellData, PDFParameter,
  REPORT_NAME, ReportHeader, ReportTable
} from '@meraki-flux/schema';

export class PaymentSummaryPdfReportDataBuilder {

  private static REPORT_SPECIAL_FONT_COLOR = "#000000";

  build(report: PaymentSummaryReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.reportName = REPORT_NAME.PAYMENT_SUMMARY;
    reportData.reportNameColor = PaymentSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    reportData.dateHeader = `Date generated ${moment(new Date()).format('DD/MM/YYYY')}`;
    reportData.bpn = report.ReportInfo.PracticeId
    reportData.orientation = "portrait";
    reportData.headers = this.buildHeader(report.ReportInfo);
    reportData.tables = this.buildTables(report);
    reportData.footerText = "\t\u200B".repeat(60)
    reportData.footer = {
      hbLogo: true
    }
    return reportData;
  }

  private buildHeader(reportInfo: PaymentSummaryReportInfo): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: {value: reportInfo.Practice, decoration: {bold: true}}
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: {value: reportInfo.PracticeId, decoration: {bold: true}}
    });
    const rightParameters: PDFParameter[] = [];
    rightParameters.push(
      {name: 'Date from', data: {value: reportInfo.DateFrom, type: CellType.DATE, decoration: {bold: true}}});
    rightParameters.push(
      {name: 'Date to', data: {value: reportInfo.DateTo, type: CellType.DATE, decoration: {bold: true}}});
    return [{
      left: leftParameters,
      right: rightParameters
    }];
  }

  private buildTables(reportModel: PaymentSummaryReportModel): ReportTable[] {
    const reportTables: ReportTable[] = [];

    reportTables.push(this.buildSummaryTable(reportModel.PatientPaymentTypeData, 'PATIENT PAYMENTS', null, 'Payment type',
      '# Payments', 'Amount paid', 'Allocated amt.', 'Unallocated amt.'));

    reportTables.push(this.buildSummaryTable(reportModel.MedicalAidPaymentTypeData, 'MEDICAL AID PAYMENTS', 'Payment type summary',
      'Payment type', '# Payments', 'Amount paid', 'Reconciled amt.', 'Unreconciled amt.'));

    reportTables.push(this.buildSummaryTable(reportModel.SchemeData, '', 'Scheme summary', 'Scheme', null, 'Amount paid'));

    reportTables.push(this.buildSummaryTable(reportModel.InsurerPaymentTypeData, 'INSURER PAYMENTS', 'Payment type summary',
      'Payment type', '# Payments', 'Amount paid', 'Reconciled amt.', 'Unreconciled amt.'));

    reportTables.push(this.buildSummaryTable(reportModel.InsurerData, '', 'Insurer summary', 'Insurer', null, 'Amount paid'));

    return reportTables;
  }

  private buildSummaryTable(data: PaymentSummaryGroupData, tableName, headerName, keyColumnName, countColumnName?, amountColumnName?,
                            allocatedColumnName?, unallocatedColumnName?): ReportTable {
    const table: ReportTable = {};
    table.borders = {
      hor: true,
      headerBorderSize: 1
    };
    if (tableName)
      table.name = tableName;
    table.nameColor = PaymentSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = PaymentSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    if (headerName) {
      table.headers = [{
        rows: [[{name: '', data: {value: headerName, decoration: {bold: true, italics: true}}}]],
        backgroundColor: '#ececec'
      }];
    }
    table.rowHeadersBold = true;

    table.rowHeaders = [{value: keyColumnName, decoration: {bold: true}}];
    if (countColumnName) table.rowHeaders.push({value: countColumnName, decoration: {bold: true}});
    if (amountColumnName) table.rowHeaders.push({value: amountColumnName, decoration: {bold: true}});
    if (allocatedColumnName) table.rowHeaders.push({value: allocatedColumnName, decoration: {bold: true}});
    if (unallocatedColumnName) table.rowHeaders.push({value: unallocatedColumnName, decoration: {bold: true}});

    const rows = [];

    if (data.map.size > 0) {
      Array.from(data.map.keys()).sort().forEach(key => {
        const paymentSummaryItem = data.map.get(key);
        const row: PDFCellData[] = [];
        row.push({value: key});
        if (countColumnName) row.push({value: paymentSummaryItem.count, type: CellType.NUMBER});
        if (amountColumnName) row.push({value: paymentSummaryItem.total, type: CellType.CURRENCY});
        if (allocatedColumnName) row.push({value: paymentSummaryItem.allocated, type: CellType.CURRENCY});
        if (unallocatedColumnName) row.push({value: paymentSummaryItem.unallocated, type: CellType.CURRENCY});
        rows.push(row);
      })
      const totalRow: PDFCellData[] = [{value: 'Total', decoration: {bold: true}}];
      if (countColumnName) totalRow.push({value: data.totals.count, type: CellType.NUMBER, decoration: {bold: true}});
      if (amountColumnName) totalRow.push({value: data.totals.total, type: CellType.CURRENCY, decoration: {bold: true}});
      if (allocatedColumnName) totalRow.push({value: data.totals.allocated, type: CellType.CURRENCY, decoration: {bold: true}});
      if (unallocatedColumnName) totalRow.push({value: data.totals.unallocated, type: CellType.CURRENCY, decoration: {bold: true}});
      rows.push(totalRow);
      table.rows = rows;
    } else {
      table.rows = undefined;
      table.rowHeaders = undefined;
      table.noRecordsText = {
        value: "No records",
        decoration: {
          italics: true
        }
      }
    }

    return table;
  }
}
