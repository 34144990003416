import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { FluxCommonModule } from '@meraki-flux/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PracticeSwitchComponent } from './practice-switch/practice-switch.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RedirectGuard } from '@meraki-flux/common';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatDividerModule,
    FluxCommonModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule.forRoot()
  ],
  providers: [RedirectGuard],
  declarations: [ToolbarComponent, PatientSearchComponent, PracticeSwitchComponent],
  exports: [ToolbarComponent],
})
export class ToolbarModule { }
