import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { DialogService } from '../../dialog.service';
import { FirestoreService } from '../../firestore.service';
import { AbstractEventAction } from './abstract-event.action';
import {CALENDAR_EVENT_STATUS, CalendarEvent, CalendarEventRecurrentInfo, Repeat} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class CreateEventAction extends AbstractEventAction {
  constructor(
    protected override authService: AuthService,
    protected override firestore: Firestore,
    protected override firestoreService: FirestoreService,
    protected dialogService: DialogService
  ) {
    super(firestore, firestoreService, authService);
  }

  async create(event: CalendarEvent, repeat: Repeat = null) {
    if (event.Id) {
      throw Error('Calendar event must have empty id');
    }
    event.Id = this.newId();
    event.RecurrentInfo = {};
    event.CreatedAt = new Date();
    event.CreatedBy = this.authService.uid;
    if (!event.Status) {
      event.Status = CALENDAR_EVENT_STATUS.BOOKED;
    }
    event.ClinicalStatus = event.Status;
    if (this.shouldRepeat(repeat)) {
      event.IsRecurrentRoot = true;
      event.RecurrentInfo = this.newRecurrentInfo(event, repeat);
    } else {
      event.IsRecurrentRoot = false;
      event.RecurrentInfo = null;
    }
    await this.setDoc(event);
  }

  newRecurrentInfo(event: CalendarEvent, repeat: Repeat) {
    return {
      RootEventId: event.Id,
      Index: 0,
      Rule: {
        Repeat: {
          Frequency: repeat?.Frequency,
          EndDate: repeat?.EndDate || null,
        },
        SkipIndexes: [],
      },
    } as CalendarEventRecurrentInfo;
  }
}
