import { SchemePlanOption } from "./scheme.models";

export enum PrivateRateType {
  PROCEDURE = 'Procedure',
  MEDICINE = 'Medicine',
  CONSUMABLE = 'Consumable',
  ANAESTHETIC = 'Anaesthetic',
}

export class PrivateRate {
  Id?: string;
  BranchName?: string;
  Name?: string;
  Code?: string;
  Type?: PrivateRateType;
  Description?: string;
  CashInvoices?: boolean;
  MedicalAidInvoices?: boolean;
  SchemeCode?: string; // deprecated
  OptionCode?: string; // deprecated
  SpoCodes?: SchemePlanOption[]; // replacement for SchemeCode, OptionCode fields
  DateFrom?: Date;
  DateTo?: Date;
  Price?: number;
  IsActive?: boolean;
  UpdatedAt?: Date;
  UpdatedBy?: string;
  UpdatedByName?: string;
}