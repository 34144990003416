import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { Timestamp } from '@angular/fire/firestore';

export interface PatientAge {
  ageUnit: number;
  unitType: string;
}

@Injectable({
  providedIn: 'root',
})
export class DateUtils {
  static yearStartDate(date: Date = new Date()): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  }

  static yearEndDate(date: Date = new Date()): Date {
    return moment(date).endOf('year').toDate();
  }

  static monthStartDate(): Date {
    return new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1));
  }

  static epochStartDate(): Date {
    return new Date(0);
  }

  static toDate(date: any): Date {
    return date ? (date instanceof Date ? date : date.toDate ? date.toDate() : null) : null;
  }

  static toSafeDate(date: any): Date {
    return date
      ? date instanceof Date
        ? date
        : date?._seconds
        ? Timestamp.fromMillis(date._seconds * 1000).toDate()
        : date.toDate
        ? date.toDate()
        : null
      : null;
  }

  static todayStartDate(): Date {
    return this.dayStartDate(new Date());
  }

  static dayStartDate(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  static dayEndDate(date: Date): Date {
    return date ? moment(date).endOf('day').toDate() : null;
  }

  static dayStartTime(date: any): number {
    return date ? this.getTime(this.dayStartDate(this.toDate(date))) : null;
  }

  static dayEndTime(date: any): number {
    return date ? this.getTime(this.dayEndDate(this.toDate(date))) : null;
  }

  static unitsFromMidnight(date: any, unitOfTime: unitOfTime.Diff): number {
    if (!date) {
      return null;
    }
    const mmt = moment(DateUtils.toDate(date));
    const mmtMidnight = mmt.clone().startOf('day');
    return mmt.diff(mmtMidnight, unitOfTime);
  }

  static dateDiffInMins(date1: any, date2: any): number {
    if (!date1 || !date2) {
      return null;
    }
    const mm1 = moment(DateUtils.toDate(date1));
    const mm2 = moment(DateUtils.toDate(date2));
    return Math.abs(mm1.diff(mm2, 'minutes'));
  }

  static updateTime(target: any, source: any): Date {
    if (!target || !source) {
      return target;
    }
    const millsFromMidnight = DateUtils.unitsFromMidnight(source, 'millisecond');
    return moment(target).startOf('day').add(millsFromMidnight, 'millisecond').toDate();
  }

  static getTime(date: any): number {
    return this.toDate(date)?.getTime();
  }

  static dateDiffInDays(date: Date, dateSent: Date): number {
    const currentDate = date;
    return Math.floor(
      (Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
        Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  static addYears(date: Date, months: number): Date {
    return moment(date).add(months, 'years').toDate();
  }

  static addMonths(date: Date, months: number): Date {
    return moment(date).add(months, 'month').toDate();
  }

  static addMills(date: Date, mills: number): Date {
    return moment(date).add(mills, 'millisecond').toDate();
  }

  static addMinutes(date: Date, minutes: number): Date {
    return moment(date).add(minutes, 'minutes').toDate();
  }

  static getAgeString(dob: Date) {
    const dateTo = new Date();
    const months =
      dateTo.getMonth() - dob.getMonth() + 12 * (dateTo.getFullYear() - dob.getFullYear());
    let ageUnit;
    let unitType;

    if (months >= 12) {
      ageUnit = (months - (months % 12)) / 12;
      unitType = ageUnit > 1 ? 'years old' : 'year old';
    } else {
      ageUnit = months;
      unitType = ageUnit > 1 ? 'months' : 'month';
    }
    return {
      ageUnit: ageUnit,
      unitType: unitType,
    } as PatientAge;
  }

  static getFriendlyAgeString(dob: Date) {
    const age: PatientAge = this.getAgeString(dob);
    return `${age.ageUnit} ${age.unitType}`;
  }

  static isSameDay(date1: any, date2: any): boolean {
    const v1 = moment(DateUtils.toDate(date1)).startOf('day');
    const v2 = moment(DateUtils.toDate(date2)).startOf('day');
    return v1.isSame(v2);
  }

  static isDifferent(date1: any, date2: any) {
    return (
      (!date1 && !!date2) ||
      (!!date1 && !date2) ||
      (date1 && date2 && DateUtils.getTime(date1) !== DateUtils.getTime(date2))
    );
  }

  static max(...dates: Date[]): Date {
    return _.max(dates.map((d) => DateUtils.toDate(d)));
  }

  static timestampsToDates(v: any) {
    const result = _.cloneDeep(v);
    if (result instanceof Object) {
      Object.keys(result).forEach((field) => {
        const fieldValue = result[field];
        result[field] = fieldValue?.toDate
          ? fieldValue.toDate()
          : this.timestampsToDates(fieldValue);
      });
    }
    return result;
  }

  static dateRangesConflict(date1From: any, date1To: any, date2From: any, date2To: any): boolean {
    const from1 = DateUtils.toDate(date1From)?.getTime();
    const to1 = DateUtils.toDate(date1To)?.getTime();
    const from2 = DateUtils.toDate(date2From)?.getTime();
    const to2 = DateUtils.toDate(date2To)?.getTime();
    const res =
      (!from1 && !from2) ||
      (!to1 && !to2) ||
      Math.max(from1 || from2, from2 || from1) < Math.min(to1 || to2, to2 || to1);
    return res;
  }
}
