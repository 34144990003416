export enum ADDON_CATEGORY {
  ALL = 'All',
  PRACTICE_MANAGEMENT = 'Practice management',
  ANALYTICS = 'Analytics',
  CLINICAL = 'Clinical',
}

export enum ADDON_TITLE {
  SNAPSCAN = 'Snapscan',
  BUSINESS_INSIGHT_DASHBOARD = 'Business Insights Dashboard',
  BULK_SMS = 'Bulk SMSs',
  CLINICAL_NOTES_FOR_ALLIED_PROFESSIONALS = 'Clinical Notes for Allied Professionals',
  TELEHEALTH = 'Telehealth',
  CLOUD_FILE_STORAGE = 'Cloud File Storage',
  RETAIL_CAPITAL = 'Retail Capital',
}

export enum ADDON_ID {
  SNAPSCAN = 'SNAPSCAN',
  BUSINESS_INSIGHT_DASHBOARD = 'BUSINESS_INSIGHT_DASHBOARD',
  BULK_SMS = 'BULK_SMS',
  CLINICAL_NOTES_FOR_ALLIED_PROFESSIONALS = 'CLINICAL_NOTES_FOR_ALLIED_PROFESSIONALS',
  TELEHEALTH = 'TELEHEALTH',
  CLOUD_FILE_STORAGE = 'CLOUD_FILE_STORAGE',
  RETAIL_CAPITAL = 'RETAIL_CAPITAL',
  HBC = 'HBC',
}

export enum ADDON_TRANSACTION_TYPE {
  ACTIVATED = 'Activated',
  DEACTIVATED = 'Deactivated',
  FREE_SMS_ON_ACTIVATION = 'Free SMS on activation',
  SMS_BUNDLE_BOUGHT = 'SMS bundle bought',
  QR_CODE_UPDATED = 'QR code updated'
}

export enum ADDON_EMAILS_TO {
  PRACTICE = 'Practice',
  FULFILLMENT = 'Fulfillment',
}

export interface BaseAddon {
  Id?: ADDON_ID;
  Title?: ADDON_TITLE;
  Description?: string;
  Icon?: string;
  Category?: ADDON_CATEGORY[];
  Activated?: boolean;
  Audit?: AddonAudit[];
  ExcludedSpecialities?: string[];
  SendEmailTo?: ADDON_EMAILS_TO[];
}

export interface BulkSMS extends Omit<BaseAddon, 'Audit'> {
  FirstTime?: boolean;
  SMSBalance?: number;
  Audit?: BulkSMSAudit[];
  SendEmailOnLowBalance? : boolean;
}

export interface SnapScan extends Omit<BaseAddon, 'Audit'> {
  QRCode?: string;
  Audit?: SnapscanAudit[];
  SnapScanURL?: string;
}
export interface BusinessInsightDashboard extends BaseAddon {}
export interface ClinicalNotesForAlliedProfessionals extends BaseAddon {}
export interface Telehealth extends BaseAddon {}
export interface CloudFileStorage extends BaseAddon {}
export interface RetailCapital extends BaseAddon {}
export interface HbcIntegrationAddon extends BaseAddon {
  ActiveProviders: string[];
}

export type Addon = BulkSMS | SnapScan | BusinessInsightDashboard | ClinicalNotesForAlliedProfessionals |
                    Telehealth | CloudFileStorage | RetailCapital | HbcIntegrationAddon;

export interface AddonAudit {
  Title: ADDON_TITLE;
  TransactionType: ADDON_TRANSACTION_TYPE;
  User: string;
  Time: Date;
}

export interface BulkSMSAudit extends AddonAudit {
  NumberOfFreeSMSs?: number,
  NumberOfSMSsBought?: number,
  BundlePrice?: number,
}

export interface SnapscanAudit extends AddonAudit {
  NewQRCode?: string;
}

export interface AddonConfigurables {
  BulkSMS: BulkSMSConfigurable;
  Email: string;
  ActivationEmailBody: string;
  DeactivationEmailBody: string;
  SMSBundleEmailBody: string;
  BusinessInsightDashboard: BusinessInsightDashboardConfigurable;
}

export interface BusinessInsightDashboardConfigurable {
  ActivationEmail: string;
  DeactivationEmail: string;
}

export interface BulkSMSConfigurable {
  Package: BulkSMSPackage[];
  FirstTimeFreeAccountSMSs: number;
}

export interface BulkSMSPackage {
  CountOfSMS: number,
  BundlePrice: number
}

export interface RetailCapitalOffer {
  billingPracticeNumber: string,
  loadedDate: string,
  offerAmount: number,
  url: string,
  validUntilDate: string
}
