import { Injectable } from "@angular/core";
import { createStore, setProp, Store, withProps } from "@ngneat/elf";

@Injectable({
    providedIn: 'root'
})
export class ReferringContextRepository {

    private readonly referenceDataStore: Store;
    private readonly toggleSelectionStore: Store;

    constructor() {
        this.referenceDataStore = createStore({
            name: 'referring-context'
        }, withProps({
            ReferringContext: null
        }));

        this.toggleSelectionStore = createStore({
            name: 'toggle-selection'
        }, withProps({
            Invoices: null,
            Claims: null,
            Collection: null,
        }));
    }

    setToggleSelection(key: string, toggleSelection: any) {
        this.toggleSelectionStore.update(setProp(key, toggleSelection))
    }

    getToggleSelection(key: string): any {
        return this.toggleSelectionStore.query(state => state[key]);
    }

    setReferringContext(referringContext: any) {
        this.referenceDataStore.update(setProp('ReferringContext', referringContext))
    }

    getReferringContext(): any {
        return this.referenceDataStore.query(state => state.ReferringContext);
    }

    clear() {
        this.referenceDataStore.reset();
    }
}