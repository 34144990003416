import {
  BaseExcelReportData, CellType,
  CellWidth, ExcelCellData,
  RefundReportData,
  RefundReportRequest,
  REPORT_NAME,
  Sheet,
  Table
} from "@meraki-flux/schema";
import moment from "moment";

export class RefundXlsReportDataBuilder {

    build(refundReportRequest: RefundReportRequest): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.REFUND;
        excelReportData.data = [];
        excelReportData.data.push(this.buildReportSheetERA(refundReportRequest));
        this.buildReportInfo(excelReportData, refundReportRequest);
        return excelReportData;
    }

    private buildReportSheetERA(refundReportRequest : RefundReportRequest)
    {
      const sheet: Sheet = {};
      sheet.name = 'Details';
      sheet.tables = [];

      const detailsTable: Table = {};
      detailsTable.headers = [
          {value: 'Refund date', bold: true, width: CellWidth.XS},
          {value: 'Refund amount', bold: true, width: CellWidth.XS},
          {value: 'Account no.', bold: true, width: CellWidth.XS},
          {value: 'Account holder', bold: true, width: CellWidth.S}];
          if (refundReportRequest.Multibranch) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.S});
        }
        detailsTable.headers.push(
          {value: 'Captured by', bold: true, width: CellWidth.S},
          {value: 'Account notes for refund date', bold: true, width: CellWidth.S});

      const detailsRows = [];

      refundReportRequest.ReportData.forEach((item: RefundReportData) => {
      const row: ExcelCellData[] = [];
      row.push({value: moment(item.RefundDate).format('DD/MM/YYYY'), type: CellType.GENERAL}),
      row.push({value: item.RefundAmount, type: CellType.CURRENCY}),
      row.push({value: item.AccountNo, hAlignment:'left'}),
      row.push({value: item.AccountHolder});
      if (refundReportRequest.Multibranch)
      { row.push({value: item.Branch}); }

      row.push({value: item.CapturedBy}),
      row.push({value: item.AccountNotes, hAlignment:'left'});
      detailsRows.push(row);
    });

      detailsTable.rows = detailsRows;
      sheet.tables.push(detailsTable);
      return sheet;

    }

    private buildReportInfo(excelReportData: BaseExcelReportData, refundReportRequest: RefundReportRequest) {
      excelReportData.parameters = [];

      excelReportData.parameters.push({
          name: 'Practice',
          data: {value: refundReportRequest.Practice.PracticeName, type: CellType.GENERAL}
      });

      excelReportData.parameters.push({
          name: 'Billing practice no.',
          data: {value: refundReportRequest.Practice.BillingPracticeNumber, type: CellType.GENERAL}
      });

      if (refundReportRequest.Multibranch) {
          excelReportData.parameters.push(
              {
                  name: 'Branch',
                  data: {value: refundReportRequest.Branch.Name ?? 'All', type: CellType.GENERAL}
              });
      }

      excelReportData.parameters.push(
          {
              name: 'Date Range',
              data: {value: refundReportRequest.DateRange, type: CellType.GENERAL}
          });

  }

}
