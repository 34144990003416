import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import { JournalReportBuilder } from './journal-report-builder';
import { JournalXlsReportDataBuilder } from './journal-xls-report-data-builder';
import { JournalPdfReportDataBuilder } from './journal-pdf-report-data-builder';
import {
  BaseExcelReportData,
  BasePdfReportData,
  JournalReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class JournalReportService {

  async generateReport(reportRequest: JournalReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildJournalReportFileName('Journal', reportRequest.Practice, reportRequest.ReportFormatType,
                              reportRequest.PaymentDateFrom, reportRequest.PaymentDateTo);

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.JOURNAL,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: JournalReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
     }
    else
    if(REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    }
    else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: JournalReportRequest): Promise<BasePdfReportData> {
    return new JournalPdfReportDataBuilder().build(
      await new JournalReportBuilder().build(reportRequest));
  }

  private async generateXlsReportData(reportRequest: JournalReportRequest): Promise<BaseExcelReportData> {
    return new JournalXlsReportDataBuilder().build(
      await new JournalReportBuilder().build(reportRequest));
  }
}
