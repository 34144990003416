import {AdditionalEmails, AdditionalPhones, Address, BankDetails} from "./general.models";

export class Business {
  Users?: string[] | null;
  Businesses?: string[] | null;
  Id?: string;
  BillingPracticeNumber?: string;
  Details?: Details;
  BusinessId?: string;
  BusinessType?: string;
  CompanyRegistrationNo?: string;
  IsMultiBranch?: boolean;
  PracticeName?: string;
  Speciality?: string;
  VatEffectiveDate?: Date;
  VatNo?: string;
}
export interface Details {
  Active?: boolean;
  Name?: string;
  IsMainBranch?: boolean;
  PhysicalAddress?: Address;
  PostalAddress?: Address;
  BankDetails?: {
    ChequeAccountDetails?: BankDetails;
    EFTAccountDetails?: BankDetails;
    SameAsMain?: boolean;
    SameAsEFT?: boolean;
  },
  SameAsMainContact?: boolean;
  SameAsPhysical?: boolean;
  AdditionalPhones?: AdditionalPhones;
  AdditionalEmails?: AdditionalEmails;
}
