import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { LogService } from './log.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class NewVersionCheckerService {
  isNewVersionAvailable = false;
  newVersionSubscription: Subscription;
  constructor(
    private swUpdate: SwUpdate,
    private logger: LogService,
    private dialogService: DialogService,
  ) {
    this.checkForUpdate();
  }
  checkForUpdate(): void {
    this.newVersionSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          this.logger.info(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          this.logger.warn(`Current app version: ${evt.currentVersion.hash}`);
          this.logger.info(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.isNewVersionAvailable = true;
          this.alertUserOfNewVersion();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.logger.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  alertUserOfNewVersion(): void {
    this.dialogService.
    showSystemAlert({
        title: 'The newest version of Nova is now available',
        message: 'Refresh your browser for the latest updates',
        icon: 'laptop_windows',
        type: 0,
        displayDuration: 20000,
        action: {
            label: 'Refresh browser',
            callback: () => {
                this.applyUpdate();
            },
        }
    }) 
  }

  
  applyUpdate(): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate.activateUpdate()
        .then(() => document.location.reload())
        .catch(error => console.error('Failed to apply updates:', error));
  }
}
