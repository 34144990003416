<div mat-dialog-content class="dialog-container">
  <div class="dialog-icon-row">
    <div class="dialog-icon-container info" [ngClass]="typeClass">
      <mat-icon class="dialog-icon info" [ngClass]="typeClass">{{typeIcon}}</mat-icon>
    </div>
  </div>
  <div *ngIf="!!dialogData?.title" class="dialog-title">{{dialogData?.title}}</div>
  <div *ngIf="!!dialogData?.message" [innerHTML]="dialogData?.message" class="dialog-content">
  </div>
  <ng-content></ng-content>
  <div class="dialog-actions-container gap-3 px-4">
    <button mat-flat-button class="dialog-action" [ngClass]="getButtonColor(button)" *ngFor="let button of buttons"
      (click)="onDialogClose(button)" (window:keydown.enter)="onEnter(button)" [disabled]="!!button.disabled">{{button.caption}}</button>
  </div>
</div>
