import { ReportRequestData } from './common.model';
import { Branch, Practice } from '../practice.models';
import { ProviderInvoices, REPORT_FORMAT_TYPE } from '../report.models';

export class CreditNoteReportRequest implements ReportRequestData {
  Practice: Practice;
  Branch?: Branch;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateRangeType?: CREDIT_NOTES_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  IsVATRegistered: boolean;
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  IncludeInactiveProviders?: boolean;
  Recipient?: string;
}

export enum CREDIT_NOTES_DATE_RANGE_TYPE {
  DATE_OF_CREDIT_NOTE = 'Date of credit note',
  DATE_OF_SUBMISSION = 'Invoice date of submission',
  DATE_OF_SERVICE = 'Invoice date of service'
}

export interface CreditNoteReportModel {
  ProviderModels: CreditNoteProviderModel[];
  AllProvidersModel?: CreditNoteProviderModel;
  CreditNoteReportInfo: CreditNoteReportInfo;
  ReportDate?: string;
}

export interface CreditNoteReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  Branch?: string
  TreatingProvider?: string | ''
  DateRange?: string
  DateRangeType?: string
  InvoiceType?: string
  IsMultiBranch?: boolean
  Speciality?: string
}


export interface CreditNoteProviderModel {
  ProviderName: string;
  SummaryTable: CreditNoteSummaryItem[];
  SummaryVATTable: CreditNoteSummaryItem[];
  DetailsTable: CreditNoteDetailsItem[];
}

export interface CreditNoteSummaryItem {
  Provider?: string;
  Discount?: number;
  Correction_of_error?: number;
  Other?: number;
  Reversal_processed?: number;
  Claim_resubmitted?: number;
  Cash_invoice_cancellation?: number;
}

export interface CreditNoteDetailsItem {
  CreditNoteDate?: string;
  CreditNoteType?: string;
  CreditNoteAmount?: number;
  Reason?: string;
  CaptureBy?: string;
  AccountNo?: string;
  Patient?: string;
  InvoiceNo?: string;
  DateOfService?: string;
  DateofSubmission?: string;
  InvoiceAmount?: number;
  Provider?: string;
  Branch?: string;
  CreditNotesHeaderAmount?: number;
  CreditNotesHeaderAmountVAT?: number;
  CreditNotesHeaderId?: string;
}
