export interface PowerBIReportResponse {
  Name?: string;
  ReportId?: string;
  WebUrl?: string;
  EmbedUrl?: string;
  AccessToken?: string;
}

export interface PowerBIReportListResponse {
  PowerBIReportList?: PowerBIReportList[];
}

export interface PowerBIReportList {
  Name?: string;
  Id?: string;
}

export class EmbedConfig {
  type?: string;
  reportsDetail?: ReportsDetail;
  embedToken?: string;
  reportEmbedConfig?: PowerBiReportDetails[];
}

export class ReportsDetail {
  reportId: string;
  reportName: string;
  embedUrl: string;
}

export class PowerBiReportDetails {
  reportId: string;
  reportName: string;
  embedUrl: string;
}

export class EmbedInfo {
  embedToken?: string;
  reportDetail?: ReportsDetail;
  status: string;
}

export class PBIReportRequest {
  reportId?: string;
  pracNo?: string;
}

export class ConfigResponse {
  Id: string;
  ReportName: string;
  EmbedUrl: string;
  EmbedToken: string;
}

export enum POWERBI_BRANCHTYPE {
  SINGLE_BRANCH = 'single branch',
  MULTI_BRANCH = 'multibranch'
}
