import {
  BasePdfReportData, CellType, InvoiceDetailsItem, PDFCellData,
  PDFParameter, ProviderSummaryItem, ReferralProviderModel,
  ReferralReportModel,
  REPORT_NAME,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class ReferralPdfReportDataBuilder {
    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(referralReportModel: ReferralReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = referralReportModel.ReferralReportInfo.PracticeId
        reportData.orientation = "landscape";
        reportData.headers = this.buildHeader(referralReportModel);
        reportData.tables = this.buildTables(referralReportModel);
        reportData.dateHeader = `Date generated ${referralReportModel.ReportDate}`;
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.footer = {
            hbLogo: true
        }
        return reportData;
    }

    private buildHeader(referralReportModel: ReferralReportModel): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({name: 'Billing practice no.', data: {value: referralReportModel.ReferralReportInfo.PracticeId, decoration: {bold: true}}});
        leftParameters.push({name: 'Practice', data: {value: referralReportModel.ReferralReportInfo.Practice, decoration: {bold: true}}});
        if (referralReportModel.ReferralReportInfo.IsMultiBranch) {
            leftParameters.push({name: 'Branch', data: {value: referralReportModel.ReferralReportInfo.Branch, decoration: {bold: true}}});
        }
        const rightParameters: PDFParameter[] = [
            {name: 'Referring provider', data: {value: referralReportModel.ReferralReportInfo.ReferringProvider, decoration: {bold: true}}},
            {name: 'Treating provider', data: {value: referralReportModel.ReferralReportInfo.TreatingProvider, decoration: {bold: true}}},
            {name: 'Date Range', data: {value: referralReportModel.ReferralReportInfo.DateRange, decoration: {bold: true}}},
        ]
        return [
            {
                name: REPORT_NAME.REFERRAL,
                nameColor: ReferralPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                includeGeneratedDate: true,
                left: leftParameters,
                right: rightParameters
            }
        ];
    }

    private buildTables(referralReportModel: ReferralReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
          reportTables.push(this.buildSummaryTable(referralReportModel.AllProvidersModel));
          reportTables.push(this.buildDetailsTable(referralReportModel.AllProvidersModel.DetailsTable, 'Details', referralReportModel.ReferralReportInfo.IsMultiBranch));
        return reportTables;
    }

    private buildSummaryTable(providerModel: ReferralProviderModel): ReportTable {
        const table: ReportTable = {};
        table.name = 'Summary';
        table.nameColor = ReferralPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ReferralPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        //table.headers = [{rows: [[{name: '', data: {value: 'Summary', decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Referring Provider', decoration: {bold: true}},
            {value: 'No. of referrals', decoration: {bold: true}},
            {value: 'Total invoiced amount', decoration: {bold: true}},
        ];
        table.rowHeadersBold = true;
        const rows = [];
        let TotalCount = 0;
        let TotalInvoicedAmount = 0;
        providerModel.SummaryTable.sort((a, b) => a.ReferringProvider > b.ReferringProvider ? 1 : -1).forEach((item: ProviderSummaryItem) => {
          if (item.TotalCount) {
                TotalCount += item.TotalCount;
            }
            if (item.InvoicedAmount) {
                TotalInvoicedAmount += item.InvoicedAmount;
            }
            const row: PDFCellData[] = [];
            row.push({value: item.ReferringProvider});
            row.push({value: item.TotalCount});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: PDFCellData[] = [];
        row.push({value: "Total", decoration: {bold: true}});
        row.push({value: TotalCount, decoration: {bold: true}});
        row.push({value: TotalInvoicedAmount, type: CellType.CURRENCY, decoration: {bold: true}});

        rows.push(row);
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        return table;
    }

    private buildDetailsTable(invoiceDetailsItem: InvoiceDetailsItem[], tableName: string, isMultiBranch: boolean): ReportTable {
        const table: ReportTable = {};
        table.name = 'Details';
        table.nameColor = ReferralPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ReferralPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        //table.headers = [{rows: [[{name: "", data: {value: tableName, decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [

            {value: 'Referring provider', decoration: {bold: true}},
            {value: 'Speciality', decoration: {bold: true}},
            {value: 'Date of service', decoration: {bold: true}},
            {value: `Treating provider`, decoration: {bold: true}},
            {value: `Patient`, decoration: {bold: true}},
            {value: 'Account no.', decoration: {bold: true}},
            {value: 'Invoice no.', decoration: {bold: true}},
            {value: 'Invoiced amount', decoration: {bold: true}},

        ];
        if (isMultiBranch)
            table.rowHeaders.push({value: 'Branch', decoration: {bold: true}});

        const rows = [];
        invoiceDetailsItem.sort((a, b) => {
          if (a.ReferralProviderName !== b.ReferralProviderName
            && typeof a.ReferralProviderName !== 'undefined' && a.ReferralProviderName
            && typeof b.ReferralProviderName !== 'undefined' && b.ReferralProviderName) {
            return a.ReferralProviderName.localeCompare(b.ReferralProviderName);
          } else {
            return a.DateOfService > b.DateOfService ? 1 : -1;
          }
        }).forEach((item: InvoiceDetailsItem) => {
            const row: PDFCellData[] = [];

            row.push({value: item.ReferralProviderName}),
            row.push({value: item.Speciality}),
            row.push({value: item.DateOfService});//, type: CellType.DATE});
            row.push({value: item.TreatingProviderName});
            row.push({value: item.PatientName});
            row.push({value: item.AccountNo});
            row.push({value: item.InvoiceNo});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});

            if (isMultiBranch)
                row.push({value: item.Branch});
            rows.push(row);
        });
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        if (!rows || rows.length === 0) {
            table.rows = undefined;
            table.rowHeaders = undefined;
            table.noRecordsText = {
                value: "No records",
                decoration: {
                    italics: true
                }
            }
        }
        return table;
    }
}
