import { DATE_RANGE_TYPE, ProviderInvoices, ProviderSummaryItem, REPORT_FORMAT_TYPE } from '../report.models';
import { firestore } from 'firebase-admin';
import { INVOICE_DISPLAY_TYPE } from '../invoice.models.ui';
import { Branch, Practice } from '../practice.models';

export class ClaimInvoiceReportRequest {
  Practice: Practice;
  PracticeId?: string;
  BranchId?: string;
  Branch?: Branch;
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  TreatingProviderId?: string;
  InvoiceType?: INVOICE_DISPLAY_TYPE;
  ReportFormatType: REPORT_FORMAT_TYPE;
  Scheme?: string;
  DateRangeType?: DATE_RANGE_TYPE;
  DateFrom: Date | firestore.Timestamp;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  ReportName: string;
  Multibranch: boolean;
}

export interface ClaimInvoiceReportModel {
  ProviderModels: ClaimInvoiceProviderModel[];
  AllProvidersModel?: ClaimInvoiceProviderModel;
  ClaimInvoiceReportInfo: ClaimInvoiceReportInfo;
  ReportDate?: string;
}

export interface ClaimInvoiceReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  Branch?: string
  TreatingProvider?: string | ''
  DateRange?: string
  DateRangeType?: string
  Scheme?: string
  InvoiceType?: string
  IsMultiBranch?: boolean
  Speciality?: string
}

export interface ClaimInvoiceProviderModel {
  ProviderName: string;
  SummaryTable: ProviderSummaryItem[];
  DetailsTable: ClaimDetailsItem[];
  CancelledDetailsTable: ClaimDetailsItem[]
}

export interface ClaimDetailsItem {
  TreatingProviderName?: string
  AccountNo?: string
  PatientName?: string
  PatientID?: string
  InvoiceType?: string
  InvoiceStatus?: string
  InvoiceNo?: string;
  Scheme?: string
  Plan?: string
  Option?: string
  MemberNo?: string
  ClaimStatus?: string
  DateOfService?: string | Date
  DateOfSubmission?: string | Date
  HBMessageID?: string
  InvoicedAmount?: number
  PatientPaymentsAmount?: number
  MedicalAidPaymentsAmount?: number
  CreditNotesAmount?: number
  WriteOffsAmount?: number
  PaymentsCorrectionsAmount?: number
  MedicalAidLiable?: number
  PatientLiable?: number
  eRAEnabled?: boolean
  PlaceOfService?: string
  Location?: string
  CreatedBy?: string
  Branch?: string
  AmountExcFromHBBill?: number
}
