<div [formGroup]="range" >
    <mat-form-field appearance="outline" class="flux-textarea-form-field w-full">
        <mat-label>Business Search</mat-label>
        <input type="text" #autoTrigger="matAutocompleteTrigger" placeholder="Type business name" matInput
            [formControl]="selectedBusiness" [matAutocomplete]="auto" />
        <mat-autocomplete cdkFocusInitial autoActiveFirstOption #auto="matAutocomplete"
            (openedChange)="panelChange($event)" (optionSelected)="selectBusiness($event.option.value)"
            [displayWith]="displayFn" panelWidth="25vw">
            <div *ngIf="filteredBusiness | async as filteredBusiness">
                <mat-option *ngFor="let business of filteredBusiness.value" [value]="business">
                    <mat-card *ngIf="business !== ''">
                        <mat-card-content >
                            <div class="flex">
                                <div class="flex-1">
                                    {{'(' + business.BillingPracticeNumber + ') ' + business.PracticeName}}
                                    {{' - ' + business.BusinessType}}
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-option>
            </div>
        </mat-autocomplete>
        <div matSuffix *ngIf="filteredBusiness | obsWithStatus | async as obs">
            <ng-template [ngIf]="obs.value && businessFound">
                <button mat-icon-button>
                    <mat-icon (click)="clearBusiness()">close</mat-icon>
                </button>
            </ng-template>
            <ng-template [ngIf]="obs.loading">
                <mat-spinner diameter="20"></mat-spinner>
            </ng-template>
        </div>
    </mat-form-field>
</div>