export interface FlorenceBranch {
  BranchXRef: string;
  BranchName: string;
  PhoneNumber: string;
  PhysicalAddress: string;
  PostalAddress: string;
  IsMainBranch: boolean;
  Enabled: boolean;
}

export interface FlorenceProvider {
  PracticeXRef: string;
  PracticeName: string;
  PracticeNumber: string;
  TreatingDoctorName: string;
  TreatingPracticeNumber: string;
  SpecialityCode: string;
  SubSpecialityCode: string;
  DispensingDoctor: YES_NO;
  DispensingLicNum: string;
  HPCSANumber: string;
  Qualification: string;
  ContactNumber: string;
  TelephoneNumber: string;
  FaxNumber: string;
  CellphoneNumber: string;
  EmailAddress: string;
  PhysicalAddress: string;
  PostalAddress: string;
}

export interface FlorenceProviderBranch {
  BranchXRef: string;
  IsDispensing: boolean;
  DispensingLicenseNumber: string;
  IsDefaultBranch: boolean;
}

export enum YES_NO {
  YES = "Yes",
  NO = "No",
}
