import {Injectable} from '@angular/core';
import {getStorage, ref, uploadBytesResumable} from "@angular/fire/storage";
import {StorageError, UploadTaskSnapshot} from "@firebase/storage";
import { LogService } from './log.service';
import {EmailRequestAttachment} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private logger: LogService) {}

  uploadFile(storagePath: string, file: File, onStateChanged: (snapshot: UploadTaskSnapshot) => void, onError: (error) => void): Promise<EmailRequestAttachment> {
    const storage = getStorage();
    // const storagePath = `Practice/${this.auth.selectedBPN}/OutboundEmail/`;
    return new Promise((resolve) => {
      const storageRef = ref(storage, `${storagePath}${file.name}`);
      const blob = new Blob([file as BlobPart]);
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on('state_changed', ((snapshot: UploadTaskSnapshot) => {
          if (onStateChanged)
            onStateChanged(snapshot);
        }) as null, ((error: StorageError) => {
          if (onError) {
            onError(error);
          } else {
            this.logger.info(error);
          }
        }) as null,
        () => {
          resolve({
            FileName: file.name,
            StoragePath: `${storagePath}${file.name}`,
            MimeType: file.type,
          });
        });

      // uploadTask.on('state_changed',
      //   (snapshot: any) => {
      //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //     this.form.controls.Attachments?.value.forEach((element: any) => {
      //       if (element.name === file.name) {
      //         element.uploadProgress = Math.trunc(progress);
      //         if (element.uploadProgress > 100)
      //           element.uploadProgress = 100
      //         if (this.data?.files.find(x => x.name === element.name) && element.uploadProgress === 100) {
      //           element.uploadProgress = 100
      //         }
      //       }
      //     });
      //   },
      //   (error: any) => {
      //     this.logger.error(error)
      //   },
      //   () => {
      //     resolve({
      //       FileName: file.name,
      //       StoragePath: `${storagePath}${file.name}`,
      //       MimeType: file.type,
      //     });
      //   });
    })
  }
}
