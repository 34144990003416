import { BaseModel } from './general.models';

export interface CommunicationTemplate extends BaseModel {
  Name?: string;
  Description?: string;
  Type?: TEMPLATE_TYPE;
  Subject?: string;
  Body?: string;
  isStatic?: boolean;
  StaticDetails?: {
    id?: string;
    partOfBulkSmsPackage?: boolean;
  };
  TemplateStatus?: TEMPLATE_STATUS;
}

export enum TEMPLATE_TYPE {
  SMS = 'SMS',
  EMAIL = 'Email',
}

export enum TEMPLATE_STATUS {
  ACTIVE = 'Active',
  DELETED = 'Deleted',
}
