export class FileUtils {

  static decorateFileNameWithUUID(fileName:string):string {
    return `${Math.random().toString(36).substring(2)}-${fileName}`;
  }

  static unDecorateFileNameWithUUID(fileName:string):string {
    return fileName.substring(fileName.indexOf('-') + 1);
  }

  static renameFile(file: File, newName:string): File {
    return new File([file], newName, {type: file.type});
  }
}
