import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringManipulationService {

   trimStrings(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'string' && obj[key]) {
        obj[key] = obj[key].trim();
      }
      if (typeof obj[key] === 'object') {
        this.trimStrings(obj[key]);
      }
    }
    return obj;
  }
}
