import { Injectable } from '@angular/core';
import { TransactionCaptureService } from './transaction-capture.service';
import {ERROR_CODE, PaymentCorrection, Reverse} from "@meraki-flux/schema";

@Injectable()
export class PaymentCorrectionService {
  constructor(private transactionCaptureService: TransactionCaptureService) {}

  async addPaymentCorrection(
    accountId: string,
    paymentCorrection: PaymentCorrection,
    onCompleteFn
  ) {
    await this.transactionCaptureService.addPaymentCorrection(
      accountId,
      paymentCorrection,
      onCompleteFn
    );
  }

  async reverse(
    accountId: string,
    paymentCorrectionTxHeaderId: string,
    reason: any,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const reverse = {
      PaymentCorrectionTxHeaderId: paymentCorrectionTxHeaderId,
      Reason: reason,
    } as Reverse;
    await this.transactionCaptureService.reverse(accountId, reverse, onCompleteFn);
  }
}
