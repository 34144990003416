import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarService } from '../../services/sidebar.service';
import {SidebarGroup} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent  {
  groups$ = this.sidebarService.sidebarGroups$.pipe(
    map(groups => groups?.map(group => ({
      ...group,
      hasItems$: combineLatest(group.items.map(item => item.access)).pipe(
        map(res => res.includes(true))
      )
    }))),
  );

  defaultGroups$ = this.sidebarService.defaultMenuItems$.pipe(
    map(groups => groups?.map(group => ({
      ...group,
      hasItems$: combineLatest(group.items.map(item => item.access)).pipe(
        map(res => res.includes(true))
      )
    }))),
  );

  filteredGroups$ = new BehaviorSubject<SidebarGroup[]>([]);
  filteredDefaultGroups$ = new BehaviorSubject<SidebarGroup[]>([]);

  constructor(
    private sidebarService: SidebarService
  ) { }
}
