import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ContextToolbarGroup, ContextToolbarItem, CONTEXT_TOOLBAR_ITEM_POSITION } from "../interfaces/context-toolbar-item";

@Injectable()
export class ContextualToolbarService {

    activeItem$ = new BehaviorSubject<ContextToolbarItem>(null);

    groups$ = new BehaviorSubject<ContextToolbarGroup[]>([]);

    topGroups$ = this.groups$.pipe(map(groups => groups.filter(group => group.position === CONTEXT_TOOLBAR_ITEM_POSITION.TOP)));
    bottomGroups$ = this.groups$.pipe(map(groups => groups.filter(group => group.position === CONTEXT_TOOLBAR_ITEM_POSITION.BOTTOM)));

    panelOpen$ = new BehaviorSubject<boolean>(false);

    badgeSubs: { [key: string]: Subscription } = {};

    addItems(...items: ContextToolbarItem[] | ContextToolbarGroup[]) {
        const defaultTopGroup: ContextToolbarGroup = {
            position: CONTEXT_TOOLBAR_ITEM_POSITION.TOP,
            items: []
        };
        const defaultBottomGroup: ContextToolbarGroup = {
            position: CONTEXT_TOOLBAR_ITEM_POSITION.BOTTOM,
            items: []
        };

        const result = [];

        items.forEach(item => {
            // Check if it's a group
            if (Object.keys(item).includes("Items")) {
                if ((<ContextToolbarGroup>item).items?.length > 0) result.push(item);
            } else {
                const toolbarItem = <ContextToolbarItem>item;
                this.subBadgeUpdate(item);
                if (toolbarItem?.position === CONTEXT_TOOLBAR_ITEM_POSITION.TOP) {
                    defaultTopGroup.items.push(toolbarItem);
                } else {
                    defaultBottomGroup.items.push(toolbarItem);
                }
            }
        });

        result.push(defaultTopGroup, defaultBottomGroup);
        this.groups$.next(result);
    }

    clearItems() {
        const groups = [];
        this.groups$.getValue().forEach(group => {
            const currentGroup = {
                items: [],
                position: group.position
            } as ContextToolbarGroup;
            group.items.forEach(item => {
                if (item.static) {
                    currentGroup.items.push(item);
                } else {
                    this.unsubBadgeUpdate(item.id);
                }
            });
            if (currentGroup.items.length > 0) {
                groups.push(currentGroup);
            }
        });
        this.groups$.next(groups);
    }

    openPanel() {
        this.panelOpen$.next(true);
    }

    closePanel() {
        this.panelOpen$.next(false);
    }

    setActiveItem(item: ContextToolbarItem) {
        this.activeItem$.next(item);
    }

    subBadgeUpdate(item: ContextToolbarItem) {
        if (item.badgeOptions?.onUpdateBadge$) {
            this.unsubBadgeUpdate(item.id);
            this.badgeSubs[item.id] = item.badgeOptions.onUpdateBadge$.subscribe((count) => {
                item.badgeOptions.badgeCount = count;
            });
        }
    }

    unsubBadgeUpdate(itemId: string) {
        if (this.badgeSubs[itemId] && !this.badgeSubs[itemId].closed) {
            this.badgeSubs[itemId].unsubscribe();
        }
        delete this.badgeSubs[itemId];
    }

}