import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DateUtils, FormatUtils } from '@meraki-flux/purejs';
import { PrivateRate } from '@meraki-flux/schema';
import * as _ from 'lodash';

export enum PrivateRatesLevelType {
  PRACTICE = 'Practice level',
  PROVIDER = 'Provider level',
}
export class PrivateRateUiUtils {
  static pipe = new DatePipe('en-US');

  static openCopyRateScreen(
    router: Router,
    route: ActivatedRoute,
    relativeUrl: any,
    providerId: any,
    branchName: any
  ) {
    router.navigate([`${relativeUrl}copy`], {
      relativeTo: route,
      queryParams: _.pickBy({
        providerId: providerId,
        branchName: branchName,
      }),
    });
  }

  static openDeleteRatesScreen(
    router: Router,
    route: ActivatedRoute,
    relativeUrl: any,
    providerId: any,
    branchName: any
  ) {
    router.navigate([`${relativeUrl}delete`], {
      relativeTo: route,
      queryParams: _.pickBy({
        providerId: providerId,
        branchName: branchName,
      }),
    });
  }

  static formatPrivateRateTooltip(privateRate: PrivateRate): string {
    const updatedAt = this.pipe.transform(
      DateUtils.toDate(privateRate?.UpdatedAt),
      'dd/MM/yyyy HH:mm'
    );
    const updatedByString = privateRate?.UpdatedByName ? `by ${privateRate?.UpdatedByName}` : '';
    return FormatUtils.join([`Last edited on ${updatedAt}`, updatedByString], ' ');
  }

  static toLocalStorage(name: string, formValue: any) {
    if (formValue) {
      localStorage.setItem(name, JSON.stringify(formValue));
    } else {
      this.removeLocalStorage(name);
    }
  }

  static removeLocalStorage(name: string) {
    localStorage.removeItem(name);
  }

  static fromLocalStorage(name: string, form: any) {
    const value = localStorage.getItem(name);
    if (value) form.patchValue(JSON.parse(value));
  }
}
