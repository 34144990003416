import {
  BasePdfReportData, ERADSData,
  ERADSReportModel, PDFCellData,
  PDFParameter,
  REPORT_NAME,
  ReportHeader,
  ReportTable
} from '@meraki-flux/schema';

export class ERADSPdfReportDataBuilder {
  build(eraReportModel: ERADSReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.bpn = eraReportModel.Practice.BillingPracticeNumber;
    reportData.headers = this.buildHeader(eraReportModel);
    reportData.tables = this.buildTables(eraReportModel);
    reportData.dateHeader = `Date generated ${eraReportModel.ReportDate}`;
    reportData.footerText = '\t\u200B'.repeat(60);
    reportData.footer = {
      hbLogo: true,
    };
    return reportData;
  }

  private buildHeader(eraReportModel: ERADSReportModel): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
     leftParameters.push({name: 'Billing practice no.', data: {value: eraReportModel.Practice.BillingPracticeNumber, decoration: {bold: true}}});
     leftParameters.push({name: 'Practice', data: {value: eraReportModel.Practice.PracticeName, decoration: {bold: true}}});
    return [
      {
        name: REPORT_NAME.ERA_DS,
        includeGeneratedDate: true,
        left: leftParameters,
      },
    ];
  }

  private buildTables(eraReportModel: ERADSReportModel): ReportTable[] {
    const reportTables: ReportTable[] = [];
    const groupedData = this.groupData(eraReportModel);

    //Test all data
    // reportTables.push(this.buildDetailsTable(eraReportModel.ReportData, 'Test all data'));
    const orderedKeys = Object.keys(groupedData).sort((a, b) => {
      const dateA = new Date('01-'+a);
      const dateB = new Date('01-'+b);
      return dateA.getTime() - dateB.getTime();
    });
    for (const key of orderedKeys) {
      reportTables.push(this.buildDetailsTable(groupedData[key], this.getMonthYearName(key)));
    }

    return reportTables;
  }

  private groupData(eraReportModel: ERADSReportModel) {
    const groupedData: { [key: string]: ERADSData[] } = {};
    for (const data of eraReportModel.ReportData) {
      if (data.DeliveryDate) {
        const monthYearKey = data.DeliveryDate.slice(3, 10);
        if (!groupedData[monthYearKey]) {
          groupedData[monthYearKey] = [];
        }
        groupedData[monthYearKey].push(data);
      }
    }

    // Sort the grouped data by DeliveryDate
    for (const key in groupedData) {
      groupedData[key].sort((a, b) => {
        const dateA = new Date(a.DeliveryDate);
        const dateB = new Date(b.DeliveryDate);
        return dateA.getTime() - dateB.getTime();
      });
    }

    return groupedData;
  }

  private getMonthYearName(dateStr: string): string {
    const [monthStr, yearStr] = dateStr.split('-');
    const month = parseInt(monthStr, 10);
    const year = parseInt(yearStr, 10);

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return monthNames[month - 1] + ' ' + year;
  }

  private buildDetailsTable(eraReportData: ERADSData[], month): ReportTable {
    const table: ReportTable = {};
    table.rowHeadersBold = true;
    table.headers = [
      {
        rows: [[{ name: '', data: { value: month, decoration: { bold: true, italics: true } } }]],
        backgroundColor: '#ececec',
      },
    ];
    table.rowHeaders = [
      { value: 'Date', width: 100 },
      { value: 'Scheme', width: 450 }
    ];
    const rows = [];
    for (const item of eraReportData.sort((a, b) => new Date(a.DeliveryDate).getTime() - new Date(b.DeliveryDate).getTime())) {
      const row: PDFCellData[] = [];
      row.push({ value: item.DeliveryDate }), row.push({ value: item.FunderName }), rows.push(row);
    }
    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };
    if (!rows || rows.length === 0) {
      table.rows = undefined;
      table.rowHeaders = undefined;
      table.noRecordsText = {
        value: 'No records',
        decoration: {
          italics: true,
        },
      };
    }
    return table;
  }

}
