import moment from 'moment';
import {
  INVOICE_TYPE,
  InvoiceDataList, InvoiceExtractionList, NoDataError, PaymentsDataList, REMITTANCE_ADVICE_TYPE,
  ReversedTransactionsReportInfo,
  ReversedTransactionsReportModel,
  ReversedTransactionsReportRequest, TRANSACTION_TYPE, TransactionDataList, TransactionsExtractionList
} from "@meraki-flux/schema";

export class ReversedTransactionsReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: ReversedTransactionsReportRequest): Promise<ReversedTransactionsReportModel> {

    const reportHeader: ReversedTransactionsReportInfo = await this.buildReportInfo(reportRequest);
    const invModel: InvoiceDataList[] = await this.buildInvoiceModel(reportRequest);
    const paymentsModel: PaymentsDataList[] = await this.buildPaymentsModel(reportRequest);
    const transactionModel: TransactionDataList[] = await this.buildTransactionModel(reportRequest);

    this.checkDataAvailability(invModel,transactionModel,paymentsModel);

    return {
      ReversedTransactionsReportInfo: reportHeader,
      RVPInvoiceDS:invModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
      RVPaymentDS:paymentsModel,
      RVTransactionDS:transactionModel
    };

  }

  private checkDataAvailability(invoiceData: InvoiceDataList[], transactionData: TransactionDataList[],paymentData: PaymentsDataList[]) {
    let noData = true;
    if (invoiceData.length > 0 ||transactionData.length > 0 ||paymentData.length > 0) {
      noData = false;
    }
    if (noData) throw new NoDataError();
  }

  private async buildPaymentsModel(reportRequest: ReversedTransactionsReportRequest) {
    const paymentsData: PaymentsDataList[] = this.buildPaymentDataModel(reportRequest.TransactionsList);
    return paymentsData;
  }

  private async buildTransactionModel(reportRequest: ReversedTransactionsReportRequest) {
    const transactionData: TransactionDataList[] = this.buildTransactionDataModel(reportRequest.TransactionsList);
    return transactionData;
  }

  private async buildInvoiceModel(reportRequest: ReversedTransactionsReportRequest) {
    const invoiceData: InvoiceDataList[] = this.buildInvoiceDataModel(reportRequest.InvoiceList);
    return invoiceData;
  }

  private buildPaymentDataModel(transactionList: TransactionsExtractionList[]): PaymentsDataList[] {

    const groupedDetails = {};
      let payType ='';

       const sortedtransactionList = transactionList.sort((a, b) => {
        const dateA = a.ReversalDate; // If DateCancelled is undefined, use a default date
        const dateB = b.ReversalDate;

        return dateA.getTime() - dateB.getTime();
      });

      for (const tra of sortedtransactionList) {
        if(tra.TransactionType === TRANSACTION_TYPE.MEDICAL_AID_PAYMENT && tra.SchemeName !==undefined){
          if(tra.RemittanceType === REMITTANCE_ADVICE_TYPE.PAPER){
            payType ='Manual';
          }else{
            payType ='Electronic';
          }
        }else{
          payType =tra.PaymentDS?.Type;
        }
      const key = `${tra.TraHeaderId}_${tra.InvoiceDataset.InvoiceNo}`;
    // eslint-disable-next-line no-prototype-builtins
    if (!groupedDetails.hasOwnProperty(key)) {
      groupedDetails[key] = {
          ReversalDate:  tra.ReversalDate ? moment((tra.ReversalDate)).format(this.DATE_FORMAT): '',
          ReversalUser: tra.ReversalUser,
          ReversalReason: (tra.TransactionType === TRANSACTION_TYPE.PATIENT_PAYMENT || tra.TransactionType === TRANSACTION_TYPE.MEDICAL_AID_PAYMENT) ? tra.PaymentDS?.ReversalReason :tra.ReversalReason,
          InvoiceNum: tra.InvoiceDataset.InvoiceNo,
          DateOfService: tra.InvoiceDataset.DateOfService ? moment(this.convertTimestampToDateTime(tra.InvoiceDataset.DateOfService)).format(this.DATE_FORMAT): '',
          DateOfSubmission: tra.InvoiceDataset.DateOfSubmission ? moment(this.convertTimestampToDateTime(tra.InvoiceDataset.DateOfSubmission)).format(this.DATE_FORMAT): '',
          TreatingProvider:tra.ProviderName,
          AccountNum: tra.InvoiceDataset.Account.AccountNo,
          AccountHolder: tra.InvoiceDataset.MainMember.Surname+', '+tra.InvoiceDataset.MainMember.Name,
          InvoicePaymentAmount:tra.InvoiceDataset.AmountBilled,
          PaymentAmount: tra.PaymentDS?.AmountPaid,
          PaymentType:payType,
          PaymentReceiptNo:tra.PaymentDS?.PaymentReceiptNo,
          Scheme: tra.SchemeName,
          RANumber: tra.RaNum,
          TransactionType:tra.TransactionType,
          PaymentDate:tra.PaymentDS?.PaymentDate? moment(this.convertTimestampToDateTime(tra.PaymentDS?.PaymentDate)).format(this.DATE_FORMAT): '',
          PatPaymentAmt:0,
          MedPaymentAmt:0
        };
    }

    groupedDetails[key].PatPaymentAmt +=tra.PatPaymentAmt * -1;
    groupedDetails[key].MedPaymentAmt +=tra.MedPaymentAmt * -1;
    // Ensure the key exists in groupedDetails and push detailsObj
        if (!groupedDetails[key]) {
          groupedDetails[key] = [];
        }
    }
        const result: PaymentsDataList[] = [];
    for (const key in groupedDetails) {
      const item = groupedDetails[key];
      result.push({
        ReversalDate:  item.ReversalDate,
          ReversalUser: item.ReversalUser,
          ReversalReason: item.ReversalReason,
          InvoiceNum: item.InvoiceNum,
          DateOfService: item.DateOfService,
          DateOfSubmission: item.DateOfSubmission,
          TreatingProvider:item.TreatingProvider,
          AccountNum: item.AccountNum,
          AccountHolder: item.AccountHolder,
          InvoicePaymentAmount:item.InvoicePaymentAmount,
          PaymentAmount: item.PaymentAmount,
          PaymentType:item.PaymentType,
          PaymentReceiptNo:item.PaymentReceiptNo,
          Scheme: item.Scheme,
          RANumber: item.RANumber,
          TransactionType:item.TransactionType,
          PaymentDate:item.PaymentDate,
          PatPaymentAmt:item.PatPaymentAmt,
          MedPaymentAmt:item.MedPaymentAmt
      });
    }
      return result;
    }

  private buildTransactionDataModel(transactionList: TransactionsExtractionList[]): TransactionDataList[] {

	const groupedDetails = {};
	//let accHolder:string;
     const sortedtransactionList = transactionList.sort((a, b) => {
      const dateA = a.ReversalDate; // If DateCancelled is undefined, use a default date
      const dateB = b.ReversalDate;

      return dateA.getTime() - dateB.getTime();
    });

    for (const tra of sortedtransactionList) {

        //accHolder = tra.InvoiceDataset.MainMember.Surname+', '+tra.InvoiceDataset.MainMember.Name;

		const key = `${tra.TraHeaderId}`;
      // eslint-disable-next-line no-prototype-builtins
      if (!groupedDetails.hasOwnProperty(key)) {
	  groupedDetails[key] = {
        ReversalDate:  tra.ReversalDate ? moment((tra.ReversalDate)).format(this.DATE_FORMAT): '',
        ReversalUser: tra.ReversalUser,
        ReversalReason: tra.ReversalReason,
        InvoiceNum: tra.InvoiceDataset.InvoiceNo,
        DateOfService: tra.InvoiceDataset?.DateOfService ? moment(this.convertTimestampToDateTime(tra.InvoiceDataset.DateOfService)).format(this.DATE_FORMAT): '',
        DateOfSubmission: tra.InvoiceDataset?.DateOfSubmission ? moment(this.convertTimestampToDateTime(tra.InvoiceDataset.DateOfSubmission)).format(this.DATE_FORMAT): '',
        TreatingProvider:tra.ProviderName,
        AccountNum: tra.InvoiceDataset.Account.AccountNo,
        AccountHolder: tra.InvoiceDataset.MainMember.Surname+', '+tra.InvoiceDataset.MainMember.Name,
        InvoicePaymentAmount:tra.InvoiceDataset.AmountBilled,
        CrDate:  tra.CreditNotesDate ? moment(this.convertTimestampToDateTime(tra.CreditNotesDate)).format(this.DATE_FORMAT): '',
        CrAmount: 0,
        CrType: tra.CreditNotesType,
        WriteOffDate: tra.WriteOffDate ? moment(this.convertTimestampToDateTime(tra.WriteOffDate)).format(this.DATE_FORMAT): '',
        WriteOffAmount: 0,
        WriteOffType: tra.WriteOffType,
        PcDate:tra.PaymentCorrectionDate ? moment(this.convertTimestampToDateTime(tra.PaymentCorrectionDate)).format(this.DATE_FORMAT): '',
        PcAmount:0,
        TransactionType:tra.TransactionType
      };
	}
  groupedDetails[key].CrAmount =tra.CreditNotesmount;
  groupedDetails[key].WriteOffAmount =tra.WriteOffAmount*-1;
  groupedDetails[key].PcAmount =tra.PaymentCorrectionAmount;
      // Ensure the key exists in groupedDetails and push detailsObj
      if (!groupedDetails[key]) {
        groupedDetails[key] = [];
      }
    }

    // Convert groupedDetails object to an array
  const result: TransactionDataList[] = [];
  for (const key in groupedDetails) {
    const item = groupedDetails[key];
    result.push({
      ReversalDate: item.ReversalDate,
      ReversalUser: item.ReversalUser,
      ReversalReason: item.ReversalReason,
      InvoiceNum: item.InvoiceNum,
      DateOfService: item.DateOfService,
      DateOfSubmission: item.DateOfSubmission,
      TreatingProvider:item.TreatingProvider,
      AccountNum: item.AccountNum,
      AccountHolder: item.AccountHolder,
      InvoicePaymentAmount:item.InvoicePaymentAmount,
      CrDate: item.CrDate,
      CrAmount: item.CrAmount,
      CrType: item.CrType,
      WriteOffDate: item.WriteOffDate,
      WriteOffAmount: item.WriteOffAmount,
      WriteOffType: item.WriteOffType,
      PcDate:item.PcDate,
      PcAmount:item.PcAmount,
      TransactionType:item.TransactionType
    });
  }
  return result;
  }

  private buildInvoiceDataModel(invoiceList: InvoiceExtractionList[]): InvoiceDataList[] {

    const detailtems: InvoiceDataList[] = [];

    const sortedInvoiceDetails = invoiceList.sort((a, b) => {
      const dateA = a.DateCancelled; // If DateCancelled is undefined, use a default date
      const dateB = b.DateCancelled;

      // First, compare by DateCancelled
      const dateComparison = dateA.getTime() - dateB.getTime();

      if (dateComparison !== 0) {
          return dateComparison; // If dates are not equal, return the result of the date comparison
      }

      return a.InvNumber.localeCompare(b.InvNumber);

  });
    let refNumber:string;
    for (const inv of sortedInvoiceDetails) {

      if(inv.InvoiceType !== INVOICE_TYPE.MEDICAL_AID){
        refNumber ='';
      }else{
        if(inv.HbMessage !== undefined || inv.HbMessage !==''){
          refNumber =inv.HbMessage;
        }else{
          refNumber=inv.ReferenceNum;
        }
      }
      const detailsObj = {
        DateCancelled:  inv.DateCancelled ? moment(inv.DateCancelled).format(this.DATE_FORMAT): '',
        ReversalUser: inv.reversalUser,
        CancelReason: inv.CancelReason,
		    InvoiceType:inv.InvoiceType,
        InvNumber: inv.InvNumber,
        DateOfService: inv.DateOfService ? moment(this.convertTimestampToDateTime(inv.DateOfService)).format(this.DATE_FORMAT): '',
        DateOfSubmission: inv.DateOfSubmission ? moment(this.convertTimestampToDateTime(inv.DateOfSubmission)).format(this.DATE_FORMAT): '',
        TreatingProvider:inv.TreatingProvider,
        AccNumber: inv.AccNumber,
        AccHolder: inv.AccHolder,
        InvAmount:inv.InvAmount,
        ReferenceNum: refNumber,

      };

      detailtems.push(detailsObj);
    }

    return detailtems;
  }

  private async buildReportInfo(
    reportRequest: ReversedTransactionsReportRequest
  ): Promise<ReversedTransactionsReportInfo> {
    const reportInfo: ReversedTransactionsReportInfo = {};

    reportInfo.Practice =
      reportRequest.Practice?.PracticeName +
      ' (' +
      reportRequest.Practice?.BillingPracticeNumber +
      ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    reportInfo.DateRange =
      moment(reportRequest.DateFrom).format('YYYY-MM-DD') +
      ' - ' +
      moment(reportRequest.DateTo).format('YYYY-MM-DD');
    reportInfo.DateRangeType = reportRequest.DateRangeType;
    reportInfo.BillingPracticeNumber = reportRequest.Practice?.BillingPracticeNumber;

    return reportInfo;
  }

  private convertTimestampToDateTime(val: any): Date {
    if (val) {
      const seconds = val.seconds;
      const nanoseconds = val.nanoseconds;
      const milliseconds = seconds * 1000 + nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date;
    }

    return null;
  }

}
