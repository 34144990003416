import { P } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Account, APP_RUNTIME, APP_RUNTIME_CONTEXT, AuthService, PatientSearchResultPipe, SearchService } from '@meraki-flux/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import {AccountMember, PatientAccountIndex} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
})
@UntilDestroy()
export class PatientSearchComponent implements OnInit, OnDestroy {

  @ViewChild('searchField', { read: MatAutocompleteTrigger }) searchAutoComplete: MatAutocompleteTrigger;
  @ViewChild('searchField') searchInput: ElementRef;

  searchBusy$ = new BehaviorSubject(false);
  searchResults$ = new BehaviorSubject<PatientAccountIndex[]>([]);
  searchForm = new FormControl('');
  searchFocused$ = new BehaviorSubject(false);

  constructor(
    private searchService: SearchService,
    private authService: AuthService,
    private router: Router,
    @Inject(APP_RUNTIME_CONTEXT) private runtimeContext: APP_RUNTIME) { }

  ngOnInit(): void {
    this.searchForm.valueChanges.pipe(
      debounceTime(500),
      tap(() => this.searchResults$.next([])),
      filter(val => !!val),
      tap(() => this.searchBusy$.next(true)),
      untilDestroyed(this)
    ).subscribe(val => this.searchService.searchPatients(this.authService.selectedBPN, val, {
      limit: 10,
      filter: [
        'Patient EXISTS',
        'Account EXISTS'
      ],
      attributesToHighlight: ['*']
    }).then(res => this.searchResults$.next((res.hits as any[]).map(h => h._formatted) || [])).finally(() => this.searchBusy$.next(false)));
  }

  ngOnDestroy() { }

  patientSearchDisplayFn(result: { patient: AccountMember, account: Account }) {
    return new PatientSearchResultPipe().transform(result);
  }

  onResultSelected(result) {
    if (result) {
      this.resetSearch();
      this.router.navigate([this.runtimeContext === APP_RUNTIME.CAPACITOR ? 'accounts' : '', 'account', result.option.value.Account.Id, 'member', result.option.value.Patient.Id]);
    }
  }

  onOptionMousedown(e) {
    e.stopPropagation();
  }


  onClearSearch(e) {
    e.stopPropagation();
    this.searchForm.setValue('');
  }

  onFocusOut(e) {
    if (!this.searchAutoComplete.panelOpen) {
      this.resetSearch();
    } else {
      this.searchAutoComplete.closePanel();
    }
  }

  private resetSearch() {
    this.searchForm.setValue('');
    this.searchResults$.next([]);
    this.searchBusy$.next(false);
    this.searchFocused$.next(false);
    requestAnimationFrame(() => this.searchInput.nativeElement.blur());
  }

}
