import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import {
  Firestore,
  collection,
  collectionSnapshots,
  doc,
  docSnapshots,
  getDoc,
  query,
  where
} from '@angular/fire/firestore';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PathUtils, PrivateRateUtils } from '@meraki-flux/purejs';
import {
  ACCOUNT_TYPE, Account, AccountMember,AccountInfo, Balance, BenefitCheckLine, CAPTURE_INVOICE_MODE,
  COPY_INVOICE_MODE, CalendarProvider, CopyInvoiceContext, CustomChargeCodeStatus,
  CustomChargeCodeType, CustomChargeCodes, DialogType, INVOICE_LINE_TYPE,
  INVOICE_SUBTYPE,
  INVOICE_TYPE, Invoice, InvoiceLine, ModifierCodes, PLACE_OF_SERVICE, PROVIDER_MEDICAL_TYPE_CODE,
  PROVIDER_SPECIALITY, PricingSubscription, PrivatePriceRequest, PrivateRate, SEARCH_TYPE, SPECIALITY_MEDICAL_TYPE, YES_BUTTON_ID,
  PracticeProvider,
  ModifierModelData
} from '@meraki-flux/schema';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { isEqual, orderBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AccountRepository } from '../../repositories/account.repository';
import { InvoiceRepository } from '../../repositories/invoice.repository';
import { AuthService } from '../../services/auth.service';
import { BenefitCheckService } from '../../services/benefit-check.service';
import { DialogService } from '../../services/dialog.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { LogService } from '../../services/log.service';
import { PricingService } from '../../services/pricing.service';
import { PrivateRatePricingService } from '../../services/private-rates/private-rate-pricing.service';
import { LookupSelection, SearchService } from '../../services/search.service';
import { DialogUtils } from '../../utils/dialog-utils';
import { MoneyUtils } from '../../utils/money-utils';
import { AdditionalMedicineDetailsModalComponent } from '../additional-medicine-details-modal/additional-medicine-details-modal.component';
import { ModifierComponent } from '../modifier/modifier.component';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';

@Component({
  selector: 'meraki-flux-invoice-lines',
  templateUrl: './invoice-lines.component.html',
  styleUrls: ['./invoice-lines.component.scss']
})
@UntilDestroy()
export class InvoiceLinesComponent implements OnInit, OnDestroy {
  @ViewChild('headerDiag') headerDiagSearchInput!: ElementRef;

  @ViewChild('selectedDiagnoses', { read: ElementRef }) diagnosisInput: ElementRef;

  @ViewChildren('focusInput', { read: ElementRef }) focusInputs: QueryList<ElementRef>;

  @Input() type: 'invoice' | 'BCLines' | 'BCTemplates';
  @Input() account: Account | AccountInfo;
  @Input() BCLines: BenefitCheckLine[];
  @Input() provider: CalendarProvider;

  availableLineTypes$ = new BehaviorSubject<INVOICE_LINE_TYPE[]>([]);
  medicineTariffCodes$ = new BehaviorSubject<{ [key: string]: { Description: string, IsDefault: boolean } }>({});
  consumableTariffCodes$ = new BehaviorSubject<{ [key: string]: { Description: string, IsDefault: boolean } }>({});
  allCustomChargeCodes$ = new BehaviorSubject<CustomChargeCodes[]>([]);
  lineTypes = INVOICE_LINE_TYPE;
  privateRates: PrivateRate[] = [];
  ppeCodes$ = new BehaviorSubject<{ code: string, description: string }[]>([]);
  account$ = new BehaviorSubject<Account | AccountInfo>({});
  activeContractSubscriptions$ = new BehaviorSubject<PricingSubscription[]>([]);
  optionCodeContracts$ = new BehaviorSubject<string[]>([]);
  initialValueApplied = false;
  invoiceLinesSource = new MatTableDataSource();
  headerDiagnosesForm = new FormControl<{ code: string, description: string }[]>([]);
  total$ = new BehaviorSubject(0);
  practiceProviders$ = new BehaviorSubject<PracticeProvider[]>([]);
  tabIndex = 0;
  reloadPrices = true;
  isModifierCode = false;

  tableColumns = [
    'add',
    'lineType',
    'tariffCode',
    'nappiCode',
    'description',
    'diagnosisCode',
    'quantity',
    'price',
    'info'
  ];

  // Diagnosis Search
  diagnosisSearchResult$ = new BehaviorSubject<{ code: string, description: string, highlightedCode?: string, highlightedDescription?: string }[]>([]);
  diagnosisSearchBusy$ = new BehaviorSubject<boolean>(false);
  singleDiagnosisSelected$ = new BehaviorSubject<boolean>(false);
  diagnosisSearchFormGroup = new UntypedFormGroup({
    headerDiagnosis: new UntypedFormControl(''),
    applyToAllLines: new UntypedFormControl(true)
  });

  // Nappi Search
  nappiCodeSearchRequest$ = new BehaviorSubject<{ query: string, lineType: INVOICE_LINE_TYPE }>({ query: '', lineType: INVOICE_LINE_TYPE.MEDICINE });
  nappiCodeSearchResult$ = new BehaviorSubject<LookupSelection[]>([]);
  nappiCodeSearchBusy$ = new BehaviorSubject<boolean>(false);
  singleNappiSelected$ = new BehaviorSubject<boolean>(false);

  // Tariff Search
  tariffCodeSearchRequest$ = new BehaviorSubject<{ query: string, lineType: INVOICE_LINE_TYPE }>({ query: '', lineType: INVOICE_LINE_TYPE.PROCEDURE });
  tariffCodeSearchResult$ = new BehaviorSubject<LookupSelection[]>([]);
  tariffCodeSearchBusy$ = new BehaviorSubject<boolean>(false);
  singleTariffSelected$ = new BehaviorSubject<boolean>(false);

  CURR_PIPE = new CurrencyPipe('en-ZA', 'R');

  INVALID_NAPPI_CODE_VALIDATOR: ValidatorFn = (control: AbstractControl) => {
    const lineType = control.parent.get('LineType')?.value;
    const lineCodeSelected = control.parent.get('LineCodeSelected')?.value;

    const relevantLineType = lineType === INVOICE_LINE_TYPE.CONSUMABLE ||
      lineType === INVOICE_LINE_TYPE.MEDICINE ||
      lineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE ||
      lineType === INVOICE_LINE_TYPE.CSTM_MEDICINE;

    return relevantLineType && lineCodeSelected === false ?
      { 'INVALID_NAPPI_CODE': 'Invalid nappi code' } : null
  };

  INVALID_TARIFF_CODE_VALIDATOR: ValidatorFn = (control: AbstractControl) => {
    const lineType = control.parent.get('LineType')?.value;
    const lineCodeSelected = control.parent.get('LineCodeSelected')?.value;

    const relevantLineType = lineType === INVOICE_LINE_TYPE.ADMIN ||
      lineType === INVOICE_LINE_TYPE.PROCEDURE ||
      lineType === INVOICE_LINE_TYPE.MODIFIER ||
      lineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE;

    return relevantLineType && lineCodeSelected === false ?
      { 'INVALID_TARIFF_CODE': 'Invalid tariff code' } : null
  }

  get pricingBusy() {
    return this.invoiceLinesSource.data?.some((d: any) => !!d?.form?.get('PriceBusy')?.value) || false;
  }

  constructor(
    private builder: UntypedFormBuilder,
    private firestore: Firestore,
    private authService: AuthService,
    private searchService: SearchService,
    private matDialog: MatDialog,
    private invoiceRepository: InvoiceRepository,
    private accountRepository: AccountRepository,
    private dialogService: DialogService,
    private pricingService: PricingService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private benefitCheckService: BenefitCheckService,
    private renderer: Renderer2,
    private el: ElementRef,
    private logger: LogService,
    private privateRatePricingService: PrivateRatePricingService
  ) {
  }

  async ngOnInit() {
    // Load options when component inits
    getDoc(doc(this.firestore, 'Configuration/TariffCode')).then((docRef) => {
      this.medicineTariffCodes$.next(docRef.get('MedicineOptions'));
      this.consumableTariffCodes$.next(docRef.get('ConsumableOptions'));
    });

    collectionSnapshots<PracticeProvider>(query(
      collection(this.firestore, PathUtils.practiceProviderCollectionPath(this.authService.selectedBPN)),
      where('IsActive', '==', true),
    )).pipe(
      first(),
      map(providerCollRef => providerCollRef.map(docRef => ({ Id: docRef.id, ...docRef.data() }))),
      tap(providers => this.practiceProviders$.next(providers)),
      untilDestroyed(this)
    ).subscribe();

    // Load custom charge codes
    // All custom charge codes are loaded here and filtered as needed.
    collectionSnapshots(collection(this.firestore, `Practice/${this.authService.selectedBPN}/CustomChargeCode`)).
      pipe(
        tap((res) => {
          this.allCustomChargeCodes$.next(res.map(doc => doc.data() as CustomChargeCodes)?.filter(c => c.Status === CustomChargeCodeStatus.ACTIVE) || []);
        }),
        untilDestroyed(this)
      ).subscribe();


      this.setupTreatingProviderHandling();
      this.setupLineChangeHandling();
      this.setupDiagnosisSearch();
      this.setupNappiCodeSearch();
      this.setupTariffCodeSearch();
      this.setupOptionCodeContractList().then(() => {
        this.type === 'invoice' ? this.applyInitialValue() : null;
      });

    if (this.type === 'BCLines' || this.type === 'BCTemplates') {
      this.tableColumns.splice(5, 1);
      this.tableColumns.splice(7, 1);

      if (this.type === 'BCTemplates') {
        this.tableColumns.splice(6, 1);
      }

      this.availableLineTypes$.next([INVOICE_LINE_TYPE.PROCEDURE, INVOICE_LINE_TYPE.MEDICINE, INVOICE_LINE_TYPE.CONSUMABLE]);
      const invLines = this.mapBCLinesToInvoiceLines(this.BCLines);
      if (invLines.length > 0) {
        await Promise.all(invLines.map(async (invLine) => {
          await this.addLine(invLine.LineNumber, invLine, false);
        }));
        this.headerDiagnosesForm.patchValue([
          {
            code: invLines[0].DiagnosisCodes[0],
            description: '',
          }
        ]);
      }
    }
  }

  ngOnDestroy() {
    return;
  }

  async loadActiveContractSubscriptions(providerId: string) {
    const providerDoc = await getDoc(doc(this.firestore, PathUtils.practiceProviderPath(this.authService.selectedBPN, providerId)));
    const providerSubs = providerDoc.get('PricingSubscriptions') as PricingSubscription[];
    const activeSubs = providerSubs?.filter(sub => {
      let res = true;
      if (sub.DateFrom) {
        res = res && moment().isSameOrAfter(moment(sub.DateFrom.toDate()));
      }

      if (sub.DateTo) {
        res = res && moment().isSameOrBefore(moment(sub.DateTo.toDate()));
      }

      return res;
    }) || [];
    this.activeContractSubscriptions$.next(activeSubs);
  }

  private setupTreatingProviderHandling() {
    this.invoiceRepository.activeInvoiceTreatingProvider$().pipe(
      untilDestroyed(this),
    ).subscribe(provider => {
      if (provider) {
        this.loadActiveContractSubscriptions(provider.Id);
      } else {
        this.activeContractSubscriptions$.next([]);
      }
    });
  }

  private async setupOptionCodeContractList() {
    const mapDoc = await getDoc(doc(this.firestore, `PriceContractOptionCode/${this.account?.Option || '91H'}`));
    const contracts = mapDoc.get('Codes') || [];
    this.optionCodeContracts$.next(contracts);
  }

  private setupDiagnosisSearch() {
    this.diagnosisSearchFormGroup.get('headerDiagnosis')?.valueChanges.pipe(
      // Checking for typeof string here since adding a chip also triggers a change
      filter(val => !!val && typeof val === 'string'),
      distinctUntilChanged(),
      debounceTime(200),
      tap(async val => {
        if (this.type !== 'invoice' && this.headerDiagnosesForm.value.length === 1) {
          this.diagnosisSearchFormGroup.get('headerDiagnosis').disable();
          const inputElement = this.el.nativeElement.querySelector('#diagnosisSearchInput');

          inputElement.value ? this.renderer.setProperty(inputElement, 'value', '') : null;
        } else {
          this.diagnosisSearchBusy$.next(true);
          this.singleDiagnosisSelected$.next(false);
          const res = await this.searchService.searchDiagnosis(val);
          const mappedRes = res.hits.filter((hit: any) => !!hit.data || !!hit._highlightResult?.data)
            .map((hit: any) => {
              const code = hit.data?.ICD10Code;
              const highlightedCode = this.formatHighlightedValue(hit._highlightResult?.data?.ICD10Code?.value || hit.data?.ICD10Code);
              const description = hit.data?.ICD10CodeDescription;
              const highlightedDescription = this.formatHighlightedValue(hit._highlightResult?.data?.ICD10CodeDescription?.value || hit.data?.ICD10CodeDescription);
              return {
                code, description, highlightedCode, highlightedDescription
              }
            });

          this.diagnosisSearchResult$.next(orderBy(mappedRes, ['code'], ['asc']));
          this.diagnosisSearchBusy$.next(false);
          this.checkSingleDiagnosisResult(mappedRes);
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.diagnosisSearchFormGroup.get('applyToAllLines')?.valueChanges.pipe(
      tap((val) => {
        if (val) {
          this.headerDiagnosesForm.updateValueAndValidity();
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.headerDiagnosesForm.valueChanges.pipe(
      tap(() => {
        (this.type !== 'invoice' && this.headerDiagnosesForm.value.length === 1) ? this.diagnosisSearchFormGroup.get('headerDiagnosis').disable() : this.diagnosisSearchFormGroup.get('headerDiagnosis').enable();
        if (this.diagnosisSearchFormGroup.get('applyToAllLines')?.value) {
          this.applyHeaderDiagnoses();
        }
        this.initialValueApplied = true;
        this.updateInvoice();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private checkSingleDiagnosisResult(mappedRes: any[]) {
    if (mappedRes?.length === 1) {
      this.singleDiagnosisSelected$.next(true)
      this.onHeaderDiagnosisSelected({
        option: {
          value: mappedRes[0]
        }
      });
      this.onHeaderDiagnosisSearchClosed();
    }
  }

  // NappiCode search is shared between all invoice lines
  private setupNappiCodeSearch() {
    this.nappiCodeSearchRequest$.pipe(
      debounceTime(200),
      tap(() => {
        this.nappiCodeSearchResult$.next([]);
      }),
      filter(request => !!request?.query),
      tap(async request => {
        try {
          this.nappiCodeSearchBusy$.next(true);
          this.singleNappiSelected$.next(false);
          let mappedResult: LookupSelection[];
          const searchVal = request.query?.toLowerCase().trim();
          const query = searchVal.substring(1, searchVal.length);
          switch (request.lineType) {
            case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
            case INVOICE_LINE_TYPE.CSTM_MEDICINE:
              {
                const lineType = request.lineType === INVOICE_LINE_TYPE.CSTM_MEDICINE ? CustomChargeCodeType.CSTM_MEDICINE : CustomChargeCodeType.CSTM_CONSUMABLE;

                const res = this.allCustomChargeCodes$.value.filter(val => val.Type === lineType &&
                  (val.Code?.toLowerCase().startsWith(searchVal) || val.Description?.toLowerCase().startsWith(searchVal)));
                let result = [...res];

                if (result.filter(r => r.Code === query).length === 1)
                  result = result.filter(r => r.Code === query);

                mappedResult = result.map(ccode => {
                  const dspString = `${ccode.Code} - ${ccode.Description || 'Unknown'} (Pack size: ${ccode.PackSize || 1} - ${this.CURR_PIPE.transform(MoneyUtils.fromCents(ccode.PackPrice || 0))})`;
                  return {
                    Code: ccode.Code,
                    Description: ccode.Description || 'Unknown',
                    DisplayString: dspString,
                    Price: 0,
                    Quantity: ccode.PackSize || 1
                  }
                }).sort((x, y) => x.Code >= y.Code ? 1 : -1);
              }
              break;
            case INVOICE_LINE_TYPE.CONSUMABLE:
            case INVOICE_LINE_TYPE.MEDICINE:
              {
                const res = await this.searchService.searchAutocomplete(request.query, request.lineType);
                let result = [...res];

                if (!isNaN(Number(query)) && result.filter(r => r.Code === query).length === 1)
                  result = result.filter(r => r.Code === query);

                mappedResult = [...result];
              }
              break;
          }
          this.nappiCodeSearchResult$.next(mappedResult);
          await this.selectSingleNappiCode(mappedResult);

        } catch (err) {
          this.logger.error(err);
        } finally {
          this.nappiCodeSearchBusy$.next(false);
        }
      }),
      untilDestroyed(this)
    ).subscribe()
  }

  // TariffCode search is shared between all invoice lines.
  private setupTariffCodeSearch() {
    const invoice$ = this.invoiceRepository.activeInvoice$().pipe(
      shareReplay(1),
      untilDestroyed(this)
    );

    const invoiceType$ = invoice$.pipe(
      map((invoice: Invoice) => (invoice?.Type)),
      distinctUntilChanged(isEqual)
    );

    const treatingProviderSpeciality$ = invoice$.pipe(
      map((invoice: Invoice) => invoice?.TreatingProvider?.Speciality),
      distinctUntilChanged(isEqual)
    );

    combineLatest([invoiceType$, treatingProviderSpeciality$]).pipe(
      distinctUntilChanged(isEqual),
      switchMap(([type, speciality]) => {
        const result = [];
        if (type && speciality) {
          let option = '90H';
          if (type === INVOICE_TYPE.MEDICAL_AID) {
            if (this.account?.Option) {
              option = this.account.Option;
            }
          }

          return docSnapshots(doc(this.firestore, `PPECode/${option}_${PROVIDER_SPECIALITY[speciality]}`)).pipe(
            map(docRef => docRef.get("Tariffs") || []),
            map(tariffs => tariffs.map(t => t.TariffCode))
          );

        }
        return of(result);
      }),
      tap(tariffCodes => this.ppeCodes$.next(tariffCodes.map((code: any) => ({ code, description: "Personal Protective Equipment (PPE)" })))),
      untilDestroyed(this)
    ).subscribe();

    this.tariffCodeSearchRequest$.pipe(
      debounceTime(200),
      tap(() => this.tariffCodeSearchResult$.next([])),
      filter(request => !!request?.query),
      tap(async request => {
        try {
          this.tariffCodeSearchBusy$.next(true);
          this.singleTariffSelected$.next(false);
          const searchVal = request.query?.toLowerCase();
          let mappedResult: {
            Code: string,
            DisplayString: string,
            Description: string,
            Price?: number,
            Quantity?: number,
            IsPPELine?: boolean
          }[];
          switch (request.lineType) {
            case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
              {
                const res = this.allCustomChargeCodes$.value.filter(val => val.Type === CustomChargeCodeType.CSTM_PROCEDURE &&
                  (val.Code?.toLowerCase().startsWith(searchVal.trim()) || val.Description?.toLowerCase().startsWith(searchVal.trim())));

                let result = [...res];

                if (result.filter(r => r.Code?.toLowerCase() === searchVal).length === 1)
                  result = result.filter(r => r.Code.toLowerCase() === searchVal);

                mappedResult = result.map(ccode => ({
                  Code: ccode.Code,
                  DisplayString: `${ccode.Code} - ${ccode.Description || 'Unknown'}`,
                  Description: ccode.Description || 'Unknown',
                  Price: 0,
                  Quantity: 1
                }));
              }
              break;
            case INVOICE_LINE_TYPE.ADMIN:
              {
                const res = this.allCustomChargeCodes$.value.filter(val => val.Type === CustomChargeCodeType.ADMIN &&
                  (val.Code?.toLowerCase().startsWith(searchVal.trim()) || val.Description?.toLowerCase().startsWith(searchVal.trim())));

                let result = [...res];

                if (result.filter(r => r.Code?.toLowerCase() === searchVal).length === 1)
                  result = result.filter(r => r.Code.toLowerCase() === searchVal);

                mappedResult = result.map(ccode => ({
                  Code: ccode.Code,
                  DisplayString: `${ccode.Code} - ${ccode.Description || 'Unknown'}`,
                  Description: ccode.Description || 'Unknown',
                  Price: 0,
                  Quantity: 1
                }));
              }
              break;
            case INVOICE_LINE_TYPE.PROCEDURE:
            case INVOICE_LINE_TYPE.MODIFIER:
              {
                const providerTypeCode = this.getProviderMedicalType();
                const disciplineCode = providerTypeCode === 'MP' || providerTypeCode === 'DP' ? null : this.getProviderSpecialityCode();
                const res = await this.searchService.searchTariffCodes(searchVal.trim(), request.lineType, disciplineCode, providerTypeCode);

                let result = [...res];

                if (result.filter(r => r.Code === searchVal).length === 1) {
                  result = result.filter(r => r.Code === searchVal);
                }

                // (AD) todo right now this add duplicates in search results as search already include ppe codes,
                // and ppeCodes$ obs seems like only keep default ppe for specific speciality,
                // if (request.lineType === INVOICE_LINE_TYPE.PROCEDURE) {
                //   const matchedPPECodes = this.ppeCodes$.value.filter(val =>
                //     val.code.toLowerCase().includes(searchVal) ||
                //     val.description.toLowerCase().includes(searchVal)).map(
                //       ppeRes => ({
                //         Code: ppeRes.code,
                //         DisplayString: `${ppeRes.code} - ${ppeRes.description}`,
                //         Description: ppeRes.description,s
                //         Price: 0,
                //         Quantity: 1
                //       })
                //     );
                //   result.push(...matchedPPECodes);
                // }

                mappedResult = result.map((p: any) => ({
                  Code: p.Code,
                  Description: p.Description.replace(/[‘’]/g, ""),
                  DisplayString: p.DisplayString,
                  IsPPELine: p.Code.includes('PPE')// ugly way to detect if it's PPE Code (required later for validation)
                }));
              }
              break;
          }
          this.tariffCodeSearchResult$.next(mappedResult);
          await this.selectSingleTariffCode(mappedResult, request.lineType);
        } catch (err) {
          this.logger.error(err);
        } finally {
          this.tariffCodeSearchBusy$.next(false);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private async selectSingleNappiCode(res: LookupSelection[]) {
    const row = this.invoiceLinesSource.data?.find(x => (x as any).active) as any;
    if (res?.length === 1 && row && row.active) { // check if current line is active
      this.singleNappiSelected$.next(true);
      await this.onNappiCodeSelected({
        option: {
          value: res[0]
        }
      }, row.form);
      this.onNappiCodeSearchClosed(row.form);
      this.clearNappiCodeSearch();
    }
  }

  private async selectSingleTariffCode(mappedRes: { Description: any; Code: any}[], LineType: any ) {
    const row = this.invoiceLinesSource.data?.find(x => (x as any).active) as any;
    if (mappedRes?.length === 1 && row) {
      const lines = this.getLines();
      const codeLines = lines.filter(l => l.get('TariffCode').value === mappedRes[0].Code  && l.get('LineType').value === LineType);
      if (codeLines.length > 1) {
        this.singleTariffSelected$.next(true);
        const firstLine = codeLines[0] as FormGroup;
        if(firstLine.get('LineType')?.value != INVOICE_LINE_TYPE.MODIFIER){
          firstLine.get('Quantity').setValue(firstLine.get('Quantity').value + (row.Quantity | 1));
          this.removeLine(this.invoiceLinesSource.data?.indexOf(row))
        }
      }
      else {
        this.singleTariffSelected$.next(true);
        await this.onTariffCodeSelected({
          option: {
            value: mappedRes[0]
          }
        }, row.form);
      }
      await this.onTariffCodeSearchClosed();
    }
  }

  async applyInitialValue() {
    const invoice = this.invoiceRepository.getActiveInvoice() as Invoice;
    const captureMode: CAPTURE_INVOICE_MODE = this.invoiceRepository.getInvoiceCaptureMode();
    const invoiceCopyContext: CopyInvoiceContext = this.invoiceRepository.getInvoiceCopyContext();
    this.reloadPrices = invoiceCopyContext ? invoiceCopyContext.copyPriceMode === COPY_INVOICE_MODE.RELOAD_PRICE : captureMode === CAPTURE_INVOICE_MODE.NEW;
    if (invoice?.Lines) {
      // assume the line is in cents and convert to rands
      invoice.Lines.forEach(line => {
        this.createLineGroup(((line.LineNumber || 0) - 1), {
          ...line,
          AmountBilled: MoneyUtils.fromCents(line.AmountBilled || 0),
        }, !this.reloadPrices);
      });

      this.calculateTotal();
    }

    const diagnosisQueries = invoice?.HeaderDiagnosisCodes?.map(async code => {
      const res = await this.searchService.searchDiagnosis(code);
      const match: any = res.hits?.find((hit: any) => hit.data.ICD10Code === code);
      if (match) {
        return { code, description: match?.data?.ICD10CodeDescription };
      }
      return { code, description: '' };

    }) || [];

    await Promise.all(diagnosisQueries).then(res => this.headerDiagnosesForm.setValue(res, { emitEvent: false }));
    if (this.reloadPrices) {
      await this.refreshPrices();
    }
    this.initialValueApplied = true;
    this.updateInvoice();
  }

  setupLineChangeHandling() {
    this.invoiceRepository.activeInvoice$().pipe(
      tap(invoice => {
        const invoiceType: INVOICE_TYPE = invoice?.Type;
        const invoiceSubType: INVOICE_SUBTYPE = invoice?.Subtype;
        const NO_CUSTOM_LIST = Object.values(INVOICE_LINE_TYPE).filter(type => {
          return type !== INVOICE_LINE_TYPE.CSTM_CONSUMABLE &&
            type !== INVOICE_LINE_TYPE.CSTM_MEDICINE &&
            type !== INVOICE_LINE_TYPE.CSTM_PROCEDURE &&
            type !== INVOICE_LINE_TYPE.ADMIN
        });
        if (invoiceSubType === INVOICE_SUBTYPE.MEDICAL_INSURER) {
          this.availableLineTypes$.next([INVOICE_LINE_TYPE.PROCEDURE]);
        } else if (invoiceType === INVOICE_TYPE.CASH) {
          const customCodes = this.allCustomChargeCodes$.getValue() || [];
          const hasAllCustomCode = customCodes.some(c => c.Type === CustomChargeCodeType.ALL);
          const types = [];
          if (hasAllCustomCode) {
            types.push(...Object.values(INVOICE_LINE_TYPE));
          } else {
            types.push(...NO_CUSTOM_LIST);
            if (customCodes.some(c => c.Type === CustomChargeCodeType.ADMIN)) types.push(INVOICE_LINE_TYPE.ADMIN);
            if (customCodes.some(c => c.Type === CustomChargeCodeType.CSTM_PROCEDURE)) types.push(INVOICE_LINE_TYPE.CSTM_PROCEDURE);
            if (customCodes.some(c => c.Type === CustomChargeCodeType.CSTM_MEDICINE)) types.push(INVOICE_LINE_TYPE.CSTM_MEDICINE);
            if (customCodes.some(c => c.Type === CustomChargeCodeType.CSTM_CONSUMABLE)) types.push(INVOICE_LINE_TYPE.CSTM_CONSUMABLE);
          }
          this.availableLineTypes$.next(types);
        } else {
          this.availableLineTypes$.next(NO_CUSTOM_LIST);

          this.invoiceLinesSource.data.forEach((line: any) => {
            if (!this.availableLineTypes$.value.includes(line?.form?.get('LineType')?.value)) {
              // Unset the invoice line type when it's not a valid option
              line?.form?.get('LineType')?.setValue(null);
            }
          });
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  // TODO: try to optimise here, could cause memory leaks.
  // TODO: Handle unsubs for line deletes as well.
  async addLine(index: number = -1, line?: InvoiceLine, validate?: boolean, useFocus: boolean = false) {
    if (validate) {
      const invoice = this.invoiceRepository.getActiveInvoice() as Invoice;

      if ((!invoice?.Patient || !invoice?.TreatingProvider) && this.type === 'invoice') {
        this.dialogService.showDialog({
          title: 'Incomplete form',
          message: 'Please specify a patient and a provider before you can add invoice lines.',
          type: DialogType.WARNING,
        });

        return;
      }
    }
    await this.onTariffCodeSearchClosed();
    this.clearNappiCodeSearch();
    this.createLineGroup(index, line);
    this.updateInvoice();
    setTimeout(() => {
      if (useFocus)
        this.focusInputs.last.nativeElement.focus();
      // else
      //   this.diagnosisInput.nativeElement.focus();
    });
  }

  private createLineGroup(index: number = -1, line: InvoiceLine, skipUpdate = false) {
    const lineGroup = this.setupRowForm();
    if (line) {
      lineGroup.patchValue({
        LineType: line.LineType,
        TariffCode: line.TariffCode || null,
        NappiCode: line.NappiCode || null,
        DiagnosisCodes: line?.DiagnosisCodes?.map(c => ({ code: c })) || [],
        Description: line.Description || null,
        IsPPELine: line.IsPPELine || false,
        AmountBilled: line.AmountBilled || 0,
      }, { emitEvent: !skipUpdate });

      // Set custom pack price if custom line
      let customUnitPrice = 0;

      const isCustom = line.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE ||
        line.LineType === INVOICE_LINE_TYPE.MEDICINE ||
        line.LineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE ||
        line.LineType === INVOICE_LINE_TYPE.ADMIN

      if (isCustom) {
        customUnitPrice = line.AmountBilled / ((!line.Quantity || line.Quantity === 0) ? 1 : line.Quantity);
      }
      lineGroup.patchValue({
        Quantity: line.Quantity || 0,
        CustomUnitPrice: customUnitPrice
      }, { emitEvent: !skipUpdate }); // emitting quantity change event forces price recalculation
    }

    const row = {
      form: lineGroup
    };

    const data = this.invoiceLinesSource.data;
    // find row index by tarif code and nappi code
    const indexByCode = data.findIndex((x: any) => x.form.get('TariffCode')?.value === lineGroup.get('TariffCode')?.value && x.form.get('NappiCode')?.value === lineGroup.get('NappiCode')?.value);
    if (indexByCode > -1) {
      data.splice(index, 0, row)
    } else {
      data.push(row);
    }

    this.invoiceLinesSource = new MatTableDataSource(data);
  }

  private setupRowForm() {
    const diagnosisCodes = this.diagnosisSearchFormGroup.get('applyToAllLines')?.value ? [...this.headerDiagnosesForm.getRawValue()] : [];

    const lineGroup = this.builder.group({
      LineType: new UntypedFormControl('', RxwebValidators.notEmpty()),
      TariffCode: new UntypedFormControl({ value: '', disabled: true }, [this.INVALID_TARIFF_CODE_VALIDATOR, RxwebValidators.notEmpty()]),
      NappiCode: new UntypedFormControl({ value: '', disabled: true }, [this.INVALID_NAPPI_CODE_VALIDATOR, RxwebValidators.notEmpty()]),
      DiagnosisCodes: new UntypedFormControl({ value: diagnosisCodes, disabled: true }),
      Description: new UntypedFormControl({ value: '', disabled: true }, RxwebValidators.notEmpty()),
      Quantity: new UntypedFormControl(0, { updateOn: 'blur' }),
      CustomUnitPrice: new UntypedFormControl(0),
      AmountBilled: new UntypedFormControl(0),
      MedicineType: new UntypedFormControl('Acute'),
      DosageUnit: new UntypedFormControl(''),
      DosageType: new UntypedFormControl(''),
      FrequencyUnit: new UntypedFormControl(''),
      PeriodUnit: new UntypedFormControl(''),
      PeriodType: new UntypedFormControl(''),
      DurationUnit: new UntypedFormControl(''),
      DurationType: new UntypedFormControl(''),
      Repeats: new UntypedFormControl(''),
      PackSize: new UntypedFormControl(''),
      IsPPELine: new UntypedFormControl(false),
      PriceBusy: new UntypedFormControl(false),
      PriceError: new UntypedFormControl(''),
      LineCodeSelected: new UntypedFormControl(null),
      ModifierParameter: new UntypedFormControl([]),
      ChargeUnit: new UntypedFormControl('Units'),
      Units: new UntypedFormControl(''),
      ChargeStart: new UntypedFormControl(''),
      ChargeEnd: new UntypedFormControl(''),
      RcfValue: new UntypedFormControl(0),
      UneditedDescription:new UntypedFormControl(''),
      ModifierLinkedLineIds: new UntypedFormControl([]),
      IsModifierLinked: new UntypedFormControl(),
    });

    lineGroup.get('LineCodeSelected')?.valueChanges.subscribe(() => {
      lineGroup.get('NappiCode')?.updateValueAndValidity({ emitEvent: false });
      lineGroup.get('TariffCode')?.updateValueAndValidity({ emitEvent: false });
    });

    // Set defaults for line types
    lineGroup.get('LineType')?.valueChanges.pipe(
      distinctUntilChanged(isEqual),
      tap(val => {
        const row = {
          NappiCode: '',
          TariffCode: '',
          Quantity: 0,
          AmountBilled: 0,
          Description: ''
        };

        if (val) {
          lineGroup.get('Description').enable({ emitEvent: false });
          lineGroup.get('TariffCode')?.enable({ emitEvent: false });
        } else {
          lineGroup.get('Description').disable({ emitEvent: false });
          lineGroup.get('NappiCode')?.disable({ emitEvent: false });
          lineGroup.get('TariffCode')?.disable({ emitEvent: false });
        }

        switch (val) {
          case INVOICE_LINE_TYPE.CONSUMABLE:
          case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
            {
              const defaultConsumableCode = Object.keys(this.consumableTariffCodes$.value)
                .map(key => ({ Code: key, ...this.consumableTariffCodes$.value[key] }))
                .find(code => code.IsDefault);

              lineGroup.get('NappiCode')?.enable({ emitEvent: false });

              if (defaultConsumableCode?.Code) {
                row.TariffCode = defaultConsumableCode.Code;
              }

            }
            break;
          case INVOICE_LINE_TYPE.MODIFIER:
            {
              lineGroup.get('NappiCode')?.disable({ emitEvent: false });
              break;
            }
          case INVOICE_LINE_TYPE.MEDICINE:
          case INVOICE_LINE_TYPE.CSTM_MEDICINE:
            {
              const defaultMedicineCode = Object.keys(this.medicineTariffCodes$.value)
                .map(key => ({ Code: key, ...this.medicineTariffCodes$.value[key] }))
                .find(code => code.IsDefault);

              lineGroup.get('NappiCode')?.enable({ emitEvent: false });

              if (defaultMedicineCode?.Code) {
                row.TariffCode = defaultMedicineCode.Code;
              }
            }
            break;
          case INVOICE_LINE_TYPE.PROCEDURE:
          case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
          case INVOICE_LINE_TYPE.ADMIN:
            {
              lineGroup.get('NappiCode')?.disable({ emitEvent: false });
            }
            break;
        }

        lineGroup.patchValue({
          ...row
        }, { emitEvent: false })
      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('NappiCode')?.valueChanges.pipe(
      filter(val => !!val),
      distinctUntilChanged(),
      tap((val) => {
        const lineType = lineGroup.get('LineType')?.value;

        if (!lineGroup.pristine) {
          lineGroup.patchValue({ LineCodeSelected: false });
        }

        switch (lineType) {
          case INVOICE_LINE_TYPE.CONSUMABLE:
          case INVOICE_LINE_TYPE.MEDICINE:
          case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
          case INVOICE_LINE_TYPE.CSTM_MEDICINE:
            this.nappiCodeSearchRequest$.next({ query: val, lineType });
            break;
        }

      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('TariffCode')?.valueChanges.pipe(
      filter(val => !!val),
      distinctUntilChanged(),
      tap(val => {
        const lineType = lineGroup.get('LineType')?.value;

        switch (lineType) {
          case INVOICE_LINE_TYPE.PROCEDURE:
          case INVOICE_LINE_TYPE.MODIFIER:
          case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
          case INVOICE_LINE_TYPE.ADMIN:
            /*
              This check is done in here to prevent the tariff dropdown on med/con lines
              from marking the nappicode as invalid.
            */
            if (!lineGroup.pristine) {
              lineGroup.patchValue({ LineCodeSelected: false });
            }
            this.tariffCodeSearchRequest$.next({ query: val, lineType });
            break;
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('Quantity')?.valueChanges.pipe(
      debounceTime(200),
      tap(async (val) => {
        if (val < 1) {
          lineGroup.patchValue({ Quantity: 1 });
        }
        const lineType = lineGroup.get('LineType')?.value as INVOICE_LINE_TYPE;
        if(lineType != INVOICE_LINE_TYPE.MODIFIER){
          await this.priceLine(lineGroup);
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('AmountBilled')?.valueChanges.pipe(
      tap(() => this.calculateTotal()),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.valueChanges.pipe(
      distinctUntilChanged(isEqual),
      tap(() => this.updateInvoice()),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('LineType').setValue(INVOICE_LINE_TYPE.PROCEDURE);

    return lineGroup;
  }

  addHeaderDiagnoses(diagnoses: { code: string, description: string }[]) {
    const diagnosesToAdd = diagnoses.filter(diag => !this.headerDiagnosesForm.value.map(hd => hd.code).includes(diag.code));
    this.headerDiagnosesForm.setValue([...this.headerDiagnosesForm.value, ...diagnosesToAdd]);
  }

  clearDiagnoses() {
    this.headerDiagnosesForm.setValue([]);
  }

  clearDiagnosisSearch() {
    this.diagnosisSearchFormGroup.patchValue({
      headerDiagnosis: ''
    });

    // Have to clear the value on the input manually since patchvalue doesnt work with chips in the input.
    this.headerDiagSearchInput.nativeElement.value = '';
  };

  onHeaderDiagnosisSelected(e: any) {
    if (e.option.value && !this.headerDiagnosesForm.value.find(d => d.code === e.option.value.code)) {
      const existingValues = this.headerDiagnosesForm.value;
      existingValues.push(e.option.value);
      this.headerDiagnosesForm.setValue(existingValues);
      this.clearDiagnosisSearch();
    }
  }

  onHeaderDiagnosisRemoved(e: any) {
    const diagnosesValue = this.headerDiagnosesForm.value;
    const idx = diagnosesValue.findIndex(d => d.code === e.code);
    diagnosesValue.splice(idx, 1);
    if (idx >= 0) {
      this.headerDiagnosesForm.setValue(diagnosesValue);
    }
  }

  onHeaderDiagnosisSearchClosed() {
    this.diagnosisSearchResult$.next([]);
    this.diagnosisSearchBusy$.next(false);
  }

  applyHeaderDiagnoses() {
    this.invoiceLinesSource.data.forEach((d: any) => {
      d.form.patchValue({ DiagnosisCodes: [...this.headerDiagnosesForm.value] });
    });
  }

  getHeaderDiagnoses() {
    return this.headerDiagnosesForm.value;
  }

  async openDiagnosisSearch(row: any) {
    let selectedDiagnoses;
    let isHeader = false;
    if (row) {
      selectedDiagnoses = row.form.get('DiagnosisCodes').value || [];
    } else {
      selectedDiagnoses = this.headerDiagnosesForm.value;
      isHeader = true;
    }


    this.matDialog.open(SearchDialogComponent, DialogUtils.codeSearchDialogParams({
      searchType: SEARCH_TYPE.DIAGNOSIS,
      allowMultiSelect: true,
      isHeaderSelect: isHeader,
      preselectedData: selectedDiagnoses,
      patient: this.accountRepository.getAccountMember(this.invoiceRepository.getActiveInvoice()?.Patient),
      selectedProviderId: this.invoiceRepository.getActiveInvoice()?.TreatingProvider?.Id
    },)).afterClosed().pipe(
      filter(res => !!res),
      tap(res => {
        if (row) {
          row.form.get('DiagnosisCodes').setValue(res.map((r: any) => ({ code: r.code, description: r.description })));
        } else {
          this.headerDiagnosesForm.setValue(res.map((r: any) => ({ code: r.code, description: r.description })));
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  async onNappiCodeSelected(selected: any, rowForm: FormGroup) {
    if (!rowForm) return;

    const lineType = rowForm.get('LineType').value;
    if (selected?.option?.value) {
      const price = 0;
      let packSize = 0;
      const quantity = selected.option.value.Quantity;

      const isCustom = lineType === INVOICE_LINE_TYPE.CSTM_MEDICINE || lineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE

      if (!isCustom) {
        const packCode = selected.option.value.Code.slice(-3);
        const packInfo = selected.option.value?.Data?.PackInfo?.find(pi => pi.NAPPIPack === packCode);

        if (packInfo?.PackSize === 1 && packInfo.Volume > 0) {
          packSize = packInfo?.StdDispensingVolumePacksize || 0;
        } else {
          packSize = packInfo?.PackSize || 0;
        }
      }

      rowForm.patchValue({
        NappiCode: selected.option.value.Code,
        Description: selected.option.value.Description,
        Quantity: quantity,
        AmountBilled: price,
        PackSize: packSize,
        LineCodeSelected: true,
      });
    }

    rowForm.updateValueAndValidity();

    this.nappiCodeSearchResult$.next([]);
    this.nappiCodeSearchRequest$.next(null);

    await this.priceLine(rowForm);
    await this.removeDuplicateNappiCodeLines(selected);
  }

  private async removeDuplicateNappiCodeLines(res: any) {
    const lines = this.getLines();
    const nappiLines = lines.filter(l => l.get('NappiCode').value === res.option.value.Code);

    if (nappiLines.length > 1) {
      // Combine quantities for same nappi code
      const totalQuantity = nappiLines.reduce((acc, line) => acc + line.get('Quantity').value, 0);
      const firstLine = nappiLines[0] as FormGroup;
      firstLine.get('Quantity').setValue(totalQuantity);
      // Update price with new quantity
      await this.priceLine(firstLine);
      // Remove other nappi lines
      nappiLines.slice(1).forEach(line => this.removeLine(lines.indexOf(line)));
    }
  }

  async onNappiCodeSearchClosed(rowForm: UntypedFormGroup) {
    // This method validates the Nappi Code, if a user goes an changes it, it revalidate it when the even closed
    const nappiCode = rowForm.get('NappiCode').value;
    const lineType = INVOICE_LINE_TYPE.MEDICINE;
    const searchVal = nappiCode; //.toLowerCase().trim(); The trim messes up the search again

    const res = await this.searchService.searchAutocomplete(searchVal, lineType);

    for (const selection of res) {
      if (selection.Code == searchVal) {
        const descriptionValue = rowForm.get('Description');
        descriptionValue.setValue(selection?.Description);
      }
    }
  }

  private clearNappiCodeSearch() {
    // clear the results, else it will loop
    this.activateRow(null);
    this.nappiCodeSearchBusy$.next(false);
    this.nappiCodeSearchResult$.next([]);
    this.nappiCodeSearchRequest$.next(null);
  }

  async onTariffCodeSearchClosed() {
    this.activateRow(null);
    this.tariffCodeSearchResult$.next([]);
    this.tariffCodeSearchBusy$.next(false);
    this.tariffCodeSearchRequest$.next(null);
  }

  async onTariffCodeSelected(selected: any, rowForm: UntypedFormGroup) {
    if (!rowForm) return;

    this.tariffCodeSearchResult$.next([]);
    this.tariffCodeSearchRequest$.next(null);

    rowForm.patchValue({
      TariffCode: selected.option.value.Code,
      Description: selected.option.value.Description,
      Quantity: selected.option.value.Quantity || 1,
      IsPPELine: selected.option.value.IsPPELine || false,
      AmountBilled: 0,
      LineCodeSelected: true,
    });

    rowForm.updateValueAndValidity();

    await this.priceLine(rowForm);
  }

  async removeLine(index: number) {
    const data: any[] = this.invoiceLinesSource.data;

    let updatedData = data;

    // Check for modifiers below this line
    const hasModifier = index < data.length - 1 &&
      data[index].form.get('LineType').value === INVOICE_LINE_TYPE.PROCEDURE &&
      data[index + 1].form.get('LineType').value === INVOICE_LINE_TYPE.MODIFIER;

    if (hasModifier) {
      updatedData = await this.dialogService.showYesNoDialog('All the modifier lines linked to this procedure line will also be deleted. Do you want to proceed?', 'Warning').closed.pipe(
        map(res => {
          if (res === YES_BUTTON_ID) {
            const newData = []

            let atDeletedProcedure = false;
            for (let i = 0; i < data.length; i++) {
              if (i === index) {
                atDeletedProcedure = true;
                continue;
              }

              const lineType = data[i].form.get('LineType')?.value;
              if (atDeletedProcedure && lineType === INVOICE_LINE_TYPE.MODIFIER) continue;

              newData.push(data[i]);
            }
            return newData;
          } else {
            return data;
          }
        })
      ).toPromise();
    } else {
      updatedData.splice(index, 1);
    }

    this.updateLines(updatedData);
  }

  private removeEmptyLines() {
    const updatedData = this.invoiceLinesSource.data.filter((line: any) =>
      line?.form?.get('LineType')?.value)
    this.updateLines(updatedData);
  }

  public removeAllLines() {
    this.updateLines([]);
  }

  private updateLines(lines: any[]) {
    this.invoiceLinesSource = new MatTableDataSource(lines);
    this.calculateTotal();
    this.updateInvoice();
  }

  async priceLine(rowGroup: FormGroup) {
    const invoice = this.invoiceRepository.getActiveInvoice();
    if (INVOICE_SUBTYPE.NO_CHARGE === invoice?.Subtype) {
      this.resetPrices();
      return;
    }
    const isBusy = !!rowGroup.get('PriceBusy')?.value;

    if (isBusy) return;

    rowGroup.patchValue({
      PriceError: '',
      PriceBusy: true
    });

    const lineType = rowGroup.get('LineType')?.value as INVOICE_LINE_TYPE;

    const optionCode =
      this.account?.AccountType === ACCOUNT_TYPE.MEDICAL_AID && this.account?.Option ? this.account?.Option : '90H'

    const placeOfServiceCode = this.getPlaceOfServiceCode(invoice?.PlaceOfService || 'OH');

    let price = 0;
    let error = '';
    try {
      const nappiCode = rowGroup.get('NappiCode')?.value;
      const tariffCode = rowGroup.get('TariffCode')?.value;
      const qtyVal = rowGroup.get('Quantity')?.value;
      const qty = qtyVal === null || qtyVal === undefined || qtyVal < 0 ? 0 : qtyVal;

      if (this.type === 'invoice') {
        switch (lineType) {
          case INVOICE_LINE_TYPE.PROCEDURE:
            {
              const isModifierLiked = rowGroup.get('IsModifierLinked')?.value as boolean;
              if(isModifierLiked){
                rowGroup.patchValue({PriceBusy: false});
                return;
              }

              const disciplineCode = this.getProviderDisciplineCode(invoice);
              const gpaTariffCode = this.getProcedureCodeForGPA(tariffCode, disciplineCode, invoice);
              const unitPrice = await this.getPrivateRatePrice(gpaTariffCode, lineType);
              if (unitPrice) {
                price = MoneyUtils.fromCents(unitPrice * qty);
              } else {
                const reqObject = await this.getPricingRequestObject(
                  tariffCode,
                  invoice,
                  placeOfServiceCode
                )
                const res = await this.pricingService.getTariffPrice(reqObject);

                if ((res as any).data?.payload?.hasResult) {
                  price = ((res as any).data.payload.result.price * qty);
                } else {
                  error = (res as any).error?.message || 'Unknown error';
                }
              }

              rowGroup.patchValue({ 'Quantity': qty }, { emitEvent: false });
            }
            break;
          case INVOICE_LINE_TYPE.MEDICINE:
          case INVOICE_LINE_TYPE.CONSUMABLE:
            {
              if (this.checkContractZeroPricedLine()) {
                price = 0;
              } else {
                const unitPrice = await this.getPrivateRatePrice(nappiCode, lineType);
                if (unitPrice) {
                  let originalPacksize = rowGroup.get('PackSize')?.value;
                  if (!originalPacksize) {
                    // const res = await this.searchService.getMedicine(nappiCode);
                    originalPacksize = qty;
                    rowGroup.get('PackSize')?.patchValue(originalPacksize);
                  }
                  price = MoneyUtils.fromCents((unitPrice / originalPacksize) * qty);
                } else {
                  const res = lineType === INVOICE_LINE_TYPE.MEDICINE
                    ? await this.pricingService.getMedicinePrice(nappiCode, optionCode, qty, moment(invoice.DateOfService).year())
                    : await this.pricingService.getConsumablePrice(nappiCode, optionCode, qty, moment(invoice.DateOfService).year());
                  if ((res as any)?.data?.payload) {
                    price = (res as any).data.payload?.price || 0;
                  } else {
                    error = (res as any)?.error?.message || 'Unknown error';
                  }
                }
              }
            }
            break;
          case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
          case INVOICE_LINE_TYPE.ADMIN:
          case INVOICE_LINE_TYPE.CSTM_MEDICINE:
          case INVOICE_LINE_TYPE.CSTM_CONSUMABLE: {
            const codeType = this.getCustomType(lineType);

            let lineCode = '';
            if (lineType === INVOICE_LINE_TYPE.ADMIN || lineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE) {
              lineCode = tariffCode;
            } else if (lineType === INVOICE_LINE_TYPE.CSTM_MEDICINE || lineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE) {
              lineCode = nappiCode;
            }

            if (!lineCode) {
              throw Error('No code on line');
            }

            const ccode = this.allCustomChargeCodes$.getValue().filter(c => (c.Type === codeType || c.Type === CustomChargeCodeType.ALL) && c.Code === lineCode);

            if (ccode?.length > 0) {
              price = MoneyUtils.fromCents(ccode[0].UnitPrice * rowGroup.get('Quantity')?.value);
            } else {
              throw Error(`No custom charge found for code '${lineCode}'`);
            }

          }
            break;
          case INVOICE_LINE_TYPE.MODIFIER:
            {
              rowGroup.patchValue({PriceError: '', PriceBusy: false});

              const providerSpeciality = PROVIDER_SPECIALITY[invoice?.TreatingProvider?.Speciality];
              const modifierCodesArray = Object.values(ModifierCodes);
              if (!providerSpeciality || !modifierCodesArray.includes(tariffCode)) {
                rowGroup.patchValue({ AmountBilled: price, PriceBusy: false, PriceError: error });
                return;
              }

              if (this.isModifierCode === true) {
                this.isModifierCode = false;
                return;
              } else {
                this.isModifierCode = true;
              }

              const unitPrice = await this.getPrivateRatePrice(tariffCode, lineType);
              if (unitPrice) {
                price = MoneyUtils.fromCents(unitPrice * qty);
              } else {
                const reqObject = await this.getPricingRequestObject(
                  tariffCode,
                  invoice,
                  placeOfServiceCode,
                  INVOICE_LINE_TYPE.MODIFIER
                );

                this.openModifierWindow(reqObject);
              }

              return;
            }
            break;
          default:
            {
              //default calculation
              price = rowGroup.get('AmountBilled')?.value * rowGroup.get('Quantity')?.value;
            }
            break;
        }
      } else if (this.type === 'BCLines') {
        const bcLine = this.mapInvoiceLineToBCLine(rowGroup.getRawValue())
        const res = await this.benefitCheckService.priceBenefitCheckLine(this.account, this.provider, bcLine);
        if (res) {
          price = bcLine.Amount > 0 ? bcLine.Amount : res;
        } else {
          error = 'Could not get price. An unknown error occured.'
        }
      }

      rowGroup.patchValue({
        AmountBilled: price,
        PriceBusy: false,
        PriceError: error
      });
    } catch (err) {
      this.logger.error(`Failed to get price. ${err}`);
      rowGroup.patchValue({
        AmountBilled: 0,
        PriceBusy: false,
        PriceError: `Could not get price. An unknown error occured.`
      });
    }
  }

  async refreshPrices() {
    this.googleAnalyticsService.logEvent('CAPTUREINVOICE_REFRESHPRICE', { PracticeId: this.authService.selectedBPN });
    const modfierFormGroupArray:FormGroup[] =[];
    await Promise.all(this.invoiceLinesSource.data.map(async (row: any) => {
      const form = (<any>row).form as FormGroup;
      const lineType = form.get('LineType').value;
      if(lineType==INVOICE_LINE_TYPE.MODIFIER){
        modfierFormGroupArray.push(form);
      }
      else{
        await this.priceLine(form);
      }
    }));

  }

  async getPrivateRatePrice(code: string, lineType: INVOICE_LINE_TYPE): Promise<number> {
    const privateRateType = PrivateRateUtils.mapInvoiceLineTypeToPrivateRateType(lineType);
    if (!code || !privateRateType) return null;
    const practice = this.authService.practiceBF$.value;
    const invoice: Invoice = this.invoiceRepository.getActiveInvoice();
    let type : "Procedure" | "Medicine" | "Consumable" = null ;
    if (INVOICE_LINE_TYPE.PROCEDURE === lineType) {
      type = 'Procedure';
    } else if (INVOICE_LINE_TYPE.MEDICINE === lineType) {
      type = 'Medicine';
    } else if (INVOICE_LINE_TYPE.CONSUMABLE === lineType) {
      type = 'Consumable';
    }
    const providerForPricing = await this.getLocumProviderForPricing(invoice);
    const req: PrivatePriceRequest = {
      practiceId: this.authService.selectedBPN,
      providerId: providerForPricing.Id,
      priceCode: code,
      type: type,
      date: invoice.DateOfService,
      isMedicalAid: invoice.Type === INVOICE_TYPE.MEDICAL_AID,
      branch: invoice.Branch,
      isMultiBranch: practice.IsMultiBranch,
      scheme: this.account.Scheme,
      option: this.account.Option
    };
    return await this.privateRatePricingService.getPrice(req);
  }

  validate() {
    // remove lines with no line types
    this.removeEmptyLines();

    this.invoiceLinesSource.data.forEach((line: any) => {
      line.form?.markAllAsTouched();
      line.form?.updateValueAndValidity();
    });

    const valid = !this.invoiceLinesSource.data.some((line: any) => line.form?.invalid);

    if (!valid) {
      this.dialogService.showWarning('Please complete the highlighted fields before proceeding.');
    }

    return valid;
  }

  activateRow(row: any) {
    this.invoiceLinesSource.data?.forEach(x => (x as any).active = false)
    if (row) row.active = true;
    // row.form.get('AmountBilled')?.patchValue(0);\\
  }

  calculateTotal() {
    let runningTotal = 0;
    this.invoiceLinesSource.data.forEach((line: any) => {
      const price = MoneyUtils.toCents(line.form.get('AmountBilled')?.value);
      runningTotal += price;
    });
    this.total$.next(runningTotal / 100);
  }

  getDiagnosisCodesDisplay(codes: any[]) {
    return codes.map(c => c.code).join(', ');
  }

  mapRowFormToLine(rowForm: UntypedFormGroup, index: number) {
    const billedInCents = MoneyUtils.toCents(rowForm.get('AmountBilled').value);

    const balance = {
      Outstanding: billedInCents,
      MedicalAidLiable: 0,
      PatientLiable: 0
    } as Balance;

    const invoiceLine = {
      ...rowForm.getRawValue(),
      AmountBilled: billedInCents,
      Balance: balance,
      LineNumber: index + 1,
      DiagnosisCodes: (<any[]>rowForm.get('DiagnosisCodes')?.value).map(d => d.code),
      ModifierParameter: rowForm.get('ModifierParameter').value,
      ChargeUnit:rowForm.get('ChargeUnit').value,
      Units:rowForm.get('Units').value,
      ChargeStart:rowForm.get('ChargeStart').value,
      ChargeEnd:rowForm.get('ChargeEnd').value,
      RcfValue:rowForm.get('RcfValue').value,
      UneditedDescription:rowForm.get('UneditedDescription').value,
      ModifierLinkedLineIds:rowForm.get('ModifierLinkedLineIds').value,
      IsModifierLinked: rowForm.get('IsModifierLinked').value
    } as InvoiceLine;

    delete invoiceLine["PriceError"];
    delete invoiceLine["PriceBusy"];

    return invoiceLine;
  }

  updateInvoice() {
    if (!this.initialValueApplied) return;
    const updObj: any = {};
    updObj.HeaderDiagnosisCodes = this.headerDiagnosesForm.value.map(d => d.code);
    updObj.Lines = this.invoiceLinesSource?.data
      // .filter((r: any) => r.form.value.TariffCode)
      .map((l: any, idx: number) => this.mapRowFormToLine(l.form, idx));
    this.invoiceRepository.updateActiveInvoice(updObj);
  }

  reset() {
    this.headerDiagnosesForm.setValue([]);
    this.diagnosisSearchFormGroup.patchValue({
      applyToAllLines: true
    });
    this.invoiceLinesSource.data = [];
    this.invoiceRepository.updateActiveInvoice({
      HeaderDiagnosisCodes: [],
      Lines: []
    } as any);
    this.total$.next(0);
  }

  resetPrices() {
    this.invoiceLinesSource.data.forEach((line: any) => {
      line.form.patchValue({ AmountBilled: 0 });
    });
  }

  onInfoClick(rowForm: UntypedFormGroup) {
    const nappiCode = rowForm.get('NappiCode');
    if (nappiCode?.errors !== null ? !nappiCode?.errors.incorrect : nappiCode.value) {
      const dialogRef = this.matDialog.open(AdditionalMedicineDetailsModalComponent, {
        height: 'auto',
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'base-dialog',
        data: rowForm.getRawValue(),
      });

      dialogRef.afterClosed().pipe(
        tap(result => {
          rowForm.patchValue(result.data);
        }),
        untilDestroyed(this)
      ).subscribe();
    }
  }

  getPlaceOfServiceCode(placeOfService: PLACE_OF_SERVICE) {
    switch (placeOfService) {
      case PLACE_OF_SERVICE.INPATIENT_HOSPITAL:
        return 'IH';
      case PLACE_OF_SERVICE.DAY_CLINIC_HOSPITAL:
        return 'DC';
      default:
        return 'OH';
    }
  }

  getProviderMedicalType(): PROVIDER_MEDICAL_TYPE_CODE {
    const invoice: Invoice = this.invoiceRepository.getActiveInvoice();
    const speciality = invoice?.TreatingProvider?.Speciality;
    let typeCode;
    if (speciality) {
      typeCode = SPECIALITY_MEDICAL_TYPE[speciality];
    }
    return typeCode || 'MP';
  }

  getProviderSpecialityCode() {
    const invoice: Invoice = this.invoiceRepository.getActiveInvoice();
    const speciality = invoice?.TreatingProvider?.Speciality;
    let typeCode: string;
    if (speciality) {
      typeCode = "" + PROVIDER_SPECIALITY[speciality];
    }
    typeCode = typeCode.padStart(3, "0");
    return typeCode;
  }

  checkContractZeroPricedLine(cons: boolean = false) {
    const value = !!this.activeContractSubscriptions$.getValue().find(sub =>
      this.optionCodeContracts$.getValue().includes(sub.Code) &&
      (cons ? sub.BillConsAtZero : sub.BillMedsAtZero)
    );
    return value;
  }

  // check if line with the same tariff code and nappi code already exists in the invoiceLinesSource
  hasLine(item: InvoiceLine) {
    const invoiceLinesSource = this.invoiceLinesSource.data;
    const found = invoiceLinesSource.find((x: any) => {
      const lineForm = x.form as FormControl;
      const lineType = lineForm.get('LineType').value;
      if (lineType === INVOICE_LINE_TYPE.PROCEDURE || lineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE) {
        return lineForm.get('TariffCode').value === item.TariffCode;
      }
      return lineForm.get('TariffCode').value === item.TariffCode && lineForm.get('NappiCode').value === item.NappiCode;
    });
    return found;
  }

  getLineCount() {
    return this.invoiceLinesSource?.data.length
  }

  getLines(): Array<FormGroup> {
    const lines = this.invoiceLinesSource.data.map(line => (line as any).form as FormGroup);
    return lines;
  }

  async tabFocusChange() {
    await this.addLine(this.invoiceLinesSource?.data?.length, null, null, true);
  }

  private formatHighlightedValue(value: string) {
    return value.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>');
  }

  private getCustomType(lineType: INVOICE_LINE_TYPE) {
    switch (lineType) {
      case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
        return CustomChargeCodeType.CSTM_PROCEDURE;
      case INVOICE_LINE_TYPE.ADMIN:
        return CustomChargeCodeType.ADMIN;
      case INVOICE_LINE_TYPE.CSTM_MEDICINE:
        return CustomChargeCodeType.CSTM_MEDICINE;
      case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
        return CustomChargeCodeType.CSTM_CONSUMABLE;
    }
    return null;
  }

  mapBCLinesToInvoiceLines(BCLines: BenefitCheckLine[]) {
    const invoiceLines: InvoiceLine[] = [];
    BCLines?.forEach(BCLine => {
      invoiceLines.push({
        Description: BCLine.Description || null,
        LineNumber: BCLine.LineNum || null,
        TariffCode: BCLine.ChargeCode || null,
        NappiCode: BCLine.NappiCode || null,
        DiagnosisCodes: [BCLine.DiagnosisCode] || null,
        AmountBilled: BCLine.Amount || null,
        LineType: BCLine.LineType || null,
        Quantity: BCLine.Quantity || null,
      });
    });

    return invoiceLines;
  }

  mapInvoiceLineToBCLine(invoiceLine: InvoiceLine) {
    return {
      Description: invoiceLine.Description || null,
      LineNum: invoiceLine.LineNumber || null,
      ChargeCode: invoiceLine.TariffCode || null,
      DiagnosisCode: this.headerDiagnosesForm.value[0]?.code || null,
      Amount: invoiceLine.AmountBilled || null,
      LineType: invoiceLine.LineType || null,
      NappiCode: invoiceLine.NappiCode || null,
      Quantity: invoiceLine.Quantity || null,
    } as BenefitCheckLine;
  }

  openModifierWindow = async (reqObject: any) => {
      this.setLineCodeSelected();

      const dialogRef = this.matDialog.open(ModifierComponent, {
        width: '400px',
        maxHeight: 'calc(100% - 1.2em)',
        height: 'auto',
        data: [reqObject]
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.isModifierCode = false;
        if (result?.keepLine) {
          this.setLineCodeSelected();
          return false;
        }
        if (result == null) {
          const updatedData = this.invoiceLinesSource.data.filter(
            (line: any) =>
              line?.form?.get('LineCodeSelected')?.value === null ||
              (line?.form?.get('LineCodeSelected')?.value === true &&
                line?.form?.get('LineType')?.value !== INVOICE_LINE_TYPE.MODIFIER)
          );
          this.updateLines(updatedData);
          return false;
        }

        let lineNumber = 1;
        this.invoiceLinesSource.data.forEach((line: any) => {
          const invoiceLine = result.find(invLine => invLine.LineNumber == lineNumber);
          line?.form?.get('LineType')?.setValue(invoiceLine?.LineType);
          line?.form?.get('TariffCode')?.setValue(invoiceLine?.TariffCode);
          if(invoiceLine?.LineType == INVOICE_LINE_TYPE.MEDICINE || invoiceLine?.LineType == INVOICE_LINE_TYPE.CONSUMABLE){
            line?.form?.get('NappiCode')?.setValue(invoiceLine?.NappiCode);
          }
          line?.form?.get('AmountBilled')?.setValue(invoiceLine?.AmountBilled);
          line?.form?.get('Quantity')?.setValue(invoiceLine?.Quantity);
          line?.form?.get('Description')?.setValue(invoiceLine?.Description);
          line?.form?.get('ModifierParameter')?.setValue(invoiceLine?.ModifierParameter);
          line?.form?.get('LineCodeSelected')?.setValue(null);
          line?.form?.get('ChargeUnit')?.setValue(invoiceLine?.ChargeUnit);
          line?.form?.get('Units')?.setValue(invoiceLine?.Units);
          line?.form?.get('ChargeStart')?.setValue(invoiceLine?.ChargeStart);
          line?.form?.get('ChargeEnd')?.setValue(invoiceLine?.ChargeEnd);
          line?.form?.get('RcfValue')?.setValue(invoiceLine?.RcfValue);
          line?.form?.get('UneditedDescription')?.setValue(invoiceLine?.UneditedDescription);
          line?.form?.get('ModifierLinkedLineIds')?.setValue(invoiceLine?.ModifierLinkedLineIds);
          line?.form?.get('IsModifierLinked')?.setValue(invoiceLine?.IsModifierLinked);
          lineNumber++;
        });
        this.onTariffCodeSearchClosed();
        const updatedData = this.invoiceLinesSource.data.filter(
          (line: any) => line?.form?.get('TariffCode')?.value != null
        );
        this.updateLines(updatedData);
        return false;
      });
  }

  async getPricingRequestObject(tariffCode:string, invoice: Invoice, placeOfServiceCode:string,lineType?:string,
    recalculateModifiers?:boolean,editModifier?:boolean) {
      invoice.TreatingProvider = await this.getLocumProviderForPricing(invoice);
    const reqObject : ModifierModelData = {
      year: moment(invoice.DateOfService).year().toString(),
      tariffCode,
      disciplineCode: this.getProviderDisciplineCode(invoice),
      placeOfService: placeOfServiceCode,
      planOptionCode: this.account.AccountType === ACCOUNT_TYPE.MEDICAL_AID ? this.account.Option : '90H',
      practiceId: this.authService.selectedBPN,
      providerId: invoice.TreatingProvider?.Id,
      invoiceLineType : lineType,
      invoice,
      invoiceLineData:this.invoiceLinesSource.data,
      editModifier,
      recalculateModifiers,
      shceme: this.account?.Scheme
    }
    return reqObject;
  }

  setLineCodeSelected(){
    this.invoiceLinesSource.data.forEach((line: any) => {
      if(!line.active){
        line?.form?.get('LineCodeSelected')?.setValue(null);
      }
    });
  }

  getProviderDisciplineCode(invoice: Invoice){
    const speciality = invoice?.TreatingProvider?.Speciality;
    let providerDisciplineCode = '014';
    if (speciality) {
      providerDisciplineCode = "0" + PROVIDER_SPECIALITY[speciality];
    }
    return providerDisciplineCode;
  }

  async onInfoModifierClick(rowForm: UntypedFormGroup) {
    rowForm.patchValue({
      LineCodeSelected: true,
    });
    const tariffCode = rowForm.get('TariffCode')?.value;
    const invoice = this.invoiceRepository.getActiveInvoice();
    const placeOfServiceCode = this.getPlaceOfServiceCode(invoice?.PlaceOfService || 'OH');

    const reqObject = await this.getPricingRequestObject(
      tariffCode,
      invoice,
      placeOfServiceCode,
      INVOICE_LINE_TYPE.MODIFIER,
      false,
      true
    );

    this.openModifierWindow(reqObject);

  }

  async recalculateModifiers(){
    try{

      const invoice = this.invoiceRepository.getActiveInvoice();
      const placeOfServiceCode = this.getPlaceOfServiceCode(invoice?.PlaceOfService || 'OH');
      const reqObject = await this.getPricingRequestObject(
        '',
        invoice,
        placeOfServiceCode,
        INVOICE_LINE_TYPE.MODIFIER,
        true
      );

      this.openModifierWindow(reqObject);

    }catch (err) {
      this.logger.error(`Failed to recalculate modifiers. ${err}`);

    }
  }

  showEditModifierButton(row:any):boolean{
    if(row.form && (row.form.get('LineType').value === INVOICE_LINE_TYPE.MODIFIER))
    {
      if(row.form.get('ModifierParameter').value.length>0){
        return true;
      }
      else if(row.form.get('ModifierLinkedLineIds').value.length>0){
        return true;
      }
    }

    return false;
  }

  async getLocumProviderForPricing(invoice): Promise<PracticeProvider> {
    let provider = invoice.TreatingProvider;

    if(invoice.TreatingProvider.IsLocum) {
      const practiceProviders = this.practiceProviders$.value;

      if(invoice.Patient) {
        let patient = this.accountRepository.getAccountMember(invoice.Patient);
        if(!patient) {
          const patientDoc = await getDoc(doc(this.firestore, PathUtils.patientPath(this.authService.selectedBPN, invoice.Patient)));
          patient = patientDoc.data() as AccountMember;
        }

        if(patient.PrimaryProvider || patient.SecondaryProvider) {
          const locumTPN = invoice.TreatingProvider.TreatingPracticeNumber;
          const primaryProvider = practiceProviders.find(prov => prov.Id === patient.PrimaryProvider);
          const secondaryProvider = practiceProviders.find(prov => prov.Id === patient.SecondaryProvider);

          if (locumTPN === primaryProvider?.TreatingPracticeNumber) {
            provider = primaryProvider;
          } else if (locumTPN === secondaryProvider?.TreatingPracticeNumber) {
              provider = secondaryProvider;
          }
        }
      }
    }

    return provider
  }

  getProcedureCodeForGPA(procedureCode: string, disciplineCode: string, invoice: any): string {
    let gpaCode = procedureCode;
    //if discipline is GP and Anaesthetist enabled on provider, adding 'A' at the end
    if ((disciplineCode === "014" || disciplineCode === "015") && invoice.IsAnaesthetist) {
      gpaCode = gpaCode + 'A';
    }
    return gpaCode;
  }
}
