export enum CLAIM_STATUS {
  SAVED = "Saved",
  AWAITING_RESPONSE = "Awaiting Response",
  RECEIVED_BY_HEALTHBRIDGE = "Received by Healthbridge",
  VALIDATED_BY_HEALTHBRIDGE = "Validated by Healthbridge",
  RECEIVED_BY_MEDICALAID = "Received by Medical Aid",
  VALIDATED_BY_MEDICALAID = "Validated by Medical Aid",
  PROCESSED_BY_MEDICALAID = "Processed by Medical Aid",
  AWAITING_REVERSAL = "Awaiting Reversal",
  REVERSAL_ACCEPTED = "Reversal Accepted",
  REVERSAL_PROCESSED = "Reversal Processed",
  REVERSAL_REJECTED = "Reversal Rejected",
  REMITTANCE_ADVICE_RECEIVED = "Remittance Advice Received",
  PART_REJECTED = "Part Rejected",
  REJECTED = "Rejected",
  PART_PROCESSED_BY_MEDICALAID = "Part Processed by Medical Aid",
  RESUBMITTED_TO_MEDICALAID = "Resubmitted to Medical Aid",
  STALE = "Stale",
}
