import {BaseAccount} from "../account.models";
import {BaseInvoice} from "../invoice.models";
import {Practice} from "../practice.models";
import {ClaimDetailData, PDFCellData, ReportICDLine, ReportTable, TableBorders} from "./pdf.model";

export class ProofOfSubmissionReportRequest {
    Practice: Practice;
    Invoice: BaseInvoice;
    Account: BaseAccount;
    SendEmail?: boolean;
  }

export interface ProofOfSubmissionReportModel {
    PracticeId: string;
    PracticeName: string;
    LetterTable: ReportTable;
    LetterText: string[];
    ProofOfSubmissionTables: ProofOfSubmissionTable[];
    ProofOfSubmissionData: ClaimDetailData;
    ReportDate?: string;
}

export interface ProofOfSubmissionTable {
    ICDLine: ReportICDLine;
    rowHeaders?: PDFCellData[];
    borders?: TableBorders;
    rows?: PDFCellData[][];
}
