import { AbstractControl, ValidatorFn } from "@angular/forms";

const PHONE_REGEX = /^[+]{0,1}[0-9]{1,}([(]{0,1}[0-9]{1,4}[)]{0,1}){0,1}[0-9]{8,}(?!.*[ -]{2})[-\s\\.\\/0-9]*[0-9]$/g;

export const PhoneNumberValidator: ValidatorFn = (control: AbstractControl) => {
  const value = control.value as string;
  let invalid = false;

  if (value) {
    const validLength = !value.startsWith('+') ? value.length === 10 : true;
    const matches = value.match(PHONE_REGEX);
    invalid = !matches || matches?.length === 0 || !validLength;
  }
  return invalid ? { 'phone': 'Invalid phone number' } : null;

};
