import moment from "moment";
import {
  NoDataError, RaDetails, RaJournalAmounts, RaPaymentDateUpdates, RaPayments, RaReasonCodes,
  RaStatementProviderModel,
  RaStatementReportInfo,
  RaStatementReportModel,
  RaStatementReportRequest
} from "@meraki-flux/schema";

export class RaStatementReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: RaStatementReportRequest): Promise<RaStatementReportModel> {
    const reportHeader: RaStatementReportInfo = await this.buildReportInfo(reportRequest);
    const providersModel: RaStatementProviderModel = await this.buildProvidersModel(reportRequest);

    this.checkDataAvailability(providersModel);

    return {
      RaStatementReportInfo: reportHeader,
      RaStatementProviderModel: providersModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

  private checkDataAvailability(providersModel: RaStatementProviderModel) {
    let noData = true;
    if (providersModel.RaDetails) {
      noData = false;
    }

    if (noData) throw new NoDataError();
  }

  private async buildReportInfo(reportRequest: RaStatementReportRequest): Promise<RaStatementReportInfo> {
    const reportInfo: RaStatementReportInfo = {};
    reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;

    return reportInfo;
  }

  private async buildProvidersModel(reportRequest: RaStatementReportRequest): Promise<RaStatementProviderModel> {
    const raDetails: RaDetails = reportRequest.RaDetails;
    const raPayments: RaPayments[] = reportRequest.RaDetails.RaPayments;
    const raJournalAmounts: RaJournalAmounts[] = reportRequest.RaDetails.RaJournalAmounts;
    const raReasonCodes: RaReasonCodes[] = reportRequest.RaDetails.RaReasonCodes;
    const raPaymentDateUpdates: RaPaymentDateUpdates[] = reportRequest.RaDetails.RaPaymentDateUpdates;

    return {
      RaDetails: raDetails,
      RaPayments: raPayments,
      RaJournalAmounts: raJournalAmounts,
      RaReasonCodes: raReasonCodes,
      RaPaymentDateUpdates: raPaymentDateUpdates,
    };
  }

  // private buildRaDetails(ra: RemittanceAdvice): RaDetails {

  //   const raDetailsObj: RaDetails = {
  //     Scheme:ra.SchemeName,
  //     RANo: ra.Payment?.RANum,
  //     PaymentDate:  moment(this.convertTimestampToDateTime(ra.Payment?.PayDate)).format(this.DATE_FORMAT),
  //     EFTNo: ra.Payment?.EFTNum,
  //     RADate: moment(this.convertTimestampToDateTime(ra.Payment?.RADate)).format(this.DATE_FORMAT),
  //     ReconType: ra.Payment?.['@Type'],
  //     MedicalAidOrInsurer: ra.Insurer,
  //     TotalAmountPaid: ra.Payment?.PaidAmount,
  //     TotalValueCaptured: 0
  // };

  //   // raDetailsObj.Scheme = ra.SchemeName;
  //   // raDetailsObj.RANo = ra.Payment?.RANum;
  //   // raDetailsObj.PaymentDate = moment(this.convertTimestampToDateTime(ra.Payment?.PayDate)).format(this.DATE_FORMAT);
  //   // raDetailsObj.EFTNo = ra.Payment?.EFTNum;
  //   // raDetailsObj.RADate = moment(this.convertTimestampToDateTime(ra.Payment?.RADate)).format(this.DATE_FORMAT);
  //   // raDetailsObj.ReconType = ra.Payment?.['@Type'];
  //   // raDetailsObj.MedicalAidOrInsurer = ra.Insurer;
  //   // raDetailsObj.TotalAmountPaid = ra.Payment?.PaidAmount;
  //   // raDetailsObj.TotalValueCaptured = 0;

  //   return raDetailsObj;
  // }

  // private buildRaPayments(raClaims: RemittanceClaim[]): RaPayments[]
  // {
  //   const raPayments: RaPayments[] = [];

  //   if (raClaims) {
  //     for (const raClaim of raClaims) {
  //       for (const lineItem of raClaim.LineItem) {
  //         const obj: RaPayments = {
  //           Status: raClaim.Status,
  //           AccountNo: raClaim.AccountNo,
  //           Patient: raClaim.PatName + ' ' + raClaim.PatSurname,
  //           MemberNo: raClaim.MemNum,
  //           DateOfService: moment(this.convertTimestampToDateTime(lineItem.DOS)).format(
  //             this.DATE_FORMAT
  //           ),
  //           TariffCode: lineItem.ChargeCode,
  //           NappiCode: '',
  //           Claimed: raClaim.TotClaimAmount,
  //           Paid: raClaim.TotPaymentAmount,
  //           ReasonCode: this.getReasonCodes(lineItem.Reason),
  //         };

  //         raPayments.push(obj);
  //       }
  //     }
  //   }

  //   return raPayments;
  // }

  // private buildRaJournalAmounts(raJournal: RemittanceJournal[]): RaJournalAmounts[]
  // {
  //   const raJournalAmounts: RaJournalAmounts[] = [];

  //   if (raJournal) {
  //     for (const journal of raJournal) {
  //       const obj: RaJournalAmounts = {
  //         JournalDescription: journal.JournalDes,
  //         Amount: journal.JournalAmount,
  //       };
  //       raJournalAmounts.push(obj);
  //     }
  //   }
  //   return raJournalAmounts;
  // }

  // private buildRaReasonCodes(raClaims: RemittanceClaim[]): RaReasonCodes[]
  // {
  //   const raReasonCodes: RaReasonCodes[] = [];
  //   if (raClaims) {
  //     for (const raClaim of raClaims) {
  //       if (raClaim.LineItem) {
  //         for (const lineItem of raClaim.LineItem) {
  //           if (lineItem.Reason) {
  //             for (const reason of lineItem.Reason) {
  //               const obj: RaReasonCodes = {
  //                 ReasonCode: reason.ReasonCode,
  //                 Description: reason.ReasonDesc,
  //               };
  //               raReasonCodes.push(obj);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return raReasonCodes;
  // }

  // private convertTimestampToDateTime(val: any): Date {
  //   if (val) {
  //     const seconds = val.seconds;
  //     const nanoseconds = val.nanoseconds;
  //     const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  //     const date = new Date(milliseconds);
  //     return date;
  //   }
  //   return null;
  // }

  // private getReasonCodes(reason: Reason[]): string {
  //   if (reason) {
  //     let reasonCodes = '';
  //     for (const reas of reason) {
  //       reasonCodes = reasonCodes ? reasonCodes + ',' + reas.ReasonCode : reas.ReasonCode;
  //     }
  //   }
  //   return '';
  // }
}
