import { Injectable } from '@angular/core';
import { arrayUnion, Firestore } from '@angular/fire/firestore';
import {CALENDAR_EVENT_STATUS, CALENDAR_EVENT_TYPE, CalendarEvent, YES_BUTTON_ID} from '@meraki-flux/schema';
import { RRuleUtils } from '../../../utils/rrule-utils';
import { AuthService } from '../../auth.service';
import { DialogService } from '../../dialog.service';
import { FirestoreService } from '../../firestore.service';
import { CalendarEventQueryService } from '../query/calendar-event-query.service';
import { CalendarEventWhereBuilder } from '../query/calendar-event-where-builder';
import { AbstractEventAction } from './abstract-event.action';

export enum DELETE_RECURRENT_DOCTOR_LEAVE_OPTION {
  THIS = 'This unavailable booking',
  THIS_AND_FOLLOWING = 'This and following unavailable bookings',
  ALL = 'All unavailable bookings',
}

export enum DELETE_RECURRENT_VISIT_OPTION {
  THIS = 'This appointment',
  THIS_AND_FOLLOWING = 'This and following appointments',
}

@Injectable({
  providedIn: 'root',
})
export class DeleteEventAction extends AbstractEventAction {
  constructor(
    protected override authService: AuthService,
    protected override firestore: Firestore,
    protected override firestoreService: FirestoreService,
    protected dialogService: DialogService,
    protected calendarEventQueryService: CalendarEventQueryService
  ) {
    super(firestore, firestoreService, authService);
  }

  async delete(event: CalendarEvent) {
    switch (event.EventType) {
      case CALENDAR_EVENT_TYPE.PATIENT_VISIT:
        return await this.deleteVisit(event);
      case CALENDAR_EVENT_TYPE.DOCTOR_LEAVE:
        return await this.deleteDoctorLeave(event);
      default:
        throw Error(`Unexpected event type ${event.EventType}`);
    }
  }

  async deleteVisit(event: CalendarEvent) {
    if (CALENDAR_EVENT_TYPE.PATIENT_VISIT !== event.EventType) {
      throw new Error('Must be a visit event');
    }
    if (event?.VisitInfo?.Invoiced) {
      this.dialogService.showWarning('This appointment can’t be removed, because there is already an invoice linked to it.');
      return false;
    }
    if (event.RecurrentInfo?.RootEventId) {
      const selection = await this.dialogService.showRadioButtonDialog(
        'Delete recurring patient appointment',
        [DELETE_RECURRENT_VISIT_OPTION.THIS, DELETE_RECURRENT_VISIT_OPTION.THIS_AND_FOLLOWING],
        DELETE_RECURRENT_VISIT_OPTION.THIS
      );
      if (DELETE_RECURRENT_VISIT_OPTION.THIS === selection) {
        await this.deleteThis(event);
      } else if (DELETE_RECURRENT_VISIT_OPTION.THIS_AND_FOLLOWING === selection) {
        await this.deleteThisAndFollowing(event);
      } else {
        return false;
      }
    } else {
      let isDeleteConfirmed = false;
      if (
        event.Status === CALENDAR_EVENT_STATUS.BOOKED ||
        event.Status === CALENDAR_EVENT_STATUS.NO_SHOW
      ) {
        isDeleteConfirmed = true;
      } else if (event.Status === CALENDAR_EVENT_STATUS.CHECKED_IN) {
        const selection = await this.dialogService
          .showYesNoDialog(
            `This appointment is already checked in. Are you sure you want to remove it?`,
            'Warning'
          )
          .closed.toPromise();
        isDeleteConfirmed = selection === YES_BUTTON_ID;
      }
      if (isDeleteConfirmed) {
        await this.changeStatusToDeleted(event.Id);
        return isDeleteConfirmed;
      }
    }
    return true;
  }

  async deleteDoctorLeave(event: CalendarEvent) {
    if (CALENDAR_EVENT_TYPE.DOCTOR_LEAVE !== event.EventType) {
      throw new Error('Must be a doctor leave event');
    }
    if (event.RecurrentInfo?.RootEventId) {
      const selection = await this.dialogService.showRadioButtonDialog(
        'Delete recurring unavailable booking',
        [
          DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS,
          DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS_AND_FOLLOWING,
          DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.ALL,
        ],
        DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS
      );
      if (DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS === selection) {
        await this.deleteThis(event);
      } else if (DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS_AND_FOLLOWING === selection) {
        await this.deleteThisAndFollowing(event);
      } else if (DELETE_RECURRENT_DOCTOR_LEAVE_OPTION.ALL === selection) {
        await this.deleteAll(event?.RecurrentInfo?.RootEventId);
      } else {
        return false;
      }
    } else {
      await this.changeStatusToDeleted(event.Id);
    }
    return true;
  }

  private async deleteThis(event: CalendarEvent) {
    const dbId = event.Metadata.Id;
    if (dbId && dbId !== event?.RecurrentInfo?.RootEventId) {
      await this.changeStatusToDeleted(dbId);
    }
    if (event?.RecurrentInfo?.RootEventId) {
      const change = {
        Id: event.RecurrentInfo.RootEventId,
        RecurrentInfo: {
          Rule: {
            SkipIndexes: arrayUnion(event.RecurrentInfo.Index),
          },
        },
      } as any;
      if (event?.RecurrentInfo?.RootEventId === event.Id) {
        change.Status = CALENDAR_EVENT_STATUS.DELETED;
      }
      await this.mergeDoc(change);
    }
  }

  private async deleteThisAndFollowing(event: CalendarEvent) {
    await this.deleteEvents(
      event?.RecurrentInfo?.RootEventId,
      event?.IsRecurrentRoot ? 0 : event?.RecurrentInfo?.Index
    );
    if (event?.RecurrentInfo.Index === 0) {
      await this.changeStatusToDeleted(event.RecurrentInfo.RootEventId);
    } else {
      const rruleEvent = event.Metadata.RecurrentRuleEvent;
      await this.mergeDoc({
        Id: event?.RecurrentInfo?.RootEventId,
        RecurrentInfo: {
          Rule: {
            Repeat: {
              EndDate: RRuleUtils.getRecurrentRuleEndDate(
                rruleEvent.StartTime,
                rruleEvent.RecurrentInfo.Rule.Repeat.Frequency,
                event.RecurrentInfo.Index + 1
              ),
            },
            SkipIndexes: rruleEvent.RecurrentInfo?.Rule?.SkipIndexes?.filter(
              (v) => v < event.RecurrentInfo.Index
            ),
          },
        },
      } as CalendarEvent);
    }
  }

  async deleteAll(rootEventId: string) {
    await this.mergeDoc({
      Id: rootEventId,
      Status: CALENDAR_EVENT_STATUS.DELETED,
    } as CalendarEvent);
    await this.deleteEvents(rootEventId, 0);
  }

  async changeStatusToDeleted(eventId: string) {
    await this.mergeDoc({
      Id: eventId,
      Status: CALENDAR_EVENT_STATUS.DELETED,
    });
  }

  async deleteEvents(rootEventId: string, indexGOE: number) {
    if (!rootEventId || indexGOE < 0) {
      throw Error('rootEventId/indexGT parameteer is missing/inalid');
    }
    const calendarEvents = await this.calendarEventQueryService.find(
      CalendarEventWhereBuilder.builder()
        .recurrentRootEventId(rootEventId)
        .recurrentEventIndexGOE(indexGOE)
        .isRecurrentRoot(false)
        .status(CALENDAR_EVENT_STATUS.BOOKED) // we delete only booked recurrent visits
        .build()
    );
    await Promise.all(calendarEvents.map(async (e) => await this.changeStatusToDeleted(e.Id)));
  }
}
