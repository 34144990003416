import { QueryConstraint, where } from '@angular/fire/firestore';
import {INVOICE_STATUS, INVOICE_SUBTYPE, INVOICE_TYPE} from "@meraki-flux/schema";

export class InvoiceWherePreset {
  static openWithBalance(accountId: string) {
    return InvoiceWhereBuilder.builder()
      .accountId(accountId)
      .status(INVOICE_STATUS.OPEN)
      .balanceOutstandingGT(0);
  }
}

export class InvoiceWhereBuilder {
  wheres: QueryConstraint[];

  constructor(wheres: QueryConstraint[]) {
    this.wheres = wheres;
  }

  static builder(): InvoiceWhereBuilder {
    return new InvoiceWhereBuilder([]);
  }

  build() {
    return this.wheres;
  }

  accountId(accountId: string): InvoiceWhereBuilder {
    if (accountId) this.wheres.push(where('Account.Id', '==', accountId));
    return this;
  }

  patientId(patientId: string): InvoiceWhereBuilder {
    if (patientId) this.wheres.push(where('Patient.Id', '==', patientId));
    return this;
  }

  status(status: INVOICE_STATUS): InvoiceWhereBuilder {
    if (status) this.wheres.push(where('Status', '==', status));
    return this;
  }

  type(type: INVOICE_TYPE): InvoiceWhereBuilder {
    if (type) this.wheres.push(where('Type', '==', type));
    return this;
  }

  subtype(subtype: INVOICE_SUBTYPE): InvoiceWhereBuilder {
    if (subtype) this.wheres.push(where('Subtype', '==', subtype));
    return this;
  }

  statusIn(...statuses: INVOICE_STATUS[]): InvoiceWhereBuilder {
    const filteredStatuses = statuses?.filter((s) => !!s);
    if (filteredStatuses?.length > 0) this.wheres.push(where('Status', 'in', filteredStatuses));
    return this;
  }

  balanceOutstandingGT(value: number): InvoiceWhereBuilder {
    if (value || value == 0) this.wheres.push(where('Balance.Outstanding', '>', value));
    return this;
  }

  dosGOE(value: any): InvoiceWhereBuilder {
    if (value) this.wheres.push(where('DateOfService', '>=', value));
    return this;
  }

  dosLOE(value: any): InvoiceWhereBuilder {
    if (value) this.wheres.push(where('DateOfService', '<=', value));
    return this;
  }

  dosBetween(value1: any, value2: any): InvoiceWhereBuilder {
    if (value1 && value2) {
      this.dosGOE(value1).dosLOE(value2);
    }
    return this;
  }

  dateOfSubmissionGOE(value: any): InvoiceWhereBuilder {
    if (value) this.wheres.push(where('DateOfSubmission', '>=', value));
    return this;
  }

  dateOfSubmissionLOE(value: any): InvoiceWhereBuilder {
    if (value) this.wheres.push(where('DateOfSubmission', '<=', value));
    return this;
  }

  dateOfSubmissionBetween(value1: any, value2: any): InvoiceWhereBuilder {
    if (value1 && value2) {
      this.dateOfSubmissionGOE(value1).dateOfSubmissionLOE(value2);
    }
    return this;
  }

  dateOfServiceGOE(value: any): InvoiceWhereBuilder {
    if (value) this.wheres.push(where('DateOfService', '>=', value));
    return this;
  }

  schemeName(schemeName: string): InvoiceWhereBuilder {
    if (schemeName) this.wheres.push(where('Account.SchemeName', '==', schemeName));
    return this;
  }
}
