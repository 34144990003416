<div class="search-field" matAutocompleteOrigin
    #origin="matAutocompleteOrigin">

    <div class="flex items-center flex-row flex-nowrap ">
        <mat-icon class="mx-2"
        matTooltip="Search on name, account no., member no., ID no, or patient file no.">
            search
        </mat-icon>
        <input #searchField [formControl]="searchForm" [matAutocomplete]="searchResultsAuto"
            [matAutocompleteConnectedTo]="origin" (blur)="onFocusOut($event)"
            placeholder="Patient search" />

        <button *ngIf="searchField.value" class="!mx-2 !h-8 !w-8 !leading-3" mat-icon-button
            (click)="onClearSearch($event)" (mousedown)="$event.preventDefault()">
            <mat-icon>close</mat-icon>
        </button>


    </div>

</div>


<mat-autocomplete #searchResultsAuto="matAutocomplete" [displayWith]="patientSearchDisplayFn"
    autoActiveFirstOption="true" autoSelectActiveOption="true"
    (optionSelected)="onResultSelected($event)" [panelWidth]="'600px'">

    <ng-container *ngIf="searchResults$ | async as results ">
        <!-- Search Results -->
        <mat-option disabled *ngIf="searchBusy$ | async">
            <mat-progress-bar class="search-busy-indicator" mode="indeterminate"></mat-progress-bar>
        </mat-option>

        <mat-option class="min-w-[300px]" *ngFor="let result of results" [value]="result" (mousedown)="$event.preventDefault()">
            <div class="text-xs" [innerHTML]="{patient: result.Patient, account: result.Account} | patientSearchResult">
            </div>
        </mat-option>
    </ng-container>

</mat-autocomplete>