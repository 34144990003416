<div mat-dialog-title class="!flex flex-row justify-between">
  <div>Edit Benefit Check Lines</div>
  <div>
      <button mat-icon-button (click)="onNoClick()">
          <mat-icon>close</mat-icon>
      </button>
  </div>
</div>
<mat-divider class="mt"></mat-divider>
<div mat-dialog-content class="h-full overflow-auto">
  <div class="pt-3">
    <ng-container *ngVar="BCLines$ | async as BCLines">
      <meraki-flux-invoice-lines [type]="type$.value" [BCLines]="BCLines" [account]="account$.value" [provider]="provider$.value">
      </meraki-flux-invoice-lines>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions class="flex flex-row justify-end">
  <button mat-flat-button class="flux-button mat-secondary" (click)="onNoClick()">Close</button>
  <button mat-flat-button class="flux-button mat-primary" (click)="onSave()">Save</button>
</div>
