import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import { ClaimStatusNotificationComponent } from '../components/notification/claim-status-notification/claim-status-notification.component';
import {ReferenceDataRepository} from "../repositories/reference-data.repository";
import { LogService } from './log.service';
import {
  ReportStatusNotificationComponent
} from "../components/notification/report-status-notification/report-status-notification.component";
import {CLAIM_STATUS, NotificationMessage, Type} from "@meraki-flux/schema";
@Injectable({
  providedIn: 'root'
})
export class SnackbarNotifierService {

  private SUPPORTED_CLAIM_NOTIFICATION_STATUSES = [CLAIM_STATUS.REJECTED, CLAIM_STATUS.PART_REJECTED, CLAIM_STATUS.PROCESSED_BY_MEDICALAID, CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID, CLAIM_STATUS.REVERSAL_REJECTED, CLAIM_STATUS.REVERSAL_ACCEPTED];

  private isProcessing = false;
  private queue: NotificationMessage[] = [];

  constructor(
    private matSnackBar: MatSnackBar,
    private referenceDataRepository: ReferenceDataRepository,
    private logger: LogService) {
  }

  private processNextQueueItem(): void {
    const notification = this.queue.length ? this.queue.shift() : undefined;

    if (!notification) {
      this.isProcessing = false;
      return;
    }

    this.isProcessing = true;

    const snackBarRef: MatSnackBarRef<any> = this.showSnackbar(notification);
    if (snackBarRef) {
      snackBarRef.afterDismissed()
        .subscribe(() => {
          this.processNextQueueItem();
        });
    } else {
      this.processNextQueueItem()
    }
  }

  public show(message: NotificationMessage): void {
    this.queue.push(message);
    if (!this.isProcessing) {
      this.processNextQueueItem();
    }
  }

  private showSnackbar(message: NotificationMessage): MatSnackBarRef<any> {
    this.logger.error("NOTIFICATION in a snackbar");
    const notificationType: Type = message.Type;
    switch (notificationType) {
      case Type.CLAIM_STATUS_NOTIFICATION:
        if (this.SUPPORTED_CLAIM_NOTIFICATION_STATUSES.indexOf(message?.ClaimStatusNotification?.ClaimStatus) == -1) {
          this.logger.error("Unsupported Claim Status");
          return null;
        }
        return this.matSnackBar.openFromComponent(ClaimStatusNotificationComponent, {
          data: message,
          duration: this.referenceDataRepository.getSnackbarDuration(),
          horizontalPosition: "right",
          verticalPosition: "bottom",
          panelClass: ['flux-snackbar-notification']
        })
      case Type.REPORT_STATUS_NOTIFICATION:
        this.logger.error("REPORT_STATUS_NOTIFICATION in a snackbar");
        return this.matSnackBar.openFromComponent(ReportStatusNotificationComponent, {
          data: message,
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
          panelClass: ['flux-snackbar-notification']
        })
    }
  }
}
