import { BaseAccountMember } from './member.models';
import { BaseAccount } from './account.models';

export interface PatientAccount {
  Patient?: BaseAccountMember;
  MainMember?: BaseAccountMember;
  Account?: BaseAccount;
  SkipErrors?: boolean;
}

export class PatientAccountResponse {
  AccountId?: string;
  MainMemberId?: string;
  MainMemberGuid?: string;
  PatientId?: string;
  PatientGuid?: string;
}

