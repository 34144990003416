import {BaseAccount} from "../account.models";
import {BaseInvoice} from "../invoice.models";
import {Practice, ReportLogoSetting} from "../practice.models";
import {FooterInfoTable, PDFCellData, ReportICDLine, ReportSnapscan, TableBorders} from "./pdf.model";
import {SnapScan} from "../addon.models";
import {NameValue, ReportTableHeader} from "../report.models";
import {AccountMember} from "../member.models.ui";

export class InvoiceReportRequest {
  Practice: Practice;
  Invoice: BaseInvoice;
  Account: BaseAccount;
  SendEmail?: boolean;
  Patient?: AccountMember;
  Snapscan?: SnapScan;
  PracticeId?: string;
  InvoiceId?: string;
}

export interface InvoiceReportModel {
    Logo?: ReportLogoSetting;
    PracticeId: string;
    PracticeName: string;
    Header: string;
    PracticeHeader: InvoiceHeader;
    AccountHeader: InvoiceHeader;
    InvoiceTables: InvoiceTable[];
    InfoTable: FooterInfoTable;
    FooterText: string;
    InvoiceMessage?: string,
    ReportDate?: string,
    Snapscan?: ReportSnapscan
}

export interface InvoiceTable {
    TableHeaders: ReportTableHeader[];
    ICDLine?: ReportICDLine;
    rowHeaders?: PDFCellData[];
    borders?: TableBorders;
    rows?: PDFCellData[][];
}



export interface InvoiceHeader {
    name?: string;
    caption?: string;
    left: NameValue[]
    right: NameValue[];
}
