import { QueryConstraint, where } from '@angular/fire/firestore';
import { PrivateRateType } from '@meraki-flux/schema';

export class PrivateRateWhereBuilder {
  wheres: QueryConstraint[];

  constructor(wheres: QueryConstraint[]) {
    this.wheres = wheres;
  }

  static builder(): PrivateRateWhereBuilder {
    return new PrivateRateWhereBuilder([]);
  }

  build() {
    return this.wheres;
  }

  active(active: boolean): PrivateRateWhereBuilder {
    if (active) this.wheres.push(where('IsActive', '==', true));
    return this;
  }

  type(privateRateType: PrivateRateType): PrivateRateWhereBuilder {
    if (privateRateType) this.wheres.push(where('Type', '==', privateRateType));
    return this;
  }

  /**
   * Filters by the branch name
   * @param branchName non-empty branch name or empty string "" for 'All Branches'
   * @returns
   */
  branchName(branchName: string): PrivateRateWhereBuilder {
    if (branchName) this.wheres.push(where('BranchName', '==', branchName));
    return this;
  }

  code(code: string): PrivateRateWhereBuilder {
    if (code) this.wheres.push(where('Code', '==', code));
    return this;
  }

  medicalAidInvoices(value: boolean): PrivateRateWhereBuilder {
    if (value) this.wheres.push(where('MedicalAidInvoices', '==', value));
    return this;
  }

  cashInvoices(value: boolean): PrivateRateWhereBuilder {
    if (value) this.wheres.push(where('CashInvoices', '==', value));
    return this;
  }
}
