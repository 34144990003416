import {
  BasePdfReportData, MedicalInsurerHeader,
  MedicalInsurerReportModel,
  MedicalInsurerTable,
  ReportFooter, ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class MedicalInsurerPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#008DA6";

    build(medicalInsurerReportModel: MedicalInsurerReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = medicalInsurerReportModel.PracticeId;
        reportData.doctorName = medicalInsurerReportModel.PracticeName;
        reportData.doctorNameColor = MedicalInsurerPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.headers = this.buildHeaders(medicalInsurerReportModel);
        reportData.tables = this.buildTables(medicalInsurerReportModel);
        reportData.footer = this.buildFooter(medicalInsurerReportModel);
        reportData.mainHeader = medicalInsurerReportModel.Header;
        reportData.dateHeader = `Statement date: ${medicalInsurerReportModel.MedicalInsurerReportDate}`;
        reportData.medInsurerstatementSummary = medicalInsurerReportModel.MedicalInsurerSummary;
        reportData.statementAges = medicalInsurerReportModel.MedicalInsurerAges;
        reportData.footerText = medicalInsurerReportModel.FooterText;
        return reportData;
    }

    buildFooter(medicalInsurerReportModel: MedicalInsurerReportModel) : ReportFooter {
        return {
            hbLogo: true,
            infoTable: medicalInsurerReportModel.InfoTable
        }
    }

    buildTables(medicalInsurerReportModel: MedicalInsurerReportModel) : ReportTable[] {
        const tables:ReportTable[] = [];
        medicalInsurerReportModel.MedicalInsurerTables.forEach(mInsTable => {
            tables.push(this.buildReportTable(mInsTable));
        })
        return tables;
    }

    buildReportTable(mInsTable: MedicalInsurerTable) : ReportTable {
        return {
            headers: mInsTable.TableHeaders.map(x => {
                return {
                    outlined: x.outlined,
                    outlineColor: x.outlineColor,
                    rows: x.rows.map(y => {
                        return y.map(xy => {
                            return {
                                name: xy.name,
                                data: {
                                    value: xy.value.value,
                                    decoration: {
                                        bold: xy.value.bold
                                    }
                                }
                            }
                        })
                    }),
                }
            }),
            reportICDLine: mInsTable.ICDLine,
            rowHeaders: mInsTable.rowHeaders,
            rows: mInsTable.rows,
            borders: mInsTable.borders,
            legend: mInsTable.legend
        }
    }

    buildHeaders(medicalInsurerReportModel: MedicalInsurerReportModel) : ReportHeader[] {
        const headers:ReportHeader[] = [];
        headers.push(this.buildReportHeader(medicalInsurerReportModel.PracticeHeader));
        headers.push(this.buildAccountHeader(medicalInsurerReportModel.AccountHeader));
        return headers;
    }

    buildReportHeader(mInsuranceHeader: MedicalInsurerHeader): ReportHeader {
        return {
            left: mInsuranceHeader.left.map(x => {
                return {
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            })
             ,
            right: mInsuranceHeader.right.map(x => {
                return {
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            })
        }
    }

    buildAccountHeader(mInsuranceHeader: MedicalInsurerHeader): ReportHeader {
        return {
            name: mInsuranceHeader.name,
            nameColor: MedicalInsurerPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            includeGeneratedDate: true,
            outlined: true,
            outlineColor: MedicalInsurerPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            ... this.buildReportHeader(mInsuranceHeader)

        }
    }
}
