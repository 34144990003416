import { Balance } from "@meraki-flux/schema";

export class MoneyUtils {
    
  static toCents(value: number) {
    return value === 0 || value ? Math.round(value * 100) : null;
  }

  static fromCents(value: number) {
    return value === 0 || value ? Math.round(value) / 100 : null;
  }

  static balanceToCents(balance: Balance): Balance {
    return {
        Outstanding: this.toCents(balance?.Outstanding),
        PatientLiable: this.toCents(balance?.PatientLiable),
        MedicalAidLiable: this.toCents(balance?.MedicalAidLiable)
    } as Balance;
  }

  static balanceFromCents(balance: Balance): Balance {
    return {
        Outstanding: this.fromCents(balance?.Outstanding),
        PatientLiable: this.fromCents(balance?.PatientLiable),
        MedicalAidLiable: this.fromCents(balance?.MedicalAidLiable)
    } as Balance;
  }

  static getCurrencySymbol(amount: number, locale?: string): string {
    return new Intl.NumberFormat(locale ?? 'en-ZA', {style: 'currency', currency: 'ZAR'}).format(amount); 
  }
}
