import moment = require('moment');
import {
  BaseAccount,
  BaseAccountMember, FileUploadData, FileUploadResult,
  LIABLE_TYPE,
  Practice,
  Provider,
  REPORT_FORMAT_TYPE
} from '@meraki-flux/schema';

export interface ReportResponse {
  url?: string;
  mime?: string;
  fileName?: string;
  storagePath?: string;
  to?: string[];
  cc?: string[];
}

export class ReportUtils {

  private static readonly DATE_FORMAT = 'DD-MM-YYYY-HH-mm-ss';
  private static readonly SHORT_DATE_FORMAT = 'DD-MM-YYYY';
  private static readonly DATE_ONLY_FORMAT = 'DD-MM-YYYY';
  private static readonly DATE_ONLY_SPACE_FORMAT = 'DD MM YYYY';
  static readonly CLAIM_INVOICE_REPORT_NAME = 'Claim and Invoice report';
  static readonly STATEMENT_REPORT_NAME = 'Statement report';
  static readonly INVOICE_REPORT_NAME = 'Invoice report';

  public static async buildReportFileName(reportName: string, practice: Practice,
                                   branch: string, reportFormat: REPORT_FORMAT_TYPE, provider?: Provider): Promise<string> {
    var practiceName: string = practice.PracticeName;

    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    if (practice.IsMultiBranch === true) {
      branch = branch ? branch : 'All branches';
      return `${reportName}_${practiceName}_${branch}_${moment(new Date()).format(this.DATE_FORMAT)}.${fileExt}`;
    } else {
      if (provider) {
        practiceName = `${provider.Title} ${provider.Name} ${provider.Surname}`;
      }
      return `${reportName}_${practiceName}_${moment(new Date()).format(this.DATE_FORMAT)}.${fileExt}`;
    }
  }

  public static async buildStatementReportFileName(reportName: string, practice: Practice, account: BaseAccount,
                                            patient: BaseAccountMember, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    var practiceName: string = practice.PracticeName;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `${reportName}_${practiceName}_${patient.Name} ${patient.Surname}_${account.AccountNo}_${moment(new Date()).format(this.DATE_ONLY_FORMAT)}.${fileExt}`;
  }

  public static async buildBulkStatementReportFileName(reportName: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `${reportName}_${moment(new Date()).format(this.DATE_FORMAT)}.${fileExt}`;
  }

  public static async buildCollectableAmountsReportFileName(practiceName: string, reportFormat: REPORT_FORMAT_TYPE, branch?: string): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    if (!branch) {
      branch = 'All branches';
    }
    return `CollectableAmounts_${practiceName}_${branch}_${moment(new Date()).format('MM YYYY')}.${fileExt}`;
  }

  public static async buildAppointmentReportFileName(
    practiceName: string, isMultiBranch: boolean, branch: string, reportFormat: REPORT_FORMAT_TYPE, dateFrom: Date, dateTo: Date): Promise<string> {

    const reportName = 'Appointments';
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    //CA Fixed report file layout according to FRS - Appintments_<practice name>_<branch>_<from> to <to>
    const datePart = `${moment(dateFrom).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(dateTo).format(this.DATE_ONLY_SPACE_FORMAT)}`;

    if (isMultiBranch) {
      const branchPart = branch ? branch : 'All branches';
      return `${reportName}_${practiceName}_${branchPart}_${datePart}.${fileExt}`;
    }

    return `${reportName}_${practiceName}_${datePart}.${fileExt}`;
  }

  public static async buildCreditNoteReportFileName(
    reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart: string = provider ? `${provider.Title} ${provider.Name} ${provider.Surname}` : practice.PracticeName;
    const datePart = `${moment(fromDate).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(toDate).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`;
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`;
  }

  public static async buildReportFileNameDates(
    reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart: string = provider ? `${provider.Title} ${provider.Name} ${provider.Surname}` : practice.PracticeName;
    const datePart = `${moment(fromDate).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(toDate).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`;
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`;
  }

  public static async buildPaymentSummaryReportFileName(practiceName: string, reportFormat: REPORT_FORMAT_TYPE, dateFrom: Date, dateTo: Date): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    //CA Fixed report file layout according to FRS - PaymentSummary_<practice name>_<from> to <to>
    const datePart = `${moment(dateFrom).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(dateTo).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    return `PaymentSummary_${practiceName}_${datePart}.${fileExt}`;
  }

  public static async buildMonthlyTransactionReportFileName(practiceName: string, month: string, year: string): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.PDF);
    // CA added practice name according to FRS - MonthlyTransactions_<practice name>_<month>_<year>
    return `MonthlyTransactions_${practiceName}_${month}_${year}.${fileExt}`;
  }

  public static async buildAgeAnalysisReportFileName(practice: Practice, liable: LIABLE_TYPE, reportFormat: REPORT_FORMAT_TYPE) {
    const reportPrefixName = liable === LIABLE_TYPE.ACCOUNT_HOLDER ? 'AgeAnalysis_PatientLiable' : 'AgeAnalysis_MedAidLiable';
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `${reportPrefixName}_${practice.PracticeName}_${moment(new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`;
  }

  public static async buildReportFileNameNoProvider(
    reportPrefixName: string, practice: Practice,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart = practice.PracticeName;
    const datePart = `${moment(fromDate).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(toDate).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  public static async buildPaymentDetailsReportFileName(reportPrefixName: string, practice: Practice, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(toDate).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.EXCEL);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  public static async buildPaymentPerProviderReportFileName(
    reportPrefixName: string, practice: Practice, fromDate: Date, toDate: Date, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format(this.DATE_ONLY_SPACE_FORMAT)} to ${moment(toDate).format(this.DATE_ONLY_SPACE_FORMAT)}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  public static async buildPatientListReportFileName(reportPrefixName: string, practice: Practice): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = moment(new Date()).format(this.DATE_ONLY_SPACE_FORMAT);
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.EXCEL);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  public static async bulkSubject() {
    return `Download link for bulk statements, generated on ${moment(new Date()).format(this.DATE_ONLY_FORMAT)}`;
  }

  public static async bulkBody(firstName: string, url: string) {
    return `Dear ${firstName}<br/><br/>

                Please click on the link below to download your bulk statement document. You must save the document on your computer, and from there you can open it to print all the statements.<br/><br/>

                <a href="${url}">Download statement report.</a><br/><br/>

                Kind regards,<br/>
                Healthbridge`;
  }

  public static lookupMimeType(reportType: REPORT_FORMAT_TYPE): string {
    if (reportType == REPORT_FORMAT_TYPE.EXCEL) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (reportType == REPORT_FORMAT_TYPE.PDF) {
      return 'application/pdf';
    }
    throw new Error('Unsupported report format type');
  }

  public static lookupReportFileExtention(reportType: REPORT_FORMAT_TYPE): string {
    if (reportType == REPORT_FORMAT_TYPE.EXCEL) {
      return 'xlsx';
    } else if (reportType == REPORT_FORMAT_TYPE.PDF) {
      return 'pdf';
    }
    throw new Error('Unsupported report format type');
  }

  public static buildReportEmailSubject(reportName: string): string {
    return 'Healthbridge Nova report - ' + reportName;
  }


  public static async uploadReportFile(bpn: string, pdfData: Buffer, fileName: string, uploadFileFunc?: any): Promise<FileUploadResult> {
    const fileUploadData: FileUploadData = {
      content: pdfData,
      fileName: fileName,
      metadata: undefined,
      makePublic: true
    };
    return await uploadFileFunc(
      `Practice/${bpn || -1}/reports`,
      fileUploadData,
      undefined
    );
  }

  public static buildClaimSummarySubscriptionReportName(dateRangePeriod: 'Daily' | 'Monthly', practiceName: string, reportDate: Date): string {
    const prefix = dateRangePeriod === 'Daily' ? 'DailyClaimSummary' : 'MonthlyClaimSummary';
    const dateFormat = dateRangePeriod === 'Daily' ? 'DD_MM_YYYY' : 'MMM_YYYY';
    return `${prefix}_${practiceName}_${moment(reportDate).format(dateFormat)}.pdf`;
  }

  public static buildBenefitCheckReportFileName(
    practiceName: string, patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): string {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `Benefit Check_${practiceName}_${patientName} ${patientSurname}.${fileExt}`
  }

  public static getMonthName(date: Date) {
    return moment(date).format('MMMM');
  }

  public static getFullYear(date: Date) {
    return String(date.getFullYear());
  }

}
