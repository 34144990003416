import {
  BaseExcelReportData, CellType, CellWidth, CollectableAccountsDoctor, CollectableAmountsAccount,
  CollectableAmountsReportModel,
  CollectableAmountsSummary, ExcelCellData,
  Sheet, Table
} from '@meraki-flux/schema';

export class CollectableAmountsXlsReportDataBuilder {

  build(reportModel: CollectableAmountsReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.data = [];
    excelReportData.name = reportModel.ReportInfo.Name;
    excelReportData.practiceId = reportModel.ReportInfo.Practice;

    this.buildReportInfoSheet(excelReportData, reportModel);
    this.buildSummarySheet(reportModel.Summary, excelReportData);
    this.buildAccountsSheet(reportModel.Accounts, excelReportData);
    this.buildDoctorsSheet(reportModel.Doctors, excelReportData);

    return excelReportData;
  }

  private buildSummarySheet(collectableAmountsSummary: CollectableAmountsSummary, excelReportData: BaseExcelReportData) {
    const sheet: Sheet = {};
    sheet.name = collectableAmountsSummary.Name;
    sheet.header = 'Collectable Amounts';
    sheet.tables = [];
    const accSummary = this.buildAccSummaryTable(collectableAmountsSummary);
    const invSummary = this.buildInvoiceSummaryTables(collectableAmountsSummary);
    sheet.tables.push(accSummary);
    sheet.tables.push(invSummary);
    excelReportData.data.push(sheet);
  }

  private buildAccSummaryTable(collectableAmountsSummary: CollectableAmountsSummary) {
    const accSummary: Table = {};
    accSummary.name = 'Account summary'
    const accSumRows = [];
    const accSumRow: ExcelCellData[] = [];
    accSumRow.push({value: `No. of accounts with ${collectableAmountsSummary.LiableType} liable amounts`, width: CellWidth.M})
    accSumRow.push({value: collectableAmountsSummary.NoOfAccounts})
    accSumRows.push(accSumRow)
    accSummary.rows = accSumRows;
    return accSummary;
  }

  private buildInvoiceSummaryTables(collectableAmountsSummary: CollectableAmountsSummary) {
    const invSummary: Table = {};
    invSummary.name = 'Invoice summary'
    invSummary.headers = [
      {value: ''},
      {value: `No. of invoices with ${collectableAmountsSummary.LiableType} liable amounts`, bold: true},
      {value: 'Total collectable', bold: true},
    ];
    const invSumRows = [];
    collectableAmountsSummary.DoctorSummary?.forEach(x => {
      const row: ExcelCellData[] = [];
      row.push({value: x.Name});
      row.push({value: x.NoOfInvoices});
      row.push({value: x.TotalCollectable, type: CellType.CURRENCY});
      invSumRows.push(row);
    })
    const row: ExcelCellData[] = [];
    row.push({value: "Totals", bold: true});
    row.push({value: collectableAmountsSummary.TotalNo, bold: true});
    row.push({value: collectableAmountsSummary.TotalAmount, type: CellType.CURRENCY, bold: true});
    invSumRows.push(row);
    invSummary.rows = invSumRows;
    return invSummary;
  }

  private buildReportInfoSheet(excelReportData: BaseExcelReportData, reportModel: CollectableAmountsReportModel) {
    excelReportData.parameters = [];
    excelReportData.parameters.push({
      name: "Practice",
      data: {
        value: reportModel.ReportInfo.Practice
      }
    })
    excelReportData.parameters.push({
      name: "Billing practice no.",
      data: {
        value: reportModel.ReportInfo.BPN, type: CellType.GENERAL,
      }
    })
    excelReportData.parameters.push({
      name: "Branch",
      data: {
        value: reportModel.ReportInfo.Branch ?? "All branches"
      }
    })
    excelReportData.parameters.push({
      name: "Provider",
      data: {
        value: reportModel.ReportInfo.Provider ?? "All providers"
      }
    })
  }

  private buildAccountsSheet(collectableAmountsAccounts: CollectableAmountsAccount[], excelReportData: BaseExcelReportData) {
    const sheet: Sheet = {};
    sheet.name = "Accounts";
    sheet.tables = [];
    const accs: Table = {};
    accs.headers = [
      {value: 'Account no.', bold: true},
      {value: 'Account holder name', bold: true},
      {value: 'Account holder surname', bold: true},
      {value: 'Member no.', bold: true},
      {value: 'Scheme', bold: true},
      {value: 'Patient liable amount', bold: true},
      {value: 'Medical aid liable amount', bold: true},
      {value: 'Account notes', bold: true},
      {value: 'Account status', bold: true},
      {value: 'Reason', bold: true},
      {value: 'Branch', bold: true},
    ];
    const accRows = [];
    collectableAmountsAccounts?.forEach(x => {
      const row: ExcelCellData[] = [];
      row.push({value: x.AccountNo, type: CellType.GENERAL});
      row.push({value: x.AccountHolderName});
      row.push({value: x.AccountHolderSurname});
      row.push({value: x.MemberNo, type: CellType.GENERAL});
      row.push({value: x.Scheme});
      row.push({value: x.PatientLiable, type: CellType.CURRENCY});
      row.push({value: x.MedicalAidLiable, type: CellType.CURRENCY});
      row.push({value: x.AccountNotes});
      row.push({value: x.AccountStatus});
      row.push({value: x.Reason});
      row.push({value: x.Branch});
      accRows.push(row);
    })
    accs.rows = accRows;
    sheet.tables.push(accs);
    excelReportData.data.push(sheet);
  }

  private buildDoctorsSheet(collectableAccountsDoctors: CollectableAccountsDoctor[], excelReportData: BaseExcelReportData) {
    collectableAccountsDoctors?.forEach( doc => {
      excelReportData.data?.push(this.buildDoctorSheet(doc))
    })
  }

  private buildDoctorSheet(collectableAccountsDoctor: CollectableAccountsDoctor) {
    const sheet: Sheet = {};
    sheet.name = `Invoices - ${collectableAccountsDoctor.Name}`;
    sheet.tables = [];
    const invs: Table = {};
    invs.headers = [
      {value: 'Account no.', bold: true},
      {value: 'Patient name', bold: true},
      {value: 'Patient surname', bold: true},
      {value: 'Member no.', bold: true},
      {value: 'Scheme', bold: true},
      {value: 'Invoice no.', bold: true},
      {value: 'Invoice type', bold: true},
      {value: 'Invoice date', bold: true},
      {value: 'Date of service', bold: true},
      {value: 'Invoice notes', bold: true},
      {value: 'Patient liable amount', bold: true},
      {value: 'Medical aid liable amount', bold: true},
      {value: 'BC (Y/N)', bold: true},
      {value: 'BC status', bold: true},
      {value: 'BC message ID', bold: true},
      {value: 'Branch', bold: true},
    ];
    const invRowa = [];
    collectableAccountsDoctor.Invoices?.forEach(x => {
      const row: ExcelCellData[] = [];
      row.push({value: x.AccountNo, type: CellType.GENERAL});
      row.push({value: x.PatientName});
      row.push({value: x.PatientSurname});
      row.push({value: x.MemberNo, type: CellType.GENERAL});
      row.push({value: x.Scheme});
      row.push({value: x.InvoiceNo});
      row.push({value: x.InvoiceType});
      row.push({value: x.InvoiceDate});
      row.push({value: x.DateOfService});
      row.push({value: x.InvoiceNotes});
      row.push({value: x.PatientLiable, type: CellType.CURRENCY});
      row.push({value: x.MedicalAidLiable, type: CellType.CURRENCY});
      row.push({value: x.BC});
      row.push({value: x.BCStatus});
      row.push({value: x.BCMessageId});
      row.push({value: x.Branch});
      invRowa.push(row);
    })
    invs.rows = invRowa;
    sheet.tables.push(invs);
    return sheet
  }
}
