import {
  AssistantDetailsItem,
  AssistantProviderModel,
  AssistantReportModel, AssistantSummaryItem,
  BasePdfReportData, CellType, PDFCellData,
  PDFParameter,
  REPORT_NAME,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class AssistantPdfReportDataBuilder {
    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(assistantReportModel: AssistantReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = assistantReportModel.AssistantReportInfo.PracticeId
        reportData.orientation = "landscape";
        reportData.headers = this.buildHeader(assistantReportModel);
        reportData.tables = this.buildTables(assistantReportModel);
        reportData.dateHeader = `Date generated ${assistantReportModel.ReportDate}`;
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.footer = {
            hbLogo: true
        }
        return reportData;
    }

    private buildHeader(assistantReportModel: AssistantReportModel): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({name: 'Billing practice no.', data: {value: assistantReportModel.AssistantReportInfo.PracticeId, decoration: {bold: true}}});
        leftParameters.push({name: 'Practice', data: {value: assistantReportModel.AssistantReportInfo.Practice, decoration: {bold: true}}});
        if (assistantReportModel.AssistantReportInfo.IsMultiBranch) {
            leftParameters.push({name: 'Branch', data: {value: assistantReportModel.AssistantReportInfo.Branch, decoration: {bold: true}}});
        }
        const rightParameters: PDFParameter[] = [
            {name: 'Assistant provider', data: {value: assistantReportModel.AssistantReportInfo.AssistingProvider, decoration: {bold: true}}},
            {name: 'Treating provider', data: {value: assistantReportModel.AssistantReportInfo.TreatingProvider, decoration: {bold: true}}},
            {name: 'Date Range', data: {value: assistantReportModel.AssistantReportInfo.DateRange, decoration: {bold: true}}},
        ]
        return [
            {
                name: REPORT_NAME.ASSISTANT,
                nameColor: AssistantPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                includeGeneratedDate: true,
                left: leftParameters,
                right: rightParameters
            }
        ];
    }

    private buildTables(assistantReportModel: AssistantReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
          reportTables.push(this.buildSummaryTable(assistantReportModel.AllProvidersModel));
          reportTables.push(this.buildDetailsTable(assistantReportModel.AllProvidersModel.DetailsTable, 'Details', assistantReportModel.AssistantReportInfo.IsMultiBranch));
        return reportTables;
    }

    private buildSummaryTable(providerModel: AssistantProviderModel): ReportTable {
        const table: ReportTable = {};
        table.name = 'Summary';
        table.nameColor = AssistantPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = AssistantPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        //table.headers = [{rows: [[{name: '', data: {value: 'Summary', decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Assisting Provider', decoration: {bold: true}},
            {value: 'No. of invoices', decoration: {bold: true}},
            {value: '0008 line total', decoration: {bold: true}},
            {value: '0009 line total', decoration: {bold: true}},
            {value: 'Total invoiced amount', decoration: {bold: true}},
        ];
        table.rowHeadersBold = true;
        const rows = [];

        let totalNoOfInvoices = 0;
        let total0008Line = 0;
        let total0009Line = 0;
        let totalInvoicedAmount = 0;

        providerModel.SummaryTable.sort((a, b) => a.AssistingProvider > b.AssistingProvider ? 1 : -1).forEach((item: AssistantSummaryItem) => {
            totalNoOfInvoices += item.NoOfINvoices;
            total0008Line += item.LineTotal0008;
            total0009Line += item.LineTotal0009;
            totalInvoicedAmount += item.TotalInvoicedAmount;

            const row: PDFCellData[] = [];
            row.push({value: item.AssistingProvider});
            row.push({value: item.NoOfINvoices, type: CellType.NUMBER});
            row.push({value: item.LineTotal0008, type: CellType.CURRENCY});
            row.push({value: item.LineTotal0009, type: CellType.CURRENCY});
            row.push({value: item.TotalInvoicedAmount, type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: PDFCellData[] = [];
        row.push({value: "Total", decoration: {bold: true}});
        row.push({value: totalNoOfInvoices, type: CellType.NUMBER, decoration: {bold: true}});
        row.push({value: total0008Line, type: CellType.CURRENCY, decoration: {bold: true}});
        row.push({value: total0009Line, type: CellType.CURRENCY, decoration: {bold: true}});
        row.push({value: totalInvoicedAmount, type: CellType.CURRENCY, decoration: {bold: true}});

        rows.push(row);
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        return table;
    }

    private buildDetailsTable(invoiceDetailsItem: AssistantDetailsItem[], tableName: string, isMultiBranch: boolean): ReportTable {
        const table: ReportTable = {};
        table.name = 'Details';
        table.nameColor = AssistantPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = AssistantPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        //table.headers = [{rows: [[{name: "", data: {value: tableName, decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Assisting provider', decoration: {bold: true}},
            {value: 'Speciality', decoration: {bold: true}},
            {value: 'Date of service', decoration: {bold: true}},
            {value: 'Treating provider', decoration: {bold: true}},
            {value: 'Patient', decoration: {bold: true}},
            {value: 'Account no.', decoration: {bold: true}},
            {value: 'Invoice type', decoration: {bold: true}},
            {value: 'Invoice no.', decoration: {bold: true}},
            {value: 'Tariff code', decoration: {bold: true}},
            {value: 'Invoice amount', decoration: {bold: true}},
        ];
        if (isMultiBranch)
            table.rowHeaders.push({value: 'Branch', decoration: {bold: true}});

        const rows = [];
        invoiceDetailsItem.sort((a, b) => {
          if (a.AssistingProvider !== b.AssistingProvider) {
            return a.AssistingProvider.localeCompare(b.AssistingProvider);
          } else {
            return a.DateOfService > b.DateOfService ? 1 : -1;
          }
        }).forEach((item: AssistantDetailsItem) => {
            const row: PDFCellData[] = [];

            row.push({value: item.AssistingProvider}),
            row.push({value: item.Speciality}),
            row.push({value: item.DateOfService});//, type: CellType.DATE});
            row.push({value: item.TreatingProviderName});
            row.push({value: item.PatientName});
            row.push({value: item.AccountNo});
            row.push({value: item.InvoiceType});
            row.push({value: item.InvoiceNo});
            row.push({value: item.TariffCode});
            row.push({value: item.AmountBilled, type: CellType.CURRENCY});

            if (isMultiBranch)
                row.push({value: item.Branch});
            rows.push(row);
        });
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        if (!rows || rows.length === 0) {
            table.rows = undefined;
            table.rowHeaders = undefined;
            table.noRecordsText = {
                value: "No records",
                decoration: {
                    italics: true
                }
            }
        }
        return table;
    }
}
