import {ERROR_CODE} from './error.models';
import {BaseModel} from './general.models';
import {Reason, REMITTANCE_ADVICE_TYPE} from './remittance.models';

export enum PAYMENT_TYPE {
  MEDICAL_AID = 'Medical Aid',
  CASH = 'Cash',
  CREDIT_CARD = 'Credit Card',
  DEBIT_CARD = 'Debit Card',
  EFT = 'EFT',
  CHEQUE = 'Cheque',
  HEALTHBRIDGE_EASYCOLLECT = 'Healthbridge Easycollect',
  SNAPSCAN = 'SnapScan',
}

export enum BALANCE_ACTION {
  NO_ACTION = 'No action',
  PATIENT_LIABLE = 'Patient liable',
  WRITE_OFF = 'Write off',
  UNALLOCATED_CREDIT = 'Unallocated credit',
}

export enum WRITE_OFF_TYPE {
  SMALL_BALANCE = 'Small balance',
  BAD_DEBT = 'Bad debt',
}

export enum CREDIT_TYPE {
  OTHER = 'Other',
  LINES_RESUBMITTED = 'Lines resubmitted',
  CLAIM_RESUBMITTED = 'Claim resubmitted',
  CLAIM_REVERSED = 'Claim reversed',
  DISCOUNT = 'Discount',
  CORRECTION_OF_ERROR = 'Correction of error',
  CASH_INVOICE_CANCELLATION = 'Cash invoice cancellation',
}

export enum TRANSACTION_TYPE {
  INVOICE = 'Invoice',
  TRANSACTION_REVERSE = 'Transaction reverse',
  LIABILITY_CHANGE = 'Liability change',
  PATIENT_PAYMENT = 'Patient payment',
  MEDICAL_AID_PAYMENT = 'Medical Aid payment',
  WRITE_OFF = 'Write off',
  CREDIT = 'Credit',
  DEBIT_NOTE = 'Debit note',
  PAYMENT_CORRECTION = 'Payment correction',
}

export enum PAYMENT_STATUS {
  VALID = 'Valid',
  REVERSED = 'Reversed',
}

export enum REFUND_STATUS {
  VALID = 'Valid',
  REVERSED = 'Reversed',
}

export interface TransactionCapture extends BaseModel {
  Id?: string;
  ProcessStatus?: ProcessStatus;
  // provide one of the following objects
  Payment?: Payment;
  PaymentUnallocate?: PaymentUnallocate;
  WriteOff?: WriteOff;
  CreditNote?: CreditNote;
  PaymentCorrection: PaymentCorrection;
  ReverseAllForInvoice?: ReverseAllForInvoice;
  LiabilityTransfer?: LiabilityTransfer;
  Reverse?: Reverse;
  Refund?: RefundRequest;
}

export interface ReverseAllForInvoice {
  InvoicePath: string;
  Reason: string;
}

export interface Payment extends BaseModel {
  Id?: string;
  PaymentDate?: Date;
  Type?: string; // of PAYMENT_TYPE or other
  ReferenceNo?: string;
  AmountPaid?: number;
  AccountNote?: string;
  Manual?: boolean; // means captured from UI
  Allocations?: Allocation[];
  // --- Back-end generated fields ---
  PaymentReceiptNo?: string; // TODO add number generation logic
  AmountUnallocated?: number;
  AmountRefunded?: number;
  InvoicePaths?: string[];
  Status?: PAYMENT_STATUS;
  ReversedAt?: Date;
  ReversedBy?: string;
  ReversalReason?: string;
  Refunds?: Refund[];
}

export interface PaymentUnallocate extends BaseModel {
  PaymentId: string;
}

export interface MAPayment extends Payment {
  RemittanceAdviceId?: string;
  RemittanceAdviceType?: REMITTANCE_ADVICE_TYPE;
  RemittanceClaimIds?: string[];
  Manual?: boolean; // means captured from UI
  SchemeCode?: string;
  SchemeName?: string;
  InsurerId?: string;
  InsurerName?: string;
}

export interface Allocation {
  InvoicePath: string;
  InvoiceLineNo: number;
  AmountAllocated: number;
  Info?: string;
  Action: BALANCE_ACTION;
  Reasons: Reason[];
  RemittanceClaimId: string; // if passed empty then new ra claim will be created
  // --- Back-end generated fields ---
  AmountSystemAllocated?: number;
  TransactionId?: string;
  InvoiceNo?: string;
}

export interface Transaction {
  Id?: string;
  TransactionType: TRANSACTION_TYPE;
  AmountPL: number;
  AmountMAL: number;
  AccountId: string; // to search and recalcualte account balance is needed
  InvoicePath: string;
  LineNo: number;
  CreatedAt: Date;
  CreatedBy: string;
  Metadata?: TransactionMetadata;
}

export interface TransactionMetadata {
  HeaderId?: string; // tx grouing Id
  HeaderDate?: Date; // Payment Date or Credit date etc.
  HeaderAmount?: number;
  HeaderAmountVAT?: number;
  InvoiceNo?: string;
  PaymentId?: string; // Candidate to remove and be replaced with HeaderId
  ReferenceNo?: string; // payment receipt no. or invoice no. etc.
  Note?: string;
  ReferenceTransactionId?: string;
  UserFullName?: string;
  InvoicePatientFullName?: string;
  CreditType?: CREDIT_TYPE;
  AmountVAT?: number;
  RemittanceId?: string;
  RemittanceClaimId?: string;
}

export interface ProcessStatus {
  Success?: boolean;
  ErrorCode?: ERROR_CODE;
  ErrorMessage?: string;
  ProcessedAt?: Date;
}

export interface WriteOff {
  Note: string;
  Allocations?: Allocation[];
}
export interface CreditNote {
  CreditType: string;
  InvoicePath: string;
  Reason: string;
  AccountNote: string;
  Allocations?: CreditNoteAllocation[];
}

export interface CreditNoteAllocation {
  InvoiceLineNo: number;
  Amount: number;
}

export interface PaymentCorrection {
  InvoicePath: string;
  Reason: string;
  AccountNote: string;
  RemittanceId: string;
  RemittanceClaimId: string;
  RemittanceClaimNote: string;
  CompleteInboxItemId: string;
  Allocations?: PaymentCorrectionAllocation[];
}

export interface PaymentCorrectionAllocation {
  InvoiceLineNo: number;
  MedicalAidLiable: number;
  PatientLiable: number;
}

export interface LiabilityTransfer {
  Reason?: string;
  InvoicePath: string;
  Lines: LiabilityTransferLine[];
}

export interface LiabilityTransferLine {
  LineNo: number;
  MedicalAidLiable: number;
  PatientLiable: number;
}

export interface Reverse {
  PaymentId?: string;
  CreditTxHeaderId?: string;
  WriteOffTxId?: string;
  PaymentCorrectionTxHeaderId?: string;
  Reason: string;
}

export interface RefundRequest {
  PaymentId?: string;
  Amount?: number;
  AccountNote?: string;
}

export interface Refund {
  Amount: number;
  AccountNote?: string;
  CreatedAt: Date;
  CreatedBy: string;
  IsValid: boolean;
}
