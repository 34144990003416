<div class="flex flex-col h-full w-full  overflow-hidden">

  <!-- Header -->
  <div
    class="flex flex-row justify-between items-center border-b border-hb-base-divider-grey !px-4 !py-1 overflow-hidden">
    <label id="dialogHeader" class="font-semibold !leading-6 text-base">{{dialogHeader}} Search</label>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="!mr-[-1px]">close</mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-1 overflow-auto">

    <!-- Selected items -->
    <div *ngIf="dialogHeader === 'Diagnosis' && selectedResultSource?.data!.length > 0"
      class="overflow-auto bg-hb-accent-5 px-6 pt-4">
      <!-- MultiSelect list -->
      <span class="!font-medium !text-sm !my-2">Selected {{pluralTypeName}}{{dialogData.isHeaderSelect ? ' (header)' :
        ''}}</span>

      <mat-table *ngIf="selectedResultSource?.data.length > 0" id='selectedTable' class="selected-results flux-table"
        [dataSource]="selectedResultSource">

        <!-- action column -->
        <ng-container matColumnDef="action">
          <mat-cell *matCellDef="let row">
            <button class="!w-6 !h-6 !leading-6" mat-icon-button (click)="onRemoveSelectedResult(row)"
              matTooltip="Remove" [matTooltipShowDelay]="1000">
              <mat-icon class="!text-[20px]">remove</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- code columm -->
        <ng-container matColumnDef="code">
          <mat-cell *matCellDef="let row">
            {{row.code}}
          </mat-cell>
        </ng-container>

        <!-- description column -->
        <ng-container matColumnDef="description">
          <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-row *matRowDef="let row; columns: ['action','code','description']"></mat-row>
      </mat-table>

      <ng-container *ngVar="diagnosisEngineMessages$ | async as diagMessages">
        <meraki-flux-info-box class="my-2" [icon]="'report_problem'" [type]="'DANGER'" *ngIf="diagMessages?.length > 0">
          <ul>
            <li><strong>Diagnosis code errors</strong></li>
            <li *ngFor="let message of diagMessages">{{message}}</li>
          </ul>
        </meraki-flux-info-box>
      </ng-container>

    </div>

    <!-- Search -->
    <div class="flex row pt-4 px-6 pb-1">
      <mat-form-field class="flex-1 flux-form-field" appearance="fill">
        <mat-icon matTooltip="Search on code or description" matPrefix>search</mat-icon>
        <mat-label>Search for a {{dialogHeader?.toLowerCase()}}</mat-label>
        <input id='searchField' #search matInput placeholder="Search to add {{dialogHeader?.toLowerCase()}}"
          [formControl]="searchCtrl" cdkFocusInitial>
        <button *ngIf="search.value" (click)="onClearSearch()" matSuffix mat-icon-button>
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="flex-1 overflow-auto px-6">
      <!-- Search results -->
      <div *ngIf="searchBusy$ | async" class="flex flex-row w-full justify-center py-4">
        <mat-spinner diameter="20"></mat-spinner>
        <span class="ml-2">Searching...</span>
      </div>


      <mat-table id='resultTable' *ngIf="(searchBusy$ | async) === false && searchResultSource?.data?.length > 0"
        class="search-results flux-table" [dataSource]="searchResultSource">

        <!-- add columm -->
        <ng-container matColumnDef="add">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngVar="isCodeSelected(row.code) as selected">
              <button [disabled]="selected" class="!w-6 !h-6 !leading-6" mat-icon-button (click)="onSelectResult(row)"
                [matTooltip]="getAddTooltip(selected)" [matTooltipShowDelay]="1000">
                <mat-icon *ngIf="!selected" class="!text-[20px] !text-hb-success-100">add</mat-icon>
                <mat-icon *ngIf="selected" class="!text-[20px] !text-hb-text-disabled">check</mat-icon>
              </button>
            </ng-container>
          </mat-cell>
        </ng-container>


        <!-- code columm -->
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef>{{codeColumnHeader}}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span [innerHtml]="row.displayCode"></span>
          </mat-cell>
        </ng-container>

        <!-- description column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell *matCellDef="let row"><span [innerHtml]="row.displayDescription"></span></mat-cell>
        </ng-container>

        <!-- favourite column -->
        <ng-container matColumnDef="favourite">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngIf="dialogData.selectedProviderId">
              <ng-container *ngVar="isFavourite(row.code) as isFavourite">
                <button class="!w-6 !h-6 !leading-6" mat-icon-button [matTooltip]="isFavourite ? 'Remove from favourites' : 'Add to favourites'" [matTooltipShowDelay]="1000" (click)="onFavouriteClicked(row)">
                  <mat-icon [fontSet]="isFavourite ? '' : 'material-icons-outlined'" class="!text-[20px]"
                    [class.!text-[22px]]="isFavourite" [class.!text-hb-text-subdued]="!isFavourite"
                    [class.!text-hb-warning-100]="isFavourite">
                    grade
                  </mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="['add','code','description','favourite']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['add', 'code','description','favourite']"></mat-row>
        <div class="flex-1 p-4 text-center" *matNoDataRow>No results</div>
      </mat-table>

      <mat-paginator *ngIf="searchResultSource?.data?.length > 0" appPagination class="flux-paginator" #resultPaginator
        [length]="totalSearchResults$ | async" [pageSize]="RESULT_PAGE_LIMIT" [pageIndex]="currentPage$ | async"
        (page)="onPageChanged($event)"></mat-paginator>

    </div>

    <!-- Actions -->
    <div
      class="flex flex-row justify-end align-middle px-4 py-1 border-t border-t-hb-base-divider-grey overflow-hidden gap-2">
      <button class="flux-button" mat-stroked-button mat-dialog-close>Close</button>
      <button id='save' *ngIf="dialogData.allowMultiSelect" class="flux-button" mat-flat-button color="primary"
        (click)="onSave()">
        Save
      </button>
    </div>

  </div>
</div>
