import {AccountDetailsData, BasePdfReportData, ReportContent} from "@meraki-flux/schema";
import { BasePdfGenerator, PDFMakeHelper } from '@meraki-flux/purejs';
import generateItalicFilledHeader = PDFMakeHelper.generateItalicFilledHeader;
import generateClaimDetailsDataTable = PDFMakeHelper.generateClaimDetailsDataTable;

/*
  Helper module to generate some common pdf structures.
*/
export class AccountDetailsPdfGenerator extends BasePdfGenerator {

  private readonly accountDetailsData: AccountDetailsData;
  private readonly patientDisclaimer: string;

  public override generateReport(): ReportContent {
    this.generateReportName();
    this.generateItalicFilledLine('Patient')
    this.generatePatientSection();
    this.generateItalicFilledLine('Account Details')
    this.generateAccountDetailsSection();
    this.generateItalicFilledLine('Main Member')
    this.generateMainMemberSection();
    if (this.data.tables && this.data.tables.length > 0 && this.data.tables[0].rows && this.data.tables[0].rows.length > 0) {
      this.generateItalicFilledLine('Next of Kin')
      this.content.push({text:"\n"})
      this.generateTables();
    }
    if (this.patientDisclaimer && this.patientDisclaimer.trim() !== '') {
      this.content.push({text:"\n"})
      this.content.push({text:"\n"})
      this.content.push({text:"\n"})
      this.content.push({text:"\n"})
      this.content.push({text: this.patientDisclaimer})
      this.content.push({text:"\n"})
      this.content.push({text:"\n"})
      this.content.push({text:"Patient signature:         ________________________________________________________        " +
          "Date:    _________________________"})
    }
    return this.buildReport();
  }

  private generateItalicFilledLine(text: string) {
    this.content.push(generateItalicFilledHeader(text));
  }

  private generatePatientSection() {
    if (this.accountDetailsData && this.accountDetailsData.patient) {
      this.content.push(generateClaimDetailsDataTable(this.accountDetailsData.patient))
    }
  }

  private generateAccountDetailsSection() {
    if (this.accountDetailsData && this.accountDetailsData.accountDetails) {
      this.content.push(generateClaimDetailsDataTable(this.accountDetailsData.accountDetails))
    }
  }

  private generateMainMemberSection() {
    if (this.accountDetailsData && this.accountDetailsData.mainMember) {
      this.content.push(generateClaimDetailsDataTable(this.accountDetailsData.mainMember))
    }
  }

  constructor(data: BasePdfReportData) {
    super(data)
    this.accountDetailsData = data.accountDetailsData;
    this.patientDisclaimer = data.disclaimer;
  }
}

