import {BaseModel} from "./general.models";
import {DOSAGE_TYPE, INVOICE_LINE_TYPE} from "./invoice.models";

export class InvoiceTemplateData {
    type?: INVOICE_TEMPLATE_TYPE;
    invoiceTemplate?: InvoiceTemplate;
    providerId?: string;
    isFavourite?: boolean;
    maxOrderNo?: number;
}
export interface InvoiceTemplate extends BaseModel{
    Id?: string,
    Caption?: string,
    Description?: string,
    Diagnosis?: Diagnosis[],
    IsActive?: boolean,
    IsGroup?: boolean,
    Templates?: InvoiceTemplate[],
    Lines?: TemplateLine[],
    Prescriptions?: InvoicePrescription,
    SortOrder?: number,
    Type?: INVOICE_TEMPLATE_TYPE,
}

export enum INVOICE_TEMPLATE_TYPE {
    DIAGNOSIS = 'Diagnosis',
    PROCEDURE = 'Procedure',
    MEDICINE = 'Medicine',
    CONSUMABLE = 'Consumable',
    INVOICE = 'Invoice',
}

export enum CHARGE_UNITS_TYPE {
    SECONDS = 'Seconds',
    MINUTES = 'Minutes',
    HOURS = 'Hours',
    DAYS = 'Days',
}

export enum DURATION_TYPE {
    SECOND = 'Second',
    MINUTE = 'Minute',
    HOUR = 'Hour',
    DAY = 'Day',
}

export interface Diagnosis {
    Code: string,
    Description: string,
    OrderNo: number
}

export interface TemplateLine {
    AdditionalInstructions?: string,
    ChargeCode?: string,
    Quantity?: number,
    ChargeUnitsType?: CHARGE_UNITS_TYPE,
    ChronicIndicator?: boolean,
    Description?: string,
    Diagnoses?: Diagnosis[],
    Dispense?: boolean,
    DosageType?: DOSAGE_TYPE,
    DosageUnit?: number,
    DurationType?: DURATION_TYPE,
    DurationUnit?: number,
    FrequencyUnit?: number,
    Instructions?: string[],
    LineNumber?: number,
    LineType?: INVOICE_LINE_TYPE,
    NappiCode?: string,
    PeriodType?: DURATION_TYPE,
    PeriodUnit?: number,
    PriceOverride?: boolean,
    Repeat?: number,
    Route?: string,
    TotalIncVat?: number,
    UserDefinedDosageType?: string
}

export interface InvoicePrescription {
    ChronicIndicator?: boolean,
    Diagnoses?: Diagnosis[],
    DispensingInstructions?: string,
    DosageType?: DOSAGE_TYPE,
    DosageUnit?: number,
    DurationType?: DURATION_TYPE,
    DurationUnit?: number,
    FrequencyUnit?: number,
    LineNumber?: number,
    MedicationDescription?: string,
    NappiCode?: string,
    PeriodType?: DURATION_TYPE,
    PeriodUnit?: number,
    Repeat?: number,
    UserDefinedDosageType?: string
}
