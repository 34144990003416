import { QueryConstraint, where } from '@angular/fire/firestore';
import {TRANSACTION_TYPE} from "@meraki-flux/schema";

export class TransactionWhereBuilder {
  wheres: QueryConstraint[];

  constructor(wheres: QueryConstraint[]) {
    this.wheres = wheres;
  }

  static builder(): TransactionWhereBuilder {
    return new TransactionWhereBuilder([]);
  }

  build() {
    return this.wheres;
  }

  createdAtGOE(date: Date): TransactionWhereBuilder {
    if (date) this.wheres.push(where('CreatedAt', '>=', date));
    return this;
  }

  createdAtLOE(date: Date): TransactionWhereBuilder {
    if (date) this.wheres.push(where('CreatedAt', '<=', date));
    return this;
  }

  type(type: TRANSACTION_TYPE): TransactionWhereBuilder {
    if (type) this.wheres.push(where('TransactionType', '==', type));
    return this;
  }

  typeIn(types: TRANSACTION_TYPE[]): TransactionWhereBuilder {
    if (types && types.length > 0) this.wheres.push(where('TransactionType', 'in', types));
    return this;
  }
}
