export interface Address {
  Line1?: string;
  Line2?: string;
  Line3?: string;
  Code?: string;
}

export interface BankDetails {
  AccountHolderName?: string;
  AccountNo?: string;
  AccountType?: BANK_ACCOUNT_TYPE | string;
  Bank?: BANK_NAME | string;
  BranchCode?: string;
}

export interface AdditionalEmails {
  Description1?: string;
  Description2?: string;
  Description3?: string;
  Address1?: string;
  Address2?: string;
  Address3?: string;
}

export interface AdditionalPhones {
  Description1?: string;
  Description2?: string;
  Description3?: string;
  Phone1?: string;
  Phone2?: string;
  Phone3?: string;
}

export interface Country {
  Name?: string;
  Alpha2?: string;
  Alpha3?: string;
}

export interface BaseModel {
  UpdatedAt?: Date;
  UpdatedBy?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
}

export interface Auditable {
  Audits?: Audit[];
}

export interface Audit {
  Date?: Date;
  TriggeredBy?: string;
  TriggeredFrom?: string;
}

export interface NexBaseResponse<T> {
  data?: {
    payload?: T;
    correlationId: string;
  };
  error?: {
    message?: string;
    correlationId: string;
  };
}

export interface Person {
  Title?: string;
  Name?: string;
  Surname?: string;
  Initials?: string;
  Gender?: string;
  IdentityNo?: string;
  DateOfBirth?: Date;
  Contact?: Contact;
  FileNo?: string;
}

export interface Contact {
  PostalAddress?: Address;
  PhysicalAddress?: Address;
  TelephoneWork?: string;
  TelephoneHome?: string;
  Cellphone?: string;
  Email?: string;
}

export interface Entity {
  _id?: PropertyKey; // case sensitive, used for entity store.
}

export interface Balance {
  Outstanding?: number;
  PatientLiable?: number;
  MedicalAidLiable?: number;
  Date?: Date;
}

export class Speciality {
  Name?: string;
  Code?: string;
}

export class NameCode {
  Name?: string;
  Code?: string;
}

export class SPECIALITY {
  static GP_014 = '014';
  static GP_015 = '015';
  static OPTOMETRIST = '070';
}

export enum VISIT_TYPE {
  REGULAR_CONSULTATION = 'Regular consultation',
  MEDICAL_INSURANCE = 'Medical insurance',
  VIDEO_CONSULT = 'Video consult',
  TELEPHONIC_CONSULT = 'Telephonic consult',
  NO_CHARGE = 'No charge (follow-up)',
  OUT_OF_ROOMS = 'Out of rooms',
  CUSTOM = 'Custom',
}

export enum BENEFIT_CHECK_STATUS {
  NOT_TRIGGERED = 'No BC triggered',
  UNKNOWN_RESPONSE = 'Unknown response',
  CALL_CENTRE_UNAVAILABLE = 'Call centre unavailable',
  FULLY_COVERED = 'Fully covered',
  PART_COVERED = 'Partially covered',
  NOT_COVERED = 'Not covered',
  REJECTED = 'Rejected',
  AWAITING_RESPONSE = 'Awaiting response',
  FAULT = 'Request fault',
  NOT_SUPPORTED = 'Not supported',
  INFORMATION_AVAILABLE = 'Information available',
  INVALID_PATIENT_DATA = 'Invalid patient data',
  AWAITING_TRIGGER = "Awaiting trigger time"
}

export enum BANK_ACCOUNT_TYPE {
  CHEQUE = 'Cheque',
  SAVINGS = 'Savings',
  TRANSMISSION = 'Transmission',
}

export enum BANK_NAME {
  POST_BANK = 'Post Bank',
  INVESTEC_BANK = 'Investec Bank',
  MERCANTILE_BANK = 'Mercantile Bank',
  FNB = 'FNB',
  CAPITEC = 'Capitec',
  STANDARD_BANK = 'Standard Bank',
  BANK_OF_AHTENS = 'Bank of Athens',
  OTHER = 'Other',
  NEDBANK = 'Nedbank',
  ABSA = 'ABSA',
  BIDVEST = 'Bidvest',
  ALBARAKA_BANK = 'Albaraka Bank',
  SASFIN_BANK = 'Sasfin Bank',
  AFRICAN_BANK = 'African Bank',
  FIRSTRAND = 'FirstRand',
  IMPERIAL = 'Imperial',
  TEBA = 'Teba',
  DISCOVERY_BANK = 'Discovery Bank',
}

export enum CALENDAR_EVENT_VIEW {
  DETAILED = 1,
  COMPACT = 2,
  DENSE = 3,
}

export enum CALENDAR_INTERVAL_SIZE {
  '10M',
  '15M',
  '20M',
  '30M',
}

export enum PROVIDER_SELECTION_TYPE {
  MULTI,
  SINGLE,
}

export enum INTERACTOR_TYPE {
  USER = 'User',
  SYSTEM = 'System',
}

export enum BC_GENDER {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
}

export enum SOURCE_SYSTEM {
  NOVA = "Nova",
  HBC = "HBC",
}

export enum YES_NO_TYPE {
  YES = 'Yes',
  NO = 'No'
}
