import {
  IncompleteContactDetailsReportRequest, NoDataError
} from "@meraki-flux/schema";
import * as _ from "lodash";

export class IncompleteContactDetailsReportBuilder {

  async build(reportRequest: IncompleteContactDetailsReportRequest): Promise<IncompleteContactDetailsReportRequest> {
    const hasData = reportRequest?.Details?.length > 0;
    if (!hasData) {
      throw new NoDataError();
    }
    reportRequest.Details = _.sortBy(reportRequest.Details, "AccountNo", "Surname", "Name")
    return reportRequest;
  }
}
