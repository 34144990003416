import {CLAIM_STATUS} from "./claim.models";

export interface NotificationMessage {
  Type: Type,
  ClaimStatusNotification?: ClaimStatusNotification,
  ReportStatusNotification?: ReportStatusNotification,
}

export interface ClaimStatusNotification {
  ClaimStatus?: CLAIM_STATUS,
  PatientFullName?: string,
  Scheme?: string,
  ClaimedAmount?: number,
  AcceptedAmount?: number,
  BalanceAmount?: number,
  InboxItemId?: string,
  AccountNo?: string
}

export interface ReportStatusNotification {
  Title: string,
  IsError?: boolean,
  Body?: string,
}

// pls don't forget to keep firebase-messaging-sw.js updated
export enum Type {
  CLAIM_STATUS_NOTIFICATION = 'ClaimStatusNotification',
  REPORT_STATUS_NOTIFICATION = 'ReportStatusNotification',
}

export interface RegistrationToken {
  Token?: string
  UserId?: string;
  UpdatedAt?: Date
  SelectedBPN?: string;
}

