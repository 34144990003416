import moment from 'moment';
import {
  ClaimSummaryItem, INVOICE_SUBTYPE,
  NoDataError, UnroutableClaimDSData, UnroutableClaimsDataExtractionList,
  UnroutableClaimsReportModel,
  UnroutableClaimsReportRequest,
  UnroutableReportInfo,
  UnroutableReportModel
} from "@meraki-flux/schema";

export class UnroutableClaimsReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: UnroutableClaimsReportRequest): Promise<UnroutableClaimsReportModel> {
    const reportHeader: UnroutableReportInfo = await this.buildReportInfo(reportRequest);
    const claimModel: UnroutableReportModel = await this.buildClaimModel(reportRequest);

    this.checkDataAvailability(claimModel);

    return {
      ReportInfo: reportHeader,
      UnroutableReportModel: claimModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

  private checkDataAvailability(claimModel: UnroutableReportModel) {
    let noData = true;
    if (claimModel.UnroutableClaimDSData.length > 0) {
      noData = false;
    }

    if (noData) throw new NoDataError();
  }

  private async buildReportInfo(
    reportRequest: UnroutableClaimsReportRequest
  ): Promise<UnroutableReportInfo> {
    const reportInfo: UnroutableReportInfo = {};

    reportInfo.Practice =
      reportRequest.Practice?.PracticeName +
      ' (' +
      reportRequest.Practice?.BillingPracticeNumber +
      ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;

    if (reportRequest.Practice?.IsMultiBranch === true){
      if(reportRequest.Branch){
        reportInfo.Branch = reportRequest.Branch;
      }else{
        reportInfo.Branch='All';
      }
    }

    reportInfo.DateRange =
      moment(reportRequest.DateFrom).format('YYYY-MM-DD') +
      ' - ' +
      moment(reportRequest.DateTo).format('YYYY-MM-DD');
    reportInfo.DateRangeType = reportRequest.DateRangeType;
    reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;

    return reportInfo;
  }

  private async buildClaimModel(
    reportRequest: UnroutableClaimsReportRequest
  ): Promise<UnroutableReportModel> {
    const detailsItem: UnroutableClaimDSData[] = this.buildUnroutableClaimsDetails(
      reportRequest.UnroutableClaimsList
    );
    const claimSummaryItem: ClaimSummaryItem[] = this.buildUnroutableClaimsSummaryItem(detailsItem);

    return {
      UnroutableClaimDSData: detailsItem,
      ClaimSummaryItem: claimSummaryItem,
    };
  }

  private buildUnroutableClaimsDetails(urClaimList: UnroutableClaimsDataExtractionList[]): UnroutableClaimDSData[] {

    const detailtems: UnroutableClaimDSData[] = [];

    if (urClaimList !== undefined || urClaimList !== null) {
      for (const urClaim of urClaimList) {
        if (urClaim.InvoiceDataSet.Subtype !== undefined) {

          const medInsurerOrScheme =
            urClaim.InvoiceDataSet.Subtype != INVOICE_SUBTYPE.MEDICAL_INSURER
              ? urClaim.InvoiceDataSet.Account?.SchemeName
              : urClaim.InvoiceDataSet.MedicalInsurer?.Name;

          const schemeOrPolicy =
            urClaim.InvoiceDataSet.Subtype != INVOICE_SUBTYPE.MEDICAL_INSURER
              ? urClaim.InvoiceDataSet.Account?.MemberNo
              : urClaim.InvoiceDataSet.PolicyNo;

          const detailsObj = {
            AccountNo: urClaim.InvoiceDataSet.Account?.AccountNo,
            PatName: urClaim.InvoiceDataSet.Patient?.Name,
            PatSurname: urClaim.InvoiceDataSet.Patient?.Surname,
            PatientIdNumber: urClaim.InvoiceDataSet.Patient?.IdentityNo,
            InvoiceType: urClaim.InvoiceDataSet.Type,
            InvoiceNum:
              urClaim.InvoiceDataSet.InvoiceNo != undefined ? urClaim.InvoiceDataSet.InvoiceNo : '',
            Scheme: medInsurerOrScheme != undefined ? medInsurerOrScheme : '', // do a check for insurer here
            Plan: urClaim.InvoiceDataSet.Account?.PlanName,
            Option: urClaim.InvoiceDataSet.Account?.OptionName,
            TreatingProvider: urClaim.TreatingProvider,
            MemberNum: schemeOrPolicy, //do a check for policy number here
            ClaimStatus: urClaim.InvoiceDataSet.ClaimInfo?.ClaimStatus,
            DateOfService: urClaim.InvoiceDataSet.DateOfService
              ? moment(
                  this.convertTimestampToDateTime(urClaim.InvoiceDataSet.DateOfService)
                ).format(this.DATE_FORMAT)
              : '',
            DateOfSubmission: urClaim.InvoiceDataSet.DateOfSubmission
              ? moment(
                  this.convertTimestampToDateTime(urClaim.InvoiceDataSet.DateOfSubmission)
                ).format(this.DATE_FORMAT)
              : '',
            InvoicedAmount: Number(urClaim.InvoiceDataSet.AmountBilled || 0),
            MedAidLiable: Number(urClaim.InvoiceDataSet.Balance?.MedicalAidLiable || 0),
            PatLiable: Number(urClaim.InvoiceDataSet.Balance?.PatientLiable || 0),
            PlaceOfService: urClaim.InvoiceDataSet.PlaceOfService,
            Location: urClaim.InvoiceDataSet.Location,
            CreatedBy: urClaim.CreatedBy,
            Branch: urClaim.InvoiceDataSet.Branch,
          };

          detailtems.push(detailsObj);
        }
      }
    }
    return detailtems;
  }

  private buildUnroutableClaimsSummaryItem(
    detailItems: UnroutableClaimDSData[]
  ): ClaimSummaryItem[] {
    const summaryTable: ClaimSummaryItem[] = [];
    for (const detailItem of detailItems) {
      const schemeName: string = detailItem.Scheme;

      let summaryItem: ClaimSummaryItem | undefined = summaryTable.find(
        (item) => item.Scheme === schemeName
      );
      if (!summaryItem) {
        summaryItem = {
          Scheme: schemeName,
          NoOfClaims: 0,
          TotalInvoicedValue: 0,
          TotalMALValue: 0,
          TotalPALValue: 0,
        };
        summaryTable.push(summaryItem);
      }
      summaryItem.NoOfClaims += 1;
      summaryItem.TotalInvoicedValue += detailItem.InvoicedAmount;
      summaryItem.TotalMALValue += detailItem.MedAidLiable;
      summaryItem.TotalPALValue += detailItem.PatLiable;
    }
    return summaryTable;
  }

  private convertTimestampToDateTime(val: any): Date {
    if (val) {
      const seconds = val.seconds;
      const nanoseconds = val.nanoseconds;
      const milliseconds = seconds * 1000 + nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date;
    }

    return null;
  }
}
