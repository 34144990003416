<div class="notification-container flex flex-col info" [ngClass]="typeClass">

  <!-- header -->
  <div class="notification-header flex flex-row w-full gap-3 p-2 content-center" [ngClass]="typeClass">
      <mat-icon class="status-color info" [ngClass]="typeClass">{{typeIcon}}</mat-icon>
      <span class="status-color grow items-center font-bold display: grid" [ngClass]="typeClass">Claim alert</span>
      <mat-icon class="status-color info hover:bg-hb-base-base-grey cursor-pointer" [ngClass]="typeClass" (click)="onClose()">close</mat-icon>
  </div>

  <!-- content -->
  <div class="notification-content flex flex-col w-full gap-3 p-2" >
    <div class="flex flex-row w-full">
      <span class="text-hb-text-default w-[100px]">Status</span>
      <span class="status-color font-bold" [ngClass]="typeClass">{{data?.ClaimStatusNotification?.ClaimStatus}}</span>
    </div>
    <div class="flex flex-row w-full">
      <span class="text-hb-text-default w-[100px]">Patient</span>
      <span class="text-hb-text-default font-bold">{{data?.ClaimStatusNotification?.PatientFullName}}</span>
    </div>
    <div class="flex flex-row w-full">
      <span class="text-hb-text-default w-[100px]">Scheme</span>
      <span class="text-hb-text-default font-bold">{{data?.ClaimStatusNotification?.Scheme}}</span>
    </div>
    <div class="flex flex-row w-full">
      <span class="text-hb-text-default w-[100px]">Claimed</span>
      <span class="text-hb-text-default font-bold">{{data?.ClaimStatusNotification?.ClaimedAmount/100 | currency:'R ':'symbol-narrow':'1.2-2'}}</span>
    </div>
    <div class="flex flex-row w-full" *ngIf="acceptedClaimStatuses.includes(data?.ClaimStatusNotification?.ClaimStatus)">
      <span class="text-hb-text-default  w-[100px]">Accepted</span>
      <span class="font-bold accepted-text">{{data?.ClaimStatusNotification?.AcceptedAmount/100 | currency:'R ':'symbol-narrow':'1.2-2'}}</span>
    </div>
    <div class="flex flex-row w-full" *ngIf="balanceClaimStatuses.includes(data?.ClaimStatusNotification?.ClaimStatus)">
      <span class="text-hb-text-default  w-[100px]">Balance</span>
      <span class="font-bold balance-text">{{data?.ClaimStatusNotification?.BalanceAmount/100 | currency:'R ':'symbol-narrow':'1.2-2'}}</span>
    </div>
  </div>

  <!-- action  -->
  <div class="notification-action flex flex-row w-full gap-3 p-2 content-center" *ngIf="isActionVisible()">
    <span class="grow items-center display: grid text-right bottom-action hover: cursor-pointer" (click)="onAction()">{{actionName}}</span>
    <mat-icon class="bottom-action hover:bg-hb-base-base-grey cursor-pointer" (click)="onAction()">arrow_forward</mat-icon>
  </div>
</div>
