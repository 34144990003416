export interface IntegrationRequest {
  PracticeId: string;
  ReferenceId: string;
  Type: INTEGRATION_REQUEST_TYPE;
  Timestamp: number;
}

export interface IntegrationRecord {
  SuccessRequestBody: any;
  SuccessTimestamp: number;
}

export interface IntegrationRecordLog {
  Request: IntegrationRequest;
  Response: IntegrationRequestResponse;
  Responses: IntegrationRequestResponse[];
  RunCount: number;
}

export interface IntegrationRequestResponse {
  RequestBody?: any;
  ResponseBody?: any;
  Status: INTEGRATION_REQUEST_STATUS;
  Timestamp: Date;
  Info?: string;
}

export enum INTEGRATION_REQUEST_STATUS {
  SUCCESS = "Success",
  FAILED = "Failed",
  CANCELLED = "Cancelled",
}

export enum INTEGRATION_REQUEST_TYPE {
  FLORENCE__PROVIDER_CREATE = "Florence/ProviderCreate",
  FLORENCE__PROVIDER_UPDATE = "Florence/ProviderUpdate",
  FLORENCE__PROVIDER_DISABLE = "Florence/ProviderDisable",
  FLORENCE__PROVIDER_ENABLE = "Florence/ProviderEnable",
}
