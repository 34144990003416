import { Injectable } from "@angular/core";
import { AnalyticsCallOptions, getAnalytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private analytics;
  constructor() {
    this.analytics = getAnalytics();
  }

  logEvent(eventName: string, eventParams?: { [key: string]: any; }, options?: AnalyticsCallOptions) {
    logEvent(this.analytics, eventName, eventParams, options);
  }
}