import { Injectable } from '@angular/core';
import { collection, doc, Firestore, serverTimestamp, WriteBatch } from '@angular/fire/firestore';
import { uuidv4 } from '@firebase/util';
import {Note, NOTE_ORIGIN_TYPE} from '@meraki-flux/schema';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(
    private firestore: Firestore,
    private fs: FirestoreService,
    public auth: AuthService,
    private dialogService: DialogService
  ) {}

  async addNote(note: Note, path: string) {
    try {
      await this.fs.addDoc(collection(this.firestore, path), note);
    } catch (err) {
      this.dialogService.showErrorMessage('Failed to add note to: ' + path);
    }
  }

  protected buildUserNote(noteText: string) {
    return this.buildNote(noteText, NOTE_ORIGIN_TYPE.USER);
  }

  protected buildSystemNote(noteText: string) {
    return this.buildNote(noteText, NOTE_ORIGIN_TYPE.USER);
  }

  protected buildNote(noteText: string, noteOriginType: NOTE_ORIGIN_TYPE) {
    return {
      Note: noteText,
      Pinned: false,
      OriginType: noteOriginType,
      CreatedBy: this.auth.uid,
      CreatedAt: serverTimestamp(),
    } as unknown as Note;
  }

  // *** Account Notes ***

  getAccountNotesCollectionRef(accountId: string) {
    return PathUtils.accountNoteCollectionPath(this.auth.selectedBPN, accountId);
  }

  async addAccountNote(note: Note, accountId: string) {
    this.addNote(note, this.getAccountNotesCollectionRef(accountId));
  }

  async addAccountUserTextNote(noteText: string, accountId: string) {
    this.addAccountNote(this.buildUserNote(noteText), accountId);
  }

  // *** Invoice Notes ***

  getInvoiceNotesCollectionRef(invoiceId: string) {
    return PathUtils.invoiceNoteCollectionPath(this.auth.selectedBPN, invoiceId);
  }

  async addInvoiceNote(note: Note, invoiceId: string) {
    this.addNote(note, this.getInvoiceNotesCollectionRef(invoiceId));
  }

  async addInvoiceUserTextNote(noteText: string, invoiceId: string) {
    this.addInvoiceNote(this.buildUserNote(noteText), invoiceId);
  }

  protected addAccountNoteToBatch(
    batch: WriteBatch,
    accountId: string,
    noteText: string,
    origin: NOTE_ORIGIN_TYPE
  ) {
    const note = this.buildNote(noteText, origin);
    const path = PathUtils.accountNotePath(this.auth.selectedBPN, accountId, uuidv4());
    batch.set(doc(this.firestore, path), note);
  }

  addSystemAccountNoteToBatch(batch: WriteBatch, accountId: string, noteText: string) {
    return this.addAccountNoteToBatch(batch, accountId, noteText, NOTE_ORIGIN_TYPE.SYSTEM);
  }

  addUserAccountNoteToBatch(batch: WriteBatch, accountId: string, noteText: string) {
    return this.addAccountNoteToBatch(batch, accountId, noteText, NOTE_ORIGIN_TYPE.USER);
  }
}

export { Note };
