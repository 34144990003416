import { MatDialogConfig } from "@angular/material/dialog";
import {SearchDialogData} from "@meraki-flux/schema";

export class DialogUtils {

    static codeSearchDialogParams(data: SearchDialogData) {
        return {
            data,
            restoreFocus: false,
            width: '800px',
            height: '700px',
            panelClass: 'base-dialog',
            disableClose: true
        } as MatDialogConfig
    }
}
