import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { collection, collectionData, collectionSnapshots, doc, docData, documentId, Firestore, orderBy, query, Timestamp, where } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  Account,
  Business, DialogButtonStyle, DialogType,
  Note,
  NOTE_FILTER_TYPE,
  NOTE_ORIGIN_TYPE,
  NOTE_TYPE, NoteModalData,
  PracticeProvider,
  User
} from "@meraki-flux/schema";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { AccountRepository } from '../../../repositories/account.repository';
import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { FirestoreService } from "../../../services/firestore.service";
import { LogService } from '../../../services/log.service';
import { NotesService } from "../../../services/notes.service";

declare type NoteTableData = Note & {
  Date: Date,
  CreatedByName: string,
  ModifiedAt: Date,
  ModifiedBy: string,
  EditMode: boolean
}

// TODO: Implement 'ShowNotes' functionality outside of component
// TODO: Add invoice notes menu option to: The dot menu (Notes option) on the Invoices screen.
// TODO: Add invoice notes menu option to: Notes menu item in the right-hand menu of the Invoice Details screen.

@Component({
  selector: 'meraki-flux-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})

@UntilDestroy()
export class NoteModalComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren("editNoteInput") editNoteInput!: QueryList<ElementRef>;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<NoteTableData>();
  noteType = NOTE_TYPE
  noteOriginType = NOTE_ORIGIN_TYPE;
  collectionRef: string;
  orderByField = 'CreatedAt';
  account: Account;

  filterTypes = [
    NOTE_FILTER_TYPE.ALL_USERS,
    NOTE_FILTER_TYPE.ALL_USERS_AND_SYSTEM,
    NOTE_FILTER_TYPE.SYSTEM,
  ];

  displayedColumns = [
    'Pin',
    'Date',
    'CreatedBy',
    'Note',
    'LastModified',
    'Edit'
  ];

  form = this.fb.group({
    note: ['', Validators.required],
    pinned: [false],
    filterType: [NOTE_FILTER_TYPE.ALL_USERS],
  });

  showNoteControl = new UntypedFormControl(false);

  state$ = new BehaviorSubject<'ready' | 'saving' | 'validating'>('ready');
  notes$: Observable<NoteTableData[]>
  notesFiltered$: Observable<NoteTableData[]>;

  users$ = this.auth.user$.pipe(
    switchMap(user => docData<Business>(doc(this.firestore, `Business/${user.SelectedBusinessId}`))),
    switchMap(business =>
      business.Users
        ? combineLatest([
          ...business.Users.map((userId: string) =>
            docData<User>(doc(this.firestore, `User/${userId}`), { idField: 'uid' }).pipe(
              switchMap(practiceUser => {
                  if (practiceUser.ProviderId) {
                    return docData<PracticeProvider>(doc(this.firestore, `Practice/${business.BillingPracticeNumber}/Provider/${practiceUser.ProviderId}`))
                      .pipe(
                        map(practiceProvider => ({
                          ...practiceUser,
                          IsActive: practiceProvider?.IsActive,
                          Name: practiceProvider?.Name,
                          Surname: practiceProvider?.Surname,
                        }))
                      );
                  } else return of(practiceUser)
              })
            )
          )
        ]).pipe(
          map((Users) => Users.filter((i) => i.IsActive === true))
        )
        : of([])),
  );

  constructor(
    public auth: AuthService,
    public dialogRef: MatDialogRef<NoteModalComponent>,
    private fb: UntypedFormBuilder,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private dialogService: DialogService,
    private fs: FirestoreService,
    private noteService: NotesService,
    private accountRepository: AccountRepository,
    private logger: LogService,
    @Inject(MAT_DIALOG_DATA) public data: NoteModalData,
  ) {}

  ngOnInit(): void {
    // Initialize collection location depending on note type.
    switch(this.data.Type) {
      case NOTE_TYPE.ACCOUNT:
        this.collectionRef = this.noteService.getAccountNotesCollectionRef(this.data.AccountId);
        this.orderByField = 'Pinned';
        this.account = this.accountRepository.getAccount(this.data.AccountId) || this.data.Account;
        this.showNoteControl.setValue(!!this.account.ShowNoteModalOnOpen);
        break;
      case NOTE_TYPE.INVOICE:
        this.collectionRef = this.noteService.getInvoiceNotesCollectionRef(this.data.InvoiceId);
        this.orderByField = 'CreatedAt'
        this.displayedColumns = this.displayedColumns.filter(col => col !== 'Pin');
        break;
      default:
        this.collectionRef = '';
        this.logger.error('Invalid note type');
    }

    this.notes$ = collectionData(
      query(
        collection(this.firestore, this.collectionRef),
        orderBy(this.orderByField, 'desc'),
      ),
      { idField: 'Id' }
    ).pipe(
      switchMap(notes => {
        const dedupedUsers = new Set([...notes.map(note => note.CreatedBy), ...notes.map(note => note.UpdatedBy)].filter((u: Note) => !!u));
        if(dedupedUsers.size > 0) {
          return collectionSnapshots(
            query(
              collection(this.firestore, `User`),
              where(documentId(), 'in', Array.from(dedupedUsers))
            )
          ).pipe(
            map((users) => {
              return notes.map(note => {
                const createdUser = users.find((u) => u.id === note.CreatedBy)?.data();
                const updatedUser = users.find((u) => u.id === note.UpdatedBy)?.data();
                return {
                  ...note as Note,
                  Date: (<Timestamp>note?.CreatedAt)?.toDate(),
                  CreatedByName: `${createdUser?.Name} ${createdUser?.Surname}`,
                  ModifiedAt: (<Timestamp>note?.UpdatedAt)?.toDate(),
                  ModifiedBy: `${updatedUser?.Name} ${updatedUser?.Surname}`,
                  EditMode: false,
                } as NoteTableData
              })
            })
          )
        } else {
          return []
        }
      }),
    )

    // Filter retrieved notes based on filter type selection
    this.notesFiltered$ = combineLatest([
      this.form.valueChanges.pipe(
        startWith({ filterType: NOTE_FILTER_TYPE.ALL_USERS }),
      ),
      this.notes$,
    ]).pipe(map(([form, notes]) => {
      return notes.filter(
        (note: Note) => {
          switch (form.filterType) {
            case NOTE_FILTER_TYPE.ALL_USERS: {
              return note.OriginType === NOTE_ORIGIN_TYPE.USER;
            }
            case NOTE_FILTER_TYPE.ALL_USERS_AND_SYSTEM: {
              return note.OriginType === NOTE_ORIGIN_TYPE.USER || note.OriginType === NOTE_ORIGIN_TYPE.SYSTEM;
            }
            case NOTE_FILTER_TYPE.SYSTEM: {
              return note.OriginType === NOTE_ORIGIN_TYPE.SYSTEM;
            }
            default: {
              return (form.filterType.uid === note.CreatedBy) && (note.OriginType === NOTE_ORIGIN_TYPE.USER);
            }
          }
        }
      );
    }),
      untilDestroyed(this))

    // Insert filtered notes into table
    this.notesFiltered$.pipe(
      tap((accNote) => {
        this.dataSource.data = accNote as NoteTableData[];
      }),
      untilDestroyed(this)
    )
      .subscribe()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = this.sortNotes;
  }

  sortNotes(data: NoteTableData[], sortState: MatSort): NoteTableData[]  {
    if (!sortState.active || sortState.direction === '') {
      return data;
    }
    const isAsc = sortState.direction === 'asc';
    const compare = (a: any, b: any, isAsc: boolean) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    const sorter = (a: NoteTableData, b: NoteTableData) => {
      if (a?.Pinned && !b?.Pinned) {
        return -1;
      } else if (!a?.Pinned && b?.Pinned) {
        return 1;
      }
      switch (sortState.active) {
        case 'Date': return compare(a.Date, b.Date, isAsc);
        case 'CreatedBy': return compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'Note': return compare(a.Note, b.Note, isAsc);
        case 'LastModified': return compare(a.ModifiedAt, b.ModifiedAt, isAsc);
        default: return 0;
      }
    };
    return data.sort(sorter);
  }

  async save() {
    if (this.isValidForm()) {
      this.state$.next('saving');
      const note = this.getFormValues();

      if (this.data.Type === NOTE_TYPE.ACCOUNT) {
        await this.noteService.addAccountNote(note, this.data.AccountId);
      } else if (this.data.Type === NOTE_TYPE.INVOICE) {
        await this.noteService.addInvoiceNote(note, this.data.InvoiceId);
      }

      this.resetForm();
      this.state$.next('ready');
      this.cancel();

    } else {
      this.dialogService.showDialog({
        title: 'Warning',
        message: 'You have not entered a note to save. Do you want to make a note now?',
        type: DialogType.WARNING,
        buttons: [
          { id: 'NO', caption: 'No', style: DialogButtonStyle.SECONDARY },
          { id: 'YES', caption: 'Yes', style: DialogButtonStyle.PRIMARY }
        ]
      })
        .closed.pipe(
          tap(resp => {
            if (resp === 'NO') {
              this.cancel();
            }
          }),
        ).subscribe();
    }

    if(this.showNoteControl.touched) {
      if(this.account) {
        const acc = {
          ...this.account,
          ShowNoteModalOnOpen: this.showNoteControl.value,
        } as Account;
        await this.firestoreService.updateDoc(doc(this.firestore, `Practice/${this.auth.selectedBPN}/Account/${this.data.AccountId}`), acc);
      }
    }
  }

  isValidForm(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid ? true : false;
  }

  getFormValues(): Note {
    const accNote: Note = {
      Note: this.form.get('note')?.value,
      Pinned: this.form.get('pinned')?.value,
      OriginType: NOTE_ORIGIN_TYPE.USER
    }
    return accNote;
  }

  resetForm() {
    this.form.patchValue({
      note: '',
    });
    this.form.controls['note'].markAsPristine();
    this.form.controls['note'].markAsUntouched();
  }

  cancel() {
    this.dialogRef.close();
  }

  async togglePin(noteId: string, pinnedStatus: boolean) {
    try {
      await this.fs.updateDoc(doc(this.firestore, `${this.collectionRef}/${noteId}`), { Pinned: !pinnedStatus })
    } catch (err) {
      this.dialogService.showErrorMessage('Failed pin note.');
    }
  }

  /**
   * Put table row into edit mode and reset all other rows' EditMode property
   */
  editMode(noteId: string, originType: NOTE_ORIGIN_TYPE, createdBy: string) {
    if (originType === NOTE_ORIGIN_TYPE.USER && createdBy === this.auth.uid) {
      this.dataSource.data.map((row) => (row.EditMode = noteId === row.Id ? true : false));
    } else {
      this.dialogService.showDialog({ message: 'You can only edit notes you have created.', type: DialogType.WARNING });
    }
  }

  async saveEdit(noteId: string) {
    const newNoteValue = this.editNoteInput.last.nativeElement.value.trim();
    if (newNoteValue !== '') {
      try {
        await this.fs.updateDoc(doc(this.firestore, `${this.collectionRef}/${noteId}`), { Note: newNoteValue })
        this.dataSource.data.map((row) => row.EditMode = false);
      } catch (err) {
        this.dialogService.showErrorMessage('Failed to save note.');
      }
    }
    else {
      this.dialogService.showDialog({ message: 'You cannot save an empty note.', type: DialogType.WARNING });
    }
  }
}
