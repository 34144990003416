import {firestore} from "firebase-admin";
import Timestamp = firestore.Timestamp;

export interface KahunCaseRequest {
    practiceBillingNumber: string;
    appointmentXRef: string;
    appointment: KahunAppointmentRequest;
}

export interface KahunAppointmentRequest {
    status: string;
    startDateTime: number;
    endDateTime: number;
    patient: KahunPatient;
    doctor: KahunDoctor;
}

export interface KahunAppointment {
    status: string;
    startDateTime: Timestamp;
    endDateTime: Timestamp;
    practiceName?: string;
    patient: KahunPatient;
    doctor: KahunDoctor;
    kahunCase?: KahunCase;
}

export interface KahunDoctor {
    doctorXRef: string;
    doctorName?: string;
    treatingPracticeNumber: string;
}

export interface KahunPatient {
    patientXRef?: string;
    title?: string;
    name: string;
    surname: string;
    cellphone?: string;
    age?: number;
    gender?: string;
}

export class KahunWigetSettings {
    patientCaseId: string;
    partnerId: string
    clinicId: string
}

export class KahunCase {
    caseId: string;
    patient: string;
    provider: string;
    status: string;
    summary: string;
    widgetSettings: KahunWigetSettings;
    pdf: string;
    time?: Timestamp;
    conversationProgress?: number;
    patientSummary?: object;
}

export class CreateKahunCaseRequest {
    age: number;
    gender: string;
    practiceBillingNumber: string;
    appointmentXRef: string;
}

export class KahunCaseChangedNotificationReq {
    clinicId: string;
    jwtSignature: string;
    notifications: KahunCaseChangedNotification[];
}

export class KahunCaseChangedNotification {
    caseId: string;
    time: Timestamp;
    status: StatusType;
    conversationProgress: number;
    patientSummary?: object;
    piiPatientProperties?: KahunCaseId;
}

export class KahunCaseId {
    practiceBillingNumber: string;
    appointmentXRef: string;
}

export enum StatusType {
    CREATED = "CREATED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ABANDONED = "ABANDONED"
}

export class KahunCampaignTemplate {
    CampaignName: string;
    CampaignVersion: string;
    CampaignElements: CampaignElement[];
}

export class KahunPracticeConfig {
    billingNumbers: {[key:string]: string[]};
    age: KahunPracticeConfigAge;
}

export class KahunPracticeConfigAge {
    billingNumbers:  {[key:string]: AgeRange};
    from: number;
    to: number;
}

export class AgeRange {
    from: number;
    to: number;
}

export class CampaignElement {
    ElementName: KahunCampainMessageType;
    ElementChannel: KahunCampainType;
    Trigger: TriggerCampaignElement;
    Message: string;
}

export class TriggerCampaignElement {
    TriggerEvent: KahunCampainTriggerEvent;
    OffsetTime: number;
    MaxTimeBeforeAppt?: number;
    MagnetSnap?: string;
}

export class KahunCampaignMessage {
    id: string;
    caseId: string;
    practiceBillingNumber: string;
    appointmentXRef: string;
    triggerTime: Timestamp;
    type: KahunCampainType;
    messageType: KahunCampainMessageType;
    outboundSmsId?: string;
    active: boolean;
}

export enum KahunCampainTriggerEvent {
    APPT_CREATE_TIME = 'ApptCreate',
    APPT_TIME = 'ApptTime'
}

export enum KahunCampainMessageType {
    NIGHT_BEFORE = 'Night before',
    MORNING_OF = 'Morning of',
    APPOINTMENT_CONFIRMATION = 'Appointment Confirmation'
}

export enum KahunCampainType {
    SMS = 'SMS'
}
