import {
  BasePdfReportData,
  ClaimDetailReportModel,
  ClaimDetailTable,
  ReportFooter,
  ReportTable
} from "@meraki-flux/schema";

export class ClaimDetailPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(claimDetailReportModel: ClaimDetailReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = claimDetailReportModel.PracticeId;
        reportData.doctorName = claimDetailReportModel.PracticeName;
        reportData.reportName = "Claim Details";
        reportData.reportNameColor = ClaimDetailPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.tables = this.buildTables(claimDetailReportModel);
        reportData.dateHeader = `Date generated: ${claimDetailReportModel.ReportDate}`;
        reportData.footer = this.buildFooter()
        reportData.claimDetailData = claimDetailReportModel.ClaimDetailData
        reportData.footerText = "\t\u200B".repeat(60)
        return reportData;
    }

    buildFooter() : ReportFooter {
        return {
            hbLogo: true,
        }
    }

    buildTables(invoiceReportModel: ClaimDetailReportModel) : ReportTable[] {
        const tables:ReportTable[] = [];
        invoiceReportModel.ClaimDetailTables.forEach(stTable => {
            tables.push(this.buildReportTable(stTable));
        })
        return tables;
    }

    buildReportTable(inTable: ClaimDetailTable) : ReportTable {
        return {
            reportICDLine: inTable.ICDLine,
            rowHeaders: inTable.rowHeaders,
            rows: inTable.rows,
            borders: inTable.borders
        }
    }
}
