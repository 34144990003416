import { Injectable } from '@angular/core';
import { DialogService, FormatUtils } from '@meraki-flux/common';

@Injectable({ providedIn: 'root' })
export class DebitInvoiceDialog {
  constructor(private dialogService: DialogService) {}

  showTotalDebitAmountRequiredWarning() {
    this.dialogService.showWarning(
      'Please specify an amount in the Debit amount field at the top of the screen first.'
    );
  }

  showLineMaxAllocationExceedWarning(maxAllowed: number) {
    this.dialogService.showWarning(
      `You cannot allocate more than ${FormatUtils.formatCents(maxAllowed)} for this line.`
    );
  }

  showLineMaxAllocationEqualWarning() {
    this.dialogService.showWarning(
      'You cannot allocate a payment correction to this line, because the current line balance is the same as the original invoiced amount for the line.'
    );
  }
}
