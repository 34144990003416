import {User} from "./user.models";
import { BaseModel } from './general.models';

export interface InboxItem extends BaseModel {
  Id?: string;
  Category?: INBOX_CATEGORY;
  FollowUpNote?: FollowUpNote[];
  AssignedTo?: AssignedToPerson;
  BranchName?: string;
  Status?: INBOX_STATUS;
  DiscardReason?: string;
  ReassignReason?: string;
  Action?: string;
  LastActionTaken?: string;
  AssignedToAudit?: AssignedToAudit[];
}

export interface FollowUpNote {
  Note: string;
  CreatedAt: Date;
}

export interface AssignedToPerson {
  Id?: string;
  FullName?: string;
}

export interface AssignedToAudit {
  assignedTime?: Date;
  previouslyAssigned?: AssignedToPerson;
  assignedTo?: AssignedToPerson;
  userId?: string;
  userName?: string;
  ReassignReason?: string;
}

export interface InvoiceInboxItem extends InboxItem {
  Action: INVOICE_INBOX_ACTION;
  AccountNo: string;
  Patient: string;
  InvoiceId: string;
  Branch?: string;
  Note?: string;
}

export interface ClaimInboxItem extends InboxItem {
  Action: CLAIM_INBOX_ACTION;
  InvoiceId: string;
  AccountId: string;
  AccountNo: string;
  Patient?: string;
  Scheme?: string;
  RequestedBy?: string;
  RequestedAt?: Date;
}

export interface CollectionInboxItem extends InboxItem {
  // Add rest of model here
  Action: COLLECTION_INBOX_ACTION;
  AccountId: string;
  AccountNo: string;
  MedicalAidLiable: number;
  PatientLiable: number;
  Patient: string;
  PatientName?: string;
}

export interface RemittanceInboxItem extends InboxItem {
  // Add rest of model here
  Action: REMITTANCE_INBOX_ACTION;
  RemittanceId: string;
  SchemeName: string,
  RaNo: string,
  RaDate: Date,
  RANum: string,
  RADate: Date,
}

export interface ReconciliationInboxItem extends InboxItem {
  Action: RECONCILIATION_INBOX_ACTION;
  DateOfService: Date;
  Patient: string; // <patient initials surname> (<member no.>)
  SchemeName: string;
  RemittanceId: string;
  RemittanceClaimId: string;
}

export interface InboxManagementInboxItem extends InboxItem {
  // Add rest of model here
  Action: INBOX_MANAGEMENT_INBOX_ACTION;
}

// enums
export enum INBOX_CATEGORY {
  INVOICES = 'Invoices',
  CLAIMS = 'Claims',
  COLLECTION = 'Collection',
  REMITTANCES = 'Remittances',
  RECONCILIATION = 'Reconciliation',
  INBOX_MANAGEMENT =  'Discard actions'
}

export enum INBOX_STATUS {
  CREATED = 'Created',
  COMPLETED = 'Completed',
  DISCARDED = 'Discarded'
}

export enum INVOICE_INBOX_ACTION {
  APPROVE = 'Approve',
  COMPLETE_SAVED ='Complete saved',
  COMPLETE_PROVIDER = 'Complete provider'
}

export enum CLAIM_INBOX_ACTION {
  FIX_AND_RESUBMIT = 'Fix & resubmit claim',
  VIEW_REJECTED_CLAIM = 'View rejected claim',
  VIEW_REJECTED_LINES = 'View rejected lines',
  RESUBMIT_REVERSED = 'Resubmit reversed claim',
  REVERESAL_REJECTED = 'Reversal rejected',
  REVERESAL_ACCEPTED = 'Reversal accepted',
}

export enum COLLECTION_INBOX_ACTION {
  COLLECT_BALANCE = 'Collect balance',
}

export enum REMITTANCE_INBOX_ACTION {
  ACKN_ERA_PROCESSED = 'Ackn. eRA processed',
  COMPLETE_MANUAL_RA = 'Complete manual RA',
  PROCESS_JOURNAL = 'Process journals'
}

export enum RECONCILIATION_INBOX_ACTION {
  RECONCILE_UNMATCHED_ERA_ITEM = "Reconcile Unmatched eRA Item",
}

export enum INBOX_MANAGEMENT_INBOX_ACTION {
  DISCARD_ACTIONS = 'Discard actions',
}

// Modals
export interface FollowUpNoteModalData {
  Id?: string;
}

export interface DiscardInboxItemModalData {
  Id?: string;
}

export interface ReassignInboxActionModalData {
  Id?: string;
  user?: User
}

export class InboxItemIsResolvedError extends Error {
  public readonly isInboxItemIsResolvedError: boolean;

  constructor(message: string) {
    super(message);
    this.name = 'InboxItemIsResolvedError';
    this.isInboxItemIsResolvedError = true;
    Object.setPrototypeOf(this, InboxItemIsResolvedError.prototype);
  }
}