import { Injectable } from '@angular/core';
import { FormatUtils } from './format-utils';
import {InvoiceProvider, PracticeProvider} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class ProviderUtils {

  static getFullName(provider: any): string {
    return FormatUtils.join([
      'Dr' === provider?.Title ? provider?.Title : '',
      provider?.Name,
      provider?.Surname,
    ], ' ');
  }

  static getNameOnly(provider: any): string {
    return FormatUtils.join([
      provider?.Surname,
      provider?.Name,
    ], ' ');
  }

  static getFullNameWithSpeciality(provider: any): string {
    return FormatUtils.join([ProviderUtils.getFullName(provider), provider?.Speciality], ', ');
  }

  static toInvoiceProvider(practiceProvider: PracticeProvider) {
    return {
      FullName: ProviderUtils.getFullName(practiceProvider),
      HPCSANumber: practiceProvider.HPCSANumber,
      TreatingPracticeNumber: practiceProvider.TreatingPracticeNumber,
      Speciality: practiceProvider.Speciality,
      Dispensing: practiceProvider.IsDispensing,
      DispensingLicNum: practiceProvider.DispensingLicenceNumber ? practiceProvider.DispensingLicenceNumber : null,
      BillingPracticeNumer: practiceProvider.BillingPracticeNumber
    } as InvoiceProvider;
  }
}
