import { setRemoteDefinitions } from '@nrwl/angular/mf';
import { environment } from './environments/environment';

let fileName = '/assets/module-federation.manifest.json';

if(environment.production) {
  fileName = '/assets/module-federation.manifest.prod.json';
} else if(environment.live) {
  fileName = '/assets/module-federation.manifest.live.json';
} else if(environment.staging) {
  fileName = '/assets/module-federation.manifest.stage.json';
} else if(environment.qa) {
  fileName = '/assets/module-federation.manifest.qa.json';
} else if(environment.ihealthdev) {
  fileName = '/assets/module-federation.manifest.ihdev.json';
} else if(environment.dev) {
  fileName = '/assets/module-federation.manifest.dev.json';
}

fetch(fileName)
  .then((res) => res.json())
  .then((definitions) => setRemoteDefinitions(definitions))
  .then(() => import('./bootstrap').catch((err) => console.error(err)));

