<div class="flex flex-col h-full w-full  overflow-hidden">

  <!-- Header -->
  <div
    class="flex flex-row justify-between items-center border-b border-hb-base-divider-grey !px-4 !py-1 overflow-hidden">
    <label id="dialogHeader" class="font-semibold !leading-6 text-base">Additional Medicine Details</label>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="!mr-[-1px]">close</mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-1 overflow-auto px-4 py-2 gap-2" [formGroup]="form">
    <p class="my-2 max-w-[550px]"><strong>{{ medicineLabel }} </strong>(Pack size: {{ packSize }})</p>


    <!-- Medicine type -->
    <div class="form-row">
      <div class="row-label">Medicine type</div>
      <div class="row-input mb-2">
        <mat-form-field class="flux-form-field flex-1" appearance="fill">
          <mat-label>Medicine type</mat-label>
          <mat-select formControlName="MedicineType" id="medicineType">
            <mat-option *ngFor="let medicineType of medicineTypes | keyvalue" [value]="medicineType.value">
              {{ medicineType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Dosage -->
    <div class="form-row">
      <div class="row-label">Dosage</div>
      <div class="row-input mb-2">
        <mat-form-field class="flux-form-field w-[80px]" floatLabel="never">
          <input formControlName="DosageUnit" matInput>
          <mat-error *ngIf="form.controls.DosageUnit.errors?.pattern">
            Numbers only
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flux-form-field flex-1" floatLabel="never" appearance="fill">
          <mat-label>Dosage type</mat-label>
          <mat-select formControlName="DosageType" id="dosageType">
            <mat-option *ngFor="let dosageType of dosageTypes | keyvalue" [value]="dosageType.value">
              {{ dosageType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Frequency -->
    <div class="form-row">
      <div class="row-label">Frequency</div>
      <div class="row-input items-center mb-2">
        <mat-form-field class="flux-form-field !w-[80px]">
          <input matInput formControlName="FrequencyUnit">
          <mat-error *ngIf="form.controls.FrequencyUnit.errors?.pattern">
            Numbers only
          </mat-error>
        </mat-form-field>
        <div class="text-xs">time(s), every</div>
        <mat-form-field class="flux-form-field !w-[80px]" >
          <input matInput formControlName="PeriodUnit">
          <mat-error *ngIf="form.controls.PeriodUnit.errors?.pattern">
            Numbers only
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flux-form-field flex-1" appearance="fill">
          <mat-label>Period type</mat-label>
          <mat-select formControlName="PeriodType" id="periodType">
            <mat-option *ngFor="let periodType of periodTypes" [value]="periodType">
              {{ periodType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Duration -->
    <div class="form-row">
      <div class="row-label">Duration</div>
      <div class="row-input mb-2">
        <mat-form-field class="flux-form-field !w-[80px]">
          <input matInput formControlName="DurationUnit">
          <mat-error *ngIf="form.controls.DurationUnit.errors?.pattern">
            Numbers only
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flux-form-field flex-1" appearance="fill">
          <mat-label>Duration type</mat-label>
          <mat-select formControlName="DurationType" id="durationType">
            <mat-option *ngFor="let periodType of periodTypes" [value]="periodType">
              {{ periodType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Repeats -->
    <div class="form-row">
      <div class="row-label">Repeats</div>
      <div class="row-input mb-2">
        <mat-form-field class="flux-form-field !w-[80px]">
          <input matInput formControlName="Repeats">
          <mat-error *ngIf="form.controls.Repeats.errors?.pattern">
            Numbers only
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div
    class="flex flex-row justify-end align-middle px-4 py-1 border-t border-t-hb-base-divider-grey overflow-hidden gap-2">
    <button class="flux-button" mat-stroked-button mat-dialog-close>Close</button>
    <button id='save' class="flux-button" mat-flat-button color="primary" (click)="onSave()">
      Save
    </button>
  </div>

</div>
