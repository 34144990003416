import {
  BasePdfReportData, CellType, PDFCellData,
  PDFParameter, RA_CLAIM_STATUS,
  RaDetails, RaJournalAmounts, RaPaymentDateUpdates, RaPayments, RaReasonCodes,
  RaStatementReportModel,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class RaStatementPdfReportDataBuilder {
  private static REPORT_SPECIAL_FONT_COLOR = '#000000';

  build(reportModel: RaStatementReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.bpn = reportModel.RaStatementReportInfo.PracticeId;
    reportData.orientation = 'landscape';
    reportData.headers = this.buildHeader(reportModel);
    reportData.tables = this.buildTables(reportModel);
    reportData.dateHeader = `Date generated ${reportModel.ReportDate}`;
    reportData.footerText = '\t\u200B'.repeat(60);
    reportData.footer = {
      hbLogo: true,
    };
    return reportData;
  }

  private buildHeader(reportModel: RaStatementReportModel): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: { value: reportModel.RaStatementReportInfo.Practice, decoration: { bold: true } },
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: { value: reportModel.RaStatementReportInfo.PracticeId, decoration: { bold: true } },
    });

    return [
      {
        name: "RA Statement",
        nameColor: RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
        includeGeneratedDate: true,
        left: leftParameters,
      },
    ];
  }

  private buildTables(reportModel: RaStatementReportModel): ReportTable[] {
    const reportTables: ReportTable[] = [];
    reportTables.push(
      this.buildRaDetail(
        reportModel.RaStatementProviderModel.RaDetails,
        reportModel.RaStatementProviderModel.RaPayments,
        reportModel.RaStatementProviderModel.RaJournalAmounts
      )
    );
    reportTables.push(this.buildPaymentsTableName(reportModel.RaStatementProviderModel.RaPayments));
    this.buildRaPaymentsTable(reportModel.RaStatementProviderModel.RaPayments,reportTables);

    //if(reportModel.RaStatementProviderModel.RaJournalAmounts && reportModel.RaStatementProviderModel.RaJournalAmounts.length>0){
      reportTables.push(this.buildRaJournals(reportModel.RaStatementProviderModel.RaJournalAmounts));
    //}

    if(reportModel.RaStatementProviderModel.RaReasonCodes && reportModel.RaStatementProviderModel.RaReasonCodes.length>0){
        reportTables.push(this.buildRaReasonCodes(reportModel.RaStatementProviderModel.RaReasonCodes));
    }
    if(reportModel.RaStatementProviderModel.RaPaymentDateUpdates && reportModel.RaStatementProviderModel.RaPaymentDateUpdates.length>0) {
        reportTables.push(this.buildRaPaymentDateUpdateLog(reportModel.RaStatementProviderModel.RaPaymentDateUpdates));
    }


    return reportTables;
  }

  private buildRaDetail(raDetails: RaDetails, raPayments: RaPayments[], raJournalAmounts: RaJournalAmounts[] ): ReportTable {
    const table: ReportTable = {};
    table.name = 'RA DETAILS';
    table.nameColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;

    table.rowHeaders = [{ value: '' }, { value: '',width: 100 }, { value: '' }, { value: '' }];

    const rows = [];

    const row1: PDFCellData[] = [];
    row1.push({ value: 'Scheme' });
    row1.push({ value: raDetails.Scheme, decoration: { bold: true } });
    row1.push({ value: 'RA no.' });
    row1.push({ value: raDetails.RANo, decoration: { bold: true } });
    rows.push(row1);

    const row2: PDFCellData[] = [];
    row2.push({ value: 'Payment date' });
    row2.push({ value: raDetails.PaymentDate, decoration: { bold: true } });
    row2.push({ value: 'EFT no.' });
    row2.push({ value: raDetails.EFTNo, decoration: { bold: true } });
    rows.push(row2);

    const row3: PDFCellData[] = [];
    row3.push({ value: 'RA date' });
    row3.push({ value: raDetails.RADate, decoration: { bold: true } });
    row3.push({ value: '' });
    row3.push({ value: '' });
    rows.push(row3);

    const row4: PDFCellData[] = [];
    row4.push({ value: 'Recon type' });
    row4.push({ value:  raDetails.ReconType == "Paper"? "Manual": raDetails.ReconType, decoration: { bold: true } });
    row4.push({ value: 'Medical aid or insurer' });
    row4.push({ value: raDetails.MedicalAidOrInsurer, decoration: { bold: true } });
    rows.push(row4);

    const row5: PDFCellData[] = [];
    row5.push({ value: 'Total amount paid' });
    row5.push({ value: raDetails.TotalAmountPaid, type: CellType.CURRENCY, decoration: { bold: true, hAlignment:'left' }});

    const totalPayment = raPayments? raPayments.reduce((acc, obj) => acc + obj.Amount, 0): 0;
    const totalJournal = raJournalAmounts? raJournalAmounts.reduce((acc, obj) => acc + obj.Amount, 0) : 0;

    if(raDetails.ReconType == "Paper"){
      row5.push({ value: 'Total value captured' });
      row5.push({ value: totalPayment + totalJournal, type: CellType.CURRENCY, decoration: { bold: true, hAlignment:'left' }});
    }
    else{
      row5.push({ value: ''});
      row5.push({ value: ''});
    }
    rows.push(row5);

    table.rows = rows;
    table.borders = {
      hor: false,
      ver: false,
    };

    return table;
  }

  private buildPaymentsTableName(raPayments: RaPayments[]): ReportTable {
    const table: ReportTable = {};
    table.name = 'PAYMENTS';
    table.nameColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.rows = undefined;
    table.rowHeaders = undefined;

    if (!raPayments || raPayments.length === 0) {
      table.noRecordsText = {
        value: 'No claim payments',
        decoration: {
          italics: true,
        },
      };
    }
    return table;
  }

  private buildRaPaymentsTable(raPayments: RaPayments[], reportTables: ReportTable[]) {

    const paymentsReconciled = raPayments.filter((ra) => ra.Status == RA_CLAIM_STATUS.RECONCILED);
    if (paymentsReconciled && paymentsReconciled.length > 0) {
        reportTables.push(this.buildRaPayments(raPayments,RA_CLAIM_STATUS.RECONCILED));
    }

    const paymentsUnReconciled = raPayments.filter((ra) => ra.Status == RA_CLAIM_STATUS.UNRECONCILED);
    if (paymentsUnReconciled && paymentsUnReconciled.length > 0) {
        reportTables.push(this.buildRaPayments(raPayments,RA_CLAIM_STATUS.UNRECONCILED));
    }

    const paymentsPartReconciled = raPayments.filter((ra) => ra.Status == RA_CLAIM_STATUS.PART_RECONCILED);
    if (paymentsPartReconciled && paymentsPartReconciled.length > 0) {
        reportTables.push(this.buildRaPayments(raPayments,RA_CLAIM_STATUS.PART_RECONCILED));
    }

    const paymentsInvalidDos = raPayments.filter((ra) => ra.Status == RA_CLAIM_STATUS.INVALID_DOS);
    if (paymentsInvalidDos && paymentsInvalidDos.length > 0) {
        reportTables.push(this.buildRaPayments(raPayments,RA_CLAIM_STATUS.INVALID_DOS));
    }

    const paymentsCouldNotReconciled = raPayments.filter((ra) => ra.Status == RA_CLAIM_STATUS.COULD_NOT_RECONCILE);
    if (paymentsCouldNotReconciled && paymentsCouldNotReconciled.length > 0) {
        reportTables.push(this.buildRaPayments(raPayments,RA_CLAIM_STATUS.COULD_NOT_RECONCILE));
    }
  }

  private buildRaPayments(raPayments: RaPayments[], raClaimStatus: string): ReportTable {
    const payments = raPayments.filter((ra) => ra.Status == raClaimStatus);

    const table: ReportTable = {};
    table.headers = [{rows: [[{ name: '', data: { value: 'Status: ' + raClaimStatus, decoration: { bold: true, italics: true } },},],], backgroundColor: '#ececec',},];
    table.rowHeaders = [
        { value: 'Account no.', decoration: { bold: true } },
        { value: 'Patient', decoration: { bold: true } },
        { value: 'Member no.', decoration: { bold: true } },
        { value: 'Date of service', decoration: { bold: true } },
        { value: 'Tariff code', decoration: { bold: true } },
        { value: 'Nappi code', decoration: { bold: true } },
        { value: 'Claimed', decoration: { bold: true } },
        { value: 'Paid', decoration: { bold: true } },
        { value: 'Reason code', decoration: { bold: true } },
      ];
    table.rowHeadersBold = true;

    const rows = [];

    payments.forEach((item: RaPayments) => {
      // if (isNaN(item.Claimed)) console.error(item.Claimed);
      // if (isNaN(item.Paid)) console.error(item.Paid);

      // totalClaimed += Math.round((item.Claimed + Number.EPSILON) * 100) / 100;
      // totalPaid += Math.round((item.Paid + Number.EPSILON) * 100) / 100;

      const row: PDFCellData[] = [];
      row.push({ value: item.AccountNo });
      if(item.IsNoteLine){
        row.push({ value: item.Patient, decoration: { italics: true }});
      }else{
        row.push({ value: item.Patient });
      }
      row.push({ value: item.MemberNo });
      row.push({ value: item.DateOfService });
      row.push({ value: item.TariffCode });
      row.push({ value: item.NappiCode });
      if(item.IsNoteLine){
        row.push({ value: ""});
        row.push({ value: ""});
      }else{
        row.push({ value: item.Claimed, type: CellType.CURRENCY });
        row.push({ value: item.Paid, type: CellType.CURRENCY });
      }
      row.push({ value: item.ReasonCode });
      rows.push(row);
    });

    const totalClaimed = payments ? payments.reduce((acc, obj) => acc + obj.Claimed, 0) : 0;
    const totalPaid = payments? payments.reduce((acc, obj) => acc + obj.Paid, 0): 0;

    const rowTotal: PDFCellData[] = [];
    rowTotal.push({ value: 'Total', decoration: { bold: true } });
    const blankColumn = { value: '', decoration: { bold: true } };
    for (let i = 0; i < 5; i++) {
      rowTotal.push(blankColumn);
    }

    rowTotal.push({ value: totalClaimed, type:CellType.CURRENCY, decoration: { bold: true } });
    rowTotal.push({ value: totalPaid, type:CellType.CURRENCY, decoration: { bold: true } });

    rowTotal.push(blankColumn);
    rows.push(rowTotal);

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };

    return table;
  }

  private buildRaJournals(raJournalAmounts: RaJournalAmounts[]): ReportTable {

    const table: ReportTable = {};
    table.name = 'JOURNAL AMOUNTS';
    table.nameColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    //table.headers = [{rows: [[{ name: '', data: { value: 'JOURNAL AMOUNTS', decoration: { bold: true, italics: true } },},],], backgroundColor: '#ececec',},];
    table.rowHeaders = [
        { value: 'Journal description', decoration: { bold: true } },
        { value: 'Amount', decoration: { bold: true } },
      ];
    table.rowHeadersBold = true;

    const rows = [];

    if(raJournalAmounts){
      raJournalAmounts.forEach((item: RaJournalAmounts) => {
        const row: PDFCellData[] = [];
        row.push({ value: item.JournalDescription });
        row.push({ value: item.Amount, type: CellType.CURRENCY });
        rows.push(row);
      });
    }

    const total = raJournalAmounts? raJournalAmounts.reduce((acc, obj) => acc + obj.Amount, 0): 0;

    const rowTotal: PDFCellData[] = [];
    rowTotal.push({ value: 'Total', decoration: { bold: true } });
    rowTotal.push({ value: total, type: CellType.CURRENCY, decoration: { bold: true } });
    rows.push(rowTotal);

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };

    if (!raJournalAmounts || raJournalAmounts.length === 0) {
      table.rows = undefined;
      table.rowHeaders = undefined;
      table.noRecordsText = {
        value: 'No journal amounts',
        decoration: {
          italics: true,
        },
      };
    }

    return table;

  }

  private buildRaReasonCodes(raReasonCodes: RaReasonCodes[]): ReportTable {

    const table: ReportTable = {};
    table.name = 'REASON CODES';
    table.nameColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    //table.headers = [{rows: [[{ name: '', data: { value: 'JOURNAL AMOUNTS', decoration: { bold: true, italics: true } },},],], backgroundColor: '#ececec',},];
    table.rowHeaders = [
        { value: 'Reason code', decoration: { bold: true } },
        { value: 'Description', decoration: { bold: true } },
      ];
    table.rowHeadersBold = true;

    const rows = [];
    raReasonCodes.forEach((item: RaReasonCodes) => {
      const row: PDFCellData[] = [];
      row.push({ value: item.ReasonCode });
      row.push({ value: item.Description });
      rows.push(row);
    });

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };

    return table;

  }

  private buildRaPaymentDateUpdateLog(raPaymentDateUpdates: RaPaymentDateUpdates[]): ReportTable {

    const table: ReportTable = {};
    table.name = 'LOG: RA PAYMENT DATE UPDATES';
    table.nameColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = RaStatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.rowHeaders = [
        { value: 'Date/time', decoration: { bold: true } },
        { value: 'User', decoration: { bold: true } },
        { value: 'Old payment date', decoration: { bold: true } },
        { value: 'New payment date', decoration: { bold: true } },
      ];
    table.rowHeadersBold = true;

    const rows = [];
    raPaymentDateUpdates.forEach((item: RaPaymentDateUpdates) => {

      const row: PDFCellData[] = [];
      row.push({ value: item.DateOfChange });
      row.push({ value: item.User });
      row.push({ value: item.OldPaymentDate });
      row.push({ value: item.NewPaymentDate });
      rows.push(row);
    });

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };

    return table;

  }
}
