import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import * as moment from "moment";

@Injectable()
export class FluxDateAdapter extends NativeDateAdapter {
    override useUtcForDisplay = true;

    override format(date: Date): string {
        return moment(date).format("DD/MM/YYYY");
    }

    override createDate(year: number, month: number, date: number): Date {
        return new Date(Date.UTC(year, month, date));
    }

    override parse(value: any): Date {
        if (value && typeof value === 'string') {
            value = value.replace('_', '');
        }
        const date = moment.utc(value, 'DD/MM/YYYY', true);
        if (!date.isValid()) {
            return this.invalid();
        }
        return date.toDate();
    }

}