import { BaseModel } from './general.models';

export interface RemittanceClaim extends BaseModel {
  Id?: string;
  LineItem?: LineItem[];
  MemNum?: string;
  OrigClaimRefNum?: string;
  PatDOB?: string | Date;
  PatDepCode?: string;
  PatInitial?: string;
  PatName?: string;
  PatSurname?: string;
  TotClaimAmount?: number;
  TotPaymentAmount?: number;
  Status?: RA_CLAIM_STATUS;
  Notes?: RemittanceNote[];
  AccountId?: string;
  AccountNo?: string;
  PatAccNum?: string;
}

export interface LineItem {
  Amount?: number;
  ChargeCode?: string;
  NappiCode?: string;
  DOS?: string | Date;
  LineNum?: number;
  PayAdvice?: PayAdvice;
  Reason?: Reason[];
}

export interface PayAdvice {
  PatPaymentAmount?: number;
  PaymentAmount?: number;
  TariffAmount?: number;
}

export interface Reason {
  ReasonCode?: string;
  ReasonDesc?: string;
}

export interface RemittanceAdvice extends BaseModel {
  Id?: string;
  Header?: any;
  Payment?: RemittancePayment;
  Claim?: RemittanceClaim[];
  Transaction?: RemittanceTransaction;
  Type?: string | REMITTANCE_ADVICE_TYPE;
  SchemeName?: string;
  Insurer?: string;
  CreatedAt?: Date;
  Journal?: RemittanceJournal[]; // will be removed, journals must be taken from a separate collection
  Audit? :RAPayDateAudit[];
}

export interface RemittanceJournal {
  Id?: string;
  JournalAmount?: number;
  JournalDate?: string | Date;
  JournalDes?: string;
  OldJournalDes?: string;
  JournalStatus?: string | JOURNAL_STATUS;
  JournalCategory?: string;
}

export interface RemittanceJournalCapture {
  Amount: number;
  Description: string;
  Category: string;
}

export interface RemittanceJournalCaptureModalData {
  Journals: RemittanceJournalCapture[];
  TotalPaidAmount: number;
}

export interface RemittancePayment {
  '@Type'?: string;
  Bank?: string;
  BankAccNum?: string;
  BranchCode?: string;
  CloseBal?: number;
  EFTNum?: string;
  OpenBal?: number;
  PaidAmount?: number;
  UnallocatedClaimAmount?: number;
  UnallocatedJournalAmount?: number;
  PayDate?: string | Date;
  PraNum?: string;
  RADate?: string | Date;
  RANum?: string;
}

export interface RemittanceNote {
  Type: REMITTANCE_NOTE_TYPE;
  Note: string;
  CreatedBy: string;
  CreatedAt: Date;
}

export interface RAPayDateAudit {
  PracticeId?: string;
  RaId?: string;
  UserId?: string;
  OldPaymentDate?: string | Date;
  NewPaymentDate?: string | Date;
  IsDateRequired?: boolean;
}

export interface RemittanceTransaction {
  TxDateTime?: string | Date;
  TxNum?: string;
}

export interface RemittanceClaimPatientFilter {
  BillingPracticeNumber: string;
  Scheme?: string;
}

export enum JOURNAL_STATUS {
  BUSINESS_INCOME = 'Business income',
  RECONCILED = 'Reconciled',
  UNRECONCILED = 'Unreconciled',
}

export enum RA_CLAIM_STATUS {
  RECONCILED = 'Reconciled',
  UNRECONCILED = 'Unreconciled',
  INVALID_DOS = 'Invalid DOS',
  COULD_NOT_RECONCILE = 'Could not reconcile',
  PART_RECONCILED = 'Part Reconciled',
}

export enum REMITTANCE_ADVICE_TYPE {
  PAPER = 'Paper',
  ELECTRONIC = 'Electronic',
}

export enum REMITTANCE_NOTE_TYPE {
  GENERAL = 'General',
  COULD_NOT_RECONCILE = 'Could not reconcile',
}

export class RemittanceClaimIsResolvedError extends Error {
  public readonly isRemittanceClaimResolvedError: boolean;

  constructor(message: string) {
    super(message);
    this.name = 'RemittanceClaimIsResolvedError';
    this.isRemittanceClaimResolvedError = true;
    Object.setPrototypeOf(this, RemittanceClaimIsResolvedError.prototype);
  }
}
