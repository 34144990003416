import { Injectable } from '@angular/core';
import { createStore, select, setProp, Store, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import {Addon, AddonConfigurables, RetailCapitalOffer} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class AddonRepository {
  private readonly addonStore: Store;

  addons$: Observable<Addon[]>;
  configurables$: Observable<AddonConfigurables>;
  retailCapital$: Observable<RetailCapitalOffer>;

  constructor() {
    this.addonStore = createStore({ name: 'addons' }, withProps({
      addons: [],
      configurables: {},
      retailCapital: {},
    }));

    this.addons$ = this.addonStore.pipe(select(state => state.addons));
    this.configurables$ = this.addonStore.pipe(select(state => state.configurables));
    this.retailCapital$ = this.addonStore.pipe(select(state => state.retailCapital));
  }

  saveAddons(addons: Addon []) {
    this.addonStore.update(setProp('addons', addons));
  }

  getAddons(): Addon [] {
    return this.addonStore.query(state => state.addons);
  }

  saveConfigurables(configurables: AddonConfigurables) {
    this.addonStore.update(setProp('configurables', configurables));
  }

  getConfigurables(): AddonConfigurables {
    return this.addonStore.query(state => state.configurables);
  }

  saveRetailCapital(retailCapital: RetailCapitalOffer) {
    this.addonStore.update(setProp('retailCapital', retailCapital));
  }

  getRetailCapital(): RetailCapitalOffer {
    return this.addonStore.query(state => state.retailCapital);
  }

  clear() {
    return this.addonStore.reset();
  }
}
