import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormUtils {
  static validateForm(control: AbstractControl | null, emitEvent: boolean = true): void {
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({ emitEvent: emitEvent });
    } else if (control instanceof UntypedFormGroup) {
      Object.keys(control.controls).forEach((field) => {
        const c = control.get(field);
        this.validateForm(c, emitEvent);
      });
    } else if (control instanceof UntypedFormArray) {
      control.controls.forEach((c) => this.validateForm(c));
    }
  }

  static enable(control: AbstractControl, enable: boolean, options: any = {}) {
    if (enable) {
      control.enable(options);
    } else {
      control.disable(options);
    }
  }

  static setRequired(required: boolean, ...controls: AbstractControl[]) {
    controls?.forEach((control) => {
      if (required) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
    });
  }
}
