import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Observable, Subject} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs/operators';
import { DialogService } from '../../../services/dialog.service';
import { CommunicationTemplatePreviewDialogComponent } from '../communication-template-preview-dialog/communication-template-preview-dialog.component';
import {AuthService} from "../../../services/auth.service";
import {FileUtils} from "../../../utils/file-utils";
import {StorageService} from "../../../services/storage.service";
import { LogService } from '../../../services/log.service';
import {
  Branch,
  CommunicationTemplate, DialogButtonStyle,
  DialogType,
  EDITOR_CONFIG,
  PlaceholderVariables,
  VariableCategories
} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-communication-template-edit-form',
  templateUrl: './communication-template-edit-form.component.html',
  styleUrls: ['./communication-template-edit-form.component.css']
})
@UntilDestroy()
export class CommunicationTemplateEditFormComponent implements OnInit {
  @Input() formMode: 'sms' | 'email' = 'email';
  @Input() practice!: Observable<any | null>;
  @Input() template!: CommunicationTemplate | null;
  @Input() showAttachment: boolean | null = true;
  @Input() formData: UntypedFormGroup | null = null;
  tinyMceEditor!: { insertContent: (arg0: any) => void };
  lastBlurred: 'subject' | 'editor' | 'sms' = 'subject';
  cursorPosition = 0;
  placeholderRegex =  /\$\{(.*?)\}/g;
  previewResults: { Subject?: string, Body?: string } = {};
  isHigherThanMaxContent = false;
  isMultibranch$: Observable<boolean | null> = new Observable()
  placeholders$: Observable<any | null> = new Observable()
  removeFileFromChild: Subject<string> = new Subject<string>();
  get maxSmsCautionLength() {
    return 150;
  }

  editorConfig = {
    ...EDITOR_CONFIG,
    setup: async (editor: any) => {
      editor.on('init', () => {
        editor.getContainer().className += ' with-border';
        this.tinyMceEditor = editor;
      });
    },
  };

  emailForm: UntypedFormGroup = this.fb.group({
    subject: ['', Validators.required],
    body: ['', Validators.required],
    Attachments: [[]]
  });

  smsBody = new UntypedFormControl('', Validators.required);
  insertVariableControl = new UntypedFormControl({});

  constructor(
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private auth: AuthService,
    private storageService: StorageService,
    private logger: LogService
    ) {}

  ngOnInit(): void {
    this.isMultibranch$ = this.practice.pipe(
      map((pr) => !!pr.IsMultiBranch),
      untilDestroyed(this)
    );

    this.placeholders$ = this.isMultibranch$.pipe(
      map((result) =>
        PlaceholderVariables.filter((variable) => {
          const filterCategory = result
            ? VariableCategories.MULTIBRANCH
            : VariableCategories.SINGLEPRACTICE;
          const templateName = this.formData.get('templateName')?.value;
          const templateCategory = templateName.includes('Appointment') ? VariableCategories.APPOINTMENT :
            templateName.includes('invoice') ? VariableCategories.INVOICE :
              templateName.includes('Payment confirmation') ? VariableCategories.PAYMENT_CONFIRMATION :
                VariableCategories.ALL;
            return (
              variable.category === filterCategory ||
              variable.category === VariableCategories.ALL ||
              variable.category === templateCategory
            );
        })
      ),
      untilDestroyed(this)
    );

    this.lastBlurred  = this.formMode === 'email' ? 'subject' : 'sms'
    this.insertVariableControl.valueChanges
      .pipe(
        tap((placeHolder) => {
          switch (this.lastBlurred) {
            case 'subject': {
              const subjectValue = this.emailForm.get('subject')?.value;
              const updatedSubjectValue = [
                subjectValue.slice(0, this.cursorPosition),
                placeHolder.value,
                subjectValue.slice(this.cursorPosition),
              ].join('');
              this.emailForm.get('subject')?.patchValue(updatedSubjectValue);
              break;
            }
            case 'editor':
              this.tinyMceEditor.insertContent(placeHolder.value);
              break;
            case 'sms':{
              const smsValue = this.smsBody.value;
              const updatedSmsValue = [
                smsValue.slice(0, this.cursorPosition),
                placeHolder.value,
                smsValue.slice(this.cursorPosition),
              ].join('');
              this.smsBody.patchValue(updatedSmsValue);
              break;
            }
          }
          this.insertVariableControl.patchValue('', { emitEvent: false });
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.emailForm.get('subject')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      withLatestFrom(this.practice),
      tap(([valChange, practice]) => {
        this.previewResults.Subject = this.replaceTemplatePlaceholderVariables(valChange, practice);
      }),
      untilDestroyed(this)
    ).subscribe();

    this.emailForm.get('body')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      withLatestFrom(this.practice),
      tap(([valChange, practice]) => {
        this.previewResults.Body = this.replaceTemplatePlaceholderVariables(valChange, practice);
      }),
      untilDestroyed(this)
    ).subscribe();

    this.smsBody.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      withLatestFrom(this.practice),
      tap(([valChange, practice]) => {
        this.previewResults.Body = this.replaceTemplatePlaceholderVariables(valChange, practice);
        this.isHigherThanMaxContent = this.previewResults.Body.length > this.maxSmsCautionLength;
      }),
      untilDestroyed(this)
    ).subscribe();

    if(this.formMode === 'email') {
      this.emailForm.patchValue({
        subject: this.template?.Subject || '',
        body: this.template?.Body || '',
      });
    } else {
      this.smsBody.patchValue(this.template?.Body || '');
    }
  }

  async validate() {
    let valid = true;
    if(this.formMode === 'email') {
      this.emailForm.markAllAsTouched();
      this.emailForm.updateValueAndValidity();

    } else {
      this.smsBody.markAsTouched();
      this.smsBody.updateValueAndValidity();
    }
    if(this.formMode === 'sms') {
      valid = valid && await this.validateSmsLength();
    }
    return valid;
  }

  private async validateSmsLength() {
    if(this.isHigherThanMaxContent) {
      const dialogResponse = await this.dialogService.showDialog({
        type: DialogType.WARNING,
        title: 'Warning',
        message: 'The calculated SMS length is possibly greater than 160 characters.  Please consider changing the message to make it shorter.<br>Do you want to proceed with saving this template?',
        buttons:  [
          { id: 'NO', caption: 'No', style: DialogButtonStyle.SECONDARY },
          { id: 'YES', caption: 'Yes', style: DialogButtonStyle.PRIMARY }
        ]
      }).closed.toPromise();
      if(dialogResponse === 'YES') {
        return true;
      }
      return false;
    }
    return true;
  }

  onPreview() {
    this.dialog.open(CommunicationTemplatePreviewDialogComponent, {
      width: '80em',
      height: '47em',
      panelClass: 'flux-dialog-small-padding',
      data: { Subject: this.previewResults?.Subject || '', Body: this.previewResults?.Body || ''},
    })
  }

  replaceTemplatePlaceholderVariables(text: string, practice: any) {
    const result = text.replace(this.placeholderRegex, (placeholderValue) => {
      return this.getPlaceholderValue(placeholderValue, practice) || '';
    })
    return result;
  }
  getPlaceholderValue(placeholder: string, practice: any) {
    const placeHolderText = placeholder
      .replace('${', '')
      .replace('}', '')
      .trim();
    const placeholderVariable = PlaceholderVariables.find(
      (variable) =>
        variable.value.replace('${', '').replace('}', '').trim() ===
        placeHolderText
    );
    if (placeholderVariable?.previewValue)
      return placeholderVariable?.previewValue;

      let mainBranch: Branch = practice.Branches.find(
        (branch: Branch) => branch.IsMainBranch
      );
      if(!mainBranch) mainBranch = practice.Branches[0];
      switch (placeHolderText) {
        case 'BranchName':
          return mainBranch.Name;
        case 'BranchAddress':
          return `${mainBranch.PhysicalAddress?.Line1??''}, ${mainBranch.PhysicalAddress?.Line2??''}, ${mainBranch.PhysicalAddress?.Line3??''}, ${mainBranch.PhysicalAddress?.Code??''}`;
        case 'BranchOfficeNo':
          return mainBranch.ContactDetails?.OfficeNo;
        case 'BranchEmail':
          return mainBranch?.ContactDetails?.EmailAddress;
        case 'PracticeName':
          return practice.PracticeName;
        case 'PracticeAddress':
          return `${mainBranch.PhysicalAddress?.Line1??''}, ${mainBranch.PhysicalAddress?.Line2??''}, ${mainBranch.PhysicalAddress?.Line3??''}, ${mainBranch.PhysicalAddress?.Code??''}`;
        case 'PracticeOfficeNo':
          return mainBranch.ContactDetails?.OfficeNo;
        case 'PracticeEmail':
            return mainBranch.ContactDetails?.EmailAddress;
        default:
          return null;
      }
  }

  onSubjectBlur(event: any) {
    this.lastBlurred = 'subject';
    this.cursorPosition = event.target.selectionStart;
  }

  onEditorBlur() {
    this.lastBlurred = 'editor';
  }

  onSMSBlur(event: any) {
    this.lastBlurred = 'sms';
    this.cursorPosition = event.target.selectionStart;
  }

  async onFilesAdded(files: File[]) {
    this.emailForm.get('Attachments')?.setValue((this.emailForm.get('Attachments')?.value as File[]).concat(files));
  }

  onFileRemoved(file: any) {
    this.emailForm.patchValue({Attachments: (this.emailForm.get('Attachments')?.value as File[]).filter(formAtt => formAtt.name !== file.name)});
    this.removeFileFromChild.next(file.name);
  }

  removeUUIDFromFileName(fileName: string) {
    return FileUtils.unDecorateFileNameWithUUID(fileName);
  }

  async uploadFiles() {
    const storagePath = `Practice/${this.auth.selectedBPN}/OutboundEmail/`;
    const uploadedAttachments = [];
    const files = this.emailForm?.get('Attachments')?.value;
    if (files) {
      for (const file of files) {

        const onStateChange = (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.emailForm?.get('Attachments')?.value.forEach((element: any) => {
            if (element.name === file.name) {
              element.uploadProgress = Math.trunc(progress);
            }
          });
        };
        const onError = (error) => {
          this.logger.error(error)
        }
        uploadedAttachments.push(await this.storageService.uploadFile(storagePath, file, onStateChange, onError));
      }
    }
    return uploadedAttachments;
  }
}
