import _ from 'lodash';
import {Payment} from "@meraki-flux/schema";

export class PaymentUtils {
  static getPositiveAllocations(payment: Payment) {
    return payment?.Allocations?.filter((a) => a?.AmountSystemAllocated > 0);
  }

  static getPaymentInvoices(payment: Payment) {
    return _.uniq(this.getPositiveAllocations(payment).map((a) => a?.InvoiceNo)).sort();
  }

  static isUnallocated(payment: Payment) {
    return this.getPositiveAllocations(payment)?.length === 0;
  }
}
