import { ERROR_CODE } from "./error.models";

export class CodedError extends Error {
  constructor(public code: ERROR_CODE, message: string) {
    super(message);
  }
}

export class IncorrectBalanceError extends CodedError {
  constructor(message: string) {
    super(ERROR_CODE.INCORRECT_BALANCE, message);
  }
}
