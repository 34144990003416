import { Injectable } from "@angular/core";
import { Scheme } from "@meraki-flux/schema";
import { createStore, select, setProp, Store, withProps } from "@ngneat/elf";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ReferenceDataRepository {

    private readonly referenceDataStore: Store;

    schemes$: Observable<{ [key: string]: string }>;
    medicalInsurers$: Observable<{ [key: string]: any }>;

    constructor() {
        this.referenceDataStore = createStore({
            name: 'reference-data'
        }, withProps({
            MedicalInsurers: {},
            Schemes: {},
            BenefitCheckSchemes: [],
            BenefitCheckRoutingCodes: [],
            FamCheckSchemes: [],
            CapitatedSchemesMap: {},
            SnackbarDuration: 6000
        }));

        this.medicalInsurers$ = this.referenceDataStore.pipe(select(state => state.MedicalInsurers));
        this.schemes$ = this.referenceDataStore.pipe(select(state => state.Schemes));
    }

    setSchemes(schemes: any) {
        this.referenceDataStore.update(setProp('Schemes', schemes))
    }

    setMedicalInsurers(insurers: any) {
        this.referenceDataStore.update(setProp('MedicalInsurers', insurers));
    }

    setBenefitCheckSchemes(schemes: string[]) {
        this.referenceDataStore.update(setProp('BenefitCheckSchemes', schemes));
    }

    setBenefitCheckRoutingCodes(codes: number[]) {
        this.referenceDataStore.update(setProp('BenefitCheckRoutingCodes', codes));
    }

    setFamCheckSchemes(schemes: string[]) {
        this.referenceDataStore.update(setProp('FamCheckSchemes', schemes));
    }

    setCapitatedSchemesMap(map: any) {
        this.referenceDataStore.update(setProp('CapitatedSchemesMap', map));
    }

    setSnackbarDuration(duration: number) {
        this.referenceDataStore.update(setProp('SnackbarDuration', duration));
    }

    getCapitatedSchemesMap(): any {
        return this.referenceDataStore.state.CapitatedSchemesMap;
    }

    getCapitatedScheme(code: string) {
        return this.referenceDataStore.state.CapitatedSchemesMap[code];
    }

    getBenefitCheckSchemes(): string[] {
        return this.referenceDataStore.state.BenefitCheckSchemes;
    }

    getBenefitCheckRoutingCodes(): number[] {
        return this.referenceDataStore.state.BenefitCheckRoutingCodes;
    }

    getSchemeName(code: string) {
        return this.referenceDataStore.state['Schemes'][code];
    }

    getFamCheckSchemes() {
        return this.referenceDataStore.state.FamCheckSchemes;
    }

    getMedicalInsurer(code: string) {
        return { ...this.referenceDataStore.state.MedicalInsurers[code], Code: code, Id: code };
    }

    getSnackbarDuration(): number {
        return this.referenceDataStore.state.SnackbarDuration;
    }

    setSchemePlanOptionFlat(schemes: Scheme[]) {
        this.referenceDataStore.update(setProp('SchemePlanOptionFlat', schemes));
    }

    getSchemePlanOptionFlat() {
        return this.referenceDataStore.state.SchemePlanOptionFlat;
    }
}
