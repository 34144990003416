import { orderBy } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { collection, doc, Firestore, getDoc, getDocs } from '@angular/fire/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CustomChargeCodes, Diagnosis, INVOICE_LINE_TYPE, INVOICE_TEMPLATE_TYPE, InvoiceTemplateData, TemplateLine } from '@meraki-flux/schema';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { LogService } from '../../services/log.service';
import { LookupSelection, SearchService } from '../../services/search.service';

@Component({
  selector: 'meraki-flux-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
@UntilDestroy()
export class InvoiceTemplateComponent implements AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: InvoiceTemplateData,
    private searchService: SearchService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<InvoiceTemplateComponent>,
    private firestore: Firestore,
    private auth: AuthService,
    private logger: LogService
  ) { }
  @ViewChild('headerDiag') headerDiagSearchInput!: ElementRef;

  diagnosisSearchResult$ = new BehaviorSubject<{ code: string, description: string, highlightedCode?: string, highlightedDescription?: string }[]>([]);
  diagnosisSearchBusy$ = new BehaviorSubject<boolean>(false);
  headerDiagnoses$ = new BehaviorSubject<{ code: string, description: string }[]>([]);
  descriptionLimit = 30;

  lineTypes = INVOICE_LINE_TYPE;
  templateTypes = INVOICE_TEMPLATE_TYPE;

  tableColumns = [
    'add',
    'lineType',
    'tariffCode',
    'nappiCode',
    'description',
    'quantity'
  ];

  header = 'Create favourite';

  templateLinesSource = new MatTableDataSource();

  lineTypes$ = of(Object.values(INVOICE_LINE_TYPE));

  medicineTariffCodes$ = new BehaviorSubject<{ [key: string]: { Description: string, IsDefault: boolean } }>({});
  consumableTariffCodes$ = new BehaviorSubject<{ [key: string]: { Description: string, IsDefault: boolean } }>({});
  allCustomChargeCodes$ = new BehaviorSubject<CustomChargeCodes[]>([]);

  // Nappi Search
  // nappiCodeSearchRequest$ = new BehaviorSubject<{ query: string, searchConsumables: boolean }>({ query: '', searchConsumables: false });
  nappiCodeSearchRequest$ = new BehaviorSubject<{ query: string, lineType: INVOICE_LINE_TYPE }>({ query: '', lineType: INVOICE_LINE_TYPE.MEDICINE });
  nappiCodeSearchResult$ = new BehaviorSubject<LookupSelection[]>([]);
  nappiCodeSearchBusy$ = new BehaviorSubject<boolean>(false);

  // Tariff Search
  tariffCodeSearchRequest$ = new BehaviorSubject<{ query: string, lineType: INVOICE_LINE_TYPE }>({ query: '', lineType: INVOICE_LINE_TYPE.PROCEDURE });
  tariffCodeSearchResult$ = new BehaviorSubject<LookupSelection[]>([]);
  tariffCodeSearchBusy$ = new BehaviorSubject<boolean>(false);

  prescribeWarning$ = new BehaviorSubject<boolean>(false);
  prescribedMeds$ = new BehaviorSubject([]);

  invoiceTemplateForm: UntypedFormGroup = this.fb.group({
    Caption: new UntypedFormControl(this.dialogData.invoiceTemplate?.Caption || '', RxwebValidators.required()),
    SortOrder: new UntypedFormControl(this.dialogData.invoiceTemplate?.SortOrder || 0),
    Diagnosis: new UntypedFormControl(''),
    IsActive: new UntypedFormControl(this.dialogData.invoiceTemplate?.IsActive || true),
    IsGroup: new UntypedFormControl(this.dialogData.invoiceTemplate?.IsGroup || false),
    Type: new UntypedFormControl(this.dialogData.invoiceTemplate?.Type),
    Lines: this.fb.array([]),
  });

  get lines(): UntypedFormArray {
    return <UntypedFormArray>this.invoiceTemplateForm.get('Lines');
  }

  get diagnosis(): UntypedFormArray {
    return <UntypedFormArray>this.invoiceTemplateForm.get('Diagnosis');
  }

  createDiagnosis(): UntypedFormGroup {
    return this.fb.group({
      Code: [''],
      Description: [''],
    });
  }

  createLine(): UntypedFormGroup {
    return this.fb.group({
      LineType: new UntypedFormControl(''),
      ChargeCode: new UntypedFormControl(''),
      NappiCode: new UntypedFormControl(''),
      Description: new UntypedFormControl(''),
      Quantity: new UntypedFormControl(''),
      Dispense: new UntypedFormControl(false),
    })
  }

  ngAfterViewInit(): void {
    const headerTpe = this.dialogData.invoiceTemplate?.Type;
    this.header = this.dialogData.invoiceTemplate?.Caption ? `Edit ${headerTpe}` : `Add ${headerTpe}`;
    if (this.dialogData.invoiceTemplate?.Diagnosis) {
      this.headerDiagnoses$.next(this.dialogData.invoiceTemplate?.Diagnosis?.map(d => ({ code: d.Code, description: d.Description })) || []);
    }
    if (this.dialogData.invoiceTemplate?.Lines) {
      this.dialogData.invoiceTemplate?.Lines?.forEach(line => {
        if((line.LineType === INVOICE_LINE_TYPE.MEDICINE || !line.LineType) && line.Dispense === false) {
          console.log(line);
          this.prescribedMeds$.next([...this.prescribedMeds$.value, line]);
          this.prescribeWarning$.next(true);
        } else {
          this.addLine(-1, line);
        }
      });
    }
    // Load options when component inits
    getDoc(doc(this.firestore, 'Configuration/TariffCode')).then((docRef) => {
      this.medicineTariffCodes$.next(docRef.get('MedicineOptions'));
      this.consumableTariffCodes$.next(docRef.get('ConsumableOptions'));
    });

    // Load custom charge codes
    // All custom charge codes are loaded here and filtered as needed.
    getDocs(collection(this.firestore, `Practice/${this.auth.selectedBPN}/CustomChargeCode`)).then((res) => {
      this.allCustomChargeCodes$.next(res.docs.map(doc => doc.data() as CustomChargeCodes));
    });

    this.setupDiagnosisSearch();
    this.setupNappiCodeSearch();
    this.setupTariffCodeSearch();
  }

  private setupDiagnosisSearch() {
    this.logger.info('setupDiagnosisSearch');
    this.invoiceTemplateForm.get('Diagnosis')?.valueChanges.pipe(
      // Checking for typeof string here since adding a chip also triggers a change
      filter(val => !!val && typeof val === 'string'),
      debounceTime(200),
      tap(async val => {
        this.diagnosisSearchBusy$.next(true);
        const res = await this.searchService.searchDiagnosis(val);
        const mappedRes = res.hits.filter((hit: any) => !!hit.data || !!hit._highlightResult?.data)
          .map((hit: any) => {
            const code = hit.data?.ICD10Code;
            const highlightedCode = this.formatHighlightedValue(hit._highlightResult?.data?.ICD10Code?.value || hit.data?.ICD10Code);
            const description = hit.data?.ICD10CodeDescription;
            const highlightedDescription = this.formatHighlightedValue(hit._highlightResult?.data?.ICD10CodeDescription?.value || hit.data?.ICD10CodeDescription);
            return {
              code, description, highlightedCode, highlightedDescription
            }
          });

        this.diagnosisSearchResult$.next(orderBy(mappedRes, ['code'], ['asc']));
        this.diagnosisSearchBusy$.next(false);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private formatHighlightedValue(value: string) {
    return value.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>');
  }

  // NappiCode search is shared between all invoice lines
  private setupNappiCodeSearch() {
    this.nappiCodeSearchRequest$.pipe(
      debounceTime(500),
      tap(() => {
        this.nappiCodeSearchResult$.next([]);
      }),
      filter(request => !!request.query),
      tap(async request => {
        try {
          this.nappiCodeSearchBusy$.next(true);
          this.nappiCodeSearchResult$.next(await this.searchService.searchAutocomplete(request.query, request.lineType));
        } catch (err) {
          this.logger.error(err);
        } finally {
          this.nappiCodeSearchBusy$.next(false);
        }
      }),
      untilDestroyed(this)
    ).subscribe()
  }

  // TariffCode search is shared between all invoice lines.
  private setupTariffCodeSearch() {
    this.tariffCodeSearchRequest$.pipe(
      debounceTime(500),
      tap(() => this.tariffCodeSearchResult$.next([])),
      filter(request => !!request.query),
      tap(async request => {
        try {
          this.tariffCodeSearchBusy$.next(true);
          this.tariffCodeSearchResult$.next(await this.searchService.searchAutocomplete(request.query, request.lineType));
        } catch (err) {
          this.logger.error(err);
        } finally {
          this.tariffCodeSearchBusy$.next(false);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  clearDiagnosisSearch() {
    this.invoiceTemplateForm.patchValue({
      Diagnosis: ''
    });

    // Have to clear the value on the input manually since patchvalue doesnt work with chips in the input.
    this.headerDiagSearchInput.nativeElement.value = '';
  }

  onHeaderDiagnosisSearchClosed() {
    this.diagnosisSearchResult$.next([]);
    this.diagnosisSearchBusy$.next(false);
  }

  onHeaderDiagnosisSelected(e: any) {
    if (e.option.value && !this.headerDiagnoses$.value.find(d => d.code === e.option.value.code)) {
      this.headerDiagnoses$.next([...this.headerDiagnoses$.value, e.option.value]);
      this.clearDiagnosisSearch();
    }
  }

  onHeaderDiagnosisRemoved(e: any) {
    const diagnosesValue = this.headerDiagnoses$.value;
    const idx = diagnosesValue.findIndex(d => d.code === e.code);
    diagnosesValue.splice(idx, 1);
    if (idx >= 0) {
      this.headerDiagnoses$.next(diagnosesValue);
    }
  }

  // TODO: try to optimise here, could cause memory leaks.
  // TODO: Handle unsubs for line deletes as well.
  addLine(index: number = -1, lineData?: TemplateLine) {

    const lineGroup = this.setupRowForm(lineData);

    const row = {
      form: lineGroup
    };

    const data = this.templateLinesSource.data;
    if (index > -1) {
      data.splice(index, 0, row)
    } else {
      data.push(row);
    }

    this.templateLinesSource = new MatTableDataSource(data);
  }

  private setupRowForm(lineData?: TemplateLine) {

    const lineGroup = new UntypedFormGroup({
      LineType: new UntypedFormControl(''),
      ChargeCode: new UntypedFormControl(''),
      NappiCode: new UntypedFormControl({ value: '', disabled: false }),
      Procedure: new UntypedFormControl(''),
      Description: new UntypedFormControl(''),
      Quantity: new UntypedFormControl(0),
      Price: new UntypedFormControl(0)
    });

    if (lineData) {
      Object.keys(lineData).forEach(key => {
        if (!lineGroup.contains(key)) {
          lineGroup.addControl(key, new UntypedFormControl(lineData[key]));
        }
      });
      lineGroup.patchValue(lineData);
    }
    // Set defaults for line types
    lineGroup.get('LineType')?.valueChanges.pipe(
      tap(val => {
        const row = {
          ChargeCode: '',
          Quantity: 0
        };

        switch (val) {
          case INVOICE_LINE_TYPE.CONSUMABLE:
          case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
            {
              const defaultConsumableCode = Object.keys(this.consumableTariffCodes$.value)
                .map(key => ({ Code: key, ...this.consumableTariffCodes$.value[key] }))
                .find(code => code.IsDefault);
              row.ChargeCode = defaultConsumableCode?.Code;
              lineGroup.get('NappiCode')?.enable();
            }
            break;
          case INVOICE_LINE_TYPE.MEDICINE:
          case INVOICE_LINE_TYPE.CSTM_MEDICINE:
            {
              const defaultMedicineCode = Object.keys(this.medicineTariffCodes$.value)
                .map(key => ({ Code: key, ...this.medicineTariffCodes$.value[key] }))
                .find(code => code.IsDefault);
              lineGroup.get('NappiCode')?.enable();
              row.ChargeCode = defaultMedicineCode.Code;
            }
            break;
          case INVOICE_LINE_TYPE.PROCEDURE:
          case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
          case INVOICE_LINE_TYPE.ADMIN:
            {
              lineGroup.get('NappiCode')?.disable();
            }
            break;
        }

        lineGroup.patchValue({
          ...row
        }, { emitEvent: false })
      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('NappiCode')?.valueChanges.pipe(
      filter(val => !!val),
      tap((val) => {
        const lineType = lineGroup.get('LineType')?.value;

        switch (lineType) {
          case INVOICE_LINE_TYPE.CONSUMABLE:
          case INVOICE_LINE_TYPE.MEDICINE:
          case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
          case INVOICE_LINE_TYPE.CSTM_MEDICINE:
            this.nappiCodeSearchRequest$.next({ query: val, lineType });
            break;
        }

      }),
      untilDestroyed(this)
    ).subscribe();

    lineGroup.get('ChargeCode')?.valueChanges.pipe(
      filter(val => !!val),
      tap(val => {
        const lineType = lineGroup.get('LineType')?.value;
        switch (lineType) {
          case INVOICE_LINE_TYPE.PROCEDURE:
          case INVOICE_LINE_TYPE.MODIFIER:
          case INVOICE_LINE_TYPE.CSTM_PROCEDURE:
          case INVOICE_LINE_TYPE.ADMIN:
            this.tariffCodeSearchRequest$.next({ query: val, lineType });
            break;
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    return lineGroup;
  }

  //#region Nappi Search
  onNappiCodeSelected(selected: MatAutocompleteSelectedEvent, rowForm: UntypedFormGroup) {
    rowForm.patchValue({
      NappiCode: selected.option.value.Code,
      Description: selected.option.value.Description,
      Quantity: selected.option.value.Quantity || 1,
    });
  }

  onNappiCodeSearchClosed() {
    this.nappiCodeSearchBusy$.next(false);
    this.nappiCodeSearchResult$.next([]);
  }
  //#endregion

  //#region TariffCode Search
  onTariffCodeSearchClosed() {
    this.tariffCodeSearchResult$.next([]);
    this.tariffCodeSearchBusy$.next(false);
  }

  onTariffCodeSelected(selected: MatAutocompleteSelectedEvent, rowForm: UntypedFormGroup) {
    rowForm.patchValue({
      ChargeCode: selected.option.value.Code,
      Description: selected.option.value.Description,
      Quantity: selected.option.value.Quantity || 1,
    });
  }
  //#endregion


  removeLine(index: number) {
    const data = this.templateLinesSource.data;
    data.splice(index, 1);
    this.templateLinesSource = new MatTableDataSource(data);
  }

  async onDone() {
    const invoiceTemplate = this.invoiceTemplateForm.value;

    //remove trailing spaces from description and make it lowercase
    invoiceTemplate.Caption = invoiceTemplate.Caption.trim().toLowerCase();

    const lines = this.templateLinesSource.data;
    const diagnosis = this.headerDiagnoses$.value;
    invoiceTemplate.Diagnosis = [...diagnosis.map((d, idx) => ({ Code: d.code, Description: d.description, OrderNo: idx }))] as Diagnosis[];
    invoiceTemplate.Lines = lines.map((l: any) => {
      const templateLine = l.form.getRawValue() as TemplateLine;
      const isMed = templateLine.LineType === INVOICE_LINE_TYPE.MEDICINE;
      // Dispensed by default if it is a medecine. Prescribed medecines are concated with the dispense field being false
      if(isMed) {
        templateLine.Dispense = isMed;
      }
      return templateLine;
    }).concat(this.prescribedMeds$.value);
    this.dialogRef.close(invoiceTemplate);
  }
}
