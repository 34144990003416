import {Injectable} from '@angular/core';
import * as ExcelJS from "exceljs";
import {getStorage, ref, uploadBytes} from "@angular/fire/storage";
import streamToBlob from 'stream-to-blob'
import {FileUtils} from "../utils/file-utils";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {map} from "rxjs/operators";
import { EmailService } from './email.service';
import { EmailComponent } from '../components/email/email.component';
import { PathUtils } from '../utils/path-utils';
import { Account } from './invoice-helper.service';
import { VariableResolverService } from './variable-resolver.service';
import { ReportUtils } from '../report/report-utils';
import { LogService } from './log.service';
import {getFunctions} from "@angular/fire/functions";
import {getApp} from "@angular/fire/app";
import {httpsCallable} from "@firebase/functions";
import {
  BaseAccountMember,
  BaseInvoice,
  CommunicationTemplate,
  EMAIL_STATUS,
  EMAIL_TYPE,
  EmailRecipient,
  EmailRequest,
  EmailRequestAttachment,
  InvoiceProvider,
  Practice,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  constructor(private emailService: EmailService,
              private variableResolver: VariableResolverService,
              private logger: LogService) {
  }

  private static generateStoragePath(fileName: string, practiceId: string): string {
    const storageFileName = FileUtils.decorateFileNameWithUUID(fileName).replace(/-/g, '_');
    return PathUtils.outboundEmailCollectionStoragePath(practiceId, storageFileName);
  }

  public static async convertReportToFile(report: Report): Promise<File> {
    if (report.format === REPORT_FORMAT_TYPE.EXCEL) {
      const workbook: ExcelJS.Workbook = report.content.data as ExcelJS.Workbook;
      // @ts-ignore
      const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
      const myBlob = new Blob([xls64], { type: ReportUtils.lookupMimeType(REPORT_FORMAT_TYPE.EXCEL) });
      return new File([myBlob], report.fileName, { type: ReportUtils.lookupMimeType(REPORT_FORMAT_TYPE.EXCEL) });
    } else if (report.format === REPORT_FORMAT_TYPE.PDF) {
      const stream = await report.content.data.getStream();
      stream.end();
      const blob = await streamToBlob(stream);
      return new File([blob], report.fileName, { type: ReportUtils.lookupMimeType(REPORT_FORMAT_TYPE.PDF) });
    } else {
      throw new Error('Unable to prepare email attachment. Unknown report type');
    }
  }

  public async openOrSend(report: Report, email: string) {
    if (email) {
      await this.sendEmail(report, email);
    } else {
      this.open(report);
    }
  }

  sendNexusReportRequest(reportName: REPORT_NAME, reportRequest: any) {
    const functions = getFunctions(getApp(), 'europe-west1');
    //functions.customDomain = 'http://localhost:5001/healthbridge-meraki-dev/europe-west1';
    const generateReportNexus = httpsCallable(
      functions,
      'pra-rpt-v1-oncall-generateReportNexus',
    );
    generateReportNexus({
      type: reportName,
      requestData: reportRequest
    });
  }

  public async open(report: Report) {
    const content = report.content;
    if (!content)
      throw new Error('Unable to open report. No content');
    if (report.format === REPORT_FORMAT_TYPE.EXCEL) {
      await this.openXls(content.data as ExcelJS.Workbook, report.fileName);
    } else if (report.format === REPORT_FORMAT_TYPE.PDF) {
      this.openPdf(content.data, report.fileName);
    } else {
      throw new Error('Unable to open report. Unknown report type');
    }
  }

  private async openXls(workbook: any, fileName: string) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    const myBlob = new Blob([xls64], {type: ReportUtils.lookupMimeType(REPORT_FORMAT_TYPE.EXCEL)});
    const url = URL.createObjectURL(myBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  private openPdf(data: any, fileName: string) {
    data.download(fileName);
  }

  public async sendEmail(report: Report, email: string) {
    const file = await ReportService.convertReportToFile(report);
    const storagePath = ReportService.generateStoragePath(report.fileName, report.practiceId);
    await this.uploadAttachmentFile(file, storagePath);
    const attachment: EmailRequestAttachment = {
      FileName: report.fileName,
      MimeType: ReportUtils.lookupMimeType(report.format),
      StoragePath: storagePath
    };
    await this.emailRequest(report.practiceId, report.fileName, email, attachment);
  }

  private async uploadAttachmentFile(file: File, path: string) {
    const storage = getStorage();
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file).then(() => {
      this.logger.info(`Uploaded file. Path: ${path}`);
    });
  }

  private async emailRequest(practiceId: string, fileName: string, to: string, attachment: EmailRequestAttachment) {
    const request: EmailRequest = {
      To: {EmailAddress: to},
      Body: "",
      Subject: ReportUtils.buildReportEmailSubject(fileName),
      Attachments: [attachment],
      Type: EMAIL_TYPE.AD_HOC,
      Status: EMAIL_STATUS.NEW,
      PracticeId: practiceId,
    }
    await this.emailService.createEmail(request);
  }

  public prepareEmailDialog(bottomSheet: MatBottomSheet, templ: CommunicationTemplate, stTempl: CommunicationTemplate, file: File, mailTo: EmailRecipient, cc: string, bpn: string,
                            replyTo: EmailRecipient, type: EMAIL_TYPE, practice?: Practice, account?: Account, member?: BaseAccountMember,
                            invoice?: BaseInvoice, provider?: InvoiceProvider) {
    const subj = this.variableResolver.resolveVariables(
      templ?.Subject ?? stTempl?.Subject,
      practice, account, member, invoice, provider
    );
    const body = this.variableResolver.resolveVariables(
      templ?.Body ?? stTempl?.Body,
      practice, account, member, invoice, provider
    );

    bottomSheet.open(EmailComponent, {
      panelClass: 'flux-bottom-sheet',
      hasBackdrop: false,
      data: {
        files: [file],
        bpn: bpn,
        recipients: mailTo ? [mailTo] : [],
        ccs: cc ? [cc] : [],
        subject: subj,
        body: body,
        replyTo: replyTo,
        type: type,
        context: {
          AccountId: account.Id
        }
      },
    }).afterDismissed().pipe(
      map(res => this.logger.info(res))
    );
  }
}
