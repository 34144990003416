import { Component, OnInit } from '@angular/core';
import { AuthService, NotificationSubscriptionService } from '@meraki-flux/common';

@Component({
  selector: 'meraki-flux-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css'],
})
export class AccessDeniedComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private notificationSubscriptionService: NotificationSubscriptionService
  ) {}

  ngOnInit(): void {}

  async signOut() {
    await this.auth.signOut();
    this.notificationSubscriptionService.unsubscribe();
  }
}
