<button *ngIf="selectedBusiness$ | async as selectedBusiness" class="practice-switch-button"
    [matMenuTriggerFor]="practiceMenu" matRipple (menuOpened)="onMenuOpened()" (menuClosed)="onMenuClosed()"
    [matTooltip]="selectedBusiness.name + ' - ' + selectedBusiness.bpn" matTooltipShowDelay="1000">

    <div class="flex flex-row flex-nowrap overflow-hidden w-full items-center h-full gap-1">
        <label class="text-ellipsis flex-1 overflow-hidden whitespace-nowrap">{{selectedBusiness.name}}</label>
        <mat-icon class="!leading-9 !h-9" fontSet="material-icons-outlined">swap_horizontal_circle</mat-icon>
    </div>

</button>

<mat-menu #practiceMenu class="!max-h-[400px] w-[300px] flux-menu-no-max-width flux-practice-switch-menu">

    <div class="h-full flex flex-col">

        <div class="practice-search-field overflow-hidden">
            <div class="flex items-center flex-row flex-nowrap ">
                <mat-icon class="mx-2">search</mat-icon>
                <input #searchField [formControl]="searchForm" placeholder="Find practice"
                    (click)="$event.stopPropagation()" />

                <button *ngIf="searchField.value" class="!mx-2 !h-8 !w-8 !leading-3" mat-icon-button
                    (click)="$event.stopPropagation(); clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>

            </div>
        </div>

        <ng-container *ngVar="filteredBusinesses$ | async as businesses">
            <div *ngIf="businesses" class="overflow-y-auto overflow-x-hidden flex-1 min-h-[120px]">

                <ng-container *ngVar="searchBusy$ | async as busy">
                    <div *ngIf="busy" class="w-full px-4">
                        <ngx-skeleton-loader count="3" [theme]="skeletonLoaderTheme"
                            appearance="line"></ngx-skeleton-loader>
                    </div>


                    <ng-container *ngIf="!busy">

                        <ng-container *ngIf="businesses?.length > 0; else noResults">
                            <button class="!h-10 !leading-10 !text-xs" *ngFor="let business of businesses" mat-menu-item 
                                matTooltip='{{business.PracticeName}} - {{business.BillingPracticeNumber}}' 
                                [matTooltipPosition]="'left'"
                                (click)="onBusinessSelected(business)"
                                [class.practice-active]="auth.selectedBPN === business.BillingPracticeNumber">
                                {{business.PracticeName}} - {{business.BillingPracticeNumber}}
                            </button>
                        </ng-container>
  

                    </ng-container>
                </ng-container>


            </div>
        </ng-container>
    </div>

</mat-menu>

<ng-template #noResults>
    <div class="h-full w-full flex flex-col justify-center items-center">
        <label>No results</label>
    </div>
</ng-template>