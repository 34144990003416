import { Injectable } from "@angular/core";
import { Firestore, arrayUnion, collection, doc, getDoc, getDocs, query, where, writeBatch } from "@angular/fire/firestore";
import { InvoiceRepository } from "../../repositories/invoice.repository";
import { AuthService } from "../auth.service";
import { PathUtils } from "../../utils/path-utils";
import { FirestoreService } from "../firestore.service";
import { InvoiceService } from "../invoice/invoice.service";
import { NotesService } from "../notes.service";
import { DatePipe } from "@angular/common";
import {
  BaseInvoice,
  CANCEL_STATUS,
  CancelRequest,
  CLAIM_STATUS,
  CopyInvoiceContext, INBOX_STATUS,
  Invoice, Note, NOTE_ORIGIN_TYPE
} from "@meraki-flux/schema";

export interface ElementVisibilityConfig {
  disable?: string[];
  hide?: string[];
}

export interface CaptureInvoiceHelper {
  elementVisibilityConfig: ElementVisibilityConfig,

  isVisible(elementId: string): boolean,
  isDisabled(elementId: string): boolean,
  beforeCapture(): Promise<boolean>,
  afterCapture(success: boolean, invoice: BaseInvoice): Promise<void>,
}

@Injectable({
  providedIn: 'root',
})
export class EditAndResubmitHelperService implements CaptureInvoiceHelper {
  oldInvoice: Invoice;

  constructor(
    private authService: AuthService,
    private invoiceRepository: InvoiceRepository,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private invoiceService: InvoiceService,
    private noteService: NotesService,
    private datePipe: DatePipe
  ) {}

  elementVisibilityConfig = {
    hide: ['saveForLater'],
    disable: ['invoiceType'],
  } as ElementVisibilityConfig;

  isVisible(elementId: string) {
    return !this.elementVisibilityConfig.hide.includes(elementId);
  }

  isDisabled(elementId: string) {
    return this.elementVisibilityConfig.disable.includes(elementId);
  }

  async beforeCapture() {
    const copyContext = this.invoiceRepository.getInvoiceCopyContext() as CopyInvoiceContext;

    // Get old invoice claiminfo status
    const oldInvoice = (await getDoc(doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, copyContext.sourceInvoiceId)))).data() as Invoice;
    this.oldInvoice = oldInvoice;

    // Update old invoice claim status
    await this.firestoreService.setDoc(doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, oldInvoice.Id)), {
      ClaimInfo: {
        ClaimStatus: CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID,
        ClaimsStaustHistory: arrayUnion({
          Status: CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID,
          AppliedAt: new Date(),
          AppliedBy: this.authService.uid
        })
      }
    }, { merge: true });

    // Create the invoice cancellation and wait
    const cancelRequest: CancelRequest = {
      Reason: CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID,
      RequestedBy: this.authService.uid,
      Status: CANCEL_STATUS.NEW
    } as CancelRequest

    const success = await this.invoiceService.cancelInvoice(oldInvoice.Id, cancelRequest);

    return success;
  }

  async afterCapture(success: boolean, invoice: BaseInvoice) {
    if(success) {
      const userNameAndSurname = await this.authService.getUserNameSurname();

      // Add note to new invoice
      const note: Note = {
        OriginType: NOTE_ORIGIN_TYPE.SYSTEM,
        Note: `Invoice ${this.oldInvoice.InvoiceNo} was edited and resubmitted by ${userNameAndSurname} on ${this.datePipe.transform(new Date(), 'dd/MM/yyyy')}`,
      }
      this.noteService.addInvoiceNote(note, invoice.Id);

      // clear invoice number to generate a new one
      delete invoice.InvoiceNo;

      const inboxItems = await this.getFixAndResubmitInboxItems(this.firestore, this.authService.selectedBPN, this.oldInvoice.Id);
      const postAction = writeBatch(this.firestore);
      if(inboxItems.length > 0) {
        inboxItems.forEach(inboxItem => {
          postAction.set(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Inbox/${inboxItem.id}`), { Status: INBOX_STATUS.DISCARDED }, { merge: true});
        })
      }
      postAction.set(doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, invoice.Id)), { ReferencedInvoice: this.oldInvoice.Id }, { merge: true });
      postAction.set(doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, this.oldInvoice.Id)), { ReferencedInvoice: invoice.Id }, { merge: true });
      await postAction.commit();
    } else {
      // Update old invoice claim status
      await this.firestoreService.setDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Invoice/${this.oldInvoice.Id}`), {
        ClaimInfo: {
          ClaimStatus: this.oldInvoice.ClaimInfo.ClaimStatus,
          ClaimsStaustHistory: arrayUnion({
            Status: this.oldInvoice.ClaimInfo.ClaimStatus,
            AppliedAt: new Date(),
            AppliedBy: this.authService.uid
          })
        }
      }, { merge: true });
    }
  }

  async getFixAndResubmitInboxItems(firestore, practiceId: string, invoiceId: string) {
    const inboxItems =  await getDocs(query(
      collection(firestore, `Practice/${practiceId}/Inbox`),
      where('InvoiceId', '==', invoiceId),
      where('Status', '==', INBOX_STATUS.CREATED)
    ));
    return inboxItems.docs;
  }
}

export class DefaultHelperService implements CaptureInvoiceHelper {
  elementVisibilityConfig = {
    hide: [],
    disable: [],
  } as ElementVisibilityConfig;

  isVisible(elementId: string) {
    return !this.elementVisibilityConfig.hide.includes(elementId);
  }

  isDisabled(elementId: string) {
    return this.elementVisibilityConfig.disable.includes(elementId);
  }

  async beforeCapture(): Promise<boolean> {
    return true;
  }

  async afterCapture(success: boolean, invoice: BaseInvoice): Promise<void> {
    //do something
  }
}
