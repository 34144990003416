import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogButton, DialogButtonStyle, DialogOptions, DialogType, OK_BUTTON} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() onBeforeCloseCallback: (buttonId: string) => Promise<boolean>;
  @Input() onCloseCallback: (buttonId: string) => Promise<any>;


  get typeClass() {
    switch (this.dialogData.type) {
      case DialogType.ERROR:
        return 'danger';
      case DialogType.QUESTION:
        return 'success';
      case DialogType.WARNING:
        return 'warning'
      case DialogType.INFO:
      default:
        return 'info';
    }
  }

  get typeIcon() {
    switch (this.dialogData.type) {
      case DialogType.ERROR:
      case DialogType.WARNING:
        return 'warning_amber';
      case DialogType.QUESTION:
        return 'help_outline';
      case DialogType.INFO:
      default:
        return 'info_outline';
    }
  }

  getButtonColor(button: DialogButton) {
    switch (button.style) {
      case DialogButtonStyle.PRIMARY:
        return 'primary';
      case DialogButtonStyle.SECONDARY:
      default:
        return 'secondary';
      case DialogButtonStyle.DANGER:
        return 'danger';
      case DialogButtonStyle.SUCCESS:
        return 'success';
    }
  }

  get buttons() {
    const firstPrimary = this.dialogData.buttons.findIndex(btn => btn.primaryAction);

    return this.dialogData.buttons?.map((btn, idx) => {
      if (idx !== firstPrimary) {
        btn.primaryAction = false;
      }
      return btn;
    }) || [OK_BUTTON];
  }

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogOptions, protected injector: Injector) { }

  ngOnInit(): void {
  }

  protected async onDialogClose(button: DialogButton) {
    const dialogRef = this.injector.get(MatDialogRef<DialogComponent>);

    if (this.onBeforeCloseCallback) {
      const canClose = await this.onBeforeCloseCallback(button.id);
      if (canClose === false) {
        return;
      }
    }

    let data;

    if (this.onCloseCallback) {
      data = await this.onCloseCallback(button.id);
    }

    if (data) {
      dialogRef.close({
        id: button.id,
        data
      })
    } else {
      dialogRef.close(button)
    }
  }

  onEnter(button: DialogButton) {
    if (button.primaryAction) {
      this.onDialogClose(button);
    }
  }

}
