import {
  BaseExcelReportData,
  CellType,
  CellWidth,
  ExcelCellData,
  PaymentPerProviderItem,
  PaymentPerProviderReportModel,
  PaymentPerProviderReportRequest,
  PaymentPerProviderSummary,
  REPORT_NAME,
  Sheet,
  Table
} from "@meraki-flux/schema";

export class PaymentsPerProviderXlsReportBuilder {

    build(reportModel: PaymentPerProviderReportModel, reprotRequest:PaymentPerProviderReportRequest): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = reportModel.PaymentPerProviderReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.PAYMENTS_PER_PROVIDER_REPORT;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, reportModel, reprotRequest);
        excelReportData.data.push(this.buildReportSummary(reportModel.PaymentPerProviderSummary));
        excelReportData.data.push(this.buildReportSheetDetail(reportModel.PaymentPerProviderItem, reprotRequest.Multibranch));

        return excelReportData;
    }

    private buildReportSummary(paymentSummary: PaymentPerProviderSummary[]): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.header = 'Payments Per Provider Report';
        sheet.tables = [];

        const detailsTable: Table = {};
        //detailsTable.name = '';
        detailsTable.headers = [
            {value: 'Provider', bold: true, width: CellWidth.M, wordWrap: true},
            {value: 'Patient payments', hAlignment: "right", bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Med aid payments', hAlignment: "right", bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Insurer payments', hAlignment: "right", bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Totals', hAlignment: "right", bold: true, width: CellWidth.XS, wordWrap: true},
        ];

        let totalPatientPayments = 0;
        let totalMedAidPayments = 0;
        let totalInsurerPayments = 0;
        let totalAll = 0;

        const detailsRows = [];
        paymentSummary.forEach((item: PaymentPerProviderSummary) => {

            const total = (item.PatientPayments? item.PatientPayments: 0)
                        + (item.MedAidPayments? item.MedAidPayments: 0)
                        + (item.InsurerPayments? item.InsurerPayments : 0);
            totalPatientPayments += item.PatientPayments? item.PatientPayments : 0;
            totalMedAidPayments += item.MedAidPayments? item.MedAidPayments : 0;
            totalInsurerPayments += item.InsurerPayments? item.InsurerPayments : 0;
            totalAll += total;

            const row: ExcelCellData[] = [];
            row.push({value: item.Provider});
            row.push({value: item.PatientPayments? item.PatientPayments : 0, type: CellType.CURRENCY});
            row.push({value: item.MedAidPayments? item.MedAidPayments : 0, type: CellType.CURRENCY });
            row.push({value: item.InsurerPayments? item.InsurerPayments : 0, type: CellType.CURRENCY});
            row.push({value: total, type: CellType.CURRENCY, bold: true});
            detailsRows.push(row);
        });


        const row: ExcelCellData[] = [];
        row.push({value: 'Totals', bold: true});
        row.push({value: totalPatientPayments? totalPatientPayments : 0 , type: CellType.CURRENCY, bold: true});
        row.push({value: totalMedAidPayments? totalMedAidPayments : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalInsurerPayments? totalInsurerPayments : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalAll? totalAll : 0, type: CellType.CURRENCY, bold: true});
        detailsRows.push(row);

        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildReportSheetDetail(paymentDetails: PaymentPerProviderItem[], addBranchColumn?: boolean): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Detail';
        sheet.header = 'Payments Per Provider Report';
        sheet.tables = [];

        const detailsTable: Table = {};
        //detailsTable.name = '';

        detailsTable.headers = [
            {value: 'Payment date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Recon date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment ref.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Receipt no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment amount', bold: true, width: CellWidth.XS},
            {value: 'Paid by', bold: true, width: CellWidth.XS},
            {value: 'Account no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Patient', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Invoice amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Provider', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.XS});
        }

        const detailsRows = [];
        paymentDetails.forEach((item: PaymentPerProviderItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.PaymentDate});
            row.push({value: item.PaymentType});
            row.push({value: item.ReconDate });
            row.push({value: item.PaymentRef, hAlignment: "left"});
            row.push({value: item.ReceiptNo, hAlignment: "left"});
            row.push({value: item.PaymentAmount, type: CellType.CURRENCY});
            row.push({value: item.PaidBy});
            row.push({value: item.AccountNo});
            row.push({value: item.Patient});
            row.push({value: item.InvoiceNo, hAlignment: "left"});
            row.push({value: item.InvoiceAmount, type: CellType.CURRENCY});
            row.push({value: item.Provider});
            if (addBranchColumn)
                row.push({value: item.Branch});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, reportModel: PaymentPerProviderReportModel, reprotRequest:PaymentPerProviderReportRequest) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: reportModel.PaymentPerProviderReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: reportModel.PaymentPerProviderReportInfo.PracticeId, type: CellType.GENERAL}
        });

        if (reprotRequest.Multibranch) {
            excelReportData.parameters.push({
                name: 'Branch',
                data: {value: reportModel.PaymentPerProviderReportInfo.Branch, type: CellType.GENERAL}
            });
        }

        excelReportData.parameters.push({
            name: 'Date Range',
            data: {value: reportModel.PaymentPerProviderReportInfo.DateRange, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Provider',
            data: {value: reportModel.PaymentPerProviderReportInfo.Provider, type: CellType.GENERAL}
        });
    }
}
