<div class="w-screen h-screen flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 xs:justify-center">
  <div
    class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-primary dark:border-r">

    <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
      preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
      <g class="fill-current text-primary-600">
        <circle r="270" cx="410" cy="320" class="opacity-30"></circle>
        <circle r="234" cx="700" cy="500" class="opacity-40"></circle>
        <circle r="270" cx="560" cy="93" class="opacity-25"></circle>
      </g>
    </svg>
  </div>
  <div
    class="md:flex md:items-center md:justify-start w-full sm:w-auto md:h-full md:w-3/4 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
    <div class="w-full md:w-1/2 max-w-80 sm:w-80 mx-auto sm:mx-0">
      <div class="w-3/4 flex items-center text-primary-500 header">
        Reset password
      </div>

      <form class="mt-8" [formGroup]="form" novalidate (ngSubmit)="onSubmit()">

        <mat-form-field class="w-full mb-1.5" appearance="outline">
          <mat-label>Password</mat-label>
          <input id="password" matInput type="password" formControlName="password" #passwordField>
          <button mat-icon-button type="button"
            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
            matSuffix>
            <mat-icon class="icon-size-5">{{passwordField.type === 'password' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <mat-error *ngIf="form.controls['password'].hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.controls['password'].hasError('password') && form.controls['password'].errors">
            {{form.controls['password'].errors['password']['message']}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full mb-1.5" appearance="outline">
          <mat-label>Confirm password</mat-label>
          <input id="confirmPassword" matInput type="password" formControlName="confirmPassword" #confirmPasswordField>
          <button mat-icon-button type="button"
            (click)="confirmPasswordField.type === 'password' ? confirmPasswordField.type = 'text' : confirmPasswordField.type = 'password'"
            matSuffix>
            <mat-icon class="icon-size-5">{{confirmPasswordField.type === 'password' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <mat-error *ngIf="form.controls['confirmPassword'].hasError('required')">
            Confirm password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.controls['confirmPassword'].hasError('compare')">
            Passwords must match
          </mat-error>
        </mat-form-field>

        <div class="btns-group">
          <button mat-flat-button routerLink="../login" class="secondary-btn">Cancel</button>
          <button id="btnReset" mat-flat-button class="primary-btn" [disabled]="loading" type="submit">Reset
            <mat-icon *ngIf="loading">
              <mat-progress-spinner [diameter]="24" mode="indeterminate" color="primary"></mat-progress-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
