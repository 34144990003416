import {BulkStatementRequestData} from "./bulk-statement.request";
import {EmailRequestAttachment} from "./email.models";


export interface BulkMessageAggr {
  practiceId?: string;
  date: Date;
  userId: string;
  sendVia: "EMAIL" | "SMS";
  attachments?: EmailRequestAttachment[]
  formData?: any;
  conditions?: string;
  medicines?: string;
  procedures?: string;
  template?: string;
  messages?: BulkMessageRequest[]
}

export interface BulkMessageRequest {
  bulkMessageId?: string;
  practiceId?: string;
  accountId?: string;
  patientId?: string;
  invoiceId?: string;
  providerId?: string;
  to: string | string[];
  cc?: string | string[];
  subjectTemplate?: string;
  bodyTemplate: string;
  attachments?: EmailRequestAttachment[]
  date: Date;
  userId: string;
  sendVia: "EMAIL" | "SMS";
}

export interface BulkError {
  To: string;
  Error: string;
}

export class BulkHistory {
  Date: Date;
  SendVia?: "EMAIL" | "SMS";
  UserId?: string;
  TotalMessages? = 0;
  FailedMessages? = 0;
  Type: BulkHistoryType
  Status: "PROCESSING" | "COMPLETED"
  RequestData? : BulkStatementRequestData | BulkMessageRequestData;
  Errors?: BulkError[];
}

export interface BulkMessageRequestData {
  LastVisitWithin?: string;
  OnlyAccountHolders?: boolean;
  Branch?: string;
  VisitDateFrom?: string;
  VisitDateTo?: string;
  FromAgeInt?: string;
  ToAgeInt?: string;
  FromAgeText?: string;
  ToAgeText?: string;
  AccountType?: string;
  AccountStatus?: string;
  Gender?: string;
  Scheme?: string;
  IncludeConditionsAndMedications?: boolean;
  IncludeSecondaryConditions?: boolean;
  Conditions?: string;
  Medicines?: string;
  Procedures?: string;
  Template?: string;
}

export enum BulkHistoryType {
  BULK = "Bulk",
  BULK_STATEMENT = "Bulk statement"
}

export class BulkIds {
  bulkEmailId?: string;
  bulkSmsId?: string;
}
