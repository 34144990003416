import { Injectable } from '@angular/core';
import { BaseExcelReportData, InboxActionsReportRequest, REPORT_FORMAT_TYPE, REPORT_NAME, Report } from '@meraki-flux/schema';
import { InboxActionsXlsReportDataBuilder, InboxActionsReportBuilder, BaseExcelGenerator } from '@meraki-flux/purejs'
import { ReportUtils } from '../report-utils';

@Injectable({
  providedIn: 'root'
})
export class InboxActionsReportService {

  async generateReport(reportRequest: InboxActionsReportRequest): Promise<Report> {
    
    const fileName: string = await ReportUtils.buildEmailMessagingReportFileName(
      'InboxActions',reportRequest.Practice, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.INBOX_ACTIONS_REPORT,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }
  
  private async getReportGenerator(reportRequest: InboxActionsReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: InboxActionsReportRequest): Promise<BaseExcelReportData> {
    return new InboxActionsXlsReportDataBuilder().build(
      await new InboxActionsReportBuilder().build(reportRequest));
  }
}
