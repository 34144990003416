import { Injectable } from "@angular/core";
import { createStore, Store, setProp, select, withProps } from "@ngneat/elf";
import {
  addEntities,
  deleteEntities,
  getActiveEntity,
  getActiveId,
  getAllEntitiesApply,
  getEntitiesCountByPredicate,
  getEntity,
  hasEntity,
  selectActiveEntity,
  selectEntity,
  selectMany,
  setActiveId,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import * as _ from "lodash";
import {Account, AccountMember, MEMBER_STATUS} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class AccountRepository {

  private readonly accountStore: Store;
  private readonly accountMemberStore: Store;

  activeAccount$: Observable<Account>
  activeAccountMainMember$: Observable<AccountMember>;

  constructor() {
    this.accountStore = createStore({ name: 'account' },
      withEntities({ idKey: '_id' }),
      withActiveId(),
      withProps({}));

    this.accountMemberStore = createStore({ name: 'accountMember' },
      withEntities({ idKey: '_id' }));

    this.activeAccount$ = this.accountStore.pipe(selectActiveEntity());
    this.activeAccountMainMember$ = this.activeAccount$.pipe(
      switchMap(account => this.accountMember$(account.MainMember))
    );
  }

  property$(key: string) {
    return this.accountStore.pipe(
      select(state => state[key])
    );
  }

  setProperty(key: string, value: any) {
    this.accountStore.update(setProp(key, value));
  }

  account$(id: string): Observable<Account> {
    return this.accountStore.pipe(selectEntity(id));
  }

  accountMember$(id: string): Observable<AccountMember> {
    return this.accountMemberStore.pipe(selectEntity(id));
  }

  accountMembers$(accountId: string): Observable<AccountMember[]> {
    return this.account$(accountId).pipe(
      switchMap(account => this.accountMemberStore.pipe(selectMany(_.uniq(account.Members))))
    )
  }

  getActiveAccountId() {
    return getActiveId(this.accountStore.state);
  }

  getActiveAccount(): Account {
    return this.accountStore.query(getActiveEntity());
  }

  getAccount(id: string): Account {
    return this.accountStore.query(getEntity(id));
  }

  accountExists(accountId: string) {
    return this.accountStore.query(hasEntity(accountId));
  }

  addAccount(account: Account) {
    // remove leading and trailing spaces from all string values
    account = this.trimStrings(account);
    this.accountStore.update(addEntities(account));
  }

  updateAccount(account: Account) {
    // remove leading and trailing spaces from all string values
    account = this.trimStrings(account);
    this.accountStore.update(updateEntities(account._id, account));
  }

  deleteAccount(id: string) {
    this.accountStore.update(deleteEntities(id));
  }

  setActiveAccount(id: string) {
    this.accountStore.update(setActiveId(id));
  }

  addAccountMembers(members: AccountMember[]) {
    // remove leading and trailing spaces from all string values
    members = members.map(member => this.trimStrings(member));
    this.accountMemberStore.update(upsertEntities(members));
  }

  deleteAccountMember(id: string) {
    this.accountMemberStore.update(deleteEntities(id));
  }

  deleteAccountMembers(ids: string[]) {
    this.accountMemberStore.update(deleteEntities(ids));
  }

  accountMemberExists(memberId: string) {
    return this.accountMemberStore.query(hasEntity(memberId));
  }

  getAccountMember(id: string): AccountMember {
    return this.accountMemberStore.query(getEntity(id));
  }

  getAccountMainMember(accountId: string) {
    const account = this.getAccount(accountId);
    return this.getAccountMember(account.MainMember);
  }

  getAccountMembers(accountId: string): AccountMember[] {
    const account = this.getAccount(accountId);
    return this.accountMemberStore.query(getAllEntitiesApply({
      filterEntity: (entity) => account.Members?.includes(entity._id)
    }));
  }

  resetAccountMember(id: string) {
    this.accountMemberStore.update(updateEntities(id, (entity) => ({ _id: id })));
  };

  updateAccountMember(member: AccountMember) {
    // remove leading and trailing spaces from all string values
    member = this.trimStrings(member);
    this.accountMemberStore.update(updateEntities(member._id, member));
  }

  getActiveAccountMemberCount(): number {
    const account: Account = this.accountStore.query(getActiveEntity());
    return this.accountMemberStore.query(getEntitiesCountByPredicate(entity => account.Members.includes(entity.Id) && MEMBER_STATUS.ACTIVE === entity.AccountMemberStatus));
  }

  getAccountMemberCount(accountId: string): number {
    const account: Account = this.accountStore.query(getEntity(accountId));
    return this.accountMemberStore.query(getEntitiesCountByPredicate(entity => account.Members.includes(entity.Id)));
  }

  setAccountSearchFilterState(state: any) {
    this.accountStore.update(setProp('AccountSearchFilterState', state));
  }

  getAccountSearchFilterState() {
    return this.accountStore.state?.AccountSearchFilterState;
  }

  setTransactionsFilterState(state: any) {
    this.accountStore.update(setProp('TransactionsFilterState', state));
  }

  getTransactionsFilterState() {
    return this.accountStore.state?.TransactionsFilterState;
  }

  clearTransactionsFilterState() {
    this.accountStore.update(setProp('TransactionsFilterState', null));
  }

  setCollectableAmountsReportFilterState(state: any) {
    this.accountStore.update(setProp('CollectableAmountsReportFilterState', state));
  }

  getCollectableAmountsReportFilterState() {
    return this.accountStore.state?.CollectableAmountsReportFilterState;
  }

  clearCollectableAmountsReportFilterState() {
    this.accountStore.update(setProp('CollectableAmountsReportFilterState', null));
  }

  setBulkEmailSmsFilterState(state: any) {
    this.accountStore.update(setProp('BulkEmailSmsFilterState', state));
  }

  getBulkEmailSmsFilterState() {
    return this.accountStore.state?.BulkEmailSmsFilterState;
  }

  setSuspenseAccountSearchFilterState(state: any) {
    this.accountStore.update(setProp('SuspenseAccountSearchFilterState', state));
  }

  getSuspenseAccountSearchFilterState() {
    return this.accountStore.state?.SuspenseAccountSearchFilterState;
  }

  clearSuspenseAccountSearchFilterState() {
    this.accountStore.update(setProp('SuspenseAccountSearchFilterState', null));
  }

  // receive an object and check if any of its values are strings,
  // if so, trim the string and return the object
  // if you have a nested object, you can pass the nested object as a parameter
  trimStrings(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'string' && obj[key]) {
        obj[key] = obj[key].trim();
      }
      if (typeof obj[key] === 'object') {
        this.trimStrings(obj[key]);
      }
    }
    return obj;
  }
}
