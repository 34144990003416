import {
  AssistantDetailsItem,
  AssistantProviderModel,
  AssistantReportModel, AssistantSummaryItem,
  BaseExcelReportData, CellType, CellWidth, ExcelCellData,
  REPORT_NAME,
  Sheet, Table
} from "@meraki-flux/schema";

export class AssistantXlsReportDataBuilder {


  build(assistantReportModel: AssistantReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.practiceId = assistantReportModel.AssistantReportInfo.PracticeId;
    excelReportData.name = REPORT_NAME.ASSISTANT;
    excelReportData.data = [];

    this.buildReportInfo(excelReportData, assistantReportModel);

    //const isOptometrist: boolean = assistantReportModel.AssistantReportInfo.Speciality == Speciality.OPTOMETRIST;

    if (!assistantReportModel.AllProvidersModel){
      assistantReportModel.ProviderModels.forEach((providerModel) => {
          excelReportData.data.push(this.buildReportSheetSummary(providerModel, assistantReportModel));
          excelReportData.data.push(
            this.buildReportSheetDetail(providerModel, assistantReportModel.AssistantReportInfo.IsMultiBranch, false)
          );
      });
    }
    else{
        excelReportData.data.push(this.buildReportSheetSummary(assistantReportModel.AllProvidersModel, assistantReportModel));
        assistantReportModel.ProviderModels.forEach((providerModel) => {
          if(providerModel.DetailsTable.length > 0){
              excelReportData.data.push(
                  this.buildReportSheetDetail(providerModel, assistantReportModel.AssistantReportInfo.IsMultiBranch,true)
              );
          }
        });
    }

    return excelReportData;
  }

  private buildReportSheetSummary(providerModel: AssistantProviderModel, assistantReportModel: AssistantReportModel): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary'
    sheet.header = `Summary of assistants from ${assistantReportModel.AssistantReportInfo.DateFrom} to ${assistantReportModel.AssistantReportInfo.DateTo}` ;
    sheet.tables = [];

    const summaryTable: Table = {};
    //summaryTable.name = 'Summary';
    summaryTable.headers = [
    {value: 'Assisting provider', bold: true, width: CellWidth.S},
    {value: 'No. of invoices', bold: true, width: CellWidth.XS},
    {value: '0008 line total', bold: true, width: CellWidth.XS},
    {value: '0009 line total', bold: true, width: CellWidth.XS},
    {value: 'Total invoiced amount', bold: true, width: CellWidth.XS}
    ];

    let totalNoOfInvoices = 0;
    let total0008Line = 0;
    let total0009Line = 0;
    let totalInvoicedAmount = 0;
    const rows = [];
    providerModel.SummaryTable.sort((a, b) => a.AssistingProvider > b.AssistingProvider ? 1 : -1).forEach((item: AssistantSummaryItem) => {

      totalNoOfInvoices += item.NoOfINvoices;
      total0008Line += item.LineTotal0008;
      total0009Line += item.LineTotal0009;
      totalInvoicedAmount += item.TotalInvoicedAmount;

      const row: ExcelCellData[] = [];
      row.push({value: item.AssistingProvider});
      row.push({value: item.NoOfINvoices, type: CellType.NUMBER});
      row.push({value: item.LineTotal0008, type: CellType.CURRENCY});
      row.push({value: item.LineTotal0009, type: CellType.CURRENCY});
      row.push({value: item.TotalInvoicedAmount, type: CellType.CURRENCY});
      rows.push(row);
    });
    const row: ExcelCellData[] = [];
    row.push({value: 'Total', bold: true});
    row.push({value: totalNoOfInvoices, type: CellType.NUMBER, bold: true});
    row.push({value: total0008Line, type: CellType.CURRENCY, bold: true});
    row.push({value: total0009Line, type: CellType.CURRENCY, bold: true});
    row.push({value: totalInvoicedAmount, type: CellType.CURRENCY, bold: true});
    rows.push(row);
    summaryTable.rows = rows;
    sheet.tables.push(summaryTable);

    return sheet;

    }

    private buildReportSheetDetail(providerModel: AssistantProviderModel, addBranchColumn?: boolean, isAllProvider?: boolean): Sheet {
      const sheet: Sheet = {};
      sheet.name = isAllProvider? providerModel.ProviderName.substring(0,30) : 'Detail';
      sheet.header = 'Assistant Report';
      sheet.tables = [];


      const detailsTable: Table = {};
      //detailsTable.name = 'Assisting Details';
      detailsTable.headers = [
        {value: 'Assisting provider', bold: true, width: CellWidth.S},
        {value: 'Speciality', bold: true, width: CellWidth.S},
        {value: 'Date of service', bold: true, width: CellWidth.XS},
        {value: 'Treating provider', bold: true, width: CellWidth.S},
        {value: 'Patient', bold: true, width: CellWidth.S},
        {value: 'Account no.', bold: true, width: CellWidth.XS},
        {value: 'Invoice type', bold: true, width: CellWidth.XS},
        {value: 'Invoice no.', bold: true, width: CellWidth.XS},
        {value: 'Tariff code', bold: true, width: CellWidth.XS},
        {value: 'Invoice amount', bold: true, width: CellWidth.XS},
      ];
      if (addBranchColumn) {
      detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.M});
      }

      const detailsRows = [];
      providerModel.DetailsTable.sort((a, b) => a.AccountNo > b.AccountNo ? 1 : -1).forEach((item: AssistantDetailsItem) => {
        const row: ExcelCellData[] = [];
        row.push({value: item.AssistingProvider});
        row.push({value: item.Speciality});
        row.push({value: item.DateOfService, type: CellType.GENERAL});
        row.push({value: item.TreatingProviderName});
        row.push({value: item.PatientName});
        row.push({value: item.AccountNo});
        row.push({value: item.InvoiceType});
        row.push({value: item.InvoiceNo});
        row.push({value: item.TariffCode});
        row.push({value: item.AmountBilled, type: CellType.CURRENCY});
        if (addBranchColumn)
          row.push({value: item.Branch});

        detailsRows.push(row);

      });
      detailsTable.rows = detailsRows;
      sheet.tables.push(detailsTable);

      return sheet;

      }

  private buildReportInfo(excelReportData: BaseExcelReportData, assistantReportModel: AssistantReportModel) {
      excelReportData.parameters = [];

      excelReportData.parameters.push({
          name: 'Practice',
          data: {value: assistantReportModel.AssistantReportInfo.Practice, type: CellType.GENERAL}
      });
      if (assistantReportModel.AssistantReportInfo.IsMultiBranch) {
          excelReportData.parameters.push(
              {
                  name: 'Branch',
                  data: {value: assistantReportModel.AssistantReportInfo.Branch, type: CellType.GENERAL}
              });
      }
      excelReportData.parameters.push(
        {
            name: 'Assisting Provider',
            data: {value: assistantReportModel.AssistantReportInfo.AssistingProvider, type: CellType.GENERAL}
        });
      excelReportData.parameters.push(
          {
              name: 'Treating Provider',
              data: {value: assistantReportModel.AssistantReportInfo.TreatingProvider, type: CellType.GENERAL}
          });
      excelReportData.parameters.push(
          {
              name: 'Date Range',
              data: {value: assistantReportModel.AssistantReportInfo.DateRange, type: CellType.GENERAL}
          });
  }

  // build(reportModel: AssistantReportRequest): BaseExcelReportData {
  //   const excelReportData: BaseExcelReportData = {};
  //   excelReportData.data = [];
  //   excelReportData.parameters = [];
  //   excelReportData.parameters.push({
  //     name: "LastVisitWithin",
  //     data: {
  //       value: reportModel.DateFrom
  //     }
  //   })
  //   const sheet: Sheet = {};
  //   sheet.name = 'Assistant Details';
  //   sheet.tables = [];
  //   const dataTable: Table = {};
  //   dataTable.headers = [
  //     {value: 'Assisting provider', bold: true},
  //     {value: 'Speciality', bold: true},
  //     {value: 'Date of service', bold: true},
  //     {value: 'Patient', bold: true},
  //     {value: 'Account no.', bold: true},
  //     {value: 'Invoice no.', bold: true },
  //     {value: 'Tariff code', bold: true },
  //     {value: 'Invoice type', bold: true },
  //     {value: 'Invoice line amount', bold: true },
  //     {value: 'Branch?', bold: true},
  //   ];
  //   const rows = [];
  //   reportModel.Invoices.forEach(item => {
  //     const row: CellData[] = [];
  //     row.push({value: item.AssistingProvider?.FullName});
  //     row.push({value: item.AssistingProvider?.Speciality});
  //     row.push({value: item.DateOfService});
  //     //row.push({value: item.AccountHolder ? "Yes" : "No"});
  //     row.push({ value: item.TreatingProvider?.FullName });
  //     row.push({ value: item.Patient.Name + ' ' + item.Patient.Surname });
  //     row.push({ value: item.Account.AccountNo });
  //     row.push({ value: item.InvoiceNo });
  //     row.push({ value: item.Account.OptionCode });
  //     row.push({ value: item.InvoiceNo });
  //     row.push({ value: item.AmountBilled });
  //     row.push({ value: item.Branch });
  //     rows.push(row);
  //   })
  //   dataTable.rows = rows;
  //   sheet.tables.push(dataTable);
  //   excelReportData.data.push(sheet);

  //   return excelReportData;
  // }
}
