import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  doc,
  Firestore,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '../../../utils/date-utils';
import { PathUtils } from '../../../utils/path-utils';
import { AuthService } from '../../auth.service';
import {CalendarEvent} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class CalendarEventQueryService {
  constructor(private firestore: Firestore, private authService: AuthService) {}

  find$(
    wheres: QueryConstraint[],
    practiceId: string = this.authService.selectedBPN
  ): Observable<CalendarEvent[]> {
    return collectionSnapshots(
      query(
        collection(this.firestore, PathUtils.calendarEventCollectionPath(practiceId)),
        ...wheres
      )
    ).pipe(map((arr) => arr.map((d) => this.toCalendarEvent(d))));
  }

  async find(wheres: QueryConstraint[], practiceId: string = this.authService.selectedBPN, providerIds?: string[]): Promise<CalendarEvent[]> {
    return (
      await getDocs(
        query(
          collection(this.firestore, PathUtils.calendarEventCollectionPath(practiceId)),
          ...wheres
        )
      )
    ).docs.filter(x => {
      if (providerIds) {
        return providerIds.includes(x.get('TreatingProvider') as string);
      } else {
        return true;
      }
    }).map((d) => this.toCalendarEvent(d));
  }

  private toCalendarEvent(eventDoc: any) {
    const event = { ...eventDoc.data(), Id: eventDoc.id } as CalendarEvent;
    const result = {
      ...event,
      StartTime: DateUtils.toDate(event.StartTime),
      EndTime: DateUtils.toDate(event.EndTime),
      Metadata: {
        Id: event.Id,
        ViewId: event.RecurrentInfo?.Index
          ? `${event.RecurrentInfo.RootEventId}_${event?.RecurrentInfo?.Index}`
          : event.Id,
      },
    } as CalendarEvent;
    if (result?.RecurrentInfo?.Rule?.Repeat?.EndDate) {
      result.RecurrentInfo.Rule.Repeat.EndDate = DateUtils.toDate(
        result.RecurrentInfo.Rule.Repeat.EndDate
      );
    }
    return result;
  }

  async get(id: string) {
    const eventDoc = await getDoc(
      doc(this.firestore, PathUtils.calendarEventPath(this.authService.selectedBPN, id))
    );
    return this.toCalendarEvent(eventDoc);
  }
}
