import {Injectable} from "@angular/core";
import {ReportUtils} from "../report-utils";
import {PaymentSummaryReportBuilder} from "./payment-summary-report-builder";
import {
  BaseExcelReportData,
  BasePdfReportData,
  PaymentSummaryReportModel,
  PaymentSummaryReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {
  BaseExcelGenerator,
  BasePdfGenerator,
  IReportGenerator,
  PaymentSummaryPdfReportDataBuilder, PaymentSummaryXlsReportDataBuilder
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class PaymentSummaryReportService {

  constructor(private paymentSummaryReportBuilder: PaymentSummaryReportBuilder) {
  }

  async generateReport(reportRequest: PaymentSummaryReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPaymentSummaryReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: IReportGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.PAYMENT_SUMMARY,
      format: reportRequest.ReportFormatType,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    }
  }

  private async getReportGenerator(reportRequest: PaymentSummaryReportRequest): Promise<IReportGenerator> {
    const reportModel: PaymentSummaryReportModel = await this.paymentSummaryReportBuilder.build(reportRequest);

    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      const pdfData: BasePdfReportData = new PaymentSummaryPdfReportDataBuilder().build(reportModel);
      return new BasePdfGenerator(pdfData);
    } else if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      const xlsData: BaseExcelReportData = new PaymentSummaryXlsReportDataBuilder().build(reportModel);
      return new BaseExcelGenerator(xlsData);
    } else {
      throw new Error("Report format is not supported.");
    }
  }
}
