import {AGE_ANALYSIS_DATE_RANGE_TYPE} from './reports/age-analysis-report.model';
import {ACCOUNT_STATUS, BaseAccount} from './account.models';
import {CalendarEvent} from './calendar.models';
import {BaseInvoice, INVOICE_TYPE} from './invoice.models';
import {BaseAccountMember} from './member.models';
import {Payment, Transaction} from "./payment.models";
import {Branch, Practice} from './practice.models';
import {Provider} from './provider.models';
import {User} from './user.models';
import {SmsRequest} from './sms.models';
import {BulkSMS} from './addon.models';
import {RAJournal} from './reports/journal-report.model';
import {CustomChargeCodeStatus, CustomChargeCodeType} from "./custom-charge-codes.models";
import {RAClaimSuspenseReport} from './reports/ra-suspense-account-report.model';
import {EmailMessagingReportList} from './reports/email-messaging-report.model';
import {UnroutableClaimsDataExtractionList} from './reports/unroutable-claims-report.model';
import {RaDetails} from './reports/ra-statement-report.model';
import {InvoiceExtractionList, TransactionsExtractionList} from './reports/reversed-transactions-report.model';
import {InsurerDetails} from './reports/medical-insurer-report.model';
import { EmailInfo, ReportRequestData } from './reports/common.model';
import { InboxActionsDataExtractionList } from './reports/inbox-actions-report.model';

export interface Report {
  reportName: string;
  fileName?: string;
  practiceId?: string;
  format?: REPORT_FORMAT_TYPE;
  content?: ReportContent;
  emailInfo?: EmailInfo;
}

export interface ReportContent {
  data: any;
}

export interface ReportDashboardItem {
  Category?: REPORT_DASHBOARD_CATEGORY,
  Description?: string,
  Name?: string,
  RouterLink?: string,
  Favourite?: boolean,
}

export interface ReportInfo {
  PracticeId?: string,
  Practice?: string,
  DateRange?: string,
  IsMultiBranch: boolean,
  IncludeAccountNotes: boolean,
  DateRangeType: AGE_ANALYSIS_DATE_RANGE_TYPE
}

export enum REPORT_DASHBOARD_CATEGORY {
  CALENDAR = 'Calendar',
  COLLECTION = 'Collection',
  FINANCIAL = 'Financial',
  OPERATIONAL = 'Operational',
  REMITTANCES = 'Remittances',
  AUDITING = 'Auditing/Tracking',
  POWERBI = 'Business Insights',
}

export enum DATE_RANGE_TYPE {
  DATE_OF_SERVICE = 'Date of service',
  DATE_OF_SUBMISSION = 'Date of submission',
}

export enum DATE_RANGE_TYPE_TRN_REPORT {
  DATE_OF_SERVICE = 'Invoice date of service',
  DATE_OF_SUBMISSION = 'Invoice submission date',
}

export enum WRITE_OFF_DATE_RANGE_TYPE {
  DATE_OF_SERVICE = 'Invoice date of service',
  DATE_OF_SUBMISSION = 'Invoice date of submission',
  DATE_OF_WRITE_OFF = 'Date of write-off',
}

export enum ORDERING_MODE {
  ALPHABETICALLY,
  NUMERICALLY
};

export enum PAYMENT_CORRECTION_DATE_RANGE_TYPE {
  DATE_OF_SERVICE = 'Invoice date of service',
  DATE_OF_SUBMISSION = 'Invoice date of submission',
  DATE_OF_PAYMENT_CORRECTION = 'Date of payment correction',
}

export enum UNROUTABLE_CLAIMS_DATE_RANGE_TYPE {
  DATE_OF_SERVICE = 'Date of service',
  DATE_OF_SUBMISSION = 'Date of submission',
}

export enum PAYMENT_DETAILS_REPORT_DATE_RANGE_TYPE {
  PAYMENT_DATE = 'Payment date',
  TRANSACTION_CREATE_DATE = 'Transaction create date',
}

export enum REVERSED_TRANSACTIONS_DATE_RANGE_TYPE{
  DATE_OF_REVERSAL = 'Reversal date',
  DATE_OF_TRANSACTION = 'Original transaction date',
}
export enum REPORT_FORMAT_TYPE {
  EXCEL = 'Excel',
  PDF = 'Pdf',
}

export enum REPORT_NAME {
  ACCOUNT_DETAILS = 'Account Details Report',
  CLAIM_DETAILS = 'Claim Details Report',
  PROOF_OF_CLAIM_SUBMISSION = 'Proof Of Claim Submission Report',
  INVOICE = 'Invoice Report',
  INCOMPLETE_CONTACT_DETAILS = 'Incomplete Contact Details Report',

  COLLECTABLE_AMOUNTS = 'Collectable Amounts Report',
  STATEMENT = 'Statement Report',
  APPOINTMENT = 'Appointments Report',
  APPOINTMENT_WITH_INVOICE = 'Appointments (Incl. Invoices) Report',
  CLAIMS_AND_INVOICES = 'Claims and Invoices Report',
  PROOF_OF_PAYMENT = 'Proof of Payment Report',
  AGE_ANALYSIS = 'Age Analysis Report',
  PAYMENT_SUMMARY = 'Payment Summary Report',
  MONTHLY_TRANSACTION = 'Monthly Transaction Report',
  CREDIT_NOTES = 'Credit Notes Report',
  WRITE_OFF = 'Write Off Report',
  PAYMENT_CORRECTIONS = 'Payment Corrections report',
  REFERRAL = 'Referral Report',
  ERA_DS = 'eRA Delivery Schedule Report',
  EMAIL_MESSAGING = 'Email Report',
  ASSISTANT = 'Assistant Report',
  POWERBI = 'Business Insights Report',
  SMS = 'SMS report',
  PATIENT_LIST = 'Patient List Report',
  CUSTOM_CHARGE_CODES = "Custom Charge Codes Report",
  JOURNAL = "Journal Report",
  RA_SUSPENSE = 'RA Suspense Account Report',
  UNROUTABLE_CLAIMS = 'Unroutable Claims Report',
  REFUND = 'Refunds Report',
  PAYMENT_DETAILS = 'Payment Details Report',
  REVERSED_TRANSACTIONS = 'Reversed Transactions Report',
  LINEITEMBILLED = 'Line Items Billed Report',
  RA_STATEMENT = 'RA Statement Report',
  PAYMENTS_PER_PROVIDER_REPORT= 'Payments Per Provider Report',
  VAT_SUMMARY = 'VAT Summary Report',
  MEDICAL_INSURER_REPORT= 'Medical Insurer Statements',
  INBOX_ACTIONS_REPORT= 'Inbox Actions Report'
}

export class ProviderInvoices {
  Provider: Provider;
  Invoices?: BaseInvoice[];
}

export class ClaimInvoiceReportResponse {
  reportName?: string;
  url?: string;
}

export class BenefitCheckReportRequest {
  bpn: string;
  practiceName: string;
  treatingProvider: string;
  CalendarEvent: CalendarEvent;
  SendEmail?: boolean;
}


export class ProofOfPaymentReportRequest {
  Practice: Practice;
  User?: User;
  Account: BaseAccount;
  AccountMember: BaseAccountMember;
  Invoices: BaseInvoice[];
  InvoicePayments: Payment[];
  Transactions: Transaction[];
}

export class AccountDetailsReportRequest {
  Practice: Practice;
  Account: BaseAccount;
  MainMember: BaseAccountMember;
  AccountMember: BaseAccountMember;
}

export enum ReportState {
  STARTED, COMPLETED
}

export class IncompleteContactDetail {
  AccountNo?: string;
  Surname?: string;
  Name?: string;
  AccountHolder: boolean;
  Cellphone?: string;
  Email?: string;
}

export class IncompleteContactDetailsReportRequest {
  Practice: Practice;
  Details: IncompleteContactDetail[];
  UserId: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  LastVisitWithin?: string;
}

export class CustomChargeCodeDetail {
  Type?: CustomChargeCodeType;
  Code?: string;
  Description?: string;
  UnitPrice?: number;
  PackSize?: number;
  PackPrice?: number;
  Status?: CustomChargeCodeStatus;
  CreateDate: Date;
  CreatedBy?: string;
  LastEditDate: Date;
  LastEditedBy?: string;
}

export class CustomChargeCodesReportRequest {
  Practice: Practice;
  Details: CustomChargeCodeDetail[];
  UserId: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  CCCType?: string;
}

export interface AccountSearchResult {
  Account?: BaseAccount;
  AccountNote?: string;
  Name?: string;
  Surname?: string;
  DateOfBirth?: Date;
  AccountNo?: string;
  AccountId?: string;
  AccountStatus?: ACCOUNT_STATUS,
  AccountStatusReason?: string,
  Scheme?: string;
  MemberNo?: string;
  PatientId?: string;
  UpdatedAt?: Date;
  PatientLiable?: number;
  PatientLiableView?: number;
  MedicalAidLiable?: number;
  Patients?: BaseAccountMember[]
}

export class WriteOffReportRequest {
  Practice: Practice;
  Branch?: Branch;
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  InvoiceType?: INVOICE_TYPE;
  ReportFormatType: REPORT_FORMAT_TYPE;
  Scheme?: string;
  DateRangeType: WRITE_OFF_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  BillingPracticeNumber: string;
  IsVATRegistered: boolean;
  IncludeInactiveProviders?: boolean;
  Recipient?: string;
}

export class PaymentCorrectionReportRequest {
  Practice: Practice;
  Branch?: Branch;
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  InvoiceType?: INVOICE_TYPE;
  ReportFormatType: REPORT_FORMAT_TYPE;
  Scheme?: string;
  DateRangeType: PAYMENT_CORRECTION_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
}

export class AssistantReportRequest {
  Practice: Practice;
  Branch?: Branch;
  DateFrom: Date;
  DateTo: Date;
  AssistingProvider?: ProviderInvoices;
  AssistingProviders?: ProviderInvoices[];
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  Invoices?: BaseInvoice[];
  ReportFormatType: REPORT_FORMAT_TYPE;
  SendEmail?: boolean;
  ShowSpecificCodes?: boolean;
  UserId: string;
  Multibranch: boolean;
}

export class SmsReportRequest {
  Practice: Practice;
  Branch?: Branch;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  ReportFormatType: REPORT_FORMAT_TYPE;
  SmsList: SmsRequest[];
  BulkSmsList: any[];
  BulkSmsAddon: BulkSMS;
  SmsTemplateMessage: string;
  freeSMSType : string[];
  bulkSMSType: string[];
}

export class PatientListReportRequest implements ReportRequestData {
  Practice: Practice;
  BillingPracticeNumber: string;
  Branch?: Branch;
  Multibranch: boolean;
  RecipientEmail: string;
  UserId: string;
  LastVisitWithin?: string;
  LastVisitWithinOriginal?: string;
  FromAge?: string;
  ToAge?: string;
  FromAgeInt?: string;
  ToAgeInt?: string;
  FromAgeText?: string;
  ToAgeText?: string;
  AccountType?: string;
  AccountStatus?: string;
  Gender?: string;
  Scheme?: string;
  Conditions?: string;
  Medications?: string;
  Procedures?: string;
  PatientList?: any[];
  InvoiceList?: BaseInvoice[];
  ReportFormatType?:REPORT_FORMAT_TYPE;
  VisitDateFrom?: Date;
  VisitDateTo?: Date;
  IncludeSecondaryConditions?: boolean;
}

export class JournalReportRequest {
  Practice: Practice;
  Branch?: Branch;
  PaymentDateFrom: Date;
  PaymentDateTo: Date;
  UserId: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  SendEmail?: boolean;
  RAJournal?: RAJournal[];
  Scheme?: string;
}

export class RASuspenseReportRequest {
  Practice: Practice;
  ReportFormatType: REPORT_FORMAT_TYPE;
  Scheme?: string;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  BillingPracticeNumber: string;
  RAList: RAClaimSuspenseReport[];
}

export class PowerBIReportInfo {
  Id: string;
  Name: string
}

export class EmailMessagingReportRequest {
  Practice: Practice;
  Branch?: Branch;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  EmailMessagingDataList: EmailMessagingReportList[];
  Recipient: string;
  IncludeInactiveProviders: boolean;
}

export class UnroutableClaimsReportRequest {
  Practice: Practice;
  Branch?: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateRangeType: UNROUTABLE_CLAIMS_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  UnroutableClaimsList: UnroutableClaimsDataExtractionList[]; //Change this to the datalist that will be used
}

export class RaStatementReportRequest {
  Practice: Practice;
  RaDetails?: RaDetails;
  ReportFormatType?:string;
}

export class ReversedTransactionsReportRequest {
  Practice: Practice;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateRangeType: REVERSED_TRANSACTIONS_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  InvoiceList: InvoiceExtractionList[];
  TransactionsList: TransactionsExtractionList[];
}

export class MedicalInsurerStatementReportRequest {
  Practice: Practice;
  User?: User;
  MedInsurerDetails?: InsurerDetails;
}

export class NoDataError extends Error {
  constructor(message?: string) {
    super(message? message : 'No data found');
    this.name = 'NoDataError';
  }
}

export { CalendarEvent };

export interface ReportTableHeader {
  rows?: NameValue[][];
  outlined?: boolean;
  outlineColor?: string;
}

export interface NameValue {
  name: string;
  value: Value;
}

export interface Value {
  value: string;
  bold?: boolean;
  italic?: boolean;
}

export interface SchemeSummaryItem{
  Scheme?: string;
  Total?: number;
  TotalValue?:number;
}

export interface ProviderSummaryItem {
  ReferringProvider?: string;
  InvoiceType?: string;
  ClaimStatus?: string;
  TotalCount?: number;
  InvoicedAmount?: number;
  PaymentsAndCreditsAmount?: number;
  PaymentsCorrections?: number;
  MedicalAidLiable?: number;
  PatientLiable?: number;
}

export interface InvoiceTransaction {
  id: string;
  date: Date;
  label: string;
  superscript: string;
  amount: number;
}

export interface FileUploadData {
  content: string | Buffer, // Base64 File data
  fileName: string, // The original file name
  metadata: any, // Any metadata attached to the file
  makePublic: boolean, // A value indicating if the file should be made publicly available
  uploadId?: string // A unique Id to identify the upload
}

export interface FileUploadResult {
  url: string,
  uploadId: string,
  fileReplaced: boolean,
  storagePath: string
}

export interface FileDeleteResult {
  fileExists: boolean
}

export class InboxActionsReportRequest {
  Practice: Practice;
  Branch?: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
  InboxActionsList: InboxActionsDataExtractionList[]; //Change this to the datalist that will be used
}
