import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {
  BaseExcelReportData,
  CreditNoteReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, CreditNotesReportBuilder, CreditNotesXlsReportDataBuilder } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class CreditNotesReportService {

  async generateReport(reportRequest: CreditNoteReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildCreditNoteReportFileName('CreditNotes',
      reportRequest.Practice, reportRequest.TreatingProvider?.Provider, reportRequest.Branch.Name, reportRequest.ReportFormatType
      ,reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.CREDIT_NOTES,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: CreditNoteReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: CreditNoteReportRequest): Promise<BaseExcelReportData> {
    return new CreditNotesXlsReportDataBuilder().build(
      await new CreditNotesReportBuilder().build(reportRequest));
  }

}
