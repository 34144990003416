// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  dev: true,
  ihealthdev: false,
  live: false,
  production: false,
  qa: false,
  staging: false,
  firebaseConfig: {
    apiKey: "AIzaSyDunXBb27ggYIgxKyA8qznR2jwJw77WfDA",
    authDomain: "healthbridge-meraki-dev.firebaseapp.com",
    projectId: "healthbridge-meraki-dev",
    storageBucket: "healthbridge-meraki-dev.appspot.com",
    messagingSenderId: "876295494566",
    appId: "1:876295494566:web:726acea6dde778cbef8d67",
    measurementId: "G-W0HP7MSPND",
    vapidKey: "BD8644OusWk_vPaPQSDw8um2g_ZOZ7NukD76HfMYdUrhBlnAKYnI2pnwlAycGBAtLAXQXKRPA69xuH7uj66h6yA",
    disableConsoleLogging: false,
    disableServerLogging: false
  },
  useEmulators: false,
  bcReportUrl: 'https://www.benefitcheck.co.za/benefitcheck/generate/report?originalMessageId='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
