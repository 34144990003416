import { Injectable } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { orderBy as orderByLodash } from "lodash";
import * as moment from "moment";
import { InvoiceService } from '../../services/invoice/invoice.service';
import { DateUtils } from '../../utils/date-utils';
import {
  ACCOUNT_STATUS,
  Account,
  AgeAnalysisPatientLiableReportModel,
  AgeAnalysisReportRequest,
  AggregatedInvoiceAge,
  AppointmentReportInfo,
  BaseAccountMember,
  BaseInvoice,
  eRASchemes,
  instanceOfAccountStatusChangedAudit,
  INVOICE_SUBTYPE,
  NoDataError,
  Note,
  PatientLiableAccountDetails,
  PatientLiableInvoiceDetails,
  PatientLiableSummary,
  SummaryData,
  VisitInfo, Practice
} from '@meraki-flux/schema';
import { BaseAgeAnalysisReportBuilder } from './base-age-analysis-report-builder';

@Injectable({
  providedIn: 'root'
})
export class AgeAnalysisPatientLiableReportBuilder extends BaseAgeAnalysisReportBuilder {
  constructor(firestore: Firestore, invoiceService: InvoiceService) {
    super(firestore, invoiceService);
  }

  async build(reportRequest: AgeAnalysisReportRequest): Promise<AgeAnalysisPatientLiableReportModel> {
    const reportHeader: AppointmentReportInfo = this.buildReportInfo(reportRequest);

    const invoices: BaseInvoice[] = await this.getInvoices(reportRequest);
    if (invoices.length == 0) {
      throw new NoDataError();
    }

    const accountMap = await this.buildAccountMap(reportRequest.Practice?.Id,
      invoices.map(i => i.Account?.Id).filter(i => i));
    const mainMemberMap = await this.buildPatientMap(reportRequest.Practice?.Id,
      Array.from(accountMap.values()).map(i => i.MainMember).filter(i => i));
    const visitInfoMap = await this.buildVisitInfoMap(reportRequest.Practice?.Id,
      invoices.map(i => i.LinkedAppointment).filter(i => i));
    let notesMap = new Map<string, Note[]>();
    if (reportRequest.IncludeLastNotes) {
      notesMap = await this.buildNotesMap(reportRequest.Practice?.Id, Array.from(accountMap.keys()));
    }

    const invoiceDetails: PatientLiableInvoiceDetails[] = await this.buildInvoiceDetails(reportRequest.Practice, invoices, visitInfoMap);
    const accountDetails: PatientLiableAccountDetails[] = this.buildAccountDetails(invoices, accountMap, mainMemberMap, notesMap);

    const summary: PatientLiableSummary = this.buildSummary(invoiceDetails);

    return {
      reportInfo: reportHeader,
      accountDetails: accountDetails,
      invoiceDetails: invoiceDetails,
      summary: summary
    };
  }

  private buildAccountDetails(invoiceList: BaseInvoice[], accountMap: Map<string, Account>,
                                patientMap: Map<string, BaseAccountMember>, noteMap: Map<string, Note[]>): PatientLiableAccountDetails[] {
    const accountDetailsMap: Map<string, PatientLiableAccountDetails> = new Map;

    for (const invoice of invoiceList) {
      const accountId = invoice.Account?.Id;
      let accountDetails: PatientLiableAccountDetails = accountDetailsMap.get(accountId);
      if (!accountDetails) {
        const account: Account = accountMap.get(accountId);
        const patientId = account.MainMember;
        const patient: BaseAccountMember = patientMap.get(patientId);
        let noteString = '';
        const notes: Note[] = noteMap.get(accountId);
        if (notes)
          noteString = notes.map(i => `${moment(DateUtils.toDate(i.CreatedAt)).format('DD/MM/YYYY')}: ${i.Note}`).join("\r\n")
        let suspendedReason = '';
        if (account.Audits?.length > 0) {
          const audits = orderByLodash(account.Audits?.filter(a => instanceOfAccountStatusChangedAudit(a)).map((a: any) => ({ ...a, Date: a.Date?.toDate() })), 'Date', 'desc');
          if (audits[0] && audits[0].NewStatus === ACCOUNT_STATUS.SUSPENDED) {
            suspendedReason = audits[0].Reason;
          }
        }

        accountDetails = {
          accountNo: account.AccountNo,
          accountHolderName: `${patient.Surname}, ${patient.Name}`,
          branch: invoice.Branch,
          age: new AggregatedInvoiceAge(),
          accountStatus: account.AccountStatus,
          memberNo: account?.MemberNo || '',
          scheme: invoice.Account?.SchemeName || '',
          cellphoneNo: patient.Contact?.Cellphone || '',
          reason: suspendedReason,
          unallocatedAmount: -account.UnallocatedCredit || 0,
          total: -account.UnallocatedCredit || 0,
          accountNotes: noteString
        };
        accountDetailsMap.set(invoice.Account.Id, accountDetails);
      }
      accountDetails.total += invoice.Balance.PatientLiable;
      accountDetails.age?.sum(new AggregatedInvoiceAge().setValue(invoice.InvoiceDate, invoice.Balance.PatientLiable))
    }
    return Array.from(accountDetailsMap.values());
  }

  private async buildInvoiceDetails(practice: Practice, invoices: BaseInvoice[], visitInfoMap: Map<string, VisitInfo>): Promise<PatientLiableInvoiceDetails[]> {
    const invoiceDetails: PatientLiableInvoiceDetails[] = [];
    const providers = await this.loadProviders(practice.BillingPracticeNumber)
    for (const invoice of invoices) {
      const visitInfo: VisitInfo = visitInfoMap.get(invoice.Id);
      invoiceDetails.push({
        age: new AggregatedInvoiceAge().setValue(invoice.InvoiceDate, invoice.Balance.PatientLiable),
        branch: invoice.Branch,
        treatingProviderId: invoice.TreatingProvider?.Id,
        treatingProvider: this.getProviderName(providers, invoice)??invoice.TreatingProvider?.FullName,
        scheme: invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER ? invoice.MedicalInsurer?.Name || '' : invoice.Account?.SchemeName || '',
        memberNo: invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER ? invoice.PolicyNo || '' : invoice.Account?.MemberNo || '',
        dateOfService: invoice.DateOfService,
        invoiceDate: invoice.InvoiceDate,
        invoiceType: invoice.Type,
        invoiceNo: invoice.InvoiceNo,
        accountNo: invoice.Account?.AccountNo,
        patientName: `${invoice.Patient.Surname}, ${invoice.Patient.Name}`,
        claimStatus: invoice.ClaimInfo?.ClaimStatus || '',
        hbMessageId: invoice.ClaimInfo?.HBMessageId || '',
        invoicedAmount: invoice.Balance?.Outstanding,
        medicalAidLiable: invoice.Balance?.MedicalAidLiable,
        patientLiable: invoice.Balance?.PatientLiable,
        bc: visitInfo?.BenefitCheckMessageId ? 'Y' : 'N',
        bcStatus: visitInfo?.BenefitCheckStatus || '',
        eRAEnabled: eRASchemes.indexOf(invoice.Account?.SchemeCode) >= 0 ? 'Y' : 'N',
        location: invoice.Location
      });
    }
    return invoiceDetails;
  }

  private async getInvoices(reportRequest: AgeAnalysisReportRequest) {
    const invoices = await this.loadInvoices(reportRequest);
    return invoices.filter(invoice => invoice.Balance?.PatientLiable > 0);
  }


  private buildSummary(invoiceDetails: PatientLiableInvoiceDetails[]): PatientLiableSummary {
    const providerData: SummaryData = new SummaryData();
    const invoiceTypeData: SummaryData = new SummaryData();
    for (const invoiceDetail of invoiceDetails) {
      providerData.addData(invoiceDetail.treatingProvider, invoiceDetail.age);
      invoiceTypeData.addData(invoiceDetail.invoiceType, invoiceDetail.age);
    }
    return {providerData: providerData, invoiceTypeData: invoiceTypeData};
  }
}
