import {Injectable} from '@angular/core';
import {getMessaging, getToken, onMessage} from '@angular/fire/messaging';
import {AuthService} from "./auth.service";
import {getFunctions, httpsCallable} from "@angular/fire/functions";
import {getApp} from "@angular/fire/app";
import {tap} from "rxjs/operators";
import {SnackbarNotifierService} from "./snackbar-notifier.service";
import {MessagePayload} from "@firebase/messaging";
import {Unsubscribe} from "@firebase/util";
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import {NotificationMessage, Type} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class NotificationSubscriptionService {
  private registrationToken: string;
  private unsubscribeToken: Unsubscribe;

  constructor(private notifierService: SnackbarNotifierService, private authService: AuthService, private logger: LogService) {
    this.authService.practice$.pipe(
      tap(async i => {
        if (this.registrationToken)
          await this.saveRegistrationToken(this.registrationToken, i.Id);
      })
    ).subscribe();
  }

  public async subscribe() {
    if (this.registrationToken) {
      this.logger.info('Subscription was already configured...')
      return;
    }

    this.logger.info('Configuring notification subscription...')
    const vapidKey = environment.vapidKey;
    if (!vapidKey) {
      this.logger.info("No vapid key configured. Skipped notification config.");
      return;
    }
    getToken(getMessaging(), {vapidKey: vapidKey}).then(async (currentToken) => {
      if (currentToken) {
        this.registrationToken = currentToken;
        await this.saveRegistrationToken(currentToken, this.authService.selectedBPN);
        this.unsubscribeToken = onMessage(getMessaging(), (i) => this.processMessage(i));
        this.logger.info(`Registration Token: ${currentToken.substring(0, 32)}...`);
      } else {
        await this.requestPermission();
      }
    }).catch((err) => {
      this.logger.info('User restricted getting notifications', err);
    });
  }

  private async requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.logger.info('Notification permission granted.');
        this.subscribe();
      } else {
        this.logger.info('Unable to get permission to notify.');
      }
    });
  }

  private async saveRegistrationToken(token: string, bpn: string) {
    try {
      const functions = getFunctions(getApp(), 'europe-west1');
      const saveRegistrationTokenRequest = httpsCallable(functions, 'ntf-tsk-v1-oncall-saveRegistrationTokenRequest');
      const resultData: any = await saveRegistrationTokenRequest({
        userId: this.authService.uid,
        token: token,
        bpn: bpn
      });
      const result = resultData.data;
      if (!result.success) {
        this.logger.error(`Unable to save registration token. ${JSON.stringify(result.data)}`);
      }
    } catch (error) {
      this.logger.error(error);
    }
  }

  private processMessage(payload: MessagePayload) {
    try {
      const message: NotificationMessage = JSON.parse(payload.data?.message);
      if (!message) {
        this.logger.error("Unable to parse notification message");
        return;
      }
      if (!message.Type) {
        this.logger.error("No notification message type");
        return;
      }
      if (message.Type === Type.CLAIM_STATUS_NOTIFICATION) {
        this.logger.info(`Claim status notification received. (msgId: ${payload.messageId}. )`);
        this.notifierService.show(message);
      } else if (message.Type === Type.REPORT_STATUS_NOTIFICATION) {
        this.logger.info(`Report status notification received. (msgId: ${payload.messageId}. )`);
        this.notifierService.show(message);
      } else {
        // process non-ui notification here
      }
    } catch (error) {
      this.logger.error(`Unable to process notification. ${error}`);
    }
  }

  public unsubscribe() {
    if (this.registrationToken && this.unsubscribeToken) {
      this.unsubscribeToken();
      this.unsubscribeBackgroundMessaging();
      this.registrationToken = null;
      this.logger.info('Unsubscribed from notifications.')
    }
  }

  private unsubscribeBackgroundMessaging() {
    this.getBackgroundSubscriptions().then((pushSubscription: PushSubscription) => {
      return pushSubscription.unsubscribe();
    });
  }

  /**
   * Returns a Promise that resolves on the PushSubscription object that controls the push notification subscription,
   *   after it finds the corresponding ServiceWorker
   */
  getBackgroundSubscriptions(): Promise<any> {
    const subscriptions = navigator.serviceWorker.getRegistrations().then((r) => {
      return r.map((sw) => {
        if (!(sw.active && sw.active.scriptURL.includes("firebase-messaging")))
          return Promise.resolve();
        return sw.pushManager.getSubscription();

      });
    });
    return subscriptions.then(arr => arr ? arr[0] : Promise.resolve(null));
  }
}
