import {BasePdfReportData, ClaimDetailData, ReportContent} from "@meraki-flux/schema";
import { BasePdfGenerator, PDFMakeHelper } from '@meraki-flux/purejs';
import generateReportHeader = PDFMakeHelper.generateReportHeader;
import generateItalicFilledHeader = PDFMakeHelper.generateItalicFilledHeader;
import generateClaimDetailsDataTable = PDFMakeHelper.generateClaimDetailsDataTable;

/*
  Helper module to generate some common exceljs structures.
*/
export class ClaimDetailPdfGenerator extends BasePdfGenerator {

  private readonly claimDetailData: ClaimDetailData

  public override generateReport(): ReportContent {
    this.generateReportName();
    this.generateReportHeader();
    this.generateItalicFilledLine('Claim Status')
    this.generateStatusSection();
    this.generateItalicFilledLine('Details')
    this.generateDetailsSection();
    this.generateItalicFilledLine('Line Details')
    this.generateTables();
    this.generateBottomMessage();
    this.generateFooterTable();
    return this.buildReport();
  }

  protected generateReportHeader() {
    if (this.data.doctorName && this.data.bpn) {
      this.content.push(generateReportHeader({
        left: [
          {name: "Practice", data: {value: this.data.doctorName, decoration: {bold: true}}},
          {name: "Billing practice no.", data: {value: this.data.bpn, decoration: {bold: true}}}
        ]
      }))
    }
  }

  private generateItalicFilledLine(text: string) {
    this.content.push(generateItalicFilledHeader(text));
  }

  private generateStatusSection() {
    if (this.claimDetailData && this.claimDetailData.claimStatus) {
      this.content.push(generateClaimDetailsDataTable(this.claimDetailData.claimStatus))
    }
  }

  private generateDetailsSection() {
    if (this.claimDetailData && this.claimDetailData.details) {
      this.content.push(generateClaimDetailsDataTable(this.claimDetailData.details))
    }
  }

  constructor(data: BasePdfReportData) {
    super(data)
    this.claimDetailData = data.claimDetailData;
  }
}

