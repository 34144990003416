import { Component, ElementRef, Input, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {FileUtils} from "@meraki-flux/common";

@Component({
  selector: 'meraki-flux-file-selection',
  templateUrl: './file-selection.component.html',
  styleUrls: ['./file-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileSelectionComponent,
      multi: true,
    }
  ],
})
export class FileSelectionComponent implements OnInit, OnDestroy {
  @Input() accept!: string;
  @Input() displayFilename!: boolean;
  @Input() events: Observable<string> = new Observable<string>();
  @Output() fileAddedEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileSelection') fileSelection!: ElementRef;

  private eventSub!: Subscription;
  files: File[] = [];

  ngOnInit() {
    this.eventSub = this.events.subscribe((fileName) => this.removeFile(fileName));
  }

  ngOnDestroy(): void {
    this.eventSub.unsubscribe();
  }

  addFiles(newfiles: File[]) {
    if (!this.files) {
      this.files = [];
    }
    this.files = [
      ...this.files,
      ...newfiles
    ];
  }

  removeFile(fileName: string) {
    this.files = this.files.filter((file) => file.name !== fileName)
  }

  changeListener(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList = element.files;
    if (fileList) {
      let files = Array.from(fileList);
      files = files.map((file) => (
        FileUtils.renameFile(file, FileUtils.decorateFileNameWithUUID(file.name))
      ))
      this.addFiles(files)
      this.fileAddedEvent.emit(files);
    }
  }
}
