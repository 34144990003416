<div id="breadcrumbs" class="flex flex-row w-full items-center h-6 relative z-[10]">
  <ng-container *ngIf="(loading$ | async) === false; else loading"> </ng-container>
  <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
    <ngx-skeleton-loader
      class="min-w-[150px] h-5 m-1"
      *ngIf="!!breadcrumb.loading"
      appearance="line"
    ></ngx-skeleton-loader>

    <ng-container *ngIf="!breadcrumb.loading">
      <span
        *ngIf="!last"
        matRipple
        [matRippleTrigger]="link"
        class="leading-6 p-2 text-hb-text-subdued hover:text-hb-text-default hover:bg-hb-base-base-grey"
      >
        <a
          #link
          id="breadcrumb"
          class="text-base align-middle"
          [routerLink]="breadcrumb.url || '.'"
          routerLinkActive="text-hb-text-primary"
          [routerLinkActiveOptions]="{ exact: true }"
          >{{ breadcrumb.loading ? 'Loading...' : breadcrumb.label }}</a
        >
      </span>

      <span *ngIf="last" class="leading-6 p-2">
        <span class="text-base align-middle">{{
          breadcrumb.loading ? 'Loading...' : breadcrumb.label
        }}</span>
      </span>

      <span class="leading-6 pr-1 text-base text-hb-text-subdued">
        <mat-icon class="!leading-4 !h-4 !w-4 !mb-1 align-middle" *ngIf="!last"
          >chevron_right</mat-icon
        >
      </span>
    </ng-container>
  </ng-container>
</div>

<ng-template #hidden>
  <div class="h-6"></div>
</ng-template>

<ng-template #loading>
  <span class="leading-6 text-center text-hb-text-subdued">Loading...</span>
</ng-template>
