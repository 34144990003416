import moment = require("moment");
import {
  NoDataError,
  PaymentPerProviderItem,
  PaymentPerProviderReportInfo,
  PaymentPerProviderReportModel,
  PaymentPerProviderReportRequest,
  PaymentPerProviderSummary
} from "@meraki-flux/schema";

export class PaymentsPerProviderReportBuilder {

    private readonly DATE_FORMAT = 'DD/MM/YYYY';



    async build(reportRequest: PaymentPerProviderReportRequest): Promise<PaymentPerProviderReportModel> {
        this.checkDataAvailability(reportRequest);
        const reportHeader: PaymentPerProviderReportInfo = await this.buildReportInfo(reportRequest);

        return {
            PaymentPerProviderReportInfo: reportHeader,
            PaymentPerProviderItem: reportRequest.Payments,
            PaymentPerProviderSummary: await this.buildSummaryTable(reportRequest.Payments),
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(reportRequest: PaymentPerProviderReportRequest) {
        let noData = true;
        if (reportRequest.Payments.length > 0) {
            noData = false;
        }

        if (noData)
            throw new NoDataError();
    }

    private buildSummaryTable(paymentPerProviderItem: PaymentPerProviderItem[]): Promise<PaymentPerProviderSummary[]> {
        return new Promise((resolve, reject) => {
            try {
                const paymentPerProviderSummary: PaymentPerProviderSummary[] = paymentPerProviderItem.reduce((acc, curr) => {
                    const providerIndex = acc.findIndex(item => item.Provider === curr.Provider);
                    if (providerIndex === -1) {
                        acc.push({
                            Provider: curr.Provider,
                            [curr.PaymentAmountType]: curr.PaymentAmount
                        });
                    } else {
                        acc[providerIndex][curr.PaymentAmountType] = (acc[providerIndex][curr.PaymentAmountType] || 0) + curr.PaymentAmount;
                    }
                    return acc;
                }, []);

                const sortedSummary = paymentPerProviderSummary.sort(this.orderByProvider);
                resolve(sortedSummary);
            } catch (error) {
                reject(error);
            }
        });
    }

    private orderByProvider(a, b) {
        const providerA = a.Provider.toLowerCase();
        const providerB = b.Provider.toLowerCase();
        if (providerA < providerB) return -1;
        if (providerA > providerB) return 1;
        return 0;
      }

    private async buildReportInfo(reportRequest: PaymentPerProviderReportRequest): Promise<PaymentPerProviderReportInfo> {
        const reportInfo: PaymentPerProviderReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        reportInfo.Branch = reportRequest.Branch?.Name?? "All";
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.Provider = reportRequest.TreatingProvider;

        return reportInfo;
    }




}
