import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, first, map, tap, shareReplay } from 'rxjs/operators';
import { Firestore, doc, DocumentReference, docData, setDoc, getDoc, getDocs, query, collection, orderBy } from '@angular/fire/firestore';
import { Auth, authState, signOut, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from '@angular/fire/auth';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { DialogService } from './dialog.service';
import { FormatUtils } from '../utils/format-utils';
import { delay } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Business, DialogType, Practice, PracticeProvider, SYSTEM_ACCESS_CODE, User, USER_TYPE} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AuthService {
  public readonly user$: Observable<User | null> = EMPTY;
  public readonly practiceBF$ = new BehaviorSubject<Practice & Business>({});
  public readonly practice$ = new Observable<any>(); //
  admin = false;
  practiceAdmin = false;
  uid: string | null = null;
  userFullName: string | null = null;
  selectedBPN: string | undefined = undefined;
  selectedBranch: string | undefined = undefined;
  providerId: string | undefined = undefined;
  userRef!: DocumentReference;
  isMultiBranch: boolean | undefined = undefined;

  constructor(
    @Optional() private auth: Auth,
    private firestore: Firestore,
    private router: Router,
    private dialogService: DialogService
  ) {
    if (auth) {
      this.user$ = authState(this.auth).pipe(
        switchMap((user) => {
          if (user) {
            user
              .getIdTokenResult()
              .then((token: any) => (this.admin = token.claims.role === 'system-admin'));
            this.uid = user.uid;
            this.userRef = doc(firestore, `User/${user.uid}`);
            return docData<User>(this.userRef, { idField: 'uid' }).pipe(
              switchMap((practiceUser) => {
                this.userFullName =
                  FormatUtils.nameSurname(practiceUser?.Name, practiceUser?.Surname) || null;
                if (practiceUser.ProviderId) {
                  return docData<PracticeProvider>(
                    doc(
                      this.firestore,
                      `Practice/${practiceUser.SelectedBPN}/Provider/${practiceUser.ProviderId}`
                    )
                  ).pipe(
                    map((practiceProvider) => ({
                      ...practiceUser,
                      IsActive: practiceProvider.IsActive,
                      Common: practiceProvider.Common,
                      Title: practiceProvider.Title,
                      Name: practiceProvider.Name,
                      Surname: practiceProvider.Surname,
                    }))
                  );
                } else return of(practiceUser);
              })
              // tap(c => console.warn('user', c)),
            );
          } else {
            return of(null);
          }
        }),
        tap((user) => {
          if (user) {
            this.selectedBPN = user.SelectedBPN;
            this.selectedBranch = user.SelectedBranch;
            this.providerId = user.ProviderId;
            this.practiceAdmin = user.Common && user.Common.IsSystemAdministrator;
          }
        }),
        shareReplay(1)
      );
      this.practice$ = this.user$.pipe(
        switchMap((user: any) => {
          if (!user) {
            return of({});
          } else if (user.SelectedBusinessType === 'Single doctor practice') {
            return docData<Practice>(doc(this.firestore, `Practice/${user.SelectedBPN}`)).pipe(
              map((practice) => ({ Id: user.SelectedBPN, ...practice }))
            );
          } else {
            return docData<Business>(
              doc(this.firestore, `Business/${user.SelectedBusinessId}`)
            ).pipe(
              map((business) => {
                return {
                  ...business,
                  BusinessId: user.SelectedBusinessId,
                  Id: user.SelectedBPN,
                };
              })
            );
          }
        }),
      );
      this.practice$.pipe(
        tap(p => this.practiceBF$.next(p)),
        tap(p => this.isMultiBranch = p.isMultiBranch),
        untilDestroyed(this)
      ).subscribe();
      if (window.location.pathname === '/login') {
        this.router.navigate(['/']);
      }
    }
  }

  async hasAccess(accessList: string[]) {
    const user: User = await this.user$.pipe(first()).toPromise();
    const hasAccess =
      this.admin ||
      accessList.includes(
        user.Common && user.Common.IsSystemAdministrator ? USER_TYPE.SYSTEM_ADMIN : USER_TYPE.USER
      ) ||
      accessList.some((access) => user.Common && user.Common.Roles.includes(access)) ||
      accessList.some((access) => user.Common && user.Common.SystemAccess.includes(access));
    return hasAccess;
  }

  testAccess(accessList: string[]) {
    return this.user$.pipe(
      map((user) => {
        if (accessList.includes(SYSTEM_ACCESS_CODE.HB_USER)) {
          return user?.email?.endsWith('@healthbridge.co.za');
        } else {
          return (
            this.admin ||
            accessList.includes(
              user?.Common && user?.Common?.IsSystemAdministrator
                ? USER_TYPE.SYSTEM_ADMIN
                : USER_TYPE.USER
            ) ||
            accessList.some((access) => user?.Common && user?.Common?.Roles?.includes(access)) ||
            accessList.some((access) => user?.Common && user?.Common?.SystemAccess?.includes(access))
          );
        }
      })
    );
  }

  async getToken() {
    return await this.auth.currentUser?.getIdToken();
  }

  async googleSignin() {
    const credential = await signInWithPopup(this.auth, new GoogleAuthProvider());
    if (
      credential.user?.email?.endsWith('@healthbridge.co.za') ||
      credential.user?.email?.endsWith('@sintez.co.za') ||
      credential.user?.email?.endsWith('@tradebridge.co.za')
    ) {
      await this.updateUserData(credential.user);
      this.router.navigate(['/']);
    } else {
      await this.signOut();
    }
  }

  async signOut() {
    await signOut(this.auth);
    return this.router.navigate(['/login']);
  }

  async signInWithEmail(email: string, password: string) {
    const credential = await signInWithEmailAndPassword(this.auth, email, password).catch((e) =>
      this.showInvalidLoginMessage()
    );
    if (credential) {
      const userDoc = await getDoc<User>(doc(this.firestore, `User/${credential.user.uid}`));
      const user = userDoc.data();

      delay(async () => {
        if (user?.IsActive) {
          this.router.navigate([user.Common.Settings.LandingTab]);
        } else {
          this.showUserDeletedMessage();
          this.signOut();
        }
      }, 400);
    }
  }

  private showInvalidLoginMessage() {
    this.dialogService.showDialog({
      message: 'Invalid login credentials.<br>Please try again.',
      type: DialogType.ERROR,
    });
  }

  private showUserDeletedMessage() {
    this.dialogService.showDialog({
      message:
        'This user has been deleted or deactivated.<br><br>Please contact your system administrator.',
      type: DialogType.ERROR,
    });
  }

  private async updateUserData(user: any) {
    const baseData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName ? user.displayName : null,
      Name: user.displayName ? user.displayName.split(' ')[0] : null,
      Surname:
        user.displayName && user.displayName.includes(' ') ? user.displayName.split(' ')[1] : null,
      photoURL: user.photoURL ? user.photoURL : null,
    };
    let data;
    if (
      user?.email?.endsWith('@healthbridge.co.za') ||
      user?.email?.endsWith('@sintez.co.za') ||
      user?.email?.endsWith('@tradebridge.co.za')
    ) {
      const q = query(collection(this.firestore, 'Business'), orderBy('BillingPracticeNumber'));
      const businessDoc = await getDocs(q);
      const business = businessDoc.docs[0]?.data();
      const userDoc = await getDoc(doc(this.firestore, `User/${user.uid}`));
      if (userDoc.exists) {
        data = {
          SelectedBPN: userDoc?.data()?.SelectedBPN
            ? userDoc?.data()?.SelectedBPN
            : business?.BillingPracticeNumber,
          SelectedBusinessId: userDoc?.data()?.SelectedBusinessId
            ? userDoc?.data()?.SelectedBusinessId
            : businessDoc.docs[0]?.id,
          SelectedBusinessType: userDoc?.data()?.SelectedBusinessType
            ? userDoc?.data()?.SelectedBusinessType
            : business?.BusinessType,
          SelectedPracticeName: userDoc?.data()?.SelectedPracticeName
            ? userDoc?.data()?.SelectedPracticeName
            : business?.PracticeName,
          ...baseData,
        };
      } else {
        data = {
          SelectedBPN: business?.BillingPracticeNumber,
          SelectedBusinessId: businessDoc.docs[0]?.id,
          SelectedBusinessType: business?.BusinessType,
          SelectedPracticeName: business?.PracticeName,
          ...baseData,
        };
      }
      await setDoc(doc(this.firestore, `User/${user.uid}`), data, { merge: true });
    }
  }

  public getUser(): Promise<any> {
    return this.user$.pipe(first()).toPromise();
  }

  public async getUserNameSurname(): Promise<string> {
    const user = (await this.getUser()) as User;
    return FormatUtils.nameSurname(user.Name, user.Surname);
  }
}
