import {
  BasePdfReportData, CellType,
  ClaimSummarySubscriptionReportModel, PDFCellData,
  PDFParameter,
  ReportHeader, ReportTable, SummaryItem
} from '@meraki-flux/schema';

export class ClaimSummarySubscriptionPdfReportDataBuilder {
    private static REPORT_SPECIAL_FONT_COLOR = "#008DA6";

    private readonly ROW_TOP_MARGIN = 10;
    private readonly HEADER_TOP_MARGIN = 6;

    build(reportModel: ClaimSummarySubscriptionReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = reportModel.ReportInfo.PracticeId;
        reportData.orientation = "portrait";
        reportData.headers = this.buildHeader(reportModel);
        reportData.tables = this.buildTables(reportModel);
        reportData.dateHeader = `Date generated ${reportModel.ReportDate}`;
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.footer = {
            hbLogo: true
        }
        return reportData;
    }


    private buildHeader(model: ClaimSummarySubscriptionReportModel): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({name: 'Practice', data: {value: model.ReportInfo.Practice, decoration: {bold: true}}});
        leftParameters.push({name: 'Billing practice no.', data: {value: model.ReportInfo.PracticeId, decoration: {bold: true}}});
        return [
            {
                name: 'Claim Summary - ' + model.ReportInfo.DateRangePeriod,
                nameColor: ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                includeGeneratedDate: true,
                left: leftParameters,
                right: []
            }
        ];
    }

    private buildTables(model: ClaimSummarySubscriptionReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
        reportTables.push(this.buildInvoiceSummaryTable(model));
        reportTables.push(this.buildClaimsRequiringActionsTable(model));
        reportTables.push(this.buildOtherWorkItemsTable(model));
        return reportTables;
    }

    private buildInvoiceSummaryTable(model: ClaimSummarySubscriptionReportModel): ReportTable {
        const table: ReportTable = {};
        table.name = 'INVOICE SUMMARY';
        table.nameColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.rowHeaders = [
            {value: "", decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN, width: "*"},
            {value: '#', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN, type: CellType.NUMBER},
            {value: 'Invoiced amount', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN, },
            {value: 'Medical aid liable', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN, },
            {value: 'Patient liable', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN, },
        ];
        table.borders = {ver: false, hor: true, outerborder: false, headerBorderSize: 0.5, rowBorderSize: 0.2}
        table.rowHeadersBold = true;
        let rows = [];
        table.rows = rows;

        this.addSummaryRow(rows, 'Cash invoices', model.TotalCashInvoiceSummaryItem, false, false, false);
        this.addSummaryRow(rows, 'Cash', model.CashInvoiceSummaryItem, true, false, true);
        this.addSummaryRow(rows, 'No charge', model.NoChargeInvoiceSummaryItem, true, false, true);

        this.addSummaryRow(rows, 'Debit note invoices', model.DebitNoteInvoiceSummaryItem, false, false, false);

        this.addSummaryRow(rows, 'Medical aid claims', model.TotalMAInvoiceSummaryItem, false, false, false);
        this.addSummaryRow(rows, 'Electronic', model.ElectronicMAInvoiceSummaryItem, true, false, true);
        this.addSummaryRow(rows, 'Unroutable', model.UnRoutableInvoiceSummaryItem, true, false, true);

        this.addSummaryRow(rows, 'Medical insurance claims', model.InsuranceInvoiceSummaryItem, false, false, false);
        this.addSummaryRow(rows, 'Total invoices captured', model.TotalInvoiceSummaryItem, false, true, false);

        return table;
    }

    private buildClaimsRequiringActionsTable(model: ClaimSummarySubscriptionReportModel): ReportTable {
        const table: ReportTable = {};
        table.name = 'CLAIMS REQUIRING ACTIONS';
        table.nameColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.rowHeaders = [
            {value: '', decoration: {bold: true},  topMargin: this.ROW_TOP_MARGIN, width: '*'},
            {value: '#', decoration: {bold: true},  topMargin: this.ROW_TOP_MARGIN, type: CellType.NUMBER},
            {value: 'Invoiced amount', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN},
            {value: 'Medical aid liable', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN},
            {value: 'Patient liable', decoration: {bold: true}, topMargin: this.ROW_TOP_MARGIN},
        ];
        table.borders = {ver: false, hor: true, outerborder: false, headerBorderSize: 0.5, rowBorderSize: 0.2}
        table.rowHeadersBold = true;
        let rows = [];
        table.rows = rows;

        this.addSummaryRow(rows, 'Total claims to be fixed', model.TotalClaimsToBeFixed, false, true, false);
        this.addSummaryRow(rows, 'Fix & resubmit claim', model.FixAndResubmitInvoiceSummaryItem, false, false, true);
        this.addSummaryRow(rows, 'View rejected lines', model.ViewRejectedLinesInvoiceSummaryItem, false, false, true);

        return table;
    }

    private buildOtherWorkItemsTable(model: ClaimSummarySubscriptionReportModel): ReportTable {
        const table: ReportTable = {};
        table.name = 'OTHER WORK ITEMS';
        table.nameColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ClaimSummarySubscriptionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.rowHeaders = [
            {value: '', decoration: {bold: true},  topMargin: this.ROW_TOP_MARGIN, width: '*'},
            {value: '#', decoration: {bold: true},  topMargin: this.ROW_TOP_MARGIN, type: CellType.NUMBER},
        ];
        table.borders = {ver: false, hor: true, outerborder: false, headerBorderSize: 0.5, rowBorderSize: 0.2}
        table.rowHeadersBold = true;
        let rows = [];
        table.rows = rows;

        this.addSummaryRow(rows, 'Total Collect Outstanding Balance work items', model.TotalCollectOutstandingBalanceCount, false, true, false);
        this.addSummaryRow(rows, 'Total incomplete invoices', model.TotalIncompleteInvoiceInboxCount, false, true, false);
        this.addSummaryRow(rows, 'Complete Provider Invoice work items', model.CompleteSavedProviderInvoiceActionCount, false, false, true);
        this.addSummaryRow(rows, 'Complete Saved Invoice work items', model.CompleteSavedInvoiceActionCount, false, false, true);
        this.addSummaryRow(rows, 'Approve Invoice work items', model.ApproveInvoiceActionCount, false, false, true);

        return table;
    }

    private addSummaryRow(rows: any[], rowName: string, value: SummaryItem | number, italic: boolean, bold: boolean, leftMargin: boolean) {
        let row: PDFCellData[] = [];
        row.push({value: rowName, decoration: {bold: bold, italics: italic}, topMargin: this.HEADER_TOP_MARGIN, leftMargin: leftMargin ? 10 : 0, type: CellType.GENERAL})

        if (value instanceof SummaryItem) {
            const item: SummaryItem = value as SummaryItem;
            row.push({value: item.TotalCount, decoration: {bold: bold, italics: italic},  topMargin: this.HEADER_TOP_MARGIN, type: CellType.NUMBER});
            row.push({value: item.InvoicedAmount, decoration: {bold: bold, italics: italic},  topMargin: this.HEADER_TOP_MARGIN, type: CellType.CURRENCY});
            row.push({value: item.MedicalAidLiable, decoration: {bold: bold, italics: italic},  topMargin: this.HEADER_TOP_MARGIN, type: CellType.CURRENCY});
            row.push({value: item.PatientLiable, decoration: {bold: bold, italics: italic},  topMargin: this.HEADER_TOP_MARGIN, type: CellType.CURRENCY});
        } else {
            row.push({value: value, decoration: {bold: bold, italics: italic},  topMargin: this.HEADER_TOP_MARGIN, type: CellType.NUMBER});
        }
        rows.push(row);
    }
}
