import { Injectable } from "@angular/core";
import { createStore, select, setProp, Store, withProps } from "@ngneat/elf";
import {
  addActiveIds,
  deleteAllEntities,
  deleteEntities,
  getActiveIds,
  getAllEntities,
  getEntity,
  hasEntity,
  resetActiveIds,
  selectActiveIds,
  selectAllEntities,
  selectEntity,
  toggleActiveIds,
  upsertEntities,
  withActiveIds,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import _ from "lodash";
import {
  CalendarEvent,
  CalendarProvider,
  CalendarSettings,
  DurationSetting,
  UserCalendarSettings,
  WorkingHours
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class CalendarRepository {

  private readonly calendarStore!: Store;
  private readonly calendarProviderStore!: Store;
  private readonly calendarEventStore!: Store;

  allProviders$: Observable<CalendarProvider[]>;
  selectedProviders$: Observable<string[]>;
  selectedBranch$: Observable<string>;
  resources$: Observable<string[]>;
  calendarSettings$: Observable<CalendarSettings>;
  userCalendarSettings$: Observable<UserCalendarSettings>;
  isMultiBranch$: Observable<boolean>;
  visitReasons$: Observable<string[]>;
  settingsLoaded$: Observable<boolean>;

  constructor() {
    this.calendarStore = createStore({ name: 'calendar' },
      withProps({
        SelectedBranch: '',
        CalendarSettings: {},
        UserCalendarSettings: {},
        MultiBranch: false,
        CustomVisitTypes: [],
        Resources: [],
        VisitReasons: [],
        WorkingHours: [],
        SettingsLoaded: false
      }));

    this.calendarProviderStore = createStore({ name: 'calendar-providers' },
      withEntities({ initialValue: [], idKey: 'Id' }),
      withActiveIds());

    this.calendarEventStore = createStore({ name: 'calendar-events' },
      withEntities({ initialValue: [], idKey: 'Id' }))

    this.allProviders$ = this.calendarProviderStore.pipe(selectAllEntities());
    this.selectedBranch$ = this.calendarStore.pipe(select(state => state.SelectedBranch));
    this.calendarSettings$ = this.calendarStore.pipe(select(state => state.CalendarSettings));
    this.userCalendarSettings$ = this.calendarStore.pipe(select(state => state.UserCalendarSettings));
    this.isMultiBranch$ = this.calendarStore.pipe(select(state => state.MultiBranch));
    this.selectedProviders$ = this.calendarProviderStore.pipe(selectActiveIds()).pipe(
      map(ids => _.uniq(ids)),
    );
    this.resources$ = this.calendarStore.pipe(select(state => state.Resources));
    this.visitReasons$ = this.calendarStore.pipe(select(state => state.VisitReasons));

    this.settingsLoaded$ = this.calendarStore.pipe(select(state => state.SettingsLoaded));
  }

  setSettingsLoaded(loaded: boolean) {
    this.calendarStore.update(setProp('SettingsLoaded', loaded));
  }

  addProviders(...providers: CalendarProvider[]) {
    this.calendarProviderStore.update(upsertEntities(providers))
  }

  removeProviders(...providerIds: string[]) {
    this.calendarProviderStore.update(toggleActiveIds(providerIds));
    this.calendarProviderStore.update(deleteEntities(providerIds));
  }

  getAllProviders(): CalendarProvider[] {
    return this.calendarProviderStore.query(getAllEntities());
  }

  getAllProvidersForSelectedBranch(): CalendarProvider[] {
    return this.getAllProviders().filter(p => !p.Branches || p.Branches?.includes(this.getSelectedBranch()));
  }

  clearProviders() {
    this.calendarProviderStore.reset();
  }

  getProvider(id: string): CalendarProvider {
    return this.calendarProviderStore.query(getEntity(id));
  }

  updateProvider(provider: CalendarProvider) {
    this.calendarProviderStore.update(upsertEntities(provider));
  }

  hasProvider(id: string) {
    return this.calendarProviderStore.query(hasEntity(id));
  }

  isSelectedProvider(id: string) {
    return this.calendarProviderStore.query(getActiveIds).includes(id);
  }

  setSelectedProviders(...ids: string[]) {
    this.calendarProviderStore.update(addActiveIds(ids));
  }

  toggleSelectedProviders(...ids: string[]) {
    this.calendarProviderStore.update(toggleActiveIds(ids));
  }

  getSelectedProviders() {
    return this.calendarProviderStore.query(getActiveIds);
  }

  resetSelectedProviders() {
    this.calendarProviderStore.update(resetActiveIds());
  }

  setSelectedBranch(branchId: string) {
    this.calendarStore.update(setProp('SelectedBranch', branchId));
  }

  getSelectedBranch() {
    return this.calendarStore.state?.SelectedBranch;
  }

  setCalendarSettings(settings: CalendarSettings) {
    this.calendarStore.update(setProp('CalendarSettings', settings));
  }

  getCalendarSettings() {
    return this.calendarStore.state?.CalendarSettings as CalendarSettings;
  }

  setUserCalendarSettings(settings: UserCalendarSettings) {
    this.calendarStore.update(setProp('UserCalendarSettings', settings));
  }

  getUserCalendarSettings() {
    return this.calendarStore.state?.UserCalendarSettings as UserCalendarSettings;
  }

  setMultiBranch(value: boolean) {
    this.calendarStore.update(setProp('MultiBranch', value));
  }

  getMultiBranch() {
    return this.calendarStore.state?.MultiBranch;
  }

  setResources(resources: string[]) {
    this.calendarStore.update(setProp('Resources', resources));
  }

  setVisitReasons(reasons: string[]) {
    this.calendarStore.update(setProp('VisitReasons', reasons));
  }

  setWorkingHours(workingHours: WorkingHours[]) {
    this.calendarStore.update(setProp('WorkingHours', workingHours));
  }

  getWorkingHours(): WorkingHours[] {
    return this.calendarStore.state?.WorkingHours;
  }

  setCustomVisitTypes(...visitTypes: DurationSetting[]) {
    this.calendarStore.update(setProp('CustomVisitTypes', visitTypes));
  }

  getActiveCustomVisitTypes(): DurationSetting[] {
    return this.calendarStore.state?.CustomVisitTypes.filter(t => t.Status === 'Active');
  }

  event$(eventId: string) {
    return this.calendarEventStore.pipe(selectEntity(eventId));
  }

  updateEvent(event: CalendarEvent) {
    this.calendarEventStore.update(upsertEntities(event));
  }

  removeEvents(...eventIds: string[]) {
    this.calendarEventStore.update(deleteEntities(eventIds))
  }

  removeAllEvents() {
    this.calendarEventStore.update(deleteAllEntities());
  }

}
