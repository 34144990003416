import {Auditable, BaseModel, Person} from "./general.models";

export interface BaseAccountMember extends BaseModel, Person, Auditable {
  Id?: string;
  UUID?: string;
  PreferredName?: string;
  IdentificationType?: IDENTIFICATION_TYPE
  PassportNumber?: string;
  Country?: string;
  RelationshipToMainMember?: string;
  DependantCode?: string;
  AccountMemberStatus?: MEMBER_STATUS;
  Accounts?: Array<string>;
  StatusUpdateReason?: string;
  Employer?: string;
  Occupation?: string;
  PatientFileNo?: string;
  TreatingProvider?: string;
  PrimaryProvider?: string;
  SecondaryProvider?: string;
  MemberSettings?: MemberSettings;
  ValidationStatus?: VALIDATION_STATUS;
  LastValidationDate?: Date;
  LastInvoiceDate?: number;
  _Tags?: string[];
  _index?: Date;
}

export enum IDENTIFICATION_TYPE {
  SAID = 'South African Id no.',
  PASSPORT = 'Passport no.'
};

export enum GENDERS {
  MALE = 'Male',
  FEMALE = 'Female',
}

export const TITLES = [
  {Title: 'Dr', Gender: ''},
  {Title: 'Miss', Gender: GENDERS.FEMALE},
  {Title:'Mr', Gender: GENDERS.MALE},
  {Title:'Mrs', Gender: GENDERS.FEMALE},
  {Title:'Ms', Gender: GENDERS.FEMALE},
  {Title: 'Mx', Gender: GENDERS.FEMALE},
  {Title:'Prof', Gender: ''},
  {Title:'Rev', Gender: ''},
  {Title:'Sir', Gender: GENDERS.MALE},
  {Title:'Sister', Gender: GENDERS.FEMALE}
];

export const MEMBER_RELATIONSHIPS = [
  'Mother',
  'Father',
  'Son',
  'Daughter',
  'Wife',
  'Husband',
  'Sister',
  'Brother',
  'Friend',
  'Other'
];

export enum MEMBER_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}
export interface MemberSettings {
  ConsentToPrivacyPolicy?: CONSENT_TO_PRIVACY_POLICY_SETTING;
  GeneralCommunicationPreferences?: GENERAL_COMMUNICATION_PREFERENCES_SETTING;
  MarketingBulkCommunicationPreferences?: MARKETING_BULK_COMMUNICATION_PREFERENCES_SETTING;
  AppointmentReminderPreferences?: APPOINTMENT_REMINDER_PREFERENCES_SETTING;
  LanguagePreferences?: LANGUAGE_PREFERENCES_SETTING;
}

export enum CONSENT_TO_PRIVACY_POLICY_SETTING {
  YES = 'Yes',
  NO = 'No',
  NO_RESPONSE = 'No response'
}

export enum GENERAL_COMMUNICATION_PREFERENCES_SETTING {
  SMS = 'SMS',
  EMAIL = 'Email',
  BOTH = 'Both'
}

export enum MARKETING_BULK_COMMUNICATION_PREFERENCES_SETTING {
  SMS = 'SMS',
  EMAIL = 'Email',
  BOTH = 'Both',
  OPT_OUT = 'Opt out'
}

export enum APPOINTMENT_REMINDER_PREFERENCES_SETTING {
  SMS = 'SMS',
  EMAIL = 'Email',
  BOTH = 'Both',
  OPT_OUT = 'Opt out'
}

export enum LANGUAGE_PREFERENCES_SETTING {
  ENGLISH = 'English',
  AFRIKAANS = 'Afrikaans',
  NDEBELE = 'IsiNdebele (Ndebele)',
  XHOSA = 'IsiXhosa (Xhosa)',
  ZULU = 'IsiZulu (Zulu)',
  SEPEDI = 'Northern Sotho (Sepedi)',
  SESOTHO = 'Sesotho (Southern Sotho)',
  TSWANA = 'Setswana (Tswana)',
  SWATI = 'SiSwati (Swati)',
  VENDA = 'Tshivenda (Venda)',
  TSONGA = 'Xitsonga (Tsonga)'
}

export enum VALIDATION_STATUS {
  NOT_DONE = 'Not done',
  VALID = 'Valid',
  NOT_VALID = 'Not valid',
  NOT_SUPPORTED = 'Not supported',
  VALIDATING = 'Validating',
  OVERDUE = 'Overdue'
}

export interface MemberAuthorization extends BaseModel {
  Id?: string,
  Type?: string,
  AuthorizationNo?: string,
  TreatingProvider?: string,
  TreatingPracticeNo?: string,
  AuthorizedFrom?: Date,
  AuthorizedTo?: Date,
  AuthorizationStatus?: AUTHORIZATION_STATUS,
}

export enum AUTHORIZATION_STATUS {
  ACTIVE = 'Active',
  DELETED = 'Deleted',
}
