<form [formGroup]="form">
  <div class="flex flex-col gap-3 pb-3">
    <div class="flex flex-row">
      <mat-form-field class="flux-form-field w-[408px]">
        <mat-label> Template name </mat-label>
        <input matInput formControlName="templateName" />
      </mat-form-field>
    </div>

    <div class="flex flex-row">
      <mat-form-field class="flux-form-field w-[408px]">
        <mat-label> Template description </mat-label>
        <input matInput formControlName="templateDescription" />
      </mat-form-field>
    </div>

    <meraki-flux-communication-template-edit-form [formData]="form" [formMode]="formMode" [template]="template" [practice]="practice" [showAttachment]="false"></meraki-flux-communication-template-edit-form>
  </div>
</form>
