import { PrivateRateType } from './private-rate.models';

export interface TariffPriceLookupResponse {
  price?: number;
  unitType?: string;
  numberOfUnits?: number;
  rate?: number;
  rcf?: number;
  tarrifCode?: string;
  description?: string;
  priceGroup?: string;
  material?: boolean;
}

export interface MedicinePriceLookupResponse {
  nappiCode?: string;
  price?: number;
}

export interface PricingLookupResponse<T> {
  hasResult: boolean;
  errorCode?: string;
  message?: string;
  result: T | null
}

export class PrivateRateResponse {
  Id?: string;
  BranchName? : string;
  Code?: string;
  Type?: PrivateRateType;
  Description?: string;
  CashInvoices? :boolean;
  MedicalAidInvoices?: boolean;
  SchemeCode?: string;
  OptionCode?: string;
  DateFrom?: Date;
  DateTo?: Date;
  Price?: number;
  IsActive?: boolean;
  UpdatedAt?: Date;
  UpdatedBy?: string;
  UpdatedByName?: string;
}

export class PriceResponse {
  description?: string;
  price?: number;
}
