import _ from "lodash";
import * as moment from "moment";
import { DateUtils } from "../../utils/date-utils";
import { FormatUtils } from "../../utils/format-utils";
import { PathUtils } from "../../utils/path-utils";
import { SchemeUtils } from "../../utils/scheme-utils";
import {
  ACCOUNT_ENTITY_TYPE,
  ACCOUNT_TYPE,
  BaseAccount,
  BaseAccountMember,
  BaseInvoice,
  Branch,
  CellType,
  ClaimInfo,
  INVOICE_LINE_TYPE,
  INVOICE_SUBTYPE,
  InvoiceLine,
  InvoiceTransaction,
  MAPayment,
  NameValue,
  Payment,
  PAYMENT_TYPE,
  Practice,
  PracticeSettings,
  Reason, ReportSnapscan,
  Scheme, SnapScan,
  StatementReportModel,
  StatementReportRequest,
  StatementTable,
  Transaction,
  TRANSACTION_TYPE
} from "@meraki-flux/schema";
import { getBase64Snapscan } from '@meraki-flux/purejs';

export class StatementReportBuilder {

    private NOW = new Date();

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: StatementReportRequest, transactions: Transaction[], payments: Payment[], scheme: Scheme): Promise<StatementReportModel> {
        const practice = reportRequest.Practice;
        const account = reportRequest.Account;
        const mainMember = reportRequest.MainMember;
        const members = reportRequest.Members;
        const branch = this.matchBranch(practice.Branches, account.HomeBranch);
        const invoiceResult = await this.buildStatementTables(practice, account, members, reportRequest.Invoices, transactions, payments);
        return {
            Logo: practice.Settings.ReportLogoSetting,
            PracticeId: practice.BillingPracticeNumber,
            PracticeName: practice.PracticeName,
            Header: await this.buildMainHeader(account, mainMember),
            PracticeHeader: await this.buildPracticeHeader(practice, branch),
            AccountHeader: await this.buildAccountHeader(account, mainMember, scheme),
            StatementTables: invoiceResult.statementTables,
            ReasonCodes: this.getReasonCodes(transactions, payments, reportRequest.Invoices),
            InfoTable: await this.buildFooterInfoTable(account, practice, branch),
            StatementSummary: invoiceResult.statementSummary,
            StatementAges: invoiceResult.statementAges,
            FooterText:  `Please note that you are personally liable for any difference between the\n${"\t\u200B".repeat(3)}claimed amount and the amount paid by your medical aid.`,
            StatementMessage: reportRequest.StatementMessage,
            StatementDate: moment(new Date()).format(this.DATE_FORMAT),
            Snapscan: await this.getQRImages(reportRequest.Snapscan),
        } as StatementReportModel;
    }

    matchBranch(practiceBranches: Branch[], accountHomeBranch: string) {
        let matchingBranch;

        if (accountHomeBranch)
            matchingBranch = practiceBranches.find((branch) =>
               branch.Name === accountHomeBranch && branch.Active
            )

        if (!matchingBranch)
            matchingBranch = practiceBranches.find((branch) =>
               branch.IsMainBranch && branch.Active
            )
        return matchingBranch;
    }

    async buildMainHeader(account: BaseAccount, patient : BaseAccountMember) {
        return `Statement of Account - ${(patient.Title && patient.Title !== "Unknown")? patient.Title : ""} ${patient.Name} ${patient.Surname} (${account.AccountNo})`;
    }

    async buildFooterInfoTable(account: BaseAccount, practice: Practice, branch: Branch) {
        return {
            bankDetails : {
                practiceName: FormatUtils.join([
                    branch.BankDetails?.ChequeAccountDetails?.AccountHolderName,
                    branch.BankDetails?.ChequeAccountDetails?.Bank
                ], ', '),
                branchName: branch.BankDetails?.ChequeAccountDetails?.BranchCode,
                accountNo: branch.BankDetails?.ChequeAccountDetails?.AccountNo,
                accountType: branch.BankDetails?.ChequeAccountDetails?.AccountType
            },
            bankDetailsEft: branch.BankDetails?.SameAsEFT ? undefined : {
                practiceName: FormatUtils.join([
                    branch.BankDetails?.EFTAccountDetails?.AccountHolderName,
                    branch.BankDetails?.EFTAccountDetails?.Bank
                ], ', '),
                branchName: branch.BankDetails?.EFTAccountDetails?.BranchCode,
                accountNo: branch.BankDetails?.EFTAccountDetails?.AccountNo,
                accountType: branch.BankDetails?.EFTAccountDetails?.AccountType
            },
            eftInfo : {
                paymentRef: account.AccountNo,
                email: branch.ContactDetails?.EmailAddress
            },
            companyInfo : {
                registrationNo: practice.CompanyRegistrationNo,
                vatNo: practice.VatNo
            }
        }
    }

    async buildStatementTables(practice: Practice, account: BaseAccount, members: BaseAccountMember[], invoices: BaseInvoice[], transactions: Transaction[], payments: Payment[])  {
        const statementTables : StatementTable[] = [];
        let patientLiable = 0;
        let medicalAidLiable = 0;
        let balance = 0;
        const unallocated = account.UnallocatedCredit
        let d120 = 0;
        let d90_120 = 0;
        let d60_90 = 0;
        let d30_60 = 0;
        let d0_30 = 0;
        invoices.forEach( (invoice) => {
            patientLiable += invoice.Balance?.PatientLiable
            medicalAidLiable += invoice.Balance?.MedicalAidLiable
            balance += invoice.Balance?.Outstanding
            d120 += this.buildAgeAnalysis(invoice, 120, undefined, practice.Settings.HideMALiable);
            d90_120 += this.buildAgeAnalysis(invoice, 90, 120, practice.Settings.HideMALiable);
            d60_90 += this.buildAgeAnalysis(invoice, 60, 90, practice.Settings.HideMALiable);
            d30_60 += this.buildAgeAnalysis(invoice, 30, 60, practice.Settings.HideMALiable);
            d0_30 += this.buildAgeAnalysis(invoice, 0, 30, practice.Settings.HideMALiable);
        });
        const sortedInvoices = invoices.sort(function(a: BaseInvoice, b: BaseInvoice){
            // @ts-ignore
            return DateUtils.toDate(a.DateOfService) - DateUtils.toDate(b.DateOfService);
          });
          for (const invoice of sortedInvoices) {
            const member = members?.find(m => m.Id === invoice?.Patient?.Id);
            const table = await this.buildStatementTable(practice, account, member, invoice, transactions, payments);
            statementTables.push(table);

          }
        return {
            statementTables: statementTables,
            statementSummary: {
                medicalAidLiable: practice.Settings?.HideMALiable ? undefined : medicalAidLiable,
                patientLiable: patientLiable,
                balance: practice.Settings?.HideMALiable ? undefined : balance
            },
            statementAges : {
                unallocated : unallocated,
                d120: d120,
                d90_120: d90_120,
                d60_90: d60_90,
                d30_60: d30_60,
                d0_30: d0_30
            }
        };
    }

    getReasonCodes(transactions: Transaction[], payments: Payment[], invoices: BaseInvoice[])  {
        const invoiceReasonCodes = invoices.map(i => i?.ClaimInfo?.ReasonCodes)
            ?.filter(rc => rc)
            ?.reduce((acc, v) => acc.concat(v), [])
            ?.map(rc => ({ReasonCode: rc.Code, ReasonDesc: rc.Description} as Reason));
        const transactionIds = transactions.map(t => t.Id);
        const allReasonCodes = payments?.filter(p => PAYMENT_TYPE.MEDICAL_AID === p.Type)
            .map(p => p?.Allocations)
            .reduce((acc, v) => acc.concat(v), [])
            .filter(a => transactionIds.includes(a.TransactionId))
            .map(a => a?.Reasons)
            .filter(r => r)
            .reduce((acc, v) => acc.concat(v), []);
        return _.uniqBy(this.userReasonCodes([...allReasonCodes, ...invoiceReasonCodes]), 'ReasonCode')
            .sort((a, b) => Number(a.ReasonCode) - Number(b.ReasonCode));
    }

    userReasonCodes(reasonCodes: Reason[]) {
      return reasonCodes?.filter((r) => !r.ReasonCode.startsWith('hb_')) || [];
    }

    buildAgeAnalysis(invoice: BaseInvoice, from: number, to?: number, hideMALiable?: boolean) {
        const dos = DateUtils.toDate(invoice.DateOfService);
        const result = hideMALiable ? invoice.Balance?.PatientLiable : invoice.Balance?.Outstanding;
        if (this.dateDiffInDays(dos, this.NOW) >= from) {
            if (to) {
              if (this.dateDiffInDays(dos, this.NOW) < to ) {
                return result;
              } else {
                return 0;
              }
            }
            return result;
        }
        return 0;
    }

    async buildStatementTable(practice: Practice, account: BaseAccount, member: BaseAccountMember, invoice: BaseInvoice, transactions: Transaction[], payments: Payment[]) {
        const headers = []
        headers.push(await this.buildPatientTableHeader(member, invoice));
        headers.push(await this.buildInvoiceTableHeader(account, invoice, practice?.Settings));
        const icdLine = await this.buildInvoiceICDLine(invoice);
        const rowHeaders = await this.buildRowHeaders();
        const rows = [];
        for (const invoiceLine of _.sortBy(invoice.Lines, 'LineNumber')) {
            rows.push(await this.buildTableRow(invoiceLine, invoice?.ClaimInfo))
        }
        [
            ...this.buildPatientPaymentInvoiceTransactions(practice, invoice, transactions, payments),
            ...this.buildMAPaymentInvoiceTransactions(practice, invoice, transactions, payments),
            ...this.buildWriteOffInvoiceTransactions(practice, invoice, transactions),
            ...this.buildCreditNoteInvoiceTransactions(practice, invoice, transactions),
            ...this.buildPaymentCorrectionTransactions(practice, invoice, transactions)
        ]
            .sort((t1: InvoiceTransaction, t2: InvoiceTransaction) => {
                let res = DateUtils.getTime(t1.date) - DateUtils.getTime(t2?.date);
                if (res === 0) res = t1?.label?.toLowerCase().localeCompare(t2?.label?.toLowerCase());
                if (res === 0) res = t1?.superscript?.toLowerCase().localeCompare(t2?.superscript?.toLowerCase());
                return res;
            })
            .map(t => this.buildTableTransactionRow(t))
            .forEach(r => rows.push(r));
        rows.push(await this.buildTotalRow(invoice))
        const table = {
            TableHeaders: headers,
            ICDLine: icdLine,
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        } as StatementTable;
        if (invoice.AmountVAT > 0) {
            table.legend = {
                text: `VAT on invoice: ${FormatUtils.formatCents(invoice.AmountVAT)}`,
                italics: true,
                marginTop: -18
            }
        }
        return table;
    }

    async buildTableRow(invoiceLine: InvoiceLine, claimInfo: ClaimInfo) {
        const reasonCodes = claimInfo?.ReasonCodes
            ?.filter(r => Number(r?.LineNo) === Number(invoiceLine.LineNumber))
            ?.map(r => r.Code);
        const row = [];
        row.push({
            value: invoiceLine.TariffCode
        })
        row.push({
            value: FormatUtils.join(this.formatDescriptionLines(invoiceLine), '\n')
        })
        row.push({
            value: invoiceLine.AmountBilled,
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.MedicalAidLiable||0,
            superscript: FormatUtils.formatReasonCodes(reasonCodes),
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.PatientLiable||0,
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.Outstanding||0,
            type: CellType.CURRENCY
        })
        return row;
    }

    formatDescriptionLines(invoiceLine: InvoiceLine) {
        const desc = _.truncate(invoiceLine.Description, {'length' : 170});
        const nappi = invoiceLine.NappiCode;
        const medicineType = (invoiceLine as any)?.MedicineType;
        const icd10 = invoiceLine.DiagnosisCodes ? `ICD-10: ${invoiceLine.DiagnosisCodes}` : null;
        switch (invoiceLine.LineType) {
            case INVOICE_LINE_TYPE.MEDICINE:
                return [
                    desc,
                    `Nappi: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    medicineType ? `Type: ${medicineType}` : null,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CSTM_MEDICINE:
                return [
                    desc,
                    `Code: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    medicineType ? `Type: ${medicineType}` : null,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CONSUMABLE:
                return [
                    desc,
                    `Nappi: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
                return [
                    desc,
                    `Code: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    icd10
                ];
            default:
                return [
                    desc,
                    invoiceLine.Quantity > 1 ? `QTY: ${invoiceLine.Quantity}` : null,
                    icd10
                ];
        }
    }

    async buildTotalRow(invoice: BaseInvoice) {
        const row = [];
        row.push({
            value: ""
        })
        row.push({
            value: ""
        })
        row.push({
            value: "",
        })
        row.push({
            value: invoice.Balance.MedicalAidLiable||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        row.push({
            value: invoice.Balance?.PatientLiable||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        row.push({
            value: invoice.Balance?.Outstanding||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        return row;
    }

    resize(arr, size) {
        const add = arr.length > size;
        while (arr.length > size) { arr.pop(); }
        if (add) {
            arr[size-1] = arr[size - 1].replace(/.$/,'...')
        }
        return arr;
    }

    async buildRowHeaders() {
        return [
            {value: 'Tariff code'},
            {value: 'Description'},
            {value: 'Amount', type: CellType.NUMBER, width: 60},
            {value: 'Med. aid liable', type: CellType.NUMBER, width: 70},
            {value: 'Patient liable', type: CellType.NUMBER, width: 70},
            {value: 'Balance', type: CellType.NUMBER, width: 60}
        ]
    }

    async buildPatientTableHeader(member: BaseAccountMember, invoice: BaseInvoice) {
        const rows = [];
        rows.push(await this.buildPatientTableHeaderLine(member, invoice));
        return {
            outlined: true,
            rows: rows
        }
    }

    async buildInvoiceTableHeader(account: BaseAccount, invoice: BaseInvoice, practiceSettings: PracticeSettings) {
        const rows = [];
        if (INVOICE_SUBTYPE.MEDICAL_INSURER === invoice.Subtype) {
            rows.push(await this.buildInsurerLineLine(invoice));
        }
        rows.push(await this.buildInvoiceTableHeader1Line(invoice, practiceSettings));
        rows.push(await this.buildInvoiceTableHeader2Line(invoice, account));
        rows.push(await this.buildInvoiceTableHeader3Line(invoice));
        rows.push(await this.buildInvoiceTableHeader4Line(invoice));
        return {
            rows: rows,
            outlined: true,
            outlineColor: '#ececec'
        }
    }

    async buildInvoiceICDLine(invoice: BaseInvoice) {
        return {
            icdCode: `ICD-10 diagnosis: ${invoice.HeaderDiagnosisCodes}`,
            status: `Status: ${invoice.ClaimInfo?.ClaimStatus ? invoice.ClaimInfo?.ClaimStatus : 'Saved'}`,
        }
    }

    async buildPatientTableHeaderLine(member: BaseAccountMember, invoice: BaseInvoice) {
        const rows = [];
        const keyStrings = [];
        if (INVOICE_SUBTYPE.DEBIT_NOTE === invoice.Subtype) keyStrings.push('DEBIT NOTE');
        keyStrings.push('Patient');
        const fullName = member
            ? FormatUtils.join([(member?.Title && member?.Title !== "Unknown")? member?.Title : "", member?.Name, member?.Surname], ' ')
            : FormatUtils.join([(invoice.Patient?.Title && invoice.Patient?.Title !== "Unknown")? invoice.Patient?.Title : "", invoice.Patient?.Name, invoice.Patient?.Surname], ' ');
        await this.addKeyValue(rows, keyStrings.join('/t'), fullName, true);
        const dob = member ? member?.DateOfBirth : invoice.Patient?.DateOfBirth;
        if (dob) {
            await this.addKeyValue(rows, "D.o.b", moment(DateUtils.toDate(dob)).format(this.DATE_FORMAT), true);
        }
        const depCode = member ? member?.DependantCode : invoice.Patient?.DependantCode;
        await this.addKeyValue(rows, "Dep. code", depCode, true);
        return rows;
    }

    async buildInsurerLineLine(invoice: BaseInvoice) {
        const rows = [];
        await this.addKeyValue(rows, "Insurer", invoice?.MedicalInsurer?.Name, true);
        await this.addKeyValue(rows, "ID no.", invoice?.MedicalInsurer?.Id, true);
        await this.addKeyValue(rows, "Policy no.", invoice?.PolicyNo, true);
        return rows;
    }

    async buildInvoiceTableHeader1Line(invoice: BaseInvoice, practiceSettings: PracticeSettings) {
        const rows = [];
        if (invoice.DateOfService) {
            await this.addKeyValue(rows, "Date of service", moment(DateUtils.toDate(invoice.DateOfService)).format(this.DATE_FORMAT), true);
        }
        if (invoice.TreatingProvider) {
            await this.addKeyValue(rows, "Treating provider", `${invoice.TreatingProvider?.FullName.split(',')[0]}`, true);
        }
        await this.addKeyValue(rows, "HPCSA", invoice.TreatingProvider?.HPCSANumber);
        if (practiceSettings?.ShowTPN) {
            await this.addKeyValue(rows, "Treating prac. no.", invoice.TreatingProvider?.TreatingPracticeNumber);
        }
        return rows;
    }

    async buildInvoiceTableHeader2Line(invoice: BaseInvoice, account: BaseAccount) {
        const rows = [];
        if (invoice.InvoiceDate) {
            await this.addKeyValue(rows, "Invoice date", moment(DateUtils.toDate(invoice.InvoiceDate)).format(this.DATE_FORMAT), true);
        }
        await this.addKeyValue(rows, "Invoice no.", invoice.InvoiceNo);
        await this.addKeyValue(rows, "Dispensing no.", invoice.TreatingProvider?.DispensingLicNum);
        if (invoice.Branch && account.HomeBranch !== invoice.Branch) {
            await this.addKeyValue(rows, "Branch", invoice.Branch);
        }
        return rows;
    }

    async buildInvoiceTableHeader3Line(invoice: BaseInvoice) {
        const rows = [];
        await this.addKeyValue(rows, "Auth. no.", invoice.AuthorizationNo);
        await this.addKeyValue(rows, "Referral no.", invoice.ReferralNo);
        await this.addKeyValue(rows, "Lab ref. no.", invoice.LabReferenceNo);
        return rows;
    }

    async buildInvoiceTableHeader4Line(invoice: BaseInvoice) {
        const rows = [];
        if (invoice?.ReferringProvider?.FullName) {
            const referringProvider = invoice.ReferringProvider?.FullName?.split(',')[0] || '';
            const treatingPracticeNumber = invoice.ReferringProvider?.TreatingPracticeNumber || '';
            const referringProviderText = treatingPracticeNumber ? `${referringProvider} (${treatingPracticeNumber})` : referringProvider;
            await this.addKeyValue(rows, "Referring provider", referringProviderText, true);
        }
        if (invoice?.AssistingProvider?.FullName) {
            const assistingProvider = invoice.AssistingProvider?.FullName?.split(',')[0] || '';
            const treatingPracticeNumber = invoice.AssistingProvider?.TreatingPracticeNumber || '';
            const assistingProviderText = treatingPracticeNumber ? `${assistingProvider} (${treatingPracticeNumber})` : assistingProvider;
            await this.addKeyValue(rows, "Assisting provider", assistingProviderText, true);
        }
        return rows;
    }

    async buildPracticeHeader(practice: Practice, branch?: Branch) {
        const right: NameValue[] = await this.buildRightPracticeHeaders(branch);
        const left: NameValue[] = await this.buildLeftPracticeHeaders(branch);
        return {
            left: left,
            right: right
        }
    }

    async buildRightPracticeHeaders(branch?: Branch) {
        const right: NameValue[] = [];
        await this.addKeyValue(right, "Office no", branch?.ContactDetails?.OfficeNo);
        await this.addKeyValue(right, "Email", branch?.ContactDetails?.EmailAddress);
        await this.addKeyValue(right, "Fax", branch?.ContactDetails?.FaxNo);
        await  this.addKeyValue(right, branch?.AdditionalEmails?.Description1 || "Email", branch?.AdditionalEmails?.Address1);
        await this.addKeyValue(right, branch?.AdditionalEmails?.Description2 || "Email", branch?.AdditionalEmails?.Address2);
        await this.addKeyValue(right, branch?.AdditionalEmails?.Description3 || "Email", branch?.AdditionalEmails?.Address3);
        await this.addKeyValue(right, branch?.AdditionalPhones?.Description1 || "Phone", branch?.AdditionalPhones?.Phone1);
        await this.addKeyValue(right, branch?.AdditionalPhones?.Description2 || "Phone", branch?.AdditionalPhones?.Phone2);
        await this.addKeyValue(right, branch?.AdditionalPhones?.Description3 || "Phone", branch?.AdditionalPhones?.Phone3);
        return right;
    }

    async buildLeftPracticeHeaders(branch?: Branch) {
        const left: NameValue[] = [];
        await this.addKeyValue(left, null, branch?.Name);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line1);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line2);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line3);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Code);
        return left;
    }

    async buildAccountHeader(account: BaseAccount, patient: BaseAccountMember, scheme: Scheme) {
        const right: NameValue[] = await this.buildRightAccountHeaders(account, patient, scheme);
        const left: NameValue[] = await this.buildLeftAccountHeaders(account, patient);
        return {
            name: "Statement of Account",
            left: left,
            right: right
        }
    }

    async buildRightAccountHeaders(account: BaseAccount, patient: BaseAccountMember, scheme: Scheme) {
        const right: NameValue[] = [];
        await this.addKeyValue(right, "Account no.", account.AccountNo);
        await this.addKeyValue(right, "File no.", patient.PatientFileNo);
        right.push({name:"", value: {value:""}});
        let medAid = "Private";
        if (account.AccountType === ACCOUNT_TYPE.MEDICAL_AID) {
            medAid = SchemeUtils.formatSpo([scheme], account.Scheme, account.Plan, account.Option);
        }
        await this.addKeyValue(right, "Medical aid", medAid, true);
        await this.addKeyValue(right, "Member no.", account.MemberNo, true);
        return right;
    }

    async buildLeftAccountHeaders(account: BaseAccount, patient: BaseAccountMember) {
        const left: NameValue[] = [];
        if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
            await this.addKeyValue(left, null, account.CompanyName, true);
        } else {
            await this.addKeyValue(left, null, `${(patient.Title && patient.Title !== "Unknown") ? patient.Title : ""} ${patient.Name} ${patient.Surname}`, true);
        }
        await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line1);
        await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line2);
        await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line3);
        await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Code);
        if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
            left.push({name:"", value: {value:""}});
            await this.addKeyValue(left, "Company reg. no.", account.CompanyRegistrationNo, true);
            await this.addKeyValue(left, "VAT no.", account.VATNo, true);
        }
        return left;
    }


    async addKeyValue(col: NameValue[], key: string, value?: string, bold: boolean = false, italic: boolean = false) {
        if (value && value !== "") {
            col.push({name: key, value: {value: value, bold: bold, italic: italic}});
        }
        return col;
    }

    async addToArray(col: string[], value? :string) {
        if (value && value !== "") {
            col.push(value)
        }
    }

    dateDiffInDays(a: any, b: any) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    buildTableTransactionRow(invoiceTransaction: InvoiceTransaction) {
        return [
            { value: "" },
            { value: invoiceTransaction.label, superscript: invoiceTransaction.superscript },
            { value: invoiceTransaction.amount, type: CellType.CURRENCY },
            { value: "" },
            { value: "" },
            { value: "" }
        ];
    }

    buildPatientPaymentInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[], allPayments: Payment[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.PATIENT_PAYMENT === t.TransactionType)
            .map(t => {
                const paymentId = t.Metadata?.PaymentId;
                let txGroup = txGroups.find(it => it.id === paymentId);
                if (!txGroup) {
                    const payment = allPayments.find(p => p.Id === paymentId);
                    txGroup = {
                        id: paymentId,
                        date: payment.PaymentDate,
                        label: FormatUtils.join([
                            `${FormatUtils.dateDDMMYYYY(payment.PaymentDate)}: Patient payment - ${payment?.Type}`,
                            t?.Metadata?.ReferenceNo ? `(${t?.Metadata?.ReferenceNo})` : null
                        ], ' '),
                        amount: t.AmountMAL + t.AmountPL
                    } as InvoiceTransaction;
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    buildMAPaymentInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[], allPayments: Payment[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        const reasonCodesMap = new Map<string, string[]>;
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.MEDICAL_AID_PAYMENT === t.TransactionType)
            .map(t => {
                const paymentId = t.Metadata?.PaymentId;
                const payment = allPayments.find(p => p.Id === paymentId) as MAPayment;
                const allocation = payment?.Allocations?.find(a => t.Id === a.TransactionId);
                const date = payment?.PaymentDate;
                let txGroup = txGroups.find(it => it.id === paymentId);
                if (!txGroup) {
                    txGroup = {
                        id: paymentId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: ${payment?.SchemeName} payment`,
                        amount: (+t.AmountMAL || 0) + (+t.AmountPL || 0)
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
                let reasonCodes = reasonCodesMap.get(paymentId);
                reasonCodes = _.uniq(_.union(reasonCodes, this.userReasonCodes(allocation?.Reasons)?.map(r => r.ReasonCode)));
                reasonCodesMap.set(paymentId, reasonCodes);
                txGroup.superscript = FormatUtils.formatReasonCodes(reasonCodes);
            });
        return txGroups;
    }

    buildWriteOffInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.WRITE_OFF === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.CreatedAt;
                let txGroup = txGroups.find(it => it.id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: ${t?.Metadata?.Note} written off`,
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }


    buildCreditNoteInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.CREDIT === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.Metadata?.HeaderDate;
                let txGroup = txGroups.find(it => it.id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: Credit note - ${t?.Metadata?.CreditType}`,
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    buildPaymentCorrectionTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.PAYMENT_CORRECTION === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.Metadata?.HeaderDate;
                let txGroup = txGroups.find(it => it.id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: FormatUtils.join([`${FormatUtils.dateDDMMYYYY(date) || undefined}: Payment correction`, (t?.Metadata?.Note ? t?.Metadata?.Note : "")], ' - '),
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    async getQRImages(snapScan: SnapScan): Promise<ReportSnapscan> {
        if(snapScan && snapScan.Activated && snapScan.QRCode && snapScan.SnapScanURL) {
            return {
                qr: `${snapScan.SnapScanURL}${snapScan.QRCode}`,
                snapScanPng: getBase64Snapscan(),
            }
        }
        return null;
    }
}
