import {Branch, Practice} from "../practice.models";
import {REPORT_FORMAT_TYPE} from "../report.models";

export class RefundReportRequest {
  Practice: Practice;
  Branch?: Branch;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  UserId: string;
  Multibranch: boolean;
  ReportData: RefundReportData[];
  ReportDate?: string;
  DateRange?: string;
}

export interface RefundReportData {
  RefundDate?: Date;
  RefundAmount?: string;
  AccountNo?: string;
  AccountHolder?: string;
  Branch?: string;
  CapturedBy?: string;
  AccountNotes?: string;
}
