import { Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from '../../services/log.service';
import {ConfigResponse, PBIReportRequest, POWERBI_BRANCHTYPE, PowerBIReportInfo} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class PowerbiReportService {
  reportList$: Observable<PowerBIReportInfo[]>;

  constructor(private logger: LogService) {}

  async getReportList(isMultiBranch:boolean){
    try {
      const functions = getFunctions(getApp(), 'europe-west1');
      const powerBIReportResponse = httpsCallable(
        functions,
        'pbi-rpt-v1-oncall-generatePowerBIReportList'
      );
      const result: any = await powerBIReportResponse();
      if (result?.data) {
        this.reportList$ = of(result?.data?.data?.PowerBIReportList).pipe(
          map((reportList) =>
            reportList.filter((report: PowerBIReportInfo) => {
              if (isMultiBranch) {
                return !report.Name.toLowerCase().includes(POWERBI_BRANCHTYPE.SINGLE_BRANCH);
              } else {
                return !report.Name.toLowerCase().includes(POWERBI_BRANCHTYPE.MULTI_BRANCH);
              }
            })
          ),
          map((reportList) =>
            reportList.map((r: PowerBIReportInfo) => {
              r.Name = r.Name.toLowerCase().replace(POWERBI_BRANCHTYPE.SINGLE_BRANCH, '').replace(
                POWERBI_BRANCHTYPE.MULTI_BRANCH,
                ''
              );
              return r;
            })
          )
        );
      }
    } catch (err) {
      this.logger.error("Error ocurred in power-bi report service. Unable to get report list.",err);
      throw err;
    }

    return this.reportList$;
  }

  async getEmbedConfig(pbiReportRequest: PBIReportRequest): Promise<ConfigResponse> {

  const reportConfigResponse = new ConfigResponse();

    try {
      const functions = getFunctions(getApp(), 'europe-west1');
      const powerBIReportResponse = httpsCallable(
        functions,
        'pbi-rpt-v1-oncall-generatePowerBIReport'
      );
      const result: any = await powerBIReportResponse(pbiReportRequest);
      if (result?.data) {
        reportConfigResponse.Id = result?.data?.data?.ReportId;
        reportConfigResponse.ReportName = result?.data?.data?.Name;
        reportConfigResponse.EmbedUrl = result?.data?.data?.EmbedUrl;
        reportConfigResponse.EmbedToken = result?.data?.data?.AccessToken;
      }

      return reportConfigResponse;
    } catch (err) {
      this.logger.error("Error ocurred in power-bi report service. Unable to get report embed info.",err);
      throw err;
    }
  }
}
