export enum USER_TYPE {
  USER = 'User',
  SYSTEM_ADMIN = 'System admin',
}

export enum USER_ROLE {
  JUNIOR_CAPTURER = 'Junior capturer',
  CAPTURER = 'Capturer',
  PRACTICE_ADMIN = 'Practice admin',
  RECEPTIONIST = 'Receptionist',
  FINANCIAL_ADMIN = 'Financial administrator',
  CUSTOM = 'Custom',
}

export enum SECTION_ACCESS {
  ADMIN = 'admin',
  INVOICE_INBOX = 'invoice inbox',
  CLAIM_INBOX = 'claim inbox',
  COLLECTION_INBOX = 'collection inbox',
  REMITTANCE_INBOX = 'remittance inbox',
  RECONCILIATION_INBOX = 'reconciliation inbox',
}

export enum SYSTEM_ACCESS_CODE {
  PRACTICE_DETAILS_AND_SETTINGS = 'prac1',
  USERS = 'prac2',
  PROVIDERS = 'prac3',
  RATES_AND_CONTRACTS = 'prac4',
  BULK_MESSAGES = 'prac5',
  ACCOUNTS_MANAGEMENT = 'acc1',
  MEDICAL_INSURER_ACCOUNTS_VIEW = 'acc2',
  VIEWING_INVOICES = 'inv1',
  CAPTURE_INVOICES = 'inv2',
  CLAIM_REVERSAL_AND_INVOICE_CANCELLATION = 'inv3',
  SUBMIT_INVOICE_FOR_APPROVAL = 'inv4',
  CAPTURE_PAYMENTS = 'pay1',
  OTHER_DEBIT_CREDIT_TRANSACTIONS = 'pay2',
  UNALLOCATION_REVERSAL_OF_TRANSACTIONS = 'pay3',
  MANUAL_RA_CAPTURE = 'pay4',
  SUSPENSE_ACCOUNT = 'pay5',
  RA_JOURNAL_LIST = 'pay6',
  TRANSFER_LIABILITIES = 'pay7',
  CALENDAR_VIEW_ONLY = 'cal1',
  CALENDAR_MANAGEMENT = 'cal2',
  APPROVE_INVOICE = 'inb1',
  COMPLETE_INVOICES = 'inb2',
  CLAIMS_REJECTIONS = 'inb3',
  CLAIMS_REVERSAL_REJECTED = 'inb4',
  COLLECT_OUTSTANDING_BALANCE = 'inb5',
  ACKNOWLEDGE_ERA_PROCESSED = 'inb6',
  RECONCILE_UNMATCHED_ERA_ITEMS = 'inb7',
  INBOX_DISCARD_ACTIONS = 'inb8',
  CLAIMS_REVERSAL_ACCEPTED = 'inb9',
  PROCESS_JOURNALS = 'inb10',
  DEFAULT = 'default',
  UNALLOCATION_AND_REVERSE_TRANSACTION = 'acc3',
  HB_USER = 'hb1',
}

export const roles = {
  Roles: [
    {
      Title: USER_ROLE.JUNIOR_CAPTURER,
      Description:
        'Capturing of invoices (invoices submitted for review and approval by Capturer)',
      SystemAccess: [
        SYSTEM_ACCESS_CODE.ACCOUNTS_MANAGEMENT,
        SYSTEM_ACCESS_CODE.VIEWING_INVOICES,
        SYSTEM_ACCESS_CODE.CAPTURE_INVOICES,
        SYSTEM_ACCESS_CODE.CALENDAR_VIEW_ONLY,
        SYSTEM_ACCESS_CODE.COMPLETE_INVOICES,
        SYSTEM_ACCESS_CODE.SUBMIT_INVOICE_FOR_APPROVAL,
      ],
      // FileAccess: ['Forms/Templates', 'Stock/Orders', 'Insurance', 'Legal', 'General', 'Medical aid forms', 'Radiology', 'Extermal notes',]
    },
    {
      Title: USER_ROLE.CAPTURER,
      Description:
        'Capturing of invoices. Claim response management (reversals, fixing and resubmitting).',
      SystemAccess: [
        SYSTEM_ACCESS_CODE.ACCOUNTS_MANAGEMENT,
        SYSTEM_ACCESS_CODE.VIEWING_INVOICES,
        SYSTEM_ACCESS_CODE.CAPTURE_INVOICES,
        SYSTEM_ACCESS_CODE.CLAIM_REVERSAL_AND_INVOICE_CANCELLATION,
        SYSTEM_ACCESS_CODE.CALENDAR_VIEW_ONLY,
        SYSTEM_ACCESS_CODE.APPROVE_INVOICE,
        SYSTEM_ACCESS_CODE.COMPLETE_INVOICES,
        SYSTEM_ACCESS_CODE.CLAIMS_REJECTIONS,
        SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_REJECTED,
        SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_ACCEPTED,
      ],
      // FileAccess: ['Forms/Templates', 'Stock/Orders', 'Insurance', 'Legal', 'General', 'Medical aid forms', 'Radiology', 'Extermal notes',]
    },
    {
      Title: USER_ROLE.PRACTICE_ADMIN,
      Description:
        'Manage patient accounts and view invoices. Send bulk messages. Manage private and custom rates.',
      SystemAccess: [
        SYSTEM_ACCESS_CODE.PRACTICE_DETAILS_AND_SETTINGS,
        SYSTEM_ACCESS_CODE.USERS,
        SYSTEM_ACCESS_CODE.PROVIDERS,
        SYSTEM_ACCESS_CODE.RATES_AND_CONTRACTS,
        SYSTEM_ACCESS_CODE.BULK_MESSAGES,
        SYSTEM_ACCESS_CODE.ACCOUNTS_MANAGEMENT,
        SYSTEM_ACCESS_CODE.MEDICAL_INSURER_ACCOUNTS_VIEW,
        SYSTEM_ACCESS_CODE.VIEWING_INVOICES,
        SYSTEM_ACCESS_CODE.CAPTURE_INVOICES,
        SYSTEM_ACCESS_CODE.CLAIM_REVERSAL_AND_INVOICE_CANCELLATION,
        SYSTEM_ACCESS_CODE.CAPTURE_PAYMENTS,
        SYSTEM_ACCESS_CODE.CALENDAR_MANAGEMENT,
        SYSTEM_ACCESS_CODE.APPROVE_INVOICE,
        SYSTEM_ACCESS_CODE.COMPLETE_INVOICES,
        SYSTEM_ACCESS_CODE.CLAIMS_REJECTIONS,
        SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_REJECTED,
        SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_ACCEPTED,
        SYSTEM_ACCESS_CODE.COLLECT_OUTSTANDING_BALANCE,
        SYSTEM_ACCESS_CODE.INBOX_DISCARD_ACTIONS,
      ],
      // FileAccess: ['Forms/Templates', 'Stock/Orders', 'Insurance', 'Legal', 'General', 'Medical aid forms', 'Radiology', 'Extermal notes',]
    },
    {
      Title: USER_ROLE.RECEPTIONIST,
      Description:
        'Manage patient accounts and view invoices. Booking and managing appointments.',
      SystemAccess: [
        SYSTEM_ACCESS_CODE.ACCOUNTS_MANAGEMENT,
        SYSTEM_ACCESS_CODE.VIEWING_INVOICES,
        SYSTEM_ACCESS_CODE.CAPTURE_INVOICES,
        SYSTEM_ACCESS_CODE.CAPTURE_PAYMENTS,
        SYSTEM_ACCESS_CODE.CALENDAR_MANAGEMENT,
      ],
      // FileAccess: ['Forms/Templates', 'Stock/Orders', 'Insurance', 'Legal', 'General', 'Medical aid forms', 'Radiology', 'Extermal notes',]
    },
    {
      Title: USER_ROLE.FINANCIAL_ADMIN,
      Description:
        'Manage private and custom rates. Remittance management and payment capture.',
      SystemAccess: [
        SYSTEM_ACCESS_CODE.MEDICAL_INSURER_ACCOUNTS_VIEW,
        SYSTEM_ACCESS_CODE.CAPTURE_PAYMENTS,
        SYSTEM_ACCESS_CODE.OTHER_DEBIT_CREDIT_TRANSACTIONS,
        SYSTEM_ACCESS_CODE.UNALLOCATION_REVERSAL_OF_TRANSACTIONS,
        SYSTEM_ACCESS_CODE.MANUAL_RA_CAPTURE,
        SYSTEM_ACCESS_CODE.SUSPENSE_ACCOUNT,
        SYSTEM_ACCESS_CODE.RA_JOURNAL_LIST,
        SYSTEM_ACCESS_CODE.TRANSFER_LIABILITIES,
        SYSTEM_ACCESS_CODE.CALENDAR_VIEW_ONLY,
        SYSTEM_ACCESS_CODE.COLLECT_OUTSTANDING_BALANCE,
        SYSTEM_ACCESS_CODE.ACKNOWLEDGE_ERA_PROCESSED,
        SYSTEM_ACCESS_CODE.RECONCILE_UNMATCHED_ERA_ITEMS,
        SYSTEM_ACCESS_CODE.UNALLOCATION_AND_REVERSE_TRANSACTION,
        SYSTEM_ACCESS_CODE.PROCESS_JOURNALS

      ],
      // FileAccess: ['Forms/Templates', 'Stock/Orders', 'Insurance', 'Legal', 'General', 'Medical aid forms', 'Radiology', 'Extermal notes',]
    },
    {
      Title: USER_ROLE.CUSTOM,
      Description:
        'Selected list of system features by the system administrator.',
      SystemAccess: [],
      // FileAccess: []
    },
  ],
};

export const sysAccess = [
  {
    Section: 'Practice Management',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.PRACTICE_DETAILS_AND_SETTINGS,
        Title: 'Practice details and settings',
        Description: '',
        Category: SECTION_ACCESS.ADMIN
      },
      {
        Code: SYSTEM_ACCESS_CODE.USERS,
        Title: 'Users',
        Description: '',
        Category: SECTION_ACCESS.ADMIN
      },
      {
        Code: SYSTEM_ACCESS_CODE.PROVIDERS,
        Title: 'Providers',
        Description: '',
        Category: SECTION_ACCESS.ADMIN
      },
      {
        Code: SYSTEM_ACCESS_CODE.RATES_AND_CONTRACTS,
        Title: 'Rates and contracts',
        Description: 'Managing private rates, network pricing contracts and custom charge codes',
        Category: SECTION_ACCESS.ADMIN
      },
      {
        Code: SYSTEM_ACCESS_CODE.BULK_MESSAGES,
        Title: 'Bulk messages',
        Description: 'Sending bulk messages via SMS or email',
        Category: SECTION_ACCESS.ADMIN
      },
    ],
  },
  {
    Section: 'Accounts and Patient Management',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.ACCOUNTS_MANAGEMENT,
        Title: 'Accounts management',
        Description:
          'Basic account and patient management functions, incl. adding/editing, generating statements and making notes.',
      },
      {
        Code: SYSTEM_ACCESS_CODE.MEDICAL_INSURER_ACCOUNTS_VIEW,
        Title: 'Medical insurer accounts view',
        Description: 'Viewing a list of medical insurers and what they owe.',
      },
      {
        Code: SYSTEM_ACCESS_CODE.UNALLOCATION_AND_REVERSE_TRANSACTION,
        Title: 'Unallocation/Reversal of transactions',
        Description: '',
      },
    ],
  },
  {
    Section: 'Invoice Capture and Claims Management',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.VIEWING_INVOICES,
        Title: 'Viewing invoices',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.CAPTURE_INVOICES,
        Title: 'Capture invoices',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.SUBMIT_INVOICE_FOR_APPROVAL,
        Title: 'Submit invoice for approval',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.CLAIM_REVERSAL_AND_INVOICE_CANCELLATION,
        Title: 'Claim reversals and invoice cancellation',
        Description: '',
      },
    ],
  },
  {
    Section: 'Payments and Reconciliation',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.CAPTURE_PAYMENTS,
        Title: 'Capture payments',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.OTHER_DEBIT_CREDIT_TRANSACTIONS,
        Title: 'Other debit/credit transactions',
        Description:
          'Credit notes, debit notes, write-offs (incl. bulk write-offs), and unallocated payments',
      },
      {
        Code: SYSTEM_ACCESS_CODE.UNALLOCATION_REVERSAL_OF_TRANSACTIONS,
        Title: 'Unallocation/reversal of transactions',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.MANUAL_RA_CAPTURE,
        Title: 'Manual RA capture',
        Description:
          'Setting up the manual RA information, including the RA number, payment date, and total amount paid',
      },
      {
        Code: SYSTEM_ACCESS_CODE.SUSPENSE_ACCOUNT,
        Title: 'Suspense account',
        Description:
          'Reconciling unmatched medical aid payments from remittances.',
      },
      {
        Code: SYSTEM_ACCESS_CODE.RA_JOURNAL_LIST,
        Title: 'RA journal list',
        Description: 'List of journals received from remittances.',
      },
      {
        Code: SYSTEM_ACCESS_CODE.TRANSFER_LIABILITIES,
        Title: 'Transfer liabilities',
        Description:
          'Manually changing the liability from patient to med aid or vice versa',
      },
    ],
  },
  {
    Section: 'Calendar',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.CALENDAR_VIEW_ONLY,
        Title: 'Calendar - view only',
        Description: '',
      },
      {
        Code: SYSTEM_ACCESS_CODE.CALENDAR_MANAGEMENT,
        Title: 'Calendar management',
        Description: '',
      },
    ],
  },
  {
    Section: 'Inbox',
    Options: [
      {
        Code: SYSTEM_ACCESS_CODE.APPROVE_INVOICE,
        Title: 'Invoices: Approve invoice',
        Description: '',
        Category: SECTION_ACCESS.INVOICE_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.COMPLETE_INVOICES,
        Title: 'Invoices: Complete invoices',
        Description: '',
        Category: SECTION_ACCESS.INVOICE_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.CLAIMS_REJECTIONS,
        Title: 'Claims: Rejections',
        Description: '',
        Category: SECTION_ACCESS.CLAIM_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_REJECTED,
        Title: 'Claims: Reversal rejected',
        Description: '',
        Category: SECTION_ACCESS.CLAIM_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_ACCEPTED,
        Title: 'Claims: Reversal accepted',
        Description: '',
        Category: SECTION_ACCESS.CLAIM_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.COLLECT_OUTSTANDING_BALANCE,
        Title: 'Collection: Collect outstanding balance',
        Description: '',
        Category: SECTION_ACCESS.COLLECTION_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.ACKNOWLEDGE_ERA_PROCESSED,
        Title: 'Remittances: Acknowledge eRA processed',
        Description: '',
        Category: SECTION_ACCESS.REMITTANCE_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.RECONCILE_UNMATCHED_ERA_ITEMS,
        Title: 'Reconciliation: Reconcile unmatched eRA item',
        Description: '',
        Category: SECTION_ACCESS.RECONCILIATION_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.PROCESS_JOURNALS,
        Title: 'Remittances: Process journals',
        Description: '',
        Category: SECTION_ACCESS.REMITTANCE_INBOX
      },
      {
        Code: SYSTEM_ACCESS_CODE.INBOX_DISCARD_ACTIONS,
        Title: 'Inbox management: Discard actions',
        Description: '',
      },
    ],
  },
];

export const fileAccess = {
  Practice: [
    'Forms/Templates',
    'Stock/Orders',
    'Insurance',
    'Legal',
    'Employee',
    'Healthbridge',
    'SARS',
    'Utility bills',
    'Medical aid',
  ],
  Account: ['Legal'],
  Patient: [
    'General',
    'Medical aid forms',
    'Radiology',
    'Extermal notes',
    'Reports',
    'Pathology',
    'Patient information',
    'Scripts',
    'Medico legal',
    'Billing instructions',
    'Specialist referal forms',
    'Historical paper record',
    'Clinical notes',
    'Electrocardiographs',
  ],
};

