import { collection, Firestore, getDoc, getDocs, limit, orderBy, query, where } from '@angular/fire/firestore';
import { doc } from '@firebase/firestore';
import * as moment from 'moment';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { PathUtils } from '../../utils/path-utils';
import {
  Account,
  AGE_ANALYSIS_DATE_RANGE_TYPE,
  AgeAnalysisReportRequest,
  BaseAccountMember,
  BaseInvoice,
  CalendarEvent, Invoice,
  INVOICE_STATUS,
  Note,
  NOTE_ORIGIN_TYPE,
  PracticeProvider,
  ReportInfo,
  VisitInfo
} from '@meraki-flux/schema';
import { InvoiceWhereBuilder } from '@meraki-flux/common';
import { first } from 'rxjs/operators';

export abstract class BaseAgeAnalysisReportBuilder {
  protected constructor(protected firestore: Firestore, protected invoiceService: InvoiceService) {
  }

  protected buildReportInfo(reportRequest: AgeAnalysisReportRequest): ReportInfo {
    const reportInfo: ReportInfo = {
      Practice: reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')',
      PracticeId: reportRequest.Practice?.BillingPracticeNumber,
      DateRange: moment(reportRequest.DateFrom).format('DD/MM/YYYY') + ' - ' + moment(reportRequest.DateTo).format('DD/MM/YYYY'),
      IsMultiBranch: reportRequest.Practice?.IsMultiBranch,
      IncludeAccountNotes: reportRequest.IncludeLastNotes,
      DateRangeType: reportRequest.DateRangeType
    };

    return reportInfo as ReportInfo;
  }

  protected async loadInvoices(reportRequest: AgeAnalysisReportRequest) {
    const invoiceWhereBuilder: InvoiceWhereBuilder = InvoiceWhereBuilder.builder().status(INVOICE_STATUS.OPEN);

    const dateFrom = moment(reportRequest.DateFrom).startOf('day').toDate();
    const dateTo = moment(reportRequest.DateTo).endOf('day').toDate();

    if (reportRequest.DateRangeType === AGE_ANALYSIS_DATE_RANGE_TYPE.DATE_OF_SERVICE) {
      invoiceWhereBuilder.dosBetween(dateFrom, dateTo);
    } else if (reportRequest.DateRangeType === AGE_ANALYSIS_DATE_RANGE_TYPE.DATE_OF_SUBMISSION) {
      invoiceWhereBuilder.dateOfSubmissionBetween(dateFrom, dateTo);
    }

    const invoices: BaseInvoice[] = await this.invoiceService.getInvoices(invoiceWhereBuilder.build()).pipe(first()).toPromise();
    return invoices;
  }

  protected async buildAccountMap(practiceId: string, accountIds: string[]): Promise<Map<string, Account>> {

    const accountDocs = await Promise.all(
      accountIds.map(async (accountId) => {
        const account = await getDoc(doc(this.firestore, PathUtils.accountPath(practiceId, accountId)));
        return {id: accountId, account};
      })
    );
    const accounts: Account[] = accountDocs.map(i => ({Id: i.id, ...i.account.data()}))

    const accountMap: Map<string, Account> = new Map<string, Account>;
    accounts.forEach(i => accountMap.set(i.Id, i));

    return accountMap;
  }

  protected async buildPatientMap(practiceId: string, patientIds: string[]): Promise<Map<string, BaseAccountMember>> {

    const patientDocs = await Promise.all(
      patientIds.map(async (patientId) => {
        const patient = await getDoc(doc(this.firestore, PathUtils.patientPath(practiceId, patientId)));
        return {id: patientId, patient};
      })
    );
    const patients: BaseAccountMember[] = patientDocs.map(i => ({Id: i.id, ...i.patient.data()}))

    const map: Map<string, BaseAccountMember> = new Map<string, BaseAccountMember>;
    patients.forEach(i => map.set(i.Id, i));

    return map;
  }

  protected async buildVisitInfoMap(practiceId: string, ids: string[]): Promise<Map<string, CalendarEvent>> {

    const docs = await Promise.all(
      ids.map(async (id) => {
        const entity = await getDoc(doc(this.firestore, PathUtils.calendarEventPath(practiceId, id)));
        return {id: id, entity};
      })
    );
    const entityList: VisitInfo[] = docs.map(i => ({...i.entity.get("VisitInfo")}))

    const map: Map<string, VisitInfo> = new Map<string, VisitInfo>;
    entityList.forEach(i => map.set(i.InvoiceId, i));

    return map;
  }

  protected async buildNotesMap(practiceId: string, accountIds: string[]): Promise<Map<string, Note[]>> {
    const map: Map<string, Note[]> = new Map<string, Note[]>;
    for (const accountId of accountIds) {
      const docRefs = await getDocs(query(collection(this.firestore, PathUtils.accountNoteCollectionPath(practiceId, accountId)),
        where('OriginType', '==', NOTE_ORIGIN_TYPE.USER), limit(3), orderBy('CreatedAt', 'desc')));
      map.set(accountId, docRefs.docs.map(i => i.data() as Note));
    }
    return map;
  }

  protected async loadProviders(practiceId: string): Promise<PracticeProvider[]> {
    const providerDocs = await getDocs(query(collection(this.firestore, `Practice/${practiceId}/Provider`)));
    return providerDocs.docs.map(doc => ({ Id: doc.id, ...doc.data() }) as PracticeProvider);
  }

  protected getProviderName(providers: PracticeProvider[], invoice: Invoice) {
    const practiceProvider = providers.find(x => x.HPCSANumber === invoice.TreatingProvider?.HPCSANumber);
    if (practiceProvider) {
      return `${practiceProvider.Title} ${(practiceProvider.Name)} ${practiceProvider.Surname}`;
    }
    return undefined;
  }
}
