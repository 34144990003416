import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';

@Component({
  selector: 'meraki-flux-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
@UntilDestroy()
export class BreadcrumbsComponent  {

  breadcrumbs: any[] = [];

  breadcrumbs$ = this.breadcrumbsService.breadcrumbs$.pipe(untilDestroyed(this));
  loading$ = this.breadcrumbsService.loading$.pipe(untilDestroyed(this));
  hidden$ = this.breadcrumbsService.hidden$.pipe(untilDestroyed(this));

  constructor(private breadcrumbsService: BreadcrumbsService) {}
}
