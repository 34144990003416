import {Injectable} from "@angular/core";
import {WriteOffsReportBuilder} from "./write-offs-report-builder";
import {WriteOffsXlsReportDataBuilder } from './write-offs-xls-report-data-builder';

import {ReportUtils} from "../report-utils";
import {BaseExcelReportData, Report, REPORT_FORMAT_TYPE, REPORT_NAME, WriteOffReportRequest} from "@meraki-flux/schema";
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class WriteOffsReportService {


  async generateReport(reportRequest: WriteOffReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildReportFileNameDates('WriteOffs',reportRequest.Practice,reportRequest.TreatingProvider?.Provider,
      reportRequest.Branch.Name,reportRequest.ReportFormatType,reportRequest.DateFrom,reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.WRITE_OFF,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: WriteOffReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }


  private async generateXlsReportData(reportRequest: WriteOffReportRequest): Promise<BaseExcelReportData> {
    return new WriteOffsXlsReportDataBuilder().build(
      await new WriteOffsReportBuilder().build(reportRequest));
  }
}


