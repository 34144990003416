import {
  BasePdfReportData, CellType,
  Reason,
  ReportFooter, ReportHeader,
  ReportTable, StatementHeader,
  StatementReportModel,
  StatementTable
} from "@meraki-flux/schema";

export class StatementPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(statementReportModel: StatementReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.logo = statementReportModel.Logo;
        reportData.bpn = statementReportModel.PracticeId;
        reportData.doctorName = statementReportModel.PracticeName;
        reportData.doctorNameColor = StatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.headers = this.buildHeaders(statementReportModel);
        reportData.tables = this.buildTables(statementReportModel);
        reportData.footer = this.buildFooter(statementReportModel);
        reportData.mainHeader = statementReportModel.Header;
        reportData.dateHeader = `Statement date: ${statementReportModel.StatementDate}`;
        reportData.statementSummary = statementReportModel.StatementSummary;
        reportData.statementAges = statementReportModel.StatementAges;
        reportData.footerText = statementReportModel.FooterText;
        reportData.bottomMessage = statementReportModel.StatementMessage;
        reportData.reasonCodes = this.buildReasonCodesTable(statementReportModel.ReasonCodes);
        reportData.snapScan = statementReportModel.Snapscan;

        return reportData;
    }

    buildFooter(statementReportModel: StatementReportModel) : ReportFooter {
        return {
            hbLogo: true,
            infoTable: statementReportModel.InfoTable
        }
    }

    buildTables(statementReportModel: StatementReportModel) : ReportTable[] {
        const tables:ReportTable[] = [];
        statementReportModel.StatementTables.forEach(stTable => {
            tables.push(this.buildReportTable(stTable));
        })
        return tables;
    }

    buildReportTable(stTable: StatementTable) : ReportTable {
        return {
            headers: stTable.TableHeaders.map(x => {
                return {
                    outlined: x.outlined,
                    outlineColor: x.outlineColor,
                    rows: x.rows.map(y => {
                        return y.map(xy => {
                            return {
                                name: xy.name,
                                data: {
                                    value: xy.value.value,
                                    decoration: {
                                        bold: xy.value.bold
                                    }
                                }
                            }
                        })
                    }),
                }
            }),
            reportICDLine: stTable.ICDLine,
            rowHeaders: stTable.rowHeaders,
            rows: stTable.rows,
            borders: stTable.borders,
            legend: stTable.legend
        }
    }

    buildReasonCodesTable(reasonCodes: Reason[]) : ReportTable {
        return {
            name: 'Medical aid reason codes',
            rowHeaders: [ {value: '', width: 'auto'}, {value: '', width: 'auto'} ],
            rows: reasonCodes.map(r => [
                { value: r.ReasonCode, type: CellType.NUMBER },
                { value: r.ReasonDesc }
            ]),
            borders: {
                rowBorderSize: 0
            },
        } as ReportTable
    }

    buildHeaders(statementReportModel: StatementReportModel) : ReportHeader[] {
        const headers:ReportHeader[] = [];
        headers.push(this.buildReportHeader(statementReportModel.PracticeHeader));
        headers.push(this.buildAccountHeader(statementReportModel.AccountHeader));
        return headers;
    }

    buildReportHeader(statementHeader: StatementHeader): ReportHeader {
        return {
            right: statementHeader.right.map(x => {
                return {
                    name: x.name,
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            }),
            left: statementHeader.left.map(x => {
                return {
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            })
        }
    }

    buildAccountHeader(statementHeader: StatementHeader): ReportHeader {
        return {
            name: statementHeader.name,
            nameColor: StatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            includeGeneratedDate: true,
            outlined: true,
            outlineColor: StatementPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            ... this.buildReportHeader(statementHeader)

        }
    }
}
