import { INBOX_CATEGORY, InboxActionsDataExtractionList, InboxActionsDSData, InboxActionsReportInfo, InboxActionsReportModel, InboxActionsReportRequest, InboxReportModel, NoDataError } from "@meraki-flux/schema";
import moment = require("moment");
import * as _ from "lodash";

export class InboxActionsReportBuilder {
    private readonly DATE_FORMAT = 'DD/MM/YYYY';
  
    async build(reportRequest: InboxActionsReportRequest): Promise<InboxActionsReportModel> {
        const reportHeader: InboxActionsReportInfo = await this.buildReportInfo(reportRequest);
        const inboxActionsModel: InboxReportModel = await this.buildInboxActionsModel(reportRequest);
    
        this.checkDataAvailability(inboxActionsModel);
    
        return {
          ReportInfo: reportHeader,
          InboxReportModel: inboxActionsModel,
          ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
      }
    
      private checkDataAvailability(inboxActionsModel: InboxReportModel) {
        let noData = true;
        if (inboxActionsModel.InboxActionsDSData.length > 0) {
          noData = false;
        }
    
        if (noData) throw new NoDataError();
      }
    
      private async buildReportInfo( reportRequest: InboxActionsReportRequest ): Promise<InboxActionsReportInfo> {
        const reportInfo: InboxActionsReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' +reportRequest.Practice?.BillingPracticeNumber +')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    
        if (reportRequest.Practice?.IsMultiBranch === true){
          if(reportRequest.Branch){
            reportInfo.Branch = reportRequest.Branch;
          }else{
            reportInfo.Branch='All';
          }
        }
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
    
        return reportInfo;
      }
    
      private async buildInboxActionsModel(reportRequest: InboxActionsReportRequest): Promise<InboxReportModel> {
        
        const detailsItem: InboxActionsDSData[] = this.buildInboxActionsDetails(reportRequest.InboxActionsList);
        return {
            InboxActionsDSData: detailsItem,
        };
      }
    
      
      private buildInboxActionsDetails(ibxActionsList: InboxActionsDataExtractionList[]): InboxActionsDSData[] {
    
        const detailtems: InboxActionsDSData[] = [];
        let actionDescr;
        if (ibxActionsList !== undefined || ibxActionsList !== null) {
          for (const ibxList of ibxActionsList) {
    
            switch (ibxList.InboxActionDataSet.Category) {
              case INBOX_CATEGORY.INVOICES:
                  actionDescr = 'Account ' + (ibxList.AccountNo ?? '-') +' - '+ (ibxList.Patient ?? '-');
                  break;
              case INBOX_CATEGORY.CLAIMS:
                  actionDescr = (ibxList.Scheme ?? '-') + (ibxList.AccountNo ? ' - Account ' + ibxList.AccountNo : '-') +' - ' +(ibxList.Patient ?? '-');
                  break;
              case INBOX_CATEGORY.COLLECTION:
                  actionDescr = 'Account ' + (ibxList.AccountNo ?? '-') +' - '+ (ibxList.Patient ?? '-');
                  break;
              case INBOX_CATEGORY.REMITTANCES:
                  actionDescr = (ibxList.Scheme ?? '-') + (ibxList.RANum ? ' - RA no. ' + ibxList.RANum : '-') + (ibxList.RADate ? ' - ' + moment(this.convertTimestampToDateTime(ibxList.RADate)).format(this.DATE_FORMAT) : ' -');
                  break;
              case INBOX_CATEGORY.RECONCILIATION:
                  actionDescr = (ibxList.Scheme ?? '-') + (ibxList.DOS ? ' - DOS ' + moment(this.convertTimestampToDateTime(ibxList.DOS)).format(this.DATE_FORMAT) : '-') + ' - ' +(ibxList.Patient ?? ' -');
                  break;
              default:
                  actionDescr = '-';
                  break;
          }
                const detailsObj = {
                  DateCompleted: ibxList.InboxActionDataSet.UpdatedAt ? moment( this.convertTimestampToDateTime(ibxList.InboxActionDataSet.UpdatedAt)).format('DD/MM/YYYY HH:mm:ss'): '',
                  DiscardReason: ibxList.InboxActionDataSet.DiscardReason,
                  ActionGroup: ibxList.InboxActionDataSet.Category,
                  ActionName: ibxList.InboxActionDataSet.Action,
                  ActionDescription: actionDescr,
                  DateCreated:ibxList.InboxActionDataSet.CreatedAt ? moment( this.convertTimestampToDateTime(ibxList.InboxActionDataSet.CreatedAt)).format(this.DATE_FORMAT): '',
                  Branch: ibxList.InboxActionDataSet.BranchName,
                  IbxStatus:ibxList.InboxActionDataSet.Status,
                  User:ibxList.CreatedBy
                };
      
                detailtems.push(detailsObj);
            
          }
        }
        return detailtems;
      }
    
      private convertTimestampToDateTime(val: any): Date {
        if (val) {
          const seconds = val.seconds;
          const nanoseconds = val.nanoseconds;
          const milliseconds = seconds * 1000 + nanoseconds / 1000000;
          const date = new Date(milliseconds);
          return date;
        }
    
        return null;
      }
  }