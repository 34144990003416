import {
  BasePdfReportData,
  CellType,
  PaymentPerProviderItem,
  PaymentPerProviderReportModel,
  PaymentPerProviderReportRequest,
  PaymentPerProviderSummary,
  PDFCellData,
  PDFParameter,
  REPORT_NAME,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class PaymentsPerProviderPdfReportBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = '#000000';

    build(reportModel: PaymentPerProviderReportModel, reportRequest: PaymentPerProviderReportRequest): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = reportRequest.Practice.BillingPracticeNumber;
        reportData.orientation = 'landscape';
        reportData.headers = this.buildHeader(reportModel, reportRequest);
        reportData.tables = this.buildTables(reportModel, reportRequest);
        reportData.dateHeader = `Date generated: ${reportModel.ReportDate}`;
        reportData.footerText = '\t\u200B'.repeat(60);
        reportData.footer = {
          hbLogo: true,
        };
        return reportData;
    }

    private buildHeader(reportModel: PaymentPerProviderReportModel, reportRequest: PaymentPerProviderReportRequest): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({
          name: 'Practice',
          data: { value: reportModel.PaymentPerProviderReportInfo.Practice, decoration: { bold: true } },
        });
        leftParameters.push({
          name: 'Billing practice no.',
          data: { value:reportRequest.Practice.BillingPracticeNumber , decoration: { bold: true } },
        });

        const rightParameters: PDFParameter[] = [];
        if (reportRequest.Multibranch) {
            rightParameters.push({
              name: 'Branch',
              data: { value: reportRequest.Branch?.Name ?? 'All', decoration: { bold: true } },
            });
        }
        rightParameters.push({ name: 'Date range', data: { value: reportModel.PaymentPerProviderReportInfo.DateRange, decoration: { bold: true } } });
        rightParameters.push({
            name: 'Provider',
            data: { value: reportModel.PaymentPerProviderReportInfo.Provider ?? 'All', decoration: { bold: true } },
        });

        return [
          {
            name: REPORT_NAME.PAYMENTS_PER_PROVIDER_REPORT,
            nameColor: PaymentsPerProviderPdfReportBuilder.REPORT_SPECIAL_FONT_COLOR,
            includeGeneratedDate: true,
            left: leftParameters,
            right: rightParameters,
          },
        ];
    }

    private buildTables(reportModel: PaymentPerProviderReportModel, reprotRequest:PaymentPerProviderReportRequest): ReportTable[] {
        const reportTables: ReportTable[] = [];
        reportTables.push(this.buildSummaryTable(reportModel.PaymentPerProviderSummary));
        reportTables.push(this.buildDetailTable(reportModel.PaymentPerProviderItem, reprotRequest.Multibranch));
        return reportTables;
    }

    private buildSummaryTable(paymentSummary: PaymentPerProviderSummary[]): ReportTable {
        const table: ReportTable = {};
        table.name = 'SUMMARY';
        table.nameColor = PaymentsPerProviderPdfReportBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = PaymentsPerProviderPdfReportBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.rowHeaders = [
            { value: 'Provider', decoration: { bold: true }, width: 200 },
            { value: 'Patient payments', decoration: { bold: true } },
            { value: 'Med aid payments', decoration: { bold: true } },
            { value: 'Insurer  payments', decoration: { bold: true } },
            { value: 'Totals', decoration: { bold: true } },
          ];

        table.rowHeadersBold = true;
        const rows: PDFCellData[][] = [];
        let totalPatientPayments = 0;
        let totalMedAidPayments = 0;
        let totalInsurerPayments = 0;
        let totalAll = 0;

        paymentSummary.forEach((item: PaymentPerProviderSummary) => {
            const total = (item.PatientPayments? item.PatientPayments: 0)
                        + (item.MedAidPayments? item.MedAidPayments: 0)
                        + (item.InsurerPayments? item.InsurerPayments : 0);
            totalPatientPayments += item.PatientPayments? item.PatientPayments : 0;
            totalMedAidPayments += item.MedAidPayments? item.MedAidPayments : 0;
            totalInsurerPayments += item.InsurerPayments? item.InsurerPayments : 0;
            totalAll += total;

            const row: PDFCellData[] = [];
            row.push({value: item.Provider});
            row.push({value: item.PatientPayments? item.PatientPayments : 0, type: CellType.CURRENCY});
            row.push({value: item.MedAidPayments? item.MedAidPayments : 0, type: CellType.CURRENCY });
            row.push({value: item.InsurerPayments? item.InsurerPayments : 0, type: CellType.CURRENCY});
            row.push({value: total, type: CellType.CURRENCY, decoration: { bold: true }});
            rows.push(row);


        });

        const row: PDFCellData[] = [];
        row.push({value: 'Totals', decoration: { bold: true }});
        row.push({value: totalPatientPayments? totalPatientPayments : 0 , type: CellType.CURRENCY, decoration: { bold: true }});
        row.push({value: totalMedAidPayments? totalMedAidPayments : 0, type: CellType.CURRENCY, decoration: { bold: true }});
        row.push({value: totalInsurerPayments? totalInsurerPayments : 0, type: CellType.CURRENCY, decoration: { bold: true }});
        row.push({value: totalAll? totalAll : 0, type: CellType.CURRENCY, decoration: { bold: true }});
        rows.push(row);

        table.rows = rows;
        table.borders = {
          hor: true,
          headerBorderSize: 1,
        };

        return table;
    }


    private buildDetailTable(paymentItem: PaymentPerProviderItem[], addBranchColumn?: boolean): ReportTable {

      const table: ReportTable = {};
      table.name = 'DETAIL';
      table.nameColor = PaymentsPerProviderPdfReportBuilder.REPORT_SPECIAL_FONT_COLOR;
      table.nameUnderline = true;
      table.nameUnderlineColor = PaymentsPerProviderPdfReportBuilder.REPORT_SPECIAL_FONT_COLOR;
      table.rowHeaders = [
          {value: 'Payment date', decoration: { bold: true }, width: 100},
          {value: 'Payment type', decoration: { bold: true }, width: 130},
          {value: 'Payment amount', decoration: { bold: true }},
          {value: 'Invoice no.', decoration: { bold: true }, width: 100},
          {value: 'Provider', decoration: { bold: true }, width: 200}
      ];

      table.rowHeadersBold = true;
      const rows = [];
      paymentItem.forEach((item: PaymentPerProviderItem) => {
        const row: PDFCellData[] = [];
        row.push({value: item.PaymentDate});
        row.push({value: item.PaymentType});
        row.push({value: item.PaymentAmount, type: CellType.CURRENCY});
        row.push({value: item.InvoiceNo});
        row.push({value: item.Provider});

        rows.push(row);
      });

      table.rows = rows;
      table.borders = {
        hor: true,
        headerBorderSize: 1,
      };

      return table;

    }
}
