import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { InvoiceLinesComponent } from '../invoice-lines/invoice-lines.component';
import { DialogService } from '../../services/dialog.service';
import {AccountInfo, BenefitCheckLine, CalendarProvider, DialogType} from '@meraki-flux/schema';

@Component({
  selector: 'meraki-flux-edit-bc-lines',
  templateUrl: './edit-bc-lines.component.html',
  styleUrls: ['./edit-bc-lines.component.scss'],
})
export class EditBcLinesComponent implements OnInit {

  @ViewChild(InvoiceLinesComponent) invoiceLines: InvoiceLinesComponent;

  BCLines$ = new BehaviorSubject<BenefitCheckLine[]>([]);
  provider$ = new BehaviorSubject<CalendarProvider>({});
  account$ = new BehaviorSubject<AccountInfo>({});
  type$ = new BehaviorSubject<'BCLines' | 'BCTemplates'>('BCLines');

  constructor(
    private dialogRef: MatDialogRef<EditBcLinesComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {
    this.BCLines$.next(this.data.BCLines);
    this.provider$.next(this.data.provider);
    this.account$.next(this.data.account);
    this.type$.next(this.data.type);
  }

  onNoClick(): void {
    this.dialogRef.close({action: false});
  }

  onSave() {
    const invLines = this.invoiceLines.getLines();
    const bcLines = invLines.map(line => this.invoiceLines.mapInvoiceLineToBCLine(line.value));
    const headerDiagnoses = this.invoiceLines.getHeaderDiagnoses();
    if(this.validate(headerDiagnoses, bcLines)) {
      this.dialogRef.close({action: true, bcLines: bcLines});
    }
  }

  validate(headerDiagnoses, bcLines: BenefitCheckLine[]) {
    if(headerDiagnoses.length <= 0  || bcLines.length <= 0) {
      this.dialogService.showDialog({
        title: 'Incomplete Information',
        message: 'Please specify at least one diagnosis, and at least one line.',
        type: DialogType.WARNING
      });
      return false;
    }
    return true;
  }
}
