<div class="flex flex-col gap-3">
  <mat-form-field class="flux-form-field w-[408px]">
    <mat-label> Insert variable </mat-label>
    <mat-select id="placeholderSelect" [formControl]="insertVariableControl">
      <mat-option *ngFor="let placeholder of placeholders$ | async" [value]="placeholder">
        {{ placeholder.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="formMode === 'email'" [formGroup]="emailForm" class="flex flex-col gap-3">
    <div class="flex flex-row gap-3">
      <mat-form-field class="w-full flux-form-field ">
        <mat-label> Subject </mat-label>
        <input id="subjectInput" matInput formControlName="subject" (blur)="onSubjectBlur($event)" />
      </mat-form-field>
    </div>
    <!--Add attachment-->
    <div class="files" *ngIf="showAttachment">
      <meraki-flux-file-selection (fileAddedEvent)="onFilesAdded($event)"
                                  [events]="removeFileFromChild.asObservable()">
        Add attachment
      </meraki-flux-file-selection>
      <div *ngIf="emailForm.controls.Attachments?.value" class="attachment-container mb-4">
        <mat-chip-list id="attachmentList" class='flux-chip attachment'>
          <mat-chip id="attachment-{{fileIndex}}" *ngFor="let file of emailForm.controls.Attachments?.value; let fileIndex = index" class="flex justify-between items-center w-full">
            <div class="flex items-center">
              <mat-icon class="attachment-icon" matChipAvatar>image</mat-icon>
              {{ removeUUIDFromFileName(file.name) }}
            </div>
            <mat-icon matChipRemove *ngIf="(!file.uploadProgress)" (click)="onFileRemoved(file)">cancel</mat-icon>
            <mat-spinner matChipRemove *ngIf="!(!file.uploadProgress)" class="loading-spinner" diameter="17"></mat-spinner>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="flex flex-row gap-3">
      <editor id="emailBody" class="w-full commSpace" formControlName="body" [init]="editorConfig"
        (onBlur)="onEditorBlur()"></editor>
    </div>
    <div class="flex justify-end">
      <button id="previewButton" mat-flat-button class="flux-button mat-secondary"
        (click)="onPreview()">Preview</button>
    </div>
  </div>

  <div *ngIf="formMode === 'sms'">
    <div class="flex flex-row gap-3">
      <mat-form-field class="w-[408px] flux-textarea-form-field" appearance="fill">
        <textarea id="SMSBody" class="min-h-[190px]" matInput [formControl]="smsBody" style="appearance: fill;"
          (blur)="onSMSBlur($event)"></textarea>
      </mat-form-field>
    </div>
    <div class="flex justify-end commFormField w-[408px]">
      <button mat-flat-button class="flux-button mat-secondary" (click)="onPreview()">Preview</button>
    </div>
  </div>

  <meraki-flux-info-box class="w-[408px]" *ngIf="formMode === 'sms' && isHigherThanMaxContent" type="DANGER" icon="report_problem">
      This message could exceed the
      <span class="font-bold">160</span> character limit for SMSs, depending
      on the length of variables such as practice name, patient name and
      surname, etc. <br /><br />
      This means that you could be paying for more than one message for each
      SMS sent.
  </meraki-flux-info-box>

</div>
