import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, withLatestFrom } from 'rxjs/operators';
import { MaPayableInvoiceHelper } from './ma-payable-invoice.helper';
import {BALANCE_ACTION, Invoice, MaPayableInvoice} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-ma-payable-invoice',
  templateUrl: './ma-payable-invoice.component.html',
  styleUrls: ['./ma-payable-invoice.component.scss'],
})
@UntilDestroy()
export class MaPayableInvoiceComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() invoiceIndex: number;
  @Input() hideHeader: boolean;
  @Output() touch = new EventEmitter<MaPayableInvoice>;

  readonly BALANCE_ACTIONS = [
    BALANCE_ACTION.NO_ACTION,
    BALANCE_ACTION.PATIENT_LIABLE,
    BALANCE_ACTION.WRITE_OFF,
    BALANCE_ACTION.UNALLOCATED_CREDIT,
  ];

  maPayableInvoice: MaPayableInvoice;
  zeroPaidCtrl = new UntypedFormControl(false);
  fullyPaidCtrl = new UntypedFormControl(false);

  constructor(protected helper: MaPayableInvoiceHelper,) {}

  ngOnInit(): void {
    this.maPayableInvoice = this.helper.buildMaPayableInvoice(this.invoice);
    this.setupAmountAllocatedInputHandler();
    this.setupFullyPaidCheckHandler();
    this.setupZeroPaidCheckHandler();
    this.helper.resetAmountAllocatedToZero(this.maPayableInvoice);
  }

  emitFormChanges() {
    this.touch.emit(this.maPayableInvoice)
  }

  setupAmountAllocatedInputHandler() {
    this.maPayableInvoice?.Lines?.forEach(l => l._AmountAllocatedCtrl.valueChanges.pipe(
      withLatestFrom(this.helper.practice()),
      tap(([v, practice]) => this.helper.onChangeLineAllocatedAmount(v, l, this.maPayableInvoice, this.fullyPaidCtrl, practice?.Settings?.WriteOffThreshold || 0)),
      tap(() => this.emitFormChanges()),
      untilDestroyed(this)
    ).subscribe());
  }

  setupFullyPaidCheckHandler() {
    this.fullyPaidCtrl.valueChanges
      .pipe(
        tap((v) =>
          this.helper.onFullyPaidCheck(
            v,
            this.zeroPaidCtrl,
            this.fullyPaidCtrl,
            this.maPayableInvoice
          )
        ),
        tap(() => this.emitFormChanges()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setupZeroPaidCheckHandler() {
    this.zeroPaidCtrl.valueChanges
      .pipe(
        tap((v) => this.onZeroPaidCheck(v)),
        tap(() => this.emitFormChanges()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getDisplayColumns() {
    const commonColumns = [
      'dos',
      'patient',
      'code',
      'invoiced',
      'mal',
      'pl',
      'amountPaid',
      'balance',
      'action',
    ];
    //if (this.maPaymentType !== MA_PAYMENT_TYPE.MEDICAL_INSURER) commonColumns.push('reasoncode'); // hodden for now as not used
    return commonColumns;
  }

  getMaPayableInvoice() {
    return this.maPayableInvoice;
  }

  onZeroPaidCheck(value: boolean) {
    this.helper.onZeroPaidCheck(value, this.fullyPaidCtrl, this.maPayableInvoice);
  }

  preventPaste(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  keyPressNumbersWithDecimal(event: any) {
    if (this.helper.isReasonCodeChar(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
