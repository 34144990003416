import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { LogService } from '../../../services/log.service';
import { DialogService } from '../../../services/dialog.service';
import {DialogType} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  form = this.fb.group({
    userName: [null, Validators.required],
    password: [null, Validators.required],
  });

  hide = true;
  loading = false;
  resetError = false;
  success = false;
  successMsg = '';
  errorMsg = '';
  errorPasswordMsg = '';
  resetCounter = 0;
  errorRetryPasswordMsg = false;
  clearResetMsg = 'false';
  usersWithErrorRetryPasswords = [];
  authenticating = false;
  communication = '';

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private logger: LogService,
    private dialogService: DialogService
  ) {
    this.displayCommunication();
    this.checkIfTimedOut()
  }

  displayCommunication() {
    const today = new Date();

    // the message must only show from 31/01/2024 to 01/03/2024.
    if (today >= new Date('2024-01-31') && today <= new Date('2024-03-01')) {
      this.communication = 'Attention: Please note that revisions have been made to the Healthbridge Terms and Conditions, specifically in the Healthbridge Privacy Policy concerning communication through WhatsApp.';
    }

  }

  async onSubmit() {
    if(this.loading) return;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const username: string = this.form.value.userName;

    if (username.endsWith('@healthbridge.co.za') || username.endsWith("@sintez.co.za") || username.endsWith("@tradebridge.co.za")) {
      this.authenticating = true;
      await this.auth.googleSignin();
      this.loading = false;
      this.logger.info("Admin login event");
    } else {
      this.authenticating = true;
      await this.auth.signInWithEmail(`${username}@healthbridge.healthbridge`, this.form.value.password);
      this.loading = false;
      this.logger.debug("User login event");
    }

    this.authenticating = false;
  }

  checkIfTimedOut() {
    if(sessionStorage.getItem('timedOut')) {
      sessionStorage.removeItem('timedOut');
      this.dialogService.showDialog({
        title: 'Session timed out',
        message: 'Your session has timed out and you need to log in again.',
        type: DialogType.WARNING,
      });
    }
  }
}
