import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import { SmsXlsReportDataBuilder } from './sms-xls-report-data-builder';
import { SmsReportBuilder } from './sms-report-builder';
import {BaseExcelReportData, Report, REPORT_FORMAT_TYPE, REPORT_NAME, SmsReportRequest} from "@meraki-flux/schema";
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class SmsReportService {

  async generateReport(reportRequest: SmsReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildSmsReportFileName('SMS', reportRequest.Practice, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.SMS,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: SmsReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: SmsReportRequest): Promise<BaseExcelReportData> {
    return new SmsXlsReportDataBuilder().build(
      await new SmsReportBuilder().build(reportRequest));
  }


}
