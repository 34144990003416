import { ChangeDetectionStrategy, Component, InjectionToken, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ContextToolbarComponentOptions, ContextToolbarItem, ContextToolbarItemRef, TOOLBAR_ITEM_REF } from '../../interfaces/context-toolbar-item';
import { ContextualToolbarService } from '../../services/contextual-toolbar.service';


@Component({
  selector: 'meraki-flux-contextual-toolbar',
  templateUrl: './contextual-toolbar.component.html',
  styleUrls: ['./contextual-toolbar.component.scss']
})
export class ContextualToolbarComponent implements OnInit {

  @ViewChild('vc', { read: ViewContainerRef }) componentViewContainer: ViewContainerRef;


  _panelVisible = false;

  set contextPanelVisible(value) {
    this._panelVisible = value;
    this.contextToolbarService.panelOpen$.next(value);
  };

  constructor(protected contextToolbarService: ContextualToolbarService, private injector: Injector) { }

  ngOnInit(): void {
    this.contextToolbarService.activeItem$.pipe(
      tap(item => {
        if (!item) {
          this.contextPanelVisible = false;
        }
      })
    ).subscribe();
  };

  toggleContextPanel() {
    this.contextPanelVisible = !this.contextPanelVisible;
  }

  async handleClick(item: ContextToolbarItem) {
    if (this.contextToolbarService.activeItem$.value?.id === item?.id) {
      this.contextToolbarService.setActiveItem(null)
    } else {
      if (item.component) {
        this.contextPanelVisible = false;
        await this.renderComponent(item);
        this.contextToolbarService.setActiveItem(item);
        this.contextPanelVisible = true;
      }
    }

    if (item.onClick) {
      item.onClick(item);
    }
  }

  async renderComponent(item: ContextToolbarItem) {
    const existingComponent = this.componentViewContainer.get(0);
    if (existingComponent) {
      existingComponent.destroy();
    }

    const componentOpts = typeof item.component === 'object' && typeof (<any>item.component).then === 'function' ? await item.component : <ContextToolbarComponentOptions>item.component;

    if (componentOpts) {
      const compInjector = Injector.create({
        providers: [
          {
            provide: TOOLBAR_ITEM_REF, useValue: {
              item,
              updateBadge: (count) => this.updateBadgeCount(item, count)
            } as ContextToolbarItemRef
          }
        ],
        parent: this.injector
      });

      this.componentViewContainer.createComponent(componentOpts.componentType, {
        injector: compInjector,
        ngModuleRef: componentOpts.moduleRef
      });
    }
  }

  private updateBadgeCount(item: ContextToolbarItem, count: number) {
    setTimeout(() => {
      if (!item.badgeOptions) {
        item.badgeOptions = {
          badgeCount: count
        }
      } else {
        item.badgeOptions.badgeCount = count;
      }
    });
  }
}
