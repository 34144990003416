import {Branch, Practice} from '../practice.models';
import {DATE_RANGE_TYPE, REPORT_FORMAT_TYPE} from '../report.models';

export class VATSummaryReportModel {
  Practice?: Practice;
  Branch?: Branch;
  DateRangeType?: DATE_RANGE_TYPE;
  DateFrom?: Date;
  DateTo?: Date;
  ReportFormatType?: REPORT_FORMAT_TYPE;
  UserId?: string;
  Multibranch?: boolean;
  ReportDate?: string;
  DateRange?: string;
  SummaryDetails?: VATData1[];
  VATData1?: VATData1[];
  VATData2?: VATData1[];
  ProviderVATData?: ProviderVATData[];
}

export interface VATData1 {
  key?: string;
  HPCSANumber?: string;
  TreatingProviderName?: string;
  FullName?: string;
  TargetDate: Date;
  CustomDate: string;
  TransactionType?: string;
  OutputVAT?: number;
  InputVAT?: number;
  BalanceVAT?: number;
  RowNum?: number;
  HeaderId?: string;
}

export interface ProviderVATData {
  TreatingProviderName?: string;
  ProviderVATData: VATData1[];
}
