import {Practice} from "../practice.models";
import {REPORT_FORMAT_TYPE} from "../report.models";

export class ERADSReportRequest {
  Practice: Practice;
  UserId: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  ERADSDataList: ERADSData[];
}

export interface ERADSReportModel {
  ReportData: ERADSData[];
  ReportDate?: string;
  Practice?: Practice;
}

export interface ERADSData {
  FunderName?: string;
  DeliveryDate?: string;
}

export interface eRASchedule {
  Schemes?: ERAScheme [];
}

export interface ERAScheme {
  Date?: Date[];
  Scheme?: string;
}


