import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Frequency, RRule, RRuleSet } from 'rrule';
import { DateUtils } from './date-utils';
import {CALENDAR_EVENT_TYPE, CalendarEvent, FREQUENCY, Repeat} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class RRuleUtils {
  static createEventTemplate(event: CalendarEvent): any {
    switch (event.EventType) {
      case CALENDAR_EVENT_TYPE.PATIENT_VISIT:
        return this.createVisitTemplate(event);
      case CALENDAR_EVENT_TYPE.DOCTOR_LEAVE:
        return this.createDoctorLeaveTemplate(event);
      default:
        throw Error(`Unsupported calendar event type ${event.EventType}`);
    }
  }

  static compactObject(data: any) {
    if (!data || typeof data !== 'object' || DateUtils.toDate(data)) {
      return data;
    }
    return Object.keys(data).reduce(function (accumulator, key) {
      const isObject = data[key] && typeof data[key] === 'object' && !DateUtils.toDate(data[key]);
      const value = isObject ? RRuleUtils.compactObject(data[key]) : data[key];
      const isEmptyObject = isObject && !Object.keys(value).length;
      if (value === undefined || isEmptyObject) {
        return accumulator;
      }

      return Object.assign(accumulator, { [key]: value });
    }, {});
  }

  private static createBaseTemplate(event: CalendarEvent): any {
    return {
      EventType: event.EventType,
      TreatingProvider: event.TreatingProvider,
      Branch: event.Branch,
      AllDay: event.AllDay,
    } as CalendarEvent;
  }

  static createVisitTemplate(event: CalendarEvent): any {
    return this.compactObject({
      ...this.createBaseTemplate(event),
      VisitInfo: {
        VisitType: event.VisitInfo?.VisitType,
        CashAccount: event.VisitInfo?.CashAccount,
        CustomVisitTypeName: event.VisitInfo?.CustomVisitTypeName,
        VisitReasonDescription: event.VisitInfo?.VisitReasonDescription,
        AuthorizationNo: event.VisitInfo?.AuthorizationNo,
        PatientInfo: event.VisitInfo?.PatientInfo,
        AccountInfo: event.VisitInfo?.AccountInfo,
      },
    } as CalendarEvent);
  }

  static createDoctorLeaveTemplate(event: CalendarEvent): any {
    return this.compactObject({
      ...this.createBaseTemplate(event),
      DoctorLeaveInfo: {
        Reason: event.DoctorLeaveInfo?.Reason,
      },
    } as CalendarEvent);
  }

  static prepareAllBaseDates(rootEventStartTime: Date, repeat: Repeat, endTimeLT: Date): Date[] {
    const frequency = RRuleUtils.getFrequency(repeat?.Frequency);
    const dates = [rootEventStartTime];
    if (frequency) {
      const rruleSet = new RRuleSet();
      rruleSet.rrule(
        new RRule({
          freq: frequency,
          dtstart: DateUtils.toDate(rootEventStartTime),
          until: DateUtils.toDate(repeat?.EndDate),
        })
      );
      dates.push(...rruleSet.between(rootEventStartTime, endTimeLT));
    }
    return dates;
  }

  static getFrequency(frequency: FREQUENCY) {
    switch (frequency) {
      case FREQUENCY.DAILY:
        return Frequency.DAILY;
      case FREQUENCY.WEEKLY:
        return Frequency.WEEKLY;
      case FREQUENCY.MONTHLY:
        return Frequency.MONTHLY;
      default:
        return null;
    }
  }

  static getRecurrentEventDate(startDate: any, frequency: FREQUENCY, count: number) {
    const rrRuleFreq = RRuleUtils.getFrequency(frequency);
    if (!rrRuleFreq || count < 1) {
      return null;
    }
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
      new RRule({
        freq: rrRuleFreq,
        dtstart: DateUtils.toDate(startDate),
        count: count,
      })
    );
    const dates = rruleSet.all();
    return dates[count - 1];
  }

  static getRecurrentRuleEndDate(startDate: any, frequency: FREQUENCY, count: number) {
    const date = this.getRecurrentEventDate(startDate, frequency, count);
    return moment(date).add(-1, 'day').endOf('day').toDate();
  }

  static getRecurrentRuleEndDateIndex(startTime: any, frequency: FREQUENCY, endDate: Date) {
    const rruleFrequency = RRuleUtils.getFrequency(frequency);
    if (!rruleFrequency || !endDate) {
      return null;
    }
    const dates = [startTime];
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
      new RRule({
        freq: rruleFrequency,
        dtstart: DateUtils.toDate(startTime),
        until: DateUtils.toDate(endDate),
      })
    );
    dates.push(...rruleSet.between(startTime, endDate));
    return dates.length;
  }

  static isRepeatChanged(repeat1: Repeat, repeat2: Repeat) {
    return (
      repeat1?.Frequency !== repeat2?.Frequency ||
      DateUtils.isDifferent(repeat1?.EndDate, repeat2?.EndDate)
    );
  }
}
