import { AuthService } from '../auth.service';
import { DocumentData, Firestore, Query, QuerySnapshot, addDoc, collection, getCountFromServer, getDocs, query, where } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { PathUtils } from '../../utils/path-utils';
import {INVOICE_TEMPLATE_TYPE, TEMPLATE_MODAL_OPTIONS} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  constructor(
    private firestore: Firestore,
    public auth: AuthService,
  ) {}

  async getTemplateDocuments(providerId: string, option: TEMPLATE_MODAL_OPTIONS): Promise<QuerySnapshot<DocumentData>> {
    const collectionPath = `Practice/${this.auth.selectedBPN}/Provider/${providerId}/InvoiceTemplate`;
    const templatesCollection = collection(this.firestore, collectionPath);

    let docQuery: Query<DocumentData> = query(templatesCollection, where('IsActive', '==', true));
    switch (option) {
      case TEMPLATE_MODAL_OPTIONS.OPTION_DIAGNOSES:
        docQuery = query(docQuery, where('Type', '==', INVOICE_TEMPLATE_TYPE.DIAGNOSIS));
        break;
      case TEMPLATE_MODAL_OPTIONS.OPTION_PROCEDURES:
        docQuery = query(docQuery, where('Type', '==', INVOICE_TEMPLATE_TYPE.PROCEDURE));
        break;
      case TEMPLATE_MODAL_OPTIONS.OPTION_MEDICINES:
        docQuery = query(docQuery, where('Type', '==', INVOICE_TEMPLATE_TYPE.MEDICINE));
        break;
      case TEMPLATE_MODAL_OPTIONS.OPTION_CONSUMABLES:
        docQuery = query(docQuery, where('Type', '==', INVOICE_TEMPLATE_TYPE.CONSUMABLE));
        break;
      case TEMPLATE_MODAL_OPTIONS.OPTION_TEMPLATES:
        docQuery = query(docQuery, where('Type', '==', INVOICE_TEMPLATE_TYPE.INVOICE));
        break;
      case TEMPLATE_MODAL_OPTIONS.OPTION_ALL:
      default:
        break;
    }

    const templates = await getDocs(docQuery);
    return templates;
  }

  async getProviderOptions(practiceId: string, providerId?: string): Promise<QuerySnapshot<DocumentData>>{
    const collectionPath = PathUtils.practiceProviderCollectionPath(practiceId);
    const providersCollection = collection(this.firestore, collectionPath);
    let docQuery: Query<DocumentData> = query(providersCollection);
    if (providerId)
      docQuery = query(docQuery, where('UserId', '!=', providerId))
    const providers = await getDocs(docQuery);
    return providers;
  }

  async copyDocuments(practiceId: string, destinationProviderId: string, data: QuerySnapshot<DocumentData>) {
    const collectionPath = PathUtils.providerTemplatesPath(practiceId, destinationProviderId);
    const templatesCollection = collection(this.firestore, collectionPath);
    data.docs.forEach(document => {
      addDoc(templatesCollection, document.data());
    })
  }

  async activeTemplatesCount(practiceId: string, providerId: string) {
    if (!practiceId || !providerId) return undefined;
    const count = await getCountFromServer(
      query(
        collection(this.firestore, PathUtils.providerTemplatesPath(practiceId, providerId)),
        where('IsActive', '==', true)
      )
    );
    return count.data().count;
  }

}
