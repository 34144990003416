import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'meraki-flux-colour-picker',
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss'],
})
export class ColourPickerComponent implements OnInit {

  @Input() initialColour = '#2C6ECB';
  @Output() onColourChanged = new EventEmitter<string>();
  selectedColour = '';

  colours = [
    '#F14F16',
    '#FC9738',
    '#FFCE00',
    '#BF3468',
    '#E0225C',
    '#FF6C99',
    '#008060',
    '#7A9F32',
    '#ADBB47',
    '#008DA6',
    '#3DB59E',
    '#8AD99B',
    '#5A2768',
    '#624290',
    '#A359AF',
    '#283BA0',
    '#2C6ECB',
    '#27B5E1'
  ];

  colour;

  moreColoursVisible = false;

  constructor() { }

  ngOnInit(): void { 
    this.selectedColour = this.initialColour;
  }

  onToggleMoreColours(e) {
    e.stopPropagation();
    this.moreColoursVisible = !this.moreColoursVisible;
  }

  onColourClicked(e, colour: string) {
    e.stopPropagation();
    this.selectedColour = colour;
  }

  onDone() {
    this.onColourChanged.emit(this.selectedColour);
  }

  onCancel() {
    
  }
}
