import {Observable} from "rxjs";

export interface DialogOptions {
  message?: string;

  title?: string;

  /**
   * The type of dialog to show.
   * If no type is specified, info will be used.
   */
  type?: DialogType;

  /**
   * The buttons to show on the modal.
   * The ID of the button will be passed into the close observable.
   * If no buttons are specified, a default Ok button will be added.
   */
  buttons?: DialogButton[];

  width?: string;

  dialogData?: any;
}

export interface RadioButtonDialogOptions extends DialogOptions{
  options?: string[];
  defaultOption: string;
}

export interface DialogButton {
  id: string;
  caption: string;

  /**
   * Style of the button to use (color)
   */
  style?: DialogButtonStyle;

  primaryAction?: boolean;

  disabled?: boolean;
}

export interface DialogResponse {

  /**
   * Returns an Observable that is triggered when the dialog opens.
   */
  opened: Observable<void>;

  /**
   * Returns an Observable of the button id that triggerred the close event.
   * If the button id is null the dialog was closed externally or by clicking on the backdrop.
   */
  closed: Observable<string | {id: string, data?: any}>;
}

export interface AlertOptions {
    title?: string,
    message: string,
    type?: DialogType,
    icon?: string,
    action?: AlertAction;
    displayDuration?: number;
}

export interface AlertAction {
  label?: string;
  callback?: () => any;
}

export enum DialogType {
  INFO = 0,
  WARNING = 1,
  ERROR = 2,
  QUESTION = 3,
  SUCCESS = 4
}

export enum DialogButtonStyle {
  PRIMARY = 0,
  SECONDARY = 1,
  DANGER = 2,
  SUCCESS = 3
}

export const OK_BUTTON_ID = 'OK';
export const CANCEL_BUTTON_ID = 'CANCEL';
export const YES_BUTTON_ID = 'YES';
export const NO_BUTTON_ID = 'NO';

export const OK_BUTTON: DialogButton = { id: OK_BUTTON_ID, caption: 'Ok', style: DialogButtonStyle.PRIMARY, primaryAction: true };
export const CANCEL_BUTTON: DialogButton = { id: CANCEL_BUTTON_ID, caption: 'Cancel', style: DialogButtonStyle.SECONDARY };
export const YES_BUTTON: DialogButton = { id: YES_BUTTON_ID, caption: 'Yes', style: DialogButtonStyle.SECONDARY };
export const NO_BUTTON: DialogButton = { id: NO_BUTTON_ID, caption: 'No', style: DialogButtonStyle.PRIMARY, primaryAction: true };
export const YES_BUTTON_REV: DialogButton = { id: YES_BUTTON_ID, caption: 'Yes', style: DialogButtonStyle.PRIMARY, primaryAction: true };
export const NO_BUTTON_REV: DialogButton = { id: NO_BUTTON_ID, caption: 'No', style: DialogButtonStyle.SECONDARY };

export const YES_NO_BUTTON_SET = [YES_BUTTON, NO_BUTTON];
export const NO_YES_BUTTON_SET = [NO_BUTTON_REV, YES_BUTTON_REV];
