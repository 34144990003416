import { Injectable } from '@angular/core';
import { writeBatch } from '@angular/fire/firestore';
import {
  DateUtils,
  InvoiceWhereBuilder,
  MoneyUtils,
  PathUtils,
} from '@meraki-flux/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractUnmatchedEraItemActionHelper } from './unmatched-era-item-action-abstract.helper';
import {
  Allocation,
  Invoice,
  INVOICE_STATUS,
  INVOICE_SUBTYPE,
  INVOICE_TYPE,
  MaPayableInvoice, MAPayment,
  PayableInvoice, PAYMENT_TYPE, REMITTANCE_ADVICE_TYPE, REMITTANCE_NOTE_TYPE, RemittanceAdvice, RemittanceClaim
} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class PositiveUnmatchedEraItemActionHelper extends AbstractUnmatchedEraItemActionHelper {
  override hasUserChangesOnInvoices(invoices: PayableInvoice[]): boolean {
    const hasAllocations = this.getAccountInvoicesAllocated(invoices) > 0;
    const hasZeroPaid = this.getZeroPaidInvoices(invoices as MaPayableInvoice[]).length > 0;
    return hasAllocations || hasZeroPaid;
  }

  override getRaClaimLineColumns() {
    return [
      'line',
      'tariffCode',
      'nappiCode',
      'claimed',
      'tariff',
      'paid',
      'rejected',
      'reasoncode',
    ];
  }

  override invoices(
    accountId: string,
    paymentDate: Date,
    yearsToSelect: number
  ): Observable<Invoice[]> {
    if (!accountId) return of([]);
    const wheres = InvoiceWhereBuilder.builder()
      .accountId(accountId)
      .type(INVOICE_TYPE.MEDICAL_AID)
      .subtype(INVOICE_SUBTYPE.NONE)
      .statusIn(INVOICE_STATUS.OPEN, INVOICE_STATUS.CLOSED)
      .dateOfServiceGOE(DateUtils.addYears(paymentDate, -yearsToSelect))
      .build();
    return this.invoiceService
      .getInvoices(wheres)
      .pipe(map((invoices) => invoices.filter((i) => i.ClaimInfo))); // just in case, claimInfo must exist
  }

  override filterInvoices(
    invoices: Invoice[],
    raClaim: RemittanceClaim,
    outstandingBalanceInvoices: boolean,
    filterOnClaimMatches: boolean
  ): Invoice[] {
    return (invoices || [])
      .filter((invoice) => {
        if (outstandingBalanceInvoices && !(invoice?.Balance?.Outstanding > 0)) return false;
        if (filterOnClaimMatches && !this.hasInvoiceToRaClaimMatch(invoice, raClaim)) return false;
        return true;
      })
      .sort((a, b) => a?.DateOfService?.getTime() - b?.DateOfService?.getTime());
  }

  hasInvoiceToRaClaimMatch(invoice: Invoice, raClaim: RemittanceClaim) {
    const invoiceLevelMatch =
      DateUtils.isSameDay(invoice?.DateOfService, raClaim?.LineItem[0]?.DOS);
    if (!invoiceLevelMatch) return false;
    return true;
  }

  getZeroPaidInvoices(invoices: MaPayableInvoice[]) {
    return invoices?.filter((i) => i._ZeroPaid === true);
  }

  override async saveAsReconciled(
    accountId: string,
    inboxId: string,
    ra: RemittanceAdvice,
    raClaimId: string,
    formValue: any,
    invoices: PayableInvoice[]
  ) {
    const totalAllocated = this.getTotalAllocated(invoices, formValue.UnallocatedAmount || 0);
    const allocations = this.getMaPaymentAllocations(raClaimId, invoices as MaPayableInvoice[]);
    const maPayment = {
      PaymentDate: ra.Payment.PayDate,
      Type: PAYMENT_TYPE.MEDICAL_AID,
      ReferenceNo: ra?.Payment?.RANum || null,
      AmountPaid: totalAllocated,
      RemittanceClaimIds: [raClaimId],
      Allocations: allocations,
      AccountNote: formValue.AdditionalAccountNotes || '',
      SchemeName: ra.SchemeName || '',
      RemittanceAdviceId: ra.Id,
      RemittanceAdviceType: REMITTANCE_ADVICE_TYPE.ELECTRONIC,
      Manual: true,
    } as MAPayment;
    const success = await this.transactionCaptureService.capturePayment(accountId, maPayment); // wait for payment to be processed
    if (!success) throw Error('Failed to process payment');
  }

  getMaPaymentAllocations(raClaimId: string, invoices: MaPayableInvoice[]): Allocation[] {
    const allocations = [];
    invoices.forEach((invoice) => {
      invoice?.Lines.forEach((line) => {
        const amountAllocated = MoneyUtils.toCents(line._AmountAllocatedCtrl.value);
        if (amountAllocated !== 0 || (amountAllocated === 0 && invoice._ZeroPaid === true)) {
          allocations.push({
            InvoicePath: PathUtils.invoicePath(this.authService.selectedBPN, invoice.Id),
            InvoiceLineNo: line.LineNumber,
            AmountAllocated: amountAllocated,
            Action: line._ActionCtrl.value,
            RemittanceClaimId: raClaimId,
          } as Allocation);
        }
      });
    });
    return allocations;
  }
}
