import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {Observable} from "rxjs";
import {roles, SECTION_ACCESS, sysAccess, SYSTEM_ACCESS_CODE, USER_ROLE, USER_TYPE} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class SystemAccessHelperService {

  constructor(
    private authService: AuthService,
  ) {}

  hasAccess(systemAccess: SYSTEM_ACCESS_CODE | USER_ROLE, sysAdmin: boolean = true): Observable<boolean> {
    return this.authService.testAccess(this.buildAccessList(systemAccess, sysAdmin));
  }

  notHaveAccess(systemAccess: SYSTEM_ACCESS_CODE) {
    return this.authService.testAccess(this.buildAccessList(systemAccess, false)).pipe(map(res => !res));
  }

  hasAccessToSection(sectionAccess: SECTION_ACCESS) {
    return this.authService.testAccess(this.buildSectionAccess(sectionAccess));
  }

  hasAccessToSections(sectionsAcess: SECTION_ACCESS[]) {
    let sectionAccessList = []
    sectionsAcess.forEach(section => {
      sectionAccessList = sectionAccessList.concat(this.buildSectionAccess(section));
    });
    return this.authService.testAccess(sectionAccessList);
  }

  private buildAccessList(systemAccess: SYSTEM_ACCESS_CODE | USER_ROLE, sysAdmin: boolean = true) {
    let accessList: any[] = sysAdmin ? [USER_TYPE.SYSTEM_ADMIN] : [];
    if(systemAccess) {
      if(systemAccess === SYSTEM_ACCESS_CODE.DEFAULT) {
        accessList.push(USER_TYPE.USER);
      }
      let concatArr = Object.values(SYSTEM_ACCESS_CODE).some(val => val === systemAccess) ? this.buildSystemAccessCodeList(systemAccess as SYSTEM_ACCESS_CODE) : this.buildRoleAccessList(systemAccess as USER_ROLE);
      accessList = accessList.concat(concatArr);
    }
    return accessList;
  }

  private buildSystemAccessCodeList(systemAccessCode: SYSTEM_ACCESS_CODE) {
    const accessList: any[] = [];
    const systemAccessRoles = roles.Roles.filter(role => role.SystemAccess.includes(systemAccessCode));
    systemAccessRoles?.forEach(sysAccessRole => {
      accessList.push(sysAccessRole.Title);
    });
    accessList.push(systemAccessCode);
    return accessList;
  }

  private buildRoleAccessList(role: USER_ROLE) {
    const accessList: any[] = [];
    accessList.push(role);
    return accessList;
  }

  private buildSectionAccess(sectionAccess: SECTION_ACCESS, sysAdmin: boolean = true) {
    let accessList: any[] = sysAdmin ? [USER_TYPE.SYSTEM_ADMIN] : [];
    const sysAccessOptions = sysAccess.map(access => access.Options).reduce((a,v) => a.concat(v), []);
    return accessList.concat(sysAccessOptions.filter(option => option.Category === sectionAccess).map(option => option.Code));
  }
}
