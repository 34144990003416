import {
  BasePdfReportData,
  ProofOfSubmissionReportModel,
  ProofOfSubmissionTable,
  ReportFooter,
  ReportTable
} from "@meraki-flux/schema";

export class ProofOfSubmissionPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(proofOfSubmissionReportModel: ProofOfSubmissionReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = proofOfSubmissionReportModel.PracticeId;
        reportData.doctorName = proofOfSubmissionReportModel.PracticeName;
        reportData.reportName = "Proof of Claim Submission";
        reportData.reportNameColor = ProofOfSubmissionPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.tables = this.buildTables(proofOfSubmissionReportModel);
        reportData.dateHeader = `Date generated: ${proofOfSubmissionReportModel.ReportDate}`;
        reportData.footer = this.buildFooter()
        reportData.claimDetailData = proofOfSubmissionReportModel.ProofOfSubmissionData
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.mainHeader = "Proof of Claim Submission";
        reportData.letterTable = proofOfSubmissionReportModel.LetterTable;
        reportData.letterText = proofOfSubmissionReportModel.LetterText;
        return reportData;
    }

    buildFooter() : ReportFooter {
        return {
            hbLogo: true,
        }
    }

    buildTables(proofOfSubmissionReportModel: ProofOfSubmissionReportModel) : ReportTable[] {
        const tables:ReportTable[] = [];
        proofOfSubmissionReportModel.ProofOfSubmissionTables.forEach(stTable => {
            tables.push(this.buildReportTable(stTable));
        })
        return tables;
    }

    buildReportTable(inTable: ProofOfSubmissionTable) : ReportTable {
        return {
            reportICDLine: inTable.ICDLine,
            rowHeaders: inTable.rowHeaders,
            rows: inTable.rows,
            borders: inTable.borders
        }
    }
}
