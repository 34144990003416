import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {NotificationMessage} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-report-status-notification',
  templateUrl: './report-status-notification.component.html',
  styleUrls: ['./report-status-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportStatusNotificationComponent  {

  constructor(
    private sbRef: MatSnackBarRef<ReportStatusNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationMessage
  ) {
  }

  onClose() {
    this.sbRef.dismiss();
  }

  get typeClass() {
    if (this.data?.ReportStatusNotification?.IsError) {
      return 'danger'
    } else {
      return 'info'
    }
  }

  get typeIcon() {
    if (this.data?.ReportStatusNotification?.IsError) {
      return 'report_problem';
    } else {
      return 'info_outlined';
    }
  }
}
