import { Injectable } from '@angular/core';
import { TransactionCaptureService } from './transaction-capture.service';
import {ERROR_CODE, Reverse} from "@meraki-flux/schema";

@Injectable()
export class WriteOffService {
  constructor(private transactionCaptureService: TransactionCaptureService) {}

  async reverse(
    accountId: string,
    writeOffTxId: string,
    reason: string,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const reverse = { WriteOffTxId: writeOffTxId, Reason: reason } as Reverse;
    await this.transactionCaptureService.reverse(accountId, reverse, onCompleteFn);
  }
}
