import { Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  Firestore,
  collection,
  collectionSnapshots,
  doc,
  docSnapshots,
  getDoc,
  query,
} from '@angular/fire/firestore';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '../utils/date-utils';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import {RemittanceAdvice, RemittanceClaim, RemittanceJournal} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class RemittanceService {
  schemeLookup$ = docSnapshots(doc(this.firestore, 'Configuration/SchemeLookup'));

  constructor(private firestore: Firestore, private authService: AuthService) {}

  private async updateHeader(remittance: RemittanceAdvice) {
    if (!remittance.SchemeName) return;
    remittance.Header = remittance.Header || {};
    const docData = (await getDoc(doc(this.firestore, 'Configuration/SchemeLookup'))).data();
    const acronyms = docData?.RASchemeNameAcronyms;
    const obo = Object.keys(acronyms).find((key) => acronyms[key] === remittance.SchemeName);
    remittance.Header.OBO = obo || remittance.SchemeName;
  }

  async process(remittance: RemittanceAdvice) {
    await this.updateHeader(remittance);
    const functions = getFunctions(getApp(), 'europe-west1');
    const hbRemittance = JSON.stringify({
      ClaimMessage: {
        RemittanceAdvice: remittance,
        Header: remittance.Header,
      },
    });
    const processRemittance = httpsCallable(functions, 'rem-utl-v1-oncall-processRemittance');
    await processRemittance({
      body: {
        message: {
          data: btoa(hbRemittance),
        },
      },
    });
  }

  async remittance(practiceId: string, remittanceId: string, validate: boolean = true) {
    if (!practiceId || !remittanceId) return undefined;
    const path = PathUtils.remittanceAdvicePath(practiceId, remittanceId);
    return await this.getDocument(path, validate) as RemittanceAdvice;
  }

  async raClaim(
    practiceId: string,
    remittanceId: string,
    remittanceClaimId: string,
    validate: boolean = true
  ) {
    if (!practiceId || !remittanceId || !remittanceClaimId) return undefined;
    const path = PathUtils.remittanceClaimPath(practiceId, remittanceId, remittanceClaimId);
    return await this.raClaimByPath(path, validate) as RemittanceClaim;
  }

  async raClaimByPath(
    raClaimPath: string,
    validate: boolean = true
  ) {
    if (!raClaimPath) return undefined;
    return await this.getDocument(raClaimPath, validate) as RemittanceClaim;
  }

  async getDocument(path: string, validate: boolean = false) {
    const raDoc = await getDoc(doc(this.firestore, path));
    if (!raDoc.exists()) {
      if (validate) throw Error(`Document doesn't exist ${path}`);
      else return undefined;
    }
    return DateUtils.timestampsToDates({ ...raDoc.data(), Id: raDoc.id }) as RemittanceClaim;
  }

  raClaims$(practiceId: string, remittanceId: string): Observable<RemittanceClaim[]> {
    return collectionSnapshots(
      query(
        collection(
          this.firestore,
          PathUtils.remittanceClaimCollectionPath(practiceId, remittanceId)
        )
      )
    ).pipe(
      map((docs) =>
        docs.map((d) => DateUtils.timestampsToDates({ ...d.data(), Id: d.id } as RemittanceClaim))
      )
    );
  }

  raJournals$(practiceId: string, remittanceId: string): Observable<RemittanceJournal[]> {
    return collectionSnapshots(
      query(
        collection(
          this.firestore,
          PathUtils.remittanceJournalCollectionPath(practiceId, remittanceId)
        )
      )
    ).pipe(
      map((docs) =>
        docs.map((d) => DateUtils.timestampsToDates({ ...d.data(), Id: d.id } as RemittanceJournal))
      )
    );
  }
}
