<div class="flex flex-row w-screen h-screen overflow-hidden bg-hb-base-white">
  <!-- Left panel -->
  <div class="flex flex-col flex-1 items-center overflow-auto h-full">

    <!-- Login form -->
    <div [formGroup]="form" class="self-center flex flex-col mt-[20%] max-w-[420px] pb-8" (keyup.enter)="onSubmit()">
    
      <img class="justify-self-center pb-[35px] max-w-[370px]" src="assets/images/hb_logo_login_new.png" />
      <label class="font-medium text-hb-text-default text-2xl text-center">
        Welcome to Healthbridge Nova
      </label>
      <label class="mt-1 text-sm text-hb-text-subdued text-center">Enter your credentials to access your practice</label>

      <mat-form-field class="flux-form-field mt-10" appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="userName" name="username">
      </mat-form-field>


      <mat-form-field class="flux-form-field mt-10" appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput placeholder="Password" formControlName="password" type="password" #passwordField>
        <button mat-icon-button type="button" (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'" matSuffix>
          <mat-icon class="icon-size-5">{{passwordField.type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <a class="text-sm text-hb-secondary-100 underline mt-1 cursor-pointer tracking-[0.5px]" routerLink="/forgot-password">Forgot your password?</a>

      <button mat-button class="login-button"
      [disabled]="authenticating" (click)="onSubmit()">
        <div class="flex flex-row flex-nowrap gap-2 h-full w-full items-center justify-center">
          <mat-spinner *ngIf="authenticating" class="ml-[-8px] login-spinner" diameter="24"></mat-spinner>
          Sign in <mat-icon class="!text-hb-base-white scale-90">arrow_forward</mat-icon></div>
        </button>
      <label class="text-sm text-hb-text-subdued mt-4">
        {{ communication }}
      </label>

      <label class="text-sm text-hb-text-default mt-4 tracking-[0.15px]">By logging in you accept the <a class="text-hb-secondary-100 underline cursor-pointer" 
        target="_blank" href="https://images.healthbridge.co.za/Healthbridge-docs/Terms-of-service.pdf">terms and conditions</a></label>

    </div>

  </div>

  <!-- Brand panel -->
  <div class="brand-panel" 
    [style.background-position]="'center'" 
    [style.background-size]="'70%'"
    [style.background-image]="'url(assets/images/hb_login_bg_logo.png)'">
  </div>
</div>
