import { Injectable } from "@angular/core";
import { PricingService } from "./pricing.service";
import { AuthService } from "./auth.service";
import { Firestore, doc, getDoc } from "@angular/fire/firestore";
import {
  BenefitCheckTemplate,
  BenefitCheckLine,
  CalendarProvider,
  AccountInfo,
  INVOICE_LINE_TYPE, NexBaseResponse
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class BenefitCheckService {

  constructor(
    private pricingService: PricingService,
    private authService: AuthService,
    private firestore: Firestore,
  ) {}

  async getBenefitCheckTemplate(provider: CalendarProvider) {
    const provBCTemplate = await this.getProviderBCTemplate(this.authService.selectedBPN, provider.Id);
    let bcTemplate: BenefitCheckTemplate;
    if(!!provBCTemplate.Lines && provBCTemplate.Lines?.length > 0) {
      bcTemplate = provBCTemplate;
    } else {
      const docId = provider.DisciplineCode || 'default';
      let docRes = await getDoc(doc(this.firestore, `BenefitCheckTemplates/${docId}`));
      if (!docRes.exists()) {
        docRes = await getDoc(doc(this.firestore, `BenefitCheckTemplates/default`));
      }
      bcTemplate = docRes.data() as BenefitCheckTemplate;
    }
    return bcTemplate;
  }

  async priceBenefitCheckLine(accountInfo: AccountInfo, provider: CalendarProvider, benefitCheckLine: BenefitCheckLine) {
    if (provider) {
      const disciplineCode = `${provider.DisciplineCode}`;
      const optionCode = accountInfo.Option || '90H';

      let priceResult;
      switch(benefitCheckLine.LineType) {
        case INVOICE_LINE_TYPE.MEDICINE:
          priceResult = await this.pricingService.getMedicinePrice(benefitCheckLine.NappiCode, optionCode, benefitCheckLine.Quantity, new Date().getFullYear());
          break;
        case INVOICE_LINE_TYPE.CONSUMABLE:
          priceResult = await this.pricingService.getConsumablePrice(benefitCheckLine.NappiCode, optionCode, benefitCheckLine.Quantity, new Date().getFullYear());
          break;
        case INVOICE_LINE_TYPE.PROCEDURE:
        default: {
            priceResult = await this.pricingService.getTariffPrice({
            disciplineCode: disciplineCode?.length < 3 ? disciplineCode.padStart(3, '0') : disciplineCode,
            placeOfService: 'OH',
            planOptionCode: optionCode,
            practiceId: this.authService.selectedBPN,
            providerId: provider.Id,
            tariffCode: benefitCheckLine.ChargeCode,
            year: `${new Date().getFullYear()}`
          })
        }
      }
      return (priceResult as NexBaseResponse<any>).data?.payload?.result?.price || (priceResult as NexBaseResponse<any>).data?.payload?.price || 0;
    }
    return 0;
  }

  async getProviderBCTemplate(practiceId: string, providerId: string) {
    const provider = await getDoc(doc(this.firestore, `Practice/${practiceId}/Provider/${providerId}`));
    return {
      Lines: provider.data()?.Settings?.BenefitCheckTemplate,
    } as BenefitCheckTemplate
  }
}
