import * as moment from "moment";
import {LIABLE_TYPE, Practice, Provider, REPORT_FORMAT_TYPE, Sheet} from "@meraki-flux/schema";

export class ReportUtils {

  private static readonly DATE_FORMAT = "DD-MM-YYYY-HH-mm-ss";
  private static readonly SHORT_DATE_FORMAT = "DD-MM-YYYY";

  static async buildReportFileName(
    reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {

    const practicePart: string = provider ? `${provider.Title} ${provider.Name} ${provider.Surname}` : practice.PracticeName;
    const datePart = moment(new Date()).format('DD_MM_YYYY');
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildCreditNoteReportFileName(
    reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart: string = provider ? `${provider.Title} ${provider.Name} ${provider.Surname}` : practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildRefundReportFileName(
    reportPrefixName: string, practice: Practice,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildReportFileNameDates(
    reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {

    const practicePart: string = provider ? `${provider.Title} ${provider.Name} ${provider.Surname}` : practice.PracticeName;
    const datePartFrom = moment(fromDate).format('DD MM YYYY');
    const datePartTo = moment(toDate).format('DD MM YYYY');
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    if (practice.IsMultiBranch === true) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportPrefixName}_${practicePart}_${branchPart}_${datePartFrom} to ${datePartTo}.${fileExt}`
    }

    return `${reportPrefixName}_${practicePart}_${datePartFrom} to ${datePartTo}.${fileExt}`
  }


  static async buildAssistantsReportFileName(
    practiceName: string, isMultiBranch: boolean, branch: string, reportFormat: REPORT_FORMAT_TYPE, dateFrom: Date, dateTo: Date): Promise<string> {

    const reportName = 'Assistants'
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    const datePart = `${moment(dateFrom).format('DD MM YYYY')}_to_${moment(dateTo).format('DD MM YYYY')}`;

    if (isMultiBranch) {
      const branchPart = branch ? branch : 'All_branches';
      return `${reportName}_${practiceName}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportName}_${practiceName}_${datePart}.${fileExt}`
  }

  static async buildAppointmentReportFileName(
    practiceName: string, isMultiBranch: boolean, branch: string, reportFormat: REPORT_FORMAT_TYPE, dateFrom: Date, dateTo: Date): Promise<string> {

    const reportName = 'Appointments'
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    //CA Fixed report file layout according to FRS - Appintments_<practice name>_<branch>_<from> to <to>
    const datePart = `${moment(dateFrom).format('DD MM YYYY')} to ${moment(dateTo).format('DD MM YYYY')}`;

    if (isMultiBranch) {
      const branchPart = branch ? branch : 'All branches';
      return `${reportName}_${practiceName}_${branchPart}_${datePart}.${fileExt}`
    }

    return `${reportName}_${practiceName}_${datePart}.${fileExt}`
  }

  static async buildPaymentSummaryReportFileName(practiceName: string, reportFormat: REPORT_FORMAT_TYPE, dateFrom: Date, dateTo: Date): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    //CA Fixed report file layout according to FRS - PaymentSummary_<practice name>_<from> to <to>
    const datePart = `${moment(dateFrom).format('DD MM YYYY')} to ${moment(dateTo).format('DD MM YYYY')}`;
    return `PaymentSummary_${practiceName}_${datePart}.${fileExt}`
  }

  static async buildMonthlyTransactionReportFileName(practiceName: string, month: string, year: string): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.PDF);
    // CA added practice name according to FRS - MonthlyTransactions_<practice name>_<month>_<year>
    return `MonthlyTransactions_${practiceName}_${month}_${year}.${fileExt}`
  }

  static async buildStatementReportFileName(practiceName: string, accountNo: string,
    patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    // CA added () according to FRS - Statement_<practice name>_<patient name surname> (<account no.>)_<date generated>
    return `Statement_${practiceName}_${patientName} ${patientSurname} (${accountNo})_${moment(new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`
  }

  static async buildInvoiceReportFileName(practiceName: string, accountNo: string,
    patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE, reportDate?: Date): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    // CA added () according to FRS - Invoice_<practice name>_<patient name surname> (<account no.>)_<invoice date>
    return `Invoice_${practiceName}_${patientName} ${patientSurname} (${accountNo})_${moment(reportDate ? reportDate : new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`
  }

  static async buildProofOfPaymentReportFileName(practiceName: string, accountNo: string,
    patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    // FRS - Proof of Payment_<practice name>_<patient name surname> (<account no.>)_<invoice date>
    return `ProofOfPayment_${practiceName}_${patientName} ${patientSurname} (${accountNo})_${moment(new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`
  }

  static async buildAgeAnalysisReportFileName(practice: Practice, liable: LIABLE_TYPE, reportFormat: REPORT_FORMAT_TYPE) {
    const reportPrefixName = liable === LIABLE_TYPE.ACCOUNT_HOLDER ? 'AgeAnalysis_PatientLiable' : 'AgeAnalysis_MedAidLiable';
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `${reportPrefixName}_${practice.PracticeName}_${moment(new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`
  }

  static async buildClaimDetailsReportFileName(
    practiceName: string, patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `ClaimDetails_${practiceName}_${patientName} ${patientSurname}.${fileExt}`
  }

  static buildBenefitCheckReportFileName(
    practiceName: string, patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): string {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `Benefit Check_${practiceName}_${patientName} ${patientSurname}.${fileExt}`
  }

  static async buildProofOfSubmissionReportFileName(memberNo: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `ProofOfClaimSubmission_${memberNo}.${fileExt}`
  }

  static async buildAccountDetailsReportFileName(patientName: string, patientSurname: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `AccountDetails_${patientName} ${patientSurname}.${fileExt}`
  }

  static async buildIncompleteContactDetailsReportFileName(practiceName: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `IncompleteContactDetails_${practiceName}_${moment(new Date()).format(this.SHORT_DATE_FORMAT)}.${fileExt}`
  }

  static async buildCustomChargeCodesReportFileName(practiceName: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    const datePart = moment(new Date()).format('DD MM YYYY');
    return `CustomChargeCodes_${practiceName}_${datePart}.${fileExt}`
  }

  static async buildCollectableAmountsReportFileName(practiceName: string, branch: string = "All branches", reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    return `CollectableAmounts_${practiceName}_${branch}_${moment(new Date()).format('MM YYYY')}.${fileExt}`
  }

  static async buildWriteOffDetailsReportFileName( reportPrefixName: string, practice: Practice, provider: Provider,
    branch: string, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    // if (practice.IsMultiBranch === true) {
    //   const branchPart = branch ? branch : 'All_branches';
    //   return `${reportPrefixName}_${practicePart}_${branchPart}_${datePart}.${fileExt}`
    // }
    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildPaymentCorrectionReportFileName(
    reportPrefixName: string, practice: Practice, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildSmsReportFileName(
    reportPrefixName: string, practice: Practice, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.EXCEL);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildJournalReportFileName(
    reportPrefixName: string, practice: Practice, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildPatientListReportFileName(reportPrefixName: string, practice: Practice): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = moment(new Date()).format('DD MM YYYY');
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.EXCEL);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildEmailMessagingReportFileName(
    reportPrefixName: string, practice: Practice, reportFormat: REPORT_FORMAT_TYPE, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildPaymentDetailsReportFileName(
    reportPrefixName: string, practice: Practice, fromDate: Date, toDate: Date): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.EXCEL);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildPaymentPerProviderReportFileName(
    reportPrefixName: string, practice: Practice, fromDate: Date, toDate: Date, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = `${moment(fromDate).format('DD MM YYYY')} to ${moment(toDate).format('DD MM YYYY')}`;
    const fileExt: string = this.lookupReportFileExtention(reportFormat);

    return `${reportPrefixName}_${practicePart}_${datePart}.${fileExt}`
  }

  static async buildRaStatementReportFileName(reportPrefixName: string, practice: Practice, schemeName: string, eftNo: string): Promise<string> {
    const practicePart: string = practice.PracticeName;
    const datePart = moment(new Date()).format('DD MM YYYY');
    const fileExt: string = this.lookupReportFileExtention(REPORT_FORMAT_TYPE.PDF);
    
    return `${reportPrefixName}_${practicePart}_${schemeName}_${datePart}_${eftNo}.${fileExt}`
  }

  static async buildMedInsuerReportFileName(insurerName: string, reportFormat: REPORT_FORMAT_TYPE): Promise<string> {
    const fileExt: string = this.lookupReportFileExtention(reportFormat);
    // CA added () according to FRS - Statement_<insurer name>_<date generated>
    return `Statement_${insurerName}_${moment(new Date()).format('DD MM YYYY')}.${fileExt}`
  }

  static lookupMimeType(reportType: REPORT_FORMAT_TYPE): string {
    if (reportType == REPORT_FORMAT_TYPE.EXCEL) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (reportType == REPORT_FORMAT_TYPE.PDF) {
      return 'application/pdf'
    }
    throw new Error('Unsupported report format type');
  }

  static lookupReportFileExtention(reportType: REPORT_FORMAT_TYPE): string {
    if (reportType == REPORT_FORMAT_TYPE.EXCEL) {
      return 'xlsx';
    } else if (reportType == REPORT_FORMAT_TYPE.PDF) {
      return 'pdf'
    }
    throw new Error('Unsupported report format type');
  }

  static buildReportEmailSubject(reportName: string): string {
    return 'Healthbridge Nova report - ' + reportName;
  }

  static getMonthName(date: Date) {
    return moment(date).format('MMMM');
  }

  static getFullYear(date: Date) {
    return String(date.getFullYear());
  }

  static getSheetName(newSheetName: string, otherSheets: Sheet[], index: number = 0) {
    const sheetName = index === 0 ? newSheetName : `${newSheetName} (${index})`;
    return otherSheets.map((s) => s.name.toLocaleLowerCase()).includes(sheetName.toLocaleLowerCase())
      ? this.getSheetName(newSheetName, otherSheets, index + 1)
      : sheetName;
  }
}
