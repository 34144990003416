import {Injectable} from "@angular/core";
import {EmailService} from "../../services/email.service";
import {ReportUtils} from "../report-utils";
import {AppointmentReportBuilder} from "./appointment-report-builder";
import {
  AppointmentReportModel,
  AppointmentReportRequest,
  BaseExcelReportData,
  BasePdfReportData,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {
  AppointmentPdfReportDataBuilder,
  AppointmentXlsReportDataBuilder,
  BaseExcelGenerator,
  BasePdfGenerator,
  IReportGenerator
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentReportService {

  constructor(private emailService: EmailService,
              private appointmentReportBuilder: AppointmentReportBuilder) {
  }

  async generateReport(reportRequest: AppointmentReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildAppointmentReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.Multibranch, reportRequest.BranchName, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: IReportGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: reportRequest.IncludeInvoiceData ? REPORT_NAME.APPOINTMENT_WITH_INVOICE : REPORT_NAME.APPOINTMENT,
      format: reportRequest.ReportFormatType === REPORT_FORMAT_TYPE.PDF ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    }
  }

  private async getReportGenerator(reportRequest: AppointmentReportRequest): Promise<IReportGenerator> {
    const reportModel: AppointmentReportModel = await this.appointmentReportBuilder.build(reportRequest);

    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      const pdfData: BasePdfReportData = new AppointmentPdfReportDataBuilder().build(reportModel);
      return new BasePdfGenerator(pdfData);
    } else if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      const xlsData: BaseExcelReportData = new AppointmentXlsReportDataBuilder().build(reportModel);
      return new BaseExcelGenerator(xlsData);
    } else {
      throw new Error("Report format is not supported.");
    }
  }
}
