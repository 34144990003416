import * as moment from "moment";
import {Practice} from "../practice.models";
import { ReportRequestData } from './common.model';

export interface AgeAnalysisReportRequest extends ReportRequestData {
  ReportName?: string;
  Practice?: Practice;
  DateRangeType?: AGE_ANALYSIS_DATE_RANGE_TYPE;
  DateFrom?: Date;
  DateTo?: Date;
  LiableType?: LIABLE_TYPE,
  IncludeLastNotes?: boolean,
  UserId: string;
  Multibranch?: boolean;
  Recipient?: string;
}

export enum AGE_ANALYSIS_DATE_RANGE_TYPE {
  ALL = 'All open invoices',
  DATE_OF_SUBMISSION = 'Invoice date of submission',
  DATE_OF_SERVICE = 'Invoice date of service'
}

export enum LIABLE_TYPE {
  ACCOUNT_HOLDER = 'Account holder',
  MEDICAL_AID = 'Medical aid'
}

export interface AgeAnalysisPatientLiableReportModel {
  reportInfo: AgeAnalysisReportInfo
  summary: PatientLiableSummary
  accountDetails: PatientLiableAccountDetails[]
  invoiceDetails: PatientLiableInvoiceDetails[]
}

export interface PatientLiableAccountDetails {
  accountNo?: string,
  accountHolderName?: string,
  cellphoneNo?: string,
  scheme?: string,
  memberNo?: string,
  age?: AggregatedInvoiceAge,
  unallocatedAmount?: number,
  accountStatus?: string,
  reason?: string,
  accountNotes?: string,
  branch?: string
  total?: number
}

export interface PatientLiableInvoiceDetails {
  age: AggregatedInvoiceAge,
  branch: string
  treatingProviderId: string,
  treatingProvider: string,
  scheme: string,
  memberNo: string,
  dateOfService: Date,
  invoiceDate: Date,
  invoiceType: string,
  invoiceNo: string,
  accountNo: string,
  patientName: string,
  claimStatus: string,
  hbMessageId: string,
  invoicedAmount: number,
  medicalAidLiable: number,
  patientLiable: number,
  bc: string,
  bcStatus: string,
  eRAEnabled: string,
  location: string,
}

export interface AgeAnalysisMedicalAidLiableReportModel {
  reportInfo: AgeAnalysisReportInfo
  summary: MedicalAidLiableSummary
  invoiceDetails: MedicalAidLiableInvoiceDetails[]
}

export interface MedicalAidLiableInvoiceDetails {
  age: AggregatedInvoiceAge,
  branch: string
  treatingProviderId: string,
  treatingProvider: string,
  scheme: string,
  planOption: string,
  memberNo: string,
  depCode: string,
  dateOfService: Date,
  invoiceDate: Date,
  invoiceType: string,
  invoiceNo: string,
  accountNo: string,
  patientName: string,
  dob: Date,
  claimStatus: string,
  hbMessageId: string,
  invoicedAmount: number,
  medicalAidLiable: number,
  patientLiable: number,
  bc: string,
  bcStatus: string,
  eRAEnabled: string,
  location: string,
}

export interface AgeAnalysisReportInfo {
  PracticeId?: string;
  Practice?: string;
  DateRange?: string
  DateRangeType?: string
  IsMultiBranch?: boolean;
  IncludeAccountNotes?: boolean;
}

export interface PatientLiableSummary {
  providerData: SummaryData;
  invoiceTypeData: SummaryData;
}

export interface MedicalAidLiableSummary {
  medicalAidProviderData: SummaryData;
  insuranceProviderData: SummaryData;
  schemeData: SummaryData;
  insurerData: SummaryData;
  eraSchemeNames: string[];
}

export class AggregatedInvoiceAge {

  totalAge = 0;
  age30 = 0;
  age30_60 = 0;
  age60_90 = 0;
  age90_120 = 0;
  age120 = 0;

  setValue(date: Date, amount: number) {
    const invoiceAge: number = moment(new Date()).diff(date, 'day');
    if (invoiceAge <= 30) {
      this.age30 = amount;
    } else if (invoiceAge > 30 && invoiceAge <= 60) {
      this.age30_60 = amount;
    } else if (invoiceAge > 60 && invoiceAge <= 90) {
      this.age60_90 = amount;
    } else if (invoiceAge > 90 && invoiceAge <= 120) {
      this.age90_120 = amount;
    } else if (invoiceAge > 120) {
      this.age120 = amount;
    }
    this.totalAge = amount;
    return this;
  }

  copyFrom(ageValue: AggregatedInvoiceAge): AggregatedInvoiceAge {
    this.totalAge = ageValue.totalAge;
    this.age30 = ageValue.age30;
    this.age30_60 = ageValue.age30_60;
    this.age60_90 = ageValue.age60_90;
    this.age90_120 = ageValue.age90_120;
    this.age120 = ageValue.age120;
    return this;
  }

  public sum(ageValue: AggregatedInvoiceAge) {
    this.totalAge += ageValue.totalAge;
    this.age30 += ageValue.age30;
    this.age30_60 += ageValue.age30_60;
    this.age60_90 += ageValue.age60_90;
    this.age90_120 += ageValue.age90_120;
    this.age120 += ageValue.age120;
  }

  getFriendlyName() {
    if (this.age30 > 0) {
      return '0 - 30';
    }
    if (this.age30_60 > 0) {
      return '30 - 60';
    }
    if (this.age60_90 > 0) {
      return '60 - 90';
    }
    if (this.age90_120 > 0) {
      return '90 - 120';
    }
    if (this.age120 > 0) {
      return '120+';
    }
    return '';
  }
}

export class SummaryData {
  map: Map<string, AggregatedInvoiceAge> = new Map;
  total: AggregatedInvoiceAge = new AggregatedInvoiceAge();

  addData(key: string, value: AggregatedInvoiceAge) {
    if (!key || !value) return;
    let mapItem: AggregatedInvoiceAge = this.map.get(key);
    if (!mapItem) {
      mapItem = new AggregatedInvoiceAge();
      this.map.set(key, mapItem);
    }
    mapItem.sum(value);
    this.total.sum(value);
  }
}
