import {UntypedFormControl} from '@angular/forms';
import {LIABILITY_TYPE} from './account.models.ui';
import {InvoiceLine} from './invoice.models';
import {Invoice} from './invoice.models.ui';

export interface PayableInvoice extends Invoice {
  _AmountAllocated: number;
  _BalanceOutstanding: number;
  Lines: PayableInvoiceLine[];
}

export interface PayableInvoiceLine extends InvoiceLine {
  _AmountAllocatedCtrl: UntypedFormControl;
  _BalanceOutstanding: number;
}

export interface MaPayableInvoice extends PayableInvoice {
  _ZeroPaid: boolean;
  Lines: MaPayableInvoiceLine[];
}

export interface MaPayableInvoiceLine extends PayableInvoiceLine {
  _AmountAllocatedCtrl: UntypedFormControl;
  _ActionCtrl: UntypedFormControl;
}

export interface DebitPayableInvoice extends PayableInvoice {
  _Reason: string;
  _LiabilityType: LIABILITY_TYPE;
}

export enum MA_PAYMENT_TYPE {
  MEDICAL_AID = 'Medical Aid',
  MEDICAL_INSURER = 'Medical Insurer',
}
