import moment from "moment";
import {
  BaseExcelReportData, BulkSmsAddOnTransactionItem, BulkSmsSummaryItem, CellType,
  CellWidth,
  ExcelCellData,
  REPORT_NAME,
  Sheet, SMS_CATEGORY, SMS_TYPE, SmsDetailsItem, SmsProviderModel,
  SmsReportModel, SmsStatusSummaryItem, SmsSummaryItem,
  Table
} from "@meraki-flux/schema";

export class SmsXlsReportDataBuilder {

    build(smsReportModel: SmsReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = smsReportModel.SmsReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.SMS;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, smsReportModel);
        excelReportData.data.push(this.buildReportSheetSummary(smsReportModel));
        excelReportData.data.push(this.buildReportSheetDetail(smsReportModel.SmsProviderModel));
        if(smsReportModel.SmsReportInfo.AddonActivated && smsReportModel.SmsProviderModel.BulkSmsAddOnTransactionItem.length > 0)
            excelReportData.data.push(this.buildBulkSmsAddonReportSheetDetail(smsReportModel.SmsProviderModel));

        return excelReportData;
    }

    private buildReportSheetSummary(smsReportModel: SmsReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.header = 'Sms Report';
        sheet.tables = [];

        //Free SMS
        const freeSmsTable: Table = {};

        freeSmsTable.headers = [
            {value: 'Free SMSs', bold: true, width: CellWidth.S, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const freeSmsRows = [];
        const freeSmsRow: ExcelCellData[] = [];
        freeSmsRow.push({value: 'SMS Type', bold: true});
        freeSmsRow.push({value: 'No. of sent', bold: true, type: CellType.NUMBER});
        freeSmsRows.push(freeSmsRow);

        const freeSmsSummary = smsReportModel.SmsProviderModel.SmsSummaryItem.filter(sms=> sms.Category === SMS_CATEGORY.FREE_SMS);
        freeSmsSummary.sort((a,b) => a.SmsType.localeCompare(b.SmsType));

        let totalFreeSms = 0;
        freeSmsSummary.forEach((item: SmsSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.SmsType === SMS_TYPE.BULK_EMAIL_SMS? "Bulk SMS": item.SmsType });
            row.push({value: item.NoOfSent, type: CellType.NUMBER});
            totalFreeSms= totalFreeSms + item.NoOfSent;

            freeSmsRows.push(row);
        });

        const totalRow: ExcelCellData[] = [];
        totalRow.push({value: 'Total', bold: true});
        totalRow.push({value: totalFreeSms, bold: true});
        freeSmsRows.push(totalRow);

        freeSmsTable.rows = freeSmsRows;
        sheet.tables.push(freeSmsTable);
        //Free SMS END

        //BUlk SMS Add on balance
        const bulkSmsSummary = smsReportModel.SmsProviderModel.SmsSummaryItem.filter(sms=> sms.Category === SMS_CATEGORY.BULK_SMS_ADDON);
        bulkSmsSummary.sort((a,b) => a.SmsType.localeCompare(b.SmsType));
        const totalBulkSmsSent = bulkSmsSummary.reduce((total,item)=>{
            return total + (item.NoOfSent || 0);
        }, 0);

        if(smsReportModel.SmsReportInfo.AddonActivated && totalBulkSmsSent > 0){
            const bulkSmsAddonBalanceTable: Table = {};
            bulkSmsAddonBalanceTable.headers = [
                {value: 'Bulk SMS Add-on', bold: true, width: CellWidth.S, wordWrap: true},
                {value: '', bold: true, width: CellWidth.XS, wordWrap: true}
            ];

            const bulkSmsAddonBalanceRows = [];
            const bulkSmsAddonBalanceRow: ExcelCellData[] = [];
            bulkSmsAddonBalanceRow.push({value: 'Current SMS balance'});
            bulkSmsAddonBalanceRow.push({value: smsReportModel.SmsReportInfo?.SMSBalance});
            bulkSmsAddonBalanceRows.push(bulkSmsAddonBalanceRow);

            bulkSmsAddonBalanceTable.rows = bulkSmsAddonBalanceRows;
            sheet.tables.push(bulkSmsAddonBalanceTable);
            //BUlk SMS Add on balance END

            //Bulk SMS Addon
            const bulkSmsTable: Table = {};
            const bulkSmsRows = [];
            const bulkSmsRow: ExcelCellData[] = [];
            bulkSmsRow.push({value: 'SMS Type', bold: true});
            bulkSmsRow.push({value: 'No. of sent', bold: true, type: CellType.NUMBER});
            bulkSmsRows.push(bulkSmsRow);


            let totalBulkSms = 0;
            bulkSmsSummary.forEach((item: SmsSummaryItem) => {
                const row: ExcelCellData[] = [];
                row.push({value: item.SmsType === SMS_TYPE.BULK_EMAIL_SMS? "Bulk SMS": item.SmsType });
                row.push({value: item.NoOfSent, type: CellType.NUMBER});
                totalBulkSms= totalBulkSms + item.NoOfSent;

                bulkSmsRows.push(row);
            });
            bulkSmsTable.rows = bulkSmsRows;
            const totalBulkRow: ExcelCellData[] = [];
            totalBulkRow.push({value: 'Total', bold: true});
            totalBulkRow.push({value: totalBulkSms, bold: true});
            bulkSmsRows.push(totalBulkRow);

            sheet.tables.push(bulkSmsTable);
        }
        //Bulk SMS Addon


        //Bulk SMS Summary
        const bulkSmsSummaryTable: Table = {};
        bulkSmsSummaryTable.headers = [
            {value: 'Bulk SMS Summary', bold: true, width: CellWidth.S, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true}
        ];
        if (smsReportModel.SmsReportInfo.IsMultiBranch) {
            bulkSmsSummaryTable.headers.push({value: '', bold: true, width: CellWidth.XS, wordWrap: true});
        }
        bulkSmsSummaryTable.headers.push({value: '', bold: true, width: CellWidth.XS, wordWrap: true});

        const bulkSmsSummaryRows = [];
        const bulkSmsSummaryRow: ExcelCellData[] = [];
        bulkSmsSummaryRow.push({value: 'Date', bold: true});
        bulkSmsSummaryRow.push({value: 'No. Sent', bold: true, type: CellType.NUMBER});
        bulkSmsSummaryRow.push({value: 'Sent by', bold: true});
        if (smsReportModel.SmsReportInfo.IsMultiBranch) {
            bulkSmsSummaryRow.push({value: 'Branch', bold: true});
        }
        bulkSmsSummaryRow.push({value: 'Message', bold: true});
        bulkSmsSummaryRows.push(bulkSmsSummaryRow);

        smsReportModel.SmsProviderModel.BulkSmsSummaryItem.forEach((item: BulkSmsSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Date, type: CellType.DATE});
            row.push({value: item.NoOfSent, type: CellType.NUMBER});
            row.push({value: item.SentBy});
            if (smsReportModel.SmsReportInfo.IsMultiBranch) {
                row.push({value: item.Branch});
            }
            row.push({value: item.Message, width: CellWidth.M, wordWrap: true});

            bulkSmsSummaryRows.push(row);
        });

        bulkSmsSummaryTable.rows = bulkSmsSummaryRows;
        sheet.tables.push(bulkSmsSummaryTable);
        //Bulk SMS Summary

        //SMS Status Summary
        const smsStatusSummaryTable: Table = {};
        smsStatusSummaryTable.headers = [
            {value: 'SMS Status Summary', bold: true, width: CellWidth.S, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true}

        ];
        if(smsReportModel.SmsReportInfo.AddonActivated && totalBulkSmsSent > 0){
            smsStatusSummaryTable.headers.push({value: '', bold: true, width: CellWidth.XS, wordWrap: true} );
        }

        const smsStatusSummaryRows = [];
        const smsStatusSummaryRow: ExcelCellData[] = [];
        smsStatusSummaryRow.push({value: '', bold: true});
        smsStatusSummaryRow.push({value: SMS_CATEGORY.FREE_SMS, bold: true, type: CellType.NUMBER});
        if(smsReportModel.SmsReportInfo.AddonActivated && totalBulkSmsSent > 0){
            smsStatusSummaryRow.push({value: SMS_CATEGORY.BULK_SMS_ADDON, bold: true, type: CellType.NUMBER});
        }
        smsStatusSummaryRows.push(smsStatusSummaryRow);

        smsReportModel.SmsProviderModel.SmsStatusSummaryItem.forEach((item: SmsStatusSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Status});
            row.push({value: item.FreeSms, type: CellType.NUMBER});
            if(smsReportModel.SmsReportInfo.AddonActivated && totalBulkSmsSent > 0){
                row.push({value: item.BulkSmsAddOn, type: CellType.NUMBER});
            }

            smsStatusSummaryRows.push(row);
        });

        smsStatusSummaryTable.rows = smsStatusSummaryRows;
        sheet.tables.push(smsStatusSummaryTable);
        //SMS Status Summary


        return sheet;
    }

    private buildReportSheetDetail(providerModel: SmsProviderModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Detail';
        sheet.header = 'Sms Report';
        sheet.tables = [];

        const detailsTable: Table = {};
        //detailsTable.name = '';

        detailsTable.headers = [
            {value: 'Date sent', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Category', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'SMS type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Recipient name', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Recipient cellphone', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Recipient account no.', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Sent by', bold: true, width: CellWidth.XS},
            {value: 'Status', bold: true, width: CellWidth.XS},
            {value: 'Status description', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'No. of message parts', bold: true, width: CellWidth.S, wordWrap: true, type: CellType.NUMBER}
        ];

        const detailsRows = [];
        providerModel.SmsDetailsItem.forEach((item: SmsDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: moment(item.DateSent).format("DD/MM/YYYY HH:mm")});
            row.push({value: item.Category});
            row.push({value: item.SMSType === SMS_TYPE.BULK_EMAIL_SMS? "Bulk SMS": item.SMSType });
            row.push({value: item.RecipientName});
            row.push({value: item.RecipientCellphone, type: CellType.GENERAL});
            row.push({value: item.RecipientAccountNo, type: CellType.GENERAL});
            row.push({value: item.SentBy});
            row.push({value: item.Status});
            row.push({value: item.StatusDescription});
            row.push({value: item.NoOfMessageParts});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildBulkSmsAddonReportSheetDetail(providerModel: SmsProviderModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Bulk SMS Add-On Transactions';
        sheet.header = 'Sms Report';
        sheet.tables = [];

        const detailsTable: Table = {};

        detailsTable.headers = [
            {value: 'Transaction type', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'User name', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Date/time', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Number of SMSs', bold: true, width: CellWidth.XS, wordWrap: true, type: CellType.NUMBER},
            {value: 'Price', bold: true, width: CellWidth.XS, wordWrap: true, type: CellType.NUMBER}
        ];

        const detailsRows = [];
        providerModel.BulkSmsAddOnTransactionItem.forEach((item: BulkSmsAddOnTransactionItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.TransactionType});
            row.push({value: item.UserName});
            row.push({value:moment(item.DateTime).format("DD/MM/YYYY HH:mm")});
            row.push({value: item.NoOfSms, type: CellType.NUMBER});
            row.push({value: item.Price, type: CellType.CURRENCY});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, smsReportModel: SmsReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: smsReportModel.SmsReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: smsReportModel.SmsReportInfo.PracticeId, type: CellType.GENERAL}
        });

        if (smsReportModel.SmsReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: smsReportModel.SmsReportInfo.Branch, type: CellType.GENERAL}
                });
        }
        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: smsReportModel.SmsReportInfo.DateRange, type: CellType.GENERAL}
            });

    }



}
