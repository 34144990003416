import { Branch, Practice } from '../practice.models';
import { REPORT_FORMAT_TYPE } from '../report.models';
import { ReportRequestData } from './common.model';

export interface PaymentPerProviderReportModel {
  PaymentPerProviderSummary: PaymentPerProviderSummary[];
  PaymentPerProviderItem: PaymentPerProviderItem[];
  PaymentPerProviderReportInfo: PaymentPerProviderReportInfo;
  ReportDate?: string;
}


export interface PaymentPerProviderReportInfo {
  PracticeId?: string;
  Practice?: string;
  BillingPracticeNumber?: string;
  Branch?: string;
  DateRange?: string;
  Provider?: string;
}

export interface PaymentPerProviderSummary {
  Provider?: string;
  PatientPayments?: number;
  MedAidPayments?: number;
  InsurerPayments?: number;
}

export interface PaymentPerProviderItem {
  PaymentDate?: string;
  PaymentType?: string;
  PaymentAmountType?: string;
  ReconDate?: string;
  PaymentRef?: string;
  ReceiptNo?: string;
  PaymentAmount?: number;
  PaidBy?: string;
  AccountNo?: string;
  Patient?: string;
  InvoiceNo?: string;
  InvoiceAmount?: number;
  Provider?: string;
  Branch?: string;

}

export class PaymentPerProviderReportRequest implements ReportRequestData {
  Practice: Practice;
  Branch?: Branch;
  Multibranch?: boolean;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  TreatingProvider?: string | '';
  RecipientEmail: string;
  UserId: string;
  Payments: PaymentPerProviderItem[];
  Recipient?: string
  HPCSANumber?: string;
  HPCSANumbers?: string[];
}




