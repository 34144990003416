import moment = require("moment");
import {
  EMAIL_STATUS,
  EMAIL_TYPE,
  EmailMessagingDataList,
  EmailMessagingModel,
  EmailMessagingReportInfo, EmailMessagingReportList,
  EmailMessagingReportModel,
  EmailMessagingReportRequest, EmailMessagingStatusData, EmailMessagingTypeData, NoDataError
} from '@meraki-flux/schema';
import { DateUtils } from "@meraki-flux/purejs";

export class EmailMessagingReportBuilder {

  private readonly DATE_FORMAT = "DD/MM/YYYY HH:mm";

  async build(reportRequest: EmailMessagingReportRequest): Promise<EmailMessagingReportModel> {
    const reportHeader: EmailMessagingReportInfo = await this.buildReportInfo(reportRequest);
    const emailMessagingModel: EmailMessagingModel = await this.buildEmailMessagingModel(reportRequest);

    this.checkDataAvailability(emailMessagingModel);

    return {
      EmailMessagingReportInfo: reportHeader,
      EmailMessagingModel: emailMessagingModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

private checkDataAvailability(emailMessagingModel: EmailMessagingModel) {
  let noData = true;
  if (emailMessagingModel.EmailMessagingDSData.length > 0) {
      noData = false;
  }

  if (noData)
      throw new NoDataError();
}

private async buildEmailMessagingModel(reportRequest: EmailMessagingReportRequest): Promise<EmailMessagingModel> {

  const detailsItem: EmailMessagingDataList[] = this.buildEmailDetails(reportRequest.EmailMessagingDataList);
  const emailTypeSummaryItem: EmailMessagingTypeData[] = this.buildEmailTypeSummaryItem(detailsItem);
  const emailStatusSummaryItem: EmailMessagingStatusData[] = this.buildEmailStatusSummaryItem(detailsItem);

  return {
    EmailMessagingDSData: detailsItem,
    EmailTypeSummaryItem: emailTypeSummaryItem,
    EmailStatusSummaryItem: emailStatusSummaryItem,
  } ;
}

private buildEmailDetails(emailList: EmailMessagingReportList[]): EmailMessagingDataList[] {

  const detailtems: EmailMessagingDataList[] = [];
  let latestStatusTime: Date | string = new Date(0).getSeconds().toString();
  for(const em of emailList){
    for(const recip of em.To){

      const recipientEmail: string = recip.EmailAddress.toLowerCase();
      let emType;

      if(em.Type === 'Bulk'){
        emType = EMAIL_TYPE.BULK;
      }else if(em.Type === 'Bulk statements'){
        emType = EMAIL_TYPE.BULK_STATEMENTS;
      }else{
        emType =em.Type;
      }
      const detailsObj = {
        CreatedAt: em.CreatedAt ? moment(DateUtils.toDate(em.CreatedAt)).utcOffset(2).format('DD/MM/YYYY HH:mm') : null,
        Type: emType,
        MessageId: em.MessageId,
        AccountNumber: em.AccountNumber,
        Subject: em.Subject,
        CreatedBy: em.SentBy,
        RecipientEmail: recipientEmail,
        Branch: em.Branch,
        Status: null
      };

      const sendGridEventsArray = Object.values(em.SendGridEvent);
      for (const gridEvent of sendGridEventsArray) {
        const eventDate = gridEvent.Date as Date;

        if (gridEvent.Email.toLowerCase() === recipientEmail && eventDate> latestStatusTime) {
          // Check if the event is for the current recipient and if its status is newer
          detailsObj.Status = gridEvent?.Status;
          latestStatusTime = eventDate; // Update the latest status time
        }
      }

      detailtems.push(detailsObj);
      latestStatusTime = new Date(0).getSeconds().toString();
    }
  }

  return detailtems;
}

private buildEmailTypeSummaryItem(detailItems: EmailMessagingDataList[]):EmailMessagingTypeData[] {

  let emailSummaryItems: EmailMessagingTypeData[] = [];

  const summaryTable: EmailMessagingTypeData[] = detailItems.reduce((acc, curr) => {

    let emType;
    if(curr.Type === 'Bulk'){
      emType = EMAIL_TYPE.BULK;
    }else if(curr.Type === 'Bulk statements'){
      emType = EMAIL_TYPE.BULK_STATEMENTS;
    }else{
      emType =curr.Type;
    }

    const emailTypeIndex = acc.findIndex((item) => item.EmailType === emType);

    if (emailTypeIndex === -1) {
      acc.push({
        EmailType: emType,
        TotalEmailTypeCount: 1,
      });
    } else {
      acc[emailTypeIndex].TotalEmailTypeCount ++;//= (acc[emailTypeIndex].TotalEmailTypeCount || 0);
    }

    return acc;
  }, []);

  emailSummaryItems = emailSummaryItems.concat(summaryTable);

  // Add missing email types
  Object.values(EMAIL_TYPE).forEach((type) => {
    if (!emailSummaryItems.some((summary) => summary.EmailType === type)) {
      emailSummaryItems.push({ EmailType: type, TotalEmailTypeCount: 0 });
    }
  });

  return emailSummaryItems;
}

private buildEmailStatusSummaryItem(detailItems: EmailMessagingDataList[]):EmailMessagingStatusData[] {
  const summaryTable: EmailMessagingStatusData[] = Object.values(EMAIL_STATUS).map(status => ({
    EmailStatus: status,
    TotalEmailStatusCount: 0,
  }));

  for (const detailItem of detailItems) {
    const emailStatus: string = detailItem.Status;

    let summaryItem: EmailMessagingStatusData | undefined = summaryTable.find(item => item.EmailStatus === emailStatus);

    if (!summaryItem) {
      summaryItem = {
        EmailStatus: emailStatus,
        TotalEmailStatusCount: 1, // Initialize count for unmatched statuses
      };
      summaryTable.push(summaryItem);
    } else {
      summaryItem.TotalEmailStatusCount += 1;
    }
  }
  return summaryTable;
}



private async buildReportInfo(reportRequest: EmailMessagingReportRequest): Promise<EmailMessagingReportInfo> {
    const reportInfo: EmailMessagingReportInfo = {};
    reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    if (reportRequest.Practice?.IsMultiBranch === true)
        reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';
    reportInfo.DateRange = moment(reportRequest.DateFrom).format('DD/MM/YYYY') + ' - ' + moment(reportRequest.DateTo).format('DD/MM/YYYY');
    reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;

    return reportInfo;
}

}
