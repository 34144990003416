import {Timestamp} from '@angular/fire/firestore';

export class PricingContract {

  static YEARS_FIELD = "Years";

  Name: string | null;
  Code: string | null;
  Levels: string[] | null;
  SubCategories: string[] | null;
  Years: string[] | null;
}

export class PricingSubscription {
  Code: string;
  DateFrom: Timestamp | any;
  DateTo?: Timestamp | any;
  Level?: string;
  SubCategory?: string;
  BillMedsAtZero?: boolean;
  BillConsAtZero?: boolean;
}

export class PricingConfiguration {
  ActiveYear: string;
}

export class TariffPriceRequest {
  tariffCode?: string;
  practiceId?: string;
  providerId?: string;
  year?: string;
  disciplineCode?: string;
  planOptionCode?: string;
  placeOfService?: string;
}

export interface NappiPriceRequest {
  nappiCode: string;
  practiceId: string;
  planOptionCode: string;
  quantity?: number;
  chronic?: boolean;
  year: number;
}

export class PrivatePriceRequest {
  practiceId: string;
  providerId: string;
  priceCode: string;
  type: "Procedure" | "Medicine" | "Consumable";
  date: Date;
  isMedicalAid?: boolean;
  branch?: string;
  isMultiBranch?: boolean;
  scheme?: string;
  option?: string;
}

export interface SinglePriceRequest {
  practiceId: string;
  providerId: string;
  priceCode: string;
  type: "Procedure" | "Medicine" | "Consumable";
  date: Date;
  disciplineCode: string;
  planOptionCode: string;
  placeOfService: string;
  isMedicalAid?: boolean;
  branch?: string;
  isMultiBranch?: boolean;
  scheme?: string;
  option?: string;
  quantity?: number;
}
