import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { MoneyUtils } from '../utils/money-utils';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import { DIALOG_WARNING_TITLE, DialogService } from './dialog.service';
import { DiagnosisEngineService } from './diagnosis-engine.service';
import {
  Account, ACCOUNT_TYPE, AccountMember, Balance, DialogButtonStyle,
  DialogType,
  Invoice, INVOICE_LINE_TYPE,
  INVOICE_SUBTYPE, INVOICE_SUBTYPE_FRIENDLY_LABEL, INVOICE_TYPE, InvoiceAccount, InvoiceMainMember,
  InvoicePatient, Person, PLACE_OF_SERVICE,
  PracticeProvider,
  YES_BUTTON_ID
} from '@meraki-flux/schema';

export const CUSTOM_CHARGE_CODES_ONLY_ON_CASH_MESSAGE = 'You can only add custom charge codes to cash invoices. Please either remove the custom charge code lines, or change the invoice type.';
export const DUPLICATE_DATES = 'The date of service of this new invoice is the same as that of the copied invoice. Are you sure this is correct?';
export const DUPLICATE_INVOICE_LINES_MESSAGE = 'You have captured duplicate procedure and/or NAPPI codes. Do you want to make any corrections before proceeding?';
export const EDIT_INVOICE_BUTTON_ID = 'EDIT_INVOICE';
export const EDIT_INVOICE_BUTTON_LABEL = 'Edit invoice';
export const MEDICAL_INSURER_INVOICE_INVALID_DATA_MESSAGE = 'This is a medical insurance invoice. It is recommended that you only add the approved “A” procedure codes (e.g. A1301, A1101, A1307, etc.). Are you sure you want to submit this invoice?';
export const MULTIPLE_PPE_CODE_MESSAGE = 'More than one PPE code has been added to this invoice. Do you want to edit the invoice or continue to submit?';
export const MUST_SPECIFY_HOSPITAL_DATES_MESSAGE = 'You have specified place of service as "Inpatient hospital". Please specify admission/discharge dates.';
export const NO_DIAG_LINES_MESSAGE = 'Please specify a diagnosis code for the invoice, and for each invoice line.';
export const NO_INVOICE_LINES_MESSAGE = 'Please specify at least one invoice line';
export const NO_ZERO_TOTAL_NO_CHARGE_MESSAGE = 'You have specified a no charge invoice. Please make sure all the line item amounts are 0 before saving this invoice.';
export const SUBMIT_INVOICE_BUTTON_ID = 'SUBMIT_INVOICE';
export const SUBMIT_INVOICE_BUTTON_LABEL = 'Submit invoice';
export const TREATING_PROVIDER_NOT_LINKED_MESSAGE = 'The selected treating provider is not linked to the selected branch for this practice. Are you sure you want to continue?';
export const ZERO_INVOICE_TOTAL_MESSAGE = 'The invoice total is R0.00. Please change this invoice type to "No Charge", or specify prices for at least one of the invoice lines.';

@Injectable({
  providedIn: 'root',
})
export class InvoiceHelperService {
  constructor(
    private dialogService: DialogService,
    private firestore: Firestore,
    private authService: AuthService,
    private diagnosisEngine: DiagnosisEngineService
  ) { }

  static getInvoiceType(invoice: Invoice): string {
    if (!invoice) {
      return '';
    }
    const type = invoice.Type;
    const subType = invoice.Subtype;
    if (subType === INVOICE_SUBTYPE.MEDICAL_INSURER) {
      return INVOICE_SUBTYPE.MEDICAL_INSURER;
    }
    if (subType === INVOICE_SUBTYPE.DEBIT_NOTE) {
      return INVOICE_SUBTYPE_FRIENDLY_LABEL.DEBIT_NOTE;
    }
    return type;
  }

  async validateInvoice(invoice: Invoice, account: Account, isMultiBranch: boolean, copiedDateOfService?: Date) {
    const patientDoc = await getDoc(
      doc(this.firestore, `Practice/${this.authService.selectedBPN}/Patient/${invoice.Patient}`)
    );
    const patient: InvoicePatient = patientDoc.data();

    // Check rand mutual and MA
    const bypassDiag = this.shouldBypassDiagnosisCheckForRandMutual(invoice, account);

    if (!bypassDiag) {
      const hasDiagLines = this.checkNoDiagLines(invoice);
      if (!hasDiagLines) return false;
      const diagnosisRule = await this.diagnosisEngine.checkDiagnoses(invoice, patient);
      if (!diagnosisRule) return false;
    }

    const providerLinkedToBranch = await this.checkIfProviderLinkedToBranch(invoice, isMultiBranch);
    if (!providerLinkedToBranch) return false;

    const medicalInsuranceInvoiceWithInvalidLines =
      await this.checkIfMedicalInsuranceInvoiceContainsInvalidLines(invoice);
    if (!medicalInsuranceInvoiceWithInvalidLines) return false;

    const allowMultiplePPECodes = await this.checkMultiplePPECodes(invoice);
    if (!allowMultiplePPECodes) return false;

    if (copiedDateOfService) {
      const sameServiceDates = await this.checkServiceDates(invoice, copiedDateOfService);
      if (!sameServiceDates) return false;
    }
    const hasLines = this.checkNoLines(invoice);
    if (!hasLines) return false;

    const noDuplicateLines = await this.checkDuplicateLines(invoice);
    if (!noDuplicateLines) return false;

    const hasCustomChargeCodeLines = this.checkForCustomChargeCodeLines(invoice);
    if (!hasCustomChargeCodeLines) return false;

    const noZeroTotalNoCharge = this.checkZeroTotalNoCharge(invoice);
    if (!noZeroTotalNoCharge) return false;

    return true;
  }

  async checkServiceDates(invoice, copiedDateOfService: Date) {
    if (invoice.DateOfService?.toDateString() === copiedDateOfService?.toDateString()) {
      return this.dialogService
        .showDialog({
          message: DUPLICATE_DATES,
          type: DialogType.WARNING,
          buttons: [
            {
              caption: 'No, Not Correct',
              id: EDIT_INVOICE_BUTTON_ID,
            },
            {
              caption: 'Correct, Submit Invoice',
              id: SUBMIT_INVOICE_BUTTON_ID,
            },
          ],
        })
        .closed.pipe(map((res) => res === SUBMIT_INVOICE_BUTTON_ID))
        .toPromise();
    }
    return true;
  }

  async checkIfProviderLinkedToBranch(invoice: Invoice, isMultiBranch: boolean) {
    if (isMultiBranch && invoice?.Branch) {
      const providerDoc = await getDoc(
        doc(
          this.firestore,
          PathUtils.practiceProviderPath(this.authService.selectedBPN, invoice.TreatingProvider.Id)
        )
      );
      const provider: PracticeProvider = providerDoc.data();

      const providerInBranch = provider?.Common?.AssignedBranches?.find(
        (b) => b.BranchName === invoice.Branch
      );

      if (!providerInBranch) {
        return this.dialogService
          .showYesNoDialog(TREATING_PROVIDER_NOT_LINKED_MESSAGE, 'Warning')
          .closed.pipe(map((res) => res === YES_BUTTON_ID))
          .toPromise();
      }
    }
    return true;
  }

  async checkIfMedicalInsuranceInvoiceContainsInvalidLines(invoice: Invoice) {
    if (invoice?.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER) {
      let invalidData = false;
      for (const line of invoice.Lines) {
        if (line.LineType && line.LineType !== INVOICE_LINE_TYPE.PROCEDURE) {
          invalidData = true;
          break;
        }
        if (line.TariffCode && !line.TariffCode.startsWith('A')) {
          invalidData = true;
          break;
        }
      }
      if (invalidData) {
        return this.dialogService
          .showYesNoDialog(MEDICAL_INSURER_INVOICE_INVALID_DATA_MESSAGE, 'Warning')
          .closed.pipe(map((res) => res === YES_BUTTON_ID))
          .toPromise();
      }
    }
    return true;
  }

  checkNoLines(invoice: Invoice) {
    if (invoice && invoice.Lines?.length === 0) {
      this.dialogService.showWarning(NO_INVOICE_LINES_MESSAGE);
      return false;
    }
    return true;
  }

  async checkDuplicateLines(invoice: Invoice) {
    const tariffGroups = invoice.Lines.filter(
      (line) =>
        line.LineType === INVOICE_LINE_TYPE.ADMIN ||
        line.LineType === INVOICE_LINE_TYPE.PROCEDURE ||
        (line.LineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE && !line.IsPPELine)
    ).reduce((prev, curr) => {
      prev[curr.TariffCode] ? prev[curr.TariffCode].push(curr) : (prev[curr.TariffCode] = [curr]);
      return prev;
    }, {});
    let hasDups = Object.keys(tariffGroups).some((key) => tariffGroups[key].length > 1);
    if (!hasDups) {
      const nappiGroups = invoice.Lines.filter(
        (line) =>
          line.LineType === INVOICE_LINE_TYPE.MEDICINE ||
          line.LineType === INVOICE_LINE_TYPE.CONSUMABLE ||
          line.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE ||
          (line.LineType === INVOICE_LINE_TYPE.CSTM_MEDICINE && !line.IsPPELine)
      ).reduce((prev, curr) => {
        prev[curr.NappiCode] ? prev[curr.NappiCode].push(curr) : (prev[curr.NappiCode] = [curr]);
        return prev;
      }, {});
      hasDups = Object.keys(nappiGroups).some((key) => nappiGroups[key].length > 1);
    }

    if (hasDups) {
      return this.dialogService
        .showYesNoDialog(DUPLICATE_INVOICE_LINES_MESSAGE, DIALOG_WARNING_TITLE)
        .closed.pipe(map((res) => res !== YES_BUTTON_ID))
        .toPromise();
    }
    return true;
  }

  checkMultiplePPECodes(invoice: Invoice) {
    const ppeLineCount = invoice?.Lines?.filter((line) => line.IsPPELine)?.length;
    if (ppeLineCount > 1) {
      return this.dialogService
        .showDialog({
          message: MULTIPLE_PPE_CODE_MESSAGE,
          type: DialogType.QUESTION,
          buttons: [
            {
              caption: SUBMIT_INVOICE_BUTTON_LABEL,
              id: SUBMIT_INVOICE_BUTTON_ID,
            },
            {
              caption: EDIT_INVOICE_BUTTON_LABEL,
              id: EDIT_INVOICE_BUTTON_ID,
              style: DialogButtonStyle.PRIMARY,
            },
          ],
        })
        .closed.pipe(map((res) => res === SUBMIT_INVOICE_BUTTON_ID))
        .toPromise();
    }
    return true;
  }

  checkForCustomChargeCodeLines(invoice: Invoice) {
    const hasCustomCodes = invoice.Lines.find(
      (line) =>
        line.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE ||
        line.LineType === INVOICE_LINE_TYPE.CSTM_MEDICINE ||
        line.LineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE ||
        line.LineType === INVOICE_LINE_TYPE.ADMIN
    );

    if (
      hasCustomCodes &&
      invoice.Subtype !== INVOICE_SUBTYPE.NO_CHARGE &&
      invoice.Type !== INVOICE_TYPE.CASH
    ) {
      this.dialogService.showWarning(CUSTOM_CHARGE_CODES_ONLY_ON_CASH_MESSAGE);
      return false;
    }

    return true;
  }

  checkZeroTotalNoCharge(invoice: Invoice) {
    if (invoice.Subtype === INVOICE_SUBTYPE.NO_CHARGE && invoice.Type !== INVOICE_TYPE.CASH) {
      const hasNoZeroLines = invoice.Lines?.some((line) => line.AmountBilled > 0);
      if (hasNoZeroLines) {
        this.dialogService.showWarning(NO_ZERO_TOTAL_NO_CHARGE_MESSAGE);
        return false;
      }
    }

    return true;
  }

  checkNoDiagLines(invoice: Invoice) {
    const hasNoDiagLines = invoice.Lines?.some((line) => line.DiagnosisCodes?.length === 0);
    if (hasNoDiagLines) {
      this.dialogService.showWarning(NO_DIAG_LINES_MESSAGE);
      return false;
    }

    return true;
  }

  shouldBypassDiagnosisCheckForRandMutual(invoice: Invoice, account: Account) {
    return invoice?.Type === INVOICE_TYPE.MEDICAL_AID &&
      account?.AccountType === ACCOUNT_TYPE.MEDICAL_AID &&
      account?.Scheme === 'S2478'
  }

  async formatInvoiceForSave(
    invoice: Invoice,
    mainMember: AccountMember,
    patient: AccountMember,
    account: Account,
    spoData: any,
    total: number
  ): Promise<Invoice> {
    let vatPercentage;
    // Check for practice VAT config and pull data
    const practiceDoc = await getDoc(
      doc(this.firestore, `Practice/${this.authService.selectedBPN}`)
    );
    const vatNumber = practiceDoc.get('VatNo');
    const isVATRegistered = !!practiceDoc.get('IsVATRegistered');
    if (vatNumber && isVATRegistered) {
      const vatConfigDataDoc = await getDoc(doc(this.firestore, `Configuration/VATSettings`));
      const data: any[] = vatConfigDataDoc.get('data');
      if (data && data?.length > 0) {
        const vatData = data
          .map((d) => ({
            ...d,
            DateFrom: d.DateFrom?.toDate() || null,
            DateTo: d.DateTo?.toDate() || moment().add(1, 'day').toDate()
          }))
          .filter((d) => !!d.DateFrom);
        let invoiceDate = invoice.InvoiceDate || new Date();
        invoiceDate = invoiceDate instanceof Date ? invoiceDate : invoiceDate.toDate();
        // Check if we can find something in range of our date of service
        const matchedPercentage = vatData.find((d) =>
          moment(invoiceDate || new Date()).isBetween(
            moment(d.DateFrom),
            moment(d.DateTo),
            'date',
            '[]'
          )
        )?.Percentage;

        if (matchedPercentage === null || matchedPercentage === undefined) {
          throw Error(`Could not find VAT settings match for invoice date ${invoice.InvoiceDate}`);
        }
        vatPercentage = matchedPercentage;
      } else {
        throw Error('No VAT configuration found');
      }
    }

    const accountInfo: Partial<InvoiceAccount> = {
      AccountNo: account.AccountNo,
      Id: account.Id,
      MemberNo: account.MemberNo,
      SchemeCode: account.Scheme || null,
      PlanCode: account.Plan || null,
      OptionCode: account.Option || null,
      SchemeName: spoData?.scheme || null,
      PlanName: spoData?.plan || null,
      OptionName: spoData?.option || null,
    };

    if (!accountInfo.MemberNo) delete accountInfo.MemberNo;

    const mainMemberPerson = {
      Name: mainMember.Name,
      Surname: mainMember.Surname,
      Title: mainMember.Title || null,
      //get the date of birth from the form in case it was changed
      DateOfBirth: patient.DateOfBirth,
      Gender: mainMember.Gender || null,
      Contact: mainMember.Contact || null,
      //get ID number from the form in case it was changed
      IdentityNo: mainMember.IdentityNo || null,
      Initials: mainMember.Initials || mainMember.Name.substring(0, 1).toUpperCase(),
      FileNo: mainMember.PatientFileNo || null,
    } as Person;

    let patientPerson = {} as Person;
    if (patient) {
      if (mainMember.Id === patient.Id) {
        patientPerson = mainMemberPerson;
      } else {
        patientPerson = {
          Name: patient.Name,
          Surname: patient.Surname,
          Title: patient.Title || null,
          DateOfBirth: patient.DateOfBirth,
          Gender: patient.Gender || null,
          Contact:
            (patient.Contact?.SameAsMainMember ? mainMemberPerson.Contact : patient.Contact) ||
            null,
          IdentityNo: patient.IdentityNo || null,
          Initials: patient.Initials || patient.Name.substring(0, 1).toUpperCase(),
          FileNo: patient.PatientFileNo || null,
        };
      }
    }

    // Invoice amounts stored in cents
    const invoiceTotal = MoneyUtils.toCents(total || 0);

    const balance = {
      Outstanding: invoiceTotal,
      PatientLiable: 0,
      MedicalAidLiable: 0,
    } as Balance;

    if (invoice.Type === INVOICE_TYPE.CASH) {
      balance.PatientLiable = invoiceTotal || 0;
    } else {
      balance.MedicalAidLiable = invoiceTotal || 0;
    }

    const result = {
      ...invoice,
      Id: invoice.Id || uuidv4(),
      Lines:
        invoice.Lines?.map((line, idx) => {
          if (invoice.Type === INVOICE_TYPE.MEDICAL_AID) {
            line.Balance.MedicalAidLiable = line.Balance.Outstanding;
          } else {
            line.Balance.PatientLiable = line.Balance.Outstanding;
          }

          if (vatPercentage) {
            line.AmountVAT = line.AmountBilled - (Math.round(line.AmountBilled / (1 + vatPercentage / 100)));
          }

          // Reset line numbers at object creation
          line.LineNumber = idx + 1;

          return line;
        }) || [],
      Account: accountInfo,
      InvoiceDate: new Date(),
      DateOfSubmission: new Date(),
      AdmissionDate: invoice.AdmissionDate || null,
      DischargeDate: invoice.DischargeDate || null,
      MainMember: {
        ...mainMemberPerson,
        Id: mainMember.Id,
      } as InvoiceMainMember,
      Patient: {
        ...patientPerson,
        Id: patient?.Id,
        DependantCode: patient?.DependantCode,
      } as InvoicePatient,
      Balance: balance,
      AmountBilled: invoiceTotal || 0, // Invoice amounts stored in cents
      LinkedAppointment: invoice.LinkedAppointment || '',
    } as Invoice;

    if (vatPercentage) {
       result.AmountVAT = invoiceTotal - (Math.round(invoiceTotal / (1 + vatPercentage / 100)));
    }

    return result;
  }

  async getRouteInfo(
    schemeCode: string,
    planCode: string,
    optionCode: string
  ): Promise<{ routeCode: string; claimEmail: string }> {
    const schemeDoc = await getDoc(doc(this.firestore, `SchemePlanOption/${schemeCode}`));
    const plans = schemeDoc.get('Plans') as any[];
    const routeCode = plans
      ?.find((p) => p.Code === planCode)
      ?.Options?.find((o) => o.Code === optionCode)?.RouteCode;
    return { routeCode, claimEmail: schemeDoc.get('ClaimEmail') };
  }

  async isRoutable(routeCode: string, specialityCode: number) {
    const routeDataDoc = await getDoc(doc(this.firestore, `RealtimeRoutingCode/${routeCode}`));
    return !!routeDataDoc.get(specialityCode.toString());
  }

  async isRealtime(routeCode: string, specialityCode: number) {
    const routeDataDoc = await getDoc(doc(this.firestore, `RealtimeRoutingCode/${routeCode}`));
    const routeData = specialityCode ? routeDataDoc.get(specialityCode?.toString()) : '';
    if(routeData) {
      return routeData.healthbridgeDeliveryType === 'Realtime';
    }
    return false;
  }
}

export { Account };
