import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { DateUtils } from '../utils/date-utils';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import {ReversalRequest} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class ReversalService {
  constructor(private firestore: Firestore, private authService: AuthService) {}

  async reversalRequest(invoiceId: string, reversalRequestId: string) {
    if (!invoiceId || !reversalRequestId) return undefined;
    const rrDoc = await getDoc(
      doc(
        this.firestore,
        PathUtils.reversalRequestPath(this.authService.selectedBPN, invoiceId, reversalRequestId)
      )
    );
    return DateUtils.timestampsToDates({
      ...rrDoc.data(),
      Id: rrDoc.id,
    }) as ReversalRequest;
  }
}
