<input
  #fileSelection
  class="file-input"
  type="file"
  style="display: none"
  (change)="changeListener($event)"
  (click)="fileSelection.value = ''"
  value=""
  [accept]="accept"
  multiple>
<span class="flex justify-end">
  <button
    #btn
    class='!mb-4'
    mat-stroked-button
    type="button"
    (click)="fileSelection.click()">
    <mat-icon class="!text-current mat-icon" aria-label="attachment icon">attach_file</mat-icon>
    <ng-content></ng-content>
  </button>
  {{ !!files?.length && displayFilename ? files[0].name : '' }}
</span>
