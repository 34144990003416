import { ProviderVo } from './provider.models';
import { BaseInvoice } from './invoice.models';
import { LinkedPatientDocument, PatientEventVo } from './patient.models';

export interface InvoiceVo {
  InvoiceId?: string | null;
  EncounterId?: string | null;
  BranchXRef?: string | null;
  PracticeId?: string | null;
  InvoiceType?: string | null;
  Provider?: ProviderVo | null;
  ReferringDoctorName?: string | null;
  ReferringDoctorPracticeNumber?: string | null;
  AssistingDoctorName?: string | null;
  AssistingDoctorPracticeNumber?: string | null;
  PatientEventDetails?: PatientEventVo | null;
  InvoiceLineItems?: InvoiceLineItemsVo | null;
  RequiresFollowUp?: boolean | null;
  Note?: string | null;
  PatientDocuments?: LinkedPatientDocument[] | null;
}

export interface InvoiceLineItemsVo {
  InvoiceHeader?: InvoiceHeaderVo | null;
  LineItems?: InvoiceLineItemVo[] | null;
}

export interface InvoiceLineItemVo {
  DateOfService?: Date | null;
  LineNum?: number | null;
  LineType?: string | null;
  ChargeCode?: string | null;
  ChargeDesc?: string | null;
  NappiCode?: string | null;
  ChargeUnits?: string | null;
  DosageUnit?: number | null;
  DosageType?: string | null;
  FrequencyUnit?: number | null;
  FrequencyType?: string | null;
  PeriodUnit?: number | null;
  PeriodType?: string | null;
  DurationUnit?: number | null;
  DurationType?: string | null;
  Repeat?: number | null;
  ChronicIndicator?: boolean | null;
  ChargeQuan?: number | null;
  TotalExcVat?: number | null;
  VatAmount?: number | null;
  TotalIncVat?: number | null;
  UnitPrice?: number | null;
  PriceOverride?: boolean | null;
  SchemeRule?: string | null;
  Amount?: number | null;
  Parameters?: { [key: string]: string } | null;
  Responses?: { [key: string]: string } | null;
  ChargeStart?: Date | null;
  ChargeEnd?: Date | null;
  Diagnosis?: DiagnosisVo[] | null;
}

export interface InvoiceHeaderVo {
  ClaimAuthorizationNumber?: string | null;
  AdmissionDateTime?: Date | null;
  DischargeDateTime?: Date | null;
  HospitalIndicator?: boolean | null;
  ClaimType?: string | null;
  TariffCode?: string | null;
  Diagnosis?: DiagnosisVo[] | null;
  DateOfService?: Date | null;
  /** 0 = Uknown, 2 = Telehealth, 11 = ConsultingRooms, 21 = InPatientHospital, 24 = DayClinic */
  PlaceOfService?: InvoiceHeaderVoPlaceOfService | null;
  Ward?: string | null;
}

export interface DiagnosisVo {
  Id?: string | null;
  DiagnosisCode?: string | null;
  DiagnosisDescription?: string | null;
  SpecificCode?: boolean | null;
  PrimaryIndicator?: boolean | null;
  ValidPrimary?: boolean | null;
  ValidSequelae?: boolean | null;
  MaxSpecificity?: boolean | null;
  Gender?: string | null;
  AgeRangeStart?: number | null;
  AgeRangeEnd?: number | null;
}

export enum InvoiceHeaderVoPlaceOfService {
  _0 = 0,
  _2 = 2,
  _11 = 11,
  _21 = 21,
  _24 = 24,
}

export function getPlaceOfServiceDescription(placeOfService: number): string {
  switch (placeOfService) {
    case 0:
    default:
      return "Unknown";
    case 2:
      return "Telehealth";
    case 11:
      return "ConsultingRooms";
    case 21:
      return "InPatientHospital";
    case 24:
      return "DayClinic";
  }
}

export interface InvoiceNexus extends BaseInvoice {
  Path?: string; // stores invoice path
  CreditNotesType?: string;
  CreditNotesDate?: string;
  CapturedBy?: string;
  CreditNotesHeaderAmount?: number;
  CreditNotesHeaderAmountVAT?: number;
  CreditNotesHeaderId?: string;
}
