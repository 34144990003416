import {BaseModel} from "./general.models";

export interface CustomChargeCodes extends BaseModel {
  Id?: string;
  Status: CustomChargeCodeStatus;
  Type: CustomChargeCodeType;
  Code: string;
  Description: string | null;
  UnitPrice: number | null;
  PackSize?: number | null;
  PackPrice?: number | null;
}

export enum CustomChargeCodeStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted',
}

export enum CustomChargeCodeType {
  ALL = 'All',
  ADMIN = 'Admin',
  CSTM_CONSUMABLE = 'cstm_Consumable',
  CSTM_MEDICINE = 'cstm_Medicine',
  CSTM_PROCEDURE = 'cstm_Procedure',
}
