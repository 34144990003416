import { BenefitCheckPdfGenerator } from "./pdf.benefit-check";
import { ReportUtils } from "../report-utils";
import { BenefitCheckPdfReportDataBuilder } from "./benefit-check-pdf-report-data-builder";
import { BenefitCheckReportBuilder } from "./benefit-check-report-builder";
import { BenefitCheckReportRequest, REPORT_FORMAT_TYPE, CalendarEvent, BasePdfReportData, Report } from "@meraki-flux/schema";

export class BenefitCheckReportService {

  generateReport(reportRequest: BenefitCheckReportRequest, serverSide = false): Report {

    const fileName: string = ReportUtils.buildBenefitCheckReportFileName(reportRequest.practiceName,
      reportRequest.CalendarEvent.VisitInfo?.PatientInfo?.Name, reportRequest.CalendarEvent.VisitInfo?.PatientInfo?.Surname,
      REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = this.getReportGenerator(reportRequest);
    const content = reportGenerator.generateReport(serverSide);


    return {
      reportName: 'Benefit check',
      fileName: fileName,
      content: content,
      practiceId: reportRequest.bpn,
      format: REPORT_FORMAT_TYPE.PDF
    };
  }

  private getReportGenerator(reportRequest: BenefitCheckReportRequest) {

    const reportGenerator = new BenefitCheckPdfGenerator(
      this.generatePdfReportData(reportRequest.bpn, reportRequest.practiceName, reportRequest.treatingProvider, reportRequest.CalendarEvent)
    );
    return reportGenerator;
  }

  private generatePdfReportData(bpn: string, practiceName: string, treatingProvider: string, calendarEvent: CalendarEvent): BasePdfReportData {
    return new BenefitCheckPdfReportDataBuilder().build(
      new BenefitCheckReportBuilder().build(bpn, practiceName, treatingProvider, calendarEvent.VisitInfo));
  }
}
