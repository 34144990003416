import { doc, Firestore } from '@angular/fire/firestore';
import { uuidv4 } from '@firebase/util';
import { PathUtils } from '../../../utils/path-utils';
import { AuthService } from '../../auth.service';
import { FirestoreService } from '../../firestore.service';
import {CalendarEvent, FREQUENCY, Repeat} from "@meraki-flux/schema";

export abstract class AbstractEventAction {
  constructor(
    protected firestore: Firestore,
    protected firestoreService: FirestoreService,
    protected authService: AuthService
  ) {}

  protected calendarEventCollectionPath() {
    return PathUtils.calendarEventCollectionPath(this.authService.selectedBPN);
  }

  protected calendarEventPath(eventId: string) {
    return PathUtils.calendarEventPath(this.authService.selectedBPN, eventId);
  }

  protected newId() {
    return uuidv4();
  }

  protected async mergeDoc(event: CalendarEvent) {
    await this.setDoc(event, true);
  }

  protected async setDoc(event: CalendarEvent, merge: boolean = false) {
    if (!event?.Id) {
      throw Error('Id is missing');
    }
    const eventPath = this.calendarEventPath(event.Id);
    const data = { ...event, UpdatedBy: this.authService.uid, UpdatedAt: new Date() };
    delete data.Id;
    delete data.Metadata;
    await this.firestoreService.setDoc(doc(this.firestore, eventPath), data, { merge: merge });
  }

  shouldRepeat(repeat: Repeat): boolean {
    return !!repeat?.Frequency && FREQUENCY.DO_NOT_REPEAT !== repeat.Frequency;
  }

  isValidRepeat(repeat: Repeat | null): boolean {
    return repeat !== null &&  repeat !== undefined && !isNaN(repeat.EndDate?.getDate()) && repeat.Frequency?.length > 0;
  }
}
