/* eslint-disable no-useless-escape */
import { Validators } from '@angular/forms';

export class FluxValidators {
  public static REGEX__NAME_SURNAME =
    "^()$|^([A-Za-z-ÁÀȦÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄NŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ\\\\.,/'\u02bc\u00b4\\^\\\- ]+)*$";
  public static REGEX_PHONE_NO = /^[0-9]*$/;
  public static REGEX_PRACTICE_NAME = /^()$|^([a-zA-Z0-9\\~!@#%&*()<>_+`/\-=\\\[\];:,.\/|?\"' ]+)*$/;
  public static ACCOUNT_NUMBERS = /^([a-zA-Z0-9_|\/ :,.()-]+)*$/;
  public static REGEX_MEMBER_NUMBER = /^[a-zA-Z0-9./_\\\-: ]*$/;
  public static REGEX_DEPENDANT_CODE = /^[0-9]*$/;

  static nameSurname() {
    return [
      Validators.pattern(this.REGEX__NAME_SURNAME),
      Validators.maxLength(50),
    ];
  }

  static cellPhoneNo() {
    return [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(this.REGEX_PHONE_NO),
    ];
  }

  static homePhoneNo() {
    return [
      Validators.minLength(10),
      Validators.pattern(this.REGEX_PHONE_NO),
    ];
  }

  static practiceName() {
    return [
      Validators.pattern(this.REGEX_PRACTICE_NAME),
      Validators.maxLength(50),
    ];
  }

  static accountNumbers() {
    return [
      Validators.pattern(this.ACCOUNT_NUMBERS),
      Validators.maxLength(28),
    ];
  }

  static dependantCodes() {
    return [
      Validators.pattern(this.REGEX_DEPENDANT_CODE),
      Validators.maxLength(5),
    ];
  }

  static memberNumber() {
    return [
      Validators.pattern(this.REGEX_MEMBER_NUMBER),
      Validators.maxLength(50),
    ];
  }
}
