<mat-progress-bar [mode]="'indeterminate'" class="w-100vw top-0 left-0 !absolute !z-50"
  *ngIf="isLoading"></mat-progress-bar>

<div *ngIf="user$ | async as user"
  class="border-b-[1px] !h-[56px] bg-hb-base-white border-hb-base-divider-grey w-100vw overflow-hidden">

  <div class="flex flex-row items-center w-full h-[56px] px-3 grow-0">

    <!-- brand logo -->
    <img class="shrink-0 h-8 w-8 justify-self-start" src="assets/icons/hb_logo.svg">

    <!-- Nav items -->
    <div class="flex flex-row h-full gap-1 ml-2 shrink-0 justify-self-start">

      <ng-container *ngFor="let item of navItems">
        <ng-container *ngIf="item.access | async">
          <ng-container
            *ngTemplateOutlet="section; context: { $implicit: item.label, link: item.url, icon: item.icon, showIndicator: item.showIndicator}">
          </ng-container>
        </ng-container>
      </ng-container>

    </div>


    <div class="flex-1"></div>

    <!-- Patient search container -->
    <meraki-flux-patient-search
      class="justify-self-end self-center h-full max-w-[550px] min-w-[300px] ml-4"></meraki-flux-patient-search>


    <!-- Right side row -->
    <div class="flex flex-row h-full items-center justify-items-end justify-self-end shrink-0 ml-2">

      <meraki-flux-practice-switch class="mr-1" *ngIf="auth?.admin || user?.AssignedPractices?.length > 1"></meraki-flux-practice-switch>

      <button class="!mr-1.5 !bg-hb-accent-help-10" *ngIf="appRuntimeContext === appRuntime.CAPACITOR && userHasAddonAccess$ | async" mat-icon-button (click)="onAddonsClicked()">
        <mat-icon [svgIcon]="'extension_add'" class="!text-hb-accent-help-100 !mr-0 !mb-1"
          fontSet="material-icons-outlined" matTooltip="Addons"></mat-icon>
      </button>

      <!-- User tile -->
      <button matRipple class="!rounded-none !p-0 !shrink-0" [matMenuTriggerFor]="userMenu">
        <div class="rounded-full !w-10 !h-10 bg-hb-secondary-100 border-[3px] border-hb-secondary-20">
          <div class="font-medium text-sm leading-9 align-middle text-hb-base-white"
            *ngIf="userInitials$ | async as initials">
            {{initials}}</div>
        </div>
      </button>


    </div>


  </div>

</div>






<ng-template #section let-label let-link="link" let-icon="icon" let-showIndicator="showIndicator">
  <a matRipple [routerLink]="link" (click)="updateInboxIndicator(link,false)"
    class="flex flex-row flex-nowrap h-full items-center py-4 px-2 hover:bg-hb-base-base-grey border-b-[3px] border-b-transparent"
    routerLinkActive="!border-b-hb-primary-100">
    <mat-icon class="text-base !w-4 !h-4 !leading-4 mt-1 !text-hb-text-default" fontSet="material-icons-outlined"
      *ngIf="icon">
      {{icon}}
    </mat-icon>
    <span class="ml-2.5 font-medium text-sm mt-1 text-hb-text-default">{{label}}</span>
    <div *ngIf="showIndicator | async" class="w-2 h-2 shrink-0 grow-0 bg-hb-critical-100 rounded-full ml-0.5 mt-[-4px]">
    </div>
  </a>
</ng-template>


<mat-menu #userMenu class="!w-[400px] !max-w-[400px]">
  <a (click)="$event.stopPropagation();$event.preventDefault();">
    <div class="bg-hb-secondary-10 flex flex-col h-[167px]">
      <div class="flex pt-7 justify-center">
        <div class="rounded-full !w-16 !h-16 p-4.5 bg-hb-secondary-100 border-[3px]">
          <span class="font-medium text-xl leading-8 text-hb-base-white flex justify-center pt-3"
            *ngIf="userInitials$ | async as initials">{{initials}}</span>
        </div>
      </div>
      <div class="flex justify-center">
        <span class="font-semibold text-lg leading-7">{{userName$ | async}}</span>
      </div>
      <div class="flex justify-center">
        <span class="font-normal text-xs leading-5">{{practiceName$ | async}}</span>
      </div>

    </div>

    <div class="flex flex-col p-2 gap-2 items-stretch">

      <!-- Branch select -->
      <div *ngIf="branches$ | async as branches" [formGroup]="branchesForm">
        <mat-form-field *ngIf="branches.length > 1" appearance="outline" class="flux-textarea-form-field w-full" >
          <mat-label>Branch</mat-label>
          <mat-select formControlName="branch" placeholder="Branch" class="h-1/2" (click)="$event.stopImmediatePropagation()">
            <mat-option *ngFor="let branch of branches" [value]="branch">
              {{ branch }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex justify-center py-4">
      <button mat-flat-button class="flux-button mat-secondary" (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>Sign out</span>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="flex justify-center py-4">
      <a routerLink="/privacy-policy"  class="cursor-pointer"><u>Privacy policy</u></a>
      <span class="px-2">&#8226;</span>
      <a routerLink="/tos"  class="cursor-pointer"><u>Terms of service</u></a>
      <span class="px-2">&#8226;</span>{{version}}
    </div>
  </a>
</mat-menu>
