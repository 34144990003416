import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DebitInvoiceHelper } from './debit-invoice.helper';
import {DebitPayableInvoice, Invoice, LIABILITY_TYPE, PayableInvoice, RemittanceAdvice} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-debit-invoice',
  templateUrl: './debit-invoice.component.html',
  styleUrls: ['./debit-invoice.component.scss'],
})
@UntilDestroy()
export class DebitInvoiceComponent implements OnInit {
  @Input() totalDebitAmount: number;
  @Input() invoices: Invoice[];
  @Input() ra: RemittanceAdvice;
  @Output() touch = new EventEmitter();

  readonly liabilityType = LIABILITY_TYPE;
  readonly COLUMNS = ['dos', 'patient', 'code', 'invoiced', 'mal', 'pl', 'debitAmount', 'balance'];
  readonly selectedInvoice$ = new BehaviorSubject<DebitPayableInvoice>({} as DebitPayableInvoice);
  payableInvoices: PayableInvoice[];

  readonly form = this.formBuilder.group({
    LiabilityType: [null, Validators.required],
    Invoice: [null, Validators.required],
    Reason: [null],
  });

  constructor(protected helper: DebitInvoiceHelper, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.payableInvoices = this.invoices?.map((i) => this.helper.buildDebitPayableInvoice(i)) || [];
    this.setupInvoiceSelectionHandler();
    this.setupAmountAllocatedInputHandler();
    this.setupReasonInputHandler();
    this.setupLiabilityInputHandler();
    this.reset(this.ra);
  }

  reset(ra: RemittanceAdvice) {
    const defaultReason = `Payment correction received in remittance ${ra?.Payment?.RANum} from ${ra?.SchemeName}`;
    this.form.reset({ LiabilityType: LIABILITY_TYPE.MAL, Invoice: null, Reason: defaultReason });
    this.helper.resetInvoicesDebitAmountToZero(this.payableInvoices);
  }

  setupInvoiceSelectionHandler() {
    this.form.controls.Invoice.valueChanges
      .pipe(
        tap((i) => this.helper.resetInvoiceDebitAmountToZero(i)),
        tap((i) => this.selectedInvoice$.next(i)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setupAmountAllocatedInputHandler() {
    this.payableInvoices.forEach((invoice) =>
      invoice?.Lines?.forEach((l) =>
        l._AmountAllocatedCtrl.valueChanges
          .pipe(
            tap((v) => this.helper.onChangeLineAllocatedAmount(v, l, invoice)),
            tap(() => this.emitFormChanges()),
            untilDestroyed(this)
          )
          .subscribe()
      )
    );
  }

  setupReasonInputHandler() {
    combineLatest([this.selectedInvoice$, this.form.controls.Reason.valueChanges])
      .pipe(
        tap(([invoice, reason]) => this.helper.updateReason(invoice, reason)),
        tap(() => this.emitFormChanges()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setupLiabilityInputHandler() {
    combineLatest([this.selectedInvoice$, this.form.controls.LiabilityType.valueChanges])
      .pipe(
        tap(([invoice, reason]) => this.helper.updateLiability(invoice, reason)),
        tap(() => this.emitFormChanges()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  emitFormChanges() {
    this.touch.emit();
  }

  onFocusOutEvent(row: any) {
    this.helper.onDebitAmountFocusOut(this.totalDebitAmount, row);
  }

  getPayableInvoice() {
    return this.selectedInvoice$.value;
  }
}
