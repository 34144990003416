import {CLAIM_STATUS} from './claim.models';
import {Balance, BaseModel, Entity, Person} from './general.models';
import {Insurer} from './insurer.models';

export interface BaseInvoice extends BaseModel, Entity {
  Id?: string;
  Account?: InvoiceAccount;
  InvoiceNo?: string;
  Type?: INVOICE_TYPE;
  Subtype?: INVOICE_SUBTYPE;
  Status?: INVOICE_STATUS;
  PlaceOfService?: PLACE_OF_SERVICE;
  AdmissionDate?: Date;
  DischargeDate?: Date;
  DateOfService?: Date;
  InvoiceDate?: Date | any;
  DateOfSubmission?: Date;
  MainMember?: InvoiceMainMember;
  Patient?: InvoicePatient;
  HeaderDiagnosisCodes?: string[];
  Lines?: InvoiceLine[];
  TreatingProvider?: InvoiceProvider;
  ReferringProvider?: InvoiceProvider;
  AssistingProvider?: InvoiceProvider;
  Branch?: string;
  AmountBilled?: number;
  AmountVAT?: number;
  Balance?: Balance;
  Reason?: string;
  AuthorizationNo?: string;
  NewBorn?: boolean;
  LabReferenceNo?: string;
  ReferralNo?: string;
  ClaimInfo?: ClaimInfo;
  LinkedAppointment?: string;
  ReferencedInvoice?: string;
  MedicalInsurer?: Insurer;
  PolicyNo?: string;
  Broker?: string;
  DateOfBirth?: Date;
  IdentityNo?: string;
  Location?: string;
  PaymentsCorrectionsAmount?: any;
  WriteOffsAmount?: number;
  CreditNotesAmount?: number;
  MedicalAidPaymentsAmount?: number;
  PatientPaymentsAmount?: number;
  AmountExcFromHBBill?: number;
  ReversalRequestId?: string;
  CancelRequestId?: string;
  Source?: INVOICE_SOURCE;
  SourceInvoiceId?: string;
  AdminNote?: string;
}

export interface ClaimInfo {
  Id?: string;
  HBMessageId?: string;
  HBBatchId?: string;
  MedicalAidRefNo?: string;
  ClaimStatus?: CLAIM_STATUS;
  ClaimStatusHistory?: ClaimStatusHistory[];
  eRAEnabled?: boolean;
  ResponseDisclaimer?: string;
  ReasonCodes?: ReasonCode[];
  Reason?: string;
  RemittanceClaims?: RemittanceClaimInfo[];
  UpdatedAt?: Date;
  Unroutable?: boolean;
}

export interface ClaimStatusHistory {
  Status: CLAIM_STATUS;
  AppliedBy: string;
  AppliedAt: Date;
}

export interface RemittanceClaimInfo {
  RemittanceId: string;
  RemittanceClaimId: string;
  TransactionId: string;
  ReasonCodes?: ReasonCode[];
}

export interface ReasonCode {
  Code?: string;
  Description?: string;
  LineNo?: number;
  Status?: string;
}

export interface InvoiceLine {
  Id?: string;
  LineType?: INVOICE_LINE_TYPE;
  LineNumber?: number;
  TariffCode?: string;
  NappiCode?: string;
  Description?: string;
  DiagnosisCodes?: string[];
  Quantity?: number;
  MedicineAdditionalInfo?: MedicineAdditionalInfo;
  IsPPELine?: boolean;
  AmountBilled?: number;
  AmountVAT?: number;
  Balance?: Balance;
  ModifierParameter?: any[];
  UnitType?:string;
  Units?:number;
  ChargeStart?:string;
  ChargeEnd?:string;
  RcfValue?: number;
  ChargeUnit?: "Days" | "Hours" | "Kilometers" | "Kilometres" | "Minutes" | "Seconds" | "Units";
  UneditedDescription?:string;
  ModifierLinkedLineIds?: any[];
  IsModifierLinked?: boolean;
}

export interface MedicineAdditionalInfo {
  MedicineType?: MEDICINE_TYPE;
  DosageUnit?: number;
  DosageType?: DOSAGE_TYPE;
  FrequencyUnit?: number;
  PeriodUnit?: number;
  PeriodType?: PERIOD_TYPE;
  DurationUnit?: number;
  DurationType?: PERIOD_TYPE;
  Repeats?: number;
}
export interface InvoiceMainMember extends Person {
  Id?: string;
}

export interface InvoicePatient extends Person {
  Id?: string;
  DependantCode?: string;
}

export interface InvoiceProvider {
  Id?: string;
  FullName?: string | null;
  HPCSANumber?: string | null;
  TreatingPracticeNumber?: string | null;
  Speciality?: string | null;
  Dispensing?: boolean | null;
  DispensingLicNum?: string | null;
  PracticeName?: string | null;
  BillingPracticeNumer?: string | null;
  IsAnaesthetist?: boolean;
  IsLocum?: boolean;
}

export interface InvoiceAccount {
  Id?: string;
  AccountNo?: string;
  MemberNo?: string;
  SchemeCode?: string;
  PlanCode?: string;
  OptionCode?: string;
  SchemeName?: string;
  PlanName?: string;
  OptionName?: string;
}

export interface ReversalRequest extends BaseModel {
  RequestedBy: string;
  Reason: string;
  Status: REVERSAL_STATUS;
  Manual: boolean;
  InvoiceNo: string;
  SchemeCode: string;
  PlanCode: string;
  OptionCode: string;
  HBMessageId?: string;
  OrignalMessageId?: string;
  LastClaimStatus?: CLAIM_STATUS;
}

export interface CancelRequest extends BaseModel {
  RequestedBy: string;
  Reason: string;
  Status: CANCEL_STATUS;
  Error?: string;
}

export interface SaveInvoiceRequest {
  BillingPracticeNumber?: string;
  Completed: boolean;
  Source: INVOICE_SOURCE;
  Note: string;
  Invoice: BaseInvoice;
  ProcessingContext: SaveInvoiceProcessingContext;
}

export interface SaveInvoiceProcessingContext {
  Completed: boolean;
  IgnoreLinkedAppointmentOnDosMismatch?: boolean; // doesn't link appointment to invoice if invoice.DOS and appointment.StartTime occur on different days
}

export enum REVERSAL_STATUS {
  NEW = "New",
  PROCESSED = "Processed",
  REJECTED = "Rejected",
}

export enum CANCEL_STATUS {
  NEW = "New",
  PROCESSED = "Processed",
  ERROR = "Error",
}

export enum INVOICE_TYPE {
  CASH = "Cash",
  MEDICAL_AID = "Medical Aid",
}

export enum INVOICE_SUBTYPE {
  NONE = "None",
  NO_CHARGE = "No Charge",
  DEBIT_NOTE = "Debit Note",
  MEDICAL_INSURER = "Medical Insurer",
}

export enum PLACE_OF_SERVICE {
  CONSULTING_ROOM = "Consulting room",
  DAY_CLINIC_HOSPITAL = "Day clinic / hospital",
  INPATIENT_HOSPITAL = "Inpatient hospital",
  TELEMEDICINE = "Telemedicine",
}

export enum INVOICE_STATUS {
  CANCELLED = "Cancelled",
  DRAFT = "Draft",
  NEW = "New",
  OPEN = "Open",
  CLOSED = "Closed",
}

export enum INVOICE_LINE_TYPE {
  PROCEDURE = "Procedure",
  MODIFIER = "Modifier",
  MEDICINE = "Medicine",
  CONSUMABLE = "Consumable",
  CSTM_PROCEDURE = "cstm_Procedure",
  CSTM_MEDICINE = "cstm_Medicine",
  CSTM_CONSUMABLE = "cstm_Consumable",
  ADMIN = "Admin",
}

export enum MEDICINE_TYPE {
  ACUTE = "Acute",
  CHRONIC = "Chronic",
}

export enum DOSAGE_TYPE {
  AMPULES = "ampules",
  CAPSULES = "capsules",
  DROPS = "drops",
  GRAMS = "g",
  LITRES = "l",
  MICROGRAMS = "mcg",
  MILLIGRAMS = "mg",
  MILLILITRES = "ml",
  OINTMENT = "ointment",
  PACK = "pack",
  PUFFS = "puffs",
  SACHET = "sachet",
  SPRAYS = "sprays",
  TABLETS = "tablets",
  UNITS = "units",
  VIAL = "vial",
  MICRO = "μ",
  BOTTLE = "bottle",
}

export enum PERIOD_TYPE {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export enum INVOICE_SOURCE {
  SYSTEM = "System",
  CLINICAL = "Clinical",
}

export enum INVOICE_PROCESS {
  COPY_AND_CREATE_NEW = 'Copy & create new',
  EDIT_AND_RESUBMIT = 'Edit & resubmit',
}

export const DEFAULT_QUANTITY = 1

export enum INVOICE_SUBTYPE_FRIENDLY_LABEL {
  NONE = 'None',
  DEBIT_NOTE = 'Cash - debit note',
}
