import { AccountDetailsVo, AccountMember, AddressVo, FamCheckRequest, PersonVo } from '@meraki-flux/schema';

export interface PatientVo {
  PatientId?: string | null;
  PatientXRef?: string | null;
  PracticeId?: string | null;
  FileNo?: string | null;
  KnownAs?: string | null;
  Occupation?: string | null;
  Employer?: string | null;
  PreferredLanguage?: string | null;
  MaritalStatus?: string | null;
  PatientDetails?: PersonVo | null;
  PatientAccountDetails?: AccountDetailsVo | null;
  PhysicalAddress?: AddressVo | null;
  PostalAddress?: AddressVo | null;
}

export interface PatientEventVo {
  PatientEventId?: string | null;
  PatientEventXRef?: string | null;
  BranchXRef?: string | null;
  BranchName?: string | null;
  PracticeId?: string | null;
  Patient?: PatientVo | null;
  ScheduledTime?: Date | null;
  /** 0 = Scheduled, 1 = CheckedIn, 2 = InProgress, 3 = Completed, 4 = Canceled, 5 = Deleted */
  Status?: PatientEventVoStatus | null;
  Type?: string | null;
  CompletedTime?: Date | null;
  Completed?: boolean | null;
  Cancelled?: boolean | null;
  CheckedIn?: boolean | null;
  BenefitCheckStatus?: string | null;
  VideoChatLink?: string | null;
  BenefitCheckPdfLink?: string | null;
  AccountBalance?: string | null;
  ProviderNote?: string | null;
  QuoteXRef?: string | null;
}

export interface LinkedPatientDocument {
  DocumentUrl?: string | null;
  Category?: string | null;
}

export enum PatientEventVoStatus {
  _0 = 0,
  _1 = 1,
  _2 = 2,
  _3 = 3,
  _4 = 4,
  _5 = 5,
}

export interface PatientDashboardHistoryEventDto {
  DateTime?: Date | null;
  PatientId?: string | null;
  PathologyReportId?: string | null;
  ParentReferenceId?: string | null;
  ParentDescription?: string | null;
  NotMatched?: boolean | null;
  Type?: string | null;
  ParentLineNum?: number | null;
  Code?: string | null;
  Description?: string | null;
  Value?: string | null;
  ValueUnits?: string | null;
  ValueFlag?: string | null;
  Narrative?: string | null;
  Thumbnail?: string | null;
}

export interface PatientToIndex {
  BillingPracticeNumber: string;
  PatientId: string;
  Patient: AccountMember;
  IndexKey: string;
}

export interface AccountMemberSearchRequestData extends FamCheckRequest {
  SearchText: string
}
