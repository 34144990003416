import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  Subject?: string,
  Body?: string
}

@Component({
  selector: 'meraki-flux-communication-template-preview-dialog',
  templateUrl: './communication-template-preview-dialog.component.html',
  styleUrls: ['./communication-template-preview-dialog.component.scss']
})
export class CommunicationTemplatePreviewDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CommunicationTemplatePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
