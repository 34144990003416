import moment from 'moment';
import {CalendarProvider} from "@meraki-flux/schema";

export class CalendarUtils {
  public static getProviderName(provider: CalendarProvider) {
    let name = '';
    if (provider) {
      if (provider.Title === 'Dr') name += 'Dr ';
      name += provider.Name.substring(0, 1);
      name += ` ${provider.Surname}`;
    }
    return name;
  }

  static fixVisitDateTime(value: Date) {
    return moment(value).utc(); // fixes visit datetime bacsuse it is used and save as UTC value
  }
}
