<div class="flex h-full items-center justify-center">
  <div class="flex-col justify-center">
    <div>
      <span class="material-icons text-[150px]"> lock
      </span>
    </div>
    <h1>Access Denied</h1>
    <div>
      <button class="flux-button"
      mat-flat-button
      color="secondary" (click)="signOut()">Login as different user</button>
    </div>
  </div>

</div>
