import {CALENDAR_EVENT_VIEW, CALENDAR_INTERVAL_SIZE, PROVIDER_SELECTION_TYPE} from "./general.models";

export class User {
  uid?: string;
  Name?: string;
  Surname?: string;
  Username?: string;
  Cellphone?: string;
  Title?: string;
  SelectedBPN?: string;
  SelectedBranch?: string;
  SelectedBusinessId?: string;
  SelectedBusinessType?: string;
  SelectedPracticeName?: string;
  email?: string;
  MPSEmail?: string;
  ProviderId?: string;
  IsActive?: boolean;
  AssignedPractices?: UserPractice[];
  Common?: CommonFeatures;
}

export interface UserPractice {
  BillingPracticeNumber?: string;
  BusinessId?: string;
  BusinessType?: string;
  PracticeName?: string;
  DefaultBranch?: string;
}

export interface UserSettings {
  BottomLineSMS?: boolean;
  ClaimSummary?: boolean;
  ClaimSummaryDaily?: boolean;
  ClaimSummaryMonthly?: boolean;
  ShowTemplates?: boolean;
  OpenInvoicesTab?: boolean;
  LandingTab?: string;
  DefaultUserFilter?: string;
  CalendarSettings?: UserCalendarSettings;
}

export interface UserCalendarSettings {
  CalendarEventView?: CALENDAR_EVENT_VIEW;
  CalendarIntervalSize?: CALENDAR_INTERVAL_SIZE;
  ProviderSelectMode?: PROVIDER_SELECTION_TYPE;
  DefaultProviders?: string[];
}

export interface CommonFeatures {
  IsSystemAdministrator?: boolean;
  IsBusinessOwner?: boolean;
  Roles?: string[];
  SystemAccess?: string[];
  FileAccess?: string[];
  ReportAccess?: string[];
  ReportFavourites?: string[];
  AssignedBranches?: AssignedBranch[];
  Settings?: UserSettings;
}

export interface AssignedBranch {
  BranchName: string;
  IsDefault: boolean;
  IsDispensing?: boolean;
  DispensingLicenceNo?: string;
}

export class UserActionContext {
  user: string;
  time: Date;
  userFullName?: string;
}

/**
 * An interface representing the JWT Token used by the authentication
 */
export interface AuthenticatedUser {
  role?: string[];
  full_name: string;
  user_id: string;
  iss: string;
  aud: string;
  exp: number;
  nbf: number;
  claims: { role: string[] };
}
