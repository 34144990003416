import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { INFO_BOX_TYPE } from '../components/info-box/info-box.component';

@Directive({
  selector: '[emphasizeContainer]'
})
export class EmphasizeContainerDirective implements OnChanges {

  baseClassName = 'flux-emphasized-container';
  fillClassName = `${this.baseClassName}-filled`;

  currentClass = '';

  @Input() emphasizeContainer: INFO_BOX_TYPE = 'DEFAULT';
  @Input() emphasizeBackground = false;

  constructor(private elementRef: ElementRef) {
    this.setMainClass(elementRef?.nativeElement);
    this.setBackgroundFill(elementRef?.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.setMainClass(this.elementRef?.nativeElement);
      this.setBackgroundFill(this.elementRef?.nativeElement);
    }
  }

  private setMainClass(element: HTMLElement) {
    if (element) {
      if (this.currentClass) {
        element.classList.remove(this.currentClass);
      }
      let typeClassName = this.getClassFromType(this.emphasizeContainer);
      typeClassName = typeClassName ? `-${typeClassName}` : typeClassName;

      this.currentClass = `${this.baseClassName}${typeClassName}`;

      element.classList.add(this.currentClass);
    }
  }

  private setBackgroundFill(element: HTMLElement) {
    if (element) {
      element.classList.remove(this.fillClassName);
      if (this.emphasizeBackground) {
        element.classList.add(this.fillClassName);
      }
    }
  }

  private getClassFromType(type: INFO_BOX_TYPE): string {
    switch (type) {
      case 'DANGER':
        return 'danger';
      case 'INFO':
        return 'info';
      case 'SUCCESS':
        return 'success';
      case 'WARNING':
        return 'warning';
      case 'PRIMARY':
        return 'primary';
      case 'HELP':
        return 'help';
      case 'DEFAULT':
      default:
        return '';
    }
  }

}
