import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DOSAGE_TYPE, MEDICINE_TYPE, PERIOD_TYPE} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-additional-medicine-details-modal',
  templateUrl: './additional-medicine-details-modal.component.html',
  styleUrls: ['./additional-medicine-details-modal.component.scss'],
})
export class AdditionalMedicineDetailsModalComponent implements OnInit {
  medicineLabel = `${this.data.NappiCode} - ${this.data.Description}`;
  packSize = this.data.PackSize;
  medicineTypes = MEDICINE_TYPE;

  dosageTypes = DOSAGE_TYPE;

  periodTypes = Object.values(PERIOD_TYPE);

  NUMBER_PATTERN = /^[0-9]{1,}([,\.]{0,1}[0-9]{1,2}){0,1}$/;

  form: UntypedFormGroup = this.fb.group({
    MedicineType: 'Acute',
    DosageUnit: ['0', Validators.pattern(this.NUMBER_PATTERN)],
    DosageType: '',
    FrequencyUnit: ['0', Validators.pattern(this.NUMBER_PATTERN)],
    PeriodUnit: ['0', Validators.pattern(this.NUMBER_PATTERN)],
    PeriodType: '',
    DurationUnit: ['0', Validators.pattern(this.NUMBER_PATTERN)],
    DurationType: '',
    Repeats: ['0', Validators.pattern(this.NUMBER_PATTERN)],
  });

  constructor(
    private dialogRef: MatDialogRef<AdditionalMedicineDetailsModalComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.form.patchValue(this.data);
  }

  onSave() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.dialogRef.close({ data: this.form.getRawValue() });
  }
}
