import {toUpper} from "lodash";
import {
  AppointmentReportModel,
  BasePdfReportData, CellType, PDFCellData,
  PDFParameter, ProviderData,
  ReportHeader,
  ReportTable
} from '@meraki-flux/schema';
import { FormatUtils } from '../../utils/format-utils';

export class AppointmentPdfReportDataBuilder {
  private static REPORT_SPECIAL_FONT_COLOR = "#000000";

  build(report: AppointmentReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.reportName = report.ReportInfo.ReportName;
    reportData.reportNameColor = AppointmentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    reportData.bpn = report.ReportInfo.PracticeId
    reportData.orientation = "landscape";
    reportData.headers = this.buildHeader(report);
    reportData.tables = this.buildTables(report);
    reportData.dateHeader = `Date generated ${report.ReportDate}`;
    reportData.footerText = "\t\u200B".repeat(60)
    reportData.mainHeader = report.ReportInfo.ReportName;
    reportData.footer = {
      hbLogo: true
    }
    return reportData;
  }

  private buildHeader(appointmentReportModel: AppointmentReportModel): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: {value: appointmentReportModel.ReportInfo.Practice, decoration: {bold: true}}
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: {value: appointmentReportModel.ReportInfo.PracticeId, decoration: {bold: true}}
    });
    const rightParameters: PDFParameter[] = [];
    if (appointmentReportModel.ReportInfo.IsMultiBranch)
      rightParameters.push({
        name: 'Branch',
        data: {value: appointmentReportModel.ReportInfo.Branch, decoration: {bold: true}}
      });
    rightParameters.push(
      {name: 'Date Range', data: {value: appointmentReportModel.ReportInfo.DateRange, decoration: {bold: true}}});
    rightParameters.push({
      name: 'Treating provider',
      data: {value: appointmentReportModel.ReportInfo.TreatingProvider, decoration: {bold: true}}
    });
    rightParameters.push({
      name: 'Patient',
      data: {value: appointmentReportModel.ReportInfo.PatientName, decoration: {bold: true}}
    });
    return [{
      left: leftParameters,
      right: rightParameters
    }];
  }

  private buildTables(appointmentReportModel: AppointmentReportModel): ReportTable[] {
    const reportTables: ReportTable[] = [];
    reportTables.push(this.buildExistingVsNewTable(appointmentReportModel));

    if (appointmentReportModel.ReportInfo.IncludeInvoiceData)
      reportTables.push(this.buildInvoiceSummaryPerProviderTable(appointmentReportModel));

    reportTables.push(this.buildVisitTypeSummaryTable(appointmentReportModel));


    appointmentReportModel.ProviderData.forEach((providerData) => {
      reportTables.push(this.buildDetailsTable(providerData, appointmentReportModel.ReportInfo.IsMultiBranch, appointmentReportModel.ReportInfo.IncludeInvoiceData));
    });
    return reportTables;
  }

  private buildExistingVsNewTable(report: AppointmentReportModel): ReportTable {
    const table: ReportTable = {};
    table.borders = {
      hor: true,
      headerBorderSize: 1
    };
    table.name = 'SUMMARY';
    table.nameColor = AppointmentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = AppointmentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.headers = [{
      rows: [[{name: '', data: {value: 'Existing vs. new patients', decoration: {bold: true, italics: true}}}]],
      backgroundColor: '#ececec'
    }];
    table.rowHeadersBold = true;

    table.rowHeaders = [
      {value: ''},
      {value: 'Existing patients', decoration: {bold: true}, type: CellType.NUMBER, width: 100},
      {value: 'New patients', decoration: {bold: true}, type: CellType.NUMBER, width: 100}];
    if (report.ReportInfo.IncludeRecomedData)
      table.rowHeaders.push({value: 'Recomed online bookings', decoration: {bold: true}});

    const rows = [];
    report.ProviderData.forEach(provider => {
      const row: PDFCellData[] = [];
      row.push({value: provider.ProviderName});
      row.push({value: provider.ExistingPatientCount, type: CellType.NUMBER});
      row.push({value: provider.NewPatientCount, type: CellType.NUMBER});
      if (report.ReportInfo.IncludeRecomedData)
        row.push({value: provider.RecomedCount, type: CellType.NUMBER});
      rows.push(row);
    });
    const totalRow: PDFCellData[] = [{value: 'Total', decoration: {bold: true}},
      {value: report.TotalData.ExistingPatientCount, decoration: {bold: true}, type: CellType.NUMBER},
      {value: report.TotalData.NewPatientCount, decoration: {bold: true}, type: CellType.NUMBER}];
    if (report.ReportInfo.IncludeRecomedData)
      totalRow.push({value: report.TotalData.RecomedCount, decoration: {bold: true}, type: CellType.NUMBER});

    rows.push(totalRow);
    table.rows = rows;
    return table;
  }

  private buildVisitTypeSummaryTable(report: AppointmentReportModel): ReportTable {
    const table: ReportTable = {};
    table.headers = [{
      rows: [[{name: '', data: {value: 'Visit type summary', decoration: {bold: true, italics: true}}}]],
      backgroundColor: '#ececec'
    }];
    table.borders = {
      hor: true,
      headerBorderSize: 1
    };
    table.rowHeadersBold = true;

    table.rowHeaders = [
      {value: 'Visit type', decoration: {bold: true}},
      {value: '#', decoration: {bold: true}, type: CellType.NUMBER, width: 80}];

    const rows: PDFCellData[][] = [];
    const visitTypes = Array.from(report.VisitTypeData.CountPerVisitType.keys()).sort();

    visitTypes.forEach(visitType => {
      const row: PDFCellData[] = [];
      row.push({value: visitType});
      row.push({value: report.VisitTypeData.CountPerVisitType.get(visitType), type: CellType.NUMBER});
      rows.push(row);
    });
    rows.push([
      {value: 'Total', decoration: {bold: true}},
      {value: report.VisitTypeData.TotalVisitCount, decoration: {bold: true}, type: CellType.NUMBER}]);
    table.rows = rows;
    return table;
  }

  private buildInvoiceSummaryPerProviderTable(report: AppointmentReportModel): ReportTable {
    const table: ReportTable = {};
    table.headers = [{
      rows: [[{name: '', data: {value: 'Invoice summary per provider', decoration: {bold: true, italics: true}}}]],
      backgroundColor: '#ececec'
    }];
    table.borders = {
      hor: true,
      headerBorderSize: 1
    };
    table.rowHeadersBold = true;

    table.rowHeaders = [
      {value: ''},
      {value: '# Appts not invoiced', decoration: {bold: true}, type: CellType.NUMBER, width: 100},
      {value: '# Appts invoiced', decoration: {bold: true}, type: CellType.NUMBER, width: 100},
      {value: 'Total invoiced', decoration: {bold: true}, type: CellType.NUMBER, width: 100}];

    const rows = [];
    report.ProviderData.forEach(provider => {
      const row: PDFCellData[] = [];
      row.push({value: provider.ProviderName});
      row.push({value: provider.NotInvoicedCount, type: CellType.NUMBER});
      row.push({value: provider.InvoicedCount, type: CellType.NUMBER});
      row.push({value: provider.TotalInvoicedAmount, type: CellType.CURRENCY});
      rows.push(row);
    });

    const totalRow: PDFCellData[] = [{value: 'Total', decoration: {bold: true}},
      {value: report.TotalData.NotInvoicedCount, decoration: {bold: true}, type: CellType.NUMBER},
      {value: report.TotalData.InvoicedCount, decoration: {bold: true}, type: CellType.NUMBER},
      {value: report.TotalData.TotalInvoicedAmount, decoration: {bold: true}, type: CellType.CURRENCY}];
    rows.push(totalRow);

    table.rows = rows;

    return table;
  }

  private buildDetailsTable(providerModel: ProviderData, addBranchColumn?: boolean, addInvoiceColumn?: boolean): ReportTable {
    const table: ReportTable = {};
    table.rowHeadersBold = true;table.name = toUpper(providerModel.ProviderName);
    table.nameColor = AppointmentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = AppointmentPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.borders = {
      hor: true,
      headerBorderSize: 1
    };
    table.rowHeaders = [
      {value: 'Date'},
      {value: 'Time', width: 45},
      {value: 'Appt status', width: 55},
      {value: 'Visit type'},
      {value: 'File no.', width: 45},
      {value: 'Patient', width: '*'},
      {value: 'Scheme', width: 60},
      {value: 'Member no.'},
      {value: 'BC (Y/N)', width: 30},
      {value: 'BC status', width: 60}];
    if (addBranchColumn) {
      table.rowHeaders.push({value: 'Branch', width: 50})
    }
    if (addInvoiceColumn) {
      table.rowHeaders.push({value: 'Invoice amt.', width: 50})
    }

    const rows = [];
    providerModel.ProviderAppointments.forEach(event => {
      const row: PDFCellData[] = [
        {value: event.Date},
        {value: event.Time},
        {value: event.Status},
        {value: event.VisitType},
        {value: event.FileNo},
        {value: event.Patient},
        {value: event.Scheme},
        {value: FormatUtils.memberNoDepCode(event.MemberNo, event.DependantCode)},
        {value: event.BC},
        {value: event.BCStatus}
      ];
      if (addBranchColumn) {
        row.push({value: event.Branch});
      }
      if (addInvoiceColumn) {
        row.push({value: event.InvoiceAmount, type: CellType.CURRENCY});
      }
      rows.push(row);
    })
    table.rows = rows;
    if (!rows || rows.length === 0) {
      table.rows = undefined;
      table.rowHeaders = undefined;
      table.noRecordsText = {
        value: "No records",
        decoration: {
          italics: true
        }
      }
    }
    return table;
  }
}
