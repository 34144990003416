<div class="carousel">
  <button mat-icon-button class="carousel-control pre"
          (click)="prev()"
          (keydown.ArrowLeft)="prev()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="carousel-container"
       [style.height.px]="height">
    <div *ngFor="let option of options" class="carousel-item" [class.active]="this.options[this.selectedOptionIndex].label === option.label">
      <h4>{{option.label}}</h4>
      <img #imgRef
           *ngIf="option.base64Image"
           [src]="option.base64Image"
           alt="option.label">
    </div>
  </div>
  <button mat-icon-button class="carousel-control next"
          (click)="next()"
          (keydown.ArrowRight)="next()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
