import {
  AgeAnalysisPatientLiableReportModel,
  BaseExcelReportData,
  ExcelCellData,
  CellType,
  CellWidth,
  PatientLiableAccountDetails,
  PatientLiableInvoiceDetails,
  PatientLiableSummary,
  Sheet,
  Table
} from "@meraki-flux/schema";
import { AbstractAgeAnalysisReportDataBuilder } from './abstract-age-analysis-report-data-builder';

export class AgeAnalysisPatientLiableXlsReportDataBuilder extends AbstractAgeAnalysisReportDataBuilder {

  private readonly REPORT_NAME = `Age Analysis - Patient Liable Amounts`;

  build(report: AgeAnalysisPatientLiableReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    this.buildReportInfo(excelReportData, report.reportInfo);
    excelReportData.practiceId = report.reportInfo.PracticeId;
    excelReportData.name = this.REPORT_NAME;
    excelReportData.data = [];
    excelReportData.data.push(this.buildSummarySheet(report.summary))
    excelReportData.data.push(this.buildAccountDetailsSheet(report.accountDetails,
      report.reportInfo.IsMultiBranch, report.reportInfo.IncludeAccountNotes))
    excelReportData.data.push(this.buildInvoiceDetailsSheet(report.invoiceDetails,
      report.reportInfo.IsMultiBranch))
    return excelReportData;
  }

  private buildSummarySheet(summary: PatientLiableSummary): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    sheet.header = this.REPORT_NAME
    sheet.tables = [];

    sheet.tables.push(this.buildSummaryTable('Summary per treating provider', 'Treating provider', summary.providerData));
    sheet.tables.push(this.buildSummaryTable('Summary per invoice type', 'Invoice type', summary.invoiceTypeData));

    return sheet;
  }

  private buildAccountDetailsSheet(accountDetails: PatientLiableAccountDetails[], addBranchColumn: boolean,
                                   includeAccountNotes: boolean): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Account Detail';
    sheet.header = this.REPORT_NAME;
    sheet.tables = [];

    const table: Table = {};
    table.headers = [
      {value: 'Account no.', bold: true, width: CellWidth.XS},
      {value: 'Account holder name', bold: true, width: CellWidth.M},
      {value: 'Cell no.', bold: true, width: CellWidth.XS},
      {value: 'Scheme', bold: true, width: CellWidth.S},
      {value: 'Member no.', bold: true, width: CellWidth.XS},
      {value: 'Total', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Unallocated', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '0 - 30', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '30 - 60', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '60 - 90', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '90 - 120', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '120+', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Account status', bold: true, width: CellWidth.XS},
      {value: 'Reason', bold: true, width: CellWidth.S}];

    if (includeAccountNotes) {
      table.headers.push({value: 'Account notes', bold: true, width: CellWidth.L})
    }
    if (addBranchColumn) {
      table.headers.push({value: 'Branch', bold: true})
    }

    const rows = [];
    accountDetails.sort((a1, a2) => (a1.accountNo??'').localeCompare(a2.accountNo)).forEach(accountDetails => {
      const row: ExcelCellData[] = [
        {value: accountDetails.accountNo, type: CellType.GENERAL},
        {value: accountDetails.accountHolderName},
        {value: accountDetails.cellphoneNo, type: CellType.GENERAL},
        {value: accountDetails.scheme},
        {value: accountDetails.memberNo, type: CellType.GENERAL},
        {value: accountDetails.total, type: CellType.CURRENCY},
        {value: accountDetails.unallocatedAmount, type: CellType.CURRENCY},
        {value: accountDetails.age.age30, type: CellType.CURRENCY},
        {value: accountDetails.age.age30_60, type: CellType.CURRENCY},
        {value: accountDetails.age.age60_90, type: CellType.CURRENCY},
        {value: accountDetails.age.age90_120, type: CellType.CURRENCY},
        {value: accountDetails.age.age120, type: CellType.CURRENCY},
        {value: accountDetails.accountStatus},
        {value: accountDetails.reason}
      ];
      if (includeAccountNotes) {
        row.push({value: accountDetails.accountNotes, wordWrap: true});
      }
      if (addBranchColumn) {
        row.push({value: accountDetails.branch});
      }
      rows.push(row);
    })
    table.rows = rows;

    sheet.tables.push(table);
    return sheet;
  }

  private buildInvoiceDetailsSheet(invoiceDetails: PatientLiableInvoiceDetails[], addBranchColumn?: boolean): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Invoice Detail';
    sheet.tables = [];

    const table: Table = {};
    table.headers = [
      {value: 'Age', bold: true, width: CellWidth.XS},
      {value: 'Treating provider', bold: true, width: CellWidth.M},
      {value: 'Scheme', bold: true, width: CellWidth.S},
      {value: 'Member no.', bold: true, width: CellWidth.XS},
      {value: 'Date of service', bold: true, width: CellWidth.XS},
      {value: 'Invoice date', bold: true, width: CellWidth.XS},
      {value: 'Invoice type', bold: true, width: CellWidth.XS},
      {value: 'Invoice no.', bold: true, width: CellWidth.XS},
      {value: 'Account no.', bold: true, width: CellWidth.XS},
      {value: 'Patient name', bold: true, width: CellWidth.M},
      {value: 'Claim status', bold: true, width: CellWidth.S},
      {value: 'HB message ID', bold: true, width: CellWidth.S},
      {value: 'Invoice total', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Med aid liable', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Patient liable', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'BC (Y/N)', bold: true, width: CellWidth.XS},
      {value: 'BC status', bold: true, width: CellWidth.S},
      {value: 'Electronic remittances enabled?', bold: true, width: CellWidth.XS},
      {value: 'Location', bold: true, width: CellWidth.XS}];

    if (addBranchColumn) {
      table.headers.splice(1, 0, {value: 'Branch', bold: true, width: CellWidth.XS})
    }

    const rows = [];
    invoiceDetails.sort((a1, a2) => (a1.invoiceNo??'').localeCompare(a2.invoiceNo)).forEach(invoiceDetails => {
      const row: ExcelCellData[] = [
        {value: invoiceDetails.age.getFriendlyName(), type: CellType.GENERAL},
        {value: invoiceDetails.treatingProvider},
        {value: invoiceDetails.scheme},
        {value: invoiceDetails.memberNo, type: CellType.GENERAL},
        {value: invoiceDetails.dateOfService, type: CellType.DATE},
        {value: invoiceDetails.invoiceDate, type: CellType.DATE},
        {value: invoiceDetails.invoiceType},
        {value: invoiceDetails.invoiceNo},
        {value: invoiceDetails.accountNo, type: CellType.GENERAL},
        {value: invoiceDetails.patientName},
        {value: invoiceDetails.claimStatus},
        {value: invoiceDetails.hbMessageId},
        {value: invoiceDetails.invoicedAmount, type: CellType.CURRENCY},
        {value: invoiceDetails.medicalAidLiable, type: CellType.CURRENCY},
        {value: invoiceDetails.patientLiable, type: CellType.CURRENCY},
        {value: invoiceDetails.bc},
        {value: invoiceDetails.bcStatus},
        {value: invoiceDetails.eRAEnabled},
        {value: invoiceDetails.location},
      ];
      if (addBranchColumn) {
       row.splice(1, 0, {value: invoiceDetails.branch});
      }
      rows.push(row);
    })
    table.rows = rows;

    sheet.tables.push(table);
    return sheet;
  }
}
