export interface ClaimSummarySubscriptionReportRequest {
    PracticeId: string;
    PracticeName: string;
    DateFrom: Date;
    DateTo: Date;
    DateRangePeriod: 'Daily' | 'Monthly';
    RecipientEmail: string;
}

export interface ClaimSummarySubscriptionReportModel {
    // Invoices
    CashInvoiceSummaryItem : SummaryItem;
    NoChargeInvoiceSummaryItem : SummaryItem;
    DebitNoteInvoiceSummaryItem : SummaryItem;
    ElectronicMAInvoiceSummaryItem : SummaryItem;
    UnRoutableInvoiceSummaryItem : SummaryItem;
    InsuranceInvoiceSummaryItem : SummaryItem;
    TotalCashInvoiceSummaryItem : SummaryItem;
    TotalMAInvoiceSummaryItem : SummaryItem;
    TotalInvoiceSummaryItem : SummaryItem;
    // Claims inbox items
    TotalClaimsToBeFixed: SummaryItem;
    FixAndResubmitInvoiceSummaryItem: SummaryItem;
    ViewRejectedLinesInvoiceSummaryItem: SummaryItem;
    // Other inbox items
    TotalCollectOutstandingBalanceCount: number;
    CompleteSavedProviderInvoiceActionCount: number;
    CompleteSavedInvoiceActionCount: number;
    ApproveInvoiceActionCount: number;
    TotalIncompleteInvoiceInboxCount: number;

    ReportInfo: ClaimSummarySubscriptionReportInfo;
    ReportDate?: string;
}

export interface ClaimSummarySubscriptionReportInfo {
    DateRangePeriod?: string;
    PracticeId?: string;
    Practice?: string;
}

export class SummaryItem {
    TotalCount?: number = 0;
    InvoicedAmount?: number = 0;
    MedicalAidLiable?: number = 0;
    PatientLiable?: number = 0;
}