import {Injectable} from "@angular/core";
import {collection, Firestore, getDocs, query, where} from "@angular/fire/firestore";
import {QueryConstraint} from "@firebase/firestore";
import {
  DateUtils,
  InvoiceService,
  InvoiceWhereBuilder,
  PathUtils,
  TransactionUtils,
} from "@meraki-flux/common";
import * as moment from "moment";
import {first} from "rxjs/operators";
import {ReportUtils} from "../report-utils";
import {getFunctions, httpsCallable} from "@angular/fire/functions";
import {getApp} from "@angular/fire/app";
import _ from "lodash";
import {
  DATE_RANGE_TYPE_TRN_REPORT,
  Invoice,
  INVOICE_LINE_TYPE,
  INVOICE_SUBTYPE, MAPayment,
  MonthlyTransactionReportDetails,
  MonthlyTransactionReportInfo,
  MonthlyTransactionReportModel,
  MonthlyTransactionReportRequest,
  NoDataError,
  Payment, PAYMENT_STATUS,
  PAYMENT_TYPE,
  RemittanceAdvice,
  TaxableAmount,
  Transaction,
  TRANSACTION_TYPE, WRITE_OFF_TYPE
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class MonthlyTransactionReportBuilder {

  constructor(
    private firestore: Firestore,
    private invoiceService: InvoiceService
  ) {}

  async build(reportRequest: MonthlyTransactionReportRequest): Promise<MonthlyTransactionReportModel> {
    const reportHeader: MonthlyTransactionReportInfo = await this.buildReportInfo(reportRequest);

    const details = await this.buildMonthlyTransactionReportDetailsRemote(reportRequest);
    return {
      ReportInfo: reportHeader,
      Details: details
      // Details: await this.buildMonthlyTransactionReportDetails(reportRequest, true)
    };
  }

  private async buildMonthlyTransactionReportDetailsRemote(reportRequest: MonthlyTransactionReportRequest) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const prepareReportData = httpsCallable(functions, 'pra-rpt-v1-oncall-prepareMonthlyTransactionsReportData', { timeout: 540000});
    const result = await prepareReportData(reportRequest) as any;
    if (result.data?.success) {
      const details =  result.data?.data as MonthlyTransactionReportDetails;
      return {
        TotalAdminAmount: new TaxableAmount(details.TotalAdminAmount?.amount, details.TotalAdminAmount?.vatAmount, details.TotalAdminAmount?.amountExVat),
        TotalProceduresAmount: new TaxableAmount(details.TotalProceduresAmount?.amount, details.TotalProceduresAmount?.vatAmount, details.TotalProceduresAmount?.amountExVat),
        TotalConsumablesAmount: new TaxableAmount(details.TotalConsumablesAmount?.amount, details.TotalConsumablesAmount?.vatAmount, details.TotalConsumablesAmount?.amountExVat),
        TotalMedicinesAmount: new TaxableAmount(details.TotalMedicinesAmount?.amount, details.TotalMedicinesAmount?.vatAmount, details.TotalMedicinesAmount?.amountExVat),
        TotalModifierAmount: new TaxableAmount(details.TotalModifierAmount?.amount, details.TotalModifierAmount?.vatAmount, details.TotalModifierAmount?.amountExVat),
        TotalDebitNoteAmount: new TaxableAmount(details.TotalDebitNoteAmount?.amount, details.TotalDebitNoteAmount?.vatAmount, details.TotalDebitNoteAmount?.amountExVat),
        TotalPaymentCorrectionAmount: details.TotalPaymentCorrectionAmount,
        TotalRefundAmount: details.TotalRefundAmount,
        TotalMedicalAidPaymentAmount: details.TotalMedicalAidPaymentAmount,
        TotalPatientPaymentAmount: details.TotalPatientPaymentAmount,
        TotalSmallBalanceWriteOffAmount: new TaxableAmount(details.TotalSmallBalanceWriteOffAmount?.amount, details.TotalSmallBalanceWriteOffAmount?.vatAmount, details.TotalSmallBalanceWriteOffAmount?.amountExVat),
        TotalBadDebtWriteOffAmount: new TaxableAmount(details.TotalBadDebtWriteOffAmount?.amount, details.TotalBadDebtWriteOffAmount?.vatAmount, details.TotalBadDebtWriteOffAmount?.amountExVat),
        TotalCreditNotesAmount: new TaxableAmount(details.TotalCreditNotesAmount?.amount, details.TotalCreditNotesAmount?.vatAmount, details.TotalCreditNotesAmount?.amountExVat),
        OpeningDate: reportRequest.DateFrom,
        ClosingDate: reportRequest.DateTo,
        IsVatPractice: reportRequest.Practice?.IsVATRegistered
      };
    } else {
      if (result.data.data.reason == 'NoDataError') {
        throw new NoDataError();
      } else {
        throw result.data.error as Error;
      }
    }
  }

  private async buildMonthlyTransactionReportDetails(reportRequest: MonthlyTransactionReportRequest, throwNoDataError: boolean) {
    const accountIdRefs = await getDocs(query(collection(this.firestore, PathUtils.accountCollectionPath(reportRequest.Practice?.Id))));
    const accountIds: string[] = accountIdRefs.docs.map(i => i.id);
    const payments: Payment[] = await this.getPayments(reportRequest, accountIds);
    const remittances: RemittanceAdvice[] = await this.getRemittances(reportRequest);
    const invoices: Invoice[] = await this.getInvoices(reportRequest);
    const transactions: Transaction[] = await this.getTransactions(reportRequest, accountIds);

    const totalAdminAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    const totalProceduresAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    const totalConsumablesAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    const totalMedicinesAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    const totalModifierAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    const totalDebitNoteAmount: TaxableAmount = new TaxableAmount(0, 0, 0);
    let totalPaymentCorrectionAmount = 0;
    let totalRefundAmount = 0;

    let totalMedicalAidPaymentAmount = remittances.reduce((sum, ra) => sum + +ra?.Payment?.PaidAmount || 0, 0)
    let totalPatientPaymentAmount = 0;
    const totalSmallBalanceWriteOffAmount = new TaxableAmount(0, 0, 0);
    const totalBadDebtWriteOffAmount = new TaxableAmount(0, 0, 0);
    const totalCreditNotesAmount = new TaxableAmount(0, 0, 0);

    for (const invoice of invoices) {
      if (invoice.Subtype === INVOICE_SUBTYPE.DEBIT_NOTE) {
        totalDebitNoteAmount.addAmountWithVAT(invoice.AmountBilled, invoice.AmountVAT);
      } else {
        for (const invoiceLine of _.sortBy(invoice.Lines, 'LineNumber')) {
          if (invoiceLine.LineType === INVOICE_LINE_TYPE.ADMIN) {
            totalAdminAmount.addAmountWithVAT(invoiceLine.AmountBilled, invoiceLine.AmountVAT);
          } else if (invoiceLine.LineType === INVOICE_LINE_TYPE.MEDICINE || invoiceLine.LineType === INVOICE_LINE_TYPE.CSTM_MEDICINE) {
            totalMedicinesAmount.addAmountWithVAT(invoiceLine.AmountBilled, invoiceLine.AmountVAT);
          } else if (invoiceLine.LineType === INVOICE_LINE_TYPE.CONSUMABLE || invoiceLine.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE) {
            totalConsumablesAmount.addAmountWithVAT(invoiceLine.AmountBilled, invoiceLine.AmountVAT);
          } else if (invoiceLine.LineType === INVOICE_LINE_TYPE.PROCEDURE || invoiceLine.LineType === INVOICE_LINE_TYPE.CSTM_PROCEDURE) {
            totalProceduresAmount.addAmountWithVAT(invoiceLine.AmountBilled, invoiceLine.AmountVAT);
          } else if (invoiceLine.LineType === INVOICE_LINE_TYPE.MODIFIER) {
            totalModifierAmount.addAmountWithVAT(invoiceLine.AmountBilled, invoiceLine.AmountVAT);
          }
        }
      }
    }

    for (const payment of payments) {
      if (payment?.AmountRefunded) {
        totalRefundAmount += Number(payment.AmountRefunded);
      }
      if (PAYMENT_TYPE.MEDICAL_AID === payment.Type) {
        totalMedicalAidPaymentAmount += payment.AmountPaid || 0;
      } else {
        totalPatientPaymentAmount += payment.AmountPaid || 0;
      }
    }

    for (const transaction of transactions) {
      if (transaction.TransactionType === TRANSACTION_TYPE.WRITE_OFF && transaction.Metadata?.Note === WRITE_OFF_TYPE.SMALL_BALANCE) {
        totalSmallBalanceWriteOffAmount.addAmountWithVAT(transaction.Metadata.HeaderAmount, transaction.Metadata.HeaderAmountVAT);
      } else if (transaction.TransactionType === TRANSACTION_TYPE.WRITE_OFF && transaction.Metadata?.Note === WRITE_OFF_TYPE.BAD_DEBT) {
        totalBadDebtWriteOffAmount.addAmountWithVAT(transaction.Metadata.HeaderAmount, transaction.Metadata.HeaderAmountVAT);
      } else if (transaction.TransactionType === TRANSACTION_TYPE.CREDIT) {
        totalCreditNotesAmount.addAmountWithVAT(transaction.Metadata.HeaderAmount, transaction.Metadata.HeaderAmountVAT);
      } else if (transaction.TransactionType === TRANSACTION_TYPE.PAYMENT_CORRECTION) {
        totalPaymentCorrectionAmount += Number(transaction.Metadata?.HeaderAmount) || 0;
      }
    }

    let totalOpeningBalance = 0;
    if (reportRequest.DateFrom) {
      const monthlyTxReportOnDateFrom = await this.buildMonthlyTransactionReportDetails({
        ...reportRequest,
        DateFrom: null,
        DateTo: DateUtils.addMills(reportRequest.DateFrom, -1)
      }, false);
      totalOpeningBalance = monthlyTxReportOnDateFrom.ClosingBalance;
    }

    const totalDebitAmount = totalAdminAmount.amount + totalProceduresAmount.amount + totalMedicinesAmount.amount +
      totalConsumablesAmount.amount + totalModifierAmount.amount + totalDebitNoteAmount.amount + totalPaymentCorrectionAmount;

    const totalCreditAmount = totalMedicalAidPaymentAmount + totalPatientPaymentAmount + totalBadDebtWriteOffAmount.amount +
      totalSmallBalanceWriteOffAmount.amount + totalCreditNotesAmount.amount;

    const totalClosingBalance = totalOpeningBalance + (totalDebitAmount - totalCreditAmount);
    if (throwNoDataError && totalOpeningBalance === 0 && totalClosingBalance === 0 && totalDebitAmount === 0 && totalCreditAmount === 0) {
      throw new NoDataError();
    }

     return {
        TotalAdminAmount: totalAdminAmount,
        TotalProceduresAmount: totalProceduresAmount,
        TotalConsumablesAmount: totalConsumablesAmount,
        TotalMedicinesAmount: totalMedicinesAmount,
        TotalModifierAmount: totalModifierAmount,
        TotalDebitNoteAmount: totalDebitNoteAmount,
        TotalPaymentCorrectionAmount: totalPaymentCorrectionAmount,
        TotalRefundAmount: totalRefundAmount,
        TotalMedicalAidPaymentAmount: totalMedicalAidPaymentAmount,
        TotalPatientPaymentAmount: totalPatientPaymentAmount,
        TotalSmallBalanceWriteOffAmount: totalSmallBalanceWriteOffAmount,
        TotalBadDebtWriteOffAmount: totalBadDebtWriteOffAmount,
        TotalCreditNotesAmount: totalCreditNotesAmount,
        OpeningBalance: totalOpeningBalance,
        OpeningDate: reportRequest.DateFrom,
        ClosingBalance: totalClosingBalance,
        ClosingDate: reportRequest.DateTo,
        IsVatPractice: reportRequest.Practice?.IsVATRegistered
    };
  }

  private async getPayments(reportRequest: MonthlyTransactionReportRequest, accountIds: string[]): Promise<Payment[]> {
    const practiceId = reportRequest.Practice?.Id;
    const whereConditions: QueryConstraint[] = [];
    if (reportRequest.DateFrom) whereConditions.push(where('PaymentDate', '>=', reportRequest.DateFrom));
    if (reportRequest.DateTo) whereConditions.push(where('PaymentDate', '<=', reportRequest.DateTo));

    const paymentDocs = await Promise.all(
      accountIds.map(async (accountId) => {
        return getDocs(query(collection(this.firestore, PathUtils.paymentCollectionPath(practiceId, accountId)), ...whereConditions));
      })
    );
    let payments: Payment[] = [];
    paymentDocs.forEach(i => {
      const accountPayments: Payment[] = i.docs.map(i => ({Id: i.id, ...i.data()} as Payment))
        .filter(p => p.Status || p.Status === PAYMENT_STATUS.VALID) // valid payments
        .filter((p: MAPayment) => !p.RemittanceAdviceId); // not linked to RA, we count RAs separately
      if (accountPayments.length > 0) {
        payments = payments.concat(accountPayments);
      }
    });
    return payments;
  }

  private async getRemittances(reportRequest: MonthlyTransactionReportRequest): Promise<RemittanceAdvice[]> {
    const practiceId = reportRequest.Practice?.Id;
    const whereConditions: QueryConstraint[] = [];
    if (reportRequest.DateFrom) whereConditions.push(where('Payment.PayDate', '>=', reportRequest.DateFrom));
    if (reportRequest.DateTo) whereConditions.push(where('Payment.PayDate', '<=', reportRequest.DateTo));
    const raDocs = await getDocs(query(collection(this.firestore, PathUtils.remittanceAdviceCollection(practiceId)), ...whereConditions));
    return raDocs.docs.map(doc => ({...doc.data(), Id: doc.id} as RemittanceAdvice));
  }

  private async getTransactions(
    reportRequest: MonthlyTransactionReportRequest,
    accountIds: string[]
  ): Promise<Transaction[]> {
    const practiceId = reportRequest.Practice?.Id;
    const whereConditions: QueryConstraint[] = [];
    if (reportRequest.DateFrom) whereConditions.push(where('CreatedAt', '>=', reportRequest.DateFrom));
    if (reportRequest.DateTo) whereConditions.push(where('CreatedAt', '<=', reportRequest.DateTo));
    const accountsTransactions = await Promise.all(
      accountIds.map(async (accountId) => {
        const coll = await getDocs(
          query(
            collection(this.firestore, PathUtils.transactionCollectionPath(practiceId, accountId)),
            ...whereConditions
          )
        );
        return coll.docs?.map((i) => ({ Id: i.id, ...i.data() } as Transaction));
      })
    );
    return TransactionUtils.getActiveTransactions([].concat(...accountsTransactions));
  }

  private async getInvoices(reportRequest: MonthlyTransactionReportRequest) {
    const invoiceWhereBuilder: InvoiceWhereBuilder = InvoiceWhereBuilder.builder();

    const dateFrom = moment(reportRequest.DateFrom ? reportRequest.DateFrom : new Date(0)).startOf('day').toDate();
    const dateTo = moment(reportRequest.DateTo ? reportRequest.DateTo : new Date()).endOf('day').toDate();
    if (reportRequest.DateRangeType === DATE_RANGE_TYPE_TRN_REPORT.DATE_OF_SERVICE) {
      invoiceWhereBuilder.dosBetween(dateFrom, dateTo);
    } else if (reportRequest.DateRangeType === DATE_RANGE_TYPE_TRN_REPORT.DATE_OF_SUBMISSION) {
      invoiceWhereBuilder.dateOfSubmissionBetween(dateFrom, dateTo);
    }

    const invoices: Invoice[] = await this.invoiceService.getInvoices(invoiceWhereBuilder.build()).pipe(first()).toPromise();
    return invoices;
  }

  private async buildReportInfo(reportRequest: MonthlyTransactionReportRequest): Promise<MonthlyTransactionReportInfo> {
    const reportInfo: MonthlyTransactionReportInfo = {};
    reportInfo.Practice = reportRequest.Practice?.PracticeName;
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    reportInfo.Month = ReportUtils.getMonthName(reportRequest.DateTo);
    reportInfo.Year = ReportUtils.getFullYear(reportRequest.DateTo);
    reportInfo.DateRangeType = reportRequest.DateRangeType;
    return reportInfo;
  }
}
