import { Injectable } from "@angular/core";
import { ReportUtils } from "../report-utils";
import { InvoicePdfReportDataBuilder } from "./invoice-pdf-report-data-builder";
import { InvoiceReportBuilder } from "./invoice-report-builder";
import {ReferenceDataService} from "../../services/reference-data.service";
import { Firestore, doc, getDoc } from "@angular/fire/firestore";
import { PathUtils } from "../../utils/path-utils";
import {
  ACCOUNT_TYPE,
  AccountMember,
  ADDON_ID, BaseAccount, BaseInvoice, BasePdfReportData, InvoiceMainMember,
  InvoiceReportRequest,
  Practice,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME,
  SnapScan
} from "@meraki-flux/schema";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceReportService {

  constructor(
    private referenceDataService: ReferenceDataService,
    private firestore: Firestore,
  ) {}

  async generateReport(reportRequest: InvoiceReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildInvoiceReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.Account.AccountNo,
      reportRequest.Invoice.Patient.Name, reportRequest.Invoice.Patient.Surname, REPORT_FORMAT_TYPE.PDF, reportRequest.Invoice.DateOfService);

    const snapscanSnapshot = await getDoc(doc(this.firestore, PathUtils.addonPath(reportRequest.Practice.Id, ADDON_ID.SNAPSCAN)));
    reportRequest.Snapscan = snapscanSnapshot.exists() ? snapscanSnapshot.data() as SnapScan : null;

    const reportGenerator = await this.getReportGenerator(reportRequest);
    return {
      fileName: fileName,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF,
      reportName: REPORT_NAME.INVOICE
    };
  }

  private async getReportGenerator(reportRequest: InvoiceReportRequest) {

    const reportGenerator = new BasePdfGenerator(await this.generatePdfReportData(reportRequest.Practice,
      reportRequest.Account, reportRequest.Invoice.MainMember, reportRequest.Invoice, reportRequest.Patient, reportRequest.Snapscan));
    return reportGenerator;
  }

  private async generatePdfReportData(practice: Practice, account: BaseAccount, mainMember: InvoiceMainMember, invoice: BaseInvoice, patient: AccountMember, Snapscan: SnapScan): Promise<BasePdfReportData> {
    const scheme =
      ACCOUNT_TYPE.MEDICAL_AID === account.AccountType && account?.Scheme
        ? await this.referenceDataService.getScheme(account.Scheme)
        : undefined;
    return new InvoicePdfReportDataBuilder().build(
      await new InvoiceReportBuilder().build(practice, account, mainMember, invoice, scheme, patient, Snapscan));
  }
}
