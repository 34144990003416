import { Component, Inject, Input } from '@angular/core';
import { doc, docSnapshots, Firestore, setDoc } from '@angular/fire/firestore';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AuthService,
  FirestoreService,
  NotificationSubscriptionService,
  SystemAccessHelperService,
  APP_RUNTIME_CONTEXT,
  APP_RUNTIME,
} from '@meraki-flux/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import packageJson from '../../../../../package.json';
import {Business, Practice, USER_ROLE} from "@meraki-flux/schema";

export interface ToolbarNavItem {
  label: string,
  url: string,
  icon?: string;
  access?: Observable<boolean>
  showIndicator?: Observable<boolean>;
}
@Component({
  selector: 'meraki-flux-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
@UntilDestroy()
export class ToolbarComponent {

  @Input() navItems: ToolbarNavItem[] = [];

  @Input() isLoading = false;

  public version: string = packageJson.version;

  appRuntime = APP_RUNTIME;

  user$ = this.auth.user$;
  userName$ = this.user$.pipe(
    map(user => user?.Username || user?.Name || '')
  );

  branches$ = combineLatest([this.auth.user$, this.auth.practice$]).pipe(
    map(([user, prac]) => {
      if (!user || !prac) {
        return [];
      } else {
        const practice = prac as Practice;
        const practiceBranches = practice?.Branches?.filter(branch => branch?.Active).map(branch => branch?.Name);
        if (this.auth.admin || (user.Common && user.Common.IsSystemAdministrator)) {
          return practiceBranches;
        } else {
          const userBranches = user.Common.AssignedBranches.map(branch => branch.BranchName);
          return practiceBranches.filter(branch => userBranches.includes(branch));
        }
      }
    }),
    untilDestroyed(this)
  );

  practiceName$ = this.user$.pipe(
    map(user => user?.SelectedPracticeName || '')
  );

  userInitials$: Observable<string> = this.user$.pipe(
    map(user => {
      if (user) {
        return `${user.Name.substring(0, 1).toUpperCase()}${user.Surname.substring(0, 1).toUpperCase()}`;
      }
      return '??';
    })
  )

  userFullName = '';
  practiceName = '';
  selectedBranch = '';

  branchesForm = this.fb.group({
    branch: ['']
  });

  businessAccess$ = this.auth.user$.pipe(
    map(user => user.AssignedPractices.filter((b: any) => b.BillingPracticeNumber !== user.SelectedBPN)),
  );

  patientSearchForm = new FormControl('');

  userHasAddonAccess$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    public auth: AuthService,
    private firestore: Firestore,
    private fs: FirestoreService,
    public fb: FormBuilder,
    private notificationSubscriptionService: NotificationSubscriptionService,
    public systemAccessHelper: SystemAccessHelperService,
    @Inject(APP_RUNTIME_CONTEXT) public appRuntimeContext: APP_RUNTIME,
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon('extension_add', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icn_extension_add.svg'));

    this.user$.pipe(
      tap(user => {
        if (user) {
          this.selectedBranch = user.SelectedBranch;
          this.branchesForm.get('branch').setValue(user.SelectedBranch);
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.systemAccessHelper.hasAccess(USER_ROLE.PRACTICE_ADMIN).pipe(
      tap(access => {
        this.userHasAddonAccess$.next(access)
      }),
      untilDestroyed(this)
    ).subscribe();

    this.branchesForm.valueChanges.pipe(tap(async value => {
      if (value.branch !== this.selectedBranch) {
        this.selectedBranch = value.branch;
        await this.fs.setDoc(doc(this.firestore, `User/${this.auth.uid}`), { SelectedBranch: value.branch }, { merge: true });
      }
    }),
      untilDestroyed(this)
    ).subscribe();

    //get the inbox count from firestore
    docSnapshots(doc(this.firestore, `Practice/${this.auth.selectedBPN}/Inbox/Count`)).pipe(
      map(doc => {
        const values = Object.values(doc.data() || {});
        const sum = values.filter(val => typeof val === 'number' && !isNaN(val))
          .reduce((acc, val) => acc + val, 0);
        const showIndicator = sessionStorage.getItem('inboxIndicator') === 'true' ? true : false;

          if(sessionStorage.getItem(`inboxCount_${this.auth.selectedBPN}`) && !showIndicator){
            const oldCount = sessionStorage.getItem(`inboxCount_${this.auth.selectedBPN}`);
            //if the new count is higher than the old count, update the count in session storage
            //and show the indicator
            if(sum > parseInt(oldCount)){
              sessionStorage.setItem(`inboxCount_${this.auth.selectedBPN}`, sum.toString());
              this.updateInboxIndicator('inbox', true);
            }
          }else{
            sessionStorage.setItem(`inboxCount_${this.auth.selectedBPN}`, sum.toString());
            this.updateInboxIndicator('inbox', true);
          }

      })
    ).subscribe();
  }

  async signOut() {
    await this.auth.signOut();
    this.notificationSubscriptionService.unsubscribe();
  }

  onAddonsClicked() {
    this.router.navigate(['addons']);
  }

  businessFound(business: Business) {
    const data = {
      SelectedBPN: business.BillingPracticeNumber,
      SelectedPracticeName: business.PracticeName,
      SelectedBusinessId: business.BusinessId ? business.BusinessId : business.Id,
      SelectedBusinessType: business.BusinessType,
    };
    setDoc(doc(this.firestore, `User/${this.auth.uid}`), data, { merge: true }).then(() => location.reload());
  }

  updateInboxIndicator(link: string, showIndicator: boolean) {

    // if we are in inbox, set showIndicator to false for inbox
    if(link === 'inbox'){
      this.navItems.forEach(item => {
        if(item.label === 'Inbox'){
          item.showIndicator = new BehaviorSubject(showIndicator);
          if(showIndicator) sessionStorage.setItem('inboxIndicator', showIndicator.toString());
        }
      });
    }
  }
}
