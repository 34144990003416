export enum SCHEDULE_STATUS {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export class ScheduleItem {
  Id: string;
  Name: string;
  Topic: string;
  Model: ScheduledItemModel;
  ExecutionTime: Date;
  Status: SCHEDULE_STATUS;
  CreatedAt: Date;
  CreatedBy: string;
}

export interface ScheduledItemModel {
  Id?: string;
}
