import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData,
  ProviderVATData,
  REPORT_NAME,
  Sheet, Table,
  VATData1,
  VATSummaryReportModel
} from '@meraki-flux/schema';

export class VATSummaryXlsReportDataBuilder {
  build(reportRequest: VATSummaryReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.name = REPORT_NAME.VAT_SUMMARY;
    excelReportData.data = [];

    excelReportData.data.push(this.buildVATDataSheet(reportRequest.SummaryDetails, 'Summary'));

    //For dev tests
    // excelReportData.data.push(this.buildVATDataSheet(reportRequest.VATData1, 'Data test - VATData1 by month group'));
    // excelReportData.data.push(this.buildVATDataSheet(reportRequest.VATData2, 'Data test - All data VATData2 by line'));

    let counter = 1; //To keep provider name unique due to limit on sheet name.
    reportRequest.ProviderVATData.forEach((item: ProviderVATData) => {

      excelReportData.data.push(this.buildVATDataSheet(item.ProviderVATData, counter + '. ' + item.TreatingProviderName));
      counter++;
    });

    this.buildReportInfo(excelReportData, reportRequest);
    return excelReportData;
  }

  private buildVATDataSheet(reportRequest: VATData1[], providerName: string) {
    const sheet: Sheet = {};
    sheet.name = providerName;
    sheet.tables = [];

    const detailsTable: Table = {};
    detailsTable.headers = [
      { value: 'Month', bold: true, width: CellWidth.XS },
      { value: 'Output VAT', bold: true, width: CellWidth.XS, hAlignment:'right' },
      { value: 'Input VAT', bold: true, width: CellWidth.XS, hAlignment:'right' },
      { value: 'Balance VAT', bold: true, width: CellWidth.XS, hAlignment:'right' },
      //For dev tests
      // { value: 'Provider', bold: true, width: CellWidth.XS, hAlignment:'right' },
      // { value: 'HeaderId', bold: true, width: CellWidth.XS, hAlignment:'right' },

    ];
    const detailsRows = [];

    let total_outputVAT = 0;
    let total_inputVAT = 0;
    let total_balanceVAT = 0;

    reportRequest.forEach((item: VATData1) => {
      const row: ExcelCellData[] = [];
        row.push({ value: item.CustomDate, type: CellType.GENERAL }),
        row.push({ value: item.OutputVAT, type: CellType.CURRENCY }),
        row.push({ value: item.InputVAT, type: CellType.CURRENCY }),
        row.push({ value: (item.OutputVAT - item.InputVAT), type: CellType.CURRENCY }),

        //For dev tests
        // row.push({ value: item.FullName + '--' + item.TransactionType + '--' + item.HPCSANumber + '--' + item.TreatingProviderName + '--RN--' + item.RowNum, type: CellType.GENERAL }),
        // row.push({ value: item.HeaderId, type: CellType.GENERAL });

        total_outputVAT = total_outputVAT + item.OutputVAT;
        total_inputVAT = total_inputVAT + item.InputVAT;
        total_balanceVAT = total_balanceVAT + (item.OutputVAT - item.InputVAT);

      detailsRows.push(row);
    });

    const row: ExcelCellData[] = [];
    row.push({ value: 'Total', bold: true });
    row.push({ value: total_outputVAT, bold: true, type: CellType.CURRENCY });
    row.push({ value: total_inputVAT, bold: true, type: CellType.CURRENCY });
    row.push({ value: total_balanceVAT, bold: true, type: CellType.CURRENCY });
    detailsRows.push(row);

    detailsTable.rows = detailsRows;
    sheet.tables.push(detailsTable);
    return sheet;
  }


  private buildReportInfo(
    excelReportData: BaseExcelReportData,
    reportRequest: VATSummaryReportModel
  ) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: { value: reportRequest.Practice.PracticeName, type: CellType.GENERAL },
    });

    excelReportData.parameters.push({
      name: 'Billing practice no.',
      data: { value: reportRequest.Practice.BillingPracticeNumber, type: CellType.GENERAL },
    });

    if (reportRequest.Multibranch) {
      excelReportData.parameters.push({
        name: 'Branch',
        data: { value: reportRequest.Branch.Name ?? 'All', type: CellType.GENERAL },
      });
    }

    excelReportData.parameters.push({
      name: 'Date Range Type',
     data: { value: reportRequest.DateRangeType, type: CellType.GENERAL },
    });

    excelReportData.parameters.push({
      name: 'Date Range',
      data: { value: reportRequest.DateRange, type: CellType.GENERAL },
    });

  }
}
