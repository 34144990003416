import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  collection,
  collectionSnapshots,
  docSnapshots,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { doc } from '@firebase/firestore';
import { PathUtils, SchemeUtils } from '@meraki-flux/purejs';
import { Country, Insurer, NameCode, NexBaseResponse, Scheme, SchemeDetails } from '@meraki-flux/schema';
import _, { keyBy, mapValues, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReferenceDataRepository } from '../repositories/reference-data.repository';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  // TODO: Move into config file
  NEXUS_BASE_URL = 'https://dev.meraki.healthbridge.co.za/v1';

  // TODO Move to LB URL:
  SCHEME_DETS_URL =
    'https://europe-west1-healthbridge-meraki-dev.cloudfunctions.net/scm-utl-v1-get-schemeDetails';

  constructor(
    private httpClient: HttpClient,
    private firestore: Firestore,
    private referenceDataRepository: ReferenceDataRepository
  ) {}

  schemes$: Observable<{ [key: string]: string }> = docSnapshots(
    doc(this.firestore, 'Configuration/SchemeLookup')
  ).pipe(
    map((schemeDocRef) => schemeDocRef.get('Schemes')),
    map((schemes: { [key: string]: string }) =>
      Object.keys(schemes)
        .filter((key) => key !== 'S0000' && key !== 'S0001')
        .reduce((result, schemeCode) => {
          result.push({ Name: schemes[schemeCode], Code: schemeCode });
          return result;
        }, [])
    ),
    map((schemes) => mapValues(keyBy(sortBy(schemes, ['Name']), 'Code'), 'Name'))
  );

  medicalInsurers$: Observable<Insurer[]> = this.referenceDataRepository.medicalInsurers$.pipe(
    map((insurers: { [key: string]: any }) =>
      Object.keys(insurers)
        .reduce((result, insurerCode) => {
          result.push({ ...insurers[insurerCode], Code: insurerCode, Id: insurerCode } as Insurer);
          return result;
        }, [])
        .sort((i1, i2) => i1?.Name?.localeCompare(i2.Name))
    )
  );

  famCheckSchemes$: Observable<{ [key: string]: string }> = docSnapshots(
    doc(this.firestore, 'Configuration/SchemeLookup')
  ).pipe(
    map((configDoc) => configDoc.get('FamCheckSchemes')),
    map((schemes) =>
      Object.entries(schemes).map((entry) => ({
        Name: entry[1] as string,
        Code: entry[0] as string,
      }))
    ),
    map((schemes) => mapValues(keyBy(sortBy(schemes, ['Name']), 'Code'), 'Name'))
  );

  raSchemes$: Observable<string[]> = docSnapshots(
    doc(this.firestore, 'Configuration/SchemeLookup')
  ).pipe(map((configDoc) => configDoc.get('RASchemes') as string[]));

  countries$: Observable<Country[]> = collectionSnapshots<Country>(
    collection(this.firestore, `Countries`)
  ).pipe(
    map((countriesRef) =>
      countriesRef.map((countryDoc) => ({ Alpha2: countryDoc.id, ...countryDoc.data() }))
    )
  );

  disabledPVSchemes$: Observable<string[]> = docSnapshots(
    doc(this.firestore, 'Configuration/SchemeLookup')
  ).pipe(map((configDoc) => configDoc.get('DisabledPatientValidationSchemes') as string[]));


  async getScheme(schemeCode: string): Promise<Scheme> {
    if (schemeCode) {
      const schemeDoc = await getDoc(doc(this.firestore, `SchemePlanOption/${schemeCode}`));
      if (schemeDoc.exists()) {
        return { ...schemeDoc.data(), Code: schemeCode } as Scheme;
      }
    }
    return null;
  }

  async isDiscontinued(schemeCode: string, planCode: string, optionCode: string): Promise<boolean> {
    if (!schemeCode) return undefined;
    const scheme = await this.getScheme(schemeCode);
    return SchemeUtils.isDiscontinued(
      { Scheme: schemeCode, Plan: planCode, Option: optionCode },
      scheme
    );
  }

  getSchemeContactDetails(schemeCode: string) {
    return this.httpClient
      .get<NexBaseResponse<SchemeDetails>>(this.SCHEME_DETS_URL, {
        params: {
          code: schemeCode,
        },
      })
      .pipe(map((result) => result.data?.payload));
  }

  async getPlanOptionDetails(planOptionCode: string) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const getOptionDetails = httpsCallable(functions, 'scm-utl-v1-oncall-getOptionDetails');
    const result: any = await getOptionDetails(planOptionCode);
    return result.data.data.payload as SchemeDetails;
  }

  isBenefitCheckScheme(schemeCode: string) {
    return !!this.referenceDataRepository.getBenefitCheckSchemes()?.includes(schemeCode);
  }

  isBenefitCheckRoutingCode(routingCode: string) {
    return !!this.referenceDataRepository.getBenefitCheckRoutingCodes()?.includes(+routingCode);
  }

  isFamCheckScheme(schemeCode: string) {
    return !!this.referenceDataRepository.getFamCheckSchemes()?.includes(schemeCode);
  }

  getSchemeName(schemeCode: string) {
    return this.referenceDataRepository.getSchemeName(schemeCode);
  }

  getCapitatedScheme(code: string) {
    return this.referenceDataRepository.getCapitatedScheme(code);
  }

  async schemePlanOptionsFlat(): Promise<Scheme[]> {
    let schemes = this.referenceDataRepository.getSchemePlanOptionFlat() || [];
    if (schemes.length === 0) {
      const spoFlatDoc = await getDoc(
        doc(this.firestore, PathUtils.configSchemePlanOptionFlatPath())
      );
      schemes = SchemeUtils.mapSchemeDocToArray(spoFlatDoc.data());
      this.referenceDataRepository.setSchemePlanOptionFlat(schemes);
    }
    return _.sortBy(schemes, 'Name');
  }

  async setupReferenceData() {
    const schemeLookupDoc = await getDoc(doc(this.firestore, `Configuration/SchemeLookup`));
    const schemeLookupData = schemeLookupDoc.data();
    const capitatedSchemesMapDoc = await getDoc(
      doc(this.firestore, `Configuration/CapitatedSchemesMap`)
    );
    const capitatedSchemesMap = capitatedSchemesMapDoc.data();
    const snackbarDurationDoc = await getDoc(doc(this.firestore, `Configuration/ResponseAlert`));
    const snackbarDuration = snackbarDurationDoc.data();
    this.referenceDataRepository.setCapitatedSchemesMap(capitatedSchemesMap);
    this.referenceDataRepository.setMedicalInsurers(schemeLookupData.MedicalInsurers);
    this.referenceDataRepository.setSchemes(schemeLookupData.Schemes);
    this.referenceDataRepository.setBenefitCheckSchemes(schemeLookupData.BenefitCheckSchemes);
    this.referenceDataRepository.setBenefitCheckRoutingCodes(
      schemeLookupData.BenefitCheckRoutingCodes
    );
    this.referenceDataRepository.setFamCheckSchemes(
      Object.keys(schemeLookupData.FamCheckSchemes || {})
    );
    this.referenceDataRepository.setSnackbarDuration(snackbarDuration?.duration || 6000);
  }

  getMedicalInsurer(insurerCode: string) {
    return this.referenceDataRepository.getMedicalInsurer(insurerCode);
  }

  async schemes(): Promise<NameCode[]> {
    const schemeLookupDoc = await getDoc(doc(this.firestore, 'Configuration/SchemeLookup'));
    const schemesMap = schemeLookupDoc.data()['Schemes'];
    const schemes = Object.keys(schemesMap)
      .filter((key) => key !== 'S0000' && key !== 'S0001')
      .reduce((result, schemeCode) => {
        result.push({ Name: schemesMap[schemeCode], Code: schemeCode } as NameCode);
        return result;
      }, [])
      .filter((r) => !r.Name.endsWith('(disc)'));
    return _.sortBy(schemes, 'Name', 'Code');
  }
}
