import { RemittanceClaim, RA_CLAIM_STATUS, Reason, RemittanceJournal, LineItem } from "@meraki-flux/schema";

export class RaUtils {
  static isReconciled(raClaim: RemittanceClaim) {
    return RA_CLAIM_STATUS.RECONCILED === raClaim?.Status;
  }

  static formatReasonCodes(array: Reason[], separator: string) {
    return this.userReasonCodes(array)
      ?.map((c) => `${c.ReasonCode || ''}`)
      .join(separator);
  }

  static formatReasonCodeAndDescs(array: Reason[], separator: string) {
    return this.userReasonCodes(array)
      ?.map((c) => `${c.ReasonCode} - ${c.ReasonDesc || ''}`)
      .join(separator);
  }

  static userReasonCodes(reasonCodes: Reason[]) {
    return reasonCodes?.filter((r) => !r.ReasonCode.startsWith('hb_')) || [];
  }

  static totalJournalAmount(journals: RemittanceJournal[]) {
    return journals?.reduce((sum, j) => sum + (+j?.JournalAmount | 0), 0);
  }

  static totalTariffAmount(lines: LineItem[]) {
    return lines?.reduce((sum, l) => sum + (+l?.PayAdvice?.TariffAmount | 0), 0);
  }

  static getPaymentAmount(raClaim: RemittanceClaim) {
    return (
      raClaim?.LineItem?.reduce((acc, line) => acc + (+line?.PayAdvice?.PaymentAmount || 0), 0) || 0
    );
  }

  static isPositivePaid(raClaim: RemittanceClaim) {
    return this.getPaymentAmount(raClaim) >= 0;
  }
}
