import { ComponentFactoryResolver, Directive, ElementRef, Injector, Input, OnInit, Renderer2, ViewContainerRef } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import * as moment from "moment";

@Directive({
    selector: '[relativeTime]',
    exportAs: 'relativeTimeValue'
})
export class RelativeTimeDirective implements OnInit {
    @Input() relativeTime: Date;

    isPast = false;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private vc: ViewContainerRef) {

    }

    ngOnInit(): void {
        this.setTimeText();
    }

    setTimeText() {
        const diff = this.getDiffInMinutes();
        const text = this.calculateRelativeTimeText(diff);

        if (diff < 0) {
            this.vc.element.nativeElement.classList.add('text-hb-critical-100');
        }

        const textContainer = this.renderer.createElement('div');
        const textEl = this.renderer.createText(text);

        this.renderer.appendChild(textContainer, textEl);

        (this.vc.element.nativeElement as HTMLDivElement).appendChild(textContainer);

    }

    calculateRelativeTimeText(diffMinutes: number) {
        const positiveDiffMinutes = Math.abs(diffMinutes);
        if (diffMinutes === 0) {
            this.isPast = false;
            return 'now';
        } else if (diffMinutes === 1) {
            this.isPast = false;
            return '1 min to go';
        } else if (diffMinutes > 1 && diffMinutes < 60) {
            this.isPast = false;
            return `${positiveDiffMinutes} mins to go`;
        } else if (diffMinutes >= 60 && diffMinutes < 120) {
            this.isPast = false;
            return '1 hr to go';
        } else if (diffMinutes >= 120) {
            return `${Math.floor(positiveDiffMinutes / 60)} hrs to go`
        } else if (diffMinutes === -1) {
            this.isPast = true;
            return '1 min ago';
        } else if (diffMinutes < -1 && diffMinutes > -60) {
            this.isPast = true;
            return `${positiveDiffMinutes} mins ago`;
        } else if (diffMinutes <= -60 && diffMinutes > 120) {
            this.isPast = true;
            return '1 hr ago';
        } else if (diffMinutes < 120) {
            this.isPast = true;
            return `${Math.floor(positiveDiffMinutes / 60)} hrs ago`;
        } else {
            this.isPast = false;
            return '';
        }
    }

    getDiffInMinutes() {
        return moment(this.relativeTime).diff(moment(), 'minutes');
    }
}