import {
  BasePdfReportData, InvoiceHeader,
  InvoiceReportModel,
  InvoiceTable,
  ReportFooter,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class InvoicePdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(invoiceReportModel: InvoiceReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.logo = invoiceReportModel.Logo;
        reportData.bpn = invoiceReportModel.PracticeId;
        reportData.doctorName = invoiceReportModel.PracticeName;
        reportData.doctorNameColor = InvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        reportData.headers = this.buildHeaders(invoiceReportModel);
        reportData.tables = this.buildTables(invoiceReportModel);
        reportData.footer = this.buildFooter(invoiceReportModel);
        reportData.mainHeader = invoiceReportModel.Header;
        reportData.footerText = invoiceReportModel.FooterText;
        reportData.bottomMessage = invoiceReportModel.InvoiceMessage;
        reportData.dateHeader = `Invoice date: ${invoiceReportModel.ReportDate}`;
        reportData.snapScan = invoiceReportModel.Snapscan;
        return reportData;
    }

    buildFooter(invoiceReportModel: InvoiceReportModel) : ReportFooter {
        return {
            hbLogo: true,
            infoTable: invoiceReportModel.InfoTable
        }
    }

    buildTables(invoiceReportModel: InvoiceReportModel) : ReportTable[] {
        const tables:ReportTable[] = [];
        invoiceReportModel.InvoiceTables.forEach(stTable => {
            tables.push(this.buildReportTable(stTable));
        })
        return tables;
    }

    buildReportTable(inTable: InvoiceTable) : ReportTable {
        return {
            headers: inTable.TableHeaders.map(x => {
                return {
                    outlined: x.outlined,
                    outlineColor: x.outlineColor,
                    rows: x.rows.map(y => {
                        return y.map(xy => {
                            return {
                                name: xy.name,
                                data: {
                                    value: xy.value.value,
                                    decoration: {
                                        bold: xy.value.bold
                                    }
                                }
                            }
                        })
                    }),
                }
            }),
            reportICDLine: inTable.ICDLine,
            rowHeaders: inTable.rowHeaders,
            rows: inTable.rows,
            borders: inTable.borders
        }
    }

    buildHeaders(invoiceReportModel: InvoiceReportModel) : ReportHeader[] {
        const headers:ReportHeader[] = [];
        headers.push(this.buildReportHeader(invoiceReportModel.PracticeHeader));
        headers.push(this.buildAccountHeader(invoiceReportModel.AccountHeader));
        return headers;
    }

    buildReportHeader(invoiceHeader: InvoiceHeader): ReportHeader {
        return {
            right: invoiceHeader.right.map(x => {
                return {
                    name: x.name,
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            }),
            left: invoiceHeader.left.map(x => {
                return {
                    data: {
                        value: x.value.value,
                        decoration: {
                            bold: x.value.bold
                        }
                    }
                }
            })
        }
    }

    buildAccountHeader(invoiceHeader: InvoiceHeader): ReportHeader {
        return {
            name: invoiceHeader.name,
            nameColor: InvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            caption: invoiceHeader.caption,
            captionColor: "red",
            includeGeneratedDate: true,
            outlined: true,
            outlineColor: InvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
            ... this.buildReportHeader(invoiceHeader)

        }
    }
}
