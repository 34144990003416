import { CLAIM_STATUS } from "./claim.models";
import { YES_NO } from './florence.models';

export interface ClaimPayload {
  ProviderMessage?: ProviderMessage;
}

export interface ProviderMessage {
  Claim?: Claim;
}

export interface Claim {
  Details: ClaimDetails;
  Member: Member;
  Patient: Patient;
  Providers: ClaimProvider[];
  Transaction: ClaimTransaction;
}

export interface ClaimDetails {
  AuthNum?: string;
  ReferralNum?: string;
  PayWho: "Provider" | "Member";
  DiagnosisCodes?: DiagnosisCode[];
  HospInd: YES_NO;
  AdmDateTime?: string; //yyyyMMddHHmm
  DisDateTime?: string; //yyyyMMddHHmm
  COIDInd: YES_NO;
  DOI?: string; //yyyy-MM-dd
  EmployerName?: string;
  EmployeeNo?: string;
  EmployerRegNo?: string;
  OptomScripts?: OptomScript[];
  ClaimFinancials: ClaimFinancials;
  LineItems: ClaimLineItem[];
}

export interface DiagnosisCode {
  Set: "ICD" | "ICPC";
  Version: string;
  Type?: "Primary" | "Secondary" | "Co-Morbidity" | "Complication";
  Value: string;
  Description?: string;
}

export interface OptomScript {
  "@Ref"?: string;
  Type?: "Spectacle" | "Contact Lenses" | "Not Specified";
  Eye?: "Left" | "Right";
  Status?: "Current" | "Previous";
  PowerSpher?: string;
  PowerCylin?: string;
  Axis?: string;
  Add?: string;
  Prism?: string;
  VisualAcuity?: string;
  InocularPressure?: string;
  VisualFieldInfo?: string;
  NVSTagNum?: string;
  Labref?: string;
  LabOrderNum?: string;
}

export interface ClaimFinancials {
  TotalBilledAmt?: string;
  TotalDiscount?: string;
  NettBilledAmt?: string;
}

export interface ClaimLineItem {
  "@Type"?: string; // 'Procedure' | 'Medicine' | 'Mixture' | 'Consumable' | 'Modifier' | 'Dental Lab Slip';
  UniqueID: string;
  OptomScriptRefs?: OptomScriptRefs[];
  DOS: string; //yyyy-MM-dd
  Labs?: Lab[];
  AuthNum?: string;
  ScriptNum?: string;
  ChargeCode: ChargeCode;
  StockCode?: StockCode;
  DiagnosisCodes?: DiagnosisCode[];
  PricingInd: "Private" | "NHRPL" | "COID";
  ChargeStart?: string; //yyyy-MM-dd'T'HH:mm:ss
  ChargeEnd?: string; //yyyy-MM-dd'T'HH:mm:ss
  ChargeQuantity?: string;
  ChargeUnit?: "Days" | "Hours" | "Kilometers" | "Kilometres" | "Minutes" | "Seconds" | "Units";
  ContactLensChargeFreq?: "Days" | "Months" | "Permanent" | "Weeks";
  Dentals?: Dental[];
  HospInd: YES_NO;
  PlaceServiceCode: string;
  Medicine?: Medicine;
  LineItemFinancials: LineItemFinancials;
}

export interface OptomScriptRefs {
  Ref?: string;
}

export interface Lab {
  RefNum?: string;
  OrderNum?: string;
  Name?: string;
  RemakeInd?: YES_NO;
}

export interface ChargeCode {
  "@Type": "Procedure" | "Optometry" | "Dentistry";
  Set: "CPT" | "Tariff" | "CCSA" | "SAOA" | "PPN" | "SADA";
  Version?: string;
  Value: string;
  Description: string;
}

export interface StockCode {
  Set: "Nappi";
  Version?: string;
  Value: string;
  Description: string;
}

export interface Dental {
  ToothNum?: string;
  ToothSurface?: string;
}

export interface Medicine {
  "@Type": "Acute" | "Chronic" | "PAT Drugs" | "Self-medication" | "Chemotherapy" | "Aids" | "Other";
  DailyDosage?: string;
  DaysSupply: string;
  SupplyQuantity: string;
  DosInstr?: string;
  DispLoc?: "Ward" | "Theatre" | "Pharmacy" | "Take-out";
  MixIdNum?: string;
  MixIngNum?: string;
  MixMed?: string;
  Repeat?: MedicineRepeat;
}

export interface MedicineRepeat {
  Number?: string;
  Total?: string;
}

export interface LineItemFinancials {
  BilledAmt: string;
  GrossAmt: string;
  Discount?: string;
  Levy?: string;
  ContainerFee?: string;
  DispenseFee?: string;
  ServiceFee?: string;
  ProfFee?: string;
  BalancedBilling?: BalancedBilling;
}

export interface BalancedBilling {
  MALiable?: string;
  PatLiable?: string;
}

export interface Member {
  Name: string;
  Initials?: string;
  Surname: string;
  DOB?: string; //yyyy-MM-dd
  ID?: string;
  MANum: string;
  SchemeName: string;
  Gender?: string;
  PlanName?: string;
  OptionName?: string;
  SchemeReg?: string;
  Demographics?: Demographics;
}

export interface Demographics {
  WkTelephone?: string;
  HMTelephone?: string;
  Fax?: string;
  Cellphone?: string;
  Email?: string;
  PostalAddress?: PostalAddress;
  PhysicalAddress?: PhysicalAddress;
}

export interface PostalAddress {
  Address?: string;
  Suburb?: string;
  Town?: string;
  Code?: string;
}

export interface PhysicalAddress {
  Address?: string;
  Suburb?: string;
  Town?: string;
  Code?: string;
}

export interface Patient {
  Name: string;
  Initials: string;
  Surname: string;
  DOB?: string; //yyyy-MM-dd
  ID?: string;
  Gender?: CLAIM_GENDER;
  PassportNum?: string;
  CountryOfIssue?: string;
  PatientHeight?: string;
  PatientWeight?: string;
  DepCode?: string;
  Identifier?: string;
  AccNum: string;
  NewBornInd: YES_NO;
  Demographics?: Demographics;
}

export interface ClaimProvider {
  "@Type": "Billing" | "Treating" | "Referring" | "Prescribing" | "Assisting";
  SpecialtyCode: string;
  SubSpecialtyCode: string;
  DispensingDoctor?: YES_NO;
  DispensingLicNum?: string;
  PracticeName: string;
  PracticeNum?: string;
  HPCSA?: string;
  Demographics?: Demographics;
}

export interface ClaimTransaction {
  RoutingCode: string;
  DataSet: string;
  InvoiceNum: string;
  PMAName: string;
  PMAVersion: string;
  MedpraxVersion?: string;
  SavedDateTime?: string; //yyyy-MM-dd'T'HH:mm:ss
}

export interface ClaimMessage {
  InvoiceId: string;
  BillingPracticeNumber: string;
  Claim: Claim;
}

export enum CLAIM_GENDER {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
}

export interface ReversalPayload {
  ProviderMessage?: ReversalProviderMessage;
}

export interface ReversalProviderMessage {
  Reversal?: Reversal;
}

export interface Reversal {
  Transaction?: ClaimTransaction;
}

export interface ClaimService200Response {
  allOf?: ClaimServiceResponse[];
}

export interface ClaimServiceResponse {
  MessageId?: string;
  Message?: string;
  InvoiceNum?: string;
}

export interface ReversalService200Response {
  allOf?: ClaimServiceResponse[];
}

export interface ClaimResponse {
  MedicalAidMessage: {
    Claim?: ResponseClaim;
    Reversal?: ResponseClaim;
    Version?: string;
  };
}

export interface ResponseClaim {
  Disclaimer: string;
  Transaction: {
    ReferenceNum: string;
    InvoiceNum: string;
    DataSet: string;
  };
  Details: {
    LineItems: ResponseLineItem[];
    ClaimFinancials: {
      TotalClaimedAmt: string;
      TotalPaidProvAmt: string;
      BalanceOutstanding: string;
    };
    Responses: LineResponse[];
  };
}

export interface ResponseLineItem {
  StatusCode: string;
  HBBatchNum: string;
  UniqueID: string;
  Editable: YES_NO;
  Responses: LineResponse[];
  Reversible: YES_NO;
  StatusDesc: string;
  LineItemFinancials: {
    BilledAmt: string;
    MAPayable: string;
    MedicalAidRate: string;
  };
  MALineRefNum: string;
  PayWho: "Provider" | "Member";
}

export interface LineResponse {
  "@Type": string;
  Code: string;
  Desc: string;
}

export function getClaimSatusCode(claimStatus: CLAIM_STATUS): number {
  switch (claimStatus) {
    case CLAIM_STATUS.SAVED:
      return 1;
    case CLAIM_STATUS.AWAITING_RESPONSE:
      return 2;
    case CLAIM_STATUS.RECEIVED_BY_HEALTHBRIDGE:
      return 3;
    case CLAIM_STATUS.VALIDATED_BY_HEALTHBRIDGE:
      return 4;
    case CLAIM_STATUS.RECEIVED_BY_MEDICALAID:
      return 5;
    case CLAIM_STATUS.VALIDATED_BY_MEDICALAID:
      return 6;
    case CLAIM_STATUS.PROCESSED_BY_MEDICALAID:
      return 7;
    case CLAIM_STATUS.REJECTED:
      return 8;
    case CLAIM_STATUS.AWAITING_REVERSAL:
      return 10;
    case CLAIM_STATUS.REVERSAL_ACCEPTED:
      return 11;
    case CLAIM_STATUS.REVERSAL_PROCESSED:
      return 12;
    case CLAIM_STATUS.REVERSAL_REJECTED:
      return 13;
    case CLAIM_STATUS.REMITTANCE_ADVICE_RECEIVED:
      return 14;
    case CLAIM_STATUS.PART_REJECTED:
      return 17;
    case CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID:
      return 21;
    case CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID:
      return 22;
    case CLAIM_STATUS.STALE:
      return 23;
    default:
      return 0;
  }
}

export function getClaimStatus(claimStatusCode: number): CLAIM_STATUS {
  switch (claimStatusCode) {
    case 1:
      return CLAIM_STATUS.SAVED;
    case 2:
      return CLAIM_STATUS.AWAITING_RESPONSE;
    case 3:
      return CLAIM_STATUS.RECEIVED_BY_HEALTHBRIDGE;
    case 4:
      return CLAIM_STATUS.VALIDATED_BY_HEALTHBRIDGE;
    case 5:
      return CLAIM_STATUS.RECEIVED_BY_MEDICALAID;
    case 6:
      return CLAIM_STATUS.VALIDATED_BY_MEDICALAID;
    case 7:
      return CLAIM_STATUS.PROCESSED_BY_MEDICALAID;
    case 10:
      return CLAIM_STATUS.AWAITING_REVERSAL;
    case 11:
      return CLAIM_STATUS.REVERSAL_ACCEPTED;
    case 12:
      return CLAIM_STATUS.REVERSAL_PROCESSED;
    case 13:
      return CLAIM_STATUS.REVERSAL_REJECTED;
    case 14:
      return CLAIM_STATUS.REMITTANCE_ADVICE_RECEIVED;
    case 17:
      return CLAIM_STATUS.PART_REJECTED;
    case 8:
      return CLAIM_STATUS.REJECTED;
    case 21:
      return CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID;
    case 22:
      return CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID;
    case 23:
      return CLAIM_STATUS.STALE;
    default:
      return CLAIM_STATUS.SAVED;
  }
}

export function getClaimStatusOrder(claimStatus: CLAIM_STATUS): number {
  switch (claimStatus) {
    case CLAIM_STATUS.SAVED:
      return 1;
    case CLAIM_STATUS.AWAITING_RESPONSE:
      return 2;
    case CLAIM_STATUS.RECEIVED_BY_HEALTHBRIDGE:
      return 3;
    case CLAIM_STATUS.VALIDATED_BY_HEALTHBRIDGE:
      return 4;
    case CLAIM_STATUS.RECEIVED_BY_MEDICALAID:
      return 5;
    case CLAIM_STATUS.VALIDATED_BY_MEDICALAID:
      return 6;
    case CLAIM_STATUS.PROCESSED_BY_MEDICALAID:
      return 7;
    case CLAIM_STATUS.AWAITING_REVERSAL:
      return 10;
    case CLAIM_STATUS.REVERSAL_ACCEPTED:
      return 11;
    case CLAIM_STATUS.REVERSAL_PROCESSED:
      return 12;
    case CLAIM_STATUS.REVERSAL_REJECTED:
      return 13;
    case CLAIM_STATUS.REMITTANCE_ADVICE_RECEIVED:
      return 9;
    case CLAIM_STATUS.PART_REJECTED:
      return 8.5;
    case CLAIM_STATUS.REJECTED:
      return 8;
    case CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID:
      return 6.5;
    case CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID:
      return 8.7;
    case CLAIM_STATUS.STALE:
      return -2;
    default:
      return 0;
  }
}
