import { Component, ElementRef, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import { ModifierHelper } from './modifier.helper';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxMaskModule } from 'ngx-mask';
import { DialogService } from '../../services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MoneyUtils } from '../../utils/money-utils';
import { PricingService } from '../../services/pricing.service';
import moment from 'moment';
import { FormUtils } from '../../utils/form-utils';
import { Firestore, collection, collectionSnapshots, query, where } from '@angular/fire/firestore';
import { PathUtils } from '@meraki-flux/purejs';
import { AuthService } from '../../services/auth.service';
import { map, tap } from 'rxjs/operators';
import {
  ChargeDto,
  ChargeModifierParameterDto,
  DialogType,
  INVOICE_LINE_TYPE,
  InvoiceProvider,
  ModifierCodes,
  ModifierLink,
  ModifierModelData,
  ModifierParameter,
  ModifierParameterInfoDto,
  ModifierParameters,
  ModifierParametersQueryDto,
  ModifierPricingRequest,
  ModifierRequirements,
  ModifierRestrictions,
  ModifierRestrictionsDto,
  ModifierUIElements,
  PracticeProvider,
  Provider,
} from '@meraki-flux/schema';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'meraki-flux-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.scss'],
})
export class ModifierComponent implements OnInit {
  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private modifierHelper: ModifierHelper,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ModifierComponent>,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private pricingService: PricingService,
    @Inject(MAT_DIALOG_DATA) public data: ModifierModelData
  ) {}

  @NgModule({
    imports: [NgxMaskModule.forRoot()],
  })
  readonly busySaving$ = new BehaviorSubject(false);
  readonly busyGettingRcf$ = new BehaviorSubject(false);
  modifierParameterInfo: ModifierParameterInfoDto[] = [];
  parameterArray: any[] = [];
  timeRegex = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/;
  componentState$ = new BehaviorSubject<'Loading' | 'Ready' | 'Error'>('Ready');

  @ViewChild('focusOnThis') focusElement: ElementRef;

  providers$ = new BehaviorSubject<PracticeProvider[]>([]);
  allProviders$: Observable<InvoiceProvider[]>;
  allProviders: InvoiceProvider[] = [];

  showTimePicker = false;
  showInputDecimal = false;
  showGrid = false;
  showCheckbox = false;
  showCombo = false;

  supportedControls: string[] = [];
  loadingText = 'Loading...';  
  bmiValue = 0;
  checkedModifiers = ['0003', '0005', '0073', '0004'];
  anaestheticModifierCodes = ['0023', '0036'];
  showBmi = false
  bmiMoreThan35 = 'Modifier 0018 can only be applied with a BMI of at least 35';
  maxWeight25 = 'Modifier 0019 can only be applied with a maximum weight of 2.5 kg';
  modifierRcfValue = 0;
  showButtons = false;
  isMedScheme = false;
  comboBoxControls = [];

  form = this.fb.group({});

  ngOnInit(): void {
    this.loadAuction();
  }

  async loadAuction() {
    this.componentState$.next('Loading');
    const invoiceLines = this.data[0].invoice?.Lines?.find(
      (obj) => obj.LineType == INVOICE_LINE_TYPE.PROCEDURE
    );
    if (!invoiceLines || invoiceLines.length == 0) {
      this.busySaving$.next(false);
      this.dialogService.showWarning('You need to add at least one procedure line.');
      this.dialogRef.close();
      return;
    }

    if (this.data[0].recalculateModifiers) {
      await this.recalculateModifiers();
    } else {
      await this.modifierParameter();
      setTimeout(() => {
        if (this.focusElement && this.focusElement.nativeElement) {
          this.focusElement.nativeElement.focus();
        }
      }, 10);
    }
    this.componentState$.next('Ready');
  }

  async validateTimeControls() {
    const timeControls = ['StartTime', 'EndTime'];
    for (const timeControl of timeControls) {
      if (this.form.get(timeControl)) {
        this.form.get(timeControl).valueChanges.subscribe((value: string) => {
          if (!value || (value && value.length == 4 && !this.timeRegex.test(value))) {
            this.form.get(timeControl).setErrors({ invalidFormat: true });
          } else {
            this.form.get(timeControl).setErrors(null);
          }
        });
      }
    }
  }

  async modifierParameter() {
    if (ModifierCodes.M0011){
      this.isMedScheme = await this.modifierHelper.isMedschemes(this.data[0].shceme);
    }
    const modifierParameterQuery: ModifierParametersQueryDto = {
      ModifierCodes: [this.data[0].tariffCode],
      DisciplineCode: this.modifierHelper.getDisciplineCodeForModifier(
        this.data[0].disciplineCode,
        this.data[0].invoice?.TreatingProvider?.IsAnaesthetist
      ),
    };

    try {
      this.modifierParameterInfo = await this.modifierHelper.getModifierParameters(
        modifierParameterQuery
      );
      if (!this.modifierParameterInfo[0].isSupported) { 
        this.dialogRef.close({ keepLine: true });
        return;
      }
      this.showButtons = this.modifierParameterInfo[0]?.parameters.length === 0? false: true;
      for (let i = 0; i < this.modifierParameterInfo.length; i++) {
        this.parameterArray = await this.modifierHelper.getParameterArray(
          this.modifierParameterInfo[i],
          this.data[0],
          this.isMedScheme
        );
        const hasLinksInParameter = this.modifierParameterInfo[i].parameters.some(obj => obj.name === ModifierParameters.LINK);
        const hasLinksInArray = this.parameterArray.some(obj => obj.name === ModifierParameters.LINK);
        if (hasLinksInParameter && !hasLinksInArray) {
          this.dialogService.showWarning('There are no valid codes this modifier can be applied to.');
          this.dialogRef.close();
          return;
        }
        await this.addFormControls();
        await this.loadControlData();
        this.componentState$.next('Ready');        
        await this.modifierRestrictions(this.modifierParameterInfo[0].restrictions);
        await this.validateTimeControls();
        if (this.data[0].editModifier) {
          await this.editModifier();
        }
        if (this.modifierParameterInfo[i]?.parameters.length === 0) {
          await this.onSave();
          return;
        }        
      }
    } catch (error) {
      // Handle any errors that may occur during the process
      let errorMessage = 'Unknown Error';
      if (error instanceof HttpErrorResponse) {
        errorMessage = `Parameter info: ${error.error}. ${error.message}`;
      }
      this.dialogService.showErrorMessage(errorMessage);
    }
  }

  async modifierRestrictions(restrictions: ModifierRestrictionsDto[]) {
    for (let i = 0; i < restrictions.length; i++) {
      const restriction = restrictions[i];
      if (restriction.name === ModifierRestrictions.PER_LINE_DISALLOWED) {
        // Implement the logic here
      } else if (restriction.name === ModifierRestrictions.PER_LINE_ONLY_ALLOWED) {
        // Implement the logic here
      }
    }
  }

  async addFormControls() {
    for (let i = 0; i < this.parameterArray.length; i++) {
      const parameter = this.parameterArray[i];
      if (parameter.name == 'TimeArray') {
        for (let t = 0; t < parameter.value.length; t++) {
          const timeArray = parameter.value[t];
          this.form.addControl(
            timeArray.name,
            new UntypedFormControl(timeArray.value || '', this.getValidators(timeArray))
          );
        }
      } else if (parameter.name == 'InputArray') {
        for (let t = 0; t < parameter.value.length; t++) {
          const inputArray = parameter.value[t];
          this.form.addControl(
            inputArray.name,
            new UntypedFormControl(inputArray.value || '', this.getValidators(inputArray))
          );
        }
      } else if (parameter.name == ModifierParameters.LINK) {
        for (let t = 0; t < parameter.linkArray.length; t++) {
          const linkArray = parameter.linkArray[t];
          this.form.addControl(
            linkArray.lineNumber,
            new UntypedFormControl(this.checkInvoiceLine(this.data[0], linkArray) || '')
          );
        }
      } else if (parameter.name == 'ComboArray') {
        if (this.data[0].tariffCode === ModifierCodes.M0011) {
          this.comboBoxControls = parameter.value;
          for (let t = 0; t < parameter.value.length; t++) {
            const comboArray = parameter.value[t];
            this.form.addControl(comboArray.name, new UntypedFormControl(null, this.getValidators(comboArray)));
          }
        } else {
          const asstProvName = ModifierParameters.ASSISTANT_PROVIDER_PRACTICE_NUMBER.replace(/\./g, '');
          const asstProvider = parameter.value.find((x: { name: string; }) => x.name === asstProvName);
          if (asstProvider) {
            this.comboBoxControls.push(asstProvider);
            this.form.addControl(asstProvider.name, new UntypedFormControl(null || '', this.getValidators(parameter)));
          }
        }
      } else if (parameter.name == 'is_for_assistant') {
        this.form.addControl(
          parameter.name,
          new UntypedFormControl(false, this.getValidators(parameter))
        );
      } else {
        this.form.addControl(
          parameter.name,
          new UntypedFormControl(parameter.value || '', this.getValidators(parameter))
        );
      }
    }
  }

  private getValidators(parameter: ModifierParameter) {
    const validators = [];
    if (parameter.isRequired) {
      validators.push(Validators.required);
    }
    // Add more validators based on field.type or custom rules
    return validators;
  }

  compareProvider(option: Provider, selected: InvoiceProvider) {
    return option?.HPCSANumber === selected?.HPCSANumber;
  }

  async loadControlData() {
    const controls = this.form.controls;
    const invoice = this.data[0]?.invoice;
    const requirements = this.modifierParameterInfo[0].requirements;   

    if (controls.RCF) {
      this.busyGettingRcf$.next(true);
      const pricingReqObject = this.getPricingRequestObject(this.data[0].tariffCode);
      const rcfVal = await this.rcfPricing(pricingReqObject);
      controls.RCF.setValue(rcfVal);
      this.busyGettingRcf$.next(false);
    }
    else{
      const modifierRcf = requirements.find(
        (obj) => obj.name === ModifierRequirements.MODIFIER_RCF
      );
      if (modifierRcf) {
        const pricingReqObject = this.getPricingRequestObject(this.data[0].tariffCode);
        this.modifierRcfValue = await this.rcfPricing(pricingReqObject);
      }
    }

    if (this.data[0]?.tariffCode === ModifierCodes.M0039) {
      const line = invoice?.Lines?.find(
        (line) => line.LineType === INVOICE_LINE_TYPE.MODIFIER && line.TariffCode != this.data[0]?.tariffCode 
        && !line.LineCodeSelected && this.anaestheticModifierCodes.includes(line.TariffCode)
      );
      if (line && line?.ChargeStart && line?.ChargeEnd) {
        const ChargeStartTime = moment(line.ChargeStart).format('HHmm');
        controls.StartTime.setValue(ChargeStartTime);
        const ChargeEndTime = moment(line.ChargeEnd).format('HHmm');
        controls.EndTime.setValue(ChargeEndTime);
      }
    }

    if(controls.height && controls.weight){
      this.showBmi = true;
    }

    if(controls.height && controls.weight){
      this.showBmi = true;
    }

    if (controls.asstproviderpracticenumber || controls.providerpracticenumber) {
      collectionSnapshots<PracticeProvider>(
        query(
          collection(
            this.firestore,
            PathUtils.practiceProviderCollectionPath(this.authService.selectedBPN)
          ),
          where('IsActive', '==', true)
        )
      )
        .pipe(
          map((providerCollRef) =>
            providerCollRef.map((docRef) => ({ Id: docRef.id, ...docRef.data() }))
          ),
          tap((providers) => this.providers$.next(providers))
        )
        .subscribe();

      this.allProviders$ = this.providers$.pipe(
        map((providers) =>
          providers.map(
            (p) =>
              ({
                NameSurname: `${p.Title} ${p.Name} ${p.Surname}`,
                FullName: `${p.Title} ${p.Name} ${p.Surname}`,
                HPCSANumber: p.HPCSANumber,
                Id: p.Id,
                TreatingPracticeNumber: p.TreatingPracticeNumber,
              } as any)
          )
        ),
        map((providers) => providers.sort((a, b) => a.NameSurname.localeCompare(b.NameSurname)))
      );
      this.form.get('asstproviderpracticenumber')?.setValue(invoice.AssistingProvider as InvoiceProvider);      
    }

    const clinicalRcfObject = requirements.find(
      (obj) => obj.name === ModifierRequirements.CLINICAL_RCF
    );
    if (clinicalRcfObject) {
      this.loadingText = 'Loading rates';
      const lines = invoice?.Lines;
      const tariffCodes = [];
      if (lines) {
        for (let i = 0; lines.length > i; i++) {
          if (lines[i].TariffCode && lines[i].LineType == INVOICE_LINE_TYPE.PROCEDURE) {
            tariffCodes.push(lines[i].TariffCode);
          }
        }
      }
      if (tariffCodes.length > 0) {
        const procedureCodes = tariffCodes.join(',');
        const pricingReqObject = this.getPricingRequestObject(this.data[0].tariffCode);
        pricingReqObject.tariffCode = procedureCodes;
        const rcfVal = await this.clinicalRcfPricing(pricingReqObject);
        if (rcfVal) {
          for (let i = 0; i < rcfVal.length; i++) {
            const rcf = rcfVal[i];
            const invoiceLine = lines.find((obj) => obj.TariffCode === rcf.Code);
            if (invoiceLine) {
              invoiceLine.ClinicalRcfValue = isNaN(rcf.ClinicalRcfValue) ? 0 : rcf.ClinicalRcfValue;
            }
          }
        }
      }
    }
  }

  async modifierRequirementsChecks(chargeDtoData: ChargeDto) {
    let error = '';
    const requirements = this.modifierParameterInfo[0].requirements;
    const chargeLines = chargeDtoData.chargeLines;
    if (chargeLines) {
      for (let i = 0; chargeLines.length > i; i++) {
        const chargeLine = chargeLines[i];

        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          if (requirement.name === ModifierRequirements.RCF) {
            const rcf = this.form.value.RCF;
            if (rcf <= 0) {
              error = error + ', Invalid RCF value';
            }
          } else if (requirement.name === ModifierRequirements.SERVICE_AMOUNT) {
            if (chargeLine.serviceAmountCents < 0) {
              error = error + ', Invalid Service Amount';
            }
          } else if (
            requirement.name === ModifierRequirements.PATIENT_AMOUNT ||
            requirement.name === ModifierRequirements.MEDAID_AMOUNT
          ) {
            if (chargeLine.patientAmountCents < 0 || chargeLine.medicalAidAmountCents < 0) {
              error = error + ', Invalid Patient/MedicalAid Amount';
            }
          }
        }
      }
    }

    return error;
  }

  async validate(): Promise<boolean> {
    FormUtils.validateForm(this.form);
    return this.form.valid;
  }

  getCheckModParamObject(name: string, value: string) {
    return {
      name: name,
      value: value,
    } as ChargeModifierParameterDto;
  }

  removeParameter(parameter: ChargeModifierParameterDto[], name: string) {
    return parameter.filter((obj) => obj.name !== name);
  }

  async onSave() {
    try {
      this.busySaving$.next(true);
      const valid = await this.validate();
      if (!valid) {
        this.busySaving$.next(false);
        return;
      }

      const modifierCode = this.data[0]?.tariffCode;
      const invoice = this.data[0]?.invoice;
      const lines = invoice?.Lines.filter(
        (line) =>
          ((line.LineType === INVOICE_LINE_TYPE.PROCEDURE ||
            line.LineType === INVOICE_LINE_TYPE.MODIFIER) &&
            line.TariffCode) ||
          ((line.LineType === INVOICE_LINE_TYPE.MEDICINE ||
            line.LineType === INVOICE_LINE_TYPE.CONSUMABLE) &&
            line.NappiCode)
      );

      const parameters = this.modifierParameterInfo[0].parameters;
      if (parameters.length > 0) {        
        const isValidTime = await this.validateTime();
        if (!isValidTime) {
          this.busySaving$.next(false);
          return;
        }
        for (let p = 0; parameters.length > p; p++) {
          const parameter = parameters[p];
          switch (parameter.uiElement) {
            case ModifierUIElements.TIME_PICKER: {              
              const chargeModifierParameter = this.bulidParameters();
              if (lines) {
                for (let i = 0; lines.length > i; i++) {
                  if (
                    lines[i].TariffCode === modifierCode &&
                    INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType &&
                    lines[i].LineCodeSelected
                  ) {
                    lines[i].ModifierParameter = chargeModifierParameter;
                    lines[i].RcfValue = MoneyUtils.toCents(this.form.value.RCF);
                  }
                }
              }
              break;
            }
            case ModifierUIElements.INPUT_DECIMAL:{
              const height = this.form.value.height;              
              const weight = this.form.value.weight;
              if (lines) {
                for (let i = 0; lines.length > i; i++) {
                  if (lines[i].TariffCode === modifierCode && INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType && lines[i].LineCodeSelected) {
                    const param = this.getCheckModParamObject(
                      parameter.name,
                      parameter.name === "weight"? weight : height
                    );
                    const modifierParameterIndex = lines[i].ModifierParameter.findIndex(
                      (existingParam) => existingParam.name === param.name
                    );                    
                    if (modifierParameterIndex !== -1) {
                      lines[i].ModifierParameter[modifierParameterIndex] = param;
                    } else {
                      lines[i].ModifierParameter.push(param);
                    }
                  }
                }
              }
              break;
            }
            case ModifierUIElements.COMBO:{
              const errorMessage = this.validateCombo();
              if (errorMessage) {
                this.busySaving$.next(false);
                this.dialogService.showWarning(errorMessage);
                return;
              }
              
              if (lines) {
                for (let i = 0; lines.length > i; i++) {
                  if (
                    lines[i].TariffCode === modifierCode &&
                    INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType &&
                    lines[i].LineCodeSelected
                  ) {
                    let checkModParam = {};
                    if (parameter.name === ModifierParameters.ASSISTANT_PROVIDER_DISPLAY_NAME) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.asstproviderpracticenumber.FullName);
                    } else if (parameter.name === ModifierParameters.ASSISTANT_PROVIDER_PRACTICE_NUMBER 
                      && this.form.value.asstproviderpracticenumber) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.asstproviderpracticenumber.TreatingPracticeNumber);
                    } else if (parameter.name === ModifierParameters.ASSISTANT_PROVIDER_COUNCIL_NUMBER) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.asstproviderpracticenumber.HPCSANumber);
                    } else if (parameter.name === ModifierParameters.ASSISTANT_PROVIDER_COUNCIL_TYPE_DESCRIPTION) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.asstproviderpracticenumber.FullName);
                    } else if (parameter.name === ModifierParameters.PROVIDER_PRACTICE_NUMBER
                      && this.form.value.providerpracticenumber) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.providerpracticenumber.TreatingPracticeNumber);
                    }
                    lines[i].ModifierParameter = this.removeParameter(
                      lines[i].ModifierParameter,
                      parameter.name
                    );
                    if(Object.keys(checkModParam).length > 0){
                      lines[i].ModifierParameter.push(checkModParam);
                    }
                  }
                }
              }
              break;
            }
            case ModifierUIElements.GRID: {
              if (lines) {
                for (let i = 0; lines.length > i; i++) {
                  if (
                    lines[i].TariffCode === modifierCode &&
                    INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType &&
                    lines[i].LineCodeSelected
                  ) {
                    const linkedIds = [];
                    const linkObjects: { [key: number]: boolean } = Object.entries(this.form.value)
                      .filter(([key]) => !isNaN(Number(key)))
                      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
                    for (const key in linkObjects) {
                      if (linkObjects[key] === true) {
                        linkedIds.push(key);
                      }
                    }
                    if (linkedIds.length === 0) {
                      this.busySaving$.next(false);
                      this.dialogService.showWarning(
                        'You have to select at least one procedure line.'
                      );
                      return;
                    }
                    lines[i].ModifierLinkedLineIds = linkedIds;
                  }
                }
              }
              break;
            }
            case ModifierUIElements.CHECKBOX: {
              if (lines) {
                for (let i = 0; lines.length > i; i++) {
                  if (
                    lines[i].TariffCode === modifierCode &&
                    INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType &&
                    lines[i].LineCodeSelected
                  ) {
                    let checkModParam = {};
                    if (parameter.name === ModifierParameters.ASSISTANT) {
                      checkModParam = this.getCheckModParamObject(parameter.name, this.form.value.is_for_assistant ? 'true' : 'false');
                    } else if (parameter.name === ModifierParameters.IS_TRY_ASST_FORMAT) {
                      checkModParam = this.getCheckModParamObject(parameter.name, '1');
                    }
                    lines[i].ModifierParameter = this.removeParameter(lines[i].ModifierParameter, parameter.name);
                    if (checkModParam) {
                      lines[i].ModifierParameter.push(checkModParam);
                    }
                  }
                }
              }
              break;
            }
            default:
              // Handle any unexpected uiElement values
              console.warn(`Unexpected uiElement value: ${parameter.uiElement}`);
          }
        }
      }
            
      const linesToMS = [];
      const modifierLine = lines.find(
        (line) => line.TariffCode === modifierCode && line.LineType == INVOICE_LINE_TYPE.MODIFIER && line.LineCodeSelected
      );
      
      if (parameters.length === 0 && modifierLine) {
        if (this.modifierRcfValue > 0) {
          modifierLine.RcfValue = MoneyUtils.toCents(this.modifierRcfValue);
        }
      }

      if (modifierLine) {
        const procedureLines = lines.filter((line) => line.LineType == INVOICE_LINE_TYPE.PROCEDURE);
        const otherLines = lines.filter(
          (line) =>
            (line.LineType == INVOICE_LINE_TYPE.MODIFIER && line.TariffCode != modifierCode ) ||
            line.LineType == INVOICE_LINE_TYPE.MEDICINE ||
            line.LineType == INVOICE_LINE_TYPE.CONSUMABLE
        );
        otherLines.forEach((line) => {
          line.IsApplyModifier = false;
        });
        linesToMS.push(...procedureLines);
        linesToMS.push(...otherLines);
        linesToMS.push(modifierLine);
      }

      const reqChargeDtoObj = this.modifierHelper.mapInvoiceToChargeDto(this.data[0], linesToMS);
      const error = await this.modifierRequirementsChecks(reqChargeDtoObj);
      if (error.length > 0) {
        this.busySaving$.next(false);
        this.dialogService.showErrorMessage(error);
      } else {
        const respChargeDtoObj = await this.modifierHelper.modifyCharge(reqChargeDtoObj);
        const chargeLines = this.modifierHelper.updateLineOrder(respChargeDtoObj.chargeLines);
        const invoiceLines = this.modifierHelper.mapChargeLinesToInvoiceLines(
          chargeLines,
          this.data[0].invoice?.Lines,
          this.modifierParameterInfo
        );
        this.busySaving$.next(false);
        this.dialogRef.close(invoiceLines);
      }
    } catch (error) {
      this.busySaving$.next(false);
      let errorMessage = 'Unknown Error';
      let isWarningMeesage = false;
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error;
        if (errorMessage.includes(this.bmiMoreThan35)){
          errorMessage = 'The BMI must be greater than 35 for modifier 0018.';
          isWarningMeesage = true;
        }else if (errorMessage.includes(this.maxWeight25)){
          errorMessage = 'The weight must be between 0 and 2.5 kg.';
          isWarningMeesage = true;
        }
      }
      if (isWarningMeesage) {
        this.dialogService.showWarning(errorMessage);
      } else {
        this.dialogService.showErrorMessage(errorMessage);
      }
    }
  }

  bulidParameters(): ChargeModifierParameterDto[] {
    const chargeModifierParameter: ChargeModifierParameterDto[] = [];

    const parameters = this.modifierParameterInfo[0].parameters;
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i];
      if (parameter.name === ModifierParameters.START_TIME) {
        const chargeModParam = {
          name: parameter.name,
          value: this.formatTime(this.form.value.StartTime),
        } as ChargeModifierParameterDto;
        chargeModifierParameter.push(chargeModParam);
      } else if (parameter.name === ModifierParameters.END_TIME) {
        const chargeModParam = {
          name: parameter.name,
          value: this.formatTime(this.form.value.EndTime),
        } as ChargeModifierParameterDto;
        chargeModifierParameter.push(chargeModParam);
      }
    }
    return chargeModifierParameter;
  }

  getPricingRequestObject(tariffCode: string) {
    const invoice = this.data[0].invoice;
    const reqObject: ModifierPricingRequest = {
      year: moment(invoice.DateOfService).year().toString(),
      tariffCode,
      disciplineCode: this.data[0].disciplineCode,
      placeOfService: this.data[0].placeOfService,
      planOptionCode: this.data[0].planOptionCode,
      practiceId: this.data[0].practiceId,
      providerId: this.data[0].providerId,
    };
    return reqObject;
  }

  async rcfPricing(requestObj: ModifierPricingRequest) {
    let price = 0;

    try {
      const res = await this.pricingService.getRcfPrice(requestObj);

      if (res) {
        price = res.price;
      } else {
        this.dialogService.showErrorMessage((res as any).error?.message || 'Unknown error');
      }
    } catch (error) {
      this.busySaving$.next(false);
      let errorMessage = 'Unknown Error';
      if (error instanceof HttpErrorResponse) {
        errorMessage = `Rcf pricing: ${error.error}. ${error.message}`;
      }
      this.dialogService.showErrorMessage(errorMessage);
    }

    return price;
  }

  async clinicalRcfPricing(requestObj: ModifierPricingRequest) {
    try {
      const resp = await this.pricingService.getClinicalRcfPrice(requestObj);

      if (resp) {
        return resp;
      } else {
        this.dialogService.showErrorMessage((resp as any).error?.message || 'Unknown error');
      }
    } catch (error) {
      this.busySaving$.next(false);
      let errorMessage = 'Unknown Error';
      if (error instanceof HttpErrorResponse) {
        errorMessage = `Clinical rcf pricing: ${error.error}. ${error.message}`;
      }
      this.dialogService.showErrorMessage(errorMessage);
    }
  }

  async validateTime() {
    let result = true;
    const startTime = this.form.controls.StartTime?.value;
    const endTime = this.form.controls.EndTime?.value;
    if(!startTime && !endTime){
      return true;
    }
    if (endTime - startTime < 0) {
      const response = await this.dialogService
        .showNoYesDialog(
          'Time crosses midnight. Are you sure you want to proceed?',
          '',
          DialogType.QUESTION
        )
        .closed.toPromise();
      if (response === 'NO') {
        result = false;
      }
    }
    return result;
  }

  formatTime(time: string): string {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes.padStart(2, '0')}`;
  }

  fixStartTime(event, name) {
    let value = event.target.value;
    if (value.length === 2 || value.length === 3) {
      value = value + '00';
      name == 'StartTime'
        ? this.form.controls.StartTime.setValue(value)
        : this.form.controls.EndTime.setValue(value);
    } else if (value.length === 4) {
      value = value.replace(':', '') + '0';
      name == 'StartTime'
        ? this.form.controls.StartTime.setValue(value)
        : this.form.controls.EndTime.setValue(value);
    }
  }

  async editModifier() {
    const invoice = this.data[0]?.invoice;
    const editLine = invoice?.Lines.find(
      (line: { LineCodeSelected: boolean }) => line.LineCodeSelected
    );
    if (editLine) {
      //Time modifier
      if (this.form.controls.StartTime) {
        const ChargeStartTime = moment(editLine.ChargeStart).format('HHmm');
        this.form.controls.StartTime.setValue(ChargeStartTime);
      }
      if (this.form.controls.EndTime) {
        const ChargeEndTime = moment(editLine.ChargeEnd).format('HHmm');
        this.form.controls.EndTime.setValue(ChargeEndTime);
      }    

      //Linked or Reduction modifier
      if (editLine.ModifierLinkedLineIds && editLine.ModifierLinkedLineIds.length > 0) {
        const lineIds = Object.keys(this.form.value);
        for (let i = 0; lineIds.length > i; i++) {
          const control = this.form.get(lineIds[i]);
          if (control) {
            control.setValue(false);
            const isChecked = editLine.ModifierLinkedLineIds.find(
              (id) => id.toString() === lineIds[i]
            );
            if (isChecked) {
              control.setValue(true);
            }
          }
        }
      }

      if (editLine.ModifierParameter && editLine.ModifierParameter.length > 0) {
        for (let i = 0; editLine.ModifierParameter.length > i; i++) {
          const parameter = editLine.ModifierParameter[i];
          if (parameter.name === 'is.for.assistant') {
            this.form.get('is_for_assistant')?.setValue(parameter.value === 'true' ? true : false);
          } else if (parameter.name === 'weight') {
            this.form.controls.weight.setValue(parameter.value);
          } else if (parameter.name === 'height') {
            this.form.controls.height.setValue(parameter.value);
            this.calculateBMI();
          } else if (parameter.name === ModifierParameters.PROVIDER_PRACTICE_NUMBER 
            || parameter.name === ModifierParameters.ASSISTANT_PROVIDER_PRACTICE_NUMBER) {
            const trtProvider = editLine.ModifierParameter.find((x: { name: string }) => x.name === parameter.name);
            if (trtProvider) {
              this.allProviders$.subscribe((providers) => {
                providers.forEach((provider) => {
                  if (provider.TreatingPracticeNumber.toLowerCase() === trtProvider.value.toLowerCase()) {
                    if (parameter.name === ModifierParameters.PROVIDER_PRACTICE_NUMBER) this.form.get('providerpracticenumber')?.setValue(provider);
                    else this.form.get('asstproviderpracticenumber')?.setValue(provider);
                  }
                });
              });
            }
          }
        }
      }
    }
  }

  getAssistingProviderValue(parameter: ChargeModifierParameterDto[], name: string) {
    const val = parameter.find((val) => val.name == name)?.value;
    return val.toUpperCase();
  }

  async recalculateModifiers() {
    this.busySaving$.next(true);
    const invoice = this.data[0]?.invoice;
    const lines = invoice?.Lines;
    if (lines) {
      for (let i = 0; lines.length > i; i++) {
        if (INVOICE_LINE_TYPE.MODIFIER == lines[i].LineType) {
          const pricingReqObject = this.getPricingRequestObject(lines[i].TariffCode);
          const rcfValue = await this.rcfPricing(pricingReqObject);
          lines[i].RcfValue = rcfValue;
        }
      }
    }
    const reqChargeDtoObj = this.modifierHelper.mapInvoiceToChargeDto(this.data[0], lines);
    const respChargeDtoObj = await this.modifierHelper.modifyCharge(reqChargeDtoObj);
    const invoiceLines = this.modifierHelper.mapChargeLinesToInvoiceLines(
      respChargeDtoObj.chargeLines,
      this.data[0].invoice?.Lines,
      this.modifierParameterInfo
    );
    this.busySaving$.next(false);
    this.dialogRef.close(invoiceLines);
  }

  validateTimeInput(event: KeyboardEvent) {
    const allowedChars = /[0-9:]/;
    const inputChar = event.key;

    if (!allowedChars.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateBMIInput(event: any) {
    const input = event.target.value + event.key; // Combine existing value and new input
    const regex = /^\d+(\.\d{0,2})?$/; // Regular expression for numbers with at most 2 decimal places
    if (!regex.test(input)) {
      event.preventDefault();
    }
  }

  calculateBMI() {
    const height = Number(this.form.controls.height.value) ?? 0;
    const weight = Number(this.form.controls.weight.value) ?? 0;
    const bmi = weight / ((height * height) / 10000);
    const roundedBMI = Math.round(bmi * 10) / 10;
    this.bmiValue = roundedBMI === Infinity || isNaN(roundedBMI) ? 0 : roundedBMI;
  }

  checkInvoiceLine(data: ModifierModelData, line: ModifierLink): boolean {    
    const isAnaesthetist = data.invoice?.TreatingProvider?.IsAnaesthetist;
    const providerAnaesthetist = data.invoice?.TreatingProvider?.Speciality === 'Anaesthetist';
    if(this.checkedModifiers.includes(data.tariffCode)){
      return true;
    } else if (isAnaesthetist || providerAnaesthetist) {
      if ((data.tariffCode == '0018' || data.tariffCode == '0019') && this.anaestheticModifierCodes.includes(line.code)) {
        const invoiceLine = data.invoice?.Lines?.find((invoiceLine) => invoiceLine.TariffCode === line.code);
        if (invoiceLine?.ChargeStart && invoiceLine?.ChargeEnd) {
          return true;
        }
      }
    }
    return false;
  }

  validateCombo(): string {
    let errorMessage = '';
    if (this.isMedScheme) {
      if (this.form.value.asstproviderpracticenumber && this.form.value.providerpracticenumber) {
        errorMessage = 'You can specify a treating provider OR an assisting provider, not both.';
        this.form.get('asstproviderpracticenumber')?.setValue(null);
        this.form.get('providerpracticenumber')?.setValue(null); 
      } 
    } else if (this.data[0].tariffCode != ModifierCodes.M0011 && !this.form.value.asstproviderpracticenumber) {
      errorMessage = 'Please select assistant provider.';
    }
    return errorMessage;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
