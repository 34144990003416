<div class="flex flex-row h-full w-full content-shadow ml-[2px]">

    <!-- Context Panel -->
    <div [class.hidden]="!_panelVisible" class="h-full w-[246px] bg-hb-base-white">
        <ng-container #vc></ng-container>
    </div>

    <!-- Toolbar -->
    <div class="h-full w-14 bg-hb-base-white border-l border-l-hb-base-divider-grey">
        <div class="h-full flex flex-col">

            <!-- Top Section -->
            <ng-container *ngIf="contextToolbarService.topGroups$ | async as topGroups">
                <div class="flex-1">
                    <ng-container *ngFor="let group of topGroups; let last = last">
                        <ng-container [ngTemplateOutlet]="toolbarGroup"
                            [ngTemplateOutletContext]="{$implicit: group}"></ng-container>
                        <div *ngIf="!last" class="bg-hb-base-divider-grey mx-2 w-10 h-[1px]"></div>
                    </ng-container>
                </div>
            </ng-container>

            <!-- Divider -->
            <div class="divider"></div>

            <ng-container *ngIf="contextToolbarService.bottomGroups$ | async as bottomGroups">

                <!-- Bottom Section -->
                <div class="flex-1">
                    <ng-container *ngFor="let group of bottomGroups; let last = last">
                        <ng-container [ngTemplateOutlet]="toolbarGroup"
                            [ngTemplateOutletContext]="{$implicit: group}"></ng-container>
                        <div *ngIf="!last" class="bg-hb-base-divider-grey mx-2 w-10 h-[1px]"></div>
                    </ng-container>
                </div>
            </ng-container>

        </div>


    </div>

</div>

<ng-template #toolbarItem let-item>
    <button [class.active]="item?.id === (contextToolbarService.activeItem$ | async)?.id" matRipple
        class="toolbar-button !w-full !h-12" (click)="handleClick(item)">
        <mat-icon [matBadge]="item?.badgeOptions?.badgeCount ?? 0" matBadgeSize="small" [matBadgeHidden]="!item?.badgeOptions?.badgeCount" [svgIcon]="item.useCustomIcon ? item.icon : ''">{{!item.useCustomIcon ? item.icon :
            ''}}</mat-icon>
    </button>
</ng-template>

<ng-template #toolbarGroup let-group>
    <div *ngFor="let item of group?.items">
        <ng-container [ngTemplateOutlet]="toolbarItem" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
    </div>
</ng-template>