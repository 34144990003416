<div mat-dialog-title>
  <div class="flex flex-row justify-between">
    <div>Preview</div>
    <button mat-icon-button (click)="onNoClick()">
      <div class="flex items-center justify-center">
        <mat-icon>close</mat-icon>
      </div>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="h-full overflow-auto !w-full !mx-0 !px-0"> <!-- !importants set to global override mat-dialog-content styles -->
  <div *ngIf="data.Subject" class="text-lg p-5">{{ data.Subject }}</div>
  <div [innerHTML]="data.Body" class="p-5"></div>
</div>
<div mat-dialog-actions class="flex flex-row justify-end">
  <button mat-stroked-button color="accent" (click)="onNoClick()">Close</button>
</div>

