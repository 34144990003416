/*
  Helper module to generate some common pdf structures.
*/
import moment = require("moment");
import {
  BasePdfReportData,
  CellType,
  MonthlyTransactionReportDetails,
  MonthlyTransactionReportInfo,
  MonthlyTransactionReportModel,
  PDFParameter,
  REPORT_NAME,
  ReportContent,
  ReportHeader,
  TaxableAmount
} from '@meraki-flux/schema';
import { BasePdfGenerator } from '../generator/pdf.base';
import { PDFMakeHelper } from '../helpers/pdf-make.helper';

export class MonthlyTransactionReportPdfGenerator extends BasePdfGenerator {

  private readonly reportModel: MonthlyTransactionReportDetails

  readonly lineMargin = 6;

  public override generateReport(): ReportContent {

    const isVat = this.reportModel.IsVatPractice;
    this.generateReportName();
    this.generateReportHeaders();
    this.generateHeader(isVat)

    this.generateTextLine('DEBITS', true);

    this.generateTableLine('Admin', isVat, false, this.reportModel.TotalAdminAmount);
    this.generateTableLine('Procedures', isVat, false, this.reportModel.TotalProceduresAmount);
    this.generateTableLine('Consumables', isVat, false, this.reportModel.TotalConsumablesAmount);
    this.generateTableLine('Medicines', isVat, false, this.reportModel.TotalMedicinesAmount);
    this.generateTableLine('Modifiers', isVat, false, this.reportModel.TotalModifierAmount);
    this.generateTableLine('', isVat, true, this.calculateTotal(this.reportModel.TotalAdminAmount,
      this.reportModel.TotalProceduresAmount, this.reportModel.TotalConsumablesAmount, this.reportModel.TotalMedicinesAmount,
      this.reportModel.TotalModifierAmount), true);

    this.generateTableLine([{text: 'Plus', italics: true}, ': Debit note invoices'], isVat, false, this.reportModel.TotalDebitNoteAmount);
    this.generateTableLine('Payment corrections', isVat, false, this.reportModel.TotalPaymentCorrectionAmount, false, 22);
    this.generateTableLine('Refunds', isVat, false, this.reportModel.TotalRefundAmount, false, 22);
    this.generateTableLine('', isVat, true, this.calculateTotal(this.reportModel.TotalDebitNoteAmount,
      this.reportModel.TotalPaymentCorrectionAmount, this.reportModel.TotalRefundAmount), true);

    this.generateTextLine('', false); // blank line

    this.generateTextLine('CREDITS', true);

    this.generateTableLine('Medical aid payments', isVat, false, this.reportModel.TotalMedicalAidPaymentAmount);
    this.generateTableLine('Patient payments', isVat, false, this.reportModel.TotalPatientPaymentAmount);
    this.generateTableLine('Write-offs: Bad debt', isVat, false, this.reportModel.TotalBadDebtWriteOffAmount);
    this.generateTableLine('Write-offs: Small amount', isVat, false, this.reportModel.TotalSmallBalanceWriteOffAmount);
    this.generateTableLine('Credit notes', isVat, false, this.reportModel.TotalCreditNotesAmount);
    this.generateTableLine('', isVat, true, this.calculateTotal(this.reportModel.TotalMedicalAidPaymentAmount,
      this.reportModel.TotalPatientPaymentAmount, this.reportModel.TotalBadDebtWriteOffAmount,
      this.reportModel.TotalSmallBalanceWriteOffAmount, this.reportModel.TotalCreditNotesAmount), true);

    return this.buildReport();
  }

  private calculateTotal(...taxableAmounts): TaxableAmount {
    const result: TaxableAmount = new TaxableAmount(0, 0, 0);
    taxableAmounts.forEach(i => {
      if (i instanceof TaxableAmount) {
        result.addTaxableAmount(i);
      } else {
        result.addAmount(i);
      }
    })
    return result;
  }

  private generateHeader(isVat: boolean) {
    const content = [];
    content.push({
      text: '',
      bold: true,
      fillColor: "#ececec",
    });
    if (isVat) {
      content.push({
          text: 'Amount ex. VAT',
          bold: true,
          fillColor: "#ececec",
          style: {alignment: 'right'}
        },
        {
          text: 'VAT Amount',
          bold: true,
          fillColor: "#ececec",
          style: {alignment: 'right'}
        })
    }
    content.push({
      text: 'Total amount',
      bold: true,
      fillColor: "#ececec",
      style: {alignment: 'right'}
    });
    this.content.push({
      layout: "noBorders",
      margin: [0, 0, 0, this.lineMargin],
      table: {
        widths: isVat ? ["*", "15%", "15%", "15%"]: ["*", "15%"],
        body: [content],
      },
    });
  }

  private generateTextLine(text: string, bold: boolean) {
    this.content.push({
      layout: "noBorders",
      margin: [5, 0, 0, this.lineMargin],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text,
              bold: bold,
            }
          ],
        ],
      },
    });
  }

  private generateTableLine(text, isVat: boolean, bold: boolean, value: TaxableAmount | number, topBorder = false, leftMargin: number = 0) {

    let widths = ['*', '15%']
    if (isVat && value instanceof TaxableAmount) {
      widths = ['*', '15%', '15%', '15%']
    }
    let content = [
      {text: text, bold: bold, style: {alignment: 'left'}, border: [false, false, false, false]}
    ];
    if (isVat && value instanceof TaxableAmount) {
      content.push({text: this.formatCurrency(value.amountExVat), bold: bold, style: {alignment: 'right'}, border: [false, topBorder, false, false]});
      content.push({text: this.formatCurrency(value.vatAmount), bold: bold, style: {alignment: 'right'}, border: [false, topBorder, false, false]});
    }
    if (value instanceof TaxableAmount) {
      content.push({text: this.formatCurrency(value.amount), bold: bold, style: {alignment: 'right'}, border: [false, topBorder, false, false]})
    } else {
      content.push({text: this.formatCurrency(value), bold: bold, style: {alignment: 'right'}, border: [false, topBorder, false, false]})
    }
    this.content.push({
      layout: {
        hLineWidth: function (i: any, node: any) {
          return 1;
        },
        vLineWidth: function (i: any, node: any) {
          return 0;
        },
        hLineColor: function () {
          return 'lightgrey';
        },
        vLineColor: function () {
          return '';
        },
        defaultBorder: false
      },
      heights: [10],
      margin: [leftMargin + 15, 0, 5, this.lineMargin],
      table: {
        widths: widths,
        body: [
          content
        ],
      },
    });
  }

  formatCurrency(value: number) {
    return PDFMakeHelper.format({value: value, type: CellType.CURRENCY});
  }

  // @ts-ignore
  private generateBalanceSection(text: string, balance: number, topBorder: boolean) {
    this.content.push({
      layout: {
        hLineWidth: function (i: any, node: any) {
          const borderIndex = topBorder ? 1 : 0;
          return i === borderIndex ? 1 : 0;
        },
        vLineWidth: function (i: any, node: any) {
          return 0;
        },
        hLineColor: function () {
          return 'lightgrey';
        },
        vLineColor: function () {
          return '';
        }
      },
      margin: [0, 0, 0, this.lineMargin],
      borderColor: ["", "", "lightgrey", ""],
      table: {
        widths: ["50%", "50%"],
        body: [
          [
            {
              text: text,
              bold: true,
            },
            {
              text: this.formatCurrency(balance),
              bold: true,
              style: {
                alignment: 'right'
              }
            }
          ],
        ],
      },
    });
  }

  constructor(data: MonthlyTransactionReportModel) {
    super();
    this.data = this.build(data);
    this.reportModel = data.Details;
  }

  build(report: MonthlyTransactionReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.reportName = REPORT_NAME.MONTHLY_TRANSACTION;
    reportData.reportNameColor = "#000000";
    reportData.dateHeader = `Date generated ${moment(new Date()).format('DD/MM/YYYY')}`;
    reportData.bpn = report.ReportInfo.PracticeId
    reportData.orientation = "portrait";
    reportData.headers = this.buildHeader(report.ReportInfo);
    reportData.footerText = "\t\u200B".repeat(60)
    reportData.footer = {
      hbLogo: true
    }
    return reportData;
  }

  private buildHeader(reportInfo: MonthlyTransactionReportInfo): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: {value: reportInfo.Practice, decoration: {bold: true}}
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: {value: reportInfo.PracticeId, decoration: {bold: true}}
    });
    const rightParameters: PDFParameter[] = [];
    rightParameters.push(
      {name: 'Date range type', data: {value: reportInfo.DateRangeType, decoration: {bold: true}}});
    rightParameters.push(
      {name: 'Month/year', data: {value: `${reportInfo.Month}/${reportInfo.Year}`, decoration: {bold: true}}});
    return [{
      left: leftParameters,
      right: rightParameters
    }];
  }
}

