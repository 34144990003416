import * as moment from "moment";
import {DateUtils} from "@meraki-flux/common";
import {
  ACCOUNT_ENTITY_TYPE,
  ACCOUNT_TYPE,
  Allocation,
  BaseAccount,
  BaseAccountMember,
  BaseInvoice,
  Branch,
  CellType,
  InvoiceLine,
  NameValue,
  Payment,
  Practice,
  ProofOfPaymentReportModel,
  ProofOfPaymentReportRequest,
  StatementTable
} from "@meraki-flux/schema";
import { StatementReportBuilder } from "../statement-report/statement-report-builder";

export class ProofOfPaymentReportBuilder {

  private readonly DATE_FORMAT = "DD/MM/YYYY";
  private readonly statementReportBuilder = new StatementReportBuilder();

  async build(reportRequest: ProofOfPaymentReportRequest, practice: Practice, account: BaseAccount, mainMember: BaseAccountMember): Promise<ProofOfPaymentReportModel> {
    const branch = this.matchBranch(practice.Branches, account.HomeBranch);
    const invoiceResult = await this.buildStatementTables(reportRequest, account);
    return {
      PracticeId: practice.BillingPracticeNumber,
      PracticeName: practice.PracticeName,
      Header: await this.buildMainHeader(account, mainMember),
      PracticeHeader: await this.buildPracticeHeader(practice, branch),
      AccountHeader: await this.buildAccountHeader(account, mainMember),
      StatementTables: invoiceResult.statementTables,
      InfoTable: await this.buildFooterInfoTable(account, practice, branch),
      FooterText:  `Please note that you are personally liable for any difference between the\n${"\t\u200B".repeat(3)}claimed amount and the amount paid by your medical aid.`,
      StatementDate: moment(new Date()).format(this.DATE_FORMAT)
    };
  }

  matchBranch(practiceBranches: Branch[], accountHomeBranch: string) {
    let matchingBranch;

    if (accountHomeBranch)
        matchingBranch = practiceBranches.find((branch) =>
           branch.Name === accountHomeBranch && branch.Active
        )

    if (!matchingBranch)
        matchingBranch = practiceBranches.find((branch) =>
           branch.IsMainBranch && branch.Active
        )
    return matchingBranch;
  }

  async buildMainHeader(account: BaseAccount, patient : BaseAccountMember) {
    return `Proof of Payment - ${patient.Title??""} ${patient.Name} ${patient.Surname} (${account.AccountNo})`;
  }

  async buildFooterInfoTable(account: BaseAccount, practice: Practice, branch: Branch) {
    return {
      bankDetails : {
        practiceName: `${branch?.BankDetails?.ChequeAccountDetails?.AccountHolderName}, ${branch?.BankDetails?.ChequeAccountDetails?.Bank}`,
        branchName: branch?.BankDetails?.ChequeAccountDetails?.BranchCode,
        accountNo: branch?.BankDetails?.ChequeAccountDetails?.AccountNo,
        accountType: branch?.BankDetails?.ChequeAccountDetails?.AccountType
      },
      bankDetailsEft: branch?.BankDetails?.SameAsEFT ? undefined : {
        practiceName: `${branch?.BankDetails?.EFTAccountDetails?.AccountHolderName}, ${branch?.BankDetails?.EFTAccountDetails?.Bank}`,
        branchName: branch?.BankDetails?.EFTAccountDetails?.BranchCode,
        accountNo: branch?.BankDetails?.EFTAccountDetails?.AccountNo,
        accountType: branch?.BankDetails?.EFTAccountDetails?.AccountType
      },
      eftInfo : {
        paymentRef: account.AccountNo,
        email: branch?.ContactDetails?.EmailAddress
      },
      companyInfo : {
        registrationNo: practice.CompanyRegistrationNo,
        vatNo: practice.VatNo
      }
    }
  }

  async buildStatementTables(reportRequest: ProofOfPaymentReportRequest, account: BaseAccount)  {
    const statementTables : StatementTable[] = [];
    let patientLiable = 0;
    let medicalAidLiable = 0;
    let balance = 0;
    const invoices = [];
    for (const inv of reportRequest.Invoices) {
      invoices.push(inv);
    }
    invoices.forEach( (invoice) => {
      patientLiable += invoice.Balance?.PatientLiable
      medicalAidLiable += invoice.Balance?.MedicalAidLiable
      balance += invoice.Balance?.Outstanding
    });
    for (const invoice of invoices) {
      const table = await this.buildStatementTable(reportRequest, account, invoice);
      statementTables.push(table);

    }
    return {
      statementTables: statementTables
    };
  }

  async buildStatementTable(req: ProofOfPaymentReportRequest, account: BaseAccount, invoice: BaseInvoice) {
    return await this.statementReportBuilder.buildStatementTable(req.Practice, account, req.AccountMember, invoice, req.Transactions, req.InvoicePayments);
  }

  async buildTableRow(invoiceLine: InvoiceLine) {
    const row = [];
    row.push({
      value: invoiceLine.TariffCode
    })
    const desc = this.resize((invoiceLine.Description??"").match(/.{1,70}/g)??[], 3).join('\n')
    row.push({
      value: `${desc}\nICD-10: ${invoiceLine.DiagnosisCodes}`
    })
    row.push({
      value: invoiceLine.AmountBilled,
      type: CellType.CURRENCY
    })
    row.push({
      value: invoiceLine.Balance?.MedicalAidLiable||0,
      type: CellType.CURRENCY
    })
    row.push({
      value: invoiceLine.Balance?.PatientLiable||0,
      type: CellType.CURRENCY
    })
    row.push({
      value: invoiceLine.Balance?.Outstanding||0,
      type: CellType.CURRENCY
    })
    return row;
  }

  async buildTablePaymentRow(invoice: BaseInvoice, allocation: Allocation, payment: Payment) {
    const row = [];
    row.push({
      value: ""
    })
    const descFull = `${moment(payment.PaymentDate).format(this.DATE_FORMAT)}: ${payment.Type === 'Medical Aid' ? invoice.Account?.SchemeName : payment.Type} payment`
    const desc = this.resize((descFull??"").match(/.{1,70}/g)??[], 3).join('\n')
    row.push({
      value: `${desc} \n Rcpt: ${payment.PaymentReceiptNo}`
    })
    row.push({
      value: allocation.AmountAllocated * -1,
      type: CellType.CURRENCY
    })
    row.push({
      value: "",
    })
    row.push({
      value: "",
    })
    row.push({
      value: "",
    })
    return row;
  }

  async buildTotalRow(invoice: BaseInvoice) {
    const row = [];
    row.push({
      value: ""
    })
    row.push({
      value: ""
    })
    row.push({
      value: "",
    })
    row.push({
      value: invoice.Balance.MedicalAidLiable||0,
      type: CellType.CURRENCY,
      decoration: {
        bold: true
      }
    })
    row.push({
      value: invoice.Balance?.PatientLiable||0,
      type: CellType.CURRENCY,
      decoration: {
        bold: true
      }
    })
    row.push({
      value: invoice.Balance?.Outstanding||0,
      type: CellType.CURRENCY,
      decoration: {
        bold: true
      }
    })
    return row;
  }

  resize(arr, size) {
    const add = arr.length > size;
    while (arr.length > size) { arr.pop(); }
    if (add) {
      arr[size-1] = arr[size - 1].replace(/.$/,'...')
    }
    return arr;
  }

  async buildRowHeaders() {
    return ["Tariff \ncode", `Description${"\t\u200B".repeat(23)}`, `Amount${"\t\u200B".repeat(1)}`, `Med.${"\xa0"}aid${"\t\u200B".repeat(1)}\nliable`, `Patient liable${"\t\u200B".repeat(1)}`, `Balance${"\t\u200B".repeat(1)}`].map(n => {
      return {
        value: n
      }
    });
  }

  async buildPatientTableHeader(invoice: BaseInvoice) {
    const rows = [];
    rows.push(await this.buildPatientTableHeaderLine(invoice));
    return {
      outlined: true,
      rows: rows
    }
  }

  async buildInvoiceTableHeader(reportRequest: ProofOfPaymentReportRequest, account: BaseAccount, invoice: BaseInvoice) {
    const rows = [];
    rows.push(await this.buildInvoiceTableHeader1Line(invoice));
    rows.push(await this.buildInvoiceTableHeader2Line(invoice, account));
    rows.push(await this.buildInvoiceTableHeader3Line(invoice));
    rows.push(await this.buildInvoiceTableHeader4Line(invoice));
    return {
      rows: rows,
      outlined: true,
      outlineColor: '#ececec'
    }
  }

  async buildInvoiceICDLine(invoice: BaseInvoice) {
    return {
      icdCode: `ICD-10 diagnosis: ${invoice.HeaderDiagnosisCodes}`,
      status: `Status: ${invoice.ClaimInfo?.ClaimStatus ? invoice.ClaimInfo?.ClaimStatus : 'Saved'}`,
    }
  }

  async buildPatientTableHeaderLine(invoice: BaseInvoice) {
    const rows = [];
    await this.addKeyValue(rows, "Patient", `${invoice.Patient?.Title??""} ${invoice.Patient?.Name} ${invoice.Patient?.Surname}`, true);
    if (invoice.Patient?.DateOfBirth) {
      await this.addKeyValue(rows, "D.o.b", moment(DateUtils.toSafeDate(invoice.Patient?.DateOfBirth)).format(this.DATE_FORMAT), true);
    }
    await this.addKeyValue(rows, "Dep. code", invoice.Patient?.DependantCode, true);
    return rows;
  }

  async buildInvoiceTableHeader1Line(invoice: BaseInvoice) {
    const rows = [];
    if (invoice.DateOfService) {
      await this.addKeyValue(rows, "Date of service", moment(invoice.DateOfService).format(this.DATE_FORMAT), true);
    }
    if (invoice.TreatingProvider) {
      await this.addKeyValue(rows, "Treating provider", `${invoice.TreatingProvider?.FullName.split(',')[0]}`, true);
    }
    await this.addKeyValue(rows, "HPCSA", invoice.TreatingProvider?.HPCSANumber);
    await this.addKeyValue(rows, "Treating prac. no.", invoice.TreatingProvider?.TreatingPracticeNumber);
    return rows;
  }

  async buildInvoiceTableHeader2Line(invoice: BaseInvoice, account: BaseAccount) {
    const rows = [];
    if (invoice.InvoiceDate) {
      // @ts-ignore
      await this.addKeyValue(rows, "Invoice date", moment(DateUtils.toSafeDate(invoice.InvoiceDate)).format(this.DATE_FORMAT), true);
    }
    await this.addKeyValue(rows, "Invoice no.", invoice.InvoiceNo);
    await this.addKeyValue(rows, "Dispensing no.", invoice.TreatingProvider?.DispensingLicNum);
    if (invoice.Branch && account.HomeBranch !== invoice.Branch) {
      await this.addKeyValue(rows, "Branch", invoice.Branch);
    }
    return rows;
  }

  async buildInvoiceTableHeader3Line(invoice: BaseInvoice) {
    const rows = [];
    await this.addKeyValue(rows, "Auth. no.", invoice.AuthorizationNo);
    await this.addKeyValue(rows, "Referral no.", invoice.ReferralNo);
    await this.addKeyValue(rows, "Lab ref. no.", invoice.LabReferenceNo);
    return rows;
  }

  async buildInvoiceTableHeader4Line(invoice: BaseInvoice) {
    const rows = [];
    if (invoice.ReferringProvider) {
      await this.addKeyValue(rows, "Referring provider", `${invoice.ReferringProvider?.FullName.split(',')[0]} (${invoice.ReferringProvider?.TreatingPracticeNumber})`, true);
    }
    if (invoice.AssistingProvider) {
      await this.addKeyValue(rows, "Assisting provider", `${invoice.TreatingProvider?.FullName.split(',')[0]} (${invoice.AssistingProvider?.TreatingPracticeNumber})`, true);
    }
    return rows;
  }

  async buildPracticeHeader(practice: Practice, branch?: Branch) {
    const right: NameValue[] = await this.buildRightPracticeHeaders(branch);
    const left: NameValue[] = await this.buildLeftPracticeHeaders(branch);
    return {
      left: left,
      right: right
    }
  }

  async buildRightPracticeHeaders(branch?: Branch) {
    const right: NameValue[] = [];
    await this.addKeyValue(right, "Office no", branch?.ContactDetails?.OfficeNo);
    await this.addKeyValue(right, "Email", branch?.ContactDetails?.EmailAddress);
    await this.addKeyValue(right, "Fax", branch?.ContactDetails?.FaxNo);
    await this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address1);
    await this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address2);
    await this.addKeyValue(right, "Email", branch?.AdditionalEmails?.Address3);
    await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone1);
    await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone2);
    await this.addKeyValue(right, "Phone", branch?.AdditionalPhones?.Phone3);
    return right;
  }

  async buildLeftPracticeHeaders(branch?: Branch) {
    const left: NameValue[] = [];
    left.push({name: "", value: {value:""}});
    await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line1);
    await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line2);
    await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line3);
    await this.addKeyValue(left, null, branch?.PhysicalAddress?.Code);
    return left;
  }

  async buildAccountHeader(account: BaseAccount, patient: BaseAccountMember) {
    const right: NameValue[] = await this.buildRightAccountHeaders(account, patient);
    const left: NameValue[] = await this.buildLeftAccountHeaders(account, patient);
    return {
      name: "Proof of Payment",
      left: left,
      right: right
    }
  }

  async buildRightAccountHeaders(account: BaseAccount, patient: BaseAccountMember) {
    const right: NameValue[] = [];
    await this.addKeyValue(right, "Account no.", account.AccountNo);
    await this.addKeyValue(right, "File no.", patient.PatientFileNo);
    right.push({name:"", value: {value:""}});
    let medAid = "Private";
    if (account.AccountType === ACCOUNT_TYPE.MEDICAL_AID) {
      medAid = `${account.Scheme} ${account.Plan} ${account.Option}`;
    }
    await this.addKeyValue(right, "Medical aid", medAid, true);
    await this.addKeyValue(right, "Member no.", account.MemberNo, true);
    return right;
  }

  async buildLeftAccountHeaders(account: BaseAccount, patient: BaseAccountMember) {
    const left: NameValue[] = [];
    if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
      await this.addKeyValue(left, null, account.CompanyName, true);
    } else {
      await this.addKeyValue(left, null, `${patient.Title??""} ${patient.Name} ${patient.Surname}`, true);
    }
    await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line1);
    await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line2);
    await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Line3);
    await this.addKeyValue(left, null, patient.Contact.PostalAddress?.Code);
    if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
      left.push({name:"", value: {value:""}});
      await this.addKeyValue(left, "Company reg. no.", account.CompanyRegistrationNo, true);
      await this.addKeyValue(left, "VAT no.", account.VATNo, true);
    }
    return left;
  }


  async addKeyValue(col: NameValue[], key: string, value?: string, bold: boolean = false, italic: boolean = false) {
    if (value && value !== "") {
      col.push({name: key, value: {value: value, bold: bold, italic: italic}});
    }
    return col;
  }

}
