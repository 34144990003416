import { Injectable } from "@angular/core";
import * as moment from "moment";
import { MoneyUtils } from "../utils/money-utils";
import {
  BaseAccount,
  BaseAccountMember,
  BaseAddon,
  BaseInvoice,
  Branch,
  InvoiceProvider,
  Practice
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class VariableResolverService {
  private readonly DATE_FORMAT = "DD/MM/YYYY";
  private placeholderRegex = /\$\{(.*?)\}/g;

  public resolveVariables(
    text: string,
    practice?: Practice,
    account?: BaseAccount,
    member?: BaseAccountMember,
    invoice?: BaseInvoice,
    provider?: InvoiceProvider,
    addon?: BaseAddon,
  ) {
    return text.replace(this.placeholderRegex, (placeholderValue) => {
      return this.getPlaceholderValue(placeholderValue, practice, account, member, invoice, provider, addon) || "";
    });
  }

  public resolveDynamicVariables(text: string, dynamicPlaceholders: {[key: string]: string}) {
    return text.replace(this.placeholderRegex, (placeholderValue) => {
      return this.getDynamicPlaceholderValue(placeholderValue, dynamicPlaceholders) || "";
    });
  }

  private getDynamicPlaceholderValue(
    placeholder: string,
    dynamicPlaceholders: {[key: string]: string}
  ) {
    const placeHolderText = placeholder.replace("${", "").replace("}", "").trim();
    return this.pseudoSwitch(dynamicPlaceholders, placeHolderText);
  }

  private pseudoSwitch(dynamicPlaceholders: {[key: string]: string}, val) {
    if(dynamicPlaceholders[val]) {
      return dynamicPlaceholders[val];
    }
    return "";
  }

  private getPlaceholderValue(
    placeholder: string,
    practice?: Practice,
    account?: BaseAccount,
    member?: BaseAccountMember,
    invoice?: BaseInvoice,
    provider?: InvoiceProvider,
    addon?: BaseAddon,
  ) {
    const placeHolderText = placeholder
      .replace("${", "")
      .replace("}", "")
      .trim();
    const mainBranch: Branch | undefined = practice?.Branches?.find(
      (branch: Branch) => branch.IsMainBranch
    );
    switch (placeHolderText) {
      case "BranchName":
        return mainBranch?.Name;
      case "BranchAddress":
        return `${mainBranch?.PhysicalAddress?.Line1}, ${mainBranch?.PhysicalAddress?.Line2}, ${mainBranch?.PhysicalAddress?.Line3}, ${mainBranch?.PhysicalAddress?.Code}`;
      case "BranchOfficeNo":
        return mainBranch?.ContactDetails?.OfficeNo;
      case "BranchEmail":
        if(practice?.IsMultiBranch && invoice?.Branch)
          return practice.Branches.find(f => f.Name === invoice.Branch)?.ContactDetails?.EmailAddress
        return mainBranch?.ContactDetails?.EmailAddress;
      case "PracticeName":
        return practice?.PracticeName;
      case "PracticeAddress":
        return `${mainBranch?.PhysicalAddress?.Line1}, ${mainBranch?.PhysicalAddress?.Line2}, ${mainBranch?.PhysicalAddress?.Line3}, ${mainBranch?.PhysicalAddress?.Code}`;
      case "PracticeOfficeNo":
        if(practice?.IsMultiBranch && invoice?.Branch)
          return practice.Branches.find(f => f.Name === invoice.Branch)?.ContactDetails?.OfficeNo
        return practice?.IsMultiBranch ? mainBranch?.ContactDetails?.OfficeNo : mainBranch?.ContactDetails?.OfficeNo; //TODO: update once practice model has an officeno
      case "PracticeEmail":
        if(practice?.IsMultiBranch && invoice?.Branch)
          return practice.Branches.find(f => f.Name === invoice.Branch)?.ContactDetails?.EmailAddress
        return mainBranch?.ContactDetails?.EmailAddress;
      case "PatientTitle":
        return member?.Title || "";
      case "PatientPrefName":
        return member?.PreferredName || member?.Name || "";
      case "PatientFirstName":
        return member?.Name || "";
      case "PatientLastName":
        return member?.Surname || "";
      case "AccountNo":
        return account?.AccountNo || "";
      case "AccountBalance":
        return invoice ? `R${MoneyUtils.balanceFromCents(invoice.Balance)?.Outstanding}` : `R${MoneyUtils.balanceFromCents(account?.Balance)?.Outstanding}`;
      case "PatientLiable":
        return invoice ? `R${MoneyUtils.balanceFromCents(invoice.Balance)?.PatientLiable}` : `R${MoneyUtils.balanceFromCents(account?.Balance)?.PatientLiable}`;
      case "MedAidLiable":
        return invoice ? `R${MoneyUtils.balanceFromCents(invoice.Balance)?.MedicalAidLiable}` : `R${MoneyUtils.balanceFromCents(account.Balance)?.MedicalAidLiable}`;
      // TODO: Where to get that?
      case "AppointmentDate":
        return moment(invoice?.DateOfService).format(this.DATE_FORMAT);
      // TODO: Where to get that?
      case "AppointmentTime":
        return moment(invoice?.DateOfService).format("HH:MM");
      case "ProviderFullName":
        return provider?.FullName??"";
      case "ProviderInitialsSurname":
        return provider?.FullName??""
      // TODO: Where to get that?
      case "AppointmentVideoLink":
        return "";
      // TODO: Where to get that?
      case "PaymentDate":
        return "";
      case "InvoiceNumber":
        return invoice?.InvoiceNo;
      case "DateOfService":
        return moment(invoice?.DateOfService).format(this.DATE_FORMAT);
      case "AddonName":
        return addon?.Title;
      case "BillingPracticeNumber":
        return practice?.BillingPracticeNumber;
      default:
        return "";
    }
  }
}
