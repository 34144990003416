import { Injectable } from '@angular/core';
import {
  BaseExcelReportData,
  BasePdfReportData,
  RASuspenseReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, BasePdfGenerator, RASuspenseAccountPdfReportDataBuilder, RASuspenseAccountReportBuilder, RASuspenseAccountXlsReportDataBuilder, ReportUtils } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class RASuspenseAccountReportService {
  async generateReport(reportRequest: RASuspenseReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPaymentPerProviderReportFileName(
      'RASuspenseAccount',reportRequest.Practice, reportRequest.DateFrom, reportRequest.DateTo,  reportRequest.ReportFormatType);

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.RA_SUSPENSE,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: RASuspenseReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
     }
    else
    if(REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    }
    else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: RASuspenseReportRequest): Promise<BasePdfReportData> {
    return new RASuspenseAccountPdfReportDataBuilder().build(
      await new RASuspenseAccountReportBuilder().build(reportRequest));
  }

  private async generateXlsReportData(reportRequest: RASuspenseReportRequest): Promise<BaseExcelReportData> {
     return new RASuspenseAccountXlsReportDataBuilder().build(
       await new RASuspenseAccountReportBuilder().build(reportRequest));
  }
}
