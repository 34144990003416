import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {AuthService} from "../../../services/auth.service";
import {SystemAccessHelperService} from "../../../services/system-access-helper.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CLAIM_STATUS, NotificationMessage, SYSTEM_ACCESS_CODE} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-claim-status-notification',
  templateUrl: './claim-status-notification.component.html',
  styleUrls: ['./claim-status-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClaimStatusNotificationComponent implements OnInit {

  public static ACCEPTED_CLAIM_STATUSES = [CLAIM_STATUS.PROCESSED_BY_MEDICALAID, CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID];
  public static BALANCE_CLAIM_STATUSES = [CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID];

  public acceptedClaimStatuses = ClaimStatusNotificationComponent.ACCEPTED_CLAIM_STATUSES;
  public balanceClaimStatuses = ClaimStatusNotificationComponent.BALANCE_CLAIM_STATUSES;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private sbRef: MatSnackBarRef<ClaimStatusNotificationComponent>,
    private accessService: SystemAccessHelperService,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationMessage
  ) {
  }

  ngOnInit() {
  }

  onClose() {
    this.sbRef.dismiss();
  }

  get actionName() {
    switch (this.data?.ClaimStatusNotification?.ClaimStatus) {
      case CLAIM_STATUS.REJECTED:
        return 'Fix & resubmit';
      case CLAIM_STATUS.PART_REJECTED:
        return 'View rejected lines';
      case CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID:
        return 'Collect';
      case CLAIM_STATUS.REVERSAL_REJECTED:
        return 'View';
      default:
        return '';
    }
  }

  get typeClass() {
    switch (this.data?.ClaimStatusNotification?.ClaimStatus) {
      case CLAIM_STATUS.REJECTED:
      case CLAIM_STATUS.PART_REJECTED:
      case CLAIM_STATUS.REVERSAL_REJECTED:
        return 'danger';
      case CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID:
      case CLAIM_STATUS.PROCESSED_BY_MEDICALAID:
        return 'success';
      case CLAIM_STATUS.REVERSAL_ACCEPTED:
        return 'info';
      default:
        return 'info';
    }
  }

  get typeIcon() {
    switch (this.data?.ClaimStatusNotification?.ClaimStatus) {
      case CLAIM_STATUS.REJECTED:
      case CLAIM_STATUS.PART_REJECTED:
      case CLAIM_STATUS.REVERSAL_REJECTED:
      case CLAIM_STATUS.REVERSAL_ACCEPTED:
        return 'info_outlined';
      case CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID:
      case CLAIM_STATUS.PROCESSED_BY_MEDICALAID:
        return 'check_circle_outline';
      default:
        return 'info_outlined';
    }
  }

  onAction() {

    if (!this.data?.ClaimStatusNotification?.InboxItemId)
      return;

    const id = this.data?.ClaimStatusNotification?.InboxItemId;

    switch (this.data?.ClaimStatusNotification?.ClaimStatus) {
      case (CLAIM_STATUS.REJECTED) :
        this.router.navigate(['inbox', 'claims', id, 'view-rejected']);
        break;
      case (CLAIM_STATUS.PART_REJECTED) :
        this.router.navigate(['inbox', 'claims', id, 'view-rejected-lines']);
        break;
      case (CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID) :
        this.router.navigate(['inbox', 'collection', id, 'collect-outstanding-balance', this.data.ClaimStatusNotification.AccountNo]);
        break;
      case (CLAIM_STATUS.REVERSAL_REJECTED) :
        this.router.navigate(['inbox', 'claims', id, 'view-rejected']);
        break;
    }

    this.onClose();
  }

  isActionVisible() {
    if (!this.actionName)
      return false;

    if (!this.data?.ClaimStatusNotification?.InboxItemId)
      return false;

    switch (this.data?.ClaimStatusNotification?.ClaimStatus) {
      case (CLAIM_STATUS.REJECTED) :
      case (CLAIM_STATUS.PART_REJECTED) :
        return this.accessService.hasAccess(SYSTEM_ACCESS_CODE.CLAIMS_REJECTIONS);
      case (CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID) :
        return this.accessService.hasAccess(SYSTEM_ACCESS_CODE.COLLECT_OUTSTANDING_BALANCE);
      case (CLAIM_STATUS.REVERSAL_REJECTED) :
        return this.accessService.hasAccess(SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_REJECTED);
      case (CLAIM_STATUS.REVERSAL_ACCEPTED) :
          return this.accessService.hasAccess(SYSTEM_ACCESS_CODE.CLAIMS_REVERSAL_ACCEPTED);
      default:
        return false;
    }
  }
}
