import { Injectable } from '@angular/core';
import { collection, docSnapshots, DocumentReference, Firestore } from '@angular/fire/firestore';
import { map, takeWhile } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { FirestoreService } from '../firestore.service';
import {
  CreditNote,
  ERROR_CODE,
  LiabilityTransfer,
  Payment, PaymentCorrection,
  PaymentUnallocate, Reverse,
  ReverseAllForInvoice,
  TransactionCapture, WriteOff
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class TransactionCaptureService {
  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private authService: AuthService
  ) {}

  async addTransactionCapture(accountId: string, transactionCapture: TransactionCapture) {
    return await this.firestoreService.addDoc(
      collection(
        this.firestore,
        `/Practice/${this.authService.selectedBPN}/Account/${accountId}/TransactionCapture`
      ),
      transactionCapture
    );
  }

  async waitForComplete(
    transactionCaptureDocRef: DocumentReference<TransactionCapture>,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void = null
  ) {
    const status = await docSnapshots(transactionCaptureDocRef)
      .pipe(
        map((r) => r.data()),
        map((tc) => tc?.ProcessStatus),
        takeWhile((status) => status?.Success === undefined, true)
      )
      .toPromise();
    if (onCompleteFn) onCompleteFn(status.Success, status.ErrorCode);
    return status;
  }

  async capturePayment(
    accountId: string,
    payment: Payment,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void = null
  ) {
    const ref = (await this.addTransactionCapture(accountId, {
      Payment: payment,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    const status = await this.waitForComplete(ref, onCompleteFn);
    return status.Success;
  }

  async unallocatePayment(
    accountId: string,
    unallocate: PaymentUnallocate,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const ref = (await this.addTransactionCapture(accountId, {
      PaymentUnallocate: unallocate,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    if (onCompleteFn) {
      await this.waitForComplete(ref, onCompleteFn);
    }
  }

  async reverseAllForInvoice(accountId: string, reverseAllForInvoice: ReverseAllForInvoice) {
    return (await this.addTransactionCapture(accountId, {
      ReverseAllForInvoice: reverseAllForInvoice,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
  }

  async liabilityTransfer(accountId: string, liabilityTransfer: LiabilityTransfer) {
    const ref = (await this.addTransactionCapture(accountId, {
      LiabilityTransfer: liabilityTransfer,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    const status = await this.waitForComplete(ref);
    return status.Success;
  }

  async addCreditCode(accountId: string, creditNote: CreditNote) {
    return (await this.addTransactionCapture(accountId, {
      CreditNote: creditNote,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
  }

  async addWriteOff(accountId: string, writeOff: WriteOff) {
    return (await this.addTransactionCapture(accountId, {
      WriteOff: writeOff,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
  }

  async addPaymentCorrection(
    accountId: string,
    paymentCorrection: PaymentCorrection,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void = null
  ) {
    const ref = (await this.addTransactionCapture(accountId, {
      PaymentCorrection: paymentCorrection,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    const status = await this.waitForComplete(ref, onCompleteFn);
    return status.Success;
  }

  async reverse(
    accountId: string,
    reverse: Reverse,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const ref = (await this.addTransactionCapture(accountId, {
      Reverse: reverse,
    } as TransactionCapture)) as DocumentReference<TransactionCapture>;
    if (onCompleteFn) {
      await this.waitForComplete(ref, onCompleteFn);
    }
  }
}
