import {Injectable} from "@angular/core";
import {MonthlyTransactionReportBuilder} from "./monthly-transaction-report-builder";
import {ReportUtils} from "../report-utils";
import {
  MonthlyTransactionReportModel,
  MonthlyTransactionReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { IReportGenerator, MonthlyTransactionReportPdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class MonthlyTransactionReportService {

  constructor(private monthlyTransactionReportBuilder: MonthlyTransactionReportBuilder) {
  }

  async generateReport(reportRequest: MonthlyTransactionReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildMonthlyTransactionReportFileName(
      reportRequest.Practice.PracticeName, ReportUtils.getMonthName(reportRequest.DateTo), ReportUtils.getFullYear(reportRequest.DateTo));

    const reportGenerator: IReportGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.MONTHLY_TRANSACTION,
      format: REPORT_FORMAT_TYPE.PDF,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    }
  }

  private async getReportGenerator(reportRequest: MonthlyTransactionReportRequest): Promise<IReportGenerator> {
    const reportModel: MonthlyTransactionReportModel = await this.monthlyTransactionReportBuilder.build(reportRequest);
    return new MonthlyTransactionReportPdfGenerator(reportModel);
  }
}
