import * as moment from "moment";
import _ from "lodash";
import {
  BaseAccount,
  BaseInvoice,
  Branch, CellType,
  ClaimDetailData, ClaimDetailDataTable, INVOICE_SUBTYPE, InvoiceLine, PDFCellData,
  Practice,
  ProofOfSubmissionReportModel, ProofOfSubmissionTable
} from "@meraki-flux/schema";

export class ProofOfSubmissionReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(practice: Practice, account: BaseAccount, invoice: BaseInvoice): Promise<ProofOfSubmissionReportModel> {
        const branch = practice.Branches.find(x => (account.HomeBranch ? x.Name === account.HomeBranch : x.IsMainBranch));
        const invoiceResult = await this.buildProofOfSubmissionTables(account, invoice);
        return {
            PracticeId: practice.BillingPracticeNumber,
            PracticeName: `${practice.PracticeName}`,
            ProofOfSubmissionTables: invoiceResult.invoiceTables,
            ProofOfSubmissionData: await this.buildProofOfSubmissionData(invoice),
            LetterTable: await this.buildLetterTable(invoice),
            LetterText: await this.buildLetterText(branch, invoice),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ReportDate: moment(invoice.InvoiceDate.toDate()).format(this.DATE_FORMAT)
        };
    }

    async buildLetterText(branch: Branch, invoice: BaseInvoice) {
      const lines = [];
      lines.push('PROOF OF CLAIM SUBMISSION – PLEASE PROCESS');
      lines.push(`This serves to confirm that the specified claim was delivered by Healthbridge to ${invoice.Account.SchemeName}`);
      lines.push('To date this claim has not been processed and requires your immediate attention. Please investigate and respond to this message as soon as possible.');
      lines.push(`Kind regards,
      ${branch.Name}`);
      lines.push(`Phone: ${branch.ContactDetails?.OfficeNo??""}`)
      lines.push(`Email address: ${branch.ContactDetails?.EmailAddress??""}`)
      return lines;
    }

    async buildLetterTable(invoice: BaseInvoice) {
      const rows = [];
      rows.push([{value: "Scheme"}, {value: invoice.Account.SchemeName}])
      rows.push([{value: "Plan"}, {value: invoice.Account.PlanName}])
      rows.push([{value: "Option"}, {value: invoice.Account.OptionName}])
      rows.push([{value: "No. of pages"}, {value: "2"}])
      return {
          rows: rows
      }
    }

    async buildProofOfSubmissionData(invoice: BaseInvoice) : Promise<ClaimDetailData> {
      return {
        claimStatus: await this.buildClaimStatus(invoice),
        details: await this.buildClaimDetails(invoice)
      }
    }

    async buildClaimStatus(invoice: BaseInvoice): Promise<ClaimDetailDataTable> {
      const result = []
      result.push(this.addClaimDetailRow("Status", {value: invoice.ClaimInfo.ClaimStatus??""}, "Invoice type", {value: invoice.Type??"-"}))
      result.push(this.addClaimDetailRow("Amount claimed", {value: invoice.AmountBilled??0, type: CellType.CURRENCY},
      "Patient liable", {value: invoice.Balance?.PatientLiable??0, type: CellType.CURRENCY}))
      result.push(this.addClaimDetailRow("Balance outstanding", {value: invoice.Balance?.Outstanding??0, type: CellType.CURRENCY},
      "Medical aid liable", {value: invoice.Balance?.MedicalAidLiable??0, type: CellType.CURRENCY}))
      return {
        rows: result
      }
    }

    async buildClaimDetails(invoice: BaseInvoice): Promise<ClaimDetailDataTable> {
      const result = []
      const insurer = invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER
      result.push(this.addClaimDetailRow("Patient", {value: `${invoice.Patient?.Name} ${invoice.Patient?.Surname}`},
      insurer?"Medical insrurer":"Scheme", {value: insurer ? invoice.Account.SchemeName : invoice.Account.SchemeName}))
      result.push(this.addClaimDetailRow("Account no.", {value: invoice.Account?.AccountNo??"-"},
      insurer?"Policy no.":"Plan", {value: insurer ? invoice.Account.PlanName : invoice.Account.PlanName}))
      result.push(this.addClaimDetailRow("Date of birth", {value: moment(invoice.Patient?.DateOfBirth).format(this.DATE_FORMAT)},
      insurer?"Broker":"Option", {value: insurer ? invoice.Account.OptionName : invoice.Account.OptionName}))
      result.push(this.addClaimDetailRow("ID no.", {value: invoice.Patient?.IdentityNo??"-"},
      insurer?"":"Member no.", {value: insurer ? "" : invoice.Account?.MemberNo??"-"}))
      if (!insurer) {
        result.push(this.addClaimDetailRow("", {value: ""},
          "Dep. code", {value: invoice.Patient?.DependantCode??"-"}))
      }
      result.push(this.addClaimDetailRow("", {value: ""}, "", {value: ""}))
      result.push(this.addClaimDetailRow("Treating provider", {value: (invoice.TreatingProvider?.FullName??"-").split(',')[0]??"-"}, "Treating practice no.", {value: invoice.TreatingProvider?.TreatingPracticeNumber??"-"}))
      result.push(this.addClaimDetailRow("Invoice no.", {value: invoice.InvoiceNo??""}, "HPCSA/MP no.", {value: invoice.TreatingProvider?.HPCSANumber??"-"}))
      result.push(this.addClaimDetailRow("Date of service", {value: moment(invoice.DateOfService).format(this.DATE_FORMAT)}, "Place of service", {value: invoice.PlaceOfService??"-"}))
      result.push(this.addClaimDetailRow("Date of submission", {value: moment(invoice.DateOfSubmission).format(this.DATE_FORMAT)}, "Referral no.", {value: invoice.ReferralNo===""?"-":invoice.ReferralNo??"-"}))
      result.push(this.addClaimDetailRow("HB message ID", {value: invoice.ClaimInfo?.HBMessageId??"-"}, "Referring provider", {value: (invoice.ReferringProvider?.FullName??"-").split(',')[0]??"-"}))
      result.push(this.addClaimDetailRow("HB batch no.", {value: invoice.ClaimInfo?.HBBatchId??"-"}, "Newborn", {value: invoice.NewBorn?"Yes":"No"}))
      result.push(this.addClaimDetailRow("Authorization no.", {value: invoice.AuthorizationNo===""?"-":invoice.AuthorizationNo??"-"}, "Assisting provider", {value: (invoice.AssistingProvider?.FullName??"-").split(',')[0]??"-"}))
      result.push(this.addClaimDetailRow("Medical aid ref. no.", {value: invoice.ClaimInfo?.MedicalAidRefNo??"-"}, "Lab reference no.", {value: invoice.LabReferenceNo===""?"-":invoice.LabReferenceNo??"-"}))
      return {
        rows: result
      }
    }

    addClaimDetailRow(h1: string, v1: PDFCellData, h2: string, v2: PDFCellData) {
      return {
        header1: h1,
        value1: v1,
        header2: h2,
        value2: v2,
      }
    }

    async buildProofOfSubmissionTables(account: BaseAccount, invoice: BaseInvoice)  {
        const proofOfSubmissionTables : ProofOfSubmissionTable[] = [];
        const table = await this.buildProofOfSubmissionTable(account, invoice);
            proofOfSubmissionTables.push(table);
        return {
            invoiceTables: proofOfSubmissionTables
        };
    }

    async buildProofOfSubmissionTable(account: BaseAccount, invoice: BaseInvoice) {
        const icdLine = await this.buildInvoiceICDLine(invoice);
        const rowHeaders = await this.buildRowHeaders();
        const rows = [];
        for (const invoiceLine of _.sortBy(invoice.Lines, 'LineNumber')) {
            rows.push(await this.buildTableRow(invoice, invoiceLine))
        }
        rows.push(await this.buildTotalRow(invoice))
        return {
            ICDLine: icdLine,
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        }
    }

    async buildTableRow(invoice: BaseInvoice, invoiceLine: InvoiceLine) {
        const row = [];
        row.push({
            value: invoiceLine.TariffCode??""
        })
        row.push({
            value: invoiceLine.NappiCode??""
        })
        const desc = this.resize(invoiceLine.Description.match(/.{1,85}/g)??[], 3).join('\n')
        row.push({
            value: `${desc}\nDiag: ${invoiceLine.DiagnosisCodes}`
        })
        row.push({
            value: invoiceLine.Quantity,
            type: CellType.NUMBER
        })
        row.push({
            value: invoiceLine.AmountBilled||0,
            type: CellType.CURRENCY
        })
        return row;
    }

    async buildTotalRow(invoice: BaseInvoice) {
      const row = [];
      row.push({
          value: "",
      })
      row.push({
          value: "",
      })
      row.push({
          value: ""
      })
      row.push({
          value: ""
      })
      row.push({
          value: invoice.AmountBilled||0,
          type: CellType.CURRENCY,
          decoration: {
              bold: true
          }
      })
      return row;
  }

    resize(arr, size) {
        const add = arr.length > size;
        while (arr.length > size) { arr.pop(); }
        if (add) {
            arr[size-1] = arr[size - 1].replace(/.$/,'...')
        }
        return arr;
    }

    async buildRowHeaders() {
        return ["Tariff code", `NAPPI code`, `Description${"\t\u200B".repeat(30)}`, "Qty.", "Claimed"].map(n => {
            return {
                value: n
            }
        });
    }

    async buildInvoiceICDLine(invoice: BaseInvoice) {
        return {
            icdCode: `Diagnosis: ${invoice.HeaderDiagnosisCodes}`
        }
    }

}
