import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Pipe({
    name: 'appointmentTime',
    pure: true
})
export class AppointmentTimePipe implements PipeTransform {
    transform(value: Date, showMeridiem = false) {
        const format = showMeridiem ? 'h:mm a' : 'h:mm';

        return moment(value).utc().format(format) || '';
    }
}