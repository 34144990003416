import { collection, doc, Firestore, getDoc, getDocs, query, where, writeBatch } from "@angular/fire/firestore";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { WriteBatch } from "@firebase/firestore";
import * as moment from "moment";
import { EmailComponent } from '../../components/email/email.component';
import { ClaimDetailReportService } from '../../report/claim-detail-report/claim-detail-report.service';
import { InvoiceReportService } from '../../report/invoice-report/invoice-report.service';
import { PathUtils } from '../../utils/path-utils';
import { AuthService } from '../auth.service';
import { FirestoreService } from '../firestore.service';
import { InvoiceHelperService } from '../invoice-helper.service';
import { ReportService } from "../report.service";
import { CalendarService } from "../calendar.service";
import {
  Account,
  BaseInvoice,
  CLAIM_STATUS, EMAIL_ACTION_EVENT, EMAIL_ACTION_STATUS,
  EMAIL_ACTION_TYPE,
  EMAIL_STATUS, FieldUpdateAction, INBOX_STATUS,
  INVOICE_STATUS,
  Practice,
  Report
} from "@meraki-flux/schema";

export abstract class AbstractInvoiceCapture {
  constructor(protected authService: AuthService, protected calendarService: CalendarService) {
  }

  capture(invoice: BaseInvoice, account: Account, postAction: WriteBatch): Promise<boolean> {
    if (!invoice?.Id) throw Error('Invoice Id must be preset');
    invoice.Status = INVOICE_STATUS.NEW;
    delete invoice.InvoiceNo; // invoice no. is generated on backend

    invoice.CreatedAt = new Date();
    invoice.UpdatedAt = new Date();
    invoice.CreatedBy = this.authService.uid;
    invoice.UpdatedBy = this.authService.uid;
    this.calendarService.updateVisitOnCaptureInvoice(postAction, invoice);

    let total = 0;
    invoice.Lines.forEach(line => {
      total += line.AmountBilled;
    });
    if(invoice.AmountBilled !== total) {
      throw new Error('Header amount does not match line amount total');
    }

    return this.doCapture(invoice, account, postAction);
  }

  protected abstract doCapture(invoice: BaseInvoice, account: Account, postAction: WriteBatch): Promise<boolean>;
}

export class MedicalInsuranceInvoiceCapture extends AbstractInvoiceCapture {
  bottomSheet: MatBottomSheet;
  claimDetailReportService: ClaimDetailReportService
  firestore: Firestore;
  invoiceHelper: InvoiceHelperService;
  firestoreService: FirestoreService;
  invoiceReportService: InvoiceReportService;

  constructor(bottomSheet: MatBottomSheet, claimDetailReportService: ClaimDetailReportService,
    firestore: Firestore, authService: AuthService, invoiceHelper: InvoiceHelperService,
    firestoreService: FirestoreService, invoiceReportService: InvoiceReportService, calendarService: CalendarService) {
    super(authService, calendarService);
    this.bottomSheet = bottomSheet;
    this.claimDetailReportService = claimDetailReportService;
    this.firestore = firestore;
    this.authService = authService;
    this.invoiceHelper = invoiceHelper;
    this.firestoreService = firestoreService;
    this.invoiceReportService = invoiceReportService;
  }

  protected async doCapture(invoice: BaseInvoice, account: Account, postAction: WriteBatch) {
    invoice.ClaimInfo = { ClaimStatus: CLAIM_STATUS.AWAITING_RESPONSE };

    const practice = (await getDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}`))).data() as Practice;

    const reportData = {
      Account: account,
      Invoice: invoice,
      Practice: practice
    };
    const report: Report = await this.claimDetailReportService.generateReport(reportData);
    const claimReportFile = await ReportService.convertReportToFile(report);

    const practiceEmail = getPracticeEmail(practice, account.HomeBranch);

    const mailRes = await this.bottomSheet.open(EmailComponent,
      getEmailData(invoice.MedicalInsurer?.Email, practiceEmail, practiceEmail, [claimReportFile],
        `Insurance claim submission - ${invoice.Patient?.Title || ''} ${invoice?.Patient?.Name?.substring(0, 1)} ${invoice.Patient?.Surname} - ${invoice.PolicyNo}`,
        `<p>Please find attached a claim for submission.</p><br><p>Kind regards,</p><p>${practice.PracticeName}</p>`,
        'Email Insurance Claim',
        `Practice/${this.authService.selectedBPN}/Invoice/${invoice.Id}`)
    ).afterDismissed().toPromise();

    if (!mailRes)
      return false;

    postAction = postAction ? postAction : writeBatch(this.firestore);
    postAction.set(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Invoice/${invoice.Id}`), invoice);
    postAction.set(doc(this.firestore, `Practice/${this.authService.selectedBPN}/OutboundEmail/${mailRes}`), { Status: EMAIL_STATUS.NEW },
      { merge: true });

    if (invoice.Patient?.DateOfBirth)
      postAction.update(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Patient/${invoice.Patient?.Id}`), { DateOfBirth: moment(invoice.Patient?.DateOfBirth, 'YYYY-MM-DD').utc().toDate() });

    if (invoice.Patient?.IdentityNo)
      postAction.update(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Patient/${invoice.Patient?.Id}`), { IdentityNo: invoice.Patient?.IdentityNo });

    await postAction.commit();
    return true;
  }
}

export class UnroutableInvoiceCapture extends AbstractInvoiceCapture {
  bottomSheet: MatBottomSheet;
  claimDetailReportService: ClaimDetailReportService
  firestore: Firestore;
  invoiceHelper: InvoiceHelperService;
  firestoreService: FirestoreService;

  constructor(bottomSheet: MatBottomSheet, claimDetailReportService: ClaimDetailReportService,
    firestore: Firestore, authService: AuthService, invoiceHelper: InvoiceHelperService,
    firestoreService: FirestoreService, calendarService: CalendarService) {
    super(authService, calendarService);
    this.bottomSheet = bottomSheet;
    this.claimDetailReportService = claimDetailReportService;
    this.firestore = firestore;
    this.authService = authService;
    this.invoiceHelper = invoiceHelper;
    this.firestoreService = firestoreService;
  }

  protected async doCapture(invoice: BaseInvoice, account: Account, postAction: WriteBatch): Promise<boolean> {
    invoice.ClaimInfo = {
      Unroutable: true
    };

    const routeInfo = await this.invoiceHelper.getRouteInfo(invoice.Account.SchemeCode, invoice.Account.PlanCode, invoice.Account.OptionCode);
    const practice = (await getDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}`))).data() as Practice;

    const report: Report = await this.claimDetailReportService.generateReport({
      Account: account,
      Invoice: invoice,
      Practice: practice
    });
    const claimReportFile = await ReportService.convertReportToFile(report);

    const practiceEmail = getPracticeEmail(practice, account.HomeBranch);

    const mailRes = await this.bottomSheet.open(EmailComponent,
      getEmailData(routeInfo.claimEmail, practiceEmail, practiceEmail, [claimReportFile],
        `Claim submission - ${invoice.Patient?.Title ?? ""} ${invoice?.Patient?.Name?.substring(0, 1)} ${invoice.Patient?.Surname} - ${invoice.Account?.MemberNo}`,
        `<p>Please find attached a claim for submission.</p><br><p>Kind regards,</p><p>${practice.PracticeName}</p>`,
        'Email Unroutable Claim',
        `Practice/${this.authService.selectedBPN}/Invoice/${invoice.Id}`)
    ).afterDismissed().toPromise();

    if (!mailRes)
      return false;

    postAction = postAction ? postAction : writeBatch(this.firestore);
    postAction.set(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Invoice/${invoice.Id}`), invoice);
    postAction.set(doc(this.firestore, `Practice/${this.authService.selectedBPN}/OutboundEmail/${mailRes}`), { Status: EMAIL_STATUS.NEW },
      { merge: true });
    await postAction.commit();
    return true;
  }
}

export class DefaultInvoiceCapture extends AbstractInvoiceCapture {
  firestore: Firestore;
  firestoreService: FirestoreService;

  constructor(firestore: Firestore, authService: AuthService, firestoreService: FirestoreService, calendarService: CalendarService) {
    super(authService, calendarService);
    this.firestore = firestore;
    this.authService = authService;
    this.firestoreService = firestoreService;
  }

  protected async doCapture(invoice: BaseInvoice, account: Account, postAction: WriteBatch) {
    postAction = postAction ? postAction : writeBatch(this.firestore);
    const docRef = doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, invoice.Id));
    postAction.set(docRef, invoice);
    await postAction.commit();
    return true;
  }
}

function getPracticeEmail(practice: Practice, accountHomeBranch: string) {
  let practiceEmail = '';
  if (practice.IsMultiBranch) {
    // Use account home branch email when multibranch
    const homeBranch = practice.Branches?.find(b => b.Name === accountHomeBranch);
    practiceEmail = homeBranch?.ContactDetails?.EmailAddress;
    if (!practiceEmail)
      practiceEmail = practice.Branches[0].ContactDetails?.EmailAddress;
  } else {
    practiceEmail = practice.Branches[0].ContactDetails?.EmailAddress;
  }
  return practiceEmail;
}

function getEmailData(recipientEmail: string, ccEmail: string, replyToEmail: string, files: File[], subject: string, body: string, title: string, documentPath: string): any {
  return {
    autoFocus: false,
    disableClose: true,
    restoreFocus: false,
    panelClass: 'sheet-full-width',
    data: {
      keepAsDraftOnSend: true,
      recipients:[
        {EmailAddress: recipientEmail}
      ],
      ccs: [ccEmail],
      replyTo: {
        EmailAddress: replyToEmail
      },
      infoMessage: `This claim could not be routed automatically by Healthbridge Nova.<br><br>Please complete the email details below. Note that the practice will be automatically copied on this email.`,
      dialogTitle: title,
      files: files,
      subject: subject,
      body: body,
      actions: [
        {
          Type: EMAIL_ACTION_TYPE.FIELD_UPDATE,
          Event: EMAIL_ACTION_EVENT.ON_SUCCESS,
          Status: EMAIL_ACTION_STATUS.NEW,
          UpdateValue: {
            ClaimInfo: {
              ClaimStatus: CLAIM_STATUS.RECEIVED_BY_HEALTHBRIDGE
            }
          },
          DocumentPath: documentPath,
        } as FieldUpdateAction
      ]
    }
  }
}

async function getFixAndResubmitInboxItems(firestore, practiceId: string, invoiceId: string) {
  const inboxItems =  await getDocs(query(
    collection(firestore, `Practice/${practiceId}/Inbox`),
    where('InvoiceId', '==', invoiceId),
    where('Status', '==', INBOX_STATUS.CREATED)
  ));
  return inboxItems.docs;
}
export { BaseInvoice, Practice };

