<div [formGroup]="form">
  <!--Header-->
  <div
    class="flex flex-row justify-between items-center border-b border-hb-base-divider-grey !py-4 !px-6 -mt-6 -mx-6 mb-6">
    <h2 class="font-medium !leading-8 text-xl">
      <ng-container *ngIf="data.Type === noteType.ACCOUNT">
        Account Notes - {{data.AccountNo}}
        <span *ngIf="data.Name && data.Surname">
          ({{data.Name | initialsPipe}} {{data.Surname | titlecase}})
        </span>
      </ng-container>
      <ng-container *ngIf="data.Type === noteType.INVOICE">
        Invoice Notes - {{data.InvoiceNo}}
      </ng-container>
    </h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!--Body-->
  <div class="-mt-6 -mx-6 mb-4 note-modal-body">
    <div class="bg-hb-accent-5 !py-4 !px-6">
      <!--Add note-->
      <mat-form-field class="w-full flux-form-field flux-light-field">
        <mat-label *ngIf="data.Type === noteType.ACCOUNT">Add account note</mat-label>
        <mat-label *ngIf="data.Type === noteType.INVOICE">Add invoice note</mat-label>
        <input required matInput formControlName="note" #noteInput>
      </mat-form-field>

      <!--Toggles-->
      <div *ngIf="data.Type === noteType.ACCOUNT" class="flex flex-col justify-between h-16">
        <mat-slide-toggle class="flux-toggle" [disabled]="noteInput.value === ''" formControlName="pinned">Pin note to
          top</mat-slide-toggle>
        <mat-slide-toggle class="flux-toggle" [formControl]="showNoteControl">Show notes whenever this account is
          opened</mat-slide-toggle>
      </div>
    </div>

    <!--Filter-->
    <div class="!px-6 !pt-4">
      <mat-form-field class="w-1/2 flux-form-field">
        <mat-label>Created by</mat-label>
        <mat-icon class="!text-hb-text-subdued material-icons-outlined" matPrefix>filter_alt</mat-icon>
        <mat-select matNativeControl required formControlName="filterType" id="filterTypeSelect">
          <mat-option *ngFor="let type of filterTypes" [value]="type">
            {{ type }}
          </mat-option>
          <mat-option *ngFor="let user of users$ | async" [value]="user">{{user.Name}} {{user.Surname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Table-->
    <table [dataSource]="dataSource" class="w-full mb-4 flux-table" id="notesTable" matSortDisableClear mat-table
      matSort matSortActive="Date" matSortDirection="desc">
      <ng-container matColumnDef="Pin">
        <th class="w-1/12" mat-header-cell *matHeaderCellDef></th>
        <td class="w-1/12" mat-cell *matCellDef="let row; let i = index">
          <button id="rowPin{{i}}" mat-icon-button (click)="togglePin(row.Id, row.Pinned)"
            [matTooltip]="row.Pinned ? 'Unpin this note' : 'Pin to top'" matTooltipPosition="right">
            <mat-icon class="material-icons-outlined" [ngClass]="{
                '!text-hb-accent-100': row.Pinned,
                '!text-hb-text-disabled': !row.Pinned
              }">
              push_pin
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Date">
        <th class="w-2/12" mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td class="w-2/12" mat-cell *matCellDef="let row">
          <div class="flex items-center">
            <span>{{row.Date | date: 'dd/MM/yyyy'}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CreatedBy">
        <th class="w-2/12" mat-header-cell *matHeaderCellDef mat-sort-header>Created by</th>
        <td class="w-2/12" mat-cell *matCellDef="let row">
          {{row.OriginType === noteOriginType.SYSTEM ?
          row.OriginType : row.CreatedByName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Note">
        <th class="w-4/12" mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td class="w-4/12" mat-cell *matCellDef="let row; let i = index">
          <ng-container *ngIf="!row.EditMode">
            <span [ngClass]="{'font-bold': row.Pinned === true}">{{row.Note}}</span>
          </ng-container>

          <mat-form-field *ngIf="row.EditMode" appearance="outline" class="edit-field flux-form-field">
            <input id="rowInput{{i}}" #editNoteInput matInput [value]="row.Note" type="text">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="LastModified">
        <th class="w-2/12" mat-header-cell *matHeaderCellDef mat-sort-header>Last modified</th>
        <td class="w-2/12" mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.ModifiedAt !== undefined">
            <div class="text-left">{{row.ModifiedAt | date: 'dd/MM/yyyy'}}</div>
            <div class="text-hb-text-disabled text-xs text-left">{{row.ModifiedBy}}</div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="Edit">
        <th class="w-1/12" mat-header-cell *matHeaderCellDef></th>
        <td class="w-1/12 text-center" mat-cell *matCellDef="let row; let i = index">

          <ng-container *ngIf="!row.EditMode">
            <button id="rowEdit{{i}}" *ngIf="row.OriginType === noteOriginType.USER" mat-icon-button
              (click)="editMode(row.Id, row.OriginType, row.CreatedBy)">
              <mat-icon class="!text-hb-secondary-100 material-icons-outlined">
                edit
              </mat-icon>
            </button>
          </ng-container>

          <button id="rowSave{{i}}" *ngIf="row.EditMode" mat-icon-button (click)="saveEdit(row.Id)">
            <mat-icon class="!text-hb-success-100">
              done
            </mat-icon>
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'!bg-hb-accent-5': row.Pinned === true}">
      </tr>
    </table>

    <!-- <mat-paginator appPagination class="flux-paginator" #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="5" aria-label="Select page">
    </mat-paginator> -->
  </div>

  <!--Buttons-->
  <div class="flex justify-end border-t border-hb-base-divider-grey !py-4 !px-6 mt-4 -mx-6 -mb-4">
    <button (click)="cancel()" class="flux-button" mat-stroked-button color="secondary">
      Cancel
    </button>
    <ng-container *ngIf="(state$ | async) as state">
      <button (click)="save()" mat-flat-button color="primary" class="flux-button !ml-3" [disabled]="state !== 'ready'">
        <div class="flex flex-row gap-3 items-center">
          <mat-spinner class="save-spinner" *ngIf="state !== 'ready'" diameter="24"></mat-spinner>
          <span>{{ state === 'saving' ? 'Saving...' : state === 'validating' ? 'Validating...' : 'Save' }}</span>
        </div>
      </button>
    </ng-container>
  </div>

</div>
