import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData,
  PaymentSummaryGroupData, PaymentSummaryItem, PaymentSummaryReportInfo,
  PaymentSummaryReportModel,
  REPORT_NAME,
  Sheet, Table
} from '@meraki-flux/schema';

export class PaymentSummaryXlsReportDataBuilder {

  build(report: PaymentSummaryReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    this.buildReportInfo(excelReportData, report.ReportInfo);
    excelReportData.practiceId = report.ReportInfo.PracticeId;
    excelReportData.name = REPORT_NAME.PAYMENT_SUMMARY;
    excelReportData.data = [];
    excelReportData.data.push(this.buildSummarySheet(report))

    return excelReportData;
  }

  private buildSummarySheet(reportModel: PaymentSummaryReportModel): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    sheet.header = `Payment Summary Report`
    sheet.tables = [];

    sheet.tables.push(this.buildSummaryTable(reportModel.PatientPaymentTypeData,
      'PATIENT PAYMENTS', null, 'Payment type', '# Payments',
      'Amount paid', 'Allocated amt.', 'Unallocated amt.'));

    sheet.tables.push(this.buildSummaryTable(reportModel.MedicalAidPaymentTypeData,
      'MEDICAL AID PAYMENTS', 'Payment type summary', 'Payment type', '# Payments',
      'Amount paid', 'Reconciled amt.', 'Unreconciled amt.'));

    sheet.tables.push(this.buildSummaryTable(reportModel.SchemeData,
      null, 'Scheme summary', 'Scheme', null, 'Amount paid'));

    sheet.tables.push(this.buildSummaryTable(reportModel.InsurerPaymentTypeData,
      'INSURER PAYMENTS', 'Payment type summary', 'Payment type', '# Payments',
      'Amount paid', 'Reconciled amt.', 'Unreconciled amt.'));

    sheet.tables.push(this.buildSummaryTable(reportModel.InsurerData,
      null, 'Insurer summary', 'Insurer', null, 'Amount paid'));

    return sheet;
  }

  private buildSummaryTable(data: PaymentSummaryGroupData, tableName, description, keyColumnName, countColumnName?, amountColumnName?,
                            allocatedColumnName?, unallocatedColumnName?): Table {
    const table: Table = {};
    table.name = tableName;
    table.description = description;
    table.headers = [{value: keyColumnName, bold: true, width: CellWidth.S}];
    table.noHeaderBackground = true;

    if (countColumnName) table.headers.push({value: countColumnName, bold: true, width: CellWidth.XS});
    if (amountColumnName) table.headers.push({value: amountColumnName, bold: true, width: CellWidth.XS});
    if (allocatedColumnName) table.headers.push({value: allocatedColumnName, bold: true, width: CellWidth.XS});
    if (unallocatedColumnName) table.headers.push({value: unallocatedColumnName, bold: true, width: CellWidth.XS});

    const rows = [];

    if (data.map.size > 0) {
      Array.from(data.map.keys()).sort().forEach(key => {
        const item: PaymentSummaryItem = data.map.get(key);
        const row: ExcelCellData[] = [];
        row.push({value: key});
        if (countColumnName) row.push({value: item.count, type: CellType.NUMBER});
        if (amountColumnName) row.push({value: item.total, type: CellType.CURRENCY});
        if (allocatedColumnName) row.push({value: item.allocated, type: CellType.CURRENCY});
        if (unallocatedColumnName) row.push({value: item.unallocated, type: CellType.CURRENCY});
        rows.push(row);
      });

      const totalRow: ExcelCellData[] = [{value: 'Total', bold: true}];
      if (countColumnName) totalRow.push({value: data.totals.count, bold: true});
      if (amountColumnName) totalRow.push({value: data.totals.total, bold: true, type: CellType.CURRENCY});
      if (allocatedColumnName) totalRow.push({value: data.totals.allocated, bold: true, type: CellType.CURRENCY});
      if (unallocatedColumnName) totalRow.push({value: data.totals.unallocated, bold: true, type: CellType.CURRENCY});

      rows.push(totalRow);
      table.rows = rows;
    }

    return table;
  }

  private buildReportInfo(excelReportData: BaseExcelReportData, reportInfo: PaymentSummaryReportInfo) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: {value: reportInfo.Practice, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Billing practice no.',
      data: {value: reportInfo.PracticeId, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Date from',
      data: {value: reportInfo.DateFrom, type: CellType.DATE}
    });
    excelReportData.parameters.push({
      name: 'Date to',
      data: {value: reportInfo.DateTo, type: CellType.DATE}
    });
  }
}
