import moment from "moment";
import {
  CategorySummaryItem,
  DetailsItem,
  JournalProviderModel,
  JournalReportInfo,
  JournalReportModel,
  JournalReportRequest,
  NoDataError, RAJournal, REMITTANCE_ADVICE_TYPE, SchemeSummaryItem
} from "@meraki-flux/schema";

export class JournalReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: JournalReportRequest): Promise<JournalReportModel> {
        const reportHeader: JournalReportInfo = await this.buildReportInfo(reportRequest);
        const providersModel: JournalProviderModel = await this.buildProvidersModel(reportRequest);

        this.checkDataAvailability(providersModel);

        return {
            JournalReportInfo: reportHeader,
            JournalProviderModel: providersModel,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(providersModel: JournalProviderModel) {
        let noData = true;
        if (providersModel.DetailsItem.length > 0) {
            noData = false;
        }

        if (noData)
            throw new NoDataError();
    }

    private async buildReportInfo(reportRequest: JournalReportRequest): Promise<JournalReportInfo> {
        const reportInfo: JournalReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        reportInfo.PaymentDateRange = moment(reportRequest.PaymentDateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.PaymentDateTo).format(this.DATE_FORMAT);
        reportInfo.Scheme = reportRequest.Scheme;

        return reportInfo;
    }

    private async buildProvidersModel(reportRequest: JournalReportRequest): Promise<JournalProviderModel> {

        const detailsItem: DetailsItem[] = this.buildJournalDetails(reportRequest.RAJournal);
        const categorySummaryItem: CategorySummaryItem[] = this.buildCategorySummaryItem(detailsItem);
        const schemeSummaryItem: SchemeSummaryItem[] = this.buildSchemeSummaryItem(detailsItem);

        return {
            DetailsItem: detailsItem,
            CategorySummaryItem: categorySummaryItem,
            SchemeSummaryItem: schemeSummaryItem
        } ;
    }

    private buildJournalDetails(raList: RAJournal[]): DetailsItem[] {

        const detailtems: DetailsItem[] = [];
        for(const ra of raList){
            const detailsObj: DetailsItem = {};

            detailsObj.Scheme = ra.SchemeName;
            detailsObj.PaymentDate = ra.PaymentDate.toString();
            detailsObj.EFTNo = ra.EFTNum;
            detailsObj.RADate = ra.RADate.toString();
            detailsObj.RANo = ra.RANum;
            detailsObj.JournalCategory = ra.JournalCategory;
            detailsObj.JournalDescription = ra.JournalDes;
            detailsObj.JournalAmount = ra.JournalAmount;
            detailsObj.ReconType = ra.Type == REMITTANCE_ADVICE_TYPE.ELECTRONIC ? REMITTANCE_ADVICE_TYPE.ELECTRONIC : "Manual" ;
            detailsObj.DateCaptured = ra.DateCaptured;
            detailtems.push(detailsObj);
        }
        return detailtems;
    }

    private convertTimestampToDateTime(val: any): Date {
        if (val) {
          const seconds = val.seconds;
          const nanoseconds = val.nanoseconds;
          const milliseconds = seconds * 1000 + nanoseconds / 1000000;
          const date = new Date(milliseconds);
          return date;
        }

        return null;
      }

    private buildCategorySummaryItem(list: DetailsItem[]): CategorySummaryItem[] {

        const summaryItems: CategorySummaryItem[]  = list.reduce((acc, curr) => {

            const index = acc.findIndex(item => item.Category === curr.JournalCategory);
            if (index === -1) {
            acc.push({
                Category: curr.JournalCategory,
                Total: 1
            } as CategorySummaryItem);
            } else {
                acc[index].Total = (acc[index].Total || 0) + 1;
            }

            return acc;
        }, []);

        return summaryItems;
    }

    private buildSchemeSummaryItem(list: DetailsItem[]): SchemeSummaryItem[] {

        const summaryItems: SchemeSummaryItem[]  = list.reduce((acc, curr) => {

            const index = acc.findIndex(item => item.Scheme === curr.Scheme);
            if (index === -1) {
            acc.push({
                Scheme: curr.Scheme,
                Total: 1
            } as SchemeSummaryItem);
            } else {
                acc[index].Total = (acc[index].Total || 0) + 1;
            }

            return acc;
        }, []);

        return summaryItems;
    }


}
