import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  doc,
  docSnapshots,
  DocumentSnapshot,
  Firestore,
  getDocs,
  query,
  QueryConstraint,
} from '@angular/fire/firestore';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { DialogService } from '../dialog.service';
import { FirestoreService } from '../firestore.service';
import { ReferenceDataService } from '../reference-data.service';
import { DialogType, PrivateRate, PrivateRateType, Scheme } from '@meraki-flux/schema';
import { PrivateRateWhereBuilder } from './private-rate-where-builder';
import { map, switchMap } from 'rxjs/operators';
import { DateUtils, PrivateRateUtils } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root',
})
export class PrivateRateService {
  constructor(
    private firestore: Firestore,
    private referenceDataService: ReferenceDataService,
    private firestoreService: FirestoreService,
    private dialogService: DialogService
  ) {}

  async getPrivateRateDuplicates(
    code: string,
    type: any,
    practiceId: any,
    providerId: string,
    branchName: any,
    excludeId: any
  ): Promise<PrivateRate[]> {
    const privateRatesCollectionPath = PrivateRateUtils.getPrivateRateCollectionPath(
      practiceId,
      providerId
    );
    if (!type || !privateRatesCollectionPath || !code) {
      return [];
    }
    const wheres = PrivateRateWhereBuilder.builder()
      .active(true)
      .type(type)
      .code(code)
      .branchName(branchName)
      .build();
    const snapshots = await getDocs(
      query(collection(this.firestore, privateRatesCollectionPath), ...wheres)
    );
    const schemes = await this.referenceDataService.schemePlanOptionsFlat();
    const loadedPrivateRates = snapshots.docs.map((doc) => this.mapPrivateRate(doc, schemes));
    const results = loadedPrivateRates.filter(
      (pr) =>
        pr.Id !== excludeId && (branchName === pr.BranchName || (!branchName && !pr.BranchName))
    );
    return _.sortBy(results, 'DateFrom');
  }

  mapPrivateRate(doc: DocumentSnapshot, schemes: Scheme[]) {
    const privateRate: PrivateRate = DateUtils.timestampsToDates({ Id: doc.id, ...doc.data() });
    PrivateRateUtils.fixSPO(privateRate, schemes);
    return privateRate;
  }

  privateRate$(practiceId: string, providerId: string, privateRateId: string) {
    if (!practiceId || !privateRateId) return of();
    const path = PrivateRateUtils.getPrivateRatePath(practiceId, providerId, privateRateId);
    return docSnapshots(doc(this.firestore, path)).pipe(
      switchMap(async (docRef) => {
        const schemes = await this.referenceDataService.schemePlanOptionsFlat();
        return this.mapPrivateRate(docRef, schemes);
      })
    );
  }

  getActivePrivateRates(
    collectionPath: string,
    privateRateType: PrivateRateType | undefined,
    branchName: string | undefined
  ) {
    if (_.isEmpty(collectionPath)) return of([]);
    const wheres = PrivateRateWhereBuilder.builder()
      .active(true)
      .type(privateRateType)
      .branchName(branchName)
      .build();
    return collectionSnapshots<PrivateRate>(
      query(collection(this.firestore, collectionPath), ...wheres)
    ).pipe(
      switchMap(async (docRefs) => {
        const schemes = await this.referenceDataService.schemePlanOptionsFlat();
        return docRefs.map((docRef) => this.mapPrivateRate(docRef, schemes));
      }),
      map((results) =>
        results.filter((pr) => branchName === pr.BranchName || (!branchName && !pr.BranchName))
      )
    );
  }

  async searchPrivateRates(collectionPath: string, whereList: QueryConstraint[]) {
    const snaphosts = await getDocs(
      query(collection(this.firestore, collectionPath), ...whereList)
    );
    const schemes = await this.referenceDataService.schemePlanOptionsFlat();
    return snaphosts.docs.map((d) => this.mapPrivateRate(d, schemes));
  }

  async validateDuplicates(
    practiceId: string,
    providerId: string,
    privateRateId: string,
    privateRate: PrivateRate,
    errorMessage: string = 'The rate you have specified is in conflict with an existing rate defined for this code.'
  ): Promise<boolean> {
    if (!practiceId) throw Error('Private rate save error: practice ID is missing!');
    if (!privateRateId) throw Error('Private rate save error: privateRateId ID is missing!');
    const code = privateRate?.Code;
    const type = privateRate?.Type;
    const branchName = privateRate?.BranchName;
    if (
      !code ||
      !type ||
      (!privateRate.MedicalAidInvoices && !privateRate.CashInvoices) ||
      !privateRate.DateFrom ||
      (!branchName && branchName !== '') // expected specific branch name or '' (all)
    )
      throw Error(`Unable to perform duplicate check. Probably data is invalid or partial`);
    const dups = await this.getPrivateRateDuplicates(
      code,
      type,
      practiceId,
      providerId,
      branchName,
      privateRateId
    );
    const hasConflicts =
      dups.filter((v) => PrivateRateUtils.hasConflict(privateRate, v)).length > 0;
    if (hasConflicts && errorMessage) this.dialogService.showWarning(errorMessage);
    return !hasConflicts;
  }

  validateNonEmptySpoRows(privateRate: PrivateRate): boolean {
    const spoCodesCount = privateRate?.SpoCodes?.length || 0;
    const invalid = privateRate.MedicalAidInvoices && spoCodesCount === 0;
    if (invalid)
      this.dialogService.showWarning('You must specify which schemes the rate applies to');
    return !invalid;
  }

  validateSpoDuplicates(privateRate: PrivateRate): boolean {
    const invalid =
      privateRate.MedicalAidInvoices &&
      PrivateRateUtils.hasSpoCodeDuplicates(privateRate?.SpoCodes);
    if (invalid)
      this.dialogService.showDialog({
        title: 'Conflicting rates',
        message:
          'There are conflicts in the medical aid list. Please fix the list before trying to save.',
        type: DialogType.WARNING,
      });
    return !invalid;
  }

  async save(
    practiceId: string,
    providerId: string,
    privateRateId: string,
    privateRate: PrivateRate // whole object must be passed here to perform duplicate validations
  ) {
    const valid =
      this.validateNonEmptySpoRows(privateRate) &&
      this.validateSpoDuplicates(privateRate) &&
      (await this.validateDuplicates(practiceId, providerId, privateRateId, privateRate));
    if (!valid) return false;
    const path = PrivateRateUtils.getPrivateRatePath(practiceId, providerId, privateRateId);
    await this.firestoreService.setDoc(doc(this.firestore, `${path}`), privateRate);
    return true;
  }
}
