import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {SidebarGroup} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  sidebarGroups$: BehaviorSubject<SidebarGroup[]> = new BehaviorSubject<SidebarGroup[]>([]);
  defaultMenuItems$: BehaviorSubject<SidebarGroup[]> = new BehaviorSubject<SidebarGroup[]>([]);

  updateMenu(...items: SidebarGroup[]) {
    this.sidebarGroups$.next(items);
  }

  resetMenu() {
    this.sidebarGroups$.next(null);
  }

  setDefaultMenu(...items: SidebarGroup[]) {
    this.defaultMenuItems$.next(items);
  }
}
