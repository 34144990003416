import { Attribute, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export type INFO_BOX_TYPE = 'DEFAULT' | 'INFO' | 'SUCCESS' | 'WARNING' | 'DANGER' | 'PRIMARY' | 'HELP';

@Component({
  selector: 'meraki-flux-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoBoxComponent implements OnInit {

  @Input() icon: string;
  @Input() showIcon: boolean = true;

  displayType: INFO_BOX_TYPE;

  @Input() set type(value: INFO_BOX_TYPE) {
    this.setIconClassObj(value);
    this.displayType = value;
  };

  iconClassObj = {};

  constructor(@Attribute('class') public classes) { }

  ngOnInit(): void { }

  setIconClassObj(type: INFO_BOX_TYPE) {
    let key = '';
    switch (type) {
      case 'DANGER':
        key = '!text-hb-critical-100';
        break;
      case 'INFO':
        key = '!text-hb-accent-100';
        break;
      case 'SUCCESS':
        key = '!text-hb-success-100';
        break;
      case 'WARNING':
        key = '!text-hb-warning-100';
        break;
      case 'PRIMARY':
        key = '!text-hb-primary-100';
        break;
      case 'HELP':
        key = '!text-hb-accent-help-100';
        break;
      case 'DEFAULT':
      default:
        key = '!text-hb-text-default';
        break;
    }

    this.iconClassObj = { [key]: true };
  }
}
