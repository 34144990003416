import * as moment from "moment";
import {ERADSReportModel, ERADSReportRequest} from "@meraki-flux/schema";

export class ERADSReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: ERADSReportRequest): Promise<ERADSReportModel> {
        const eRADSData = reportRequest.ERADSDataList;
        return {
            ReportData: eRADSData,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
            Practice: reportRequest.Practice
        };
    }
}
