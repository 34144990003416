import { DateUtils, PathUtils, PrivateRateUtils } from '@meraki-flux/purejs';
import { PrivatePriceRequest, PrivateRate, PrivateRateType, Scheme } from '@meraki-flux/schema';

export abstract class AbstractPrivateRatePricingService {
  async getPrice(req: PrivatePriceRequest) {
    if (!req?.practiceId || !req?.date || !req.priceCode || !req.type)
      throw Error('Invalid private rates pricing request');

    const schemes = await this.getAllSchemes();

    // Check provider level first
    const providerCollPath = PathUtils.praticeProviderPrivateRateCollectionPath(
      req.practiceId,
      req.providerId
    );
    let results = await this.filterPrivateRates(providerCollPath, req, schemes);

    // If nothing found on provider level, try practice level.
    if (results.length === 0) {
      const practiceCollPath = PathUtils.practicePrivateRateCollectionPath(req.practiceId);
      results = await this.filterPrivateRates(practiceCollPath, req, schemes);
    }

    return results?.[0]?.Price;
  }

  protected async filterPrivateRates(
    collectionPath: string,
    req: PrivatePriceRequest,
    schemes: Scheme[]
  ) {
    const filterFn = (pr: PrivateRate) => {
      const inDateRange = DateUtils.isDateInRange(req.date, pr.DateFrom, pr.DateTo);
      let spoMatch = false;
      if (!req.isMedicalAid && pr.CashInvoices) {
        spoMatch = true;
      } else if (req.isMedicalAid && pr.MedicalAidInvoices) {
        spoMatch =
          pr.SpoCodes?.filter((spo) => {
            const schemeMatch = spo.Scheme === '' || spo.Scheme === req.scheme;
            if (!schemeMatch) return false;
            return spo.Option === '' || spo.Option === req.option;
          }).length > 0;
      }
      return inDateRange && spoMatch;
    };
    let results = [];
    const privateRates = await this.getPrivateRates(
      collectionPath,
      req.priceCode,
      req.type,
      req.isMedicalAid,
      schemes
    );
    if (req.isMultiBranch && req.branch) {
      results = privateRates.filter(filterFn).filter((r) => r.BranchName === req.branch);
    }
    if (results.length === 0) {
      results = privateRates.filter(filterFn).filter((r) => !r.BranchName);
    }
    return results;
  }

  protected async getPrivateRates(
    collection: string,
    code: string,
    lineType: 'Procedure' | 'Medicine' | 'Consumable',
    isMedicalAid: boolean,
    schemes: Scheme[]
  ) {
    const applyTo = isMedicalAid ? 'MedicalAidInvoices' : 'CashInvoices';
    let privateRateType: PrivateRateType;
    if ('Procedure' === lineType) {
      privateRateType = PrivateRateType.PROCEDURE;
    } else if ('Medicine' === lineType) {
      privateRateType = PrivateRateType.MEDICINE;
    } else if ('Consumable' === lineType) {
      privateRateType = PrivateRateType.CONSUMABLE;
    }
    if (!collection || !lineType || !applyTo) return [];

    const privateRates = await this.findActivePrivateRates(
      collection,
      code,
      privateRateType,
      applyTo
    );

    return privateRates.map((pr) => {
      const privateRate: PrivateRate = DateUtils.timestampsToDates(pr);
      PrivateRateUtils.fixSPO(privateRate, schemes);
      return privateRate;
    });
  }

  abstract findActivePrivateRates(
    collectionPath: string,
    code: string,
    type: PrivateRateType,
    applyTo: 'CashInvoices' | 'MedicalAidInvoices'
  ): Promise<PrivateRate[]>;

  abstract getAllSchemes(): Promise<Scheme[]>;
}
