import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { getApp } from '@angular/fire/app';
import { environment } from '../../environments/environment';
import {ILoggerConfig, LoggerLevel, System} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root'})
export class LogService {
  private loggerLevel = LoggerLevel.DEBUG;
  private loggerConfig: ILoggerConfig;

  get level(): LoggerLevel {
    return this.loggerLevel;
  }

  set level(value) {
    this.loggerLevel = value;
  }

  get global_config() : ILoggerConfig {
    return this.loggerConfig;
  }

  constructor(
    public auth: AuthService,
  ) {
    this.initializeService();
  }

  private initializeService(): void {
    this.level = LoggerLevel.INFO;
    this.loggerConfig = {
      level: this.level,
      disableConsoleLogging: environment.disableConsoleLogging || false,
      disableServerLogging: environment.disableServerLogging || false,
      color: 'none',
      context: {
        timestamp: new Date().toISOString() || '',
        system: System.FLUX
      }
    };
  }

  trace(message?: any | (() => any), ...data: any[]): void{
    const config = this.global_config;
    config.color = 'light-green';
    this.level = LoggerLevel.TRACE;
    this.logMessage(message, data, config);
  }

  debug(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.color = 'cyan';
    this.level = LoggerLevel.DEBUG;
    this.logMessage(message, data, config);
  }

  info(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.color = 'white';
    this.level = LoggerLevel.LOG;
    this.logMessage(message, data, config);
  }

  log(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.level = LoggerLevel.LOG;
    this.logMessage(message, data);
  }

  warn(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.color = 'yellow';
    config.level = LoggerLevel.WARN;
    this.logMessage(message, data, config);
  }

  error(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.color = 'orange';
    config.level = LoggerLevel.ERROR;
    this.logMessage(message, data, config);
  }

  fatal(message?: any | (() => any), ...data: any[]): void {
    const config = this.global_config;
    config.color = 'red';
    config.level = LoggerLevel.FATAL;
    this.logMessage(message, data, config);
  }

  private logMessage (message?: any | (() => any), data?: any[], config?: ILoggerConfig): void {
    try {
      config = config ?? this.global_config;

      const context = config.context;
      context.timestamp = new Date().toISOString() || '';
      context.practiceId = this.auth.selectedBPN;
      context.userId = this.auth.uid;
      context.level = config.level;

      const logMessage = {
        message: message,
        context: context,
        data: data
      };

      //console logging
      if (!config.disableConsoleLogging)
        console.log(`%c${logMessage.message}`, `color: ${config.color}`, logMessage.data, logMessage.context);

      //server logging
      if (!config.disableServerLogging) {
        const functions = getFunctions(getApp(), 'europe-west1');
        const serverLog = httpsCallable(
          functions,
          'adm-utl-v1-oncall-log',
        );
        logMessage.data = [];
        serverLog(logMessage);
      }
    } catch (error) {
      console.error(error);
    }
  }
}


