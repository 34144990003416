<ng-container *ngTemplateOutlet="emphasize ? emphasizedContainer : basicContainer"></ng-container>

<ng-template #innerContent>
    <label class="flux-account-value-tile-label">{{label}}</label>
    <div class="flux-account-value-tile-content">
        <ng-content content></ng-content>
        {{value}}
    </div>
</ng-template>

<ng-template #basicContainer>
    <div class="{{ 'flux-account-value-tile ' + classes }}" [class.flux-account-value-tile-offset-content]="enableContentOffset">
        <ng-container *ngTemplateOutlet="innerContent"></ng-container>
    </div>
</ng-template>

<ng-template #emphasizedContainer>
    <div class="{{ 'flux-account-value-tile ' + classes }}"
    [class.flux-account-value-tile-offset-content-emphasized]="enableContentOffset"
    [emphasizeContainer]="'PRIMARY'" [emphasizeBackground]="true">
        <ng-container *ngTemplateOutlet="innerContent"></ng-container>
    </div>
</ng-template>