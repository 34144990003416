import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { AuthService, BusinessSearchService } from '@meraki-flux/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import {Business, User} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-practice-switch',
  templateUrl: './practice-switch.component.html',
  styleUrls: ['./practice-switch.component.scss'],
})
@UntilDestroy()
export class PracticeSwitchComponent implements OnInit {

  @ViewChild('searchField') searchField: ElementRef;

  searchForm = new FormControl('');

  selectedBusiness$ = this.auth.user$.pipe(
    map(user => ({
      name: user?.SelectedPracticeName ?? '',
      bpn: user?.SelectedBPN ?? ''
    })),
    untilDestroyed(this)
  );

  filteredBusinesses$ = new BehaviorSubject(null);

  searchBusy$ = new BehaviorSubject(false);

  skeletonLoaderTheme = {
    height: '32px',
    'margin-top': '1px',
    'margin-bottom': '1px'
  }

  constructor(
    private businessSearch: BusinessSearchService,
    protected auth: AuthService,
    private firestore: Firestore) {
  }

  ngOnInit(): void {
    this.searchForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchBusy$.next(true)),
      switchMap(searchVal => {
        return from(this.search(searchVal))
      }),
      catchError(err => {
        console.error(err);
        return of(null)
      }),
      tap(() => this.searchBusy$.next(false)),
      untilDestroyed(this)
    ).subscribe();
  }

  onBusinessSelected(business: Partial<Business>) {
    if (this.auth.selectedBPN === business?.BillingPracticeNumber) return;

    const data = {
      SelectedBPN: business.BillingPracticeNumber,
      SelectedPracticeName: business.PracticeName,
      SelectedBusinessId: business.BusinessId ? business.BusinessId : business.Id,
      SelectedBusinessType: business.BusinessType,
    };
    setDoc(doc(this.firestore, `User/${this.auth.uid}`), data, { merge: true }).then(() => location.reload());
  }

  onMenuOpened() {
    // If not a healthbridge admin user, prepopulate the list with the assigned user practices
    if (!this.auth.admin) {
      this.search('', true);
    }

    setTimeout(() => {
      this.searchField?.nativeElement?.focus();
    }, 100);
  }

  onMenuClosed() {
    this.searchBusy$.next(false);
    this.filteredBusinesses$.next(null);
    this.clearSearch();
  }

  clearSearch() {
    this.searchForm.setValue('');
    this.searchField?.nativeElement?.focus();
  }

  async search(searchVal: string, getAllResults = false) {
    const result = this.auth.admin ? await this.searchBusinesses(searchVal, getAllResults) : await this.searchUserPractices(searchVal);
    this.filteredBusinesses$.next(result || []);
  }

  async searchBusinesses(searchVal: string, getAllResults = false) {
    if (searchVal?.length > 1 || getAllResults) {
      let searchRes = await this.businessSearch.searchBusiness(searchVal, ['Details.Active=true'], ['PracticeName:asc']);
      const result = [];
      searchRes?.hits?.forEach(hit => {
        if (!result.find(res => res.BillingPracticeNumber === hit.BillingPracticeNumber)) {
          result.push(hit);
        }
      });
      return result;
    }
    return null;
  }

  async searchUserPractices(searchVal: string) {
    const currentUser: User = await this.auth.getUser();
    if (currentUser?.AssignedPractices) {
      const lowerSearchVal = searchVal.toLowerCase().trim();
      return currentUser.AssignedPractices.filter(prac =>
        prac.PracticeName.toLowerCase().includes(lowerSearchVal) ||
        prac.BillingPracticeNumber.includes(lowerSearchVal));
    }
    return null;
  }
}
