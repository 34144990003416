import {Audit, Auditable, Balance, BaseModel, BENEFIT_CHECK_STATUS } from './general.models';

export interface BaseAccount extends BaseModel, Auditable {
  Id?: string;
  AccountEntityType?: ACCOUNT_ENTITY_TYPE;
  HomeBranch?: string;
  AccountNo?: string;
  AccountNoDigit?: number;
  AccountType?: ACCOUNT_TYPE;
  AccountStatus?: ACCOUNT_STATUS;
  Scheme?: string;
  MemberNo?: string;
  Plan?: string;
  Option?: string;
  MainMember?: string;
  MainMemberDependantCode?: string;
  TreatingProvider?: string;
  PrimaryProvider?: string;
  SecondaryProvider?: string;
  CompanyName?: string;
  CompanyRegistrationNo?: string;
  VATNo?: string;
  MemberCount?: number;
  LastFamCheckDate?: Date;
  LastFamCheckSPO?: string;
  Members?: string[];
  Balance?: Balance;
  UnallocatedCredit?: number;
  NextOfKin?: NextOfKin;
  AccountSettings?: AccountSettings;
  ShowNoteModalOnOpen?: boolean;
  FirstInvoiceDate?: Date;
  LastStatementDate?: Date;
  LastBenefitCheckStatus?: BENEFIT_CHECK_STATUS;
  LastBenefitCheckReportUrl?: string;
  LastBenefitCheckDate?: Date;
  LastBcContractData?: any;
  LastValidatedScheme?: string;
}

export enum ACCOUNT_ENTITY_TYPE {
  PATIENT = 'Patient',
  COMPANY = 'Company',
}

export enum ACCOUNT_TYPE {
  MEDICAL_AID = 'Medical Aid',
  PRIVATE = 'Private',
}

export enum ACCOUNT_STATUS {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  FINAL_NOTICE = 'Final notice',
  HANDED_OVER = 'Handed over',
  ARCHIVED = 'Archived',
}

export interface NextOfKin {
  ContactPersonOne?: NextOfKinContact;
  ContactPersonTwo?: NextOfKinContact;
}

export interface NextOfKinContact {
  Name?: string;
  CellphoneNo?: string;
  EmailAddress?: string;
  Notes?: string;
}

export interface AccountSettings {
  OptionalCCEmail?: string;
}

export interface AccountStatusChangedAudit extends Audit {
  PreviousStatus?: string;
  NewStatus?: string;
  Reason?: string;
}

export interface Note extends BaseModel {
  Id?: string;
  Note: string;
  Pinned?: boolean;
  ShowNote?: boolean;
  OriginType: NOTE_ORIGIN_TYPE;
}

export enum NOTE_ORIGIN_TYPE {
  USER = 'User',
  SYSTEM = 'System',
}
