import {Injectable} from "@angular/core";
import {ReportUtils} from "../report-utils";
import {ClaimInvoicePdfReportDataBuilder} from "./claim-invoice-pdf-report-data-builder";
import {ClaimInvoiceReportBuilder} from "./claim-invoice-report-builder";
import {ClaimInvoiceXlsReportDataBuilder} from "./claim-invoice-xls-report-data-builder";
import {
  BaseExcelReportData,
  BasePdfReportData,
  ClaimInvoiceReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class ClaimInvoiceReportService {

  async generateReport(reportRequest: ClaimInvoiceReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildReportFileName('Claims_and_Invoices',
      reportRequest.Practice, reportRequest.TreatingProvider?.Provider, reportRequest.Branch.Name, reportRequest.ReportFormatType);

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.CLAIMS_AND_INVOICES,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: ClaimInvoiceReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    } else if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: ClaimInvoiceReportRequest): Promise<BasePdfReportData> {
    return new ClaimInvoicePdfReportDataBuilder().build(
      await new ClaimInvoiceReportBuilder().build(reportRequest));
  }

  private async generateXlsReportData(reportRequest: ClaimInvoiceReportRequest): Promise<BaseExcelReportData> {
    return new ClaimInvoiceXlsReportDataBuilder().build(
      await new ClaimInvoiceReportBuilder().build(reportRequest));
  }
}
