import { BALANCE_ACTION } from "@meraki-flux/schema";

export class CapturePaymentUtils {
  static getRecommendedAction(balanceOutstanding: number, writeOffThreshold: any) {
    if (balanceOutstanding > 0 && balanceOutstanding <= writeOffThreshold) {
      return BALANCE_ACTION.WRITE_OFF;
    } else if (balanceOutstanding > (writeOffThreshold || 0)) {
      return BALANCE_ACTION.PATIENT_LIABLE;
    } else if (balanceOutstanding < 0) {
      return BALANCE_ACTION.UNALLOCATED_CREDIT;
    } else {
      return BALANCE_ACTION.NO_ACTION;
    }
  }
}
