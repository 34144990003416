import { Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import { Firestore } from '@angular/fire/firestore';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { InboxService } from './inbox.service';
import { LogService } from './log.service';

@Injectable({ providedIn: 'root' })
export class InboxCollectionService extends InboxService {
  constructor(
    firestore: Firestore,
    firestoreService: FirestoreService,
    authService: AuthService,
    logger: LogService,
  ) {
    super(firestore, firestoreService, authService, logger);
  }

  async triggerCollectBalance(practiceId: string, accountId: string) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const callable = httpsCallable(functions, 'inb-utl-v1-oncall-triggerCollectBalance');
    await callable({
      practiceId: practiceId,
      accountId: accountId,
    });
  }
}
