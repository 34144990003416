import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { InvoiceService } from '../invoice/invoice.service';
import { TransactionCaptureService } from './transaction-capture.service';
import {ERROR_CODE, Invoice, Reverse} from "@meraki-flux/schema";

@Injectable()
export class CreditService {
  constructor(
    private transactionCaptureService: TransactionCaptureService,
    private invoiceService: InvoiceService
  ) {}

  invoicesToCredit$(accountId: string): Observable<Invoice[]> {
    return this.invoiceService.getOpenInvoicesWithBalance(accountId);
  }

  async hasInvoicesToCredit(accountId: string): Promise<boolean> {
    const invoices = await this.invoicesToCredit$(accountId).pipe(first()).toPromise();
    return invoices.length > 0;
  }

  async reverse(
    accountId: string,
    creditTxHeaderId: string,
    reason: string,
    onCompleteFn: (success: boolean, errorCode: ERROR_CODE) => void
  ) {
    const reverse = { CreditTxHeaderId: creditTxHeaderId, Reason: reason } as Reverse;
    await this.transactionCaptureService.reverse(accountId, reverse, onCompleteFn);
  }
}
