import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  Firestore,
  query,
  where
} from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import { ProviderUtils } from '../utils/provider-utils';
import {PracticeProvider, Provider, ProviderType} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(private firestore: Firestore, private authService: AuthService) {}

  treatingProviders$ = this.authService.practice$.pipe(
    switchMap((practice) =>
        collectionSnapshots(
          query(
            collection(
              this.firestore,
              PathUtils.practiceProviderCollectionPath(practice.Id)
            ),
            where('Type', '==', ProviderType.TREATING),
            where('IsActive', '==', true)
          )
        )
      ),
    map((refs) => refs.map((ref) => ({ Id: ref.id, ...ref.data() })))
  );

  activeTreatingProviders$(
    billingPracticeNumber: any
  ) {
    return collectionSnapshots<PracticeProvider>(
      query(
        collection(
          this.firestore,
          `Practice/${billingPracticeNumber}/Provider`
        ),
        where('Type', '==', ProviderType.TREATING),
        where('IsActive', '==', true)
      )
    ).pipe(
      map((r) => r.map((docRef) => ({ Id: docRef.data().HPCSANumber, ...docRef.data() }))),
      map((v) =>
        v.sort((v1, v2) =>
          ProviderUtils.getFullName(v1).localeCompare(ProviderUtils.getFullName(v2))
        )
      )
    );
  }

  lookupTreatingProviders$(
    billingPracticeNumber: any
  ) {
    return collectionSnapshots<Provider>(
      query(
        collection(
          this.firestore,
          `Practice/${billingPracticeNumber}/Provider`
        ),
        where('Type', '==', ProviderType.TREATING),
        where('IsActive', '==', true),
      )
    ).pipe(
      map((r) => r.map((docRef) => ({
              UserId: docRef.data().HPCSANumber,
              Title: docRef.data().Title,
              Name: docRef.data().Name,
              Surname: docRef.data().Surname,
            } as Provider))),
      map((v) =>
        v.sort((v1, v2) =>
          ProviderUtils.getNameOnly(v1).localeCompare(ProviderUtils.getNameOnly(v2))
        )
      )
    );
  }
}
