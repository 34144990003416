import {Injectable} from "@angular/core";
import {ERADSReportBuilder} from "./era-ds-report-builder";
import {BasePdfReportData, ERADSReportRequest, Report, REPORT_FORMAT_TYPE, REPORT_NAME} from "@meraki-flux/schema";
import { ERADSPdfReportDataBuilder } from "./era-ds-pdf-report-data-builder";
import moment from "moment";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class ERADSReportService {

  private readonly DATE_FORMAT = "DD/MM/YYYY";

  async generateReport(reportRequest: ERADSReportRequest): Promise<Report> {
    //const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);
    const reportGenerator: BasePdfGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: 'eRADeliverySchedule_' + moment(new Date()).format(this.DATE_FORMAT),
      reportName: REPORT_NAME.ERA_DS,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      //format: REPORT_FORMAT_TYPE.EXCEL
      format: REPORT_FORMAT_TYPE.PDF
    }
  }

/*   private async getReportGenerator(reportRequest: ERADSReportRequest): Promise<BaseExcelGenerator> {
     return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
  } */

/*  private async generateXlsReportData(reportRequest: ERADSReportRequest): Promise<BaseExcelReportData> {
   return new ERADSXlsReportDataBuilder().build(
    await new ERADSReportBuilder().build(reportRequest));
  } */

  private async getReportGenerator(reportRequest: ERADSReportRequest): Promise<BasePdfGenerator> {
    return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
 }

  private async generatePdfReportData(reportRequest: ERADSReportRequest): Promise<BasePdfReportData> {
    return new ERADSPdfReportDataBuilder().build(
      await new ERADSReportBuilder().build(reportRequest));
  }

}
