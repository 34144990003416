import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {
  BaseExcelReportData,
  BasePdfReportData,
  LineItemReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from '@meraki-flux/schema';
import {
  BaseExcelGenerator,
  BasePdfGenerator,
  LineItemPdfReportDataBuilder,
  LineItemReportBuilder,
  LineItemXlsReportDataBuilder
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root',
})
export class LineItemReportService {
  async generateReport(reportRequest: LineItemReportRequest): Promise<Report> {
    const fileName: string = await ReportUtils.buildRefundReportFileName(
      'LineItemsBilled',
      reportRequest.Practice,
      reportRequest.Branch.Name,
      reportRequest.ReportFormatType,
      reportRequest.DateFrom,
      reportRequest.DateTo
    );

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.LINEITEMBILLED,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: reportRequest.ReportFormatType,
    };
  }

  private async getReportGenerator(
    reportRequest: LineItemReportRequest
  ): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    } else
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error('Report format is not supported.');
    }
  }

  private async generateXlsReportData(
    reportRequest: LineItemReportRequest
  ): Promise<BaseExcelReportData> {
    return new LineItemXlsReportDataBuilder().build(
      await new LineItemReportBuilder().build(reportRequest)
    );
  }

  private async generatePdfReportData(reportRequest: LineItemReportRequest): Promise<BasePdfReportData> {
    return new LineItemPdfReportDataBuilder().build(
      await new LineItemReportBuilder().build(reportRequest));
  }

}
