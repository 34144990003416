import {
  BaseExcelReportData, CellType, CellWidth, CreditNoteDetailsItem,
  CreditNoteProviderModel,
  CreditNoteReportModel, CreditNoteSummaryItem, ExcelCellData,
  REPORT_NAME,
  Sheet, Table
} from '@meraki-flux/schema';


export class CreditNotesXlsReportDataBuilder {

    build(creditNoteReportModel: CreditNoteReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = creditNoteReportModel.CreditNoteReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.CREDIT_NOTES;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, creditNoteReportModel);

        //const isOptometrist: boolean = creditNoteReportModel.CreditNoteReportInfo.Speciality == Speciality.OPTOMETRIST;

        if (!creditNoteReportModel.AllProvidersModel) {
          creditNoteReportModel.ProviderModels.forEach((providerModel) => {
            excelReportData.data.push(
                this.buildReportSheetSummary(providerModel)
            );
            excelReportData.data.push(
                this.buildReportSheetDetail(providerModel, creditNoteReportModel.CreditNoteReportInfo.IsMultiBranch, false)
            );
          });
        } else {
          excelReportData.data.push(
            this.buildReportSheetSummary(creditNoteReportModel.AllProvidersModel)
          );
          creditNoteReportModel.ProviderModels.forEach((providerModel) => {
            if(providerModel.DetailsTable.length > 0){
                excelReportData.data.push(
                    this.buildReportSheetDetail(providerModel, creditNoteReportModel.CreditNoteReportInfo.IsMultiBranch,true)
                );
            }
          });
        //   excelReportData.data.push(
        //     this.buildReportSheetDetail(creditNoteReportModel.AllProvidersModel,creditNoteReportModel.CreditNoteReportInfo.IsMultiBranch, true)
        //   );

        }

        return excelReportData;
    }

    private buildReportSheetSummary(providerModel: CreditNoteProviderModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary' //providerModel.ProviderName.substring(0,30);
        sheet.header = 'Credit Notes Report'
        sheet.tables = [];

        const summaryTable: Table = {};
        //summaryTable.name = 'Summary';
        summaryTable.headers = [
        {value: 'Provider', bold: true, width: CellWidth.M},
        {value: 'Discount', bold: true, width: CellWidth.XS},
        {value: 'Correction of error', bold: true, width: CellWidth.XS, wordWrap: true},
        {value: 'Other', bold: true, width: CellWidth.XS},
        {value: 'Reversal processed', bold: true, width: CellWidth.XS, wordWrap: true},
        {value: 'Claim resubmitted', bold: true, width: CellWidth.XS, wordWrap: true},
        {value: 'Cash invoice cancellation', bold: true, width: CellWidth.XS, wordWrap: true}
        ];


        let totalDiscount = 0;
        let totalCorrection_Of_Error = 0;
        let totalOther = 0;
        let totalReversal_Processed = 0;
        let totalClaim_Resubmitted = 0;
        let totalCash_Invoice_Cancellation = 0;

        const rows = [];
        providerModel.SummaryTable.forEach((item: CreditNoteSummaryItem) => {

            totalDiscount += item.Discount? item.Discount : 0;
            totalCorrection_Of_Error += item.Correction_of_error? item.Correction_of_error : 0;
            totalOther += item.Other? item.Other : 0;
            totalReversal_Processed += item.Reversal_processed? item.Reversal_processed : 0;
            totalClaim_Resubmitted += item.Claim_resubmitted? item.Claim_resubmitted : 0;
            totalCash_Invoice_Cancellation += item.Cash_invoice_cancellation? item.Cash_invoice_cancellation : 0;

            const row: ExcelCellData[] = [];
            row.push({value: item.Provider});
            row.push({value: item.Discount? item.Discount : 0 , type: CellType.CURRENCY});
            row.push({value: item.Correction_of_error? item.Correction_of_error : 0 , type: CellType.CURRENCY});
            row.push({value: item.Other? item.Other : 0 , type: CellType.CURRENCY});
            row.push({value: item.Reversal_processed? item.Reversal_processed : 0 , type: CellType.CURRENCY});
            row.push({value: item.Claim_resubmitted? item.Claim_resubmitted : 0 , type: CellType.CURRENCY});
            row.push({value: item.Cash_invoice_cancellation? item.Cash_invoice_cancellation : 0 , type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: ExcelCellData[] = [];
        row.push({value: 'Total', bold: true});
        row.push({value: totalDiscount? totalDiscount : 0 , type: CellType.CURRENCY, bold: true});
        row.push({value: totalCorrection_Of_Error? totalCorrection_Of_Error : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalOther? totalOther : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalReversal_Processed? totalReversal_Processed : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalClaim_Resubmitted? totalClaim_Resubmitted : 0, type: CellType.CURRENCY, bold: true});
        row.push({value: totalCash_Invoice_Cancellation? totalCash_Invoice_Cancellation : 0, type: CellType.CURRENCY, bold: true});
        rows.push(row);

        rows.push([{ value: '' }]);

        // Vat Exclusive calculation
        totalDiscount = 0;
        totalCorrection_Of_Error = 0;
        totalOther = 0;
        totalReversal_Processed = 0;
        totalClaim_Resubmitted = 0;
        totalCash_Invoice_Cancellation = 0;

        const exclVatItems = providerModel.SummaryVATTable.filter(item => item.Provider == "HeaderAmount");
        exclVatItems.forEach((item: CreditNoteSummaryItem) => {
            totalDiscount += item.Discount? item.Discount : 0;
            totalCorrection_Of_Error += item.Correction_of_error? item.Correction_of_error : 0;
            totalOther += item.Other? item.Other : 0;
            totalReversal_Processed += item.Reversal_processed? item.Reversal_processed : 0;
            totalClaim_Resubmitted += item.Claim_resubmitted? item.Claim_resubmitted : 0;
            totalCash_Invoice_Cancellation += item.Cash_invoice_cancellation? item.Cash_invoice_cancellation : 0;
        });

        // Vat Amount calculation
        let totalDiscountVat = 0;
        let totalCorrection_Of_ErrorVat = 0;
        let totalOtherVat = 0;
        let totalReversal_ProcessedVat = 0;
        let totalClaim_ResubmittedVat = 0;
        let totalCash_Invoice_CancellationVat = 0;

        const vatAmountItems = providerModel.SummaryVATTable.filter(item => item.Provider == "HeaderAmountVAT");
        vatAmountItems.forEach((item: CreditNoteSummaryItem) => {
            totalDiscountVat += item.Discount? item.Discount : 0;
            totalCorrection_Of_ErrorVat += item.Correction_of_error? item.Correction_of_error : 0;
            totalOtherVat += item.Other? item.Other : 0;
            totalReversal_ProcessedVat += item.Reversal_processed? item.Reversal_processed : 0;
            totalClaim_ResubmittedVat += item.Claim_resubmitted? item.Claim_resubmitted : 0;
            totalCash_Invoice_CancellationVat += item.Cash_invoice_cancellation? item.Cash_invoice_cancellation : 0;
        });

        const totalVatAmount = totalDiscountVat + totalCorrection_Of_ErrorVat + totalOtherVat
                            + totalReversal_ProcessedVat + totalClaim_ResubmittedVat + totalCash_Invoice_CancellationVat;

        if(totalVatAmount != 0){
            const exclVatRow: ExcelCellData[] = [];
            exclVatRow.push({value: "Total (excl. VAT)", bold: true});
            exclVatRow.push({value: totalDiscount? totalDiscount : 0 , type: CellType.CURRENCY});
            exclVatRow.push({value: totalCorrection_Of_Error? totalCorrection_Of_Error : 0, type: CellType.CURRENCY});
            exclVatRow.push({value: totalOther? totalOther : 0, type: CellType.CURRENCY});
            exclVatRow.push({value: totalReversal_Processed? totalReversal_Processed : 0, type: CellType.CURRENCY});
            exclVatRow.push({value: totalClaim_Resubmitted? totalClaim_Resubmitted : 0, type: CellType.CURRENCY});
            exclVatRow.push({value: totalCash_Invoice_Cancellation? totalCash_Invoice_Cancellation : 0, type: CellType.CURRENCY});
            rows.push(exclVatRow);

            const vatAmountRow: ExcelCellData[] = [];
            vatAmountRow.push({value: "Total VAT amount", bold: true});
            vatAmountRow.push({value: totalDiscountVat? totalDiscountVat : 0 , type: CellType.CURRENCY});
            vatAmountRow.push({value: totalCorrection_Of_ErrorVat? totalCorrection_Of_ErrorVat : 0, type: CellType.CURRENCY});
            vatAmountRow.push({value: totalOtherVat? totalOtherVat : 0, type: CellType.CURRENCY});
            vatAmountRow.push({value: totalReversal_ProcessedVat? totalReversal_ProcessedVat : 0, type: CellType.CURRENCY});
            vatAmountRow.push({value: totalClaim_ResubmittedVat? totalClaim_ResubmittedVat : 0, type: CellType.CURRENCY});
            vatAmountRow.push({value: totalCash_Invoice_CancellationVat? totalCash_Invoice_CancellationVat : 0, type: CellType.CURRENCY});
            rows.push(vatAmountRow);
        }

        summaryTable.rows = rows;
        sheet.tables.push(summaryTable);

        return sheet;
    }

    private buildReportSheetDetail(providerModel: CreditNoteProviderModel, addBranchColumn?: boolean, isAllProvider?: boolean): Sheet {
        const sheet: Sheet = {};
        sheet.name = isAllProvider? providerModel.ProviderName.substring(0,30) : 'Detail';
        sheet.header = 'Credit Notes Report';
        sheet.tables = [];

        const detailsTable: Table = {};
        //detailsTable.name = 'Credit Notes Report';

        detailsTable.headers = [
            {value: 'Credit note date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Credit note type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Credit note amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Reason', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Captured by', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Account no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Patient', bold: true, width: CellWidth.XS},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS},
            {value: 'Date of service', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Date of submission', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Invoice amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Provider', bold: true, width: CellWidth.XS},
        ];

        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.XS});
        }

        const detailsRows = [];
        providerModel.DetailsTable.forEach((item: CreditNoteDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.CreditNoteDate, type: CellType.GENERAL});
            row.push({value: item.CreditNoteType});
            row.push({value: item.CreditNoteAmount, type: CellType.CURRENCY});
            row.push({value: item.Reason});
            row.push({value: item.CaptureBy});
            row.push({value: item.AccountNo});
            row.push({value: item.Patient});
            row.push({value: item.InvoiceNo});
            row.push({value: item.DateOfService, type: CellType.GENERAL});
            row.push({value: item.DateofSubmission, type: CellType.GENERAL});
            row.push({value: item.InvoiceAmount, type: CellType.CURRENCY});
            row.push({value: item.Provider});
            if (addBranchColumn)
                row.push({value: item.Branch});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }



    private buildReportInfo(excelReportData: BaseExcelReportData, creditNoteReportModel: CreditNoteReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: creditNoteReportModel.CreditNoteReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: creditNoteReportModel.CreditNoteReportInfo.PracticeId, type: CellType.GENERAL}
        });

        if (creditNoteReportModel.CreditNoteReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: creditNoteReportModel.CreditNoteReportInfo.Branch, type: CellType.GENERAL}
                });
        }

        excelReportData.parameters.push(
            {
                name: 'Date Range Type',
                   data: {value: creditNoteReportModel.CreditNoteReportInfo.DateRangeType, type: CellType.GENERAL}
            });

        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: creditNoteReportModel.CreditNoteReportInfo.DateRange, type: CellType.GENERAL}
            });

        excelReportData.parameters.push(
            {
                name: 'Treating Provider',
                data: {value: creditNoteReportModel.CreditNoteReportInfo.TreatingProvider, type: CellType.GENERAL}
            });
    }
}
