import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Breadcrumb } from '../interfaces/breadcrumb';
import { LogService } from './log.service';
import { BehaviorSubject } from "rxjs";
import { filter, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  TOKEN_REGEX = /\${(?<placeholder>[A-Za-z0-9-_]+)}/g

  breadcrumbs$: BehaviorSubject<Breadcrumb[]>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hidden$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  tokenCache: { [key: string]: string } = {};

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private logger: LogService) {
    this.breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.loading$.next(true)),
      )
      .subscribe(() => {
        this.updateBreadcrumbs(...this.createBreadcrumbs(this.activatedRoute.root));
        // this.logger.info(this.breadcrumbs$.value);
        this.loading$.next(false);
      });
  }

  updateBreadcrumbs(...breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs$.next([...breadcrumbs]);
  }

  updateBreadcrumbLabels(labels: { [key: string]: string }) {
    this.hidden$.next(false);
    if (labels) {
      this.tokenCache = { ...this.tokenCache, ...labels };
      const breadcrumbValue = this.breadcrumbs$.value;
      const updatedValue = [];
      for (const breadcrumb of breadcrumbValue) {
        const label = breadcrumb.label?.replace(this.TOKEN_REGEX, (matchedVal: string) => {
          let key = matchedVal.startsWith('${') ? matchedVal.substring(2) : matchedVal;
          key = key.endsWith('}') ? key.substring(0, key.length - 1) : key;

          if (key in labels) {
            return labels[key];
          }
          else if (key in this.tokenCache) {
            return this.tokenCache[key];
          }

          return matchedVal;
        });
        
        breadcrumb.loading = this.TOKEN_REGEX.test(label);
        updatedValue.push({ ...breadcrumb, label });
      }
      this.breadcrumbs$.next(updatedValue);
    }
  }

  deleteCacheKey(key: string) {
    delete this.tokenCache[key];
  }

  hideBreadcrumbs() {
    this.hidden$.next(true);
  }

  showBreadcrumbs() {
    this.hidden$.next(false);
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): any {

    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb']
      if (breadcrumbs && !!label) {
        if (breadcrumbs.length === 0 || (breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].url !== url)) {
          const loading = this.TOKEN_REGEX.test(label);
          breadcrumbs.push({ label, url, loading });
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

}
