import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, concat, from } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { BusinessSearchService } from './business-search.service';

@Component({
  selector: 'meraki-flux-business-search',
  templateUrl: './business-search.component.html',
  styleUrls: ['./business-search.component.scss']
})
export class BusinessSearchComponent implements OnInit {
  @Output() updateBusiness: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('autoTrigger', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger | undefined;

  range = new UntypedFormGroup({
    selectedBusiness: new UntypedFormControl(),
  });

  selectedBusiness = new UntypedFormControl();
  filteredBusiness: Observable<any> = of({ type: 'start'});
  businessFound = false;

  constructor(private businessSearch: BusinessSearchService) { }

  ngOnInit(): void {
    this.filteredBusiness = this.selectedBusiness.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(val =>
        concat(
          of({ type: 'start'}),
          this._filter(val || '').pipe(map((value:any) => ({ type: 'finish', value: value ? value.hits : [] })))
        )
      )
    );
  }

  _filter(value: any): Observable<any> {
    const filterValue = value && !value.BillingPracticeNumber ? value.toLowerCase() : '';
    if (filterValue.length > 1) {
      return from(this.businessSearch.searchBusiness(value))
    } else {
      return of(null);
    }
  }

  selectBusiness(business: any) {
    this.businessFound = true;
    this.range.controls.selectedBusiness.setValue(business);
    this.range.updateValueAndValidity();
    this.updateBusiness.emit(business);
  }

  clearBusiness() {
    this.businessFound = false;
    this.range.controls.selectedBusiness.setValue('');
    this.selectedBusiness.patchValue('');
    this.range.updateValueAndValidity();
  }

  displayFn(business: any): string {
    return business
      ? `${business.BillingPracticeNumber} ${business.PracticeName} (${business.BusinessType})`
      : '';
  }

  panelChange(panelOpen: any) {
    if (!panelOpen) {
      this.range.updateValueAndValidity();
    }
  }

}
