import {Injectable} from "@angular/core";
import {
  BaseExcelReportData,
  CustomChargeCodesReportRequest, Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {ReportUtils} from "../report-utils";
import {CustomChargeCodesReportBuilder} from "./custom-charge-codes-report-builder";
import {CustomChargeCodesXlsReportDataBuilder} from "./custom-charge-codes-xls-report-data-builder";
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class CustomChargeCodesReportService {

  async generateReport(reportRequest: CustomChargeCodesReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildCustomChargeCodesReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.ReportFormatType);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.CUSTOM_CHARGE_CODES,
      format: REPORT_FORMAT_TYPE.EXCEL,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    };
  }

  private async getReportGenerator(reportRequest: CustomChargeCodesReportRequest): Promise<BaseExcelGenerator> {
    return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
  }

  private async generateXlsReportData(reportRequest: CustomChargeCodesReportRequest): Promise<BaseExcelReportData> {
    return new CustomChargeCodesXlsReportDataBuilder().build(
      await new CustomChargeCodesReportBuilder().build(reportRequest));
  }
}
