import { v4 as uuidv4 } from 'uuid';
import { DateUtils } from './date-utils';
import { SchemeUtils } from './scheme-utils';
import { PrivateRate, SchemePlanOption, Scheme, PrivateRateType, INVOICE_LINE_TYPE, User } from "@meraki-flux/schema";
import { PathUtils } from './path-utils';

export class PrivateRateUtils {
  static readonly ALL_BRANCHES_VALUE = '';

  static getPrivateRateCollectionPath(practiceId: string, providerId: string): string {
    return providerId
      ? PathUtils.praticeProviderPrivateRateCollectionPath(practiceId, providerId)
      : PathUtils.practicePrivateRateCollectionPath(practiceId);
  }

  static getPrivateRatePath(practiceId: string, providerId: string, privateRateId: string): string {
    return providerId
      ? PathUtils.practiceProviderPrivateRatePath(practiceId, providerId, privateRateId)
      : PathUtils.practicePrivateRatePath(practiceId, privateRateId);
  }

  static dateRangesConflict(date1From: any, date1To: any, date2From: any, date2To: any): boolean {
    return DateUtils.dateRangesConflict(date1From, date1To, date2From, date2To);
  }

  static hasConflict(pr1: PrivateRate, pr2: PrivateRate): boolean {
    return (
      PrivateRateUtils.dateRangesConflict(pr1?.DateFrom, pr1?.DateTo, pr2?.DateFrom, pr2?.DateTo) &&
      ((pr1.CashInvoices === true && pr2.CashInvoices === true) ||
        (pr1.MedicalAidInvoices === true &&
          pr2.MedicalAidInvoices === true &&
          PrivateRateUtils.hasSpoCodeDuplicates([
            ...(pr1.SpoCodes || []),
            ...(pr2.SpoCodes || []),
          ])))
    );
  }

  static hasSpoCodeDuplicates(spoCodes: SchemePlanOption[]) {
    const anySchemeSelectedRecords = spoCodes.filter((i) => i.Scheme === '' || i.Scheme);
    const hasAllSchemesSelected =
      anySchemeSelectedRecords.filter((i) => i.Scheme === '').length > 0;
    if (hasAllSchemesSelected && anySchemeSelectedRecords.length > 1) return true;
    const specificSchemeSelectedRecords = spoCodes.filter((i) => i.Scheme);
    for (let s = 0; s < specificSchemeSelectedRecords.length; s++) {
      const schemeCode = specificSchemeSelectedRecords[s].Scheme;
      const sameSchemeRecords = specificSchemeSelectedRecords.filter(
        (i) => i.Scheme === schemeCode
      );
      if (sameSchemeRecords.length < 2) continue;
      const hasAllPlansSelected = sameSchemeRecords.filter((i) => i.Plan === '').length > 0;
      if (hasAllPlansSelected) return true;
      for (let p = 0; p < sameSchemeRecords.length; p++) {
        const planCode = sameSchemeRecords[p].Plan;
        const samePlanRecords = sameSchemeRecords.filter((i) => i.Plan === planCode);
        if (samePlanRecords.length < 2) continue;
        const hasAllOptionsSelected = samePlanRecords.filter((i) => i.Option === '').length > 0;
        if (hasAllOptionsSelected) return true;
        for (let o = 0; o < samePlanRecords.length; o++) {
          const optionCode = samePlanRecords[o].Option;
          const sameOptionRecords = samePlanRecords.filter((i) => i.Option === optionCode);
          if (sameOptionRecords.length > 1) return true;
        }
      }
    }
    return false;
  }

  static newId() {
    return uuidv4();
  }

  static describePrivateRatesApplyTo(
    privateRate: PrivateRate,
    schemes: Scheme[],
    separator: string = '; '
  ) {
    const descriptions = [];
    if (privateRate?.CashInvoices) {
      descriptions.push('Cash');
    }
    if (privateRate?.MedicalAidInvoices) {
      const spoCodes: SchemePlanOption[] = privateRate?.SpoCodes || [];
      spoCodes.map((spoCode) =>
        descriptions.push(this.describePrivateRateApplyTo(spoCode, schemes))
      );
    }
    return descriptions.join(separator);
  }

  static describePrivateRateApplyTo(spoCode: SchemePlanOption, schemes: Scheme[]) {
    if (!spoCode?.Scheme) {
      return 'All schemes';
    } else {
      const schemeName = schemes?.find((s) => s.Code === spoCode.Scheme)?.Name || spoCode.Scheme;
      if (!spoCode?.Plan) {
        return `${schemeName}/All/All`;
      } else {
        const scheme = schemes.find((s) => s.Code === spoCode.Scheme);
        const plans = scheme?.Plans || [];
        const plan = plans.find((p) => p.Code === spoCode.Plan);
        const planName = plan?.Plan || spoCode.Plan;
        if (!spoCode.Option) {
          return `${schemeName}/${planName}/All`;
        } else {
          const optionName =
            plan?.Options?.find((o) => o.Code === spoCode.Option)?.Option || spoCode.Option;
          return `${schemeName}/${planName}/${optionName}`;
        }
      }
    }
  }

  static userDisplayName(user: User) {
    return `${user?.Name || ''} ${user?.Surname || ''}`.trim();
  }

  static mapPrivateRateTypeToInvoiceLineType(privateRateType: PrivateRateType) {
    switch (privateRateType) {
      case PrivateRateType.PROCEDURE:
        return INVOICE_LINE_TYPE.PROCEDURE;
      case PrivateRateType.MEDICINE:
        return INVOICE_LINE_TYPE.MEDICINE;
      case PrivateRateType.CONSUMABLE:
        return INVOICE_LINE_TYPE.CONSUMABLE;
      default:
        return INVOICE_LINE_TYPE.PROCEDURE;
    }
  }

  static mapInvoiceLineTypeToPrivateRateType(invoiceLineType: INVOICE_LINE_TYPE) {
    switch (invoiceLineType) {
      case INVOICE_LINE_TYPE.PROCEDURE:
        return PrivateRateType.PROCEDURE;
      case INVOICE_LINE_TYPE.MEDICINE:
        return PrivateRateType.MEDICINE;
      case INVOICE_LINE_TYPE.CONSUMABLE:
        return PrivateRateType.CONSUMABLE;
      default:
        return undefined;
    }
  }

  static fixSPO(pr: PrivateRate, schemes: Scheme[]) {
    if (!pr || !pr?.MedicalAidInvoices || pr?.SpoCodes?.length > 0) return;
    const schemeCode = pr.SchemeCode;
    if (!schemeCode) {
      pr.SpoCodes = [{ Scheme: '', Plan: '', Option: '' }];
    } else {
      const optionCode = pr.OptionCode;
      if (!optionCode) {
        pr.SpoCodes = [{ Scheme: schemeCode, Plan: '', Option: '' }];
      } else {
        const plan = SchemeUtils.getPlanByOptionCode(schemes, schemeCode, optionCode);
        pr.SpoCodes = [{ Scheme: schemeCode, Plan: plan?.Code, Option: optionCode }];
      }
    }
  }

  static match(
    pr: PrivateRate,
    dos: Date,
    schemeCode: string,
    optionCode: string,
    applyTo: 'Cash' | 'Medical Aid'
  ): boolean {
    const inDateRange = DateUtils.isDateInRange(dos, pr.DateFrom, pr.DateTo);
    let spoMatch = false;
    if (applyTo === 'Cash' && pr.CashInvoices) {
      spoMatch = true;
    } else if (applyTo === 'Medical Aid' && pr.MedicalAidInvoices) {
      spoMatch =
        pr.SpoCodes?.filter((spo) => {
          const schemeMatch = spo.Scheme === '' || spo.Scheme === schemeCode;
          if (!schemeMatch) return false;
          return spo.Option === '' || spo.Option === optionCode;
        }).length > 0;
    }
    return inDateRange && spoMatch;
  }
}
