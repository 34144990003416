import { Injectable } from "@angular/core";
import { PaymentService } from "../../services/transaction/payment.service";
import { TransactionService } from "../../services/transaction/transaction.service";
import { ReportUtils } from "../report-utils";
import { StatementPdfReportDataBuilder } from "./statement-pdf-report-data-builder";
import { StatementReportBuilder } from "./statement-report-builder";
import _ from "lodash";
import { ReferenceDataService } from "../../services/reference-data.service";
import {
  ACCOUNT_TYPE,
  BasePdfReportData,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME,
  StatementReportRequest
} from "@meraki-flux/schema";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class StatementReportService {

  constructor(
    private transactionService: TransactionService,
    private paymentService: PaymentService,
    private referenceDataService: ReferenceDataService
) {}

  async generateReport(reportRequest: StatementReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildStatementReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.Account.AccountNo,
      reportRequest.MainMember.Name, reportRequest.MainMember.Surname, REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    return {
      reportName: REPORT_NAME.STATEMENT,
      content: reportGenerator.generateReport(),
      fileName: fileName,
      format: REPORT_FORMAT_TYPE.PDF,
      practiceId: reportRequest.Practice.Id
    };
  }

  private async generatePdfReportData(reportRequest: StatementReportRequest): Promise<BasePdfReportData> {
    const practiceId = reportRequest.Practice.Id;
    const account = reportRequest.Account;
    const transactions = _.flatten(await Promise.all(reportRequest.Invoices
      .map(async i => await this.transactionService.getActiveInvoiceTransactions(practiceId, account.Id, i.Id))));
    const payments = await Promise.all(transactions
      .filter(t => t.Metadata?.PaymentId)
      .map(async t => await this.paymentService.getPayment(practiceId, account.Id, t.Metadata?.PaymentId)));
    const scheme =
      ACCOUNT_TYPE.MEDICAL_AID === account.AccountType && account?.Scheme
        ? await this.referenceDataService.getScheme(account.Scheme)
        : undefined;
    return new StatementPdfReportDataBuilder().build(
      await new StatementReportBuilder().build(reportRequest, transactions, payments, scheme));
  }
}
