import * as moment from 'moment';
import {
  GroupedByCodeDetails,
  INVOICE_LINE_TYPE,
  LineDetails,
  LineItemReportRequest,
  NoDataError,
  SummaryPart1Details,
  SummaryPart2Details
} from '@meraki-flux/schema';

export class LineItemReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: LineItemReportRequest): Promise<LineItemReportRequest> {
    this.checkDataAvailability(reportRequest);
    const data = reportRequest;

    data.LineDetails = await this.buildLineDetails(reportRequest);
    data.GroupedByCodeDetails = await this.buildGroupByCode(reportRequest.LineDetails);
    data.SummaryPart1Details = await this.buildSummaryPart1(reportRequest.LineDetails);
    data.SummaryPart2Details = await this.buildSummaryPart2(reportRequest.LineDetails);

    data.ReportDate = moment(new Date()).format(this.DATE_FORMAT);
    data.DateRange =
      moment(reportRequest.DateFrom).format(this.DATE_FORMAT) +
      ' - ' +
      moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    return data;
  }

  //Line details
  private async buildLineDetails(lineItemReportRequest: LineItemReportRequest): Promise<LineDetails[]> {
    const lineDetails = [];

    for (const invoice of lineItemReportRequest.ReportData) {
      for (const line of invoice.Lines || []) {
        const providerName = invoice.TreatingProvider as string;
      //const provider = providerName && providerName.includes(',')
      //? providerName.split(',')[0].trim()
      //: providerName;
        const lineDetail: LineDetails = {
          LineType: line.LineType,
          Code:
            line.LineType === INVOICE_LINE_TYPE.MEDICINE ||
            line.LineType === INVOICE_LINE_TYPE.CSTM_MEDICINE ||
            line.LineType === INVOICE_LINE_TYPE.CONSUMABLE ||
            line.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE
              ? line.NappiCode
              : line.TariffCode,
          Description: line.Description,
          Qty: Number(line.Quantity).toFixed(2),
          Amount: line.AmountBilled,
          InvoiceNo: invoice.InvoiceNo,
          DateOfService: moment(invoice.DateOfService).format(this.DATE_FORMAT),
          Scheme: invoice.Type,
          Provider: providerName,
          Branch: invoice.Branch,
        };
        lineDetails.push(lineDetail);
      }
    }

    // Apply sorting logic:
    lineDetails.sort((a, b) => {
      // Prioritize sorting by LineType:
      const lineTypeComparison = a.LineType.localeCompare(b.LineType);
      if (lineTypeComparison !== 0) {
        return lineTypeComparison;
      }

      // If LineTypes are equal, sort by Code:
      const codeComparison = a.Code.localeCompare(b.Code);
      if (codeComparison !== 0) {
        return codeComparison;
      }

      // If both LineType and Code are equal, sort by DateOfService:
      return a.DateOfService.localeCompare(b.DateOfService);
    });

    return lineDetails;
  }

  //Grouped by Code
  private async buildGroupByCode(lineDetails: LineDetails[]): Promise<GroupedByCodeDetails[]> {
    const groupedData: Map<string, GroupedByCodeDetails> = new Map();

    for (const lineDetail of lineDetails) {
      const code = lineDetail.Code;

      const existingGroup = groupedData.get(code);
      if (existingGroup) {
        existingGroup.Qty = (parseFloat(existingGroup.Qty) + parseFloat(lineDetail.Qty)).toFixed(2);
        existingGroup.Amount += lineDetail.Amount;
      } else {
        groupedData.set(code, {
          LineType: lineDetail.LineType,
          Code: lineDetail.Code,
          Qty: parseFloat(lineDetail.Qty).toFixed(2),
          Amount: lineDetail.Amount,
        });
      }
    }

    // Order the grouped data by LineType and then by Code
    return Array.from(groupedData.values()).sort((a, b) => {
      if (a.LineType !== b.LineType) {
        return a.LineType.localeCompare(b.LineType); // Sort by LineType alphabetically
      } else {
        return a.Code.localeCompare(b.Code); // If LineType is the same, sort by Code alphabetically
      }
    });
  }

  //Summary - Part 1 and Part 2
  private async buildSummaryPart1(lineDetails: LineDetails[]): Promise<SummaryPart1Details[]> {

    const lineTypeOrder = [
      INVOICE_LINE_TYPE.PROCEDURE,
      INVOICE_LINE_TYPE.MEDICINE,
      INVOICE_LINE_TYPE.CONSUMABLE,
      INVOICE_LINE_TYPE.MODIFIER,
      INVOICE_LINE_TYPE.ADMIN,
      INVOICE_LINE_TYPE.CSTM_PROCEDURE,
      INVOICE_LINE_TYPE.CSTM_MEDICINE,
      INVOICE_LINE_TYPE.CSTM_CONSUMABLE
    ];

    const groupedData: Map<string, SummaryPart1Details> = new Map();

    for (const lineDetail of lineDetails) {
      const lineType = lineDetail.LineType;

      groupedData.set(lineType, {
        LineType: lineType,
        Amount: (groupedData.get(lineType)?.Amount ?? 0) + lineDetail.Amount,
      });
    }

    // Sort the data based on the defined lineTypeOrder
    const sortedData: SummaryPart1Details[] = [];
    for (const lineType of lineTypeOrder) {
      if (groupedData.has(lineType)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sortedData.push(groupedData.get(lineType)!);
      }
    }

    return sortedData;
  }

  private async buildSummaryPart2(lineDetails: LineDetails[]): Promise<SummaryPart2Details[]> {
    const groupedData: Map<string, Map<string, number>> = new Map(); // Use a nested map for amounts directly

    for (const lineDetail of lineDetails) {
      const provider = lineDetail.Provider;
      const lineType = lineDetail.LineType;

      // Access or create the nested Map for the provider
      const providerMap = groupedData.get(provider) ?? new Map();

      // Access or create the amount for the lineType
      let amount = providerMap.get(lineType) ?? 0;
      amount += lineDetail.Amount; // Update the amount directly

      // Update the nested Map
      providerMap.set(lineType, amount);
      groupedData.set(provider, providerMap);
    }

    // Build the pivoted output array
    const summaryPart2Details: SummaryPart2Details[] = [];
    const uniqueLineTypes = Array.from(new Set(lineDetails.map(d => d.LineType))); // Get unique line types

    for (const [provider, lineTypeAmounts] of groupedData.entries()) {
      const providerDetails: SummaryPart2Details = { Provider: provider };
      for (const lineType of uniqueLineTypes) {
        providerDetails[lineType] = lineTypeAmounts.get(lineType) ?? 0; // Assign amount for each line type
      }
      summaryPart2Details.push(providerDetails);
    }

    return summaryPart2Details;
  }

  //Report Info - optional

  private checkDataAvailability(lineItemReportRequest: LineItemReportRequest) {
    let noData = true;

    if (lineItemReportRequest.ReportData.length > 0) {
      noData = false;
    }
    if (noData) throw new NoDataError();
  }
}
