import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { DIALOG_WARNING_TITLE, DialogService } from '../../services/dialog.service';
import { TransactionCaptureService } from '../../services/transaction/transaction-capture.service';
import { MoneyUtils } from '../../utils/money-utils';
import {
  CLAIM_STATUS, DialogType,
  Invoice,
  INVOICE_LINE_TYPE,
  INVOICE_STATUS,
  INVOICE_SUBTYPE,
  INVOICE_TYPE,
  InvoiceLine, LiabilityTransfer, LiabilityTransferLine
} from "@meraki-flux/schema";

export interface TransferLiabilityInvoice extends Invoice {
  MedicalInsurerTransfer: boolean;
}

export interface TransferLiabilityInvoiceLine {
  Code?: string;
  Balance?: number;
  LineNumber?: number;
  MedicalAidLiable?: number;
  PatientLiable?: number;
  Liable?: UntypedFormGroup;
}
@Component({
  selector: 'meraki-flux-transfer-liability',
  templateUrl: './transfer-liability.component.html',
  styleUrls: ['./transfer-liability.component.scss'],
})
@UntilDestroy()
export class TransferLiabilityComponent implements OnInit, OnDestroy {
  warningMessages = [
    {
      message:
        'No response has been received yet for this claim. It is recommended that you wait for a response before transferring the liability, if possible.',
      status: [CLAIM_STATUS.AWAITING_RESPONSE],
    },
    {
      message:
        'A remittance has not been received yet for this claim. It is recommended that you wait for a remittance before transferring the liability, if possible.',
      status: [
        CLAIM_STATUS.RECEIVED_BY_HEALTHBRIDGE,
        CLAIM_STATUS.VALIDATED_BY_HEALTHBRIDGE,
        CLAIM_STATUS.RECEIVED_BY_MEDICALAID,
        CLAIM_STATUS.VALIDATED_BY_MEDICALAID,
      ],
    },
    {
      message:
        'The reversal is not in a final state yet. It is recommended that you wait for the final response before transferring the liability, if possible.',
      status: [CLAIM_STATUS.AWAITING_REVERSAL],
    },
    {
      message:
        'The claim is not yet fully processed. It is recommended that you wait for the final response before transferring the liability, if possible.',
      status: [CLAIM_STATUS.PART_PROCESSED_BY_MEDICALAID],
    },
  ];

  claimStatusWarning$ = new BehaviorSubject<string | undefined>(undefined);
  displayedColumns = ['Code', 'Balance', 'MedAidLiable', 'PatientLiable'];

  dataSource = new MatTableDataSource<TransferLiabilityInvoiceLine>();
  reasonToTransfer = new UntypedFormControl('', Validators.required);

  state$ = new BehaviorSubject<'ready' | 'saving' | 'validating'>('ready');

  constructor(
    public auth: AuthService,
    public dialogRef: MatDialogRef<TransferLiabilityComponent>,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private transactionCaptureService: TransactionCaptureService,
    @Inject(MAT_DIALOG_DATA) public data: TransferLiabilityInvoice
  ) {}

  ngOnDestroy(): void {}

  async ngOnInit() {
    if (this.data.Status === INVOICE_STATUS.CANCELLED) {
      this.dialogRef.close();
      this.dialogService.showWarning('This is a cancelled invoice, with no liability to transfer.');
    } else if (this.data.Balance?.Outstanding === 0) {
      this.dialogRef.close();
      this.dialogService.showWarning(
        'This invoice does not have an outstanding balance, so there is no liability to transfer.'
      );
    } else if (
      this.data.Type === INVOICE_TYPE.CASH ||
      this.data.Subtype === INVOICE_SUBTYPE.DEBIT_NOTE
    ) {
      this.dialogRef.close();
      this.dialogService.showWarning(
        'Cash invoices and debit note invoices are always patient liable, and liability cannot be transferred.'
      );
    } else if (
      this.data.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER &&
      !this.data.MedicalInsurerTransfer
    ) {
      this.dialogRef.close();
      await this.dialogService
        .showYesNoDialog(
          'This is a medical insurance insurance invoice, for which only the insurer is supposed to be liable.<br><br>Are you sure you want to transfer liability for this invoice?',
          DIALOG_WARNING_TITLE
        )
        .closed.pipe(
          tap((response) => {
            if (response === 'YES') {
              this.data.MedicalInsurerTransfer = true;
              this.dialog.open(TransferLiabilityComponent, {
                panelClass: 'flux-dialog-medium',
                data: this.data,
              });
            }
          })
        )
        .toPromise();
    } else {
      const claimWarningMessage = this.warningMessages.find((warningMessage) =>
        warningMessage.status.includes(this.data.ClaimInfo?.ClaimStatus)
      );
      this.claimStatusWarning$.next(claimWarningMessage?.message);
      this.dataSource.data = this.mapToTransferLiabilityLines(this.data.Lines);
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  transferAllToMedAid() {
    this.dataSource.data.forEach((transferLiabilityLine) => {
      transferLiabilityLine.Liable.patchValue(
        {
          MedicalAidLiable: transferLiabilityLine.Balance / 100,
          PatientLiable: 0,
        },
        { emitEvent: false }
      );
    });
  }

  transferAllToPatientLiable() {
    this.dataSource.data.forEach((transferLiabilityLine) => {
      transferLiabilityLine.Liable.patchValue(
        {
          PatientLiable: transferLiabilityLine.Balance / 100,
          MedicalAidLiable: 0,
        },
        { emitEvent: false }
      );
    });
  }

  mapToTransferLiabilityLines(lines: InvoiceLine[]): TransferLiabilityInvoiceLine[] {
    return lines?.map((line) => {
      const code =
        line.LineType === INVOICE_LINE_TYPE.CONSUMABLE ||
        line.LineType === INVOICE_LINE_TYPE.MEDICINE ||
        line.LineType === INVOICE_LINE_TYPE.CSTM_CONSUMABLE ||
        line.LineType === INVOICE_LINE_TYPE.CSTM_MEDICINE
          ? line.NappiCode
          : line.TariffCode;
      const transferLiabilityLine = {
        Code: code,
        Balance: line.Balance.Outstanding,
        MedicalAidLiable: line.Balance.MedicalAidLiable,
        PatientLiable: line.Balance.PatientLiable,
        LineNumber: line.LineNumber,
        Liable: this.fb.group({
          MedicalAidLiable: line.Balance.MedicalAidLiable / 100,
          PatientLiable: line.Balance.PatientLiable / 100,
        }),
      } as TransferLiabilityInvoiceLine;
      this.createValueChanges(transferLiabilityLine);
      return transferLiabilityLine;
    });
  }

  createValueChanges(line: TransferLiabilityInvoiceLine) {
    const form = line.Liable;
    form
      .get('MedicalAidLiable')
      .valueChanges.pipe(
        tap((value) => {
          const balance = line.Balance / 100;
          if (!(value > balance)) {
            form.patchValue(
              {
                PatientLiable: balance - value,
              },
              { emitEvent: false }
            );
          } else {
            form.patchValue(
              {
                MedicalAidLiable: balance,
                PatientLiable: 0,
              },
              { emitEvent: false }
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    form
      .get('PatientLiable')
      .valueChanges.pipe(
        tap((value) => {
          const balance = line.Balance / 100;
          if (!(value > balance)) {
            form.patchValue(
              {
                MedicalAidLiable: balance - value,
              },
              { emitEvent: false }
            );
          } else {
            form.patchValue(
              {
                PatientLiable: balance,
                MedicalAidLiable: 0,
              },
              { emitEvent: false }
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  buildLiabilityTransfer(): LiabilityTransfer {
    const lines = this.dataSource.data.map((line) => {
      return {
        LineNo: line.LineNumber,
        MedicalAidLiable: MoneyUtils.toCents(line.Liable.get('MedicalAidLiable')?.value) || 0,
        PatientLiable: MoneyUtils.toCents(line.Liable.get('PatientLiable')?.value) || 0,
      } as LiabilityTransferLine;
    });
    return {
      InvoicePath: `Practice/${this.auth.selectedBPN}/Invoice/${this.data.Id}`,
      Lines: lines,
      Reason: this.reasonToTransfer?.value,
    };
  }

  async onSave() {
    if (this.state$.value !== 'ready') return;
    try {
      this.state$.next('validating');
      if (await this.validate()) {
        this.state$.next('saving');
        const success = await this.transactionCaptureService.liabilityTransfer(
          this.data.Account.Id,
          this.buildLiabilityTransfer()
        );
        if (success) {
          this.reset();
          this.dialogService.showSnackbar('Liability has been processed');
        } else {
          this.dialogService.showErrorMessage('Error on processing liability');
        }
        this.dialogRef.close();
      }
    } catch (err) {
      console.error('Transaction Capture saving error', err);
      console.error('Payment details:', this.buildLiabilityTransfer());
      this.dialogService.showErrorMessage(`${err}`);
    } finally {
      this.state$.next('ready');
    }
  }

  async validate() {
    this.reasonToTransfer.markAllAsTouched();
    this.reasonToTransfer.updateValueAndValidity();
    let valid = true;
    if (!this.reasonToTransfer.valid) {
      valid = false;
      this.dialogService.showDialog({
        title: 'Warning',
        message: 'Please check highlighted fields before proceeding.',
        type: DialogType.WARNING,
      });
    }
    return valid;
  }

  reset() {
    this.reasonToTransfer.patchValue('');
    this.dataSource.data.forEach((liability) => {
      liability.Liable.patchValue(
        {
          MedicalAidLiable: MoneyUtils.fromCents(liability.MedicalAidLiable),
          PatientLiable: MoneyUtils.fromCents(liability.PatientLiable),
        },
        { emitEvent: false }
      );
    });
  }
}
