import { Injectable } from '@angular/core';
import { AbstractPrivateRatePricingService } from '@meraki-flux/purejs';
import { PrivateRate, PrivateRateType, Scheme } from '@meraki-flux/schema';
import { ReferenceDataService } from '../reference-data.service';
import { PrivateRateWhereBuilder } from './private-rate-where-builder';
import { PrivateRateService } from './private-rate.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateRatePricingService extends AbstractPrivateRatePricingService {
  constructor(
    private referenceDataService: ReferenceDataService,
    private privateRateService: PrivateRateService
  ) {
    super();
  }

  override findActivePrivateRates(
    collectionPath: string,
    code: string,
    type: PrivateRateType,
    applyTo: 'CashInvoices' | 'MedicalAidInvoices'
  ): Promise<PrivateRate[]> {
    let whereBuilder = PrivateRateWhereBuilder.builder().active(true).type(type).code(code);
    if (applyTo === 'MedicalAidInvoices') {
      whereBuilder = whereBuilder.medicalAidInvoices(true);
    } else if (applyTo === 'CashInvoices') {
      whereBuilder = whereBuilder.cashInvoices(true);
    }
    return this.privateRateService.searchPrivateRates(collectionPath, whereBuilder.build());
  }

  override getAllSchemes(): Promise<Scheme[]> {
    return this.referenceDataService.schemePlanOptionsFlat();
  }
}
