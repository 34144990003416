import moment = require("moment");
import {
  NoDataError,
  PaymentDetailsItem,
  PaymentDetailsReportInfo,
  PaymentDetailsReportModel,
  PaymentDetailsReportRequest
} from "@meraki-flux/schema";


export class PaymentDetailsReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: PaymentDetailsReportRequest): Promise<PaymentDetailsReportModel> {
        const reportHeader: PaymentDetailsReportInfo = await this.buildReportInfo(reportRequest);

        this.checkDataAvailability(reportRequest.Payments);

        return {
            PaymentDetailsReportInfo: reportHeader,
            PaymentDetailsItems: reportRequest.Payments,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(paymentDetailsItem: PaymentDetailsItem[]) {
        let noData = true;
        if (paymentDetailsItem.length > 0) {
            noData = false;
        }

        if (noData)
            throw new NoDataError();
    }

    private async buildReportInfo(reportRequest: PaymentDetailsReportRequest): Promise<PaymentDetailsReportInfo> {
        const reportInfo: PaymentDetailsReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.DateRangeType = reportRequest.DateRangeType;

        return reportInfo;
    }

}
