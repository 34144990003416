import { InjectionToken, NgModuleRef, Type } from "@angular/core";
import { Observable } from "rxjs";

export interface ContextToolbarItem {
    id?: string;
    icon?: string;
    useCustomIcon?: boolean;
    component?: ContextToolbarComponentOptions | Promise<ContextToolbarComponentOptions>;
    onClick?: (item: ContextToolbarItem) => void;
    position?: CONTEXT_TOOLBAR_ITEM_POSITION;
    badgeOptions?: ContextToolbarBadgeOptions;
    static?: boolean;
}

export interface ContextToolbarGroup {
    position?: CONTEXT_TOOLBAR_ITEM_POSITION;
    items?: ContextToolbarItem[];
}

export enum CONTEXT_TOOLBAR_ITEM_POSITION {
    TOP = 1,
    BOTTOM = 2
}

export interface ContextToolbarComponentOptions {
    componentType?: Type<any>;
    moduleRef?: NgModuleRef<any>
}

export interface ContextToolbarBadgeOptions {
    badgeCount?: number;
    onUpdateBadge$?: Observable<number>;
}

export interface ContextToolbarItemRef {
    updateBadge: (count: number) => void;
    item: ContextToolbarItem;
}

export const TOOLBAR_ITEM_REF = new InjectionToken<ContextToolbarItemRef>('TOOLBAR_ITEM_REF');
