<div mat-dialog-title class="!flex flex-row justify-between">
  <div>Transfer Liability</div>
  <div>
      <button mat-icon-button (click)="onNoClick()">
          <mat-icon>close</mat-icon>
      </button>
  </div>
</div>
<mat-divider class="mt"></mat-divider>
<div mat-dialog-content class="h-full overflow-auto">
  <div class="p-4">
    <div *ngIf="claimStatusWarning$ | async as warningMessage" class="flux-info-box negative info-block mb-4">
      <div class="icon">
        <mat-icon fontSet="material-icons-outlined">report_problem</mat-icon>
      </div>
      <div class="content" i18n id="warningMessageBlock">
        {{ warningMessage }}
        <br><br>
        Please contact Healthbridge on 0860 200 222 if you require further assistance.
      </div>
    </div>

    <div class="flex w-full flex-row justify-start gap-4 flex-wrap">
      <div class="flex flex-col flex-1 gap-4 min-w-[300px] mb-4">
        <ng-container *ngTemplateOutlet="info; context: { $implicit: 'Invoice type', content: data?.Type ? data.Type : data.Subtype, Id: 'invoiceType' }">
        </ng-container>

        <ng-container *ngTemplateOutlet="info; context: { $implicit: 'Claim status', content: data?.ClaimInfo?.ClaimStatus, Id: 'claimStatus' }">
        </ng-container>

        <mat-form-field class="flux-form-field w-[408px]">
          <mat-label>Reason to transfer</mat-label>
          <input id="reasonToTransfer" matInput type="text" [formControl]="reasonToTransfer">
        </mat-form-field>
      </div>
    </div>

    <div class="flex w-full flex-row justify-start gap-4 flex-wrap">
      <div class="flex flex-col flex-1 gap-4 min-w-[300px] mb-4">
        <a class="text-hb-secondary-100 font-medium cursor-pointer" (click)="transferAllToMedAid()">Transfer all to medical aid liable</a>
        <a class="text-hb-secondary-100 font-medium cursor-pointer" (click)="transferAllToPatientLiable()">Transfer all to patient liable</a>
      </div>
    </div>

    <mat-table id="linesTable" class="transfer-liability-table flux-table" [dataSource]="dataSource">
      <ng-container matColumnDef="Code">
        <mat-header-cell *matHeaderCellDef>
          Code
        </mat-header-cell>
        <mat-cell id="codeCell" *matCellDef="let element"> {{ element.Code }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <mat-header-cell *matHeaderCellDef>
          Balance
        </mat-header-cell>
        <mat-cell id="balanceCell" *matCellDef="let element"> {{ element.Balance/100 | currency:'R ':'symbol-narrow':'1.2-2'  }} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="MedAidLiable">
        <mat-header-cell *matHeaderCellDef>
          Med aid liable
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element.Liable">
          <mat-form-field *ngIf="element.Liable" appearance="outline" class="table-field flux-form-field">
            <input id="medAidInput" matInput currencyMask formControlName="MedicalAidLiable" [options]="{ prefix: 'R ', thousands: ',', decimal: '.' }">
          </mat-form-field>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="PatientLiable">
        <mat-header-cell *matHeaderCellDef>
          Patient liable
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element.Liable">
          <mat-form-field *ngIf="element.Liable"appearance="outline" class="table-field flux-form-field">
            <input id="patientLiableInput" matInput currencyMask formControlName="PatientLiable" [options]="{ prefix: 'R ', thousands: ',', decimal: '.' }">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>


  </div>
</div>
<div mat-dialog-actions class="flux-page-actions-container">
  <button mat-flat-button class="flux-button" color="secondary" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button class="flux-button" color="secondary" (click)="reset()">Reset</button>
  <ng-container *ngIf="(state$ | async) as state">
    <button id="saveButton" mat-flat-button color="primary" class="flux-button mat-primary" [disabled]="state !== 'ready'" (click)="onSave()">
      <div class="flex flex-row gap-3 items-center">
        <mat-spinner class="save-spinner" *ngIf="state !== 'ready'" diameter="24"></mat-spinner>
        <span>{{ state === 'saving' ? 'Saving...' : state === 'validating' ? 'Validating...' : 'Save' }}</span>
      </div>
    </button>
  </ng-container>
</div>


<ng-template #info let-title let-content="content" let-Id="Id">
  <div class="flex flex-col">
    <label class="text-hb-text-subdued text-xs">{{title}}</label>
    <label id="{{Id}}" class="text-hb-text-default font-medium text-sm mt-0.5">{{content || 'Not specified'}}</label>
  </div>
</ng-template>

