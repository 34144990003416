import {
  AgeAnalysisReportInfo,
  AggregatedInvoiceAge,
  BaseExcelReportData,
  CellType,
  CellWidth,
  ExcelCellData,
  SummaryData,
  Table
} from "@meraki-flux/schema";

export abstract class AbstractAgeAnalysisReportDataBuilder {

  protected buildReportInfo(excelReportData: BaseExcelReportData, reportInfo: AgeAnalysisReportInfo) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: {value: reportInfo.Practice, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Billing practice no.',
      data: {value: reportInfo.PracticeId, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Date range type',
      data: {value: reportInfo.DateRangeType, type: CellType.GENERAL}
    });
    excelReportData.parameters.push({
      name: 'Date range',
      data: {value: reportInfo.DateRange, type: CellType.GENERAL}
    });
  }

  protected buildSummaryTable(tableName: string, firstColumnHeaderName: string, summary: SummaryData): Table {
    const table: Table = {};
    table.name = tableName;
    table.headers = [
      {value: firstColumnHeaderName, bold: true, width: CellWidth.M},
      {value: 'Total', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '0 - 30', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '30 - 60', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '60 - 90', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '90 - 120', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: '120+', bold: true, width: CellWidth.XS, hAlignment: 'right'}];

    const rows = [];
    Array.from(summary.map.keys()).sort().forEach(key => {
      const row: ExcelCellData[] = [];
      row.push({value: key});
      const age: AggregatedInvoiceAge = summary.map.get(key);
      row.push({value: age.totalAge, type: CellType.CURRENCY});
      row.push({value: age.age30, type: CellType.CURRENCY});
      row.push({value: age.age30_60, type: CellType.CURRENCY});
      row.push({value: age.age60_90, type: CellType.CURRENCY});
      row.push({value: age.age90_120, type: CellType.CURRENCY});
      row.push({value: age.age120, type: CellType.CURRENCY});
      rows.push(row);
    });

    const totalRow = [{value: 'Totals', bold: true},
      {value: summary.total.totalAge, bold: true, type: CellType.CURRENCY},
      {value: summary.total.age30, bold: true, type: CellType.CURRENCY},
      {value: summary.total.age30_60, bold: true, type: CellType.CURRENCY},
      {value: summary.total.age60_90, bold: true, type: CellType.CURRENCY},
      {value: summary.total.age90_120, bold: true, type: CellType.CURRENCY},
      {value: summary.total.age120, bold: true, type: CellType.CURRENCY}];
    rows.push(totalRow);

    table.rows = rows;

    return table;
  }

  protected buildSchemeSummaryTable(tableName: string, firstColumnHeaderName: string, summary: SummaryData, eraSchemeNames: string[]): Table {
    const table = this.buildSummaryTable(tableName, firstColumnHeaderName, summary);
    table.headers.push({value: 'Electronic remittances enabled?', bold: true, width: CellWidth.S});
    table.rows?.slice(0, table.rows.length - 1).forEach(r => r.push({value: eraSchemeNames.includes(r[0].value) ? 'Y' : 'N', type: CellType.GENERAL}))
    return table;
  }
}
