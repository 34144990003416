import { Injectable } from '@angular/core';
import { Firestore, WriteBatch, arrayUnion, doc, writeBatch } from '@angular/fire/firestore';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Account,
  AuthService,
  FormUtils,
  FormatUtils,
  InboxService,
  InvoiceService,
  LogService,
  MoneyUtils,
  PathUtils,
  RaUtils,
  RemittanceService,
  SearchService,
  TransactionCaptureService,
} from '@meraki-flux/common';
import {
  AccountMember,
  CLAIM_STATUS,
  INBOX_STATUS,
  Invoice,
  PayableInvoice,
  RA_CLAIM_STATUS,
  REMITTANCE_NOTE_TYPE,
  RemittanceAdvice,
  RemittanceClaim,
  RemittanceClaimIsResolvedError,
  RemittanceNote,
} from '@meraki-flux/schema';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnmatchedEraItemActionComponentForm } from './unmatched-era-item-action.component';
import { UnmatchedEraItemActionDialog } from './unmatched-era-item-action.dialog';

@Injectable({ providedIn: 'root' })
export abstract class AbstractUnmatchedEraItemActionHelper {

  shouldWarnWhenNavigating$ = new BehaviorSubject(true);

  constructor(
    protected firestore: Firestore,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected invoiceService: InvoiceService,
    protected dialog: UnmatchedEraItemActionDialog,
    protected inboxService: InboxService,
    protected logger: LogService,
    protected router: Router,
    protected transactionCaptureService: TransactionCaptureService,
    protected remittanceService: RemittanceService
  ) {}

  abstract getRaClaimLineColumns(): string[];

  abstract invoices(
    accountId: string,
    paymentDate: Date,
    yearsToSelect: number
  ): Observable<Invoice[]>;

  async searchAccounts(practiceId: string, searchText: string) {
    if (searchText?.length > 0) {
      const result = await this.searchService.searchPatients(practiceId, searchText, {
        hitsPerPage: 10,
        filter: ['Account EXISTS', 'Patient EXISTS', 'IsMainMember=true'],
      });
      return result.hits.map((hit) => ({ patient: hit.Patient, account: hit.Account }));
    } else {
      return [];
    }
  }

  abstract filterInvoices(
    invoices: Invoice[],
    raClaim: RemittanceClaim,
    outstandingBalanceInvoices: boolean,
    filterOnClaimMatches: boolean
  ): Invoice[];

  accountPatientDisplayFn(account: Account, patient: AccountMember) {
    if (!account || !patient) return '';
    const fileNo = patient?.FileNo;
    return `${account?.MemberNo ? account?.MemberNo + ' - ' : ''}${FormatUtils.nameSurname(
      patient.Name,
      patient.Surname
    )} (Acc ${account?.AccountNo}${fileNo ? ` / File ${fileNo}` : ''})`;
  }

  totalAllocated(invoices: Invoice[]): number {
    return invoices.reduce(
      (sum, invoice) => sum + invoice?.AmountBilled - invoice?.Balance?.Outstanding,
      0
    );
  }

  async canDeactivate(
    isSaved: boolean,
    isDiscarded: boolean,
    invoices: PayableInvoice[],
    formValue: any,
    defaultFormValue: any
  ): Promise<boolean> {
    return !isSaved && !isDiscarded && this.shouldWarnWhenNavigating$.value && this.hasUserChanges(invoices, formValue, defaultFormValue)
      ? await this.dialog.confirmDeactivation()
      : true;
  }

  hasUserChanges(
    invoices: PayableInvoice[],
    formValue: UnmatchedEraItemActionComponentForm,
    defaultFormValue: UnmatchedEraItemActionComponentForm
  ): boolean {
    return (
      this.hasUserChangesOnInvoices(invoices) ||
      formValue.UnallocatedAmount > 0 ||
      formValue.CouldNotReconcile !== defaultFormValue.CouldNotReconcile ||
      formValue.AdditionalRemittanceNotes !== defaultFormValue.AdditionalRemittanceNotes ||
      formValue.AdditionalAccountNotes !== defaultFormValue.AdditionalAccountNotes
    );
  }

  abstract hasUserChangesOnInvoices(invoices: PayableInvoice[]): boolean;

  async confirmAccountChange(
    currentAccountId: string,
    newAccountId: string,
    invoices: PayableInvoice[]
  ) {
    return currentAccountId &&
      currentAccountId !== newAccountId &&
      this.hasUserChangesOnInvoices(invoices)
      ? await this.dialog.confirmAccountChange()
      : true;
  }

  async confirmCouldNotReconcile(invoices: PayableInvoice[]) {
    return this.hasUserChangesOnInvoices(invoices)
      ? await this.dialog.confirmCouldNotReconcile()
      : true;
  }

  validateOverAllocation(remainingAmount: number) {
    if (remainingAmount >= 0) return true;
    this.dialog.showOverAllocatationWarning();
    return false;
  }

  validateFullAllocation(remainingAmount: number, couldNotReconcile: boolean) {
    if (couldNotReconcile || remainingAmount === 0) return true;
    this.dialog.showFullAllocationRequiredWarning();
    return false;
  }

  async validateAllocationToRejectedClaim(invoices: PayableInvoice[]) {
    const rejectedWithAllocations = invoices?.filter(
      (i) => i._AmountAllocated > 0 && i.ClaimInfo?.ClaimStatus === CLAIM_STATUS.REJECTED
    );
    if (rejectedWithAllocations.length > 0) {
      return await this.dialog.confirmAllocationToRejectedClaim();
    }
    return true;
  }

  getTotalToAllocate(raClaim: RemittanceClaim) {
    return Math.abs(RaUtils.getPaymentAmount(raClaim));
  }

  getAccountInvoicesAllocated(invoices: PayableInvoice[]) {
    return invoices?.reduce((sum, i) => sum + (i._AmountAllocated || 0), 0) || 0;
  }

  getAccountAllocated(invoices: PayableInvoice[], accountUnallocatedAmountRands: number) {
    const accountUnallocatedAmountCents = MoneyUtils.toCents(accountUnallocatedAmountRands);
    return this.getAccountInvoicesAllocated(invoices) + accountUnallocatedAmountCents;
  }

  getTotalAllocated(invoices: PayableInvoice[], accountUnallocatedAmountRands: number) {
    return this.getAccountAllocated(invoices, accountUnallocatedAmountRands);
  }

  getTotalRemaining(
    raClaim: RemittanceClaim,
    invoices: PayableInvoice[],
    accountUnallocatedAmount: number
  ) {
    const totalToAllocate = this.getTotalToAllocate(raClaim);
    const totalAllocated = this.getTotalAllocated(invoices, accountUnallocatedAmount);
    return totalToAllocate - totalAllocated;
  }

  async close(route: ActivatedRoute, path?: string) {
    const pathToNavigate = path ? path : '../..';
    await this.router.navigate([pathToNavigate], { relativeTo: route });
  }

  async validateOnSave(
    form: UntypedFormGroup,
    raClaim: RemittanceClaim,
    invoices: PayableInvoice[]
  ) {
    FormUtils.validateForm(form, false);
    const formValue = form.value;
    const remainingAmount = this.getTotalRemaining(raClaim, invoices, formValue.UnallocatedAmount);
    const couldNotReconcile = form.value.CouldNotReconcile;
    return (
      form.valid &&
      (!couldNotReconcile || (couldNotReconcile && form.value.AdditionalRemittanceNotes)) &&
      this.validateOverAllocation(remainingAmount) &&
      this.validateFullAllocation(remainingAmount, formValue.CouldNotReconcile) &&
      (await this.validateAllocationToRejectedClaim(invoices))
    );
  }

  async saveAndClose(
    accountId: string,
    inboxId: string,
    ra: RemittanceAdvice,
    raClaim: RemittanceClaim,
    form: UntypedFormGroup,
    invoices: PayableInvoice[],
    saveState$: BehaviorSubject<'Ready' | 'Busy' | 'Saved' | 'Discarded'>,
    route: ActivatedRoute,
    path?: string,
    executionContext?: 'inbox' | 'suspense account'
  ) {
    if (saveState$.value !== 'Ready') return;
    saveState$.next('Busy');
    let saved = false;
    try {
      saved = await this.save(accountId, inboxId, ra, raClaim, form, invoices, executionContext);
    } catch (err: any) {
      if (err.isInboxItemIsResolvedError || err.isRemittanceClaimResolvedError) {
        const error = err as { message: string };
        console.log('shouldddddd warn', this.shouldWarnWhenNavigating$.value);
        await this.dialog.showWarning(error.message, 'Action already completed');
        this.shouldWarnWhenNavigating$.next(false);
        await this.close(route, path);
      } else {
        this.logger.error(err);
        this.dialog.showSaveError(err.message || 'Failed to process Inbox item');
      }
    } finally {
      saveState$.next(saved ? 'Saved' : 'Ready');
      this.shouldWarnWhenNavigating$.next(true);
      if (saved) {
        this.dialog.showSnackbar('Remittance claim has been reconciled');
        await this.close(route, path);
      }
    }
  }

  async save(
    accountId: string,
    inboxId: string,
    remittance: RemittanceAdvice,
    raClaim: RemittanceClaim,
    form: UntypedFormGroup,
    invoices: PayableInvoice[],
    executionContext: 'inbox' | 'suspense account'
  ) {
    const valid = await this.validateOnSave(form, raClaim, invoices);
    if (!valid) {
      this.logger.info('form is invalid');
      return false;
    }
    if (form.value.CouldNotReconcile) {
      await this.saveAsCouldNotReconcile(
        this.authService.selectedBPN,
        inboxId,
        PathUtils.remittanceClaimPath(this.authService.selectedBPN, remittance.Id, raClaim.Id),
        form.value.AdditionalRemittanceNotes,
        executionContext
      );
    } else {
      await this.saveAsReconciled(accountId, inboxId, remittance, raClaim.Id, form.value, invoices);
    }
    return true;
  }

  abstract saveAsReconciled(
    accountId: string,
    inboxId: string,
    ra: RemittanceAdvice,
    raClaimId: string,
    formValue: any,
    invoices: PayableInvoice[],
  ): Promise<any>;

  async saveAsCouldNotReconcile(practiceId: string, inboxId: string, raClaimPath: string, reason: string, executionContext: 'inbox' | 'suspense account') {
    const batch = writeBatch(this.firestore);
    this.batchAddRaClaimNote(batch, raClaimPath, REMITTANCE_NOTE_TYPE.COULD_NOT_RECONCILE, reason);
    await this.batchUpdatesRaClaimStatus(batch, raClaimPath, RA_CLAIM_STATUS.COULD_NOT_RECONCILE);
    if (inboxId) {
      // Check if inbox item has been actioned and only throw the error if it's in the inbox context
      try {
        await this.inboxService.changeStatusWithBatch(batch, practiceId, inboxId, INBOX_STATUS.COMPLETED);
      } catch (err) {
        if(executionContext === 'inbox') {
          throw err
        } else {
          this.logger.warn(err);
        }
      }
    }
    await batch.commit(); // complete CouldNotReconcile operations within a batch
  }

  batchAddRaClaimNote(
    batch: WriteBatch,
    raClaimPath: string,
    type: REMITTANCE_NOTE_TYPE,
    note: string
  ) {
    if (!note) return; // skip if note is empty
    const data = {
      Notes: arrayUnion({
        Type: type,
        Note: note,
        CreatedBy: this.authService.uid,
        CreatedByName: this.authService.userFullName,
        CreatedAt: new Date(),
      } as RemittanceNote),
      UpdatedBy: this.authService.uid,
      UpdatedAt: new Date(),
    };
    batch.update(doc(this.firestore, raClaimPath), data);
  }

  async batchUpdatesRaClaimStatus(batch: WriteBatch, raClaimPath: string, status: RA_CLAIM_STATUS) {
    const raClaim = await this.remittanceService.raClaimByPath(raClaimPath);
    if(raClaim.Status !== RA_CLAIM_STATUS.UNRECONCILED) {
      throw new RemittanceClaimIsResolvedError('This remittance claim has already been actioned');
    }
    const data = { Status: status, UpdatedBy: this.authService.uid, UpdatedAt: new Date() };
    batch.update(doc(this.firestore, raClaimPath), data);
  }

  async matchDefaultAccount(practiceId: string, memberNo: string) {
    if (!practiceId || !memberNo) return undefined;
    const results = await this.searchAccounts(practiceId, memberNo); // replace with account index search and filter by memNo later
    const filteredResults = results.filter((r) => r.account.MemberNo === memberNo);
    return filteredResults.length === 1 ? filteredResults[0] : undefined;
  }

  defaultAccountFormValue(
    allInvoices: Invoice[],
    raClaim: RemittanceClaim,
    accountSearchValue: any,
    defaultFormValue: any
  ) {
    const defaultAccountFormValue = { AccountSearch: accountSearchValue, ...defaultFormValue };
    const filteredInvoices = this.filterInvoices(
      allInvoices,
      raClaim,
      defaultAccountFormValue.OutstandingBalanceInvoices,
      true
    );
    if (allInvoices?.length > 0 && filteredInvoices.length > 0) {
      defaultAccountFormValue.FilterOnClaimMatches = true;
    }
    return defaultAccountFormValue;
  }
}
