import { Practice } from '../practice.models';
import { PAYMENT_DETAILS_REPORT_DATE_RANGE_TYPE, REPORT_FORMAT_TYPE } from '../report.models';
import { ReportRequestData } from './common.model';

export interface PaymentDetailsReportModel {
  PaymentDetailsItems: PaymentDetailsItem[];
  PaymentDetailsReportInfo: PaymentDetailsReportInfo;
  ReportDate?: string;
}

export interface PaymentDetailsReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  DateRange?: string;
  DateRangeType?: string;
}

export interface PaymentDetailsItem {
  PaymentDate?: string;
  PaymentType?: string;
  TransactionCreateDate?: string;
  RANo?: string;
  PaymentRef?: string;
  ReceiptNo?: string;
  PaidBy?: string;
  PaymentAmount?: number;
  AllocatedAmount?: number;
  UnallocatedAmount?: number;
  CreatedBy?: string;
}

export class PaymentDetailsReportRequest implements ReportRequestData {
  Practice: Practice;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateRangeType?: PAYMENT_DETAILS_REPORT_DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Payments: PaymentDetailsItem[];
  Recipient?: string;
}



