import {AccountDetailsReportModel, BasePdfReportData, ReportFooter} from "@meraki-flux/schema";

export class AccountDetailsPdfReportDataBuilder {

  private static REPORT_SPECIAL_FONT_COLOR = "#000000";

  build(accountDetailsReportModel: AccountDetailsReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.reportName = accountDetailsReportModel.ReportName;
    reportData.dateHeader = `Date generated: ${accountDetailsReportModel.ReportDate}`;
    reportData.reportNameColor = AccountDetailsPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    reportData.accountDetailsData = accountDetailsReportModel.AccountDetailsData;
    reportData.tables = [{
      rowHeaders: accountDetailsReportModel.NextOfKin?.rowHeaders,
      rows: accountDetailsReportModel.NextOfKin?.rows,
      borders: accountDetailsReportModel.NextOfKin?.borders
    }];
    reportData.footer = this.buildFooter();
    reportData.footerText = "\t\u200B".repeat(60)
    reportData.disclaimer = accountDetailsReportModel.PatientDisclaimer;
    return reportData;
  }

  buildFooter() : ReportFooter {
    return {
      hbLogo: true,
    }
  }
}
