import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'meraki-flux-add-reason-dialog',
  templateUrl: './add-reason-dialog.component.html',
  styleUrls: ['./add-reason-dialog.component.scss'],
})
export class AddReasonDialogComponent extends DialogComponent implements OnInit, AfterViewInit {
  @ViewChild('reasonField') reasonField: ElementRef;

  form = new FormControl('', [Validators.required, Validators.maxLength(500)]);

  constructor(@Inject(MAT_DIALOG_DATA) dialogData, protected override injector: Injector) {
    super(dialogData, injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      this.reasonField.nativeElement.focus();
    });
  }

  onBeforeClose = async (buttonId) => {
    if (buttonId === 'SAVE') {
      this.form.markAsTouched();
      return this.form.valid;
    }
    return true;
  };

  onClose = async (buttonId) => {
    if (buttonId === 'SAVE') {
      return this.form.value;
    }
    return null;
  };
}
