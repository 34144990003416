import _ from "lodash";
import * as moment from "moment";
import { DateUtils } from "../../utils/date-utils";
import { FormatUtils } from "../../utils/format-utils";
import { PathUtils } from "../../utils/path-utils";
import {
  BaseInvoice,
  Branch,
  CellType,
  ClaimInfo,
  INVOICE_LINE_TYPE,
  INVOICE_SUBTYPE,
  InvoiceLine,
  InvoiceTransaction,
  MAPayment,
  MedicalInsurerReportModel,
  MedicalInsurerStatementReportRequest,
  MedicalInsurerTable,
  NameValue,
  Payment,
  Practice,
  Transaction,
  TRANSACTION_TYPE
} from "@meraki-flux/schema";

export class MedicalInsurerReportBuilder {

    private NOW = new Date();

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: MedicalInsurerStatementReportRequest, transactions: Transaction[], payments: Payment[]): Promise<MedicalInsurerReportModel> {
        const practice = reportRequest.Practice;
        const branch = practice.Branches.find(x => (x.IsMainBranch));
        const invoiceResult = await this.buildMedicalInsurerTables(practice, reportRequest.MedInsurerDetails.Invoices, transactions, payments);

        return {
            PracticeId: practice.BillingPracticeNumber,
            PracticeName: practice.PracticeName,
            Header: await this.buildMainHeader(reportRequest.MedInsurerDetails.Invoices),
            PracticeHeader: await this.buildPracticeHeader(branch),
            AccountHeader: await this.buildAccountHeader(reportRequest.MedInsurerDetails.Invoices),
            MedicalInsurerTables: invoiceResult.MedicalInsurerTables,
            InfoTable: await this.buildFooterInfoTable(reportRequest.MedInsurerDetails.Invoices, practice, branch),
            MedicalInsurerSummary: invoiceResult.MedicalInsurerSummary,
            MedicalInsurerAges: invoiceResult.MedicalInsurerAges,
            MedicalInsurerReportDate: moment(new Date()).format(this.DATE_FORMAT),
            FooterText:  `${"\t\u200B".repeat(3)}`,
        } as MedicalInsurerReportModel;
    }

    async buildMainHeader(invoice: BaseInvoice[]) {
        const header = `Statement of Account - ${invoice[0].MedicalInsurer.Name}`;
        return header;
    }

    async buildFooterInfoTable(invoice: BaseInvoice[], practice: Practice, branch: Branch) {
        return {
            bankDetails : {
                practiceName: FormatUtils.join([
                    branch.BankDetails?.ChequeAccountDetails?.AccountHolderName,
                    branch.BankDetails?.ChequeAccountDetails?.Bank
                ], ', '),
                branchName: branch.BankDetails?.ChequeAccountDetails?.BranchCode,
                accountNo: branch.BankDetails?.ChequeAccountDetails?.AccountNo,
                accountType: branch.BankDetails?.ChequeAccountDetails?.AccountType
            },
            bankDetailsEft: branch.BankDetails?.SameAsEFT ? undefined : {
                practiceName: FormatUtils.join([
                    branch.BankDetails?.EFTAccountDetails?.AccountHolderName,
                    branch.BankDetails?.EFTAccountDetails?.Bank
                ], ', '),
                branchName: branch.BankDetails?.EFTAccountDetails?.BranchCode,
                accountNo: branch.BankDetails?.EFTAccountDetails?.AccountNo,
                accountType: branch.BankDetails?.EFTAccountDetails?.AccountType
            },
            eftInfo : {
                paymentRef: invoice[0].MedicalInsurer?.Name,
                email: branch.ContactDetails?.EmailAddress
            },
            companyInfo : {
                registrationNo: practice.CompanyRegistrationNo,
                vatNo: practice.VatNo
            }
        }
    }

    async buildMedicalInsurerTables(practice: Practice, invoices: BaseInvoice[], transactions: Transaction[], payments: Payment[])  {
        const mInsurerTables : MedicalInsurerTable[] = [];
        let patientLiable = 0;
        let medicalAidLiable = 0;
        let balance = 0;
        const unallocated = 0;
        let d120 = 0;
        let d90_120 = 0;
        let d60_90 = 0;
        let d30_60 = 0;
        let d0_30 = 0;
        invoices.forEach( (invoice) => {
            patientLiable += invoice.Balance?.PatientLiable
            medicalAidLiable += invoice.Balance?.MedicalAidLiable
            balance += invoice.Balance?.Outstanding
            d120 += this.buildAgeAnalysis(invoice, 120, undefined, practice.Settings.HideMALiable);
            d90_120 += this.buildAgeAnalysis(invoice, 90, 120, practice.Settings.HideMALiable);
            d60_90 += this.buildAgeAnalysis(invoice, 60, 90, practice.Settings.HideMALiable);
            d30_60 += this.buildAgeAnalysis(invoice, 30, 60, practice.Settings.HideMALiable);
            d0_30 += this.buildAgeAnalysis(invoice, 0, 30, practice.Settings.HideMALiable);
        });
        const sortedInvoices = invoices.sort(function(a: BaseInvoice, b: BaseInvoice){
            // @ts-ignore
            return DateUtils.toDate(a.DateOfService) - DateUtils.toDate(b.DateOfService);
          });
          for (const invoice of sortedInvoices) {
            const table = await this.buildStatementTable(practice, invoice, transactions, payments);
            mInsurerTables.push(table);

          }
        return {
            MedicalInsurerTables: mInsurerTables,
            MedicalInsurerSummary: {
                medicalAidLiable: practice.Settings?.HideMALiable ? undefined : medicalAidLiable,
                patientLiable: patientLiable,
                balance: practice.Settings?.HideMALiable ? undefined : balance
            },
            MedicalInsurerAges : {
                unallocated : unallocated,
                d120: d120,
                d90_120: d90_120,
                d60_90: d60_90,
                d30_60: d30_60,
                d0_30: d0_30
            }
        };
    }

    buildAgeAnalysis(invoice: BaseInvoice, from: number, to?: number, hideMALiable?: boolean) {
        const dos = DateUtils.toDate(invoice.DateOfService);
        const result = hideMALiable ? invoice.Balance?.PatientLiable : invoice.Balance?.Outstanding;
        if (this.dateDiffInDays(dos, this.NOW) >= from) {
            if (to) {
              if (this.dateDiffInDays(dos, this.NOW) < to ) {
                return result;
              } else {
                return 0;
              }
            }
            return result;
        }
        return 0;
    }

    async buildStatementTable(practice: Practice, invoice: BaseInvoice, transactions: Transaction[], payments: Payment[]) {
        const headers = []
        headers.push(await this.buildPatientTableHeader(invoice));
        headers.push(await this.buildInvoiceTableHeader(invoice));
        const icdLine = await this.buildInvoiceICDLine(invoice);
        const rowHeaders = await this.buildRowHeaders();
        const rows = [];
        for (const invoiceLine of _.sortBy(invoice.Lines, 'LineNumber')) {
            rows.push(await this.buildTableRow(invoiceLine, invoice?.ClaimInfo))
        }
        [
            ...this.buildPatientPaymentInvoiceTransactions(practice, invoice, transactions, payments),
            ...this.buildMAPaymentInvoiceTransactions(practice, invoice, transactions, payments),
            ...this.buildWriteOffInvoiceTransactions(practice, invoice, transactions),
            ...this.buildCreditNoteInvoiceTransactions(practice, invoice, transactions),
            ...this.buildPaymentCorrectionTransactions(practice, invoice, transactions)
        ]
            .sort((t1: InvoiceTransaction, t2: InvoiceTransaction) => {
                let res = DateUtils.getTime(t1.date) - DateUtils.getTime(t2?.date);
                if (res === 0) res = t1?.label?.toLowerCase().localeCompare(t2?.label?.toLowerCase());
                if (res === 0) res = t1?.superscript?.toLowerCase().localeCompare(t2?.superscript?.toLowerCase());
                return res;
            })
            .map(t => this.buildTableTransactionRow(t))
            .forEach(r => rows.push(r));
        rows.push(await this.buildTotalRow(invoice))
        const table = {
            TableHeaders: headers,
            ICDLine: icdLine,
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        } as MedicalInsurerTable;
        if (invoice.AmountVAT > 0) {
            table.legend = {
                text: `VAT on invoice: ${FormatUtils.formatCents(invoice.AmountVAT)}`,
                italics: true,
                marginTop: -18
            }
        }
        return table;
    }

    async buildTableRow(invoiceLine: InvoiceLine, claimInfo: ClaimInfo) {
        const reasonCodes = claimInfo?.ReasonCodes
            ?.filter(r => Number(r?.LineNo) === Number(invoiceLine.LineNumber))
            ?.map(r => r.Code);
        const row = [];
        row.push({
            value: invoiceLine.TariffCode
        })
        row.push({
            value: FormatUtils.join(this.formatDescriptionLines(invoiceLine), '\n')
        })
        row.push({
            value: invoiceLine.AmountBilled,
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.MedicalAidLiable||0,
            superscript: FormatUtils.formatReasonCodes(reasonCodes),
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.PatientLiable||0,
            type: CellType.CURRENCY
        })
        row.push({
            value: invoiceLine.Balance?.Outstanding||0,
            type: CellType.CURRENCY
        })
        return row;
    }

    formatDescriptionLines(invoiceLine: InvoiceLine) {
        const desc = _.truncate(invoiceLine.Description, {'length' : 170});
        const packSize = (invoiceLine as any).PackSize;
        const nappi = invoiceLine.NappiCode;
        const medicineType = (invoiceLine as any)?.MedicineType;
        const icd10 = invoiceLine.DiagnosisCodes ? `ICD-10: ${invoiceLine.DiagnosisCodes}` : null;
        switch (invoiceLine.LineType) {
            case INVOICE_LINE_TYPE.MEDICINE:
                return [
                    packSize ? `${desc} (Pack size: ${packSize})` : desc,
                    `Nappi: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    medicineType ? `Type: ${medicineType}` : null,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CSTM_MEDICINE:
                return [
                    packSize ? `${desc} (Pack size: ${packSize})` : desc,
                    `Code: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    medicineType ? `Type: ${medicineType}` : null,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CONSUMABLE:
                return [
                    packSize ? `${desc} (Pack size: ${packSize})` : desc,
                    `Nappi: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    icd10
                ];
            case INVOICE_LINE_TYPE.CSTM_CONSUMABLE:
                return [
                    packSize ? `${desc} (Pack size: ${packSize})` : desc,
                    `Code: ${nappi}\tQTY: ${invoiceLine.Quantity}`,
                    icd10
                ];
            default:
                return [
                    desc,
                    invoiceLine.Quantity > 1 ? `QTY: ${invoiceLine.Quantity}` : null,
                    icd10
                ];
        }
    }

    async buildTotalRow(invoice: BaseInvoice) {
        const row = [];
        row.push({
            value: ""
        })
        row.push({
            value: ""
        })
        row.push({
            value: "",
        })
        row.push({
            value: invoice.Balance.MedicalAidLiable||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        row.push({
            value: invoice.Balance?.PatientLiable||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        row.push({
            value: invoice.Balance?.Outstanding||0,
            type: CellType.CURRENCY,
            decoration: {
                bold: true
            }
        })
        return row;
    }

    resize(arr, size) {
        const add = arr.length > size;
        while (arr.length > size) { arr.pop(); }
        if (add) {
            arr[size-1] = arr[size - 1].replace(/.$/,'...')
        }
        return arr;
    }

    async buildRowHeaders() {
        return [
            {value: 'Tariff code'},
            {value: 'Description'},
            {value: 'Amount', type: CellType.NUMBER, width: 60},
            {value: 'Insurer liable', type: CellType.NUMBER, width: 70},
            {value: 'Patient liable', type: CellType.NUMBER, width: 70},
            {value: 'Balance', type: CellType.NUMBER, width: 60}
        ]
    }

    async buildPatientTableHeader(invoice: BaseInvoice) {
        const rows = [];
        rows.push(await this.buildPatientTableHeaderLine(invoice));
        return {
            outlined: true,
            rows: rows
        }
    }

    async buildInvoiceTableHeader(invoice: BaseInvoice) {
        const rows = [];

        rows.push(await this.buildInvoiceTableInsurerHeaderLine(invoice));
        rows.push(await this.buildInvoiceTableHeader1Line(invoice));
        rows.push(await this.buildInvoiceTableHeader2Line(invoice));

        return {
            rows: rows,
            outlined: true,
            outlineColor: '#ececec'
        }
    }

    async buildInvoiceICDLine(invoice: BaseInvoice) {
        return {
            icdCode: `ICD-10 diagnosis: ${invoice.HeaderDiagnosisCodes}`,
            status: `Status: ${invoice.ClaimInfo?.ClaimStatus ? invoice.ClaimInfo?.ClaimStatus : 'Saved'}`,
        }
    }

    async buildPatientTableHeaderLine(invoice: BaseInvoice) {
        const rows = [];
        const keyStrings = [];
        if (INVOICE_SUBTYPE.DEBIT_NOTE === invoice.Subtype) keyStrings.push('DEBIT NOTE');
        keyStrings.push('Patient');
        await this.addKeyValue(rows, keyStrings.join('/t'), `${invoice.Patient?.Title??""} ${invoice.Patient?.Name} ${invoice.Patient?.Surname}`, true);
        if (invoice.Patient?.DateOfBirth) {
            await this.addKeyValue(rows, "D.o.b", moment(DateUtils.toDate(invoice.Patient?.DateOfBirth)).format(this.DATE_FORMAT), true);
        }
        return rows;
    }

    async buildInvoiceTableInsurerHeaderLine(invoice: BaseInvoice) {
        const rows = [];
        await this.addKeyValue(rows, "Insurer.", invoice.MedicalInsurer.Name, true);
        await this.addKeyValue(rows, "ID no.", invoice.Patient.IdentityNo);
        await this.addKeyValue(rows, "Policy no.", invoice.PolicyNo);
        return rows;
    }

    async buildInvoiceTableHeader1Line(invoice: BaseInvoice) {
        const rows = [];
        if (invoice.DateOfService) {
            await this.addKeyValue(rows, "Date of service", moment(DateUtils.toDate(invoice.DateOfService)).format(this.DATE_FORMAT), true);
        }
        if (invoice.TreatingProvider) {
            await this.addKeyValue(rows, "Treating provider", `${invoice.TreatingProvider?.FullName.split(',')[0]}`, true);
        }
        await this.addKeyValue(rows, "HPCSA", invoice.TreatingProvider?.HPCSANumber);
        return rows;
    }

    async buildInvoiceTableHeader2Line(invoice: BaseInvoice) {
        const rows = [];
        if (invoice.InvoiceDate) {
            await this.addKeyValue(rows, "Invoice date", moment(DateUtils.toDate(invoice.InvoiceDate)).format(this.DATE_FORMAT), true);
        }
        await this.addKeyValue(rows, "Invoice no.", invoice.InvoiceNo);
        await this.addKeyValue(rows, "Dispensing no.", invoice.TreatingProvider?.DispensingLicNum);
        return rows;
    }

    async buildPracticeHeader(branch?: Branch) {
        const right: NameValue[] = await this.buildRightPracticeHeaders(branch);
        const left: NameValue[] = await this.buildLeftPracticeHeaders(branch);
        return {
            left: left,
            right: right
        }
    }

    async buildRightPracticeHeaders(branch?: Branch) {
        const right: NameValue[] = [];
        await this.addKeyValue(right, "Office no",branch?.ContactDetails?.OfficeNo ? ("Office no: "+ branch?.ContactDetails?.OfficeNo) :"");
        await this.addKeyValue(right, "Email",branch?.ContactDetails?.EmailAddress ? ("Email: "+ branch?.ContactDetails?.EmailAddress) :"");
        await this.addKeyValue(right, "Fax",branch?.ContactDetails?.FaxNo ? ("Fax: "+ branch?.ContactDetails?.FaxNo) :"");
        await this.addKeyValue(right, "Email",branch?.AdditionalEmails?.Address1  ? (branch?.AdditionalEmails?.Description1 + ": "+  branch?.AdditionalEmails?.Address1) : "");
        await this.addKeyValue(right, "Email",branch?.AdditionalEmails?.Address2  ? (branch?.AdditionalEmails?.Description2 + ": "+  branch?.AdditionalEmails?.Address2) : "");
        await this.addKeyValue(right, "Email",branch?.AdditionalEmails?.Address3  ? (branch?.AdditionalEmails?.Description3 + ": "+  branch?.AdditionalEmails?.Address3) : "");
        await this.addKeyValue(right, "Phone",branch?.AdditionalPhones?.Phone1 ? (branch?.AdditionalPhones?.Description1 + ": "+ branch?.AdditionalPhones?.Phone1) : "");
        await this.addKeyValue(right, "Phone",branch?.AdditionalPhones?.Phone2 ? (branch?.AdditionalPhones?.Description2 + ": "+ branch?.AdditionalPhones?.Phone2) : "");
        await this.addKeyValue(right, "Phone",branch?.AdditionalPhones?.Phone3 ? (branch?.AdditionalPhones?.Description3 + ": "+ branch?.AdditionalPhones?.Phone3) : "");
        return right;
    }

    async buildLeftPracticeHeaders(branch?: Branch) {
        const left: NameValue[] = [];
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line1);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line2);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Line3);
        await this.addKeyValue(left, null, branch?.PhysicalAddress?.Code);
        return left;
    }

    async buildAccountHeader(invoice: BaseInvoice[]) {
        const left: NameValue[] = await this.buildLeftAccountHeaders(invoice);
        const right: NameValue[] = await this.buildRightAccountHeaders();
        return {
            name: "Statement of Account",
            left: left,
            right:right
        }
    }

    async buildLeftAccountHeaders(invoice: BaseInvoice[]) {
        const left: NameValue[] = [];
        await this.addKeyValue(left, null, invoice[0].MedicalInsurer.Name, true);
        return left;
    }

    //This empty method has to be created in order to generate the right panel on the header
    //or else the report fails
    async buildRightAccountHeaders() {
        const right: NameValue[] = [];
        await this.addKeyValue(right, null, '');
        return right;
    }

    async addKeyValue(col: NameValue[], key: string, value?: string, bold: boolean = false, italic: boolean = false) {
        if (value && value !== "") {
            col.push({name: key, value: {value: value, bold: bold, italic: italic}});
        }
        return col;
    }

    async addToArray(col: string[], value? :string) {
        if (value && value !== "") {
            col.push(value)
        }
    }

    dateDiffInDays(a: any, b: any) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    buildTableTransactionRow(invoiceTransaction: InvoiceTransaction) {
        return [
            { value: "" },
            { value: invoiceTransaction.label, superscript: invoiceTransaction.superscript },
            { value: invoiceTransaction.amount, type: CellType.CURRENCY },
            { value: "" },
            { value: "" },
            { value: "" }
        ];
    }

    buildPatientPaymentInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[], allPayments: Payment[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.PATIENT_PAYMENT === t.TransactionType)
            .map(t => {
                const paymentId = t.Metadata?.PaymentId;
                let txGroup = txGroups.find(it => it.Id === paymentId);
                if (!txGroup) {
                    const payment = allPayments.find(p => p.Id === paymentId);
                    txGroup = {
                        id: paymentId,
                        date: payment.PaymentDate,
                        label: FormatUtils.join([
                            `${FormatUtils.dateDDMMYYYY(payment.PaymentDate)}: Patient payment - ${payment?.Type}`,
                            t?.Metadata?.ReferenceNo ? `(${t?.Metadata?.ReferenceNo})` : null
                        ], ' '),
                        amount: t.AmountMAL + t.AmountPL
                    } as InvoiceTransaction;
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    buildMAPaymentInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[], allPayments: Payment[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.MEDICAL_AID_PAYMENT === t.TransactionType)
            .map(t => {
                const paymentId = t.Metadata?.PaymentId;
                const payment = allPayments.find(p => p.Id === paymentId) as MAPayment;
                const date = payment?.PaymentDate;
                let txGroup = txGroups.find(it => it.Id === paymentId);
                if (!txGroup) {
                    txGroup = {
                        id: paymentId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: ${payment?.SchemeName} payment`,
                        amount: (+t.AmountMAL || 0) + (+t.AmountPL || 0)
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    buildWriteOffInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.WRITE_OFF === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.CreatedAt;
                let txGroup = txGroups.find(it => it.Id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: ${t?.Metadata?.Note} written off`,
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }


    buildCreditNoteInvoiceTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.CREDIT === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.Metadata?.HeaderDate;
                let txGroup = txGroups.find(it => it.Id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: Credit note - ${t?.Metadata?.CreditType}`,
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }

    buildPaymentCorrectionTransactions(practice: Practice, invoice: BaseInvoice, allTransactions: Transaction[]) {
        const invoicePath = PathUtils.invoicePath(practice.Id, invoice.Id);
        const txGroups = [];
        allTransactions
            .filter(t => t.InvoicePath === invoicePath && TRANSACTION_TYPE.PAYMENT_CORRECTION === t.TransactionType)
            .map(t => {
                const groupId = t.Metadata?.HeaderId;
                const date = t.Metadata?.HeaderDate;
                let txGroup = txGroups.find(it => it.Id === groupId);
                if (!txGroup) {
                    txGroup = {
                        id: groupId,
                        date: date,
                        label: `${FormatUtils.dateDDMMYYYY(date) || undefined}: Payment correction - ${t?.Metadata?.Note}`,
                        amount: t.AmountMAL + t.AmountPL
                    };
                    txGroups.push(txGroup);
                } else {
                    txGroup.amount += t.AmountMAL + t.AmountPL;
                }
            });
        return txGroups;
    }
}
