import {
  BasePdfReportData, CellType, PDFCellData, PDFParameter, RASchemeModel, RASuspenseDSData, RASuspenseReportModel, REPORT_NAME, ReportHeader,
  ReportTable, SchemeSummaryItem
} from '@meraki-flux/schema';

export class RASuspenseAccountPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";
  
      build(raSuspenseReportModel: RASuspenseReportModel): BasePdfReportData {
          const reportData: BasePdfReportData = {};
  
          reportData.bpn = raSuspenseReportModel.ReportInfo.PracticeId
          reportData.orientation = "portrait";
          reportData.headers = this.buildHeader(raSuspenseReportModel);
          reportData.tables = this.buildTables(raSuspenseReportModel);
          reportData.dateHeader = `Date generated ${raSuspenseReportModel.ReportDate}`;
          reportData.footerText = "\t\u200B".repeat(60)
  
          reportData.footer = {
            hbLogo: true,
          };
          return reportData;
        }
  
        private buildHeader(raSuspenseReportModel: RASuspenseReportModel): ReportHeader[] {
          const leftParameters: PDFParameter[] = [];
          leftParameters.push({name: 'Practice', data: {value: raSuspenseReportModel.ReportInfo.Practice, decoration: {bold: true}}});
          leftParameters.push({name: 'Billing practice no.', data: {value: raSuspenseReportModel.ReportInfo.PracticeId, decoration: {bold: true}}});
  
  
          const rightParameters: PDFParameter[] = [
              {name: 'Scheme', data: {value: raSuspenseReportModel.ReportInfo.Scheme, decoration: {bold: true}}},
              {name: 'Payment Date Range', data: {value: raSuspenseReportModel.ReportInfo.DateRange, decoration: {bold: true}}},
          ]
          return [
              {
                  name: REPORT_NAME.RA_SUSPENSE,
                  nameColor: RASuspenseAccountPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                  includeGeneratedDate: true,
                  left: leftParameters,
                  right: rightParameters
              }
          ];
      }
  
      private buildTables(raSuspenseReportModel: RASuspenseReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
          reportTables.push(this.buildSummaryTable(raSuspenseReportModel.RASchemeModel));
          reportTables.push(this.buildDetailsTable(raSuspenseReportModel.RASchemeModel.RASuspenseDSData));
        return reportTables;
    }
  
      private buildDetailsTable(detailsItem: RASuspenseDSData[]): ReportTable {
        const table: ReportTable = {};
        table.name = 'UNRECONCILED RAs';
        table.nameColor = RASuspenseAccountPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = RASuspenseAccountPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.rowHeaders = [
  
            {value: 'Scheme', decoration: {bold: true}},
            {value: 'RA no.', decoration: {bold: true}},
            {value: 'Payment date', decoration: {bold: true}},
            {value: 'Patient (Account no.)', decoration: {bold: true}},
            {value: 'Date of service', decoration: {bold: true}},
            {value: 'Amount', decoration: {bold: true}, type: CellType.NUMBER}
  
        ];
        table.rowHeadersBold = true;
        const rows = [];
        detailsItem.forEach((item: RASuspenseDSData) => {
            const row: PDFCellData[] = [];
  
            row.push({value: item.SchemeName}),
            row.push({value: item.RANum}),
            row.push({value: item.PayDate});
            row.push({value: item.PatName+' '+item.PatSurname+' ('+item.AccountNo+')'});
            row.push({value: item.DateOfService});
            row.push({value: item.PaidAmount, type: CellType.CURRENCY});
  
            rows.push(row);
        });
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        if (!rows || rows.length === 0) {
            table.rows = undefined;
            table.rowHeaders = undefined;
            table.noRecordsText = {
                value: "No records",
                decoration: {
                    italics: true
                }
            }
        }
        return table;
    }
  
    private buildSummaryTable(schemesModel: RASchemeModel): ReportTable {
      const table: ReportTable = {};
      table.name = 'SUMMARY';
      table.nameColor = RASuspenseAccountPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
      table.nameUnderline = true;
      table.nameUnderlineColor = RASuspenseAccountPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
      table.rowHeaders = [
          {value: 'Scheme', decoration: {bold: true}},
          {value: 'Items', decoration: {bold: true}},
          {value: 'Total', decoration: {bold: true}, type: CellType.NUMBER}
      ];
      table.rowHeadersBold = true;
      const rows = [];
      let TotalCount = 0;
      let TotalPaidAmount = 0;
      schemesModel.SchemeSummaryItem.sort((a, b) => a.Scheme > b.Scheme ? 1 : -1).forEach((item: SchemeSummaryItem) => {
        if (item.Total) {
              TotalCount += item.Total;
          }
          if (item.TotalValue) {
            TotalPaidAmount += item.TotalValue;
          }
          const row: PDFCellData[] = [];
          row.push({value: item.Scheme});
          row.push({value: item.Total, type: CellType.NUMBER});
          row.push({value: item.TotalValue, type: CellType.CURRENCY});
          rows.push(row);
      });
      const row: PDFCellData[] = [];
      row.push({value: "Total", decoration: {bold: true} });
      row.push({value: TotalCount, type: CellType.NUMBER, decoration: {bold: true}});
      row.push({value: TotalPaidAmount, type: CellType.CURRENCY, decoration: {bold: true}});
   
      rows.push(row);
      table.rows = rows;
      table.borders = {
          hor: true,
          headerBorderSize: 1,
      }
      return table;
  }
  
  }