import {BenefitCheckLine} from "./benefit-check.models";
import {PLACE_OF_SERVICE} from "./invoice.models";
import {IDENTIFICATION_TYPE} from "./member.models";
import {PricingSubscription} from "./pricing.models";
import {CommonFeatures} from "./user.models";

export interface ProviderVo {
  PracticeType?: string | null;
  PracticeName?: string | null;
  SpecialityCode?: string | null;
  SubSpecialityCode?: string | null;
  PracticeNumber?: string | null;
  TreatingDoctorName?: string | null;
  TreatingDoctorPracticeNumber?: string | null;
  DispensingDoctor?: string | null;
  DispensingLicenseNumber?: string | null;
  HPCSANumber?: string | null;
}

export interface ProviderConfigurationVo {
  PracticeId?: string | null;
  PracticeTenantId?: string | null;
  PracticeXRef?: string | null;
  BranchXRef?: string | null;
  PracticeName?: string | null;
  SpecialityCode?: string | null;
  SubSpecialityCode?: string | null;
  PracticeNumber?: string | null;
  BillingIdentifier?: string | null;
  BillingStatus?: string | null;
  BillingStartDate?: Date | null;
  BillingEndDate?: Date | null;
  TreatingPracticeNumber?: string | null;
  TreatingDoctorName?: string | null;
  DispensingDoctor?: string | null;
  DispensingLicNum?: string | null;
  HPCSANumber?: string | null;
  Qualification?: string | null;
  SpecialityDescription?: string | null;
  TelephoneNumber?: string | null;
  FaxNumber?: string | null;
  CellphoneNumber?: string | null;
  EmailAddress?: string | null;
  PhysicalAddress?: string | null;
  PostalAddress?: string | null;
  ContactNumber?: string | null;
  NetworkedSchemes?: string[] | null;
  ManualPricingSchemes?: string[] | null;
  Options?: { [key: string]: string; } | null;
  LastUpdatedDate?: Date | null;
  IsLocumProvider?: boolean | null;
  KnownAs?: string | null;
}

export class AbstractProvider {
  Title?: string;
  Name?: string;
  Surname?: string;
  Speciality?: string;
  SpecialityCode?: string;
  Qualifications?: string;
  HPCSANumber?: string;
  IdentificationType?: IDENTIFICATION_TYPE;
  IDNumber?: string;
  PassportNo?: string;
  Email?: string;
  CellPhoneNo?: string;
  HomePhoneNo?: string;
}

export class Provider extends AbstractProvider {
  UserId?: string;
  Practices?: string[];
}

export class PracticeProvider extends AbstractProvider {
  Id?: string;
  BillingPracticeNumber?: string;
  IsLocum?: boolean;
  TreatingPracticeNumber?: string;
  IsNotTreatingPracticeNumberOwner?: boolean;
  IsDispensing?: boolean;
  IsAnaesthetist?: boolean;
  IsIPA?: boolean;
  DispensingLicenceNumber?: string;
  IsActive?: boolean;
  Type?: ProviderType;
  DefaultBranchName?: string;
  Settings?: any;
  Common?: CommonFeatures;
  PricingSubscriptions?: PricingSubscription[];
  ProviderCalendarId?: string;
}

export enum ProviderType {
  TREATING = 'Treating',
  EXTERNAL = 'External'
}

export class PracticeProviderSettings {
  DefaultVisitType?: string | null;
  DefaultPlaceOfService?: PLACE_OF_SERVICE | null;
  PromptForIncompleteClinicalNotesOnCheckOut?: string | null;
  DefaultTariffCodeForConsultingRoom?: string | null;
  DefaultTariffCodeForDayHospital?: string | null;
  DefaultTariffCodeForInpatientHospital?: string | null;
  DefaultTariffCodeForTelemedicine?: string | null;
  AlwaysAddPpeCodeToInvoice?: string | null;
  TriggerBalanceWiOnRaReceiveOnly?: string | null;
  AutoWriteOff?: string | null;
  BenefitCheckTemplate?: BenefitCheckLine[];
}

export enum PROVIDER_SPECIALITY {
  "Anaesthetist" = 10,
  "Cardiologist" = 21,
  "Dietician" = 84,
  "GP (14)" = 14,
  "GP (15)" = 15,
  "Homeopaths" = 8,
  "Paediatrition" = 32,
  "Physiotheraptist" = 72,
  "Otorhinolaryngology" = 30,
  "Gynaecologist" = 16,
  "General Surgeon" = 42,
  "Psychologist" = 86,
  "Social Worker" = 89,
  "Paediatric cardiology" = 33
}

export type PROVIDER_MEDICAL_TYPE_CODE = 'MP' | 'AP' | 'OP' | 'DP';

export enum SPECIALITY_MEDICAL_TYPE {
  "Anaesthetist" = "MP",
  "Cardiologist" = "MP",
  "Dietician" = "AP",
  "GP (14)" = "MP",
  "GP (15)" = "MP",
  "Homeopaths" = "AP",
  "Paediatrition" = "MP",
  "Physiotheraptist" = "AP",
  "Psychologist" = "AP",
  "Social Worker" = "AP"
}
