export enum VariableCategories {
  ALL = 'all',
  SINGLEPRACTICE = 'SinglePractice',
  MULTIBRANCH = 'Multibranch',
  INVOICE = 'Invoice',
  APPOINTMENT = 'Appointment',
  PAYMENT_CONFIRMATION = 'Payment confirmation',
  VIDEO_LINK = 'Video Link'
}

export enum LoggerLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  FATAL = 6,
  OFF = 7
}

export enum System {
  FLUX,
  NEXUS
}

export enum MeilisearchHost {
  LOOKUP = 0,
  ACCOUNT = 1,
  INVOICE = 2,
  CALENDAR = 3,
  DEFAULT = 4
}
