import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectorRef, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, map } from 'rxjs/operators';
import { AddReasonDialogComponent } from '../components/dialog-custom/add-reason-dialog/add-reason-dialog.component';
import { RadioButtonDialogComponent } from '../components/dialog-custom/radio-button-dialog/radio-button-dialog.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { SystemAlertComponent } from '../components/system-alert/system-alert.component';
import { InputDialogComponent } from '../components/dialog-custom/input-dialog/input-dialog.component';
import {
  AlertOptions,
  CANCEL_BUTTON,
  DialogButton, DialogButtonStyle,
  DialogOptions,
  DialogResponse,
  DialogType, NO_BUTTON,
  NO_BUTTON_REV,
  OK_BUTTON, OK_BUTTON_ID,
  YES_BUTTON,
  YES_BUTTON_REV
} from "@meraki-flux/schema";

export const DIALOG_WARNING_TITLE = 'Warning';
export const DIALOG_ERROR_TITLE = 'Error';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    @Optional() private matDialog: MatDialog,
    @Optional() private matSnackBar: MatSnackBar
  ) {}

  /**
   * Shows a dialog using the provided options.
   * @param dialogOptions
   * @returns
   */
  showDialog(dialogOptions: DialogOptions, width?: string): DialogResponse {
    let data: DialogOptions = {
      ...dialogOptions,
    };

    // Add default OK button if no buttons specified.
    if (!dialogOptions.buttons || dialogOptions.buttons.length === 0) {
      data.buttons = [OK_BUTTON];
    }

    // Add default titles to warning and error dialogs
    if (dialogOptions.title === undefined && dialogOptions.type === DialogType.WARNING) {
      data.title = DIALOG_WARNING_TITLE;
    } else if (dialogOptions.title === undefined && dialogOptions.type === DialogType.ERROR) {
      data.title = 'Error';
    }

    const dialogRef = this.matDialog.open<DialogComponent, DialogOptions>(DialogComponent, {
      data,
      width: width ?? '400px',
      panelClass: 'flux-dialog',
      autoFocus: false,
      restoreFocus: true,
      disableClose: true,
    });

    const opened = dialogRef.afterOpened();
    const closed = dialogRef.afterClosed().pipe(map((res) => res?.id));

    const response = {
      opened,
      closed,
    };

    return response;
  }

  showCustomDialog(component: ComponentType<any>, dialogOptions: any) {
    let data: DialogOptions = {
      ...dialogOptions,
    };

    const dialogRef = this.matDialog.open<typeof component, DialogOptions>(component, {
      data,
      width: dialogOptions?.width || '400px',
      panelClass: 'flux-dialog',
      autoFocus: false,
      restoreFocus: true,
      disableClose: true,
    });

    const opened = dialogRef.afterOpened();
    const closed = dialogRef.afterClosed();
    const componentInstance = dialogRef.componentInstance;

    const response = {
      opened,
      closed,
      componentInstance: componentInstance,
    };

    return response;
  }

  showYesNoDialog(
    message: string,
    title: string = DIALOG_WARNING_TITLE,
    type: DialogType = DialogType.WARNING
  ) {
    return this.showDialog({
      message,
      title,
      buttons: [YES_BUTTON_REV, NO_BUTTON_REV],
      type,
    });
  }

  showYesNoDialogCustom(
    message: string,
    title: string = DIALOG_WARNING_TITLE,
    type: DialogType = DialogType.WARNING
  ) {
    return this.showDialog({
      message,
      title,
      buttons: [YES_BUTTON, NO_BUTTON],
      type,
    });
  }

  showNoYesDialog(
    message: string,
    title: string = DIALOG_WARNING_TITLE,
    type: DialogType = DialogType.WARNING
  ) {
    return this.showDialog({
      message,
      title,
      type,
      buttons: [NO_BUTTON_REV, YES_BUTTON_REV],
    });
  }

  showCustomButtonDialog(
    message: string,
    title: string,
    type: DialogType,
    primaryButton: DialogButton,
    secondaryButton: DialogButton
  ) {
    return this.showDialog({
      message,
      title,
      type,
      buttons: [primaryButton, secondaryButton],
    });
  }


  showErrorMessage(error: string) {
    this.showDialog({
      title: DIALOG_ERROR_TITLE,
      message: error,
      type: DialogType.ERROR,
    });
  }

  showWarning(warning: string) {
    this.showDialog({
      title: DIALOG_WARNING_TITLE,
      message: warning,
      type: DialogType.WARNING,
    });
  }

  showCustomWarning(warning: string) {
    this.showDialog({
      title: DIALOG_WARNING_TITLE,
      message: warning,
      type: DialogType.QUESTION,
    });
  }

  showInfoMessage(info: string) {
    this.showDialog({
      message: info,
      type: DialogType.INFO,
    });
  }

  showSnackbar(message: string) {
    this.matSnackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: ['flux-snackbar'],
    });
  }

  async showReasonDialog(message: string, primaryButtonCaption = 'Proceed') {
    return await this.showCustomDialog(AddReasonDialogComponent, {
      type: DialogType.WARNING,
      title: DIALOG_WARNING_TITLE,
      message: message,
      buttons: [
        CANCEL_BUTTON,
        {
          caption: primaryButtonCaption,
          id: 'SAVE',
          style: DialogButtonStyle.PRIMARY,
          primaryAction: true,
        },
      ],
    })
      .closed.pipe(
        first(),
        map((res) => {
          if (!res || typeof res === 'string') {
            return null;
          } else if (res?.id === 'SAVE') {
            return res?.data;
          }
        })
      )
      .toPromise();
  }

  async showCustomInputDialog(dialogOptions: DialogOptions, inputLabel?: string) {
    const dialogResponse = this.showCustomDialog(InputDialogComponent, dialogOptions);
    if (dialogResponse.componentInstance && 'inputLabel' in dialogResponse.componentInstance) {
      (dialogResponse.componentInstance as any).inputLabel = inputLabel;
    }
    return await dialogResponse.closed
      .pipe(
        first(),
        map((res) => {
          if (!res || typeof res === 'string') {
            return null;
          } else if (res?.id === dialogOptions.buttons.find((but) => but.primaryAction).id) {
            return res?.data;
          }
        })
      )
      .toPromise();
  }

  async showRadioButtonDialog(title: string, options: string[], defaultOption: string = null) {
    return await this.showCustomDialog(RadioButtonDialogComponent, {
      type: DialogType.QUESTION,
      title: title,
      options: options,
      defaultOption: defaultOption,
      buttons: [
        CANCEL_BUTTON,
        {
          caption: 'Ok',
          id: OK_BUTTON_ID,
          style: DialogButtonStyle.PRIMARY,
          primaryAction: true,
        },
      ],
    })
      .closed.pipe(
        first(),
        map((res) => {
          if (!res || typeof res === 'string') {
            return null;
          } else if (res?.id === OK_BUTTON_ID) {
            return res?.data;
          }
        })
      )
      .toPromise();
  }

  // Using custom options and component to abstract snackbar service away.
  showSystemAlert(options?: AlertOptions) {
    this.matSnackBar.openFromComponent(SystemAlertComponent, {
      data: options,
      duration: options.displayDuration ?? 3000,
      panelClass: 'flux-system-alert-panel'
    });
  }

  showInfoSystemAlert(message: string, title?: string) {
    this.showSystemAlert({
      message,
      title
    });
  }

  showSuccessSystemAlert(message: string, title?: string) {
    this.showSystemAlert({
      message,
      title,
      icon: 'check_circle',
      type: DialogType.SUCCESS
    });
  }

  showErrorSystemAlert(message: string, title?: string) {
    this.showSystemAlert({
      message,
      title,
      icon: 'error_outline',
      type: DialogType.ERROR
    });
  }

  showWarningSystemAlert(message: string, title?: string) {
    this.showSystemAlert({
      message,
      title,
      icon: 'error_outline',
      type: DialogType.WARNING
    });
  }

}
