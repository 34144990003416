import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "initialsPipe"
})
/**
 * Converts names to initials
 * e.g.: Daniel Pearson -> DP
 * e.g.: Daniel Robert Pearson -> DP
 */
export class InitialsPipe implements PipeTransform {
  transform(fullName: string): any {
    if(fullName) {
      return fullName
        .split(" ")
        .map((n, i) => i === 0 || i === fullName.split(" ").length -1 ? n[0].toUpperCase() : '')
        .join("");
    }
  }
}
