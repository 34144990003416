import {
  AgeAnalysisMedicalAidLiableReportModel,
  BaseExcelReportData,
  CellType,
  CellWidth,
  ExcelCellData,
  MedicalAidLiableInvoiceDetails,
  MedicalAidLiableSummary,
  Sheet,
  Table
} from "@meraki-flux/schema";
import { AbstractAgeAnalysisReportDataBuilder } from './abstract-age-analysis-report-data-builder';

export class AgeAnalysisMedicalAidLiableXlsReportDataBuilder extends AbstractAgeAnalysisReportDataBuilder {

  private readonly REPORT_NAME = `Age Analysis - Medical Aid Liable Amounts`;

  build(report: AgeAnalysisMedicalAidLiableReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    this.buildReportInfo(excelReportData, report.reportInfo);
    excelReportData.practiceId = report.reportInfo.PracticeId;
    excelReportData.name = this.REPORT_NAME;
    excelReportData.data = [];
    excelReportData.data.push(this.buildSummarySheet(report.summary))
    excelReportData.data.push(this.buildInvoiceDetailsSheet(report.invoiceDetails,
      report.reportInfo.IsMultiBranch))
    return excelReportData;
  }

  private buildSummarySheet(summary: MedicalAidLiableSummary): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    sheet.header = this.REPORT_NAME
    sheet.tables = [];

    sheet.tables.push(this.buildSummaryTable('Summary per treating provider - Medical aid schemes', 'Treating provider', summary.medicalAidProviderData));
    sheet.tables.push(this.buildSummaryTable('Summary per treating provider - Medical insurers', 'Treating provider', summary.insuranceProviderData));
    sheet.tables.push(this.buildSchemeSummaryTable('Summary per medical aid scheme', 'Scheme', summary.schemeData, summary.eraSchemeNames));
    sheet.tables.push(this.buildSchemeSummaryTable('Summary per medical insurer', 'Medical insurer', summary.insurerData, summary.eraSchemeNames));

    return sheet;
  }

  private buildInvoiceDetailsSheet(invoiceDetails: MedicalAidLiableInvoiceDetails[], addBranchColumn?: boolean): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Invoice Detail';
    sheet.tables = [];

    const table: Table = {};
    table.headers = [
      {value: 'Age', bold: true, width: CellWidth.XS},
      {value: 'Treating provider', bold: true, width: CellWidth.M},
      {value: 'Scheme', bold: true, width: CellWidth.S},
      {value: 'Plan/option', bold: true, width: CellWidth.S},
      {value: 'Member no.', bold: true, width: CellWidth.XS},
      {value: 'Dep. code', bold: true, width: CellWidth.XS},
      {value: 'Date of service', bold: true, width: CellWidth.XS},
      {value: 'Invoice date', bold: true, width: CellWidth.XS},
      {value: 'Invoice type', bold: true, width: CellWidth.XS},
      {value: 'Invoice no.', bold: true, width: CellWidth.XS},
      {value: 'Account no.', bold: true, width: CellWidth.XS},
      {value: 'Patient name', bold: true, width: CellWidth.M},
      {value: 'Date of birth', bold: true, width: CellWidth.XS},
      {value: 'Claim status', bold: true, width: CellWidth.S},
      {value: 'HB message ID', bold: true, width: CellWidth.S},
      {value: 'Invoice total', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Med aid liable', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'Patient liable', bold: true, width: CellWidth.XS, hAlignment: 'right'},
      {value: 'BC (Y/N)', bold: true, width: CellWidth.XS},
      {value: 'BC status', bold: true, width: CellWidth.S},
      {value: 'Electronic remittances enabled?', bold: true, width: CellWidth.XS},
      {value: 'Location', bold: true, width: CellWidth.XS}];

    if (addBranchColumn) {
      table.headers.splice(1, 0, {value: 'Branch', bold: true, width: CellWidth.XS})
    }

    const compareFn = (a1: MedicalAidLiableInvoiceDetails, a2: MedicalAidLiableInvoiceDetails) => {
      if (!a1.invoiceNo) return -1;
      if (!a2.invoiceNo) return 1;
      return (a1.invoiceNo??'').localeCompare(a2.invoiceNo);
    }

    const rows = [];
    invoiceDetails.sort((a1, a2) => compareFn(a1, a2)).forEach(invoiceDetails => {
      const row: ExcelCellData[] = [
        {value: invoiceDetails.age.getFriendlyName(), type: CellType.GENERAL},
        {value: invoiceDetails.treatingProvider},
        {value: invoiceDetails.scheme},
        {value: invoiceDetails.planOption},
        {value: invoiceDetails.memberNo, type: CellType.GENERAL},
        {value: invoiceDetails.depCode, type: CellType.GENERAL},
        {value: invoiceDetails.dateOfService, type: CellType.DATE},
        {value: invoiceDetails.invoiceDate, type: CellType.DATE},
        {value: invoiceDetails.invoiceType},
        {value: invoiceDetails.invoiceNo},
        {value: invoiceDetails.accountNo, type: CellType.GENERAL},
        {value: invoiceDetails.patientName},
        {value: invoiceDetails.dob, type: CellType.DATE},
        {value: invoiceDetails.claimStatus},
        {value: invoiceDetails.hbMessageId},
        {value: invoiceDetails.invoicedAmount, type: CellType.CURRENCY},
        {value: invoiceDetails.medicalAidLiable, type: CellType.CURRENCY},
        {value: invoiceDetails.patientLiable, type: CellType.CURRENCY},
        {value: invoiceDetails.bc},
        {value: invoiceDetails.bcStatus},
        {value: invoiceDetails.eRAEnabled},
        {value: invoiceDetails.location},
      ];
      if (addBranchColumn) {
        row.splice(1, 0, {value: invoiceDetails.branch});
      }
      rows.push(row);
    })
    table.rows = rows;

    sheet.tables.push(table);
    return sheet;
  }
}
