<div>
  <div *ngIf="!file" draggable="true"
       style="max-width:{{width}};max-height:{{height}}"
       ngClass=" {{dragAreaClass}}">
    <mat-icon class="!text-hb-secondary-100 image-icon">image</mat-icon>
    <br/>
    {{dragAndDropTitle}}
    <br/>or <a class="!text-hb-secondary-100" href="javascript:void(0)" (click)="fileInput.click()">
      browse
    </a>
    <input type="file"
           #fileInput
           accept="image/jpeg, image/png"
           [multiple]="false"
           (change)="onFileChange($event)"
           style="display:none" />
  </div>
  <!--droped image preview-->
  <img *ngIf="file" [src]="file.url"
       class="downloaded-image"
       style="max-width:{{width}};max-height:{{height}}"
       alt="Logo image">
  <div class="info">
    Recommended dimensions: {{width}} x {{height}}
    <br/>
    Accepted file formats: JPEG, PNG
  </div>
</div>
