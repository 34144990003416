import * as moment from 'moment';
import * as _ from 'lodash';
import {
  Invoice,
  NoDataError, Provider, ProviderInvoices, WriteOffDetailsItem,
  WriteOffProviderModel,
  WriteOffReportInfo,
  WriteOffReportModel,
  WriteOffReportRequest, WriteOffSummaryItem
} from "@meraki-flux/schema";

export class WriteOffsReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: WriteOffReportRequest): Promise<WriteOffReportModel> {
    const reportHeader: WriteOffReportInfo = await this.buildReportInfo(reportRequest);
    const providersModel: WriteOffProviderModel[] = await this.buildProvidersModel(reportRequest);

    this.checkDataAvailability(providersModel);

    let allProvidersModel: WriteOffProviderModel = undefined;
    if (!reportRequest.TreatingProvider && providersModel.length > 1) {
      allProvidersModel = this.buildAllProvidersModel(providersModel, reportRequest);
    }
    return {
      WriteOffReportInfo: reportHeader,
      ProviderModels: providersModel,
      AllProvidersModel: allProvidersModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

  private checkDataAvailability(providersModel: WriteOffProviderModel[]) {
    let noData = true;
    providersModel.forEach((providerModel) => {
      if (providerModel.DetailsTable.length > 0) {
        noData = false;
      }
    });
    if (noData) throw new NoDataError();
  }

  private buildAllProvidersModel(
    providerTabs: WriteOffProviderModel[],
    reportRequest: WriteOffReportRequest
  ): WriteOffProviderModel {
    const treatingProviderName = 'All providers';
    let summaryTable: WriteOffSummaryItem[] = [];
    let summaryExVATTable: WriteOffSummaryItem[] = [];
    let summaryVATTable: WriteOffSummaryItem[] = [];
    let detailsTable: WriteOffDetailsItem[] = [];
    for (const providerTab of providerTabs) {
      detailsTable = detailsTable.concat(providerTab.DetailsTable);
      summaryTable = summaryTable.concat(this.updateSummaryTable(providerTab.DetailsTable, providerTab.ProviderName));
      summaryExVATTable = reportRequest.IsVATRegistered? this.updateSummaryExVATTable(detailsTable): [];
      summaryVATTable = reportRequest.IsVATRegistered? this.updateSummaryVATTable(detailsTable): [];
    }
    return {
      SummaryTable: summaryTable,
      SummaryExVATTable: summaryExVATTable,
      DetailsTable: detailsTable,
      ProviderName: treatingProviderName,
      SummaryVATTable:summaryVATTable
    };
  }

  private async buildReportInfo(reportRequest: WriteOffReportRequest): Promise<WriteOffReportInfo> {
    const reportInfo: WriteOffReportInfo = {};

    let fullTreatingProviderName: string;
    let speciality: string;
    if (reportRequest.TreatingProvider) {
      fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
      speciality = reportRequest.TreatingProvider.Provider.Speciality;
    }

    reportInfo.Practice =
      reportRequest.Practice?.PracticeName +
      ' (' +
      reportRequest.Practice?.BillingPracticeNumber +
      ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    if (reportRequest.Practice?.IsMultiBranch === true)
      reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

    reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
    reportInfo.DateRangeType = reportRequest.DateRangeType;
    reportInfo.DateRange =
      moment(reportRequest.DateFrom).format(this.DATE_FORMAT) +
      ' - ' +
      moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    reportInfo.BillingPracticeNumber = reportRequest.Practice?.BillingPracticeNumber;
    reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
    reportInfo.Speciality = speciality;
    return reportInfo;
  }

  private async buildProvidersModel(
    reportRequest: WriteOffReportRequest
  ): Promise<WriteOffProviderModel[]> {
    const providerTab: WriteOffProviderModel[] = [];
    const providerList = [];
    if (reportRequest.TreatingProvider) {
      providerList.push(reportRequest.TreatingProvider);
    } else if (reportRequest.TreatingProviders) {
      //providerList.push(...reportRequest.TreatingProviders)
      for (const treatingProvider of reportRequest.TreatingProviders) {
        if (treatingProvider.Invoices) providerList.push(treatingProvider);
      }
    }
    _.sortBy(providerList, 'Surname', 'Name');
    for (const provider of providerList) {
      providerTab.push(await this.buildProviderTabModel(provider, reportRequest));
    }
    return providerTab;
  }

  private async buildProviderTabModel(
    providerInvoice: ProviderInvoices,
    reportRequest: WriteOffReportRequest
  ): Promise<WriteOffProviderModel> {
    const treatingProviderName: string =
      providerInvoice.Provider.Title == 'Dr'
        ? `${providerInvoice.Provider.Title} ${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}`
        : `${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}`;
    let summaryTable: WriteOffSummaryItem[] = [];
    let summaryExVATTable: WriteOffSummaryItem[] = [];
    let summaryVATTable: WriteOffSummaryItem[] = [];
    const detailsTable: WriteOffDetailsItem[] = [];

    const invoices: Invoice[] = providerInvoice.Invoices;
    if (invoices) {
      invoices.forEach((invoice: Invoice) => {
        const writeOffDetailsItem: WriteOffDetailsItem = this.buildWriteOffDetails(
          invoice,
          treatingProviderName
        );
        detailsTable.push(writeOffDetailsItem);
      });
      summaryTable = this.updateSummaryTable(detailsTable);
      summaryExVATTable = reportRequest.IsVATRegistered? this.updateSummaryExVATTable(detailsTable): [];
      summaryVATTable = reportRequest.IsVATRegistered? this.updateSummaryVATTable(detailsTable): [];
    }
    return {
      SummaryTable: summaryTable,
      SummaryExVATTable: summaryExVATTable,
      DetailsTable: detailsTable,
      ProviderName: treatingProviderName,
      SummaryVATTable:summaryVATTable
    };
  }

  private buildWriteOffDetails(invoice: Invoice,treatingProviderName: string): WriteOffDetailsItem {
    const writeOffDetailsItem: WriteOffDetailsItem = {};

    writeOffDetailsItem.WriteOffDate = invoice.WriteOffDate
      ? moment(invoice.WriteOffDate).format(this.DATE_FORMAT)
      : '';
    writeOffDetailsItem.WriteOffType = invoice.WriteOffType;
    writeOffDetailsItem.WriteOffAmount = invoice.WriteOffsAmount;
    writeOffDetailsItem.RecommendedWriteOffLimit = invoice.WriteOffLimit;
    writeOffDetailsItem.CaptureBy = invoice.WriteOffCapturedBy;
    writeOffDetailsItem.AccountNo = invoice.Account?.AccountNo;
    writeOffDetailsItem.Patient = `${invoice.Patient?.Name ?? ''} ${
      invoice.Patient?.Surname ?? ''
    }`.trim();
    writeOffDetailsItem.InvoiceNo = invoice.InvoiceNo;
    writeOffDetailsItem.DateOfService = invoice.DateOfService
      ? moment(invoice.DateOfService).format(this.DATE_FORMAT)
      : '';
    writeOffDetailsItem.DateofSubmission = invoice.DateOfSubmission
      ? moment(invoice.DateOfSubmission).format(this.DATE_FORMAT)
      : '';
    writeOffDetailsItem.InvoiceAmount = invoice.AmountBilled;
    writeOffDetailsItem.Provider = treatingProviderName;
    writeOffDetailsItem.Branch = invoice.Branch;
    writeOffDetailsItem.WriteOffHeaderAmount = invoice.WriteOffHeaderAmount;
    writeOffDetailsItem.WriteOffHeaderAmountVAT = invoice.WriteOffHeaderAmountVAT;
    writeOffDetailsItem.WriteOffHeaderId = invoice.WriteOffHeaderId;

    return writeOffDetailsItem;
  }

  //VAT INCLUSIVE SUMMARY
  private updateSummaryTable(writeOffDetailsItem: WriteOffDetailsItem[],providerName?: string) {

    const writeOffSummary: WriteOffSummaryItem[] = writeOffDetailsItem.reduce((acc, curr) => {
      const providerIndex = acc.findIndex((item) => item.Provider === curr.Provider);
      const writeOffType = curr.WriteOffType?.replace(/\s/g, '_');
      if (providerIndex === -1) {
        acc.push({
          Provider: curr.Provider,
          [writeOffType]: curr.WriteOffAmount,
        });
      } else {
        acc[providerIndex][writeOffType] =
          (acc[providerIndex][writeOffType] || 0) + curr.WriteOffAmount;
      }
      return acc;
    }, []);

    if (writeOffSummary.length == 0) {
      if (providerName != undefined) {
        const writeOffSummaryBlank = {
          Provider: providerName,
          Small_balance: 0,
          Bad_debt: 0,
        };
        writeOffSummary.push(writeOffSummaryBlank);
      }
    }

    return writeOffSummary.sort(this.orderByProvider);
  }

  //VAT EXCLUSIVE SUMMARY
  private updateSummaryExVATTable(writeOffDetailsItem: WriteOffDetailsItem[],providerName?: string) {

    const writeOffSummary: WriteOffSummaryItem[] = writeOffDetailsItem.reduce((acc, curr) => {
      const providerIndex = acc.findIndex((item) => item.WriteOffType === curr.WriteOffType);
      const writeOffType = curr.WriteOffType?.replace(/\s/g, '_ExVAT_');
      if (providerIndex === -1) {
        acc.push({
          Provider: curr.WriteOffType,
          [writeOffType]: curr.WriteOffAmount-curr.WriteOffHeaderAmountVAT,
        });
      } else {
        acc[providerIndex][writeOffType] =
          (acc[providerIndex][writeOffType] || 0) + (curr.WriteOffAmount-curr.WriteOffHeaderAmountVAT);
      }
      return acc;
    }, []);

    if (writeOffSummary.length == 0) {
      if (providerName != undefined) {
        const writeOffSummaryBlank = {
          Provider: providerName,
          Small_ExVAT_balance: 0,
          Bad_ExVAT_debt: 0,
        };
        writeOffSummary.push(writeOffSummaryBlank);
      }
    }
    return writeOffSummary;
  }

  //VAT SUMMARY
  private updateSummaryVATTable(writeOffDetailsItem: WriteOffDetailsItem[],providerName?: string) {

    const writeOffSummary: WriteOffSummaryItem[] = writeOffDetailsItem.reduce((acc, curr) => {
      const providerIndex = acc.findIndex((item) => item.WriteOffType === curr.WriteOffType);
      const writeOffType = curr.WriteOffType?.replace(/\s/g, '_VAT_');
      if (providerIndex === -1) {
        acc.push({
          Provider: curr.WriteOffType,
          [writeOffType]: curr.WriteOffHeaderAmountVAT,
        });
      } else {
        acc[providerIndex][writeOffType] =
          (acc[providerIndex][writeOffType] || 0) + (curr.WriteOffHeaderAmountVAT);
      }
      return acc;
    }, []);

    if (writeOffSummary.length == 0) {
      if (providerName != undefined) {
        const writeOffSummaryBlank = {
          Provider: providerName,
          Small_VAT_balance: 0,
          Bad_VAT_debt: 0,
        };
        writeOffSummary.push(writeOffSummaryBlank);
      }
    }
    return writeOffSummary;
  }

  private orderByProvider(a, b) {
    const providerA = a.Provider.toLowerCase();
    const providerB = b.Provider.toLowerCase();
    if (providerA < providerB) return -1;
    if (providerA > providerB) return 1;
    return 0;
  }

  private formatProviderName(treatingProviderData: Provider | undefined): string {
    return (
      treatingProviderData?.Title +
      ' ' +
      treatingProviderData?.Name +
      ' ' +
      treatingProviderData?.Surname
    );
  }
}
