/* eslint-disable require-jsdoc,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-call,max-len,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return */
// noinspection JSUnresolvedReference

import {IReportGenerator} from "./report-generator";
import moment = require("moment");
import {PDF_MAKE} from "./custom-fonts";
import { BasePdfReportData, FileUploadResult, ReportContent } from '@meraki-flux/schema';
import { PDFMakeHelper } from '../helpers/pdf-make.helper';

/*
  Helper module to generate some common exceljs structures.
*/
export class BasePdfGenerator implements IReportGenerator {
  protected data: BasePdfReportData;
  protected content: any = [];
  protected genDate: string;

  public generateReport(): ReportContent {
    this.generateReportName();
    this.generateDoctorName();
    this.generateBpn();
    this.generateReportHeaders();
    this.generateTables();
    this.generateReasonCodesTable();
    this.generateStatementSummary();
    this.generateStatementAges();
    this.generateBottomMessage();
    this.generateQRCode();
    this.generateFooterTable();

    return this.buildReport();
  }

  protected buildReport(): ReportContent {
    return {
      data: this.generate(),
    };
  }

  private generate(): any {
    const pdfmake = require("pdfmake/build/pdfmake.js");
    pdfmake.vfs = PDF_MAKE;
    pdfmake.fonts = this.getFonts();
    const docDef = PDFMakeHelper.generateDocDefinition(this.data.reportName, this.content, this.data.mainHeader, this.data.orientation, this.genDate, this.data.footerText, this.data.footer?.hbLogo);
    return pdfmake.createPdf(docDef);
  }

  public async uploadReport(fileName?: string, reportData?: any, uploadDataFunc?: any): Promise<FileUploadResult> {
    fileName = this.getReportName(fileName);
    const pdfData = this.getBuffer(reportData);
    return await uploadDataFunc(this.data.bpn, pdfData, fileName);
  }

  public getBuffer(reportData: any) {
    const pdfStream = reportData.getStream();
    pdfStream.end();
    const toArray = require("stream-to-array");
    return toArray(pdfStream).then((parts: any) => {
      return Buffer.concat(parts);
    });
  }

  protected generateReportName() {
    if (this.data.reportName) {
      this.content.push(PDFMakeHelper.generatePageName(this.data.reportName, this.data.reportNameColor, this.genDate));
    }
  }

  private generateDoctorName() {
    if (this.data.doctorName && this.data.bpn) {
      this.content.push(PDFMakeHelper.generateSubSectionHeader(this.data.doctorName, 12, this.data.doctorNameColor));
    }
  }

  private generateBpn() {
    if (this.data.bpn && this.data.doctorName) {
      this.content.push(PDFMakeHelper.generateReportHeader({
        left: [{
          name: "Billing practice no.",
          data: {value: this.data.bpn, decoration: {bold: true}},
        }],
      }));
    }
  }

  protected generateReportHeaders() {
    if (this.data.headers) {
      this.data.headers.forEach((header) => {
        this.content.push(PDFMakeHelper.generateReportHeader(header, this.genDate));
      });
    }
  }

  protected generateTables() {
    if (this.data.tables && this.data.tables.length > 0) {
      this.data.tables.forEach((table) => {
        this.content.push(PDFMakeHelper.generateTableSection(table));
      });
    }
  }

  protected generateReasonCodesTable() {
    if (this.data?.reasonCodes?.rows?.length > 0) {
      this.content.push(PDFMakeHelper.generateTableSection(this.data.reasonCodes));
    }
  }

  private generateStatementSummary() {
    if (this.data.statementSummary) {
      this.content.push(PDFMakeHelper.generateStatementSummary(this.data.statementSummary));
    }
  }

  private generateStatementAges() {
    if (this.data.statementAges) {
      this.content.push(PDFMakeHelper.generateStatementAges(this.data.statementAges));
    }
  }

  protected generateBottomMessage() {
    if (this.data.bottomMessage) {
      this.content.push(PDFMakeHelper.generateBottomMessage(this.data.bottomMessage));
    }
  }

  protected generateFooterTable() {
    if (this.data.footer) {
      this.content.push(PDFMakeHelper.generateFooterTable(this.data.footer));
    }
  }

  private getReportName(fName?: string) {
    let fileName = "Report.pdf";
    if (fName) {
      fileName = fName;
      if (fileName.split(".").pop() !== "pdf") {
        fileName += ".pdf";
      }
    } else if (this.data.reportName) {
      fileName = `${this.data.reportName}_${moment(new Date()).format(
          "DD-MM-YYYY_hh:mm:ss"
      )}.pdf`;
    }
    return fileName;
  }

  protected generateQRCode() {
    if(this.data.snapScan) {
      this.content.push(PDFMakeHelper.generateSnapscanDisplay(this.data.snapScan))
    }
  }

  private getFonts() {
    return {
      Helvetica: {
        normal: "Helvetica.ttf",
        bold: "Helvetica-Bold.ttf",
        italics: "Helvetica-Oblique.ttf",
        bolditalics: "Helvetica-BoldOblique.ttf"
      },
    };
  }

  constructor(data?: BasePdfReportData) {
    this.data = data;
    if (data) {
      this.genDate = `${this.data.dateHeader}`;
    }
  }
}

