import {BaseAccount} from "../account.models";
import {SnapScan} from "../addon.models";
import {BaseInvoice} from "../invoice.models";
import {BaseAccountMember} from "../member.models";
import {Practice, ReportLogoSetting} from "../practice.models";
import {Reason} from "../remittance.models";
import {User} from "../user.models";
import {
  FooterInfoTable,
  PDFCellData,
  ReportICDLine,
  ReportSnapscan,
  ReportTableLegend,
  RowHeaderData,
  StatementAges,
  StatementSummary,
  TableBorders
} from './pdf.model';
import { NameValue, ReportTableHeader } from '../report.models';
import { EMAIL_TYPE } from '../email.models';
import { ReportRequestData } from './common.model';

export class StatementReportRequest implements ReportRequestData {
  Practice?: Practice;
  PracticeId: string;
  AccountId: string;
  Account?: BaseAccount;
  MainMember?: BaseAccountMember;
  Members?: BaseAccountMember[];
  Invoices?: BaseInvoice[];
  SendEmail?: boolean;
  StatementMessage?: string;
  ReplyTo: string;
  ReplyToName: string;
  Type?: EMAIL_TYPE;
  BulkMessageId?: string;
  Snapscan?: SnapScan;
  UserId: string;
}

export class InternalStatementReportRequest {
  Practice: Practice;
  User?: User;
  Account: BaseAccount;
  MainMember: BaseAccountMember;
  Members: BaseAccountMember[];
  Invoices: BaseInvoice[];
  SendEmail?: boolean;
  StatementMessage?: string;
  Snapscan?: SnapScan;
}

export interface BulkStatementReportRequest {
  PracticeId: string;
  Email: string;
  FirstName: string;
  UserId?: string;
  Accounts: BaseAccount[];
  Type? : EMAIL_TYPE;
  BulkMessageId: string;
  Snapscan?: SnapScan;
}

export interface StatementReportModel {
    Logo?: ReportLogoSetting;
    PracticeId: string;
    PracticeName: string;
    Header: string;
    PracticeHeader: StatementHeader;
    AccountHeader: StatementHeader;
    StatementTables: StatementTable[];
    ReasonCodes?: Reason[];
    InfoTable: FooterInfoTable;
    StatementSummary?: StatementSummary;
    StatementAges?: StatementAges;
    FooterText: string;
    StatementMessage?: string,
    StatementDate?: string,
    Snapscan?: ReportSnapscan
}

export interface ProofOfPaymentReportModel {
  PracticeId: string;
  PracticeName: string;
  Header: string;
  PracticeHeader: StatementHeader;
  AccountHeader: StatementHeader;
  StatementTables: StatementTable[];
  InfoTable: FooterInfoTable;
  FooterText: string;
  StatementDate?: string;
}

export interface StatementTable {
  TableHeaders: ReportTableHeader[];
  ICDLine: ReportICDLine;
  rowHeaders?: RowHeaderData[];
  borders?: TableBorders;
  rows?: PDFCellData[][];
  legend?: ReportTableLegend;
}

export interface StatementHeader {
  name?: string;
  left: NameValue[];
  right: NameValue[];
}
