import { Injectable } from "@angular/core";
import { ReportUtils } from "../report-utils";
import { MedicalInsurerPdfReportDataBuilder } from "./medical-insurer-pdf-report-data-builder";
import { MedicalInsurerReportBuilder } from "./medical-insurer-report-builder";
import { PaymentService } from "../../services/transaction/payment.service";
import { TransactionService } from "../../services/transaction/transaction.service";
import {
  BasePdfReportData,
  MedicalInsurerStatementReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class MedicalInsurerReportService {

  constructor(
    private transactionService: TransactionService,
    private paymentService: PaymentService,
) {}

  async generateReport(reportRequest: MedicalInsurerStatementReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildMedInsuerReportFileName(
      reportRequest.MedInsurerDetails.InsurerName, REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    return {
      reportName: REPORT_NAME.STATEMENT,
      content: reportGenerator.generateReport(),
      fileName: fileName,
      format: REPORT_FORMAT_TYPE.PDF,
      practiceId: reportRequest.Practice.Id
    };
  }

  private async generatePdfReportData(reportRequest: MedicalInsurerStatementReportRequest): Promise<BasePdfReportData> {
     const practiceId = reportRequest.Practice.Id;
     const invoices = reportRequest.MedInsurerDetails.Invoices;
     let invoiceTransactions;

     for(let index =0; index< invoices.length; index++){
      const account = invoices[index].Account;
      invoiceTransactions = await this.transactionService.getActiveInvoiceTransactions(practiceId, account.Id, invoices[index].Id);
    }

  const payments = await Promise.all(invoiceTransactions.filter(transaction => transaction.Metadata?.PaymentId)
      .map(async (transaction, index) => {
          const account = invoiceTransactions[index].AccountId;
          const payment = await this.paymentService.getPayment(practiceId, account, transaction.Metadata?.PaymentId);
          return payment;
  }));
     return new MedicalInsurerPdfReportDataBuilder().build(
      await new MedicalInsurerReportBuilder().build(reportRequest, invoiceTransactions, payments));
  }
}
