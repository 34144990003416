import { Injectable } from "@angular/core";
import { collection, collectionSnapshots, doc, docSnapshots, Firestore, query, where, WriteBatch } from "@angular/fire/firestore";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CalendarRepository } from "../repositories/calendar.repository";
import { AuthService } from './auth.service';
import { CalendarEventService } from './calendar/calendar-event.service';
import { orderBy } from "lodash";
import { PathUtils } from "../utils/path-utils";
import {
  CalendarProvider,
  Invoice,
  Practice,
  PracticeProvider,
  PROVIDER_COLOURS,
  PROVIDER_SPECIALITY,
  User,
  VISIT_TYPE
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private calendarRepository: CalendarRepository,
    private firestore: Firestore,
    private authService: AuthService,
    private calendarEventService: CalendarEventService,
  ) {
  }

  updateVisitOnCaptureInvoice(postAction: WriteBatch, invoice: Invoice) {
    // we updated ONLY static invoice data here if it has linked visit
    const visitId = invoice?.LinkedAppointment;
    const invoiceId = invoice?.Id;
    if (!visitId) return;
    if (!invoiceId) throw Error('Invoice ID is null!');
    const visitChange = {
      VisitInfo: {
        InvoiceId: invoiceId,
        Invoiced: true,
        InvoiceAmount: invoice.AmountBilled,
      },
    };
    const path = PathUtils.calendarEventPath(this.authService.selectedBPN, visitId);
    postAction.set(doc(this.firestore, path), visitChange, { merge: true }); // we can use direct update for this case
  }

  loadSupportingData() {
    const practiceLoaded$ = new BehaviorSubject<boolean>(false);
    const userSettingsLoaded$ = new BehaviorSubject<boolean>(false);
    const calendarSettingsLoaded$ = new BehaviorSubject<boolean>(false);

    combineLatest([practiceLoaded$, userSettingsLoaded$, calendarSettingsLoaded$]).subscribe(
      ([practiceLoaded, userLoaded, calendarLoaded]) =>
        this.calendarRepository.setSettingsLoaded(practiceLoaded && userLoaded && calendarLoaded)
    );

    // watch practice and push data to store.
    const practice$ = docSnapshots(doc(this.firestore, `Practice/${this.authService.selectedBPN}`)).pipe(
      map(practiceDoc => practiceDoc.data() as Practice),
      tap(practice => {

        // Set calendar settings
        this.calendarRepository.setCalendarSettings(practice.Settings?.CalendarSettings || {});

        // Set custom visit types
        this.calendarRepository.setCustomVisitTypes(...practice.Settings?.VisitTypes || []);

        // Set selected branch
        const branches = practice.Branches?.filter(b => b.Active);

        let branch = this.calendarRepository.getSelectedBranch() || '';

        // Pick first branch if not main branch
        if (!branch && branches?.length > 0) {
          branch = branches.find(b => b.IsMainBranch)?.Name || branches[0].Name;
        }


        this.calendarRepository.setSelectedBranch(branch);

        // Set visit reasons
        this.calendarRepository.setVisitReasons(practice?.Settings?.ReasonsForVisit?.filter(r => r.Status === 'Active').map(r => r.Text) || []);

        // Set multibranch
        this.calendarRepository.setMultiBranch(practice.IsMultiBranch || false);

        // Set resources
        this.calendarRepository.setResources(practice?.Settings?.RoomsResources?.filter(res => res.Status === 'Active').map(res => res.Text) || []);

        // Set working hours
        this.calendarRepository.setWorkingHours(practice?.Settings?.WorkingHoursEnabled ? (practice?.Settings?.WorkingHours || []) : []);
      })
    );

    let userSettings$: Observable<any>;

    // Load user or provider settings
    if (this.authService.providerId) {
      userSettings$ = docSnapshots(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Provider/${this.authService.providerId}`)).pipe(
        map(providerDoc => providerDoc.data() as PracticeProvider),
        tap(provider => this.calendarRepository.setUserCalendarSettings(provider?.Common?.Settings?.CalendarSettings || {})
        ));
    } else {
      userSettings$ = docSnapshots(this.authService.userRef).pipe(
        map(userDoc => userDoc.data() as User),
        tap(user => this.calendarRepository.setUserCalendarSettings(user?.Common?.Settings?.CalendarSettings || {})),
      );
    }

    // Load providers before loading other options
    // fetch only treating providers
    collectionSnapshots(query(collection(this.firestore, `Practice/${this.authService.selectedBPN}/Provider`),
    where('IsActive', '==', true), where('Type', '==', 'Treating'))).pipe(
      tap(providerColl => {
        const providers = providerColl.map(providerDoc => {
          const docData = providerDoc.data() as PracticeProvider;
          const name = docData.Name;
          const surname = docData.Surname;
          const title = docData.Title;
          return {
            Id: docData.HPCSANumber,
            Name: name,
            Surname: surname,
            Title: title,
            DisplayName: `${title === 'Dr' ? title + ' ' : ''}${name} ${surname}`,
            IsSelected: false,
            DefaultBranch: docData?.Common?.AssignedBranches?.find(b => b.IsDefault) || '',
            Branches: docData?.Common?.AssignedBranches?.map(b => b.BranchName) || [],
            DisciplineCode: PROVIDER_SPECIALITY[docData?.Speciality] || 14,
            DefaultVisitType: docData.Settings?.DefaultVisitType || VISIT_TYPE.REGULAR_CONSULTATION,
            Colour: docData.Settings?.ColourPreferences?.Colour || PROVIDER_COLOURS[0]
          } as CalendarProvider
        });
        const currentProviders = this.calendarRepository.getAllProviders();
        this.calendarRepository.addProviders(...providers);
        const missingProvidersId = currentProviders.filter(cp => !providers.find(p => p.Id === cp.Id)).map(p => p.Id);
        this.calendarRepository.removeProviders(...missingProvidersId);

        practice$.subscribe(() => {
          if (!practiceLoaded$.getValue()) {
            practiceLoaded$.next(true);

            userSettings$.subscribe(() => {
              if (!userSettingsLoaded$.getValue()) {
                userSettingsLoaded$.next(true);
                if (this.calendarRepository.getSelectedProviders().length === 0) {
                  const settings = this.calendarRepository.getUserCalendarSettings();
                  if (settings.DefaultProviders && settings.DefaultProviders.length > 0) {
                    // remove missing default providers from selected providers
                    const missingDefaultProviders = this.calendarRepository.getAllProviders().filter(p => !settings.DefaultProviders.includes(p.Id));
                    if (missingDefaultProviders.length > 0) {
                      // check if any of the missing providers are selected
                      const selectedMissingProviders = missingDefaultProviders.filter(p => this.calendarRepository.isSelectedProvider(p.Id));
                      if (selectedMissingProviders.length > 0) {
                        this.calendarRepository.setSelectedProviders(...selectedMissingProviders.map(p => p.Id));
                      }
                    }
                    // add default providers that belong to practice to selected providers
                    const defaultProviders = this.calendarRepository.getAllProviders().filter(p => settings.DefaultProviders.includes(p.Id));
                    if (defaultProviders.length > 0) {
                      defaultProviders.forEach(provider => {
                        if (!this.calendarRepository.isSelectedProvider(provider.Id)) {
                          this.calendarRepository.setSelectedProviders(provider.Id);
                        }
                      });
                    } else {
                      // set the first provider as selected
                      if (!this.calendarRepository.isSelectedProvider(orderBy(this.calendarRepository.getAllProviders(), 'Index', 'asc')[0].Id))
                        this.calendarRepository.setSelectedProviders(...[orderBy(this.calendarRepository.getAllProviders(), 'Index', 'asc')[0].Id]);
                    }
                  } else {
                    this.calendarRepository.setSelectedProviders(...[orderBy(this.calendarRepository.getAllProviders(), 'Index', 'asc')[0].Id]);
                  }
                }
              }
            });
          }
        });
      }
      )).subscribe();

    // Update providers when user settings change
    this.calendarRepository.calendarSettings$.pipe(
      tap(settings => {
        settings?.ColourPreferences?.ProviderDisplay?.forEach(pref => {
          if (this.calendarRepository.hasProvider(pref.ProviderId)) {
            this.calendarRepository.updateProvider({
              Id: pref.ProviderId,
              Colour: pref.Colour || PROVIDER_COLOURS[0],
              Index: pref.Index || -1
            })
          }
        });
      })
    ).subscribe(() => {
      if (!calendarSettingsLoaded$.getValue()) {
        calendarSettingsLoaded$.next(true);
      }
    });
  }

  selectableProviders() {
    const allProviders = this.calendarRepository.getAllProviders();
    const isMultiBranch = this.calendarRepository.getMultiBranch();
    const selectedBranch = this.calendarRepository.getSelectedBranch();
    const providers = isMultiBranch
      ? allProviders.filter((p) => p.Branches?.includes(selectedBranch))
      : allProviders;
    return providers.sort((a, b) => (b.DisplayName < a.DisplayName ? 1 : b.DisplayName > a.DisplayName ? -1 : 0));
  }

}
