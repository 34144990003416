export class SendOptions {
  senderId = "";
  duplicateCheck = "";
  startDeliveryUtc = "";
  endDeliveryUtc = "";
  replyRuleSetName = "";
  campaignName = "";
  costCentre = "";
  checkOptOuts = "";
  validityPeriod = "";
}

export class Messages {
  content = "";
  destination = "";
  customerId = "";
}

export class SmsData {
  sendOptions = new SendOptions();
  messages: any;
}
