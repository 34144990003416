import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate({ data }: ActivatedRouteSnapshot): boolean {
    window.open(data.externalUrl, '_blank');
    return false;
  }
}
