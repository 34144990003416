import { PathUtils } from '../../utils/path-utils';
import { ReferenceDataService } from '../../services/reference-data.service';
import {collection, Firestore, getDocs, query, where} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {QueryConstraint} from "@firebase/firestore";
import {
  JOURNAL_STATUS,
  MAPayment,
  NoDataError,
  Payment,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PaymentSummaryGroupData,
  PaymentSummaryItem,
  PaymentSummaryReportInfo,
  PaymentSummaryReportModel,
  PaymentSummaryReportRequest,
  RA_CLAIM_STATUS,
  REMITTANCE_ADVICE_TYPE,
  RemittanceAdvice,
  RemittanceClaim,
  RemittanceJournal
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class PaymentSummaryReportBuilder {

  constructor(private firestore: Firestore, private referenceDataService: ReferenceDataService) {
  }

  private readonly REMITTANCE_ELECTRONIC = 'Remittances - electronic';
  private readonly REMITTANCE_MANUAL = 'Remittances - manual';
  private readonly NO_REMITTANCE = 'Not linked to remittance';

  async build(reportRequest: PaymentSummaryReportRequest): Promise<PaymentSummaryReportModel> {

    const reportHeader: PaymentSummaryReportInfo = await this.buildReportInfo(reportRequest);

    const payments: Payment[] = await this.getPayments(reportRequest);
    const remittances: RemittanceAdvice[] = await this.getRemittances(reportRequest);

    if (payments.length == 0 && remittances.length == 0) {
      throw new NoDataError();
    }

    const patientPaymentTypeData: PaymentSummaryGroupData = new PaymentSummaryGroupData();
    const medicalAidPaymentTypeData: PaymentSummaryGroupData = new PaymentSummaryGroupData();
    const schemeData: PaymentSummaryGroupData = new PaymentSummaryGroupData();
    const insurerPaymentTypeData: PaymentSummaryGroupData = new PaymentSummaryGroupData();
    const insurerData: PaymentSummaryGroupData = new PaymentSummaryGroupData();

    for (const payment of payments) {

      if(payment.Status !== PAYMENT_STATUS.REVERSED){

        if (payment.Type === PAYMENT_TYPE.MEDICAL_AID) {

          const summaryItem: PaymentSummaryItem = {
            total: payment.AmountPaid, count: 1,
            allocated: payment.AmountPaid, unallocated: 0
          } as PaymentSummaryItem;

          const maPayment: MAPayment = payment as MAPayment;
          // const paymentLinkedToRA = maPayment.RemittanceAdviceId !== null;
          // const isElectronicRAPayment = paymentLinkedToRA && maPayment.RemittanceAdviceType === REMITTANCE_ADVICE_TYPE.ELECTRONIC;


          if (maPayment.RemittanceAdviceId === null) {
            const paymentSummaryData = maPayment.InsurerName ? insurerPaymentTypeData : medicalAidPaymentTypeData;
            paymentSummaryData.addData(this.NO_REMITTANCE, summaryItem);
            insurerData.addData(maPayment.InsurerName, summaryItem);
            schemeData.addData(maPayment.SchemeName, summaryItem);
          } else {
            // logic below
          }

        } else{
          patientPaymentTypeData.addData(payment.Type, {
            total: payment.AmountPaid, count: 1,
            allocated: payment.AmountPaid - payment.AmountUnallocated, unallocated: payment.AmountUnallocated
            } as PaymentSummaryItem);
        }
      }
    }

    for (const ra of remittances) {
      if (ra.Header?.OBO && (!ra.Type || REMITTANCE_ADVICE_TYPE.ELECTRONIC === ra.Type)) {
        // electronic RA
        const schemeName = ra.SchemeName;//ra.Header?.OBO?.toUpperCase();
        if (!schemeName) continue;
        const paymentSummaryItem = await this.buildRAPaymentSummaryItem(reportRequest.Practice?.Id, ra);
        schemeData.addData(schemeName, paymentSummaryItem);
        medicalAidPaymentTypeData.addData(this.REMITTANCE_ELECTRONIC, paymentSummaryItem);
      } else if (REMITTANCE_ADVICE_TYPE.PAPER === ra.Type && ra.SchemeName) {
        // manual RA

        // const scheme = await this.referenceDataService.getScheme(ra.SchemeCode);
        const schemeName = ra.SchemeName;
        if (!schemeName) continue;
        const paymentSummaryItem = await this.buildRAPaymentSummaryItem(reportRequest.Practice?.Id, ra);
        schemeData.addData(schemeName, paymentSummaryItem);
        medicalAidPaymentTypeData.addData(this.REMITTANCE_MANUAL, paymentSummaryItem);
      } else if (ra.Insurer) {
        // insurer RA

        const insurerName = ra.Insurer;
        if (!insurerName) continue;
        const paymentSummaryItem = await this.buildRAPaymentSummaryItem(reportRequest.Practice?.Id, ra);
        insurerData.addData(insurerName, paymentSummaryItem);
        insurerPaymentTypeData.addData(this.REMITTANCE_MANUAL, paymentSummaryItem);
      }
    }

    return {
      ReportInfo: reportHeader,
      PatientPaymentTypeData: patientPaymentTypeData,
      MedicalAidPaymentTypeData: medicalAidPaymentTypeData,
      SchemeData: schemeData,
      InsurerPaymentTypeData: insurerPaymentTypeData,
      InsurerData: insurerData
    };
  }

  private chunkAccounts(accounts: any[], chunkSize: number): any[][] {
    const chunks = [];
    for (let i = 0; i < accounts.length; i += chunkSize) {
      chunks.push(accounts.slice(i, i + chunkSize));
    }
    return chunks;
  }

  private async getPayments(reportRequest: PaymentSummaryReportRequest): Promise<Payment[]> {
    const practiceId = reportRequest.Practice?.Id;
    const whereConditions: QueryConstraint[] = [
      where('PaymentDate', '>=', reportRequest.DateFrom),
      where('PaymentDate', '<=', reportRequest.DateTo)
    ];
    const accountIdRefs = await getDocs(query(collection(this.firestore, PathUtils.accountCollectionPath(practiceId))));
    const accountIds: string[] = accountIdRefs.docs.map(i => i.id);

    const paymentDocs = [];
    for (const chunk of this.chunkAccounts(accountIds, 2000)) {
      await Promise.all(
        chunk.map(async (accountId) => {
          const payments = await getDocs(query(collection(this.firestore, PathUtils.paymentCollectionPath(practiceId, accountId)), ...whereConditions));
          paymentDocs.push(payments);
        })
      );
    }
    let payments: Payment[] = [];
    paymentDocs.forEach(i => {
      const accountPayments: Payment[] = i.docs.map(i => ({Id: i.id, ...i.data()} as Payment));
      if (accountPayments.length > 0) {
        payments = payments.concat(accountPayments);
      }
    });
    return payments;
  }

  private async buildReportInfo(reportRequest: PaymentSummaryReportRequest): Promise<PaymentSummaryReportInfo> {
    const reportInfo: PaymentSummaryReportInfo = {};
    reportInfo.Practice = reportRequest.Practice?.PracticeName;
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    reportInfo.DateFrom = reportRequest.DateFrom;
    reportInfo.DateTo = reportRequest.DateTo;
    return reportInfo;
  }

  private async getRemittances(reportRequest: PaymentSummaryReportRequest) {
    const practiceId = reportRequest.Practice?.Id;
    const whereConditions: QueryConstraint[] = [
      where('Payment.PayDate', '>=', reportRequest.DateFrom),
      where('Payment.PayDate', '<=', reportRequest.DateTo),
    ];
    const raRefs = await getDocs(query(collection(this.firestore, PathUtils.raCollectionPath(practiceId)), ...whereConditions));
    const raList: RemittanceAdvice[] = raRefs.docs.map(i => ({Id: i.id, ...i.data() as RemittanceAdvice}));

    return raList;
  }

  private async getRemittanceClaims(practiceId: string, raId: string): Promise<RemittanceClaim[]> {
    const refs = await getDocs(query(collection(this.firestore, PathUtils.remittanceClaimCollectionPath(practiceId, raId))));
    return refs.docs.map(i => ({...i.data()} as RemittanceClaim));
  }

  private async getRemittanceJournals(practiceId: string, raId: string): Promise<RemittanceJournal[]> {
    const refs = await getDocs(query(collection(this.firestore, PathUtils.remittanceJournalCollectionPath(practiceId, raId))));
    return refs.docs.map(i => ({...i.data()} as RemittanceJournal));
  }

  private async buildRAPaymentSummaryItem(practiceId: string, ra: RemittanceAdvice) {

    const raClaims: RemittanceClaim[] = await this.getRemittanceClaims(practiceId, ra.Id);
    const raJournals: RemittanceJournal[] = await this.getRemittanceJournals(practiceId, ra.Id);

    const raClaimReconciledAmount: number = raClaims.filter(raClaim => raClaim.Status === RA_CLAIM_STATUS.RECONCILED).reduce((sum, v) => {
      return sum + Number(v.TotPaymentAmount);
    }, 0);

    const raJournalReconciledAmount: number = raJournals.filter(raJournal => raJournal.JournalStatus === JOURNAL_STATUS.RECONCILED ||
      raJournal.JournalStatus === JOURNAL_STATUS.BUSINESS_INCOME).reduce((sum, v) => {
      return sum + Number(v.JournalAmount);
    }, 0);

    const totalReconciled = raClaimReconciledAmount + raJournalReconciledAmount;
    const totalUnreconciled = Number(ra.Payment?.PaidAmount) - totalReconciled;
    const paymentSummaryItem = {
      count: 1, total: Number(ra.Payment?.PaidAmount),
      allocated: totalReconciled,
      unallocated: totalUnreconciled
    } as PaymentSummaryItem;
    return paymentSummaryItem;

  }
}
