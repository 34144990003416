import _ from 'lodash';
import * as moment from 'moment';
import { DateUtils } from './date-utils';
import { MoneyUtils } from './money-utils';

export class FormatUtils {
  private static readonly DATE_FORMAT__DD_MM_YYYY = 'DD/MM/YYYY';
  private static readonly DATE_FORMAT__HH_MM = 'HH:mm';

  static join(strings: string[], separator: string): string {
    return strings
      ?.map((v) => FormatUtils.stringValue(v)?.trim())
      ?.filter((v) => v)
      ?.join(separator);
  }

  static nameSurname(name: string, surname: string): string {
    return FormatUtils.join([name, surname], ' ');
  }

  static dateDDMMYYYY(anyDate: any): string {
    return this.formatDate(anyDate, FormatUtils.DATE_FORMAT__DD_MM_YYYY);
  }

  static formatDate(anyDate: any, format: string): string {
    const date = DateUtils.toDate(anyDate);
    return date ? moment(DateUtils.toDate(date)).format(format) : null;
  }

  static formatCents(cents: number): string {
    return cents || cents === 0
      ? MoneyUtils.fromCents(cents).toLocaleString('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 2
        })
      : null;
  }

  static dateHHMM(anyDate: any): string {
    return this.formatDate(anyDate, FormatUtils.DATE_FORMAT__HH_MM);
  }

  static memberNoDepCode(memberNo: string, depCode: string): string {
    return memberNo ? FormatUtils.join([memberNo, depCode ? `(${depCode})` : ''], ' ') : '';
  }

  static formatReasonCodes(reasonCodes: string[]): string {
    return FormatUtils.join(
      _.uniq(reasonCodes)?.sort((a, b) => Number(a) - Number(b)),
      ', '
    );
  }

  static stringValue(object: any): string {
    return typeof object === 'string' ? object : null;
  }
}
