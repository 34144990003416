import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DateUtils,
  InvoiceWhereBuilder,
  MoneyUtils,
  PathUtils,
} from '@meraki-flux/common';
import { Observable, of } from 'rxjs';
import { AbstractUnmatchedEraItemActionHelper } from './unmatched-era-item-action-abstract.helper';
import {
  DebitPayableInvoice,
  Invoice,
  INVOICE_STATUS, LIABILITY_TYPE,
  PayableInvoice, PaymentCorrection, PaymentCorrectionAllocation, 
  RemittanceAdvice,
  RemittanceClaim
} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class NegativeUnmatchedEraItemActionHelper extends AbstractUnmatchedEraItemActionHelper {
  override hasUserChangesOnInvoices(invoices: PayableInvoice[]): boolean {
    return !!invoices.find((i) => i._AmountAllocated > 0);
  }

  override getRaClaimLineColumns() {
    return ['line', 'tariffCode', 'nappiCode', 'claimed', 'tariff', 'paid', 'reasoncode'];
  }

  override invoices(
    accountId: string,
    paymentDate: Date,
    yearsToSelect: number
  ): Observable<Invoice[]> {
    if (!accountId) return of([]);
    const wheres = InvoiceWhereBuilder.builder()
      .accountId(accountId)
      .statusIn(INVOICE_STATUS.OPEN, INVOICE_STATUS.CLOSED)
      .dateOfServiceGOE(DateUtils.addYears(paymentDate, -yearsToSelect))
      .build();
    return this.invoiceService.getInvoices(wheres);
  }

  override filterInvoices(
    invoices: Invoice[],
    raClaim: RemittanceClaim,
    outstandingBalanceInvoices: boolean,
    filterOnClaimMatches: boolean
  ): Invoice[] {
    return (invoices || [])
      .filter((invoice) => {
        if (!(invoice?.Balance?.Outstanding < invoice.AmountBilled)) return false;
        return true;
      })
      .sort((a, b) => b?.DateOfService?.getTime() - a?.DateOfService?.getTime());
  }

  override async validateOnSave(
    form: UntypedFormGroup,
    raClaim: RemittanceClaim,
    invoices: PayableInvoice[]
  ) {
    return (
      (await super.validateOnSave(form, raClaim, invoices)) &&
      this.validateLiabilityTypeSet(invoices as DebitPayableInvoice[])
    );
  }

  validateLiabilityTypeSet(invoices: DebitPayableInvoice[]) {
    const valid = invoices.filter((i: DebitPayableInvoice) => !i._LiabilityType).length === 0;
    if (!valid) {
      this.dialog.showLiabilityTypeIsMissingWarning();
    }
    return valid;
  }

  override async saveAsReconciled(
    accountId: string,
    inboxItemId: string,
    ra: RemittanceAdvice,
    raClaimId: string,
    formValue: any,
    invoices: PayableInvoice[]
  ) {
    if (invoices.length !== 1) throw Error('Single invoice is expected here');
    const invoice = invoices[0] as DebitPayableInvoice;
    const paymentCorrection = this.buildPaymentCorrection(
      invoice,
      invoice._Reason,
      formValue.AdditionalAccountNotes,
      ra.Id,
      raClaimId,
      formValue.AdditionalRemittanceNotes,
      inboxItemId
    );
    const success = await this.transactionCaptureService.addPaymentCorrection(
      accountId,
      paymentCorrection
    );
    if (!success) throw Error('Failed to process payment correction');
  }

  buildPaymentCorrection(
    invoice: DebitPayableInvoice,
    reason: string,
    accountNote: string,
    raId: string,
    raClaimId: string,
    raClaimNote: string,
    completeInboxItemId: string
  ) {
    const allocations = [];
    const liatilityType = invoice._LiabilityType;
    invoice.Lines.forEach((line) => {
      const amountAllocated = MoneyUtils.toCents(line._AmountAllocatedCtrl.value);
      const mal = liatilityType === LIABILITY_TYPE.MAL ? amountAllocated : 0;
      const pl = liatilityType === LIABILITY_TYPE.PL ? amountAllocated : 0;
      if (mal > 0 || pl > 0) {
        allocations.push({
          InvoiceLineNo: line.LineNumber,
          MedicalAidLiable: mal,
          PatientLiable: pl,
        } as PaymentCorrectionAllocation);
      }
    });
    return {
      InvoicePath: PathUtils.invoicePath(this.authService.selectedBPN, invoice.Id),
      Reason: reason || null,
      AccountNote: accountNote || null,
      Allocations: allocations,
      RemittanceId: raId,
      RemittanceClaimId: raClaimId,
      RemittanceClaimNote: raClaimNote || null,
      CompleteInboxItemId: completeInboxItemId || null,
    } as PaymentCorrection;
  }
}
