<div class="flex flex-col gap-3" [formGroup]="form">

    <!-- Invoice selection -->
    <mat-form-field class="flux-form-field w-full">
        <mat-label i18n>Select invoice</mat-label>
        <mat-select id="invoiceSelect" formControlName="Invoice">
            <mat-option *ngFor="let invoice of payableInvoices" [value]="invoice">
                DOS {{ invoice?.DateOfService | date: 'dd/MM/yyyy'}} - {{ invoice?.Patient?.Name }} {{
                invoice?.Patient?.Surname }} - Inv. {{ invoice?.InvoiceNo}} ({{ invoice?.AmountBilled/100 | currency
                :
                'R ' : 'symbol' : '1.2-2' }})
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Liability type selection radio buttom group -->
    <mat-radio-group class="flux-form-field w-[408px] space-y-4 my-2" formControlName="LiabilityType">
        <mat-radio-button id="malRadioBtn" [value]="liabilityType.MAL">Increase medical aid liable
            amount</mat-radio-button>
        <mat-radio-button id="plRadioBtn" [value]="liabilityType.PL">Increase patient liable amount
        </mat-radio-button>
    </mat-radio-group>

    <!-- Reason for correction -->
    <mat-form-field class="flux-form-field  w-full">
        <mat-label>Reason for correction</mat-label>
        <input id='reasonInput' class="font-semibold" matInput formControlName='Reason'>
    </mat-form-field>

    <div *ngIf="selectedInvoice$ | async as invoice">
        <mat-table id='invocieTable' class='lines-table flux-table' [dataSource]="invoice.Lines">

            <!-- DOS / Invoice no. column -->
            <ng-container matColumnDef="dos">
                <mat-header-cell *matHeaderCellDef>Date of service /<br>Invoice no.</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div class="flex flex-col">
                        <span id='dateOfService'>{{ i === 0 ? (invoice.DateOfService | date:'dd/MM/yyyy') : '' }}</span>
                        <span id='invoiceNo' class="text-hb-text-disabled text-xs">
                            {{ i === 0 ?invoice.InvoiceNo : '' }}
                        </span>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <!-- Patient / Claim status column -->
            <ng-container matColumnDef="patient">
                <mat-header-cell *matHeaderCellDef>Patient /<br>Claim status</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div class="flex flex-col">
                        <span id='patient'>{{ helper.patientNameSurnameDepCode(i, invoice?.Patient)
                            }}</span>
                        <span id='invoiceDesc' class="text-hb-text-disabled text-xs">
                            {{ helper.invoiceDescription(i, invoice) }}
                        </span>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <!-- Code column -->
            <ng-container matColumnDef="code">
                <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <p id='code_{{i}}'>{{ row.NappiCode ? row.NappiCode : row.TariffCode }}</p>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>Subtotal</mat-footer-cell>
            </ng-container>

            <!-- Invoiced column -->
            <ng-container matColumnDef="invoiced">
                <mat-header-cell *matHeaderCellDef class="text-right">Invoiced</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <span id='amountBilled_{{i}}' class="w-full text-right">
                        {{ row.AmountBilled/100 | number : '1.2-2' }}
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <span id='totalAmountBilled' class="w-full text-right">
                        {{ invoice.AmountBilled/100 | number : '1.2-2' }}
                    </span>
                </mat-footer-cell>
            </ng-container>

            <!-- MAL column -->
            <ng-container matColumnDef="mal">
                <mat-header-cell *matHeaderCellDef class="text-right">Medical aid liable</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <span id='mal_{{i}}' class="w-full text-right"
                        [ngClass]="{ 'text-hb-critical-100': row.Balance?.MedicalAidLiable> 0 }">
                        {{ row.Balance?.MedicalAidLiable/100 | number : '1.2-2' }}
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <span id='totalMAL' class="w-full text-right">
                        {{ invoice.Balance?.MedicalAidLiable/100 | number : '1.2-2' }}
                    </span>
                </mat-footer-cell>
            </ng-container>

            <!-- PL column -->
            <ng-container matColumnDef="pl">
                <mat-header-cell *matHeaderCellDef class="text-right">Patient liable</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <span id='pl_{{i}}' class="w-full text-right">
                        {{ row.Balance?.PatientLiable/100 | number : '1.2-2' }}
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <button *ngIf="helper.showTransferLiabilities() | async" mat-icon-button
                        (click)="helper.onTransferLiabilities(invoice)">
                        <mat-icon class="!text-hb-success-100 mb-1" matTooltip="Transfer Liabilities">
                            swap_horiz</mat-icon>
                    </button>
                    <span id='totalPL' class="w-full text-right">
                        {{ invoice.Balance?.PatientLiable/100 | number : '1.2-2' }}
                    </span>
                </mat-footer-cell>
            </ng-container>

            <!-- Debit Amount column -->
            <ng-container matColumnDef="debitAmount">
                <mat-header-cell *matHeaderCellDef class="text-right">Debit amount</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <mat-form-field appearance="outline" class="w-full flux-form-field">
                        <input id="amountPaid_{{i}}" class="font-semibold" matInput currencyMask
                            [formControl]='row._AmountAllocatedCtrl' (focusout)="onFocusOutEvent(row)"
                            [options]="{ prefix: '', thousands: ',', decimal: '.', allowNegative : false }">
                    </mat-form-field>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <span id="totalAmountAllocated" class="w-full text-right mr-2.5" *ngVar="invoice._AmountAllocated">
                        {{ invoice._AmountAllocated/100 | currency : ' ' : 'symbol' : '1.2-2' }}
                    </span>
                </mat-footer-cell>
            </ng-container>

            <!-- Balance column -->
            <ng-container matColumnDef="balance">
                <mat-header-cell *matHeaderCellDef class="text-right">Balance</mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <span id="balance_{{i}}" class="w-full text-right font-semibold"
                        [ngClass]="{ 'text-hb-critical-100': row._BalanceOutstanding < 0 }">
                        {{ row._BalanceOutstanding/100 | currency : ' ' : 'symbol' : '1.2-2' }}
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <span id='totalBalance' class="w-full text-right"
                        [ngClass]="{ 'text-hb-critical-100': invoice._BalanceOutstanding < 0 }">
                        {{ invoice._BalanceOutstanding/100 | currency : ' ' : 'symbol' : '1.2-2' }}
                    </span>
                </mat-footer-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: COLUMNS"></mat-row>
            <mat-header-row [ngClass]="{'hidden' : !invoice?.Lines}" *matHeaderRowDef="COLUMNS"></mat-header-row>
            <mat-footer-row [ngClass]="{'hidden' : !invoice?.Lines}" *matFooterRowDef="COLUMNS"></mat-footer-row>
        </mat-table>
    </div>

</div>