import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { finalize, first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'meraki-flux-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  form = this.fb.group({
    userName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
  });

  loading = false;
  submitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthService,
    private http: HttpClient,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async onSubmit() {
    this.form.markAllAsTouched();

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const forgotPasswordRequest = { username: this.form.controls.userName.value, email: this.form.controls.email.value };
    this.http.post(environment.forgotPasswordUrl, forgotPasswordRequest)
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.dialogService.showInfoMessage('Confirmation email was sent to your mailbox');
          this.router.navigate(['../login'], { relativeTo: this.route });
        },
        error: error => {
          const innerError = error.error.error ? error.error.error : error;
          if (innerError.message) {
            this.dialogService.showErrorMessage(innerError.message)
          } else {
            this.dialogService.showErrorMessage('Could not send confirmation email')
          }
        }
      });
  }
}
