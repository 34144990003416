import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { SystemAccessHelperService } from '../services/system-access-helper.service';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SystemAccessGuard implements CanActivate {
  constructor(
    public systemAccessHelperService: SystemAccessHelperService,
    public router: Router,
    public route: ActivatedRoute,
    private location: Location,
    private dialogService: DialogService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let navigateRoute = next.data?.redirectTo ?? '403';
    if(next.data?.access) {
      return this.systemAccessHelperService.hasAccess(next.data?.access).pipe(
        first(),
        map(access => {
          if(!access) {
            const url = state.url.split('/');
            this.dialogService.showSnackbar('You have insufficient permissions');
            this.router.navigate([`${url.length > 2 ? url[1] : ''}`, navigateRoute]);
            return false;
          } else {
            return true;
          }
        }),
      )
    } else if(next.data?.sectionAccess) {
      return this.systemAccessHelperService.hasAccessToSection(next.data?.sectionAccess).pipe(
        first(),
        map(access => {
          if(!access) {
            const url = state.url.split('/');
            this.dialogService.showSnackbar('You have insufficient permissions');
            this.router.navigate([`${url.length > 2 ? url[1] : ''}`, navigateRoute]);
            return false;
          } else {
            return true;
          }
        }),
      )
    }
    return of(false);
  }
}
