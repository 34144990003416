import { Injectable } from '@angular/core';
import { doc, Firestore, WriteBatch, writeBatch } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { INBOX_STATUS, InboxItem, InboxItemIsResolvedError } from '@meraki-flux/schema';
import { PathUtils } from '../utils/path-utils';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { LogService } from './log.service';

@Injectable({ providedIn: 'root' })
export class InboxService {
  route: ActivatedRoute;
  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private logger: LogService
  ) {}

  async complete(inboxId: string, batch: WriteBatch = null) {
    await this.completeAndUpdate(inboxId, {}, batch);
  }

  async completeAndUpdate(inboxId: string, additionalData: any = {}, batch: WriteBatch = null) {
    if (!inboxId) this.logger.error('Complete WI error: missing input');
    const completeData = {
      ...additionalData,
      Status: INBOX_STATUS.COMPLETED,
      ...this.firestoreService.getUpdatedByFields(),
    };
    const _batch = batch || writeBatch(this.firestore);
    _batch.update(
      doc(this.firestore, PathUtils.inboxPath(this.authService.selectedBPN, inboxId)),
      completeData
    );
    if (!batch) await _batch.commit();
  }

  async changeStatusWithBatch(
    batch: WriteBatch,
    practiceId: string,
    inboxId: string,
    status: INBOX_STATUS,
    additionalData: any = {},
    commit: boolean = false
  ) {
    if (!practiceId || !inboxId || !status || !batch) 
      throw Error('Complete WI error: missing input');

    await this.checkIfStatusIsTerminal(practiceId, inboxId);

    const completeData = {
      ...additionalData,
      Status: status,
      ...this.firestoreService.getUpdatedByFields(),
    };

    batch.update(doc(this.firestore, PathUtils.inboxPath(practiceId, inboxId)), completeData);

    if (commit) await batch.commit();
  }

  async checkIfStatusIsTerminal(practiceId: string, inboxId: string) {
    const firestoreInboxItem = await this.getInboxItem(practiceId, inboxId);
    const isTerminalStatus = firestoreInboxItem.Status !== INBOX_STATUS.CREATED;
    // If the status is already terminal, do not update it
    // and return a message to the user
    if (isTerminalStatus)
      throw new InboxItemIsResolvedError(
        `The action you are working on has already been updated to ${firestoreInboxItem.Status} by ${firestoreInboxItem.AssignedTo.FullName}`
      );
  }

  async getInboxItem(practiceId: string, inboxId: string) {
    return (await this.firestoreService.findOne(
      PathUtils.inboxPath(practiceId, inboxId)
    )) as InboxItem;
  }
}
