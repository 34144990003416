import moment = require("moment");
import * as _ from "lodash";
import {
  CreditNoteDetailsItem,
  CreditNoteProviderModel,
  CreditNoteReportInfo,
  CreditNoteReportModel,
  CreditNoteReportRequest, CreditNoteSummaryItem,
  InvoiceNexus, NoDataError,
  Provider, ProviderInvoices
} from '@meraki-flux/schema';

export class CreditNotesReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: CreditNoteReportRequest): Promise<CreditNoteReportModel> {
        const reportHeader: CreditNoteReportInfo = await this.buildReportInfo(reportRequest);
        const providersModel: CreditNoteProviderModel[] = await this.buildProvidersModel(reportRequest);

        this.checkDataAvailability(providersModel);

        let allProvidersModel: CreditNoteProviderModel = undefined;
        if (!reportRequest.TreatingProvider && providersModel.length > 1) {
            allProvidersModel = this.buildAllProvidersModel(providersModel, reportRequest);
        }
        return {
            CreditNoteReportInfo: reportHeader,
            ProviderModels: providersModel,
            AllProvidersModel: allProvidersModel,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(providersModel: CreditNoteProviderModel[]) {
        let noData = true;
        providersModel.forEach(providerModel => {
            if (providerModel.DetailsTable.length > 0) {
                noData = false;
            }
        });
        if (noData)
            throw new NoDataError();
    }

    private buildAllProvidersModel(providerTabs: CreditNoteProviderModel[], reportRequest: CreditNoteReportRequest): CreditNoteProviderModel {
        const treatingProviderName = 'All providers';
        let summaryTable: CreditNoteSummaryItem[] = [];
        let summaryVATTable: CreditNoteSummaryItem[] = [];
        let detailsTable: CreditNoteDetailsItem[] = [];
        for (const providerTab of providerTabs) {
            detailsTable = detailsTable.concat(providerTab.DetailsTable);
            summaryTable = summaryTable.concat(this.updateSummaryTable(summaryTable, providerTab.DetailsTable, providerTab.ProviderName));
            summaryVATTable = reportRequest.IsVATRegistered? this.updateSummaryVATTable(summaryTable, detailsTable) : [];
        }
        return {
            SummaryTable: summaryTable,
            SummaryVATTable: summaryVATTable,
            DetailsTable: detailsTable,
            ProviderName: treatingProviderName
        };
    }

    private async buildReportInfo(reportRequest: CreditNoteReportRequest): Promise<CreditNoteReportInfo> {
        const reportInfo: CreditNoteReportInfo = {};

        let fullTreatingProviderName: string;
        let speciality: string;
        if (reportRequest.TreatingProvider) {
            fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
            speciality = reportRequest.TreatingProvider.Provider.Speciality;
        }

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        if (reportRequest.Practice?.IsMultiBranch === true)
            reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

        reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
        reportInfo.DateRangeType = reportRequest.DateRangeType;
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        // reportInfo.InvoiceType = reportRequest.InvoiceType ? reportRequest.InvoiceType : 'All';
        // reportInfo.Scheme = reportRequest.Scheme ? reportRequest.Scheme : 'All';
        reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
        reportInfo.Speciality = speciality;
        return reportInfo;
    }

    private async buildProvidersModel(reportRequest: CreditNoteReportRequest): Promise<CreditNoteProviderModel[]> {
        const providerTab: CreditNoteProviderModel[] = [];
        const providerList = [];
        if (reportRequest.TreatingProvider) {
            providerList.push(reportRequest.TreatingProvider);
        } else if (reportRequest.TreatingProviders) {
            //providerList.push(...reportRequest.TreatingProviders)
            for (const treatingProvider of reportRequest.TreatingProviders) {
                if(treatingProvider.Invoices)
                    providerList.push(treatingProvider);
            }
        }
        _.sortBy(providerList, 'Surname', 'Name');
        for (const provider of providerList) {
            providerTab.push(await this.buildProviderTabModel(provider, reportRequest));
        }
        return providerTab;
    }

    private async buildProviderTabModel(providerInvoice: ProviderInvoices, reportRequest: CreditNoteReportRequest): Promise<CreditNoteProviderModel> {

        const treatingProviderName: string = providerInvoice.Provider.Title == 'Dr' ?
            `${providerInvoice.Provider.Title} ${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}` :
            `${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}`;
        let summaryTable: CreditNoteSummaryItem[] = [];
        let summaryVATTable: CreditNoteSummaryItem[] = [];
        const detailsTable: CreditNoteDetailsItem[] = [];

        const invoices: InvoiceNexus[] = providerInvoice.Invoices
        if (invoices) {
            invoices.forEach((invoice: InvoiceNexus) => {
                const creditNoteDetailsItem: CreditNoteDetailsItem = this.buildCreditNoteDetails(invoice, treatingProviderName);
                detailsTable.push(creditNoteDetailsItem);

            })
            summaryTable = this.updateSummaryTable(summaryTable, detailsTable);
            summaryVATTable = reportRequest.IsVATRegistered? this.updateSummaryVATTable(summaryTable, detailsTable) : [];
        }
        return {
            SummaryTable: summaryTable,
            SummaryVATTable: summaryVATTable,
            DetailsTable: detailsTable,
            ProviderName: treatingProviderName
        };
    }

    private buildCreditNoteDetails(invoice: InvoiceNexus, treatingProviderName: string): CreditNoteDetailsItem {
        const creditNoteDetailsItem: CreditNoteDetailsItem = {};
        creditNoteDetailsItem.CreditNoteDate = invoice.CreditNotesDate ? moment(invoice.CreditNotesDate).format(this.DATE_FORMAT) : "";
        creditNoteDetailsItem.CreditNoteType = invoice.CreditNotesType;
        creditNoteDetailsItem.CreditNoteAmount = invoice.CreditNotesAmount;
        creditNoteDetailsItem.AccountNo = invoice.Account?.AccountNo;
        creditNoteDetailsItem.CaptureBy = invoice.CapturedBy;
        creditNoteDetailsItem.Reason = invoice.Reason;
        creditNoteDetailsItem.Patient = `${invoice.Patient?.Name??''} ${invoice.Patient?.Surname??''}`.trim();
        creditNoteDetailsItem.InvoiceNo = invoice.InvoiceNo;
        creditNoteDetailsItem.DateOfService = invoice.DateOfService ? moment(invoice.DateOfService).format(this.DATE_FORMAT) : "";
        creditNoteDetailsItem.DateofSubmission = invoice.DateOfSubmission ? moment(invoice.DateOfSubmission).format(this.DATE_FORMAT) : "";
        creditNoteDetailsItem.InvoiceAmount = invoice.AmountBilled;
        creditNoteDetailsItem.Provider = treatingProviderName;
        creditNoteDetailsItem.Branch = invoice.Branch;
        creditNoteDetailsItem.CreditNotesHeaderAmount = invoice.CreditNotesHeaderAmount;
        creditNoteDetailsItem.CreditNotesHeaderAmountVAT = invoice.CreditNotesHeaderAmountVAT;
        creditNoteDetailsItem.CreditNotesHeaderId = invoice.CreditNotesHeaderId;

        return creditNoteDetailsItem;
    }

    private updateSummaryTable(summaryTable: CreditNoteSummaryItem[], creditNoteDetailsItem: CreditNoteDetailsItem[], providerName?: string) {

        const summaryColumns: string[] = ['Discount','Correction of error','Reversal processed','Claim resubmitted','Cash invoice cancellation'];

        const creditNoteSummary: CreditNoteSummaryItem[] = creditNoteDetailsItem.reduce((acc, curr) => {
            const providerIndex = acc.findIndex(item => item.Provider === curr.Provider);
            const isOthers = summaryColumns.includes(curr.CreditNoteType)? false :true;
            const creditNoteType = isOthers? "Other" : curr.CreditNoteType?.replace(/\s/g, "_") ;
            if (providerIndex === -1) {
            acc.push({
                Provider: curr.Provider,
                [creditNoteType]: curr.CreditNoteAmount
            });
            } else {
                acc[providerIndex][creditNoteType] = (acc[providerIndex][creditNoteType] || 0) + curr.CreditNoteAmount;
            }
            return acc;
        }, []);

        if(creditNoteSummary.length == 0){
            if(providerName != undefined){
                const creditNoteSummaryBlank = {
                  Provider: providerName,
                  Discount: 0,
                  Correction_of_error: 0,
                  Other: 0,
                  Reversal_processed: 0,
                  Claim_resubmitted: 0,
                  Cash_invoice_cancellation: 0,
                };
                creditNoteSummary.push(creditNoteSummaryBlank);
            }
        }

        return creditNoteSummary.sort(this.orderByProvider);

    }

    private orderByProvider(a, b) {
        const providerA = a.Provider.toLowerCase();
        const providerB = b.Provider.toLowerCase();
        if (providerA < providerB) return -1;
        if (providerA > providerB) return 1;
        return 0;
      }

    private updateSummaryVATTable(summaryTable: CreditNoteSummaryItem[], creditNoteDetailsItem: CreditNoteDetailsItem[]) {

        const summaryColumns: string[] = ['Discount','Correction of error','Reversal processed','Claim resubmitted','Cash invoice cancellation'];


        const map = new Map<string, CreditNoteDetailsItem[]>();
        for (const item of creditNoteDetailsItem) {
            const headerId = item.CreditNotesHeaderId ?? "";
            const array = map.get(headerId) ?? [];
            array.push(item);
            map.set(headerId, array);
        }

        const creditNoteSummary: CreditNoteSummaryItem[] = [];
        for (const [, array] of map.entries()) {
            const first = array[0];
            //const creditNoteType =  first.CreditNoteType?.replace(/\s/g, "_") ?? "";
            const isOthers = summaryColumns.includes(first.CreditNoteType)? false :true;
            const creditNoteType = isOthers? "Other" : first.CreditNoteType?.replace(/\s/g, "_") ;
            const CreditNotesHeaderAmount =  first.CreditNotesHeaderAmount ?? 0;
            const CreditNotesHeaderAmountVAT =  first.CreditNotesHeaderAmountVAT ?? 0;

            const item: CreditNoteSummaryItem = {
                Provider: "HeaderAmount",
                [creditNoteType]: CreditNotesHeaderAmount - CreditNotesHeaderAmountVAT,
            };
            creditNoteSummary.push(item);

            const itemVat: CreditNoteSummaryItem = {
                Provider: "HeaderAmountVAT",
                [creditNoteType]: CreditNotesHeaderAmountVAT,
            };
            creditNoteSummary.push(itemVat);

        }

        return creditNoteSummary;

    }



    private formatProviderName(treatingProviderData: Provider | undefined): string {
        return treatingProviderData?.Title + ' ' + treatingProviderData?.Name + ' ' + treatingProviderData?.Surname;
    }

}
