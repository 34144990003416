import { Injectable } from '@angular/core';
import {
  DialogService,
} from '@meraki-flux/common';
import {DialogButtonStyle, DialogType, NO_BUTTON, NO_BUTTON_ID, YES_BUTTON, YES_BUTTON_ID} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class UnmatchedEraItemActionDialog {
  constructor(private dialogService: DialogService) {}

  async confirmDeactivation(): Promise<boolean> {
    const reponse = await this.dialogService
      .showCustomButtonDialog(
        'If you click away now, the work you have completed will be lost.<br>Are you sure you want to leave this screen?',
        'Unsaved Changes',
        DialogType.WARNING,
        YES_BUTTON,
        NO_BUTTON
      )
      .closed.toPromise();
    return reponse === YES_BUTTON_ID;
  }

  async confirmAccountChange() {
    const reponse = await this.dialogService
      .showDialog({
        message:
          'If you change to a different account, the allocations you’ve already done will be lost. <br><br>Do you want to change the account?',
        type: DialogType.WARNING,
        buttons: [
          {
            caption: 'No',
            style: DialogButtonStyle.SECONDARY,
            id: NO_BUTTON_ID,
          },
          {
            caption: 'Yes, change account',
            style: DialogButtonStyle.PRIMARY,
            id: YES_BUTTON_ID,
          },
        ],
      })
      .closed.toPromise();
    return reponse === YES_BUTTON_ID;
  }

  async confirmCouldNotReconcile() {
    const reponse = await this.dialogService
      .showDialog({
        message:
          'You have already filled in some information for payment against the selected account.<br><br>If you indicate that you cannot reconcile, the information you’ve entered will be discarded.<br><br>Do you want to proceed?',
        type: DialogType.WARNING,
        buttons: [
          {
            caption: 'Yes, cannot reconcile',
            style: DialogButtonStyle.PRIMARY,
            id: YES_BUTTON_ID,
          },
          {
            caption: 'No',
            style: DialogButtonStyle.SECONDARY,
            id: NO_BUTTON_ID,
          },
        ],
      })
      .closed.toPromise();
    return reponse === YES_BUTTON_ID;
  }

  showOverAllocatationWarning() {
    this.dialogService.showWarning(
      'You cannot allocate a greater amount than what was paid for this RA.<br><br>Please correct before saving.'
    );
  }

  showFullAllocationRequiredWarning() {
    this.dialogService.showWarning(
      'You have to either allocate the full payment amount, or you have to indicate that you could not reconcile.'
    );
  }

  showLiabilityTypeIsMissingWarning() {
    this.dialogService.showWarning('You have to select liability type.');
  }

  async confirmAllocationToRejectedClaim() {
    const reponse = await this.dialogService
      .showDialog({
        message:
          'You are allocating a payment against a claim with “Rejected” status. The status of the claim will be updated to “Remittance advice received”, and the matching  Fix & Resubmit action in your Inbox will be removed automatically.<br><br>Do you want to proceed?',
        type: DialogType.WARNING,
        buttons: [
          {
            caption: 'Yes, proceed',
            style: DialogButtonStyle.PRIMARY,
            id: YES_BUTTON_ID,
          },
          {
            caption: 'No',
            style: DialogButtonStyle.SECONDARY,
            id: NO_BUTTON_ID,
          },
        ],
      })
      .closed.toPromise();
    return reponse === YES_BUTTON_ID;
  }

  showSnackbar(message: string) {
    this.dialogService.showSnackbar(message);
  }

  showSaveError(message: string) {
    this.dialogService.showWarning(message);
  }

  async showWarning(message: string, title?: string) {
    await this.dialogService
      .showDialog({
        message: message,
        title: title || '',
        type: DialogType.WARNING,
      })
      .closed.toPromise();
  }
}
