import { Scheme, SchemePlanOption } from '@meraki-flux/schema';
import { FormatUtils } from './format-utils';
import _ from 'lodash';

export class SchemeUtils {
  static readonly DISCONTINUED_SUFFIX = '(disc)';

  static spoNames(
    schemes: Scheme[],
    schemeCode: string,
    planCode: string,
    optionsCode: string
  ): SchemePlanOption {
    const scheme = schemes?.find((p) => p.Code === schemeCode);
    const plan = scheme?.Plans?.find((p) => p.Code === planCode);
    const option = plan?.Options?.find((o) => o.Code === optionsCode);
    return {
      Scheme: scheme?.Name || '',
      Plan: plan?.Plan || '',
      Option: option?.Option || '',
    } as SchemePlanOption;
  }

  static formatSpo(
    schemes: Scheme[],
    schemeCode: string,
    planCode: string,
    optionsCode: string,
    separator: string = ' '
  ): string {
    const names = SchemeUtils.spoNames(schemes, schemeCode, planCode, optionsCode);
    return FormatUtils.join([names.Scheme, names.Plan, names.Option], separator);
  }

  static hasDiscontinuedSuffix(name: string): boolean {
    return name?.toLocaleLowerCase().endsWith(SchemeUtils.DISCONTINUED_SUFFIX.toLocaleLowerCase());
  }

  static isDiscontinued(spoCodes: SchemePlanOption, scheme: Scheme): boolean {
    if (scheme?.DiscontinuedDate || this.hasDiscontinuedSuffix(scheme?.Name)) return true;
    const plan = scheme?.Plans?.find((p) => p.Code === spoCodes.Plan);
    if (!plan || this.hasDiscontinuedSuffix(plan.Plan)) return true;
    const option = plan?.Options?.find((o) => o.Code === spoCodes.Option);
    return !option || this.hasDiscontinuedSuffix(option.Option);
  }

  static getPlans(schemes: Scheme[], schemeCode: string) {
    return schemes?.find((s) => s.Code === schemeCode)?.Plans || [];
  }

  static getPlanByPlanCode(schemes: Scheme[], schemeCode: string, planCode: string) {
    const plans = this.getPlans(schemes, schemeCode);
    return plans?.find((p) => p.Code === planCode);
  }

  static getOptions(schemes: Scheme[], schemeCode: string, planCode: string) {
    return this.getPlanByPlanCode(schemes, schemeCode, planCode)?.Options || [];
  }

  static getPlanByOptionCode(schemes: Scheme[], schemeCode: string, optionCode: string) {
    const plans = this.getPlans(schemes, schemeCode);
    return plans?.find((p) => p?.Options?.find((o) => o.Code === optionCode));
  }

  static mapSchemeDocToArray(schemeDoc: any): Scheme[] {
    if (!schemeDoc) return [];
    return Object.keys(schemeDoc)
      .filter((key) => key !== 'S0000' && key !== 'S0001')
      .reduce((result, schemeCode) => {
        result.push({ ...schemeDoc[schemeCode], Code: schemeCode });
        return result;
      }, [])
      .filter((r) => !r.Name.endsWith('(disc)'));
  }
}
