import {
  NoDataError, RAClaimSuspenseReport, RASchemeModel, RASuspenseDSData, RASuspenseReportInfo, RASuspenseReportModel, RASuspenseReportRequest,
  SchemeSummaryItem
} from '@meraki-flux/schema';

import moment = require('moment');

export class RASuspenseAccountReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: RASuspenseReportRequest): Promise<RASuspenseReportModel> {
    const reportHeader: RASuspenseReportInfo = await this.buildReportInfo(reportRequest);
    const schemesModel: RASchemeModel = await this.buildSchemesModel(reportRequest);

    this.checkDataAvailability(schemesModel);

    return {
      ReportInfo: reportHeader,
      RASchemeModel: schemesModel,
      ReportDate: moment(new Date()).format(this.DATE_FORMAT),
    };
  }

  private checkDataAvailability(schemesModel: RASchemeModel) {
    let noData = true;
    if (schemesModel.RASuspenseDSData.length > 0) {
      noData = false;
    }

    if (noData) throw new NoDataError();
  }

  private async buildReportInfo(reportRequest: RASuspenseReportRequest): Promise<RASuspenseReportInfo> {
    const reportInfo: RASuspenseReportInfo = {};

    let schemeName: string;
    if (reportRequest.Scheme) {
      schemeName = reportRequest.Scheme;
    }

    reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
    reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
    reportInfo.Scheme = schemeName ? schemeName : 'All';
    reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    return reportInfo;
  }

  private async buildSchemesModel(reportRequest: RASuspenseReportRequest): Promise<RASchemeModel> {
    const detailsItem: RASuspenseDSData[] = this.buildRASchemeDetails(reportRequest.RAList);
    const schemeSummaryItem: SchemeSummaryItem[] = this.buildSchemeSummaryItem(detailsItem);

    return {
      RASuspenseDSData: detailsItem,
      SchemeSummaryItem: schemeSummaryItem,
    };
  }

  private buildRASchemeDetails(raClaim: RAClaimSuspenseReport[]): RASuspenseDSData[] {
    const groupedDetails = {};

    for (const detailsObj of raClaim) {
      const key = `${detailsObj.SchemeName}_${detailsObj.RANum}_${detailsObj.PaymentDate}_${detailsObj.PatName}_${detailsObj.PatSurname}_${detailsObj.PatAccNum}_${detailsObj.DOS}`;

      // eslint-disable-next-line no-prototype-builtins
      if (!groupedDetails.hasOwnProperty(key)) {
        groupedDetails[key] = {
          SchemeName: detailsObj.SchemeName,
          RANum: detailsObj.RANum,
          DateOfService:  moment(this.convertTimestampToDateTime(detailsObj.DOS)).format(this.DATE_FORMAT),
          PayDate:  moment(this.convertTimestampToDateTime(detailsObj.PaymentDate)).format(this.DATE_FORMAT),
          PatName: detailsObj.PatName,
          PatSurname: detailsObj.PatSurname,
          AccountNo: detailsObj.PatAccNum,
          PaidAmount: 0,
        };
      }

      groupedDetails[key].PaidAmount = detailsObj.TotPaymentAmount;
      
      // Ensure the key exists in groupedDetails and push detailsObj
      if (!groupedDetails[key]) {
        groupedDetails[key] = [];
      }
    }
    // Convert groupedDetails object to an array
    const result = Object.values(groupedDetails);
    return result;
  }

  private buildSchemeSummaryItem(detailItems: RASuspenseDSData[]): SchemeSummaryItem[] {
    const summaryTable: SchemeSummaryItem[] = [];
    for (const detailItem of detailItems) {
      const schemeName: string = detailItem.SchemeName;

      let summaryItem: SchemeSummaryItem | undefined = summaryTable.find((item) => item.Scheme === schemeName);
      if (!summaryItem) {
        summaryItem = {
          Scheme: schemeName,
          Total: 0,
          TotalValue: 0,
        };
        summaryTable.push(summaryItem);
      }
      summaryItem.Total += 1;
      summaryItem.TotalValue += detailItem.PaidAmount;
    }

    return summaryTable;
  }

  private convertTimestampToDateTime(val: any): Date {
    if (val) {
      const seconds = val.seconds;
      const nanoseconds = val.nanoseconds;
      const milliseconds = seconds * 1000 + nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date;
    }

    return null;
  }
}
