import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {
  BaseExcelReportData,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME,
  ReversedTransactionsReportRequest
} from "@meraki-flux/schema";
import { ReversedTransactionsXlsReportDataBuilder } from './reversed-transactions-xls-report-data-builder';
import { ReversedTransactionsReportBuilder } from './reversed-transactions-report-builder';
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class ReversedTransactionsReportService {

  async generateReport(reportRequest: ReversedTransactionsReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPaymentCorrectionReportFileName(
      'ReversedTransactions',reportRequest.Practice, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.REVERSED_TRANSACTIONS,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: ReversedTransactionsReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: ReversedTransactionsReportRequest): Promise<BaseExcelReportData> {
    return new ReversedTransactionsXlsReportDataBuilder().build(
      await new ReversedTransactionsReportBuilder().build(reportRequest));
  }
}
