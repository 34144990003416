import { Injectable } from '@angular/core';
import {ReportUtils} from "../report-utils";
import { AssistantReportBuilder } from './assistant-report-builder';
import { AssistantXlsReportDataBuilder } from './assistant-xls-report-data-builder';
import { AssistantPdfReportDataBuilder } from './assistant-pdf-report-data-builder';
import {
  AssistantReportRequest,
  BaseExcelReportData,
  BasePdfReportData,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class AssistantReportService {
  async generateReport(reportRequest: AssistantReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildAssistantsReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.Multibranch, reportRequest.Branch.Name, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.ASSISTANT,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: AssistantReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
     }
    else
    if(REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    }
    else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: AssistantReportRequest): Promise<BasePdfReportData> {
    return new AssistantPdfReportDataBuilder().build(
      await new AssistantReportBuilder().build(reportRequest));
  }

  private async generateXlsReportData(reportRequest: AssistantReportRequest): Promise<BaseExcelReportData> {
     return new AssistantXlsReportDataBuilder().build(
       await new AssistantReportBuilder().build(reportRequest));
  }
}
