<div class="notification-container flex flex-col info" [ngClass]="typeClass">

  <!-- header -->
  <div class="notification-header flex flex-row w-full gap-3 p-2 content-center" [ngClass]="typeClass">
      <mat-icon class="status-color info" [ngClass]="typeClass">{{typeIcon}}</mat-icon>
      <span class="status-color grow items-center font-bold display: grid" [ngClass]="typeClass">{{ data.ReportStatusNotification?.Title }}</span>
      <mat-icon class="status-color info hover:bg-hb-base-base-grey cursor-pointer" [ngClass]="typeClass" (click)="onClose()">close</mat-icon>
  </div>

  <!-- content -->
  <div class="notification-content flex flex-col w-full gap-3 p-2" >
    <div class="flex flex-row w-full">
      <span class="status-color font-bold" [ngClass]="typeClass">{{data?.ReportStatusNotification?.Body}}</span>
    </div>
  </div>

</div>
