import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogService } from '../../services/dialog.service';
import {DialogType} from "@meraki-flux/schema";

export interface FileDetails {
  file: File,
  url: SafeUrl,
  base64: string,
  width: number,
  height: number,
  type: string
}

@Component({
  selector: 'meraki-flux-image-drag-n-drop',
  templateUrl: './image-drag-n-drop.component.html',
  styleUrls: ['./image-drag-n-drop.component.scss']
})

export class ImageDragNDropComponent implements OnInit {
  dragAreaClass: string;
  file: FileDetails;
  @Output() onImageUploaded = new EventEmitter<FileDetails>();
  @Input() width = '100%';
  @Input() height = 'auto';
  @Input() dragAndDropTitle = 'Drag & drop your image here';

  constructor(private sanitizer: DomSanitizer,
              private dialogService: DialogService) {
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    this.saveFiles(files);
  }

  ngOnInit() {
    this.dragAreaClass = 'drag-area';
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'drop-area';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'drop-area';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'drag-area';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'drag-area';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'drag-area';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  saveFiles(files: FileList) {
    if (files.length > 1) {
      this.dialogService.showDialog({
        message: 'Only one file at time is allowed',
        type: DialogType.ERROR
      });
    } else if (!files[0].type.includes('png') && !files[0].type.includes('jpeg')) {
      this.dialogService.showDialog({
        message: 'Accepted file formats: JPEG, PNG',
        type: DialogType.ERROR
      });
    } else {
      this.saveFile(files[0]);
    }
  }

  saveFile(file: File) {
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        this.file = {
          file: file,
          url: url,
          base64: reader.result as string,
          width: img.width,
          height: img.height,
          type: file.type
        };
        this.onImageUploaded.emit(this.file);
      };
      img.src = reader.result as string;
    };
  }

  @Input()
  get value() {
    return this.file?.base64;
  }

  set value(base64: string) {
    if (!base64) {
      this.file = undefined;
      this.onImageUploaded.emit(undefined);
      return;
    }
    const parts = base64?.split(';base64,');
    const imageType = parts[0]?.split(':')[1];
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        this.saveFile(new File([blob], 'image', { type: imageType }));
      });
  }
}
