import {
  BaseExcelReportData,
  CellType,
  CellWidth,
  ExcelCellData,
  GroupedByCodeDetails,
  INVOICE_LINE_TYPE,
  LineDetails,
  LineItemReportRequest,
  REPORT_NAME,
  Sheet,
  SummaryPart1Details,
  Table
} from '@meraki-flux/schema';

export class LineItemXlsReportDataBuilder {
  build(reportRequest: LineItemReportRequest): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.name = REPORT_NAME.LINEITEMBILLED;
    excelReportData.data = [];
    excelReportData.data.push(this.buildSummaryReportSheet(reportRequest));
    excelReportData.data.push(this.buildGroupByCodeReportSheet(reportRequest));
    excelReportData.data.push(this.buildReportSheet(reportRequest));
    this.buildReportInfo(excelReportData, reportRequest);
    return excelReportData;
  }

  private buildReportSheet(reportRequest: LineItemReportRequest) {
    const sheet: Sheet = {};
    sheet.name = 'Line Details';
    sheet.tables = [];

    const detailsTable: Table = {};
    detailsTable.headers = [
      { value: 'Line type', bold: true, width: CellWidth.XS },
      { value: 'Code', bold: true, width: CellWidth.XS },
      { value: 'Description', bold: true, width: CellWidth.S },
      { value: 'Qty', bold: true, width: CellWidth.XS, hAlignment: 'right' },
      { value: 'Amount', bold: true, width: CellWidth.XS, hAlignment: 'right' },
      { value: 'Invoice no.', bold: true, width: CellWidth.XS },
      { value: 'Date of service', bold: true, width: CellWidth.XS },
      { value: 'Scheme', bold: true, width: CellWidth.XS },
      { value: 'Provider', bold: true, width: CellWidth.S }
    ];
    if (reportRequest.Multibranch) {
      detailsTable.headers.push({ value: 'Branch', bold: true, width: CellWidth.S });
    }

    const detailsRows = [];

    reportRequest.LineDetails.forEach((item: LineDetails) => {
      const row: ExcelCellData[] = [];
      row.push({ value: item.LineType });
      row.push({ value: item.Code, type: CellType.GENERAL });
      row.push({ value: item.Description });
      row.push({ value: item.Qty });
      row.push({ value: item.Amount, type: CellType.CURRENCY });
      row.push({ value: item.InvoiceNo });
      row.push({ value: item.DateOfService, type: CellType.GENERAL });
      row.push({ value: item.Scheme });
      row.push({ value: item.Provider });

      if (reportRequest.Multibranch) {
        row.push({ value: item.Branch });
      }

      detailsRows.push(row);
    });

    detailsTable.rows = detailsRows;
    sheet.tables.push(detailsTable);
    return sheet;
  }

  private buildGroupByCodeReportSheet(reportRequest: LineItemReportRequest) {
    const sheet: Sheet = {};
    sheet.name = 'Grouped by Code';
    sheet.tables = [];

    const detailsTable: Table = {};
    detailsTable.headers = [
      { value: 'Line type', bold: true, width: CellWidth.XS },
      { value: 'Code', bold: true, width: CellWidth.XS },
      { value: 'Qty', bold: true, width: CellWidth.XS, hAlignment: 'right' },
      { value: 'Total Amount', bold: true, width: CellWidth.XS, hAlignment: 'right' }
    ];
    const detailsRows = [];

    reportRequest.GroupedByCodeDetails.forEach((item: GroupedByCodeDetails) => {
      const row: ExcelCellData[] = [];
      row.push({ value: item.LineType });
      row.push({ value: item.Code, type: CellType.GENERAL });
      row.push({ value: item.Qty });
      row.push({ value: item.Amount, type: CellType.CURRENCY });

      detailsRows.push(row);
    });

    detailsTable.rows = detailsRows;
    sheet.tables.push(detailsTable);
    return sheet;
  }

  private buildSummaryReportSheet(reportRequest: LineItemReportRequest) {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    sheet.tables = [];

    const summary1Table: Table = {};
    summary1Table.name = 'Amount billed per line type';
    summary1Table.headers = [
      { value: 'Line type', bold: true, width: CellWidth.S },
      { value: 'Amount', bold: true, width: CellWidth.XS, hAlignment: 'right' }
    ];
    const summary1Rows = [];

    let lineTypeTotal = 0;
    reportRequest.SummaryPart1Details.forEach((item: SummaryPart1Details) => {
      const row: ExcelCellData[] = [];
      row.push({ value: item.LineType });
      row.push({ value: item.Amount, type: CellType.CURRENCY });
      lineTypeTotal = lineTypeTotal + item.Amount;
      summary1Rows.push(row);
    });

    const lineTypeTotalRow: ExcelCellData[] = [];
    lineTypeTotalRow.push({ value: 'Total', bold: true });
    lineTypeTotalRow.push({ value: lineTypeTotal, bold: true, type: CellType.CURRENCY });
    summary1Rows.push(lineTypeTotalRow);

    summary1Table.rows = summary1Rows;
    sheet.tables.push(summary1Table);

    //// Summary Part 2

    const lineTypeOrder = [
      INVOICE_LINE_TYPE.PROCEDURE,
      INVOICE_LINE_TYPE.MEDICINE,
      INVOICE_LINE_TYPE.CONSUMABLE,
      INVOICE_LINE_TYPE.MODIFIER,
      INVOICE_LINE_TYPE.ADMIN,
      INVOICE_LINE_TYPE.CSTM_PROCEDURE,
      INVOICE_LINE_TYPE.CSTM_MEDICINE,
      INVOICE_LINE_TYPE.CSTM_CONSUMABLE
    ];

    const summary2Table: Table = {};
    summary2Table.name = 'Amount billed per provider and line type';

    // Retrieve summaryPart2Details before using it
    const summaryPart2Details = reportRequest.SummaryPart2Details;

    // Update table headers in the specified order, removing those without data
    summary2Table.headers = [
      { value: '', bold: true, width: CellWidth.S }, // Provider column
      ...lineTypeOrder
        .filter((lineType) =>
          summaryPart2Details.some((providerDetails) => providerDetails[lineType])
        )
        .map((lineType) => ({ value: lineType, bold: true, width: CellWidth.XS })),
      { value: '', bold: true, width: CellWidth.XS } // Total column
    ];

    // Update column totals to match the new header order
    const columnTotals = new Array(summary2Table.headers.length - 1).fill(0);

    const summary2Rows = [];

    for (const providerDetails of summaryPart2Details) {
      const row: ExcelCellData[] = [];
      row.push({ value: providerDetails.Provider });

      // Add amounts for each line type (using the new header order)
      for (let i = 1; i < summary2Table.headers.length - 1; i++) {
        const lineType = summary2Table.headers[i].value;
        const amount = providerDetails[lineType];
        row.push({ value: amount, type: CellType.CURRENCY });
        columnTotals[i - 1] += Number(amount); // Accumulate column totals
      }

      let providerTotal = 0;
      for (const amount of Object.values(providerDetails).filter(
        (value) => typeof value === 'number'
      )) {
        providerTotal += Number(amount);
      }
      row.push({ value: providerTotal, bold: true, type: CellType.CURRENCY });
      summary2Rows.push(row);
    }

    // Update totals row for the new header order
    const totalsRow: ExcelCellData[] = [
      { value: 'Total', bold: true },
      ...columnTotals.slice(0, -1).map((total) => ({ value: total, bold: true, type: CellType.CURRENCY }))
    ];

    summary2Rows.push(totalsRow);

    summary2Table.rows = summary2Rows;
    sheet.tables.push(summary2Table);

    return sheet;
  }

  private buildReportInfo(
    excelReportData: BaseExcelReportData,
    reportRequest: LineItemReportRequest
  ) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: { value: reportRequest.Practice.PracticeName, type: CellType.GENERAL }
    });

    excelReportData.parameters.push({
      name: 'Billing practice no.',
      data: { value: reportRequest.Practice.BillingPracticeNumber, type: CellType.GENERAL }
    });

    if (reportRequest.Multibranch) {
      excelReportData.parameters.push({
        name: 'Branch',
        data: { value: reportRequest.Branch.Name ?? 'All', type: CellType.GENERAL }
      });
    }

    excelReportData.parameters.push({
      name: 'Scheme',
      data: { value: reportRequest.Scheme, type: CellType.GENERAL }
    });

    excelReportData.parameters.push({
      name: 'Date Range',
      data: { value: reportRequest.DateRange, type: CellType.GENERAL }
    });

    excelReportData.parameters.push({
      name: 'Provider',
      data: { value: reportRequest.TreatingProvider, type: CellType.GENERAL }
    });
  }
}
