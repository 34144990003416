import { QueryConstraint, where } from '@angular/fire/firestore';
import {PAYMENT_STATUS, PAYMENT_TYPE} from "@meraki-flux/schema";

export class PaymentWhereBuilder {
  wheres: QueryConstraint[];

  constructor(wheres: QueryConstraint[]) {
    this.wheres = wheres;
  }

  static builder(): PaymentWhereBuilder {
    return new PaymentWhereBuilder([]);
  }

  build() {
    return this.wheres;
  }

  status(status: PAYMENT_STATUS): PaymentWhereBuilder {
    if (status) this.wheres.push(where('Status', '==', status));
    return this;
  }

  createdAtGOE(date: Date): PaymentWhereBuilder {
    if (date) this.wheres.push(where('CreatedAt', '>=', date));
    return this;
  }

  createdAtLOE(date: Date): PaymentWhereBuilder {
    if (date) this.wheres.push(where('CreatedAt', '<=', date));
    return this;
  }

  type(type: PAYMENT_TYPE): PaymentWhereBuilder {
    if (type) this.wheres.push(where('Type', '==', type));
    return this;
  }

  typeNot(type: PAYMENT_TYPE): PaymentWhereBuilder {
    if (type) this.wheres.push(where('Type', '!=', type));
    return this;
  }

  unallocated(): PaymentWhereBuilder {
    this.wheres.push(where('Allocations', '==', []));
    return this;
  }

  hasUnallocatedAmount(): PaymentWhereBuilder {
    this.wheres.push(where('AmountUnallocated', '>', 0));
    return this;
  }

  invoicePath(invoicePath: string): PaymentWhereBuilder {
    if (invoicePath) this.wheres.push(where('InvoicePaths', 'array-contains', invoicePath));
    return this;
  }
}
