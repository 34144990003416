import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StorageModule } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { NoteModalComponent } from './components/account/note-modal/note-modal.component';
import { AdditionalMedicineDetailsModalComponent } from './components/additional-medicine-details-modal/additional-medicine-details-modal.component';
import { ForgotPasswordComponent } from './components/auth/forgotpassword/forgot-password.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ResetPasswordComponent } from './components/auth/resetpassword/reset-password.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BusinessSearchComponent } from './components/business-search/business-search.component';
import { ColourPickerComponent } from './components/colour-picker/colour-picker.component';
import { ContextualToolbarComponent } from './components/contextual-toolbar/contextual-toolbar.component';
import { AddReasonDialogComponent } from './components/dialog-custom/add-reason-dialog/add-reason-dialog.component';
import { RadioButtonDialogComponent } from './components/dialog-custom/radio-button-dialog/radio-button-dialog.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EditBcLinesComponent } from './components/edit-bc-lines/edit-bc-lines.component';
import { EmailComponent } from './components/email/email.component';
import { FileSelectionComponent } from './components/file-selection/file-selection.component';
import { CarouselSelectorComponent } from './components/carousel-selector/carousel-selector.component';
import { ImageDragNDropComponent } from './components/image-drag-n-drop/image-drag-n-drop.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { InvoiceLinesComponent } from './components/invoice-lines/invoice-lines.component';
import { InvoiceSimpleTemplateComponent } from './components/invoice-simple-template/invoice-simple-template.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { MaPayableInvoiceComponent } from './components/ma-payable-invoice/ma-payable-invoice.component';
import { ClaimStatusNotificationComponent } from './components/notification/claim-status-notification/claim-status-notification.component';
import { SearchDialogComponent } from './components/search-dialog/search-dialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SystemAlertComponent } from './components/system-alert/system-alert.component';
import { CommunicationTemplateEditFormComponent } from './components/template/communication-template-edit-form/communication-template-edit-form.component';
import { CommunicationTemplateFormComponent } from './components/template/communication-template-form.component';
import { CommunicationTemplatePreviewDialogComponent } from './components/template/communication-template-preview-dialog/communication-template-preview-dialog.component';
import { TransferLiabilityComponent } from './components/transfer-liability/transfer-liability.component';
import { AutocompletePositionDirective } from './directives/autocomplete-position.directive';
import { VarDirective } from './directives/ng-var.directive';
import { PaginatorDirective } from './directives/pagination.directive';
import { RelativeTimeDirective } from './directives/relative-time.directive';
import { AppointmentTimePipe } from './pipes/appointment-time.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { ObsWithStatusPipe } from './pipes/obs-with-status.pipe';
import { PatientSearchResultPipe } from './pipes/patient-search-result.pipe';
import { ToHrsMinsPipe } from './pipes/to-hrs-mins.pipe';
import { StatementReportService } from './report/statement-report/statement-report.service';
import { CalendarEventService } from './services/calendar/calendar-event.service';
import { InvoiceCaptureService } from './services/invoice-capture/invoice-capture.service';
import { InvoiceTemplateService } from './services/invoice-template.service';
import { LogService } from './services/log.service';
import { PatientAuthorizationService } from './services/patient-authorization.service';
import { CreditService } from './services/transaction/credit.service';
import { PaymentCorrectionService } from './services/transaction/payment-correction.service';
import { PaymentService } from './services/transaction/payment.service';
import { TransactionService } from './services/transaction/transaction.service';
import { WriteOffService } from './services/transaction/write-off.service';
import { EmphasizeContainerDirective } from './directives/emphasize-container.directive';
import { DebitInvoiceComponent } from './components/debit-invoice/debit-invoice.component';
import { AccountValueTileComponent } from './components/account/account-value-tile/account-value-tile.component';
import { UnmatchedEraItemActionComponent } from './components/unmatched-era-item-action/unmatched-era-item-action.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { NgxMaskModule } from 'ngx-mask';
import {
  ReportStatusNotificationComponent
} from "./components/notification/report-status-notification/report-status-notification.component";
import { InputDialogComponent } from './components/dialog-custom/input-dialog/input-dialog.component';
import { ReferenceDataService } from './services/reference-data.service';
import { MedicalInsurerReportService } from './report/medical-insurer-report/medical-insurer-report.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PrivateRateService } from './services/private-rates/private-rate.service';

@NgModule({
  imports: [
    ColorPickerModule,
    CommonModule,
    EditorModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    RouterModule,
    StorageModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot()
  ],
  declarations: [
    AccessDeniedComponent,
    AdditionalMedicineDetailsModalComponent,
    AddReasonDialogComponent,
    AppointmentTimePipe,
    AutocompletePositionDirective,
    BreadcrumbsComponent,
    BusinessSearchComponent,
    ClaimStatusNotificationComponent,
    ReportStatusNotificationComponent,
    ColourPickerComponent,
    CommunicationTemplateEditFormComponent,
    CommunicationTemplateFormComponent,
    CommunicationTemplatePreviewDialogComponent,
    ContextualToolbarComponent,
    DialogComponent,
    EditBcLinesComponent,
    EmailComponent,
    FileSelectionComponent,
    ForgotPasswordComponent,
    InfoBoxComponent,
    InitialsPipe,
    InvoiceLinesComponent,
    InvoiceSimpleTemplateComponent,
    InvoiceTemplateComponent,
    LoginComponent,
    NoteModalComponent,
    ObsWithStatusPipe,
    PaginatorDirective,
    PatientSearchResultPipe,
    RadioButtonDialogComponent,
    RelativeTimeDirective,
    ResetPasswordComponent,
    SearchDialogComponent,
    SidebarComponent,
    SystemAlertComponent,
    ToHrsMinsPipe,
    VarDirective,
    EmphasizeContainerDirective,
    AutocompletePositionDirective,
    InvoiceLinesComponent,
    AdditionalMedicineDetailsModalComponent,
    EditBcLinesComponent,
    InvoiceSimpleTemplateComponent,
    MaPayableInvoiceComponent,
    TransferLiabilityComponent,
    AccountValueTileComponent,
    DebitInvoiceComponent,
    UnmatchedEraItemActionComponent,
    ModifierComponent,
    InputDialogComponent,
    CarouselSelectorComponent,
    ImageDragNDropComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    CalendarEventService,
    CreditService,
    InvoiceCaptureService,
    InvoiceTemplateService,
    LogService,
    PatientAuthorizationService,
    PaymentCorrectionService,
    PaymentService,
    StatementReportService,
    TransactionService,
    WriteOffService,
    ReferenceDataService,
    MedicalInsurerReportService,
    PrivateRateService
  ],
  exports: [
    AccessDeniedComponent,
    AdditionalMedicineDetailsModalComponent,
    AddReasonDialogComponent,
    AppointmentTimePipe,
    AutocompletePositionDirective,
    BreadcrumbsComponent,
    BusinessSearchComponent,
    ClaimStatusNotificationComponent,
    ColourPickerComponent,
    CommunicationTemplateEditFormComponent,
    CommunicationTemplateFormComponent,
    CommunicationTemplatePreviewDialogComponent,
    ContextualToolbarComponent,
    DialogComponent,
    EditBcLinesComponent,
    InfoBoxComponent,
    InitialsPipe,
    InvoiceLinesComponent,
    InvoiceSimpleTemplateComponent,
    InvoiceTemplateComponent,
    NoteModalComponent,
    ObsWithStatusPipe,
    PaginatorDirective,
    PatientSearchResultPipe,
    RadioButtonDialogComponent,
    RelativeTimeDirective,
    SearchDialogComponent,
    SidebarComponent,
    SystemAlertComponent,
    ToHrsMinsPipe,
    VarDirective,
    EmphasizeContainerDirective,
    AutocompletePositionDirective,
    InvoiceLinesComponent,
    AdditionalMedicineDetailsModalComponent,
    EditBcLinesComponent,
    MaPayableInvoiceComponent,
    TransferLiabilityComponent,
    DebitInvoiceComponent,
    AccountValueTileComponent,
    UnmatchedEraItemActionComponent,
    InputDialogComponent,
    CarouselSelectorComponent,
    ImageDragNDropComponent
  ]
})
export class FluxCommonModule {}
