import { Injectable } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { InvoiceService } from '../../services/invoice/invoice.service';
import {
  AgeAnalysisMedicalAidLiableReportModel,
  AgeAnalysisReportRequest,
  AggregatedInvoiceAge,
  AppointmentReportInfo, eRASchemes,
  Invoice,
  INVOICE_SUBTYPE, INVOICE_TYPE,
  MedicalAidLiableInvoiceDetails,
  MedicalAidLiableSummary,
  NoDataError, Practice, PracticeProvider, SummaryData,
  VisitInfo
} from '@meraki-flux/schema';
import { BaseAgeAnalysisReportBuilder } from './base-age-analysis-report-builder';

@Injectable({
  providedIn: 'root'
})
export class AgeAnalysisMedicalAidLiableReportBuilder extends BaseAgeAnalysisReportBuilder {

  constructor(firestore: Firestore, invoiceService: InvoiceService) {
    super(firestore, invoiceService);
  }

  async build(reportRequest: AgeAnalysisReportRequest): Promise<AgeAnalysisMedicalAidLiableReportModel> {
    const reportHeader: AppointmentReportInfo = this.buildReportInfo(reportRequest);

    const invoices: Invoice[] = await this.getInvoices(reportRequest);
    if (invoices.length == 0) {
      throw new NoDataError();
    }

    const visitInfoMap = await this.buildVisitInfoMap(reportRequest.Practice?.Id,
      invoices.map(i => i.LinkedAppointment).filter(i => i));

    const providers = await this.loadProviders(reportRequest.Practice.BillingPracticeNumber)
    const invoiceDetails: MedicalAidLiableInvoiceDetails[] = await this.buildInvoiceDetails(providers, invoices, visitInfoMap);
    const summary: MedicalAidLiableSummary = this.buildSummary(providers, invoices);

    return {
      reportInfo: reportHeader,
      invoiceDetails: invoiceDetails,
      summary: summary
    };
  }

  private async buildInvoiceDetails(providers: PracticeProvider[], invoices: Invoice[], visitInfoMap: Map<string, VisitInfo>): Promise<MedicalAidLiableInvoiceDetails[]> {
    const invoiceDetails: MedicalAidLiableInvoiceDetails[] = [];
    for (const invoice of invoices) {
      const visitInfo: VisitInfo = visitInfoMap.get(invoice.Id);
      const isInsuranceInsurance = this.isMedicalInsuranceInvoice(invoice);
      invoiceDetails.push({
        age: new AggregatedInvoiceAge().setValue(invoice.InvoiceDate, invoice.Balance?.MedicalAidLiable),
        branch: invoice.Branch,
        treatingProviderId: invoice.TreatingProvider?.Id,
        treatingProvider: this.getProviderName(providers, invoice)??invoice.TreatingProvider?.FullName,
        scheme: isInsuranceInsurance ? invoice.MedicalInsurer?.Name || '' : invoice.Account?.SchemeName || '',
        planOption: `${invoice.Account?.PlanName} ${invoice.Account?.OptionName}`,
        memberNo: isInsuranceInsurance ? invoice.PolicyNo || '' : invoice.Account?.MemberNo || '',
        depCode: invoice.Patient?.DependantCode,
        dateOfService: invoice.DateOfService,
        invoiceDate: invoice.InvoiceDate,
        invoiceType: isInsuranceInsurance ? INVOICE_SUBTYPE.MEDICAL_INSURER : invoice.Type,
        invoiceNo: invoice.InvoiceNo,
        accountNo: invoice.Account?.AccountNo,
        patientName: `${invoice.Patient.Surname}, ${invoice.Patient.Name}`,
        dob: invoice.Patient?.DateOfBirth,
        claimStatus: invoice.ClaimInfo?.ClaimStatus || '',
        hbMessageId: invoice.ClaimInfo?.HBMessageId || '',
        invoicedAmount: invoice.Balance?.Outstanding,
        medicalAidLiable: invoice.Balance?.MedicalAidLiable,
        patientLiable: invoice.Balance?.PatientLiable,
        bc: visitInfo?.BenefitCheckMessageId ? 'Y' : 'N',
        bcStatus: visitInfo?.BenefitCheckStatus || '',
        eRAEnabled: eRASchemes.indexOf(invoice.Account?.SchemeCode) >= 0 ? 'Y' : 'N',
        location: invoice.Location
      });
    }
    return invoiceDetails;
  }

  private async getInvoices(reportRequest: AgeAnalysisReportRequest) {
    const invoices = await this.loadInvoices(reportRequest);
    return invoices.filter(invoice => invoice.Balance?.MedicalAidLiable > 0);
  }

  private buildSummary(providers: PracticeProvider[], invoices: Invoice[]): MedicalAidLiableSummary {

    const medicalAidProviderData: SummaryData = new SummaryData();
    const insuranceProviderData: SummaryData = new SummaryData();
    const schemeData: SummaryData = new SummaryData();
    const medicalInsurerData: SummaryData = new SummaryData();
    const eraSchemeNames = [];

    for (const invoice of invoices) {
      const invoiceAge: AggregatedInvoiceAge =
        new AggregatedInvoiceAge().setValue(invoice.InvoiceDate, invoice.Balance?.MedicalAidLiable)
      if (this.isMedicalInsuranceInvoice(invoice)) {
        insuranceProviderData.addData(this.getProviderName(providers, invoice)??invoice.TreatingProvider?.FullName, invoiceAge);
        medicalInsurerData.addData(invoice.MedicalInsurer?.Name, invoiceAge);
      }
      if (invoice.Type === INVOICE_TYPE.MEDICAL_AID) {
        medicalAidProviderData.addData(this.getProviderName(providers, invoice)??invoice.TreatingProvider?.FullName, invoiceAge);
        schemeData.addData(invoice.Account?.SchemeName, invoiceAge);
        if (eRASchemes.includes(invoice.Account?.SchemeCode)) {
          eraSchemeNames.push(invoice.Account?.SchemeName);
        }
      }
    }
    return {
      medicalAidProviderData: medicalAidProviderData, insuranceProviderData: insuranceProviderData,
      schemeData: schemeData, insurerData: medicalInsurerData, eraSchemeNames: eraSchemeNames
    };
  }

  private isMedicalInsuranceInvoice(invoice: Invoice) {
    return invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER;
  }
}
