import moment from "moment";
import {
  BulkSMS,
  BulkSmsAddOnTransactionItem, BulkSmsSummaryItem, MAX_SMS_BODY_LENGTH,
  NoDataError, SMS_CATEGORY,
  SmsDetailsItem,
  SmsProviderModel,
  SmsReportInfo,
  SmsReportModel,
  SmsReportRequest, SmsRequest, SmsStatusSummaryItem, SmsSummaryItem
} from "@meraki-flux/schema";

export class SmsReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    async build(reportRequest: SmsReportRequest): Promise<SmsReportModel> {
        const reportHeader: SmsReportInfo = await this.buildReportInfo(reportRequest);
        const providersModel: SmsProviderModel = await this.buildProvidersModel(reportRequest);

        this.checkDataAvailability(providersModel);

        return {
            SmsReportInfo: reportHeader,
            SmsProviderModel: providersModel,
            ReportDate: moment(new Date()).format(this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(providersModel: SmsProviderModel) {
        let noData = true;
        if (providersModel.SmsDetailsItem.length > 0) {
            noData = false;
        }

        if (noData)
            throw new NoDataError();
    }

    private async buildReportInfo(reportRequest: SmsReportRequest): Promise<SmsReportInfo> {
        const reportInfo: SmsReportInfo = {};

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;

        if (reportRequest.Practice?.IsMultiBranch === true)
            reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

        reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
        reportInfo.DateFrom = moment(reportRequest.DateFrom).format(this.DATE_FORMAT);
        reportInfo.DateTo = moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.DateRange = moment(reportRequest.DateFrom).format(this.DATE_FORMAT) + ' - ' + moment(reportRequest.DateTo).format(this.DATE_FORMAT);
        reportInfo.SMSBalance = reportRequest.BulkSmsAddon?.SMSBalance;
        reportInfo.AddonActivated = reportRequest.BulkSmsAddon?.Activated;


        return reportInfo;
    }

    private async buildProvidersModel(reportRequest: SmsReportRequest): Promise<SmsProviderModel> {

        const smsDetailsItem: SmsDetailsItem[] = this.buildSmsDetails(reportRequest.SmsList);
        const bulkSmsAddOnTransactionItem: BulkSmsAddOnTransactionItem[] = this.buildBulkSmsAddonTransaction(reportRequest.BulkSmsAddon);;
        const smsSummaryItem: SmsSummaryItem[] = this.buildSmsSummaryItem(reportRequest.SmsList, reportRequest);
        const bulkSmsSummaryItem: BulkSmsSummaryItem[] = this.buildBulkSmsSummaryItem(reportRequest);
        const smsStatusSummaryItem: SmsStatusSummaryItem[] = this.buildSmsStatusSummaryItem(reportRequest.SmsList);;

        return {
            SmsDetailsItem: smsDetailsItem,
            BulkSmsAddOnTransactionItem: bulkSmsAddOnTransactionItem,
            SmsSummaryItem: smsSummaryItem,
            BulkSmsSummaryItem: bulkSmsSummaryItem,
            SmsStatusSummaryItem: smsStatusSummaryItem,
        } ;
    }

    private buildSmsDetails(smsList: SmsRequest[]): SmsDetailsItem[] {

        const smsDetailtems: SmsDetailsItem[] = [];

        for(const sms of smsList){
            const smsDetailsObj: SmsDetailsItem = {};

            smsDetailsObj.DateSent = sms.CreatedAt;
            smsDetailsObj.Category = sms.Category;
            smsDetailsObj.SMSType = sms.Type;
            smsDetailsObj.RecipientName = sms.RecipientName;
            smsDetailsObj.RecipientCellphone =  sms.To;
            smsDetailsObj.RecipientAccountNo =  sms.RecipientAccountNo;
            smsDetailsObj.SentBy = sms.CreatedBy;
            smsDetailsObj.Status = sms.Status;
            smsDetailsObj.StatusDescription = '';
            smsDetailsObj.NoOfMessageParts = this.getNoOfSentSms(sms.Body);

            smsDetailtems.push(smsDetailsObj)

        }

        return smsDetailtems;
    }

    private buildBulkSmsAddonTransaction(bulkSms: BulkSMS): BulkSmsAddOnTransactionItem[] {

        const bulkSmsAddOnTransactionItem: BulkSmsAddOnTransactionItem[] = [];

        if(bulkSms){
            for(const audit of bulkSms.Audit){
                const bulkSmsAddonObj: BulkSmsAddOnTransactionItem = {};

                bulkSmsAddonObj.TransactionType = audit.TransactionType;
                bulkSmsAddonObj.UserName = audit.User;
                bulkSmsAddonObj.DateTime = audit.Time;
                bulkSmsAddonObj.NoOfSms = audit.NumberOfSMSsBought;
                bulkSmsAddonObj.Price =  audit.BundlePrice;

                bulkSmsAddOnTransactionItem.push(bulkSmsAddonObj);
            }
        }

        return bulkSmsAddOnTransactionItem;
    }

    private buildSmsSummaryItem(smsList: SmsRequest[], reportRequest: SmsReportRequest): SmsSummaryItem[] {

        let smsSummaryItems: SmsSummaryItem[] =[];
        const freeSmsList: SmsRequest[] = smsList.filter(sms=> sms.Category === SMS_CATEGORY.FREE_SMS);
        const bulkSmsList: SmsRequest[] = smsList.filter(sms=> sms.Category === SMS_CATEGORY.BULK_SMS_ADDON);

        //Free sms summary
        const freeSmsSummary: SmsSummaryItem[]  = freeSmsList.reduce((acc, curr) => {

            const smsTypeIndex = acc.findIndex(item => item.SmsType === curr.Type);
            if (smsTypeIndex === -1) {
            acc.push({
                SmsType: curr.Type,
                Category: curr.Category,
                NoOfSent: this.getNoOfSentSms(curr.Body)
            } as SmsSummaryItem);
            } else {
                acc[smsTypeIndex].NoOfSent = (acc[smsTypeIndex].NoOfSent || 0) + this.getNoOfSentSms(curr.Body);
            }

            return acc;
        }, []);

        smsSummaryItems = smsSummaryItems.concat(freeSmsSummary);
        reportRequest.freeSMSType.forEach((type) => {
          if (!smsSummaryItems.some((summary) => summary.SmsType === type)) {
            smsSummaryItems.push({ SmsType: type, Category: SMS_CATEGORY.FREE_SMS, NoOfSent: 0 });
          }
        });


        //bulk sms summary
        const bulkSmsSummary: SmsSummaryItem[]  = bulkSmsList.reduce((acc, curr) => {

            const smsTypeIndex = acc.findIndex(item => item.SmsType === curr.Type);
            if (smsTypeIndex === -1) {
            acc.push({
                SmsType: curr.Type,
                Category: curr.Category,
                NoOfSent: this.getNoOfSentSms(curr.Body)
            });
            } else {
                acc[smsTypeIndex].NoOfSent = (acc[smsTypeIndex].NoOfSent || 0) + this.getNoOfSentSms(curr.Body);
            }

            return acc;
        }, []);

        smsSummaryItems = smsSummaryItems.concat(bulkSmsSummary);
        reportRequest.bulkSMSType.forEach((type) => {
          if (!smsSummaryItems.some((summary) => summary.SmsType === type)) {
            smsSummaryItems.push({ SmsType: type, Category: SMS_CATEGORY.BULK_SMS_ADDON, NoOfSent: 0 });
          }
        });

        return smsSummaryItems;
    }

    private buildBulkSmsSummaryItem(reportRequest: SmsReportRequest): BulkSmsSummaryItem[] {

        const bulkSmsDetailtems: BulkSmsSummaryItem[] = [];

        for(const bulkSms of reportRequest.BulkSmsList){
            const bulkSmsDetailsObj: BulkSmsSummaryItem = {};

            bulkSmsDetailsObj.Date = bulkSms.Date;
            bulkSmsDetailsObj.NoOfSent = bulkSms.TotalMessages;
            bulkSmsDetailsObj.SentBy = bulkSms.User;
            bulkSmsDetailsObj.Branch =  bulkSms.RequestData?.Branch;
            bulkSmsDetailsObj.Message =  reportRequest.SmsTemplateMessage;

            bulkSmsDetailtems.push(bulkSmsDetailsObj)

        }

        return bulkSmsDetailtems;
    }

    private buildSmsStatusSummaryItem(smsList: SmsRequest[]): SmsStatusSummaryItem[] {

        const freeSmsSummary: SmsStatusSummaryItem[]  = smsList.reduce((acc, curr) => {

            const smsTypeIndex = acc.findIndex(item => item.Status === curr.Status);
            if (smsTypeIndex === -1) {
            acc.push({
                Status: curr.Status,
                FreeSms: curr.Category == SMS_CATEGORY.FREE_SMS? this.getNoOfSentSms(curr.Body): 0,
                BulkSmsAddOn:  curr.Category == SMS_CATEGORY.BULK_SMS_ADDON? this.getNoOfSentSms(curr.Body): 0
            });
            } else {
                if(curr.Category == SMS_CATEGORY.FREE_SMS)
                    acc[smsTypeIndex].FreeSms = (acc[smsTypeIndex].FreeSms || 0) + this.getNoOfSentSms(curr.Body);
                else if(curr.Category == SMS_CATEGORY.BULK_SMS_ADDON)
                    acc[smsTypeIndex].BulkSmsAddOn = (acc[smsTypeIndex].BulkSmsAddOn || 0) + this.getNoOfSentSms(curr.Body);
            }

            return acc;
        }, []);

        return freeSmsSummary;
    }

    private getNoOfSentSms(body: string): number{
        let noOfMessageParts = 1;
        if(body.length > MAX_SMS_BODY_LENGTH){
            noOfMessageParts = Math.ceil(body.length / MAX_SMS_BODY_LENGTH);
        }
        return noOfMessageParts
    }


}
