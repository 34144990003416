<div class="flex flex-col w-full">
  <!-- Diagnosis search row -->
  <div class="flex flex-row align-middle items-center justify-start gap-2 flex-wrap" [formGroup]="diagnosisSearchFormGroup">

    <mat-form-field id="inheritHeight" class="flux-form-field flex-1" appearance="fill">
      <mat-icon matTooltip="Search on code or description" matPrefix>search</mat-icon>
      <mat-label>Search for a diagnosis</mat-label>
      <mat-chip-list id='diagnosisChips' #selectedDiagnoses aria-label="Selected diagnoses">
        <mat-chip *ngFor="let diagnosis of headerDiagnosesForm.value" (removed)="onHeaderDiagnosisRemoved(diagnosis)"
          [matTooltip]="diagnosis.description">
          {{diagnosis.code}}
          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
        <input #headerDiag id='diagnosisSearchInput' placeholder="Search diagnosis..." formControlName='headerDiagnosis'
          [matAutocomplete]="diagnosisAuto" [matChipInputFor]="selectedDiagnoses" tabindex="0">
      </mat-chip-list>

      <!-- using a stop propagation and then a focus instead of fall-through focus to prevent focussing the first chip instead of the input -->
      <button *ngIf="headerDiag.value" mat-icon-button matSuffix
        (click)="$event.stopPropagation(); clearDiagnosisSearch(); headerDiag.focus()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete class="flux-search-results" #diagnosisAuto="matAutocomplete" (closed)="onHeaderDiagnosisSearchClosed()"
        (optionSelected)="onHeaderDiagnosisSelected($event)" autoActiveFirstOption>
        <ng-container *ngVar="singleDiagnosisSelected$ | async as singleSelected">
          <ng-container *ngVar="diagnosisSearchBusy$ | async as searchBusy">
            <mat-option *ngIf="searchBusy" disabled>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-option>
            <mat-option *ngIf="!searchBusy && !singleSelected && (diagnosisSearchResult$ | async)?.length === 0"
              disabled>
              No results
            </mat-option>
            <ng-container *ngIf="!searchBusy">
              <mat-option *ngFor="let result of diagnosisSearchResult$ | async" [value]="result">
                <span class="text-xs"
                  [innerHtml]="result.highlightedCode + ' - ' + result.highlightedDescription"></span>
              </mat-option>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>


    <div class="flex flex-row gap-4 ml-4" *ngIf="type === 'invoice'">

      <button id='searchButton' class="flux-button search-button shrink-0" mat-stroked-button (click)="openDiagnosisSearch(null)" tabindex="-1">
        <mat-icon class="!text-hb-secondary-100">search</mat-icon>
      </button>

    <mat-checkbox id='applyToAllLines' class="flux-checkbox-secondary leading-10 shrink-0 mx-1.5" formControlName="applyToAllLines" tabIndex="-1">Apply to all lines
    </mat-checkbox>

    <button mat-stroked-button class="!flux-button shrink-0 !px-4 !text-sm !h-9 !tracking-wide" (click)="refreshPrices()" tabindex="-1">
      <mat-icon inline class="!text-hb-secondary-100 !text-xl !mr-2" >refresh</mat-icon>Refresh prices
    </button>

  </div>

  <!-- Invoice lines table -->
  <div class="flex flex-col w-full overflow-x-auto mt-3.5">
    <mat-table id='linesTable' class="invoice-lines-table flux-table" [dataSource]="invoiceLinesSource" #invoiceTable dir="ltr">
      <!-- add/remove column -->
      <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <button id='rowAddLine' class="!w-6 !h-6 !leading-6" mat-icon-button (click)="addLine(i + 1, null, true)" tabindex="-1">
            <mat-icon class="!text-[20px]">add</mat-icon>
          </button>
          <button id='rowRemoveLine' class="!w-6 !h-6 !leading-6" mat-icon-button (click)="removeLine(i)" tabindex="-1">
            <mat-icon class="!text-[20px]">remove</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Line type column -->
      <ng-container matColumnDef="lineType">
        <mat-header-cell *matHeaderCellDef> Line type </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field appearance="outline" class="table-field">
            <mat-select id='rowLineType' *ngIf="row.form" formControlName='LineType' #focusInput>
              <mat-option *ngFor="let type of availableLineTypes$ | async" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- TariffCode column -->
      <ng-container matColumnDef="tariffCode">
        <mat-header-cell *matHeaderCellDef> Tariff code </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field
            *ngIf="row.form &&
            (row.form.get('LineType').value === lineTypes.MEDICINE || row.form.get('LineType').value === lineTypes.CSTM_MEDICINE)"
            appearance="outline" class="table-field">
            <mat-select id='rowTariffCodeSelect' formControlName="TariffCode">
              <mat-option *ngFor="let code of medicineTariffCodes$ | async | keyvalue" [value]="code.key">
                {{code.key}} - {{code.value.Description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="row.form &&
          (row.form.get('LineType').value === lineTypes.CONSUMABLE || row.form.get('LineType').value === lineTypes.CSTM_CONSUMABLE)"
            appearance="outline" class="table-field">
            <mat-select id='rowTariffCodeSelect' formControlName="TariffCode">
              <mat-option *ngFor="let code of consumableTariffCodes$ | async | keyvalue" [value]="code.key">
                {{code.key}} - {{code.value.Description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="row.form &&
            (row.form.get('LineType').value !== lineTypes.CONSUMABLE &&
            row.form.get('LineType').value !== lineTypes.CSTM_CONSUMABLE &&
            row.form.get('LineType').value !== lineTypes.MEDICINE &&
            row.form.get('LineType').value !== lineTypes.CSTM_MEDICINE)" appearance="outline"
            class="table-field">
            <input id='rowTariffCodeInput' matInput [matAutocomplete]="tariffCodeSearchAutoComplete"
              formControlName='TariffCode' (click)='activateRow(row)'>
          </mat-form-field>

          <mat-autocomplete class="flux-search-results" #tariffCodeSearchAutoComplete="matAutocomplete" (closed)="onTariffCodeSearchClosed()"
            (optionSelected)="onTariffCodeSelected($event, row.form)" panelWidth="50%"  autoActiveFirstOption="false" autoSelectActiveOption="false">
            <ng-container *ngVar="singleTariffSelected$ | async as singleSelected">
              <ng-container *ngVar="tariffCodeSearchBusy$ | async as searchBusy;">
                <mat-option *ngIf="searchBusy" disabled>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-option>
                <mat-option *ngIf="!searchBusy && !singleSelected && (tariffCodeSearchResult$ | async)?.length === 0"
                  disabled>
                  No results
                </mat-option>
                <ng-container *ngIf="!searchBusy">
                  <mat-option *ngFor="let result of tariffCodeSearchResult$ | async" [value]="result">
                    <div class="text-xs" [innerHTML]="result.DisplayString"></div>
                  </mat-option>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-autocomplete>

        </mat-cell>
      </ng-container>

      <!-- NappiCode column -->
      <ng-container matColumnDef="nappiCode">
        <mat-header-cell *matHeaderCellDef> Nappi code </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field appearance="outline" class="table-field">
            <input id='rowNappiCodeInput' [matAutocomplete]="nappiSearchAutoComplete" matInput
              formControlName='NappiCode' (click)='activateRow(row)'>
          </mat-form-field>

          <mat-autocomplete class="flux-search-results" #nappiSearchAutoComplete="matAutocomplete" (closed)="onNappiCodeSearchClosed(row.form)"
            (optionSelected)="onNappiCodeSelected($event, row.form)" panelWidth="40%"  autoActiveFirstOption="false" autoSelectActiveOption="false">
            <ng-container *ngVar="singleNappiSelected$ | async as singleSelected">
              <ng-container *ngVar="nappiCodeSearchBusy$ | async as searchBusy">
                <mat-option *ngIf="searchBusy" disabled>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-option>
                <mat-option *ngIf="!searchBusy && !singleSelected && (nappiCodeSearchResult$ | async)?.length === 0"
                  disabled>
                  No results
                </mat-option>
                <ng-container *ngIf="!searchBusy">
                  <mat-option *ngFor="let result of nappiCodeSearchResult$ | async" [value]="result">
                    <div class="text-xs" [innerHtml]="result.DisplayString"></div>
                  </mat-option>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-autocomplete>
        </mat-cell>
      </ng-container>

      <!-- Description column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field appearance="outline" class="table-field">
            <input matInput formControlName='Description' [matTooltip]="row.form.get('Description')?.value">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- DiagnosisCode column -->
      <ng-container matColumnDef="diagnosisCode">
        <mat-header-cell *matHeaderCellDef> Diag. code </mat-header-cell>
        <mat-cell *matCellDef="let row" #diagCell>
          <div class="w-full h-full cursor-pointer" matRipple (click)="openDiagnosisSearch(row)">
            <mat-form-field appearance="outline" class="table-field !-z-10">
              <input id='rowDiagnosisCode' matInput [disabled]="true"
                [value]="getDiagnosisCodesDisplay(row.form.get('DiagnosisCodes').value)" />
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Quantity column -->
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef> Qty </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field appearance="outline" class="table-field">
            <input #qtyField class="text-right" type="number" matInput formControlName='Quantity'
              (click)="qtyField.select()">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- Price columm -->
      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row.form">
          <mat-form-field appearance="outline" class="table-field">
            <input #priceField [disabled]="!!row.form.get('PriceBusy')?.value" class="font-semibold" matInput
              type="text" formControlName='AmountBilled' currencyMask
              [options]="{ prefix: 'R ', thousands: ',', decimal: '.' }" (click)="priceField.select()" (keydown.Tab)="tabFocusChange()">
            <mat-icon class="!text-hb-critical-100 cursor-pointer" matPrefix fontSet="material-icons-outlined"
              *ngIf="!!row.form.get('PriceError')?.value" [matTooltip]="row.form.get('PriceError')?.value">
              report_problem
            </mat-icon>
            <mat-spinner class="mb-0.5" *ngIf="!!row.form.get('PriceBusy')?.value" matPrefix mode="indeterminate" diameter="16">
            </mat-spinner>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- Info column -->
      <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" class="!justify-center !p-0">
          <button
            *ngIf="row.form && (row.form.get('LineType').value === lineTypes.MEDICINE || row.form.get('LineType').value === lineTypes.CSTM_MEDICINE)"
            id="infoButton" class="!w-6 !h-6 !leading-6" mat-icon-button (click)="onInfoClick(row.form)">
            <mat-icon class="!text-hb-accent-100 !text-lg !leading-5 !h-5" fontSet="material-icons-outlined"
              [matTooltip]="'Med. Type: ' + row.form.get('MedicineType').value">open_in_new</mat-icon>
          </button>
          <button
            *ngIf="this.showEditModifierButton(row)"
            id="infoModifierButton" class="!w-6 !h-6 !leading-6" mat-icon-button (click)="onInfoModifierClick(row.form)">
            <mat-icon class="!text-hb-accent-100 !text-lg !leading-5 !h-5" fontSet="material-icons-outlined"
              [matTooltip]="'Open modifier window'">open_in_new</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

    </mat-table>

    <button id='addLine' (click)="addLine(invoiceLinesSource?.data?.length, null, true)" mat-button
      class="!border-dashed !border-[1px] !border-hb-base-divider-grey text-center !py-1 !mb-2 !mt-2 !w-full" tabindex="0">
      <div class="flex flex-row justify-center align-middle">
        <mat-icon class="mr-2 !text-hb-secondary-100 text-center">add</mat-icon><span
          class="text-sm leading-6 font-medium text-hb-secondary-100 text-center">Add a line</span>
      </div>
    </button>
  </div>

  <!-- Total line -->
  <div class="flex flex-row w-full justify-end" *ngIf="type !== 'BCTemplates'">
    <div class="flex flex-row gap-24 justify-between border-t border-b py-4 pr-14">
      <span class="font-medium">Total: </span>
      <span class="font-medium" *ngVar="total$ | async as total">{{total |
        currency:'ZAR':'symbol-narrow':'1.2-2'}}</span>
    </div>
  </div>
</div>

<mat-autocomplete #diagAutoComplete="matAutocomplete">
  <mat-option *ngFor="let option of tableColumns" [value]="option">
    {{option}}
  </mat-option>
</mat-autocomplete>
