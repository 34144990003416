export interface SmsRequest {
  To: string;
  Body: string;
  Type: SMS_TYPE,
  Status: SMS_STATUS;
  SendAt?: number; // Epoch in seconds UTC
  MessageId?: string;
  ErrorMessage?: string;
  RecipientName?: string;
  AccountId?: string;
  PracticeId?: string;
  CalendarEventId?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
  UpdatedAt?: Date;
  UpdatedBy?: string;
  Category?:string;
  RecipientAccountNo?:string;
  BulkMessageId?: string;
}

export enum SMS_STATUS {
  NEW = 'New',
  SENT = 'Sent',
  FAILED = 'Failed',
  DRAFT = 'Draft'
}

export enum SMS_TYPE {
  OUTSTANDING_BALANCE_FIRST_NOTIFICATION = '1st notification of balance outstanding',
  OUTSTANDING_BALANCE_REMINDER = 'Reminder of balance outstanding',
  AD_HOC = 'Ad hoc',
  THANK_YOU = 'Thank you',
  WELCOME = 'Welcome',
  PAYMENT_CONFIRMATION = 'Payment Confirmation',
  BIRTHDAY = 'Birthday',
  APPOINTMENT_CONFIRMATION = 'Appointment Confirmation',
  APPOINTMENT_REMINDER = 'Appointment Reminder',
  APPOINTMENT_REMINDER_FIVE_MINUTE = '5-minute Appointment Reminder',
  BULK_STATEMENT = 'Bulk Statement',
  BULK_SMS = "Bulk sms",
  BULK = 'Bulk',
  BULK_EMAIL_SMS = 'Bulk emails or sms',
  KAHUN = "Kahun",
  PURCHASE = 'Purchase',
}

export const MAX_SMS_BODY_LENGTH = 160;

export enum SMS_CATEGORY{
  FREE_SMS = 'Free SMS',
  BULK_SMS_ADDON = 'Bulk SMS add-on'
}
