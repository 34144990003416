import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SystemAccessHelperService } from '../../services/system-access-helper.service';
import { MoneyUtils } from '../../utils/money-utils';
import { TransferLiabilityComponent } from '../transfer-liability/transfer-liability.component';
import { DebitInvoiceDialog } from './debit-invoice.dialog';
import {
  DebitPayableInvoice,
  Invoice, INVOICE_SUBTYPE, INVOICE_TYPE,
  InvoiceLine,
  InvoicePatient, LIABILITY_TYPE,
  PayableInvoice,
  PayableInvoiceLine, SYSTEM_ACCESS_CODE
} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class DebitInvoiceHelper {
  constructor(
    private systemAccessHelperService: SystemAccessHelperService,
    private matDialog: MatDialog,
    private dialog: DebitInvoiceDialog
  ) {}

  buildDebitPayableInvoice(invoice: Invoice) {
    const payableInvoiceDebit = {
      ...invoice,
      Lines: invoice.Lines.map((line) => this.buildPayableInvoiceLine(line)),
    } as DebitPayableInvoice;
    return payableInvoiceDebit;
  }

  buildPayableInvoiceLine(invoiceLine: InvoiceLine) {
    return {
      ...invoiceLine,
      _AmountAllocatedCtrl: new UntypedFormControl(null, Validators.min(0)),
      _BalanceOutstanding: invoiceLine?.Balance?.Outstanding,
    } as PayableInvoiceLine;
  }

  resetLineDebitAmountToZero(invoiceLine: PayableInvoiceLine) {
    invoiceLine?._AmountAllocatedCtrl?.setValue(0);
  }

  resetInvoiceDebitAmountToZero(invoice: PayableInvoice) {
    invoice?.Lines?.forEach((line) => this.resetLineDebitAmountToZero(line));
  }

  resetInvoicesDebitAmountToZero(invoices: PayableInvoice[]) {
    invoices?.forEach((i) => this.resetInvoiceDebitAmountToZero(i));
  }

  onChangeLineDebitedAmount(
    allocatedAmountRands: number,
    line: PayableInvoiceLine,
    invoice: PayableInvoice
  ) {
    if (!line || !invoice) return;
    const amountAllocated = MoneyUtils.toCents(allocatedAmountRands || 0);
    const initialBalanceOutstanding = line?.Balance?.Outstanding || 0;
    line._BalanceOutstanding = initialBalanceOutstanding - amountAllocated;
    // refresh totals
    invoice._AmountAllocated = invoice?.Lines?.reduce((acc, curr: PayableInvoiceLine) => {
      const lineAllocated = MoneyUtils.toCents(curr?._AmountAllocatedCtrl?.value || 0);
      return acc + lineAllocated;
    }, 0);
    invoice._BalanceOutstanding = invoice?.Lines?.reduce(
      (acc, curr: PayableInvoiceLine) => acc + (curr?._BalanceOutstanding || 0),
      0
    );
  }

  // dup??
  patientNameSurnameDepCode(invoiceLineIndex: number, patient: InvoicePatient) {
    if (invoiceLineIndex !== 0) return '';
    return patient?.DependantCode
      ? `${patient?.Name} ${patient?.Surname} (${patient?.DependantCode})`
      : `${patient?.Name} ${patient?.Surname}`;
  }

  // dup??
  invoiceDescription(invoiceLineIndex: number, invoice: Invoice) {
    if (invoiceLineIndex !== 0) return '';
    if (invoice.Type === INVOICE_TYPE.CASH) {
      return 'Cash';
    } else if (invoice.Type === INVOICE_TYPE.MEDICAL_AID) {
      return invoice.Subtype === INVOICE_SUBTYPE.MEDICAL_INSURER
        ? `Insurance - ${invoice?.ClaimInfo?.ClaimStatus}`
        : invoice?.ClaimInfo?.ClaimStatus;
    } else {
      return undefined;
    }
  }

  // dup??
  showTransferLiabilities() {
    return this.systemAccessHelperService.hasAccess(SYSTEM_ACCESS_CODE.TRANSFER_LIABILITIES);
  }

  // dup??
  onTransferLiabilities(invoice: Invoice) {
    this.matDialog.open(TransferLiabilityComponent, {
      panelClass: 'flux-dialog-medium',
      data: invoice,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  onDebitAmountFocusOut(totalDebitAmount: number, line: PayableInvoiceLine) {
    const amountAllocated = line._AmountAllocatedCtrl.value;
    const valid =
      this.validateTotalDebitAmountProvided(totalDebitAmount, amountAllocated) &&
      this.validateDebitedAmount(line);
    if (!valid) {
      this.resetLineDebitAmountToZero(line);
    }
    return valid;
  }

  validateTotalDebitAmountProvided(totalDebitAmount: number, amountAllocated: number): boolean {
    if (!totalDebitAmount && amountAllocated > 0) {
      this.dialog.showTotalDebitAmountRequiredWarning();
      return false;
    } else {
      return true;
    }
  }

  validateDebitedAmount(line: PayableInvoiceLine) {
    const maxAllowed = line.AmountBilled - line.Balance.Outstanding;
    const amountAllocated = MoneyUtils.toCents(line._AmountAllocatedCtrl.value);
    if (amountAllocated > maxAllowed) {
      if (maxAllowed > 0) this.dialog.showLineMaxAllocationExceedWarning(maxAllowed);
      else this.dialog.showLineMaxAllocationEqualWarning();
      return false;
    } else {
      return true;
    }
  }

  onChangeLineAllocatedAmount(
    allocatedAmountRands: number,
    line: PayableInvoiceLine,
    invoice: PayableInvoice
  ) {
    if (!line || !invoice) return;
    const amountAllocated = MoneyUtils.toCents(allocatedAmountRands || 0);
    const initialBalanceOutstanding = line?.Balance?.Outstanding || 0;
    line._BalanceOutstanding = initialBalanceOutstanding + amountAllocated;
    // refresh totals
    invoice._AmountAllocated = invoice?.Lines?.reduce((acc, curr: PayableInvoiceLine) => {
      const lineAllocated = MoneyUtils.toCents(curr?._AmountAllocatedCtrl?.value || 0);
      return acc + lineAllocated;
    }, 0);
    invoice._BalanceOutstanding = invoice?.Lines?.reduce(
      (acc, curr: PayableInvoiceLine) => acc + (curr?._BalanceOutstanding || 0),
      0
    );
  }

  updateReason(invoice: DebitPayableInvoice, reason: string) {
    if (invoice) invoice._Reason = reason;
  }

  updateLiability(invoice: DebitPayableInvoice, liability: LIABILITY_TYPE) {
    if (invoice) invoice._LiabilityType = liability;
  }
}
