<div class="sidebar w-full h-full bg-hb-base-base-grey">
  <ng-container *ngVar="groups$ | async as items">
    <ng-container
      *ngTemplateOutlet="menuItems; context: { $implicit: items?.length > 0 ? items : (defaultGroups$ | async) }">
    </ng-container>
  </ng-container>
</div>

<ng-template #menuItems let-groups>
  <div id="sidebar" *ngFor="let group of groups; let first = first">
    <ng-container *ngIf="group.hasItems$ | async">
      <ng-container *ngIf="!first">
        <!-- <div class="h-[1px] mx-3 bg-hb-base-divider-grey"></div> -->
        <mat-divider class="bg-hb-base-divider-grey"></mat-divider>
      </ng-container>
      <div id='group-header' *ngIf="group.header"
        class="text-[10px] tracking-[3px] mx-3 leading-4 mt-3 font-medium text-hb-text-subdued">{{group.header}}</div>
      <mat-nav-list>
        <ng-container *ngFor="let item of group.items">
          <a mat-list-item [routerLink]="item.url" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}"
            [disabled]="!!item?.disabled" *ngIf="item.access | async" (click)="item.click ? item.click() : null">
            <span class="flex items-center justify-between w-full">
              {{ item.label }}
              <ng-container *ngIf="item?.counter !== undefined">
                <ng-container *ngIf="item.counter <= 999; else showGreater">
                  <span
                    class="bg-hb-base-divider-grey border-ra text-xs text-hb-base-white px-[6px] py-[2px] rounded-full flex items-center justify-center min-w-[20px]"
                    [ngClass]="{ '!bg-hb-primary-100': item?.counter > 0 }">
                    {{ item.counter }}
                  </span>
                </ng-container>
              </ng-container>
            </span>
          </a>
        </ng-container>
      </mat-nav-list>
    </ng-container>
  </div>
</ng-template>

<!-- TODO: Implement collapsible groups -->
<ng-template #collapsibleGroup let-group>
  <mat-expansion-panel>

  </mat-expansion-panel>
</ng-template>

<ng-template #showGreater>
  <span
    class="bg-hb-primary-100 border-ra text-xs text-hb-base-white px-[6px] py-[2px] rounded-full flex items-center justify-center">
    999+
  </span>
</ng-template>
