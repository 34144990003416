import { ReportRequestData} from "./common.model";
import { Practice } from '../practice.models';
import { PracticeProvider } from '../provider.models';
import { AccountSearchResult, REPORT_FORMAT_TYPE } from '../report.models';
import { BaseInvoice } from '../invoice.models';
import { Scheme } from '../scheme.models';

export class CollectableAmountsReportRequest implements ReportRequestData {
  Practice: Practice;
  Branch?: string;
  TreatingProvider?: PracticeProvider;
  Details: AccountSearchResult[];
  Invoices?: BaseInvoice[];
  UserId: string;
  ReportFormatType: REPORT_FORMAT_TYPE;
  FilteredBy?: 'patient' | 'medical aid';
  SearchProviders: string[];
  SearchBranches: string[]
  AllProvidersId: string;
  Schemes: Scheme[];
  Recipient?: string;
}

export class CollectableAmountsReportModel {
  Practice?: Practice;
  Branch?: string;
  TreatingProvider?: PracticeProvider;
  Summary?: CollectableAmountsSummary;
  Accounts?: CollectableAmountsAccount[];
  Doctors?: CollectableAccountsDoctor[];
  ReportInfo: CollectableAmountsReportInfo;
  Details?: AccountSearchResult[];
}

export class CollectableAmountsSummary {
  Name: string;
  NoOfAccounts: number;
  DoctorSummary: CollectableAccountsDoctorSummary[];
  TotalNo?: number;
  TotalAmount?: number;
  LiableType?: string
}

export class CollectableAccountsDoctorSummary {
  DoctorId?: string;
  Name?: string;
  NoOfInvoices: number;
  TotalCollectable: number;
}

export class CollectableAmountsReportInfo {
  Name: string;
  Practice: string;
  BPN: string;
  Branch?: string;
  Provider?: string;
}

export class CollectableAmountsAccount {
  AccountNo?: string;
  AccountHolderName?: string;
  AccountHolderSurname?: string;
  MemberNo?: string;
  Scheme?: string;
  PatientLiable?: number;
  MedicalAidLiable?: number;
  AccountNotes?: string;
  AccountStatus?: string;
  Reason?: string;
  Branch?: string;
}

export class CollectableAccountsDoctor {
  DoctorId: string;
  Name: string;
  Invoices?: CollectableAccountsDoctorInvoice[]
}

export class CollectableAccountsDoctorInvoice {
  AccountNo?: string;
  PatientName?: string;
  PatientSurname?: string;
  MemberNo?: string;
  Scheme?: string;
  InvoiceNo?: string;
  InvoiceType?: string;
  InvoiceDate?: string;
  DateOfService?: string;
  InvoiceNotes?: string;
  PatientLiable?: number;
  MedicalAidLiable?: number;
  BC?: "Y" | "N" | "";
  BCStatus?: string;
  BCMessageId?: string;
  Branch?: string
}
