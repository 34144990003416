import {
  BaseInvoice,
  CollectableAccountsDoctor,
  CollectableAccountsDoctorInvoice,
  CollectableAccountsDoctorSummary,
  CollectableAmountsAccount,
  CollectableAmountsReportInfo,
  CollectableAmountsReportModel,
  CollectableAmountsReportRequest,
  CollectableAmountsSummary,
  NoDataError,
  REPORT_NAME
} from '@meraki-flux/schema';

export class CollectableAmountsReportBuilder {

  async build(reportRequest: CollectableAmountsReportRequest): Promise<CollectableAmountsReportModel> {
    if (!reportRequest.Details) {
      throw new NoDataError();
    }
    return {
      Practice: reportRequest.Practice,
      Branch: reportRequest.Branch,
      TreatingProvider: reportRequest.TreatingProvider,
      Summary: await this.buildReportSummary(reportRequest),
      Accounts: await this.buildReportAccount(reportRequest),
      Doctors: await this.buildReportDoctors(reportRequest),
      ReportInfo: await this.buildReportInfo(reportRequest),
      Details: reportRequest.Details
    }
  }

  private async buildReportInfo(reportRequest: CollectableAmountsReportRequest) : Promise<CollectableAmountsReportInfo> {
    return {
      Practice: reportRequest.Practice.PracticeName,
      BPN: reportRequest.Practice.BillingPracticeNumber,
      Name: REPORT_NAME.COLLECTABLE_AMOUNTS,
      Branch: reportRequest.Branch,
      Provider: reportRequest.TreatingProvider?.HPCSANumber
    };
  }

  private async buildReportSummary(reportRequest: CollectableAmountsReportRequest) : Promise<CollectableAmountsSummary> {
    let totalNo = 0;
    let totalAmount = 0;
    const collAccDoctorSummary = reportRequest.Invoices?.reduce((collAccDoctorSummary: CollectableAccountsDoctorSummary[], invoice: BaseInvoice) => {
      let doctorSummary = collAccDoctorSummary.find(x => x.DoctorId === invoice.TreatingProvider?.HPCSANumber);
      if (doctorSummary) {
        doctorSummary.NoOfInvoices++;
        doctorSummary.TotalCollectable += invoice.Balance?.PatientLiable
      } else {
        collAccDoctorSummary.push({
          Name : invoice.TreatingProvider?.FullName,
          NoOfInvoices: 1,
          TotalCollectable: invoice.Balance?.PatientLiable,
          DoctorId: invoice.TreatingProvider?.HPCSANumber
        })
      }
      totalNo++;
      totalAmount += invoice.Balance?.PatientLiable
      return collAccDoctorSummary;
    }, [] as CollectableAccountsDoctorSummary[])
    return {
      Name: 'Summary',
      NoOfAccounts: reportRequest.Details?.length,
      DoctorSummary: collAccDoctorSummary,
      TotalNo: totalNo,
      TotalAmount: totalAmount,
      LiableType: reportRequest.FilteredBy,
    };
  }

  private async buildReportAccount(reportRequest: CollectableAmountsReportRequest): Promise<CollectableAmountsAccount[]> {
    return reportRequest.Details.map(det => {
      const member = det.Patients?.find(x => x.Id === det.PatientId)
      return {
        AccountNo: det.AccountNo,
        AccountStatus: det.AccountStatus,
        AccountNotes: det.AccountNote,
        AccountHolderSurname: member?.Surname??"",
        AccountHolderName: member?.Name??"",
        Branch: det.Account?.HomeBranch??"",
        MemberNo: det.MemberNo,
        Scheme: det.Scheme,
        PatientLiable: det.PatientLiable,
        MedicalAidLiable: det.MedicalAidLiable,
        Reason: det.AccountStatusReason
      }
    })
  }

  private async buildReportDoctors(reportRequest: CollectableAmountsReportRequest) : Promise<CollectableAccountsDoctor[]> {
    return reportRequest.Invoices?.reduce((collAccDoctorSummary: CollectableAccountsDoctor[], invoice: BaseInvoice) => {
      let doctorSummary = collAccDoctorSummary.find(x => x.DoctorId === invoice.TreatingProvider?.HPCSANumber);
      const collAccDocInv: CollectableAccountsDoctorInvoice = {
        AccountNo: invoice.Account?.AccountNo,
        PatientName: invoice.Patient?.Name,
        PatientSurname: invoice.Patient?.Surname,
        MemberNo: invoice.Account?.MemberNo,
        InvoiceNo: invoice.InvoiceNo,
        InvoiceType: invoice.Type,
        InvoiceDate: this.formatDate(invoice.InvoiceDate),
        DateOfService: this.formatDate(invoice.DateOfService),
        InvoiceNotes: invoice.Broker,
        Branch: invoice.Branch,
        PatientLiable: invoice.Balance?.PatientLiable,
        MedicalAidLiable: invoice.Balance.MedicalAidLiable,
        BCStatus: "", // TODO Add BCStatus
        BC: "", // TODO Add BC
        Scheme: invoice.Account?.SchemeName,
        BCMessageId: "" // TODO Add BCMessageId
      }
      if (doctorSummary) {
        doctorSummary.Invoices?.push(collAccDocInv);
      } else {
        collAccDoctorSummary.push({
          DoctorId: invoice.TreatingProvider?.HPCSANumber,
          Name : invoice.TreatingProvider?.FullName,
          Invoices: [collAccDocInv]
        })
      }
      return collAccDoctorSummary;
    }, [] as CollectableAccountsDoctor[])
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  }
}
