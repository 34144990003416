import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  Firestore,
  getDocs,
  query,
  QueryConstraint,
  where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountRepository } from '../../repositories/account.repository';
import { PathUtils } from '../../utils/path-utils';
import { AuthService } from '../auth.service';
import { DateUtils } from '@meraki-flux/purejs';
import {Transaction, TRANSACTION_TYPE} from "@meraki-flux/schema";

@Injectable()
export class TransactionService {
  constructor(
    private authService: AuthService,
    private accountRepository: AccountRepository,
    private firestore: Firestore
  ) {}

  selectedAccountTransactions(wheres: QueryConstraint[]): Observable<Transaction[]> {
    return this.authService.practice$.pipe(
      withLatestFrom(this.accountRepository.activeAccount$),
      switchMap(([practice, account]) =>
        collectionSnapshots(
          query(
            collection(
              this.firestore,
              `${PathUtils.transactionCollectionPath(practice.Id, account.Id)}`
            ),
            ...wheres
          )
        )
      ),
      map((arr) => arr.map((doc) => (DateUtils.timestampsToDates({ ...doc.data(), Id: doc.id } as Transaction))))
    );
  }

  async getActiveInvoiceTransactions(practiceId: string, accountId: string, invoiceId: string) {
    const allInvoiceTransactions = (
      await getDocs(
        query(
          collection(this.firestore, PathUtils.transactionCollectionPath(practiceId, accountId)),
          where('InvoicePath', '==', PathUtils.invoicePath(practiceId, invoiceId))
        )
      )
    ).docs.map((doc) => ({ ...doc.data(), Id: doc.id } as Transaction));
    const reversedTransactionIds = allInvoiceTransactions
      .filter((tx) => TRANSACTION_TYPE.TRANSACTION_REVERSE === tx.TransactionType)
      .map((tx) => tx?.Metadata?.ReferenceTransactionId);
    return allInvoiceTransactions.filter(
      (tx) =>
        TRANSACTION_TYPE.TRANSACTION_REVERSE !== tx.TransactionType &&
        !reversedTransactionIds.includes(tx.Id)
    );
  }
}
