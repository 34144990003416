import {
  BasePdfReportData, CellType, PDFCellData,
  PDFParameter, ProviderVATData,
  REPORT_NAME,
  ReportHeader,
  ReportTable, VATData1,
  VATSummaryReportModel
} from "@meraki-flux/schema";

export class VATSummaryPdfReportDataBuilder {
  private static REPORT_SPECIAL_FONT_COLOR = '#000000';

  build(reportRequest: VATSummaryReportModel): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.bpn = reportRequest.Practice.BillingPracticeNumber;
    //reportData.orientation = 'landscape';
    reportData.headers = this.buildHeader(reportRequest);
    reportData.tables = this.buildTables(reportRequest);
    reportData.dateHeader = `Date generated ${reportRequest.ReportDate}`;
    reportData.footerText = '\t\u200B'.repeat(60);
    reportData.footer = {
      hbLogo: true,
    };
    return reportData;
  }

  private buildHeader(reportRequest: VATSummaryReportModel): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: { value: reportRequest.Practice.PracticeName, decoration: { bold: true } },
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: { value: reportRequest.Practice.BillingPracticeNumber, decoration: { bold: true } },
    });
    if (reportRequest.Multibranch) {
      leftParameters.push({
        name: 'Branch',
        data: { value: reportRequest.Branch.Name ?? 'All', decoration: { bold: true } },
      });
    }
    const rightParameters: PDFParameter[] = [
      { name: 'Date Range Type', data: { value: reportRequest.DateRangeType, decoration: { bold: true } } },
      { name: 'Date Range', data: { value: reportRequest.DateRange, decoration: { bold: true } } },
    ];
    return [
      {
        name: REPORT_NAME.VAT_SUMMARY,
        nameColor: VATSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
        includeGeneratedDate: true,
        left: leftParameters,
        right: rightParameters,
      },
    ];
  }

  private buildTables(reportRequest: VATSummaryReportModel): ReportTable[] {
    const reportTables: ReportTable[] = [];
    reportTables.push(this.buildGroupByProviderTable(reportRequest.SummaryDetails, 'Summary', null));

    //Loop providers
    let firstIteration = true;
    reportRequest.ProviderVATData.forEach((item: ProviderVATData) => {

      //For summary
      //Per Provider
      const nullValue = firstIteration ? 'VAT PER PROVIDER' : null;
      reportTables.push(this.buildGroupByProviderTable(item.ProviderVATData, nullValue, item.TreatingProviderName));
      firstIteration = false;

    });

    return reportTables;
  }

  private buildGroupByProviderTable(reportRequest: VATData1 [], tableName: string, tableHeader: string): ReportTable {
    const table: ReportTable = {};
    table.name = tableName;
    if(tableHeader)
    {
      table.headers = [{
          rows: [[{name: '', data: {value: tableHeader, decoration: {bold: true, italics: true}}}]],
          backgroundColor: '#ececec'
        }];
    }
    table.nameColor = VATSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = VATSummaryPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.rowHeaders = [
      { value: 'Month', decoration: { bold: true } },
      { value: 'Output VAT', decoration: { bold: true } },
      { value: 'Input VAT', decoration: { bold: true } },
      { value: 'Balance VAT', decoration: { bold: true } },
    ];
    table.rowHeadersBold = true;
    const rows = [];

    let total_outputVAT = 0;
    let total_inputVAT = 0;
    let total_balanceVAT = 0;

    reportRequest.forEach(
      (item: VATData1) => {
        total_outputVAT += item.OutputVAT;
        total_inputVAT += item.InputVAT;
        total_balanceVAT += (item.OutputVAT - item.InputVAT);

        const row: PDFCellData[] = [];
        row.push({ value: item.CustomDate });
        row.push({ value: item.OutputVAT, type: CellType.CURRENCY });
        row.push({ value: item.InputVAT, type: CellType.CURRENCY  });
        row.push({ value: (item.OutputVAT - item.InputVAT), type: CellType.CURRENCY });
        rows.push(row);
      }
    );

    const row: PDFCellData[] = [];
    row.push({ value: 'Total', decoration: { bold: true } });
    row.push({ value: total_outputVAT, type: CellType.CURRENCY, decoration: { bold: true } });
    row.push({ value: total_inputVAT, type: CellType.CURRENCY, decoration: { bold: true } });
    row.push({ value: total_balanceVAT, type: CellType.CURRENCY, decoration: { bold: true } });
    rows.push(row);

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };
    return table;
  }
}
