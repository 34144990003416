import * as _ from "lodash";
import { DateUtils } from "../../utils/date-utils";
import { FormatUtils } from "../../utils/format-utils";
import {
  BaseInvoice,
  CLAIM_STATUS,
  ClaimDetailsItem,
  ClaimInvoiceProviderModel,
  ClaimInvoiceReportInfo,
  ClaimInvoiceReportModel,
  ClaimInvoiceReportRequest, INVOICE_DISPLAY_TYPE,
  INVOICE_STATUS,
  INVOICE_SUBTYPE,
  INVOICE_TYPE,
  NoDataError, Provider,
  ProviderInvoices,
  ProviderSummaryItem
} from "@meraki-flux/schema";

export class ClaimInvoiceReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";
    readonly EXCLUDED_CLAIM_STATUSES: string[] = [CLAIM_STATUS.RESUBMITTED_TO_MEDICALAID, CLAIM_STATUS.REVERSAL_PROCESSED, CLAIM_STATUS.REVERSAL_ACCEPTED];

    async build(reportRequest: ClaimInvoiceReportRequest): Promise<ClaimInvoiceReportModel> {
        const reportHeader: ClaimInvoiceReportInfo = await this.buildReportInfo(reportRequest);
        const providersModel: ClaimInvoiceProviderModel[] = await this.buildProvidersModel(reportRequest);

        this.checkDataAvailability(providersModel);

        let allProvidersModel: ClaimInvoiceProviderModel = undefined;
        if (!reportRequest.TreatingProvider && providersModel.length > 1) {
            allProvidersModel = this.buildAllProvidersModel(providersModel);
        }
        return {
            ClaimInvoiceReportInfo: reportHeader,
            ProviderModels: providersModel,
            AllProvidersModel: allProvidersModel,
            ReportDate: FormatUtils.formatDate(new Date(), this.DATE_FORMAT),
        };
    }

    private checkDataAvailability(providersModel: ClaimInvoiceProviderModel[]) {
        let noData = true;
        providersModel.forEach(providerModel => {
            if (providerModel.CancelledDetailsTable.length > 0 || providerModel.DetailsTable.length > 0) {
                noData = false;
            }
        });
        if (noData)
            throw new NoDataError();
    }

    private buildAllProvidersModel(providerTabs: ClaimInvoiceProviderModel[]): ClaimInvoiceProviderModel {
        const treatingProviderName = 'All providers';
        const summaryTable: ProviderSummaryItem[] = [];
        let detailsTable: ClaimDetailsItem[] = [];
        let cancelledDetailsTable: ClaimDetailsItem[] = [];
        for (const providerTab of providerTabs) {
            providerTab.SummaryTable.forEach((summaryItem) => {
                let item: ProviderSummaryItem = summaryTable.find((item) => item.ClaimStatus === summaryItem.ClaimStatus &&
                    item.InvoiceType === summaryItem.InvoiceType);
                if (item == null) {
                    item = {...summaryItem};
                    summaryTable.push(item);
                } else {
                    item.InvoicedAmount += summaryItem.InvoicedAmount;
                    item.MedicalAidLiable += summaryItem.MedicalAidLiable;
                    item.PatientLiable += summaryItem.PatientLiable;
                    item.PaymentsAndCreditsAmount += summaryItem.PaymentsAndCreditsAmount;
                    item.PaymentsCorrections += summaryItem.PaymentsCorrections;
                    item.TotalCount += summaryItem.TotalCount;
                }
            })
            detailsTable = detailsTable.concat(providerTab.DetailsTable);
            cancelledDetailsTable = cancelledDetailsTable.concat(providerTab.CancelledDetailsTable);
        }
        return {
            SummaryTable: summaryTable,
            CancelledDetailsTable: cancelledDetailsTable,
            DetailsTable: detailsTable,
            ProviderName: treatingProviderName
        };
    }

    private async buildProvidersModel(reportRequest: ClaimInvoiceReportRequest): Promise<ClaimInvoiceProviderModel[]> {
        const providerTab: ClaimInvoiceProviderModel[] = [];
        const providerList = [];
        if (reportRequest.TreatingProvider) {
            providerList.push(reportRequest.TreatingProvider);
        } else if (reportRequest.TreatingProviders) {
            providerList.push(...reportRequest.TreatingProviders)
        }
        _.sortBy(providerList, 'Surname', 'Name');
        for (const provider of providerList) {
            providerTab.push(await this.buildProviderTabModel(provider));
        }
        return providerTab;
    }

    private async buildProviderTabModel(providerInvoice: ProviderInvoices): Promise<ClaimInvoiceProviderModel> {

        const treatingProviderName: string = providerInvoice.Provider.Title == 'Dr' ?
            `${providerInvoice.Provider.Title} ${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}` :
            `${providerInvoice.Provider.Name} ${providerInvoice.Provider.Surname}`;
        const summaryTable: ProviderSummaryItem[] = [];
        const detailsTable: ClaimDetailsItem[] = [];
        const cancelledDetailsTable: ClaimDetailsItem[] = [];

        const invoices: BaseInvoice[] = providerInvoice.Invoices
        if (invoices) {
            invoices.forEach((invoice: BaseInvoice) => {
                const claimDetailsItem: ClaimDetailsItem = this.buildClaimDetails(invoice, treatingProviderName);
                if (claimDetailsItem.InvoiceStatus == INVOICE_STATUS.CANCELLED) {
                    cancelledDetailsTable.push(claimDetailsItem);
                } else {
                    detailsTable.push(claimDetailsItem);
                    this.updateSummaryTable(summaryTable, claimDetailsItem);
                }
            })
        }
        return {
            SummaryTable: summaryTable,
            CancelledDetailsTable: cancelledDetailsTable,
            DetailsTable: detailsTable,
            ProviderName: treatingProviderName
        };
    }

    private updateSummaryTable(summaryTable: ProviderSummaryItem[], claimDetailsItem: ClaimDetailsItem) {
        const invoiceType: string = claimDetailsItem.InvoiceType;
        const claimStatus: string = claimDetailsItem.ClaimStatus;

        let summaryItem: ProviderSummaryItem = summaryTable.find((item) => item.ClaimStatus === claimStatus && item.InvoiceType === invoiceType);
        if (!summaryItem) {
            summaryItem = {
                ClaimStatus: claimStatus,
                InvoiceType: invoiceType,
                TotalCount: 0,
                InvoicedAmount: 0,
                PaymentsAndCreditsAmount: 0,
                PaymentsCorrections: 0,
                MedicalAidLiable: 0,
                PatientLiable: 0
            };
            summaryTable.push(summaryItem);
        }
        summaryItem.TotalCount += 1;
        summaryItem.InvoicedAmount += claimDetailsItem.InvoicedAmount;
        summaryItem.MedicalAidLiable += claimDetailsItem.MedicalAidLiable;
        summaryItem.PatientLiable += claimDetailsItem.PatientLiable;
        summaryItem.PaymentsAndCreditsAmount += claimDetailsItem.PatientPaymentsAmount + claimDetailsItem.WriteOffsAmount +
            claimDetailsItem.MedicalAidPaymentsAmount + claimDetailsItem.CreditNotesAmount;
        summaryItem.PaymentsCorrections += claimDetailsItem.PaymentsCorrectionsAmount;
    }

    private buildClaimDetails(invoice: BaseInvoice, treatingProviderName: string): ClaimDetailsItem {
        const claimDetailsItem: ClaimDetailsItem = {};
        claimDetailsItem.AccountNo = invoice.Account?.AccountNo;
        claimDetailsItem.PatientName = `${invoice.Patient?.Name??''} ${invoice.Patient?.Surname??''}`.trim();
        claimDetailsItem.PatientID = invoice.Patient?.IdentityNo;
        claimDetailsItem.InvoiceType = this.getInvoiceDisplayType(invoice.Type, invoice.Subtype);
        claimDetailsItem.InvoiceStatus = invoice.Status;
        claimDetailsItem.InvoiceNo = invoice.InvoiceNo;
        claimDetailsItem.Scheme = invoice.Account?.SchemeName;
        claimDetailsItem.Plan = invoice.Account?.PlanName;
        claimDetailsItem.Option = invoice.Account?.OptionName;
        claimDetailsItem.MemberNo = invoice.Account?.MemberNo;
        claimDetailsItem.ClaimStatus = invoice.ClaimInfo?.ClaimStatus;
        claimDetailsItem.HBMessageID = invoice.ClaimInfo?.HBMessageId;
        claimDetailsItem.DateOfService = DateUtils.toDate(invoice.DateOfService);
        claimDetailsItem.DateOfSubmission = DateUtils.toDate(invoice.DateOfSubmission);
        claimDetailsItem.InvoicedAmount = invoice.AmountBilled;
        claimDetailsItem.PatientPaymentsAmount = invoice.PatientPaymentsAmount ? invoice.PatientPaymentsAmount : 0;
        claimDetailsItem.MedicalAidPaymentsAmount = invoice.MedicalAidPaymentsAmount ? invoice.MedicalAidPaymentsAmount : 0;
        claimDetailsItem.CreditNotesAmount = invoice.CreditNotesAmount ? invoice.CreditNotesAmount : 0;
        claimDetailsItem.WriteOffsAmount = invoice.WriteOffsAmount ? invoice.WriteOffsAmount : 0;
        claimDetailsItem.PaymentsCorrectionsAmount = invoice.PaymentsCorrectionsAmount ? invoice.PaymentsCorrectionsAmount : 0;
        claimDetailsItem.MedicalAidLiable = invoice.Balance?.MedicalAidLiable;
        claimDetailsItem.PatientLiable = invoice.Balance?.PatientLiable;
        claimDetailsItem.eRAEnabled = invoice.ClaimInfo?.eRAEnabled;
        claimDetailsItem.PlaceOfService = invoice.PlaceOfService;
        claimDetailsItem.Location = invoice.Location;
        claimDetailsItem.CreatedBy = invoice.CreatedBy;
        claimDetailsItem.Branch = invoice.Branch;
        claimDetailsItem.TreatingProviderName = treatingProviderName;
        claimDetailsItem.AmountExcFromHBBill = invoice.AmountExcFromHBBill;
        return claimDetailsItem;
    }

    private getInvoiceDisplayType(invoiceType: INVOICE_TYPE, invoiceSubtype: INVOICE_SUBTYPE) {
        if (INVOICE_TYPE.MEDICAL_AID === invoiceType) {
            if (INVOICE_SUBTYPE.MEDICAL_INSURER === invoiceSubtype) {
                return INVOICE_DISPLAY_TYPE.MEDICAL_INSURANCE;
            } else {
                return INVOICE_DISPLAY_TYPE.MEDICAL_AID;
            }
        } else if (INVOICE_TYPE.CASH === invoiceType) {
            if (INVOICE_SUBTYPE.DEBIT_NOTE === invoiceSubtype) {
                return INVOICE_DISPLAY_TYPE.CASH_DEBIT_NOTE;
            } else {
                return INVOICE_DISPLAY_TYPE.CASH;
            }
        } else {
            return invoiceType;
        }
    }

    private async buildReportInfo(reportRequest: ClaimInvoiceReportRequest): Promise<ClaimInvoiceReportInfo> {
        const reportInfo: ClaimInvoiceReportInfo = {};

        let fullTreatingProviderName: string;
        let speciality: string;
        if (reportRequest.TreatingProvider) {
            fullTreatingProviderName = this.formatProviderName(reportRequest.TreatingProvider.Provider);
            speciality = reportRequest.TreatingProvider.Provider.Speciality;
        }

        reportInfo.Practice = reportRequest.Practice?.PracticeName + ' (' + reportRequest.Practice?.BillingPracticeNumber + ')';
        reportInfo.PracticeId = reportRequest.Practice?.BillingPracticeNumber;
        if (reportRequest.Practice?.IsMultiBranch === true)
            reportInfo.Branch = reportRequest.Branch?.Name ? reportRequest.Branch?.Name : 'All';

        reportInfo.TreatingProvider = fullTreatingProviderName ? fullTreatingProviderName : 'All';
        reportInfo.DateRangeType = reportRequest.DateRangeType;
        reportInfo.DateRange = FormatUtils.formatDate(reportRequest.DateFrom, 'DD/MM/YYYY') + ' - ' + FormatUtils.formatDate(reportRequest.DateTo, 'DD/MM/YYYY');
        reportInfo.InvoiceType = reportRequest.InvoiceType ? reportRequest.InvoiceType : 'All';
        reportInfo.Scheme = reportRequest.Scheme ? reportRequest.Scheme : 'All';
        reportInfo.IsMultiBranch = reportRequest.Practice?.IsMultiBranch;
        reportInfo.Speciality = speciality;
        return reportInfo;
    }

    private formatProviderName(treatingProviderData: Provider | undefined): string {
        return treatingProviderData?.Title + ' ' + treatingProviderData?.Name + ' ' + treatingProviderData?.Surname;
    }
}
