export interface EmailRequest {
  To: EmailRecipient | EmailRecipient[];
  Cc?: EmailRecipient | EmailRecipient[];
  ReplyTo?: EmailRecipient;
  Subject: string;
  Body: string;
  Type?: EMAIL_TYPE;
  Status?: EMAIL_STATUS;
  Attachments?: EmailRequestAttachment[];
  AttachmentsRef?: string[];
  SendAt?: number; // Epoch in seconds UTC
  MessageId?: string;
  PracticeId?: string;
  AccountId?: string;
  CalendarEventId?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
  CreatedByFullName?: string;
  UpdatedAt?: Date;
  UpdatedBy?: string;
  Actions?: EmailAction[];
  BulkMessageId?: string;
}

export interface EmailRecipient {
  EmailAddress: string;
  Name?: string;
}

export interface EmailRequestAttachment {
  Data?: any;
  MimeType: string;
  StoragePath: string;
  FileName: string;
}

export enum EMAIL_TYPE {
  BULK = "Bulk",
  BULK_STATEMENTS = "Bulk statements",
  AD_HOC = "Ad Hoc",
  STATEMENT = "Statement",
  INVOICE = "Invoice",
  APPOINTMENT_CONFIRMATION = "Appointment Confirmation",
  APPOINTMENT_REMINDER = "Appointment Reminder",
  PROOF_OF_PAYMENT = "Proof Of Payment"
}

export enum EMAIL_STATUS {
  NEW = "New",
  SENT = "Sent",
  FAILED = "Failed",
  DRAFT = "Draft",
  ACTIONS_EXECUTED = "Actions Executed",
  PROCESSED = 'Processed',
  DROPPED ='Dropped',
  DEFERRED = 'Deferred',
  BOUNCE= 'Bounce',
  DELIVERED = 'Delivered',
  OPEN = 'Open',
  CLICK = 'Click',
  SPAM_REPORT = 'Spam Report',
}

export enum EMAIL_TEMPLATE_TYPE {
  INVOICE = "Invoice",
  STATEMENT = "Statement",
}

export enum EMAIL_ACTION_EVENT {
  INVOICE = "Invoice",
  ON_SUCCESS = "On Success",
}

export enum EMAIL_ACTION_TYPE {
  ON_SUCCESS = 'On Success',
  FIELD_UPDATE = "Field Update"
}

export enum EMAIL_ACTION_STATUS {
  NEW = "New",
  EXECUTED = "Executed",
  FAILED = "Failed",
}

export interface EmailAction {
  Event: EMAIL_ACTION_EVENT;
  Type: EMAIL_ACTION_TYPE;
  Status: EMAIL_ACTION_STATUS;
  Reason?: string;
}

export interface FieldUpdateAction extends EmailAction {
  DocumentPath: string;
  FieldPath: string;
  UpdateValue: any;
}
