import {
  BaseExcelReportData,
  CellType, ClaimDetailsItem,
  ClaimInvoiceProviderModel, ClaimInvoiceReportModel, ExcelCellData,
  ProviderSummaryItem,
  REPORT_NAME,
  Sheet,
  SPECIALITY,
  Table
} from "@meraki-flux/schema";
import * as _ from "lodash";
import { ReportUtils } from "../report-utils";

export class ClaimInvoiceXlsReportDataBuilder {

    build(claimInvoiceReportModel: ClaimInvoiceReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = claimInvoiceReportModel.ClaimInvoiceReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.CLAIMS_AND_INVOICES;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, claimInvoiceReportModel);

        const isOptometrist: boolean = claimInvoiceReportModel.ClaimInvoiceReportInfo.Speciality == SPECIALITY.OPTOMETRIST;

        if (!claimInvoiceReportModel.AllProvidersModel) {

           claimInvoiceReportModel.ProviderModels.forEach((providerModel) => {
                 excelReportData.data.push(this.buildReportSheet(providerModel, excelReportData.data,
                     claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch, false, isOptometrist, providerModel.ProviderName));
             });

         } else {

           let counter = 1;
           claimInvoiceReportModel.ProviderModels.forEach((providerModel) => {
                 excelReportData.data.push(this.buildReportSheet(providerModel, excelReportData.data,
                     claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch, false, isOptometrist,counter + '. ' + providerModel.ProviderName));
                     counter++;
             });

             excelReportData.data.push(this.buildReportSheet(claimInvoiceReportModel.AllProvidersModel,
                     excelReportData.data, claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch,
                     true, isOptometrist, 'All providers'));
         }

        return excelReportData;
    }

    private buildReportSheet(providerModel: ClaimInvoiceProviderModel, otherSheets: Sheet[],
                            addBranchColumn?: boolean, addTreatingProviderColumn?: boolean,
                            addOptomsColumn?: boolean, providerName?: string): Sheet {
        const sheet: Sheet = {};
        sheet.name = ReportUtils.getSheetName(providerName, otherSheets);
        sheet.header = 'Claims and Invoices: ' + providerModel.ProviderName
        sheet.tables = [];

        const summaryTable: Table = {};
        summaryTable.name = 'Summary';
        summaryTable.headers = [
            {value: 'Invoice type', bold: true},
            {value: 'Claim status', bold: true},
            {value: 'Items', bold: true, type: CellType.NUMBER},
            {value: 'Invoices amount', bold: true, type: CellType.NUMBER},
            {value: 'Payments & credits', bold: true, type: CellType.NUMBER},
            {value: 'Payment corrections (debits)', bold: true, type: CellType.NUMBER},
            {value: 'Medical aid liable', bold: true, type: CellType.NUMBER},
            {value: 'Patient liable', bold: true, type: CellType.NUMBER}
        ];

        let totalCount = 0;
        let totalInvoicedAmount = 0;
        let totalPaymentsAndCreditsAmount = 0;
        let totalPaymentsCorrections = 0;
        let totalMedicalAidLiable = 0;
        let totalPatientLiable = 0;
        const rows = [];
        providerModel.SummaryTable.sort((a, b) => a.InvoiceType > b.InvoiceType ? 1 : -1).forEach((item: ProviderSummaryItem) => {
            totalCount += item.TotalCount;
            totalInvoicedAmount += item.InvoicedAmount;
            totalPaymentsAndCreditsAmount += item.PaymentsAndCreditsAmount;
            totalPaymentsCorrections += item.PaymentsCorrections;
            totalMedicalAidLiable += item.MedicalAidLiable;
            totalPatientLiable += item.PatientLiable;

            const row: ExcelCellData[] = [];
            row.push({value: item.InvoiceType});
            row.push({value: item.ClaimStatus});
            row.push({value: item.TotalCount});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            row.push({value: item.PaymentsAndCreditsAmount, type: CellType.CURRENCY});
            row.push({value: item.PaymentsCorrections, type: CellType.CURRENCY});
            row.push({value: item.MedicalAidLiable, type: CellType.CURRENCY});
            row.push({value: item.PatientLiable, type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: ExcelCellData[] = [];
        row.push({value: 'Total', bold: true});
        row.push({value: ''});
        row.push({value: totalCount, type: CellType.NUMBER, bold: true});
        row.push({value: totalInvoicedAmount, type: CellType.CURRENCY, bold: true});
        row.push({value: totalPaymentsAndCreditsAmount, type: CellType.CURRENCY, bold: true});
        row.push({value: totalPaymentsCorrections, type: CellType.CURRENCY, bold: true});
        row.push({value: totalMedicalAidLiable, type: CellType.CURRENCY, bold: true});
        row.push({value: totalPatientLiable, type: CellType.CURRENCY, bold: true});
        rows.push(row);
        summaryTable.rows = rows;
        sheet.tables.push(summaryTable);

        const detailsTable: Table = {};
        detailsTable.name = 'Invoice/Claim Details';
        detailsTable.headers = [
            {value: 'Acc. no.', bold: true},
            {value: 'Patient', bold: true},
            {value: 'Patient ID no.', bold: true},
            {value: 'Invoice type', bold: true},
            {value: 'Invoice no.', bold: true},
            {value: 'Scheme', bold: true},
            {value: 'Plan', bold: true},
            {value: 'Option', bold: true}];
        if (addTreatingProviderColumn) {
            detailsTable.headers.push({value: 'Treating provider', bold: true});
        }
        detailsTable.headers.push(
            {value: 'Member no.', bold: true},
            {value: 'Claim status', bold: true},
            {value: 'Date of service', bold: true},
            {value: 'Date of submission', bold: true},
            {value: 'HB message ID', bold: true},
            {value: 'Invoiced amount', bold: true, type: CellType.NUMBER},
            {value: 'Patient payments', bold: true, type: CellType.NUMBER},
            {value: 'Medical aid payments', bold: true, type: CellType.NUMBER},
            {value: 'Credit notes', bold: true, type: CellType.NUMBER},
            {value: 'Write-offs', bold: true, type: CellType.NUMBER},
            {value: 'Payment corrections (debits)', bold: true, type: CellType.NUMBER},
            {value: 'Med aid liable', bold: true, type: CellType.NUMBER},
            {value: 'Patient liable', bold: true, type: CellType.NUMBER},
            {value: 'eRA enabled', bold: true, type: CellType.NUMBER},
            {value: 'Place of service', bold: true},
            {value: 'Location', bold: true},
            {value: 'Created by', bold: true}
        );
        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true});
        }
        if (addOptomsColumn) {
            detailsTable.headers.push({value: 'Amount excl. from Healthbridge bill', bold: true});
        }

        const detailsRows = [];
        _.orderBy(providerModel.DetailsTable, ['DateOfService', 'InvoiceNo']).forEach((item: ClaimDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.AccountNo, type: CellType.GENERAL});
            row.push({value: item.PatientName});
            row.push({value: item.PatientID, type: CellType.GENERAL});
            row.push({value: item.InvoiceType});
            row.push({value: item.InvoiceNo});
            row.push({value: item.Scheme});
            row.push({value: item.Plan});
            row.push({value: item.Option});
            if (addTreatingProviderColumn) {
                row.push({value: item.TreatingProviderName});
            }
            row.push({value: item.MemberNo, type: CellType.GENERAL});
            row.push({value: item.ClaimStatus});
            row.push({value: item.DateOfService, type: CellType.DATE});
            row.push({value: item.DateOfSubmission, type: CellType.DATE});
            row.push({value: item.HBMessageID});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            row.push({value: item.PatientPaymentsAmount, type: CellType.CURRENCY});
            row.push({value: item.MedicalAidPaymentsAmount, type: CellType.CURRENCY});
            row.push({value: item.CreditNotesAmount, type: CellType.CURRENCY});
            row.push({value: item.WriteOffsAmount, type: CellType.CURRENCY});
            row.push({value: item.PaymentsCorrectionsAmount, type: CellType.CURRENCY});
            row.push({value: item.MedicalAidLiable, type: CellType.CURRENCY});
            row.push({value: item.PatientLiable, type: CellType.CURRENCY});
            row.push({value: item.eRAEnabled});
            row.push({value: item.PlaceOfService});
            row.push({value: item.Location});
            row.push({value: item.CreatedBy});
            if (addBranchColumn)
                row.push({value: item.Branch});
            if (addOptomsColumn)
                row.push({value: item.AmountExcFromHBBill});
            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        const cancelledTable: Table = {};
        cancelledTable.name = 'Cancelled Invoices';

        cancelledTable.headers = [
            {value: 'Acc. no.', bold: true},
            {value: 'Patient', bold: true},
            {value: 'Patient ID no.', bold: true},
            {value: 'Invoice type', bold: true},
            {value: 'Invoice no.', bold: true},
            {value: 'Scheme', bold: true},
            {value: 'Plan', bold: true},
            {value: 'Option', bold: true}];
        if (addTreatingProviderColumn) {
            cancelledTable.headers.push({value: 'Treating provider', bold: true});
        }
        cancelledTable.headers.push(
            {value: 'Member no.', bold: true},
            {value: 'Claim status', bold: true},
            {value: 'Date of service', bold: true},
            {value: 'Date of submission', bold: true},
            {value: 'HB message ID', bold: true},
            {value: 'Invoiced amount', bold: true, type: CellType.NUMBER},
            {value: 'Place of service', bold: true},
            {value: 'Location', bold: true},
            {value: 'Created by', bold: true}
        );
        if (addBranchColumn)
            cancelledTable.headers.push({value: 'Branch', bold: true})

        const cancelledRows = [];
        _.orderBy(providerModel.CancelledDetailsTable, ['DateOfService', 'InvoiceNo']).forEach((item: ClaimDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.AccountNo});
            row.push({value: item.PatientName});
            row.push({value: item.PatientID});
            row.push({value: item.InvoiceType});
            row.push({value: item.InvoiceNo});
            row.push({value: item.Scheme});
            row.push({value: item.Plan});
            row.push({value: item.Option});
            if (addTreatingProviderColumn) {
                row.push({value: item.TreatingProviderName});
            }
            row.push({value: item.MemberNo, type: CellType.GENERAL});
            row.push({value: item.ClaimStatus});
            row.push({value: item.DateOfService, type: CellType.DATE});
            row.push({value: item.DateOfSubmission, type: CellType.DATE});
            row.push({value: item.HBMessageID});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            row.push({value: item.PlaceOfService});
            row.push({value: item.Location});
            row.push({value: item.CreatedBy});
            if (addBranchColumn)
                row.push({value: item.Branch});
            cancelledRows.push(row);
        });
        cancelledTable.rows = cancelledRows;
        sheet.tables.push(cancelledTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, claimInvoiceReportModel: ClaimInvoiceReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.Practice, type: CellType.GENERAL}
        });
        if (claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.Branch, type: CellType.GENERAL}
                });
        }
        excelReportData.parameters.push(
            {
                name: 'Treating Provider',
                data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.TreatingProvider, type: CellType.GENERAL}
            });
        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.DateRange, type: CellType.GENERAL}
            });
    }
}
