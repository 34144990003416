import { Injectable } from '@angular/core';
import {
  BaseExcelReportData,
  IncompleteContactDetailsReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from '@meraki-flux/schema';
import { ReportUtils } from '../report-utils';
import { IncompleteContactDetailsReportBuilder } from './incomplete-contact-details-report-builder';
import { IncompleteContactDetailsXlsReportDataBuilder } from './incomplete-contact-details-xls-report-data-builder';
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class IncompleteContactDetailsReportService {

  async generateReport(reportRequest: IncompleteContactDetailsReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildIncompleteContactDetailsReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.ReportFormatType);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.INCOMPLETE_CONTACT_DETAILS,
      format: REPORT_FORMAT_TYPE.EXCEL,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    };
  }

  private async getReportGenerator(reportRequest: IncompleteContactDetailsReportRequest): Promise<BaseExcelGenerator> {
    return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
  }

  private async generateXlsReportData(reportRequest: IncompleteContactDetailsReportRequest): Promise<BaseExcelReportData> {
    return new IncompleteContactDetailsXlsReportDataBuilder().build(
      await new IncompleteContactDetailsReportBuilder().build(reportRequest));
  }
}
