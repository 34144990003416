import {BaseAccount} from "../account.models";
import {BaseInvoice} from "../invoice.models";
import {Practice} from "../practice.models";
import {ClaimDetailData, PDFCellData, ReportICDLine, TableBorders} from "./pdf.model";

export class ClaimDetailReportRequest {
    Practice: Practice;
    Invoice: BaseInvoice;
    Account: BaseAccount;
    SendEmail?: boolean;
  }

export interface ClaimDetailReportModel {
    PracticeId: string;
    PracticeName: string;
    ClaimDetailTables: ClaimDetailTable[];
    ClaimDetailData: ClaimDetailData;
    ReportDate?: string;
}

export interface ClaimDetailTable {
    ICDLine: ReportICDLine;
    rowHeaders?: PDFCellData[];
    borders?: TableBorders;
    rows?: PDFCellData[][];
}
