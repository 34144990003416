import {ACCOUNT_STATUS, ACCOUNT_TYPE} from './account.models';
import {BenefitCheckLine, BenefitCheckResponse} from './benefit-check.models';
import {BaseModel, BENEFIT_CHECK_STATUS, VISIT_TYPE} from './general.models';

export interface CalendarProvider {
  Id?: string;
  Title?: string;
  Name?: string;
  Surname?: string;
  DisplayName?: string;
  Colour?: string;
  Index?: number;
  IsSelected?: boolean;
  DefaultBranch?: string;
  Branches?: string[];
  DisciplineCode?: number;
  DefaultVisitType?: string;
}

export interface CalendarEvent extends BaseModel {
  Id?: string;
  StartTime?: Date;
  EndTime?: Date;
  EventType?: CALENDAR_EVENT_TYPE;
  Status?: CALENDAR_EVENT_STATUS;
  ClinicalStatus?: CALENDAR_EVENT_STATUS | CLINICAL_CALENDAR_EVENT_STATUS,
  Statuses?: CalendarEventStatusHistory[];
  TreatingProvider?: string;
  Branch?: string;
  Resource?: string;
  DoctorLeaveInfo?: DoctorLeaveInfo;
  VisitInfo?: VisitInfo;
  AllDay?: boolean;
  IsWalkIn?: boolean;
  IsRecurrentRoot?: boolean;
  BCInvoiceNo?: string;
  BCError?: string;
  RecurrentInfo?: CalendarEventRecurrentInfo;
  Metadata?: CalendarEventMetadata; // additional info to be used with event for rendering etc. and which should not be saved to DB
}

export interface CalendarEventStatusHistory {
  Status?: CALENDAR_EVENT_STATUS;
  AppliedBy?: string;
  AppliedAt?: Date;
}

export interface CalendarEventMetadata {
  Id?: string; // optional ID of saved CalendarEvent document
  ViewId?: string; // mandatory ID used for rendering. Euqal to Id for saved events or сomposite value for virtual recurrent events
  RecurrentRuleEvent?: CalendarEvent;
  OriginalEvent?: CalendarEvent;
  DefaultDurationMins?: number;
}

export interface CalendarEventRecurrentInfo {
  RootEventId?: string;
  Index?: number;
  Rule?: CalendarEventRecurrentRule;
}

export interface CalendarEventRecurrentRule {
  Repeat?: Repeat;
  SkipIndexes?: number[];
}

export interface Repeat {
  Frequency?: FREQUENCY;
  EndDate?: Date;
}

export interface CalendarEventIndex extends CalendarEvent {
  StartTimestamp?: number;
  EndTimestamp?: number;
  PracticeId?: string;
  uid?: string;
  IndexBucketName?: string;
}

export interface DoctorLeaveInfo {
  Reason: string;
}

export interface VisitInfo {
  VisitType?: VISIT_TYPE;
  BenefitCheckMessageId?: string;
  BenefitCheckStatus?: BENEFIT_CHECK_STATUS;
  BenefitCheckLines?: BenefitCheckLine[];
  Invoiced?: boolean;
  InvoiceId?: string;
  InvoiceAmount?: number;
  InvoiceNo?: string;
  EmailReminderSent?: boolean;
  SMSReminderSent?: boolean;
  ClinicalNotesCaptured?: boolean;
  CashAccount?: boolean;
  CustomVisitTypeName?: string;
  VisitReasonDescription?: string;
  AuthorizationNo?: string;
  PatientInfo?: PatientInfo;
  AccountInfo?: AccountInfo;
  WaitingRoomInfo?: WaitingRoomInfo;
  IsWalkIn?: boolean;
  AppointmentNote?: string;
  NoteToProvider?: string;
  CreateCashInvoiceOnCheckout?: boolean;
  BenefitCheckResponse?: BenefitCheckResponse;
}

export interface PatientInfo {
  PatientId?: string;
  UUID?: string;
  Name?: string;
  Surname?: string;
  IdentityNo?: string;
  DateOfBirth?: Date | any;
  Cellphone?: string;
  Email?: string;
  Insurer?: string;
  InsurancePolicyNo?: string;
  InsuranceBroker?: string;
  DependantCode?: string;
  FileNumber?: string;
}

export interface AccountInfo {
  AccountId?: string;
  AccountNo?: string;
  AccountType?: ACCOUNT_TYPE;
  AccountStatus?: ACCOUNT_STATUS;
  PatientLiable?: number;
  Scheme?: string;
  Plan?: string;
  Option?: string;
  SchemeName?: string;
  PlanName?: string;
  OptionName?: string;
  MemberNumber?: string;
}

class WaitingRoomInfo {
  VideoChatJoinedUsersCount: number;
  CheckInAppliedTime: Date;
  WaitingRoomPosition: number;
}

export enum RECURRENT_DAY_OF_WEEK {
  SUNDAY = 'SUN',
  MONDAY = 'MON',
  TUESDAY = 'TUE',
  WEDNESDAY = 'WED',
  THURSDAY = 'THU',
  FRIDAY = 'FRI',
  SATURDAY = 'SAT',
}

export enum CALENDAR_EVENT_TYPE {
  DOCTOR_LEAVE = 'Doctor leave',
  PATIENT_VISIT = 'Patient visit',
}

export enum CALENDAR_EVENT_STATUS {
  BOOKED = 'Booked',
  DELETED = 'Deleted',
  CHECKED_IN = 'Checked in',
  CHECKED_OUT = 'Checked out',
  CANCELLED = 'Cancelled',
  NO_SHOW = 'No-show',
}

export enum CLINICAL_CALENDAR_EVENT_STATUS {
  IN_PROGRESS = "In-progress"
}

export enum FREQUENCY {
  DO_NOT_REPEAT = 'Do not repeat',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum CHECKIN_OPTIONS {
  CREATE_NEW_ACCOUNT = 'Create a new account',
  ADD_TO_EXISTING = 'Add to existing account'
}
