import { Injectable } from '@angular/core';
import MeiliSearch from 'meilisearch';
import { environment } from '../../../environments/environment';

const searchClient = new MeiliSearch({
  host: environment.meilisearchLookup,
  apiKey: environment.meilisearchLookupKey,
});

@Injectable({
  providedIn: 'root',
})
export class BusinessSearchService {
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }
  async searchBusiness(searchStr: string, filter: string[] = [], sort: string[] = [])  {
    const index = searchClient.index(`business`);
    return await index.search(searchStr, { sort, filter });
  }
}
