import {
  BasePdfReportData, CategorySummaryItem, CellType, DetailsItem,
  JournalReportModel, PDFCellData,
  PDFParameter,
  REPORT_NAME,
  ReportHeader,
  ReportTable, SchemeSummaryItem
} from "@meraki-flux/schema";

export class JournalPdfReportDataBuilder {

    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(journalReportModel: JournalReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = journalReportModel.JournalReportInfo.PracticeId
        reportData.orientation = "landscape";
        reportData.headers = this.buildHeader(journalReportModel);
        reportData.tables = this.buildTables(journalReportModel);
        reportData.dateHeader = `Date generated ${journalReportModel.ReportDate}`;
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.footer = {
            hbLogo: true
        }
        return reportData;
    }

    private buildHeader(journalReportModel: JournalReportModel): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({name: 'Billing practice no.', data: {value: journalReportModel.JournalReportInfo.PracticeId, decoration: {bold: true}}});
        leftParameters.push({name: 'Practice', data: {value: journalReportModel.JournalReportInfo.Practice, decoration: {bold: true}}});

        const rightParameters: PDFParameter[] = [
            {name: 'Scheme', data: {value: journalReportModel.JournalReportInfo.Scheme, decoration: {bold: true}}},
            {name: 'Payment date range', data: {value: journalReportModel.JournalReportInfo.PaymentDateRange, decoration: {bold: true}}},
        ]
        return [
            {
                name: REPORT_NAME.JOURNAL,
                nameColor: JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                includeGeneratedDate: true,
                left: leftParameters,
                right: rightParameters
            }
        ];
    }

    private buildTables(journalReportModel: JournalReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
            reportTables.push(this.buildCategorySummaryTable(journalReportModel.JournalProviderModel.CategorySummaryItem));
            reportTables.push(this.buildSchemeSummaryTable(journalReportModel.JournalProviderModel.SchemeSummaryItem));
          reportTables.push(this.buildDetailsTable(journalReportModel.JournalProviderModel.DetailsItem, 'Details'));
        return reportTables;
    }

    private buildCategorySummaryTable(categorySummaryItem: CategorySummaryItem[]): ReportTable {
        const table: ReportTable = {};
        table.name = 'Summary';
        table.nameColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.headers = [{rows: [[{name: '', data: {value: 'Totals per journal category', decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Category', decoration: {bold: true}},
            {value: 'Total', decoration: {bold: true}},
        ];
        table.rowHeadersBold = true;
        const rows = [];

        let total = 0;
        categorySummaryItem.forEach((item: CategorySummaryItem) => {
            total += item.Total;

            const row: PDFCellData[] = [];
            row.push({value: item.Category});
            row.push({value: item.Total, type: CellType.NUMBER});
            rows.push(row);
        });
        const row: PDFCellData[] = [];
        row.push({value: "", decoration: {bold: true}});
        row.push({value: total, type: CellType.NUMBER, decoration: {bold: true}});
        rows.push(row);
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        return table;
    }

    private buildSchemeSummaryTable(schemeSummaryItem: SchemeSummaryItem[]): ReportTable {
        const table: ReportTable = {};
        //table.name = 'Summary';
        table.nameColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.headers = [{rows: [[{name: '', data: {value: 'Totals per scheme', decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Scheme', decoration: {bold: true}},
            {value: 'Total', decoration: {bold: true}},
        ];
        table.rowHeadersBold = true;
        const rows = [];

        let total = 0;
        schemeSummaryItem.forEach((item: SchemeSummaryItem) => {
            total += item.Total;

            const row: PDFCellData[] = [];
            row.push({value: item.Scheme});
            row.push({value: item.Total, type: CellType.NUMBER});
            rows.push(row);
        });
        const row: PDFCellData[] = [];
        row.push({value: "", decoration: {bold: true}});
        row.push({value: total, type: CellType.NUMBER, decoration: {bold: true}});
        rows.push(row);
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        return table;
    }

    private buildDetailsTable(detailsItem: DetailsItem[], tableName: string): ReportTable {
        const table: ReportTable = {};
        table.name = 'Details';
        table.nameColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = JournalPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.headers = [{rows: [[{name: "", data: {value: tableName, decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [

            {value: 'Scheme', decoration: {bold: true}},
            {value: 'Payment date', decoration: {bold: true}},
            {value: 'EFT no.', decoration: {bold: true}},
            {value: 'RA date', decoration: {bold: true}},
            {value: 'RA no.', decoration: {bold: true}},
            {value: 'Journal category', decoration: {bold: true}},
            {value: 'Journal description', decoration: {bold: true}},
            {value: 'Journal amount', decoration: {bold: true}},
            {value: 'Recon type', decoration: {bold: true}},
            {value: 'Date captured', decoration: {bold: true}},
        ];


        const rows = [];
        detailsItem.forEach((item: DetailsItem) => {
            const row: PDFCellData[] = [];

            row.push({value: item.Scheme}),
            row.push({value: item.PaymentDate, type: CellType.GENERAL}),
            row.push({value: item.EFTNo});
            row.push({value: item.RADate, type: CellType.GENERAL});
            row.push({value: item.RANo});
            row.push({value: item.JournalCategory});
            row.push({value: item.JournalDescription});
            row.push({value: item.JournalAmount, type: CellType.CURRENCY});
            row.push({value: item.ReconType});
            row.push({value: item.DateCaptured, type: CellType.GENERAL});

            rows.push(row);
        });
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        if (!rows || rows.length === 0) {
            table.rows = undefined;
            table.rowHeaders = undefined;
            table.noRecordsText = {
                value: "No records",
                decoration: {
                    italics: true
                }
            }
        }
        return table;
    }

}
