import { Injectable } from "@angular/core";
import {
  Account,
  BaseAccountMember,
  Practice,
  ProofOfPaymentReportRequest,
  REPORT_FORMAT_TYPE,
  REPORT_NAME,
  Report, BasePdfReportData
} from "@meraki-flux/schema";
import { ReportUtils } from "../report-utils";
import { ProofOfPaymentPdfReportDataBuilder } from "./proof-of-payment-pdf-report-data-builder";
import { ProofOfPaymentReportBuilder } from "./proof-of-payment-report-builder";
import { BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class ProofOfPaymentReportService {

  async generateReport(reportRequest: ProofOfPaymentReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildProofOfPaymentReportFileName(
      reportRequest.Practice.PracticeName, reportRequest.Account.AccountNo,
      reportRequest.AccountMember.Name, reportRequest.AccountMember.Surname,
      REPORT_FORMAT_TYPE.PDF);

    const reportGenerator = new BasePdfGenerator(await this.generatePdfReportData(reportRequest, reportRequest.Practice,
      reportRequest.Account, reportRequest.AccountMember));
    return {
      fileName: fileName,
      reportName: REPORT_NAME.PROOF_OF_PAYMENT,
      format: REPORT_FORMAT_TYPE.PDF,
      practiceId: reportRequest.Practice.Id,
      content: reportGenerator.generateReport()
    }
  }

  private async generatePdfReportData(reportRequest: ProofOfPaymentReportRequest, practice: Practice, account: Account, mainMember: BaseAccountMember): Promise<BasePdfReportData> {
    return new ProofOfPaymentPdfReportDataBuilder().build(
      await new ProofOfPaymentReportBuilder().build(reportRequest, practice, account, mainMember));
  }
}
