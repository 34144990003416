import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData, PaymentCorrectionDetailsItem,
  PaymentCorrectionProviderModel,
  PaymentCorrectionReportModel, PaymentCorrectionSummaryItem,
  REPORT_NAME, Sheet, Table
} from "@meraki-flux/schema";

export class PaymentCorrectionsXlsReportDataBuilder {


    build(paymentCorrectionReportModel: PaymentCorrectionReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = paymentCorrectionReportModel.PaymentCorrectionReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.PAYMENT_CORRECTIONS;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, paymentCorrectionReportModel);

        //const isOptometrist: boolean = creditNoteReportModel.CreditNoteReportInfo.Speciality == Speciality.OPTOMETRIST;

        if (!paymentCorrectionReportModel.AllProvidersModel) {
            paymentCorrectionReportModel.ProviderModels.forEach((providerModel) => {
            excelReportData.data.push(
                this.buildReportSheetSummary(providerModel)
            );
            excelReportData.data.push(
                this.buildReportSheetDetail(providerModel, paymentCorrectionReportModel.PaymentCorrectionReportInfo.IsMultiBranch, false)
            );
          });
        } else {
          excelReportData.data.push(
            this.buildReportSheetSummary(paymentCorrectionReportModel.AllProvidersModel)
          );
          paymentCorrectionReportModel.ProviderModels.forEach((providerModel) => {
            if(providerModel.DetailsTable.length > 0){
                excelReportData.data.push(
                    this.buildReportSheetDetail(providerModel, paymentCorrectionReportModel.PaymentCorrectionReportInfo.IsMultiBranch,true)
                );
            }
          });
        }

        return excelReportData;
    }

    private buildReportSheetSummary(providerModel: PaymentCorrectionProviderModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary' //providerModel.ProviderName.substring(0,30);
        sheet.header = 'Payment Corrections Report'
        sheet.tables = [];

        const summaryTable: Table = {};
        //summaryTable.name = 'Summary';
        summaryTable.headers = [
        {value: 'Provider', bold: true, width: CellWidth.M},
        {value: 'Payment corrections total', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        let totalPayment_Corrections = 0;

        const rows = [];
        providerModel.SummaryTable.forEach((item: PaymentCorrectionSummaryItem) => {

            totalPayment_Corrections += item.Payment_correction? item.Payment_correction : 0;

            const row: ExcelCellData[] = [];
            row.push({value: item.Provider});
            row.push({value: item.Payment_correction? item.Payment_correction : 0 , type: CellType.CURRENCY});
            rows.push(row);
        });
        const row: ExcelCellData[] = [];
        row.push({value: 'Total', bold: true});
        row.push({value: totalPayment_Corrections? totalPayment_Corrections : 0 , type: CellType.CURRENCY, bold: true});
        rows.push(row);

        rows.push([{ value: '' }]);

        summaryTable.rows = rows;
        sheet.tables.push(summaryTable);

        return sheet;
    }

    private buildReportSheetDetail(providerModel: PaymentCorrectionProviderModel, addBranchColumn?: boolean, isAllProvider?: boolean): Sheet {
        const sheet: Sheet = {};
        sheet.name = isAllProvider? providerModel.ProviderName.substring(0,30) : 'Detail';
        sheet.header = 'Payment Corrections Report';
        sheet.tables = [];

        const detailsTable: Table = {};

        detailsTable.headers = [
            {value: 'Payment correction date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Liability to increase', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Correction amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Reason', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Captured by', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Account no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Patient', bold: true, width: CellWidth.XS},
            {value: 'Invoice no.', bold: true, width: CellWidth.XS},
            {value: 'Date of service', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Date of submission', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Invoice amount', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Provider', bold: true, width: CellWidth.XS},
        ];

        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.XS});
        }

        const detailsRows = [];
        providerModel.DetailsTable.forEach((item: PaymentCorrectionDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.PaymentCorrectionDate, type: CellType.GENERAL});
            row.push({value: item.LiabilityToIncrease});
            row.push({value: item.CorrectionAmount, type: CellType.CURRENCY});
            row.push({value: item.Reason});
            row.push({value: item.CaptureBy});
            row.push({value: item.AccountNo});
            row.push({value: item.Patient});
            row.push({value: item.InvoiceNo});
            row.push({value: item.DateOfService, type: CellType.GENERAL});
            row.push({value: item.DateofSubmission, type: CellType.GENERAL});
            row.push({value: item.InvoiceAmount, type: CellType.CURRENCY});
            row.push({value: item.Provider});
            if (addBranchColumn)
                row.push({value: item.Branch});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, paymentCorrectionReportModel: PaymentCorrectionReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.PracticeId, type: CellType.GENERAL}
        });

        if (paymentCorrectionReportModel.PaymentCorrectionReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.Branch, type: CellType.GENERAL}
                });
        }

        excelReportData.parameters.push(
            {
                name: 'Date Range Type',
                   data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.DateRangeType, type: CellType.GENERAL}
            });

        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.DateRange, type: CellType.GENERAL}
            });

        excelReportData.parameters.push(
            {
                name: 'Treating Provider',
                data: {value: paymentCorrectionReportModel.PaymentCorrectionReportInfo.TreatingProvider, type: CellType.GENERAL}
            });
    }
}
