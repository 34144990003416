<ng-template #info_blank>
  <div class="flex flex-col">
    <label>&nbsp;</label>
    <label>&nbsp;</label>
  </div>
</ng-template>

<ng-template #info let-title let-content="content">
  <div class="flex flex-col">
    <label class="text-hb-text-subdued text-xs">{{title}}</label>
    <label class="text-hb-text-default font-medium text-sm mt-0.5">{{ content || 'Not specified' }}</label>
  </div>
</ng-template>

<ng-template #balanceItem let-label let-value="value" let-color="color">
  <div class="balance-item" [ngClass]="color">
    <ng-container
      *ngTemplateOutlet="info; context: { $implicit: label, content: value | currency :'R ': 'symbol' : '1.2-2' }">
    </ng-container>
  </div>
</ng-template>

<div class="flex flex-col h-full w-full  overflow-hidden space-y-4"
  *ngIf="{ account: account$ | async, totalRemaining: helper?.getTotalRemaining(raClaim, getPayableInvoices(), form.value.UnallocatedAmount)/100} as context"
  [formGroup]="form">

  <!--eRA Details-->
  <div class="flex flex-row justify-start">

    <div class="flex flex-col gap-4 w-1/3">

      <div class="flex flex-row align-middle">
        <div class=" bg-hb-accent-20 w-10 h-10 rounded-full text-center mr-3">
          <span
            class="font-medium text-base text-hb-accent-100 leading-10 overflow-ellipsis whitespace-nowrap">RA</span>
        </div>
        <ng-container *ngTemplateOutlet="info; context: { $implicit: 'RA no.', content: ra?.Payment?.RANum }">
        </ng-container>
      </div>

      <ng-container
        *ngTemplateOutlet="info; context: { $implicit: 'RA date', content: ra?.Payment?.RADate| date:'dd/MM/yyyy' }">
      </ng-container>

    </div>

    <!--Col 2-->
    <div class="flex flex-col gap-4 w-1/3">

      <div class="flex flex-row align-middle">
        <div class="bg-hb-accent-20 w-10 h-10 rounded-full text-center mr-3">
          <span class="font-medium text-base text-hb-accent-100 leading-10 overflow-ellipsis whitespace-nowrap">
            {{ ra?.SchemeName | initialsPipe }}
          </span>
        </div>
        <ng-container *ngTemplateOutlet="info; context: { $implicit: 'Scheme', content: ra?.SchemeName }">
        </ng-container>
      </div>

      <ng-container *ngTemplateOutlet="info; context: { $implicit: 'EFT no.', content: ra?.Payment?.EFTNum}">
      </ng-container>
    </div>

    <!--Col 3-->
    <div class="flex flex-col gap-4 w-1/3">

      <ng-container *ngTemplateOutlet="info_blank"></ng-container>

      <ng-container
        *ngTemplateOutlet="info; context: { $implicit: 'Payment date', content: ra?.Payment?.PayDate| date:'dd/MM/yyyy' }">
      </ng-container>
    </div>

  </div>

  <!--Line separator-->
  <div class="h-[1px] bg-hb-base-divider-grey"></div>

  <mat-expansion-panel class="red" hideToggle expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="flex flex-col">
        <span class="w-full font-bold text-sm pb-1">Unmatched remittance</span>
        <div class="w-full flex flex-row">
          <label class="w-1/2">{{raClaim?.PatName }} {{raClaim?.PatSurname}}
            ({{raClaim?.MemNum}})</label>
          <label class="w-1/4">Visit on {{raClaim?.LineItem[0]?.DOS | date: 'dd/MM/yyyy'}}</label>
          <label class="w-1/4 text-right font-medium text-hb-critical-100">
            {{raClaim?.TotPaymentAmount/100 | currency : 'R ' : 'symbol' : '1.2-2'}} paid
          </label>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table class='ra-claim-lines-table flux-table mb-4' [dataSource]="raClaim?.LineItem">

      <!-- Line no. column -->
      <ng-container matColumnDef="line">
        <mat-header-cell *matHeaderCellDef>Line</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <p>{{ i + 1 }}</p>
        </mat-cell>
      </ng-container>


      <!-- Tariff code column -->
      <ng-container matColumnDef="tariffCode">
        <mat-header-cell *matHeaderCellDef>Tariff code</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <p>{{ row?.ChargeCode || '-' }}</p>
        </mat-cell>
      </ng-container>

      <!-- Nappi code column -->
      <ng-container matColumnDef="nappiCode">
        <mat-header-cell *matHeaderCellDef>Nappi code</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <p>{{ row?.NappiCode || '-' }}</p>
        </mat-cell>
      </ng-container>

      <!-- Claimed amount column -->
      <ng-container matColumnDef="claimed">
        <mat-header-cell *matHeaderCellDef class="text-right">Claimed</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <span class="w-full text-right">
            {{ row?.PayAdvice?.TariffAmount/100 | currency : ' ' : 'symbol' : '1.2-2' }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Tariff amount column -->
      <ng-container matColumnDef="tariff">
        <mat-header-cell *matHeaderCellDef class="text-right">Tariff amount</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <span class="w-full text-right">
            {{ row?.PayAdvice?.TariffAmount/100 | currency : ' ' : 'symbol' : '1.2-2' }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Paid to provider amount column -->
      <ng-container matColumnDef="paid">
        <mat-header-cell *matHeaderCellDef class="text-right">Paid to provider</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <span class="w-full text-right">
            {{ row?.PayAdvice?.PaymentAmount/100 | currency : ' ' : 'symbol' : '1.2-2' }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Rejected amount column -->
      <ng-container matColumnDef="rejected">
        <mat-header-cell *matHeaderCellDef class="text-right">Amount rejected</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <span class="w-full text-right">
            {{ (row?.PayAdvice?.TariffAmount - row?.PayAdvice?.PaymentAmount)/100 | currency : ' ' : 'symbol' :
            '1.2-2' }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Reason code -->
      <ng-container matColumnDef="reasoncode">
        <mat-header-cell *matHeaderCellDef class="text-right">Reason code</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <span [matTooltip]="raUtils.formatReasonCodeAndDescs(row?.Reason, '\n')" class="w-full text-right">
            {{ raUtils.formatReasonCodes(row?.Reason, ', ') }}
          </span>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: helper?.getRaClaimLineColumns()"></mat-row>
      <mat-header-row *matHeaderRowDef="helper?.getRaClaimLineColumns()"></mat-header-row>
    </mat-table>
  </mat-expansion-panel>

  <!-- Total banners -->
  <div class="flex flex-row justify-start space-x-6">

    <!--Total to allocate-->
    <ng-container
      *ngTemplateOutlet="balanceItem; context: { $implicit: 'Total to allocate', value: totalToAllocate, color: 'orange' }">
    </ng-container>

    <!--Allocated--->
    <ng-container
      *ngTemplateOutlet="balanceItem; context: { $implicit: 'Allocated', value: getTotalAllocated()/100, color: 'green' }">
    </ng-container>

    <!--Remaining--->
    <ng-container
      *ngTemplateOutlet="balanceItem; context: { $implicit: 'Remaining', value: context.totalRemaining, color: context.totalRemaining < 0 ? 'red' : context.totalRemaining > 0 ? 'orange' : 'green' }">
    </ng-container>

  </div>

  <!-- Account with invoices block -->
  <div *ngIf="form.value.CouldNotReconcile !== true">
    <!-- Account block --->
    <div class="flex flex-col gap-3 bg-hb-accent-5 p-2 pb-4 mb-4">
      <mat-label>Account</mat-label>
      <div class="flex flex-row gap-3">
        <mat-form-field class="flux-form-field w-full flux-light-field">
          <input #searchField id='searchField' matInput formControlName="AccountSearch" [matAutocomplete]="auto">
          <mat-label i18n>Search on name, surname, account no., file no. or member no.</mat-label>
          <mat-icon class="mr-1" matPrefix>search</mat-icon>
          <button *ngIf="!!searchField?.value" matSuffix mat-icon-button (click)="onAccountResetClick($event)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAccountSelected($event?.option?.value)">

          <ng-container *ngIf="accountSearchResults$ | async as results ">
            <!-- Search Results -->
            <mat-option class="!h-8" disabled *ngIf="accountSearchBusy$ | async">
              <mat-progress-bar class="search-busy-indicator" mode="indeterminate"></mat-progress-bar>
            </mat-option>

            <mat-option class="min-w-[300px] !h-8 !text-xs" *ngFor="let result of results" [value]="result">
              {{ accountPatientDisplayFn(result) }}
            </mat-option>

            <mat-option
              *ngIf="results.length === 0 && form.value.AccountSearch && (accountSearchBusy$ | async) === false"
              class="min-w-[300px] !h-8 !text-xs" disabled>
              No Results
            </mat-option>
          </ng-container>

        </mat-autocomplete>

      </div>

      <div class="flex flex-1 flex-row items-center space-x-6" *ngIf="context?.account?.Id && positivePaid">

        <!-- Allocated to account -->
        <div *ngVar="getAccountAllocated(form.value.UnallocatedAmount) / 100 as value">
          <ng-container class="w-[200px] min-w-[50px] max-w-[250px]"
            *ngTemplateOutlet="balanceItem; context: { $implicit: 'Allocated to account', value: value || 0, color : value === totalToAllocate ? 'green' : 'orange' }">
          </ng-container>
        </div>

        <!-- Unallocated amount -->
        <mat-form-field class="flux-form-field flux-light-field w-[200px] min-w-[50px] max-w-[250px]">
          <mat-label i18n>Unallocated amount</mat-label>
          <input id='unallocatedAmountInput' matInput currencyMask formControlName="UnallocatedAmount"
            [options]="{ prefix: 'R ', thousands: ',', decimal: '.', align: 'left', allowNegative : false}">
        </mat-form-field>

        <!-- Outstanding balances only checkbox -->
        <mat-checkbox class="min-w-[50px]" formControlName="OutstandingBalanceInvoices">
          <div class="whitespace-pre-wrap">Outstanding balances only</div>
        </mat-checkbox>

        <!-- Filter on claim matches checkbox -->
        <mat-checkbox class="min-w-[50px]" formControlName="FilterOnClaimMatches">
          <div class="whitespace-pre-wrap">Filter on claim matches</div>
        </mat-checkbox>
      </div>
    </div>

    <div *ngVar="invoices$ | async as invoices">
      <div *ngIf="positivePaid">
        <ng-container *ngFor="let invoice of invoices; let invoiceIndex = index">
          <meraki-flux-ma-payable-invoice [invoice]="invoice" [invoiceIndex]="invoiceIndex"
            [hideHeader]="invoiceIndex > 0">
          </meraki-flux-ma-payable-invoice>
          <mat-divider class="invoices-divider"></mat-divider>
        </ng-container>
      </div>

      <div class="mt-4" *ngIf="!positivePaid && context?.account?.Id && invoices?.length > 0">
        <meraki-flux-debit-invoice [totalDebitAmount]="totalToAllocate"
          [invoices]="invoices" [ra]="ra"></meraki-flux-debit-invoice>
      </div>

      <!-- No invoices banner -->
      <meraki-flux-info-box id='noInvoicesBanner' type="HELP" icon="info_outlined"
        *ngIf="context?.account?.Id && invoices && invoices.length === 0">
        <div class="my-4">No invoices found for the selected account</div>
      </meraki-flux-info-box>
    </div>
  </div>

  <!-- Could not reconcile -->
  <div class="flex flex-row space-x-2">
    <mat-slide-toggle class="flux-toggle" id="enableSlideToggle" formControlName="CouldNotReconcile" (change)="onCouldNotReconcile($event)">
      Could not reconcile
    </mat-slide-toggle>
    <mat-icon class="!text-hb-accent-100" fontSet="material-icons-outlined"
      matTooltip="If you cannot reconcile this item, please provide a reason in the remittance notes field below and save. The item will then no longer be on the suspense account to reconcile, and you can view its status and notes in the RA Statement report.">
      info
    </mat-icon>
  </div>

  <!-- Additional remittance notes -->
  <div class="flex flex-row items-center">
    <mat-form-field class="flux-form-field w-full">
      <mat-label>Additional remittance notes</mat-label>
      <input id='raNotesInput' class="font-semibold w-full" matInput formControlName='AdditionalRemittanceNotes'
        [required]="form.value.CouldNotReconcile === true">
    </mat-form-field>
    <mat-icon class="!text-hb-accent-100 mx-2" fontSet="material-icons-outlined"
      matTooltip="This note will be saved against the RA, and will be visible on the RA Statement report.">
      info
    </mat-icon>
  </div>

  <!-- Additional account notes -->
  <div class="flex flex-row items-center" *ngIf="accountId$ | async">
    <mat-form-field class="flux-form-field w-full">
      <mat-label>Additional account notes</mat-label>
      <input id='accountNotesInput' class="font-semibold w-full" matInput formControlName='AdditionalAccountNotes'>
    </mat-form-field>
    <mat-icon class="!text-hb-accent-100 mx-2" fontSet="material-icons-outlined"
      matTooltip="This note will be saved against the patient account.">
      info
    </mat-icon>
  </div>

  <!-- Action Buttons -->
  <div class="flux-page-actions-container">
    <ng-container *ngVar="saveState$ | async as state">
      <div class="flex flex-row w-full justify-end">
        <div class="flex flex-row gap-2">
          <ng-content></ng-content>
          <div *ngIf="(componentState$ | async) === 'Ready'" class="flex flex-row gap-2">
            <button mat-flat-button class="flux-button" color="primary" [disabled]="state !== 'Ready'"
              (click)="onSave()">
              <div class="flex flex-row gap-3 items-center">
                <mat-spinner class="save-spinner" *ngIf="state === 'Busy'" diameter="24">
                </mat-spinner>
                <span>{{state === 'Busy' ? 'Saving...' : 'Save' }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>