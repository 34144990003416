import * as moment from "moment";
import {
  ACCOUNT_ENTITY_TYPE, ACCOUNT_TYPE,
  AccountDetailsData,
  AccountDetailsReportModel,
  AccountDetailsReportRequest,
  BaseAccount,
  BaseAccountMember, ClaimDetailDataTable, NextOfKinContact, PDFCellData,
  Practice
} from "@meraki-flux/schema";

export class AccountDetailsReportBuilder {

  private NOW = new Date();

  private readonly DATE_FORMAT = "DD/MM/YYYY";

  async build(reportRequest: AccountDetailsReportRequest, practice: Practice, account: BaseAccount, mainMember: BaseAccountMember,
              patient: BaseAccountMember, scheme: string, plan: string, option: string): Promise<AccountDetailsReportModel> {
    return {
      ReportName: `${patient.Name} ${patient.Surname}`,
      ReportDate: moment(this.NOW).format(this.DATE_FORMAT),
      AccountDetailsData: await this.buildAccountDetailsData(patient, mainMember, account, scheme, plan, option),
      NextOfKin: await this.buildNextOfKin(account),
      PatientDisclaimer: practice.Settings?.PatientDisclaimerText
    };
  }

  async buildAccountDetailsData(patient: BaseAccountMember, mainMember: BaseAccountMember, account: BaseAccount, scheme: string, plan: string, option: string): Promise<AccountDetailsData> {
    return {
      patient: await this.buildPatientDetails(patient),
      accountDetails: await this.buildAccountDetails(account, scheme, plan, option),
      mainMember: await this.buildMainMember(mainMember),
    }
  }

  private async buildPatientDetails(patient: BaseAccountMember): Promise<ClaimDetailDataTable> {
    const result = []
    let preferred = "";
    if (patient.PreferredName) {
      preferred = ` (${patient.PreferredName})`
    }
    result.push(this.addDetailRow("First name", {value: `${patient.Name ?? ""}${preferred}`}, "Surname", {value: patient.Surname ?? ""}));
    result.push(this.addDetailRow("ID no.", {value: patient.IdentityNo}, "Date of birth", {value: moment(patient.DateOfBirth).format(this.DATE_FORMAT)}))
    result.push(this.addDetailRow("Gender", {value: patient.Gender ?? ""}, "Patient file no.", {value: patient.PatientFileNo ?? ""}));
    result.push(this.addDetailRow("Relationship to main member", {value: patient.RelationshipToMainMember ?? ""}, "Dep. code", {value: patient.DependantCode ?? ""}));
    result.push(this.addDetailRow("Cellphone no.", {value: patient.Contact?.Cellphone ?? ""}, "Email", {value: patient.Contact?.Email ?? ""}));
    result.push(this.addDetailRow("Physical address", {value: `${patient.Contact?.PhysicalAddress?.Line1 ?? ""}\n${patient.Contact?.PhysicalAddress?.Line2 ?? ""}\n${patient.Contact?.PhysicalAddress?.Line3 ?? ""}\n${patient.Contact?.PhysicalAddress?.Code ?? ""}`},
      "Postal address", {value: `${patient.Contact?.PostalAddress?.Line1 ?? ""}\n${patient.Contact?.PostalAddress?.Line2 ?? ""}\n${patient.Contact?.PostalAddress?.Line3 ?? ""}\n${patient.Contact?.PostalAddress?.Code ?? ""}`}));
    result.push(this.addDetailRow("Occupation", {value: patient.Occupation ?? ""}, "Employer", {value: patient.Employer ?? ""}));
    return {
      rows: result
    }
  }

  private async buildAccountDetails(account: BaseAccount, scheme: string, plan: string, option: string) {
    const result = []
    if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.PATIENT) {
      if (account.AccountType === ACCOUNT_TYPE.MEDICAL_AID) {
        result.push(this.addDetailRow("Type", {value: account.AccountType ?? ""}, "Scheme", {value: scheme ?? ""}));
        result.push(this.addDetailRow("Account no.", {value: account.AccountNo ?? ""}, "Plan", {value: plan ?? ""}));
        result.push(this.addDetailRow("", {value: ""}, "Option", {value: option ?? ""}));
        result.push(this.addDetailRow("", {value: ""}, "Member no.", {value: account.MemberNo ?? ""}));
      } else if (account.AccountType === ACCOUNT_TYPE.PRIVATE) {
        result.push(this.addDetailRow("Type", {value: account.AccountType ?? ""}, "", {value: ""}));
        result.push(this.addDetailRow("Account no.", {value: account.AccountNo ?? ""}, "", {value: ""}));
      }
    } else if (account.AccountEntityType === ACCOUNT_ENTITY_TYPE.COMPANY) {
      result.push(this.addDetailRow("Company", {value: account.CompanyName}, "", {value: ""}));
      result.push(this.addDetailRow("Company reg. no.", {value: account.CompanyRegistrationNo ?? ""}, "VAT no.", {value: account.VATNo ?? ""}));
      result.push(this.addDetailRow("Type", {value: account.AccountType ?? ""}, "Scheme", {value: scheme ?? ""}));
      result.push(this.addDetailRow("Account no.", {value: account.AccountNo ?? ""}, "Plan", {value: plan ?? ""}));
      result.push(this.addDetailRow("", {value: ""}, "Member no.", {value: account.MemberNo ?? ""}));
    }
    return {
      rows: result
    }
  }

  private async buildMainMember(mainMember: BaseAccountMember) {
    const result = []
    let preferred = "";
    if (mainMember.PreferredName) {
      preferred = ` (${mainMember.PreferredName})`
    }
    result.push(this.addDetailRow("First name", {value: `${mainMember.Name ?? ""}${preferred}`}, "Surname", {value: mainMember.Surname ?? ""}));
    result.push(this.addDetailRow("ID no.", {value: mainMember.IdentityNo}, "Date of birth", {value: moment(mainMember.DateOfBirth).format(this.DATE_FORMAT)}))
    result.push(this.addDetailRow("Cellphone no.", {value: mainMember.Contact?.Cellphone ?? ""}, "Email", {value: mainMember.Contact?.Email ?? ""}));
    result.push(this.addDetailRow("Physical address", {value: `${mainMember.Contact?.PhysicalAddress?.Line1 ?? ""}\n${mainMember.Contact?.PhysicalAddress?.Line2 ?? ""}\n${mainMember.Contact?.PhysicalAddress?.Line3 ?? ""}\n${mainMember.Contact?.PhysicalAddress?.Code ?? ""}`},
      "Postal address", {value: `${mainMember.Contact?.PostalAddress?.Line1 ?? ""}\n${mainMember.Contact?.PostalAddress?.Line2 ?? ""}\n${mainMember.Contact?.PostalAddress?.Line3 ?? ""}\n${mainMember.Contact?.PostalAddress?.Code ?? ""}`}));
    return {
      rows: result
    }
  }

  private addDetailRow(h1: string, v1: PDFCellData, h2: string, v2: PDFCellData) {
    return {
      header1: h1,
      value1: v1,
      header2: h2,
      value2: v2,
    }
  }

  private async buildNextOfKin(account: BaseAccount) {
    const rowHeaders = await this.buildRowHeaders();
    const rows = [];
    if (account.NextOfKin?.ContactPersonOne && this.hasNextKinData(account.NextOfKin.ContactPersonOne)) {
      rows.push(await this.buildTableRow(account.NextOfKin.ContactPersonOne));
    }
    if (account.NextOfKin?.ContactPersonTwo && this.hasNextKinData(account.NextOfKin.ContactPersonTwo)) {
      rows.push(await this.buildTableRow(account.NextOfKin.ContactPersonTwo));
    }
    return {
      rowHeaders: rowHeaders,
      rows: rows,
      borders: {
        hor: true,
        outerborder: false,
        headerBorderSize: 1,
        rowBorderSize: 0.5
      }
    }
  }

  private async buildRowHeaders() {
    return [`Name${"\t\u200B".repeat(18)}`, `Contact no.${"\t\u200B".repeat(4)}`, `Email address${"\t\u200B".repeat(18)}`].map(n => {
      return {
        value: n
      }
    });
  }

  private async buildTableRow(contact: NextOfKinContact) {
    const row = [];
    row.push({
      value: contact.Name??""
    })
    row.push({
      value: contact.CellphoneNo??""
    })
    row.push({
      value: contact.EmailAddress??"",
    })
    return row;
  }

  private hasNextKinData(kin: NextOfKinContact) {
    return (kin.Name && kin.Name !== "") || (kin.CellphoneNo && kin.CellphoneNo !== "") || (kin.EmailAddress && kin.EmailAddress !== "");
  }
}
