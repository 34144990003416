import { Injectable } from '@angular/core';
import { createStore, setProp, Store, withProps } from '@ngneat/elf';
import { getActiveEntity, getActiveId, getEntity, selectActiveEntity, selectEntity, setActiveId, setEntities, updateEntities, withActiveId, withEntities, deleteEntities, resetActiveId } from '@ngneat/elf-entities';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {CAPTURE_INVOICE_MODE, CopyInvoiceContext, Invoice, InvoiceProvider} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class InvoiceRepository {
  private readonly invoiceStore!: Store;

  constructor() {
    this.invoiceStore = createStore({ name: 'invoice' },
      withEntities({ idKey: '_id' }),
      withActiveId(),
      withProps({}));
  }

  activeInvoice$() {
    return this.invoiceStore.pipe(selectActiveEntity());
  }

  activeInvoiceTreatingProvider$(): Observable<InvoiceProvider> {
    return this.invoiceStore.pipe(
      selectActiveEntity(),
      map((invoice: Invoice) => invoice?.TreatingProvider))
  }

  invoice$(invoiceId: string) {
    return this.invoiceStore.pipe(selectEntity(invoiceId));
  }

  createInvoice(invoice: Invoice) {
    invoice = this.trimStrings(invoice);
    this.invoiceStore.update(setEntities([invoice]));
  }

  updateInvoice(invoiceId: string, invoice: Invoice) {
    invoice = this.trimStrings(invoice);
    const existingState = this.getInvoice(invoiceId);
    this.invoiceStore.update(setEntities([{ _id: invoiceId, ...existingState, ...invoice }]));
  }

  invoiceExists(invoiceId: string) {
    return this.invoiceStore.query(getEntity(invoiceId));
  }

  getInvoice(invoiceId: string) {
    return this.invoiceStore.query(getEntity(invoiceId));
  }

  clearActiveInvoiceId() {
    this.invoiceStore.update(resetActiveId());
  }

  setActiveInvoiceId(invoiceId: string) {
    this.invoiceStore.update(setActiveId(invoiceId));
  }

  getActiveInvoiceId() {
    return getActiveId(this.invoiceStore.state);
  }

  getActiveInvoice() {
    return this.invoiceStore.query(getActiveEntity());
  }

  updateActiveInvoice(update: Partial<Invoice>) {
    const activeId = this.getActiveInvoiceId();
    this.invoiceStore.update(updateEntities(activeId, entity => ({ ...entity, ...update })));
  }

  deleteActiveInvoice() {
    const activeId = this.getActiveInvoiceId();
    this.invoiceStore.update(deleteEntities(activeId));
  }

  deleteInvoice(invoiceId: string) {
    this.invoiceStore.update(deleteEntities(invoiceId));
  }

  setInvoiceSearchFilterState(state: any) {
    this.invoiceStore.update(setProp('InvoiceSearchFilterState', state));
  }

  getInvoiceSearchFilterState() {
    return this.invoiceStore.state?.InvoiceSearchFilterState;
  }

  setInvoiceCopyContext(context: CopyInvoiceContext) {
    this.invoiceStore.update(setProp('CopyInvoiceContext', context));
  }

  getInvoiceCopyContext() {
    return this.invoiceStore.state?.CopyInvoiceContext;
  }

  setInvoiceCaptureMode(mode: CAPTURE_INVOICE_MODE) {
    this.invoiceStore.update(setProp('InvoiceCaptureMode', mode));
  }

  getInvoiceCaptureMode() {
    return this.invoiceStore.state?.InvoiceCaptureMode;
  }

  trimStrings(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'string' && obj[key]) {
        obj[key] = obj[key].trim();
      }
      if (typeof obj[key] === 'object') {
        this.trimStrings(obj[key]);
      }
    }
    return obj;
  }

}
