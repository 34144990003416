import {Practice} from "../practice.models";
import {DATE_RANGE_TYPE_TRN_REPORT} from "../report.models";
import { ReportRequestData } from './common.model';

export interface MonthlyTransactionReportRequest extends ReportRequestData {
  Practice?: Practice;
  DateFrom?: Date;
  DateTo?: Date;
  DateRangeType: DATE_RANGE_TYPE_TRN_REPORT;
  IsRecursive?: boolean
  UserId: string;
  Recipient?: string;
}

export interface MonthlyTransactionReportModel {
  ReportInfo?: MonthlyTransactionReportInfo;
  ReportDate?: string;
  Details?: MonthlyTransactionReportDetails
}

export interface MonthlyTransactionReportDetails {
  IsVatPractice?: boolean;

  OpeningDate?: Date,

  ClosingDate?: Date,

  TotalAdminAmount?: TaxableAmount;
  TotalProceduresAmount?: TaxableAmount;
  TotalConsumablesAmount?: TaxableAmount;
  TotalMedicinesAmount?: TaxableAmount;
  TotalModifierAmount?: TaxableAmount;
  TotalDebitNoteAmount?: TaxableAmount;
  TotalPaymentCorrectionAmount?: number;
  TotalRefundAmount?: number;

  TotalMedicalAidPaymentAmount?: number;
  TotalPatientPaymentAmount?: number;
  TotalSmallBalanceWriteOffAmount?: TaxableAmount;
  TotalBadDebtWriteOffAmount?: TaxableAmount;
  TotalCreditNotesAmount?: TaxableAmount;
}

export interface MonthlyTransactionReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  Month?: string,
  Year?: string,
  DateRangeType?: DATE_RANGE_TYPE_TRN_REPORT
}

export class TaxableAmount {
  amount = 0;
  vatAmount = 0;
  amountExVat = 0;

  constructor(amount: number, vatAmount: number, amountExVat: number) {
    this.amount = amount;
    this.vatAmount = vatAmount;
    this.amountExVat = amountExVat;
  }

  public addAmountWithVAT(amount: number, vatAmount?: number) {
    if (amount)
      this.amount += amount;
    if (vatAmount)
      this.vatAmount += vatAmount;
    if (vatAmount)
      this.amountExVat += (amount - vatAmount);
  }

  public addAmount(amount: number) {
    this.addAmountWithVAT(amount);
  }

  public addTaxableAmount(taxable: TaxableAmount) {
    this.addAmountWithVAT(taxable.amount, taxable.vatAmount);
  }
}
