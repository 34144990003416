
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'libs/common/src/environments/environment';
import {ChargeDto, ModifierParameterInfoDto} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class ModifierService {
  private billingModifierURL = `${environment.billingModifierURL}`;
  private token = `${environment.billingModifieTtoken}`

  constructor(private http: HttpClient) {}

  getModifierParameters(modifierParameterQuery: any): Observable<ModifierParameterInfoDto[]> {

    if (!this.token) {
      throw new Error('Token not found or invalid.');
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    const options = { headers: headers };

    return this.http.post<ModifierParameterInfoDto[]>(
      `${this.billingModifierURL}/ParameterInfo`,
      modifierParameterQuery,
      options
    );
  }

  modifyCharge(chargeDto: ChargeDto): Observable<ChargeDto> {

    if (!this.token) {
      throw new Error('Token not found or invalid.');
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });

    const options = { headers: headers };

    return this.http.post<ChargeDto>(
      `${this.billingModifierURL}/ModifyCharge`,
      chargeDto,
      options
    );
  }
}






