import {
  BasePdfReportData,
  CellType,
  GroupedByCodeDetails,
  INVOICE_LINE_TYPE,
  LineItemReportRequest,
  PDFCellData,
  PDFParameter,
  REPORT_NAME,
  ReportHeader,
  ReportTable,
  SummaryPart1Details
} from '@meraki-flux/schema';

export class LineItemPdfReportDataBuilder {
  private static REPORT_SPECIAL_FONT_COLOR = '#000000';

  build(reportRequest: LineItemReportRequest): BasePdfReportData {
    const reportData: BasePdfReportData = {};
    reportData.bpn = reportRequest.Practice.BillingPracticeNumber;
    reportData.orientation = 'landscape';
    reportData.headers = this.buildHeader(reportRequest);
    reportData.tables = this.buildTables(reportRequest);
    reportData.dateHeader = `Date generated ${reportRequest.ReportDate}`;
    reportData.footerText = '\t\u200B'.repeat(60);
    reportData.footer = {
      hbLogo: true,
    };
    return reportData;
  }

  private buildHeader(reportRequest: LineItemReportRequest): ReportHeader[] {
    const leftParameters: PDFParameter[] = [];
    leftParameters.push({
      name: 'Practice',
      data: { value: reportRequest.Practice.PracticeName, decoration: { bold: true } },
    });
    leftParameters.push({
      name: 'Billing practice no.',
      data: { value: reportRequest.Practice.BillingPracticeNumber, decoration: { bold: true } },
    });
    if (reportRequest.Multibranch) {
      leftParameters.push({
        name: 'Branch',
        data: { value: reportRequest.Branch.Name ?? 'All', decoration: { bold: true } },
      });
    }
    const rightParameters: PDFParameter[] = [
      { name: 'Scheme', data: { value: reportRequest.Scheme, decoration: { bold: true } } },
      { name: 'Date Range', data: { value: reportRequest.DateRange, decoration: { bold: true } } },
      {
        name: 'Provider',
        data: { value: reportRequest.TreatingProvider, decoration: { bold: true } },
      },
    ];
    return [
      {
        name: REPORT_NAME.LINEITEMBILLED,
        nameColor: LineItemPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
        includeGeneratedDate: true,
        left: leftParameters,
        right: rightParameters,
      },
    ];
  }

  private buildTables(reportRequest: LineItemReportRequest): ReportTable[] {
    const reportTables: ReportTable[] = [];
    reportTables.push(this.buildSummaryP1Table(reportRequest));
    reportTables.push(this.buildSummaryP2Table(reportRequest));
    reportTables.push(this.buildGroupByCodeTable(reportRequest));
    return reportTables;
  }

  private buildSummaryP1Table(reportRequest: LineItemReportRequest): ReportTable {
    const table: ReportTable = {};
    table.name = 'SUMMARY';
    table.nameColor = LineItemPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = LineItemPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.headers = [
      {
        rows: [
          [
            {
              name: '',
              data: {
                value: 'Amount billed per line type',
                decoration: { bold: true, italics: true },
              },
            },
          ],
        ],
        backgroundColor: '#ececec',
      },
    ];
    table.rowHeaders = [
      { value: 'Line type', decoration: { bold: true } },
      { value: 'Amount', decoration: { bold: true } },
    ];
    table.rowHeadersBold = true;
    const rows = [];

    let total = 0;

    // Iterate through the details without sorting
    reportRequest.SummaryPart1Details.forEach((item: SummaryPart1Details) => {
      total += item.Amount;

      const row: PDFCellData[] = [];
      row.push({ value: item.LineType });
      row.push({ value: item.Amount, type: CellType.CURRENCY });
      rows.push(row);
    });

    const row: PDFCellData[] = [];
    row.push({ value: 'Total', decoration: { bold: true } });
    row.push({ value: total, type: CellType.CURRENCY, decoration: { bold: true } });

    rows.push(row);
    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };
    return table;
  }

  private buildSummaryP2Table(reportRequest: LineItemReportRequest): ReportTable {
    const lineTypeOrder = [
      INVOICE_LINE_TYPE.PROCEDURE,
      INVOICE_LINE_TYPE.MEDICINE,
      INVOICE_LINE_TYPE.CONSUMABLE,
      INVOICE_LINE_TYPE.MODIFIER,
      INVOICE_LINE_TYPE.ADMIN,
      INVOICE_LINE_TYPE.CSTM_PROCEDURE,
      INVOICE_LINE_TYPE.CSTM_MEDICINE,
      INVOICE_LINE_TYPE.CSTM_CONSUMABLE,
    ];

    const table: ReportTable = {};
    table.headers = [
      {
        rows: [
          [
            {
              name: '',
              data: {
                value: 'Amount per provider per line type',
                decoration: { bold: true, italics: true },
              },
            },
          ],
        ],
        backgroundColor: '#ececec',
      },
    ];

    const summaryPart2Details = reportRequest.SummaryPart2Details;

    // Get line types with data, ordered according to lineTypeOrder
    const lineTypesWithData = lineTypeOrder.filter(
      (lineType) => summaryPart2Details.some((d) => d[lineType])
    );

    // Create row headers with only the line types that have data
    const rowHeaders = [''];
    rowHeaders.push(...lineTypesWithData);
    rowHeaders.push('');

    table.rowHeaders = rowHeaders.map((header) => ({ value: header, decoration: { bold: true } }));
    table.rowHeadersBold = true;

    const rows = [];

    // Calculate column totals for each line type with data
    const columnTotals: Record<string, number> = {};
    lineTypesWithData.forEach((lineType) => (columnTotals[lineType] = 0));
    for (const providerDetails of summaryPart2Details) {
      for (const lineType of lineTypesWithData) {
        columnTotals[lineType] += providerDetails[lineType] || 0;
      }
    }

    for (const providerDetails of summaryPart2Details) {
      const row: PDFCellData[] = [];
      row.push({ value: providerDetails.Provider });

      for (const lineType of lineTypesWithData) {
        row.push({ value: providerDetails[lineType], type: CellType.CURRENCY });
      }

      const providerTotal = lineTypesWithData.reduce(
        (total, lineType) => total + providerDetails[lineType] || 0,
        0
      );
      row.push({ value: providerTotal, decoration: { bold: true }, type: CellType.CURRENCY });

      rows.push(row);
    }

    // Calculate grand total for all providers (including only line types with data)
    // @ts-ignore
    const grandTotal = summaryPart2Details.reduce((total, providerDetails) => {
      const providerTotal = lineTypesWithData.reduce(
        (providerTotal, lineType) => providerTotal + providerDetails[lineType] || 0,
        0
      );
      return total + providerTotal;
    }, 0);

    // Add column totals to the table (including the grand total)
    const columnTotalsRow: PDFCellData[] = [];
    columnTotalsRow.push({ value: 'Total', decoration: { bold: true } }); // Header for column totals
    columnTotalsRow.push(
      ...lineTypesWithData.map((lineType) => ({
        value: columnTotals[lineType],
        type: CellType.CURRENCY,
        decoration: { bold: true },
      }))
    );
    columnTotalsRow.push({
      value: '',
      type: CellType.CURRENCY,
      decoration: { bold: true },
    });
    rows.push(columnTotalsRow);

    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };
    return table;
  }

  private buildGroupByCodeTable(reportRequest: LineItemReportRequest): ReportTable {
    const table: ReportTable = {};
    table.name = 'GROUPED BY CODE';
    table.nameColor = LineItemPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.nameUnderline = true;
    table.nameUnderlineColor = LineItemPdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
    table.rowHeaders = [
      { value: 'Line type', decoration: { bold: true } },
      { value: 'Code', decoration: { bold: true } },
      { value: 'Qty', decoration: { bold: true } },
      { value: 'Total Amount', decoration: { bold: true } },
    ];
    table.rowHeadersBold = true;
    const rows = [];

    // @ts-ignore
    let total = 0;

    reportRequest.GroupedByCodeDetails.sort((a, b) => (a.LineType > b.LineType ? 1 : -1)).forEach(
      (item: GroupedByCodeDetails) => {
        total += item.Amount;

        const row: PDFCellData[] = [];
        row.push({ value: item.LineType });
        row.push({ value: item.Code, type: CellType.GENERAL });
        row.push({ value: item.Qty });
        row.push({ value: item.Amount, type: CellType.CURRENCY });
        rows.push(row);
      }
    );
    table.rows = rows;
    table.borders = {
      hor: true,
      headerBorderSize: 1,
    };
    return table;
  }
}
