import {
  BaseExcelReportData,
  EMAIL_REGEX, ExcelCellData,
  IncompleteContactDetailsReportRequest,
  Sheet,
  Table
} from "@meraki-flux/schema";

export class IncompleteContactDetailsXlsReportDataBuilder {

  build(reportModel: IncompleteContactDetailsReportRequest): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.data = [];
    excelReportData.parameters = [];
    excelReportData.parameters.push({
      name: "LastVisitWithin",
      data: {
        value: reportModel.LastVisitWithin
      }
    })
    const sheet: Sheet = {};
    sheet.name = 'Incomplete Contact Details';
    sheet.tables = [];
    const dataTable: Table = {};
    dataTable.headers = [
      {value: 'Account no.', bold: true},
      {value: 'Patient surname', bold: true},
      {value: 'Patient name', bold: true},
      {value: 'Account holder?', bold: true},
      {value: 'Cellphone no.', bold: true},
      {value: 'Email address', bold: true},
    ];
    const rows = [];
    reportModel.Details.forEach(item => {
      const row: ExcelCellData[] = [];
      row.push({value: item.AccountNo});
      row.push({value: item.Surname});
      row.push({value: item.Name});
      row.push({value: item.AccountHolder ? "Yes" : "No"});
      row.push({value: item.Cellphone, bgColor: this.isValidCellphone(item.Cellphone) ? undefined : 'ffff00'});
      row.push({value: item.Email, bgColor: this.isValidEmail(item.Email) ? undefined : 'ffff00'});
      rows.push(row);
    })
    dataTable.rows = rows;
    sheet.tables.push(dataTable);
    excelReportData.data.push(sheet);

    return excelReportData;
  }

  private isValidEmail(email?: string): boolean {
    if (email) {
      return EMAIL_REGEX.test(email);
    }
    return false;
  }

  private isValidCellphone(phone?: string): boolean {
    if (phone) {
      if (phone.startsWith('+')) {
        phone = phone.substring(1)
      }
      return phone.length >= 10 && /^\d+$/.test(phone);
    }
    return false;
  }
}
