import * as _ from 'lodash';
import * as moment from 'moment';
import {NoDataError, RefundReportData, RefundReportRequest} from '@meraki-flux/schema';

export class RefundReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: RefundReportRequest): Promise<RefundReportRequest> {
    this.checkDataAvailability(reportRequest.ReportData);

    const data = reportRequest;
    data.ReportDate = moment(new Date()).format(this.DATE_FORMAT);
    data.DateRange =
      moment(reportRequest.DateFrom).format(this.DATE_FORMAT) +
      ' - ' +
      moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    return data;
  }

  private checkDataAvailability(refundReportData: RefundReportData[]) {
    let noData = true;

    if (refundReportData.length > 0) {
      noData = false;
    }
    if (noData) throw new NoDataError();
  }
}
