<mat-table id='invoice{{invoiceIndex}}Table' class='lines-table flux-table' [dataSource]="maPayableInvoice.Lines">

    <!-- DOS / Invoice no. column -->
    <ng-container matColumnDef="dos">
        <mat-header-cell *matHeaderCellDef>Date of service /<br>Invoice no.</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <div class="flex flex-col">
                <span id='dateOfService{{invoiceIndex}}'>{{ i === 0 ? (invoice.DateOfService | date: 'dd/MM/yyyy')
                    : ''
                    }}</span>
                <span id='invoiceNo{{invoiceIndex}}' class="text-hb-text-disabled text-xs">{{ i === 0 ?
                    invoice.InvoiceNo :
                    ''
                    }}</span>
            </div>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <mat-checkbox id='fullyPaid{{invoiceIndex}}' class="flux-checkbox-secondary" [formControl]='fullyPaidCtrl'
                i18n>Fully
                paid</mat-checkbox>
        </mat-footer-cell>
    </ng-container>

    <!-- Patient / Claim status column -->
    <ng-container matColumnDef="patient">
        <mat-header-cell *matHeaderCellDef>Patient /<br>Claim status</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <div class="flex flex-col">
                <span id='patient{{invoiceIndex}}'>{{ helper.patientNameSurnameDepCode(i, invoice?.Patient) }}</span>
                <span id='invoiceDesc{{invoiceIndex}}' class="text-hb-text-disabled text-xs">
                    {{ helper.invoiceDescription(i, invoice) }}
                </span>
            </div>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <mat-checkbox id='zeroPaid{{invoiceIndex}}' class="flux-checkbox-secondary" [formControl]='zeroPaidCtrl'
                i18n>
                Zero paid</mat-checkbox>
            <mat-icon class="!text-hb-accent-100 ml-2" fontSet="material-icons-outlined"
                matTooltip="Only select this option if the medical aid has processed the claim and they have confirmed that 0 has been paid. The claim status will then be updated to “Remittance advice received”.">
                info</mat-icon>
        </mat-footer-cell>
    </ng-container>

    <!-- Code column -->
    <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <p id='code{{invoiceIndex}}_{{i}}'>{{ row.NappiCode ? row.NappiCode : row.TariffCode }}</p>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>Subtotal</mat-footer-cell>
    </ng-container>

    <!-- Invoiced column -->
    <ng-container matColumnDef="invoiced">
        <mat-header-cell *matHeaderCellDef class="text-right">Invoiced</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span id='amountBilled{{invoiceIndex}}_{{i}}' class="w-full text-right">
                {{ row.AmountBilled/100 | number : '1.2-2' }}
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <span id='totalAmountBilled{{invoiceIndex}}' class="w-full text-right">
                {{ invoice.AmountBilled/100 | number : '1.2-2' }}
            </span>
        </mat-footer-cell>
    </ng-container>

    <!-- MAL column -->
    <ng-container matColumnDef="mal">
        <mat-header-cell *matHeaderCellDef class="text-right">Medical aid liable</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span id='mal{{invoiceIndex}}_{{i}}' class="w-full text-right"
                [ngClass]="{ 'text-hb-critical-100': row.Balance?.MedicalAidLiable> 0 }">
                {{ row.Balance?.MedicalAidLiable/100 | number : '1.2-2' }}
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <span id='totalMAL{{invoiceIndex}}' class="w-full text-right">
                {{ invoice.Balance?.MedicalAidLiable/100 | number : '1.2-2' }}
            </span>
        </mat-footer-cell>
    </ng-container>

    <!-- PL column -->
    <ng-container matColumnDef="pl">
        <mat-header-cell *matHeaderCellDef class="text-right">Patient liable</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span id='pl{{invoiceIndex}}_{{i}}' class="w-full text-right">
                {{ row.Balance?.PatientLiable/100 | number : '1.2-2' }}
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <button *ngIf="helper.showTransferLiabilities() | async" mat-icon-button
                (click)="helper.onTransferLiabilities(invoice)">
                <mat-icon class="!text-hb-success-100 mb-1" matTooltip="Transfer Liabilities">
                    swap_horiz</mat-icon>
            </button>
            <span id='totalPL{{invoiceIndex}}' class="w-full text-right">
                {{ invoice.Balance?.PatientLiable/100 | number : '1.2-2' }}
            </span>
        </mat-footer-cell>
    </ng-container>

    <!-- Amount Paid column -->
    <ng-container matColumnDef="amountPaid">
        <mat-header-cell *matHeaderCellDef class="text-right">Amount paid</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <mat-form-field appearance="outline" class="w-full flux-form-field">
                <input id="amountPaid{{invoiceIndex}}_{{i}}" class="font-semibold" matInput currencyMask
                    [formControl]='row._AmountAllocatedCtrl'
                    [options]="{ prefix: '', thousands: ',', decimal: '.', allowNegative : false }">
            </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <span id="totalAmountAllocated{{invoiceIndex}}" class="w-full text-right mr-2.5"
                *ngVar="maPayableInvoice._AmountAllocated">
                {{ maPayableInvoice._AmountAllocated/100 | currency : ' ' : 'symbol' : '1.2-2' }}
            </span>
        </mat-footer-cell>
    </ng-container>

    <!-- Balance column -->
    <ng-container matColumnDef="balance">
        <mat-header-cell *matHeaderCellDef class="text-right">Balance</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span id="balance{{invoiceIndex}}_{{i}}" class="w-full text-right font-semibold"
                [ngClass]="{ 'text-hb-critical-100': row._BalanceOutstanding < 0 }">
                {{ row._BalanceOutstanding/100 | currency : ' ' : 'symbol' : '1.2-2' }}
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <span id='totalBalance{{invoiceIndex}}' class="w-full text-right"
                [ngClass]="{ 'text-hb-critical-100': maPayableInvoice._BalanceOutstanding < 0 }">
                {{ maPayableInvoice._BalanceOutstanding/100 | currency : ' ' : 'symbol' : '1.2-2' }}
            </span>
        </mat-footer-cell>
    </ng-container>

    <!-- Action column -->
    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="text-right">Action</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <mat-form-field appearance="outline" class="table-field w-full flux-form-field">
                <mat-select id="action{{invoiceIndex}}_{{i}}" matTooltip="{{row._ActionCtrl.value}}"
                    [formControl]='row._ActionCtrl'>
                    <mat-option *ngFor="let action of BALANCE_ACTIONS" [value]="action">{{action}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <!-- Reason code -->
    <ng-container matColumnDef="reasoncode">
        <mat-header-cell *matHeaderCellDef class="text-right">Reason code</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <mat-form-field appearance="outline" class="w-full flux-form-field">
                <input id="reasonCode{{invoiceIndex}}_{{i}}" class="font-semibold" matInput maxlength="20"
                    (paste)="preventPaste($event)" (keypress)="keyPressNumbersWithDecimal($event)">
            </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: getDisplayColumns()"></mat-row>
    <mat-header-row [ngClass]="{'hidden' : hideHeader}" *matHeaderRowDef="getDisplayColumns()"></mat-header-row>
    <mat-footer-row [ngClass]="{'hidden' : !invoice?.Lines }" *matFooterRowDef="getDisplayColumns()"></mat-footer-row>
</mat-table>