import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  doc,
  docSnapshots,
  Firestore,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
} from '@angular/fire/firestore';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { AccountRepository } from '../../repositories/account.repository';
import { DateUtils } from '../../utils/date-utils';
import { PathUtils } from '../../utils/path-utils';
import { AuthService } from '../auth.service';
import { DialogService } from '../dialog.service';
import { FirestoreService } from '../firestore.service';
import { InvoiceWherePreset } from './invoice-where-builder';
import {CANCEL_STATUS, CancelRequest, Invoice, ReasonCode} from "@meraki-flux/schema";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  readonly HB_INTERNAL_REASON_CODES_PREFIX = 'hb_';

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private accountRepository: AccountRepository,
    public auth: AuthService,
    private fs: FirestoreService,
    private dialogService: DialogService
  ) {}

  getInvoices(wheres: QueryConstraint[]): Observable<Invoice[]> {
    return this.authService.practice$.pipe(
      switchMap((practice) =>
        collectionSnapshots(
          query(collection(this.firestore, PathUtils.invoiceCollectionPath(practice.Id)), ...wheres)
        )
      ),
      map((arr) =>
        arr.map((doc) => DateUtils.timestampsToDates({ ...doc.data(), Id: doc.id } as Invoice))
      )
    );
  }

  async invoice(invoiceId: string): Promise<Invoice> {
    if (!invoiceId) return undefined;
    const invoiceDoc = await getDoc(
      doc(this.firestore, PathUtils.invoicePath(this.authService.selectedBPN, invoiceId))
    );
    return DateUtils.timestampsToDates({ ...invoiceDoc.data(), Id: invoiceDoc.id }) as Invoice;
  }

  async invoices(wheres: QueryConstraint[]): Promise<Invoice[]> {
    const practiceId = this.authService.selectedBPN;
    if (!practiceId) {
      return [];
    }
    const docs = await getDocs(
      query(collection(this.firestore, PathUtils.invoiceCollectionPath(practiceId)), ...wheres)
    );
    return docs.docs.map((doc) =>
      DateUtils.timestampsToDates({ ...doc.data(), Id: doc.id } as Invoice)
    );
  }

  async searchIndex(wheres: QueryConstraint[]): Promise<Invoice[]> {
    const practiceId = this.authService.selectedBPN;
    if (!practiceId) {
      return [];
    }

    const docs = await getDocs(
      query(collection(this.firestore, PathUtils.invoiceCollectionPath(practiceId)), ...wheres)
    );
    return docs.docs.map((doc) =>
      DateUtils.timestampsToDates({ ...doc.data(), Id: doc.id } as Invoice)
    );
  }

  getOpenInvoicesWithBalance(accountId: string): Observable<Invoice[]> {
    const wheres = InvoiceWherePreset.openWithBalance(accountId).build();
    return this.getInvoices(wheres);
  }

  activeAccountOpenInvoicesWithBalance(): Observable<Invoice[]> {
    return this.accountRepository.activeAccount$.pipe(
      map((a) => a.Id),
      distinctUntilChanged(),
      switchMap((accountId) => this.getOpenInvoicesWithBalance(accountId)),
      distinctUntilChanged()
    );
  }

  getReasonCodes(invoice: Invoice): ReasonCode[] {
    const maPaymentReasonCodes = _.uniqBy(
      invoice?.ClaimInfo?.RemittanceClaims?.filter((rc) => rc?.ReasonCodes?.length > 0)
        .map((rc) => rc.ReasonCodes)
        .reduce((acc, curr) => acc.concat(curr), []),
      (rc) => [rc.Code, rc.LineNo].join()
    );
    return (
      (maPaymentReasonCodes.length > 0
        ? invoice?.ClaimInfo?.ReasonCodes.concat(maPaymentReasonCodes)
        : invoice?.ClaimInfo?.ReasonCodes) || []
    )
      .filter(
        (c) =>
          !c?.Code?.toLocaleLowerCase().startsWith(
            this.HB_INTERNAL_REASON_CODES_PREFIX.toLocaleLowerCase()
          )
      )
      .filter((c) => c?.Code?.trim() !== '0')
      .sort((c1, c2) => c1?.Code.localeCompare(c2?.Code, undefined, { numeric: true }));
  }

  async cancelInvoice(invoiceId: string, cancelRequest: CancelRequest) {
    try {
      const newRevReq = await this.fs.addDoc(
        collection(
          this.firestore,
          `Practice/${this.auth.selectedBPN}/Invoice/${invoiceId}/CancelRequest`
        ),
        cancelRequest
      );
      await this.fs.setDoc(
        doc(this.firestore, `Practice/${this.auth.selectedBPN}/Invoice/${invoiceId}`),
        { CancelRequestId: newRevReq.id },
        { merge: true }
      );

      const success = await docSnapshots(
        doc(
          this.firestore,
          `Practice/${this.auth.selectedBPN}/Invoice/${invoiceId}/CancelRequest/${newRevReq.id}`
        )
      )
        .pipe(
          first(),
          map(async (snap) => {
            if (snap.data()) {
              const cancelRequest = snap.data() as CancelRequest;
              if (cancelRequest.Status === CANCEL_STATUS.PROCESSED) {
                this.dialogService.showSnackbar('Cancel request processed successfully');
              }
              if (cancelRequest.Status === CANCEL_STATUS.ERROR) {
                this.dialogService.showErrorMessage(
                  `Cancel request did not succeed due to ${cancelRequest.Error}`
                );
              }
              return cancelRequest.Status !== CANCEL_STATUS.ERROR;
            }
            return false;
          })
        )
        .toPromise();

      return success;
    } catch (error) {
      this.dialogService.showErrorMessage(`${error}`);
      return false;
    }
  }

  async refreshBalance(practiceId: string, invoiceId: string, refreshAccountBalance: boolean = true) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const callable = httpsCallable(functions, 'inv-utl-v1-oncall-refreshInvoiceBalance');
    const result:any = await callable({
      practiceId: practiceId,
      invoiceId: invoiceId,
      refreshAccountBalance: refreshAccountBalance,
    });
    return result?.data;
  }
}
