import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  SetOptions,
  updateDoc,
  writeBatch,
} from '@angular/fire/firestore';
import { DateUtils } from '../utils/date-utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore, private auth: AuthService) {}

  setDoc<T>(reference: DocumentReference<any>, data: any, options?: SetOptions): Promise<void> {
    if (!options) {
      return setDoc(reference, {
        ...data,
        UpdatedBy: this.auth.uid,
        UpdatedAt: serverTimestamp(),
      });
    } else {
      return setDoc(
        reference,
        {
          ...data,
          UpdatedBy: this.auth.uid,
          UpdatedAt: serverTimestamp(),
        },
        options
      );
    }
  }

  addDoc<T>(reference: CollectionReference<T>, data: any): Promise<DocumentReference<T>> {
    return addDoc(reference, {
      ...data,
      CreatedBy: this.auth.uid,
      CreatedAt: serverTimestamp(),
    });
  }

  updateDoc(reference: DocumentReference<any>, data: any) {
    return updateDoc(reference, {
      ...data,
      UpdatedBy: this.auth.uid,
      UpdatedAt: serverTimestamp(),
    });
  }

  softDeleteDocs(reference: QuerySnapshot<DocumentData>) {
    reference.docs.forEach(doc => {
      setDoc(doc.ref, { IsActive: false, UpdatedBy: this.auth.uid, UpdatedAt: serverTimestamp() }, { merge: true });
    })
  }

  hardDeleteDocs(reference: QuerySnapshot<DocumentData>) {
    const batch = writeBatch(this.firestore);
    reference.docs.forEach(doc => {
      batch.delete(doc.ref)
    })
    batch.commit();
  }

  mergeDoc(documentPath: string, data: any, applyUpdatedFlags: boolean = true) {
    if (!data) return Promise.resolve(data);
    if (applyUpdatedFlags) {
      data.UpdatedBy = this.auth.uid;
      data.UpdatedAt = serverTimestamp();
    }
    return setDoc(doc(this.firestore, documentPath), data, { merge: true });
  }

  getUpdatedByFields() {
    return {
      UpdatedBy: this.auth.uid,
      UpdatedAt: serverTimestamp(),
    };
  }

  getCreateByFields() {
    return {
      CreatedBy: this.auth.uid,
      CreatedAt: serverTimestamp(),
    };
  }

  async query(collectionPath: string, ...wheres: QueryConstraint[]): Promise<any> {
    const snapshots = await getDocs(query(collection(this.firestore, collectionPath), ...wheres));
    return snapshots.docs.map((doc) => DateUtils.timestampsToDates({ ...doc.data(), Id: doc.id }));
  }

  async findOne(documentPath: string): Promise<any> {
    const docData = await getDoc(doc(this.firestore, documentPath));
    return DateUtils.timestampsToDates({
      ...docData.data(),
      Id: docData.id,
    });
  }

  async exists(documentPath: string): Promise<boolean> {
    const docData = await getDoc(doc(this.firestore, documentPath));
    return docData.exists();
  }
}
