<div class="flex flex-row w-screen h-screen overflow-hidden bg-hb-base-white">
  <!-- Left panel -->
  <div class="flex flex-col flex-1 justify-center items-center overflow-auto h-full">

    <!-- Login form -->
    <div [formGroup]="form" class="self-center flex flex-col max-w-[420px] pb-8">
      <label class="font-medium text-hb-text-default text-2xl">
        Reset password
      </label>
      <label class="mt-1 text-sm text-hb-text-subdued max-w-[360px]"> If you have forgotten your password, please enter your username and email address below and click the "Reset"
        button.
        You will receive an email that contains a link to set a new password.</label>

      <mat-form-field class="flux-form-field mt-4" appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="userName" name="username">
      </mat-form-field>


      <mat-form-field class="flux-form-field mt-4" appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" type="email">
      </mat-form-field>

      <div class="flex flex-row gap-4 w-full mt-4 justify-end">
        <button [disabled]="loading" mat-stroked-button routerLink="../login" class="cancel-button">Cancel</button>

        <button mat-button class="reset-button" (click)="onSubmit()"
        [disabled]="loading" id="btnReset">
          <div class="flex flex-row flex-nowrap gap-2 h-full w-full items-center justify-center">
            <mat-spinner *ngIf="loading" class="ml-[-8px] reset-spinner" diameter="24"></mat-spinner>
            Reset</div>
          </button>
      </div>

    </div>

  </div>

  <!-- Brand panel -->
  <div class="brand-panel" 
    [style.background-position]="'center'" 
    [style.background-size]="'70%'"
    [style.background-image]="'url(assets/images/hb_login_bg_logo.png)'">
  </div>
</div>