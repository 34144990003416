<div [formGroup]="form">
    <!--Header-->    
    <div class="flex flex-row justify-between items-center border-b border-hb-base-divider-grey !py-2 !px-6 -mt-6 -mx-6 mb-6">
        <h2 class="font-medium !leading-8 text-xl">
            <mat-label>Modifier {{this.data[0].recalculateModifiers? 'price reloading' : this.data[0].tariffCode}}</mat-label> 
        </h2>
        <!-- <input #focusOnThis matInput autofocus placeholder="Enter something here"> -->
        <button mat-icon-button mat-dialog-close *ngIf="this.showButtons" class="" (click)="onCancel()">
            <mat-icon class="!mr-[-1px]">close</mat-icon>
        </button>
    </div>

    <div *ngIf="(componentState$ | async) === 'Loading'" class="flex flex-row w-full items-center">
        <mat-spinner diameter="20"></mat-spinner>
        <span class="ml-2 font-semibold">{{loadingText}}</span>
    </div> 

    <ng-container *ngFor="let paramter of this.parameterArray">
        <ng-container [ngSwitch]="paramter.name">
            <!-- RCF -->
            <div *ngSwitchCase="'RCF'" class="row w-full">
                <mat-form-field class="flux-form-field w-full" *ngVar="busyGettingRcf$ | async as isBusy">
                    <mat-label>{{paramter.label}}</mat-label>                    
                    <input #focusOnThis matInput placeholder="Clinical RCF" [readonly]="true" [formControlName]="paramter.name"
                        currencyMask [options]="{ prefix: 'R ', thousands: ',', decimal: '.' }" class="rcf" (focus)="true">    
                    <mat-spinner *ngIf="isBusy" [diameter]="20" mode="indeterminate" color="accent" class="spinner rcfSpinner"></mat-spinner>                
                </mat-form-field>
            </div>

            <!-- Time controls -->
            <div *ngSwitchCase="'TimeArray'" class="row w-96p !py-2">
                <ng-container *ngFor="let timeArray of this.paramter.value">
                    <mat-form-field class="w-1/2 flux-form-field">
                        <mat-label>{{timeArray.label}}</mat-label>
                        <input matInput [mask]="'99:99'" placeholder="hh:mm" [formControlName]="timeArray.name"
                        (blur)="fixStartTime($event,timeArray.name)" (keypress)="validateTimeInput($event)">
                    </mat-form-field>   
                </ng-container>
            </div>     
            
            <!-- InputDecimal -->
            <div *ngSwitchCase="'InputArray'" class="row w-96p !py-2">
                <ng-container *ngFor="let inputArray of this.paramter.value">
                    <mat-form-field class="w-1/2 flux-form-field">
                        <mat-label>{{inputArray.label}}</mat-label>
                        <input matInput [formControlName]="inputArray.name"
                        (blur)="calculateBMI()" (keypress)="validateBMIInput($event)">
                    </mat-form-field>   
                </ng-container>
            </div> 

            <div *ngSwitchCase="'BMI'" class="row w-96p !py-2">
                <div class="w-1/2 flux-form-field" *ngIf="this.showBmi">
                    <mat-label class="bmiLable">BMI</mat-label><br>
                    <mat-label class="bmiValue">{{bmiValue}}</mat-label>
                </div>                
            </div>

            <!-- Checkbox -->
            <div *ngSwitchCase="'is_for_assistant'" >
                <!-- Render a checkbox element.  -->
                <mat-slide-toggle class="pr-4 flux-toggle" [formControlName]="paramter.name">Claim for assistant doctor
                </mat-slide-toggle>
            </div>

            <!-- ComboArray -->
            <div *ngSwitchCase="'ComboArray'" >
                <!-- Render a select element. -->                
                <ng-container *ngFor="let comboArray of this.comboBoxControls">
                    <mat-form-field class="flux-form-field w-full">
                        <mat-label>{{comboArray.label}}</mat-label>
                        <mat-select id="{{comboArray.name}}" placeholder="" [formControlName]="comboArray.name"
                        [compareWith]="compareProvider">
                        <mat-option *ngFor="let provider of allProviders$ | async" [value]="provider">
                            {{provider.FullName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>

            <!-- Grid -->
            <div *ngSwitchCase="'links'" >
                 <!-- Render a data grid. -->
                 <mat-label>{{paramter.label}}</mat-label>
                 <div *ngFor="let linkArray of this.paramter.linkArray">
                    <mat-checkbox class="flux-checkbox-secondary leading-6 shrink-0 mx-1.5" #focusOnThis
                    [formControlName]="linkArray.lineNumber">{{ linkArray.description }}
                    </mat-checkbox>
                    <!-- <input type="checkbox" [formControlName]="linkArray.lineNumber"> {{ linkArray.description }} -->
                 </div>
            </div>           

            
            
        </ng-container>
    </ng-container>         

    <div class="row flex justify-end space-x-2 !px-6 -mx-6 bottomRow"  *ngIf="this.showButtons">
        <button id="cancelButton" class="flux-button mat-secondary" mat-flat-button (click)="onCancel()">Cancel</button>
        <button id="saveButton" *ngVar="busySaving$ | async as isSaving" mat-flat-button color="primary" type="button"
            class="flux-button" [disabled]="isSaving" (click)="onSave()" i18n>
            <mat-icon *ngIf="isSaving">
                <mat-progress-spinner [diameter]="20" mode="indeterminate" color="accent">
                </mat-progress-spinner>
            </mat-icon>
            Save
        </button>
    </div>

</div>

