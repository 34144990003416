<div class="system-alert-container danger" 
[class.danger]="options.type === dialogTypes.ERROR"
[class.success]="options.type === dialogTypes.SUCCESS"
[class.warning]="options.type === dialogTypes.WARNING">
    <mat-icon class="icon" fontSet="material-icons-outlined">{{options.icon ?? 'info'}}</mat-icon>

    <div class="message-container">
        <label *ngIf="options.title" class="title">{{options.title}}</label>
        <label class="message">{{options.message}}</label>
    </div>

    <div class="button-container">
        <button *ngIf="options.action" class="action-button" mat-button (click)="execAction()">
            {{options.action.label ?? 'Action'}}
        </button>
    
        <button *ngIf="displayCloseButton" class="close-button" mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    
</div>