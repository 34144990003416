import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DialogService } from './dialog.service';
import { SearchService } from './search.service';
import {DialogType, GENDERS, Invoice, InvoicePatient} from "@meraki-flux/schema";

export interface DiagnosisRule {
  Code: string;
  ValidPrimary: boolean;
  ValidSequelae: boolean;
  MaxSpecificity: boolean;
  Gender: string;
  AgeRange: string;
}

export interface ErrorMessage{
  name: string
  errors: string[]
}

@Injectable({
  providedIn: 'root'
})
export class DiagnosisEngineService {

  constructor(
    private dialogService: DialogService,
    private searchService: SearchService
  ) { }

  public async checkDiagnoses(invoice: Invoice, patient: InvoicePatient) {
    const errors: ErrorMessage[] = [];
    if (!invoice.HeaderDiagnosisCodes || invoice.HeaderDiagnosisCodes.length === 0) {
      this.putErrors(errors, "Header level diagnosis", ['Please provide a diagnosis code for this invoice.']);
    } else {
      const hCheck = await this.checkDiagnosisRules(invoice, invoice.HeaderDiagnosisCodes, patient);
      this.putErrors(errors, "Header level diagnosis", hCheck);
      for (let i = 0; i < invoice.Lines.length; i++) {
        const line = invoice.Lines[i];
        const lCheck = await this.checkDiagnosisRules(invoice, line.DiagnosisCodes, patient);
        this.putErrors(errors, `Line ${i + 1}`, lCheck);
      }
    }
    if (errors.length !== 0) {
      this.showErrorDialog(errors);
      return false;
    }
    return true;
  }

  private putErrors(errors: ErrorMessage[], name: string, messages: string[]) {
    if (messages.length > 0) {
      errors.push({
        name: name,
        errors: messages
      })
    }
  }

  private showErrorDialog(errors: ErrorMessage[]) {
    let message = 'You will need to fix the diagnosis code errors below before trying to submit.<br/>'
      errors.forEach(error => {
        message += `<br/><span class="font-bold float-left">${error.name}</span><br/>`
        error.errors.forEach(er => {
          message += `<span class="float-left">${er}</span><br/>`
        })
      })
      this.dialogService.showDialog({
        message: message,
        type: DialogType.ERROR
      }, "700px")
  }

  async checkDiagnosisRules(invoice: Invoice, codes: string[], patient: any): Promise<string[]> {
    const errors = [];
    let realAge = undefined;
    if (patient.DateOfBirth) {
      realAge = patient.DateOfBirth.toDate ? patient.DateOfBirth.toDate() : patient.DateOfBirth;
    }
    const rules = await this.loadRules(codes);
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      if (i === 0) {
        if (!rule.MaxSpecificity) {
          errors.push(this.notFullSpecificity(rule.Code));
        }
        if (!rule.ValidPrimary) {
          errors.push(this.notValidPrimary(rule.Code));
        }
        if (rule.ValidSequelae) {
          errors.push(this.notValidSequelae(rule.Code));
        }
        if (this.isEccCode(rule.Code)) {
          errors.push(this.notEccPrimary(rule.Code));
        }
        this.checkEccFollowed(rule, rules, i, errors);
        this.checkGenderAndAge(rule, patient.Gender, realAge, errors);
        continue;
      }
      if (!rule.MaxSpecificity) {
        errors.push(this.notFullSpecificity(rule.Code));
      }
      if (rule.ValidSequelae) {
        if (rules.filter((rl, ind) => !rl.ValidSequelae && ind > i).length === 0) {
          errors.push(this.notValidSequelae(rule.Code));
        }
      }
      this.checkEccFollowed(rule, rules, i, errors);
      this.checkGenderAndAge(rule, patient.Gender, realAge, errors);
    }
    return errors;
  }

  private checkGenderAndAge(rule: DiagnosisRule, gender: any, realAge: any, errors: any[]) {
    if (rule.Gender && rule.Gender !== "") {
      if ((gender === GENDERS.MALE && rule.Gender !== "M") || (gender === GENDERS.FEMALE && rule.Gender !== "F")) {
        errors.push(this.notValidGender(rule.Code));
      }
    }
    if (rule.AgeRange && rule.AgeRange !== "") {
      if (!this.checkAge(rule.AgeRange, realAge)) {
        errors.push(this.notValidAge(rule.Code, moment(realAge).format('DD/MM/YYYY'), rule.AgeRange));
      }
    }
  }

  private checkEccFollowed(rule: DiagnosisRule, rules: DiagnosisRule[], i: number, errors: any[]) {
    if (this.isSTCode(rule.Code)) {
      if (rules.filter((rl, ind) => this.isEccCode(rl.Code) && ind > i).length === 0) {
        errors.push(this.notEccFollowed(rule.Code));
      }
    }
  }

  private isEccCode(code: string): boolean {
    return code.toUpperCase().startsWith("V")
      || code.toUpperCase().startsWith("W")
      || code.toUpperCase().startsWith("X")
      || code.toUpperCase().startsWith("Y");
  }

  private isSTCode(code: string): boolean {
    return code.toUpperCase().startsWith("S")
      || code.toUpperCase().startsWith("T");
  }

  private async loadRules(codes: string[]): Promise<DiagnosisRule[]> {
    return await Promise.all(codes.map(async (code) => {
      const diag = await this.searchService.getDiagnosis(code) as any;
      return {
        Code: diag.data.ICD10Code,
        ValidPrimary: diag.data.ValidAsPrimary === 1,
        ValidSequelae: diag.data.ValidAsSequelae === 1,
        MaxSpecificity: diag.data.ValidForClinicalUse === 1,
        Gender: diag.data.Gender,
        AgeRange: diag.data.AgeRange
      }
    }))
  }

  private notValidPrimary(code: string) {
    return `${code} - Invalid primary code.`;
  }

  private notFullSpecificity(code: string) {
    return `${code} - Complete code must be used.`;
  }

  private notValidSequelae(code: string) {
    return `${code} - Cannot be used on its own or in the primary position.`;
  }

  private notEccPrimary(code: string) {
    return `${code} - Cannot be used on its own or in the primary position.`;
  }

  private notEccFollowed(code: string) {
    return `${code} - Must be followed by an ECC.`;
  }

  private notValidGender(code: string) {
    return `${code} - Invalid patient gender.`;
  }

  private notValidAge(code: string, age: any, valid: string) {
    return `${code} - Invalid patient age (${age}). Valid ages are from ${valid}.`;
  }

  private checkAge(ageExpression: string, age?: any): boolean {
    if (!age) {
      return false;
    }
    let result = true;
    const years = moment().diff(age, 'years')
    const days = moment().diff(age, 'days')
    ageExpression.split('and').map(fL => {
      return fL.split('-').map(sL => {
        const num = Number(sL.replace( /\D+/g, ''));
        if (sL.indexOf('<') != -1) {
          if (sL.indexOf('year') != -1) {
            if (years > num) {
              result = false;
            }
          }
          if (sL.indexOf('day') != -1) {
            if (days > num) {
              result = false;
            }
          }
        }
        if (sL.indexOf('>') != -1) {
          if (sL.indexOf('year') != -1) {
            if (years < num) {
              result = false;
            }
          }
          if (sL.indexOf('day') != -1) {
            if (days < num) {
              result = false;
            }
          }
        }
      })
    })
    return result;
  }
}
