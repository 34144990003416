import { BaseExcelReportData, CellType, CellWidth, ExcelCellData, RASuspenseDSData, RASuspenseReportModel, REPORT_NAME, SchemeSummaryItem, Sheet, Table } from "@meraki-flux/schema";

export class RASuspenseAccountXlsReportDataBuilder {


    build(raSuspenseReportModel: RASuspenseReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.RA_SUSPENSE;
        excelReportData.data = [];

        excelReportData.data.push(this.buildReportSheetSummary(raSuspenseReportModel));
        excelReportData.data.push(this.buildReportSheetDetail(raSuspenseReportModel));

        this.buildReportInfo(excelReportData,raSuspenseReportModel);

        return excelReportData;
    }

    private buildReportSheetSummary(raSuspenseReportModel: RASuspenseReportModel): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Summary';
        sheet.tables = [];

        //Scheme summary
        const schemeTable: Table = {};
        schemeTable.headers = [
            {value: 'Scheme', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Items', bold: true, width: CellWidth.S, wordWrap: true, hAlignment:'right'},
            {value: 'Total', bold: true, width: CellWidth.XS, wordWrap: true, hAlignment:'right'}
        ];

        const schemeRows = [];

        const schemeSummary = raSuspenseReportModel.RASchemeModel.SchemeSummaryItem;
        schemeSummary.sort((a,b) => a.Scheme.localeCompare(b.Scheme));

        let totalScheme = 0;
        let totalSchemeValue = 0;
        schemeSummary.forEach((item: SchemeSummaryItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Scheme });
            row.push({value: item.Total, type: CellType.NUMBER});
            row.push({value: item.TotalValue, type: CellType.CURRENCY});
            totalScheme= totalScheme + item.Total;

            schemeRows.push(row);
        });

        const totalRowScheme: ExcelCellData[] = [];
        totalRowScheme.push({value: 'Totals', bold: true});
        totalRowScheme.push({value: totalScheme, bold: true});
        totalSchemeValue = schemeSummary.reduce((acc, item) => acc + item.TotalValue, 0);
        totalRowScheme.push({value: totalSchemeValue, bold: true, type: CellType.CURRENCY});
        schemeRows.push(totalRowScheme);

        schemeTable.rows = schemeRows;
        sheet.tables.push(schemeTable);
        //END - Scheme summary

        return sheet;
    }

    private buildReportSheetDetail(raSuspenseReportModel: RASuspenseReportModel) {
        const sheet: Sheet = {};
        sheet.name = 'Unreconciled RAs';
        sheet.tables = [];

        const detailsTable: Table = {};
        detailsTable.headers = [
            {value: 'Scheme', bold: true, width: CellWidth.S},
            {value: 'RA no.', bold: true, width: CellWidth.XS},
            {value: 'Payment date', bold: true, width: CellWidth.XS},
            {value: 'Patient (Account no.)', bold: true, width: CellWidth.M},
            {value: 'Date of service', bold: true, width: CellWidth.XS},
            {value: 'Amount', bold: true, width: CellWidth.XS, hAlignment:'right'}];

        const detailsRows = [];

        raSuspenseReportModel.RASchemeModel.RASuspenseDSData.forEach((item: RASuspenseDSData) => {
        const row: ExcelCellData[] = [];
        row.push({value: item.SchemeName}),
        row.push({value: item.RANum}),
        row.push({value: item.PayDate, type: CellType.GENERAL}),
        row.push({value: item.PatName+' '+item.PatSurname+' ('+item.AccountNo+')'}),
        row.push({value: item.DateOfService, type: CellType.GENERAL}),
        row.push({value: item.PaidAmount, type: CellType.CURRENCY});
        detailsRows.push(row);
      });

        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, raSuspenseReportModel: RASuspenseReportModel) {

        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: raSuspenseReportModel.ReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push(
            {
                name: 'Scheme',
                data: {value: raSuspenseReportModel.ReportInfo.Scheme, type: CellType.GENERAL}
            });

        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: raSuspenseReportModel.ReportInfo.DateRange, type: CellType.GENERAL}
            });
    }
}