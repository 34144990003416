import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import {OK_BUTTON_ID, RadioButtonDialogOptions} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-radio-button-dialog',
  templateUrl: './radio-button-dialog.component.html',
  styleUrls: ['./radio-button-dialog.component.scss'],
})
export class RadioButtonDialogComponent extends DialogComponent implements OnInit {
  Selection = new UntypedFormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public override dialogData: RadioButtonDialogOptions,
    protected override injector: Injector
  ) {
    super(dialogData, injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.Selection.setValue(this.dialogData.defaultOption);
  }

  onBeforeClose = async (buttonId) => {
    if (buttonId === OK_BUTTON_ID) {
      return this.Selection.value;
    }
    return true;
  };

  onClose = async (buttonId) => {
    if (buttonId === OK_BUTTON_ID) {
      return this.Selection.value;
    }
    return null;
  };
}
