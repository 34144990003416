export interface MedpraxContractPriceRequest {
  year: string;
  tariffCode: string;
  planOptionCode: string;
  disciplineCode?: string;
  tariffTypeCode?: string;
  dispensingCode?: string;
  levelCode?: string;
  providerType?: MedpraxProviderType
}

export enum MedpraxProviderType {
  GP = 0,
  Specialist = 1,
  Optometrist = 2,
  Alternative = 3,
  Dental = 4
}