import { Injectable } from '@angular/core';
import { collection, Firestore } from '@angular/fire/firestore';
import { FirestoreService } from './firestore.service';
import {PathUtils} from "../utils/path-utils";
import {EmailRequest} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private firestore: Firestore,
    private fs: FirestoreService,
  ) { }

  async createEmail(request: EmailRequest): Promise<string> {
    return (await this.fs.addDoc(collection(this.firestore, PathUtils.outboundEmailCollectionPath(request.PracticeId)), request)).id;
  }
}
