import { EMAIL_TYPE } from "./email.models";
import { SMS_TYPE } from './sms.models';

export interface CommunicationHistory {
    To: CommunicationRecipient[];
    Cc?: CommunicationRecipient[];
    Subject?: string;
    MessageId?: string;
    Body: string;
    Type: COMMUNICATION_TYPE;
    SubType: EMAIL_TYPE | SMS_TYPE
    Date: Date;
    Status: string;
    Attachments?: string[];
    Events: CommunicationHistoryEvent[],
    CreatedAt?: Date;
    CreatedBy?: string;
    CreatedByFullName?: string;
    UpdatedAt?: Date;
    UpdatedBy?: string;
}

export enum COMMUNICATION_TYPE {
    EMAIL = 'Email',
    SMS = 'SMS'
}

export interface CommunicationRecipient {
    PhoneNumber?: string;
    EmailAddress?: string;
    Name?: string;
}

export interface CommunicationHistoryEvent {
    Date?: Date;
    Status?: string;
    Reason?: string;
    IP?: string;
    Attempt?: number;
}
