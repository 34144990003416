import {Injectable} from "@angular/core";
import {ReportUtils} from "../report-utils";
import {ReferralPdfReportDataBuilder} from "./referral-pdf-report-data-builder";
import {ReferralReportBuilder} from "./referral-report-builder";
import {ReferralXlsReportDataBuilder} from "./referral-xls-report-data-builder";
import {
  BaseExcelReportData,
  BasePdfReportData,
  ReferralReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator, BasePdfGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class ReferralReportService {

  async generateReport(reportRequest: ReferralReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildReportFileNameDates('Referrals',
      reportRequest.Practice, null, reportRequest.Branch.Name, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo );

    const reportGenerator: BasePdfGenerator | BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.REFERRAL,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType ? REPORT_FORMAT_TYPE.PDF : REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: ReferralReportRequest): Promise<BasePdfGenerator | BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.PDF === reportRequest.ReportFormatType) {
      return new BasePdfGenerator(await this.generatePdfReportData(reportRequest));
    } else
   if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
     return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
     throw new Error("Report format is not supported.");
    }
  }

  private async generatePdfReportData(reportRequest: ReferralReportRequest): Promise<BasePdfReportData> {
    return new ReferralPdfReportDataBuilder().build(
      await new ReferralReportBuilder().build(reportRequest));
  }

 private async generateXlsReportData(reportRequest: ReferralReportRequest): Promise<BaseExcelReportData> {
   return new ReferralXlsReportDataBuilder().build(
    await new ReferralReportBuilder().build(reportRequest));
  }
}
