import { Attribute, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'meraki-flux-account-value-tile',
  templateUrl: './account-value-tile.component.html',
  styleUrls: ['./account-value-tile.component.scss'],
})
export class AccountValueTileComponent implements OnInit {

  @Input() label: string;
  @Input() value: any;
  @Input() enableContentOffset = false;
  @Input() emphasize = false;

  constructor(@Attribute('class') public classes) {}

  ngOnInit(): void {}
}
