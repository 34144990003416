import { TRANSACTION_TYPE, Transaction } from "@meraki-flux/schema";

export class TransactionUtils {
  static getActiveTransactions(transactions: Transaction[]) {
    const reversedTransactionIds = transactions
      .filter((tx) => TRANSACTION_TYPE.TRANSACTION_REVERSE === tx.TransactionType)
      .map((tx) => tx?.Metadata?.ReferenceTransactionId);
    return transactions.filter(
      (tx) =>
        TRANSACTION_TYPE.TRANSACTION_REVERSE !== tx.TransactionType &&
        !reversedTransactionIds.includes(tx.Id)
    );
  }
}
