export interface Scheme {
  Name?: string;
  Code?: string;
  Plans?: Plan[];
  DiscontinuedDate?: string;
  FamCheckSupported?: boolean;
  CanSearchOnIDNumber?: boolean;
}

export interface Plan {
  Code?: string;
  Plan?: string;
  Options?: PlanOption[]
}

export interface PlanOption {
  Code?: string;
  Option?: string;
  RouteCode?: string;
}

export interface SchemeDetails {
  Code?: string;
  Name?: string;
  Phone?: string;
  Phone2?: string;
  Fax?: string;
  Email?: string;
  Email2?: string;
  Url?: string;
  IsCapitated?: boolean;
}

export interface SchemePlanOption {
  Scheme: string,
  Plan: string;
  Option: string;
}

export interface FamCheckAdministrator {
  schemeName?: string;
  schemeCode?: string;
  canSearchOnIDNumber?: boolean;
}

export interface SchemePlanOptionCodes {
  SchemeCode?: string;
  PlanCode?: string;
  OptionCode?: string;
}

export interface SchemePlanOptionNames {
  SchemeName?: string;
  PlanName?: string;
  OptionName?: string;
}

export const eRASchemes = ['S0350', 'S3377', 'S1321', 'S3234', 'S0212', 'S3084', 'S3321', 'S3235', 'S0680', 'S3063', 'S3080', 'S3246', 'S2804', 'S2895', 'S3087', 'S2920', 'S3088', 'S2359', 'S2953', 'S3089', 'S3373', 'S2481', 'S3090', 'S3059', 'S3091', 'S2525', 'S3092', 'S3403', 'S3164', 'S2771', 'S3093', 'S2973', 'S3094', 'S1590', 'S3340', 'S0130', 'S3044', 'S0310', 'S0400', 'S3209', 'S0762', 'S0805', 'S1790', 'S3155', 'S0660', 'S1651', 'S1930', 'S2050', 'S2160', 'S2200', 'S3323', 'S2650', 'S2720', 'S2918', 'S1751', 'S2070', 'S2715', 'S3176', 'S0760', 'S3105', 'S3231', 'S0183', 'S2889', 'S2892', 'S1965', 'S2591', 'S3104', 'S0430', 'S2786', 'S0970', 'S3003', 'S2250', 'S2290', 'S2785', 'S2803', 'S2779'];
