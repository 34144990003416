/**
 * These are not versioned as secrets form part of our configuration and configuration change should
 * require a redployment on functions that rely on it.
 */
export enum SecretKey {
  JWT_SIGNATURE_FLORENCE = 'jwt-signature-florence',
  JWT_SIGNATURE_FLORENCE_DEMO = 'jwt-signature-florence-demo',
  SENDGRID_API_KEY = 'api-key-sendgrid',
  SENDGRID_WEBHOOK_KEY = 'webhook-key-sendgrid',
  MEDPRAX_API_USER = 'api_user_medprax',
  MEDPRAX_API_PASSWORD = 'api_password_medprax',
  MEILISEARCH_PRIVATE_KEY = 'meilisearch-private-key',
  MEILISEARCH_LOOKUP_PRIVATE_KEY = 'meilisearch-lookup-private-key',
  MEILISEARCH_ACCOUNT_PRIVATE_KEY = 'meilisearch-account-private-key',
  MEILISEARCH_INVOICE_PRIVATE_KEY = 'meilisearch-invoice-private-key',
  MEILISEARCH_CALENDAR_PRIVATE_KEY = 'meilisearch-calendar-private-key',
  FLORENCE_API_KEY = 'florence-api-key',
}