import { Injectable } from '@angular/core';
import { ReportUtils } from '../report-utils';
import {BaseExcelReportData, RefundReportRequest, Report, REPORT_FORMAT_TYPE, REPORT_NAME} from "@meraki-flux/schema";
import { RefundReportBuilder } from './refund-report-builder';
import { RefundXlsReportDataBuilder } from './refund-xls-report-data-builder';
import { BaseExcelGenerator } from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class RefundReportService {

  async generateReport(reportRequest: RefundReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildRefundReportFileName('Refunds',
      reportRequest.Practice, reportRequest.Branch.Name, reportRequest.ReportFormatType
      ,reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.REFUND,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: RefundReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }

  private async generateXlsReportData(reportRequest: RefundReportRequest): Promise<BaseExcelReportData> {
    return new RefundXlsReportDataBuilder().build(
      await new RefundReportBuilder().build(reportRequest));
  }

}
