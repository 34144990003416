import { Injectable } from '@angular/core';
import { ModifierService } from '../../services/modifier/modifier.service';
import { MoneyUtils } from '../../utils/money-utils';
import _ from 'lodash';
import {
  ChargeDto,
  ChargeLineDto,
  ChargeModifierParameterDto,
  Invoice,
  INVOICE_LINE_TYPE,
  INVOICE_TYPE,
  InvoiceLine,
  ModifierCodes,
  ModifierModelData,
  ModifierParameterDto,
  ModifierParameterInfoDto,
  ModifierParameters,
  ModifierParametersQueryDto,
  ModifierType,
  ModifierUIElements,
} from '@meraki-flux/schema';
import moment from 'moment';
import { SearchService } from '../../services/search.service';
import { Firestore, getDoc} from '@angular/fire/firestore';
import { doc } from '@firebase/firestore';

@Injectable({ providedIn: 'root' })
export class ModifierHelper {
  constructor(
    private modifierService: ModifierService, 
    private searchService: SearchService,
    private firestore: Firestore,
  ) {}

  procedureModifierCodes = ['0005', '0009', '0018', '0067', '0003', '0004'];

  async getModifierParameters(
    modifierParameterQuery: ModifierParametersQueryDto
  ): Promise<ModifierParameterInfoDto[]> {
    try {
      const modifierParameter: ModifierParameterInfoDto[] = await new Promise((resolve, reject) => {
        this.modifierService.getModifierParameters(modifierParameterQuery).subscribe(
          async (data) => {
            // Handle the API response here
            const modifierParameterInfo = await this.setParameterType(data);
            resolve(modifierParameterInfo);
          },
          (error) => {
            // Handle errors here
            console.error(error);
            reject(error);
          }
        );
      });

      return modifierParameter;
    } catch (error) {
      // Handle any exceptions here
      console.error(error);
      throw error;
    }
  }

  async modifyCharge(chargeDtoData: ChargeDto): Promise<ChargeDto> {
    try {
      const chargeDto: ChargeDto = await new Promise((resolve, reject) => {
        this.modifierService.modifyCharge(chargeDtoData).subscribe(
          (data) => {
            // Handle the API response here
            resolve(data);
          },
          (error) => {
            // Handle errors here
            console.error(error);
            reject(error);
          }
        );
      });

      return chargeDto;
    } catch (error) {
      // Handle any exceptions here
      console.error(error);
      throw error;
    }
  }

  // Mapping Invoice To ChargeDto
  mapInvoiceToChargeDto(dialogData: ModifierModelData, invoiceLine: any[]): ChargeDto {
    const invoice = dialogData.invoice;
    return {
      practiceNumber: '0000000',
      chargeNumber: '0000000',
      serviceDate: invoice.DateOfService,
      placeOfService: invoice.PlaceOfService,
      billingProviderDisciplineCode: this.getDisciplineCodeForModifier(
        dialogData?.disciplineCode,
        invoice?.TreatingProvider?.IsAnaesthetist
      ),
      gender: 'm',
      isBalanceBilled: false,
      chargeCategory: invoice.Type == INVOICE_TYPE.CASH ? 'Private' : 'MedicalAid',
      markUpPercentage: 100,
      chargeLines: this.mapInvoiceLinesToChargeLines(invoice, invoiceLine),
      customData: '',
    };
  }

  mapInvoiceLinesToChargeLines(invoice: Invoice, invoiceLines: any[]): ChargeLineDto[] {
    return invoiceLines.map((invoiceLine) => ({
      lineId: invoiceLine.LineNumber.toString(),
      lineOrder: invoiceLine.LineNumber,
      code: invoiceLine.TariffCode || '',
      qty: invoiceLine.Quantity || 0,
      units: 1,
      description: invoiceLine.UneditedDescription
        ? invoiceLine.UneditedDescription
        : invoiceLine.Description || '',
      parametersDescription: '',
      serviceAmountCents: invoiceLine.AmountBilled,
      medicalAidAmountCents:
        invoice.Type == INVOICE_TYPE.MEDICAL_AID ? invoiceLine.AmountBilled : 0,
      patientAmountCents: invoice.Type == INVOICE_TYPE.CASH ? invoiceLine.AmountBilled : 0,
      rcfCents: invoiceLine.RcfValue || 0,
      clinicalRCFCents: MoneyUtils.toCents(invoiceLine.ClinicalRcfValue ?? 0),
      modifierLinkedLineIds: invoiceLine.ModifierLinkedLineIds,
      isPerLineModifier: false,
      isApplyModifier: invoiceLine.IsApplyModifier === false ? false : true,
      isServiceCreatedLine: false,
      modifierParameters:
        invoiceLine.LineType == INVOICE_LINE_TYPE.MODIFIER ? invoiceLine.ModifierParameter : [],
      customData: '',
    }));
  }

  // Mapping ChargeLines To InvoiceLines
  mapChargeLinesToInvoiceLines(
    chargeLines: ChargeLineDto[],
    invoiceLine: any[],
    modifierParameterInfo: ModifierParameterInfoDto[]
  ): InvoiceLine[] {
    return chargeLines.map((chargeLine) => {
      const line = invoiceLine?.find((line) => line.LineNumber.toString() === chargeLine.lineId);
      return {
        Id: line?.Id,
        LineType: line?.LineType,
        LineNumber: Number(chargeLine.lineOrder || 0),
        TariffCode: chargeLine.code || '',
        NappiCode: line.NappiCode || '',
        Description:
          line?.LineType == INVOICE_LINE_TYPE.MODIFIER && line?.LineCodeSelected
            ? this.setParamaterDescription(chargeLine)
            : line.Description,
        UneditedDescription: chargeLine.description,
        DiagnosisCodes: line?.DiagnosisCodes,
        Quantity: this.getChargeQty(chargeLine, line?.LineType, modifierParameterInfo),
        MedicineAdditionalInfo: line?.MedicineAdditionalInfo,
        IsPPELine: line?.IsPPELine,
        AmountBilled: MoneyUtils.fromCents(chargeLine.serviceAmountCents) || 0,
        AmountVAT: 0,
        Balance: line?.Balance,
        ModifierParameter: chargeLine.modifierParameters,
        Units: line?.LineType == INVOICE_LINE_TYPE.MODIFIER ? chargeLine.minutes : chargeLine.units,
        ChargeStart: this.getChargeTimeValue(
          chargeLine.modifierParameters,
          ModifierParameters.START_TIME
        ),
        ChargeEnd: this.getChargeTimeValue(
          chargeLine.modifierParameters,
          ModifierParameters.END_TIME
        ),
        RcfValue: chargeLine.rcfCents,
        ChargeUnit: this.getChargeUnit(chargeLine, line?.LineType),
        ModifierLinkedLineIds: this.getModifierLinkedLineIds(
          chargeLines,
          chargeLine.modifierLinkedLineIds
        ),
        IsModifierLinked: true,
      };
    });
  }

  isTimeModifier(modifierParameters:ChargeModifierParameterDto[]):boolean{
    const chargeStart = modifierParameters.some(obj => obj.name === ModifierParameters.START_TIME);
    const chargeEnd = modifierParameters.some(obj => obj.name === ModifierParameters.END_TIME);
    if(chargeStart && chargeEnd){
      return true;
    }
    return false;
  }

  getChargeUnit(chargeLine: ChargeLineDto, lineType: string) {
    if (lineType == INVOICE_LINE_TYPE.MODIFIER) {
      const isTimeModifier = this.isTimeModifier(chargeLine.modifierParameters);
      if (isTimeModifier && chargeLine.code != ModifierCodes.M1211) {
        return 'Minutes';
      }
    }
    return 'Units';
  }

  getChargeQty(chargeLine: ChargeLineDto, lineType: string, modifierParameterInfo: ModifierParameterInfoDto[]): number {
    if (lineType == INVOICE_LINE_TYPE.MODIFIER) {
      const isTimeModifier = this.isTimeModifier(chargeLine.modifierParameters)
      if (isTimeModifier && chargeLine.code != ModifierCodes.M1211 
          && chargeLine.code === modifierParameterInfo[0]?.modifierCode) {
        return chargeLine.minutes;
      }
    }
    return chargeLine.qty;
  }

  getChargeTimeValue(modifierParameters: ChargeModifierParameterDto[], chargeType: string) {
    let time: string = null;
    const chargeTime = modifierParameters.find((c) => c.name == chargeType);
    if (chargeTime) {
      time = chargeTime.value;
    }
    return time;
  }

  getModifierLinkedLineIds(
    chargeLines: ChargeLineDto[],
    modifierLinkedLineIds: string[]
  ): string[] {
    const modifierLineIds = [];
    for (let i = 0; i < modifierLinkedLineIds.length; i++) {
      const line = chargeLines.find((line) => line.lineId == modifierLinkedLineIds[i]);
      if (line) {
        modifierLineIds.push(line.lineOrder.toString());
      }
    }
    return modifierLineIds;
  }

  getDisciplineCodeForModifier(disciplineCode: string, isAnaesthetist: boolean): string {
    let discipline = disciplineCode;
    //if discipline is GP and Anaesthetist enabled on provider, adding 'A' at the end
    if (disciplineCode === '014' && isAnaesthetist) {
      discipline = disciplineCode + 'A';
    }
    return discipline;
  }

  async setParameterType(
    modifierParameterInfo: ModifierParameterInfoDto[]
  ): Promise<ModifierParameterInfoDto[]> {
    for (const parameterInfo of modifierParameterInfo) {
      const isTimeParameter = parameterInfo.parameters.find(
        (parameter) =>
          parameter.name == ModifierParameters.START_TIME ||
          parameter.name == ModifierParameters.END_TIME
      );
      if (isTimeParameter) {
        parameterInfo.parameterType = ModifierType.TIME;
      }

      // Link modifier
      const isLinkParameter = parameterInfo.parameters.find(
        (parameter) => parameter.name == ModifierParameters.LINK
      );
      if (isLinkParameter) {
        parameterInfo.parameterType = ModifierType.LINK;
      }
      // Note : other modifier types needs to be implemented.
    }

    return modifierParameterInfo;
  }

  setParamaterDescription(chargeLine: ChargeLineDto) {
    let description = chargeLine.description;
    if (!description.includes(chargeLine.parametersDescription)) {
      description = chargeLine.parametersDescription
        ? chargeLine.parametersDescription + '. ' + chargeLine.description
        : chargeLine.description || '';
    }
    return description;
  }

  async getParameterArray(modifierParameterInfo: ModifierParameterInfoDto, data:ModifierModelData, isMedScheme: boolean): Promise<any[]> {

    const parameters = modifierParameterInfo.parameters;
    const invoice = data.invoice;
    const parameterArray = [];
    const timeArray = [];
    const inputArray = [];
    const linkArray = [];
    const comboArray = [];
    let checkObj = {};
    let linkObj = {};
    let bmiObj = {};    

    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i];

      switch (parameter.uiElement) {
        case ModifierUIElements.TIME_PICKER: {
          const checkRcf = parameterArray.some((parameter) => parameter.name == 'RCF');
          if (!checkRcf) {
            const rcfObj = {
              name: 'RCF',
              label: this.getRcfLable(modifierParameterInfo.modifierCode),
              isRequired: true,
              uiElement: parameter.uiElement,
            };
            parameterArray.push(rcfObj);
          }

          const timeObj = {
            name: parameter.name == ModifierParameters.START_TIME ? 'StartTime' : 'EndTime',
            label: this.toSentenceCase(parameter.uiName),
            isRequired: parameter.isRequired,
            uiElement: parameter.uiElement,
          };
          timeArray.push(timeObj);
          break;
        }

        case ModifierUIElements.INPUT_DECIMAL: {
          const inputObj = {
            name: parameter.name,
            label: parameter.name == ModifierParameters.HEIGHT ? 'Height (cm)' : 'Weight (kg)',
            isRequired: parameter.isRequired,
            uiElement: parameter.uiElement,
          };
          inputArray.push(inputObj);    

          const checkBMI = parameterArray.some((parameter) => parameter.name == 'BMI');
          if (!checkBMI) {
            bmiObj = {
              name: 'BMI',
              label: 'BMI',
              isRequired: false,
              uiElement: 'BMI',
            };            
          }

          break;
        }
        case ModifierUIElements.CHECKBOX:
          checkObj = this.getDefautParameterObject(parameter);
          break;

        case ModifierUIElements.COMBO: {
          const comboObj = {
            name: parameter.name.replace(/\./g, ""),
            label: parameter.name == ModifierParameters.ASSISTANT_PROVIDER_PRACTICE_NUMBER ? 'Assisting Provider' : 'Treating Provider',
            isRequired: parameter.isRequired,
            uiElement: parameter.uiElement,
          };
          if (data.tariffCode === ModifierCodes.M0011){            
            if(isMedScheme){
              comboArray.push(comboObj);
            }
          }else {
            comboArray.push(comboObj);
          }          
          break;
        }
        case ModifierUIElements.GRID: {
          const lines = invoice?.Lines;
          if (lines) {
              let invoiceLines = lines;
              // Show procedure code only for certain modifiers
              const procedureCode = this.procedureModifierCodes.some(code => code === data.tariffCode);
              if(procedureCode){
                const codes = lines.map((line: any) => line.TariffCode);
                const codesString = codes.join('|');
                const consultReqObj = {
                  tariffCodes: codesString,
                  year: moment(invoice.DateOfService).year().toString(),
                  planOptionCode: data.planOptionCode,
                  disciplineCode: data.disciplineCode,
                  model: true,
                };
                const consultCodes = await this.searchService.getConsultCodes(consultReqObj);
                invoiceLines = lines.filter((line) => !consultCodes.includes(line.TariffCode));
              }

            for (let i = 0; invoiceLines.length > i; i++) {
              if (this.isValidCode(invoiceLines[i], modifierParameterInfo, data)) {
                const truncatedDescription =
                  invoiceLines[i].Description.length > 40
                    ? invoiceLines[i].Description.substring(0, 40) + '...'
                    : invoiceLines[i].Description;
                const linkObj = {
                  lineNumber: invoiceLines[i].LineNumber,
                  description: invoiceLines[i].TariffCode + ' - ' + truncatedDescription,
                  code: invoiceLines[i].TariffCode
                };
                linkArray.push(linkObj);
              }
            }
          }
          linkObj = {
            name: parameter.name,
            label: this.toSentenceCase(parameter.uiName) + ':',
            isRequired: parameter.isRequired,
            uiElement: parameter.uiElement,
            linkArray: linkArray,
          };
          break;
        }

        default:
          // Handle any unexpected uiElement values
          console.warn(`Unexpected uiElement value: ${parameter.uiElement}`);
      }
    }

    if (timeArray.length > 0) {
      const timeArrayObj = { name: 'TimeArray', value: timeArray };
      parameterArray.push(timeArrayObj);
    }

    if (inputArray.length > 0) {  
      const sortedInputArray = inputArray.sort((a, b) => a.label.localeCompare(b.label));
      const inputArrayObj = { name: 'InputArray', value: sortedInputArray };
      parameterArray.push(inputArrayObj);
      parameterArray.push(bmiObj);
    }    

    parameterArray.push(checkObj);
    if (comboArray.length > 0) {
      const comboArrayObj = { name: 'ComboArray', value: comboArray };
      parameterArray.push(comboArrayObj);
    }
    if (linkArray.length > 0) {
      parameterArray.push(linkObj);
    }

    return parameterArray;
  }

  isValidCode(invoiceLine: any, modifierParameterInfo: ModifierParameterInfoDto, data:ModifierModelData): boolean {
  
    if ((data.tariffCode === '0018' || data.tariffCode === '0019') && invoiceLine.TariffCode === data.tariffCode 
        && invoiceLine.LineCodeSelected) {
      return false;
    }

    if ((data.tariffCode === '0018' || data.tariffCode === '0019') && 
        (invoiceLine.LineType === INVOICE_LINE_TYPE.PROCEDURE || invoiceLine.LineType === INVOICE_LINE_TYPE.MODIFIER) &&
        !invoiceLine.IsPPELine) {
      return true;
    }

    if (
      invoiceLine.LineType !== INVOICE_LINE_TYPE.PROCEDURE ||
      !invoiceLine.TariffCode ||
      invoiceLine.IsPPELine
    ) {
      return false;
    }
  
    const { restrictedCodes, applicableCodes } = modifierParameterInfo;
    return (
      (restrictedCodes[0] != '_' && !restrictedCodes?.includes(invoiceLine.TariffCode)) ||
      applicableCodes[0] === '*' ||
      applicableCodes?.includes(invoiceLine.TariffCode)
    );
  }  

  

  getDefautParameterObject(parameter: ModifierParameterDto) {
    return {
      name: parameter.name.replace(/\./g, '_'),
      label: this.toSentenceCase(parameter.uiName) + ':',
      isRequired: parameter.isRequired,
      uiElement: parameter.uiElement,
    };
  }

  toSentenceCase(str: string): string {
    const sentenceCaseString = _.transform(
      _.words(str),
      (result, word, index) => {
        result.push(index === 0 ? word : word.toLowerCase());
      },
      []
    ).join(' ');
    return sentenceCaseString;
  }

  updateLineOrder(data: ChargeLineDto[]): ChargeLineDto[] {
    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];
      if (currentItem.code === '0009') {
        const code0009Index = i;
        for (let j = 0; j < data.length; j++) {
          if (data[j].code === '0005') {
            const code0005Index = j;
            if (code0005Index > code0009Index) {
              const tempOrder = data[code0005Index].lineOrder;
              data[code0005Index].lineOrder = data[code0009Index].lineOrder;
              data[code0009Index].lineOrder = tempOrder;
            }
            break;
          }
        }
        break;
      }
    }
    return data;
  }

  getRcfLable(modifierCode: string){
    const anaesthetistRcfCodes = ['0036', '0039'];
    const isAnaesthetistRcf = anaesthetistRcfCodes.find((code) => code === modifierCode);
    if (isAnaesthetistRcf) {
      return 'Anaesthetic RCF';
    }
    return 'RCF';
  }

  async isMedschemes(schemeCode: string){
    const schemeLookupDoc = await getDoc(doc(this.firestore, 'Configuration/SchemeLookup'));
    const medschemes = schemeLookupDoc.data()['Medschemes'];    
    return medschemes.includes(schemeCode);
  }  

}
