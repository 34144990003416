import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { DateUtils } from './date-utils';
import { MoneyUtils } from './money-utils';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FormatUtils {
  private static readonly DATE_FORMAT__DD_MM_YYYY = 'DD/MM/YYYY';
  private static readonly DATE_FORMAT__HH_MM = 'HH:mm';
  private static readonly currencyPipe = new CurrencyPipe('en-ZA', 'R');

  static join(strings: string[], separator: string): string {
    return strings
      ?.map((v) => v?.trim())
      ?.filter((v) => v)
      ?.join(separator);
  }

  static nameSurname(name: string, surname: string): string {
    return FormatUtils.join([name, surname], ' ');
  }

  static dateDDMMYYYY(anyDate: any): string {
    return this.formatDate(anyDate, FormatUtils.DATE_FORMAT__DD_MM_YYYY);
  }

  static formatDate(anyDate: any, format: string): string {
    const date = DateUtils.toDate(anyDate);
    return date ? moment(date).format(format) : null;
  }

  static formatCents(cents: number): string {
    return cents || cents === 0
      ? this.currencyPipe.transform(MoneyUtils.fromCents(cents), 'R ', 'symbol', '1.2-2')
      : null;
  }

  static dateHHMM(anyDate: any): string {
    return this.formatDate(anyDate, FormatUtils.DATE_FORMAT__HH_MM);
  }

  static memberNoDepCode(memberNo: string, depCode: string): string {
    return memberNo ? FormatUtils.join([memberNo, depCode ? `(${depCode})` : ''], ' ') : '';
  }

  static formatReasonCodes(reasonCodes: string[]): string {
    return FormatUtils.join(
      _.uniq(reasonCodes)?.sort((a, b) => Number(a) - Number(b)),
      ', '
    );
  }
}
