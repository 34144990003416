import * as moment from 'moment';
import {NoDataError, TRANSACTION_TYPE, VATData1, VATSummaryReportModel} from "@meraki-flux/schema";

export class VATSummaryReportBuilder {
  private readonly DATE_FORMAT = 'DD/MM/YYYY';

  async build(reportRequest: VATSummaryReportModel): Promise<VATSummaryReportModel> {

    this.checkDataAvailability(reportRequest);
    const data = reportRequest;


    data.VATData2 = [...reportRequest.VATData1, ...reportRequest.VATData2]; //combbine invoice and transactions.
    data.VATData2 = this.filterUniqueTransactions(data.VATData2); //filter out, unique for invoices and credit notes, all for write off(per line).
    data.VATData1 = await this.buildVATProvider(data.VATData2); //groupByMonthProvider and sortByProviderAndDate.

    let summaryDetails = [];
    summaryDetails = this.groupByMonthSummary(data.VATData1, ['CustomDate']);
    data.SummaryDetails = this.sortByDate(summaryDetails);

    data.VATData2 = [...reportRequest.VATData2, ...reportRequest.SummaryDetails]; //used for testing

    //Group by provider - for each tab
    const result = data.VATData1.reduce((acc, item) => {
      const providerName = item.TreatingProviderName;
      acc[providerName] = acc[providerName] || [];
      acc[providerName].push(item);
      return acc;
    }, {});
    data.ProviderVATData = Object.values(result).map(this.createProviderVATData);

    data.ReportDate = moment(new Date()).format(this.DATE_FORMAT);
    data.DateRange =
      moment(reportRequest.DateFrom).format(this.DATE_FORMAT) +
      ' - ' +
      moment(reportRequest.DateTo).format(this.DATE_FORMAT);
    return data;
  }

  private async buildVATProvider(data: VATData1[]): Promise<VATData1[]> {
    let vATData1 = [];
    vATData1 = this.groupByMonthProvider(data, [
      'CustomDate',
      'TreatingProviderName',
      'HPCSANumber',
    ]);

    vATData1 = this.sortByProviderAndDate(vATData1);

    return vATData1;
  }

  private filterUniqueTransactions(data: VATData1[]): VATData1[] {
    return data.reduce((acc, item) => {
      const key = `${item.TransactionType}-${item.HeaderId}`;
      const exists = acc.some((accItem) =>
        accItem.key === key && (item.TransactionType !== TRANSACTION_TYPE.WRITE_OFF || accItem.TransactionType !== TRANSACTION_TYPE.WRITE_OFF)
      );
      if (!exists) {
        acc.push({ ...item, key });
      }
      return acc;
    }, [] as VATData1[]);
  }

  private groupByMonthProvider(data, keys) {
    const groups = {};
    for (const item of data) {
      const groupKey = keys.map((key) => item[key]).join(' - ');

      // Check if both VAT values are zero before creating the group
      if (item.OutputVAT !== 0 || item.InputVAT !== 0) {
        if (!groups[groupKey]) {
          groups[groupKey] = {
            key: groupKey,
            HPCSANumber: item.HPCSANumber,
            TreatingProviderName: item.TreatingProviderName,
            CustomDate: item.CustomDate,
            TargetDate: item.TargetDate,
            OutputVAT: 0,
            InputVAT: 0,
            // BalanceVAT: 0,
          };
        }
        groups[groupKey].OutputVAT += item.OutputVAT;
        groups[groupKey].InputVAT += item.InputVAT;
        //groups[groupKey].BalanceVAT += item.BalanceVAT;
      }
    }
    return Object.values(groups);
  }

  private groupByMonthSummary(data, keys) {
    const groups = {};
    for (const item of data) {
      const groupKey = keys.map((key) => item[key]).join(' - ');
      if (!groups[groupKey]) {
        groups[groupKey] = {
          key: groupKey,
          CustomDate: item.CustomDate,
          TargetDate: item.TargetDate,
          OutputVAT: 0,
          InputVAT: 0,
        };
      }
      groups[groupKey].OutputVAT += item.OutputVAT;
      groups[groupKey].InputVAT += item.InputVAT;
    }
    return Object.values(groups);
  }

  private createProviderVATData(group) {
    const treatingProviderName = group[0].TreatingProviderName;
    const providerVATData = group.map((item) => ({ ...item })); // Clone each item to avoid reference issues
    return { TreatingProviderName: treatingProviderName, ProviderVATData: providerVATData };
  }

  private sortByDate(data: VATData1[]): VATData1[] {
    return data.sort((a, b) => {
        return a.TargetDate.getTime() - b.TargetDate.getTime();
    });
  }

  private sortByProviderAndDate(data: VATData1[]): VATData1[] {
    return data.sort((a, b) => {
      if (a.TreatingProviderName !== b.TreatingProviderName) {
        return a.TreatingProviderName.localeCompare(b.TreatingProviderName);
      } else {
        return a.TargetDate.getTime() - b.TargetDate.getTime();
      }
    });
  }

  private checkDataAvailability(data: VATSummaryReportModel) {
    let noData = true;

    if (data.VATData1.length > 0) {
      noData = false;
    }
    if (data.VATData2.length > 0) {
      noData = false;
    }
    if (noData) throw new NoDataError();
  }

}
