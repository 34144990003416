import moment = require("moment");
import {
  BaseExcelReportData, CellType, CellWidth, EmailMessagingDataList,
  EmailMessagingReportModel,
  EmailMessagingReportRequest, EmailMessagingStatusData, EmailMessagingTypeData, ExcelCellData,
  REPORT_NAME, Sheet, Table
} from '@meraki-flux/schema';

export class EmailMessagingXlsReportDataBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    build(emailMessagingReportModel: EmailMessagingReportModel,reportRequest: EmailMessagingReportRequest): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.name = REPORT_NAME.EMAIL_MESSAGING;
        excelReportData.data = [];

        excelReportData.data.push(this.buildReportSheetSummary(emailMessagingReportModel,reportRequest));
        excelReportData.data.push(this.buildReportSheetDetail(emailMessagingReportModel,emailMessagingReportModel.EmailMessagingReportInfo.IsMultiBranch));

        this.buildReportInfo(excelReportData,emailMessagingReportModel);

        return excelReportData;
    }

    private buildReportSheetSummary(emailMessagingReportModel: EmailMessagingReportModel, reportRequest: EmailMessagingReportRequest): Sheet {
        const sheet: Sheet = {};

        const DateFrom = moment(reportRequest.DateFrom).format(this.DATE_FORMAT);
        const DateTo = moment(reportRequest.DateTo).format(this.DATE_FORMAT);

        sheet.name = 'Summary';
        sheet.header = 'Summary of emails sent from '+DateFrom+' to '+DateTo;
        sheet.tables = [];

        //Email Type summary
        const emailTypeTable: Table = {};

        emailTypeTable.headers = [
            {value: 'Email Type Summary', bold: true, width: CellWidth.S, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const emailTypeRows = [];
        const emailTypeRow: ExcelCellData[] = [];
        emailTypeRow.push({value: 'Email type', bold: true});
        emailTypeRow.push({value: 'No. sent', bold: true, hAlignment: 'right'});
        emailTypeRows.push(emailTypeRow);

        const emailTypeSummary = emailMessagingReportModel.EmailMessagingModel.EmailTypeSummaryItem;
        emailTypeSummary.sort((a,b) => a.EmailType.localeCompare(b.EmailType));

        let totalEmailType = 0;
        emailTypeSummary.forEach((item: EmailMessagingTypeData) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.EmailType });
            row.push({value: item.TotalEmailTypeCount, type: CellType.NUMBER});
            totalEmailType= totalEmailType + item.TotalEmailTypeCount;

            emailTypeRows.push(row);
        });

        const totalTypeRow: ExcelCellData[] = [];
        totalTypeRow.push({value: 'Total', bold: true});
        totalTypeRow.push({value: totalEmailType, bold: true});
        emailTypeRows.push(totalTypeRow);

        emailTypeTable.rows = emailTypeRows;
        sheet.tables.push(emailTypeTable);

        //Email Status summary
        const emailStatusTable: Table = {};

        emailStatusTable.headers = [
            {value: 'Email Status Summary', bold: true, width: CellWidth.S, wordWrap: true},
            {value: '', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const emailStatusRows = [];
        const emailStatusRow: ExcelCellData[] = [];
        emailStatusRow.push({value: 'Status', bold: true});
        emailStatusRow.push({value: 'No.', bold: true, hAlignment: 'right'});
        emailStatusRows.push(emailStatusRow);

        const emailStatusSummary = emailMessagingReportModel.EmailMessagingModel.EmailStatusSummaryItem;
        //if the email status is null, then make it 'No Status' for sorting
        emailStatusSummary.forEach((item: EmailMessagingStatusData) => {
            if (!item.EmailStatus) {
                item.EmailStatus = 'No Status';
            }
        });

        emailStatusSummary.sort((a,b) => a.EmailStatus.localeCompare(b.EmailStatus));

        let totalEmailStatus = 0;
        emailStatusSummary.forEach((item: EmailMessagingStatusData) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.EmailStatus });
            row.push({value: item.TotalEmailStatusCount, type: CellType.NUMBER});
            totalEmailStatus= totalEmailStatus + item.TotalEmailStatusCount;

            emailStatusRows.push(row);
        });

        const totalEmailStatusRow: ExcelCellData[] = [];
        totalEmailStatusRow.push({value: 'Total', bold: true});
        totalEmailStatusRow.push({value: totalEmailStatus, bold: true});
        emailStatusRows.push(totalEmailStatusRow);

        emailStatusTable.rows = emailStatusRows;
        sheet.tables.push(emailStatusTable);


        return sheet;
    }

    private buildReportSheetDetail(emailMessagingReportModel: EmailMessagingReportModel , addBranchColumn?: boolean) {
        const sheet: Sheet = {};
        sheet.name = 'Detailed';
        sheet.tables = [];

        const detailsTable: Table = {};
        detailsTable.headers = [
            {value: 'Date/time sent', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Email type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'DocumentId', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Recipient email address', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'Account no. (If patient)', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Email subject', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Sent by', bold: true, width: CellWidth.XS},
            {value: 'Status', bold: true, width: CellWidth.XS},
		];

        if (addBranchColumn) {
            detailsTable.headers.push({value: 'Branch', bold: true, width: CellWidth.XS});
        }
        const detailsRows = [];

        const emailDetail = emailMessagingReportModel.EmailMessagingModel.EmailMessagingDSData;

        emailDetail.forEach((item: EmailMessagingDataList) => {
        const row: ExcelCellData[] = [];

        row.push({value: item.CreatedAt});
        row.push({value: item.Type});
        row.push({value: item.MessageId});
        row.push({value: item.RecipientEmail});
        row.push({value: item.AccountNumber, hAlignment: 'left'});
        row.push({value: item.Subject});
        row.push({value: item.CreatedBy});
        row.push({value: item.Status});
        if (addBranchColumn)
                row.push({value: item.Branch});
        detailsRows.push(row);
      });

        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);
        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, emailMessagingReportModel: EmailMessagingReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: emailMessagingReportModel.EmailMessagingReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: emailMessagingReportModel.EmailMessagingReportInfo.PracticeId, type: CellType.GENERAL}
        });

        if (emailMessagingReportModel.EmailMessagingReportInfo.IsMultiBranch) {
            excelReportData.parameters.push(
                {
                    name: 'Branch',
                    data: {value: emailMessagingReportModel.EmailMessagingReportInfo.Branch, type: CellType.GENERAL}
                });
        }

        excelReportData.parameters.push(
            {
                name: 'Date Range',
                data: {value: emailMessagingReportModel.EmailMessagingReportInfo.DateRange, type: CellType.GENERAL}
            });
    }
}
