/* eslint-disable object-curly-spacing,require-jsdoc,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment,max-len,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import * as ExcelJS from "exceljs";
import moment = require("moment");
import {IReportGenerator} from "./report-generator";
import {
  BaseExcelReportData,
  CellType,
  ExcelParameter, FileUploadData,
  FileUploadResult,
  ReportContent,
  Sheet,
  Table
} from '@meraki-flux/schema';
import { ExcelJSHelper } from '../helpers/exceljs.helper';

/*
  Helper module to generate some common exceljs structures.
*/
export class BaseExcelGenerator implements IReportGenerator {
  private readonly DEFAULT_TABLE_HEADER_BACKGROUND_COLOR = "DDDDDDDD";
  private readonly DESCRIPTION_BACKGROUND_COLOR = "F2F2F2";
  private readonly DATE_GENERATED_FORMAT = "DD/MM/YYYY";

  private readonly data: BaseExcelReportData;
  private workbook: any;

  public generateReport(): ReportContent {
    this.workbook = ExcelJSHelper.createEmptyWorkbook();
    ExcelJSHelper.workbookProperties(this.workbook);
    this.addMainSheets();
    this.addParametersSheet();
    this.checkWorkbookIsEmpty();
    return {
      data: this.workbook,
    } as ReportContent;
  }

  public async uploadReport(fName: string, data: any, uploadDataFunc?: any): Promise<FileUploadResult> {
    const fileName = this.getReportName(fName);
    const buffer = await data.xlsx.writeBuffer();
    const fileUploadData: FileUploadData = {
      content: buffer as Buffer,
      fileName: fileName,
      metadata: undefined,
      makePublic: true,
    };
    const result = await uploadDataFunc(
        `Practice/${this.data.practiceId || -1}/reports`,
        fileUploadData,
        undefined
    );
    return result;
  }

  private addMainSheets() {
    if (this.data) {
      this.data.data.forEach((sheet) => {
        this.addSingleSheet(sheet);
      });
    }
  }

  private addSingleSheet(sheet: Sheet): ExcelJS.Worksheet {
    const worksheet = ExcelJSHelper.addSheet(this.getWorkbook(), sheet.name);
    ExcelJSHelper.addWorksheetHeader(worksheet, sheet.header);

    if (sheet.extra) {
      this.addParameters(worksheet, sheet.extra);
    }
    this.addTables(worksheet, sheet.tables);
    return worksheet;
  }

  private addTables(worksheet: ExcelJS.Worksheet, tables?: Table[]) {
    if (tables) {
      tables.forEach((table) => {
        this.addTable(worksheet, table);
      });
    }
  }

  private addTable(worksheet: ExcelJS.Worksheet, table: Table) {
    ExcelJSHelper.addEmptyRow(worksheet);
    if (table.name) {
      const nameRow = ExcelJSHelper.addEmptyRow(worksheet);
      ExcelJSHelper.setCellValue(nameRow.getCell(1), table.name);
      if (table.nameBGColor) {
        if (table.nameMergeCount) {
          ExcelJSHelper.setCellBackgroundMerged(nameRow.getCell(1), table.nameMergeCount, table.nameBGColor);
        } else {
          ExcelJSHelper.setCellBackground(nameRow.getCell(1), table.nameBGColor);
        }
      }
    }
    if (table.description) {
      const descriptionRow = ExcelJSHelper.addEmptyRow(worksheet);
      ExcelJSHelper.setCellValue(descriptionRow.getCell(1), table.description);
      ExcelJSHelper.setCellBackground(descriptionRow.getCell(1), this.DESCRIPTION_BACKGROUND_COLOR);
      worksheet.mergeCells(descriptionRow.number, 1, descriptionRow.number, table.headers?.length);
    }
    if (table.rows && table.rows.length > 0) {
      const headerColor = table.noHeaderBackground ? null : this.DEFAULT_TABLE_HEADER_BACKGROUND_COLOR;
      ExcelJSHelper.fillRow(worksheet, table.headers, headerColor);
      if (table.rows && table.rows.length > 0) {
        table.rows.forEach((row) => {
          ExcelJSHelper.fillRow(worksheet, row);
        });
      }
    } else {
      const nothingRow = ExcelJSHelper.addEmptyRow(worksheet);
      ExcelJSHelper.setCellValue(nothingRow.getCell(1), "No records");
    }
  }

  private addParametersSheet() {
    const reportInfo = ExcelJSHelper.addSheet(
        this.getWorkbook(),
        "Report Info"
    );
    reportInfo.columns = [
      {key: "label", width: 25},
      {key: "value", width: 25},
    ];
    ExcelJSHelper.addWorksheetHeader(reportInfo, this.data.name);
    this.insertParameters(reportInfo);
    ExcelJSHelper.insertHBLogo(this.getWorkbook(), reportInfo);
  }

  getWorkbook(): ExcelJS.Workbook {
    return this.workbook;
  }

  private getParameters(): ExcelParameter[] | undefined {
    return this.data.parameters;
  }

  private checkWorkbookIsEmpty() {
    if (this.workbook.worksheets.length === 0) {
      ExcelJSHelper.addSheet(this.workbook);
    }
  }

  private insertParameters(reportInfo: ExcelJS.Worksheet) {
    const row = ExcelJSHelper.addEmptyRow(reportInfo);
    ExcelJSHelper.setCellValue(row.getCell(1), "Date generated");
    ExcelJSHelper.setItalic(row.getCell(1));
    ExcelJSHelper.setCellValue(row.getCell(2), moment(new Date()).format(this.DATE_GENERATED_FORMAT), CellType.GENERAL);
    ExcelJSHelper.setItalic(row.getCell(2));
    if (this.getParameters() && this.getParameters()?.length !== 0) {
      this.addParameters(reportInfo, this.getParameters());
    }
  }

  private addParameters(
      worksheet: ExcelJS.Worksheet,
      parameters: ExcelParameter[]
  ) {
    ExcelJSHelper.addEmptyRow(worksheet);
    parameters.forEach((par) => {
      const row = ExcelJSHelper.addEmptyRow(worksheet);
      ExcelJSHelper.setCellValue(row.getCell(1), par.name);
      ExcelJSHelper.setCellValue(row.getCell(2), par.data.value, par.data.type);
      ExcelJSHelper.setBoldCell(row.getCell(2));
    });
  }

  private getReportName(fName?: string) {
    let fileName = "Report.xlsx";
    if (fName) {
      fileName = fName;
      if (fileName.split(".").pop() !== "xlsx") {
        fileName += ".xlsx";
      }
    } else if (this.data.name) {
      fileName = `${this.data.name}_${moment(new Date()).format(
          "DD-MM-YYYY_hh:mm:ss"
      )}.xlsx`;
    }
    return fileName;
  }

  constructor(data: BaseExcelReportData) {
    this.data = data;
  }
}
