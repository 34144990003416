import { AbstractControl, ValidatorFn } from "@angular/forms";

export const SAIDNumberValidator: ValidatorFn = (control: AbstractControl) => {
  if (control) {
    let invalid = false;
    const idNumber: string = control.value?.trim() || '';
    invalid = idNumber?.length > 0 && idNumber?.length !== 13;

    if (!invalid) {
      const checkDigit = +idNumber.substring(12);
      const valWithoutCheckDigit = Array.from(idNumber.substring(0, 12));

      const checkSum = valWithoutCheckDigit.reverse().reduce<number>((totalSum, current, index) => {
        if (index % 2 === 0) {
          const sumVal = Array.from(((+current) * 2).toString()).reduce((sum, val) => sum += +val, 0);
          totalSum += sumVal;
        } else {
          totalSum += +current;
        }
        return totalSum;
      }, 0);

      const modulus = (checkSum % 10);
      if (modulus > 0) {
        invalid = (10 - modulus) !== checkDigit;
      } else {
        invalid = false;
      }
    }

    return invalid ? { 'invalid-id': 'Invalid SA ID Number' } : null;
  }
  return null;
}