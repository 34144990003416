import { Injectable } from '@angular/core';
import { createStore, setProp, Store, withProps } from '@ngneat/elf';

@Injectable({
  providedIn: 'root'
})
export class TabIndexRepository {
  private readonly tabIndexStore!: Store;

  constructor() {
    this.tabIndexStore = createStore({ name: 'templateTab' },
      withProps<{providerTabIndex: number, templateTabIndex: number}>({
        providerTabIndex: 0, templateTabIndex: 0
        })
    );
  }

  setProviderTab(index: number) {
    this.tabIndexStore.update(setProp('providerTabIndex', index));
  }

  getProviderTab() {
    return this.tabIndexStore.query(state => state.providerTabIndex);
  }

  setTemplateTab(index: number) {
    this.tabIndexStore.update(setProp('templateTabIndex', index));
  }

  getTemplateTab() {
    return this.tabIndexStore.query(state => state.templateTabIndex);
  }
}