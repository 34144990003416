import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { environment } from '../../environments/environment';
import { getFunctions, httpsCallable } from "@angular/fire/functions";
import { getApp } from "@angular/fire/app";
import {NoDataError, TariffPriceRequest} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  BASE_URL = `${environment.loadBalancerUrl}/v1/pricing`;

  constructor(private auth: AuthService, private httpClient: HttpClient) {

  }

  async getTariffPrice(priceRequest: TariffPriceRequest) {
    const url = `${this.BASE_URL}/tariff/${priceRequest.practiceId}/${priceRequest.providerId}`;
    const token = await this.auth.getToken();
    return await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        rejectUnauthorized: 'false'
      },
      params: {
        year: priceRequest.year,
        tariffCode: priceRequest.tariffCode,
        disciplineCode: priceRequest.disciplineCode,
        placeOfService: priceRequest.placeOfService,
        planOptionCode: priceRequest.planOptionCode
      }
    }).toPromise();
  }

  async getMedicinePrice(nappiCode: string, planOptionCode, quantity: number = 0, year: number) {
    const url = `${this.BASE_URL}/${this.auth.selectedBPN}/medicine`;
    const token = await this.auth.getToken();
    return await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        rejectUnauthorized: 'false'
      },
      params: {
        nappiCode,
        planOptionCode,
        quantity,
        year
      }
    }).toPromise();
  }

  async getConsumablePrice(nappiCode: string, planOptionCode, quantity: number = 0, year: number) {
    const url = `${this.BASE_URL}/${this.auth.selectedBPN}/consumable`;
    const token = await this.auth.getToken();
    return await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        rejectUnauthorized: 'false'
      },
      params: {
        nappiCode,
        quantity,
        planOptionCode,
        year
      }
    }).toPromise();
  }

  // I may need this code. so I just keep it.
  // async getRcfPrice(priceRequest: TariffPriceRequest) {
  //   const url = `${this.BASE_URL}/rcf/${priceRequest.practiceId}/${priceRequest.providerId}`;
  //   const token = await this.auth.getToken();
  //   return await this.httpClient.get(url, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       rejectUnauthorized: 'false'
  //     },
  //     params: {
  //       year: priceRequest.year,
  //       tariffCode: priceRequest.tariffCode,
  //       disciplineCode: priceRequest.disciplineCode,
  //       placeOfService: priceRequest.placeOfService,
  //       planOptionCode: priceRequest.planOptionCode
  //     }
  //   }).toPromise();
  // }

  async getRcfPrice(priceRequest: TariffPriceRequest) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const prepareReportData = httpsCallable(functions, 'prc-trf-v1-oncall-rcfPrice', { timeout: 540000});
    const result = await prepareReportData(priceRequest) as any;
    if (result.data?.success) {
      const price =  result.data?.data as any;
      return price;
    }
    else {
      if (result.data.data.reason == 'NoDataError') {
        throw new NoDataError();
      } else {
        throw result.data.error as Error;
      }
    }
  }

  async getClinicalRcfPrice(priceRequest: TariffPriceRequest) {
    const functions = getFunctions(getApp(), 'europe-west1');
    const prepareReportData = httpsCallable(functions, 'prc-trf-v1-oncall-clinicalRcfPrice', { timeout: 540000});
    const result = await prepareReportData(priceRequest) as any;
    if (result.data?.success) {
      const resp =  result.data?.data as any;
      return resp;
    }
    else {
      if (result.data.data.reason == 'NoDataError') {
        throw new NoDataError();
      } else {
        throw result.data.error as Error;
      }
    }
  }


}
