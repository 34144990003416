import * as _ from "lodash";
import { toUpper } from "lodash";
import {
  BasePdfReportData, CellType, ClaimDetailsItem,
  ClaimInvoiceProviderModel,
  ClaimInvoiceReportModel, PDFCellData,
  PDFParameter, REPORT_NAME,
  ReportHeader,
  ReportTable
} from "@meraki-flux/schema";

export class ClaimInvoicePdfReportDataBuilder {
    private static REPORT_SPECIAL_FONT_COLOR = "#000000";

    build(claimInvoiceReportModel: ClaimInvoiceReportModel): BasePdfReportData {
        const reportData: BasePdfReportData = {};
        reportData.bpn = claimInvoiceReportModel.ClaimInvoiceReportInfo.PracticeId
        reportData.orientation = "landscape";
        reportData.headers = this.buildHeader(claimInvoiceReportModel);
        reportData.tables = this.buildTables(claimInvoiceReportModel);
        reportData.dateHeader = `Date generated ${claimInvoiceReportModel.ReportDate}`;
        reportData.footerText = "\t\u200B".repeat(60)
        reportData.footer = {
            hbLogo: true
        }
        return reportData;
    }

    private buildHeader(claimInvoiceReportModel: ClaimInvoiceReportModel): ReportHeader[] {
        const leftParameters: PDFParameter[] = [];
        leftParameters.push({name: 'Billing practice no.', data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.PracticeId, decoration: {bold: true}}});
        leftParameters.push({name: 'Practice', data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.Practice, decoration: {bold: true}}});
        if (claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch) {
            leftParameters.push({name: 'Branch', data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.Branch, decoration: {bold: true}}});
        }
        const rightParameters: PDFParameter[] = [
            {name: 'Treating provider', data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.TreatingProvider, decoration: {bold: true}}},
            {name: 'Date Range', data: {value: claimInvoiceReportModel.ClaimInvoiceReportInfo.DateRange, decoration: {bold: true}}},
        ]
        return [
            {
                name: REPORT_NAME.CLAIMS_AND_INVOICES,
                nameColor: ClaimInvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR,
                includeGeneratedDate: true,
                left: leftParameters,
                right: rightParameters
            }
        ];
    }

    private buildTables(claimInvoiceReportModel: ClaimInvoiceReportModel): ReportTable[] {
        const reportTables: ReportTable[] = [];
        claimInvoiceReportModel.ProviderModels.forEach((providerTab) => {
            reportTables.push(this.buildSummaryTable(providerTab));
            reportTables.push(this.buildDetailsTable(providerTab.DetailsTable, 'Invoice/Claim Details', claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch));
            reportTables.push(this.buildDetailsTable(providerTab.CancelledDetailsTable, 'Cancelled Invoices', claimInvoiceReportModel.ClaimInvoiceReportInfo.IsMultiBranch));
        });
        return reportTables;
    }

    private buildSummaryTable(providerModel: ClaimInvoiceProviderModel): ReportTable {
        const table: ReportTable = {};
        table.name = toUpper('CLAIMS AND INVOICES: ' + providerModel.ProviderName);
        table.nameColor = ClaimInvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.nameUnderline = true;
        table.nameUnderlineColor = ClaimInvoicePdfReportDataBuilder.REPORT_SPECIAL_FONT_COLOR;
        table.headers = [{rows: [[{name: '', data: {value: 'Summary', decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Invoice type', decoration: {bold: true}, width:60},
            {value: 'Claim status', decoration: {bold: true}, width:60},
            {value: 'Items', decoration: {bold: true}, width:40},
            {value: 'Invoiced amount', decoration: {bold: true}, type: CellType.NUMBER, width:100},
            {value: 'Medical aid liable', decoration: {bold: true}, type: CellType.NUMBER, width:100},
            {value: 'Patient liable', decoration: {bold: true}, type: CellType.NUMBER, width:100},
        ];
        table.rowHeadersBold = true;
        const rows = [];
        let TotalCount = 0;
        let TotalInvoicedAmount = 0;
        let TotalMedicalAidLiable = 0;
        let TotalPatientLiable = 0;
        for (const item of providerModel.SummaryTable) {
            if (item.TotalCount) {
                TotalCount += item.TotalCount;
            }
            if (item.InvoicedAmount) {
                TotalInvoicedAmount += item.InvoicedAmount;
            }
            if (item.MedicalAidLiable) {
                TotalMedicalAidLiable += item.MedicalAidLiable;
            }
            if (item.PatientLiable) {
                TotalPatientLiable += item.PatientLiable;
            }
            const row: PDFCellData[] = [];
            row.push({value: item.InvoiceType});
            row.push({value: item.ClaimStatus});
            row.push({value: item.TotalCount});
            row.push({value: item.InvoicedAmount, type: CellType.CURRENCY});
            row.push({value: item.MedicalAidLiable, type: CellType.CURRENCY});
            row.push({value: item.PatientLiable, type: CellType.CURRENCY});
            rows.push(row);
        }
        const row: PDFCellData[] = [];
        row.push({value: "Total", decoration: {bold: true}});
        row.push({value: ""});
        row.push({value: TotalCount, decoration: {bold: true}});
        row.push({value: TotalInvoicedAmount, type: CellType.CURRENCY});
        row.push({value: TotalMedicalAidLiable, type: CellType.CURRENCY});
        row.push({value: TotalPatientLiable, type: CellType.CURRENCY});
        rows.push(row);
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        return table;
    }

    private buildDetailsTable(claimDetailsItem: ClaimDetailsItem[], tableName: string, isMultiBranch: boolean): ReportTable {
        const table: ReportTable = {};
        table.headers = [{rows: [[{name: "", data: {value: tableName, decoration: {bold: true, italics: true}}}]], backgroundColor: '#ececec'}];
        table.rowHeaders = [
            {value: 'Account no.', decoration: {bold: true}},
            {value: `Patient`, decoration: {bold: true}},
            {value: 'Invoice type', decoration: {bold: true}},
            {value: `Scheme`, decoration: {bold: true}},
            {value: `Claim status`, decoration: {bold: true}},
            {value: 'Date of service', decoration: {bold: true}},
            {value: 'Date of submission', decoration: {bold: true}},
            {value: 'Hb message ID', decoration: {bold: true}},
            {value: 'Invoiced amount', decoration: {bold: true}, type: CellType.NUMBER},
            {value: 'Medical aid liable', decoration: {bold: true}, type: CellType.NUMBER},
            {value: 'Patient liable', decoration: {bold: true}, type: CellType.NUMBER}
        ];
        if (isMultiBranch)
            table.rowHeaders.push({value: 'Branch', decoration: {bold: true}});

        const rows = [];
        for (const item of _.orderBy(claimDetailsItem, ['DateOfService', 'InvoiceNo'])) {
            const row: PDFCellData[] = [];
            row.push({value: item.AccountNo});
            row.push({value: item.PatientName});
            row.push({value: item.InvoiceType});
            row.push({value: item.Scheme});
            row.push({value: item.ClaimStatus});
            row.push({value: item.DateOfService, type: CellType.DATE});
            row.push({value: item.DateOfSubmission, type: CellType.DATE});
            row.push({value: item.HBMessageID});
            row.push({value: this.formatAmount(item.InvoicedAmount), type: CellType.NUMBER});
            row.push({value: this.formatAmount(item.MedicalAidLiable),type: CellType.NUMBER});
            row.push({value: this.formatAmount(item.PatientLiable),type: CellType.NUMBER});
            if (isMultiBranch)
                row.push({value: item.Branch});
            rows.push(row);
        }
        table.rows = rows;
        table.borders = {
            hor: true,
            headerBorderSize: 1,
        }
        if (!rows || rows.length === 0) {
            table.rows = undefined;
            table.rowHeaders = undefined;
            table.noRecordsText = {
                value: "No records",
                decoration: {
                    italics: true
                }
            }
        }
        table.fontSize = 8;
        return table;
    }


    formatAmount(amount: any) {
        if (amount === null || amount === undefined) {
            return undefined;
          }
        const amountstr = (amount / 100).toLocaleString();
        return `${amountstr}`;
    }
}
