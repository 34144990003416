/* eslint-disable require-jsdoc,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unnecessary-type-assertion,max-len,no-inner-declarations,@typescript-eslint/no-namespace */
import * as ExcelJS from "exceljs";
import moment = require("moment");
import { BASE64_DATA, CellType, CellWidth, ExcelCellData, HAlignment } from '@meraki-flux/schema';
export module ExcelJSHelper {
  const DEFAULT_FONT = "Arial";

  const DEFAULT_FONT_SIZE = 9;

  export function createEmptyWorkbook(): ExcelJS.Workbook {
    return new ExcelJS.Workbook();
  }

// updatedSheetName without special Characters
  export function cleanSheetName(name: string) {
    const REGEX_SHEET_NAME = /[\\*?:/\]\\[]/g;
    const cleanName = name.replace(REGEX_SHEET_NAME, "_");
    return cleanName;
  }

  export function addSheet(
      workbook: ExcelJS.Workbook,
      name?: string,
      options?: Partial<ExcelJS.AddWorksheetOptions>
  ): ExcelJS.Worksheet {
    if (name) {
      name = checkName(workbook, cleanSheetName(name));
    }
    return workbook.addWorksheet(name, options);
  }

  export function workbookProperties(
      workbook: ExcelJS.Workbook,
      width?: number,
      height?: number
  ) {
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: width || 10000,
        height: height || 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: "visible",
      },
    ];
  }

  export function fillRow(
      worksheet: ExcelJS.Worksheet,
      data?: ExcelCellData[],
      cellBackgroundColor?: string
  ) {
    if (data && data.length > 0) {
      const headersRow = addEmptyRow(worksheet);
      data.forEach((cellData, index) => {
        setCellValue(
            headersRow.getCell(index + 1),
            cellData.value,
            cellData.type,
            cellData.bold,
            cellData.wordWrap,
            cellData.shrinkToFit,
            cellData.hAlignment
        );
        if (cellData.width) {
          setColumnWidth(
              worksheet.getColumn(headersRow.getCell(index + 1).col),
              cellData.width
          );
        }
        if (cellData.bgColor) {
          setCellBackground(headersRow.getCell(index + 1), cellData.bgColor);
        }
        if (cellBackgroundColor) {
          setCellBackground(headersRow.getCell(index + 1), cellBackgroundColor);
        }
      });
      headersRow.commit();
    }
  }

  export function setBoldCell(cell: ExcelJS.Cell) {
    cell.style.font = setFontBold();
  }

  export function setDefaultCellStyle(cell: ExcelJS.Cell) {
    cell.style.font = setFontDefault();
  }

  export function setItalic(cell: ExcelJS.Cell) {
    cell.style.font = {
      size: DEFAULT_FONT_SIZE,
      name: DEFAULT_FONT,
      italic: true,
    };
  }

  export function setDateCellValue(cell: ExcelJS.Cell, value?: any) {
    const format = "DD/MM/yyyy";
    if (isValidDate(new Date(value))) {
      value = moment(new Date(value)).format(format);
    } else if (isValidTimestamp(value)) {
      value = moment(new Date(value.seconds * 1000)).format(format);
    }
    cell.value = value;
    cell.style.numFmt = format;
  }

  export function setCellValue(
      cell: ExcelJS.Cell,
      value?: any,
      type?: CellType,
      bold?: boolean,
      wordWrap?: boolean,
      shrinkToFit?: boolean,
      forceHAlignment?: HAlignment
  ) {
    if (type && type === CellType.DATE) {
      setDateCellValue(cell, value);
    } else {
      if (CellType.GENERAL !== type) {
        if (
          CellType.CURRENCY === type ||
            CellType.NUMBER === type ||
            !isNaN(+value)
        ) {
          if (!value) {
            cell.value = "-";
          }
          alignRight(cell);
        }
      }
      if (forceHAlignment) {
        alignHorizontal(cell, forceHAlignment);
      }
      cell.value = value;
      if (CellType.CURRENCY === type) {
        cell.value = value || value === 0 ? value / 100 : null;
        cell.numFmt = "\"R\" #,##0.00";
      }
    }
    if (bold) {
      setBoldCell(cell);
    } else {
      setDefaultCellStyle(cell);
    }
    if (wordWrap) {
      if (!cell.alignment) cell.alignment = {};
      cell.alignment.wrapText = true;
    }
    if (shrinkToFit) {
      if (!cell.alignment) cell.alignment = {};
      cell.alignment.shrinkToFit = true;
    }
  }

  export function setFontSize(
      size: number,
      bold?: boolean
  ): Partial<ExcelJS.Font> {
    return setDefaultFont(DEFAULT_FONT, bold ?? false, size);
  }

  export function setFontBold(): Partial<ExcelJS.Font> {
    return setDefaultFont(DEFAULT_FONT, true, DEFAULT_FONT_SIZE);
  }

  export function setFontDefault(): Partial<ExcelJS.Font> {
    return setDefaultFont(DEFAULT_FONT, false, DEFAULT_FONT_SIZE);
  }

  export function decorateCellGrey(cell: ExcelJS.Cell) {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {argb: "DDDDDDDD"},
    };
  }

  export function setCellBackground(cell: ExcelJS.Cell, color: string) {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {argb: color},
    };
  }

  export function decorateAsFullBlackBorder(cell: ExcelJS.Cell) {
    cell.border = {
      top: {style: "thin", color: {argb: "00000000"}},
      left: {style: "thin", color: {argb: "00000000"}},
      bottom: {style: "thin", color: {argb: "00000000"}},
      right: {style: "thin", color: {argb: "00000000"}},
    };
  }

  export function decorateAsLeftBlackBorder(cell: ExcelJS.Cell) {
    cell.border = {
      left: {style: "thin", color: {argb: "00000000"}},
    };
  }

  export function decorateAsRightBlackBorder(cell: ExcelJS.Cell) {
    cell.border = {
      right: {style: "thin", color: {argb: "00000000"}},
    };
  }

  export function decorateAsBottomBlackBorder(cell: ExcelJS.Cell) {
    cell.border = {
      bottom: {style: "thin", color: {argb: "00000000"}},
    };
  }

  export function alignRight(cell: ExcelJS.Cell) {
    if (!cell.alignment) cell.alignment = {};
    (cell.alignment as Partial<ExcelJS.Alignment>).horizontal = "right";
    // cell.alignment = { horizontal: 'right' };
  }

  export function alignHorizontal(cell: ExcelJS.Cell, hAlignment: HAlignment) {
    if (!cell.alignment) cell.alignment = {};
    (cell.alignment as Partial<ExcelJS.Alignment>).horizontal = hAlignment;
    // cell.alignment = { horizontal: 'right' };
  }

  export function addWorksheetHeader(
      worksheet: ExcelJS.Worksheet,
      header?: string
  ) {
    if (header) {
      worksheet.getCell("A1").master.style = {font: setFontSize(16)};
      worksheet.getCell("A1").master.alignment = {horizontal: "left"};
      worksheet.getCell("A1").value = header;
    }
  }

  export function setCellBackgroundMerged(cell: ExcelJS.Cell, count: number, color: string) {
    cell.worksheet.mergeCells(cell.fullAddress.row, cell.fullAddress.col, cell.fullAddress.row, count);
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {argb: color},
    };
  }

  export function addEmptyRow(worksheet: ExcelJS.Worksheet): ExcelJS.Row {
    return worksheet.addRow({});
  }

  export function addEmptyRows(
      worksheet: ExcelJS.Worksheet,
      count: number
  ): ExcelJS.Row {
    while (count-- > 0) {
      addEmptyRow(worksheet);
    }
    return worksheet.lastRow;
  }

  export function setDefaultFont(
      name: string,
      bold: boolean,
      size: number
  ): Partial<ExcelJS.Font> {
    return {
      size: size,
      name: name,
      bold: bold,
    };
  }

  export function setColumnWidth(col: ExcelJS.Column, width?: CellWidth): void {
    if (width) {
      switch (width) {
        case CellWidth.XS:
          col.width = 15;
          break;
        case CellWidth.S:
          col.width = 25;
          break;
        case CellWidth.M:
          col.width = 50;
          break;
        case CellWidth.L:
          col.width = 75;
          break;
        default:
          break;
      }
    } else {
      col.width = 25;
    }
  }

  export function insertHBLogo(
      workbook: ExcelJS.Workbook,
      worksheet: ExcelJS.Worksheet
  ) {
    const logoImage = workbook.addImage({
      base64: BASE64_DATA.LOGO,
      extension: 'png',
    });
    const row = 4 + worksheet.lastRow?.number;
    worksheet.addImage(logoImage, {
      // @ts-expect-error Issue with ExcelJs types.
      tl: {col: 0, row: row},
      // @ts-expect-error Issue with ExcelJs types.
      br: {col: 2, row: row + 3},
      editAs: "absolute",
    });
  }

  function checkName(
      workbook: ExcelJS.Workbook,
      name: string
  ): string {
    if (workbook.getWorksheet(name) !== undefined) {
      for (let i = 1; i < 1000; i++) {
        const check = `${name} ${i}`;
        if (workbook.getWorksheet(check) === undefined) {
          return check;
        }
      }
    }
    return name;
  }

  function isValidDate(date) {
    return (
      date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
    );
  }

  function isValidTimestamp(date) {
    return date && typeof date.seconds === "number";
  }
}
