import {Branch, Practice} from "../practice.models";
import {REPORT_FORMAT_TYPE} from "../report.models";
import {BaseInvoice} from "../invoice.models";
import { ReportRequestData } from './common.model';

export class LineItemReportRequest implements ReportRequestData {
  Practice: Practice;
  Branch?: Branch;
  ReportFormatType: REPORT_FORMAT_TYPE;
  DateFrom: Date;
  DateTo: Date;
  UserId: string;
  Multibranch: boolean;
  ReportDate?: string;
  DateRange?: string;
  TreatingProvider?: string | '';
  TreatingPracticeNumber?: string;
  Scheme?: string | '';
  ReportData: BaseInvoice[];
  LineDetails?: LineDetails[];
  GroupedByCodeDetails?: GroupedByCodeDetails[];
  SummaryPart1Details?: SummaryPart1Details[];
  SummaryPart2Details?: any;
  Recipient?: string;
}

export interface LineDetails {
  LineType?: string;
  Code?: string;
  Description?: string;
  Qty?: string;
  Amount?: number;
  InvoiceNo?: string;
  DateOfService?: string;
  Scheme?: string;
  Provider?: string;
  Branch?: string;
}

export interface GroupedByCodeDetails {
  LineType?: string;
  Code?: string;
  Qty?: string;
  Amount?: number;
}

export interface SummaryPart1Details {
  LineType?: string;
  Amount?: number;
}

export interface SummaryPart2Details {
  Provider?: string;
  LineType?: string;
  Amount?: number;
}
