import {CellType, HAlignment} from "./reports.common";
import { ReportLogoSetting } from "@meraki-flux/schema";

export interface PDFCellData {
  value: any;
  superscript?: string,
  type?: CellType;
  decoration?: Decoration;
  leftMargin?: number
  topMargin?: number;
  width?: string;
}

export interface RowHeaderData extends PDFCellData {
  width?: any; // '*', 'auto', 100
}


export interface Decoration {
  outline?: Outline;
  bold?: boolean;
  italics?: boolean;
  hAlignment?: HAlignment;
}

export interface Outline {
  left?: OutlineType;
  right?: OutlineType;
  top?: OutlineType;
  bottom?: OutlineType;
}

export enum OutlineType {
  THIN = 'THIN',
  MIDDLE = 'MIDDLE',
  BOLD = 'BOLD'
}

export interface PDFParameter {
  name?: string;
  data?: PDFCellData;
}

export interface ReportHeader {
  name?: string;
  nameColor?: string;
  includeGeneratedDate?: boolean;
  left?: PDFParameter[];
  right?: PDFParameter[];
  outlined?: boolean;
  outlineColor?: string;
  caption?: string;
  captionColor?: string;
}

export interface TableHeader {
  rows?: PDFParameter[][];
  outlined?: boolean;
  backgroundColor?: string;
  outlineColor?: string;
}

export interface ReportTable {
  newPage?: boolean;
  name?: string;
  nameColor?: string;
  nameUnderline?: boolean;
  nameUnderlineColor?: string;
  headers?: TableHeader[];
  reportICDLine?: ReportICDLine;
  rowHeaders?: RowHeaderData[];
  rowHeadersBold?: boolean;
  rowHeadersBGColor?: string;
  borders?: TableBorders;
  rows?: PDFCellData[][];
  legend?: ReportTableLegend;
  noRecordsText?: PDFCellData;
  fontSize?: number;
}

export interface ReportTableLegend {
  text: string;
  italics?: boolean;
  marginTop?: number;
}

export interface TableBorders {
  ver?: boolean;
  outerborder?: boolean;
  headerBorderSize?: number;
  rowBorderSize?: number;
  hor?: boolean;
}

export interface ReportICDLine {
  icdCode: string,
  status?: string
  outlined?: boolean;
  outlineColor?: string;
}

export interface FooterBankDetails {
  practiceName?: string;
  branchName?: string;
  accountNo?: string;
  accountType?: string;
}

export interface FooterEftInfo {
  paymentRef?: string;
  email?: string;
}

export interface FooterCompanyInfo {
  registrationNo?: string;
  vatNo?: string;
}

export interface FooterInfoTable {
  vatValue?: number;
  bankDetails?: FooterBankDetails;
  bankDetailsEft?: FooterBankDetails;
  eftInfo?: FooterEftInfo;
  companyInfo?: FooterCompanyInfo;
}

export interface ReportFooter {
  infoTable?: FooterInfoTable;
  hbLogo?: boolean;
}

export interface StatementSummary {
  patientLiable?: number;
  medicalAidLiable?: number;
  balance?: number;
}

export interface StatementAges {
  unallocated?: number;
  d120: number;
  d90_120: number;
  d60_90: number;
  d30_60: number;
  d0_30: number;
}

export interface ClaimDetailTableRow {
  header1: string,
  value1: PDFCellData,
  header2: string,
  value2: PDFCellData,
}

export interface ClaimDetailDataTable {
  rows: ClaimDetailTableRow[];
}

export interface ClaimDetailData {
  claimStatus?: ClaimDetailDataTable,
  details?: ClaimDetailDataTable
}

export interface AccountDetailsData {
  patient?: ClaimDetailDataTable,
  accountDetails?: ClaimDetailDataTable,
  mainMember?: ClaimDetailDataTable,
}

export interface ReportSnapscan {
  qr?: string,
  snapScanPng?: string,
}

export interface BasePdfReportData {
    logo?: ReportLogoSetting;
    orientation?: "landscape" | "portrait";
    reportName?: string;
    reportNameColor?: string;
    doctorName?: string;
    doctorNameColor?: string;
    bpn?: string;
    headers?: ReportHeader[];
    tables?: ReportTable[];
    mainHeader?: string;
    dateHeader?: string;
    footer?: ReportFooter;
    footerText? : string;
    statementSummary?: StatementSummary;
    medInsurerstatementSummary?: StatementSummary;
    statementAges?: StatementAges,
    bottomMessage?: string;
    letterTable?: ReportTable;
    letterText?: string[];
    claimDetailData?: ClaimDetailData;
    accountDetailsData?: AccountDetailsData;
    disclaimer?: string;
    reasonCodes?: ReportTable;
    snapScan?: ReportSnapscan;
}
