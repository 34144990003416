import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CommunicationTemplateEditFormComponent } from './communication-template-edit-form/communication-template-edit-form.component';
import {CommunicationTemplate, DialogType} from "@meraki-flux/schema";

@Component({
  selector: 'meraki-flux-communication-template-form',
  templateUrl: './communication-template-form.component.html',
  styleUrls: ['./communication-template-form.component.scss'],
})
@UntilDestroy()
export class CommunicationTemplateFormComponent implements OnInit, OnDestroy {
  @ViewChild(CommunicationTemplateEditFormComponent)
  communicationTemplateEdit!: CommunicationTemplateEditFormComponent;
  @Input() formMode: 'sms' | 'email' = 'email';
  @Input() practice!: Observable<any | null>;
  @Input() template: CommunicationTemplate | null = {};

  form: UntypedFormGroup = this.fb.group({
    templateName: ['', Validators.required],
    templateDescription: ['', Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dialog: MatDialog,
    ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.form.patchValue({
      templateName: this.template?.Name || '',
      templateDescription: this.template?.Description || '',
    });
    if(this.template?.isStatic) {
      this.form.get('templateName')?.disable();
      this.form.get('templateDescription')?.disable();
    }
  }

  async validate() {
    let valid = true;
    if(!this.template?.isStatic) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
    valid = await this.communicationTemplateEdit.validate();
    if (!this.form.valid && !this.form.disabled) {
      valid = false;
      this.dialogService.showDialog({
        title: 'Warning',
        message: 'Please check highlighted fields before proceeding.',
        type: DialogType.WARNING,
      });
    }
    return valid;
  }
}


