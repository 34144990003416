import {
  BaseExcelReportData,
  CellType, CellWidth, ExcelCellData,
  PaymentDetailsItem,
  PaymentDetailsReportModel,
  REPORT_NAME, Sheet, Table
} from "@meraki-flux/schema";

export class PaymentDetailsXlsReportDataBuilder {

    build(reportModel: PaymentDetailsReportModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = reportModel.PaymentDetailsReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.PAYMENT_DETAILS;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, reportModel);
        excelReportData.data.push(this.buildReportSheetDetail(reportModel.PaymentDetailsItems));

        return excelReportData;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, reportModel: PaymentDetailsReportModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: reportModel.PaymentDetailsReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: reportModel.PaymentDetailsReportInfo.PracticeId, type: CellType.GENERAL}
        });


        excelReportData.parameters.push({
            name: 'Date Range Type',
            data: {value: reportModel.PaymentDetailsReportInfo.DateRangeType, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Date Range',
            data: {value: reportModel.PaymentDetailsReportInfo.DateRange, type: CellType.GENERAL}
        });

    }

    private buildReportSheetDetail(paymentDetails: PaymentDetailsItem[]): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Detail';
        sheet.header = 'Payment Details Report';
        sheet.tables = [];

        const detailsTable: Table = {};
        //detailsTable.name = '';

        detailsTable.headers = [
            {value: 'Payment date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Transaction create date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'RA no. (if linked)', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Payment ref.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Receipt no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Paid by', bold: true, width: CellWidth.XS},
            {value: 'Payment amount', bold: true, width: CellWidth.XS, type:CellType.NUMBER},
            {value: 'Allocated amount ', bold: true, width: CellWidth.XS, wordWrap: true, type:CellType.NUMBER},
            {value: 'Unallocated amount', bold: true, width: CellWidth.XS, wordWrap: true, type:CellType.NUMBER},
            {value: 'Created by', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const detailsRows = [];
        paymentDetails.forEach((item: PaymentDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.PaymentDate});
            row.push({value: item.PaymentType});
            row.push({value: item.TransactionCreateDate });
            row.push({value: item.RANo, hAlignment: "left"});
            row.push({value: item.PaymentRef, hAlignment: "left"});
            row.push({value: item.ReceiptNo, hAlignment: "left"});
            row.push({value: item.PaidBy});
            row.push({value: item.PaymentAmount, type: CellType.CURRENCY});
            row.push({value: item.AllocatedAmount, type: CellType.CURRENCY});
            row.push({value: item.UnallocatedAmount, type: CellType.CURRENCY});
            row.push({value: item.CreatedBy});

            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

}
