import { Invoice } from "./invoice.models.ui";

export interface ModifierParametersQueryDto{
    ModifierCodes: string[];
    DisciplineCode: string;
}

export interface ModifierParameterInfoDto{
    modifierCode: string;
    disciplineCode: string;
    isSupported: boolean;
    parameters: ModifierParameterDto[];
    requirements: ModifierRequirementsDto[];
    restrictions: ModifierRestrictionsDto[];
    restrictedCodes: string[];
    applicableCodes: string[];
    parameterType: ModifierType;

}

export interface ModifierParameterDto{
    name: string;
    description: string;
    ParameterType: string;
    isRequired: boolean;
    uiName: string;
    uiElement: string;
    uiElementSource: string;
}

export interface ModifierRequirementsDto{
    name: string;
    description: string;
}

export interface ModifierRestrictionsDto{
    name: string;
    description: string;
}

export interface ChargeDto{
    practiceNumber: string;
    chargeNumber: string;
    serviceDate: Date;
    placeOfService: string;
    billingProviderDisciplineCode: string;
    gender: string;
    isBalanceBilled: boolean;
    chargeCategory: string;
    markUpPercentage: number;
    chargeLines: ChargeLineDto[];
    customData: string;
}

export interface ChargeLineDto{
    lineId: string;
    lineOrder: number;
    code: string;
    qty: number;
    units: number;
    description: string;
    parametersDescription?: string;
    serviceAmountCents: number;
    medicalAidAmountCents: number;
    patientAmountCents: number;
    rcfCents?: number;
    clinicalRCFCents?: number;
    modifierLinkedLineIds?: any[];
    isPerLineModifier?: boolean;
    isApplyModifier?: boolean;
    isServiceCreatedLine?: boolean;
    modifierParameters: ChargeModifierParameterDto[];
    customData?: string,
    minutes?:number,
    segments?:number
}

export interface ChargeModifierParameterDto{
    name: string;
    value: string;
}

export interface ModifierModelData{
    year: string;
    tariffCode: string;
    disciplineCode: string;
    placeOfService: string;
    planOptionCode: string;
    practiceId: string;
    providerId: string;
    invoiceLineType?: string;
    invoice?: Invoice;
    invoiceLineData?: any[];
    editModifier?: boolean;
    recalculateModifiers?: boolean;
    shceme?: string;
}

export interface ModifierLink {
  lineNumber: string;
  description: string;
  code: string;
}

export interface ModifierParameter {
    name: string;
    label: string;
    isRequired: boolean,
    uiElement: string;
}

export interface ModifierPricingRequest{
    year: string;
    tariffCode: string;
    disciplineCode: string;
    placeOfService: string;
    planOptionCode: string;
    practiceId: string;
    providerId: string;   
}

export enum ModifierType{
    TIME = 1,
    BMI = 2,
    ASSISTANT_DOCTOR = 3,
    WEIGHT = 4,
    LINK = 5
}

export enum ModifierParameters {
    CLINICAL_RCF= 'ClinicalRCF',
    WEIGHT =  'weight',
    HEIGHT = 'height',
    LINK = 'links',
    START_TIME = 'charge.start.date.time',
    END_TIME = 'charge.end.date.time',
    ASSISTANT = 'is.for.assistant',
    ASSISTANT_PROVIDER_PRACTICE_NUMBER = 'asst.provider.practice.number',
    ASSISTANT_PROVIDER_DISPLAY_NAME = 'asst.provider.display.name',
    ASSISTANT_PROVIDER_COUNCIL_NUMBER = 'asst.provider.council.number',
    ASSISTANT_PROVIDER_COUNCIL_TYPE_DESCRIPTION = 'asst.provider.council.type.description',
    PROVIDER_PRACTICE_NUMBER = 'provider.practice.number',
    IS_TRY_ASST_FORMAT = 'is.trt.asst.format'
}

export enum ModifierUIElements {
    TIME_PICKER =  'time.picker',
    INPUT_DECIMAL = 'input.decimal',
    GRID = 'grid',
    CHECKBOX = 'checkbox',
    COMBO = 'combo'
}

export enum ModifierRequirements {
    SERVICE_AMOUNT =  'charge-line-service-amount',
    PATIENT_AMOUNT = 'charge-line-patient-amount',
    MEDAID_AMOUNT = 'charge-line-medicial-aid-amount',
    RCF = 'charge-line-rcf',
    CLINICAL_RCF = 'charge-line-clinical-rcf',
    MODIFIER_RCF = "modifier-rcf"
}

export enum ModifierRestrictions{
    PER_LINE_DISALLOWED =  'per-line-disallowed',
    PER_LINE_ONLY_ALLOWED = 'per-line-only-allowed'
}

export enum ModifierCodes{
    M1211 = "1211",
    M0011 = "0011",
    M0005 = "0005",
    M0067 = "0067",
    M0009 = "0009",
    M0003 = "0003",
    M0069 = "0069",
    M0018 = "0018",
    M0073 = "0073",
    M0036 = "0036",
    M0004 = "0004",
    M0039 = "0039",
    M0019 = "0019",
    M0010 = "0010"
    // Add other modifier codes when implemeting them
}



