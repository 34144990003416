<div *ngIf="auth.user$ | async as user" class="m-2" [formGroup]="form">

    <!--Title Row-->
    <div class="flex flex-row justify-between items-center mb-4">
        <div id='dialogId' class="text-base font-medium">{{dialogTitle}}</div>
        <div class="flex flex-row">
            <button (click)="discard()" id="discard-btn" class="flux-button" mat-stroked-button color="secondary">
                <mat-icon class="mat-icon" aria-label="discard icon">delete_outline</mat-icon>
                Discard
            </button>
            <button (click)="onSend()" class="flux-button !ml-3" mat-flat-button color="primary">
                Send
                <mat-icon class="mat-icon ml-1" fontSet="material-icons-outlined" aria-label="send icon">send</mat-icon>
            </button>
        </div>
    </div>

    <meraki-flux-info-box type="HELP" icon="info_outlined" class="mb-4" *ngIf="infoMessage !== ''">
        <div id="infoMessage" class="content" [innerHtml]="infoMessage">
        </div>
    </meraki-flux-info-box>

    <!--Recipients-->
    <div class="flex flex-row items-center">
        <div class='input-label'>Recipients:</div>
        <mat-form-field class="flux-input w-full flux-textarea-form-field" floatLabel="never" appearance="fill">
            <mat-chip-list id="recipientList" #recipientChipList class="flux-chip w-full">
                <mat-chip id="recipient-{{recipientIndex}}" *ngFor="let recipient of recipients; let recipientIndex = index" selectable="true" removable="true"
                    [class.error]="!isEmailValid(recipient?.EmailAddress)" (removed)="onRecipientRemoved(recipient)">
                    {{ formatRecipient(recipient) }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input class="flux-input" matInput #recipientInput formControlName="Recipients"
                       matChipInputAddOnBlur="true" [matChipInputFor]="recipientChipList"
                       (matChipInputTokenEnd)="onRecipientAdded($event)" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
        </mat-form-field>
    </div>

    <!--CC-->
    <div class="flex flex-row items-center">
        <div class='input-label'>Cc:</div>
        <mat-form-field class="flux-input w-full flux-textarea-form-field" floatLabel="never" appearance="fill">
            <mat-chip-list id="ccList" #ccChipList class="flux-chip w-full">
                <mat-chip id="cc-{{ccIndex}}" *ngFor="let cc of ccs; let ccIndex = index" selectable="true" removable="true" [class.error]="!isEmailValid(cc)"
                    (removed)="onCCRemoved(cc)">
                    {{ cc }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input class="flux-input" matInput #ccInput formControlName="Ccs" matChipInputAddOnBlur="true"
                    [matChipInputFor]="ccChipList" (matChipInputTokenEnd)="onCCAdded($event)"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
        </mat-form-field>
    </div>

    <!--Subject-->
    <div class="flex flex-row items-center">
        <div class='input-label'>Subject:</div>
        <mat-form-field class="flux-input w-full flux-textarea-form-field" floatLabel="never" appearance="fill">
            <input id="subject" formControlName="Subject" matInput type="text">
        </mat-form-field>
    </div>

    <div>
        <!--Add attachment-->
        <div class="files">
            <meraki-flux-file-selection (fileAddedEvent)="onFilesAdded($event)"
                [events]="removeFileFromChild.asObservable()">
                Add attachment
            </meraki-flux-file-selection>
            <div *ngIf="form.controls.Attachments?.value" class="attachment-container mb-4">
                <mat-chip-list id="attachmentList" class='flux-chip attachment'>
                    <mat-chip id="attachment-{{fileIndex}}" *ngFor="let file of form.controls.Attachments?.value; let fileIndex = index" class="flex justify-between items-center w-full">
                        <div class="flex items-center">
                            <mat-icon class="attachment-icon" matChipAvatar>image</mat-icon>
                            {{ removeUUIDFromFileName(file.name) }}
                        </div>
                        <mat-icon matChipRemove *ngIf="(!file.uploadProgress)" (click)="onFileRemoved(file)">cancel</mat-icon>
                        <mat-spinner matChipRemove *ngIf="!(!file.uploadProgress)" class="loading-spinner" diameter="17"></mat-spinner>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <!--Editor-->
        <editor formControlName="Body" [init]="editorConfig"></editor>
    </div>

</div>
