import {CellType, HAlignment} from "./reports.common";

export interface ExcelCellData {
    value: any;
    type?: CellType;
    width?: CellWidth;
    bold?: boolean;
    wordWrap?: boolean;
    shrinkToFit?: boolean;
    bgColor?: string;
    hAlignment?: HAlignment;
}

export enum CellWidth {
    XS = "XS",
    S = "S",
    M = "M",
    L = "L",
}


export interface Sheet {
    name?: string;
    header?: string;
    extra?: ExcelParameter[];
    tables?: Table[];
}

export interface Table {
    name?: string;
    nameBGColor?: string;
    nameMergeCount?: number;
    description?: string;
    noHeaderBackground?: boolean;
    headers?: ExcelCellData[];
    rows?: ExcelCellData[][];
}

export interface ExcelParameter {
    name?: string;
    data: ExcelCellData;
}

export interface BaseExcelReportData {
    practiceId?: string;
    name?: string;
    parameters?: ExcelParameter[];
    data?: Sheet[];
}
