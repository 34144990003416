import {Branch, Practice} from "../practice.models";
import {PracticeProvider} from "../provider.models";
import {DATE_RANGE_TYPE, ProviderInvoices, ProviderSummaryItem, REPORT_FORMAT_TYPE} from "../report.models";
import {INVOICE_TYPE} from "../invoice.models";

export class ReferralReportRequest {
  Practice: Practice;
  Branch?: Branch;
  BranchName?: string;
  SelectedTreatingProvider: PracticeProvider;
  TreatingProvider?: ProviderInvoices;
  TreatingProviders?: ProviderInvoices[];
  ReferringProvider?: ProviderInvoices;
  ReferringProviders?: ProviderInvoices[];
  InvoiceType?: INVOICE_TYPE;
  ReportFormatType: REPORT_FORMAT_TYPE;
  //Scheme?: string;
  DateRangeType?: DATE_RANGE_TYPE;
  DateFrom: Date;
  DateTo: Date;
  RecipientEmail: string;
  UserId: string;
  Multibranch: boolean;
}

export interface ReferralReportInfo {
  PracticeId?: string;
  Name?: string;
  Practice?: string;
  Branch?: string
  TreatingProvider?: string | ''
  DateRange?: string
  IsMultiBranch?: boolean;
  IncludeInvoiceData?: boolean;
  IncludeRecomedData?: boolean;
  ReportName?: string;
  PatientName?: string;
}


/////////////////////

export interface ReferralReportModel {
    ProviderModels: ReferralProviderModel[];
    AllProvidersModel?: ReferralProviderModel;
    ReferralReportInfo: ReferralReportInfo;
    ReportDate?: string;
}

export interface ReferralReportInfo {
    PracticeId?: string;
    Name?: string;
    Practice?: string;
    Branch?: string
    TreatingProvider?: string | ''
    DateRange?: string
    DateRangeType?: string
    Scheme?: string
    InvoiceType?: string
    IsMultiBranch?: boolean
    Speciality?: string
    ReferringProvider?: string | ''
}

export interface ReferralProviderModel {
    SummaryTable: ProviderSummaryItem[];
    DetailsTable: InvoiceDetailsItem[];
}

export interface InvoiceDetailsItem {
  TreatingProviderName?: string
  ReferralProviderName?: string
  Speciality?: string
  AccountNo?: string
  PatientName?: string
  PatientID?: string
  InvoiceType?: string
  InvoiceStatus?: string
  InvoiceNo?: string;
  Scheme?: string
  Plan?: string
  Option?: string
  MemberNo?: string
  ClaimStatus?: string
  DateOfService?: string
  DateOfSubmission?: string
  HBMessageID?: string
  InvoicedAmount?: number
  PatientPaymentsAmount?: number
  MedicalAidPaymentsAmount?: number
  CreditNotesAmount?: number
  WriteOffsAmount?: number
  PaymentsCorrectionsAmount?: number
  MedicalAidLiable?: number
  PatientLiable?: number
  eRAEnabled?: boolean
  PlaceOfService?: string
  Location?: string
  CreatedBy?: string
  Branch?: string
  AmountExcFromHBBill?: number
}

