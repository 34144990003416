import { Pipe, PipeTransform } from "@angular/core";
import { Account, BaseAccountMember } from "@meraki-flux/schema";
import * as moment from "moment";

@Pipe({
    name: 'patientSearchResult',
    pure: false
})
export class PatientSearchResultPipe implements PipeTransform {
    transform(value: { account: Account, patient: BaseAccountMember }, ...args: any[]) {
        if (value?.patient) {
            let parts = [];

            parts.push(`${value.patient.Surname}, ${value.patient.Name}${value.patient.PreferredName ? ' (' + value.patient.PreferredName + ')' : ''}`);

            if (value.account?.AccountNo) {
                parts.push(`Acc: ${value.account.AccountNo}`);
            }

            if (value.patient.FileNo) {
                parts.push(`File: ${value.patient.FileNo}`)
            }

            if (value.patient.DateOfBirth) {
                // remove html tags
                const dob: any = value.patient.DateOfBirth;
                const dobClean = dob.replace(/(<([^>]+)>)/gi, "");
                parts.push(`Dob: ${moment(dobClean).format('DD/MM/YYYY')}`)
            }

            if (value.account?.MemberNo) {
                parts.push(`MemberNo: ${value.account?.MemberNo}`);
            }

            if (value.patient.Contact?.Cellphone) {
                parts.push(`Cell: ${value.patient.Contact?.Cellphone}`);
            }

            return parts.join(' - ');
        }
        return '';
    }

}
