import {AdditionalEmails, AdditionalPhones, Address, BankDetails} from './general.models';
import {SMS_TYPE} from './sms.models';

export interface Practice {
  Id?: string;
  BillingPracticeNumber?: string;
  Branches?: Branch[];
  BusinessId?: string;
  BusinessType?: string;
  CompanyRegistrationNo?: string;
  IsMultiBranch?: boolean;
  PracticeName?: string;
  Speciality?: string;
  IsVATRegistered?: boolean;
  VatEffectiveDate?: Date;
  VatNo?: string;
  Settings?: PracticeSettings;
  SmsBalance?: SmsBalance;
  InvoiceNo?: {
    Prefix?: string;
    Counter?: number;
  }
  InvoiceNoBC?: {
    Prefix?: string;
    Counter?: number;
  }
  PaymentReceiptNoContext?: {
    Prefix?: string;
    Counter?: number;
  }
  IsERAEnabled?: boolean;
  CreatedAt?: Date; // TODO: populate with practice start date
}

export interface PracticeSettings {
  AccountNumberFormat?: ACCOUNT_NUMBER_FORMAT;
  PatientIdInAccounts?: boolean;
  InsuranceClaimsInAccounts?: boolean;
  PatientSMSPaymenConfirmation?: boolean;
  PatientBirthdaySMS?: boolean;
  BirthdaySMSPeriod?: BIRTHDAY_SMS_PERIOD;
  AppointmenConfirmations?: APPOINTMENT_CONFIRMATION;
  AppointmenRemiders?: APPOINTMENT_CONFIRMATION;
  EmailReminderPeriod?: REMINDER_PERIOD;
  SMSReminderPeriod?: REMINDER_PERIOD;
  BCPeriod?: BC_PERIOD;
  AutomaticCheckout?: boolean;
  AllowDiscount?: boolean;
  ZeroBilledLinesWarning?: boolean;
  ZeroBilledMedicineLines?: boolean;
  AssigningOfLiabilities?: boolean;
  WriteOffThreshold?: number;
  ERAReceivedActions?: boolean;
  CollectOnReconciled?: boolean;
  AutoWriteOffReconciled?: boolean;
  ShowTPN?: boolean;
  CCPractice?: boolean;
  HideMALiable?: boolean;
  StatementFooter?: boolean;
  StatementFooterText?: string;
  InvoiceFooter?: boolean;
  InvoiceFooterText?: string;
  AccountNoFormat?: ACCOUNT_NUMBER_FORMAT;
  StartAccountNoFrom?: number;
  BranchPrefixes?: BranchPrefix[];
  VisitTypes?: DurationSetting[];
  ReasonsForVisit?: TextSetting[];
  RoomsResources?: RoomsAndResourcesSetting[];
  OtherLocations?: TextSetting[];
  PaymentTypes?: TextSetting[];
  CreditNoteTypes?: TextSetting[];
  JournalCategories?: TextSetting[];
  WorkingHoursEnabled?: boolean;
  WorkingHours?: WorkingHours[];
  PatientDisclaimerText?: string;
  CalendarSettings?: CalendarSettings;
  EnableSessionTimeout?: boolean;
  SessionTimeout?: number;
  ReportLogoSetting?: ReportLogoSetting

}

export interface SessionTimeoutFormat {
  Text: string;
  TimeInSeconds: number;
}
export interface TextSetting {
  Text: string;
  Status: string;
}

export interface RoomsAndResourcesSetting extends TextSetting{
  Branch?: string;
}

export interface DurationSetting {
  Text: string;
  Duration: string;
  Status: string;
}
export interface BranchPrefix {
  BranchName: string;
  Prefix: string;
}

export interface WorkingHours {
  Day?: string;
  From?: string;
  To?: string;
  Index?: number;
}

export interface Days {
  Day?: string;
  From?: string;
  To?: string;
  Index?: number;
}

export interface CalendarSettings {
  ColourPreferences?: ColourPreferences;
  View?: CALENDAR_VIEW;
}

export interface ColourPreferences {
  ProviderDisplay?: ProviderColourSetting[];
  AppointmentDisplay?: AppointmentDisplaySettings;
  VisitTypeDisplay?: VisitTypeColourSetting[];
}

export enum CALENDAR_VIEW {
  DAY = 'day',
  WEEK = 'week'
}

export interface ProviderColourSetting {
  ProviderId: string;
  Colour?: string;
  Index?: number;
}

export interface VisitTypeColourSetting {
  Id: string,
  Colour?: string;
}

export interface AppointmentDisplaySettings {
  ColourStripIndicator: APPOINTMENT_COLOUR_STRIP_INDICATOR;
}

export enum APPOINTMENT_COLOUR_STRIP_INDICATOR {
  BENEFIT_CHECK_STATUS = 'Benefit check status',
  VISIT_TYPE = 'Visit type'
}

export enum ACCOUNT_NUMBER_FORMAT {
  DEFAULT = 'Default system format',
  NUMBERS_ONLY = 'Numbers only',
  BRANCH_BASED = 'Branch based prefix',
}

export enum BIRTHDAY_SMS_PERIOD {
  SIX_MONTHS = '6 months',
  ONE_YEAR = '1 year',
  TWO_YEARS = '2 years',
  THREE_YEARS = '3 years',
  FOUR_YEARS = '4 years',
  FIVE_YEARS = '5 years',
}

export enum APPOINTMENT_CONFIRMATION {
  NONE = 'None',
  EMAIL = 'Email',
  SMS = 'SMS',
  EMAIL_AND_SMS = 'Email and SMS',
}

export enum REMINDER_PERIOD {
  ONE_HOUR_BEFORE = '1 hour before',
  TWO_HOURS_BEFORE = '2 hours before',
  ONE_DAY_BEFORE = '1 day before',
  TWO_DAYS_BEFORE = '2 days before',
}

export enum BC_PERIOD {
  THIRTY_MINS_BEFORE = '30 minutes before',
  ONE_HOUR_BEFORE = '1 hour before',
  TWO_HOURS_BEFORE = '2 hours before',
  THREE_HOURS_BEFORE = '3 hours before',
  FOUR_HOURS_BEFORE = '4 hours before',
}

export interface Branch {
  IsMainBranch?: boolean;
  Active?: boolean;
  Name?: string;
  SameAsMainContact?: boolean;
  ContactDetails?: {
    OfficeNo?: string;
    EmailAddress?: string;
    FaxNo?: string;
  };
  AdditionalPhones?: AdditionalPhones;
  AdditionalEmails?: AdditionalEmails;
  PhysicalAddress?: Address;
  SameAsPhysical?: boolean;
  PostalAddress?: Address;
  BankDetails?: {
    ChequeAccountDetails?: BankDetails;
    EFTAccountDetails?: BankDetails;
    SameAsMain?: boolean;
    SameAsEFT?: boolean;
  };
}

export interface SmsBalance {
  Balance: number;
  UpdatedAt: Date;
}

export enum PLACE_OF_SERVICE_CODE {
  CONSULTING_ROOM = '11',
  HOME = '12',
  INPATEINT_HOSPITAL = '21',
  HOSPITAL_OUTPATIENT_FACILITY = '22',
  CASUALTY = '23',
  DAY_CLINIC_HOSPITAL = '24',
  BIRTHING_CENTRE = '25',
  MILITARY_TREATMENT_FACILITY = '26',
  STEP_DOWN = '31',
  NURSING_HOME = '32',
  CHRONIC_PSYCHIATRIC_FACILITY = '33',
  HOSPICE = '34',
  AMBULANCE_LAND = '41',
  AMBULANCE_AIR_OR_WATER = '42',
  INPATIENT_PHYCHIATRIC_HOSPITAL = '51',
  ACUTE_PSYCHIATRIC_FACILITY = '52',
  OUT_OF_HOSPITAL_MENTAL_HEALTH = '53',
  SUBSTANCE_REHABILITATION = '55',
  HALFWAY_HOUSE = '56',
  INPATIENT_REHABILITATION_FACILITY = '61',
  OUTPATIENT_REHABILITATION = '62',
  DIALYSIS_CENTRE = '65',
  RADIOTHERAPY_TREATMENT_CENTRE = '66',
  CHEMOTHERAPY_TREATMENT_CENRE = '68',
  ONCOLOGY_CENTRE = '70',
  STATE_OR_LOCAL_PUBLIC_HEALTH = '71',
  RURAL_HEALTH_CLINIC = '72',
  PRIVATE_LABORATORY = '81',
  INDEPENDENT_PHARMACY = '84',
  HOSPITAL_PHARMACY = '85',
  OTHER_UNLISTED_FACILITY = '99',
  TELEMEDICINE = '02'
}

export enum SPECIALITY_CODE {
  ANAESTHETIST = '10',
  CARDIOLOGIST = '21',
  DIETICIAN = '84',
  GP_14 = '14',
  GP_15 = '15',
  HOMEOPATHS = '8',
  PEADIATRITION = '32',
  PHYSIOTHERAPIST = '72'
}

export interface SmsBalanceTransaction {
  Amount: number;
  Type: SMS_TYPE | "Purchase";
  CreatedAt: Date;
  SmsRequestId: string
  Price?: number;
}

export enum REPORT_LOGO_TYPE {
  NO_LOGO_TEXT_ONLY = 'No logo, text only',
  LOGO_TOP_LEFT = 'Logo top left',
  BANNER_LOGO = 'Banner logo',
}

export interface ReportLogoSetting {
  logoType: REPORT_LOGO_TYPE;
  base64Image?: string;
}
