import { Injectable } from '@angular/core';
import { Firestore, collection, doc, where } from '@angular/fire/firestore';
import { DateUtils } from '../utils/date-utils';
import { PathUtils } from '../utils/path-utils';
import { FirestoreService } from './firestore.service';
import {AUTHORIZATION_STATUS, MemberAuthorization} from "@meraki-flux/schema";

@Injectable({ providedIn: 'root' })
export class PatientAuthorizationService {
  constructor(private firestore: Firestore, private firestoreService: FirestoreService) {}

  async getAuthorization(
    bpn: string,
    patientId: string,
    authorizationId: string
  ): Promise<MemberAuthorization> {
    return await this.firestoreService.findOne(
      PathUtils.authorizationPath(bpn, patientId, authorizationId)
    );
  }

  async getActiveAuthorizations(bpn: string, patientId: string): Promise<MemberAuthorization[]> {
    return await this.firestoreService.query(
      PathUtils.authorizationCollectionPath(bpn, patientId),
      where('AuthorizationStatus', '==', AUTHORIZATION_STATUS.ACTIVE)
    );
  }

  async getActiveAuthorizationsToCurrentDate(
    bpn: string,
    patientId: string,
    currentDate: Date
  ): Promise<MemberAuthorization[]> {
    if (!currentDate) return [];
    const allActive = await this.getActiveAuthorizations(bpn, patientId);
    const currentTime = DateUtils.getTime(currentDate);
    return allActive.filter((a) => {
      const from = DateUtils.dayStartTime(a.AuthorizedFrom) || currentTime - 1;
      const to = DateUtils.dayEndTime(a.AuthorizedTo) || currentTime + 1;
      return currentTime >= from && currentTime <= to;
    });
  }

  async getActiveAuthorizationsByNo(
    bpn: string,
    patientId: string,
    authorizationNo: string
  ): Promise<MemberAuthorization[]> {
    return await this.firestoreService.query(
      PathUtils.authorizationCollectionPath(bpn, patientId),
      where('AuthorizationStatus', '==', AUTHORIZATION_STATUS.ACTIVE),
      where('AuthorizationNo', '==', authorizationNo)
    );
  }

  async getActiveAuthorization(
    bpn: string,
    patientId: string,
    authorizationNo: string,
    authorizedFrom: Date,
    authorizedTo: Date,
    excludeId: string = null
  ): Promise<MemberAuthorization> {
    const results = (await this.firestoreService.query(
      PathUtils.authorizationCollectionPath(bpn, patientId),
      where('AuthorizationStatus', '==', AUTHORIZATION_STATUS.ACTIVE),
      where('AuthorizationNo', '==', authorizationNo),
      where('AuthorizedFrom', '==', authorizedFrom || null),
      where('AuthorizedTo', '==', authorizedTo || null)
    )) as MemberAuthorization[];
    const idFiltered = results.filter((i) => !excludeId || excludeId !== i.Id);
    return idFiltered.length > 0 ? idFiltered[0] : null;
  }

  async hasActiveAuthorization(
    bpn: string,
    patientId: string,
    authorizationNo: string,
    authorizedFrom: Date,
    authorizedTo: Date,
    excludeId: string = null
  ): Promise<boolean> {
    const result = await this.getActiveAuthorization(
      bpn,
      patientId,
      authorizationNo,
      authorizedFrom,
      authorizedTo,
      excludeId
    );
    return !!result;
  }

  async saveNewAuthorizationNo(
    bpn: string,
    patientId: string,
    authorizationNo: string
  ): Promise<boolean> {
    return await this.saveNewAuthorization(bpn, patientId, {
      AuthorizationNo: authorizationNo,
      AuthorizationStatus: AUTHORIZATION_STATUS.ACTIVE,
    } as MemberAuthorization);
  }

  async saveNewAuthorization(
    bpn: string,
    patientId: string,
    authorization: MemberAuthorization
  ): Promise<boolean> {
    if (!bpn || !patientId || !authorization || !authorization?.AuthorizationNo) return false;
    const duplicate = await this.hasActiveAuthorization(
      bpn,
      patientId,
      authorization.AuthorizationNo,
      authorization.AuthorizedFrom,
      authorization.AuthorizedTo
    );
    if (duplicate) return false;
    await this.firestoreService.addDoc(
      collection(this.firestore, PathUtils.authorizationCollectionPath(bpn, patientId)),
      {
        ...authorization,
        AuthorizedFrom: authorization.AuthorizedFrom || null, // need to explicitly set null to be able to filter by this field
        AuthorizedTo: authorization.AuthorizedTo || null, // need to explicitly set null to be able to filter by this field
      }
    );
    return true;
  }

  async updateAuthorization(
    bpn: string,
    patientId: string,
    authorizationId: string,
    authorization: MemberAuthorization
  ) {
    if (!bpn || !patientId || !authorizationId) throw 'updateAuthorization error';
    this.firestoreService.updateDoc(
      doc(this.firestore, PathUtils.authorizationPath(bpn, patientId, authorizationId)),
      authorization
    );
  }

  public async deleteOne(bpn: string, patientId: string, authorizationId: string) {
    await this.firestoreService.mergeDoc(
      PathUtils.authorizationPath(bpn, patientId, authorizationId),
      {
        AuthorizationStatus: AUTHORIZATION_STATUS.DELETED,
      }
    );
  }

  public async deleteAll(bpn: string, patientId: string) {
    const authorizations = await this.getActiveAuthorizations(bpn, patientId);
    authorizations.forEach(async (authorization) => {
      await this.deleteOne(bpn, patientId, authorization.Id);
    });
  }
}
