import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterContentChecked
} from '@angular/core';
export interface CarouselSelectorOption {
  label: string;
  base64Image?: string;
}

@Component({
  selector: 'meraki-flux-carousel-selector',
  templateUrl: './carousel-selector.component.html',
  styleUrls: ['./carousel-selector.component.scss']
})
export class CarouselSelectorComponent implements AfterContentChecked {
  @Input() options: CarouselSelectorOption[] = [];
  @Output() onOptionChanged = new EventEmitter<string>();
  selectedOptionIndex = 0
  @ViewChild('imgRef')
  imgRef: ElementRef;
  height = 500;

  constructor() {
  }

  next() {
    this.selectedOptionIndex++;
    if (this.selectedOptionIndex >= this.options.length) this.selectedOptionIndex = 0;
    this.onOptionChanged.emit(this.value);
  }

  prev() {
    this.selectedOptionIndex--;
    if (this.selectedOptionIndex < 0) this.selectedOptionIndex = this.options.length - 1;
    this.onOptionChanged.emit(this.value);
  }

  @Input()
  get value() {
    return this.options[this.selectedOptionIndex]?.label;
  }

  set value(v: string) {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i]?.label === v) {
        this.selectedOptionIndex = i;
        this.onOptionChanged.emit(v);
      }
    }
  }

  ngAfterContentChecked(): void {
    this.height = this.imgRef?.nativeElement?.offsetHeight + 40;
  }
}
