import {Injectable} from "@angular/core";
import {
  BaseExcelReportData,
  CollectableAmountsReportRequest,
  Invoice,
  Note,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import {ReportUtils} from "../report-utils";
import {collection, Firestore, getDocs, orderBy, query, where} from "@angular/fire/firestore";
import * as _ from "lodash";
import { NotesService } from "@meraki-flux/common";
import {
  BaseExcelGenerator,
  CollectableAmountsReportBuilder,
  CollectableAmountsXlsReportDataBuilder
} from '@meraki-flux/purejs';

@Injectable({
  providedIn: 'root'
})
export class CollectableAmountsReportService {

  constructor(
    private firestore: Firestore,
    private noteService: NotesService
  ) {}

    async generateReport(reportRequest: CollectableAmountsReportRequest): Promise<Report> {

        const fileName: string = await ReportUtils.buildCollectableAmountsReportFileName(
            reportRequest.Practice.PracticeName, reportRequest.Branch, reportRequest.ReportFormatType);

        await Promise.all(reportRequest.Details.map(async asr => {
          const notesRaw = await getDocs<Note>(
            query<any>(collection(this.firestore, this.noteService.getAccountNotesCollectionRef(asr.AccountId)),
              orderBy("Pinned", 'desc')
            ));
          asr.AccountNote = notesRaw.docs.map(n => (n.data() as Note)?.Note??"").join("|")
        }))

        const slice = 10;
        const accIds = _.uniq((reportRequest.Details).map(p => p.AccountId));
        const invoices: Invoice[] = []
        let cUnk = _.chunk(accIds, slice);
        for (let i = 0; i < cUnk.length; i++) {
          const invNoQueryRaw = await getDocs<Invoice>(query<any>(collection(this.firestore, `Practice/${reportRequest.Practice.BillingPracticeNumber}/Invoice`), ...this.getSearchWhereList(cUnk[i])));
          invoices.push(...invNoQueryRaw.docs.map(doc => ({
            ...doc.data(),
            DateOfService: doc.get('DateOfService').toDate(),
            InvoiceDate: doc.get('InvoiceDate').toDate(),
            Id: doc.id
          } as Invoice)))
        }
        reportRequest.Invoices = invoices;

      await Promise.all(reportRequest.Invoices.map(async inv => {
        const notesRaw = await getDocs<Note>(
          query<any>(collection(this.firestore, this.noteService.getInvoiceNotesCollectionRef(inv.Id)),
            orderBy("CreatedAt", 'desc')
          ));
        inv.Broker = notesRaw.docs.map(n => (n.data() as Note)?.Note??"").join("|")
      }))

        const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

        return {
          fileName: fileName,
          reportName: REPORT_NAME.COLLECTABLE_AMOUNTS,
          format: REPORT_FORMAT_TYPE.EXCEL,
          practiceId: reportRequest.Practice.Id,
          content: reportGenerator.generateReport()
        };
    }

    private async getReportGenerator(reportRequest: CollectableAmountsReportRequest): Promise<BaseExcelGenerator> {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    }

    private async generateXlsReportData(reportRequest: CollectableAmountsReportRequest): Promise<BaseExcelReportData> {
        return new CollectableAmountsXlsReportDataBuilder().build(
            await new CollectableAmountsReportBuilder().build(reportRequest));
    }

  private getSearchWhereList(accounts: string[]) {
    const whereList = [];

    whereList.push(where('Balance.PatientLiable', '>', 0));
    whereList.push(where('Account.Id', 'in', accounts));
    return whereList;
  }
}
