export class PathUtils {
  static practicePath(practiceId: string) {
    return `Practice/${practiceId}`;
  }

  static accountCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/Account`;
  }

  static accountPath(practiceId: string, accountId: string) {
    return `${this.accountCollectionPath(practiceId)}/${accountId}`;
  }

  static transactionCollectionPath(practiceId: string, accountId: string) {
    return `${this.accountPath(practiceId, accountId)}/Transaction`;
  }

  static paymentCollectionPath(practiceId: string, accountId: string) {
    return `${this.accountPath(practiceId, accountId)}/Payment`;
  }

  static paymentPath(practiceId: string, accountId: string, paymentId: string): string {
    return `${this.paymentCollectionPath(practiceId, accountId)}/${paymentId}`;
  }

  static patientCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/Patient`;
  }

  static patientPath(practiceId: string, patientId: string) {
    return `${this.patientCollectionPath(practiceId)}/${patientId}`;
  }

  static calendarEventCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/CalendarEvent`;
  }

  static calendarEventPath(practiceId: string, calendarEventId: string) {
    return `${this.calendarEventCollectionPath(practiceId)}/${calendarEventId}`;
  }

  static calendarEventWaitingRoomCollectionPath(practiceId: string) {
    return `${this.calendarEventCollectionPath(practiceId)}/WaitingRoom`;
  }

  static raCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/RemittanceAdvice`;
  }

  static raPath(practiceId: string, remittanceAdviceId: string) {
    return `${this.raCollectionPath(practiceId)}/${remittanceAdviceId}`;
  }

  static invoiceNoteCollectionPath(practiceId: string, invoiceId: string) {
    return `${this.practicePath(practiceId)}/Invoice/${invoiceId}/Note`;
  }

  static invoiceNotePath(practiceId: string, invoiceId: string, noteId: string) {
    return `${this.invoiceNoteCollectionPath(practiceId, invoiceId)}/${noteId}`;
  }

  static remittanceAdviceCollection(practiceId: string) {
    return `${this.practicePath(practiceId)}/RemittanceAdvice`;
  }

  static remittanceAdvicePath(practiceId: string, remittanceAdviceId: string) {
    return `${this.remittanceAdviceCollection(practiceId)}/${remittanceAdviceId}`;
  }

  static remittanceClaimCollectionPath(practiceId: string, remittanceAdviceId: string) {
    return `${this.remittanceAdvicePath(practiceId, remittanceAdviceId)}/RemittanceClaim`;
  }

  static remittanceJournalCollectionPath(practiceId: string, remittanceAdviceId: string) {
    return `${this.remittanceAdvicePath(practiceId, remittanceAdviceId)}/RemittanceJournal`;
  }

  static remittanceClaimPath(practiceId: string, raId: string, raClaimId: string) {
    return `${this.remittanceClaimCollectionPath(practiceId, raId)}/${raClaimId}`;
  }

  static rootProviderPath(providerId: string) {
    return `Provider/${providerId}`;
  }

  static practiceProviderCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/Provider`;
  }

  static practiceProviderPath(practiceId: string, providerId: string) {
    return `${this.practiceProviderCollectionPath(practiceId)}/${providerId}`;
  }

  static providerTemplatesPath(practiceId: string, providerId: string) {
    return `${this.practiceProviderPath(practiceId, providerId)}/InvoiceTemplate`;
  }

  static invoiceCollectionPath(practiceId: string): string {
    return `${this.practicePath(practiceId)}/Invoice`;
  }

  static invoicePath(practiceId: string, invoiceId: string): string {
    return `${this.invoiceCollectionPath(practiceId)}/${invoiceId}`;
  }

  static accountNoteCollectionPath(practiceId: string, accountId: string): string {
    return `${this.accountPath(practiceId, accountId)}/Note`;
  }

  static accountNotePath(practiceId: string, accountId: string, noteId: string): string {
    return `${this.accountNoteCollectionPath(practiceId, accountId)}/${noteId}`;
  }

  static outboundEmailCollectionPath(practiceId: string): string {
    return `${this.practicePath(practiceId)}/OutboundEmail`;
  }

  static outboundEmailCollectionStoragePath(practiceId: string, fileName: string) {
    return `${this.outboundEmailCollectionPath(practiceId)}/${fileName}`;
  }

  static userCollectionPath() {
    return `User`;
  }

  static userPath(userId: string) {
    return `${this.userCollectionPath()}/${userId}`;
  }

  static recurrentRuleCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/RecurrentRule`;
  }

  static authorizationCollectionPath(practiceId: string, patientId: string) {
    return `${this.patientPath(practiceId, patientId)}/Authorizations`;
  }

  static authorizationPath(practiceId: string, patientId: string, authorizationId: string) {
    return `${this.authorizationCollectionPath(practiceId, patientId)}/${authorizationId}`;
  }

  static inboxCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/Inbox`;
  }

  static inboxPath(practiceId: string, inboxId: string) {
    return `${this.inboxCollectionPath(practiceId)}/${inboxId}`;
  }

  static customChargeCodeCollectionPath(practiceId: string) {
    return `${this.practicePath(practiceId)}/CustomChargeCode`;
  }

  static reversalRequestCollectionPath(practiceId: string, invoiceId: string): string {
    return `${this.invoicePath(practiceId, invoiceId)}/ReversalRequest`;
  }

  static reversalRequestPath(
    practiceId: string,
    invoiceId: string,
    reversalRequestId: string
  ): string {
    return `${this.reversalRequestCollectionPath(practiceId, invoiceId)}/${reversalRequestId}`;
  }

  static addonCollection(practiceId: string) {
    return `${this.practicePath(practiceId)}/Addon`;
  }

  static addonPath(practiceId: string, addonId: string) {
    return `${this.addonCollection(practiceId)}/${addonId}`;
  }
}
