import {Injectable} from "@angular/core";
import {MatDialog} from '@angular/material/dialog';
import {InvoiceTemplateComponent} from '../components/invoice-template/invoice-template.component';
import {FirestoreService} from "./firestore.service";
import {AuthService} from "./auth.service";
import {collection, deleteDoc, doc, Firestore, getDocs, query, where} from "@angular/fire/firestore";
import { InvoiceSimpleTemplateComponent } from "../components/invoice-simple-template/invoice-simple-template.component";
import { Observable } from "rxjs";
import { PathUtils } from "../utils/path-utils";
import {
  CustomChargeCodes, CustomChargeCodeType,
  DialogResponse,
  InvoiceTemplate,
  InvoiceTemplateData,
  PracticeProvider, TemplateLine
} from "@meraki-flux/schema";

@Injectable()
export class InvoiceTemplateService {

  constructor(
    private matDialog: MatDialog,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private firestore: Firestore
  ) {
  }

  /**
   * Shows a dialog using the provided options.
   * @param invoiceTemplateData
   * @returns
   */
  showDialog(invoiceTemplateData: InvoiceTemplateData): DialogResponse {
    const data: InvoiceTemplateData = {
      ...invoiceTemplateData
    };
    const isFavourite = invoiceTemplateData.isFavourite ?? false;
    let opened: Observable<void>, closed: Observable<any>;

    if (isFavourite) {
      const dialogRef = this.matDialog.open(InvoiceSimpleTemplateComponent, {
        panelClass:['flux-dialog-medium', 'of-inherit'],
        data,
        // width: '420px',
        // height: 'auto',
        autoFocus: true,
        restoreFocus: true,
        disableClose: false
      });

      opened = dialogRef.afterOpened();
      closed = dialogRef.afterClosed();
    } else {
      const dialogRef = this.matDialog.open(InvoiceTemplateComponent, {
        panelClass:['flux-dialog-medium', 'of-inherit'],
        data,
        // width: '200ex',
        // height: '100ex',
        autoFocus: false,
        restoreFocus: true,
        disableClose: false
      });

      opened = dialogRef.afterOpened();
      closed = dialogRef.afterClosed();
    }
    const response = {
      opened,
      closed
    }

    return response;
  }

  async addTemplate(template: InvoiceTemplate, providerId: string) {
    await this.firestoreService.addDoc(collection(this.firestore, `Practice/${this.authService.selectedBPN}/Provider/${providerId}/InvoiceTemplate`), template);
  }

  /**
   * This function deletes a template for the given provider
   * @param templateId
   * @param providerId
   */
  async removeFavourite(templateId: string, providerId: string) {
    await deleteDoc(doc(this.firestore, `${PathUtils.providerTemplatesPath(this.authService.selectedBPN, providerId)}/${templateId}`));
  }

  async updateCustomChargeCodes(oldChargeCode: CustomChargeCodes, newChargeCode: CustomChargeCodes) {
    const providerDocs = await getDocs(query(collection(this.firestore, `Practice/${this.authService.selectedBPN}/Provider`), where('IsActive', '==', true)));
    const practiceProviders = providerDocs.docs.map(doc => ({ Id: doc.id, ...doc.data() }) as PracticeProvider);
    for (const practiceProvider of practiceProviders) {
      const invoiceTemplateDocs = await getDocs(query(collection(this.firestore, `Practice/${this.authService.selectedBPN}/Provider/${practiceProvider.Id}/InvoiceTemplate`)));
      const invoiceTemplates = invoiceTemplateDocs.docs.map(doc => ({ Id: doc.id, ...doc.data() }) as InvoiceTemplate);
      for (const invoiceTemplate of invoiceTemplates) {
        if (invoiceTemplate.Lines?.filter(x => this.containsOldCode(oldChargeCode, x)).length > 0) {
          invoiceTemplate.Lines?.forEach(line => {
            if (this.containsOldCode(oldChargeCode, line)) {
              if (this.isProcedureCode(oldChargeCode, line)) {
                line.ChargeCode = newChargeCode.Code;
              }
              if (this.isMedicineCode(oldChargeCode, line)) {
                line.NappiCode = newChargeCode.Code;
              }
              line.Description = newChargeCode.Description;
            }
          })
          await this.firestoreService.updateDoc(doc(this.firestore, `Practice/${this.authService.selectedBPN}/Provider/${practiceProvider.Id}/InvoiceTemplate/${invoiceTemplate.Id}`), invoiceTemplate)
        }
      }
    }
  }

  private containsOldCode(oldChargeCode: CustomChargeCodes, line: TemplateLine) {
    return this.isProcedureCode(oldChargeCode, line)
      || this.isMedicineCode(oldChargeCode, line);
  }

  private isMedicineCode(oldChargeCode: CustomChargeCodes, line: TemplateLine) {
    return (oldChargeCode.Type === CustomChargeCodeType.CSTM_CONSUMABLE || oldChargeCode.Type === CustomChargeCodeType.CSTM_MEDICINE) && oldChargeCode.Code === line.NappiCode;
  }

  private isProcedureCode(oldChargeCode: CustomChargeCodes, line: TemplateLine) {
    return (oldChargeCode.Type === CustomChargeCodeType.ADMIN || oldChargeCode.Type === CustomChargeCodeType.CSTM_PROCEDURE) && oldChargeCode.Code === line.ChargeCode;
  }
}
