import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData,
  REPORT_NAME,
  Sheet,
  Table, WriteOffDetailsItem,
  WriteOffProviderModel,
  WriteOffReportModel, WriteOffSummaryItem
} from "@meraki-flux/schema";

export class WriteOffsXlsReportDataBuilder {
  build(writeOffReportModel: WriteOffReportModel): BaseExcelReportData {
    const excelReportData: BaseExcelReportData = {};
    excelReportData.practiceId = writeOffReportModel.WriteOffReportInfo.PracticeId;
    excelReportData.name = REPORT_NAME.WRITE_OFF;
    excelReportData.data = [];

    this.buildReportInfo(excelReportData, writeOffReportModel);

    if (!writeOffReportModel.AllProvidersModel) {
      writeOffReportModel.ProviderModels.forEach((providerModel) => {
        excelReportData.data.push(this.buildReportSheetSummary(providerModel));
        excelReportData.data.push(
          this.buildReportSheetDetail(
            providerModel,
            writeOffReportModel.WriteOffReportInfo.IsMultiBranch,
            false
          )
        );
      });
    } else {
      excelReportData.data.push(
        this.buildReportSheetSummary(writeOffReportModel.AllProvidersModel)
      );
      writeOffReportModel.ProviderModels.forEach((providerModel) => {
        if (providerModel.DetailsTable.length > 0) {
          excelReportData.data.push(
            this.buildReportSheetDetail(
              providerModel,
              writeOffReportModel.WriteOffReportInfo.IsMultiBranch,
              true
            )
          );
        }
      });
    }

    return excelReportData;
  }

  private buildReportSheetSummary(providerModel: WriteOffProviderModel): Sheet {
    const sheet: Sheet = {};
    sheet.name = 'Summary';
    sheet.header = 'Write-Offs Report';
    sheet.tables = [];

    const summaryTable: Table = {};
    //summaryTable.name = 'Summary';
    summaryTable.headers = [
      { value: 'Provider', bold: true, width: CellWidth.M },
      { value: 'Small balance', bold: true, width: CellWidth.XS },
      { value: 'Bad debt', bold: true, width: CellWidth.XS },
    ];

    let totalSmall_Balance = 0;
    let totalBad_Debt = 0;

    const rows = [];
    providerModel.SummaryTable.forEach((item: WriteOffSummaryItem) => {
      totalSmall_Balance += item.Small_balance ? item.Small_balance : 0;
      totalBad_Debt += item.Bad_debt ? item.Bad_debt : 0;

      const row: ExcelCellData[] = [];
      row.push({ value: item.Provider });
      row.push({ value: item.Small_balance ? item.Small_balance : 0, type: CellType.CURRENCY });
      row.push({ value: item.Bad_debt ? item.Bad_debt : 0, type: CellType.CURRENCY });
      rows.push(row);
    });

    const row: ExcelCellData[] = [];
    row.push({ value: 'Total', bold: true });
    row.push({value: totalSmall_Balance ? totalSmall_Balance : 0,type: CellType.CURRENCY,bold: true,});
    row.push({ value: totalBad_Debt ? totalBad_Debt : 0, type: CellType.CURRENCY, bold: true });
    rows.push(row);

    rows.push([{ value: '' }]);

    // Vat Exclusive calculation
    let totalSmall_Balance_ExVAT = 0;
    let totalBad_Debt_ExVAT = 0;

    providerModel.SummaryExVATTable.forEach((item: WriteOffSummaryItem) => {
      totalSmall_Balance_ExVAT += item.Small_ExVAT_balance ? item.Small_ExVAT_balance : 0;
      totalBad_Debt_ExVAT += item.Bad_ExVAT_debt ? item.Bad_ExVAT_debt : 0;
    });

    // Vat Amount calculation
    let totalSmall_BalanceVat = 0;
    let totalBad_DebtVat = 0;

    providerModel.SummaryVATTable.forEach((item: WriteOffSummaryItem) => {
      totalSmall_BalanceVat += item.Small_VAT_balance ? item.Small_VAT_balance : 0;
      totalBad_DebtVat += item.Bad_VAT_debt ? item.Bad_VAT_debt : 0;
    });

    const totalVatAmount = totalSmall_BalanceVat + totalBad_DebtVat;

    if (totalVatAmount != 0) {
      const exclVatRow: ExcelCellData[] = [];
      exclVatRow.push({ value: 'Total (excl. VAT)', bold: true });
      exclVatRow.push({value: totalSmall_Balance_ExVAT ? totalSmall_Balance_ExVAT : 0,type: CellType.CURRENCY,});
      exclVatRow.push({ value: totalBad_Debt_ExVAT ? totalBad_Debt_ExVAT : 0, type: CellType.CURRENCY });
      rows.push(exclVatRow);

      const vatAmountRow: ExcelCellData[] = [];
      vatAmountRow.push({ value: 'Total VAT amount', bold: true });
      vatAmountRow.push({value: totalSmall_BalanceVat ? totalSmall_BalanceVat : 0,type: CellType.CURRENCY,});
      vatAmountRow.push({value: totalBad_DebtVat ? totalBad_DebtVat : 0,type: CellType.CURRENCY,});
      rows.push(vatAmountRow);
    }

    summaryTable.rows = rows;
    sheet.tables.push(summaryTable);

    return sheet;
  }

  private buildReportSheetDetail(
    providerModel: WriteOffProviderModel,
    addBranchColumn?: boolean,
    isAllProvider?: boolean
  ): Sheet {
    const sheet: Sheet = {};
    sheet.name = isAllProvider ? providerModel.ProviderName.substring(0, 30) : 'Detail';
    sheet.header = 'Write-Offs Report';
    sheet.tables = [];

    const detailsTable: Table = {};

    detailsTable.headers = [
      { value: 'Write-off date', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Write-off type', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Write-off amount', bold: true, width: CellWidth.XS, wordWrap: true },
      {
        value: 'Recommended write-off limit exceeded?',
        bold: true,
        width: CellWidth.XS,
        wordWrap: true,
      },
      { value: 'Captured by', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Account no.', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Patient', bold: true, width: CellWidth.XS },
      { value: 'Invoice no.', bold: true, width: CellWidth.XS },
      { value: 'Date of service', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Date of submission', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Invoice amount', bold: true, width: CellWidth.XS, wordWrap: true },
      { value: 'Provider', bold: true, width: CellWidth.XS },
    ];

    if (addBranchColumn) {
      detailsTable.headers.push({ value: 'Branch', bold: true, width: CellWidth.XS });
    }

    const detailsRows = [];
    providerModel.DetailsTable.forEach((item: WriteOffDetailsItem) => {
      const row: ExcelCellData[] = [];
      row.push({ value: item.WriteOffDate, type: CellType.GENERAL });
      row.push({ value: item.WriteOffType });
      row.push({ value: item.WriteOffAmount, type: CellType.CURRENCY });
      row.push({ value: item.RecommendedWriteOffLimit });
      row.push({ value: item.CaptureBy });
      row.push({ value: item.AccountNo });
      row.push({ value: item.Patient });
      row.push({ value: item.InvoiceNo });
      row.push({ value: item.DateOfService, type: CellType.GENERAL });
      row.push({ value: item.DateofSubmission, type: CellType.GENERAL });
      row.push({ value: item.InvoiceAmount, type: CellType.CURRENCY });
      row.push({ value: item.Provider });
      if (addBranchColumn) row.push({ value: item.Branch });

      detailsRows.push(row);
    });
    detailsTable.rows = detailsRows;
    sheet.tables.push(detailsTable);

    return sheet;
  }

  private buildReportInfo(
    excelReportData: BaseExcelReportData,
    writeOffReportModel: WriteOffReportModel
  ) {
    excelReportData.parameters = [];

    excelReportData.parameters.push({
      name: 'Practice',
      data: { value: writeOffReportModel.WriteOffReportInfo.Practice, type: CellType.GENERAL },
    });
    excelReportData.parameters.push({
      name: 'Billing practice no',
      data: {
        value: writeOffReportModel.WriteOffReportInfo.BillingPracticeNumber,
        type: CellType.GENERAL,
      },
    });
    if (writeOffReportModel.WriteOffReportInfo.IsMultiBranch) {
      excelReportData.parameters.push({
        name: 'Branch',
        data: { value: writeOffReportModel.WriteOffReportInfo.Branch, type: CellType.GENERAL },
      });
    }
    excelReportData.parameters.push({
      name: 'Date range type',
      data: { value: writeOffReportModel.WriteOffReportInfo.DateRangeType, type: CellType.GENERAL },
    });
    excelReportData.parameters.push({
      name: 'Date Range',
      data: { value: writeOffReportModel.WriteOffReportInfo.DateRange, type: CellType.GENERAL },
    });
    excelReportData.parameters.push({
      name: 'Treating Provider',
      data: {
        value: writeOffReportModel.WriteOffReportInfo.TreatingProvider,
        type: CellType.GENERAL,
      },
    });
  }
}
