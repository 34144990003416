import { VariableCategories } from "./enums";

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Config file for tinymce editor
export const EDITOR_CONFIG: any = {
  base_url: '/tinymce',
  suffix: '.min',
  plugins: 'link table preview lists',
  toolbar: 'fontsizeselect | bold italic underline | forecolor | numlist bullist alignleft aligncenter alignright | outdent indent | file | customInsertButton',
  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  browser_spellcheck: true,
  contextmenu: false,
  statusbar: false,
  menubar: false,
  visual: false,
  height: 250,
  valid_children: '+body[style],+body,+head',
  extended_valid_elements: 'body,head',
  elementpath: false,
  branding: false,
  content_css_cors: true,
}

// Placeholder variables for communication templates
export const PlaceholderVariables = [
  { text: 'PracticeName', value: '${PracticeName}', category: VariableCategories.SINGLEPRACTICE, previewValue: '' },
  { text: 'PracticeAddress', value: '${PracticeAddress}', category: VariableCategories.SINGLEPRACTICE, previewValue: '' },
  { text: 'PracticeOfficeNo', value: '${PracticeOfficeNo}', category: VariableCategories.SINGLEPRACTICE, previewValue: '' },
  { text: 'PracticeEmail', value: '${PracticeEmail}', category: VariableCategories.SINGLEPRACTICE, previewValue: '' },
  { text: 'BranchName', value: '${BranchName}', category: VariableCategories.MULTIBRANCH, previewValue: '' },
  { text: 'BranchAddress', value: '${BranchAddress}', category: VariableCategories.MULTIBRANCH, previewValue: '' },
  { text: 'BranchOfficeNo', value: '${BranchOfficeNo}', category: VariableCategories.MULTIBRANCH, previewValue: '' },
  { text: 'BranchEmail', value: '${BranchEmail}', category: VariableCategories.MULTIBRANCH, previewValue: '' },
  { text: 'PatientTitle', value: '${PatientTitle}', category: VariableCategories.ALL, previewValue: 'Mr' },
  { text: 'PatientPrefName', value: '${PatientPrefName}', category: VariableCategories.ALL, previewValue: 'Christo' },
  { text: 'PatientFirstName', value: '${PatientFirstName}', category: VariableCategories.ALL, previewValue: 'Christopher' },
  { text: 'PatientLastName', value: '${PatientLastName}', category: VariableCategories.ALL, previewValue: 'Makonpanza' },
  { text: 'AccountNo', value: '${AccountNo}', category: VariableCategories.ALL, previewValue: 'MAK005' },
  { text: 'AccountBalance', value: '${AccountBalance}', category: VariableCategories.ALL, previewValue: 'R1450.00' },
  { text: 'PatientLiable', value: '${PatientLiable}', category: VariableCategories.ALL, previewValue: 'R1200.00' },
  { text: 'MedAidLiable', value: '${MedAidLiable}', category: VariableCategories.ALL, previewValue: 'R250.00' },
  { text: 'AppointmentDate', value: '${AppointmentDate}', category: VariableCategories.APPOINTMENT, previewValue: '12/03/2019' },
  { text: 'AppointmentTime', value: '${AppointmentTime}', category: VariableCategories.APPOINTMENT, previewValue: '1:30pm' },
  { text: 'ProviderFullName', value: '${ProviderFullName}', category: VariableCategories.APPOINTMENT, previewValue: 'Dr Samual Ellis' },
  { text: 'ProviderInitialsSurname', value: '${ProviderInitialsSurname}', category: VariableCategories.APPOINTMENT, previewValue: 'Dr S Ellis' },
  { text: 'AppointmentVideoLink', value: '${AppointmentVideoLink}', category: VariableCategories.VIDEO_LINK, previewValue: 'http://videolink84727272' },
  { text: 'PaymentDate', value: '${PaymentDate}', category: VariableCategories.PAYMENT_CONFIRMATION, previewValue: '02/01/2022' },
  { text: 'InvoiceNumber', value: '${InvoiceNumber}', category: VariableCategories.INVOICE, previewValue: '2201/00234' },
  { text: 'DateOfService', value: '${DateOfService}', category: VariableCategories.INVOICE, previewValue: '14/01/2022' },
];

export const FAQUrls = {
  BI_DASHBOARD: 'https://resources.healthbridgenova.co.za/add-on-bi-dashboard/',
  SNAPSCAN: 'https://resources.healthbridgenova.co.za/add-on-snapscan/',
  BULK_SMS: 'https://resources.healthbridgenova.co.za/add-on-bulk-sms/',
}