import * as moment from "moment";
import * as _ from "lodash";
import { VisitInfo, BenefitCheckReportModel, BenefitCheckResponse, BenefitCheckLine, ClaimDetailData, ClaimDetailDataTable, CellType, PDFCellData, BenefitCheckTable, FinancialResponseLine } from "@meraki-flux/schema";

export class BenefitCheckReportBuilder {

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    build(bpn: string, practiceName: string, treatingProvider: string, visitInfo: VisitInfo): BenefitCheckReportModel {
        const invoiceResult = this.buildStatementTables(visitInfo.BenefitCheckResponse as BenefitCheckResponse, visitInfo.BenefitCheckLines as BenefitCheckLine[]);
        return {
            PracticeId: bpn,
            PracticeName: `${practiceName}`,
            BenefitCheckTables: invoiceResult.invoiceTables,
            BenefitCheckData: this.buildBenefitChecksData(bpn, practiceName, treatingProvider, visitInfo as VisitInfo),
            ReportDate: moment(visitInfo.BenefitCheckResponse?.ResponseDetails?.ResponseDateTime).format(this.DATE_FORMAT)
        };
    }

    buildBenefitChecksData(bpn: string, practiceName: string, treatingProvider: string, visitInfo: VisitInfo): ClaimDetailData {
        return {
            claimStatus: this.buildBenefitCheckStatus(bpn, practiceName, treatingProvider, visitInfo),
            details: this.buildBenefitCheck(visitInfo)
        }
    }

    buildBenefitCheckStatus(bpn: string, practiceName: string, treatingProvider: string, visitInfo: VisitInfo): ClaimDetailDataTable {
        const result: any[] = []
        result.push(this.addBenefitCheckRow(
            'Patient', { value: `${visitInfo.PatientInfo?.Name} ${visitInfo.PatientInfo?.Surname}` },
            'Practice', { value: practiceName }
        ));
        result.push(this.addBenefitCheckRow(
            'Medical aid', { value: visitInfo.AccountInfo?.SchemeName ?? "-" },
            'Practice number', { value: bpn }
        ));
        result.push(this.addBenefitCheckRow(
            'Member number', { value: visitInfo.AccountInfo?.MemberNumber ?? "-" },
            'Treating doctor', { value: treatingProvider }
        ));
        result.push(this.addBenefitCheckRow(
            'Dependat code', { value: visitInfo.PatientInfo?.DependantCode ?? "-" },
            '', { value: `` }
        ));
        return {
            rows: result
        }
    }

    buildBenefitCheck(visitInfo: VisitInfo): ClaimDetailDataTable {
        const benefitCheckResponse = visitInfo.BenefitCheckResponse as BenefitCheckResponse;
        const result: any[] = []
        result.push(this.addBenefitCheckRow(
            'Status', { value: visitInfo.BenefitCheckStatus ?? "-" },
            'Benefits requested', { value: benefitCheckResponse.FinancialResponseDetails?.TotalRequestedAmount ?? 0, type: CellType.CURRENCY }
        ));
        result.push(this.addBenefitCheckRow(
            'Reference number', { value: benefitCheckResponse.ResponseDetails?.ReferenceNum ?? "-" },
            'Paid to provider', { value: benefitCheckResponse.FinancialResponseDetails?.TotalAmountToProvider ?? 0, type: CellType.CURRENCY }
        ));
        result.push(this.addBenefitCheckRow(
            'Benefit response', { value: moment(benefitCheckResponse.ResponseDetails?.ResponseDateTime).format("ddd MMM Do h:mm a") ?? "-" },
            'Paid to member', { value: benefitCheckResponse.FinancialResponseDetails?.TotalAmountToMember ?? 0, type: CellType.CURRENCY }
        ));
        const responseMessage = benefitCheckResponse.FinancialResponseDetails?.FundingIndicator;
        const responseCodes: any[] = benefitCheckResponse.ResponseDetails?.ResponseActions ?? benefitCheckResponse.ResponseDetails?.ResponseCodes ?? ["-"];
        result.push(this.addBenefitCheckRow(
            'Response message', { value: responseMessage ?? responseCodes[0].Description ?? "-" },
            'Member not liable', { value: benefitCheckResponse.FinancialResponseDetails?.TotalMemberNotLiableAmount ?? 0, type: CellType.CURRENCY }
        ));
        result.push(this.addBenefitCheckRow(
            '', { value: '' },
            'Member liable', {
            value: benefitCheckResponse.FinancialResponseDetails?.TotalMemberLiableAmount ?? benefitCheckResponse.FinancialResponseDetails?.TotalOutstandingAmount ?? 0, type: CellType.CURRENCY
        }
        ));
        return {
            rows: result
        }
    }

    addBenefitCheckRow(h1: string, v1: PDFCellData, h2: string, v2: PDFCellData) {
        return {
            header1: h1,
            value1: v1,
            header2: h2,
            value2: v2,
        }
    }

    buildStatementTables(benefitCheckResponse: BenefitCheckResponse, benefitCheckLines: BenefitCheckLine[]) {
        const BenefitCheckTables: BenefitCheckTable[] = [];
        if (benefitCheckResponse.FinancialResponseDetails) {
            const linesTable = this.buildStatementTable(benefitCheckResponse, benefitCheckLines);
            BenefitCheckTables.push(linesTable);
            if (benefitCheckResponse.FinancialResponseDetails?.FinancialResponseLines[0]?.LineResponseCodes) {
                const maCodesTable = this.buildMaCodesTable(benefitCheckResponse);
                BenefitCheckTables.push(maCodesTable);
            }
        }
        const disclaimerTable = this.buildDisclaimerTable(benefitCheckResponse);
        BenefitCheckTables.push(disclaimerTable);
        return {
            invoiceTables: BenefitCheckTables
        };
    }

    buildDisclaimerTable(benefitCheckResponse: BenefitCheckResponse) {
        const rowHeaders = this.buildStatementRowHeaders();
        return {
            rowHeaders: rowHeaders,
            rows: [[{ value: benefitCheckResponse.ResponseDetails?.Disclaimer ?? "" }]],
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        };
    }

    buildStatementRowHeaders() {
        const rowHeaders = [];
        rowHeaders.push({
            value: "Disclaimer"
        });
        return rowHeaders;
    }

    buildMaCodesTable(benefitCheckResponse: BenefitCheckResponse) {
        const rowHeaders = this.buildMaCodesRowHeaders();
        const rows = [];
        let responseCodes: any[] = [];
        if (benefitCheckResponse.FinancialResponseDetails) {
            benefitCheckResponse.FinancialResponseDetails.FinancialResponseLines.forEach((line) => {
                responseCodes = responseCodes.concat(line.LineResponseCodes);
                responseCodes = _.uniqBy(responseCodes, 'Code');
            });
        } else {
            responseCodes = benefitCheckResponse.ResponseDetails?.ResponseActions ?? benefitCheckResponse.ResponseDetails?.ResponseCodes ?? [];
        }
        responseCodes.forEach((responseCode) => {
            if (responseCode)
                rows.push(this.buildMaCodesTableRow(responseCode))
        });
        return {
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        };
    }

    buildMaCodesTableRow(responseCode: any) {
        const row = [];
        if (responseCode)
            row.push({
                value: `${responseCode.Code} - ${responseCode.Description}` ?? ""
            })
        return row;
    }

    buildMaCodesRowHeaders() {
        const rowHeaders = [];
        rowHeaders.push({
            value: "Medical aid response codes", width: "100%"
        })
        return rowHeaders;
    }

    buildStatementTable(benefitCheckResponse: BenefitCheckResponse, benefitCheckLines: BenefitCheckLine[]) {
        const rowHeaders = this.buildLinesRowHeaders();
        const rows = [];
        const BCLines = benefitCheckResponse.FinancialResponseDetails?.FinancialResponseLines as FinancialResponseLine[] ?? [];
        BCLines.forEach((BCLine, index) => {
            rows.push(this.buildTableRow(BCLine, benefitCheckLines[index]))
        });
        return {
            rowHeaders: rowHeaders,
            rows: rows,
            borders: {
                hor: true,
                outerborder: false,
                headerBorderSize: 1,
                rowBorderSize: 0.5
            }
        };
    }

    buildTableRow(frLine: FinancialResponseLine, bcLine: BenefitCheckLine) {
        const row = [];
        row.push({
            value: frLine.TariffCode ?? ""
        })
        row.push({
            value: bcLine.DiagnosisCode ?? ""
        })
        row.push({
            value: bcLine.Description
        })
        const responseCodes: any[] = frLine.LineResponseCodes ?? [];
        row.push({
            value: responseCodes.map(c => c.Code).join(',')
        })
        row.push({
            value: frLine.RequestedAmount || 0,
            type: CellType.CURRENCY
        })
        row.push({
            value: frLine.AmountToProvider || 0,
            type: CellType.CURRENCY
        })
        row.push({
            value: frLine.AmountToMember || 0,
            type: CellType.CURRENCY
        })
        row.push({
            value: frLine.MemberLiableAmount || frLine.OutstandingAmount || 0,
            type: CellType.CURRENCY
        })
        row.push({
            value: frLine.MemberNotLiableAmount || 0,
            type: CellType.CURRENCY
        })
        return row;
    }

    resize(arr, size) {
        const add = arr.length > size;
        while (arr.length > size) { arr.pop(); }
        if (add) {
            arr[size - 1] = arr[size - 1].replace(/.$/, '...')
        }
        return arr;
    }

    buildLinesRowHeaders() {
        return ["Charge code", `Diag.`, `Description${"\t\u200B".repeat(12)}`, "Response codes", "Benefits requested", "Paid to provider", "Paid to member", "Member liable", "Member not liable"].map(n => {
            return {
                value: n
            }
        });
    }

}
