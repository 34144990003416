import { Injectable } from "@angular/core";
import { ReferenceDataService } from "../../services/reference-data.service";
import { ReportUtils } from "../report-utils";
import { AccountDetailsPdfReportDataBuilder } from "./account-details-pdf-report-data-builder";
import { AccountDetailsReportBuilder } from "./account-details-report-builder";
import { AccountDetailsPdfGenerator } from "./pdf.account-details";
import {
  AccountDetailsReportRequest, BaseAccount, BaseAccountMember, BasePdfReportData,
  Plan,
  PlanOption, Practice,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root'
})
export class AccountDetailsReportService {

  async generateReport(reportRequest: AccountDetailsReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildAccountDetailsReportFileName(
      reportRequest.AccountMember.Name, reportRequest.AccountMember.Surname, REPORT_FORMAT_TYPE.PDF);
    const scheme = await this.referenceDataService.getScheme(reportRequest.Account.Scheme);
    let plan: Plan = undefined
    let option: PlanOption = undefined
    if (scheme?.Plans) {
      plan = scheme?.Plans?.find(x => x.Code === reportRequest.Account.Plan)
    }
    if (plan?.Options) {
      option = plan?.Options?.find(x => x.Code === reportRequest.Account.Option)
    }
    const reportGenerator = new AccountDetailsPdfGenerator(await this.generatePdfReportData(reportRequest,
      reportRequest.Practice, reportRequest.Account, reportRequest.MainMember, reportRequest.AccountMember,
      scheme?.Name ?? "", plan?.Plan ?? "", option?.Option ?? ""));

    return {
      content: reportGenerator.generateReport(),
      format: REPORT_FORMAT_TYPE.PDF,
      reportName: REPORT_NAME.ACCOUNT_DETAILS,
      fileName: fileName,
      practiceId: reportRequest.Practice.Id
    };
  }

  private async generatePdfReportData(reportRequest: AccountDetailsReportRequest, practice: Practice, account: BaseAccount,
                                      mainMember: BaseAccountMember, accountMember: BaseAccountMember,
                                      scheme: string, plan: string, option: string): Promise<BasePdfReportData> {
    return new AccountDetailsPdfReportDataBuilder().build(
      await new AccountDetailsReportBuilder().build(reportRequest, practice, account, mainMember, accountMember,
        scheme, plan, option));
  }

  constructor(private referenceDataService: ReferenceDataService) {
  }
}
