// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  meilisearchHost: "https://flexhunt.dev",
  meilisearchSearchKey: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  meilisearchLookup: "https://flexhunt.dev",
  meilisearchLookupKey: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  meilisearchAccount: "https://flexhunt.dev",
  meilisearchAccountKey: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  meilisearchInvoice: "https://flexhunt.dev",
  meilisearchInvoiceKey: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  meilisearchCalendar: "https://flexhunt.dev",
  meilisearchCalendarKey: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  forgotPasswordUrl: "https://europe-west1-healthbridge-meraki-dev.cloudfunctions.net/usr-pwd-v1-post-forgotUserPassword",
  resetPasswordUrl: "https://europe-west1-healthbridge-meraki-dev.cloudfunctions.net/usr-pwd-v1-post-updateUserPassword",
  loadBalancerUrl: "https://dev.meraki.healthbridge.co.za",
  vapidKey: "BD8644OusWk_vPaPQSDw8um2g_ZOZ7NukD76HfMYdUrhBlnAKYnI2pnwlAycGBAtLAXQXKRPA69xuH7uj66h6yA",
  disableConsoleLogging: false,
  disableServerLogging: false,
  billingModifierURL: "https://ihealthmods-cwkfrxe7da-ew.a.run.app/api/iHealth/Modifiers/v2",  
  billingModifieTtoken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RNb2RpZmllcnNTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIyZTY4OWZlNS1kM2VkLTQzNzktYjcyYy03MGExNDkzZTY3YTUiLCJpYXQiOiIxNzA1OTQ5MjM1IiwiVXNlcklkIjoiMSIsIkRpc3BsYXlOYW1lIjoiaUhlYWx0aCIsIlVzZXJOYW1lIjoiaUhlYWx0aCIsIkVtYWlsIjoidGVzdEBtZS5jby56YSIsImV4cCI6MjAyMTU2ODQzNSwiaXNzIjoiaUhlYWx0aEpXVEF1dGhlbnRpY2F0aW9uU2VydmVyIiwiYXVkIjoiaUhlYWx0aFdlYkNsaWVudCJ9.SR7rHXwKF6kPWc97HlGFD_M_qNaJt8Cck9YLS0VLdAk"
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
