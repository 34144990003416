import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth.service';
import { DialogService } from '../../dialog.service';
import { PatientAuthorizationService } from '../../patient-authorization.service';
import { CalendarEventService } from '../calendar-event.service';
import { CalendarRepository } from '../../../repositories/calendar.repository';
import moment from 'moment';
import { DialogType, YES_BUTTON_ID, CalendarEvent, CALENDAR_EVENT_STATUS } from '@meraki-flux/schema';
import { first } from 'rxjs/operators';
//import { EventDialogComponent } from '../event-dialog/event-dialog.component';

@Injectable({ providedIn: 'root' })
export class AppointmentCheckInService {
  constructor(
    private calendarEventService: CalendarEventService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private patientAuthorizationService: PatientAuthorizationService,
    private calendarRepository: CalendarRepository
  ) {}

  //Added to be used for Patient dotmenu checkin
  //Originally from calendar AppointmentCheckInService, both should be maintained until this new service is fully implemented.
  async smartCheckIn(event: CalendarEvent, callback?: () => void) {
    if (event.VisitInfo?.AccountInfo?.AccountId) {
      await this.addMissingAuthorizationNo(event);
      await this.calendarEventService.changeStatus(event, CALENDAR_EVENT_STATUS.CHECKED_IN);
      callback && callback();
    } else {
      await this.dialogService.showErrorMessage('Account or Member not set.');
    }
  }

  //Added to be used for Patient dotmenu checkin
  //Originally from calendar AppointmentCheckInService, both should be maintained until this new service is fully implemented.
  async addMissingAuthorizationNo(event: CalendarEvent) {
    const practiceId = this.authService.selectedBPN;
    const patientId = event?.VisitInfo?.PatientInfo?.PatientId;
    const authorizationNo = event?.VisitInfo?.AuthorizationNo;
    if (practiceId && patientId && authorizationNo) {
      await this.patientAuthorizationService.saveNewAuthorizationNo(practiceId, patientId, authorizationNo);
    }
  }

}
