import {Observable} from 'rxjs';
import {Audit, BENEFIT_CHECK_STATUS, VISIT_TYPE} from './general.models';

export interface ChangeAudit extends Audit {
  Changes?: FieldChangeAudit[];
}

export interface FieldChangeAudit {
  FieldName?: string;
  ValueFrom?: any;
  ValueTo?: any;
}

export interface SidebarItem {
  label: string;
  icon?: string;
  url?: string;
  disabled?: boolean;
  counter?: number;
  access?: Observable<boolean>;
  click?: (e: any) => void;
}

export interface SidebarGroup {
  header?: string;
  items: SidebarItem[];
  hasItems$?: Observable<boolean>;
}

export const DEFAULT_VISIT_TYPE_COLOURS = {
  [VISIT_TYPE.MEDICAL_INSURANCE]: '#00B286',
  [VISIT_TYPE.NO_CHARGE]: '#D82C0D',
  [VISIT_TYPE.OUT_OF_ROOMS]: '#8C9196',
  [VISIT_TYPE.REGULAR_CONSULTATION]: '#2C6ECB',
  [VISIT_TYPE.TELEPHONIC_CONSULT]: '#F7941C',
  [VISIT_TYPE.VIDEO_CONSULT]: '#FFCE00',
};

export const DEFAULT_BENEFIT_CHECK_COLOURS = {
  [BENEFIT_CHECK_STATUS.AWAITING_RESPONSE]: '#8C9196',
  [BENEFIT_CHECK_STATUS.CALL_CENTRE_UNAVAILABLE]: '#202223',
  [BENEFIT_CHECK_STATUS.FAULT]: '#202223',
  [BENEFIT_CHECK_STATUS.FULLY_COVERED]: '#00B286',
  [BENEFIT_CHECK_STATUS.INFORMATION_AVAILABLE]: '#2C6ECB',
  [BENEFIT_CHECK_STATUS.INVALID_PATIENT_DATA]: '#202223',
  [BENEFIT_CHECK_STATUS.NOT_COVERED]: '#F7941C',
  [BENEFIT_CHECK_STATUS.NOT_SUPPORTED]: '#8C9196',
  [BENEFIT_CHECK_STATUS.NOT_TRIGGERED]: '#2C6ECB',
  [BENEFIT_CHECK_STATUS.PART_COVERED]: '#FFCE00',
  [BENEFIT_CHECK_STATUS.REJECTED]: '#D82C0D',
  [BENEFIT_CHECK_STATUS.UNKNOWN_RESPONSE]: '#202223',
};

// Order of colours is priority
export const PROVIDER_COLOURS = [
  '#2C6ECB',
  '#FFCE00',
  '#008060',
  '#FC9738',
  '#624290',
  '#E0225C',
  '#27B5E1',
  '#ADBB47',
  '#F14F36',
  '#3DB59E',
  '#5A2768',
  '#7A9F32',
  '#BF3468',
  '#283BA0',
  '#FF6C99',
  '#8AD99B',
  '#A359AF',
  '#008DA6',
];

// Order of colours is priority
export const VISIT_TYPE_COLOURS = [
  '#2C6ECB',
  '#FFCE00',
  '#008060',
  '#FC9738',
  '#624290',
  '#E0225C',
  '#27B5E1',
  '#ADBB47',
  '#F14F36',
  '#3DB59E',
];
