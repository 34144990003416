import { Injectable } from '@angular/core';
import { DateUtils } from '../../utils/date-utils';
import { RRuleUtils } from '../../utils/rrule-utils';
import {CALENDAR_EVENT_STATUS, CalendarEvent} from "@meraki-flux/schema";

@Injectable({
  providedIn: 'root',
})
export class RecurrentEventService {
  prepareRecurrentEvents(rootEvent: CalendarEvent, startTimeGOE: Date, endTimeLT: Date) {
    const dates = RRuleUtils.prepareAllBaseDates(
      rootEvent.StartTime,
      rootEvent?.RecurrentInfo?.Rule.Repeat,
      endTimeLT
    );
    const durationMills = rootEvent.EndTime.getTime() - rootEvent.StartTime.getTime();
    const rule = rootEvent?.RecurrentInfo?.Rule;
    const skipIndexes = rule?.SkipIndexes || [];
    const recurrentEvents = dates
      .filter((d) => d.getTime() >= startTimeGOE.getTime() && d.getTime() <= endTimeLT.getTime())
      .map((d) => {
        const rrEventIndex = dates.indexOf(d);
        return {
          ...RRuleUtils.createEventTemplate(rootEvent),
          StartTime: d,
          EndTime: DateUtils.addMills(d, durationMills),
          IsRecurrentRoot: false,
          RecurrentInfo: {
            RootEventId: rootEvent.Id,
            Index: rrEventIndex,
            Rule: rule,
          },
          Status: CALENDAR_EVENT_STATUS.BOOKED,
          Metadata: {
            ViewId: `${rootEvent.RecurrentInfo.RootEventId}_${rrEventIndex}`,
            RecurrentRuleEvent: rootEvent,
          },
        } as CalendarEvent;
      })
      .filter((event) => !skipIndexes.includes(event.RecurrentInfo.Index));
    return recurrentEvents;
  }

  fixRecurrentInfo(event: CalendarEvent, recurrentRootEvents: CalendarEvent[]): any {
    if (!event?.RecurrentInfo?.RootEventId || event?.RecurrentInfo?.RootEventId === event.Id) {
      return event;
    }
    const matchedRecurrentRootEvent = recurrentRootEvents?.find(
      (re) => re.Id === event?.RecurrentInfo?.RootEventId
    );
    if (matchedRecurrentRootEvent) {
      event.RecurrentInfo.Rule = matchedRecurrentRootEvent.RecurrentInfo.Rule;
      event.Metadata.RecurrentRuleEvent = matchedRecurrentRootEvent;
    }
    return event;
  }
}
