import {InvoicePatient} from "./invoice.models";

export interface SearchDialogData {
  allowMultiSelect?: boolean;
  searchType?: SEARCH_TYPE;
  preselectedData?: SearchResult[];
  enablePaging?: boolean;
  selectedProviderId?: string;
  isHeaderSelect?: boolean;
  patient?: InvoicePatient
}

export interface SearchDialogFavouriteData {
  searchType?: SEARCH_TYPE;
  selectedProviderId?: string;
  code?: string;
  description?: string;
}

export interface SearchResult {
  code?: string;
  description?: string;
  displayCode?: string;
  displayDescription?: string;
  additionalData?: any;
}

export interface DialogSearchResult {
  pageResults: Array<SearchResult>;
  addFavourite: boolean;
}

export interface SearchResponse {
  pageResults: SearchResult[];
  totalCount: number;
}

export interface SearchRequest {
  query: string,
  page: number;
}

export enum SEARCH_TYPE {
  DIAGNOSIS = 'Diagnosis',
  MEDICINE = 'Medicine',
  CONSUMABLE = 'Consumable',
  PROCEDURE = 'Procedure'
}
