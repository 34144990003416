import {
  BaseExcelReportData, CellType, CellWidth, ExcelCellData,
  PatientListDetailsItem,
  PatientListModel,
  REPORT_NAME,
  Sheet,
  Table
} from "@meraki-flux/schema";

export class PatientListXlsReportDataBuilder {

    build(patientListReportModel: PatientListModel): BaseExcelReportData {
        const excelReportData: BaseExcelReportData = {};
        excelReportData.practiceId = patientListReportModel.PatientListReportInfo.PracticeId;
        excelReportData.name = REPORT_NAME.PATIENT_LIST;
        excelReportData.data = [];

        this.buildReportInfo(excelReportData, patientListReportModel);
        excelReportData.data.push(this.buildReportSheetDetail(patientListReportModel.PatientListDetailsItem));

        return excelReportData;
    }

    private buildReportSheetDetail(patientListDetailsItem: PatientListDetailsItem[]): Sheet {
        const sheet: Sheet = {};
        sheet.name = 'Patient List';
        sheet.header = 'Patient List Report';
        sheet.tables = [];

        const detailsTable: Table = {};

        detailsTable.headers = [
            {value: 'Surname', bold: true, width: CellWidth.S, wordWrap: true},
            {value: 'First name', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Preferred name', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Date of birth', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Gender', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Cellphone no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Email address', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Account type', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Account no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Account holder', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Scheme', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Plan', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Option', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Member no.', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Dependent code', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Last visit date', bold: true, width: CellWidth.XS, wordWrap: true},
            {value: 'Branch', bold: true, width: CellWidth.XS, wordWrap: true}
        ];

        const detailsRows = [];
        patientListDetailsItem.forEach((item: PatientListDetailsItem) => {
            const row: ExcelCellData[] = [];
            row.push({value: item.Surname});
            row.push({value: item.FirstName});
            row.push({value: item.PreferredName});
            row.push({value: item.DateOfBirth, type: CellType.DATE});
            row.push({value: item.Gender});
            row.push({value: item.CellphoneNo});
            row.push({value: item.EmailAddress});
            row.push({value: item.AccountType});
            row.push({value: item.AccountNo});
            row.push({value: item.AccountHolder});
            row.push({value: item.Scheme});
            row.push({value: item.Plan});
            row.push({value: item.Option});
            row.push({value: item.MemberNo});
            row.push({value: item.DependentCode});
            row.push({value: item.LastVisitDate});
            row.push({value: item.Branch});



            detailsRows.push(row);
        });
        detailsTable.rows = detailsRows;
        sheet.tables.push(detailsTable);

        return sheet;
    }

    private buildReportInfo(excelReportData: BaseExcelReportData, patientListReportModel: PatientListModel) {
        excelReportData.parameters = [];

        excelReportData.parameters.push({
            name: 'Practice',
            data: {value: patientListReportModel.PatientListReportInfo.Practice, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Billing practice no.',
            data: {value: patientListReportModel.PatientListReportInfo.PracticeId, type: CellType.GENERAL}
        });

        // if (patientListReportModel.PatientListReportInfo.IsMultiBranch) {
        //     excelReportData.parameters.push(
        //         {
        //             name: 'Branch',
        //             data: {value: patientListReportModel.PatientListReportInfo.Branch, type: CellType.GENERAL}
        //         });
        // }


        excelReportData.parameters.push({
            name: 'Last visit within',
            data: {value: patientListReportModel.PatientListReportInfo.LastVisitWithin, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'From age',
            data: {value: patientListReportModel.PatientListReportInfo.FromAge, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'To age',
            data: {value: patientListReportModel.PatientListReportInfo.ToAge, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Account type',
            data: {value: patientListReportModel.PatientListReportInfo.AccountType, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Account status',
            data: {value: patientListReportModel.PatientListReportInfo.AccountStatus, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Gender',
            data: {value: patientListReportModel.PatientListReportInfo.Gender, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Scheme',
            data: {value: patientListReportModel.PatientListReportInfo.Scheme, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Conditions',
            data: {value: patientListReportModel.PatientListReportInfo.Conditions, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Medications',
            data: {value: patientListReportModel.PatientListReportInfo.Medications, type: CellType.GENERAL}
        });

        excelReportData.parameters.push({
            name: 'Procedures',
            data: {value: patientListReportModel.PatientListReportInfo.Procedures, type: CellType.GENERAL}
        });

    }


}
