import { Injectable } from '@angular/core';
import {PaymentCorrectionsReportBuilder} from "./payment-corrections-report-builder";
import {PaymentCorrectionsXlsReportDataBuilder } from './payment-corrections-xls-report-data-builder';

import {ReportUtils} from "../report-utils";
import {
  BaseExcelReportData,
  PaymentCorrectionReportRequest,
  Report,
  REPORT_FORMAT_TYPE,
  REPORT_NAME
} from "@meraki-flux/schema";
import { BaseExcelGenerator } from '@meraki-flux/purejs';


@Injectable({
  providedIn: 'root'
})
export class PaymentCorrectionsReportService {

  async generateReport(reportRequest: PaymentCorrectionReportRequest): Promise<Report> {

    const fileName: string = await ReportUtils.buildPaymentCorrectionReportFileName('PaymentCorrections',
      reportRequest.Practice, reportRequest.ReportFormatType, reportRequest.DateFrom, reportRequest.DateTo);

    const reportGenerator: BaseExcelGenerator = await this.getReportGenerator(reportRequest);

    return {
      fileName: fileName,
      reportName: REPORT_NAME.PAYMENT_CORRECTIONS,
      content: reportGenerator.generateReport(),
      practiceId: reportRequest.Practice.Id,
      format: REPORT_FORMAT_TYPE.EXCEL
    }
  }

  private async getReportGenerator(reportRequest: PaymentCorrectionReportRequest): Promise<BaseExcelGenerator> {
    if (REPORT_FORMAT_TYPE.EXCEL === reportRequest.ReportFormatType) {
      return new BaseExcelGenerator(await this.generateXlsReportData(reportRequest));
    } else {
      throw new Error("Report format is not supported.");
    }
  }


  private async generateXlsReportData(reportRequest: PaymentCorrectionReportRequest): Promise<BaseExcelReportData> {
    return new PaymentCorrectionsXlsReportDataBuilder().build(
      await new PaymentCorrectionsReportBuilder().build(reportRequest));
  }
}
