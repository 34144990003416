import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { Account, AccountMember, Invoice, ReconciliationInboxItem, RemittanceAdvice, RemittanceClaim } from '@meraki-flux/schema';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../services/auth.service';
import { RemittanceService } from '../../services/remittance.service';
import { MoneyUtils } from '../../utils/money-utils';
import { RaUtils } from '../../utils/ra-utils';
import { DebitInvoiceComponent } from '../debit-invoice/debit-invoice.component';
import { MaPayableInvoiceComponent } from '../ma-payable-invoice/ma-payable-invoice.component';
import { AbstractUnmatchedEraItemActionHelper } from './unmatched-era-item-action-abstract.helper';
import { NegativeUnmatchedEraItemActionHelper } from './unmatched-era-item-action-negative.helper';
import { PositiveUnmatchedEraItemActionHelper } from './unmatched-era-item-action-positive.helper';

export interface UnmatchedEraItemActionComponentForm {
  AccountSearch: any;
  OutstandingBalanceInvoices: any;
  FilterOnClaimMatches: any;
  UnallocatedAmount: any;
  CouldNotReconcile: any;
  AdditionalRemittanceNotes: any;
  AdditionalAccountNotes: any;
}

@Component({
  selector: 'meraki-flux-unmatched-era-item-action',
  templateUrl: './unmatched-era-item-action.component.html',
  styleUrls: ['./unmatched-era-item-action.component.scss'],
})
@UntilDestroy()
export class UnmatchedEraItemActionComponent implements OnInit, OnChanges {
  @ViewChildren(MaPayableInvoiceComponent)
  creditInvoiceComponents: QueryList<MaPayableInvoiceComponent>;
  @ViewChild(DebitInvoiceComponent)
  debitInvoiceComponent: DebitInvoiceComponent;
  @Input() executionContext: 'inbox' | 'suspense account';

  YEARS_TO_SELECT = 6;
  DEFAULT_FORM_VALUES = {
    OutstandingBalanceInvoices: true,
    FilterOnClaimMatches: false,
    CouldNotReconcile: false,
    AdditionalRemittanceNotes: '',
    AdditionalAccountNotes: '',
    UnallocatedAmount: 0,
  };
  raUtils = RaUtils;
  componentState$ = new BehaviorSubject<'Loading' | 'Ready' | 'Error'>('Ready');
  saveState$ = new BehaviorSubject<'Ready' | 'Busy' | 'Saved' | 'Discarded'>('Ready');
  accountSearchBusy$ = new BehaviorSubject(false);

  @Input() inboxItem: ReconciliationInboxItem;
  @Input() ra: RemittanceAdvice;
  @Input() raClaim: RemittanceClaim;
  positivePaid: boolean;
  totalToAllocate: number;
  account$ = new BehaviorSubject<Account>(undefined);
  invoicesFilter$ = new BehaviorSubject({});
  invoices$ = new BehaviorSubject<Invoice[]>([]);
  accountSearchResult$ = new BehaviorSubject({ account: {}, patient: {} });
  accountSearchResults$ = new BehaviorSubject([]);
  helper: AbstractUnmatchedEraItemActionHelper;
  defaultAccount: any;
  defaultAccountFormValues$ = new BehaviorSubject(this.DEFAULT_FORM_VALUES);

  accountId$ = this.account$.pipe(
    map((a) => a?.Id),
    distinctUntilChanged(isEqual)
  );

  allInvoices$ = this.accountId$.pipe(
    switchMap((accountId) =>
      this.helper.invoices(accountId, this.ra?.Payment?.PayDate as Date, this.YEARS_TO_SELECT)
    ),
    tap((allInvoices) => {
      const defaultAccountFormValue = this.helper?.defaultAccountFormValue(
        allInvoices,
        this.raClaim,
        this.form.value.AccountSearch,
        this.DEFAULT_FORM_VALUES
      );
      this.resetForm(defaultAccountFormValue);
      this.defaultAccountFormValues$.next(defaultAccountFormValue);
    })
  );

  form: UntypedFormGroup = this.formBuilder.group({
    AccountSearch: [],
    OutstandingBalanceInvoices: [],
    FilterOnClaimMatches: [],
    UnallocatedAmount: [null, Validators.min(0)],
    CouldNotReconcile: [],
    AdditionalRemittanceNotes: [],
    AdditionalAccountNotes: [],
  } as UnmatchedEraItemActionComponentForm);

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    protected remittanceService: RemittanceService,
    private positiveUnmatchedEraItemActionHelper: PositiveUnmatchedEraItemActionHelper,
    private negativeUnmatchedEraItemActionHelper: NegativeUnmatchedEraItemActionHelper
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.raClaim && this.raClaim !== undefined) {
      this.initializeComponent(this.raClaim);
    }
  }

  async ngOnInit() {
    if (this.executionContext === 'inbox') {
      this.inboxItem = this.activatedRoute.snapshot.data.inboxItem as ReconciliationInboxItem;
      this.ra = await this.remittanceService.remittance(
        this.authService.selectedBPN,
        this.inboxItem.RemittanceId
      );
      this.raClaim = await this.remittanceService.raClaim(
        this.authService.selectedBPN,
        this.inboxItem.RemittanceId,
        this.inboxItem.RemittanceClaimId
      );
    }
    await this.initializeComponent(this.raClaim);
  }

  async initializeComponent(raClaim) {
    this.positivePaid = RaUtils.isPositivePaid(raClaim);
    this.helper = this.positivePaid
      ? this.positiveUnmatchedEraItemActionHelper
      : this.negativeUnmatchedEraItemActionHelper;
    this.totalToAllocate = MoneyUtils.fromCents(this.helper.getTotalToAllocate(raClaim));
    this.setupAccountSearch();
    this.setupInvoicesSearch();
    const practiceId = this.authService.practiceBF$.value?.Id;
    this.defaultAccount = await this.helper.matchDefaultAccount(practiceId, raClaim?.MemNum);
    await this.reset(this.DEFAULT_FORM_VALUES);
  }

  setupAccountSearch() {
    this.form.controls.AccountSearch.valueChanges
      .pipe(
        tap((value) => this.accountSearchBusy$.next(value?.length > 0)),
        debounceTime(200),
        withLatestFrom(this.authService.practiceBF$),
        switchMap(
          async ([value, practice]) => await this.helper?.searchAccounts(practice.Id, value)
        ),
        tap((results) => this.accountSearchResults$.next(results)),
        tap(() => this.accountSearchBusy$.next(false)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setupInvoicesSearch() {
    combineLatest([
      this.allInvoices$,
      this.form.controls.OutstandingBalanceInvoices.valueChanges,
      this.form.controls.FilterOnClaimMatches.valueChanges,
    ])
      .pipe(
        map(([invoices, withBalance, matched]) =>
          this.helper?.filterInvoices(invoices, this.raClaim, withBalance, matched)
        ),
        tap((results) => this.invoices$.next(results)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  async onAccountResetClick(event: Event = undefined) {
    event?.stopPropagation(); // avoids dropdown opening
    await this.resetAccountSelection();
  }

  async resetAccountSelection(force: boolean = false) {
    const reset = !force && (await this.onAccountSelected(undefined));
    if (reset) {
      this.form.controls.AccountSearch?.setValue('');
      this.form.controls.UnallocatedAmount.setValue(0);
    }
  }

  async onDiscard() {
    this.saveState$.next('Discarded');
    this.helper.close(this.activatedRoute);
  }

  async onSave() {
    const invoices = this.getPayableInvoices();
    await this.helper.saveAndClose(
      this.account$.value?.Id,
      this.inboxItem?.Id || '',
      this.ra,
      this.raClaim,
      this.form,
      invoices,
      this.saveState$,
      this.activatedRoute,
      this.executionContext === 'suspense account' ? '../../..' : '',
      this.executionContext
    );
  }

  accountPatientDisplayFn(result: { account: Account; patient: AccountMember }) {
    return this.helper.accountPatientDisplayFn(result?.account, result?.patient);
  }

  async reset(defaultFormValues: any) {
    this.resetForm(defaultFormValues);
    await this.onAccountSelected(this.defaultAccount);
  }

  resetForm(defaultFormValues: any) {
    this.form.reset(defaultFormValues, { emitEvent: true });
  }

  async onAccountSelected(result: { account: Account; patient: AccountMember }) {
    this.form.controls.AccountSearch?.setValue(
      this.accountPatientDisplayFn(this.accountSearchResult$.value)
    );
    const confirmed = await this.helper.confirmAccountChange(
      this.account$.value?.Id,
      result?.account?.Id,
      this.getPayableInvoices()
    );
    if (!confirmed) return false;
    this.form.controls.AccountSearch?.setValue(this.accountPatientDisplayFn(result));
    this.accountSearchResult$.next(result);
    const account = result?.account;
    this.account$.next(account ? { ...account } : undefined);
    this.invoices$.next(undefined); // to avoid 'no invoices' banner to be displayed while invoices are loading
    this.accountSearchResults$.next([]);
    this.accountSearchBusy$.next(false);
    return true;
  }

  async onCouldNotReconcile(event: MatSlideToggleChange) {
    if (event?.checked) {
      event.source.toggle();
      const confirmed = await this.helper.confirmCouldNotReconcile(this.getPayableInvoices());
      if (confirmed) {
        event.source.toggle();
        await this.resetAccountSelection(true);
      }
    }
  }

  // Method to check if the form has unsaved changes on navigate out
  async canDeactivate(): Promise<boolean> {
    if (!this.helper) return true;
    return await this.helper.canDeactivate(
      this.saveState$.value === 'Saved',
      this.saveState$.value === 'Discarded',
      this.getPayableInvoices(),
      this.form.value,
      this.defaultAccountFormValues$.value
    );
  }

  getPayableInvoices() {
    return this.positivePaid
      ? this.creditInvoiceComponents?.map((c) => c.getMaPayableInvoice())
      : [this.debitInvoiceComponent?.getPayableInvoice()].filter((i) => !!i);
  }

  getTotalAllocated() {
    return this.helper?.getTotalAllocated(
      this.getPayableInvoices(),
      this.form.value.UnallocatedAmount
    );
  }

  getAccountAllocated(unallocatedAmount: number) {
    return this.helper?.getAccountAllocated(this.getPayableInvoices(), unallocatedAmount);
  }
}
