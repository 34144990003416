import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { arrayUnion } from '@firebase/firestore';
import { DateUtils } from '../../../utils/date-utils';
import { FormatUtils } from '../../../utils/format-utils';
import { RRuleUtils } from '../../../utils/rrule-utils';
import { AuthService } from '../../auth.service';
import { DialogService } from '../../dialog.service';
import { FirestoreService } from '../../firestore.service';
import { CalendarEventQueryService } from '../query/calendar-event-query.service';
import { AbstractEventAction } from './abstract-event.action';
import { CreateEventAction } from './create-event.action';
import { DeleteEventAction } from './delete-event.action';
import {CALENDAR_EVENT_TYPE, CalendarEvent, Repeat} from "@meraki-flux/schema";

export enum UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION {
  THIS = 'This unavailable booking',
  THIS_AND_FOLLOWING = 'This and following unavailable bookings',
  ALL = 'All unavailable bookings',
}

export enum UPDATE_RECURRENT_VISIT_OPTION {
  THIS = 'This appointment',
  THIS_AND_FOLLOWING = 'This and following appointments',
}

@Injectable({
  providedIn: 'root',
})
export class UpdateEventAction extends AbstractEventAction {
  constructor(
    protected override authService: AuthService,
    protected override firestore: Firestore,
    protected override firestoreService: FirestoreService,
    protected dialogService: DialogService,
    protected createEventAction: CreateEventAction,
    protected deleteEventAction: DeleteEventAction,
    protected calendarEventQuery: CalendarEventQueryService
  ) {
    super(firestore, firestoreService, authService);
  }

  protected isRecurrent(event: CalendarEvent) {
    return event.RecurrentInfo?.RootEventId;
  }

  async update(event: CalendarEvent, repeat: Repeat) {
    if (this.isRecurrent(event)) {
      switch (event.EventType) {
        case CALENDAR_EVENT_TYPE.PATIENT_VISIT:
          return await this.updateRecurrentAppointment(event, repeat);
        case CALENDAR_EVENT_TYPE.DOCTOR_LEAVE:
          return await this.updateRecurrentDoctorLeave(event, repeat);
        default:
          throw Error(`Unexpected event type ${event.EventType}`);
      }
    } else {
      await this.updateNonRecurrentEvent(event, repeat);
      return true;
    }
  }

  protected async updateRecurrentAppointment(event: CalendarEvent, repeat: Repeat) {
    if (CALENDAR_EVENT_TYPE.PATIENT_VISIT !== event.EventType || !this.isRecurrent(event)) {
      throw new Error('Must be a visit recurrent event');
    }
    if (!this.shouldRepeat(repeat)) {
      await this.dontRepeatRecurrentEvent(event);
    } else {
      if (!event?.Metadata?.RecurrentRuleEvent)
        throw Error(`Metadata.RecurrentRuleEvent is missing for viewId=${event.Metadata.ViewId}`);
      const timeChanged = this.isRecurrentEventTimeChanged(event);
      const ruleRepeatChanged = RRuleUtils.isRepeatChanged(
        event?.RecurrentInfo?.Rule?.Repeat,
        repeat
      );

      if (timeChanged || ruleRepeatChanged) {
        const updateRecurrentVisitOptions = ruleRepeatChanged
          ? []
          : [UPDATE_RECURRENT_VISIT_OPTION.THIS];
        updateRecurrentVisitOptions.push(UPDATE_RECURRENT_VISIT_OPTION.THIS_AND_FOLLOWING);
        const selection = await this.dialogService.showRadioButtonDialog(
          'Edit recurring patient appointment',
          updateRecurrentVisitOptions,
          updateRecurrentVisitOptions[0]
        );
        if (UPDATE_RECURRENT_VISIT_OPTION.THIS === selection) {
          await this.updateThis(event);
        } else if (UPDATE_RECURRENT_VISIT_OPTION.THIS_AND_FOLLOWING === selection) {
          await this.updateThisAndFollowing(event, repeat);
        } else if (selection) {
          throw Error(`Unsupported operation type ${selection}`);
        } else {
          return false;
        }
      } else {
        await this.updateThis(event);
      }
    }
    return true;
  }

  protected async updateRecurrentDoctorLeave(event: CalendarEvent, repeat: Repeat) {
    if (CALENDAR_EVENT_TYPE.DOCTOR_LEAVE !== event.EventType || !this.isRecurrent(event)) {
      throw new Error('Must be a doctor leave recurrent event');
    }
    if (!this.shouldRepeat(repeat)) {
      await this.dontRepeatRecurrentEvent(event);
    } else {
      const ruleRepeatChanged = RRuleUtils.isRepeatChanged(
        event?.RecurrentInfo?.Rule?.Repeat,
        repeat
      );
      const updateRecurrentDoctorLeaveOptions = ruleRepeatChanged
        ? []
        : [UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS];
      updateRecurrentDoctorLeaveOptions.push(
        UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS_AND_FOLLOWING
      );
      if (DateUtils.isSameDay(event.StartTime, event.Metadata.OriginalEvent.StartTime)) {
        updateRecurrentDoctorLeaveOptions.push(UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.ALL);
      }
      const selection = await this.dialogService.showRadioButtonDialog(
        'Edit recurring unavailable booking',
        updateRecurrentDoctorLeaveOptions,
        updateRecurrentDoctorLeaveOptions[0]
      );
      if (UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS === selection) {
        await this.updateThis(event);
      } else if (UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.THIS_AND_FOLLOWING === selection) {
        await this.updateThisAndFollowing(event, repeat);
      } else if (UPDATE_RECURRENT_DOCTOR_LEAVE_OPTION.ALL === selection) {
        await this.updateAllDoctorLeaves(event, repeat);
      } else {
        return false;
      }
    }
    return true;
  }

  async updateThis(event: CalendarEvent) {
    if (this.isRecurrent(event) && !event?.Metadata?.Id) {
      // if recurrent event wasn't save to DB then we exclude it from the root rule
      const rootEventChange = {
        Id: event.RecurrentInfo.RootEventId,
        RecurrentInfo: {
          Rule: {
            SkipIndexes: arrayUnion(event.RecurrentInfo.Index),
          },
        },
      } as any;
      await this.mergeDoc(rootEventChange);
    }
    await this.mergeDoc({
      ...event,
      Id: event.Id || this.newId(),
    });
  }

  async updateThisById(id: string, change: CalendarEvent) {
    if (!id) {
      throw Error('Calendar Event ID in missing');
    }
    await this.mergeDoc({
      ...change,
      Id: id,
    });
  }

  protected async updateThisAndFollowing(event: CalendarEvent, repeat: Repeat) {
    // remove all future booked events because the following will be generated based on template
    await this.deleteEventAction.deleteEvents(
      event?.RecurrentInfo?.RootEventId,
      event?.RecurrentInfo?.Index
    );
    const rruleEvent = event.Metadata.RecurrentRuleEvent;
    if (event.RecurrentInfo.Index > 0) {
      // break the recurrent series of the event is not the starting one
      await this.mergeDoc({
        Id: event?.RecurrentInfo?.RootEventId,
        RecurrentInfo: {
          Rule: {
            Repeat: {
              EndDate: RRuleUtils.getRecurrentRuleEndDate(
                rruleEvent.StartTime,
                rruleEvent.RecurrentInfo.Rule.Repeat.Frequency,
                event.RecurrentInfo.Index + 1
              ),
            },
            SkipIndexes: rruleEvent.RecurrentInfo?.Rule?.SkipIndexes?.filter(
              (v) => v < event.RecurrentInfo.Index
            ),
          },
        },
      } as CalendarEvent);
    }
    // update current recurrent root for the starting event or start new series
    event.Id =
      event.RecurrentInfo.Index === 0
        ? event.RecurrentInfo.RootEventId
        : event.Metadata.Id || this.newId();
    await this.mergeDoc({
      ...event,
      IsRecurrentRoot: true,
      RecurrentInfo: this.createEventAction.newRecurrentInfo(event, repeat),
    } as CalendarEvent);
  }

  protected async updateAllDoctorLeaves(event: CalendarEvent, repeat: Repeat) {
    const ruleEvent = event?.Metadata?.RecurrentRuleEvent as CalendarEvent;
    if (!event.RecurrentInfo?.RootEventId || !ruleEvent) {
      return;
    }
    await this.mergeDoc({
      Id: event.RecurrentInfo.RootEventId,
      StartTime: DateUtils.updateTime(ruleEvent.StartTime, event.StartTime),
      EndTime: DateUtils.updateTime(ruleEvent.EndTime, event.EndTime),
      DoctorLeaveInfo: {
        Reason: event?.DoctorLeaveInfo?.Reason,
      },
      RecurrentInfo: {
        Rule: {
          Repeat: repeat,
          SkipIndexes: event.RecurrentInfo?.Rule?.SkipIndexes?.filter(
            (v) => v !== event.RecurrentInfo.Index
          ),
        },
      },
    });
  }

  protected async dontRepeatRecurrentEvent(event: CalendarEvent) {
    const rootEventId = event.RecurrentInfo.RootEventId;
    if (!event.Metadata.Id) {
      event.Id = this.newId();
    }
    await this.mergeDoc({
      ...event,
      IsRecurrentRoot: false,
      RecurrentInfo: null,
    } as CalendarEvent);
    await this.deleteEventAction.deleteAll(rootEventId);
  }

  protected isRecurrentEventTimeChanged(event: CalendarEvent) {
    const recurrentRuleEvent = event.Metadata?.RecurrentRuleEvent;
    return (
      FormatUtils.dateHHMM(event.StartTime) !==
        FormatUtils.dateHHMM(recurrentRuleEvent.StartTime) ||
      DateUtils.dateDiffInMins(event.StartTime, event.EndTime) !==
        DateUtils.dateDiffInMins(recurrentRuleEvent.StartTime, recurrentRuleEvent.EndTime)
    );
  }

  // isRuleTemplateUpdated(event: CalendarEvent) {
  //   const template = event.RecurrentInfo?.Rule?.Template;
  //   const newTemplate = RRuleUtils.createEventTemplate(event);
  //   return !_.isEqual(newTemplate, template);
  // }

  protected async updateNonRecurrentEvent(event: CalendarEvent, repeat: Repeat) {
    const IsRecurrentRoot = this.shouldRepeat(repeat);
    await this.mergeDoc({
      ...event,
      IsRecurrentRoot: IsRecurrentRoot,
      RecurrentInfo: IsRecurrentRoot
        ? this.createEventAction.newRecurrentInfo(event, repeat)
        : null,
    });
  }

  async updateRuleEndDate(eventId: string, endDate: Date) {
    if (!eventId) {
      return;
    }
    await this.mergeDoc({
      Id: eventId,
      RecurrentInfo: {
        Rule: {
          Repeat: {
            EndDate: endDate || null,
          },
        },
      },
    } as CalendarEvent);
  }
}
